import React, { Component } from "react";
import PropTypes from "prop-types";
import { Favorite, FavoriteBorder, PermDataSettingSharp } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Badge } from "@material-ui/core";
import { GREY } from "../../utils/color_utils";
import { GET_POINTS, CLEAR_POINTS, SHOW_TOAST } from "./../../store/actions/types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateLikePost } from "./../../store/actions/likesActions";
import LikedByDialog from "../Dialogs/LikedByDialog";
import { likesPoints } from './../../store/actions/freshCardActions';
import { getRealPoints } from './../../store/actions/realPointsActions'
import { showToast } from './../../store/actions/toastAction'
import lodash from "lodash";
import firebase from './../../store/firebase';
import { _toArrayUsers } from './../../store/actions/commonActions'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
// import { updateDecorator } from "typescript";

const styles = theme => ({
  favoritesdiv: {
    color: "#fff",
    float: "left!important",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center"
  },
  badge: {
    width: 40,
    color: "#676543",
    padding: "8px",
    fontSize: "8pt",
    border: `2px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
      }`,
    right: "-10px",
    backgroundColor: '#CDCCB6',
    pointer: "cursor"
  },
  mbadge: {
    width: 20,
    color: "#676543",
    padding: "5px",
    fontSize: "8pt",
    border: `1px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
      }`,
    right: "1px",
    backgroundColor: '#CDCCB6',
    pointer: "cursor"
  }
});

// Get the user thats logged in

// TODO: Call it Liking
class LikeButton extends Component {
  //// console.log("LIKED", liked);
  state = {
    //liked: false,
    likeCount: 0,
    userLoggedIn: {},
    openLikedByDialog: null,
    pointsData: 0
  };

  static postTypes = ["thoughts", "articles", "podcasts", "videos"];

	makeid = (length) => {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * 
	charactersLength));
		}
		return result;
	}

  getLikeUnlike(props) {
    for (let x in props) {
      return props[x].likeUnlike
    }
  }

  getRPoints(props) {
    for (let x in props) {
      return props[x].points
    }
  }

  pointsPass = (dbPoints, realPoints) => {
    console.log('poixxxxxs 1 ', dbPoints);
    console.log('poixxxxxs 2 ', realPoints);

    var pass = false;
    if (dbPoints < realPoints) {
      if (parseInt(realPoints) - parseInt(dbPoints) < 2) { 
        pass = true;
      }
    } else
    {
      if (parseInt(dbPoints) - parseInt(realPoints) < 2) { //too big - CANCEL
        pass = true;
      } 
    }
    console.log('poixxxxxs 4 ', pass);
    return pass;
  }


  getPoints = (post, realPoints, realPointsy) => {
    //const { postType, postID, userdata, rrpoints } = this.props;
    const { postType, postID, userdata } = this.props;
    console.log('GET-REAL-POINTS 5 ', realPoints);
    const params = {
      postType: postType,
      postID: postID,
      //likeUnlike: !this.state.liked ? "unlike" : "like"
      likeUnlike: !this.state.liked ? "like" : "unlike"
    };
    return new Promise((resolve, reject) => {

      setTimeout(() => {
        let data = { 
          uid: this.props.post.uid,
          userID: this.props.post.journalUserID,
          //pointsCurrent: this.props.rrpoints,
          pointsCurrent: realPoints,
          ypointsCurrent: realPointsy, //this.props.post.pointsy,
          leadID: this.props.postID,
          leadPoints: this.props.post.leadPoints ? this.props.post.leadPoints : 0,
          // pointsCalf: this.props.post.pointsCalf,
          pointsAdded: params.likeUnlike==="like" ? 1 : -1,
          trailID: this.makeid(10),
          companyName: this.props.post.companyName,
          firstName: this.props.post.journalUserName, // this.props.post.firstName,
          lastName: "empty", // this.props.post.lastName,
          title: this.props.post.title,
          actionType: "Ilead", //this.state.postInternalGlobal + ' added',
          actionDesc: "LEAD comment", //this.state.postInternalGlobal,
          currentUser: userdata.userID
          }

          console.log('greenopenLikedBy pre : ', data)

        if ((userdata.userID !== this.props.post.journalUserID) && (data.pointsCurrent)) { //not the same person (LEAD author cant get points by commenting on own LEAD)
          console.log('greenopenLikedBy data : ', data)
          console.log('greenopenLikedBy props : ', this.props)
         
          // if(data.pointsAdded > 0) {
          //   var totPoints = parseInt(data.pointsCurrent) + parseInt(1); 
          // }
          // else{
          //   var totPoints = parseInt(data.pointsCurrent) - parseInt(1); 
          // }
          //update LEAD points on LEAD
         //this.props.updateLeadPoints(totPoints)

        //  this.fetchData()

          //GET REAL POINTS DIRECTLY FROM DATABASE
          // console.log('green pointsPass state : ', this.state.pointsData);

          //  var pdata;
          //  firebase.database
          //  .ref('user/' + data.uid)
          //  .on('value', function (xdata) {
          //    if (xdata.val().points) {
          //     pdata = xdata.val().points
          //     console.log('GET-REAL-POINTS 3: ', pdata )
          //    }})
          //    console.log('GET-REAL-POINTS 4: ', pdata )
          //ADD POINTS TO LEAD CREATOR
          //if(data.pointsCurrent) {
           //if (this.pointsPass(pdata, this.props.rrpoints)) {
            
             console.log('GET-REAL-POINTS 6 ', data);

            // this.props.likesPoints(data)

             console.log('inside showLead function 0 ', this.props.showLeadb?this.props.showLeadb:'')

          // }
          //}
          //else
          //{
            //  let params = {
            //    type: SHOW_TOAST,
            //    open: true,
            //    variant: data.pointsCurrent ?  'warning' : 'success',
            //    message: data.pointsCurrent ? 'Points could not be updated. Please try later.' : 'OK, Points activated'
            //  }
      
            // this.props.showToast(params)
          }
          if (this.props.showLeadb) {
            console.log('inside showLead function 1 ', this.props.post)
           //  const exdata = {
           //   points: data.pointsAdded
           //  }
            this.props.showLeadb(this.props.post, data.pointsAdded);
          }
        //}
      }, 2000);     
    });
  }

   handleUpdatePostLike = async (post, realPoints) => {
    const { postType, postID, userdata } = this.props;
  
    console.log('GET-REAL-POINTS 0: ', this.props )

     let data, ydata = 0;
     if (post.uid) {
      //GET REAL POINTS DIRECTLY FROM DATABASE
     
      await firebase.database
      .ref('user/' + post.uid)
      .once('value', function (xdata) {
        if (xdata.val().points) {
          console.log('GET-REAL-POINTS 1: ', xdata )
          data = xdata.val().points
          ydata = xdata.val().pointsy
         // console.log('GET-REAL-POINTS 1: ', data )
         // return data
        }
      }
      )
      console.log('GET-REAL-POINTS 2: ', data )
     }
    this.setState({
      liked: !this.state.liked,
    });
    console.log('GET-REAL-POINTS 3: ', data )
    const params = {
      postType: postType,
      postID: postID,
     // likeUnlike: !this.state.liked ? "unlike" : "like"
      likeUnlike: !this.state.liked ? "like" : "unlike"
    };
   console.log('GET-REAL-POINTS 4: ', data )
    this.props.updateLikePost(params, post, this.props.userdata);  

      const promise = this.getPoints(post, data, ydata);
      promise.then(this.onFulfilled, this.onRejected);
  };

  handleLikeClick = (e, post) => {
    e.preventDefault()
    this.handleUpdatePostLike(post);
  };

  handleCloseActivityDialog = () => {
    this.setState({
      openLikedByDialog: null
    });
  };

  openLikedBy = () => {
    this.setState({
      openLikedByDialog: true
    });
  };

  componentDidMount() {
    const { userdata, post } = this.props;
    console.log('green stuff 1 : ', post)
    console.log('green stuff 2 : ', this.props)
    
    let liked = post.likesData && post.likesData[userdata.userID];
    
    this.setState(prevState => ({
      userLoggedIn: userdata,
      liked: liked
    }));
  }


  render() {
    const { classes, post } = this.props;
    console.log('LIKING-PROPS- : ', this.props)
    return (
      <div className={classes.favoritesdiv}>
        <div>
          <IconButton
            style={{
              color: "",
              backgroundColor: '#CDCCB6',
              userSelect: "none"
             }}
            aria-label="Add to favorites"
            onClick={() => this.handleUpdatePostLike(post, this.data)}
          >
            {this.state.liked ? (                              
                 <Favorite style={{ width: "12px", height: "12px" }} />
            ) : (
                <FavoriteBorder style={{ width: "12px", height: "12px" }} />
              )}
          </IconButton>
        </div>
        <div style={{ cursor: "pointer" }}>
       
          <Badge
            onClick={this.openLikedBy}
            invisible={false}
            badgeContent={
               post.likesData
                 ? Object.keys(post.likesData).length
                 : 0
             }

            classes={{ badge: isMobile ? classes.mbadge : classes.badge }}
            style={{ cursor: "pointer" }}
          />
          
          {this.state.openLikedByDialog ? (
            <LikedByDialog
              open={this.state.openLikedByDialog}
              likeData={post.likesData}
              onCloseLikedBy={this.handleCloseActivityDialog.bind(this)}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

LikeButton.propTypes = {
  post: PropTypes.object.isRequired,
  postType: PropTypes.string.isRequired,
  postID: PropTypes.string.isRequired
};

const Liking = withStyles(styles, { withTheme: true })(LikeButton);

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      updateLikePost,
      likesPoints,
      getRealPoints,
      showToast
    },
    dispatch
  )
});

const mapStateToProps = (state, ownProps) => {
  return {
    likes: state.likes,
    userdata: state.userDialog.userDetails[0],
    rrpoints: state.realpoints.realpoints,
    //thoughts: state.thoughts.ilead
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Liking);
