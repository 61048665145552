import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ArticlesView from '../components/Views/ArticlesView'
import { connect } from 'react-redux'
import {
  fetchArticles,
  fetchExternalArticles
} from '../store/actions/articlesActions'
import { fetchUserData } from '../store/actions/userActions'
import {
  INTERNAL_ARTICLES,
  EXTERNAL_ARTICLES,
  SET_SEEN_NOTIFICATIONS
} from '../store/actions/types'
import { deletePostAttachment } from './../store/actions/attachmentActions'

class Articles extends Component {
  constructor (props) {
    super(props)
    this.state = {
      height: window.innerHeight,
      minData: 6
    }
    this.handleScroll = this.handleScroll.bind(this)
  }
  handleScroll() {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const windowBottom = Math.round(windowHeight + window.pageYOffset)
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 6
      })
      this.fetchData()
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentWillMount = () => {
    this.fetchData()
  };

  fetchData = () => {
    let params = {
      type: INTERNAL_ARTICLES,
      companyID: this.props.userdata.companyID,
      orderChild: 'companyID_status',
      equalTo: this.props.userdata.companyID + '_approved',
      minData: this.state.minData
    }

    let params_external = {
      type: EXTERNAL_ARTICLES,
      companyID: this.props.userdata.companyID,
      orderChild: 'dailyThoughtType_status',
      equalTo: '2_approved',
      minData: this.state.minData
    }

    // load harmony posts only
    if (this.props.userdata && this.props.userdata.companyID !== '-Lu4BlhZ-tsDSnd-fnFd') {
      this.props.fetchExternalArticles(params_external)
    }

    this.props.fetchArticles(params)

    this.props.fetchUserData({
      type: SET_SEEN_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      path: this.props.match.path,
      unseenotifications: this.props.unseenotifications.unseenotifications
    })
  };

  render() {
    const { articles, userdata } = this.props
    return (
      <div>
        {userdata && articles ? (
          <React.Fragment>
            <ArticlesView
              articles={articles}
              currentUser={userdata}
              {...this.props}
              openFileViewer={this.props.openFileViewer}
            />
          </React.Fragment>
        ) : null}
      </div>
    )
  }
}

Articles.propTypes = {
  fetchArticles: PropTypes.func.isRequired,
  fetchExternalArticles: PropTypes.func.isRequired,
  articles: PropTypes.object,
  externalarticles: PropTypes.object
}

const mapStateToProps = state => {
  return {
    articles: {
      ...state.articles.articles,
      ...state.articles.externalarticles
    },
    userdata: state.userDialog.userDetails[0],
    unseenotifications: state.userdata.unseenotifications
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchArticles: params => dispatch(fetchArticles(params)),
    deletePostAttachment: params => dispatch(deletePostAttachment(params)),
    fetchExternalArticles: params => dispatch(fetchExternalArticles(params)),
    fetchUserData: params => dispatch(fetchUserData(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Articles)
