import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ViewLikedBy from "./ViewLikedBy";
import FavoriteIcon from "@material-ui/icons/Favorite";

import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CloseIcon from "@material-ui/icons/Close";
import { HOVERRED, RED, LIGHTBLUE, LIGHTGREY } from "../../utils/color_utils";

const styles = theme => ({
  appBar: {
    position: "static",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  flex: {
    flex: 1
  },
  dialog: {},
  divStyle: {
    padding: "0px",
    paddingTop: "0px!important",
    maxHeight: "100vh!important",
    margin: "0px"
  },
  close: {
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "999999",
    color: "#fff",
    backgroundColor: `${RED}`,
    borderRadius: "0%",
    padding: "8px",
    "&:hover": {
      background: `${HOVERRED}`
    },
    [theme.breakpoints.down("sm")]: {
      top: "10px",
      right: "10px",
      padding: "10px",
      backgroundColor: "#f44336",
      zIndex: "99999999999"
    },
    webkitTransform: `translate3d(0,0,0)`
  }
});

function Transition(props) {
  return <Slide direction="left" {...props} />;
}
class LikeByDialog extends React.Component {
  state = {
    open: false,
    scroll: "paper"
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = e => {
    this.props.onCloseLikedBy();
  };

  render() {
    const { classes, theme, fullScreen, option, likeData } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          fullScreen={fullScreen}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          aria-labelledby="scroll-dialog-title"
          style={{ height: "100vh!important" }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              color: `${LIGHTBLUE}`,
              borderBottom: `1px solid ${LIGHTGREY}`
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FavoriteIcon
                fontSize="small"
                style={{ color: `${LIGHTBLUE}` }}
              />
              <span
                style={{
                  color: `${LIGHTBLUE}`,
                  fontWeight: "bold",
                  fontSize: "15px"
                }}
              >
                &nbsp;Liked By
              </span>
            </div>
            <Button
              variant="contained"
              onClick={this.handleClose.bind(this)}
              style={{
                backgroundColor: RED,
                color: "#fff",
                padding: "10px",
                top: "10px",
                right: "2%",
                position: "absolute"
              }}
            >
              <CloseIcon fontSize="small" className={classes.icon} />
            </Button>
          </DialogTitle>
          <DialogContent className={classes.divStyle}>
            {/* <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose.bind(this)}
            >
              <CloseIcon fontSize="small" className={classes.icon} />
            </IconButton> */}
            <ViewLikedBy likeData={likeData} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

LikeByDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

const likedByDlg = withMobileDialog({ breakpoint: "xs" })(LikeByDialog);

export default withStyles(styles, { withTheme: true })(likedByDlg);
