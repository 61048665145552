import React, { Component } from "react";
import PropTypes from "prop-types";
import GroupThoughtsView from "../components/Views/GroupThoughtsView";
import GroupArticlesView from "../components/Views/GroupArticlesView";
import GroupPodcastsView from "../components/Views/GroupPodcastsView";
import GroupVideosView from "../components/Views/GroupVideosView";
import { connect } from "react-redux";
import { fetchThoughts } from "../store/actions/thoughtsActions";
import { fetchUserData } from "../store/actions/userActions";
import { SET_SEEN_NOTIFICATIONS, SELECTED_GROUP_POSTS, SELECTED_GROUP_FILES } from "../store/actions/types";
import { deletePostAttachment } from "../store/actions/attachmentActions";

class GroupPosts extends Component {
  constructor (props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      minData: 10,
      group_id: ""
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 10
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    // window.addEventListener("scroll", this.handleScroll);
    // this.fetchData();
  }

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
    // this.fetchData();
  };

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.group_id !== "") {
    //   if (prevProps.group_id !== this.props.group_id) {
    //     // console.log("fetching data")
    //     this.setState({ group_id: this.props.group_id })
    //     this.fetchDataInit();
    //     this.props.updateGroupId();
    //   }
    // }

  }

  fetchData = () => {
    var params = {
      type: SELECTED_GROUP_POSTS,
      companyID: this.props.userdata.companyID,
      orderChild: "dailyThoughtType_status",
      equalTo: "4_approved",
      minData: this.state.minData,
      group_id: this.state.group_id,
      // userID: this.props.userdata.userID,
      // companyName: this.props.userdata.companyName,
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    };

    this.props.fetchThoughts(params);

    this.props.fetchUserData({
      type: SET_SEEN_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      unseenotifications: this.props.unseenotifications.unseenotifications
    });
  };

  fetchDataInit = () => {
    var params = {
      type: SELECTED_GROUP_POSTS,
      companyID: this.props.userdata.companyID,
      orderChild: "dailyThoughtType_status",
      equalTo: "4_approved",
      minData: this.state.minData,
      group_id: this.props.group_id,
      userID: this.props.userdata.userID,
      companyName: this.props.userdata.companyName,
      firstName: this.props.userdata.firstName,
      lastName: this.props.userdata.lastName,
      points: this.props.userdata.points ? this.props.userdata.points : ''
    };
    this.props.fetchThoughts(params);

    this.props.fetchUserData({
      type: SET_SEEN_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      unseenotifications: this.props.unseenotifications.unseenotifications
    });
  };

  render() {
    const { groupposts, userdata } = this.props;
    return (
      <div>
        {groupposts ?
          Object.keys(groupposts).map(id => {
            return groupposts[id].postGroupType === "thoughts" ? (
              <GroupThoughtsView
                thought={groupposts[id]}
                currentUser={userdata}
                {...this.props}
                openFileViewer={this.props.openFileViewer}
              />
            ) : groupposts[id].postGroupType === "articles" ? <GroupArticlesView grouparticle={groupposts[id]}
              currentUser={userdata}
              {...this.props} openFileViewer={this.props.openFileViewer} /> : groupposts[id].postGroupType === "podcasts" ? <GroupPodcastsView podcast={groupposts[id]}
                currentUser={userdata}
                {...this.props} /> : groupposts[id].postGroupType === "videos" ? <GroupVideosView video={groupposts[id]}
                  currentUser={userdata}
                  {...this.props} /> : null;
          })
          : <div style={{ textAlign: 'center', padding: '100px 20px 20px' }}>No posts available</div>}
      </div>
    );
  }
}

GroupPosts.propTypes = {
  fetchThoughts: PropTypes.func.isRequired,
  groupposts: PropTypes.any,
  groupfiles: PropTypes.any,
  userdata: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    // groupposts: state.thoughts.groupposts,
    userdata: state.userDialog.userDetails[0],
    unseenotifications: state.userdata.unseenotifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchThoughts: params => dispatch(fetchThoughts(params)),
    deletePostAttachment: params => dispatch(deletePostAttachment(params)),
    fetchUserData: params => dispatch(fetchUserData(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupPosts);
