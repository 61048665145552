import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import VideosCard from "../Cards/VideosCard";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class GroupVideosView extends React.Component {
  render() {
    const { classes, video, followingData } = this.props;

    return (
      <React.Fragment>
        <div className={classes.cardstyles}>
          {video ?
            <VideosCard
              video={video}
              key={video.id}
              {...this.props}
              following={
                followingData
                  ? followingData[video.userID]
                    ? true
                    : false
                  : false
              }
              hideComments={false}
            />
            : null}
        </div>
      </React.Fragment>
    );
  }
}

GroupVideosView.propTypes = {
  classes: PropTypes.object.isRequired,
  video: PropTypes.any
};

var GroupVideosViewStyled = withStyles(styles)(GroupVideosView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(GroupVideosViewStyled);
