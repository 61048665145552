/* eslint-disable */
import React, { Fragment } from "react";
//import { ShareButtons, ShareCounts, generateShareIcon } from "react-share";
//import { TwitterShareButton } from "react-share";
// import { Helmet } from 'react-helmet-async';
// import { HelmetProvider } from 'react-helmet-async';
//import { SimpleShareButtons } from "react-simple-share";
//-----1
//-----2
//-----3
import { Facebook } from "react-social-sharing";
import { Twitter } from "react-social-sharing";
import { Linkedin } from "react-social-sharing";
//import MetaTags from 'react-meta-tags';
import ReducedThoughtsDialog from "../Dialogs/ReducedThoughtsDialog";


const shareStyle = {
  marginLeft: "auto",
  marginTop: "auto",
  minWidth: "200px"
  //zIndex: "600",
};

const socialStyle = {
  marginLeft: "auto",
  marginTop: "auto",
  minWidth: "200px"
};
class Sharebuttons extends React.Component {
  constructor (props) {
    super(props);
    //// console.log('LOGO: ', this.props.logoprop);
  }

  render() {

    return (

      <React.Fragment>
        {/* <div className="wrapper"> */}
          {/* <MetaTags>
            <title>ThinkLead</title>
            <meta charSet="utf-8" />
            <meta property="og:title" content={this.props.thought} />
            <meta
              name="description"
              content={this.props.thought}
            />
            <meta
              property="og:description"
              content={this.props.thought}
            />
            <meta name="twitter:card" content="photo" />
            <meta name="twitter:site" content="@qscuttertutor" />
            <meta name="twitter:creator" content="@narayanprusty" />
            <meta name="twitter:title" content="An Introduction To Twitter Card" />
            <meta name="twitter:image:width" content="610" />
            <meta name="twitter:image:height" content="610" />

            <meta property="og:image" content={this.props.imageprop} />
            <meta property="og:url" content={this.props.post} />
            <meta name="og:card" content={this.props.imageprop} />
            <meta name="twitter:image" content={this.props.imageprop} />
            <meta property="og:type" content="website" />
            <link property="og:url" href={this.props.website} />

            <meta property="og:site_name" content="Global Leadership Platform" />
            <meta name="twitter:image:alt" content={this.props.slogan} />
            <meta property="fb:app_id" content="your_app_id" />

            <meta name="twitter:site" content={this.props.website} />
            <meta name="twitter:creator" content={this.props.quotemaster} />
          </MetaTags>

          <div className="content"> Post </div>
        </div>      */}

    {/* <HelmetProvider>
      <Helmet defer={false}>
       <title>ThinkLead</title>
       <meta name="title" content="Default Title" data-react-helmet="true"/>
      <meta charSet="utf-8" />
      <meta property="og:title" content={this.props.thought}/>
      <meta
        name="description"
        content={this.props.thought} 
      />
      <meta
        property="og:description"
        content={this.props.thought}
      />
      <meta property="og:image" content={this.props.imageURL}/>
      <meta property="og:url" content={this.props.post} />
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="og:card" content={this.props.imageURL}/>
      <meta name="twitter:image" content={this.props.imageURL}/>
      <meta property="og:type" content="website"/>
      <link property="og:url" href={this.props.website}/>

      <meta property="og:site_name" content="Global Leadership Platform"/>
      <meta name="twitter:image:alt" content={this.props.slogan}/> 
      <meta property="fb:app_id" content="your_app_id" />
 
      <meta name="twitter:site" content={this.props.website} />
      <meta name="twitter:creator" content={this.props.quotemaster} />
    </Helmet>  
    </HelmetProvider> */}

        {/* {this.props.source==='articlesscard'?
             <ReducedArticlesDialog 
             postuserId={this.props.postuserId} 
             postuserName={this.props.postuserName} 
             props={this.props} 
             thought={this.props.thought} 
             userdata={this.props.userdata}
             imageURL={this.props.imageURL}
             quotemaster={this.props.quotemaster}
             location={this.props.location}
             closeModal={this.props.closeModal}
             imageprop={this.props.imageprop}
             articleLink={this.props.articleLink}
             />
            :null} */}

            <div style={shareStyle}>

           {this.props.source==='thoughtscard'?
           
             <ReducedThoughtsDialog
             postuserId={this.props.postuserId} 
             postuserName={this.props.postuserName} 
             //props={this.props} 
             thought={this.props.thought} 
             userdata={this.props.userdata}
             imageURL={this.props.imageURL}
             quotemaster={this.props.quotemaster}
             location={this.props.location}
             closeModal={this.props.closeModal}
             imageprop={this.props.imageprop}
             podcasturl={this.props.podcasturl}
             podcastname={this.props.podcastname}
             videoname={this.props.videoname} 
             videourl={this.props.videourl}
             filetitle={this.props.filetitle}
             fileurl={this.props.fileurl}
             articlesurl={this.props.articlesurl}
             articletitle={this.props.articletitle}
                          
             filesprop={this.props.filesprop}
             logoprop={this.props.logoprop}
             slogan={this.props.slogan}
             website={this.props.website}
             textcolor={this.props.textcolor}
             />
            
            :null}
            </div>

        <div style={socialStyle}>
          <Facebook
            solidcircle
            small
            message={this.props.post}
            link={this.props.post}
          />

          <Twitter
            solidcircle
            small
            text="Leadership Message"
            link={this.props.post}
          />

          <Linkedin
            solidcircle
            small
            text="Leadership Message"
            link={this.props.post}
          />
        </div>
      </React.Fragment>

    );
  }
}
export default Sharebuttons;
