import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Paper,
	TextField,
	InputBase,
	Fab,
	Typography,
	Grid,
	InputAdornment,
	MenuItem,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar
} from '@material-ui/core';
import { PHOTO_BACKGROUND } from '../../utils/photo_utils';

// const VideosResults = ({ searchQuery }) => (
// 	<Query query={queries.QUERY_LIST_VIDEOS} fetchPolicy="cache-and-network">
// 		{({ loading, data, error, fetchMore }) => {
// 			if (loading) return <span>loading....</span>;
// 			return <div>
// 				{ fetchMore({
// 					variables: {
// 						searchQuery
// 					},
// 					updateQuery: (previousResult, { fetchMoreResult }) => ({
// 						...previousResult,
// 						listVideos: {
// 							...previousResult.listVideos,
// 							videos: fetchMoreResult.listVideos.videos
// 						}
// 					})
// 				}) }

// 			</div>;
// 		}}
// 	</Query>
// );

const ThoughtResult = ({ classes, thought }) => (
	<ListItem alignItems="flex-start" divider={true} button>
		<ListItemAvatar>
			<Grid item md={2} xs={3}>
				<img className={classes.img} src={thought.photoURL} />
			</Grid>
		</ListItemAvatar>
		<ListItemText
			primary={thought.title}
			secondary={
				<React.Fragment>
					<Typography gutterBottom component="h2" color="textPrimary">
						{thought.subtitle} |{thought.stringDateRegistered}
					</Typography>
					<Typography component="subtitle2" color="textPrimary">
						{thought.journalUserName}
					</Typography>
					{` — ${thought.dailyThoughtDescription}`}
				</React.Fragment>
			}
		/>
	</ListItem>
);

const ArticleResult = (props) => <h1>Articles Results</h1>;

const PodcastResult = (props) => <h1>Podcast Results</h1>;

const UserResult = (props) => <h1>Users Results</h1>;
const VideosResult = (props) => <h1>VideosResults</h1>;
class Results extends Component {
	renderResults = () => {
		const { type, items } = this.props;
		let resultList = [];
		switch (type) {
			case 'thoughts':
				resultList = items.map((thought, i) => <ThoughtResult thought={thought} key={i} {...this.props} />);
				break;
			case 'articles':
				resultList = items.map((article, i) => <ArticleResult article={article} key={i} {...this.props} />);
				break;
			case 'podcasts':
				resultList = items.map((podcast, i) => <PodcastResult podcast={podcast} key={i} {...this.props} />);
				break;
			case 'videos':
				resultList = items.map((video, i) => <VideosResult video={video} key={i} {...this.props} />);
				break;

			default:
				resultList = <p>Sorry, no results.</p>;

				break;
		}
		return resultList;
	};

	render() {
		return this.renderResults();
	}
}

Results.propTypes = {};

export default Results;
