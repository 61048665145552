//

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit
  }
});

var dialogMessages = {
  thoughts:
    "Would you like to save this LEAD under your favourites?",
  articles:
    "Would you like to save this Article under your favourites?",
  podcasts:
    "Based on what you just listened to, would you like to save this Podcast under your favourites?",
  videos:
    "Based on what you just watched, would you like to save this Video under your favourites?"
};

class AddToPLDPDialogDialog extends React.Component {
  state = {
    open: false,
    fullWidth: true,
    maxWidth: "md"
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.props.onCloseDialog();
  };

  handleProceed = () => {
    this.props.onClickProceed();
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">My LFP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessages[this.props.postType]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={this.handleProceed} color="primary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddToPLDPDialogDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AddToPLDPDialogDialog);
