import { gGET_O, gGET_O_S, gGET_SLAVE_OPINION, gGET_OPINIONID } from '../actions/types';

const initState = {
  Gopinion: {},
  Gfeedback: {},
  Gopinionid: '',
  Gsopinion: {},
};

const opinionG_Reducer = (state = initState , action) => {
  switch(action.type){
    case gGET_O:
      return{ 
        ...state,
        type: "gGET_O",
        Gopinion: action.opinion
      }
    case gGET_O_S:
      console.log('green putOpinions reducer: ', action.Gsopinion)
      return{ 
        ...state,
        type: "gGET_O_S",
        Gsopinion: action.sopinion
      }
    case gGET_SLAVE_OPINION:
      console.log('green opinionReducer : ', action.Gslaveopinion)
      return{ 
        ...state,
        type: "gGET_SLAVE_OPINION",
        Gfeedback: action.slaveopinion 
      }
    case gGET_OPINIONID:
      return{ 
        ...state,
        type: "gGET_OPINIONID",
        Gopinionid: action.opinion
      }
    default:
      return state
  }
  
}

export default opinionG_Reducer