import { UPDATE_POST_LIKING } from './../actions/types';

const initialState = {
	thought: {},
	isLiked: false
};

const likesReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_POST_LIKING:
			return {
				...state,
				thought: action.thought
			};
			break;

		default:
			return state;
			break;
	}
};

export default likesReducer;
