import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArticlesCard from "../Cards/ArticlesCard";
import ReadArticles from "./ReadArticles";
import MediaPlayer from "../Dialogs/MediaPlayer";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class GroupArticlesView extends React.Component {
  state = {
    readarticles: false,
    article: null,
    openmediaplayer: false,
    mediatype: "articles",
    media: null,
    openAddPLDP: false,
    attachmentType: ""
  };

  UpdateReadArticles = () => {
    this.setState({
      ...this.state,
      readarticles: false,
      article: null
    });
  };

  ReadArticles = article => {
    this.setState({
      ...this.state,
      readarticles: true,
      article: article
    });
  };

  OpenMediaPlayer = (media, mediatype) => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media,
      mediatype: mediatype
    });
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    });
  };

  handleRemoveAttachement = (thought, attachmentType, attachmentID) => { };

  render() {
    const { classes, grouparticle, currentUser, followingData } = this.props;
    const {
      readarticles,
      article,
      openmediaplayer,
      media,
      mediatype
    } = this.state;
    return (
      <React.Fragment>
        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}
        {readarticles ? (
          <div>
            <ReadArticles
              article={article}
              closeViewer={this.UpdateReadArticles}
              ReadArticle={this.ReadArticles}
              currentUser={this.props.currentUser}
              {...this.props}
            />
          </div>
        ) : (
            <div className={classes.cardstyles}>
              {grouparticle ? <ArticlesCard
                ReadArticle={this.ReadArticles}
                article={grouparticle}
                currentUser={currentUser}
                PlayMedia={this.OpenMediaPlayer}
                onRemoveAttachement={this.handleRemoveAttachement}
                key={grouparticle.id}
                {...this.props}
                following={
                  followingData
                    ? followingData[grouparticle.journalUserID]
                      ? true
                      : false
                    : false
                }
                hideComments={false}
                openFileViewer={this.props.openFileViewer}
              />
                : null}
            </div>
          )}
      </React.Fragment>
    );
  }
}

GroupArticlesView.propTypes = {
  classes: PropTypes.object.isRequired,
  grouparticle: PropTypes.any
};

var GroupArticlesViewStyled = withStyles(styles)(GroupArticlesView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(GroupArticlesViewStyled);