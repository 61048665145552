import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SurveyView extends Component {
  render() {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', backgroundColor: '#fff',
        borderRadius: '6px', paddingTop: '20px'
      }} dangerouslySetInnerHTML={{ __html: this.props.survey_link }} />
    )
  }
}

SurveyView.propTypes = {
  classes: PropTypes.object
}

export default SurveyView;
