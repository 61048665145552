import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArticlesCard from "../Cards/ArticlesCard";
import ReadArticles from "./ReadArticles";
import MediaPlayer from "../Dialogs/MediaPlayer";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";
import { HIDE_COMMENTS_BUTTON } from "../../store/actions/types";

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class ArticlesView extends React.Component {
  state = {
    readarticles: false,
    article: null,
    openmediaplayer: false,
    mediatype: "articles",
    media: null,
    openAddPLDP: false,
    attachmentType: ""
  };

  UpdateReadArticles = () => {
    this.setState({
      ...this.state,
      readarticles: false,
      article: null
    });
  };

  ReadArticles = article => {
    this.setState({
      ...this.state,
      readarticles: true,
      article: article
    });
  };

  OpenMediaPlayer = (media, mediatype) => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media,
      mediatype: mediatype
    });
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    });
  };

  handleRemoveAttachement = (thought, attachmentType, attachmentID) => { };

  render() {
    const { classes, articles, currentUser, followingData } = this.props;
    const {
      readarticles,
      article,
      openmediaplayer,
      media,
      mediatype
    } = this.state;
    return (
      <React.Fragment>
        {/* {Object.keys(articles).length > 0 ? null : (
          <Progressbar className={classes.progress} color="secondary" />
        )} */}

        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}
        {readarticles ? (
          <div>
            <ReadArticles
              article={article}
              closeViewer={this.UpdateReadArticles}
              ReadArticle={this.ReadArticles}
              currentUser={this.props.currentUser}
              {...this.props}
            />
          </div>
        ) : (
            <div className={classes.cardstyles}>
              {articles &&
                Object.keys(articles).map(id => {
                  return (
                    <ArticlesCard
                      ReadArticle={this.ReadArticles}
                      article={articles[id]}
                      currentUser={currentUser}
                      PlayMedia={this.OpenMediaPlayer}
                      onRemoveAttachement={this.handleRemoveAttachement}
                      key={id}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[articles[id].journalUserID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                      openFileViewer={this.props.openFileViewer}
                    />
                  );
                })}
            </div>
          )}
      </React.Fragment>
    );
  }
}

ArticlesView.propTypes = {
  classes: PropTypes.object.isRequired,
  articles: PropTypes.object
};

var ArticlesViewStyled = withStyles(styles)(ArticlesView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(ArticlesViewStyled);
