import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import {
  SHOW_CALF, SIMPLE_VAL2, CHECK_TOKEN
} from '../store/actions/types'
import { showCalf } from '../store/actions/calfActions'
import { convertToken, getMyToken, createToken, getAdmin, checkToken } from '../store/actions/companyAdminActions'
import AddMyTokensDialog from '../components/Dialogs/AddMyTokensDialog'
//components/Dialogs/AddMyTokensDialog.js
import ReactPlayer from 'react-player'
import img1 from './eximg/img1.jpg'
import img2 from './eximg/img2.jpg'
import img3 from './eximg/img3.jpg'
import * as qs from 'qs'
import GenTutorial from '../pages/PointsSurveys/GenTutorial'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import { simpleValAction2 } from '../store/actions/simpleValAction2'

class Gotocalf extends React.Component {
  state = {
    code: null,
    calfmember: false,
    tokenData: null,
    success: true,
    openDialog: false
  };

  constructor(props) {
    super(props)

    // this.sendGetRequest(this.props.calfemail, this.props, '2') //set onething = true if on calf databse
  }

  askPermission = () => {
    const { tokenRequested} = this.props
    // this.props.loadingAction({
    //   leaderboard_loading: 1,
    //   type: LEADERBOARD_LOADING
    // })
    console.log('inside askpermission 1 ', tokenRequested)
    const promise = this.getPermission()
    promise.then(this.onFulfilled, this.onRejected)
  }

  handleCloseDialog = () => {
    console.log('green createToken usersData 2 : ', this.state.openDialog);
    this.setState({ openDialog: !this.state.openDialog })
  };

///////////////////////////////////////////
getOwnerProps(props, typ) {
  console.log('green createToken params 0 : ', props); 
  for (let x in props) {
    switch(typ) {
      case 'email':
        return props[x].email
        break;
      case 'firstName':
        return props[x].firstName
        break;
      case 'lastName':
        return props[x].lastName
        break;
      case 'companyID':
        return props[x].companyID
        break;
      case 'userID':
        return props[x].userID
        break;
      default:
        // code block
    }
  }
}

loadTokenA = (params, data) => {
  console.log('loadtokenA 1 : ', this.props)
 // if (this.props.adminAcc) {
   const promise = this.loadTokenB(params, data)
   promise.then(this.onFulfilled, this.onRejected)
 // } 
}

loadTokenB = (params, data) => {
  const { createToken } = this.props
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.log('this.props.adminAcc 4 : ', this.props.adminAcc)    
      if (this.state.success) {
        let data = {
          email: this.getOwnerProps(this.props.adminAcc, 'email'),
          firstName: this.getOwnerProps(this.props.adminAcc, 'firstName'),
          lastName: this.getOwnerProps(this.props.adminAcc, 'lastName'),
          companyID: this.getOwnerProps(this.props.adminAcc, 'companyID'),
          userID: this.getOwnerProps(this.props.adminAcc, 'userID'),
    
          personID: this.props.userdata.userID, //token sent to
          personName: this.props.userdata.firstName,
          personLastName: this.props.userdata.lastName,
          personEmail: this.props.userdata.email,
          tokenType: params.tokenType,
         // amount: this.props.amount
        } 
        //console.log('green createToken params 1 : ', this.props);  
        console.log('green createToken params 2 : ', data);  
         if (data.email) {
          createToken(data);
          console.log('in createToken 1')
        } 
         else
         {
          console.log('in createToken 2 - no data')
         }
      } else {
        reject('Failed to the user list')
      }
    }, 10000)
  })
}

  handleSubmitUser = async usersData => {
    const { getAdmin, userdata } = this.props
     let data = {companyID: userdata.companyID}
    await getAdmin(data);

    let params={
      tokenType: usersData.tokenType
    }
    console.log('loadTokenA 0 ', this.props)
    this.loadTokenA(params, data)

        this.handleCloseDialog()
  };

  getPermission = () => {
    const { convertToken, onething, simpleValAction2, getMyToken, mytoken, tokenRequested } = this.props
    var tokentext
    var tok;

    console.log('inside askpermission 2 ', tokenRequested)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('inside askpermission 3', tokenRequested)
        console.log('green token reducer 0 ', this.props.mytoken)
        if (this.state.success) {
          console.log('inside askpermission 4', this.props.onething)
         // if (this.state.calfmember === true && this.props.userdata.from !== 'StrategyX') { // FIRST TIME GOING THROUGH
         // && this.props.userdata.from !=='StrategyX'
             if (this.props.mytoken && tokenRequested === 'yes' && this.props.onething === true ) { // FIRST TIME GOING THROUGH means user is on calf database
            //token requested and payment already made
            //CALF PRODUCTS MODIFY HERE
            console.log('inside askpermission 5')
            if (this.props.mytoken.tokenType === 'app') { tokentext = 'ThinkLead app' }
            if (this.props.mytoken.tokenType === 'ex1') { tokentext = 'Executive Club 1' }
            if (this.props.mytoken.tokenType === 'ex2') { tokentext = 'Executive Club 2' }

            tok = this.props.mytoken.token

            const answerToken = prompt(
              'Please provide a Token to join the Executive Club.'
              + ' You have purchased the ' + tokentext + '. ' + 
              'Here is your token: ' + tok + '', tok
            )

            if (answerToken === '' || answerToken === null || answerToken === 'no') {
              console.log('green token reducer 6')
            } else {
              console.log('tokenRequested 3 : ', this.props.tokenRequested );
              this.sendGetRequest(this.props.calfemail, this.props, '3') //set execmember=true & open EC
              let val = {
                token: answerToken,
                userID: this.props.userdata.userID
              }
              convertToken(val)  // CONVERT TOKEN TO CREDIT
            }
          }
          else {

            
          }
        } 
        else {
          reject('Failed to the user list')
        }
      }, 6000)
    })
  }

  findTokenA = () => {
    console.log('greentokenusers 6 : ', this.props.tokenRequested);
    const promise = this.findTokenB()
    promise.then(this.onFulfilled, this.onRejected)
  }
  
  findTokenB = () => {
    const { tokenRequested } = this.props
   
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.state.success) {
          console.log('greentokenusers 7 : ', this.props.tokenRequested);
        
          if (tokenRequested==='no' || tokenRequested===undefined) {
            this.setState({openDialog: true})
           }
        } else {
          reject('Failed to the user list')
        }
      }, 6000)
    })
  }

 async componentDidMount() {
     const { getMyToken, mytoken, getAdmin, checkToken, tokenRequested } = this.props

    const dd = {
      type: CHECK_TOKEN,
      userID: this.props.userdata.userID,
      orderChild: 'tpersonID'
    }
    await checkToken(dd); //check if token has been requested already
   
    if (this.props.userdata.execmember) {
      //
    }
    else{
        this.findTokenA()
    }
  
    const miTOken = {
      tpersonID: this.props.userdata.userID
    }
    await getMyToken(miTOken); //get token details

    //this.loadTokenA()
    console.log('tokenRequested 1 : ', this.props.tokenRequested );

    await this.sendGetRequest(this.props.calfemail, this.props, '2') //set onething = true

    console.log('inside askpermission 0 ', tokenRequested)

    if (this.props.userdata.execmember) { // NORMAL EC member execmember = true
      console.log('tokenRequested 2 : ', this.props.tokenRequested );
      this.sendGetRequest(this.props.calfemail, this.props, '1') //open exec page
    } else // NOT MARKED AS EC MEMBER YET...execmember = false
    {
      // this.sendGetRequest(this.props.calfemail, this.props, '2') //set onething = true
      console.log('inside askpermission 11 ', tokenRequested)
      this.askPermission()

    }

  }

  handleOpen = url => {
    // console.log('handleopen: ', url);
    window.open(url, '_blank')
  };

  async sendGetRequest(cemail, props, status) {
    const { simpleValAction2, checkToken } = this.props
    console.log('greentokenreducer s1  : ', status);

    var code
    const data = {
      username: 'ZGYrTGlJc3VoZTZhbDZacmZrSHNDbW5iOTFTaXJha1pnbHY5Qkt2bDJWMD0=',
      password: 'AQ2GD&*Q'
    }
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: 'https://mp.exec-club.com/api/auth/session-create'
    }

   await axios(options)
      .then(function (response) {
        const data = {
          email_id: cemail
        }
        const xoptions = {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Token': response.data.token
          },
          data: data,
          url: 'https://mp.exec-club.com/api/validate-user'
        }
       axios(xoptions)
          .then(res => {
            console.log('greentokenreducer s2 :', status);
            code = res.data.login_url
            if (code && code !== undefined) {
              // ///////////////////////////////reached here means EXECUTIVE CLUB member

              if (status === '2') {
                console.log('greentokenreducer s3 :', status);
                let body = {
                  type: SIMPLE_VAL2,
                  surveyid: true,
                  go: true
                }
                 simpleValAction2(body)
              }

              if ( status === '3' || status === '1') {
                console.log('green token reducer d :', status);
                let params = {
                  type: SHOW_CALF,
                  code: code,
                  uid: props.userdata.uid,
                  userID: props.userdata.userID,
                  companyID: props.userdata.companyID,
                  userType: props.userdata.userType
                }
                props.showCalf(params)
                //if (status === '1') {
                window.open(res.data.login_url, '_blank')
                //}
              }
              console.log('valid calf member')
            } else {
              console.log('green params 5', cemail)
            }
          })
          .catch(error => {
            console.log('green params 6: ', error)
            return 2
          })
          .catch(function (error) {
            console.log('green params 7: ', error)
            return 3
          })
      })
      .catch(function (error) {
        console.log('green params 8: ', error)
        return 3
      })
  }

  render() {
    const { calf } = this.props
     console.log('green createToken usersData A: ', this.props.adminAcc);
    if (this.props.userdata.from === 'StrategyX' && this.props.userdata.execmember === false) {
      // if (this.props.userdata.from==='StrategyX') {
      ////STRATEGYX USERS
      console.log('inside StrategyX member')
      return (
        <React.Fragment>
          <div style={{
            display: 'block',
            // marginLeft: isMobile ? "10px" : "0px",
            backgroundColor: '#FFFBE6',
            color: '#695800',
            padding: '20px',
            width: isMobile ? '100%' : '80%',
            size: '16px'
          }}>
            <h2>Dear Leader</h2>
            <p>When you join Executive Club you will open up many more opportunities to be rewarded with real-time points that appear next to your name on the Leaderboard, in your chosen sector, which affects your place in the rankings. The points also display next to your picture when you LEAD (share messages inside the Global Forum) with your skills, expertise, knowledge, experience and wisdom.</p>
            <p>Joining Executive Club makes it possible for ThinkLead to reward you with real-time points as you complete courses and bulk points upon completion of full programs, with real-time certificates. Currently you can use your Development Plan to develop yourself, so that you LEAD more effectively on ThinkLead or anywhere in your community. However, when you become an Executive Club member you are rewarded with real-time points for all activities inside your Development Plan. </p>
            <p>Have a look at all <b>these powerful leadership and personal development courses</b> inside Executive Club, for you and those you lead.</p>


            <a target='main' href='https://thinkleadecosystem.com' >
              <p align='center'>
                <img src={'https://thinkleadecosystem.com/wp-content/uploads/2022/08/1-1024x1024.png'} style={{ width: '80%' }} />
              </p></a>
            <a target='main' href='https://thinkleadecosystem.com' >
              <p align='center'>
                <img align='center' src={'https://thinkleadecosystem.com/wp-content/uploads/2022/08/2-1024x1024.png'} style={{ width: '80%' }} />
              </p></a>

            <p>As you browse, imagine the following: Inside the Ecosystem those who work with and for you can assess your leadership real-time by clicking on
              your avatar on the leaderboard, offering you <b>real-time</b> (continuous), anonymous <b>feedback</b>, on the four universal leadership levels of:</p>
            <ol>
              <li>leading self,</li>
              <li>leading others,</li>
              <li>leading organisation (non-people stuff) and</li>
              <li>leading society (beyond work).</li>
            </ol>
            <p>And then, based on this feedback, you alternate between your <b>Personal Development Plan</b> - where the feedback details come in -
              and the Learning Platform inside Executive Club with its growing library of powerful leadership and personal development courses.
              Note you can see your detailed feedback only after signing up for Executive Club.</p>


            <p>We are already at a point where irrespective of what feedback you receive, you will probably be able to initiate any number of courses to
              <b>improve your real-time Feedback Mean Score</b> and authentically grow real-time as a leader.</p>
            <p>The certificates you receive after completion - also real-time - become less important than your <b>genuine personal growth and actual performance</b>
              in your relevant environment.</p>
            <p>Add to that a <b>real-time points system</b> not only for when<b> you LEAD</b> (sharing messages inside the Global Forum) and through course participation,
              but the recording - inside your development plan - of learnings from any activities like coaching, mentoring, reading articles and books,
              listening to podcasts, watching videos, attending webcasts and more…</p>

            <p><div align='center'>
              <a target='main' href='https://thinkleadecosystem.com/levels-of-leadership-fitness/' >
                <img src={img1} style={{ width: '80%' }} />
              </a>
            </div>
            </p>

            <p>We are all about <b>“real-time” leadership</b> matched with <b>“real-time” improvement…</b> watch this short video </p>
            <p align='center'>
              {isMobile ?
                <GenTutorial
                  url={'https://www.youtube.com/watch?v=XcWwt0q5U7Y'}
                />
                :
                <ReactPlayer
                  url='https://www.youtube.com/watch?v=XcWwt0q5U7Y'
                />}
            </p>
          </div>
        </React.Fragment>

      )
    }
    else {

      return (
        <React.Fragment>
          <div style={{
            display: 'block',
            // marginLeft: isMobile ? "10px" : "0px",
            backgroundColor: '#FFFBE6',
            color: '#695800',
            padding: '20px',
            width: isMobile ? '100%' : '80%',
            size: '16px'
          }}>
            {(calf) ?
            <div>
              <p align='center'><a target='main' href={calf} >
                <button
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >Executive Club Members
                </button></a></p>
                <p align='center'>
                <button
                  onClick={() => this.setState({openDialog: true})}
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >Buy Products
                </button></p>
                </div>
              :
            [ this.props.tokenRequested === 'yes' ?  <p align='center'> <a target='main' href='https://mp.exec-club.com/' >
               <button
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '20px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >Sign Up
                </button></a></p> : null]
            }

            <h2>Why Join the Executive Club?</h2>

            <blockquote> <p>Everyone demands that you show up as your Best Self - all the time - at work and at home? In this VUCA ever changing world it’s more challenging than ever to navigate one’s personal growth, relationships, career or business successfully. </p>

<p>ThinkLead will support you every step on this journey, whether you are a specialist or a leader of others, organisation or society. Our digital ecosystem with all its powerful services and functions - including a link to thousands of International certificates and diplomas in almost any sector in society - will empower you with all the confidence, skills and abilities needed in order to navigate your career and life from student to qualified specialist to supervisor or senior leader. </p>

<p>How will an employer and broader society know that you are “Best Self Fit” and continually ready for the next career move?</p>

<ol>
    <li>All participation earns you real-time CBS (Continuous Best Self) Points - the equivalent of CPD (Continuous Professional Development) Points</li>
    <li>Sufficient CBS Points secure your annual <q>Best Self (professional) status</q>, on your current level, and in any chosen sector and country – see further down points system</li>
    <li>Your progression on specific levels will be recorded and a certificate awarded for every level achieved - declaring you Best Self Fit</li>
    <li>All participation will be captured on a Global Leaderboard and inside a personal Portfolio of Evidence</li>
</ol>
 
<p>Consistently achieving one’s own Best Self in today’s world is and always will be a deeply personal journey. No sausage-factory approach has ever been nor will it ever be up to the task.</p>  

<p>So Join Executive Club and open up many more opportunities to be rewarded with real-time CBS Points that appear next to your name on the Leaderboard, in your chosen sector, which affects your annual Best Self (professional) status. It also affects your place in the rankings, should that be important to you. The points display next to your picture when you LEAD (share messages inside the Global Forum) with your skills, expertise, knowledge, experience and wisdom, for which you receive real-time CBS Points as well.</p>

<p>Joining Executive Club makes it possible for ThinkLead to reward you with real-time points as you complete courses and bulk points upon completion of full programs, with real-time certificates. Currently you can use your Development Plan to develop yourself, so that you LEAD more effectively on ThinkLead or anywhere in your community. However, when you become an Executive Club member you are rewarded with real-time points for all activities inside your Development Plan.</p>

<p>After joining Executive Club you will notice the endless certificates and diplomas available under your Personal Development space and quality programmes inside Executive Club itself. Choose any relevant courses and while completing it, remember to record learnings inside your Personal Development Plan, in order to capture it inside your Portfolio of Evidence and earn valuable CBS Points.</p>

<p>You can also purchase a licence for your own ThinkLead Academy and help us transform society through this incredible ecosystem. After doing so we will hold a personal conversation with you about how to manage your own Academy, including the opportunity of earning residual income.</p>

<p>Have a look at all these powerful leadership and personal development courses inside Executive Club, for you and those you lead.</p>
</blockquote>

            <a target='main' href='https://thinkleadecosystem.com' >
              <p align='center'>
                <img src={'https://thinkleadecosystem.com/wp-content/uploads/2022/08/1-1024x1024.png'} style={{ width: '80%' }} />
              </p></a>
            <a target='main' href='https://thinkleadecosystem.com' >
              <p align='center'>
                <img align='center' src={'https://thinkleadecosystem.com/wp-content/uploads/2022/08/2-1024x1024.png'} style={{ width: '80%' }} />
              </p></a>

            <p>As you browse, imagine the following: Inside the Ecosystem those who work with and for you can assess
              your leadership real-time by clicking on your avatar on the leaderboard, offering you <b>real-time </b>(continuous), anonymous <b>feedback</b>,
              on the four universal leadership levels of:</p>
            <ol>
              <li>leading self,</li>
              <li>leading others,</li>
              <li>leading organisation (non-people stuff) and</li>
              <li>leading society (beyond work).</li>
            </ol>
            <p>And then, based on this feedback, you alternate between your
              <b>Personal Development Plan</b> -
              where the feedback details come in - and the Learning Platform inside
              <b>Executive Club</b>
              with its growing library of powerful leadership and personal development courses.
              Note you can see your detailed feedback only after signing up for Executive Club.</p>

            {calf ?
              <p align='center'><a target='main' href={calf} >
                <button
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >Executive Club Members
                </button></a></p>
              :
              [ this.props.tokenRequested === 'yes' ?  <p align='center'> <a target='main' href='https://mp.exec-club.com/' >
              <button
                 style={{
                   backgroundColor: '#cdbc74',
                   border: 'none',
                   color: 'white;',
                   padding: '15px 32px',
                   textAlign: 'center',
                   textDecoration: ' none',
                   display: 'inline-block',
                   fontSize: '20px',
                   margin: '4px 2px',
                   cursor: 'pointer'
                 }}
               >Sign Up
               </button></a></p> : null]
            }

            <h2>Executive Club Members</h2>
            <p>We are already at a point where irrespective of what feedback you receive, you will probably be able to initiate any number of courses to <b>improve your real-time Feedback Mean Score</b> and authentically grow real-time as a leader.</p>
            <p>The certificates you receive after completion - also real-time - become less important than your <b>genuine personal growth and actual performance</b> in your relevant environment.</p>
            <p>Add to that a <b>real-time points system</b> not only for when <b>you LEAD</b>
              (sharing messages inside the Global Forum) and through course participation, but the recording
              - inside your development plan - of learnings from any activities like coaching, mentoring,
              reading articles and books, listening to podcasts, watching videos, attending webcasts and more…
            </p>

            <p><div align='center'>
              <a target='main' href='https://thinkleadecosystem.com/levels-of-leadership-fitness/' >
                <img src={img1} style={{ width: '80%' }} />
              </a>
            </div>
            </p>

            <p>We are all about <b>“real-time” leadership</b> matched with <b>“real-time” improvement…</b> watch this short video </p>
            <p align='center'>
              {isMobile ?
                <GenTutorial
                  url={'https://www.youtube.com/watch?v=XcWwt0q5U7Y'}
                />
                :
                <ReactPlayer
                  url='https://www.youtube.com/watch?v=XcWwt0q5U7Y'
                />}
            </p>
            {(calf) ?
              <p align='center'> <a target='main' href='https://thinkleadecosystem.com' >
                <button
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >EXECUTIVE CLUB COURSE LIBRARY
                </button></a></p>
              : null}

            {(calf) ?
              <p align='center'><a target='main' href={calf} >
                <button
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >Executive Club Members
                </button></a></p>
              :
              [ this.props.tokenRequested === 'yes' ?  <p align='center'> <a target='main' href='https://mp.exec-club.com/' >
              <button
                 style={{
                   backgroundColor: '#cdbc74',
                   border: 'none',
                   color: 'white;',
                   padding: '15px 32px',
                   textAlign: 'center',
                   textDecoration: ' none',
                   display: 'inline-block',
                   fontSize: '20px',
                   margin: '4px 2px',
                   cursor: 'pointer'
                 }}
               >Sign Up
               </button></a></p> : null]
            }

          </div>
          { console.log('greentokensDIalog : ', this.props) }
          { this.props.tokenRequested==="yes" ? null : 
          <AddMyTokensDialog
            open={this.state.openDialog}
            edit={this.state.edit}
            editedUser={this.state.editedUser}
            onSubmitUserClick={this.handleSubmitUser}
            updateOnSave={this.state.openUpdateDialog}
            onOpenAddUserDialog={this.handleCloseDialog}
            userdata={this.props.userdata}
            admin={this.props.adminAcc}
          />
          }
        </React.Fragment>

      )
    }
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    calf: state.calf.calf,
    onething: state.oneVal2.sVal2,
    mytoken: state.mainToken.mtoken,
    adminAcc: state.mainToken.oadmin,
    tokenRequested: state.hasToken.hasToken
    // onething: state.sVal
    // simplething: state.simpleVal.simplething
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showCalf: params => dispatch(showCalf(params)),
    getMyToken: params => dispatch(getMyToken(params)),
    convertToken: params => dispatch(convertToken(params)),
    createToken: params => dispatch(createToken(params)),
    simpleValAction2: params => dispatch(simpleValAction2(params)),
    getAdmin: params => dispatch(getAdmin(params)),
    checkToken: params => dispatch(checkToken(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gotocalf)
