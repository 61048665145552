import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	ListItemSecondaryAction,
	TextField,
	IconButton
} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { borders } from '@material-ui/system';
import SendIcon from '@material-ui/icons/SendRounded';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { GET_POINTS, CLEAR_POINTS, SHOW_TOAST } from "../../store/actions/types";
// Redux
import { connect } from 'react-redux';
import {
	createNewComment,
	createNewSubComment,
	deleteComment,
	deleteSubComment
} from '../../store/actions/commentActions';
import { addMorePoints } from '../../store/actions/freshCardActions'
import { getRealPoints } from '../../store/actions/realPointsActions'
import {
	isMobile,
	isBrowser
  } from 'react-device-detect'
import { showToast } from '../../store/actions/toastAction'
import firebase from './../../store/firebase';

const styles = {
	root: {
		listStyle: 'none !important',
		justifyContent: 'left',
		position: 'relative',
        display: 'flex'
	},
	nested: {
		paddingLeft: '0px',
		justifyContent: 'left',
		position: 'relative',
        display: 'flex'
	}
};

	function makeid(length) {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * 
	charactersLength));
		}
		return result;
	}

const AddComment = (props) => {
	const { commentText, onCommentTextChange, isSubComment, parentID, onSendComment, classes } = props;
	return (
		<ListItem
			style={isSubComment ? styles.root : styles.root}
			//className={isSubComment ? classNames(styles) : null}
			ContainerComponent="div"
		>
			<ListItemAvatar>
				<Avatar alt={props.userdata.firstName} src={props.userdata.photoURL} />
			</ListItemAvatar>
			<ListItemText>
				 <TextareaAutosize
					value={commentText}
					onChange={(e) => onCommentTextChange(e.target.value)}
					fullWidth
					placeholder="Leave a comment"
					style={{
					    borderTop: 'none', 
						borderLeft: 'none', 
						borderRight: 'none', 
						resize: 'none', 
						width: isMobile ? '180px' : '340px',
						minWidth: isMobile ? '140px' : '290px',
						maxWidth: isMobile ? '190px' : '360px',
						outline: 'none'
					}}
				/>	
			</ListItemText>
			<ListItemSecondaryAction
				style={{ 
					width: isMobile ? '50px' : '0px', 
					minWidth: isMobile ? '30px' : '0px',
					maxWidth: isMobile ? '60px' : '0px',
					}}
			>
				<IconButton onClick={onSendComment} aria-label="SendComment"
				>
					<SendIcon />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
};
class AddCommentField extends Component {
	constructor(props) {
		super(props)
	  }

	state = {
		comment: ''
	};

	componentDidMount() {
		const { userdata, post, rrpoints } = this.props;
		// console.log('green getReal Points 1 : ', this.props.post.uid)
		// if (this.props.post.uid) {
		//   var pdata = {
		//   type: "GET_POINTS",
		//   uid: this.props.post.uid
		// }
		// this.props.getRealPoints(pdata);
		// }

	  }

	handleCommentChange = (value) => {
		this.setState((prevState) => ({
			comment: value
		}));
	};

	pointsPass = (dbPoints, realPoints) => {
		console.log('poixxxxxs 1 ', dbPoints);
		console.log('poixxxxxs 2 ', realPoints);
	
		var pass = false;
		if (dbPoints < realPoints) {
		  if (parseInt(realPoints) - parseInt(dbPoints) < 3) { 
			pass = true;
		  }
		} else
		{
		  if (parseInt(dbPoints) - parseInt(realPoints) < 3) { //too big - CANCEL
			pass = true;
		  } 
		}
		console.log('poixxxxxs 4 ', pass);
		return pass;
	  }

	handleSendComment = async (e) => {
		const { userdata, postType, post, isSubComment, parentID, showLeadb } = this.props;
		let commentParams = {
			postType: postType,
			comment: this.state.comment
		};

		console.log('green getReal Points 1 : ', this.props.post.uid)

		//GET REAL POINTS DIRECTLY FROM DATABASE
			let pdata, ydata = 0;
			await firebase.database
			.ref('user/' + post.uid)
			.once('value', function (xdata) {
				if (xdata.val().points) {
				pdata = xdata.val().points
				ydata = xdata.val().pointsy
				console.log('GET-REAL-POINTS 1: ', pdata )
				//return pdata
				}
				console.log('GET-REAL-POINTS 2: ', xdata.val() )
			  }
			
			)
		if (!isSubComment) {
			console.log('purple addMorePoints params props : ', post)
			console.log('purple addMorePoints params userdata : ', userdata)
			console.log('purple addMorePoints params commentParams : ', commentParams)

			this.props.createNewComment(post, userdata, commentParams);
			//ADD POINTS TO LEAD CREATOR
			let data = {
				uid: this.props.post.uid,
				userID: this.props.post.journalUserID,
				pointsCurrent: pdata,
				ypointsCurrent: ydata, //this.props.post.pointsy,
				leadPoints: this.props.post.leadPoints ? this.props.post.leadPoints : 0,
				pointsAdded: 2,
				trailID: makeid(10),
				companyName: this.props.post.companyName,
				firstName: this.props.post.journalUserName, // this.props.post.firstName,
				lastName: "empty", // this.props.post.lastName,
				title: this.state.comment,
				actionType: "Ilead", //this.state.postInternalGlobal + ' added',
				actionDesc: "LEAD comment", //this.state.postInternalGlobal,
				currentUser: userdata.userID,
				leadID: this.props.post.dailyThoughtID ? this.props.post.dailyThoughtID : ""
			  }
		   if (userdata.userID !== this.props.post.journalUserID && data.pointsCurrent) { //not the same person (LEAD author cant get points by commenting on own LEAD)
		    	console.log('inside showLead data 1 ', data)
		    	//this.props.addMorePoints(data)
		  }
		  console.log('inside showLead subcomment 1 ', showLeadb)
		  if (this.props.showLeadb) {
			//console.log('inside showLead function 1 ', data)
			showLeadb(this.props.post, data.pointsAdded);
		  }		

		} else {
			commentParams.parentID = parentID;
			this.props.createNewSubComment(post, userdata, commentParams);
			//ADD POINTS TO LEAD CREATOR
			let data = {
				uid: this.props.post.uid,
				userID: this.props.post.journalUserID,
				pointsCurrent: pdata,
				ypointsCurrent: ydata, //this.props.post.pointsy,
				leadPoints: this.props.post.leadPoints ? this.props.post.leadPoints : 0,
				pointsAdded: 2,
				trailID: makeid(10),
				companyName: this.props.post.companyName,
				firstName: this.props.post.journalUserName, // this.props.post.firstName,
				lastName: "empty", // this.props.post.lastName,
				title: this.state.comment,
				actionType: "Ilead", //this.state.postInternalGlobal + ' added',
				actionDesc: "LEAD comment", //this.state.postInternalGlobal,
				currentUser: userdata.userID,
				leadID: this.props.post.dailyThoughtID ? this.props.post.dailyThoughtID : ""
				}

				if (userdata.userID !== this.props.post.journalUserID && data.pointsCurrent) { //not the same person (LEAD author cant get points by commenting on own LEAD)
					console.log('inside showLead data 2 ', data)
					//this.props.addMorePoints(data)
					console.log('inside showLead subcomment 2 ', showLeadb)
				}
				if (this.props.showLeadb) {
					console.log('inside showLead function 1 ', data)
					showLeadb(this.props.post, data.pointsAdded);
				  }			
		}
		this.setState((prevState) => ({
			comment: ''
		}));
     //};
  }

	render() {
		const { comment } = this.state;
		const { isSubComment, parentID, showLeadb } = this.props;
		//console.log('addcommentfield props: ', this.props);
		
		return (
			<AddComment
				onSendComment={this.handleSendComment}
				onCommentTextChange={this.handleCommentChange}
				showLeadb={showLeadb? showLeadb: ''}
				commentText={comment}
				parentID={parentID}
				{...this.props}
			/>
		);
	}
 
}
//AddCommentField.propTypes = {};
AddCommentField.propTypes = {
	classes: PropTypes.object.isRequired,
	fullScreen: PropTypes.bool.isRequired
  }
// const mapDispatchToProps = dispatch => {
// 	return {
// 	  addMorePoints: params => dispatch(addMorePoints(params)),
// 	  createNewComment: params => dispatch(createNewComment(params)),
// 	  createNewSubComment: params => dispatch(createNewSubComment(params)),
// 	  deleteComment: params => dispatch(deleteComment(params)),
// 	  deleteSubComment: params => dispatch(deleteSubComment(params)),
// 	}
//   }
const mapStateToProps = (state) => {
	return {
		comments: state.comments,
		userdata: state.userDialog.userDetails[0],
		rrpoints: state.realpoints.realpoints
	};
};
// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps,
//   )(AddCommentField)

export default connect(mapStateToProps, { createNewComment, createNewSubComment, addMorePoints, getRealPoints, showToast })(AddCommentField);
