import { SURVEY_TESTS_LIST } from "../actions/types";

const initState = {
  type: SURVEY_TESTS_LIST,
  tests_list: []
};

const testsDisplayReducer = (state = initState, action) => {
  switch (action.type) {
    case SURVEY_TESTS_LIST:
      console.log('action.testsDisplayReducer : ', action.tests_list)
      return {
        type: SURVEY_TESTS_LIST,
        survey_tests_list: action.tests_list
      };

    default:
      return state;
  }
};

export default testsDisplayReducer;