import { TEST_HISTORY } from "./types";
//import firebase from "../firebase";

export const testHistoryActions = params => {
  if (params.type === TEST_HISTORY) {
    console.log('GREEN testHistoryActions: ', params); 
    return (dispatch, getState) => {

    if (params) {     
          dispatch({
            type: TEST_HISTORY,
            q: params.q,
            a1: params.a1,
            a2: params.a2,
            a3: params.a3,
            a4: params.a4,
            a5: params.a5
          });
    
        } else {
          dispatch({
            type: TEST_HISTORY,
            q: "",
            a1: "",
            a2: "",
            a3: "",
            a4: "",
            a5: ""
          });
        }
     // });
   } 
 }
};