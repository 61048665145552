import { SIMPLE_VALUES } from "../actions/types";

const initState = {
  type: SIMPLE_VALUES,
  simplething: []
};

const simpleReducer = (state = initState, action) => {
 // console.log('capman action.update_Survey: ', action.update_Survey);
  switch (action.type) {
    case SIMPLE_VALUES:
      return {
        type: SIMPLE_VALUES,
        simplething: action.simple_action
      };

    default:
      return state;
  }
};

export default simpleReducer;
