import { COLLECT_ANSWER } from "../actions/types";

const initState = {
  type: COLLECT_ANSWER,
  surveyDone: '',
  sgroupid: ''
};

const surveyAnswerReducer = (state = initState, action) => {
  console.log('inside answerReducer');
  switch (action.type) {
    case COLLECT_ANSWER:
      return {
        type: COLLECT_ANSWER,
        surveyDone: action.surveyDone,
        sgroupid: action.sgroupid
      };

    default:
      return state;
  }
};

export default surveyAnswerReducer;