//import React,{Component} from 'react';
import React,{ createRef } from 'react';
import ReactDOM from 'react-dom'
//import React, { Component } from "react";
//import ScrollUpButton from "react-scroll-up-button"; 
// import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, Row, Col } from 'react-grid-system';
import { showToast } from "../../store/actions/toastAction";
import { createSurvey } from "../../store/actions/SurveyActions";
import { GREY, RED, LIGHTBLUE, LIGHTGREY, MAROONHOVER } from "../../utils/color_utils";
import { updateSurvey } from "../../store/actions/surveyUpdate";
import TestIcon from '@material-ui/icons/QuestionAnswer';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'supercons';
//import {Avatar} from '@material-ui/core'
import Avatar from '@mui/material/Avatar';
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { connect } from 'react-redux'
import {
  getUserProfile
} from '../../store/actions/companyAdminActions'
import {
  addOpinions, addSOpinion, getOpinionsTrail, FindSlave, getOpinions, getOpinionsOnSubmit
} from '../../store/actions/opinionActions'
import {
  GET_USER_PROFILE, GET_O, SHOW_TOAST, GET_SLAVE_OPINION, GET_OPINIONID, GET_O_S
} from "../../store/actions/types";
import firebase from '../../store/firebase'
import { _toArray, _toArrayUsers, _toArrayUsersx, _toArrayCalendar, _toArrayGroups, _toArrayUsersS } from '../../store/actions/commonActions'
import SendIcon from '@material-ui/icons/Send'
import { withStyles } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { addMorePoints } from '../../store/actions/freshCardActions'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import FollowButton from '../../components/Buttons/Follow2'

//src/components/Buttons/Follow2.js
//import { scroller } from "react-scroll";
//import { Route } from 'react-router-dom'++++++++++++++++++++++++++++++++++++++
//import { ScrollTo } from "react-scroll-to";

const table = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #CAC9C8",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 7
}
const tableh = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 10,
  borderRadius: 15
}
const tableM = {
  color: "#515A5A",
  fontSize: 18,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 15
}

const content = {
  margin: "500px",
  height: "800px",
  width: "2000px"
}
const myDIV = {
  height: "250px",
  width: "250px",
  overflow: "auto",
  background: "coral"
}

const buttonStyle = {
  backgroundColor: RED,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const hoverbuttonStyle = {
  backgroundColor: MAROONHOVER,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const styles = {
  mark: {
    width: 250,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  mlist: {
    width: 250,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  survs: {
    width: 1500,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  row2: {
    //     backgroundColor: 'green',
  },
  col1: {
    backgroundColor: 'yellow',
  },
  col2: {
    backgroundColor: 'brown',
  },
  col: {
    padding: '1rem',
    backgroundColor: '#33b5e5',
    border: '2px solid #fff',
    color: '#fff',
    textAlign: 'center'
  },
  rightIcon: {
    paddingRight: '8px'
  }
};

const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: '#fff',
  cursor: 'pointer'
}

const topB = {
  position: 'fixed',
  bottom: '40px',
  right: '25px',
  zindex: '20',
  visibility: 'hidden',
  position: 'relative'
}

class ViewUser extends React.Component {
  constructor(props) {
    super(props);
    this.scollToRef = createRef();
  }

  state = {
    Open: false,
    userDetails: {},
    firstName: '',
    lastName: '',
    followers: 0,
    following: 0,
    companyName: '',
    dailythoughts: 0,
    articles: 0,
    videos: 0,
    podccasts: 0,
    biography: '',
    isLoading: 1,
    maxLengthThought: 0,
    showButton: true,
    tac: 0,
    slave: 0,
    simple: 0,
    simpleopinion: "",
    self: 0,
    selfopinion: "",
    others: 0,
    othersopinion: "",
    organisation: 0,
    orgopinion: "",
    society: 0,
    sosopinion: "",
    stoggleTac: false,
    stoggleSlave: false,
    stoggleSimple: false,
    stoggleSelf: false,
    stoggleOthers: false,
    stoggleOrg: false,
    stoggleSos: false,
    is_visible: false,
    hiddenVal: "",
    state_opinionID: "",
    opinionID: "",
    imgURLx: "",
    simpleopinion: ""
  };

  componentDidMount() {
    let { leaderboardData, feedback, opinions, sopinions } = this.props
    const { leaderID, slaveID, findSlave } = this.props;

    if (this.props.slaveID===this.props.leaderID) {
      this.setState({ showButton: false })
    }
    console.log('handleChecked 1 ', this.state.stoggleTac)


    if (leaderboardData) {
      this.getUserInfo();
    }
  }

    focusTextInput = () => {
      // Focus the text input using the raw DOM API
    //window && window.scroll(0,0);
     // window && window.scrollTo(0,0);
     // window && Window.scrollByLines(0,0)
     // window && Window.scrollByPages(0,0)
      //Element.scrollIntoView(0,0)

      //this.props.history.push('/textInput')

      console.log('inside focusTextInput 1')
      React.findDOMNode(this.refs.textInput).click();

      if (this.textInput) {
        this.textInput.click();
        console.log('inside focusTextInput 2')
       }
     };

  getFeedback = () => {
    return new Promise((resolve, reject) => {

      setTimeout(() => {
         if (this.props.feedback) {
           console.log('green populateState 2 : ', this.props.feedback)
          this.populateState(); 
        } else {
          reject('Failed to the user list');
        }
      }, 500);     
    });
  }

  populateState = () => {
    const { feedback } = this.props;
    
    if (feedback && feedback[0]) {
      Object.keys(feedback[0]).map(id => {
        console.log('inside populateState function 2 : ', feedback[0].selfopinion);
        //var feedbacksimple = feedback[0].simple;
        var feedbackself = feedback[0].self;
        var feedbackselfopinion = feedback[0].selfopinion;
        var feedbackothers = feedback[0].others;
        var feedbackothersopinion = feedback[0].othersopinion;
        var feedbackorganisation = feedback[0].organisation;
        var feedbackorgopinion = feedback[0].orgopinion;
        var feedbacksosopinion = feedback[0].sosopinion;
        var feedbacksociety = feedback[0].society;

      this.setState({
        self: feedbackself,
        selfopinion: feedbackselfopinion,
        others: feedbackothers,
        othersopinion: feedbackothersopinion,
        organisation: feedbackorganisation,
        orgopinion: feedbackorgopinion,
        sosopinion: feedbacksosopinion,
        society: feedbacksociety
      })
     }
    )}
    }

 shouldComponentUpdate() {
     return true;
 }

  xxshouldComponentUpdate(prevProps, prevState) {
    console.log('componentDidMount.feedback 3 : ', this.props.feedback)
    console.log('componentDidMount.feedback prevProps: ', prevProps.feedback); 
    console.log('componentDidMount.feedback selfopinion: ', this.state.selfopinion); 
   }

   static getDerivedStateFromProps(nextProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    return {
      feedback: nextProps.feedback,
      // ... other derived state properties
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('componentDidMount.feedback 3 : ', this.props.feedback)
    console.log('green componentDidUpdate prevProps: ', prevProps.feedback); // Previous properties of the component.
    console.log('green componentDidUpdate prevState: ', prevState.feedback) // Previous state of the component.
    //console.log('green componentDidUpdate snapshot: ', snapshot)  // Current rendered content
  }

  // scrollToSection = () => {
  //   console.log('green scrolltosection')
  //   scroller.scrollTo("topspot", {
  //     duration: 800,
  //     delay: 0,
  //     smooth: "easeInOutQuart",
  //   });
  // };

  setFocusName() {
    //this.evalu.focus();
    //document.getElementById("topspot").focus();
    //window && window.scroll(0,0);
    //window && window.scrollTo(0,0);
    //window && Window.scrollByLines(0,0)
    //window && Window.scrollByPages(0,0)
    //Element.scrollIntoView(0,0)

    var Element = document.getElementById("topspot");
    Element.focus();
    //Element.scrollIntoView(0,0)

  
    //element.scrollIntoView(false);
    //element.scrollIntoView({block: "end"});
    //element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

    // element.scrollTo({
    //   top: 100,
    //   left: 100,
    //   behavior: 'smooth'
    // });
  }
  
  focusOn() {
    setTimeout(() => {
      this.setFocusName()
    }, 1000)
  }

  getUserInfo() {
    let { userInfo, leaderboardData } = this.props
    console.log('green getUserInfo : ', leaderboardData)
    
    var userInf;

    var userDat;
    var firstName = '';
    var lastName = '';
    var followers = 0;
    var following = 0;
    var companyName = '';
    var dailythoughts = 0;
    var articles = 0;
    var videos = 0;
    var podcasts = 0;
    var biography = '';

    let user = {};

    user = Object.assign({}, this.props.leaderboardData);
    let receivedData = {};
    console.log('red leaderboardMData 1 : ', Object.keys(user).length + ' --- ' + this.props.leaderID);
    delete user.undefined

    console.log('red leaderboardMData 2 : ', Object.keys(user).length + ' --- ' + this.props.leaderID);

    if (Object.keys(user).length > 0) {
      let sortedData = _toArrayUsersS(user, this.props.leaderID)

      console.log('red leaderboardMData 3: ', sortedData);

      sortedData.sort(function (a, b) {
        return b.points - a.points
      });

      receivedData = { ...sortedData }
      receivedData = Object.assign({}, sortedData)
      try {
        if (receivedData[0].unseencounts) 
        { console.log('red leaderboardMData 4: ', receivedData[0].unseencounts) }
      }
      catch (err) {
        console.log('red leaderboardMData 4: ', err)
      }
      try {
        if (receivedData[0]) { firstName = receivedData[0].firstName } else { firstName = 'undefined' }
        if (receivedData[0]) { lastName = receivedData[0].lastName } else { lastName = 'undefined' }
        if (receivedData[0].analytics) { followers = receivedData[0].analytics.followers } else { followers = 0 }
        if (receivedData[0].analytics) { following = receivedData[0].analytics.following } else { following = 0 }
        companyName = receivedData[0].companyName
        if (receivedData[0].unseencounts) { dailythoughts = receivedData[0].unseencounts.dailythoughts } else { dailythoughts = 0 }
        if (receivedData[0].unseencounts) { articles = receivedData[0].unseencounts.articles } else { articles = 0 }
        if (receivedData[0].unseencounts) { videos = receivedData[0].unseencounts.videos } else { videos = 0 }
        if (receivedData[0].unseencounts) { podcasts = receivedData[0].unseencounts.podcasts } else { podcasts = 0 }
        biography = receivedData[0].biography
      }
      catch (err) {
        console.log('red leaderboardMData 5: ', err);

        firstName = 'undefined'
        lastName = 'undefined'
        followers = 0
        following = 0
        companyName = 'undefined'
        dailythoughts = 0
        articles = 0
        videos = 0
        podcasts = 0
        biography = 'undefined'
        console.log('err ViewUser : ', err)
      }

    }

    console.log('red leaderboardMData showButton : ', this.props.slaveID + ' --- ' + this.props.leaderID);

    this.setState({
      isLoading: 0,
      firstName: firstName,
      lastName: lastName,
      followers: followers,
      following: following,
      companyName: companyName,
      dailythoughts: dailythoughts,
      articles: articles,
      videos: videos,
      podcasts: podcasts,
      biography: biography,
      showButton: this.props.slaveID===this.props.leaderID ? false : true
    })
     
  }

  getViewerProfile(zuserID) {
    firebase.database
      .ref('user')
      .orderByChild('userID')
      .equalTo(zuserID)
      .on('value', function (data) {
        if (data.val()) {
          var user = data.val()
          let receivedData = {}
          delete user.undefined

          console.log('green getUserData 1: ', user.firstName)
          return user;
        } else {
          console.log('green getUserData 2: null')
          return 0;
        }

      })

  }

  getFollowers = (props) => {
    for (let x in props) {
      return props[x].analytics.followers
    }
  }
  getFollowing = (props) => {
    for (let x in props) {
      return props[x].analytics.following
    }
  }
  getfirstName = (props) => {
    for (let x in props) {
      return props[x].firstName
    }
  }

  //-----------------------------------------
  handleChangeA = () => {
    if (this.state.goodleader) {
      this.setState({ goodleader: false })
    }
    else {
      this.setState({ goodleader: true })
    }

  }

  handleChangeB = () => {
    if (this.state.goodlistener) {
      this.setState({ goodlistener: false })
    }
    else {
      this.setState({ goodlistener: true })
    }

  }
  
 xfindSlave = (leaderID, slaveID) => {
    console.log('blue slave data 2 ')
    var pass = ''; //no post yet
    var database = firebase.database;
     database
      .ref('opinionsTrail')
      .orderByChild('leaderID')
      .equalTo(leaderID)
      .on('value', function (data) {

        if (data.val()) {
          let odata = data.val()
          if (Object.keys(odata).length > 0) {
            let sortedData = _toArrayUsers(odata)
            Object.keys(sortedData).map((id, index) => {

              if (sortedData[id].leaderID === leaderID) {
                if (sortedData[id].slaveID === slaveID) { //user already made opinion on this leader
                 pass = sortedData[id].opinionID; //has posted before
                 //this.setState({opinionID: sortedData[id].opinionID})
                 //this.setState( (state,props) =>  ({opinionID: sortedData[id].opinionID}) );
                 return pass;
                  console.log('green submitPost leaderID : ', leaderID)
                  console.log('green submitPost slaveID : ', slaveID)
                  console.log('green submitPost opinionID 2 : ', sortedData[id].opinionID)

                }
              }
            })
          }
        }
      })
    return pass;
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  checkFields = () => {
    var protest = false;
    var bootskap = "Please complete the following fields: ";

    console.log('checkFields stoggleSlave: ', this.state.stoggleSlave)
    console.log('checkFields stoggleSelf: ', this.state.stoggleSelf)
    console.log('checkFields stoggleOthers: ', this.state.stoggleOthers)
    console.log('checkFields stoggleOrg: ', this.state.stoggleOrg)
    console.log('checkFields stoggleSos: ', this.state.stoggleSos)

    console.log('checkFields selfopinion: ', this.state.selfopinion)
    console.log('checkFields othersopinion: ', this.state.othersopinion)
    console.log('checkFields orgopinion: ', this.state.orgopinion)
    console.log('checkFields sosopinion: ', this.state.sosopinion)

    if (this.state.stoggleTac===false) {
     // console.log('checkFields tac: ', this.state.tac)
      protest = true;
      bootskap = bootskap + 'Participant Agreement, '
    }
    if (this.state.stoggleSlave===false) {
      protest = true;
      bootskap = bootskap + 'Participant Relation, '
    }
    if (this.state.stoggleSelf===false) {
      protest = true;
      bootskap = bootskap + 'Leading Self, '
    }
    if (this.state.stoggleOthers===false) {
      protest = true;
      bootskap = bootskap + 'Leading Others, '
    }
    if (this.state.stoggleOrg===false) {
      protest = true;
      bootskap = bootskap + 'Leading Organisation, '
    }
    if (this.state.stoggleSos===false) {
      protest = true;
      bootskap = bootskap + 'Leading Society, '
    }

   
    if (this.state.selfopinion==='') {
     protest = true;
     bootskap = bootskap + 'Leading Self Comment, '
    }
    if (this.state.othersopinion==='') {
      protest = true;
      bootskap = bootskap + 'Leading others Comment, '
     }
     if (this.state.orgopinion==='') {
      protest = true;
      bootskap = bootskap + 'Leading Organisation Comment, '
     }
     if (this.state.sosopinion==='') {
      protest = true;
      bootskap = bootskap + 'Leading Society Comment, '
     }
 // if (this.state.tac===2) { //user doesnt agree
   if (this.state.stoggleTac===false) { //user doesnt agree
    let params = {
      type: SHOW_TOAST,
      open: true,
      variant: 'warning',
      message: 'You need to agree to the declaration before submitting feedback',
      pos: "bottom"
    }

    this.props.showToast(params)
    return 3;
  }
  else  //user agrees
   {
    if (protest) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: bootskap,
        pos: "bottom"
      }

      this.props.showToast(params)
      return 3;
   }
  }
 }

 checkSFields = () => {
  var protest = false;
    var bootskap = "Please complete the following fields: ";

    if (this.state.stoggleTac===false) {
      // console.log('checkFields tac: ', this.state.tac)
       protest = true;
       bootskap = bootskap + 'Participant Agreement, '
     }
    if (this.state.stoggleSlave===false) {
      protest = true;
      bootskap = bootskap + 'Participant Relation, '
    }
    if (this.state.stoggleSimple===false) {
     // protest = true;
     // bootskap = bootskap + 'Immediate feedback score, '
    }
    if (this.state.simpleopinion==='') {
      protest = true;
      bootskap = bootskap + 'Immediate feedback comment, '
     }
   if (this.state.stoggleTac===false) { //user doesnt agree
    let params = {
      type: SHOW_TOAST,
      open: true,
      variant: 'warning',
      message: 'You need to agree to the declaration before submitting feedback',
      pos: "bottom"
    }

    this.props.showToast(params)
    return 3;
  }
  else  //user agrees
   {
    if (protest) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: bootskap,
        pos: "bottom"
      }

      this.props.showToast(params)
      return 3;
   }
  }
}
 getopinionID = () => {
  var params = {
    leaderID: this.props.leaderID,
    slaveID: this.props.slaveID
  }
  this.props.findSlave(params);
 }

 addSlaveOpinion = (opinionID) => {
  var addPoints = false;
  if (opinionID) {  
    //allready exists
    } else 
  { 
    opinionID = this.makeid(10)
    addPoints = true;
  }
  var params = {
    opinionID: opinionID,
    leaderID: this.props.leaderID,
    companyName: this.props.companyName,
    slaveID: this.props.slaveID,
    companyID: this.props.companyID,
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    selfopinion: this.state.selfopinion,
    slave: this.state.slave,
    self: this.state.self,
    othersopinion: this.state.othersopinion,
    simple: this.state.simple,
    simpleopinion: this.state.simpleopinion,
    others: this.state.others,
    organisation: this.state.organisation,
    orgopinion: this.state.orgopinion,
    society: this.state.society,
    sosopinion: this.state.sosopinion
  }
    this.props.addOpinions(params)

  if (addPoints === true && this.props.execmember) {
    let params = {
      uid: this.props.uid,
      userID: this.props.slaveID,
      pointsCurrent: this.props.points,
      ypointsCurrent: this.props.pointsy,
      pointsAdded: 10,
      trailID: this.makeid(10),
      companyName: this.props.companyName,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      title: 'Opinion on ' + this.props.leaderID,
      actionType: 'Submitted Opinion Leader Poll',
      actionDesc: 'Opinion Poll Completed'
    }
    console.log('blue green props 1 : ', this.props)
    this.props.addMorePoints(params)
  }
}


  putOpinionsB = (addPoints) => {
    const { leaderID, slaveID, getOpinionsOnSubmit, opinions } = this.props;
    var slave_count = 0;
    var self_count = 0;
    var simple_count = 0;
    var others_count = 0;
    var organisation_count = 0;
    var society_count = 0;
  
    var slave_all_count = 0;
    var simple_all_count = 0;
    var self_all_count = 0;
    var others_all_count = 0;
    var org_all_count = 0;
    var sos_all_count = 0;

    console.log('green putOpinions sopinions3 ', opinions)

   if (opinions) { Object.keys(opinions).map((opinionsIDd, indx) => {
    console.log('green putOpinions sopinions4 :', opinions)
    if (opinions[indx].slave) { 
      slave_all_count = slave_all_count + 1;
      slave_count = slave_count + opinions[indx].slave 
    }
    if (opinions[indx].self) { 
      self_all_count = self_all_count + 1;
      self_count = self_count + opinions[indx].self 
    }
    if (opinions[indx].simple) { 
      simple_all_count = simple_all_count + 1;
      simple_count = simple_count + opinions[indx].simple 
    }
    if (opinions[indx].others) { 
      others_all_count = others_all_count + 1;
      others_count = others_count + opinions[indx].others 
    }
    if (opinions[indx].organisation) { 
      org_all_count = org_all_count + 1;
      organisation_count = organisation_count + opinions[indx].organisation 
    }   
    if (opinions[indx].society) { 
      sos_all_count = sos_all_count + 1;
      society_count = society_count + opinions[indx].society 
    }   
  })}

   //////////////////////////////////////////////////////////////////
   var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
   var iself = self_all_count > 0 ? self_count / self_all_count : 0;
   var isimple = simple_all_count > 0 ? simple_count / simple_all_count : 0;
   var iothers = others_all_count > 0 ? others_count / others_all_count : 0;
   var iorganisation = org_all_count > 0 ? organisation_count / org_all_count : 0;
   var isociety = sos_all_count > 0 ?  society_count / sos_all_count : 0;

   //insertPollCount into user and users
   const updates = {}

   //var finalScore = (iself + iothers + iorganisation + isociety)/4
   var finalScore = (isimple + iself + iothers + iorganisation + isociety)/4
   console.log('green putOpinions scores final ', finalScore)

   console.log('scores final uid ', this.props.leaderUid)
   console.log('scores final userID ', this.props.leaderID)
    
   if(finalScore) {
    updates['/users/' + this.props.leaderID + '/feedback'] = finalScore.toFixed(2);
    updates['/user/' + this.props.leaderUid + '/feedback' ] = finalScore.toFixed(2);
   }
   // server: create group - send data to firebase
   firebase.database.ref().update(
     updates,
     function (error) {
       if (error) {
         console.log('success : ');
       } else {
        console.log('err : ', error);
       }
     })
     //////////////ADD POINTS OR NOT///////////////////////////////////////////////////////////
     if (addPoints === true && this.props.execmember) {
      let params = {
        uid: this.props.uid,
        userID: this.props.slaveID,
        pointsCurrent: this.props.points,
        ypointsCurrent: this.props.pointsy,
        pointsAdded: 10,
        trailID: this.makeid(10),
        companyName: this.props.companyName,
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        title: 'Opinion on ' + this.props.leaderID,
        actionType: 'Submitted Opinion Leader Poll',
        actionDesc: 'Opinion Poll Completed'
      }
      console.log('blue green props 1 : ', this.props)
      this.props.addMorePoints(params)
    }
     /////////////////////////////////////////////////////////////////////////
  }

  putOpinions = async() => {
    const { sopinions, getOpinionsOnSubmit } = this.props
    console.log('green putOpinions sopinions2 :', sopinions)

    var addPoints = false;

    var self_count = 0;
    var simple_count = 0;
    var others_count = 0;
    var organisation_count = 0;
    var society_count = 0;

    var self_all_count = 0;
    var others_all_count = 0;
    var org_all_count = 0;
    var sos_all_count = 0;

    var opinionID = '';
    var simple;
    var simpleopinion;
    //var imgURLX = '';
   
    if (sopinions) { Object.keys(sopinions).map((opinionsID, index) => {
      opinionID = sopinions[index].opinionID;
      simple = sopinions[index].simple;
      simpleopinion = sopinions[index].simpleopinion;
    })}
      ///////////////////////ADD/EDIT opinionsTrail collection///////////////////////////////////////////////
      //console.log('green putOpinions imgURLX :', imgURLX)
      console.log('green putOpinions opinionID :', opinionID)

      if (opinionID) {  
        //allready exists going to edit existing entry
        } else 
      { 
        opinionID = this.makeid(10) //going to make new entry
        addPoints = true;
      }
      var params = {
        opinionID: opinionID,
        leaderID: this.props.leaderID,
        companyName: this.props.companyName,
        slaveID: this.props.slaveID,
        companyID: this.props.companyID,
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        slave: this.state.slave,
        selfopinion: this.state.selfopinion,
        self: this.state.self,
        othersopinion: this.state.othersopinion,
        others: this.state.others,
        organisation: this.state.organisation,
        orgopinion: this.state.orgopinion,
        society: this.state.society,
        sosopinion: this.state.sosopinion,
        simple: simple,
        simpleopinion: simpleopinion
      }
      this.props.addOpinions(params) //ADD OR EDIT UNIQUE MASTER-SLAVE SURVEY
      //////////////////////////////////////////////////////////////////////
     // if (addPoints===false) {
        // getOpinionsOnSubmit({ //REDO sopinions
        //   leaderID: this.props.leaderID, 
        //   slaveID: this.props.slaveID
        // }); 
        await this.props.getOpinions({ //REDO sopinions
          leaderID: this.props.leaderID, 
        }); 
      //}

      //this.putOpinionsB(addPoints);
      const promise = this.getOpinions();
      promise.then(this.onFulfilledB, this.onRejectedB);
    }

    getOpinions = () => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.putOpinionsB();
        }, 3000);     
      });
    }
  
    onFulfilledB = (valIn) => {
      console.log('white users : ', 'ooooooooooooooooo');
    }
    onRejectedB = (error) => {
      console.log('white error : ', error);
    }

    putSOpinions = async() => {
      const { sopinions, getOpinionsOnSubmit } = this.props
      console.log('green putOpinions sopinions2 :', sopinions)
  
      var addPoints = false;
      var simple_all_count = 0;
      var opinionID = '';
     
      if (sopinions) { Object.keys(sopinions).map((opinionsID, index) => {
        opinionID = sopinions[index].opinionID;
      })}
        ///////////////////////ADD/EDIT opinionsTrail collection///////////////////////////////////////////////
        // console.log('green putOpinions opinionID :', opinionID)
  
        if (opinionID) {  
          //allready exists going to edit existing entry
          console.log('green putOpinions opinionID 1 :', opinionID)
          } else 
        { 
          console.log('green putOpinions opinionID 2 :', sopinions)
          opinionID = this.makeid(10) //going to make new entry
          addPoints = true;
        }
        var params = {
          opinionID: opinionID,
          leaderID: this.props.leaderID,
          companyName: this.props.companyName,
          slaveID: this.props.slaveID,
          companyID: this.props.companyID,
          firstName: this.props.firstName,
          lastName: this.props.lastName,
          slave: this.state.slave,
          simpleopinion: this.state.simpleopinion,
          simple: this.state.simple ? this.state.simple : 0
        }
        //this.props.addOpinion(params) 
        console.log('green putOpinions opinionID 3 :', params)
        this.props.addSOpinion(params) //ADD OR EDIT UNIQUE MASTER-SLAVE SURVEY
        //////////////////////////////////////////////////////////////////////
  
          await this.props.getOpinions({ //REDO sopinions
            leaderID: this.props.leaderID, 
          }); 
  
        const promise = this.getOpinions();
        promise.then(this.onFulfilledB, this.onRejectedB);
        this.setState({Open: false})
      }

   submitPost = async() => {
   const { leaderID, slaveID, getOpinionsOnSubmit } = this.props;

    if (this.checkFields()===3) {
      console.log('checkFields return')
      return
    }
    console.log('green putOpinions leaderID :', leaderID)
    console.log('green putOpinions slaveID :', slaveID)
    // const promise = this.getsOpinions();
    // promise.then(this.onFulfilled, this.onRejected);

   await getOpinionsOnSubmit({ 
        leaderID: leaderID, 
        slaveID: slaveID
      }); 
    this.setState({ showButton: false })

    console.log('checkFields not return')
    console.log('green putOpinions sopinions1 :', this.props.sopinions)

   //  this.putOpinions(); //compile new mean values for leaderboard from opinions in state
     const promise = this.getsOpinions();
     promise.then(this.onFulfilled, this.onRejected);
  }

  submitSPost = async() => {
    const { leaderID, slaveID, getOpinionsOnSubmit } = this.props;
 
     if (this.checkSFields()===3) {
       console.log('checkFields return')
       return
     }
     console.log('green putOpinions leaderID :', leaderID)
     console.log('green putOpinions slaveID :', slaveID)
     // const promise = this.getsOpinions();
     // promise.then(this.onFulfilled, this.onRejected);
 
    await getOpinionsOnSubmit({ 
         leaderID: leaderID, 
         slaveID: slaveID
       }); 
     this.setState({ showButton: false })
 
     console.log('checkFields not return')
     console.log('green putOpinions sopinions1 :', this.props.sopinions)
 
    //  this.putOpinions(); //compile new mean values for leaderboard from opinions in state
      const promise = this.getSsOpinions();
      promise.then(this.onFulfilled, this.onRejected);
   }

  getsOpinions = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.putOpinions();
      }, 3000);     
    });
  }

  getSsOpinions = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.putSOpinions();
      }, 3000);     
    });
  }

  onFulfilled = (valIn) => {
    console.log('white users : ', 'ooooooooooooooooo');
  }
  onRejected = (error) => {
    console.log('white error : ', error);
  }

  toggleSlave = (nr) => {
    const { slave } = this.state;
    this.setState({
      slave: nr,
      stoggleSlave: true
    })
  }

  toggleSelf = (nr) => {
    const { self } = this.state;
    this.setState({
      self: nr,
      stoggleSelf: true
    })
  }
  toggleSimple = (nr) => {
    const { simple } = this.state;
    this.setState({
      simple: nr,
      stoggleSimple: true
    })
  }
  toggleTac = (nr) => {
    const { tac } = this.state;
    this.setState({
      tac: nr,
      stoggleTac: nr===1 ? true : false
    })
  }

  // toggleTac = (nr) => {
  //   const { tac } = this.state;
  //   this.setState({
  //     tac: nr,
  //     stoggleTac: nr===1 ? true : false
  //   })
  // }

  handleChecked = () => {
    console.log('handleChecked 2', this.state.stoggleTac)
   // this.setState({stoggleTac: !this.state.stoggleTac});
   if (document.querySelectorAll('#declaration input[type=checkbox]:checked').length > 0)
   {
    this.setState({stoggleTac: true})
   }
   else
    {
      this.setState({stoggleTac: false})
    }

  }

  toggleOthers = (nr) => {
    const { others } = this.state;
    this.setState({
      others: nr,
      stoggleOthers: true
    })
  }
  
  toggleOrg = (nr) => {
    const { organisation } = this.state;
    this.setState({
      organisation: nr,
      stoggleOrg: true
    })
  }

  toggleSos = (nr) => {
    const { society } = this.state;
    this.setState({
      society: nr,
      stoggleSos: true
    })
  }
  
  // scrollIt = () => {
  //     this.scollToRef.current.scrollIntoView()
  // }

  render() {
    const { userInfo, getNewKeyx, opinions, feedback } = this.props;
    console.log('green blue viewUser : ', this.props)
    var selfComment;

    const handleClickOpenMain = () => {
      console.log('green handleClickOpen Main')
      this.getUserInfo()
      this.setState({ 
        Open: true
      });
    };
  
    const onClickz = (e) => {
      e.preventDefault();
      console.log("clicked");
    };

    const handleClickOpen = () => {
      console.log('green handleClickOpen ---')
      this.setState({ Open: true });
    };

    const handleClose = () => {
      this.setState({ Open: false });
    };
    const handleClosex = () => {
      console.log('handleClose is running');
      this.setState({ Open: false });
    };

    const getFollowers = (props) => {
      for (let x in props) {
        return props[x].analytics.followers
      }
    }
    const scrollIt = () => {
        this.scollToRef.current.scrollIntoView()
      }
      console.log('green avatar view : ', this.props.src)
       var imgURL = this.props.src;
      //  var imgURL2; 
      //  if (imgURL) { 
      //    imgURL2 = imgURL.replace(/\/?(\?|#|$)/, '/$1');
        console.log('green avatarx : ', imgURL)
      //  this.setState({imgURLx: imgURL2})
      //  };

      const toggleSidenav = () => {
        var css = (this.props.showHideSidenav === "hidden") ? "show" : "hidden";
        this.setState({"showHideSidenav":css});
      }

    return (
         <div>
          <div 
          style={{ 
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            align:'center',
            color: 'black', 
            textShadow: '3px 3px 3px gold',
            width: '100%',
            maxWidth: '100%'
            }}
          >
            <Avatar
              alt={this.props.alt}
              //alt="Remy Sharp"
              src={PHOTO_PROFILE(this.props.src)}
              //src={PHOTO_PROFILE(require("/static/images/remy.jpg"))} 
              //src={PHOTO_PROFILE('../images/default-user.png')}
              //src={PHOTO_PROFILE(this.props.srcx)}
              //src={PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/thinklead-b3299.appspot.com/o/testphotos%2Fimage_2021-03-03T08%3A39%3A23.736Z%20-%20bruce.jpeg?alt=media&token=f0f0d61d-bb3c-4cea-b03d-88facfa1290e')}
              id={this.props.id}
              onClick={handleClickOpenMain}
            />

          </div>

          <Dialog 
          open={this.state.Open} 
          // defaultPosition={0, 0}
          // position={firstPosition}
          onLoad={scrollIt}
          //onMouseOver={scrollIt}
          onClose={handleClose} 
          // aria-labelledby="form-dialog-title"
          // anchorOrigin={{
          //   vertical: 'top',
          //   horizontal: 'center'
          // }}
          // aria-describedby="scroll-dialog-description"

        //  style={{
        //     top: '-15px',
        //     width: '100%',
        //     maxWidth: '100%',
        //     height: '700px'
        //     }}  
          style={{
             top: '-15px' ,
             left: isMobile ? '-50px' : '0',
             width: isMobile ? '450px' : '100%',
             maxWidth: isMobile ? '450px' : '100%',
             height: '700px',
             }}  
          >
            <DialogContent
              style={{
                // top: '-15px',
                // left: isMobile ? '-10px' : '0px',
                // width: isMobile ? '360px' : '100%',
                // maxWidth: isMobile ? '400px' : '100%',
                // height: '700px'
                top: '-15px',
                left: isMobile ? '0px' : '0px',
                width: isMobile ? '410px' : '100%',
                maxWidth: isMobile ? '400px' : '100%',
                height: '700px'
                }}  
            >
              <div>
              <TableContainer
                    style={{
                      top: '-15px',
                      left: isMobile ? '-50px' : '0px',
                      width: isMobile ? '360px' : '100%',
                      maxWidth: isMobile ? '400px' : '100%',
                      height: '700px'
                      }}  
              >
                <Table aria-label='simple table'>
                  <TableBody>
                  <TableRow><TableCell colspan='4'>
                  {/* <div align='center'><a name='gototop' id='gototop'>aaaaa</a></div> */}

                  <div ref={this.scollToRef}></div>
                    </TableCell></TableRow>
                    <TableRow
                    style={{
                      width: isMobile ? '450px' : '100%',
                      maxWidth: isMobile ? '450px' : '100%'
                      }}
                    >
                      <TableCell align='center' colspan='1'> Followers: {this.state.followers}</TableCell>
                      <TableCell align='center' colspan='2' border='1'>
                      <div align='center'>
                          <Avatar
                            alt={this.state.firstName}
                            src={this.props.src}
                            id={this.props.userID}
                            onClick={handleClickOpen}
                          />
                          </div>

                  {this.props.journalUserID !== this.props.userIDlogged ? <div align='center'><FollowButton post={this.props.post} following={this.props.following} ></FollowButton></div> : null}       
                        
                      </TableCell>
                      <TableCell align='center' colspan='1'>Following: {this.state.following} </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center' colspan='4'>{this.state.firstName + ' ' + this.state.lastName}<br />{this.props.post.companyName}</TableCell>
                    </TableRow>
                
                    <TableRow bgColor='#000000' 
                    >
                      <TableCell>
                        <font color='#ffffff'><b>Messages: {this.state.dailythoughts}</b></font>
                      </TableCell>
                      <TableCell>
                        <font color='#ffffff'><b>Articles: {this.state.articles}</b></font>
                      </TableCell>
                      <TableCell>
                        <font color='#ffffff'><b>Videos: {this.state.videos}</b></font>
                      </TableCell>
                      <TableCell>
                        <font color='#ffffff'><b>Podcasts: {this.state.podcasts}</b></font>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colspan='4'>
                        Biography:
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan='4' style={{size:8}} >
                        {this.state.biography ? this.state.biography : 'No Biography yet'}
                      </TableCell>
                    </TableRow>
                    </TableBody>
                   </Table>

                   <Table aria-label='simple table'>
                  <TableBody>
                    {this.state.showButton ?
                    <div>
                    <TableRow>
                        <TableCell colspan='4' bgColor='#000000'>
                        <div align='center'>
                          <font color='#ffffff'><b>Leader Evaluation</b></font>
                          </div>
                        </TableCell>
                    </TableRow>  

                    <TableRow>
                <TableCell colspan='4'>
                  <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}>
                    <b>A Personal Declaration:</b><br/>
                    I offer anonymous real-time feedback because I am committed to the purpose of assisting leaders 
                    to become more aware of their leadership; 
                    to continuously raise their standards for a happier, conscious and more prosperous world. 
                    I do not offer real-time feedback with malicious intent. And as a leader / future leader 
                    I commit to invite others to give me real-time feedback as well.
                    I also recognise that all assessments are in some measure based on perception, yet when facts and 
                    perception combine, 
                    it offers a leader valuable insights.
                    </div>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colspan='4'>
                  {/* <div id="rtac">
                        <input type="radio" id="r1" name="tac" value="1" onClick={() => this.toggleTac(1)}/>&nbsp;&nbsp;I agree
                    <br/><input type="radio" id="r2" name="tac" value="2" onClick={() => this.toggleTac(2)}/>&nbsp;&nbsp;I don't agree
                   
                    <br/> <input type="checkbox" onChange={ this.handleChecked }/>&nbsp;&nbsp;I Agree
                  </div> */}
                  <div id="declaration" >
                  <input type="checkbox" onChange={ this.handleChecked }/>&nbsp;&nbsp;I Agree
                  </div>
                </TableCell>
                </TableRow>   

                    <TableRow>
                <TableCell colspan='4'>
                  <div><b>In what capacity are you offering this leadership feedback?:</b></div>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colspan='4'>
                  <div id="rslave">
                    <input type="radio" id="r1" name="slave" value="1" onClick={() => this.toggleSlave(1)}/>&nbsp;&nbsp;I report to the leader directly
                    <br/><input type="radio" id="r2" name="slave" value="2" onClick={() => this.toggleSlave(2)}/>&nbsp;&nbsp;The leader reports to me
                    <br/><input type="radio" id="r3" name="slave" value="3" onClick={() => this.toggleSlave(3)}/>&nbsp;&nbsp;I work with the leader as a peer, colleague
                    <br/><input type="radio" id="r4" name="slave" value="4" onClick={() => this.toggleSlave(4)}/>&nbsp;&nbsp;I am an employee/member of the organisation
                    <br/><input type="radio" id="r5" name="slave" value="5" onClick={() => this.toggleSlave(5)}/>&nbsp;&nbsp;I am merely a concerned citizen / distant shareholder
                    <br/><input type="radio" id="r6" name="slave" value="6" onClick={() => this.toggleSlave(6)}/>&nbsp;&nbsp;I am an outside supplier/client to this organisation that the leader leads
                    <br/><input type="radio" id="r7" name="slave" value="7" onClick={() => this.toggleSlave(7)}/>&nbsp;&nbsp;I work with the leader from an outside community perspective - church, club, etc
                    <br/><input type="radio" id="r8" name="slave" value="8" onClick={() => this.toggleSlave(8)}/>&nbsp;&nbsp;The leader is my direct coach/captain
                    <br/><input type="radio" id="r9" name="slave" value="9" onClick={() => this.toggleSlave(9)}/>&nbsp;&nbsp;I am an active student of the leaders work 
                    <br/><input type="radio" id="r10" name="slave" value="10" onClick={() => this.toggleSlave(10)}/>&nbsp;&nbsp;Other
                  </div>
                </TableCell>
                </TableRow>                   
                
                {this.state.showButton ?
                      <TableRow bgColor='#eeeeee'>
                        <TableCell colspan='4'><span style={{size: '18px'}}>
                        <b>1. Real-time Behavior and Performance Feedback</b>
                        </span>
                        </TableCell>
                      </TableRow>
                      : null}               
                    {this.state.showButton ?
                      <TableRow bgColor='#eeeeee'>
                        <TableCell colspan='4' backgroundColor='#eeeeee'>
                        <b>The only way for anyone to continue effective, or change ineffective everyday 
                          behaviours and performance towards their Best Self, is through real-time feedback. 
                        Please type your real-time feedback in the box below. </b>
                        Where appropriate, also give a simple rating on the behaviour or performance. <b>The scale is 1-10 (1 being poor and 10 being excellent):</b>
                        </TableCell>
                      </TableRow>
                      : null}
                     {this.state.showButton ?
                     <div bgColor='#eeeeee'>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rself">
                            1<input type="radio" id="r1" name="simple" value="1" onClick={() => this.toggleSimple(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="simple" value="2" onClick={() => this.toggleSimple(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="simple" value="3" onClick={() => this.toggleSimple(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="simple" value="4" onClick={() => this.toggleSimple(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="simple" value="5" onClick={() => this.toggleSimple(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="simple" value="6" onClick={() => this.toggleSimple(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="simple" value="7" onClick={() => this.toggleSimple(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="simple" value="8" onClick={() => this.toggleSimple(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="simple" value="9" onClick={() => this.toggleSimple(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="simple" value="10" onClick={() => this.toggleSimple(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                        </div>
                          :null}
                {this.state.showButton ?
                      <TableRow bgColor='#eeeeee'>
                        <TableCell colspan='4' bgColor='#eeeeee'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='simpleopinion'
                            id='simpleopinion'   
                            //value={this.state.selfopinion ? this.state.selfopinion : fb[0]}   
                            value={this.state.simpleopinion}  
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                simpleopinion: value
                              })
                            }}
                          />
        
                        </TableCell>
                      </TableRow>
                     :null}

                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <Button
                            variant='contained'
                            onClick={this.submitSPost}
                            style={sendBtn}
                            inputProps={{ maxLength: 280 }}
                            aria-describedby='opinion'
                           // value={this.state.selfopinion}
                            helperText={this.state.maxLengthThought}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                opinion: value
                              })
                            }}
                          >
                            <SendIcon />
                            Submit
                          </Button>
                        </TableCell>
                      </TableRow>
                      : null}
                   {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'><span style={{size: '18px'}}>
                        <b>2. General Behavior and Performance Feedback</b>
                        </span>
                        </TableCell>
                      </TableRow>
                      : null} 
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>1. On a scale of 1-10 (1 being poor and 10 being excellent) 
                            how effective is he/she at leading him/her self </b>
                            (Has a good enough technical understanding in the field where they lead, 
                            knows clearly and is congruent at living personal values; 
                            is clear about and tuned in to a sense of purpose; strives towards 
                            personal improvement continuously; is organised in general; 
                            is generally happy and at peace within; is balanced in physical, 
                            emotional, spiritual, financial and other areas of life.)?</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rself">
                            1<input type="radio" id="r1" name="self" value="1" onClick={() => this.toggleSelf(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="self" value="2" onClick={() => this.toggleSelf(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="self" value="3" onClick={() => this.toggleSelf(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="self" value="4" onClick={() => this.toggleSelf(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="self" value="5" onClick={() => this.toggleSelf(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="self" value="6" onClick={() => this.toggleSelf(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="self" value="7" onClick={() => this.toggleSelf(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="self" value="8" onClick={() => this.toggleSelf(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="self" value="9" onClick={() => this.toggleSelf(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="self" value="10" onClick={() => this.toggleSelf(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <b>Comment on the reason for your score, so that the leader can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}

                      {/* {feedback[0] ? Object.keys(feedback[0]).map(id => {
                        selfComment = feedback[0].selfopinion }
                        ): selfComment = this.state.selfopinion}   */}

                   {this.state.showButton ?
                      <TableRow >
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='selfopinion'
                            id='selfopinion'   
                            //value={this.state.selfopinion ? this.state.selfopinion : fb[0]}   
                            value={this.state.selfopinion}  
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                selfopinion: value
                              })
                            }}
                          />
        
                        </TableCell>
                      </TableRow>
                     :null}

                    {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>2. On a scale of 1-10 (1 being poor and 10 being excellent) how effective is he/she at leading others </b>(Mobilises others - the team - around a clear and powerful vision; is able to move others towards achieving their own and teams strategy and objectives; communicates effectively to individuals and team; ensures unity, a healthy culture in the team and organisation; is able to control emotions well under pressure; respects and treats all people the same; enjoys bringing the best out of people; empowers people to perform optimally - technically and personally; is able to adapt his/her leadership style to different individuals and situations; in general is respected and viewed as someone with integrity and a character of substance..)?</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rothers">
                            1<input type="radio" id="r1" name="others" value="1" onClick={() => this.toggleOthers(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="others" value="2" onClick={() => this.toggleOthers(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="others" value="3" onClick={() => this.toggleOthers(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="others" value="4" onClick={() => this.toggleOthers(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="others" value="5" onClick={() => this.toggleOthers(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="others" value="6" onClick={() => this.toggleOthers(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="others" value="7" onClick={() => this.toggleOthers(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="others" value="8" onClick={() => this.toggleOthers(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="others" value="9" onClick={() => this.toggleOthers(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="others" value="10" onClick={() => this.toggleOthers(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <b>Comment on the reason for your score, so that the leader can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            // className={classes.maintextField}
                            name='othersopinion'
                            id='othersopinion'
                            //value={this.state.othersopinion ? this.state.othersopinion : fb[1]}
                            value={this.state.othersopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                othersopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      : null} 

                     {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>3. On a scale of 1-10 (1 being poor and 10 being excellent) how effective is he/she at leading organisation </b>(Drives the entire organisation or division towards a high performance culture and consistent success; aligns systems, processes and procedures with the organisations vision and strategy, then manages these effectively; manages finances effectively and profitably; optimises effectively all available resources; manages effectively “things” as opposed to people.)?</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtoggleOrg">
                            1<input type="radio" id="r1" name="organisation" value="1" onClick={() => this.toggleOrg(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="organisation" value="2" onClick={() => this.toggleOrg(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="organisation" value="3" onClick={() => this.toggleOrg(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="organisation" value="4" onClick={() => this.toggleOrg(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="organisation" value="5" onClick={() => this.toggleOrg(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="organisation" value="6" onClick={() => this.toggleOrg(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="organisation" value="7" onClick={() => this.toggleOrg(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="organisation" value="8" onClick={() => this.toggleOrg(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="organisation" value="9" onClick={() => this.toggleOrg(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="organisation" value="10" onClick={() => this.toggleOrg(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <b>Comment on the reason for your score, so that the leader can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            // className={classes.maintextField}
                            name='orgopinion'
                            id='orgopinion'
                            //value={this.state.orgopinion ? this.state.orgopinion : feedback[index].orgopinion}
                            value={this.state.orgopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                orgopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      : null}   

                    {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>4. On a scale of 1-10 (1 being poor and 10 being excellent) how effective is he/she at leading society </b>(making a difference outside of the work environment - church, family, community…; getting involved in making the world a better place beyond work.)?</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtoggleOrg">
                            1<input type="radio" id="r1" name="society" value="1" onClick={() => this.toggleSos(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="society" value="2" onClick={() => this.toggleSos(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="society" value="3" onClick={() => this.toggleSos(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="society" value="4" onClick={() => this.toggleSos(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="society" value="5" onClick={() => this.toggleSos(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="society" value="6" onClick={() => this.toggleSos(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="society" value="7" onClick={() => this.toggleSos(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="society" value="8" onClick={() => this.toggleSos(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="society" value="9" onClick={() => this.toggleSos(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="society" value="10" onClick={() => this.toggleSos(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <b>Comment on the reason for your score, so that the leader can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='sosopinion'
                            id='sosopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.sosopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                sosopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}                                   
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <Button
                            variant='contained'
                            onClick={this.submitPost}
                            style={sendBtn}
                            inputProps={{ maxLength: 280 }}
                            aria-describedby='opinion'
                           // value={this.state.selfopinion}
                            helperText={this.state.maxLengthThought}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                opinion: value
                              })
                            }}
                          >
                            <SendIcon />
                            Submit
                          </Button>
                        </TableCell>
                      </TableRow>
                      : null}
                  </TableBody>
                   </Table>
                
              </TableContainer>
               </div>
            </DialogContent>
            <DialogActions>
              {/* <a href='#gototop' id='textInput'>....goto top</a> */}
              {/* <Button onClick={this.scrollIt} ref='inputElement'>Scroll</Button> */}
              {/* <Button onMouseOver={this.scrollIt} ref='inputElement'>Scroll</Button> */}
              <Button onClick={() => handleClosex()} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
    ); 
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
    leaderboardData: state.users.leaderboardData,
    sopinions: state.opinions.sopinion,
    opinions: state.opinions.opinion,
    feedback: state.opinions.feedback,
    //stateopinionID: state.opinions.opinionid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: params => dispatch(getUserProfile(params)),
    addOpinions: params => dispatch(addOpinions(params)),
    addSOpinion: params => dispatch(addSOpinion(params)),
    getOpinionsTrail: params => dispatch(getOpinionsTrail(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
    getOpinions: params => dispatch(getOpinions(params)),
    showToast: params => dispatch(showToast(params)),
    getOpinionsOnSubmit: params => dispatch(getOpinionsOnSubmit(params)),
    //FindSlave: params => dispatch(FindSlave(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser)
