import { SHOW_EDIT_USER_DIALOG, SHOW_USER_PROFILE_DIALOG, BASE_API_URL, GLP_LOGGED_PROFILE } from './types';
import axios from 'axios';
import { _toArray } from './commonActions';

export const showUserProfile = (params) => {
	if (params.type === SHOW_USER_PROFILE_DIALOG) {
		return (dispatch, getState) => {
			let userObj = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
			if (userObj) {
				axios
					.get(`${BASE_API_URL}/users`, {
						params: {
							tblcol: 'uid',
							tblval: userObj.uid
						}
					})
					.then((res) => {
						// var formattedData = _toArray(res.data.data);
						var formattedData = [];
						formattedData[0] = res.data.data;
						formattedData[0].id = userObj.uid;
						formattedData[0].uid = userObj.uid;

						dispatch({
							type: SHOW_USER_PROFILE_DIALOG,
							userDetails: formattedData
						});
					})
					.catch((err) => {
						dispatch({
							type: 'SHOW_TOAST',
							open: true,
							variant: 'error',
							message: 'Error response: ' + err.message
						});
					});
			} else {
				dispatch({
					type: SHOW_USER_PROFILE_DIALOG,
					userDetails: []
				});
			}
		};
	} else {
		return (dispatch, getState) => {
			dispatch({
				type: SHOW_EDIT_USER_DIALOG,
				userDetails: {}
			});
		};
	}
};

export const updateUserProfile = (params) => {
	return (dispatch, getState) => {
		var body = {
			...params,
			name: 'users',
			id: params.userID,
			LeaderboardKey: params.companyID + '_' + params.nationality + '_' + params.position + '_' + params.department,
		};
      console.log('green updateUserProfile: ', body);

		delete body['confirmPassword'];
		delete body['password'];
		delete body['showConfirmPassword'];
		delete body['showPassword'];
		delete body['unsavedchanges'];
		delete body['value'];
		delete body['userURL'];
        delete body['showPassword'];

		// update new data
		axios
			.put(`${BASE_API_URL}/users/${body.userID}`, body)
			.then((res) => {
				dispatch({
					type: 'UPDATE_USER_SUCCESS'
				});
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: 'SHOW_TOAST',
						open: true,
						variant: 'error',
						message: 'Error response: ' + err.response.data.message
					});
				} else if (err.request) {
					dispatch({
						type: 'SHOW_TOAST',
						open: true,
						variant: 'error',
						message: 'Error request: ' + err.request
					});
				} else {
					dispatch({
						type: 'SHOW_TOAST',
						open: true,
						variant: 'error',
						message: 'Error message: ' + err.message
					});
				}
			});
	};
};

export const updateUserPassword = (params) => {
	return (dispatch, getState) => {
		var body = {
			...params,
			name: 'resetpassword',
			id: params.uid
		};

		// delete body['confirmPassword'];
		delete body['showConfirmPassword'];
		delete body['showPassword'];
		delete body['unsavedchanges'];
		delete body['value'];
		delete body['userURL'];

		// update new data
		axios
			.put(`${BASE_API_URL}/resetpassword/${body.uid}`, body)
			.then((res) => {
				dispatch({
					type: 'UPDATE_USER_SUCCESS'
				});
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: 'SHOW_TOAST',
						open: true,
						variant: 'error',
						message: 'Error response: ' + err.response.data.message
					});
				} else if (err.request) {
					dispatch({
						type: 'SHOW_TOAST',
						open: true,
						variant: 'error',
						message: 'Error request: ' + err.request
					});
				} else {
					dispatch({
						type: 'SHOW_TOAST',
						open: true,
						variant: 'error',
						message: 'Error message: ' + err.message
					});
				}
			});
	};
};
