import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List, ListItem, ListItemText, ListSubheader, Avatar, ListItemAvatar,
  Grid, Typography
} from '@material-ui/core'
import {
  withStyles,
} from '@material-ui/core/styles'
import { LIGHTGREY } from "../../utils/color_utils";
import { PHOTO_PROFILE } from '../../utils/photo_utils'

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

class GroupSummary extends Component {
  render() {
    const { classes, participants, group_name, group_no, start_date, end_date, status } = this.props;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h5'>{group_no} - {group_name}</Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant='caption'>Start date:</Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant='caption'>{start_date}</Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant='caption'>End date:</Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant='caption'>{end_date}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant='caption'>{status}</Typography>
          </Grid>
        </Grid>
        <List subheader={<ListSubheader style={{ backgroundColor: `${LIGHTGREY}`, fontWeight: "bold", fontSize: "13px", lineHeight: "28px", color: "#000", borderRadius: "4px", position: 'static' }}>Participants</ListSubheader>} dense className={classes.root}>
          {participants && Object.keys(participants).map((participant, id) => {
            return (
              <ListItem key={id} button>
                <ListItemAvatar>
                  <Avatar
                    alt={participants[participant].firstName + ' ' + participants[participant].lastName}
                    src={PHOTO_PROFILE(participants[participant].photoURL)}
                    id={participants[participant].uid}
                  />
                </ListItemAvatar>
                <ListItemText id={participants[participant].userID} primary={participants[participant].firstName + ' ' + participants[participant].lastName} secondary={participants[participant].email} />
              </ListItem>
            );
          })}
        </List>
      </div>
    )
  }
}

GroupSummary.propTypes = {
  classes: PropTypes.object,
  participants: PropTypes.any,
  group_no: PropTypes.any,
  group_name: PropTypes.any,
  start_date: PropTypes.any,
  end_date: PropTypes.any,
  status: PropTypes.any,
}

const GroupSummaryStyled = withStyles(styles)(GroupSummary)

export default (GroupSummaryStyled);
