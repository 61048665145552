import { SUBCOMPETENCIES_LIST } from "../actions/types";

const initState = {
  type: SUBCOMPETENCIES_LIST,
  subcompetencies_list: {},
  subcompetencies: {},
};

const subcompetenciesReducer = (state = initState, action) => {
  switch (action.type) {
    case SUBCOMPETENCIES_LIST:
      return {
        // ...state, 
        // type: SUBCOMPETENCIES_LIST, 
        // subcompetencies_list: action.subcompetencies_list
        type: SUBCOMPETENCIES_LIST,
        subcompetencies_list: {
          ...state.subcompetencies_list,
          ...action.subcompetencies_list
        }, 
        subcompetencies: {
          ...state.subcompetencies,
          ...action.subcompetencies
        } 
      };

    default:
      return state;
  }
};

export default subcompetenciesReducer;
