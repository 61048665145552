import React from "react";
import Questions from "./Questions";
import Answers from "./Answers";
import Correct from "./Correct";
import Incorrect from "./Incorrect";
import FinalScope from "./FinalScope";
import "./Survey.css";
import { updateSurvey } from "../../store/actions/surveyUpdate";
import { compileQuestions } from "../../store/actions/surveyQAction";
import { appendAnswer } from "../../store/actions/collectedAnswers";
import { showToast, clearStates } from "../../store/actions/toastAction";
import { connect } from "react-redux";
import firebase from "../../store/firebase";
import {
  UPDATE_SURVEY,
  SURVEY_Q_LIST,
  COLLECT_ANSWER,
  SHOW_TOAST
} from "../../store/actions/types";
//import { addMorePoints } from '../../store/actions/freshCardActions'
//import { appendTest } from "../../store/actions/collectTestResults";
class Survey extends React.Component {
  constructor(props) {
    super(props);
    //this.props.letgoPic(true);
    this.state = {
      counterCorrectAnswers: 0,
      counterIncorrectAnswers: 0,
      questionNumber: -1,
      surveyid: this.props.surveyid, 
      typeofSurvey: this.props.typeofSurvey,
      real_companyID: this.props.real_companyID,
      companyID: this.props.companyID,
      size: this.props.size,
      surveysArr: [],
      varQuestion: ''
    };
   console.log('finalscore props: ', this.props);

    //const { survey_Q_list } = this.props;
    this.checkingCorrectAnswer = this.checkingCorrectAnswer.bind(this);
    this.checkingCorrectAnswerx = this.checkingCorrectAnswerx.bind(this);
  }

   getQ(nrIn) {  
     var returnVal = 'aaaa';
     var nrIn2;
    // var nrIn = 0;

     var x;
     this.props.survey_Q_list && Object.keys(this.props.survey_Q_list).map((QandA, id) => {   
      var lenq = parseInt(nrIn)+1;
      console.log('orange lenq: ', lenq);
      if (lenq <= this.state.size) {
       x = parseInt(nrIn);

        if ( id == x ) {
        nrIn2 = parseInt(x) + 1;
        var questionNumber = String("QandA"+nrIn2); 
        console.log('orange this.state.size: ', this.state.size);
        console.log('orange questionNumber: ', nrIn2);

        if ( this.props.survey_Q_list[questionNumber] !== undefined ) {
            console.log('orange inside 4: ',this.props.survey_Q_list[questionNumber].question);
            returnVal = this.props.survey_Q_list[questionNumber].question;
         }
        } 
      }

     });
     return returnVal;
    }

    checkingCorrectAnswerx(value) {
      console.log('blue inside checkingCorrectAnswer');
      console.log('blue correct: ');
      console.log('blue value checkingCorrectAnswerx: ', value.id);
      

     //if (value.id) {
        this.setState(prevState => ({
          questionNumber: prevState.questionNumber + 1
        }))
        var params = {
          type: COLLECT_ANSWER,
          surveyDone: value.id +'-'+ this.props.surveyid,
          surveyid: this.props.surveyid,
          companyID: this.props.companyID,
          real_companyID: this.props.real_companyID,
          //groupID: value.id,
          personid: this.props.personid, // this.props.personid,
          questionNr: -1,
          thequestion: this.getQ(this.state.questionNumber),
          questionAnswer: '',
          surveyName: this.props.surveyName
        };
        this.props.appendAnswer(params);       
    };

   checkingCorrectAnswer = (value, correct) => {
    console.log('blue value: ', value );
    console.log('blue correct: ', correct );
    console.log('blue questionNumber: ', this.state.questionNumber);

    value === correct
      ? this.setState(prevState => ({
          counterCorrectAnswers: prevState.counterCorrectAnswers + 1,
          questionNumber: prevState.questionNumber + 1
        }))
      : this.setState(prevState => ({
          counterIncorrectAnswers: prevState.counterIncorrectAnswers + 1,
          questionNumber: prevState.questionNumber + 1
        }));
      console.log('blue inside checkingCorrectAnswer:', this.props.surveyDone);
      console.log('blue questionNumber 2: ', this.state.questionNumber);

      var surveyiDone = this.props.surveyDone;
      var key = "QandA"+this.state.questionNumber;
      console.log('GREENy QandA key:', key);

      if (this.props.publisherid !== this.props.personid) { 
        var params = {
          type: COLLECT_ANSWER,
          surveyDone: this.props.surveyDone,
          surveyid: this.props.surveyid,
          companyID: this.props.companyID,
          real_companyID: this.props.real_companyID,
          personid: this.props.personid, 
          questionNr: this.state.questionNumber,
          thequestion: this.getQ(this.state.questionNumber),
          questionAnswer: value
        };
        this.props.appendAnswer(params);
     }
   };

  render() {
    if (this.state.questionNumber < this.state.size) {
      console.log('pink questionNumber: ', this.state.questionNumber);
      console.log('pink this.props: ', this.props);

      return (
        // <div className="container">
        <div style={{
          width:'100%', 
          align: 'center'
          }}>
        { this.state.questionNumber ===-1?
        <React.Fragment>
        <Questions 
            key={this.state.surveyid}
            surveyid={this.state.surveyid}
            questionNumber={this.state.questionNumber}
            companyID={this.state.companyID} 
            real_companyID={this.state.real_companyID} 
            personid={this.props.personid}
            checkingCorrectAnswerx={this.checkingCorrectAnswerx}
            />  
        </React.Fragment>
          :  
          <div className="row">
          <div className="col-9">
            <Questions 
                key={this.state.surveyid}
                surveyid={this.state.surveyid}
                questionNumber={this.state.questionNumber}
                companyID={this.state.companyID} 
                real_companyID={this.state.real_companyID} 
                checkingCorrectAnswer={this.checkingCorrectAnswer}
            />
          </div>
          </div>
            }
        </div>
      );
    } else {
      console.log('Finalscope props 0: ', this.props);
      return (
        <FinalScope
          counterCorrectAnswers={this.state.counterCorrectAnswers}
          counterIncorrectAnswers={this.state.counterIncorrectAnswers}
          typeofSurvey={this.props.typeofSurvey}
          personid={this.props.personid}
          personName={this.props.userdata.firstName}
          personLname={this.props.userdata.lastName}
          companyID={this.props.companyID} 
          real_companyID={this.props.real_companyID}
          companyName={this.props.userdata.companyName} 
          surveyid={this.props.surveyid}
          qlen={this.props.size}
          uid={this.props.userdata.uid}
          points={this.props.userdata.points}
          pointsy={this.props.userdata.pointsy}
          publisherid={this.props.publisherid}
          thoughtType={this.props.thoughtType}
          title={this.props.title}
          subtitle={this.props.subtitle}
          showLeadb={this.props.showLeadb}
          thoughtID={this.props.thoughtID}
         // thought={this.props.thought}
         // resetTest={this.props.resetTest}
        />
      );
   }
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    update_Survey: state.updateSurvey.update_Survey,
    survey_Q_list: state.surveyQ.survey_Q_list,
    surveyDone: state.collectAnw.surveyDone,
    sgroupid: state.collectAnw.sgroupid
    //collectTest: state.collectTest.collectTest
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSurvey: params => dispatch(updateSurvey(params)),
    compileQuestions: params => dispatch(compileQuestions(params)),
    appendAnswer: params => dispatch(appendAnswer(params)),
    showToast: params => dispatch(showToast(params))
   // appendTest: params => dispatch(appendTest(params))
   // addMorePoints: params => dispatch(addMorePoints(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);

