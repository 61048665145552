import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ViewPostActivity from "./ViewPostActivity";
import MoreIcon from "@material-ui/icons/More";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CloseIcon from "@material-ui/icons/Close";
import { HOVERRED, RED, LIGHTBLUE } from "../../utils/color_utils";

const styles = theme => ({
  appBar: {
    position: "static",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  flex: {
    flex: 1
  },
  dialog: {},
  divStyle: {
    padding: "0px",
    paddingTop: "0px!important",
    height: "100vh!important",
    margin: "0px"
  },
  close: {
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "999999",
    color: "#fff",
    backgroundColor: `${RED}`,
    borderRadius: "0%",
    padding: "8px",
    "&:hover": {
      background: `${HOVERRED}`
    }
  }
});

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class PostActivityDialog extends React.Component {
  state = {
    open: false,
    scroll: "paper"
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    this.props.onClosePostActivity();
  };

  render() {
    const { classes, fullScreen, postActivity } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          fullScreen={fullScreen}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          aria-labelledby="scroll-dialog-title"
          style={{ height: "100vh!important" }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: `${LIGHTBLUE}` }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MoreIcon fontSize="small" style={{ color: `${LIGHTBLUE}` }} />
              <span style={{ color: `${LIGHTBLUE}`, fontWeight: "bold" }}>
                &nbsp;Post analytics
              </span>
            </div>
          </DialogTitle>
          <DialogContent className={classes.divStyle}>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose.bind(this)}
            >
              <CloseIcon fontSize="small" className={classes.icon} />
            </IconButton>
            <ViewPostActivity postActivity={postActivity} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

PostActivityDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  postActivity: PropTypes.object.isRequired
};

const PostActivityDlg = withMobileDialog({ breakpoint: "xs" })(
  PostActivityDialog
);

export default withStyles(styles, { withTheme: true })(PostActivityDlg);
