/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
/* eslint-disable no-dupe-keys */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emphasize, withStyles } from '@material-ui/core/styles'
import {
  Paper,
  Grid,
  Typography,
  Tab,
  Tabs,
  Breadcrumbs,
  Chip,
  Button
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import GroupsUsers from './groupUsers'
import Events from './events'
import { LIGHTBLUE } from '../../utils/color_utils'
import { red } from '@material-ui/core/colors'
import { showToast } from '../../store/actions/toastAction'
import { getGroupDetails, resendGroupWelcomeEmail } from '../../store/actions/companyAdminActions'
import Calendar from './calendar'
import AttendanceRegister from './AttendanceRegister'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import firebase from '../../store/firebase'
import { withRouter } from 'react-router-dom'


function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 0, marginTop: 6 }}>
      {props.children}
    </Typography>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const styles = theme => ({
  root: {
    marginTop: 0,
    padding: '[10px',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '110px'
    },
    primary: `${LIGHTBLUE}`

  },
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: '#1890ff'
  },
  paper: {
    padding: theme.spacing(),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    minHeight: '-webkit-fill-available',
    minHeight: '-moz-fill-available',
    minHeight: 'fill-available'
  },
  bluepaper: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(2)

  },
  deleteButton: {
    'backgroundColor': red[500],
    'color': '#ffffff',
    'margin': theme.spacing.unit,
    'fontSize': '10px',
    '&:hover': {
      backgroundColor: red[300],
      color: '#ffffff'
    }
  },
  group_userslabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: theme.spacing()
  },
  tabsInnerDiv: {
    marginTop: '16px',
    background: '#fff!important',
    borderBottom: '1px solid #e8e8e8'
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
})
const tabsdiv = {
  flexGrow: 1,
  width: '100%!important',
  backgroundColor: '#fff'
}

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    'backgroundColor': theme.palette.grey[100],
    'height': theme.spacing(3),
    'color': theme.palette.grey[800],
    'fontWeight': theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none'
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none'
    }
  }
}))(Chip)
class GroupDetails extends Component {
  state = {
    tabsvalue: 0,
    open: false
  }

  handleTabsChange = (event, tabsvalue) => {
    this.setState({ tabsvalue })
  }

  handleClickOpen = () => {
    this.setState({ open: !this.state.open })
  };

  handleClose = () => {
    this.setState({ open: !this.state.open })
  };

  handleRemoveGroup = async () => {
    try {
      let database = firebase.database
      let response = await database.ref(`/company_groups/${this.props.group_details.company_id}/${this.props.group_details.group_id}`).update({ active: false })
     // console.log('[response]', response)
      this.setState({ open: !this.state.open })
      this.props.history.push('/groups')
    } catch (e) {
      console.log('[ERROR]', e)
    }
  }
  sendGroupWelcomeEmail = () => {
    let params = { ...this.props.group_details }
    let users = this.props.group_users

    let userEmails = Object.keys(users).map(id => {
      return [users[id].email]
    })

    let strEmails = userEmails.join()

    params.firstName = this.props.group_details.group_name
    params.lastName = this.props.group_details.group_name
    params.password = this.props.group_details.group_name
    params.email = strEmails
    params.to = strEmails
    params.companyID = this.props.group_details.company_id
    params.companyName = this.props.userdata.companyName
    params.new_update = 1
    params.email_type = 'welcome'

    // console.log("params: ", params)

    this.props.resendGroupWelcomeEmail(params)
  }

  componentDidMount() {
    let path = window.location.pathname
    let pathString = path.split('/')
    let group_id = pathString[2]

    let params = {
      companyID: this.props.userdata.companyID,
      group_id: group_id
    }
    this.props.getGroupDetails(params)
  }

  render() {
    const { classes, group_details } = this.props
    const { tabsvalue } = this.state
    let path = window.location.pathname
    let pathString = path.split('/')
    let groupId = pathString[2]
    let group_name = `${this.props.group_details.group_name} Group`
    return (
      <div className={classes.root}>
        <Grid container spacing={2} gutterBottom style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h5'>{this.props.group_details.group_name}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Breadcrumbs aria-label='breadcrumb'>
              <StyledBreadcrumb
                component={Link}
                to={`/companyadmin`}
                label='Club Admin'
              />
              <StyledBreadcrumb
                component={Link}
                to={`/groups`}
                label='Manage Groups'
              />
              <StyledBreadcrumb
                label={group_name}
              />
            </Breadcrumbs>
          </Grid>
        </Grid>

        <div style={{ alignItems: 'flex-end' }}>
          <Button
            variant='outlined'
            color='secondary'
            size='small'
            href='#outlined-buttons'
            onClick={this.handleClickOpen}
          >
            <DeleteIcon className={classes.leftIcon} fontSize='small' />
             Remove Group
          </Button>
        </div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>{'Remove Group?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
           Are you sure you want to remove group?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRemoveGroup} color='primary'>
           Yes
            </Button>
            <Button onClick={this.handleClose} color='primary'>
           Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ tabsdiv }}>
          {/* <AppBar position='static' color='default' > */}
          <Tabs
            value={tabsvalue}
            onChange={this.handleTabsChange}
            variant='fullWidth'
            indicatorColor='primary'
            textColor='primary'
            aria-label='group tabs'
            className={classes.tabsInnerDiv}
          >
            <Tab aria-label='group-members' label='Participants' />
            <Tab aria-label='events' label='Events' />
            <Tab aria-label='calendar' label='Calendar' />
            <Tab aria-label='attendance_register' label='Attendance Registers' />
            {/* <Tab aria-label='study_materials' label='Study Materials' /> */}
          </Tabs>
          {/* </AppBar> */}
          {/* Members Tab */}
          {tabsvalue === 0 && (
            <TabContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper elevation={0} className={classes.paper}>
                    <Grid item xs={12}>
                      <Paper className={classes.bluepaper}>
                        <div>
                          <GroupsUsers sendWelcomeEmail={this.sendGroupWelcomeEmail} groupId={groupId} />
                        </div>
                      </Paper>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {/* Events */}
          {tabsvalue === 1 && (
            <TabContainer>
              <Paper className={classes.bluepaper}>
                <div>
                  <Events group_id={groupId} group_details={group_details} />
                </div>
              </Paper>
            </TabContainer>
          )}
          {/* Calendar */}
          {tabsvalue === 2 && (
            <TabContainer>
              <div style={{ paddingTop: '12px' }}>
                <Calendar group_id={groupId} />
              </div>
            </TabContainer>
          )}
          {/* Attendance Register */}
          {tabsvalue === 3 && (
            <TabContainer>
              <div style={{ paddingTop: '12px' }}>
                <AttendanceRegister group_id={groupId} group_details={group_details} />
              </div>
            </TabContainer>
          )}
          {/* Study Materials */}
          {/* {tabsvalue === 4 && (
            <TabContainer>
              <div style={{ paddingTop: '12px' }}>
                <GroupStudyMaterials group_id={groupId} group_details={group_details} />
              </div>
            </TabContainer>
          )} */}
        </div>
      </div>
    )
  }
}

GroupDetails.propTypes = {
  classes: PropTypes.object
}


const mapStateToProps = state => {
  return {
    classes: PropTypes.object,
    userdata: state.userDialog.userDetails[0],
    group_users: state.users.group_users,
    group_details: state.users.group_details
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    getGroupDetails: params => dispatch(getGroupDetails(params)),
    resendGroupWelcomeEmail: params => dispatch(resendGroupWelcomeEmail(params))
  }
}

const GroupDetailsStyled = withStyles(styles)(GroupDetails)
const GroupWithRouter = withRouter(GroupDetailsStyled)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupWithRouter)


