import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ThoughtsView from "../components/Views/ThoughtsView";
import { fetchThoughts } from "../store/actions/thoughtsActions";
import { fetchUserData } from "../store/actions/userActions";
import { ORG_THOUGHTS, SET_SEEN_NOTIFICATIONS } from "../store/actions/types";
import { deletePostAttachment } from "./../store/actions/attachmentActions";

class Organisational extends Component {
  constructor (props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      minData: 6
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 6
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    console.log('organisational compponentDidMount 10 : ', this.props.thoughts) 
    window.addEventListener("scroll", this.handleScroll);
    console.log('organisational compponentDidMount 1 ', this.props.thoughts)
    if (this.props.thoughts.length !== 0) {
      console.log('organisational compponentDidMount 2 ', this.props.thoughts.length) 
    } else {
      console.log('organisational compponentDidMount 3 ', this.props.thoughts)
      this.fetchData()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
   // this.fetchData();
  };

  fetchData = () => {
    console.log('organisational compponentDidMount 9 : ', this.props.thoughts) 
    var params = {
      type: ORG_THOUGHTS,
      companyID: this.props.userdata.companyID,
      orderChild: "publish_status",
      equalTo: this.props.userdata.companyID + "_approved",
      minData: this.state.minData
      // userID: this.props.userdata.userID,
      // companyName: this.props.userdata.companyName,
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    };
    console.log('green ORG_THOUGHTS 1')
    this.props.fetchThoughts(params);

    this.props.fetchUserData({
      type: SET_SEEN_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      path: this.props.match.path,
      unseenotifications: this.props.unseenotifications.unseenotifications
    });
  };
  render() {
    const { thoughts, userdata } = this.props;
    console.log('organisational compponentDidMount 4', thoughts)
    return (
      <div>
        {userdata && thoughts ? (
          <React.Fragment>
            <ThoughtsView
              thoughts={this.props.thoughts}
              thoughtsb={this.props.thoughts}
              currentUser={userdata}
              {...this.props}
              openFileViewer={this.props.openFileViewer}
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

Organisational.propTypes = {
  fetchThoughts: PropTypes.func.isRequired,
  thoughts: PropTypes.array,
  userdata: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    thoughts: state.thoughts.organisational,
    userdata: state.userDialog.userDetails[0],
    unseenotifications: state.userdata.unseenotifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchThoughts: params => dispatch(fetchThoughts(params)),
    deletePostAttachment: params => dispatch(deletePostAttachment(params)),
    fetchUserData: params => dispatch(fetchUserData(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organisational);
