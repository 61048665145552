import React from "react";
import Button from "./Button";
import firebase from "../../store/firebase";
import { Container, Row, Col } from 'react-grid-system';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import {
  isMobile
} from "react-device-detect";

class Answers extends React.Component {
  constructor(props) {
    super(props);
  }
  
  state = {
    groupname: "",
    defaultgroup: "Select Your Group",
    gr: ""
  }

  componentDidMount() {
    console.log('blue props userID: ', this.props.personid);

    var database = firebase.database;
    database
      .ref("company_user_groups/" + this.props.personid)
      .once("value")
      .then(snapshot => {
        const groupsArr = [];

        snapshot.forEach(childSnapshot => {
          groupsArr.push({
            id: childSnapshot.key,
            group_id: childSnapshot.val().group_id,
            group_name: childSnapshot.val().group_name
          });
        });
        this.setState({ gr: groupsArr });
      });
  }

  render() {
    const { QandA } = this.props;
    console.log('blue this.props: ', this.props);
    var widthx;
    if (isMobile) {
      widthx = '300px'
    }
    else
    {
      widthx = '380px'
    }
    return (
    <React.Fragment>
      { this.props.questionNumber===-1?  
       <div> 
         <div style={{
             marginLeft: '-50px',
             width: widthx,
             color: '#000000',
             fontSize: '12px',
             align: 'center',
             display: 'block'
         }}>
 
         <b>Dear Leader</b> <br /><br />
      You are about to complete a test for the selected publication. Your test will consist of multiple choice questions with one correct
      answer for each question. There will be between three and eight questions set by the publisher. If you pass the test, you will earn 20 points. Once a test is completed
      it is final and on completion of the test, your earned points will be allocated to you. Enjoy! 
      </div>
      
      <Container> 
      <Row>
          <Col sm={12}>
          <Input name="survey" id="survey" value={this.props.thoughtID} hidden='true'/>
          </Col>
          </Row>
          <Row><Col>
          &nbsp;</Col></Row>
          <Row>
            <Col sm={12}>
               <button style={{
                  width: '90%',
                  fontSize: '15px',
                  opacity: '60%',
                  transition: '0.3s',
                  display: 'block',
                  cursor: 'pointer',
                  borderColor: '#ffffff',
                  marginLeft: '-50px',
                  color: '#000000',
                }}
            onClick={() => {
              this.props.checkingCorrectAnswerx(this.state.groupname);
            }}
            >
              {'Start >>'}
            </button>
            </Col>
          </Row>
          </Container> 
           
       </div>            
       :
       <div>   
        { QandA && Object.keys(QandA).map((ans, id) => {
          if (id<4) {
           return (
            <button style={{
             width: '100%',
             fontSize: '15px',
             opacity: '60%',
             transition: '0.3s',
             cursor: 'pointer',
             borderColor: '#ffffff',
             color: '#000000',
           }}
            onClick={() => {
              this.props.checkingCorrectAnswer(
                QandA[ans],
                QandA["a5"]
              );
            }}
            >
              {QandA[ans]}
            </button>
            ) 
          }

        })}
      </div>
      }
      
       </React.Fragment>
    );
  }
}
export default Answers;

