import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArticlesCard from "../Cards/ArticlesCard";
import ReadArticles from "./ReadArticles";
import MediaPlayer from "../Dialogs/MediaPlayer";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";
import CategoriesSelect from "../../components/categories/categoriesSelect";
import { storeSubCompetency } from "../../store/actions/subcompActions";
import { STORE_SUBCOMPETENCYID } from "../../store/actions/types";
import { HIDE_COMMENTS_BUTTON } from "../../store/actions/types";
import {
  isMobile
} from "react-device-detect";

var receivedData = {};

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class ArticlesView extends React.Component {
  state = {
    readarticles: false,
    article: null,
    openmediaplayer: false,
    mediatype: "articles",
    media: null,
    openAddPLDP: false,
    attachmentType: "",
    subcompetencyid: ""
  };

  UpdateReadArticles = () => {
    this.setState({
      ...this.state,
      readarticles: false,
      article: null
    });
  };

  ReadArticles = article => {
    this.setState({
      ...this.state,
      readarticles: true,
      article: article
    });
  };

  OpenMediaPlayer = (media, mediatype) => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media,
      mediatype: mediatype
    });
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    });
  };

  handleRemoveAttachement = (thought, attachmentType, attachmentID) => { };

  _sortData = data => {
    var sortedData = _toArray(data);
    sortedData.sort(function(a, b) {
      if (a.dateScheduled > b.dateScheduled) return -1;
      if (a.dateScheduled < b.dateScheduled) return 1;
      return 0;
    });

    return sortedData;
  };

  componentDidUpdate() {
    if (Object.keys(this.props.articles).length > 0) {
      var arrayData = this._sortData(this.props.articles);
      receivedData = { ...arrayData };

      receivedData = Object.assign({}, arrayData);
    }
  }
  //function to go with CategoriesSelect ///
  handleCompState = (scname, scid) => {
    this.setState({ subcompetency: scname });
    this.setState({ subcompetencyid: scid });
  }
  render() {
    const { classes, articles, currentUser, followingData } = this.props;
    const {
      readarticles,
      article,
      openmediaplayer,
      media,
      mediatype
    } = this.state;
    return (
      <React.Fragment>
        {isMobile?
         <div><p>&nbsp;</p></div>: 
         <div></div>
        }
        <CategoriesSelect keepsubcomp={false} doCompState={this.handleCompState} companyID={this.props.userdata.companyID} displayComp={this.props.fscompname} />

        {/* {Object.keys(articles).length > 0 ? null : (
          <Progressbar className={classes.progress} color="secondary" />
        )} */}

        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}
        {readarticles ? (
          <div>
            <ReadArticles
              article={article}
              closeViewer={this.UpdateReadArticles}
              ReadArticle={this.ReadArticles}
              currentUser={this.props.currentUser}
              {...this.props}
            />
          </div>
        ) : (
            <div className={classes.cardstyles}>
              {this.props.match.path === '/filtered-articles' ? this.props.match.path === '/filtered-articles' &&
                articles && Object.keys(articles).length > 0 && Object.keys(articles).map(id => {

                  // console.log('Articles 1:', this.props );
                  //##################################
                  //if (this.props.subcompFilter === "" || this.props.subcompFilter == undefined || this.props.articles[articles[id].id].subcompetencyid == null) {
                  if (this.state.subcompetencyid.trim() === "") {
                    return (
                      <ArticlesCard
                        ReadArticle={this.ReadArticles}
                        article={articles[id]}
                        currentUser={currentUser}
                        PlayMedia={this.OpenMediaPlayer}
                        onRemoveAttachement={this.handleRemoveAttachement}
                        key={id}
                        {...this.props}
                        following={
                          followingData
                            ? followingData[articles[id].journalUserID]
                              ? true
                              : false
                            : false
                        }
                        hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                        openFileViewer={this.props.openFileViewer}
                      />

                    );
                  }
                  else {
                    //user redux prop
                    // console.log('Articles 2:', this.props );
                    if (this.props.articles[articles[id].id].subcompetencyid === this.state.subcompetencyid) {
                      return (

                        <ArticlesCard
                          ReadArticle={this.ReadArticles}
                          article={articles[id]}
                          currentUser={currentUser}
                          PlayMedia={this.OpenMediaPlayer}
                          onRemoveAttachement={this.handleRemoveAttachement}
                          key={id}
                          {...this.props}
                          following={
                            followingData
                              ? followingData[articles[id].journalUserID]
                                ? true
                                : false
                              : false
                          }
                          hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                          openFileViewer={this.props.openFileViewer}
                        />
                      );
                    }
                  }
                  //##########################################


                }) : articles &&
                // Object.keys(articles).length > 0 &&
                Object.keys(receivedData).map(id => {

                  // // console.log('Articles 3:', this.props.articles[receivedData[id].id].subcompetencyid ); //########################
                  //var mapid = receivedData[id].id;
                  //if (this.props.subcompFilter === "" || this.props.subcompFilter == undefined || this.props.articles[receivedData[id].id].subcompetencyid == undefined) {
                  // if (this.props.articles[receivedData[id].id].subcompetencyid === 1580999508241) {  
                  // if (this.props.subcompFilter === "" ) {
                  if (this.state.subcompetencyid.trim() === "") {
                    return (

                      <ArticlesCard
                        ReadArticle={this.ReadArticles}
                        article={receivedData[id]}
                        currentUser={currentUser}
                        PlayMedia={this.OpenMediaPlayer}
                        onRemoveAttachement={this.handleRemoveAttachement}
                        key={id}
                        {...this.props}
                        following={
                          followingData
                            ? followingData[receivedData[id].journalUserID]
                              ? true
                              : false
                            : false
                        }
                        hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                        openFileViewer={this.props.openFileViewer}
                      />

                    );
                  }
                  else {
                    // console.log('Articles 4:', this.props );

                    //user redux prop
                    if (receivedData[id].subcompetencyid === this.state.subcompetencyid) {
                    //  if (this.props.articles[receivedData[id].id].subcompetencyid === this.state.subcompetencyid) {
                      return (
                        <ArticlesCard
                          ReadArticle={this.ReadArticles}
                          article={receivedData[id]}
                          currentUser={currentUser}
                          PlayMedia={this.OpenMediaPlayer}
                          onRemoveAttachement={this.handleRemoveAttachement}
                          key={id}
                          {...this.props}
                          following={
                            followingData
                              ? followingData[receivedData[id].journalUserID]
                                ? true
                                : false
                              : false
                          }
                          hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                          openFileViewer={this.props.openFileViewer}
                        />
                      );
                    }
                  }

                })}
            </div>
          )}
      </React.Fragment>
    );
  }
}

ArticlesView.propTypes = {
  classes: PropTypes.object.isRequired,
  articles: PropTypes.object
};

var ArticlesViewStyled = withStyles(styles)(ArticlesView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton,
    subcompFilter: state.subcomp.cvalue,
    fscompname: state.scompname.scompname
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeSubCompetency: params => dispatch(storeSubCompetency(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticlesViewStyled);
