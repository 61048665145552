import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
// import CompOptions from "./competenciesoptions";
import CompetencyOptions from "./CompetencyOptions";

const styles = {
  searchUser__block: {
    background: "#fff",
    position: "relative",
    top: "1px"
  }
};

class CompSelect extends Component {
  constructor (props) {
    super(props);
    console.log('props for compSelect: ', this.props);
  }

  state = {
    name: ""
  };

  handleChangeValue = (id, name) => {
    this.setState(
      preState => ({
        ...preState,
        cid: id,
        name: name,
      }),
      function() {
        //this.props.handleCompetency(target.value.cid, target.value.name);
        this.props.handleCompetency(id, name);
      }
    );
  };

  render() {
    const { classes, competencyid } = this.props;
     console.log("greencompetency d : ", this.props.dailyThoughtType)
     console.log("greencompetency c : ", competencyid)

    return (
       <CompetencyOptions dailyThoughtType={this.props.dailyThoughtType} onChangeValue={this.handleChangeValue} competencyid={competencyid} />
    );
  }
}

export default withStyles(styles)(CompSelect);
