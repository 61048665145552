import { SHOW_SPEEDDIAL, SHOW_TOAST } from "./types";

export const showSpeedDial = params => {
  //console.log('inside speedDialAction :', params.path);

  if (params.type === SHOW_SPEEDDIAL) {
    return (dispatch, getState) => {
      dispatch({
        type: SHOW_SPEEDDIAL,
        speedDPath: '/'+params.path
      });
    };
  } else {
    return (dispatch, getState) => {
      dispatch({
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Some problem with speedDialActions."
      })
    };
  }
};




