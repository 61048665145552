import React from "react";
import ReactDOM from "react-dom";
import "./Index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from 'react-router-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  // useResolvedPath
} from "react-router-dom";
import "./Index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Survey from "./Survey";
import { Container, Row, Col } from 'react-grid-system';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Grid,
  FormHelperText,
  Button
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Hidden } from '@material-ui/core';
import DialogActions from "@material-ui/core/DialogActions";
import { GREY, RED, LIGHTBLUE, LIGHTGREY } from "../../utils/color_utils";
import SendIcon from "@material-ui/icons/Send";
import { showToast } from "../../store/actions/toastAction";
import { createSurvey } from "../../store/actions/SurveyActions";
import { updateSurvey } from "../../store/actions/surveyUpdate";
import { surveyStats } from "../../store/actions/surveyStatsAction";
import { simpleAction } from "../../store/actions/simpleAction";
import { surveyTestsDisplay } from "../../store/actions/surveyTestsDisplayAction";
import firebase from "../../store/firebase";
import { connect } from "react-redux";
import {
  SURVEY_LIST,
  UPDATE_SURVEY,
  SURVEY_STATS_LIST,
  SIMPLE_VALUES,
  SURVEY_TESTS_LIST
} from "../../store/actions/types";
import { array } from "prop-types";
import survey from './img/surveypic.jpg';
import './sidebar.css'; 
import { blue } from "@material-ui/core/colors";


const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: "#fff"
};
const sendBtn2 = {
  backgroundColor: RED,
  color: "#fff"
};
const table = {
    color: "#515A5A",
    fontSize: 14,
    border: "0px",
    //border: "1px solid #CAC9C8",
    backgroundColor: '#F2F3F4',
    padding: 1,
    borderRadius: 2
}
const tableh = {
  color: "#f2f3f3",
  fontSize: 14,
  //border: "1px solid #707B7C",
  border: "0px",
  backgroundColor: '#acb3b9',
  padding: 2,
  borderRadius: 2
}
const tableM = {
  color: "#515A5A",
  fontSize: 18,
  //border: "1px solid #707B7C",
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}

const styles = {
  mlist: {
    width: 250,
    // backgroundColor: 'red',
  },
  survs: {
    width: 1500,
    // backgroundColor: 'blue',
  },
  row2: {
//     backgroundColor: 'green',
  },
  col1: {
    backgroundColor: 'yellow',
  },
  col2: {
    backgroundColor: 'brown',
  },
  col: {
    padding: '1rem',
    backgroundColor: '#33b5e5',
    border: '2px solid #fff',
    color: '#fff',
    textAlign: 'center'
    }
};

// class Surveys extends React.Component {
//   constructor (props) {
//      super(props);
//   }

//   state = {
//     surveysLoaded: this.props.update_Survey,
//     showPic: true,
//     groupname: "",
//     groupid: "",
//     defaultgroup: "Select Group",
//     gr: ""
//    // surveylist: ""
//   }


//   componentDidMount() {
//     // console.log('blue props userID: ', this.props.userdata.userID);

//     // var database = firebase.database;
//     // database
//     //   .ref("company_user_groups/" + this.props.userdata.userID)
//     //   .once("value")
//     //   .then(snapshot => {
//     //     const groupsArr = [];

//     //     snapshot.forEach(childSnapshot => {
//     //       groupsArr.push({
//     //         id: childSnapshot.key,
//     //         group_id: childSnapshot.val().group_id,
//     //         group_name: childSnapshot.val().group_name
//     //       });
//     //     });
//     //     this.setState({ gr: groupsArr });
//     //   });
//   }

//   functionTwo = (var1, callback) => {
//     callback(var1);
//     console.log('inside callback 2: ', this.props.update_Survey);   
//    }

//     objsize = (Myobj) => {
//       var osize = 0, key;
//       for (key in Myobj) {
//           if (Myobj.hasOwnProperty(key)) osize++;
//       }
//       return osize;
//     };

//     getKeys(object) {
//       function iter(o, p) {
//           if (Array.isArray(o)) { return; }
//           if (o && typeof o === 'object') {
//               var keys = Object.keys(o);
//               if (keys.length) {
//                   keys.forEach(function (k) { iter(o[k], p.concat(k)); });
//               }
//               return;
//           }
//           result.push(p.join('.'));
//       }
//       var result = [];
//       iter(object, []);
//       return result;
//   }

//   hidePic = (cond) => {
//     if (cond) {
//       this.setState({showPic:true});
//     }
//     else {
//      this.setState({showPic:false});
//     }
//   }

//   getS = (surid, groupid) => {
//     console.log('inside getS');
//     var body = {
//       type: SURVEY_STATS_LIST,
//       companyID: this.props.userdata.companyID,
//       surveyid: surid, 
//       groupID: groupid //this.state.mgroup.id
//       }

//    this.props.surveyStats(body); 

//  }

//   render(){
//   const { update_Survey, surveyStats, survey_stats_list, surveyTestsDisplay, survey_tests_list, simpleAction } = this.props;
//   return (
//     <Router>
//       <div className="sidebar">
//       <Container>
//         <Row>
//           <Col sm={2}>
//             <Row style={tableh}><b>
//             <OldSchoolMenuLink 
//               to="CreateaSurvey" 
//               label="Make survey" 
//             />
//             </b></Row>
//             {/* <Row style={{border: "1px solid rgb(194, 36, 7)"}}> */}
//             <Row style={tableh}>
//           <div>

//          <React.Fragment>
       
//         {/* SURVEY LINKS 1 */}

//         { update_Survey && Object.keys(update_Survey).map((QandA, id) => {
//               return (            
//                     <OldSchoolMenuLink
//                       key={update_Survey[QandA].surveyid.toString()}
//                       activeOnlyWhenExact={true}
//                       to={update_Survey[QandA].surveyid}
//                       label={update_Survey[QandA].title}
//                   />
//                  );
//               }) 
//             }

//            </React.Fragment>

//           </div>
//           </Row>
//           {/* <Row><b>STATS</b></Row> */}
//           {/* <Row style={{border: "1px solid rgb(194, 36, 7)"}} > */}
//           <Row style={tableh}>
//           <Col sm={8}>
//           <FormControl style={{minWidth: 100, maxWidth: 120}}> 
//           {/* <InputLabel shrink htmlFor="age-native-label-placeholder">
//             Enter Relevant Group
//           </InputLabel> */}
//           <Select
//               label="Group"
//               onChange={event => {
//                 const { value } = event.target;
//                 this.setState({groupname: value, groupid: value.id});
//                 console.log("SURVEY_STATS_LIST companyID: ", this.props.userdata.companyID);
//                 console.log("SURVEY_STATS_LIST surveyid: ", this.props.simplething);
//                 console.log("SURVEY_STATS_LIST groupID: ", value.id);

//                 var body;
//                // update_Survey && Object.keys(update_Survey).map((QandA, id) => 
//                 body = {
//                   type: SURVEY_STATS_LIST,
//                   companyID: this.props.userdata.companyID,
//                   surveyid: this.props.simplething, //this.props.surveyid, 
//                   groupID: value.id //this.state.mgroup.id
//                   }
//                 this.props.surveyStats(body)
//                // )
//               }}

//                // console.log('groupname: ', this.state.groupname);
//                 // var body = {
//                 //   type:
//                 //   groupID: value      
//                 //   }
//                 // this.props.simpleAction(body);  

//               value={this.state.groupname}
//               displayEmpty
//               input={<Input name="survey" id="survey" value={this.state.groupname} />}
//             >
//               <MenuItem value={this.state.groupname}>
//                 <em>{this.state.defaultgroup}</em>
//               </MenuItem>

//               {this.state.gr
//                   ? this.state.gr.map(item => {
//                     return (
//                       <MenuItem value={item}>{item.group_name}</MenuItem>
//                     );
//                   })
//                   : null}
//             </Select>
//             </FormControl>
//           </Col>
//           </Row>
//           {/* <Row style={{border: "1px solid rgb(100, 68, 64)"}} ><b> */}
//           <Row style={tableh}><b>
//             {/* <OldSchoolMenuLink 
//               to="SurveyAllStats" 
//               label="Survey Stats" 
//             /> */}
//           {/* SURVEY STATS LINKS 1 */}
//           { update_Survey && Object.keys(update_Survey).map((QandA, id) => {
//                 return (            
//                       <OldSchoolMenuLink
//                         key={update_Survey[QandA].surveyid.toString()}
//                         activeOnlyWhenExact={true}
//                         to={'x'+update_Survey[QandA].surveyid}
//                         label={update_Survey[QandA].title}
//                     />
//                     );
//                   }) 
//                 }

//             </b></Row>
//           </Col>

//           <Col style={styles.survs}>
//             <Switch>
//               <Route exact path="/createaSurvey">
//                 <CreateaSurvey letgoPic={this.hidePic} key={this.props} props={this.props}/>
//               </Route>

//               {/* SURVEY STATS LINKS 2 */}
//               {update_Survey && Object.keys(update_Survey).map((QandA, id) => {
//                 const magicpath = "/x"+update_Survey[QandA].surveyid;
//                 console.log('this.props.userdata: ', this.props.userdata);

//                 // var body = {
//                 //   type: SIMPLE_VALUES,
//                 //   surveyid: update_Survey[QandA].surveyid 
//                 // }

//                 return (
//                   <Route exact path={magicpath}>
//                    <SurveyAllStats
//                       key={update_Survey[QandA].surveyid}
//                       surveyid={update_Survey[QandA].surveyid} 
//                       surveyType={update_Survey[QandA].type}
//                       letgoPic={this.hidePic} 
//                       surveyStats={surveyStats} 
//                       simpleAction={simpleAction}
//                       surveyTestsDisplay={surveyTestsDisplay}
//                       survey_stats_list={survey_stats_list} 
//                       survey_tests_list={this.props.survey_tests_list}
//                       companyID={this.props.userdata.companyID} 
//                       //groupID={this.props.rgroupid}
//                       groupidx={this.state.groupname}
//                       groupid={this.state.groupid}
//                       //getS={this.getS}
//                       // onClick={event => {
//                       //   console.log('BANG!', event)
//                       // }}
//                       //onClick={() => {this.props.simpleAction(body)}}
//                    />  
//                    </Route>            
//                     );
//                   }) 
//                 }                                                                                                          
//             {/* SURVEY LINKS 2 */}
//             { update_Survey && Object.keys(update_Survey).map((QandA, id) => {
//              const magicpath = "/"+update_Survey[QandA].surveyid;

//               return (
//                 <Route exact path={magicpath}>
//                   <div>
//                     <h2>{update_Survey[QandA].title}</h2>
//                     <Survey 
//                       letgoPic={this.hidePic} 
//                       personid={this.props.userdata.userID} 
//                       key={update_Survey[QandA].surveyid} 
//                       companyID={this.props.userdata.companyID} 
//                       surveyid={update_Survey[QandA].surveyid} 
//                       typeofSurvey={update_Survey[QandA].type} 
//                       size={update_Survey[QandA].size}
//                     />
//                   </div>
//                 </Route>                    
//                   );
//                 }) 
//               }                                          
//             </Switch>
//           </Col>
//          { this.state.showPic===true? <div className="howpic"><Col><div align="left"><img width="700px" height={500} alt="" src={survey} /></div></Col></div> 
//           :null
//          } 
//         </Row>
//       </Container>
//       </div>
//     </Router>
//   );
//  }
// }

function OldSchoolMenuLink({ label, to, activeOnlyWhenExact }) {
  var tutu="/"+to;
  let match = useRouteMatch({
    path: tutu,
    exact: activeOnlyWhenExact
  });

  return (
    <div className={match ? "active" : ""}>
      {match && ">> "}
      <Link to={tutu}>{label}</Link>
    </div>
  );
}

function handleChange() {
  console.log('in select function:');
}
function swapStuff(str) {
  var c, cc;
    c = str.replace(/\./g, (i => _ => ' ?'[i = 1 - i])(1));
    cc = c.replace(/\,/g, (i => _ => ' ?'[i = 1 - i])(1));
    cc = cc.replace(/\’/g, (i => _ => '?'[i = 1 - i])(1));
    cc = cc.replace(/\?/g, (i => _ => ' ?'[i = 1 - i])(1));
    
   // cc = cc.replace(/.$/," ");

  return cc;
 }

// class CreateaSurvey extends React.Component {
//     constructor (props) {   
//       super(props);
//       this.props.letgoPic(false);
//     }

//     state = {
//       stitle: '',
//       companyid: this.props.props.userdata.companyID,
//       Qnumber: 0,
//       sQuestion: '',
//       sAnswer1: '',
//       sAnswer2: '',
//       sAnswer3: '',
//       sAnswer4: '',
//       sAnswer5: '',
//       QandA1: [],
//       QandA2: [],
//       QandA3: [],
//       QandA4: [],
//       QandA5: [],
//       QandA6: [],
//       QandA7: [],
//       QandA8: [],
//       QandA9: [],
//       QandA10: [],
//       QandA11: [],
//       QandA12: [],
//       typeofsurvey: "",
//       defaultsurvey: "Select Survey type",
//       focus: true
//     }

//   selectSurveyType = (event, name) => {
//     //this.setState({ typeofsurvey: name.props.value });
//     //this.setState({ typeofsurvey: typeofsurvey[0].value })
//   }
 
//   stop = (nrIn, val) => {
//     var body = {
//       type: SURVEY_LIST,
//       title: this.state.stitle,
//       companyID: this.state.companyid,
//       typeofSurvey: this.state.typeofsurvey,
//       size: this.state.Qnumber,
//       QandA1: (nrIn===1 ? val:this.state.QandA1),
//       QandA2: (nrIn===2 ? val:this.state.QandA2),
//       QandA3: (nrIn===3 ? val:this.state.QandA3),
//       QandA4: (nrIn===4 ? val:this.state.QandA4),
//       QandA5: (nrIn===5 ? val:this.state.QandA5),
//       QandA6: (nrIn===6 ? val:this.state.QandA6),
//       QandA7: (nrIn===7 ? val:this.state.QandA7),
//       QandA8: (nrIn===8 ? val:this.state.QandA8),
//       QandA9: (nrIn===9 ? val:this.state.QandA9),
//       QandA10: (nrIn===10 ? val:this.state.QandA10),
//       QandA11: (nrIn===11 ? val:this.state.QandA11),
//       QandA12: (nrIn===12 ? val:this.state.QandA12)
//     };
//     console.log('blue stop: ', body);
    
//     this.props.props.createSurvey(body);

//     this.setState({stitle: "", typeofsurvey: 0, Qnumber: 1000});
//     this.props.letgoPic(true);
//   }

//   submitPost = (desc) => {
//     console.log('in submitPost');
//     var arrAnswer1 = [];
//     var arrAnswer2 = [];
//     var arrAnswer3 = [];
//     var arrAnswer4 = [];
//     var arrAnswer5 = [];
//     var arrAnswer6 = []; 
//     var arrAnswer7 = [];
//     var arrAnswer8 = [];
//     var arrAnswer9 = [];
//     var arrAnswer10 = [];
//     var arrAnswer11 = [];
//     var arrAnswer12 = [];
//     var arrQandA = [];

//    console.log('submitPost props: ',  this.props);
//    var qq = this.state.Qnumber + 1;

//    this.setState({Qnumber: qq});
//    var arrQandA = []; //{}; //new Array();
//    var arrAnswer = []; //{};
//    var Qnum = this.state.Qnumber;
//    var lastQ;

//    //var Qlimit = this.state.Qnumber;

//   //  if (desc === 2) {
//   //    Qnum = 12

//   //   }

//    switch (Qnum) {
//     case 1000:
//       console.log('inside case 1000');
//       break;
//     case 0: // survey basic details
//       this.setState({
//         title: this.state.stitle,
//         companyID: this.state.companyid 
//       })
//       break;
//     case 1: // build arrays for all other cases
//     arrAnswer1 = {
//       "question": this.state.sQuestion,
//       "a1": this.state.sAnswer1,
//       "a2": this.state.sAnswer2,
//       "a3": this.state.sAnswer3,
//       "a4": this.state.sAnswer4,
//       "a5": this.state.sAnswer5
//     };
//     this.setState({QandA1: arrAnswer1});
//     if ( desc === 2 && Qnum === 1 ) {
//       this.stop(1, arrAnswer1)
//     }
//       break;
//     case 2: // build arrays for all other cases
//     arrAnswer2 = {
//       "question": this.state.sQuestion,
//       "a1": this.state.sAnswer1,
//       "a2": this.state.sAnswer2,
//       "a3": this.state.sAnswer3,
//       "a4": this.state.sAnswer4,
//       "a5": this.state.sAnswer5
//     };
//     this.setState({QandA2: arrAnswer2});
//     if ( desc === 2 && Qnum === 2 ) {
//       this.stop(2, arrAnswer2)
//     }
//       break;
//     case 3: // build arrays for all other cases
//     arrAnswer3 = {
//       "question": this.state.sQuestion,
//       "a1": this.state.sAnswer1,
//       "a2": this.state.sAnswer2,
//       "a3": this.state.sAnswer3,
//       "a4": this.state.sAnswer4,
//       "a5": this.state.sAnswer5
//     };
//     if ( desc === 2 && Qnum === 3 ) {
//       this.stop(3, arrAnswer3)
//     }
//     this.setState({QandA3: arrAnswer3});
//       break; 
//     case 4: // build arrays for all other cases
//     arrAnswer4 = {
//       "question": this.state.sQuestion,
//       "a1": this.state.sAnswer1,
//       "a2": this.state.sAnswer2,
//       "a3": this.state.sAnswer3,
//       "a4": this.state.sAnswer4,
//       "a5": this.state.sAnswer5
//     };
//     this.setState({QandA4: arrAnswer4});
//     if ( desc === 2 && Qnum === 4 ) {
//       this.stop(4, arrAnswer4)
//     }
//       break;  
//    case 5: // build arrays for all other cases
//     arrAnswer5 = {
//       "question": this.state.sQuestion,
//       "a1": this.state.sAnswer1,
//       "a2": this.state.sAnswer2,
//       "a3": this.state.sAnswer3,
//       "a4": this.state.sAnswer4,
//       "a5": this.state.sAnswer5
//     };
//     this.setState({QandA5: arrAnswer5});
//     if ( desc === 2 && Qnum === 5 ) {
//       this.stop(5, arrAnswer5)
//     }
//       break;    
//    case 6: // build arrays for all other cases
//       arrAnswer6 = {
//         "question": this.state.sQuestion,
//         "a1": this.state.sAnswer1,
//         "a2": this.state.sAnswer2,
//         "a3": this.state.sAnswer3,
//         "a4": this.state.sAnswer4,
//         "a5": this.state.sAnswer5
//       };
//       this.setState({QandA6: arrAnswer6});
//       if ( desc === 2 && Qnum === 6 ) {
//         this.stop(6, arrAnswer6)
//       }
//         break;      
//    case 7: // build arrays for all other cases
//         arrAnswer7 = {
//           "question": this.state.sQuestion,
//           "a1": this.state.sAnswer1,
//           "a2": this.state.sAnswer2,
//           "a3": this.state.sAnswer3,
//           "a4": this.state.sAnswer4,
//           "a5": this.state.sAnswer5
//         };
//         this.setState({QandA7: arrAnswer7});
//         if ( desc === 2 && Qnum === 7 ) {
//           this.stop(7, arrAnswer7)
//         }
//           break;       
//    case 8: // build arrays for all other cases
//           arrAnswer8 = {
//             "question": this.state.sQuestion,
//             "a1": this.state.sAnswer1,
//             "a2": this.state.sAnswer2,
//             "a3": this.state.sAnswer3,
//             "a4": this.state.sAnswer4,
//             "a5": this.state.sAnswer5
//           };
//           this.setState({QandA8: arrAnswer8});
//           if ( desc === 2 && Qnum === 8 ) {
//             this.stop(8, arrAnswer8)
//           }
//             break; 
//    case 9: // build arrays for all other cases
//           arrAnswer9 = {
//             "question": this.state.sQuestion,
//             "a1": this.state.sAnswer1,
//             "a2": this.state.sAnswer2,
//             "a3": this.state.sAnswer3,
//             "a4": this.state.sAnswer4,
//             "a5": this.state.sAnswer5
//           };
//           this.setState({QandA9: arrAnswer9});
//           if ( desc === 2 && Qnum === 9 ) {
//             this.stop(9, arrAnswer9)
//           }
//             break; 
//     case 10: // build arrays for all other cases
//           arrAnswer10 = {
//             "question": this.state.sQuestion,
//             "a1": this.state.sAnswer1,
//             "a2": this.state.sAnswer2,
//             "a3": this.state.sAnswer3,
//             "a4": this.state.sAnswer4,
//             "a5": this.state.sAnswer5
//           };
//           this.setState({QandA10: arrAnswer10});
//           if ( desc === 2 && Qnum === 10 ) {
//             this.stop(10, arrAnswer10)
//           }
//             break; 
//      case 11: // build arrays for all other cases
//             arrAnswer11 = {
//               "question": this.state.sQuestion,
//               "a1": this.state.sAnswer1,
//               "a2": this.state.sAnswer2,
//               "a3": this.state.sAnswer3,
//               "a4": this.state.sAnswer4,
//               "a5": this.state.sAnswer5
//             };
//             this.setState({QandA11: arrAnswer11});
//             if ( desc === 2 && Qnum === 11 ) {
//               this.stop(11, arrAnswer11)
//             }
//               break;              
//     case 12: // last question, submit all 
//       arrAnswer12 = {
//         "question": this.state.sQuestion,
//         "a1": this.state.sAnswer1,
//         "a2": this.state.sAnswer2,
//         "a3": this.state.sAnswer3,
//         "a4": this.state.sAnswer4,
//         "a5": this.state.sAnswer5
//       };
//       this.setState({QandA12: arrAnswer12});
//       if ( desc === 2 && Qnum === 12 ) {
//         this.stop(12, arrAnswer12)
//       }
//       // lastQ = "QandA" + this.state.Qnumber
//       // this.setState({[lastQ]: arrAnswer12});
//       // if (this.state.Qnumber != 12) {arrAnswer12=[]}

//       // var body = {
//       //   type: SURVEY_LIST,
//       //   title: this.state.stitle,
//       //   companyID: this.state.companyid,
//       //   typeofSurvey: this.state.typeofsurvey,
//       //   size: this.state.Qnumber,
//       //   QandA1: this.state.QandA1,
//       //   QandA2: this.state.QandA2,
//       //   QandA3: this.state.QandA3,
//       //   QandA4: this.state.QandA4,
//       //   QandA5: this.state.QandA5,
//       //   QandA6: this.state.QandA6,
//       //   QandA7: this.state.QandA7,
//       //   QandA8: this.state.QandA8,
//       //   QandA9: this.state.QandA9,
//       //   QandA10: this.state.QandA10,
//       //   QandA11: this.state.QandA11,
//       //   QandA12: arrAnswer12
//       // };

//       // console.log('body: ', body);
//       // this.props.props.createSurvey(body);

//       // this.setState({stitle: "", typeofsurvey: 0, Qnumber: 1000});
//       // this.props.letgoPic(true);
//       break;

//     default: // build arrays for all other cases
//    }
//    this.setState({
//     sQuestion: '',
//     sAnswer1: '',
//     sAnswer2: '',
//     sAnswer3: '',
//     sAnswer4: '',
//     sAnswer5: '',
//    })
//   }

//   componentDidMount() {
//    this.setState({defaultsurvey: "Select Survey Type"});
//   }

//   render() {
//     console.log('CreateaSurvey props:', this.props);

//   return (
//     <div className="bod">
//       <Container>
//       { this.state.Qnumber===0 ?
//       <React.Fragment>
//       <h2>New Survey</h2> 
//       <Row>
//         <Col sm={12}>
//           <TextField
//                 autoFocus
//                 id="title"
//                 label="Enter Title"
//                 multiline
//                 fullWidth
//                 margin="normal"
//                 InputLabelProps={{
//                   shrink: true
//                 }}
//                 inputProps={{ maxLength: 200 }}
//                 aria-describedby="surveyTitle"
//                 onChange={event => {
//                   const { value } = event.target;
//                   this.setState({stitle: swapStuff(value)})
//                 }}
//                 value={swapStuff(this.state.stitle)}
//               />
//         </Col>
//       </Row>

//       <Row>
//         <Col sm={12}>
//         <FormControl style={{minWidth: 160}}> 
//         <InputLabel shrink htmlFor="age-native-label-placeholder">
//           Enter Type of Survey
//         </InputLabel>
//          <Select
//             label="Type of Survey"
//             onChange={event => {
//               const { value } = event.target;
//               this.setState({typeofsurvey: value})
//               console.log('typeofsurvey: ', this.state.typeofsurvey);
//             }}
//             value={this.state.typeofsurvey}
//             displayEmpty
//             input={<Input name="survey" id="survey" value={this.state.typeofsurvey} />}
//           >
//             <MenuItem value={this.state.typeofsurvey}>
//               <em>{this.state.defaultsurvey}</em>
//             </MenuItem>
//             <MenuItem value='P'>
//               <em>Poll</em>
//             </MenuItem>
//             <MenuItem value='T'>
//               Test
//             </MenuItem>
//           </Select>
//           </FormControl>
//         </Col>
//         </Row>
//         </React.Fragment> 
//         :
//          this.state.Qnumber < 13 ?
//         <React.Fragment> 
//         <h2>Survey Builder</h2> 
//         <Row>
//         <Col sm={12}>
//           <TextField
//               autoFocus={this.state.focus}
//               id="sQuestion"
//               label={"Enter Question "+this.state.Qnumber}
//               multiline
//               fullWidth
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true
//               }}
//               inputProps={{ maxLength: 200 }}
//               aria-describedby="surveyTitle"
//               onChange={event => {
//                 const { value } = event.target;
//                 this.setState({sQuestion: swapStuff(value)})
//               }}
//               value={swapStuff(this.state.sQuestion)}
//             />
//         </Col>
//       </Row>  
//        <Row>
//        </Row>
//        <Row>
//         <Col sm={10}>
//           <TextField
//               // autoFocus
//               id="sAnswer1"
//               label="Enter Answer 1"
//               multiline
//               fullWidth
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true
//               }}
//               inputProps={{ maxLength: 200 }}
//               aria-describedby="answer"
//               onChange={event => {
//                 const { value } = event.target;
//                 this.setState({sAnswer1: swapStuff(value)})
//               }}
//               value={swapStuff(this.state.sAnswer1)}
//             />
//         </Col>
//       </Row> 
//       <Row>
//         <Col sm={10}>
//           <TextField
//               // autoFocus
//               id="sAnswer2"
//               label="Enter Answer 2"
//               multiline
//               fullWidth
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true
//               }}
//               inputProps={{ maxLength: 200 }}
//               aria-describedby="answer"
//               onChange={event => {
//                 const { value } = event.target;
//                 this.setState({sAnswer2: swapStuff(value)})
//               }}
//               value={swapStuff(this.state.sAnswer2)}
//             />
//         </Col>
//       </Row> 
//       <Row>
//         <Col sm={10}>
//           <TextField
//               // autoFocus
//               id="sAnswer3"
//               label="Enter Answer 3"
//               multiline
//               fullWidth
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true
//               }}
//               inputProps={{ maxLength: 200 }}
//               aria-describedby="answer"
//               onChange={event => {
//                 const { value } = event.target;
//                 this.setState({sAnswer3: swapStuff(value)})
//               }}
//               value={swapStuff(this.state.sAnswer3)}
//             />
//         </Col>
//       </Row> 
//       <Row>
//         <Col sm={10}>
//           <TextField
//               // autoFocus
//               id="sAnswer4"
//               label="Enter Answer 4"
//               multiline
//               fullWidth
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true
//               }}
//               inputProps={{ maxLength: 200 }}
//               aria-describedby="answer"
//               onChange={event => {
//                 const { value } = event.target;
//                 this.setState({sAnswer4: swapStuff(value)})
//               }}
//               value={swapStuff(this.state.sAnswer4)}
//             />
//         </Col>
//       </Row> 

//   { this.state.typeofsurvey==="T"?
//       <Row>
//         <Col sm={10}>
//           <TextField
//               // autoFocus
//               id="sAnswer5"
//               label="Enter Correct Answer 5"
//               multiline
//               fullWidth
//               margin="normal"
//               InputLabelProps={{
//                 shrink: true
//               }}
//               inputProps={{ maxLength: 200 }}
//               aria-describedby="answer"
//               onChange={event => {
//                 const { value } = event.target;
//                 this.setState({sAnswer5: swapStuff(value)})
//               }}
//               value={swapStuff(this.state.sAnswer5)}
//             />
//         </Col>
//       </Row> 
//      :
//      <Row>
//      <Col sm={10}>
//      <input type="hidden"
//            id="sAnswer5"
//            aria-describedby="answer"
//            value="no answer"
//          />
//      </Col>
//    </Row> 
//      }
//       </React.Fragment> 
//        :null
//         // OR 
//         // PUT IN SOME NICE MESSAGE
//         }
//         { this.state.Qnumber < 13 ?
//         <Row>
//          <Col sm={8}></Col>
//         <Col sm={8}>
//         <FormControl>
         
//           <DialogActions>
//             {this.state.Qnumber < 12? 
//               <Button
//                 variant="contained"
//                 onClick={() => this.submitPost(1)}
//                 style={sendBtn}
//               >
//                 <SendIcon/>
//                 CONTINUE
//               </Button>
//              :null}
//              {this.state.Qnumber > 0?
//               <Button
//                 variant="contained"
//                 onClick={() => this.submitPost(2)}
//                 style={sendBtn2}
//               >
//                 <SendIcon/>
//                 END
//               </Button>
//               :null}
//             </DialogActions>
//           </FormControl>
//         </Col>
//       </Row>
//       :null}
//     </Container>
//     </div>
//   );
//   }
//  }

 class SurveyAllStats extends React.Component {
  constructor (props) {
     super(props);
    // this.props.letgoPic(false);
  }

  state = {
    showPic: false,
    mgroupid: this.props.groupID,
    mgroupname: this.props.title,
  }

  trimStripe(strIn){
    var str = strIn;
   return strIn;
  }

  componentDidMount() {
    console.log('blue surveyType: ', this.props);
    this.getS();
   }

    getS = () => {
      var booty = {
        type: SURVEY_TESTS_LIST,
        companyID: this.props.companyID,
        surveyid: this.props.surveyid, 
        groupID: this.props.groupID, //this.state.mgroup.id
        surveyTitle: this.props.surveyTitle,
        personid: this.props.personid,
        size: this.props.size
        }
        console.log('black test tests 1: ', booty);
        this.props.surveyTestsDisplay(booty); 

      var body = {
        type: SURVEY_STATS_LIST,
        companyID: this.props.companyID,
        surveyid: this.props.surveyid, 
        groupID: this.props.groupID, //this.state.mgroup.id
        personid: this.props.personid,
        size: this.props.size
        }
        console.log('black test tests 2: ', body);
        this.props.surveyStats(body); 

      var body = {
        type: SIMPLE_VALUES,
        surveyid: this.props.surveyid      
        }
      this.props.simpleAction(body);

      console.log('black test body: ', body);
      console.log('black props: ', this.props);
   }

   truncate(str){
    return str.length > 10 ? str.substring(0, 7) + "..." : str;
   } 

   componentWillReceiveProps(nextProps) {
    this.setState({ 
      mgroup: nextProps.groupidx
     }); 
   }

  render(){
    const { survey_stats_list, survey_tests_list } = this.props;
    console.log('orange survey_stats_list1: ', survey_stats_list);
    console.log('orange survey_tests_list2: ', survey_tests_list);

    const getPersentageScore = (score, qlen) => {
      console.log('inside getPersentageScore');
      var x = (score/qlen)*100;
      console.log('purple x: ', x);

      var y;
      if (x!=undefined) {
        y = x;
      }
      else {
        y = 0;
      }
      return y.toFixed(1)+'%';
     }
    var questionArr = [];
    
    return (
             <React.Fragment>         
                  <MDBContainer>           
                      <MDBRow> 
                        <MDBCol style={tableM} >
                         <b>{ this.props.groupName }</b>
                        </MDBCol>                        
                    </MDBRow>  
                   </MDBContainer>     
                   
                    {survey_stats_list && survey_stats_list.Answer1 && Object.keys(survey_stats_list.Answer1).map(function (template_name, i) {
                   
                     return (                                                     
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh} ><b>Q: {survey_stats_list.Answer1.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table} >
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table} >
                            {survey_stats_list.Answer1[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>  
                              
                        );
                       }
                     )}
                    
                  {survey_stats_list && survey_stats_list.Answer2 && Object.keys(survey_stats_list.Answer2).map(function (template_name, i) {
                     return (
                       
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer2.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer2[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>           
                      );
                     }
                    )}
                  {survey_stats_list && survey_stats_list.Answer3 && Object.keys(survey_stats_list.Answer3).map(function (template_name, i) {
                     return (
                        
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer3.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer3[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer> 
                                   
                      );
                     }
                    )}
                  {survey_stats_list && survey_stats_list.Answer4 && Object.keys(survey_stats_list.Answer4).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer4.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer4[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )}                    
                  {survey_stats_list && survey_stats_list.Answer5 && Object.keys(survey_stats_list.Answer5).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer5.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer5[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )}  
                  {survey_stats_list && survey_stats_list.Answer6 && Object.keys(survey_stats_list.Answer6).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer6.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer6[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )}   
                {survey_stats_list && survey_stats_list.Answer7 && Object.keys(survey_stats_list.Answer7).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer7.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer7[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )}  
                {survey_stats_list && survey_stats_list.Answer8 && Object.keys(survey_stats_list.Answer8).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer8.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer8[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )}   
                   {survey_stats_list && survey_stats_list.Answer9 && Object.keys(survey_stats_list.Answer9).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer9.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer9[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )} 
               {survey_stats_list && survey_stats_list.Answer10 && Object.keys(survey_stats_list.Answer10).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer10.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer10[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )}   
                   {survey_stats_list && survey_stats_list.Answer11 && Object.keys(survey_stats_list.Answer11).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer11.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer11[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )} 
                  {survey_stats_list && survey_stats_list.Answer12 && Object.keys(survey_stats_list.Answer12).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                    
                      { i===0?<MDBRow><MDBCol style={tableh}><b>Q: {survey_stats_list.Answer12.thequestion}</b></MDBCol></MDBRow>:null}
                       { template_name !== 'thequestion' ?
                        <MDBRow> 
                          <MDBCol style={table}>
                             <i>{template_name}</i>
                          </MDBCol>
                          <MDBCol style={table}>
                            {survey_stats_list.Answer12[template_name]}
                          </MDBCol>                        
                      </MDBRow>  
                         :null}
                     
                     </MDBContainer>          
                      );
                     }
                    )}  

            {this.props.surveyType==="T" ? <div>
                 <MDBContainer>           
                      <MDBRow> 
                        <MDBCol style={tableM} >
                          Test Resulsts
                        </MDBCol>                        
                    </MDBRow>  
                   </MDBContainer>     
                   
                  {survey_tests_list && survey_tests_list && Object.keys(survey_tests_list).map(function (template_name, i) {
                     return (
                      <MDBContainer key={i}> 
                      <MDBRow>
                      <MDBCol><b>{survey_tests_list[template_name].personname}</b></MDBCol>
                      <MDBCol>{survey_tests_list[template_name].testscore} Answers Correct</MDBCol>
                      <MDBCol>{getPersentageScore(survey_tests_list[template_name].testscore, survey_tests_list[template_name].qlen)}</MDBCol>
                      
                      </MDBRow> 
                     
                     </MDBContainer>          
                      );
                     }
                    )}
                    </div>:null}                                                                                                                                    
                </React.Fragment> 

    )
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    survey_list: state.surveyList.survey_list,
    update_Survey: state.updateSurvey.update_Survey,
    survey_stats_list: state.surveyStat.survey_stats_list,
    simplething: state.simpleVal.simplething,
    survey_tests_list: state.surveyTes.survey_tests_list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createSurvey: params => dispatch(createSurvey(params)),
    updateSurvey: params => dispatch(updateSurvey(params)),
    surveyStats: params => dispatch(surveyStats(params)),
    simpleAction: params => dispatch(simpleAction(params)),
    surveyTestsDisplay: params => dispatch(surveyTestsDisplay(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyAllStats);


