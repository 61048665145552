/**
	TITLE:
		Loader Actions
	DESCRIPTION:
		Provides methods to interact with the state of Loader found in components/LoaderBar.js
**/

// =========
// LIBS
import { SHOW_LOADER, HIDE_LOADER } from './types'

// =========
// DATA STRUCTS
// ----------
// Struct: Show Loader
const showloader = {
  type: SHOW_LOADER,
  showloader: true
}

// ----------
// Struct: Hide Loader
const hideloader = {
  type: HIDE_LOADER,
  showloader: false
}

// =========
// FUNCTIONS
// ----------
// Function: Show Loader becomes externally visible to be reused in App.js
export const showLoader = (params) => {
  return (dispatch) => {
    dispatch(params.type === SHOW_LOADER ? showloader : hideloader)
  }
}

// ----------
// Function: Hide Loader becomes externally visible to be reused in App.js
export const hideLoader = () => {
  return (dispatch) => {
    dispatch(hideloader)
  }
}

