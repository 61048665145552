/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { getCompanyValues, deleteCompanyValue } from '../../../store/actions/pldpActions'
import {
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions, Grid, Typography, Button, Modal, Dialog,
  DialogActions, DialogContent, DialogTitle
} from '@material-ui/core'
import { Link } from "react-router-dom";

import AddIcon from '@material-ui/icons/Add'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import AccountBalance from '@material-ui/icons/AccountBalance'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { VALUES_NAME } from '../../../store/actions/types'
import { LIGHTGREY, THEMECOLOR } from '../../../utils/color_utils'
// import { RadioGroup, Radio } from 'react-radio-group'
// import Task from './Task'
// import MyTasks from './MyTasks'
// import TasksListView from './TasksListView'
//import { fetchMyPLDPTasks, fetchAllMyPLDPTasks, updateNewPLDPTask, completeTask } from '../../../store/actions/pldpActions'
// import PrintTasks from './PrintTasks';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { addMorePoints } from '../../../store/actions/freshCardActions'
import {
 CgetOpinions
} from '../../../store/actions/opinionCActions'
// import { ThreeSixty } from '@material-ui/icons'
import firebase from '../../../store/firebase'

const tableM = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}
const tableW = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#FFFFFF',
  padding: 1,
  borderRadius: 2
}
const tableMhh = {
  color: "#515A5A",
  fontSize: 12,
  border: "2px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2,
  width: '25%'
}
const tableWh = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#FFFFFF',
  padding: 1,
  borderRadius: 2,
  width: '25%'
}
const tableC = {
  color: "#ffffff",
  fontSize: 12,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#515A5A'
}
const tableMh = {
  color: "#515A5A",
  fontSize: 15,
  border: "2px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}
const tableS = {
  color: "#515A5A",
  fontSize: 10,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}
const tableH = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 2,
  borderRadius: 2
}

Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

// function includedCompetencies(openedTasks, tasks) {
//   let competenciesFound = []
//   Object.keys(tasks).map(id => {
//     if (tasks[id] && (openedTasks === tasks[id].active)) {
//       const a = competenciesFound.indexOf(tasks[id].moveAction)
//       competenciesFound.push(tasks[id].moveAction)
//     }
//   })
//   return competenciesFound
// }

// function TabContainer(props) {
//   return (
//     <Typography component='div' style={{ padding: 0 }}>
//       {props.children}
//     </Typography>
//   )
// }

// function rand() {
//   return Math.round(Math.random() * 20) - 10
// }
// const top = 50 + rand()
// const left = 50 + rand()
// let groupTasks

const styles = theme => ({
  root: {
    marginTop: 0,
    padding: '10px',
    flexGrow: 1
    // [theme.breakpoints.down("xs")]: {
    //   marginTop: "110px"
    // }
  },
  panel: {
    marginBottom: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
    // "&:hover": {
    //   boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)"
    // }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '6px'
  },
  title: {
    borderBottom: `1px solid ${LIGHTGREY}`,
    fontSize: '15px',
    minHeight: '0px',
    width: 'auto',
    // color: '#fff',
    padding: '5px 10px',
    borderRadius: 5
    // backgroundColor: '#0085a5'
  },
  unopendtitle: {
    borderBottom: `1px solid ${LIGHTGREY}`,
    fontSize: '15px',
    minHeight: '0px',
    width: 'auto',
    color: '#e5e4e2',
    padding: '5px 10px',
    borderRadius: 5
    // backgroundColor: '#0085a5'
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    outline: 'none'
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  divStyle: {
    margin: '20px',
    padding: '10px 0',
    border: '1px solid #ccc!important',
    [theme.breakpoints.down('sm')]: {
      margin: '4px',
      padding: '0px 6px'
    }
  },
  dialogHeight: {
    // height: `calc(100% - 20px)`
    height: 'auto'
  },
  unopendList: {
    width: '100%',
    padding: '20px 10px 20px 20px',
    border: '1px solid #e5e4e2',
    margin: '10px',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addIconstyles: {
    backgroundColor: THEMECOLOR,
    color: '#000',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

class Competencies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editText: '',
      editID: '',
      reload: false,
      open: false,
      //openConfirm: false,
      // addTask: false,
      // editTask: false,
      active: false,
      //taskOpened: {},
      companyValue: '',
      editText: '',
      openComplete: 'complete',
      tabsvalue: 0,
      openedTasks: true,
      mainCompetencies: [],
      leadershipTasks: [],
      slave: 0,
      slaveparticipants: 0,

      one: 0,
      oneparticipants: 0,
      qoneopinion: "",

      two: 0,
      twoopinion: "",
      qtwoparticipants: 0,

      three: 0,
      qthreeopinion: "",
      threeparticipants: 0,

      four: 0,
      qfouropinion: "",
      fourparticipants: 0,

      five: 0,
      qfiveopinion: "",
      fiveparticipants: 0,

      six: 0,
      qsixopinion: "",
      sixparticipants: 0,

      seven: 0,
      qsevenopinion: "",
      sevenparticipants: 0,

      eight: 0,
      qeightopinion: "",
      eightparticipants: 0,

      nine: 0,
      qnineopinion: "",
      tenparticipants: 0,

      ten: 0,
      qtenopinion: "",
      tenparticipants: 0,

      one_count: 0,
      two_count: 0,
      three_count: 0,
      four_count: 0,
      five_count: 0,
      six_count: 0,
      seven_count: 0,
      eight_count: 0,
      nine_count: 0,
      ten_count: 0,

      one_arr: [],
      two_arr: [],
      three_arr: [],
      four_arr: [],
      five_arr: [],
      six_arr: [],
      seven_arr: [],
      eight_arr: [],
      nine_arr:  [],
      ten_arr: [], 
      feedbackScore: 0
    }

    if (this.props.userdata.companyID) {
      this.props.CgetOpinions({ //loads the opinions of leaders recorded in state
       leaderID: this.props.userdata.companyID
     })
   } 
  }

  componentDidMount() {
    let { opinions } = this.props
     this.putOpinions();

   if (this.getOpinionCompanyID(opinions)) {} else { 
     console.log('green opinions is zero')
    //  this.props.CgetOpinions({ //loads the opinions of leaders recorded in state
    //   leaderID: this.props.userdata.companyID
    //  })

     //this.setState({ reload: true });
     //window.location.reload();
    }
  }

  componentWillMount() {
    // this.HandleSeparateTasksAndCompetencies()
  }

//   fetchData = () => {
//     this.props.fetchCompanyValues(this.props.userdata.companyID)
//     this.props.fetchMyPLDPTasks(this.props.userdata.userID, this.state.openedTasks)
//     this.props.fetchAllMyPLDPTasks({ userID: this.props.userdata.userID })
//   };

//   handleClose = () => {
//     this.setState({
//       editText: '',
//       editID: '',
//       open: false,
//       openConfirm: false,
//       addTask: false,
//       editTask: false,
//       taskOpened: {},
//       companyValue: '',
//       openComplete: this.state.active ? 'open' : 'complete'

//     })
//   };

//   addAction = () => {
//     this.setState({
//       ...this.state,
//       open: true,
//       addTask: true,
//       editTask: false
//     })
//   }

//   handleOnSaveTask = (newTask, updateOnSave) => {
//     this.props.updateNewPLDPTask(newTask, updateOnSave)

//     this.handleClose()
//   };

//   handleFilteredTasks = (val) => {
//     // this.setState({ filterby: val })
//     // console.log("active ", val)
//     if (val.trim() === 'open') {
//       this.setState({
//         ...this.state,
//         filterby: val,
//         tabsvalue: 0,
//         openedTasks: true
//       })
//       this.props.fetchData(true)
//     } else if (val.trim() === 'complete') {
//       this.setState({
//         ...this.state,
//         filterby: val,
//         tabsvalue: 0,
//         openedTasks: false
//       })
//       this.props.fetchData(false)
//     } else if (val.trim() === 'feedback') {
//       this.setState({
//         ...this.state,
//         filterby: val,
//         tabsvalue: 2,
//         openedTasks: false
//       })
//       this.props.fetchData(false)
//     }
//     else {
//       this.setState({
//         ...this.state,
//         filterby: val,
//         tabsvalue: 1
//       })
//     }
//   }

//   editTask(id, name, task) {
//     this.setState({
//       openConfirm: false,
//       open: true,
//       addTask: false,
//       editTask: true,
//       companyValue: id,
//       taskOpened: task
//     })
//   }

//   onMarkComplete(id, name, task, active) {
//     this.setState({
//       open: false,
//       openConfirm: true,
//       addTask: false,
//       editTask: true,
//       companyValue: id,
//       taskOpened: task,
//       editText: name,
//       active: active,
//       openComplete: active ? 'open' : 'complete'
//     })
//   }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
  }


  putOpinions = () => {
    let { opinions, userdata } = this.props
   //console.log('scores final x ', opinions);

    var slave_count = 0;
    var dbone_count = 0;
    var dbtwo_count = 0;
    var dbthree_count = 0;
    var dbfour_count = 0;
    var dbfive_count = 0;
    var dbsix_count = 0;
    var dbseven_count = 0;
    var dbeight_count = 0;
    var dbnine_count = 0;
    var dbten_count = 0;

    var slave_all_count = 0;
    var one_all_count = 0;
    var two_all_count = 0;
    var three_all_count = 0;
    var four_all_count = 0;
    var five_all_count = 0;
    var six_all_count = 0;
    var seven_all_count = 0;
    var eight_all_count = 0;
    var nine_all_count = 0;
    var ten_all_count = 0;

    var one = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var two = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var three = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var four = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var five = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var six = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var seven = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var eight = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var nine = [0,0,0,0,0,0,0,0,0,0,0];//0;
    var ten = [0,0,0,0,0,0,0,0,0,0,0];//0;

    var one_sub = 0;
    var two_sub = 0;
    var three_sub = 0;
    var four_sub = 0;
    var five_sub = 0;
    var six_sub = 0;
    var seven_sub = 0;
    var eight_sub = 0;
    var nine_sub = 0;
    var ten_sub = 0;

    var one_count = 0;
    var two_count = 0;
    var three_count = 0;
    var four_count = 0;
    var five_count = 0;
    var six_count = 0;
    var seven_count = 0;
    var eight_count = 0;
    var nine_count = 0;
    var ten_count = 0;

    if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
      console.log('inside putOpinions 2', opinions[index]);
      if (opinions[index].slave) { 
        slave_all_count = slave_all_count + 1;
        slave_count = opinions[index].slave 
        console.log('green slave_count : ', slave_count);
        switch(slave_count) {
          case 1:
            //one = one + 1;
            one_count = one_count + 1;
            if (opinions[index].one) { 
              one_sub = one[0]
              one[0] = one_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              one_sub = one[1]
              one[1] = one_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              one_sub = one[2]
              one[2] = one_sub + opinions[index].three 
            }   
            if (opinions[index].four) {
              one_sub = one[3] 
              one[3] = one_sub + opinions[index].four 
            } 
            if (opinions[index].five) {
              one_sub = one[4] 
              one[4] = one_sub + opinions[index].five 
            } 
            if (opinions[index].six) {
              one_sub = one[5] 
              one[5] = one_sub + opinions[index].six 
            } 
            if (opinions[index].seven) {
              one_sub = one[6] 
              one[6] = one_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) {
              one_sub = one[7] 
              one[7] = one_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) {
              one_sub = one[8] 
              one[8] = one_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) {
              one_sub = one[9] 
              one[9] = one_sub + opinions[index].ten 
            } 
            break;
          case 2:
            //two = two + 1;
            two_count = two_count + 1;
            if (opinions[index].one) { 
              two_sub = two[0]
              two[0] = two_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              two_sub = two[1]
              two[1] = two_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              two_sub = two[2]
              two[2] = two_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              two_sub = two[3]
              two[3] = two_sub + opinions[index].four 
            } 
            if (opinions[index].five) { 
              two_sub = two[4]
              two[4] = two_sub + opinions[index].five 
            } 
            if (opinions[index].six) { 
              two_sub = two[5]
              two[5] = two_sub + opinions[index].six 
            } 
            if (opinions[index].seven) { 
              two_sub = two[6]
              two[6] = two_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) { 
              two_sub = two[7]
              two[7] = two_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) { 
              two_sub = two[8]
              two[8] = two_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) { 
              two_sub = two[9]
              two[9] = two_sub + opinions[index].ten 
            } 
            break;
          case 3:
            //three = three + 1;
            three_count = three_count + 1;
            if (opinions[index].one) { 
              three_sub = three[0]
              three[0] = three_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              three_sub = three[1]
              three[1] = three_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              three_sub = three[2]
              three[2] = three_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              three_sub = three[3]
              three[3] = three_sub + opinions[index].four 
            }   
            if (opinions[index].five) { 
              three_sub = three[4]
              three[4] = three_sub + opinions[index].five 
            }  
            if (opinions[index].six) { 
              three_sub = three[5]
              three[5] = three_sub + opinions[index].six 
            }  
            if (opinions[index].seven) { 
              three_sub = three[6]
              three[6] = three_sub + opinions[index].seven 
            }  
            if (opinions[index].eight) { 
              three_sub = three[7]
              three[7] = three_sub + opinions[index].eight 
            }  
            if (opinions[index].nine) { 
              three_sub = three[8]
              three[8] = three_sub + opinions[index].nine 
            }  
            if (opinions[index].ten) { 
              three_sub = three[9]
              three[9] = three_sub + opinions[index].ten 
            }           
            break;
          case 4:
            //four = four + 1;
            four_count = four_count + 1;
            if (opinions[index].one) { 
              four_sub = four[0]
              four[0] = four_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              four_sub = four[1]
              four[1] = four_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              four_sub = four[2]
              four[2] = four_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              four_sub = four[3]
              four[3] = four_sub + opinions[index].four 
            }  
            if (opinions[index].five) { 
              four_sub = four[4]
              four[4] = four_sub + opinions[index].five 
            } 
            if (opinions[index].six) { 
              four_sub = four[5]
              four[5] = four_sub + opinions[index].six 
            } 
            if (opinions[index].seven) { 
              four_sub = four[6]
              four[6] = four_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) { 
              four_sub = four[7]
              four[7] = four_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) { 
              four_sub = four[8]
              four[8] = four_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) { 
              four_sub = four[9]
              four[9] = four_sub + opinions[index].ten 
            } 
            break;
          case 5:
            //five = five + 1;
            five_count = five_count + 1;
            if (opinions[index].one) { 
              five_sub = five[0]
              five[0] = five_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              five_sub = five[1]
              five[1] = five_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              five_sub = five[2]
              five[2] = five_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              five_sub = five[3]
              five[3] = five_sub + opinions[index].four 
            } 
            if (opinions[index].five) { 
              five_sub = five[4]
              five[4] = five_sub + opinions[index].five 
            } 
            if (opinions[index].six) { 
              five_sub = five[5]
              five[5] = five_sub + opinions[index].six 
            } 
            if (opinions[index].seven) { 
              five_sub = five[6]
              five[6] = five_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) { 
              five_sub = five[7]
              five[7] = five_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) { 
              five_sub = five[8]
              five[8] = five_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) { 
              five_sub = five[9]
              five[9] = five_sub + opinions[index].ten 
            } 
            break;
          case 6:
           // six = six + 1;
            six_count = six_count + 1;
            if (opinions[index].one) { 
              six_sub = six[0]
              six[0] = six_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              six_sub = six[1]
              six[1] = six_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              six_sub = six[2]
              six[2] = six_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              six_sub = six[3]
              six[3] = six_sub + opinions[index].four 
            } 
            if (opinions[index].five) { 
              six_sub = six[4]
              six[4] = six_sub + opinions[index].five 
            } 
            if (opinions[index].six) { 
              six_sub = six[5]
              six[5] = six_sub + opinions[index].six 
            } 
            if (opinions[index].seven) { 
              six_sub = six[6]
              six[6] = six_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) { 
              six_sub = six[7]
              six[7] = six_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) { 
              six_sub = six[8]
              six[8] = six_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) { 
              six_sub = six[9]
              six[9] = six_sub + opinions[index].ten 
            } 
            break;
          case 7:
            //seven = seven + 1;
            seven_count = seven_count + 1;
            if (opinions[index].one) { 
              seven_sub = seven[0]
              seven[0] = seven_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              seven_sub = seven[1]
              seven[1] = seven_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              seven_sub = seven[2]
              seven[2] = seven_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              seven_sub = seven[3]
              seven[3] = seven_sub + opinions[index].four 
            } 
            if (opinions[index].five) { 
              seven_sub = seven[4]
              seven[4] = seven_sub + opinions[index].five 
            } 
            if (opinions[index].six) { 
              seven_sub = seven[5]
              seven[5] = seven_sub + opinions[index].six 
            } 
            if (opinions[index].seven) { 
              seven_sub = seven[6]
              seven[6] = seven_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) { 
              seven_sub = seven[7]
              seven[7] = seven_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) { 
              seven_sub = seven[8]
              seven[8] = seven_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) { 
              seven_sub = seven[9]
              seven[9] = seven_sub + opinions[index].ten 
            } 
            break;
          case 8:
           // eight = eight + 1;
            eight_count = eight_count + 1;
            if (opinions[index].one) { 
              eight_sub = eight[0]
              eight[0] = eight_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              eight_sub = eight[1]
              eight[1] = eight_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              eight_sub = eight[2]
              eight[2] = eight_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              eight_sub = eight[3]
              eight[3] = eight_sub + opinions[index].four 
            } 
            if (opinions[index].five) { 
              eight_sub = eight[4]
              eight[4] = eight_sub + opinions[index].five 
            } 
            if (opinions[index].six) { 
              eight_sub = eight[5]
              eight[5] = eight_sub + opinions[index].six 
            } 
            if (opinions[index].seven) { 
              eight_sub = eight[6]
              eight[6] = eight_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) { 
              eight_sub = eight[7]
              eight[7] = eight_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) { 
              eight_sub = eight[8]
              eight[8] = eight_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) { 
              eight_sub = eight[9]
              eight[9] = eight_sub + opinions[index].ten 
            } 
            break;
          case 9:
            //nine = nine + 1;
            nine_count = nine_count + 1;
            if (opinions[index].one) { 
              nine_sub = nine[0]
              nine[0] = nine_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              nine_sub = nine[1]
              nine[1] = nine_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              nine_sub = nine[2]
              nine[2] = nine_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              nine_sub = nine[3]
              nine[3] = nine_sub + opinions[index].four 
            } 
            if (opinions[index].five) { 
              nine_sub = nine[4]
              nine[4] = nine_sub + opinions[index].five 
            } 
            if (opinions[index].six) { 
              nine_sub = nine[5]
              nine[6] = nine_sub + opinions[index].six 
            } 
            if (opinions[index].seven) { 
              nine_sub = nine[6]
              nine[6] = nine_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) { 
              nine_sub = nine[7]
              nine[7] = nine_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) { 
              nine_sub = nine[8]
              nine[8] = nine_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) { 
              nine_sub = nine[9]
              nine[9] = nine_sub + opinions[index].ten 
            } 
            break;
          case 10:
            //ten = ten + 1;
            ten_count = ten_count + 1;
            if (opinions[index].one) { 
              ten_sub = ten[0]
              ten[0] = ten_sub + opinions[index].one 
            }
            if (opinions[index].two) { 
              ten_sub = ten[1]
              ten[1] = ten_sub + opinions[index].two 
            }
            if (opinions[index].three) { 
              ten_sub = ten[2]
              ten[2] = ten_sub + opinions[index].three 
            }   
            if (opinions[index].four) { 
              ten_sub = ten[3]
              ten[3] = ten_sub + opinions[index].four 
            } 
            if (opinions[index].five) { 
              ten_sub = ten[4]
              ten[3] = ten_sub + opinions[index].five 
            } 
            if (opinions[index].six) { 
              ten_sub = ten[5]
              ten[5] = ten_sub + opinions[index].six 
            } 
            if (opinions[index].seven) { 
              ten_sub = ten[6]
              ten[6] = ten_sub + opinions[index].seven 
            } 
            if (opinions[index].eight) { 
              ten_sub = ten[7]
              ten[7] = ten_sub + opinions[index].eight 
            } 
            if (opinions[index].nine) { 
              ten_sub = ten[8]
              ten[8] = ten_sub + opinions[index].nine 
            } 
            if (opinions[index].ten) { 
              ten_sub = ten[9]
              ten[9] = ten_sub + opinions[index].ten 
            } 
            break;
          default:
            // code block
        }
      }
      if (opinions[index].one) { 
        one_all_count = one_all_count + 1;
        dbone_count = dbone_count + opinions[index].one 
      }
      if (opinions[index].two) { 
        two_all_count = two_all_count + 1;
        dbtwo_count = dbtwo_count + opinions[index].two 
      }
      if (opinions[index].three) { 
        three_all_count = three_all_count + 1;
        dbthree_count = dbthree_count + opinions[index].three 
      }   
      if (opinions[index].four) { 
        four_all_count = four_all_count + 1;
        dbfour_count = dbfour_count + opinions[index].four 
      }   
      if (opinions[index].five) { 
        five_all_count = five_all_count + 1;
        dbfive_count = dbfive_count + opinions[index].five 
      }   
      if (opinions[index].six) { 
        six_all_count = six_all_count + 1;
        dbsix_count = dbsix_count + opinions[index].six 
      }   
      if (opinions[index].seven) { 
        seven_all_count = seven_all_count + 1;
        dbseven_count = dbseven_count + opinions[index].seven 
      }   
      if (opinions[index].eight) { 
        eight_all_count = eight_all_count + 1;
        dbeight_count = dbeight_count + opinions[index].eight 
      }   
      if (opinions[index].nine) { 
        nine_all_count = nine_all_count + 1;
        dbnine_count = dbnine_count + opinions[index].nine 
      }   
      if (opinions[index].ten) { 
        ten_all_count = ten_all_count + 1;
        dbten_count = dbten_count + opinions[index].ten 
      }   
    })}
    console.log('green one_count : ', dbone_count)
     //////////////////////////////////////////////////////////////////
    //  var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
    //  var ione = self_all_count > 0 ? self_count / self_all_count : 0;
    //  var iothers = others_all_count > 0 ? others_count / others_all_count : 0;
    //  var iorganisation = org_all_count > 0 ? organisation_count / org_all_count : 0;
    //  var isociety = sos_all_count > 0 ?  society_count / sos_all_count : 0;

     var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
     var ione = one_all_count > 0 ? dbone_count / one_all_count : 0;
     var itwo = two_all_count > 0 ? dbtwo_count / two_all_count : 0;
     var ithree = three_all_count > 0 ? dbthree_count / three_all_count : 0;
     var ifour = four_all_count > 0 ? dbfour_count / four_all_count : 0;
     var ifive = five_all_count > 0 ? dbfive_count / five_all_count : 0;
     var isix = six_all_count > 0 ? dbsix_count / six_all_count : 0;
     var iseven = seven_all_count > 0 ? dbseven_count / seven_all_count : 0;
     var ieight = eight_all_count > 0 ? dbeight_count / eight_all_count : 0;
     var inine = nine_all_count > 0 ? dbnine_count / nine_all_count : 0;
     var iten = ten_all_count > 0 ? dbten_count / ten_all_count : 0;

    //  var iself = self_all_count > 0 ? iself.toFixed(2) : 0;
    //  var iothers = others_all_count > 0 ? iothers.toFixed(2) : 0;
    //  var iorganisation = org_all_count > 0 ? iorganisation.toFixed(2) : 0;
    //  var isociety = sos_all_count > 0 ? isociety.toFixed(2) : 0;
     //////////////////////////////////////////////////////////////////

    if (opinions) {
     this.setState({
      slave: islave.toFixed(2),
      one: ione.toFixed(2),
      two: itwo.toFixed(2),
      three: ithree.toFixed(2),
      four: ifour.toFixed(2),
      five: ifive.toFixed(2),
      six: isix.toFixed(2),
      seven: iseven.toFixed(2),
      eight: ieight.toFixed(2),
      nine: inine.toFixed(2),
      ten: iten.toFixed(2),
      slaveparticipants: slave_all_count,
      oneparticipants: one_all_count,
      twoparticipants: two_all_count,
      threeparticipants: three_all_count,
      fourparticipants: four_all_count,
      fiveparticipants: five_all_count,
      sixparticipants: six_all_count,
      sevenparticipants: seven_all_count,
      eightparticipants: eight_all_count,
      nineparticipants: nine_all_count,
      tenparticipants: ten_all_count,
      one_count: one_count,
      two_count: two_count,
      three_count: three_count,
      four_count: four_count,
      five_count: five_count,
      six_count: six_count,
      seven_count: seven_count,
      eight_count: eight_count,
      nine_count: nine_count,
      ten_count: ten_count,
      one_arr: [one[0],one[1],one[2],one[3],one[4],one[5],one[6],one[7],one[8],one[9]],
      two_arr: [two[0],two[1],two[2],two[3],two[4],two[5],two[6],two[7],two[8],two[9]],
      three_arr: [three[0],three[1],three[2],three[3],three[4],three[5],three[6],three[7],three[8],three[9]],
      four_arr: [four[0],four[1],four[2],four[3],four[4],four[5],four[6],four[7],four[8],four[9]],
      five_arr: [five[0],five[1],five[2],five[3],five[4],five[5],five[6],five[7],five[8],five[9]],
      six_arr: [six[0],six[1],six[2],six[3],six[4],six[5],six[6],six[7],six[8],six[9]],
      seven_arr: [seven[0],seven[1],seven[2],seven[3],seven[4],seven[5],seven[6],seven[7],seven[8],seven[9]],
      eight_arr: [eight[0],eight[1],eight[2],eight[3],eight[4],eight[5],eight[6],eight[7],eight[8],eight[9]],
      nine_arr: [nine[0],nine[1],nine[2],nine[3],nine[4],nine[5],nine[6],nine[7],nine[8],nine[9]],
      ten_arr: [ten[0],ten[1],ten[2],ten[3],ten[4],ten[5],ten[6],ten[7],ten[8],ten[9]]
     })};

     //insertPollCount into user and users
     const updates = {}

     var finalScore = (ione + itwo + ithree + ifour + ifive + isix + iseven + ieight + inine + iten)/10
     //console.log('scores final 1 : ', iself + ' ' + iothers + ' ' + iorganisation + ' ' + isociety)
     console.log('scores final 2 : ', finalScore)
     //finalScore = finalScore.toFixed(2);
     console.log('scores final 3 ', finalScore)

     if(finalScore) {
       this.setState({feedbackScore: finalScore.toFixed(2)});

      updates['/companies/' + userdata.companyID + '/feedback'] = finalScore.toFixed(2);
     }
    // updates['/user/' + this.props.userdata.uid + '/feedback' ] = finalScore.toFixed(2);
 
     // server: create group - send data to firebase
     firebase.database.ref().update(
       updates,
       function (error) {
         if (error) {
           console.log('success : ');
         } else {
          console.log('err : ', error);
         }
       })
  }

  // XinsertPollCount = () => {
  //   const updates = {}
  //   var finalScore = (this.state.self + this.state.others + this.state.organisation)/3
  //    finalScore = parseInt(finalScore);
  //   updates['/users/' + this.props.userdata.userID + '/feedback'] = finalScore;
    
  //   updates['/user/' + this.props.userdata.uid + '/feedback' ] = finalScore;

  //   // server: create group - send data to firebase
  //   firebase.database.ref().update(
  //     updates,
  //     function (error) {
  //       if (error) {
  //         console.log('success : ');
  //       } else {
  //        console.log('err : ', error);
  //       }
  //     })
  // }

   getOpinion = (props) => {
    for (let x in props) {
      return props[x].self
    }
   }

   getOpinionCompanyID(props) {
    for (let x in props) {
      return props[x].companyID
    }
  }

   roundOff = (nr) => {
    var fnr = 0;
    fnr = nr.toFixed(0);
    return fnr;
   }

  render() {
    const { classes, companyValues, userdata, opinions } = this.props
    console.log('green opinions orgStats : ', opinions);
    console.log('green this.state.one_count : ', this.state.one_count);
    console.log('green  this.state.one_arr : ', this.state.one_arr[4]);

    return (
              <div>
              <div>&nbsp; </div>
              <div><b>Feedback Stats and Opinions</b></div>
              <div>
              <MDBContainer>  
                    <MDBRow> 
                    <MDBCol style={tableMh} >
                    <b>Feedbackscore: {this.state.feedbackScore}</b>
                    </MDBCol>          
                  </MDBRow>  
                    <MDBRow> 
                      <MDBCol style={tableC} >
                      <b> Opinion</b>
                      </MDBCol>          
                      <MDBCol style={tableC} >
                      <b> Mean Score</b>
                      </MDBCol> 
                      <MDBCol style={tableC} >
                      <b> Participants</b>
                      </MDBCol> 
                    </MDBRow>  
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                      <b>(1) Real-time:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.one}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.oneparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Real-time: </i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qoneopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol >
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow> 
                      </React.Fragment>    
                     ) 
                   })} 

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(2) Pin-point individualisation:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.two}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.twoparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Pin-point individualisation: </i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qtwoopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                   <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(3) Aligned to organisational values and culture:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.three}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.threeparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on  Aligned to organisational values and culture: </i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qthreeopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(4) Becoming a way of life:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.four}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.fourparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Becoming a way of life</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qfouropinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(5) Viewed as a personal responsibility:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.five}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.fiveparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Viewed as a personal responsibility</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qfiveopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(6) Bold Accountability:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.six}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.sixparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Bold Accountability</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qsixopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(7) Feedback on leadership of managers :</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.seven}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.sevenparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Feedback on leadership of managers </i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qsevenopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(8) Real-time rewards for participation :</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.eight}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.eightparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Real-time rewards for participation </i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qeightopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(9) Access to quality resources:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.nine}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.nineparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Becoming a way of life</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qnineopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b>(10) Direct impact on performance:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.ten}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.tenparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Direct impact on performance</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].qtenopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}
                   
            {/* ////////////////////////////////////////////////////////////////////////// */}

                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                       <b>Participant Categories ({this.state.slaveparticipants})</b>
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       <b>Nr</b>
                      </MDBCol> 

                      <MDBCol style={tableM} >
                       <b>(1)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(2)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(3)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(4)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(5)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(6)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(7)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(8)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(9)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>(10)</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>Tot %</b>
                      </MDBCol>                                         
                    </MDBRow> 

                     <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      CEO - MD - Founder Level
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.one_count}
                      </MDBCol> 

                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[0]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[1]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[2]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[3]/this.state.one_count) }
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[4]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[5]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[6]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[7]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[8]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[9]/this.state.one_count) }
                      </MDBCol> 

                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.one_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>                      

                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      Senior Leader - Director Level
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.two_count}
                      </MDBCol>  
                       

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[0]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[1]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[2]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[3]/this.state.two_count) }
                      </MDBCol>                      
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[4]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[5]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[6]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[7]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[8]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[9]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.two_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      Senior Leader - Executive Level
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.three_count}
                      </MDBCol>    
                       

                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[0]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[1]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[2]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[3]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[4]/this.state.three_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[5]/this.state.three_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[6]/this.state.three_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[7]/this.state.three_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[8]/this.state.three_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[9]/this.state.three_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.three_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      Senior Leader
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.four_count}
                      </MDBCol> 
                      

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[0]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[1]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[2]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[3]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[4]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[5]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[6]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[7]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[8]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[9]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.four_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      Mid Level Leader
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.five_count}
                      </MDBCol>  
                        

                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[0]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[1]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[2]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[3]/this.state.five_count)}
                      </MDBCol>                       
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[4]/this.state.five_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[5]/this.state.five_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[6]/this.state.five_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[7]/this.state.five_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[8]/this.state.five_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[9]/this.state.five_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.five_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      Junior Leader
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.six_count}
                      </MDBCol>
                        

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[0]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[1]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[2]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[3]/this.state.six_count)}
                      </MDBCol>      
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[4]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[5]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[6]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[7]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[8]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[9]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.six_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 

                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      Technical Expert Level
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.seven_count}
                      </MDBCol>  

                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[0]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[1]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[2]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[3]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[4]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[5]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[6]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[7]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[8]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[9]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.seven_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      Other
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.eight_count}
                      </MDBCol> 
                        

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[0]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[1]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[2]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[3]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[4]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[5]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[6]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[7]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[8]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[9]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.eight_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>  
                    </MDBRow> 

                   {/* <MDBRow> 
                       <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[0]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[1]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[2]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[3]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[4]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[5]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[6]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[7]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[8]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[9]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.ten_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>   
                    </MDBRow> */}
               </MDBContainer>                 
              </div>
              </div>
    )} 
}     

Competencies.propTypes = {
  fetchCompanyValues: PropTypes.func.isRequired,
  userdata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

// const modalStyle = {
//   top: `${top}%`,
//   left: `${left}%`,
//   transform: `translate(-${top}%, -${left}%)`
// }
// const modalStyle2 = {
//   top: `${top}%`,
//   left: `${left}%`,
//   transform: `translate(-${top}%, -${left}%)`,
//   padding: '16px 12px 0px'
// }

const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0],
    // pldpTasks: state.pldp.pldpTasks,
    companyValues: state.pldp.companyValues,
    //pldpAllMyTasks: state.pldp.pldpAllMyTasks,
    opinions: state.Copinions.Copinion
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // fetchCompanyValues: params => dispatch(getCompanyValues(params)),
    // deleteCompanyValue: params => dispatch(deleteCompanyValue(params)),
    // fetchMyPLDPTasks: (userid, active) => dispatch(fetchMyPLDPTasks(userid, active)),
    // fetchAllMyPLDPTasks: params => dispatch(fetchAllMyPLDPTasks(params)),
    // updateNewPLDPTask: (task, edit) => dispatch(updateNewPLDPTask(task, edit)),
    // completeTask: params => dispatch(completeTask(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
    CgetOpinions: params => dispatch(CgetOpinions(params)),
  }
}

const CompetenciesStyled = withStyles(styles)(Competencies)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetenciesStyled)
