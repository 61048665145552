import {
  INTERNAL_VIDEOS,
  EXTERNAL_VIDEOS,
  MY_VIDEOS,
  BASE_API_URL,
  GLP_LOGGED_PROFILE,
  PLDP_VIDEOS
} from "./types";
import firebase from "../firebase";
import axios from "axios";
import { _toArray, _toArrayPosts } from "./commonActions";

export const fetchVideos = params => {
  switch (params.type) {
    case INTERNAL_VIDEOS:
      return (dispatch, getState) => {
        firebase.database
          .ref("videos")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              // var sortedData = _toArray(data.val());
              // sortedData.sort(function(a, b) {
              //   if (a.dateScheduled > b.dateScheduled) return -1;
              //   if (a.dateScheduled < b.dateScheduled) return 1;
              //   return 0;
              // });
              var sortedData = data.val();
              dispatch({
                type: INTERNAL_VIDEOS,
                videos: sortedData
              });
              params.resolve();
            } else {
              dispatch({
                type: INTERNAL_VIDEOS,
                videos: {}
              });
              params.resolve();
            }
          });
      };
    case MY_VIDEOS:
      return (dispatch, getState) => {
        firebase.database
          .ref("videos")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var sortedData = _toArrayPosts(data.val());
              sortedData.sort(function(a, b) {
                if (a.dateScheduled > b.dateScheduled) return -1;
                if (a.dateScheduled < b.dateScheduled) return 1;
                return 0;
              });
              dispatch({
                type: MY_VIDEOS,
                myvideos: sortedData
              });
            } else {
              dispatch({
                type: MY_VIDEOS,
                myvideos: {}
              });
            }
          });
      };
    case PLDP_VIDEOS:
      return (dispatch, getState) => {
        firebase.database
          .ref("myPLDP/videos")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(10)
          .on("value", function(data) {
            if (data.val()) {
              var sortedData = _toArrayPosts(data.val());
              sortedData.sort(function(a, b) {
                if (a.dateScheduled > b.dateScheduled) return -1;
                if (a.dateScheduled < b.dateScheduled) return 1;
                return 0;
              });
              dispatch({
                type: PLDP_VIDEOS,
                pldpvideos: sortedData
              });
              return;
            } else {
              dispatch({
                type: PLDP_VIDEOS,
                pldpvideos: {}
              });
              return;
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: "NO_DATA",
          videos: {}
        });
      };
  }
};

export const fetchExternalVideos = params => {
  switch (params.type) {
    case EXTERNAL_VIDEOS:
      return (dispatch, getState) => {
        firebase.database
          .ref("videos")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var sortedData = data.val();
              dispatch({
                type: EXTERNAL_VIDEOS,
                externalvideos: sortedData
              });
              params.resolve();
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: EXTERNAL_VIDEOS,
                  externalvideos: {}
                });
                params.resolve();
              };
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: EXTERNAL_VIDEOS,
          externalvideos: {}
        });
      };
  }
};

export const createVideo = params => {
  var USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
  return (dispatch, getState) => {
    if (params.subcompetencyid) {
      //nothing
      }
      else
      {
        params.subcompetencyid = params.competencyid + '-' + 'None';
        console.log('thoughtsActions createThought props: ', params.subcompetencyid);
      }
    // set user data
    var body = {
      ...params,
      companyID: USER_LOGGED.companyID,
      companyName: USER_LOGGED.companyName,
      userID: USER_LOGGED.userID,
      userName: USER_LOGGED.firstName + " " + USER_LOGGED.lastName,
      user_type: USER_LOGGED.userType,
      photoURL: USER_LOGGED.photoURL || ""
    };
    console.log('purple createVideo body : ', body);

    // clean data before posting
    delete body["maxLengthThought"];
    delete body["maxLengthAuthor"];
    delete body["modalTitle"];
    delete body["postInternalGlobal"];
    delete body["uploadInProgress"];
    delete body["selectedDate"];
    delete body["selectedTime"];

    // Editing or Creating new post?
    if (params.editing) {
      delete body["dateRegistered"];
      delete body["post"];
      body.name = "videos";
      body.id = params.post.videoID;

       //console.log('videosActions BASE_API_URL: ', BASE_API_URL);
       console.log('videos body : 2', body);
      // post new data
      axios
        .put(`${BASE_API_URL}/videos/${body.id}`, body)
        .then(res => {
          dispatch({
            type: "CREATE_VIDEO_SUCCESS"
          });
        })
        .catch(err => {
          if (err.response) {
            dispatch({
              type: "SHOW_TOAST",
              open: true,
              variant: "error",
              message: "Error response: " + err.response.data.message
            });
          } else if (err.request) {
            dispatch({
              type: "SHOW_TOAST",
              open: true,
              variant: "error",
              message: "Error request: " + err.request
            });
          } else {
            dispatch({
              type: "SHOW_TOAST",
              open: true,
              variant: "error",
              message: "Error message: " + err.message
            });
          }
        });
    } else {
      // post new data
      if (body.groupid === "1") {
        console.log('videos body 3: ', body);

        // create multiple posts requests
        // console.log("selected groups: ", body.selectedgroups)
        // console.log("multiple posts requests: ", body)
        var multi_requests = [];


        for (let i = 0, l = body.selectedgroups.length; i < l; i += 1) {
          console.log('videos body: 4', body);
          let single_body = { ...body };
          single_body.groupid = body.selectedgroups[i].group_id
          single_body.groupname = body.selectedgroups[i].group_name

          // console.log("i: ", i)
          // console.log("group_id: ", body.selectedgroups[i].group_id)
          // console.log("group_name: ", body.selectedgroups[i].group_name)
          // console.log("single_body: ", single_body)

          const request = axios.post(`${BASE_API_URL}/videos`, single_body)

          multi_requests.push(request)
        }

        // console.log("multi_requests: ", multi_requests)

        axios.all(multi_requests).then(axios.spread((...responses) => {
          // console.log("finished posting: ", responses)
          dispatch({
            type: "CREATE_VIDEO_SUCCESS"
          });
          // const responseOne = responses[0]
          // const responseTwo = responses[1]
          // const responesThree = responses[2]
          // use/access the results 
        })).catch(errors => {
          // react on errors.
          // console.log("error posting: ", errors)
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error posting a video!"
          });
        })
      } else {
        console.log('videos body: 5 ', body);
        axios
          .post(`${BASE_API_URL}/videos`, body)
          .then(res => {
            dispatch({
              type: "CREATE_VIDEO_SUCCESS"
            });
          })
          .catch(err => {
            if (err.response) {
              dispatch({
                type: "SHOW_TOAST",
                open: true,
                variant: "error",
                message: "Error response: " + err.response.data.message
              });
            } else if (err.request) {
              dispatch({
                type: "SHOW_TOAST",
                open: true,
                variant: "error",
                message: "Error request: " + err.request
              });
            } else {
              dispatch({
                type: "SHOW_TOAST",
                open: true,
                variant: "error",
                message: "Error message: " + err.message
              });
            }
          });
      }
    }
  };
};
