import React from "react";

import { connect } from "react-redux";
import { signUpNewUsers } from "../../store/actions/authActions";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import { Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// Custom Components
import Companylogo from "../../components/layout/Companylogo";
import classNames from "classnames";
import { LIGHTBLUE, HOVERBLUE } from "../../utils/color_utils";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  formTextControl: {
    fontSize: "0.875rem"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(320 + theme.spacing.unit * 3 * 2)]: {
      width: 320,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  text: {
    textAlign: "center"
  },
  form: {
    width: "100%"
  },
  button: {
    margin: `${theme.spacing.unit}px 0px`,
    color: LIGHTBLUE
  },
  authCardHeading: {
    margin: "10px 15px 0"
  },
  authCardTitle: {
    textTransform: "uppercase",
    fontStyle: "bold",
    margin: theme.spacing.unit
  },
  submitButton: {
    marginTop: "10px",
    padding: "10px 0",
    backgroundColor: LIGHTBLUE,
    "&:hover": {
      backgroundColor: HOVERBLUE
    }
  }
});

function AuthCardHeader(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.authCardHeading)}>
      <Companylogo />
      <Typography
        className={classNames(classes.authCardTitle)}
        component="h5"
        variant="h5"
        align="center"
      >
        Sign Up
      </Typography>
    </div>
  );
}

class SignUp extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    showPassword: false,
    agreeTCs: false
  };

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  };

  handleChecked = prop => event => {
    this.setState({
      [prop]: event.target.checked
    });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  SignUpWithEmail = e => {
    e.preventDefault();

    this.props.SignUpWithEmail(this.state);
    this.props.history.push("/");
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      password,
      showPassword,
      agreeTCs
    } = this.state;

    const isInvalid =
      password.trim().length < 7 ||
      password === "" ||
      email === "" ||
      firstName === "" ||
      lastName === "" ||
      !agreeTCs;
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <AuthCardHeader {...this.props} />
          <form className={classes.form} onSubmit={this.SignUpWithEmail}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="firstName">First name</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                value={this.state.firstName}
                //autoFocus={true}
                onChange={this.handleChange("firstName")}
              />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="lastName">Last name</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange("lastName")}
              />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="filled-adornment-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                helperText="Atleast six characters"
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControlLabel
              classes={{ label: classes.formTextControl }}
              control={
                <Checkbox
                  checked={agreeTCs}
                  value="agreeTCs"
                  color="primary"
                  onChange={this.handleChecked("agreeTCs")}
                />
              }
              label="I agree to the Thinklead Terms and Conditions"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInvalid}
              className={classes.submitButton}
            >
              Sign Up
            </Button>
            <Button
              component={Link}
              to="/"
              className={classes.button}
              fullWidth
            >
              Back to login
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

const SignUpModule = withStyles(styles)(SignUp);

const mapDispatchToProps = dispatch => {
  return {
    SignUpWithEmail: params => dispatch(signUpNewUsers(params))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SignUpModule);