import React from 'react'
import ThoughtsDialog from './ThoughtsDialog'
// import ArticlesDialog from './ArticlesDialog'
// import PodcastsDialog from './PodcastsDialog'
// import VideosDialog from './VideosDialog'
// import PostDialog from './PostDialog'

export default class AddDialog extends React.Component {
  constructor(props) {
    super(props)

  }
  componentDidMount() {
   //some code
  }

  render() {
    const { opendialog, dialogname } = this.props

     console.log('greenAddModals 1 : ', dialogname);
    //  try {

    //  switch(dialogname) {
    //   case 'Thought' :
    //     console.log('greenAddModals 2 : ', dialogname);
    //     return(
    //     <ThoughtsDialog
    //     handleClose={this.props.handleModalClose}
    //     opendialog={opendialog}
    //     editing={this.props.editing || false}
    //     post={this.props.post || null}
    //     posttype={this.props.posttype || null}
    //     {...this.props}
    //    />
    //   )
    //     break;
    //   default:
    //     return(
    //       <ThoughtsDialog
    //       handleClose={this.props.handleModalClose}
    //       opendialog={opendialog}
    //       editing={this.props.editing || false}
    //       post={this.props.post || null}
    //       posttype={this.props.posttype || null}
    //       {...this.props}
    //     />)
    // }
    //  }
    // catch(err) {
    //   console.log('greenAddModals err : ', err.message);
    // }

    const DIALOG_TYPES = {
      Thought: (
        <ThoughtsDialog
          handleClose={this.props.handleModalClose}
          opendialog={opendialog}
          editing={this.props.editing || false}
          post={this.props.post || null}
          posttype={this.props.posttype || null}
          {...this.props}
        />
      ),
      Post: (
        <ThoughtsDialog
          handleClose={this.props.handleModalClose}
          opendialog={opendialog}
          editing={this.props.editing || false}
          post={this.props.post || null}
          posttype={this.props.posttype || null}
          {...this.props}
        />
      ),

      // Article: (
      //   <ArticlesDialog
      //     handleClose={this.props.handleModalClose}
      //     opendialog={opendialog}
      //     editing={this.props.editing || false}
      //     post={this.props.post || null}
      //     posttype={this.props.posttype || null}
      //     {...this.props}
      //   />
      // ),
      // Podcast: (
      //   <PodcastsDialog
      //     handleClose={this.props.handleModalClose}
      //     opendialog={opendialog}
      //     editing={this.props.editing || false}
      //     post={this.props.post || null}
      //     posttype={this.props.posttype || null}
      //     {...this.props}
      //   />
      // ),
      // Video: (
      //   <VideosDialog
      //     handleClose={this.props.handleModalClose}
      //     opendialog={opendialog}
      //     editing={this.props.editing || false}
      //     post={this.props.post || null}
      //     posttype={this.props.posttype || null}
      //     {...this.props}
      //   />
      // ),
      // Post: (
      //   <PostDialog
      //     handleClose={this.props.handleModalClose}
      //     opendialog={opendialog}
      //     editing={this.props.editing || false}
      //     post={this.props.post || null}
      //     posttype={this.props.posttype || null}
      //     {...this.props}
      //   />
      // )
    }
    return <div>{DIALOG_TYPES[dialogname]}</div>
  }
}
