import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, Row, Col } from 'react-grid-system';
import { showToast } from "../../store/actions/toastAction";
import { createSurvey } from "../../store/actions/SurveyActions";
import { GREY, RED, LIGHTBLUE, LIGHTGREY, MAROONHOVER } from "../../utils/color_utils";
import { updateSurvey } from "../../store/actions/surveyUpdate";
import CreateaSurvey from "./CreateaSurvey";
import TestIcon from '@material-ui/icons/QuestionAnswer';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'supercons';

// const sendBtn = {
//   backgroundColor: LIGHTBLUE,
//   color: "#fff"
// };
// const sendBtn2 = {
//   backgroundColor: RED,
//   color: "#fff"
// };

const table = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #CAC9C8",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 7
}
const tableh = {
color: "#515A5A",
fontSize: 14,
border: "1px solid #707B7C",
backgroundColor: '#F2F3F4',
padding: 10,
borderRadius: 15
}
const tableM = {
color: "#515A5A",
fontSize: 18,
border: "1px solid #707B7C",
backgroundColor: '#F2F3F4',
padding: 1,
borderRadius: 15
}

const buttonStyle = {
  backgroundColor: RED,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const hoverbuttonStyle = {
  backgroundColor: MAROONHOVER,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const styles = {
mlist: {
  width: 250,
  minHeight: '80vh',
  maxHeight: '80vh'
},
survs: {
  width: 1500,
  minHeight: '80vh',
  maxHeight: '80vh'
},
row2: {
//     backgroundColor: 'green',
},
col1: {
  backgroundColor: 'yellow',
},
col2: {
  backgroundColor: 'brown',
},
col: {
  padding: '1rem',
  backgroundColor: '#33b5e5',
  border: '2px solid #fff',
  color: '#fff',
  textAlign: 'center'
  }
};

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  console.log('MakeTest props: ', props);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // props.resetMakeTest(props.thoughtID, props.real_companyID, props.companyID);
    // props.getSurveyStage(props.thoughtID);
    setOpen(false);
  };
  const handleClosex = (thoughtID, real_companyID, companyID, showLeadb) => {
    props.resetMakeTest(thoughtID, real_companyID, companyID);
    props.getSurveyStage(thoughtID);
     console.log('inside showLead function B ', props)
    //props.showMakeTest(); //false
    //console.log('handleClose is running');
    //console.log('MakeTest props: ', props);

    if (showLeadb) {
      props.showLeadb(props, 'something');
      }	
    setOpen(false);
  };
  return (
    <div>
      <IconButton color="secondary" aria-label="add an alarm" onClick={handleClickOpen}>
        <div style={{ color: 'black', textShadow: '3px 3px 3px gold' }}>
          <Icon glyph="attachment" size={32} />
        </div>
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
           style={{
            top: '-20px',
            width: '100%',
            maxWidth: '100%',
            height: '700px'
          }}
      >
        <DialogContent 
        >
          <DialogContentText
          >
          <CreateaSurvey
            thoughtID={props.thoughtID}
            //thought={thought}
            showLeadb={props.showLeadb}
            companyID={props.userdata.companyID}
            handleClosex={props.handleClosex}
            getSurveyStage={props.getSurveyStage}
            resetMakeTest={props.resetMakeTest}
            title={props.title}
            dailyThoughtType={props.dailyThoughtType}
            groupid={props.groupid}
            key={props} 
            props={props}            
          />
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary"> */}
          <Button onClick={() => handleClosex(props.thoughtID, props.real_companyID, props.companyID, props.showLeadb)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

