import { COMPETENCIES_TEST } from "../actions/types";

const initState = {
  type: COMPETENCIES_TEST,
  competencies_is: false
};

const competenciesTestReducer = (state = initState, action) => {
  switch (action.type) {
    case COMPETENCIES_TEST:
      console.log('addcompetencycompetenciesIS 3 : ', action.competencies_is)
      return {
        type: COMPETENCIES_TEST,
        competencies_is: action.competencies_is
      };
    default:
      return state;
  }
};

export default competenciesTestReducer;
