import React, { Component } from 'react'
import { Checkbox, TextField, Button, ListItemText, ListItem, ListItemIcon, List, Slide, ListItemAvatar, Avatar, Dialog, DialogContentText, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import _ from 'lodash'
import { connect } from 'react-redux'
import { SHOW_LOADER, HIDE_LOADER, SHOW_TOAST } from '../../../store/actions/types'
import { hideLoader, showLoader } from '../../../store/actions/loaderActions'
import { showToast } from '../../../store/actions/toastAction'
import firebase from '../../../store/firebase'
import { getAllCompanyUsers } from '../../../store/actions/companyAdminActions'

// ---------------
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

class GroupEditAdminDialog extends Component {
  // --------------
  constructor (props) {
    super(props)
    this.state = {
      ...props,
      userList: this.props.users,
      inputSearch: '',
      groupName: '',
      groupID: '',
      groups: [],
      groupSelected: {},
      groupUsersSelected: []
    }

    this.handleGroup = this.handleGroup.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleChangeGroupSelect = this.handleChangeGroupSelect.bind(this)
    this.removeUserFromGroup = this.removeUserFromGroup.bind(this)
  }

  // --------------
  // filter items
  handleSearch(event) {
    this.setState({ inputSearch: event.target.value }, () => {
      this.setState({
        list: this.state.userList.map((obj) => {
          if (((new RegExp(this.state.inputSearch, 'gm')).test(Object.values(obj)))) {
            obj.visible = ''
          } else {
            obj.visible = 'none'
          }
          return obj
        })
      })
    })
  }

  // --------------
  // select items
  handleCheck(checkedItem) {
    const temp = this.state.userList.map((item) => {
      if ((new RegExp(checkedItem.email, 'gm')).test(item.email)) {
        (!item.selected) ? item.selected = true : item.selected = false
      }
      return item
    })

    this.setState({
      userList: temp
    })
  }

  // --------------
  handleGroup(event) {
    this.setState({
      groupName: event.target.value
    })
  }

  // --------------
  hasSelected() {
    const check = this.state.userList.map((user) => {
      return (user.selected)
    })
    return check.includes(true)
  }

  removeUserFromGroup() {
    const updates = {}
    const { userdata, showLoader, showToast, closeGroupAdminDialog } = this.props
    const { groupName, groupID } = this.state
    let usersToRemove = []
    let userGroupsToRemove = []

    //check which users were previously selected
    this.state.userList.forEach((allUsers) => {
      for (let key in this.state.groupUsersSelected) {
        let populatedUsers = this.state.groupUsersSelected[key]
        if (populatedUsers.userID === allUsers.userID) {
          if (!allUsers.selected) {
            usersToRemove[allUsers.userID] = {
              firstName: null,
              lastName: null,
              email: null,
              userID: null,
              can_add_remove_users: null,
              can_edit: null,
              can_post: null,
              company_ID: null,
              company_name: null
            }
          }
        }
      }
    })

    updates['company_groups_users/' + userdata.companyID + '/' + groupID] = usersToRemove

    // updates['company_user_groups/' + user.userID + '/' + groupID ] = {
    //   user_id: null,
    //   group_id: null,
    //   group_name: null
    // }

    firebase.database.ref().update(updates,
      function(error) {
        if (error) {
          showToast({ type: SHOW_TOAST, open: true, variant: 'error', message: error.message })
        } else {
          showToast({ type: SHOW_TOAST, open: true, variant: 'success', message: "User has been removed from group." })
        }

        // hide progress
        showLoader({ type: HIDE_LOADER })

        closeGroupAdminDialog()
      }
    )
  }
  // ---------------
  saveGroupList(event) {
    const { userdata, showLoader } = this.props
    const { groupName } = this.state
    const temp = []
    let isSelected = true

    // extract users selected and check if users selected
    if (!this.hasSelected()) {
      isSelected = false
      showToast({ type: SHOW_TOAST, open: true, variant: 'warning', message: 'Please select users to be part of a group.' })
    }

    // check if group name inserted
    if (groupName === '') {
      showToast({ type: SHOW_TOAST, open: true, variant: 'warning', message: 'Please provide a group name.' })
    }

    // send data to firebase
    if (groupName !== '') {
      if (isSelected) {
        showLoader({ type: SHOW_LOADER })
        let obj = {}
        this.state.userList.map(function(user) {
          if (user.selected) {
            obj[user.userID] = {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              userID: user.userID,
              can_add_remove_users: false,
              can_edit: false,
              can_post: true,
              company_ID: userdata.companyID,
              company_name: userdata.companyName
            }
          }
        })

        // add current user to group with admin access
        obj[userdata.userID] = {
          firstName: userdata.firstName,
          lastName: userdata.lastName,
          email: userdata.email,
          userID: userdata.userID,
          can_add_remove_users: true,
          can_edit: true,
          can_post: true,
          company_ID: userdata.companyID,
          company_name: userdata.companyName
        }
        this.postNewGroup(obj)
      }
    }
  }

  // ---------------
  postNewGroup(rowsSelected) {
    const updates = {}
    const { userdata, showLoader, showToast, closeGroupAdminDialog } = this.props
    const { groupName, groupID, removeUserFromGroup } = this.state

    // generate new key for group
    const key = groupID

    // 1 create new group
    updates['/company_groups/' + userdata.companyID + '/' + key] = {
      group_id: key,
      group_name: groupName,
      company_id: userdata.companyID,
      total_users: Object.keys(rowsSelected).length
    }

    //console.log('rowsSelected', rowsSelected)

    // 2 add users to group
    updates['/company_groups_users/' + userdata.companyID + '/' + key] = rowsSelected

    // 3 add group to user specific list
    _.each(rowsSelected, function(value, index) {
      updates['/company_user_groups/' + value.userID + '/' + key] = {
        user_id: value.userID,
        group_id: key,
        group_name: groupName
      }
    })

    // 4 add current user to group with admin access
    updates['/company_user_groups/' + userdata.userID + '/' + key] = {
      user_id: userdata.userID,
      group_id: key,
      group_name: groupName
    }

    // server: create group - send data to firebase
    firebase.database.ref().update(
      updates,
      function(error) {
        // check which users to remove
        if (error) {
          showToast({ type: SHOW_TOAST, open: true, variant: 'error', message: error.message })
        } else {
          showToast({ type: SHOW_TOAST, open: true, variant: 'success', message: "Group has been editted." })
        }

        // hide progress
        showLoader({ type: HIDE_LOADER })
        closeGroupAdminDialog()
      })

    this.setState({ groupName: '' });

    // todo: remove user from group
    this.removeUserFromGroup()
  }


  // --------------
  componentWillReceiveProps() {
    firebase.database
      .ref("company_user_groups/" + this.props.userdata.userID)
      .once("value")
      .then(result => {
        let groups = [];

        result.forEach(obj => {
          groups.push({
            value: {
              id: obj.key,
              group_id: obj.val().group_id,
              group_name: obj.val().group_name,
              user_id: obj.val().user_id
            },
            label: obj.val().group_name
          });
        });

        this.setState({
          groups: groups,
          userList: this.props.users
        })
      });
    return null
  }

  handleChangeGroupSelect(event) {
    // console.log('handleChangeGroupSelect')

    const { userdata } = this.props
    //set dropdown selected menu item
    this.setState({
      groupSelected: event.target.value,
      groupName: event.target.value.group_name,
      groupID: event.target.value.group_id,
      userList: this.state.userList.map((user) => {
        user.selected = false
        return user
      })
    }, () => {
      // console.log('set users false')
      //load group data selected
      firebase.database
        .ref('company_groups_users/' + userdata.companyID + '/' + event.target.value.group_id)
        .on('value', (snapshot) => {
          const value = snapshot.val()
          const temp = _.sortBy(this.state.userList.map((user) => {
            for (let obj in value) {
              if (obj.includes(user.userID)) {
                user.selected = true
              }
            }
            return user
          }), (_user) => {
            return _user.selected === false
          })

          this.setState({
            groupUsersSelected: value,
            userList: temp
          }, function() {
            // console.log('set users true')
          })
        })
    })
  }

  // --------------
  render(props) {
    const {
      open
    } = this.props

    return (
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => { this.setState({ groupName: '' }); this.props.closeGroupAdminDialog() }}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <DialogContentText>
            {/* Select Group */}
            <FormControl style={{ width: '93%' }}>
              <InputLabel>Select Group</InputLabel>
              <Select
                onChange={this.handleChangeGroupSelect}
                value={this.state.groupSelected}
              >
                {
                  this.state.groups.map((obj) => {
                    return (
                      <MenuItem value={obj.value} >
                        {obj.label}
                      </MenuItem>)
                  })
                }
              </Select>
            </FormControl>

            <br />

            {/* ------------- */}
            <TextField
              placeholder='Group name'
              value={this.state.groupName}
              onChange={this.handleGroup}
              style={{ width: '93%' }}
              margin='normal'
            />

            {/* ------------- */}
            {/* // search bar */}
            <TextField
              placeholder='Search'
              value={this.state.inputSearch}
              onChange={this.handleSearch}
              style={{ width: '93%' }}
              margin='dense'
              variant='outlined'
            />

            {/* ------------- */}
            {/* // display list */}
            <List dense style={{ overflowY: 'scroll', marginBottom: '20px' }}>
              {
                this.state.userList.map((obj) => {
                  const labelId = `checkbox-list-secondary-label-${obj.firstName}-${obj.lastName}`
                  return (
                    <ListItem key={obj.email} button onClick={(e) => { this.handleCheck(obj) }} style={{ display: obj.visible }}>
                      {/** add avatar */}
                      <ListItemIcon>
                        <Checkbox edge='end' value={obj} checked={obj.selected} inputProps={{ 'aria-labelledby': labelId }} color='primary' />
                      </ListItemIcon>
                      <ListItemText id={labelId} style={{ width: '10px' }} primary={`${obj.firstName}` + ' ' + `${obj.lastName}` + ' ' + `${obj.email}`} />
                    </ListItem>
                  )
                })
              }
              {/* ------------- */}
              {/* save */}
            </List>

          </DialogContentText>
        </DialogContent>

        {/* ---------- */}
        {/* Save */}
        <DialogActions>
          <Button onClick={() => { this.setState({ groupName: '' }); this.props.closeGroupAdminDialog() }}>Cancel</Button>
          <Button variant='contained' onClick={() => { this.saveGroupList() }} color='primary'>Save</Button>
        </DialogActions>
      </Dialog>
    )
  };
}

//  ===============
const mapDispatchToProps = dispatch => {
  return {
    showLoader: params => dispatch(showLoader(params)),
    hideLoader: params => dispatch(hideLoader(params)),
    showToast: params => dispatch(showToast(params)),
    getAllCompanyUsers: params => dispatch(getAllCompanyUsers(params))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userdata: state.userDialog.userDetails[0]
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupEditAdminDialog)