import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ViewFollowing from "../../components/UserProfile/ViewFollowing";

import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CloseIcon from "@material-ui/icons/Close";
import { HOVERRED, RED } from "../../utils/color_utils";

const styles = theme => ({
  appBar: {
    position: "static",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  flex: {
    flex: 1
  },
  dialog: {},
  divStyle: {
    padding: "0px",
    paddingTop: "0px!important",
    height: "100vh!important",
    margin: "0px"
  },
  close: {
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "999999",
    color: "#fff",
    backgroundColor: `${RED}`,
    borderRadius: "0%",
    padding: "8px",
    "&:hover": {
      background: `${HOVERRED}`
    }
  }
});

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class FollowingDialog extends React.Component {
  state = {
    open: false,
    scroll: "paper"
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = e => {
    this.props.onOpenFollowersDialog(e.target);
  };

  render() {
    const { classes, theme, fullScreen, option } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          fullScreen={fullScreen}
          fullWidth
          maxWidth="sm"
          //TransitionComponent={Transition}
          aria-labelledby="scroll-dialog-title"
          style={{ height: "100vh!important" }}
        >
          <DialogContent className={classes.divStyle}>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose.bind(this)}
            >
              <CloseIcon fontSize="small" className={classes.icon} />
            </IconButton>
            <ViewFollowing option={option} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

FollowingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

const followingDlg = withMobileDialog({ breakpoint: "xs" })(FollowingDialog);

export default withStyles(styles, { withTheme: true })(followingDlg);
