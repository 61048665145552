import moment from 'moment'

const initState = {
  yearSelected: moment().year(),
  monthSelected: moment().month() + 1,
  daySelected: moment().date(),
  hourSelected: moment().hour(),
  minuteSelected: moment().minutes()
};

export default function (state = initState, actions) {
  switch (actions.type) {
    case 'YEARSELECTED':
      return {
        ...state,
        yearSelected: actions.payload
      };
    case 'MONTHSELECTED':
      return {
        ...state,
        monthSelected: actions.payload
      };
    case 'DAYSELECTED':
      return {
        ...state,
        daySelected: actions.payload
      };
    case 'HOURSELECTED':
      return {
        ...state,
        hourSelected: actions.payload
      };
    case 'MINUTESELECTED':
      return {
        ...state,
        minuteSelected: actions.payload
      };
    default:
      return { ...state };
  }
}