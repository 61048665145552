import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { DARK, LIGHTGREY } from "../../utils/color_utils";
import { PHOTO_PROFILE } from "../../utils/photo_utils";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {
  fetchUserBiography,
  openUserProfileDialog
} from "../../store/actions/userBiographyActions";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  icon: {
    padding: "5px",
    fontSize: "30px"
  },
  colorDark: {
    color: `${DARK}`
  },
  colorDarkBlue: {
    color: `#607d8b`
  },
  analyticsCount: {
    right: "16px"
  },
  avatar: {
    backgroundColor: `${LIGHTGREY}`,
    cursor: "pointer"
  }
});

class LikeDataList extends React.Component {
  _setTotals = (item, total) => {
    var tot = total || 0;
    for (var x in item) {
      tot += item[x] || 0;
    }
    return tot;
  };

  openViewUserDialog = post => {
    var params = {
      userID: post.journalUserID,
      userName: post.journalUserName,
      companyID: post.companyID,
      companyName: post.companyName,
      photoURL: PHOTO_PROFILE(post.photoURL),
      showprofiledlg: true
    };

    // Fetch user bio data
    this.props.fetchUserBiography({ ...params, type: "USER_BIOGRAPHY" });

    // Open user profile dialog
    this.props.openUserProfileDialog(params);
  };

  render() {
    const { classes, likeData } = this.props;
    return (
      <div>
        <Paper elevation="0">
          <List className={classes.root}>
            <div>
              {likeData &&
                Object.keys(likeData).map((id, index) => {
                  return (
                    <ListItem alignItems="flex-start" divider={true} key={id}>
                      <ListItemAvatar>
                        <Avatar
                          onClick={() => this.openViewUserDialog(likeData[id])}
                          alt={likeData[id].journalUserName}
                          src={PHOTO_PROFILE(likeData[id].photoURL)}
                          className={classes.avatar}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            className={classes.colorBlue}
                            component="strong"
                            color="textPrimary"
                          >
                            {likeData[id].journalUserName}
                          </Typography>
                        }
                        className={classes.colorBlue}
                        secondary={
                          <React.Fragment>
                            <Typography variant="body2" color="textPrimary">
                              {likeData[id].companyName}&nbsp;
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  );
                })}
            </div>
          </List>
        </Paper>
      </div>
    );
  }
}

LikeDataList.propTypes = {
  classes: PropTypes.object.isRequired
};

const MyLikeData = withStyles(styles)(LikeDataList);

const mapDispatchToProps = dispatch => {
  return {
    fetchUserBiography: params => dispatch(fetchUserBiography(params)),
    openUserProfileDialog: params => dispatch(openUserProfileDialog(params))
  };
};

const LikedBy = connect(
  null,
  mapDispatchToProps
)(MyLikeData);

export default LikedBy;
