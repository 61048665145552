import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, messages, articles, videos, podcasts, pldp) {
  return { name, messages, articles, videos, podcasts, pldp };
}

const rows = [
  createData('Administration', 120, 70, 100, 20, 20, 84, 61),
  createData('Information Technology', 80, 7, 10, 2, 56, 12, 4),
  createData('Commercial Services', 15, 12, 20, 8, 6, 24, 48),
  createData('Executive', 5, 0, 2, 0, 12, 4, 6),
  createData('Marketing and Communications', 120, 70, 100, 20, 20, 84, 61),
  createData('Projects', 15, 12, 20, 8, 6, 24, 48),
  createData('Risk', 80, 7, 10, 2, 56, 12, 4),
];

const data_types = ["Messages", "Articles", "Podcasts", "Videos", "PLDP"];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function PostsByDepartmentTable(props) {
  const classes = useStyles();

  const [graph_data, setDataRows] = useState([]);

  function handleStatusChange(prop_data) {
    let graph_d = [];

    let departments = prop_data && prop_data.analytics && prop_data.analytics.department ? prop_data.analytics.department : {};

    for (const department in departments) {
      let department_title = department
      let department_data = departments[department];
      let thoughts_no = 0;
      let articles_no = 0;
      let podcasts_no = 0;
      let videos_no = 0;
      let pldp_no = 0;

      data_types.map((v, i) => {

        if (v === "Messages") {
          thoughts_no = department_data && department_data.thoughts ? department_data.thoughts : 0;
        }
        else if (v === "Articles") {
          articles_no = department_data && department_data.articles ? department_data.articles : 0;

        }
        else if (v === "Podcasts") {
          podcasts_no = department_data && department_data.podcasts ? department_data.podcasts : 0;
        }
        else if (v === "Videos") {
          videos_no = department_data && department_data.videos ? department_data.videos : 0;
        }
        else if (v === "PLDP") {
          pldp_no = department_data && department_data.pldp ? department_data.pldp : 0;
        }

      })

      graph_d.push(createData(department, thoughts_no, articles_no, videos_no, podcasts_no, pldp_no));

      setDataRows(graph_d);

    }
  }

  useEffect(() => {
    //console.log("analytics here 1: ", props.analytics);
    handleStatusChange(props);
  }, [props]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Item</StyledTableCell>
            <StyledTableCell align="center">Messages</StyledTableCell>
            <StyledTableCell align="center">Articles</StyledTableCell>
            <StyledTableCell align="center">Videos</StyledTableCell>
            <StyledTableCell align="center">Podcasts</StyledTableCell>
            <StyledTableCell align="center">PLDP</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {graph_data && graph_data.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.messages}</StyledTableCell>
              <StyledTableCell align="center">{row.articles}</StyledTableCell>
              <StyledTableCell align="center">{row.videos}</StyledTableCell>
              <StyledTableCell align="center">{row.podcasts}</StyledTableCell>
              <StyledTableCell align="center">{row.pldp}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
