import { SIMPLE_VALUES } from "./types";

export const simpleAction = params => {
  if (params.type === SIMPLE_VALUES) {
      console.log('in simpleaction: ', params.surveyid);
    return (dispatch, getState) => {
        if (params) {
          dispatch({
            type: SIMPLE_VALUES,
            simple_action: params.surveyid
          });
    
        } else {
          return (dispatch, getState) => {
            dispatch({
              type: SIMPLE_VALUES,
              simple_action: {}
            });
          };
        }
   } 
 }
};
