import { SURVEY_Q_LIST } from "../actions/types";

const initState = {
  type: SURVEY_Q_LIST,
  survey_Q_list: []
};

const surveyQReducer = (state = initState, action) => {
  switch (action.type) {
    case SURVEY_Q_LIST:
      return {
        type: SURVEY_Q_LIST,
        survey_Q_list: action.survey_Q_list
      };

    default:
      return state;
  }
};

export default surveyQReducer;
