import React, { Component } from "react";
import PropTypes from "prop-types";
import VideosView from "../../components/Views/PldpVideosView";
import { connect } from "react-redux";
import { fetchVideos } from "../../store/actions/videosActions";
import { PLDP_VIDEOS } from "../../store/actions/types";
import { deletePostAttachment } from "./../../store/actions/attachmentActions";


class Videos extends Component {
  componentWillMount() {
    var params = {
      type: PLDP_VIDEOS,
      orderChild: "PLDPUserID",
      equalTo: this.props.userdata.userID
    };

    this.props.fetchVideos(params);
  }

  render() {
    const { videos, userdata } = this.props;
    console.log('videosView props 3: ', this.props);
    return (
      <div>
        {userdata && videos ? (
          <React.Fragment>
            <VideosView videos={videos} {...this.props} />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

Videos.propTypes = {
  fetchVideos: PropTypes.func.isRequired,
  videos: PropTypes.array
};

const mapStateToProps = state => {
  return {
    videos: state.videos.pldpvideos,
    userdata: state.userDialog.userDetails[0]
  };
};

export default connect(
  mapStateToProps,
  { fetchVideos, deletePostAttachment }
)(Videos);
