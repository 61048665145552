import { GET_POINTS, CLEAR_POINTS } from "./../actions/types";
import firebase from "../firebase";
import { _toArrayUsers } from './commonActions'

    export const clearRealPoints = params => {
        return (dispatch, getState) => {
                dispatch({
                  type: CLEAR_POINTS,
                  realpoints: {}  
            })
        }
    }

   function getP(props) {
      for (let x in props) {
        return props[x].points
      }
    }

    function getPY(props) {
      for (let x in props) {
        return props[x].pointsy
      }
    }

  export const getRealPoints = params => {
        var finalpoints = 0;
        if (params.uid) {
         return (dispatch, getState) => {
          console.log('green blue 2 : ', params.uid)
          firebase.database
             .ref('user')
             .orderByChild('uid')
             .equalTo(params.uid)
             .once("value", function (data) {
              console.log('green blue 3 : ', params.uid)
                if (data.val()) {
                  finalpoints = data.val();
                  console.log('green blue 4 : ', data.val())
                }
                console.log('green blue 5 : ', finalpoints)

                if (finalpoints) {
                  var pdata = {
                    points: getP(finalpoints),
                    pointsy: getPY(finalpoints)
                  }
                 // console.log('green blue 6 : ', getP(finalpoints))
                  dispatch({
                    type: GET_POINTS,
                    realpoints: pdata //getP(finalpoints)
                  })
              } else {
                  console.log('green blue 7 : ')
                  dispatch({
                    type: GET_POINTS,
                    realpoints: {}
                  })
                 }

             });

           }}
        }

        export const getRealPointsxxxxxx = params => {
          if (params.type === GET_POINTS) {
            return (dispatch, getState) => {
              firebase.database
              .ref('user')
              .orderByChild('uid')
              .equalTo(params.uid)
              .once("value", function (data) {
        
            if (data.val()) {  
              var rpoints = data.val() 
                
                  dispatch({
                    type: GET_POINTS,
                    realpoints: rpoints
                  });
            
                } else {
                  dispatch({
                    type: GET_POINTS,
                    realpoints: {}
                  });
                }
              });
           } 
         }
        };

     const getRealPointsxxxxxxxxxxxxxx = params => {
       var finalpoints = 0;
        return async(dispatch, getState) => {
          await firebase.database
            .ref('user')
            .orderByChild('uid')
            .equalTo(params.uid)
            .on('value', function (data) {
               if (data.val()) {
                let alldata = data.val()
                let receivedData = {}
                console.log('green getRealPoints 1 : ', alldata)
             if (Object.keys(alldata).length > 0) {    
              //let sortedData = alldata.points
              let sortedData = _toArrayUsers(alldata)
            //   sortedData.sort(function (a, b) {
  
            //     if (a.points > b.points) {
            //       return 1
            //     }
            //     if (a.points < b.points) {
            //       return -1
            //     }
            //     return 0
            //   })
  
              receivedData = { ...sortedData }
  
              receivedData = Object.assign({}, sortedData)
              finalpoints = getP(receivedData)
              console.log('green blue 1 : ', finalpoints)
            }
               dispatch({
                  type: GET_POINTS,
                  realpoints: finalpoints
                })
              } else {
                dispatch({
                  type: GET_POINTS,
                  realpoints: 0
                })
               }
             
          })
        }
      }

    