import { SHOW_TOAST, HIDE_TOAST, CREATE_FEEDBACK, BASE_API_URL } from './types';
import axios from 'axios';
export const sendFeedback = (form) => {
	return (dispatch, getState) => {
		// console.log(form);

		axios
			.post(`${BASE_API_URL}/feedback`, form)
			.then((res) => {
				// console.log('feedback data: ', form);
				dispatch({
					type: 'SHOW_TOAST',
					feedback: 'sent',
					open: true,
					variant: 'success',
					message: 'Feedback sent successfully'
				});
			})
			.catch((err) => {
				dispatch({
					type: 'SHOW_TOAST',
					feedback: 'not sent',
					open: true,
					variant: 'error',
					message: 'Feedback Error ' + err.message
				});
			});
	};
};
