import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import VideosCard from "../Cards/VideosCard";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class VideosView extends React.Component {
  render() {
    const { classes, videos, followingData } = this.props;

    return (
      <React.Fragment>
        {/* {Object.keys(videos).length > 0 ? null : (
          <Progressbar className={classes.progress} color="secondary" />
        )} */}

        <div className={classes.cardstyles}>
          {videos &&
            //Object.keys(videos).length > 0 &&
            Object.keys(videos).map(id => {
              return (
                <VideosCard
                  video={videos[id]}
                  key={id}
                  {...this.props}
                  following={
                    followingData
                      ? followingData[videos[id].userID]
                        ? true
                        : false
                      : false
                  }
                  userdata={this.props.userdata}
                />
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

VideosView.propTypes = {
  classes: PropTypes.object.isRequired,
  videos: PropTypes.object.isRequired
};

var VideosViewStyled = withStyles(styles)(VideosView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(VideosViewStyled);
