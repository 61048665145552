import firebase from "../../config/fbConfig";
import { loggedInProfileActions } from "../actions/loggedInProfileActions";
import { LOGIN_SUCCESS, USER_LOGGED_IN } from "../actions/types";

class Firebase {
  constructor() {
    this.auth = firebase.auth();
    this.auth_ = firebase.auth;
    this.database = firebase.database();
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logOut() {
    this.auth.signOut();
  }

  async register(name, email, password) {
    await this.auth.createUserWithEmailAndPassword(email, password);
    this.auth.currentUser.updateProfile({
      displayName: name
    });
  }

  getCurrentUsername() {
    return this.auth.currentUser.displayName;
  }
}

export default new Firebase();