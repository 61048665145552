import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid, Typography, Divider
} from '@material-ui/core'
import AddGroups from './AddGroups'
import AssignedGroupsList from './AssignedGroupsList'

class AssignedGroups extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Typography variant='body2' style={{ fontWeight: 'bold' }}>{this.props.file.name}</Typography>
          </Grid>
          <Divider />
          <Grid item sm={12} xs={12}>
            <AddGroups file={this.props.file} userdata={this.props.userdata} />
          </Grid>
          <Grid item sm={12} xs={12}>
            <AssignedGroupsList file={this.props.file} userdata={this.props.userdata} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

// AssignedGroups.propTypes = {
//   classes: PropTypes.object,
//   userdata: PropTypes.any
// }

export default AssignedGroups;
