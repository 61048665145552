import React from 'react'
import ReactPlayer from 'react-player'

function Tutorial() {
  return (
    <div>
    <div>
      <h2>Introduction Tutorial</h2>
      <ReactPlayer
        //url='https://www.youtube.com/watch?v=JGL1vDjJZD0'
        //url='https://www.youtube.com/watch?v=U8-LnNd9J4E'
        url='https://www.youtube.com/watch?v=Aj9LqiOjjFA'
        controls={true}
      /><br/><br/>
    </div>
    <div>
      <h2>How to LEAD</h2>
      <ReactPlayer
        url='https://youtu.be/bWqgnPWGt-0'
        controls={true}
      /><br/><br/>
    </div>
    <div>
      <h2>Personal Development Tutorial</h2>
      <ReactPlayer
        url='https://youtu.be/s0WvGR94oLQ'
        controls={true}
      /><br/><br/>
    </div> 
    <div>
      <h2>LeaderTrack Tutorial</h2>
      <ReactPlayer
        url='https://www.youtube.com/watch?v=_Y4jOW71yJ8'
        controls={true}
      /><br/><br/>
    </div> 

        {/* <div>
        <h2>Earn Points by publishing a test related to your posts</h2>
        <ReactPlayer
          url='https://www.youtube.com/watch?v=GzIkcq2xaRA'
          controls={true}
        /><br/><br/>
    </div>
        <div>
        <h2>Earn Points by completing publishers tests</h2>
        <ReactPlayer
          url='https://youtu.be/4gj1jqLUCS4'
          controls={true}
        />
      </div> */}
      <br/><br/>
    </div>
  )
}

export default Tutorial
