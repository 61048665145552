import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chart from "react-google-charts";

class PopularTopicsPie extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    let thoughts = this.props && this.props.history.thoughts ? this.props.history.thoughts : 0;
    let articles = this.props && this.props.history.articles ? this.props.history.articles : 0;
    let podcasts = this.props && this.props.history.podcasts ? this.props.history.podcasts : 0;
    let videos = this.props && this.props.history.videos ? this.props.history.videos : 0;
    let pldp = this.props && this.props.history.pldp ? this.props.history.pldp : 0;

    let data_final = [];
    let data_final2 = [];
    let data_item = [];
    data_item.push(['Page', 'Visitors']);

    if (this.props && this.props.history) {
      this.props.history.map((historyRow) => {
        let hist_item = []
        hist_item.push(historyRow.item, historyRow.count)
        data_item.push(hist_item);
      })

      data_final.push(data_item);
    }

    data_final2.push(data_final)

    this.setState({
      data: data_item
    })

  }

  render() {
    const { history } = this.props

    return (
      <div>
        <Chart
          width={'100%'}
          height={'300px'}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={this.state.data}
          options={{
            title: 'Popular Topic - Drilldown',
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </div>
    )
  }
}

PopularTopicsPie.propTypes = {
  history: PropTypes.any,
}

export default PopularTopicsPie;
