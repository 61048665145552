/* eslint-disable no-inline-comments */
/* eslint-disable consistent-return */
/* eslint-disable no-undefined */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import MUIDataTable from 'mui-datatables'
import { hideLoader, showLoader } from '../store/actions/loaderActions'
import {
  SHOW_TOAST,
  SHOW_LOADER,
  HIDE_LOADER,
  VALUES_NAME,
  ACCESS_COMPETENCIES,
  SIMPLE_VALUES,
  GET_ALL_TOKENS
} from '../store/actions/types'
import { bindActionCreators } from 'redux'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Paper,
  Button,
  Avatar,
  Card,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  Menu,
  MenuItem
} from '@material-ui/core'
import { green, orange, red } from '@material-ui/core/colors'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import DescriptionIcon from '@material-ui/icons/Description'
import TextsmsIcon from '@material-ui/icons/Textsms'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import FilterListIcon from '@material-ui/icons/FilterList'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import grey from '@material-ui/core/colors/grey'
import AddTokensDialog from './../components/Dialogs/AddTokensDialog'
import GroupAdminDialog from './../components/Dialogs/GroupAdmin/GroupAdminDialog'
import GroupEditAdminDialog from './../components/Dialogs/GroupAdmin/GroupEditAdminDialog'
import UserTypeDialog from './../components/Dialogs/UserTypeDialog'
import ChangeUserPasswordDialog from './../components/Dialogs/ChangeUserPasswordDialog'
import { LIGHTGREY, LIGHTBLUE } from '../utils/color_utils'
import CardContent from '@material-ui/core/CardContent'
import { PHOTO_PROFILE } from '../utils/photo_utils'
import { connect } from 'react-redux'
import {
  getAllTokens,
 // deleteCompanyUser,
 getAllCompanyUsers,
  deleteToken,
  createToken,
  //updateCompanyUser,
  //resendWelcomeEmail,
  changeUserType,
  checkUserExists,
  //getAllCompanySettings,
  //getAllGeneralSettings,
  editUser,
  checkUserExistsEdit
} from './../store/actions/companyAdminActions'
// [NEED TO BE FIX] need to fix as per need
// import xlsx from 'xlsx'
import firebase from '../store/firebase'
import { showToast } from './../store/actions/toastAction'
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import TextField from "@material-ui/core/TextField";
import LeaderLoader from './LeaderLoader'
import { simpleAction } from "./../store/actions/simpleAction";

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    boxShadow: 'none'
  },
  highlight: theme.palette.type === 'light' ? {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85)
  } : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark
    },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  button: {
    'margin': theme.spacing.unit,
    'color': '#ffffff',
    'backgroundColor': `${LIGHTBLUE}`,
    '&:hover': {
      backgroundColor: `${LIGHTBLUE}`,
      color: '#ffffff'
    },
    'fontSize': '10px'
  },
  deleteButton: {
    backgroundColor: red[400],
    color: '#ffffff',
    margin: theme.spacing.unit,
    fontSize: '10px'
  },
  activeButton: {
    backgroundColor: orange[800],
    color: '#ffffff',
    margin: theme.spacing.unit,
    fontSize: '10px'
  },
  userTypeButton: {
    backgroundColor: green[400],
    color: '#ffffff',
    margin: theme.spacing.unit,
    fontSize: '10px'
  },

  settingsIcons: {
    margin: theme.spacing.unit
  },
  icon: {
    fontSize: '20px'
  }
})

const table = {
  color: "red",
  fontSize: 8,
  border: "1px solid grey"
}

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    multiple,
    onDeleteUser,
    onChangeUserData,
    onOpenUpdateUserDialog,
    onResendWelcomeEmail,
    onOpenChangePassword,
    onOpenUserTypeDialog,
    handleEditUserDialog
  } = props

  console.log('green props: ', props.userType);

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      elevation={0}
    >
      <div className={classes.title} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {numSelected === 1 ? (
              <React.Fragment>
           
              </React.Fragment>
            ) : null}
            {props.userType===14 ? null : 
            ( <Button
              onClick={onDeleteUser}
              variant='contained'
              className={classes.deleteButton}
              aria-label='Delete'
              size='small'
            >
              <DeleteIcon />
              {multiple ? `Delete (${numSelected})` : 'Delete'}
            </Button> )
             }
          </React.Fragment>
        ) : (
            <Tooltip title='Filter list'>
              <IconButton aria-label='Filter list'>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    minWidth: '700'
  },
  btn: {
    margin: theme.spacing.unit
  },
  button: {
    'margin': theme.spacing.unit,
    'backgroundColor': '#0288d1',
    '&:hover': {
      backgroundColor: '#0288d1'
    }
  },
  button2: {
    'margin': theme.spacing.unit,
    'backgroundColor': '#0288d1',
    '&:hover': {
      backgroundColor: '#0288d1'
    },
    'height': '50px',
    'width': '15%'
  },
  pageHeader: {
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: '120px'
    }
  },
  paperHeader: {
    backgroundColor: grey[300]
  },
  activate: {
    color: green[800],
    textAlign: 'center'
  },
  deactivate: {
    color: red[400],
    textAlign: 'center'
  },
  card: {
    width: 500
  }
})

const UserCardList = props => {
  const { classes } = props
  return (
    <Card className={classes.card}>
      <CardContent>
        <Avatar>{props.user[1][0]}</Avatar>
        <List>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>First Name</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[1]}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>Last Name</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[2]}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>Email</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[3]}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>User type</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[4]}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>Status</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[5]}</Typography>
              {/* <RadioButtonCheckedIcon className={classes.activate} /> */}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

class Tokens extends Component {
     constructor(props){
         super(props);

       }

  getMuiTheme = () =>
  createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: '#FF000'
          },
          paper: {
            boxShadow: 'none'
          },
          responsiveScroll: {
            marginTop: '50px'
          }
        },
        MUIDataTableHeadCell: {
          root: {
            padding: '0px',
            verticalAlign: 'top!important'
          },
          head: {
            fontSize: '0.7rem',
            fontWeight: '500',
            padding: '0px',
            verticalAlign: 'textTop'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: '0px',
            fontSize: '0.70rem!important',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            padding: '0px 8px 0px 0px'
          }
          // responsiveStacked: {
          //   height: "34px!important",
          //   fontSize: "0.70rem!important"
          // },
          // cellStacked: {
          //   height: "44px!important",
          //   fontSize: "0.70rem!important",
          //   // width: "50px!important",
          //   textAlign: "right!important"
          // }
        },
        MUIDataTableSelectCell: {
          root: {
            borderBottom: '0px'
          }
        },
        MUIDataTableToolbarSelect: {
          root: {
            display: 'inlineBlock'
          }
        }
      }
    });

  // columns = [{
  //   name: 'Avatar',
  //   options: { filter: false }
  // },
  columns = [
  {
    name: 'Token',
    options: { filter: true }
  },    
  {
    name: 'Firstname',
    options: { filter: true }
  },
  {
    name: 'Lastname',
    options: { filter: true }
  },
  {
    name: 'Email',
    options: { filter: true }
  },
  {
    name: 'Token Type',
    options: { filter: true }
  },
  {
    name: 'Child ID',
    options: { filter: true }
  },
  {
    name: 'Token/Credit',
    options: { filter: true }
  }
  // {
  //   name: 'Send Token',
  //   options: { filter: true }
  // }
  // {
  //   name: 'Account',
  //   options: { filter: true }
  // }
  // {
  //   name: 'User Type',
  //   options: { filter: true }
  // },
  // {
  //   name: 'Gender',
  //   options: { filter: true }
  // },
  // {
  //   name: 'Points',
  //   options: { filter: true }
  // },
  // {
  //   name: 'Sector',
  //   options: { filter: true }
  // },
  // {
  //   name: 'Operation',
  //   options: { filter: true }
  // },
  // {
  //   name: 'Position',
  //   options: { filter: true }
  // },
  // {
  //   name: 'Country',
  //   options: { filter: true }
  // },
  // {
  //   name: 'Status',
  //   options: { filter: false }
  // },
  // {
  //   name: 'DateRegistered',
  //   options: { filter: false }
  // },
  // {
  //   name: 'EC',
  //   options: { filter: false }
  // },
  // {
  //   name: 'Feedback',
  //   options: { filter: false }
  // }
  ];

  options = {
    filterType: 'radio',
    sort: true,
    rowHover: true,
    responsive: 'standard',
    print: false,
    download: false,
    selectableRows: false, //'multiple',
    fixedHeader: false,
    tableHeaderStyle: { borderRight: '40px solid transparent' },
    onFilterChange: (changedColumn, filterList) => {
      // console.log(changedColumn, filterList);
    },
    // expandableRows: true,
    // renderExpandableRow: (rowData, rowMeta) => <UserCardList user={rowData} {...this.props} />,

    customToolbarSelect: e => {
     // console.log('customToolbarSelect props: ', this.props.userdata.userType);

      return (
        <EnhancedTableToolbar
          userType={this.props.userdata.userType}
          numSelected={e.data.length}
          multiple={e.data.length > 0}
          options={{
            elevation: 0
          }}
          onOpenChangePassword={this.handleOpenChangePassword}
          onOpenUserTypeDialog={this.handleUserTypeDialog}
          handleEditUserDialog={this.handleEditUserDialog}
          // onResendWelcomeEmail={() =>
          //   this.handleResendWelcomeEmail(this.props.resendWelcomeEmail)
          // }
          onChangeUserData={changeType => this.handleChangeUserData(changeType)}
          onDeleteUser={() =>
            e.data.length === 1? 
              this.handleDeleteUser()
              :
              this.handleBulkDeleteUser(this.props.deleteToken, e.data.length)
          }
        />
      )
    },
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      let { classes, userdata, users } = this.props
      
      let data =
        users &&
        Object.keys(users).map((v, i) => {
          return { data: users[v], uid: users[v].id }
        })
        console.log('testhd data: ', data );

      //............................................
      let selectedData =
      allRowsSelected &&
        Object.keys(allRowsSelected).map((v, i) => {
        //  return { data: allRowsSelected[v], uid: allRowsSelected[v].dataIndex }
        //  return { data: data[allRowsSelected[v].dataIndex], uid: allRowsSelected[v].dataIndex }
        return { data: users[allRowsSelected[v].dataIndex], uid: allRowsSelected[v].dataIndex }
        })
      //............................................
    
      if (allRowsSelected.length === 1) {
        if (currentRowsSelected[0] !== undefined) {
          window.su = data[currentRowsSelected[0].dataIndex]
          this.handleSelectedUser(window.su)
        }
      }
      else
      {
        window.su = selectedData;
      }

    }
  };

  state = {
    data: [],
    openGroupAdminDialog: false,
    openGroupEditAdminDialog: false,
    openDialog: false,
    openUploadDialog: false,
    openPasswordChange: false,
    openUserType: false,
    selectedRowData: {},
    selectedUserType: "",
    selectedFile: null,
    showFdelete: false,
    selectDelete: 0,
    profilesDelete: {},
    anchorEl: null,
    edit: false,
    editedUser: {},
    uploading: false
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: !this.state.openDialog })
  };

  handleUserTypeDialog = () => {
    this.setState({
      openUserType: !this.state.openUserType,
      selectedUserType: window.su.data.userDescription
    })
  };
  handleOpenChangePassword = () => {
    this.setState({
      openPasswordChange: !this.state.openPasswordChange
    })
  };

  handleSelectedUserType = userTypeSelected => {
    const { changeUserType, userdata } = this.props
    let user = window.su.data
    user.uid = window.su.uid
    let params = {
      usersData: user,
      currentloggedinuser: userdata,
      selectedUserType: userTypeSelected
    }
    changeUserType(params)
    this.handleUserTypeDialog()
  };
  handleUploadDialog = () => {
    this.setState({ openUploadDialog: !this.state.openUploadDialog })
  };
  handleChangeUserData = changeType => {
    const { updateCompanyUser, getAllCompanyUsers, userdata } = this.props
    let userProfile = window.su.data
    userProfile.uid = window.su.uid
    let params = {
      changeType: changeType,
      userProfile: userProfile
    }
    //updateCompanyUser(params)
    getAllTokens({
      userID: userdata.userID,
      orderChild: 'userID'
    })
  };

  // handleResendWelcomeEmail = resendWelcomeEmail => {
  //   let user = window.su
  //   resendWelcomeEmail({
  //     userProfile: { uid: user.uid, ...user.data }
  //   })
  // };

  componentWillUnmount() {
    window.su = null
  }

  handleSelectedUser = currentRowsSelected => {
    // this.setState({
    //  selectedRowData: {
    //    uid: rowUserData[5].props.id,
    //    userID: rowUserData[5].key,
    //    firstName: rowUserData[1],
    //    lastName: rowUserData[2],
    //    email: rowUserData[3],
    //    userType: rowUserData[4]
    //  }
    // });
  };

  handleSubmitUser = usersData => {
    console.log('green createToken usersData : ', usersData);
    console.log('green createToken userData : ', this.props.userdata);

    let params = {
      email: this.props.userdata.email,
      firstName: this.props.userdata.firstName,
      lastName: this.props.userdata.lastName,
      companyID: this.props.userdata.companyID,
      userID: this.props.userdata.userID, // userID of person who owns the Token
      personID: usersData.personID,
      personName: usersData.personName,
      personLastName: usersData.personLastName,
      personEmail: usersData.personEmail,
      tokenType: usersData.tokenType,
      amount: usersData.amount
    }
    for (let i = 0; i < usersData.amount; i++) {
      console.log('green createToken params : ', params);  
      this.props.createToken(params);
    }
        this.props.getAllTokens({
          userID: this.props.userdata.userID,
          orderChild: 'userID'
        });
        this.handleCloseDialog()
  };

  componentDidUpdate() {
    // if (this.props.user_exists && this.props.user_exists.proceed === true) {
    // create new user if everything is fine

    // }
  }

  componentDidMount() {
    let { classes, userdata, getAllTokens } = this.props
    getAllTokens({
      userID: userdata.userID,
      orderChild: 'userID'
    })
    // getAllCompanyUsers({
    //   companyID: userdata.companyID,
    //   orderChild: 'companyID'
    // })
    // getAllCompanySettings({
    //   companyID: userdata.companyID,
    // })
    // getAllGeneralSettings({
    //   companyID: userdata.companyID,
    // })
    // var body = {
    //   type: SIMPLE_VALUES,
    //   surveyid: false      
    //   }
    // simpleAction(body);
  }

  formatUserList(param) {
    const temp = []
    param.map((user) => {
      temp.push({
        token: user[1],
        firstName: user[2],
        lastName: user[3],
        email: user[4],
        tokenType:user[5],
        child: user[6],
        // grandChild: user[5],
      })
    })

    return temp
  }

  timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    //var month = months[a.getMonth()];
    var month = a.getMonth();
    month = month + 1;

    var date = a.getDate();
    var vdate = date.toString();
    var vmonth = month.toString();
    var vvdate;
    var vvmonth;
    console.log('date len: ', vdate.length);
    /////////////////////
    if (vdate.length===1)
     {
       var s = "0" + date;
       vvdate = s.substr(s.length-2);
     }
     else {
       vvdate = vdate;
     }
     ///////////////////////////
     if (vmonth.length===1)
     {
       var s = "0" + month;
       vvmonth = s.substr(s.length-2);
     }
     else {
      vvmonth = month;
     }
     ////////////////////
     // vdate = (Math.round(date * 100) / 100).toFixed(2);
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
   // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    var time = year + '/' + vvmonth + '/' + vvdate;
    return time;
  }

  transformTableData = () => {
    let { classes, userdata, getAllCompanyUsers, users } = this.props
    let dataListClone = [];
    var tokenMoney;
    var tokenType;

    console.log('green transformTableData : ', users); 
    let tokens = users; //users.tokens; 
    if (tokens) {
    dataListClone = Object.keys(tokens).map((userID, index) => {
      //CALF PRODUCTS MODIFY HERE
      if (tokens[userID].tokenType==='app') {
        tokenMoney = '$54'
        tokenType = 'ThinkLead own app'
      }
      if (tokens[userID].tokenType==='ex1') {
        tokenMoney = '$14'
        tokenType = 'Executive Club 1'
      }
      if (tokens[userID].tokenType==='ex2') {
        tokenMoney = '$2.6'
        tokenType = 'Executive Club 2'
      }

      // if (tokens[userID].tokenType==='app') 
      // {tokenMoney = 'R1000'} else {tokenMoney = 'R500'}

      return [
        // let tpersonID = params.personID;
        // let tpersonName = params.personName;
        // let tpersonLastName = params.personLastName;
        // let tpersonEmail = params.personEmail;

        tokens[userID].token ? tokens[userID].token : '',
        tokens[userID].firstName ? tokens[userID].tpersonName : '',
        tokens[userID].lastName ? tokens[userID].tpersonLastName : '',
        tokens[userID].email ? tokens[userID].tpersonEmail : '',
        //tokens[userID].tokenType==='app' ? 'ThinkLead own app' : 'Executive club',
        tokens[userID].tokenType ? tokenType : '',
        tokens[userID].child ? tokens[userID].child : '',
        tokens[userID].isCredit ? <font color="red">{tokenMoney}</font> : "TOKEN",
        // tokens[userID].isCredit ? null : 
        // <Button variant="outlined" size="small"
        //  onClick={this.handleOpen}
        // >
        //  SEND TOKEN
        // </Button>
      ]
    })
    console.log('green dataListClone ', dataListClone);
  }
    return dataListClone
  };

  getToken = (props) => {
    for (let x in props) {
      return props[x].userID
    }
  }

  handleDeleteUser() {
    const { deleteToken, showToast } = this.props
    let userProfile = window.su.data
    console.log('green handleDeleteUser 1', userProfile);
    //var tok = this.props.users.tokens;

    //var lenghtTok = Object.keys(tok).length;

    //console.log('green handleDeleteUser 1a', lenghtTok);
    //console.log('green handleDeleteUser 1b', this.props.users.tokens[1].userID);

    // let tokens = {
    //   userID: this.props.tokens.userID,
    //   firstName: this.props.tokens.firstName,
    //   lastName: this.props.tokens.lastName,
    //   email: this.props.tokens.email
    // }
   // console.log('green handleDeleteUser 1', tokens);

    // let userProfile = window.su.data
    // userProfile.uid = window.su.uid

    // var currUserID = this.props.userdata.userID;

    // if (currUserID == userProfile.userID) {
    //   showToast({
    //     type: 'SHOW_TOAST',
    //     open: true,
    //     variant: 'warning',
    //     message: "Cannot delete your own account!"
    //   });

    //   return
    // }

    const answerEmail = prompt(
      `Are you sure you want to Delete the token \n ( Please type in Token !) `
    );

    if (answerEmail === '' || answerEmail === null || answerEmail !== userProfile.token) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: "Token cannot be deleted: Incorrect Token provided."
      });

      return;
    }

    deleteToken({
      userProfile: userProfile
    })
    showToast({
      type: 'SHOW_TOAST',
      open: true,
      variant: 'success',
      message: "Deleted " + userProfile.Token + " successfully!"
    });
  }

  handleBulkDeleteUser( deleteToken, numSelected ) {
    let { classes, userdata, users, showToast} = this.props
    console.log('green handleDeleteUser 2',);
     let userProfile = window.su

     var currTokenID = numSelected //this.props.userdata.userID;
     var allDeletedUsers = '';
    //var suicide = false;

    // let sdata =
    // userProfile &&
    // Object.keys(userProfile).map((v, i) => {
    //   console.log('green handleDeleteUser 3 ', userProfile[v].data.tokenID);
    //   console.log('green handleDeleteUser 4 ', currTokenID);

    //   allDeletedUsers = allDeletedUsers + '\n' + userProfile[v].data.tokenID + '\n'
    //   if (currTokenID == userProfile[v].data.tokenID) {
    //     suicide = true;
    //   }
    // })

    // if (suicide == true) {
    //   showToast({
    //     type: 'SHOW_TOAST',
    //     open: true,
    //     variant: 'warning',
    //     message: "Cannot delete your own account!"
    //   });

    //   return
    // }

    const answerYes = prompt(
      `Are you sure you want to Delete these tokens \n ( Please type 'yes' if you want to proceed !) `
    );

    console.log("answer: ", 'yes');

   // if (answerYes === '' || answerYes === null || answerYes !== 'yes' || answerYes !== 'Yes' || answerYes !== 'y') {
    if (answerYes === '' || answerYes === null || answerYes !== 'yes') {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: "Delete process cancelled."
      });

      return;
    }

      let data =
      userProfile &&
      Object.keys(userProfile).map((v, i) => {
        deleteToken({
         userProfile: userProfile[v].data
       }) 
      })

      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'success',
        message: "Deleted " + "\n" + allDeletedUsers + " successfully!"
      });
  }

  yyhandleDeleteUser(deleteToken, numSelected) {
    const { showToast } = this.props

    let userProfile = window.su.data
    userProfile.uid = window.su.uid
    // console.log('testhd userProfile: ', userProfile);

    var currUserID = this.props.userdata.userID;

    if (currUserID == userProfile.userID) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: "Cannot delete your own account!"
      });

      return
    }

    // const answerEmail = prompt(
    //   `Are your sure you want to remove ${userProfile.firstName} ${userProfile.lastName} \n ( Please type in the email of the User !) `
    // );

    // console.log("answer: ", answerEmail);

    // if (answerEmail === '' || answerEmail === null || answerEmail !== userProfile.email) {
    //   showToast({
    //     type: 'SHOW_TOAST',
    //     open: true,
    //     variant: 'warning',
    //     message: "User cannot be deleted: Incorrect email address provided."
    //   });

    //   return;
    // }

    // showToast({
    //   type: 'SHOW_TOAST',
    //   open: true,
    //   variant: 'error',
    //   message: "User deleted."
    // });

    // return;
    // deleteCompanyUser({
    //   userProfile: userProfile
    // })
  }

  xxhandleDeleteUser() {
    const { deleteToken, showToast } = this.props

    let userProfile = window.su.data
    userProfile.uid = window.su.uid

    var currUserID = this.props.userdata.userID;

    if (currUserID == userProfile.userID) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: "Cannot delete your own account!"
      });

      return
    }

    const answerEmail = prompt(
      `Are you sure you want to Delete ${userProfile.firstName} ${userProfile.lastName} \n ( Please type in the email of the User !) `
    );

    // console.log("answer: ", answerEmail);

    if (answerEmail === '' || answerEmail === null || answerEmail !== userProfile.email) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: "User cannot be deleted: Incorrect email address provided."
      });

      return;
    }

    // showToast({
    //   type: 'SHOW_TOAST',
    //   open: true,
    //   variant: 'error',
    //   message: "User deleted."
    // });

    // return;

    deleteToken({
      userProfile: userProfile
    })
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true, edit: false, editedUser: {} })
    this.handleClose()
  };

  handleEditUserDialog = () => {
    this.setState({ openDialog: true, edit: true, editedUser: window.su.data })
  };

  formatDate(date) {
    let d = new Date(date)


    let month = '' + (d.getMonth() + 1)


    let day = '' + d.getDate()


    let year = d.getFullYear()


    let hours = '' + d.getHours()


    let minutes = '' + d.getMinutes()


    let seconds = '' + d.getSeconds()


    let milliseconds = '' + d.getMilliseconds()

    let today = new Date()


    let thisYear = today.getFullYear()

    // get week day name Sunday - Saturday
    let dayName = this.getDayMonthStr(d.getDay(), 'day', true)

    // get month name
    let monthName = this.getDayMonthStr(d.getMonth(), 'month', true)

    // add preceding zeros where needed
    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    if (hours.length < 2) {
      hours = '0' + hours
    }
    if (minutes.length < 2) {
      minutes = '0' + minutes
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds
    }
    if (milliseconds.length < 2) {
      milliseconds = '0' + milliseconds
    }

    // format thoughts date
    // Monday, 04 December 2017 14:21:56
    let thoughtDate =
      dayName +
      ', ' +
      day +
      ' ' +
      monthName +
      ' ' +
      year +
      ' ' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds

    return thoughtDate
    // return [year, month, day].join('-');
  }

  getDayMonthStr(val, dateParam, long) {
    // days
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ]
    let daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']

    // Months
    let monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    let monthNamesShort = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    if (dateParam === 'day' && long === true) {
      // return day in long format
      return days[val]
    } else if (dateParam === 'day' && long === false) {
      // return days in short
      return daysShort[val]
    }
    if (dateParam === 'month' && long === true) {
      // return month in long format
      return monthNames[val]
    } else if (dateParam === 'month' && long === false) {
      // return month in short
      return monthNamesShort[val]
    }
  }

  openGroupAdmin = event => {
    this.setState({ openGroupAdminDialog: true })
  }

  openGroupEditAdmin = event => {
    this.setState({ openGroupEditAdminDialog: true })
  }

  openEditGroupAdmin = event => {
    this.setState({ openEditGroupAdminDialog: true })
  }

  onChangeHandler = event => {
   const {simpleAction} = this.props;
    let companyID = this.props.userdata.companyID
    let companyName = this.props.userdata.companyName
    // let companyID = "-LoacuJOqQP7sZU7_sFQ";
    // let companyName = "Global Leadership Initiatives";
    let reader = new FileReader()
    let timeInMs = Date.now()
    let datetime = new Date(timeInMs)

    datetime = this.formatDate(datetime)

    reader.onload = function(e) {
      let data = e.target.result
      var workbook;
      // if (data) {
      //   this.setState({ uploading: true });
      // }
      var body = {
        type: SIMPLE_VALUES,
        surveyid: true      
        }
      simpleAction(body);

      console.log('green uploading')
      // [NEED TO BE FIX] need to fix as per need
      // let workbook = xlsx.read(data, {
      //   type: 'binary'
      // })

      function to_json(workbook) {
        let result = {}
        // [NEED TO BE FIX] need to fix as per need
        workbook.SheetNames.forEach(function(sheetName) {
          // let roa = xlsx.utils.sheet_to_row_object_array(
          //   workbook.Sheets[sheetName]
          // )
          // if (roa.length > 0) {
          //   result[sheetName] = roa
          // }
        })
        return result
      }

      function randomString(length) {
        let text = "";
        let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789";
        for (var i = 0; i < length; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
      }
      //var workbook;
      let xls_json = to_json(workbook)
      let users

      Object.keys(xls_json).forEach(key => {
        users = xls_json[key]
        users.forEach((userobj, index) => {
          let email = userobj.Email

          if (email !== undefined && email.length > 5) {
            let userID = firebase.database
              .ref()
              .child('newUploadUsers')
              .push().key
            let defPassword = randomString(15) //"PASS123456789";
            // let defPassword =
            //   Math.random()
            //     .toString(36)
            //     .substring(2, 18) +
            //   Math.random()
            //     .toString(36)
            //     .substring(2, 18)
            defPassword = defPassword.toUpperCase() //"PASS123456789";

            let data = {
              companyID: companyID,
              companyName: companyName,
              dateRegistered: timeInMs,
              email: userobj.Email,
              firstName: userobj.Firstname,
              lastName: userobj.Lastname,
              surName: userobj.Surname ? userobj.Surname : "",
              gender: userobj.gender ? userobj.gender : "",
              points: userobj.points ? userobj.points : "",
              race: userobj.race ? userobj.race : "",
              position: userobj.position ? userobj.position : "",
              nationality: userobj.nationality ? userobj.nationality : "",
              idNumber: userobj.idNumber ? userobj.idNumber : "",
              department: userobj.department ? userobj.department : "",
              operation: userobj.operation ? userobj.operation : "",
              password: defPassword,
              stringDateRegistered: userobj.stringDateRegistered ? userobj.stringDateRegistered : "",
              userDescription: userobj['User Type'],
              newUploadUserID: userID,
              userType: 8,
              companyID_userType: companyID + '_' + userobj['User Type']
            }

            let selectedUserType = ''

            selectedUserType = [
              { value: 3, description: 'Public Contributor' },
              { value: 7, description: 'Company Admin' },
              { value: 8, description: 'Standard User' },
              { value: 10, description: 'Corporate I-Leader' },
              { value: 11, description: 'Organisational Contributor' },
              { value: 4, description: 'Global Contributor' },
              { value: 14, description: 'Client Admin' },
              { value: 12, description: 'Delegate' }
            ].find((userType) => {
              if (userType.description === data.userDescription) {
                return userType.value
              }
            })

            data.userType = selectedUserType.value

            // console.log('bulk upload', data);
            // Write the new rating data
            let updates = {}
           
            updates['/newUploadUsers/' + userID] = data

            // console.log("new upload users updates", updates);
            firebase.database.ref().update(updates, error => {
              if (error) {
                showToast({
                  type: 'SHOW_TOAST',
                  open: true,
                  variant: 'error',
                  message: error.message
                })
              } else {

                var body = {
                  type: SIMPLE_VALUES,
                  surveyid: false      
                  }
                simpleAction(body);

                showToast({
                  type: 'SHOW_TOAST',
                  open: true,
                  variant: 'success',
                  message: 'Bulk file containing users has been uploaded.'
                })
              }
            })
          }
        })
      })
    }
    this.handleClose()
    console.log('green green close thing')
    reader.readAsBinaryString(event.target.files[0])
    event.target.value = ''
  };

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget })
    //console.log(this.state.anchorEl)
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, match, location, users, showToast } = this.props

    let StyledMenu = withStyles({
      paper: {
        border: '1px solid #d3d4d5',
        backgroundColor: LIGHTGREY
      }
    })((props) => (
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        {...props}
      />
    ))

    let StyledMenuItem = withStyles((theme) => ({
      root: {
        '&:focus': {
          'backgroundColor': theme.palette.primary.main,
          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.common.white
          }
        }
      }
    }))(MenuItem)

    return (

      <Paper style={{ maxWidth: '120vw' }} className={classes.pageHeader}>
       <div>
       { this.props.simplething===true ?
        <div style={{
          width:'100%',
          height: '20px'
          }}><LeaderLoader /></div>
         :null} 
         </div>

        <div>
          <Button
            className={classes.btn}
            aria-controls='customized-menu'
            aria-haspopup='true'
            variant='outlined'
            color='primary'
            size='small'
            onClick={this.handleClick}
          >
            ACTIONS
            <ArrowDropDownIcon />
          </Button>
          <StyledMenu
            id='customized-menu'
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            {this.props.userdata.userType===7 ?
            <StyledMenuItem
              onClick={this.handleOpenDialog}
            >
              <ListItemIcon>
                <PersonAddIcon fontSize='medium' />  
              </ListItemIcon>
              <ListItemText primary='Send Token' />
             
            </StyledMenuItem>
             :null}

            {this.props.userdata.userType===7 ?
            <StyledMenuItem
            component={Link}
            to={`/companyadmin`}
            variant='contained'
          >
           
            <ListItemIcon>
              <GroupWorkIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Club Admin' />
          </StyledMenuItem>
            :null}

          </StyledMenu>

        </div>

        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title={'Tokens'}
            data={this.transformTableData()}
            columns={this.columns}
            options={this.options}
            {...this.props}
          />
        </MuiThemeProvider>

        {/* <GroupAdminDialog
          open={this.state.openGroupAdminDialog}
          closeGroupAdminDialog={this.handleCloseGroupAdminDialog}
          columns={this.columns}
          users={this.formatUserList(this.transformTableData())}
        />

        <GroupEditAdminDialog
          open={this.state.openGroupEditAdminDialog}
          closeGroupAdminDialog={this.handleCloseGroupEditAdminDialog}
          columns={this.columns}
          users={this.formatUserList(this.transformTableData())}
        /> */}

        <AddTokensDialog
          open={this.state.openDialog}
          edit={this.state.edit}
          editedUser={this.state.editedUser}
          onSubmitUserClick={this.handleSubmitUser}
          updateOnSave={this.state.openUpdateDialog}
          onOpenAddUserDialog={this.handleCloseDialog}
          userdata={this.props.userdata}
        />

        <UserTypeDialog
          open={this.state.openUserType}
          onSubmitSelectedUserType={this.handleSelectedUserType}
          selectedUserType={this.state.selectedUserType}
          onOpenUserTypeDialog={this.handleUserTypeDialog}
          userdata={this.props.userdata}
        />
        <ChangeUserPasswordDialog
          onOpenChangePassword={this.handleOpenChangePassword}
          open={this.state.openPasswordChange}
        />

      </Paper>
     
    )
  }
}


const TokensStyled = withStyles(styles)(Tokens)

const mapStateToProps = (state, ownProps) => {
  return {
    userdata: state.userDialog.userDetails[0],
    //users: state.users,
    users: state.allTokens.tokens,
    people: state.users.users,
    user_exists: state.users.userexists,
    showprogress: state.loader.showloader,
    simplething: state.simpleVal.simplething
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllTokens,
    //getAllCompanySettings,
    //getAllGeneralSettings,
    deleteToken,
    createToken,
    checkUserExists,
    checkUserExistsEdit,
    //resendWelcomeEmail,
    //updateCompanyUser,
    changeUserType,
    showLoader,
    showToast,
    hideLoader,
    editUser,
    simpleAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TokensStyled)
