/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'
import MessageIcon from '@material-ui/icons/Message'
import MicIcon from '@material-ui/icons/Mic'
import VideoIcon from '@material-ui/icons/Videocam'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import { teal, red, blue, green } from '@material-ui/core/colors'
import { connect } from 'react-redux'
// import { fetchPLDPFavorites } from './../../store/actions/pldpActions'
import PLDPThoughts from './PLDPThoughts'
import PLDPArticles from './PLDPArticles'
import PLDPPodcasts from './PLDPPodcasts'
import PLDPVideos from './PLDPVideos'

function TabContainer(props) {
  return props.children
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    textAlign: 'right'
  },
  tabRoot: {
    'textAlign': 'right',
    'flexDirection': 'row',
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'padding': '0 11px',
    'minHeight': '61px',
    // '&:hover': {
    //   color: '#40a9ff',
    //   opacity: 1,
    // },
    '&$tabSelected': {
      color: '#000000'
    }
    // '&:focus': {
    //   color: '#40a9ff',
    // },
  },
  tabSelected: {},
  tabsIndicator: {
    backgroundColor: '#000000'
  },
  videoIcon: {
    color: red[500]
  },
  messagesIcon: {
    color: teal[500]
  },
  articlesIcon: {
    color: blue[500]
  },
  podcastIcon: {
    color: green[500]
  }
})

class MyFavorites extends React.Component {
	state = {
	  tabValue: 0
	};
	componentWillMount() {}
	handleChange = (e, tabValue) => {
	  this.setState({ tabValue })
	};

	render() {
	  const { classes } = this.props
	  const { tabValue } = this.state

	  return (
	    <div className={classes.root}>
	      <Tabs
	        value={tabValue}
	        onChange={this.handleChange.bind(this)}
	        indicatorColor='primary'
	        textColor='primary'
	        variant='scrollable'
	        scrollButtons='on'
	        classes={{ indicator: classes.tabsIndicator }}
	      >
	        <Tab
	          classes={{ root: classes.tabRoot, wrapper: classes.tabRoot, selected: classes.tabSelected }}
	          icon={<MessageIcon className={classNames(classes.messagesIcon)} align='left' />}
	          label='Messages'
	        />
	        <Tab
	          classes={{ root: classes.tabRoot, wrapper: classes.tabRoot, selected: classes.tabSelected }}
	          icon={<RemoveRedEyeIcon className={classNames(classes.articlesIcon)} />}
	          label='Articles'
	        />
	        <Tab
	          classes={{ root: classes.tabRoot, wrapper: classes.tabRoot, selected: classes.tabSelected }}
	          icon={<MicIcon className={classNames(classes.podcastIcon)} />}
	          label='Podcasts'
	        />
	        <Tab
	          classes={{ root: classes.tabRoot, wrapper: classes.tabRoot, selected: classes.tabSelected }}
	          icon={<VideoIcon className={classNames(classes.videoIcon)} />}
	          label='Videos'
	        />
	      </Tabs>

	      {tabValue === 0 && (
	        <TabContainer>
	          <PLDPThoughts />
	        </TabContainer>
	      )}
	      {tabValue === 1 && (
	        <TabContainer>
	          <PLDPArticles />
	        </TabContainer>
	      )}
	      {tabValue === 2 && (
	        <TabContainer>
	          <PLDPPodcasts />
	        </TabContainer>
	      )}
	      {tabValue === 3 && (
	        <TabContainer>
	          <PLDPVideos />
	        </TabContainer>
	      )}
	    </div>
	  )
	}
}

MyFavorites.propTypes = {
  classes: PropTypes.object.isRequired
}
const Favorites = withStyles(styles)(MyFavorites)
const mapStateToProps = (state) => {
  return {
    userdata: state.userDialog.userDetails[0]
  }
}
export default connect(mapStateToProps, null)(Favorites)
