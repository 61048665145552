import {
  INTERNAL_PODCASTS,
  EXTERNAL_PODCASTS,
  MY_PODCASTS,
  CREATE_PODCAST_SUCCESS,
  PLDP_PODCASTS
} from "../actions/types";

const initState = {
  podcasts: {},
  pldppodcasts: {},
  externalpodcasts: {},
  mypodcasts: [],
  podcast: {},
  CREATE_PODCAST: ""
};

const podcastsReducer = (state = initState, action) => {
  switch (action.type) {
    case INTERNAL_PODCASTS:
      return {
        ...state,
        podcasts: action.podcasts,
        type: INTERNAL_PODCASTS,
        CREATE_PODCAST: ""
      };
    case EXTERNAL_PODCASTS:
      return {
        ...state,
        externalpodcasts: action.externalpodcasts,
        type: EXTERNAL_PODCASTS,
        CREATE_PODCAST: ""
      };
    case MY_PODCASTS:
      return {
        ...state,
        mypodcasts: action.mypodcasts,
        type: MY_PODCASTS,
        CREATE_PODCAST: ""
      };
    case PLDP_PODCASTS:
      return {
        ...state,
        pldppodcasts: action.pldppodcasts,
        type: PLDP_PODCASTS,
        CREATE_PODCAST: ""
      };
    case CREATE_PODCAST_SUCCESS:
      return {
        ...state,
        CREATE_PODCAST: CREATE_PODCAST_SUCCESS
      };
    case "CLEAR_PODCAST_SUCCESS":
      return {
        ...state,
        CREATE_PODCAST: ""
      };
    default:
      return state;
  }
};

export default podcastsReducer;
