import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List, ListItem, ListItemText, ListSubheader, Avatar, ListItemAvatar,
  Grid, Typography, Checkbox, ListItemSecondaryAction
} from '@material-ui/core'
import {
  withStyles,
} from '@material-ui/core/styles'
import { LIGHTGREY } from "../../utils/color_utils";
import { PHOTO_PROFILE } from '../../utils/photo_utils'

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

class UsersList extends Component {
  render() {
    const { classes, participants } = this.props;

    return (
      <div>
        <List subheader={
          <ListSubheader style={{ backgroundColor: `${LIGHTGREY}`, fontWeight: "bold", fontSize: "13px", lineHeight: "28px", color: "#000", borderRadius: "4px", position: 'static' }}>
            <Grid container spacing={2}>
              <Grid item xs={11} sm={11}>Participants</Grid>
              <Grid item xs={1} sm={1}>
                <Checkbox
                  inputProps={{ 'aria-labelledby': 'select-all-users' }}
                  onChange={e => {
                    this.props.handleSelectAll(e)
                  }}
                />
              </Grid>
            </Grid>
          </ListSubheader>} dense className={classes.root}>
          {participants && Object.keys(participants).map((participant, id) => {
            const labelId = `checkbox-list-secondary-label-${id}`;
            return (
              <ListItem key={id} button>
                <ListItemAvatar>
                  <Avatar
                    alt={participants[participant].firstName + ' ' + participants[participant].lastName}
                    src={PHOTO_PROFILE(participants[participant].photoURL)}
                    id={participants[participant].uid}
                  />
                </ListItemAvatar>
                <ListItemText id={participants[participant].userID} primary={participants[participant].firstName + ' ' + participants[participant].lastName} secondary={participants[participant].email} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    inputProps={{ 'aria-labelledby': labelId }}
                    checked={participants[participant].selected}
                    onChange={e => {
                      this.props.handleSelected(participants[participant])
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    )
  }
}

UsersList.propTypes = {
  classes: PropTypes.object,
  participants: PropTypes.any,
}

const UsersListStyled = withStyles(styles)(UsersList)

export default (UsersListStyled);
