import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  Typography, IconButton, Button, InputAdornment,
  FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';

class TrainingProgrammes extends Component {
  state = {
    group_name: ''
  }

  render() {
    const { classes } = this.props
    const trainingProgrammes = this.props.company_programmes;
    return (
      <div>
        <InputLabel id="group-programme-label" style={{ marginTop: "14px", fontSize: "12px" }}>Programme Name</InputLabel>
        <Select
          labelId="group-programme-label"
          id="group-programme"
          value={this.props.group_name}
          onChange={e =>
            this.props.handleChange(e, "group_name")
          }
          inputProps={{
            name: "group_name",
            id: "group_name"
          }}
          fullWidth
        >
          {trainingProgrammes.map((label, id) => (
            <MenuItem value={label}>{label}</MenuItem>
          ))}
        </Select>
      </div>
    )
  }
}

TrainingProgrammes.propTypes = {
  classes: PropTypes.object
}

export default TrainingProgrammes;
