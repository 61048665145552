/* eslint-disable no-inline-comments */
import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Button, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addPLDPFavorites } from '../../store/actions/pldpActions'
import { withRouter } from 'react-router-dom'
import AddToPLDPDialog from '../Dialogs/AddToPLDPDialog'
import { showLoader } from '../../store/actions/loaderActions'
import StarRoundedIcon from '@material-ui/icons/StarRounded'

const styles = () => ({})
class AddPLPD extends Component {
  state = {
    openPLDPDialog: null
  };
  handleAddToPLDPFavorites = () => {
    const {
      post,
      postType,
      postTypeID,
      userdata
    } = this.props

    let params = {
      equalTo:
        post.dailyThoughtID || post.videoID || post.podcastID || post.newsID,
      post: post,
      orderChild: postTypeID,
      postType: postType,
      user: {
        PLDPUserID: userdata.userID,
        PLDPUserName: userdata.firstName + ' ' + userdata.lastName
      }
    }
    this.props.addPLDPFavorites(params)
    this.handlePLDPDialogToggle()
  };
  handlePLDPDialogToggle = () => {
    this.setState({
      openPLDPDialog: !this.state.openPLDPDialog
    })
  };

  componentDidUpdate() {
    if (
      this.props.message ===
      'Your LEAD has been successfully added to your Personal Library'
    ) {
      // this.props.history.push("/pldp");

      this.props.history.push({
        pathname: '/pldp',
        state: { detail: 1 }
      })
    }
  }
  render() {
    const { match } = this.props

    const showPLPD = !match.path.includes('/pldp')
    return (
      showPLPD && (
        <div>
          {/* <Button color="secondary" onClick={this.handlePLDPDialogToggle}>
            My LFP
          </Button> */}
          <Tooltip title='Add To Favourites'>
            <Button aria-label='add' onClick={this.handlePLDPDialogToggle}>
              <StarRoundedIcon style={{ fontSize: 25, color: '#ccbc74' }}/>
            </Button>
          </Tooltip>
          {this.state.openPLDPDialog ? (
            <AddToPLDPDialog
              postType={this.props.postType || 'thoughts'}
              onClickProceed={this.handleAddToPLDPFavorites}
              onCloseDialog={this.handlePLDPDialogToggle}
              open={this.state.openPLDPDialog}
            />
          ) : null}
        </div>
      )
    )
  }
}

const PLDPFavorites = withStyles(styles)(AddPLPD)

const mapStateToProps = state => {
  return {
    opentoast: state.toast.open,
    variant: state.toast.variant,
    message: state.toast.message
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      addPLDPFavorites,
      showLoader
    },
    dispatch
  )
})
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PLDPFavorites)
)
