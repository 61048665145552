
import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import firebase from "../firebase";
import ReactDOM from "react-dom";
import { APP_TYPE } from "./types";
//import { CloudDownload } from '@material-ui/icons';
import { AirplayOutlined } from '@material-ui/icons';

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5),
  }
});


class DownloadNote extends React.Component {
  constructor (props) {
    super(props);
    // console.log('>>>>>>>>>>>>>>>>props for DownloadNote: ',this.props)
    // console.log('>>>>>>>>>>>>>>>>props for window.location.origin: ',window.location)
    this.state = {
      idadded: false,
      nbr: 0
    };
  }

  chooseOS() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    var macstate = navigator.appVersion.indexOf("Mac");

    if (winstate !== -1) { winner = "win" }
    if (linuxstate !== -1) { winner = "linux" }
    if (macstate !== -1) { winner = "mac" }
    // console.log('chooseOS: ',winner);
    return winner;
  }

  componentDidMount() {
    //if (this.chooseOS()==="win") {
    setTimeout(this.getUserData, 1500)
    setTimeout(this.handleClick, 1500)
    //}
  }
  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
    }
  }
  getUserData = () => {
    var database = firebase.database;

    database
      .ref("exedownload/")
      .once("value")
      .then(snapshot => {
        const exedownload = [];
        if (snapshot.exists()===false) {this.setState({idadded: false, nbr: 1})}
        var pass=false;

          snapshot.forEach(childSnapshot => {
            if ( pass===false ) {
            if (childSnapshot.val().uid===this.props.usrIDD)
            { pass=true;
              this.setState({idadded: true, nbr: 1},() => {console.log('idadded(checkExeid):', this.state.idadded)}
            )} else {this.setState({idadded: false, nbr: 1})}
            }
        });
      });
  }


  render() {
    // console.log('inside rendering of DownloadNote>>>>>>>>>>>>>>>>>>>>>>>>');
    return (
      <div>
        {this.state.idadded === false && this.state.nbr === 1 ?
          <Note usrIDD={this.props.usrIDD} />
          : null}
      </div>
    )
  }
}

class Note extends React.Component {

  constructor (props) {
    super(props);
    // console.log('>>>>>>>>>>>>>>props for Note: ',this.props)
  }
  state = {
    open: false,
    idadded: false
  };

  // componentWillMount() {
  //   setTimeout(this.handleClick, 1500)
  // }


  chooseOSpublic() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var macstate = navigator.appVersion.indexOf("Mac");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    winner = 'www.google.com';

    var server = window.location.host
    switch (server) {
      case "thinklead.app":         //live public server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fpublic%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=df76331e-ea29-45b6-b8bb-b157379ab85b';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        break;

      case "glp-test.appspot.com":  //test server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fwindows%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=92dad5e5-ea6d-4bcd-8474-f326a35fcb1d';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fmac%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=a24e2ffb-eac8-4b41-9888-4a4085988ccb';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;
        break;

      default:
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fpublic%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=df76331e-ea29-45b6-b8bb-b157379ab85b';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        break;
    }

    return winner;
  }

  chooseOScorporate() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var macstate = navigator.appVersion.indexOf("Mac");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    //placeholder
    winner = 'www.google.com';

    var server = window.location.host
          switch(server) {
            case "thinklead.app":         //live corporate server
                if (winstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=0f92ef11-6f4b-4ade-afe2-fdf08ce101dc';
                  }
                if (macstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fpublic%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=709da6e2-5702-4e6d-9d46-1953252b075e';
                }  
                if (linuxstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
                }    
              break;
           
            case "glp-test.appspot.com":  //test server
                if (winstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fwindows%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=92dad5e5-ea6d-4bcd-8474-f326a35fcb1d';
                  }
                if (macstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fmac%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=a24e2ffb-eac8-4b41-9888-4a4085988ccb';
                }  
                if (linuxstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
                } 
              break;

            case "harmony.thinklead.co.za":  //Harmony
                if (winstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fcorporate%2Fharmony%2FLDP%20Support%20Portal%20Setup%200.1.0.exe?alt=media&token=1153215c-37c5-45f6-95e4-86b85a823225';
                  }
                if (macstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2Fharmony%2FLDP%20Support%20Portal-1.0.0.dmg?alt=media&token=a9ec35ef-34b9-4afb-b6a5-5b6cc1cf6a2a';  
                  }          
                if (linuxstate!=-1) {
                  winner = '';           
                  } 
            break; 

            default:
                if (winstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=0f92ef11-6f4b-4ade-afe2-fdf08ce101dc';
                  }
                if (macstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fpublic%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=709da6e2-5702-4e6d-9d46-1953252b075e';
                }  
                if (linuxstate!=-1) {
                  winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
                } 
              break;
          }
   
         return winner; 
  }


  handleSubmit(event) {
    // console.log('inside componentDidMount');
    // console.log('>>>>>>>>>>>>>>>>>>this.state.idadded in componentDidUpdate:',this.state.idadded);
    if (event === true) {
      if (this.state.idadded === true) {
        //do nothing, user already downloaded it
        //dont show modal
        // console.log('>>>>>>>>>>>> person already added>>>>><<<<<<<<<');
      } else { //virgin
        const uid = this.props.usrIDD;
        const dstatus = 1;
        this.setState({ idadded: false }, () => {
          firebase.database
            .ref("exedownload/" + uid + "/uid/").set(uid);
          firebase.database
            .ref("exedownload/" + uid + "/dstatus/").set(dstatus);
          this.setState({ idadded: true })
        })
        // console.log(">>>>>><>><<<<<>>>>>>DATA SAVED");
      }
    }
    this.handleClose();
  }

  handleClick = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  // handleClick = () => {
  //   this.setState({
  //     open: true
  //   });
  // };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {

    return (
      <div style={{ backgroundColor: 'white' }}>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          //open={open}
          open={this.state.open}
          //autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id"></span>}
          onClose={this.handleClose}
          action={[
            <div className="container" >

              <a style={{
                textDecoration: 'none', color: 'white',
                backgroundColor: 'none', paddingLeft: '8px',
                paddingRight: '8px', paddingTop: '9px', paddingBottom: '9px',
                marginTop: '20px'
              }} 
              href={
                'https://www.youtube.com/watch?v=Aj9LqiOjjFA'
              } 
              // href={
              //   (APP_TYPE === "PUBLIC") ?
              //     this.chooseOSpublic()
              //     : this.chooseOScorporate()
              // } 
              download target="main">
                <Button key="undo" style={{ color: 'white' }} onClick={() => this.handleSubmit(true)} >
                  <AirplayOutlined style={{ paddingRight: '15px', fontSize: 35 }} /> 
                  Click here to watch Onboarding Video
        
          </Button>
              </a>
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                //className={classes.close}
                //record in db when dismissed
                onClick={() => this.handleSubmit(true)}
              >
                <CloseIcon />
              </IconButton>

            </div>
          ]}
        />
      </div>
    );
  }
}


export default withStyles(styles)(DownloadNote);

