import { COLLECT_ANSWER, COLLECT_FINAL_ANSWER } from "./types";
import firebase from "../firebase";
const {v4: uuidv4} = require('uuid')
console.log('in collectedAnswers action');

function fillup(strIn) {
  var str = strIn;
  return str.split(' ').join('_');
}
export const appendAnswer = params => {
  if (params.type === COLLECT_ANSWER) {
    
    return (dispatch, getState) => {
        var valueID; //unique survey id for this group //uuidv4();
        var timeInMs = Date.now();
        var qnumber = params.questionNr+1;
        var newID;
        var OptionChosen = fillup(params.questionAnswer);
        //var OptionChosen = params.questionAnswer;
        var dbaseMain = firebase.database;
        var dbaseAppend = firebase.database;
        var dbaseFind = firebase.database;
        var valueID = params.surveyid;

        var i = 1;
        var x;  
        var isCompany;

        if (params.real_companyID) {
          isCompany = params.real_companyID;
        }
        else
        {
          isCompany = params.companyID;
        }

        var query = '/surveysAnalytics/' +isCompany+ '/' +valueID+ '/' +'Answer'+qnumber + '/' + OptionChosen;
        console.log('blue query: ', query);

        dbaseMain
        .ref(query) 
        .once("value")
        .then(snapshot => {
          console.log('snapshot i 1: ', i );
                  i = snapshot.val()+1;
                  console.log('snapshot i 2:', i );

                console.log('snapshot i 3: ', i );

           if (isNaN(i)===false) { console.log('snapshot i is numeric:', i)}
          //////////////////////////////////////////////////////////////////////          
        if (qnumber!==1) { // everything after first answer
          console.log('blue qnumber: ', qnumber);
          if (qnumber !== 0) {
            var newID = params.surveyDone; //unique survey id carried over from first question
            console.log('blue - valueID3: ', 'surveysAnalytics/' + isCompany + '/' + valueID + '/' +'groupID');
            var updates = {};
            updates['surveysAnalytics/' + isCompany + '/' + newID + '/' +['Answer'+qnumber]+ '/' + [OptionChosen]] = i;
            updates['surveysAnalytics/' + isCompany + '/' + newID + '/' +['Answer'+qnumber]+ '/' + 'thequestion'] = params.thequestion; 
            dbaseAppend
            .ref().update(updates);
          }
          else // qnumber = 0
          {
              newID = valueID; //FIRST RECORD; //unique survey id carried over from first question
              console.log('blue - valueID1: ', 'surveysAnalytics/' + isCompany + '/' + valueID + '/' +'groupID');

              var updates = {};
              updates['surveysAnalytics/' + isCompany + '/' + newID + '/' +'groupID'] = params.surveyid;
              dbaseMain
            .ref().update(updates)
            .then(() => {
              dispatch({
                type: COLLECT_ANSWER,
                surveyDone: newID,
                sgroupid: params.surveyid
              });
            })
            .catch(err => {
              dispatch({
                type: COLLECT_ANSWER,
                message: err.message
              });
            });          

            }

          //////////////////////////////////////////////////

          }
        else { // first entry do surveysData
          //newID = valueID; //FIRST RECORD
          var newID = params.surveyDone;
          console.log('blue - valueID2: ', 'surveysAnalytics/' + isCompany + '/' + newID + '/' +'groupID');

          var updates = {};
          updates['surveysAnalytics/' + isCompany + '/' + newID + '/' +['Answer'+qnumber]+ '/' + 'thequestion'] = params.thequestion;       
          updates['surveysAnalytics/' + isCompany + '/' + newID + '/' +'surveyDone'] = params.surveyDone;
          updates['surveysAnalytics/' + isCompany + '/' + newID + '/' +'surveyid'] = params.surveyid;
          updates['surveysAnalytics/' + isCompany + '/' + newID + '/' +'companyID'] = isCompany;
          //updates['surveysAnalytics/' + params.companyID + '/' + newID + '/' +'/groupID'] = params.groupID;
          updates['surveysAnalytics/' + isCompany + '/' + newID + '/' +['Answer'+qnumber]+ '/' + [OptionChosen]] = i;

          dbaseAppend
          .ref().update(updates)

          .then(() => {
    
            dispatch({
              type: COLLECT_ANSWER,
              surveyDone: newID,
              sgroupid: params.groupID
            });
          })
          .catch(err => {
            dispatch({
              type: COLLECT_ANSWER,
              message: err.message
            });
          });

        /////////////////////////////
       }

      })

      };
     }
    };
    