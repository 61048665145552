import { SURVEY_STATS_LIST } from "./types";
import firebase from "../firebase";

export const surveyStats = params => {
  if (params.type === SURVEY_STATS_LIST) {
    return (dispatch, getState) => {
    //  firebase.database.ref(`surveyAnalytics/${params.companyID}/${params.companyID}+'-'+${params.surveyid}`).on("value", function(data) {
    // firebase.database.ref("surveysAnalytics/"+params.companyID+"/"+params.groupID+"-"+params.surveyid+"/").on("value", function(data) {
    firebase.database.ref("surveysAnalytics/"+params.companyID+"/"+params.surveyid+"/").on("value", function(data) {

    console.log('inside surveyStatsAction companyID: ', params.companyID);
    console.log('inside surveyStatsAction : ', params);
    console.log('beebee inside surveyStatsAction data: ', data.val());

    if (data.val()) {     
          dispatch({
            type: SURVEY_STATS_LIST,
            survey_stats_list: data.val()
          });
    
        } else {
          dispatch({
            type: SURVEY_STATS_LIST,
            survey_stats_list: {}
          });
        }
      });
   } 
 }
};


// import { SURVEY_STATS_LIST } from "./types";
// import firebase from "../firebase";

// export const surveyStats = params => {
//   if (params.type === SURVEY_STATS_LIST) {
//     return (dispatch, getState) => {
//     //  firebase.database.ref(`surveyAnalytics/${params.companyID}/${params.companyID}+'-'+${params.surveyid}`).on("value", function(data) {
//     firebase.database.ref("surveysAnalytics/"+params.companyID+"/"+params.groupID+"-"+params.surveyid+"/").on("value", function(data) {
//     console.log('inside surveyStatsAction companyID: ', params.companyID);
//     console.log('inside surveyStatsAction groupID: ', params.groupID);
//     console.log('beebee inside surveyStatsAction data: ', data.val());

//     if (data.val()) {     
//           dispatch({
//             type: SURVEY_STATS_LIST,
//             survey_stats_list: data.val()
//           });
    
//         } else {
//           return (dispatch, getState) => {
//             dispatch({
//               type: SURVEY_STATS_LIST,
//               survey_stats_list: {}
//             });
//           };
//         }
//       });
//    } 
//  }
// };