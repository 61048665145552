import {
  ORG_THOUGHTS,
  ILEAD_THOUGHTS,
  CORP_ILEAD_THOUGHTS,
  GC_THOUGHTS,
  DAILY_THOUGHTS,
  MY_THOUGHTS,
  PLDP_THOUGHTS,
  CREATE_THOUGHT_SUCCESS,
  FILTERED_POST,
  SELECTED_GROUP_POSTS,
  SCHEDULED_GROUP_POSTS,
  SELECTED_GROUP_FILES,
  INPUT_HASHTAG,
  GET_TOP_HASHTAGS,
  ILEAD_LEADS,
  ILEAD_LEADS2,
  ILEAD_BLEADS
} from "../actions/types";

const initState = {
  thoughts: [],
  pldpthoughts: [],
  filteredpost: [],
  organisational: [],
  ilead: [],
  corpilead: [],
  gcthoughts: [],
  dailythoughts: [],
  mythoughts: [],
  thought: {},
  CREATE_THOUGHT: ""
};

const thoughtsReducer = (state = initState, action) => {
  switch (action.type) {
    case SELECTED_GROUP_POSTS:
      return {
        ...state,
        groupposts: action.groupposts,
        type: SELECTED_GROUP_POSTS,
        CREATE_THOUGHT: ""
      };
    case SCHEDULED_GROUP_POSTS:
      return {
        ...state,
        scheduledgroupposts: action.scheduledgroupposts,
        type: SCHEDULED_GROUP_POSTS,
        CREATE_THOUGHT: ""
      };
    case SELECTED_GROUP_FILES:
      return {
        ...state,
        groupfiles: action.groupfiles,
        type: SELECTED_GROUP_FILES,
        CREATE_THOUGHT: ""
      };
    case ORG_THOUGHTS:
      return {
        ...state,
        organisational: action.organisational,
        type: ORG_THOUGHTS,
        CREATE_THOUGHT: ""
      };
    case FILTERED_POST:
      return {
        ...state,
        filteredpost: action.filteredpost,
        type: FILTERED_POST,
        CREATE_THOUGHT: ""
      };
    case ILEAD_LEADS:
      console.log('green categories topleads Reducer : ', action.ilead)  
      return {
        ...state,
        topleads: action.ilead,
        type: ILEAD_LEADS,
        CREATE_THOUGHT: ""
      };
    case ILEAD_LEADS2:
    //  console.log('green categories topleads Reducer 2 : ', action.ilead)  
      return {
        ...state,
        topleads: action.ilead,
        type: ILEAD_LEADS2,
        CREATE_THOUGHT: ""
      };
    case ILEAD_BLEADS:
     // console.log('green categories topleads Reducer B : ', action.ilead)  
      return {
        ...state,
        topleads: action.ilead,
        type: ILEAD_THOUGHTS,
        CREATE_THOUGHT: ""
      };
    case ILEAD_THOUGHTS:
      return {
        ...state,
        ilead: action.ilead,
        type: ILEAD_THOUGHTS,
        CREATE_THOUGHT: ""
      };
    case CORP_ILEAD_THOUGHTS:
      return {
        ...state,
        corpilead: action.corpilead,
        type: CORP_ILEAD_THOUGHTS,
        CREATE_THOUGHT: ""
      };
    case GC_THOUGHTS:
      return {
        ...state,
        gcthoughts: action.gcthoughts,
        type: GC_THOUGHTS,
        CREATE_THOUGHT: ""
      };
    case DAILY_THOUGHTS:
      return {
        ...state,
        dailythoughts: action.dailythoughts,
        type: DAILY_THOUGHTS,
        CREATE_THOUGHT: ""
      };
    case MY_THOUGHTS:
      return {
        ...state,
        mythoughts: action.mythoughts,
        type: MY_THOUGHTS,
        CREATE_THOUGHT: ""
      };
    case PLDP_THOUGHTS:
      return {
        ...state,
        pldpthoughts: action.pldpthoughts,
        type: PLDP_THOUGHTS,
        CREATE_THOUGHT: ""
      };
    case CREATE_THOUGHT_SUCCESS:
      return {
        ...state,
        CREATE_THOUGHT: CREATE_THOUGHT_SUCCESS
      };
    case "CLEAR_THOUGHT_SUCCESS":
      return {
        ...state,
        CREATE_THOUGHT: ""
      };
    case INPUT_HASHTAG:
        return {
          ...state,
          hashcount: action.count,
          type: INPUT_HASHTAG,
        };
    case GET_TOP_HASHTAGS:
         return {
           ...state,
           hashcount: action.hashlist,
           type: GET_TOP_HASHTAGS,
         };      
    default:
      return state;
  }
};

export default thoughtsReducer;
