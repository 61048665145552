import { SURVEY_TESTS_LIST } from "./types";
import firebase from "../firebase";

export const surveyTestsDisplay = params => {
  if (params.type === SURVEY_TESTS_LIST) {
    console.log('black test surveyTests action 2: ', params);
    return (dispatch, getState) => {
    console.log('black test surveyTests action 3: ', params);

    //  firebase.database.ref(`surveyAnalytics/${params.companyID}/${params.companyID}+'-'+${params.surveyid}`).on("value", function(data) {
   // firebase.database.ref('surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/').on("value", function(data) {
    firebase.database.ref('surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/').on("value", function(data) {
   
    console.log('inside surveyTestsAction companyID: ', params.companyID);
    console.log('inside surveyTestsAction groupID: ', params.groupID);
    console.log('inside surveyTestsAction data: ', data.val());

    //updates['surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/' + 'surveyname'] = params.surveyTitle;       
    // updates['surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/' + 'person'] = params.personid;       
    // updates['surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/' + 'testscore'] = params.counterCorrectAnswers;       

    if (data.val()) {     
          dispatch({
            type: SURVEY_TESTS_LIST,
            tests_list: data.val()
          });
    
        } else {
          return (dispatch, getState) => {
            dispatch({
              type: SURVEY_TESTS_LIST,
              tests_list: {}
            });
          };
        }
      });
   } 
 }
};
