import React, { useState, useEffect } from 'react'
import Chart from "react-google-charts";

const data = [
  ['Topic', 'Motivation', 'Direction', 'Structure', 'Personal Leadership', 'Team Leadership', 'Organisational Leadership', 'Social / Society', 'Spiritual Leadership', 'Visionary Leadership', 'Authenticity', 'Innovation', 'Purpose'],
  ['Topics', 1030, 460, 1030, 200, 800, 660, 900, 300, 400, 1170, 660, 900],
]

const PopularTopicsCharts = (props) => {

  const [graph_data, setGraphData] = useState([]);

  function handleStatusChange(prop_data) {
    let topics_h = [];
    let topics_d = [];
    let graph_d = [];

    let topics = prop_data && prop_data.analytics && prop_data.analytics.topics ? prop_data.analytics.topics : {};

    topics_h.push("Topic")
    topics_d.push("Topic(s)")

    for (const topic in topics) {
      var __FOUND = prop_data.companyValues.find(function(company_value, index) {
        if (company_value.valueID === topic)
          return true;
      });

      let topic_title = __FOUND && __FOUND.valueDesc ? __FOUND.valueDesc : "not_specified"
      let topic_total = topics[topic].total ? topics[topic].total : 0

      topics_h.push(topic_title)
      topics_d.push(topic_total)

      // console.log("topics: ", `${topic_title}: ${topics[topic].total}`);
    }

    graph_d.push(topics_h)
    graph_d.push(topics_d)

    // console.log("topics: ", graph_d);

    setGraphData(graph_d);
  }

  useEffect(() => {
   // console.log("analytics here 1: ", props.analytics);
    handleStatusChange(props);
  }, [props]);

  return <>
    <Chart
      width={'100%'}
      height={'400px'}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={graph_data}
      options={{
        // Material design options
        chart: {
          title: 'Popular Posts - 2020',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        },
        chartArea: { width: '60%' },
        vAxis: {
          title: 'No of Posts',
        },
      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  </>
}

export default PopularTopicsCharts