import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import firebase from '../../store/firebase'

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Grid,
  Chip,
  IconButton
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import { SUPPORTED_FILE_TYPES, SHOW_TOAST } from "../../store/actions/types";
import { showToast } from '../../store/actions/toastAction'
import { assignGroupStudyMaterials } from '../../store/actions/companyAdminActions'

const styles = {
  root: {
    '&>div': {
      '&:hover': {
        borderBottom: '1px solid red'
      }
    }
  },
  formControl: {
    'width': '100%',
    '& label span': {
      color: 'red'
    }
  },
  searchForm_submit_button: {
    'background': '#e40000',
    'borderRadius': 0,
    'color': '#FFF',
    'fontSize': 20,
    'fontWeight': 100,
    '&:hover': {
      background: '#a30000',
      borderColor: '#a30000'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}

class AddGroups extends React.Component {
  constructor (props) {
    super(props)
    // console.log("AddGroups.js props: ", this.props);
  }

  state = {
    gr: '',
    group_id: '',
    group_name: 'Select Group',
    selectedgroups: [],
    open: false
  };

  handleChangeMultiple = (event) => {
    const { value } = event.target;
    var selectedgroups = []

    for (let i = 0, l = value.length; i < l; i += 1) {
      if (value[i] !== undefined) {
        selectedgroups.push(value[i])
      }
    }

    this.setState({
      selectedgroups,
      open: false
    });

    // this.props.handleInput(selectedgroups, 'multiplegroups')

  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  componentDidMount() {
    let database = firebase.database
    let ref = 'company_groups/' + this.props.userdata.companyID;

    database
      .ref(ref)
      .once('value')
      .then(snapshot => {
        const groupsArr = []
        snapshot.forEach(childSnapshot => {
          groupsArr.push({
            id: childSnapshot.key,
            group_id: childSnapshot.val().group_id,
            group_name: childSnapshot.val().group_name,
            group_no: childSnapshot.val().group_no ? childSnapshot.val().group_no : '',
            study_school: childSnapshot.val().study_school ? childSnapshot.val().study_school : '',
          })
        })
        this.setState({ gr: groupsArr })
      })
  }

  saveData = () => {
    var selectedgroups = [];

    selectedgroups = this.state.selectedgroups;

    if (selectedgroups.length === 0) {
      var params = {
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Please select group(s)"
      };

      this.props.showToast(params);
      return;
    }

    this.props.assignGroupStudyMaterials(
      {
        selectedgroups,
        userdata: this.props.userdata,
        file: this.props.file,
      }
    )

    this.setState({
      selectedgroups: []
    });

    // if (this.props.type === "competency") {
    //   this.props.updateOrCreateCompanyValue({
    //     companyID: this.props.userdata.companyID,
    //     valueDesc: this.state.competencyname,
    //     companyName: this.props.userdata.companyName,
    //     updateValue: this.state.isUpdate,
    //     valueID: this.state.valueID
    //   });
    // } else {
    //   // sub competency
    //   this.props.updateOrCreateSubCompetency({
    //     companyID: this.props.userdata.companyID,
    //     valueDesc: this.state.competencyname,
    //     companyName: this.props.userdata.companyName,
    //     updateValue: this.state.isUpdate,
    //     valueID: this.state.valueID,
    //     compid: this.props.compid,
    //   });
    // }

    // this.resetFields();
  }

  saveObject = () => e => {
    e.preventDefault();

    this.saveData();
  };

  // handleMouseDown = event => {
  //   event.preventDefault();
  // };

  render() {
    const style = { color: 'red' }

    const {
      classes
    } = this.props

    const { open } = this.state

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item sm={11} xs={11}>
            <form className='' noValidate autoComplete='off'>
              <FormControl required className={classes.formControl}>
                <InputLabel shrink htmlFor='groups'>
                  Groups
                </InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  open={open}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  value={this.state.selectedgroups}
                  onChange={event => this.handleChangeMultiple(event)}
                  input={<Input />}
                >
                  {this.state.gr
                    ? this.state.gr.map(item => {
                      return (
                        <MenuItem key={item.group_name} value={item}>
                          {item.group_name}
                        </MenuItem>
                      )
                    })
                    : null}
                </Select>
              </FormControl>
            </form>
          </Grid>
          <Grid item sm={1} xs={1}>
            <IconButton
              aria-label="add groups"
              onClick={this.saveObject()}
              //onMouseDown={this.handleMouseDown.bind(this)}
              style={{ marginTop: '10px' }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    )
  }
}

AddGroups.propTypes = {
  classes: PropTypes.object,
  userdata: PropTypes.any
}

const AddGroupsStyled = withStyles(styles)(AddGroups)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    assignGroupStudyMaterials: params => dispatch(assignGroupStudyMaterials(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddGroupsStyled)


