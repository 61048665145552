import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emphasize, withStyles } from '@material-ui/core/styles'
import {
  Paper,
  Grid,
  Typography, Breadcrumbs, Chip
} from '@material-ui/core'
import { Link } from 'react-router-dom'

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none',
    },
  },
}))(Chip);

class GroupCourses extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={2} gutterBottom style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h5'>Training Programmes / Courses</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <StyledBreadcrumb
                component={Link}
                to={`/companyadmin`}
                label="Clubs Admin"
              />
              <StyledBreadcrumb
                component={Link}
                to={`/groups`}
                label="Manage Groups"
              />
              <StyledBreadcrumb
                label="Courses"
              />
            </Breadcrumbs>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default GroupCourses;
