const initialState = {
	message: ''
};

const attachmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'DELETE_POST_ATTACHMENT':
			return {
				...state,
				type: 'DELETE_POST_ATTACHMENT',
				message: action.message
			};
			break;

		default:
			return state;
			break;
	}
};

export default attachmentReducer;
