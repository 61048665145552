
import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
//import firebase from "../firebase";
import ReactDOM from "react-dom";
//import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { APP_TYPE } from "./types";
import { CloudDownload } from '@material-ui/icons';

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5),
  }
});

class DownloadNote extends React.Component {
  constructor (props) {
    super(props);
    // console.log('>>>>>>>>>>>>>>>>props for newnote: ',this.props.newnote)
    // console.log('>>>>>>>>>>>>>>>>props for window.location.origin: ',window.location)
  }

  chooseOS() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    var macstate = navigator.appVersion.indexOf("Mac");

    if (winstate !== -1) { winner = "win" }
    if (linuxstate !== -1) { winner = "linux" }
    if (macstate !== -1) { winner = "mac" }
    // console.log('chooseOS: ',winner);
    return winner;
  }

  componentWillMount() {
    // setTimeout( this.getUserData, 1500)
    // console.log('>>>>>>>>>>>>>>>>props for DownloadNote: ',this.props)
  }
  componentDidMount() {
    // this.setState({newnote:false})
  }
  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
    }
  }


  render() {
    // console.log('inside rendering of DownloadNote>>>>>>>>>>>>>>>>>>>>>>>>');
    return (
      <div>
        <Note />
      </div>
    )
  }
}

class Note extends React.Component {

  constructor (props) {
    super(props);
    // console.log('>>>>>>>>>>>>>>props for Note: ',this.props)
  }
  state = {
    open: false
  };

  componentWillMount() {
    // console.log('inside componentWillMount')
    setTimeout(this.handleClick, 1500)
  }

  componentDidMount() {
    // console.log('inside componentDidMount')
    this.setState({
      open: false
    });
  }

  chooseOSpublic() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var macstate = navigator.appVersion.indexOf("Mac");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    winner = 'www.google.com';

    var server = window.location.host
    switch (server) {
      case "thinklead.app":         //live public server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fpublic%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=df76331e-ea29-45b6-b8bb-b157379ab85b';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        break;

      case "glp-test.appspot.com":  //test server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fwindows%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=92dad5e5-ea6d-4bcd-8474-f326a35fcb1d';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fmac%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=a24e2ffb-eac8-4b41-9888-4a4085988ccb';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;
        break;

      default:
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fpublic%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=df76331e-ea29-45b6-b8bb-b157379ab85b';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        break;
    }

    return winner;
  }

  chooseOScorporate() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var macstate = navigator.appVersion.indexOf("Mac");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    //placeholder
    winner = 'www.google.com';

    var server = window.location.host
    switch (server) {
      case "thinklead.app":         //live corporate server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fcorporate%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=d7e3a460-23d3-4fd2-9c72-64b3a5320c58';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;

      case "glp-test.appspot.com":  //test server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fwindows%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=92dad5e5-ea6d-4bcd-8474-f326a35fcb1d';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fmac%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=a24e2ffb-eac8-4b41-9888-4a4085988ccb';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;

      case "harmony.thinklead.co.za":  //Harmony
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fcorporate%2Fharmony%2FLDP%20Support%20Portal%20Setup%200.1.0.exe?alt=media&token=1153215c-37c5-45f6-95e4-86b85a823225';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2Fharmony%2FLDP%20Support%20Portal-1.0.0.dmg?alt=media&token=a9ec35ef-34b9-4afb-b6a5-5b6cc1cf6a2a';
        }
        if (linuxstate !== -1) {
          winner = '';
        }
        break;

      default:
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fcorporate%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=d7e3a460-23d3-4fd2-9c72-64b3a5320c58';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;
    }

    return winner;
  }


  handleClick = () => {
    // console.log('>>>> Inside handleClick: ', this.state.open);
    this.setState(state => ({
      open: !state.open
    }));
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    return (
      <div style={{ backgroundColor: 'white' }}>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          //open={open}
          open={this.state.open}
          //autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id"></span>}
          onClose={this.handleClose}
          action={[
            <div className="container" >

              <a style={{
                textDecoration: 'none', color: 'white',
                backgroundColor: 'none', paddingLeft: '8px',
                paddingRight: '8px', paddingTop: '9px', paddingBottom: '9px',
                marginTop: '20px'
              }} href={
                (APP_TYPE === "PUBLIC") ?
                  this.chooseOSpublic()
                  : this.chooseOScorporate()
              } download target="_self">
                <Button key="undo" style={{ color: 'white' }} onClick={() => this.handleClose()} >
                  <CloudDownload style={{ paddingRight: '15px', fontSize: 35 }} /> Download GLP app onto your desktop
          </Button>
              </a>
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                //className={classes.close}
                onClick={() => this.handleClose()}
              >
                <CloseIcon />
              </IconButton>

            </div>
          ]}
        />
      </div>
    );
  }
}


export default withStyles(styles)(DownloadNote);

