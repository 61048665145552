import axios from "axios";
import { BASE_API_URL, GLP_LOGGED_PROFILE } from "./types";

export const deletePosts = params => {
  var USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
  return (dispatch, getState) => {
    var postTypes = {
      thoughts: "dailyThoughts",
      articles: "news",
      podcasts: "podcasts",
      videos: "videos"
    };

    var postID = {
      thoughts: params.post["dailyThoughtID"],
      articles: params.post["newsID"],
      podcasts: params.post["podcastID"],
      videos: params.post["videoID"]
    };

    var name = params.posttype;
    var id = params.post.id ; //postID[params.posttype];
    var uid = USER_LOGGED.uid;
    console.log('postactions 1: ', name);
    console.log('postactions 2: ', params.post.id);
    console.log('postactions 3: ', uid);

    if (!name) {
      dispatch({
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Invalid post type!"
      });
      return;
    }

    if (!id) {
      dispatch({
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Invalid key to delete, please try again!"
      });
      return;
    }

    if (!uid) {
      dispatch({
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Invalid user account, please log in again!"
      });
      return;
    }

    var body = {
      name: name,
      id: id,
      uid: uid,
      ...params.post
    };

    console.log('postactions 4: ', params)
    //return;
      //var paramsb;


    // update new data
    axios
      .delete(`${BASE_API_URL}/${name}/${body.id}`, { data: { ...body } })
      .then(res => {
        dispatch({
          type: "SHOW_TOAST",
          open: true,
          variant: "success",
          message: "Post deleted successfully!"
        });
      })
      .catch(err => {
        if (err.response) {
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error response: " + err.response.data.message
          });
        } else if (err.request) {
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error request: " + err.request
          });
        } else {
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error message: " + err.message
          });
        }
      });
  };
};
