import React, { Component } from "react";
import PropTypes from "prop-types";
import ThoughtsView from "../components/Views/MyThoughtsView";
import { connect } from "react-redux";
import { fetchThoughts } from "../store/actions/thoughtsActions";
import { deletePostAttachment } from "../store/actions/attachmentActions";
import { MY_THOUGHTS } from "../store/actions/types";

class MyThoughts extends Component {
  constructor (props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      minData: 6
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 6
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    var params = {
      type: MY_THOUGHTS,
      companyID: this.props.userdata.companyID,
      orderChild: "journalUserID",
      equalTo: this.props.userdata.userID,
      minData: this.state.minData
      // userID: this.props.userdata.userID,
      // companyName: this.props.userdata.companyName,
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    };
    this.props.fetchThoughts(params);
  };

  render() {
    const { thoughts, userdata } = this.props;
    return (
      <div>
        {userdata && thoughts ? (
          <React.Fragment>
            <ThoughtsView
              thoughts={thoughts}
              currentUser={userdata}
              {...this.props}
              openFileViewer={this.props.openFileViewer}
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

MyThoughts.propTypes = {
  fetchThoughts: PropTypes.func.isRequired,
  thoughts: PropTypes.array
};

const mapStateToProps = state => {
  return {
    thoughts: state.thoughts.mythoughts,
    userdata: state.userDialog.userDetails[0]
  };
};

export default connect(
  mapStateToProps,
  { fetchThoughts, deletePostAttachment }
)(MyThoughts);
