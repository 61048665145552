import { CREATE_FEEDBACK, SHOW_TOAST } from '../actions/types';

const initState = {
	feedback: {}
};

const feedbackReducer = (state = initState, action) => {
	switch (action.type) {
		case CREATE_FEEDBACK:
			return {
				...state,
				feedback: action.feedback,
				type: SHOW_TOAST,
				open: true,
				variant: action.variant,
				message: action.message
			};
		default:
			return state;
	}
};

export default feedbackReducer;
