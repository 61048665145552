import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinkIcon from "@material-ui/icons/Link";
import EmailIcon from "@material-ui/icons/Email";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LIGHTBLUE, GREEN } from "../../utils/color_utils";
import UploadFiles from "../../components/UploadFiles";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PaletteRoundedIcon from "@material-ui/icons/PaletteRounded";
import ColorWheel from "../../utils/ColorWheel";
import { showToast } from "../../store/actions/toastAction";
import { SHOW_TOAST } from "../../store/actions/types";
import { getCompanies, updateNewCompany } from "../../store/actions/companiesActions";

const styles = theme => ({
  root: {
    marginTop: 0,
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      marginTop: "130px",
      padding: "0 10px",
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    position: "relative",
    display: "flex",
    width: "100%",
    alignItems: "center",
    borderRadius: "1px",
    textAlign: "center"
  },
  mediaUpload: {
    height: 140,
  },
  mediaUploadIcon: {
    height: 70,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
  },
});

class Companies extends Component {
  constructor (props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      minData: 10,
      open: false,
      dialogTitle: "Add Company",
      companyName: "",
      companyID: "",
      companyTypeDesc: "",
      imageurl: "",
      imageuploaded: false,
      logouploaded: false,
      companyType: 1,
      companyWebsite: "",
      companySlogan: "",
      email: "",
      primaryColor: "#fff",
      topBarColor: "#fff",
      companyLogo: "",
      logo: "",
      color: "#fff",
      uploadinprogress: false,
      uploadlogoprogress: false,
      openPallet: false,
      edit: false,
      companypldpslogan: "",
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 6
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    var params = {
      minData: this.state.minData
    };
    this.props.getCompanies(params);
  };

  openDialog = (type, title) => e => {
    this.setState({
      dialogTitle: title,
      open: true,
      edit: type === 1 ? false : true
    });
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (event, name) => {
    this.setState({ companyType: event.target.value, companyTypeDesc: name.props.children });
  };

  onImageUploadDeleted = () => {
    this.setState({
      ...this.state,
      imageurl: "",
      imagename: "",
      companyLogo: "",
      imageuploaded: false
    });
  };

  onLogoUploadDeleted = () => {
    this.setState({
      ...this.state,
      logo: "",
      logouploaded: false
    });
  };

  logoUploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      imageurl: url,
      logo: url,
      imagename: filename,
      logouploaded: url ? true : false
    });
  };

  imageUploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      companyLogo: url,
      imageuploaded: url ? true : false
    });
  };

  uploadInProgress = status => {
    this.setState({
      ...this.state,
      uploadinprogress: status
    });
  };

  uploadLogoInProgress = status => {
    this.setState({
      ...this.state,
      uploadlogoprogress: status
    });
  };

  handleShowColorPallet = () => {
    this.setState({ openPallet: true });
  };

  handleTopColorPallet = () => {
    this.setState({ openTopPallet: true });
  };

  handleColorChange = color => {
    this.setState({ color, primaryColor: color, openPallet: false });
  };

  handleTopColorChange = color => {
    this.setState({ openTopPallet: false, topBarColor: color });
  };

  editCompany = id => {
    this.setState({
      companyID: this.props.companies[id].companyID, 
      companyName: this.props.companies[id].companyName, 
      topBarColor: this.props.companies[id].topBarColor,
      companyLogo : this.props.companies[id].companyLogo,
      companyType : this.props.companies[id].companyType,
      companySlogan : this.props.companies[id].companySlogan,
      companyWebsite : this.props.companies[id].companyWebsite,
      email : this.props.companies[id].email,
      logo : this.props.companies[id].logo,
      color : this.props.companies[id].primaryColor,
      primaryColor : this.props.companies[id].primaryColor,
      secondaryColor : this.props.companies[id].primaryColor,
      topBarColor : this.props.companies[id].topBarColor,
      companypldpslogan : this.props.companies[id].companypldpslogan ? this.props.companies[id].companypldpslogan : "",
      dialogTitle: "Edit Company",
      open: true,
      edit: true
    });
  };

  saveCompany = () => {
    if (this.state.uploadInProgress || this.state.uploadlogoprogress) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please wait, Upload in progress.."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.companyName === "") {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter company name"
      };

      this.props.showToast(params);
      return;
    }
    

    if (this.state.companyLogo === "") {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please provide company logo"
      };

      this.props.showToast(params);
      return;
    }
    
    if (this.state.logo === "") {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please provide company icon"
      };

      this.props.showToast(params);
      return;
    }

    let params = {
      "companyLogo" : this.state.companyLogo,
      "companyName" : this.state.companyName,
      "companyType" : this.state.companyType,
      "companySlogan" : this.state.companySlogan,
      "companyWebsite" : this.state.companyWebsite,
      "dateRegistered" : new Date().getTime(),
      "dateUpdated" : new Date().getTime(),
      "email" : this.state.email,
      "logo" : this.state.logo,
      "primaryColor" : this.state.primaryColor,
      "secondaryColor" : this.state.primaryColor,
      "topBarColor" : this.state.topBarColor,
      "companypldpslogan" : this.state.companypldpslogan,
      "stringDateRegistered" : new Date().toUTCString()
    }

    if(this.state.edit) {
      params["companyID"] = this.state.companyID;
      delete params["dateRegistered"];
      delete params["stringDateRegistered"];
    }

    this.props.updateNewCompany(params, this.state.edit);

    this.handleClose();
  };

  render() {
    const { classes, companies } = this.props;
    const { open, dialogTitle, companyType, companyLogo, imageuploaded, logouploaded, logo, color, openPallet, openTopPallet, topBarColor } = this.state;
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography variant="h5">Companies</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={this.openDialog(1, 'Add Company')} size="medium">
              Add Company
            </Button>
          </Grid>
          {companies &&
            Object.keys(companies).map(id => {
              return (<Grid item xs={12} sm={4}>
                <Card style={{ cursor: "pointer" }} onClick={() => this.editCompany(id)}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="company-logo"
                        className={classes.avatar}
                        src={companies[id].companyLogo ? companies[id].companyLogo : ""}>
                        C
                  </Avatar>
                    }
                    title={companies[id].companyName ? companies[id].companyName : ""}
                    subheader=""
                  />
                  <CardMedia
                    className={classes.media}
                    image={companies[id].logo}
                    title="Logo"
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body2">~ {companies[id].companySlogan}</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ borderTop: "1px solid rgba(0,0,0,0.04)" }}>
                        <Typography variant="body2"><LinkIcon fontSize="small" style={{ color: LIGHTBLUE }} /> {companies[id].companyWebsite}</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ borderTop: "1px solid rgba(0,0,0,0.08)" }}>
                        <Typography variant="body2"><EmailIcon fontSize="small" style={{ color: GREEN }} /> {companies[id].email}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ width: "50px", height: "50px", backgroundColor: companies[id].primaryColor, margin: "auto" }}></div>
                      </Grid>
                      <Grid item xs={12} style={{ borderTop: "1px solid rgba(0,0,0,0.08)", textAlign: "center" }}>
                        <Typography variant="body2">Company Color</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>)
            })}
        </Grid>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <div style={{ padding: "8px", border: "1px solid rgba(0,0,0,0.08)" }}>
              <Typography variant="subtitle">Company Logo *</Typography>
              <CardMedia className={classes.mediaUpload} image={logo} title="Company Logo *" style={{ cursor: "pointer" }} />
              {logouploaded ? (<Button
                color="secondary"
                component="span"
                size="small"
                className={classes.button}
                onClick={this.onImageUploadDeleted}
              >
                <CancelIcon className={classes.leftIcon} />
                Remove
              </Button>) : null}
              {logouploaded ? null : (<div style={{ padding: "8px" }}>
                <UploadFiles
                  onUploadComplete={this.logoUploadFinished.bind(this)}
                  onUploadProgress={this.uploadLogoInProgress.bind(this)}
                  uploadtype="image"
                />
              </div>)}
            </div>
            <TextField
              //autoFocus={true}
              required
              margin="dense"
              id="name"
              label="Company name"
              type="text"
              fullWidth
              value={this.state.companyName}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  companyName: value,
                });
              }}
            />
            <TextField
              margin="dense"
              id="slogan"
              label="Company slogan"
              type="text"
              fullWidth
              value={this.state.companySlogan}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  companySlogan: value,
                });
              }}
            />
            <InputLabel id="demo-controlled-open-select-label" style={{ marginTop: "14px", fontSize: "12px" }}>Account Type</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={companyType}
              onChange={this.handleChange}
              fullWidth
            >
              <MenuItem value={1}>Global Leadership App</MenuItem>
              <MenuItem value={2}>Global Sales App</MenuItem>
              <MenuItem value={3}>Global HR App</MenuItem>
              <MenuItem value={4}>Public App</MenuItem>
            </Select>
            <TextField
              margin="dense"
              id="website"
              label="Company website"
              type="text"
              fullWidth
              value={this.state.companyWebsite}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  companyWebsite: value,
                });
              }}
            />
            <TextField
              margin="dense"
              id="website"
              label="Company email"
              type="email"
              fullWidth
              value={this.state.email}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  email: value,
                });
              }}
            />
            <TextField
              margin="dense"
              id="companypldpslogan"
              label="PlDP Slogan"
              type="text"
              fullWidth
              multiline
              rows="2"
              value={this.state.companypldpslogan}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  companypldpslogan: value,
                });
              }}
            />
            <div style={{ padding: "8px", border: "1px solid rgba(0,0,0,0.08)" }}>
              <Typography variant="subtitle">Company Icon *</Typography>
              <Avatar aria-label="company-icon"
                className={classes.avatar}
                src={companyLogo ? companyLogo : ""} style={{ cursor: "pointer" }}>
                C
                  </Avatar>
              {imageuploaded ? (<Button
                color="secondary"
                component="span"
                size="small"
                className={classes.button}
                onClick={this.onLogoUploadDeleted}
              >
                <CancelIcon className={classes.leftIcon} />
                Remove
              </Button>) : null}
              {imageuploaded ? null : (<div style={{ padding: "8px" }}>
                <UploadFiles
                  onUploadComplete={this.imageUploadFinished.bind(this)}
                  onUploadProgress={this.uploadInProgress.bind(this)}
                  uploadtype="image"
                />
              </div>)}
            </div>
            <ColorWheel open={openPallet} onChange={this.handleColorChange} />
            <div style={{ padding: "8px 16px", backgroundColor: `${color}` }}>
              <TextField
                id="selectedColor"
                variant="filled"
                label="Select large screens bar color"
                value={this.state.color}
                className={classes.palleteStyle}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle color pallet"
                        onClick={this.handleShowColorPallet}
                      >
                        <PaletteRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <ColorWheel open={openTopPallet} onChange={this.handleTopColorChange} />
            <div style={{ padding: "8px 16px", backgroundColor: `${topBarColor}` }}>
              <TextField
                id="selectedTopColor"
                variant="filled"
                label="Select mobile screens bar color"
                value={this.state.topBarColor}
                className={classes.palleteStyle}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle color pallet"
                        onClick={this.handleTopColorPallet}
                      >
                        <PaletteRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
          </Button>
            <Button onClick={this.saveCompany} color="primary">
              Submit
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

Companies.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    classes: PropTypes.object,
    userdata: state.userDialog.userDetails[0],
    companies: state.companiesList.companies,
  };
};

const CompaniesStyled = withStyles(styles)(Companies);

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    getCompanies: params => dispatch(getCompanies(params)),
    updateNewCompany: (params, edit) => dispatch(updateNewCompany(params, edit)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompaniesStyled); 
