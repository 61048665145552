import {
  GET_COMPANY_ANALYTICS,
  GET_ACTIVE_COMPANY_USERS,
  GET_COMPANY_COUNTS,
  GET_ACTIVE_COMPANIES,
  POST_ANALYTICS
} from "../actions/types";

const initState = {
  analytics: {},
  activeCompanies: [],
  month: "",
  postsanalytics: {},
  companycountsanalytics: {},
  activeusersanalytics: []
};

const analyticsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_COMPANY_ANALYTICS:
      return {
        ...state,
        analytics: action.analytics
      };
    case GET_ACTIVE_COMPANY_USERS:
      return {
        ...state,
        activeusersanalytics: action.activeusersanalytics
      };
    case GET_COMPANY_COUNTS:
      return {
        ...state,
        companycountsanalytics: action.companycountsanalytics
      };
    case GET_ACTIVE_COMPANIES:
      return {
        ...state,
        activeCompanies: action.activeCompanies
      };
    case POST_ANALYTICS:
      return {
        ...state,
        postsanalytics: action.postsanalytics
      };
    default:
      return state;
  }
};

export default analyticsReducer;
