var _YOUTUBE = {};
function getYoutubeID(youtubeURL) {
  var str = youtubeURL;
  var n = str.indexOf("=");
  var x = str.indexOf("&");
  var res = "";

  if (n === -1) {
    n = str.indexOf("youtu.be");
    if (x === -1) res = str.substring(n + 9);
    else res = str.substring(n + 9, x);
  } else {
    if (x === -1) res = str.substring(n + 1);
    else res = str.substring(n + 1, x);
  }

  return res;
}

_YOUTUBE.getMediaURL = url => {
  var res = "";
  var checkHTTP;
  if (url) {
    if (url.includes("youtu")) {
      var youtubeID = getYoutubeID(url);
      res = "https://www.youtube.com/embed/" + youtubeID;
    } else {
      checkHTTP = url.indexOf("http:");
      if (checkHTTP === -1) res = url;
      else res = url.replace("http:", "https:");
    }
  }
  return res;
};

_YOUTUBE.getYoutubeThumbnail = youtubeURL => {
  var res = getYoutubeID(youtubeURL);
  return "https://img.youtube.com/vi/" + res + "/0.jpg";
};

export { _YOUTUBE as YOUTUBE };
