import firebase from '../firebase';
import { SHOW_TOAST } from './types';

/**
 * const typestoEnd = {
    'Link':'urls',
    'Video':'videos',
    'podcast':'podcasts',
    'Photo':'photos',
  }
var database = firebase.database();
								database.ref(this.thetype+'/'+this.itemid+'/'+typestoEnd[itemType]+'/'+ itemID).remove();

*/

export const deletePostAttachment = (params) => {
	console.log('green removeAttachment 2: ', params)	
	return (dispatch, getState) => {
		var updates = {};
		var postPath = '';
		var attachmentPath = '';

		let deletePath = {
			postType: {
				thoughts: 'dailyThoughts',
				articles: 'news',
				groups: 'company-group-posts'
			},
			attachmentType: {
				urls: 'urls',
				videos: 'videos',
				podcasts: 'podcasts',
				photos: 'photos',
				files: 'files',
			}
		};

		if (params.post && params.post.groupid.length > 0) {
			// console.log("inside groups")
			postPath = `${deletePath.postType['groups']}/${params.post.companyID}/${params.postID}`;
			attachmentPath = `${deletePath.attachmentType[params.attachmentType]}/${params.attachmentID}`;


			updates[`${postPath}/${attachmentPath}`] = null;
			updates[`group-posts/${params.post.groupid}/${params.postID}/${attachmentPath}`] = null;
			updates[`groupfiles/${params.post.groupid}/${params.attachmentID}`] = null;

			if (params.attachmentType === 'files') {
				updates['files/' + params.attachmentID] = null;
			}
		} else {
			// console.log("outside groups")
			postPath = `${deletePath.postType[params.postType]}/${params.postID}`;
			attachmentPath = `${deletePath.attachmentType[params.attachmentType]}/${params.attachmentID}`;
			updates[`${postPath}/${attachmentPath}`] = null;
		}

		// Do a deep-path update
		firebase.database.ref().update(updates, function(error) {
			if (error) {
				dispatch({
					type: SHOW_TOAST,
					variant: 'error',
					message: error.message
				});
			} else {
				dispatch({
					type: SHOW_TOAST,
					variant: 'success',
					message: 'Attachment deleted successfully!!'
				});
				dispatch({
					type: 'DELETE_POST_ATTACHMENT',
					message: `${params.postType} attachment deleted!!`
				});
			}
		});

		// firebase.database
		// 	.ref(`${postPath}/${attachmentPath}`)
		// 	.remove()
		// 	.then((res) => {
		// 		dispatch({
		// 			type: SHOW_TOAST,
		// 			variant: 'success',
		// 			message: 'Attachment deleted successfully!!'
		// 		});
		// 		dispatch({
		// 			type: 'DELETE_POST_ATTACHMENT',
		// 			message: `${params.postType} attachment deleted!!`
		// 		});
		// 	})
		// 	.catch((err) => {
		// 		dispatch({
		// 			type: SHOW_TOAST,
		// 			variant: 'error',
		// 			message: err.message
		// 		});
		// 	});
	};
};
