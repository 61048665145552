import grey from '@material-ui/core/colors/grey'
import lightBlue from '@material-ui/core/colors/lightBlue'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import deepOrange from '@material-ui/core/colors/deepOrange'

const GREY = grey[500]
const LIGHTGREY = '#f1f1f1'
const DARKBLUE = '#154360'
const LIGHTBLUE = lightBlue[500]
const LIGHTBLUE300 = lightBlue[300]
const GREEN = green[500]
const RED = '#cc0000'
const HOVERRED = red[700]
const PINK = '#f50057'
const DARK = '#2e2e2e'
const SIDEBARCOLOR = '#222d32'
const DARKSIDEBAR = '#14242b'
const THEMECOLOR = '#ccbc74'
const HOVERBLUE = '#1769aa'
const HOVERMAROON = '#ab003c'
const GLPMAROON = '#810029'
const MAROONHOVER = '#d32f2f'
const TEALBLUE = '#0085a5'
const DEEPORANGE = deepOrange[500]

export {
  GREY,
  RED,
  LIGHTBLUE,
  GREEN,
  LIGHTGREY,
  PINK,
  DARK,
  SIDEBARCOLOR,
  THEMECOLOR,
  DARKSIDEBAR,
  HOVERBLUE,
  GLPMAROON,
  HOVERMAROON,
  MAROONHOVER,
  TEALBLUE,
  HOVERRED,
  DEEPORANGE,
  DARKBLUE,
  LIGHTBLUE300
}
