/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import CreateIcon from '@material-ui/icons/Create'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import { SIDEBARCOLOR, THEMECOLOR, DARKSIDEBAR } from '../../utils/color_utils'
import FollowingDialog from './../Dialogs/FollowingDialog'
import {
  USER_ANALYTICS,
  GET_UNSEEN_NOTIFICATIONS,
  GET_UNSEEN_GROUP_NOTIFICATIONS,
  GET_UNSEEN_PLDP_NOTIFICATIONS,
  UPDATE_SURVEY,
  FB_URL
} from '../../store/actions/types'
import {
  fetchUserData,
  fetchUserData_users,
  fetchUserData_con,
  updateLoggedUser
} from '../../store/actions/userActions'
import { fetchCompanyData } from '../../store/actions/loggedInCompanyActions'
import { Link } from 'react-router-dom'
import { clearStates } from '../../store/actions/toastAction'
import { updateSurvey } from '../../store/actions/surveyUpdate'
// level 0s
import { BiPyramid } from 'react-icons/bi'
// level one
import { BiHive } from 'react-icons/bi'
// level 2
import { BiArch } from 'react-icons/bi'
// level 3
import { BiAperture } from 'react-icons/bi'
// level 4
import { BiAnchor } from 'react-icons/bi'
// level 5
import { BiMagnet } from 'react-icons/bi'
// level 6
import { BiCrown } from 'react-icons/bi'

const styles = {
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    // border: `4px solid ${THEMECOLOR}`
    border: `4px solid #eeeeee`
  },
  cardHeader: {
    padding: 0,
    color: '#ffffff',
    marginTop: '-20px',
    backgroundColor: '#eeeeee'
  },
  title: {
    color: '#000000',
    fontWeight: 600,
    padding: '8px 0px 0px'
  },
  subheader: {
    color: '#000000',
    fontWeight: 600,
    padding: '8px 0px 0px'
    // color: '#Fafcc6',
    // fontWeight: 1000,
    // padding: '8px 0px 0px',
    // fontSize: '10px!important'
  },
  avatar: {
    marginRight: '-20px'
  },
  editprofile: {
    'position': 'relative',
    'top': '-12px',
    'left': '-22px',
    'borderRadius': '50%',
    'backgroundColor': `${THEMECOLOR}`,
    'padding': '1px',
    'cursor': 'pointer',
    'color': '#fff',
    '&:hover': {
      background: `${SIDEBARCOLOR}`
    }
  },
  chip: {
    height: '16px',
    borderRadius: '50%',
    fontSize: '12px!important',
    cursor: 'pointer'
  },
  followers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 'small',
    // backgroundColor: `${DARKSIDEBAR}`,
    backgroundColor: `#ffffff`,
    padding: '8px 16px!important'
  },

  follow: {
    width: '49.9%',
    textAlign: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  typfont: {
    color: '#000000',
    cursor: 'pointer'
  }
}

class LoggedUser extends React.Component {
  constructor(props) {
    super(props)
    console.log('green props loggeduser : ', this.props)
  }
  state = {
    openFollowing: false,
    userUpdated: false,
    userdetails: [],
    ppoints: ''
    // combinePoints: 0
  };

  handleOpenFollowersDialog = (e) => {
    let option = ''
    if (e.currentTarget) {
      option = e.currentTarget.getAttribute('option') || ''
    }

    this.setState({
      ...this.state,
      openFollowing: !this.state.openFollowing,
      option: option
    })
  };

  toggleDrawer = () => {
    this.props.toggleDrawer()
  };

  //WHATSTEPP SEND////////////////////////////////////////////////////////////////
  async sendWhatsAppMessage(phoneNumber, message) {
    try {
      console.log('Sending WhatsApp message:', message);
      const res = await axios.post(`${FB_URL}/m109-whatsappsend`, {
        params: {
         to: phoneNumber, // recipient's phone number in international format
         message: message, // message content
        }
      });

      console.log('Response from backend:', res.data);
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
    }
  }


  componentDidMount = () => { 

    //const tellphone = "+27605783348";
    //const message = 'In the beginning God created the Heavens and the Earth. And the earth was dark and flat etc.';
    //this.sendWhatsAppMessage(tellphone, message);
    // console.log('Loggeduser componentDidMount :', this.props.userdetails[0])
    //  this.props.updateLoggedUser({
    //   user: this.props.userdetails[0]
    // });
    //   console.log('inside loggeduser componentDidUpdate 1 ', this.props)
    //   if (this.props.UPDATE_USER === 'LOG_UPDATE_USER_SUCCESS') {
    //   console.log('inside loggeduser componentDidUpdate 2 ', this.props)
    //  // console.log('blue componentDidUpdate this.props.opt:', this.props.opt)
    //   if (this.props.opt === true) {
    //     // this.update_optimise(); //google cost optimisation activated
    //     if (this.props.userdetails[0].uid) {
    //      console.log('green inside userdetails');
    //      this.update_optimise_shrinked() // one request
    //     }
    //   } else {
    //     this.updateBackup() // get userdata from users table
    //   }
    //  }
  };

  componentWillReceiveProps(nextProps) {
    // console.log('test logged in user 0 ', nextProps.userdetails[0] ? nextProps.userdetails[0].email : null);
    // console.log('test logged in user 1 ', this.props.userdetails[0] ? this.props.userdetails[0].email : null); 
    if (this.props !== nextProps) {
     //this.setState(nextProps);
    // console.log('test logged in user 2 ', nextProps.userdetails[0].email); 
    }
   }

  // componentDidUpdate() {
  //   console.log('test logged in user 2 ', this.props.userdata.userID); 
  // }

  shouldComponentUpdate(prevProps, nextProps) {
    // console.log('test logged in user 3 ', prevProps.userdetails[0]? prevProps.userdetails[0].email: null);
    // console.log('test logged in user 4 ', nextProps.userdetails[0]? nextProps.userdetails[0].email: null);

    if (this.props.userdetails[0]) { 
      return prevProps.userdetails[0] !== nextProps.userdetails[0]
    }
    
    return true
  }

  componentDidUpdate = () => {
    // var combinePoints = parseInt(this.props.userdetails[0].points + this.props.userdetails[0].pointsCalf)
    // this.setState({ combinePoints: combinePoints})
    console.log('green loggeduser update 2 : ', this.props.userdetails[0].userID)

    if (this.props.UPDATE_USER === 'LOG_UPDATE_USER_SUCCESS') {
      console.log('inside loggeduser componentDidUpdate 2 ', this.props)
      // console.log('blue componentDidUpdate this.props.opt:', this.props.opt)
      if (this.props.opt === true) {
        // this.update_optimise(); //google cost optimisation activated
        if (this.props.userdetails[0].userID) {
          console.log(
            'green inside userdetails',
            this.props.userdetails[0].uid
          )
          this.update_optimise_shrinked() // one request
        }
      } else {
        this.updateBackup() // get userdata from users table
      }
    }
  };

  update_optimise = () => {
    if (this.props.UPDATE_USER === 'LOG_UPDATE_USER_SUCCESS') {
      // console.log('purple inside componentDidUpdate')
      this.props.clearStates({ type: 'CLEAR_UPDATE_USER_SUCCESS' })

      this.props.fetchUserData({
        type: USER_ANALYTICS,
        uid: this.props.userdetails[0].uid,
        companyID: this.props.userdetails[0].companyID
      })

      this.props.fetchUserData({
        type: GET_UNSEEN_NOTIFICATIONS,
        uid: this.props.userdetails[0].uid,
        companyID: this.props.userdetails[0].companyID
      })

      this.props.fetchUserData({
        type: GET_UNSEEN_GROUP_NOTIFICATIONS,
        uid: this.props.userdetails[0].uid,
        companyID: this.props.userdetails[0].companyID
      })

      this.props.fetchUserData({
        type: GET_UNSEEN_PLDP_NOTIFICATIONS,
        uid: this.props.userdetails[0].uid,
        companyID: this.props.userdetails[0].companyID
      })
      //  var params = {
      //   type: UPDATE_SURVEY,
      //   //surveyid: this.props.surveyid,
      //   companyID: this.props.userdetails[0].companyID
      // };
      // this.props.updateSurvey(params);
      // ---------------------------------------------
      this.props.fetchUserData({
        type: 'FOLLOWING_BUTTON',
        userID: this.props.userdetails[0].userID,
        companyID: this.props.userdetails[0].companyID
      })
      this.props.fetchCompanyData({
        type: 'FETCHED_COMPANY_DATA',
        companyID: this.props.userdetails[0].companyID
      })
      this.props.fetchUserData({
        type: 'UPDATE_MY_FOLLOWERS',
        userID: this.props.userdetails[0].userID,
        companyID: this.props.userdetails[0].companyID
      })

      this.props.updateLoggedUser({
        user: this.props.userdetails[0]
      })
    }
  };

  update_optimise_shrinked = () => {
    //  if (this.props.UPDATE_USER === 'LOG_UPDATE_USER_SUCCESS') {

    this.props.clearStates({ type: 'CLEAR_UPDATE_USER_SUCCESS' })
    // companyID: this.props.userdetails[0].companyID,
    console.log('green update_optimise_shrinked', this.props)
    if (this.props.followingData) {
      this.props.fetchUserData_con({
        type: USER_ANALYTICS,
        uid: this.props.userdetails[0].uid,
        following: this.props.followingData
      })
    } else {
      this.props.fetchUserData_con({
        type: USER_ANALYTICS,
        uid: this.props.userdetails[0].uid,
        following: {}
      })
    }
    // var params = {
    //   type: UPDATE_SURVEY,
    //   companyID: this.props.userdetails[0].companyID
    //   };
    //   this.props.updateSurvey(params);

    // this.props.fetchUserData({
    //   type: GET_UNSEEN_NOTIFICATIONS,
    //   uid: this.props.userdetails[0].uid,
    //   companyID: this.props.userdetails[0].companyID
    // });

    // this.props.fetchUserData({
    //   type: GET_UNSEEN_GROUP_NOTIFICATIONS,
    //   uid: this.props.userdetails[0].uid,
    //   companyID: this.props.userdetails[0].companyID
    // });

    // this.props.fetchUserData({
    //    type: GET_UNSEEN_PLDP_NOTIFICATIONS,
    //    uid: this.props.userdetails[0].uid,
    //    companyID: this.props.userdetails[0].companyID
    // });
    // -------------------------------------------------

    if (Object.keys(this.props.followingData).length > 0) {
      //  console.log('blue FOLLOWING_BUTTON Loggeduser 1 len :', Object.keys(this.props.followingData).length)
      //  console.log('blue FOLLOWING_BUTTON Loggeduser 1 full:', this.props.followingData)
    } else {
      // console.log('blue FOLLOWING_BUTTON Loggeduser 2 :')

      this.props.fetchUserData({
        type: 'FOLLOWING_BUTTON',
        userID: this.props.userdetails[0].userID,
        companyID: this.props.userdetails[0].companyID
      })
    }

    // this.props.fetchUserData({
    //   type: "FOLLOWING_BUTTON",
    //   userID: this.props.userdetails[0].userID,
    //   companyID: this.props.userdetails[0].companyID
    // });

    this.props.fetchCompanyData({
      type: 'FETCHED_COMPANY_DATA',
      companyID: this.props.userdetails[0].companyID
    })

    this.props.fetchUserData({
      type: 'UPDATE_MY_FOLLOWERS',
      userID: this.props.userdetails[0].userID,
      companyID: this.props.userdetails[0].companyID
    })

    this.props.updateLoggedUser({
      user: this.props.userdetails[0]
    })
    // }
  };

  updateBackup = () => {
    if (this.props.UPDATE_USER === 'LOG_UPDATE_USER_SUCCESS') {
      let params = {
        type: USER_ANALYTICS
      }
      this.props.clearStates({ type: 'CLEAR_UPDATE_USER_SUCCESS' })

      this.props.fetchUserData_users(params)
      this.props.fetchUserData_users({
        type: GET_UNSEEN_NOTIFICATIONS,
        userID: this.props.userdetails[0].userID,
        companyID: this.props.userdetails[0].companyID
      })
      this.props.fetchUserData_users({
        type: GET_UNSEEN_GROUP_NOTIFICATIONS,
        userID: this.props.userdetails[0].userID,
        companyID: this.props.userdetails[0].companyID
      })
      this.props.fetchUserData_users({
        type: GET_UNSEEN_PLDP_NOTIFICATIONS,
        userID: this.props.userdetails[0].userID,
        companyID: this.props.userdetails[0].companyID
      })
      this.props.fetchUserData_users({
        type: 'FOLLOWING_BUTTON',
        userID: this.props.userdetails[0].userID,
        companyID: this.props.userdetails[0].companyID
      })
      this.props.fetchCompanyData({
        type: 'FETCHED_COMPANY_DATA',
        companyID: this.props.userdetails[0].companyID
      })
      this.props.fetchUserData_users({
        type: 'UPDATE_MY_FOLLOWERS',
        userID: this.props.userdetails[0].userID,
        companyID: this.props.userdetails[0].companyID
      })
    }
    // var params = {
    //   type: UPDATE_SURVEY,
    //   companyID: this.props.userdetails[0].companyID
    //   };
    //   console.log('green updateSurvey')
    //   this.props.updateSurvey(params);
  };

  convertToLev = (inPoints) => {
    let points = parseInt(inPoints)
    console.log('inpoints : ', inPoints)
    let result
    if (points < 0) {
      result = 'Level 0'
    }    
    if (points >= 0 && points <= 2999) {
      result = 'Level 0'
    }
    if (points >= 3000 && points <= 5999) {
      result = 'Level 1'
    }
    if (points >= 6000 && points <= 8999) {
      result = 'Level 2'
    }
    if (points >= 9000 && points <= 11999) {
      result = 'Level 3'
    }
    if (points >= 12000 && points <= 14999) {
      result = 'Level 4'
    }
    if (points >= 15000 && points <= 17999) {
      result = 'Level 5'
    }
    if (points >= 18000) {
      result = 'Level 6'
    }
    // if (points > 21000) {
    //   result = 'Level 6'
    // }
    return result
  }

  render() {
    const {
      classes,
      useranalytics,
      userdetails,
      followingData,
      update_Survey
    } = this.props
    console.log('black signInWithGoogle: ', userdetails[0])

    return (
      <React.Fragment>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            title: classes.title,
            avatar: classes.avatar,
            subheader: classes.subheader
          }}
          avatar={
            <Grid container justify='left' alignItems='center'>
              {userdetails && userdetails.length > 0 ? (
                <Avatar
                  component={Link}
                  to='/userprofile'
                  alt={userdetails[0].firstName}
                  src={PHOTO_PROFILE(userdetails[0].photoURL)}
                  className={classes.bigAvatar}
                  onClick={this.toggleDrawer}
                />
              ) : (
                <Avatar
                  alt='profile'
                  src={PHOTO_PROFILE('')}
                  className={classes.bigAvatar}
                  onClick={this.toggleDrawer}
                />
              )}
              <IconButton
                component={Link}
                to='/userprofile'
                aria-label='Edit profile'
                className={classes.editprofile}
                onClick={this.toggleDrawer}
              >
                <CreateIcon fontSize='small' />
              </IconButton>
            </Grid>
          }
          // TODO: Fix Issue if This returns undefined.
          title={
            userdetails.length > 0
              ? userdetails[0].firstName + ' ' + userdetails[0].lastName
              : ''
          }
          //  subheader={
          //   userdetails.length > 0 && userdetails[0].points && userdetails[0].execmember
          //     ? this.convertToLev(userdetails[0].points) + ' (' + userdetails[0].points + ' points' + ')'
          //     : ''
          // }

          // userdetails.length > 0 && userdetails[0].points && userdetails[0].execmember ? <>
          subheader={
            userdetails.length > 0 && userdetails[0].points ? (
              <>
                <div className={classes.points}>
                  {this.convertToLev(userdetails[0].points) === 'Level 0' ? (
                    <BiPyramid fontSize='1.5em' />
                  ) : this.convertToLev(userdetails[0].points) === 'Level 1' ? (
                    <BiHive fontSize='1.5em' />
                  ) : this.convertToLev(userdetails[0].points) === 'Level 2' ? (
                    <BiArch fontSize='1.5em' />
                  ) : this.convertToLev(userdetails[0].points) === 'Level 3' ? (
                    <BiAperture fontSize='1.5em' />
                  ) : this.convertToLev(userdetails[0].points) === 'Level 4' ? (
                    <BiAnchor fontSize='1.5em' />
                  ) : this.convertToLev(userdetails[0].points) === 'Level 5' ? (
                    <BiMagnet fontSize='1.5em' />
                  ) : this.convertToLev(userdetails[0].points) === 'Level 6' ? (
                    <BiCrown fontSize='1.5em' />
                  ) : null}
                  {/* <Typography variant='body1'> */}
                  {/* {`${this.convertToLev(userdetails[0].points)} (${userdetails[0].points} points)`} */}
                  {`${this.convertToLev(
                    parseInt(this.props.userdetails[0].points || 1) +
                      parseInt(this.props.userdetails[0].pointsCalf || 0)
                  )}  (${
                    parseInt(this.props.userdetails[0].points || 1) +
                    parseInt(this.props.userdetails[0].pointsCalf || 0)
                  } points)`}

                  {/* </Typography> */}
                </div>
                <div>{/* {userdetails[0].companyName} */}</div>
              </>
            ) : null // userdetails[0].companyName
          }
        />

        <div className={classes.followers}>
          <div
            option='followers'
            className={classes.follow}
            onClick={this.handleOpenFollowersDialog.bind(this)}
          >
            <Chip
              label={
                Object.keys(useranalytics).length > 0
                  ? useranalytics.followers
                  : 0
              }
              className={classes.chip}
              variant='default'
            />
            &nbsp;
            <Typography className={classes.typfont} variant='body2'>
              Followers
            </Typography>
          </div>
          <div
            option='following'
            className={classes.follow}
            onClick={this.handleOpenFollowersDialog.bind(this)}
          >
            <Chip
              label={
                Object.keys(useranalytics).length > 0
                  ? useranalytics.following
                  : 0
              }
              className={classes.chip}
              variant='default'
            />
            &nbsp;
            <Typography className={classes.typfont} variant='body2'>
              Following
            </Typography>
          </div>
        </div>
        <FollowingDialog
          open={this.state.openFollowing}
          option={this.state.option}
          onOpenFollowersDialog={this.handleOpenFollowersDialog}
        />
      </React.Fragment>
    )
  }
}

const LoggedInUser = withStyles(styles)(LoggedUser)

LoggedUser.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  fetchUserData_users: PropTypes.func.isRequired,
  fetchCompanyData: PropTypes.func.isRequired,
  fetchUserData_con: PropTypes.func.isRequired,
  useranalytics: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    useranalytics: state.userdata.useranalytics,
    userdetails: state.userDialog.userDetails,
    followingData: state.userdata.followingButton,
    UPDATE_USER: state.userDialog.UPDATE_USER,
    update_Survey: state.updateSurvey.update_Survey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserData: (params) => dispatch(fetchUserData(params)),
    fetchUserData_users: (params) => dispatch(fetchUserData_users(params)),
    updateLoggedUser: (params) => dispatch(updateLoggedUser(params)),
    fetchCompanyData: (params) => dispatch(fetchCompanyData(params)),
    fetchUserData_con: (params) => dispatch(fetchUserData_con(params)),
    clearStates: (params) => dispatch(clearStates(params)),
    updateSurvey: (params) => dispatch(updateSurvey(params))
    // showUserProfile: params => dispatch(showUserProfile(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInUser)
