
import {
  ORG_THOUGHTS,
  ILEAD_THOUGHTS,
  CORP_ILEAD_THOUGHTS,
  GC_THOUGHTS,
  DAILY_THOUGHTS,
  MY_THOUGHTS,
  BASE_API_URL,
  GLP_LOGGED_PROFILE,
  APP_TYPE,
  PLDP_THOUGHTS,
  FILTERED_POST,
  ALL_GROUPS_POSTS,
  SELECTED_GROUP_POSTS,
  SCHEDULED_GROUP_POSTS,
  SELECTED_GROUP_FILES,
  FILTERED_GROUP_POST,
  SHOW_TOAST,
  ILEAD_LEADS,
  ILEAD_LEADS2,
  ILEAD_LEADS2x,
  ILEAD_LEADS5x,
  ILEAD_LEADS6x,
  ILEAD_LEADS3,
  ILEAD_LEADS4,
  ILEAD_BLEADS,
  //INPUT_HASHTAG,
  GET_TOP_HASHTAGS,
  ILEAD_THOUGHTS_REQ,
  FB_URL,
} from './types'
import firebase from '../firebase'
import { _toArrayPosts, _toArray, _toArrayLeads, _toArrayLeadsLimit } from './commonActions'
import axios from 'axios'
import querystring from 'querystring'
var _ = require('lodash');
// import { ref } from "pdfkit";
import {v4 as uuidv4} from 'uuid'

export const fetchThoughts = params => {
  console.log('sortedData params.type: ', params)
  switch (params.type) {
  case SELECTED_GROUP_POSTS:
    return (dispatch, getState) => {
      firebase.database
        .ref('group-posts/' + params.group_id)
        .orderByChild(params.orderChild)
        .equalTo(params.equalTo)
        .limitToLast(params.minData)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArrayPosts(data.val())
            sortedData.sort(function (a, b) {
              if (a.dateScheduled > b.dateScheduled) {
                return -1
              }
              if (a.dateScheduled < b.dateScheduled) {
                return 1
              }
              return 0
            })
            dispatch({
              type: SELECTED_GROUP_POSTS,
              groupposts: sortedData
            })
          } else {
            dispatch({
              type: SELECTED_GROUP_POSTS,
              groupposts: {}
            })
          }
        })
    }
  case SCHEDULED_GROUP_POSTS:
    return (dispatch, getState) => {
      firebase.database
        .ref('group-posts-unpublished/')
        .orderByChild(params.orderChild)
        .equalTo(params.equalTo)
      // .limitToLast(params.minData)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArrayPosts(data.val())
            sortedData.sort(function (a, b) {
              if (a.dateScheduled > b.dateScheduled) {
                return -1
              }
              if (a.dateScheduled < b.dateScheduled) {
                return 1
              }
              return 0
            })
            dispatch({
              type: SCHEDULED_GROUP_POSTS,
              scheduledgroupposts: sortedData
            })
          } else {
            dispatch({
              type: SCHEDULED_GROUP_POSTS,
              scheduledgroupposts: {}
            })
          }
        })
    }
  case SELECTED_GROUP_FILES:
    return (dispatch, getState) => {
      firebase.database
        .ref('groupfiles/' + params.group_id)
        .limitToLast(params.minData)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArrayPosts(data.val())
            sortedData.sort(function (a, b) {
              if (a.dateRegistered > b.dateRegistered) {
                return -1
              }
              if (a.dateRegistered < b.dateRegistered) {
                return 1
              }
              return 0
            })
            dispatch({
              type: SELECTED_GROUP_FILES,
              groupfiles: sortedData
            })
          } else {
            dispatch({
              type: SELECTED_GROUP_FILES,
              groupfiles: {}
            })
          }
        })
    }
  case ORG_THOUGHTS:
    return async (dispatch, getState) => {
     await firebase.database
        .ref('dailyThoughts')
        .orderByChild(params.orderChild)
        .equalTo(params.equalTo)
        .limitToLast(params.minData)
       // .on('value').then((snapshot) => {
          .on('value', function(snapshot){
           // const image = snapshot.val()
         // snapshot.forEach((subSnapshot) => {
          if (snapshot.val()) {
           // snapshot.forEach((snapshot.val()) => {
            let sortedData = _toArrayPosts(snapshot.val())
            sortedData.sort(function (a, b) {
              if (a.dateScheduled > b.dateScheduled) {
                return -1
              }
              if (a.dateScheduled < b.dateScheduled) {
                return 1
              }
              return 0
            })
          //})
            // addPoints(sortedData);
            dispatch({
              type: ORG_THOUGHTS,
              organisational: sortedData
            })
          } else {
            dispatch({
              type: ORG_THOUGHTS,
              organisational: {}
            })
          }
        })
      //})
    } 
    case ILEAD_LEADS2:
      /////////////////////////////////////////////////////
      console.log('greenILEAD_LEADS 2 : ', params)
      return async (dispatch, getState) => {
        // let receivedData = []
        // let receivedDatab = []
        // const eq = params.equalTo;
        // const x = 1
        //.post(`${FB_URL}/m102-leadsData`, {params: {equalTo: eq, inc: x}})
        await axios
        .post(`${FB_URL}/m102-leadsData`, {})
        .then(res => {
          console.log('ileads_leads2 3a : ', res);
          if (res.data) {
          let result = res.data;
          console.log('greenThoughtsActions typeof 1 : ', typeof result);

         let sortedDatab = result.data //_toArrayLeads(result)
         sortedDatab = _toArrayPosts(result.data)

         sortedDatab.sort(function (a, b) {
           if (a.leadPoints > b.leadPoints) {
             return -1
           }
           if (a.leadPoints < b.leadPoints) {
             return 1
           }
           return 0
         })

       //  let json_obj =  result.data

       // let sortedDatac = [];
         let sortedData = _toArrayLeadsLimit(sortedDatab)

         // let myJSONstr = JSON.stringify(sortedData);
         // let myJSON2str = myJSONstr.trim();
         // let myJSON = JSON.parse(sortedData);

          // let receivedData = { ...sortedData }
          // receivedData = Object.assign({}, sortedData)
        
         console.log('green categories topleads F : ', sortedData)

          dispatch({
            type: ILEAD_LEADS,
            ilead: sortedData
          })
         }
         else {
          dispatch({
            type: ILEAD_LEADS,//ILEAD_LEADS2,
            ilead: []
          })
        }
        })
        } 

        case ILEAD_LEADS2x:
          /////////////////////////////////////////////////////
          console.log('xyz greenILEAD_LEADS2x : ', params)
          return async (dispatch, getState) => {
             let receivedData = []
            // let receivedDatab = []
            // const eq = params.equalTo;
            // const x = 1
            //.post(`${FB_URL}/m102-leadsData`, {params: {equalTo: eq, inc: x}})
            await axios
            .post(`${FB_URL}/m102-leadsData`, {})
            .then(res => {
              console.log('ileads_leads2 3a : ', res);
              if (res.data) {
              let result = JSON.parse(JSON.stringify(res.data.data)) //res.data.data;
              receivedData = _toArrayLeadsLimit(result)
              //let result = JSON.parse(JSON.stringify(res.data))
              console.log('greenThoughtsActions typeof 1 : ', typeof result);
              console.log('green categories topleads q3 3 : ', receivedData)
              //const serializedObject = JSON.stringify(result);
              console.log('green categories topleads F : ', receivedData)
              // const deserializedObject = JSON.parse(serializedObject, (key, value) => {
              //   if (Array.isArray(value)) {
              //     return Array.from(value);
              //   }
              //   return value;
              // });
              // const allProperties = analyzeArray(receivedData);
              // console.log('array properties 1 : ', allProperties);

            // let sortedDatab = _toArrayPosts(result)

           // console.log('green categories topleads D : ', sortedDatab)

            // sortedDatab.sort(function (a, b) {
            //    if (a.leadPoints > b.leadPoints) {
            //      return -1
            //    }
            //    if (a.leadPoints < b.leadPoints) {
            //      return 1
            //    }
            //    return 0
            //  })
    
           //  let json_obj =  result.data
    
           // let sortedDatac = [];
          // const copiedArray = sortedDatab.map(obj => ({ ...obj }));

           //  const sortedData = _toArrayLeadsLimit(result)

             // let myJSONstr = JSON.stringify(sortedData);
             // let myJSON2str = myJSONstr.trim();
             // let myJSON = JSON.parse(sortedData);
    
            //  let receivedData = { ...sortedDatab }
            //  receivedData = Object.assign({}, sortedDatab)
           // receivedData = JSON.parse(JSON.stringify(result))
           //  console.log('green categories topleads F : ', sortedData)
          // console.log('greenThoughtsActions typeof 2 : ', isSerializable(sortedDatab))
         // let sortedDatab = _toArrayLeadsLimit(result)

              dispatch({
                type: ILEAD_LEADS,
                ilead: receivedData
              })
             }
             else {
              dispatch({
                type: ILEAD_LEADS,//ILEAD_LEADS2,
                ilead: []
              })
            }
            })
            }  
    case ILEAD_LEADS4:
      /////////////////////////////////////////////////////
      console.log('greenILEAD_LEADS 2 : ', params)
      return async (dispatch, getState) => {
        await axios
        .post(`${FB_URL}/m102-leadsData`, {})
        .then(res => {
          console.log('ileads_leads2 3a : ', res);
          if (res.data) {
          let result = res.data;

        let sortedData = result.data //_toArrayLeads(result)

        let sortedDatab = _toArrayLeadsLimit(sortedData)
        
          console.log('green categories topleads F : ', sortedDatab)

          dispatch({
            type: ILEAD_THOUGHTS,
            ilead: sortedDatab
          })
          }
          else {
          dispatch({
            type: ILEAD_THOUGHTS,//ILEAD_LEADS2,
            ilead: []
          })
        }
        })
        } 

        // case ILEAD_LEADS5x:
        //   return async(dispatch, getState) => {
        //           await axios
        //             .post(`${FB_URL}/m102-leadsData`, {})
        //             .then(res => {
        //               if (res.data) {
        //                 let result = res.data.data;
        //                 console.log('greenThoughtsActions typeof 1 : ', result);
             
        //                 let sortedData = _toArrayLeads(result)
        //                  sortedData.sort(function (a, b) {
        //                    if (a.leadPoints > b.leadPoints) {
        //                      return -1
        //                    }
        //                    if (a.leadPoints < b.leadPoints) {
        //                      return 1
        //                    }
        //                    return 0
        //                  })
        //                  console.log('green categories topleads R : ', sortedData)
        //                  let sortedDatab = _toArrayLeadsLimit(sortedData)
               
        //                 console.log('green categories topleads F 1: ', sortedDatab)
        //                 dispatch({
        //                   type: ILEAD_LEADS,
        //                   ilead: sortedDatab
        //                 })
        //               }
        //               else {
        //                 dispatch({
        //                   type: ILEAD_LEADS,
        //                   ilead: {}
        //                 })
        //               }
        //           })
        //    } 

        // case ILEAD_LEADS5x: 
        // return async (dispatch, getState) => {
        //   try {
        //     const snapshot = await firebase.database
        //       .ref('dailyThoughts')
        //       .orderByChild('publish_status')
        //       .equalTo('-LEiZPT-C2PyLu_YLKNU_approved')
        //       .limitToLast(500)
        //       .on('value');
        
        //     if (snapshot.exists()) {
        //       const snapshotData = Object.values(snapshot.val());

        //         let sortedData = _toArrayLeads(snapshotData)
        //           sortedData.sort(function (a, b) {
        //             if (a.leadPoints > b.leadPoints) {
        //               return -1
        //             }
        //             if (a.leadPoints < b.leadPoints) {
        //               return 1
        //             }
        //             return 0
        //           })
               
        //           let sortedDatab = _toArrayLeadsLimit(sortedData)
        //           console.log('green categories topleads F 1 : ', sortedDatab)

        //       const res = await axios.post(`${FB_URL}/m102-leadsData`, {});
        //       if (res.data) {
        //         const receivedData = res.data.data;
        //         console.log('green categories topleads F 2:', receivedData);
        
        //         dispatch({
        //           type: ILEAD_LEADS,
        //           ilead: sortedDatab,
        //         });
        //       } else {
        //         dispatch({
        //           type: ILEAD_LEADS,
        //           ilead: {},
        //         });
        //       }
        //     } else {
        //       dispatch({
        //         type: ILEAD_LEADS,
        //         ilead: {},
        //       });
        //     }
        //   } catch (error) {
        //     console.error('Error occurred:', error);
        //     dispatch({
        //       type: ILEAD_LEADS,
        //       ilead: {},
        //     });
        //   }
        // };

        case ILEAD_LEADS6x: 
          return (dispatch, getState) => {
           const receivedData = []
           console.log('receivedData 1 : ')
            try {
              receivedData = loadLeads();
              console.log('receivedData 2 : '. receivedData)
                dispatch({
                  type: ILEAD_LEADS,
                  ilead: receivedData
                })
             } catch (err) {
              console.log('receivedData err : '. err)
            }    
           } 

         case ILEAD_LEADS5x: //works as is
           return async(dispatch, getState) => {
             // firebase.database
             // .ref('dailyThoughts')
             // .orderByChild('publish_status')
             // .equalTo('-LEiZPT-C2PyLu_YLKNU_approved')
             // .limitToLast(500)
 
             // .ref('active-companies')
             // .orderByChild('companyID')
             // .equalTo('-NTUaz-CD0gKI2jagykx')  
 
           // .on('value', function(snapshot){
           //  if (snapshot.val()) {
            // if (1===1) {  
              //  let snapshotData = snapshot.val();
               // snapshotData = []
              // console.log('greenThoughtsActions typeof 1 : ', snapshot.val());
              
             //  let sortedData = _toArrayLeads(snapshot.val())
             //   sortedData.sort(function (a, b) {
             //     if (a.leadPoints > b.leadPoints) {
             //       return -1
             //     }
             //     if (a.leadPoints < b.leadPoints) {
             //       return 1
             //     }
             //     return 0
             //   })
             //   console.log('green categories topleads R : ', sortedData)
             //   let sortedDatab = _toArrayLeadsLimit(sortedData)
     
               //  console.log('green categories topleads F 1: ', snapshotData)
 
                   let receivedData = []
                   let receivedDatab = []
                  ////////////////////////////////////////
                  // axios
                    // .post(`${FB_URL}/m102-leadsData`, {})
                     const res = await axios.post(`${FB_URL}/m102-leadsData`, {});
                     //.then(res => {
                       if (res.data) {
                        //receivedData = res.data
                        // receivedData = JSON.parse(JSON.stringify(res.data.data)) //res.data.data;
                        console.log('green categories topleads F 2 : ', res.data)
                       //receivedData = JSON.parse(res.data.data)
                       //receivedData = _toArrayLeads(res.data.data)
                       receivedData = res.data.data
                       //receivedDatab = _toArrayLeadsLimit(receivedData)
                       //console.log('green categories topleads F 1 : ', receivedData)
                       }
                    console.log('green categories topleads F 3 : ', receivedData)
                   // snapshotData = receivedDatab;
                   // console.log('green categories topleads F 4 : ', snapshotData)
                    dispatch({
                      type: ILEAD_LEADS,
                      ilead: receivedData
                    })
                  // })
                  ////////////////////////////////////
                 //  } else {
                 //    dispatch({
                 //      type: ILEAD_LEADS,
                 //      ilead: {}
                 //    })
                 //  }
              //  })
            } 
           
        //  case ILEAD_LEADS5xxx:
        //   return async(dispatch, getState) => {
        //     firebase.database
        //     .ref('dailyThoughts')
        //     .orderByChild('publish_status')
        //     .equalTo('-LEiZPT-C2PyLu_YLKNU_approved')
        //     .limitToLast(500)
        //     .on('value', function(snapshot){
        //      console.log('green categories topleads q2 : ', snapshot)
        //     if (snapshot.val()) {
        //     // console.log('green categories topleads q3 3 : ', snapshot.val())
        //      console.log('greenThoughtsActions typeof 1 : ', typeof snapshot.val());
             
        //      let sortedData = _toArrayLeads(snapshot.val())
        //       sortedData.sort(function (a, b) {
        //         if (a.leadPoints > b.leadPoints) {
        //           return -1
        //         }
        //         if (a.leadPoints < b.leadPoints) {
        //           return 1
        //         }
        //         return 0
        //       })
        //       console.log('green categories topleads R : ', sortedData)
        //      let sortedDatab = _toArrayLeadsLimit(sortedData)
    
        //      console.log('green categories topleads F 1: ', sortedDatab)
        //           let receivedData = []
        //          ////////////////////////////////////////
        //            axios
        //             .post(`${FB_URL}/m102-leadsData`, {})
        //             .then(res => {
        //               if (res.data) {
        //                //receivedData = res.data
        //                // receivedData = JSON.parse(JSON.stringify(res.data.data)) //res.data.data;
        //                receivedData = _toArrayLeads(res.data.data)
        //               //receivedData = _toArrayLeadsLimit(result)
        //               //console.log('green categories topleads F 1 : ', receivedData)
        //               }
        //            console.log('green categories topleads F 2 : ', receivedData)
                  
        //            dispatch({
        //              type: ILEAD_LEADS,
        //              ilead: receivedData
        //            })
        //           })
        //          ////////////////////////////////////
        //          } else {
        //            dispatch({
        //              type: ILEAD_LEADS,
        //              ilead: {}
        //            })
        //          }
        //        })
        //    } 
    // case ILEAD_LEADS5xx:
    // return (dispatch, getState) => {
    //   firebase.database
    //        .ref('active-companies')
    //        .orderByChild('companyID')
    //        .equalTo('-KgsUcgfo7z1U9MXgd9i')
    //        .on('value', function(snapshot){
    //      //  if (snapshot.val()) {
    //        if (1===1) {
    //         //let receivedData = []
    //         let receivedData = snapshot.val();
    //         let sortedData = []
    //           axios
    //           .post(`${FB_URL}/m102-leadsData`, {})
    //           .then(res => {
    //             if (res.data) {
    //              //receivedData = res.data
    //              // receivedData = JSON.parse(JSON.stringify(res.data.data)) //res.data.data;
    //               sortedData = _toArrayLeads(res.data.data)
    //             //receivedData = _toArrayLeadsLimit(result)
    //             console.log('green categories topleads F 1 : ', sortedData)
    //             }
    //             console.log('green categories topleads F 2 : ', sortedData)
    //          dispatch({
    //            type: ILEAD_LEADS,
    //            ilead: sortedData
    //          })
    //         })

    //        } else {
    //          dispatch({
    //            type: ILEAD_LEADS,
    //            ilead: {}
    //          })
    //        }
    //      })
    //  }   

    case ILEAD_LEADS3:
    ///////////////////////////////////////////  
    console.log('xyz greenILEAD_LEADS 3 : ', params)
    return async (dispatch, getState) => {
      console.log('green categories topleads q1 : ', params)
      await firebase.database
          //  .ref('dailyThoughts')
          //  .orderByChild('leadPoints')
          //  .startAfter(parseInt(0))
          //  .limitToLast(parseInt(100))
           .ref('dailyThoughts')
           .orderByChild('publish_status')
           .equalTo('-LEiZPT-C2PyLu_YLKNU_approved')
           .limitToLast(500)
           .on('value', function(snapshot){
            console.log('green categories topleads q2 : ', snapshot)
           if (snapshot.val()) {
           // console.log('green categories topleads q3 3 : ', snapshot.val())
            console.log('greenThoughtsActions typeof 1 : ', typeof snapshot.val());
            
            let sortedData = _toArrayLeads(snapshot.val())
             sortedData.sort(function (a, b) {
               if (a.leadPoints > b.leadPoints) {
                 return -1
               }
               if (a.leadPoints < b.leadPoints) {
                 return 1
               }
               return 0
             })
             console.log('green categories topleads R : ', sortedData)
            let sortedDatab = _toArrayLeadsLimit(sortedData)

            console.log('green categories topleads F : ', sortedDatab)

            const allProperties = analyzeArray(sortedDatab);
            console.log('array properties 2 : ', allProperties);

            //console.log('greenThoughtsActions typeof 2 : ', typeof sortedDatab);
           // console.log('greenThoughtsActions typeof 2 : ', isSerializable(sortedDatab))
             dispatch({
               type: ILEAD_LEADS,
               ilead: sortedDatab
             })
           } else {
             dispatch({
               type: ILEAD_LEADS,
               ilead: {}
             })
           }
         })
     } 
    case ILEAD_LEADS:
    console.log('xyz greenILEAD_LEADS : ', params)
    return async (dispatch, getState) => {
      console.log('green categories topleads q1 : ', params)
      await firebase.database
          //  .ref('dailyThoughts')
          //  .orderByChild('leadPoints')
          //  .startAfter(parseInt(0))
          //  .limitToLast(parseInt(100))
          .ref('dailyThoughts')
          .orderByChild('publish_status')
          .equalTo('-LEiZPT-C2PyLu_YLKNU_approved')
          .limitToLast(500)
           .on('value', function(snapshot){
            console.log('green categories topleads q2 : ', snapshot)
           if (snapshot.val()) {
            console.log('green categories topleads q3 1 : ', snapshot.val())
            let sortedData = _toArrayLeads(snapshot.val())
             sortedData.sort(function (a, b) {
               if (a.leadPoints > b.leadPoints) {
                 return -1
               }
               if (a.leadPoints < b.leadPoints) {
                 return 1
               }
               return 0
             })
             console.log('green categories topleads R : ', sortedData)
            let sortedDatab = _toArrayLeadsLimit(sortedData)
             dispatch({
               type: ILEAD_LEADS,
               ilead: sortedDatab
             })
           } else {
             dispatch({
               type: ILEAD_LEADS,
               ilead: {}
             })
           }
         })
       //})
     }  
     case ILEAD_BLEADS:
      console.log('xyz greenILEAD_BLEADS : ', params)
      return async (dispatch, getState) => {
        console.log('fetchThoughtsILEAD ILEAD_BLEADS ')
        await firebase.database
            //  .ref('dailyThoughts')
            //  .orderByChild('leadPoints')
            //  .startAfter(parseInt(0))
            //  .limitToLast(parseInt(100))
            .ref('dailyThoughts')
            .orderByChild('publish_status')
            .equalTo('-LEiZPT-C2PyLu_YLKNU_approved')
            .limitToLast(500)           
             .on('value', function(snapshot){
              console.log('green categories topleads q2 : ', snapshot)
             if (snapshot.val()) {
              console.log('green categories topleads q3 2: ', snapshot.val())
              let sortedData = _toArrayLeads(snapshot.val())
               sortedData.sort(function (a, b) {
                 if (a.leadPoints > b.leadPoints) {
                   return -1
                 }
                 if (a.leadPoints < b.leadPoints) {
                   return 1
                 }
                 return 0
               })
               console.log('green categories topleads R : ', sortedData)
              let sortedDatab = _toArrayLeadsLimit(sortedData)
               dispatch({
                 type: ILEAD_THOUGHTS,
                 ilead: sortedDatab
               })
             } else {
               dispatch({
                 type: ILEAD_THOUGHTS,
                 ilead: {}
               })
             }
           })
         //})
       } 
    case ILEAD_THOUGHTS_REQ:
      console.log('fetchThoughtsILEAD ILEAD_THOUGHTS_REQ ')
    return async (dispatch, getState) => {
      await firebase.database
          .ref('dailyThoughts')
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
        // .on('value').then((snapshot) => {
            .on('value', function(snapshot){
            // const image = snapshot.val()
          // snapshot.forEach((subSnapshot) => {
            if (snapshot.val()) {
            // snapshot.forEach((snapshot.val()) => {
              let sortedData = _toArrayPosts(snapshot.val())
              sortedData.sort(function (a, b) {
                if (a.dateScheduled > b.dateScheduled) {
                  return -1
                }
                if (a.dateScheduled < b.dateScheduled) {
                  return 1
                }
                return 0
              })
            //})
              // addPoints(sortedData);
              console.log('green fetchData x: ', sortedData)
              dispatch({
                type: ILEAD_THOUGHTS,
                ilead: sortedData
              })
            } else {
              dispatch({
                type: ILEAD_THOUGHTS,
                ilead: {}
              })
            }
          })
        //})
      } 
  case ILEAD_THOUGHTS:
    console.log('fetchThoughtsILEAD ILEAD_THOUGHTS ')
    return async (dispatch, getState) => {
      await firebase.database
         .ref('dailyThoughts')
         .orderByChild(params.orderChild)
         .equalTo(params.equalTo)
         .limitToLast(params.minData) 
           .on('value', function(snapshot){
            // const image = snapshot.val()
          // snapshot.forEach((subSnapshot) => {
           if (snapshot.val()) {
            // snapshot.forEach((snapshot.val()) => {
             let sortedData = _toArrayPosts(snapshot.val())
             sortedData.sort(function (a, b) {
               if (a.dateScheduled > b.dateScheduled) {
                 return -1
               }
               if (a.dateScheduled < b.dateScheduled) {
                 return 1
               }
               return 0
             })
           //})
             // addPoints(sortedData);
             console.log('green fetchData: ', sortedData)
             dispatch({
               type: ILEAD_THOUGHTS,
               ilead: sortedData
             })
           } else {
             dispatch({
               type: ILEAD_THOUGHTS,
               ilead: {}
             })
           }
         })
       //})
     }
  case CORP_ILEAD_THOUGHTS:
    return (dispatch, getState) => {
      firebase.database
        .ref('dailyThoughts')
        .orderByChild(params.orderChild)
        .equalTo(params.equalTo)
        .limitToLast(params.minData)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArrayPosts(data.val())
            sortedData.sort(function (a, b) {
              if (a.dateScheduled > b.dateScheduled) {
                return -1
              }
              if (a.dateScheduled < b.dateScheduled) {
                return 1
              }
              return 0
            })
            dispatch({
              type: CORP_ILEAD_THOUGHTS,
              corpilead: sortedData
            })
          } else {
            dispatch({
              type: CORP_ILEAD_THOUGHTS,
              corpilead: {}
            })
          }
        })
    }
  case GC_THOUGHTS:
    return (dispatch, getState) => {
      firebase.database
        .ref('dailyThoughts')
        .orderByChild(params.orderChild)
        .equalTo(params.equalTo)
        .limitToLast(params.minData)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArrayPosts(data.val())
            sortedData.sort(function (a, b) {
              if (a.dateScheduled > b.dateScheduled) {
                return -1
              }
              if (a.dateScheduled < b.dateScheduled) {
                return 1
              }
              return 0
            })
            // addPoints(sortedData);
            dispatch({
              type: GC_THOUGHTS,
              gcthoughts: sortedData
            })
          } else {
            dispatch({
              type: GC_THOUGHTS,
              gcthoughts: {}
            })
          }
        })
    }
  case DAILY_THOUGHTS:
    return (dispatch, getState) => {
      firebase.database
        .ref('dailyThoughts')
        .orderByChild(params.orderChild)
        .equalTo(params.equalTo)
        .limitToLast(params.minData)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArrayPosts(data.val())
            sortedData.sort(function (a, b) {
              if (a.dateScheduled > b.dateScheduled) {
                return -1
              }
              if (a.dateScheduled < b.dateScheduled) {
                return 1
              }
              return 0
            })
            dispatch({
              type: DAILY_THOUGHTS,
              dailythoughts: sortedData
            })
          } else {
            dispatch({
              type: DAILY_THOUGHTS,
              dailythoughts: {}
            })
          }
        })
    }
  case MY_THOUGHTS:
    return (dispatch, getState) => {
      firebase.database
        .ref('dailyThoughts')
        .orderByChild(params.orderChild)
        .equalTo(params.equalTo)
        .limitToLast(params.minData)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArrayPosts(data.val())
            sortedData.sort(function (a, b) {
              if (a.dateScheduled > b.dateScheduled) {
                return -1
              }
              if (a.dateScheduled < b.dateScheduled) {
                return 1
              }
              return 0
            })
            dispatch({
              type: MY_THOUGHTS,
              mythoughts: sortedData
            })
          } else {
            dispatch({
              type: MY_THOUGHTS,
              mythoughts: {}
            })
          }
        })
    }
  case PLDP_THOUGHTS:
    return (dispatch, getState) => {
      firebase.database
        .ref('myPLDP/dailyThoughts')
        .orderByChild(params.orderChild)
        .equalTo(params.equalTo)
        .limitToLast(10)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArrayPosts(data.val())
            sortedData.sort(function (a, b) {
              if (a.dateScheduled > b.dateScheduled) {
                return -1
              }
              if (a.dateScheduled < b.dateScheduled) {
                return 1
              }
              return 0
            })
            dispatch({
              type: PLDP_THOUGHTS,
              pldpthoughts: sortedData
            })
            return
          }
          dispatch({
            type: PLDP_THOUGHTS,
            pldpthoughts: {}
          })
          return
        })
    }

    // case INPUT_HASHTAG: // add count //count this hashtag // export count
    // const updates = {};
    //   return (dispatch, getState) => {
    //     firebase.database
    //       .ref('hashtags')
    //       .orderByChild('hashtag')
    //       .equalTo(params.hashtag)
    //       .limitToLast(params.minData)
    //       .on('value', function (data) {
    //         if (data.val()) { //hashtag exists // increment count // export new count
    //           let hash = data.val()
             
    //           var hashcount;
    //           if (Object.keys(hash).length > 0) {
    //             updates['/hashtags/' + params.hashtag] = {
    //               hash: params.hashtag,
    //               count: hash.count + 1
    //             }
    //             let hashcount = hash.count + 1;
    //            }
    //           dispatch({
    //             type: INPUT_HASHTAG,
    //             hashcount: hashcount
    //           })
    //         } else { //no hashtag yet // update add hash and count=1
    //           updates['/hashtags/' + params.hashtag] = {
    //             hash: params.hashtag,
    //             count: 1,
    //             leadID: params.postID
    //           }
    //           dispatch({
    //             type: INPUT_HASHTAG,
    //             hashcount: 1
    //           })
    //         }
    //       })
    //   }
      case GET_TOP_HASHTAGS: // add count //count this hashtag // export count
      return (dispatch, getState) => {
        firebase.database
          .ref('hashtags')
          .orderByChild('hashtag')
          .limitToLast(params.minData)
          .on('value', function (data) {
            if (data.val()) { //hashtag exists // increment count // export new count
              let hash = data.val()
              let receivedData = {};
              if (Object.keys(hash).length > 0) {
               
                let sortedData = _toArrayPosts(hash)
                sortedData.sort(function (a, b) {
    
                  if (a.count > b.count) {
                    // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                    return 1
                  }
                  if (a.count < b.count) {
                  //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                    return -1
                  }
                  return 0
                })
          
                receivedData = { ...sortedData }
    
                receivedData = Object.assign({}, sortedData)
                console.log('green company users : ', receivedData)
               }
              dispatch({
                type: GET_TOP_HASHTAGS,
                hashlist: receivedData
              })
            } else { //no hashtag yet // update add hash and count=1
              dispatch({
                type: GET_TOP_HASHTAGS,
                hashlist: {}
              })
            }
          })
      }      
  default:
    return (dispatch, getState) => {
      dispatch({
        type: 'NO_DATA',
        thoughts: {}
      })
    }
  }
}

function loadLeads() {
  // const promise = this.findAnswerD(prompt)
  // promise.then(this.onFulfilled, this.onRejected)
  console.log('receivedData 3 : '. receivedData)
  const promise = getLeads();
  promise.then(this.onFulfilled, this.onRejected)
}

function getLeads(){
  const receivedData = []
  console.log('receivedData 4 : '. receivedData)
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.log('receivedData 5 : '. receivedData)
      const res = axios.post(`${FB_URL}/m102-leadsData`, {});
      if (res.data) {
        // .then(res => {
          console.log('receivedData 6 : '. receivedData)
            receivedData = res.data.data
        // })
          }
     }, 5000)
    return receivedData;
  })
}

const analyzeArray = (arr) => {
  const properties = new Set();

  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      properties.add(key);
    });
  });

  return Array.from(properties);
};

const isSerializable = (obj) => {
  if (_.isUndefined(obj) ||
      _.isNull(obj) ||
      _.isBoolean(obj) ||
      _.isNumber(obj) ||
      _.isString(obj)) {
    return true;
  }

  if (!_.isPlainObject(obj) &&
      !_.isArray(obj)) {
    return false;
  }

  for (var key in obj) {
    if (!isSerializable(obj[key])) {
      return false;
    }
  }

  return true;
};


export const getTrendingLeads = (params) => {
  return async (dispatch, getState) => {
    // let receivedData = []
    // let receivedDatab = []
    //const eq = params.equalTo;
   // const x = 1
   //.post(`${FB_URL}/m102-leadsData`, {params: {equalTo: eq, inc: x}})
    await axios
    .post(`${FB_URL}/m102-leadsData`, {})
    .then(res => {
      console.log('ileads_leads2 3 : ', res);
      if (res.data) {
      let result = res.data;
     // var leads = [];
    
     let json_obj =  result.data

     let sortedData = _toArrayLeadsLimit(json_obj)

      // let myJSONstr = JSON.stringify(sortedData);
      // let myJSON2str = myJSONstr.trim();
      // let myJSON = JSON.parse(myJSON2str);

      // let receivedData = { ...sortedData }
      // receivedData = Object.assign({}, sortedData)
    
    console.log('green categories topleads F : ', sortedData)

      dispatch({
        type: ILEAD_LEADS,
        ilead: sortedData
      })
     }
     else {
      dispatch({
        type: ILEAD_LEADS,//ILEAD_LEADS2,
        ilead: {}
      })
    }
    })
    }  
}

function addPoints(sData) {
  console.log('inside addPoints ', sData)

  let updates = {}
  // uid, thoughtId
  for (let i = 0; i < 5; i++) {
    let uid = sData[i].uid ? sData[i].uid : 0
    var thoughtId = sData[i].dailyThoughtID ? sData[i].dailyThoughtID : 0
    console.log('inside addPoints 2: ', uid)
    if (uid !== 0) {
      firebase.database
        .ref('user/' + uid)
        .on('value', function (data) {
          if (data.val().points) {
          // var sortedData = _toArrayPosts(data.val());
            console.log('addPoints sortedData: ', data.val())
            // ----------------------------------------------------
            updates['dailyThoughts/' + thoughtId] = {
              // companyName: params.companyName,
              // firstName: params.firstName,
              // lastName: params.lastName,
              points: data.val().points
            }
            firebase.database.ref().update(updates, error => {
              console.log('add new data to thoughtscard SUCCESS')
            })
            // -----------------------------------------------------
          }
        })
    }
  } // for loop
}

// function addPoints(sData) {
//     var updates = {};
//    // uid, thoughtId

//     firebase.database
//       .ref('user/' + uid)
//       .on("value", function(data) {
//          if (data.val().points) {
//           // var sortedData = _toArrayPosts(data.val());
//            console.log('addPoints sortedData: ', data.val());
//               //----------------------------------------------------
//                 updates['dailyThoughts/' + thoughtId] = {
//                   // companyName: params.companyName,
//                   // firstName: params.firstName,
//                   // lastName: params.lastName,
//                   points: data.val().points
//                 }
//                 firebase.database.ref().update(updates, error => {
//                   console.log('add new data to thoughtscard SUCCESS');
//                 })
//               //-----------------------------------------------------
//         //  return true;
//          }
//          return true
//       });
// };

// const insertNewUserdata = params => {
//   var updates = {};
//   console.log('insertNewUserdata: ', params);
//          // updates['/'+params.collection+'/' + params.cardID] = {
//           updates[params.refr] = {
//             companyName: params.companyName,
//             firstName: params.firstName,
//             lastName: params.lastName,
//             points: params.points ? params.points : ''
//           }
//         firebase.database.ref().update(updates, error => {
//           console.log('add new data to thoughtscard SUCCESS');
//        })
//  };

export const filteredPost = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref(params.ref)
      .orderByChild(params.orderChild)
      .equalTo(params.equalTo)
      .on('value', function (data) {
        if (data.val()) {
        
          let sortedData = _toArrayPosts(data.val())
          sortedData.sort(function (a, b) {
            if (a.dateScheduled > b.dateScheduled) {
              return -1
            }
            if (a.dateScheduled < b.dateScheduled) {
              return 1
            }
            return 0
          })

          dispatch({
            type: FILTERED_POST,
            filteredpost: sortedData
          })
        } else {
          return (dispatch, getState) => {
            dispatch({
              type: FILTERED_POST,
              filteredpost: []
            })
          }
        }
      })
  }
}

export const filteredGroupPost = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref(params.ref)
      .on('value', function (data) {
        if (data.val()) {
          let dataval = data.val()
          let sortedData = []
          sortedData[dataval.postID] = data.val()
          sortedData[dataval.postID].id = dataval.postID
          dispatch({
            type: FILTERED_POST,
            filteredpost: sortedData
          })
        } else {
          return (dispatch, getState) => {
            dispatch({
              type: FILTERED_POST,
              filteredpost: []
            })
          }
        }
      })
  }


  // export const createThought = params => {
  //   var USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
  //   return (dispatch, getState) => {
  //     // set user data
  //     if (params.subcompetencyid) {
  //     //nothing
  //     }
  //     else
  //     {
  //       params.subcompetencyid = params.competencyid + '-' + 'None';
  //       console.log('thoughtsActions createThought props: ', params.subcompetencyid);
  //     }
  //     var body = {
  //       ...params,
  //       companyID: USER_LOGGED.companyID,
  //       companyName: USER_LOGGED.companyName,
  //       journalUserID: USER_LOGGED.userID,
  //       journalUserName: USER_LOGGED.firstName + " " + USER_LOGGED.lastName,
  //       user_type: USER_LOGGED.userType,
  //       photoURL: USER_LOGGED.photoURL || "",
  //       postingFrom: APP_TYPE === "PUBLIC" ? 1 : 2
  //     };

  //     // attach any available urls object
  //     // Add url to thought
  //     var urlCount = Object.keys(params.articlesObj).length;
  //     var urls = {};

  //     for (var i = 0; i < urlCount; i++) {
  //       var uuid = uuidv4();
  //       var url = {
  //         companyID: USER_LOGGED.companyID,
  //         companyName: USER_LOGGED.companyName,
  //         dateRegistered: body.dateRegistered,
  //         stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
  //         title: params.articlesObj[i].title,
  //         url: params.articlesObj[i].url
  //       };

  //       urls[uuid] = url;
  //     }

  //     // embed urls to body
  //     if (urlCount > 0) {
  //       body.urls = urls;
  //     }

  //     // Add files to thought
  //     var fileCount = Object.keys(params.filesObj).length;
  //     var files = {};

  //     for (var i = 0; i < fileCount; i++) {
  //       var uuid = uuidv4();
  //       var file = {
  //         companyID: USER_LOGGED.companyID,
  //         companyName: USER_LOGGED.companyName,
  //         dateRegistered: body.dateRegistered,
  //         stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
  //         title: params.filesObj[i].title,
  //         url: params.filesObj[i].url
  //       };

  //       files[uuid] = file;
  //     }

  //     // embed files to body
  //     if (fileCount > 0) {
  //       body.files = files;
  //     }

  //     // attach any available podcasts object
  //     // Add podcast url to thought
  //     if (params.PodcastExternalRecord !== "podcastnone") {
  //       var podcastUrlCount = Object.keys(params.podcastsObj).length;
  //       var podcastUrls = {};

  //       for (var i = 0; i < podcastUrlCount; i++) {
  //         var uuid = uuidv4();
  //         var url = {
  //           active: true,
  //           companyID: USER_LOGGED.companyID,
  //           companyName: USER_LOGGED.companyName,
  //           dateRegistered: body.dateRegistered,
  //           dateScheduled: body.dateScheduled,
  //           stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
  //           stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
  //           title: params.podcastsObj[i].title,
  //           url: params.podcastsObj[i].url,
  //           dailyThoughtID: "0",
  //           podcastDescription: "Podcast",
  //           podcastID: uuid,
  //           podcastSize: 0,
  //           podcastType: 2,
  //           storageName: params.podcastsObj[i].title,
  //           userID: body.journalUserID,
  //           userName: body.journalUserName,
  //           uid: body.uid
  //         };

  //         podcastUrls[uuid] = url;

  //         // Embed podcasts to body
  //         body.podcasts = podcastUrls;
  //       }

  //       // Add podcast uploads
  //       var podcastUrlCnt = Object.keys(params.podcasturl).length;

  //       // embed urls to body
  //       if (podcastUrlCnt > 0) {
  //         var uuid = uuidv4();
  //         var url = {
  //           active: true,
  //           companyID: USER_LOGGED.companyID,
  //           companyName: USER_LOGGED.companyName,
  //           dateRegistered: body.dateRegistered,
  //           dateScheduled: body.dateScheduled,
  //           stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
  //           stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
  //           title: params.podcasturl.podcastname,
  //           url: params.podcasturl.podcasturl,
  //           dailyThoughtID: "0",
  //           podcastDescription: "Podcast",
  //           podcastID: uuid,
  //           podcastSize: 0,
  //           podcastType: 2,
  //           storageName: params.podcasturl.podcastname,
  //           userID: body.journalUserID,
  //           userName: body.journalUserName,
  //           uid: body.uid
  //         };

  //         podcastUrls[uuid] = url;

  //         // Embed podcasts to body
  //         body.podcasts = podcastUrls;
  //       }

  //       // Add audio recordings
  //       var audioUrlCnt = Object.keys(params.audiourl).length;

  //       // embed urls to body
  //       if (audioUrlCnt > 0) {
  //         var uuid = uuidv4();
  //         var url = {
  //           active: true,
  //           companyID: USER_LOGGED.companyID,
  //           companyName: USER_LOGGED.companyName,
  //           dateRegistered: body.dateRegistered,
  //           dateScheduled: body.dateScheduled,
  //           stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
  //           stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
  //           title: params.audiourl.audioname,
  //           url: params.audiourl.audiourl,
  //           dailyThoughtID: "0",
  //           podcastDescription: "Voicemail",
  //           podcastID: uuid,
  //           podcastSize: 0,
  //           podcastType: 1,
  //           storageName: params.audiourl.audioname,
  //           userID: body.journalUserID,
  //           userName: body.journalUserName,
  //           uid: body.uid
  //         };

  //         podcastUrls[uuid] = url;

  //         // Embed podcasts to body
  //         body.podcasts = podcastUrls;
  //       }
  //     }

  //     // Embed images
  //     var imageUrlCnt = Object.keys(params.imageurl).length;
  //     var imageUrls = {};

  //     // embed urls to body
  //     if (imageUrlCnt > 0) {
  //       var uuid = uuidv4();
  //       var url = {
  //         bytes: 0,
  //         caption: params.imageurl.imagename,
  //         dailyThoughtID: "0",
  //         date: body.dateRegistered,
  //         dateRegistered: body.dateRegistered,
  //         dateUploaded: body.dateRegistered,
  //         height: 0,
  //         imageSize: 0,
  //         stringDate: new Date(body.dateRegistered).toLocaleString(),
  //         stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
  //         stringDateUploaded: new Date(body.dateRegistered).toLocaleString(),
  //         thumbNailUrl: params.imageurl.imageurl,
  //         title: body.title,
  //         url: params.imageurl.imageurl,
  //         photoID: uuid,
  //         userID: body.journalUserID,
  //         userName: body.journalUserName,
  //         uid: body.uid,
  //         width: 0
  //       };

  //       imageUrls[uuid] = url;

  //       // Embed images to body
  //       body.photos = imageUrls;
  //     }

  //     // Embed Videos
  //     // attach any available video object
  //     // Add video url to thought
  //     var videoUrlCount = Object.keys(params.videosObj).length;
  //     var videoUrls = {};

  //     for (var i = 0; i < videoUrlCount; i++) {
  //       var uuid = uuidv4();
  //       var url = {
  //         active: true,
  //         company_status: USER_LOGGED.companyID + "_true",
  //         caption: params.videosObj[i].title,
  //         companyID: USER_LOGGED.companyID,
  //         companyName: USER_LOGGED.companyName,
  //         date: body.dateRegistered,
  //         dateRegistered: body.dateRegistered,
  //         dateUploaded: body.dateRegistered,
  //         dateUpdated: 0,
  //         stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
  //         title: params.videosObj[i].title,
  //         url: params.videosObj[i].url,
  //         dailyThoughtID: "0",
  //         description: body.title,
  //         videoID: uuid,
  //         storageName: params.videosObj[i].title,
  //         userID: body.journalUserID,
  //         userName: body.journalUserName,
  //         uid: body.uid,
  //         photoURL: USER_LOGGED.photoURL || "",
  //         videoSize: 0,
  //         lengthInSeconds: 0,
  //         UploadExternal: "vidupload"
  //       };

  //       videoUrls[uuid] = url;

  //       // Embed videos to body
  //       body.videos = videoUrls;
  //     }

  //     if (params.VidUploadExternal !== "videonone") {
  //       // Add video uploads
  //       var videoUrlCnt = Object.keys(params.videourl).length;

  //       // embed urls to body
  //       if (videoUrlCnt > 0) {
  //         var uuid = uuidv4();
  //         var url = {
  //           active: true,
  //           company_status: USER_LOGGED.companyID + "_true",
  //           caption: params.videourl.videoname,
  //           companyID: USER_LOGGED.companyID,
  //           companyName: USER_LOGGED.companyName,
  //           date: body.dateRegistered,
  //           dateRegistered: body.dateRegistered,
  //           dateUploaded: body.dateRegistered,
  //           dateUpdated: 0,
  //           stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
  //           url: params.videourl.videourl,
  //           dailyThoughtID: "0",
  //           description: body.title,
  //           videoID: uuid,
  //           storageName: params.videourl.title,
  //           userID: body.journalUserID,
  //           userName: body.journalUserName,
  //           uid: body.uid,
  //           photoURL: USER_LOGGED.photoURL || "",
  //           videoSize: 0,
  //           lengthInSeconds: 0,
  //           UploadExternal: "videxternal"
  //         };

  //         videoUrls[uuid] = url;

  //         // Embed podcasts to body
  //         body.videos = videoUrls;
  //       }
  //     }

  //     // clean data before posting
  //     delete body["maxLengthThought"];
  //     delete body["maxLengthAuthor"];
  //     delete body["modalTitle"];
  //     delete body["postInternalGlobal"];
  //     delete body["uploadInProgress"];
  //     delete body["selectedDate"];
  //     delete body["selectedTime"];
  //     delete body["PodcastExternalRecord"];
  //     delete body["VidUploadExternal"];
  //     delete body["articlesObj"];
  //     delete body["audiourl"];
  //     delete body["imageurl"];
  //     delete body["podcastsObj"];
  //     delete body["podcastType"];
  //     delete body["podcasturl"];
  //     delete body["videourl"];
  //     delete body["videosObj"];
  //     delete body["fileurl"];
  //     delete body["filesObj"];

  //     // Editing or Creating new post?
  //     if (params.editing) {
  //       delete body["dateRegistered"];
  //       delete body["post"];
  //       body.name = "thoughts";
  //       body.id = params.post.id; //params.post.dailyThoughtID;

  //       // post new data
  //       // console.log('thoughtsActions post: ', params);

  //       axios
  //         .put(`${BASE_API_URL}/thoughts/${body.id}`, body)
  //         .then(res => {
  //           dispatch({
  //             type: "CREATE_THOUGHT_SUCCESS"
  //           });
  //         })
  //         .catch(err => {
  //           dispatch({
  //             type: "SHOW_TOAST",
  //             open: true,
  //             variant: "error",
  //             message: "Error creating a post, please confirm that all fields are filled in!"
  //           });
  //           // if (err.response) {
  //           //   dispatch({
  //           //     type: "SHOW_TOAST",
  //           //     open: true,
  //           //     variant: "error",
  //           //     message: "Error response: " + err.response.data.message
  //           //   });
  //           // } else if (err.request) {
  //           //   dispatch({
  //           //     type: "SHOW_TOAST",
  //           //     open: true,
  //           //     variant: "error",
  //           //     message: "Error request: " + err.request
  //           //   });
  //           // } else {
  //           //   dispatch({
  //           //     type: "SHOW_TOAST",
  //           //     open: true,
  //           //     variant: "error",
  //           //     message: "Error message: " + err.message
  //           //   });
  //           // }
  //         });
  //     } else {
  //       // post new data
  //       console.log("thoughtsActions single posting: ", body);
  //       if ((body.groupid === "1") && (body.dailyThoughtType == "4") && (body.selectedgroups.length > 0)) {

  //         // create multiple posts requests
  //         // console.log("selected groups: ", body.selectedgroups)
  //         // console.log("multiple posts requests: ", body)
  //         var multi_requests = [];


  //         for (let i = 0, l = body.selectedgroups.length; i < l; i += 1) {
  //           let single_body = { ...body };
  //           single_body.groupid = body.selectedgroups[i].group_id
  //           single_body.groupname = body.selectedgroups[i].group_name

  //           delete single_body["selectedgroups"];
  //           console.log("thoughtsActions finished posting 1: ", multi_requests)
  //           const request = axios.post(`${BASE_API_URL}/thoughts`, single_body)

  //           multi_requests.push(request)
  //         }

  //         // console.log("multi_requests: ", multi_requests)

  //         axios.all(multi_requests).then(axios.spread((...responses) => {
  //          console.log("thoughtsActions finished posting 2: ", responses)
  //           dispatch({
  //             type: "CREATE_THOUGHT_SUCCESS"
  //           });
  //           // const responseOne = responses[0]
  //           // const responseTwo = responses[1]
  //           // const responesThree = responses[2]
  //           // use/access the results
  //         })).catch(errors => {
  //           // react on errors.
  //           // console.log("error posting: ", errors)
  //           dispatch({
  //             type: "SHOW_TOAST",
  //             open: true,
  //             variant: "error",
  //             message: "Error posting a thought!"
  //           });
  //         })
  //       } else {
  //          console.log("thoughtsActions finished posting 3: ", body)
  //         axios
  //           .post(`${BASE_API_URL}/thoughts`, body)
  //           .then(res => {
  //             dispatch({
  //               type: "CREATE_THOUGHT_SUCCESS"
  //             });
  //           })
  //           .catch(err => {
  //             dispatch({
  //               type: "SHOW_TOAST",
  //               open: true,
  //               variant: "error",
  //               message: "Error creating a post, please confirm that all fields are filled in!"
  //             });
  //             // if (err.response) {
  //             //   dispatch({
  //             //     type: "SHOW_TOAST",
  //             //     open: true,
  //             //     variant: "error",
  //             //     message: "Error response: " + err.response.data.message
  //             //   });
  //             // } else if (err.request) {
  //             //   dispatch({
  //             //     type: "SHOW_TOAST",
  //             //     open: true,
  //             //     variant: "error",
  //             //     message: "Error request: " + err.request
  //             //   });
  //             // } else {
  //             //   dispatch({
  //             //     type: "SHOW_TOAST",
  //             //     open: true,
  //             //     variant: "error",
  //             //     message: "Error message: " + err.message
  //             //   });
  //             // }
  //           });

  //       }

  //     }
  //   };
  // };
}

export const createThought = (params) => {
 // let e = params.e;
 // e.preventDefault();
  let USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE))
  console.log('green USER_LOGGED: ', USER_LOGGED) // -LEiZPT-C2PyLu_YLKNU
  console.log('green params 1 : ', params)
  console.log('green params 2 : ', params.videosObj)
 // console.log('green params 3 : ', params.props.userdata.pointsy)

  return async (dispatch, getState) => {
    // set user data
    if (params.subcompetencyid) {
    // nothing
    } else {
      params.subcompetencyid = params.competencyid + '-' + 'None'
      console.log('thoughtsActions createThought props: ', params.subcompetencyid)
    }
    let body = {
      ...params,
      companyID: USER_LOGGED.companyID,
      companyName: USER_LOGGED.companyName,
      journalUserID: USER_LOGGED.userID,
      journalUserName: USER_LOGGED.firstName + ' ' + USER_LOGGED.lastName,
      user_type: USER_LOGGED.userType,
      photoURL: USER_LOGGED.photoURL || '',
      postingFrom: APP_TYPE === 'PUBLIC' ? 1 : 2
    }

    // attach any available urls object
    // Add url to thought
    let urlCount = Object.keys(params.articlesObj).length
    let urls = {}

    for (var i = 0; i < urlCount; i++) {
      var uuid = uuidv4()
      var url = {
        companyID: USER_LOGGED.companyID,
        companyName: USER_LOGGED.companyName,
        dateRegistered: body.dateRegistered,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.articlesObj[i].title,
        url: params.articlesObj[i].url,
        real_companyID: params.real_companyID,
        execmember: body.execmember
      }

      urls[uuid] = url
    }

    // embed urls to body
    if (urlCount > 0) {
      body.urls = urls
    }

    // Add files to thought
    let fileCount = Object.keys(params.filesObj).length
    let files = {}

    for (var i = 0; i < fileCount; i++) {
      var uuid = uuidv4()
      let file = {
        companyID: USER_LOGGED.companyID,
        real_companyID: params.real_companyID,
        execmember: body.execmember,
        companyName: USER_LOGGED.companyName,
        dateRegistered: body.dateRegistered,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.filesObj[i].title,
        url: params.filesObj[i].url
      }

      files[uuid] = file
    }

    // embed files to body
    if (fileCount > 0) {
      body.files = files
    }

    // attach any available podcasts object
    // Add podcast url to thought
    if (params.PodcastExternalRecord !== 'podcastnone') {
      let podcastUrlCount = Object.keys(params.podcastsObj).length
      let podcastUrls = {}

      for (var i = 0; i < podcastUrlCount; i++) {
        var uuid = uuidv4()
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          real_companyID: params.real_companyID,
          execmember: body.execmember,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.podcastsObj[i].title,
          url: params.podcastsObj[i].url,
          dailyThoughtID: '0',
          podcastDescription: 'Podcast',
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 2,
          storageName: params.podcastsObj[i].title,
          userID: body.journalUserID,
          userName: body.journalUserName,
          uid: body.uid
        }

        podcastUrls[uuid] = url

        // Embed podcasts to body
        body.podcasts = podcastUrls
      }

      // Add podcast uploads
      let podcastUrlCnt = Object.keys(params.podcasturl).length

      // embed urls to body
      if (podcastUrlCnt > 0) {
        var uuid = uuidv4()
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          real_companyID: params.real_companyID,
          execmember: body.execmember,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.podcasturl.podcastname,
          url: params.podcasturl.podcasturl,
          dailyThoughtID: '0',
          podcastDescription: 'Podcast',
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 2,
          storageName: params.podcasturl.podcastname,
          userID: body.journalUserID,
          userName: body.journalUserName,
          uid: body.uid
        }

        podcastUrls[uuid] = url

        // Embed podcasts to body
        body.podcasts = podcastUrls
      }

      // Add audio recordings
      let audioUrlCnt = Object.keys(params.audiourl).length

      // embed urls to body
      if (audioUrlCnt > 0) {
        var uuid = uuidv4()
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          real_companyID: params.real_companyID,
          execmember: body.execmember,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.audiourl.audioname,
          url: params.audiourl.audiourl,
          dailyThoughtID: '0',
          podcastDescription: 'Voicemail',
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 1,
          storageName: params.audiourl.audioname,
          userID: body.journalUserID,
          userName: body.journalUserName,
          uid: body.uid
        }

        podcastUrls[uuid] = url

        // Embed podcasts to body
        body.podcasts = podcastUrls
      }
    }

    // Embed images
    let imageUrlCnt = Object.keys(params.imageurl).length
    let imageUrls = {}

    // embed urls to body
    if (imageUrlCnt > 0) {
      var uuid = uuidv4()
      var url = {
        bytes: 0,
        caption: params.imageurl.imagename,
        dailyThoughtID: '0',
        date: body.dateRegistered,
        dateRegistered: body.dateRegistered,
        dateUploaded: body.dateRegistered,
        height: 0,
        imageSize: 0,
        stringDate: new Date(body.dateRegistered).toLocaleString(),
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        stringDateUploaded: new Date(body.dateRegistered).toLocaleString(),
        thumbNailUrl: params.imageurl.imageurl,
        title: body.title,
        url: params.imageurl.imageurl,
        photoID: uuid,
        userID: body.journalUserID,
        userName: body.journalUserName,
        real_companyID: params.real_companyID,
        execmember: body.execmember,
        uid: body.uid,
        points: body.points ? body.points : '0',
        pointsy: body.pointsy ? body.pointsy : '0',
        pointsCalf: body.pointsCalf ? body.pointsCalf : '0',
        width: 0
      }

      imageUrls[uuid] = url

      // Embed images to body
      body.photos = imageUrls
    }

    // Embed Videos
    // attach any available video object
    // Add video url to thought
    let videoUrlCount = Object.keys(params.videosObj).length
    let videoUrls = {}

    for (var i = 0; i < videoUrlCount; i++) {
      var uuid = uuidv4()
      var url = {
        active: true,
        company_status: USER_LOGGED.companyID + '_true',
        caption: params.videosObj[i].title,
        companyID: USER_LOGGED.companyID,
        real_companyID: params.real_companyID,
        execmember: body.execmember,
        companyName: USER_LOGGED.companyName,
        date: body.dateRegistered,
        dateRegistered: body.dateRegistered,
        dateUploaded: body.dateRegistered,
        dateUpdated: 0,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.videosObj[i].title,
        url: params.videosObj[i].url,
        dailyThoughtID: '0',
        description: body.title,
        videoID: uuid,
        storageName: params.videosObj[i].title,
        userID: body.journalUserID,
        userName: body.journalUserName,
        uid: body.uid,
        points: body.points ? body.points : '0',
        pointsy: body.pointsy ? body.pointsy : '0',
        pointsCalf: body.pointsCalf ? body.pointsCalf : '0',
        photoURL: USER_LOGGED.photoURL || '',
        videoSize: 0,
        lengthInSeconds: 0,
        UploadExternal: 'vidupload'
      }

      videoUrls[uuid] = url

      // Embed videos to body
      body.videos = videoUrls
    }

    if (params.VidUploadExternal !== 'videonone') {
      // Add video uploads
      let videoUrlCnt = Object.keys(params.videourl).length

      // embed urls to body
      if (videoUrlCnt > 0) {
        var uuid = uuidv4()
        var url = {
          active: true,
          company_status: USER_LOGGED.companyID + '_true',
          caption: params.videourl.videoname,
          companyID: USER_LOGGED.companyID,
          real_companyID: params.real_companyID,
          execmember: body.execmember,
          companyName: USER_LOGGED.companyName,
          date: body.dateRegistered,
          dateRegistered: body.dateRegistered,
          dateUploaded: body.dateRegistered,
          dateUpdated: 0,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          url: params.videourl.videourl,
          dailyThoughtID: '0',
          description: body.title,
          videoID: uuid,
          storageName: params.videourl.title,
          userID: body.journalUserID,
          userName: body.journalUserName,
          uid: body.uid,
          points: body.points ? body.points : '0',
          pointsy: body.pointsy ? body.pointsy : '0',
          pointsCalf: body.pointsCalf ? body.pointsCalf : '0',
          photoURL: USER_LOGGED.photoURL || '',
          videoSize: 0,
          lengthInSeconds: 0,
          UploadExternal: 'videxternal'
        }

        videoUrls[uuid] = url

        // Embed podcasts to body
        body.videos = videoUrls
      }
    }

    // clean data before posting
    delete body.maxLengthThought
    delete body.maxLengthAuthor
    delete body.modalTitle
    delete body.postInternalGlobal
    delete body.uploadInProgress
    delete body.selectedDate
    delete body.selectedTime
    delete body.PodcastExternalRecord
    delete body.VidUploadExternal
    delete body.articlesObj
    delete body.audiourl
    delete body.imageurl
    delete body.podcastsObj
    delete body.podcastType
    delete body.podcasturl
    delete body.videourl
    delete body.videosObj
    delete body.fileurl
    delete body.filesObj

    // Editing or Creating new post?
    if (params.editing) {
      delete body.dateRegistered
      delete body.post
      body.name = 'thoughts'
      body.id = params.post.id // params.post.dailyThoughtID;

      // post new data
      //body.hashtags = '#sport3';

      console.log('thoughtsActions finished posting 3 : ', body);

      axios
        .put(`${BASE_API_URL}/thoughts/${body.id}`, body)
        .then(res => {
          dispatch({
            type: 'CREATE_THOUGHT_SUCCESS'
          })
        })
        .catch(err => {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error creating a LEAD, please confirm that all fields are filled in! 1: ' + err.message
          })
          // if (err.response) {
          //   dispatch({
          //     type: "SHOW_TOAST",
          //     open: true,
          //     variant: "error",
          //     message: "Error response: " + err.response.data.message
          //   });
          // } else if (err.request) {
          //   dispatch({
          //     type: "SHOW_TOAST",
          //     open: true,
          //     variant: "error",
          //     message: "Error request: " + err.request
          //   });
          // } else {
          //   dispatch({
          //     type: "SHOW_TOAST",
          //     open: true,
          //     variant: "error",
          //     message: "Error message: " + err.message
          //   });
          // }
        })
    } else {
      // post new data
      //body.hashtags = '#sport1';
      console.log('thoughtsActions finished posting 1: ', body)
      if ((body.groupid === '1') && (body.dailyThoughtType == '4') && (body.selectedgroups.length > 0)) {
        console.log('thoughtsActions single inside: ', body)
        // create multiple posts requests
        // console.log("selected groups: ", body.selectedgroups)
        // console.log("multiple posts requests: ", body)
        let multi_requests = []


        for (let i = 0, l = body.selectedgroups.length; i < l; i = i + 1) {
          let single_body = { ...body }
          single_body.groupid = body.selectedgroups[i].group_id
          single_body.groupname = body.selectedgroups[i].group_name

          delete single_body.selectedgroups
          //body.hashtags = '#sport2';
          console.log('thoughtsActions finished posting 2: ', multi_requests)
          const request = axios.post(`${BASE_API_URL}/thoughts`, single_body)

          multi_requests.push(request)
        }

        // console.log("multi_requests: ", multi_requests)

       axios.all(multi_requests).then(axios.spread((...responses) => {
        //body.hashtags = '#sport3';
          console.log('thoughtsActions finished posting 3: ', responses)
          dispatch({
            type: 'CREATE_THOUGHT_SUCCESS'
          })
          // const responseOne = responses[0]
          // const responseTwo = responses[1]
          // const responesThree = responses[2]
          // use/access the results
        })).catch(errors => {
          // react on errors.
          // console.log("error posting: ", errors)
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error posting a thought!'
          })
        })
      } else {
     // const updates = {};
       /////DOES THE HASHTAG EXIST? IF SO, ADD TO THE COUNT// IF NOT, CREATE IT///////////////
        // firebase.database
        //   .ref('hashtags')
        //   .orderByChild('hash')
        //   .equalTo(params.hashtag)
        //   .on('value', function (data) {
        //     if (data.val()) { //hashtag exists // increment count // export new count
        //       let hash = data.val()
             
        //       var hashcount;
        //       if (Object.keys(hash).length > 0) {
        //         updates['/hashtags/' + params.hashtag] = {
        //           hash: params.hashtag,
        //           count: hash.count + 1
        //         }
        //         let hashcount = hash.count + 1;
        //        }
        //     } else { //no hashtag yet // add hash and count=1
        //       updates['/hashtags/' + params.hashtag] = {
        //         hash: params.hashtag,
        //         count: 1,
        //         leadID: params.postID
        //       }
        //     }
        //   })
       //////////////////////////////////////////////////////////////////////////////
       console.log('thoughtsActions finished posting 4: ', body)
       body.id = params.post.dailyThoughtID;
        // axios
        //   .post(`${BASE_API_URL}/thoughts`, body)
        //   .then(res => {
        //     dispatch({
        //       type: 'CREATE_THOUGHT_SUCCESS'
        //     })
        //   })

       // await fetch(`${BASE_API_URL}/thoughts`, {

        // await axios(`${BASE_API_URL}/thoughts`, { 
        //   method: "post",
        //   headers: {
        //     'Content-Type': 'Application/json'
        //   },
        //   data: body
        // })

        // await axios.request({
        //   url: `${BASE_API_URL}/thoughts`,
        //   method: 'post',
        //   headers: {'Content-Type': 'Application/json'},
        //   data: body
        // })

        // axios.post(BASE_API_URL + '/thoughts', body, {
        //   headers: {
        //     Accept: "application/json",
        //     "Cache-Control": "no-cache"
        //   },
        // })


      //   await axios(BASE_API_URL + '/thoughts',
      //   {
      //     'method': 'post',
      //     'type': 'Application/json',
      //     'data': querystring.stringify(body)
      //   }
      // )

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      await axios.post(BASE_API_URL + '/thoughts',
      body,config)


      // await axios.request({
      //   url: `${BASE_API_URL}/thoughts`,
      //   method: 'post',
      //   headers: {'Content-Type': 'Application/json'},
      //   data: JSON.stringify(body)
      // })
      
      //   await axios(BASE_API_URL + '/thoughts',
      //   {
      //     'method': 'post',
      //     'type': 'Application/json',
      //     'data': body
      //   }
      // )  

      .then(res => {
        // params.props.handleClose()
             dispatch({
               type: 'CREATE_THOUGHT_SUCCESS'
             })
           })
          .catch(e => {
            //body.hashtags = '#sport5';
            console.log('thoughtsActions finished posting 5 : ', e.response)
            dispatch({
              type: 'SHOW_TOAST',
              open: true,
              variant: 'error',
              message: 'Error creating a LEAD, please confirm that all fields are filled in! 2: ' + e.response
            })

            // .catch(error => {
            //   if (error.response.status === 400) {
            //     // handle 400 error
            //     console.log('Bad Request:', error.response.data);
            //   } else {
            //     // handle other errors
            //     console.log(error);
            //   }
           
            
            // if (err.response) {
            //   dispatch({
            //     type: "SHOW_TOAST",
            //     open: true,
            //     variant: "error",
            //     message: "Error response: " + err.response.data.message
            //   });
            // } else if (err.request) {
            //   dispatch({
            //     type: "SHOW_TOAST",
            //     open: true,
            //     variant: "error",
            //     message: "Error request: " + err.request
            //   });
            // } else {
            //   dispatch({
            //     type: "SHOW_TOAST",
            //     open: true,
            //     variant: "error",
            //     message: "Error message: " + err.message
            //   });
            // }
          })
          //this.handleClose()
      }
    }
  }
}

const makeid = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
charactersLength));
  }
  return result;
}

export const createThought_IOS = params => {
 // let e = params.e;
 // e.preventDefault();
  let USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
  console.log("green USER_LOGGED: ", USER_LOGGED) // -LEiZPT-C2PyLu_YLKNU
  console.log("green params 1 : ", params.uid)
  console.log("green params 2 : ", params.videosObj)
  console.log("green params 3 : ", params.videourl.videouploaded)

  return async (dispatch, getState) => {
    // set user data
    if (params.subcompetencyid) {
    // nothing
    } else {
      params.subcompetencyid = params.competencyid + "-" + "None"
      console.log("thoughtsActions createThought props: ", params.subcompetencyid)
    }
    var body = {
      ...params,
      companyID: USER_LOGGED.companyID,
      companyName: USER_LOGGED.companyName,
      journalUserID: USER_LOGGED.userID,
      journalUserName: USER_LOGGED.firstName + " " + USER_LOGGED.lastName,
      user_type: USER_LOGGED.userType,
      photoURL: USER_LOGGED.photoURL || "",
      postingFrom: APP_TYPE === "PUBLIC" ? 1 : 2
    };

    // attach any available urls object
    // Add url to thought
    var urlCount = Object.keys(params.articlesObj).length
    var urls = {};

    for (var i = 0; i < urlCount; i++) {
      var uuid = uuidv4();
      var url = {
        companyID: USER_LOGGED.companyID,
        companyName: USER_LOGGED.companyName,
        dateRegistered: body.dateRegistered,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.articlesObj[i].title,
        url: params.articlesObj[i].url,
        real_companyID: params.real_companyID,
        execmember: body.execmember
      }

      urls[uuid] = url;
    }

    // embed urls to body
    if (urlCount > 0) {
      body.urls = urls
    }

    // Add files to thought
    let fileCount = Object.keys(params.filesObj).length
    let files = {}

    for (var i = 0; i < fileCount; i++) {
      var uuid = uuidv4()
      let file = {
        companyID: USER_LOGGED.companyID,
        real_companyID: params.real_companyID,
        execmember: body.execmember,
        companyName: USER_LOGGED.companyName,
        dateRegistered: body.dateRegistered,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.filesObj[i].title,
        url: params.filesObj[i].url
      }

      files[uuid] = file;
    }

    // embed files to body
    if (fileCount > 0) {
      body.files = files;
    }

    // attach any available podcasts object
    // Add podcast url to thought
    if (params.PodcastExternalRecord !== "podcastnone") {
      let podcastUrlCount = Object.keys(params.podcastsObj).length
      let podcastUrls = {};

      for (var i = 0; i < podcastUrlCount; i++) {
        var uuid = uuidv4()
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          real_companyID: params.real_companyID,
          execmember: body.execmember,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.podcastsObj[i].title,
          url: params.podcastsObj[i].url,
          dailyThoughtID: "0",
          podcastDescription: "Podcast",
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 2,
          storageName: params.podcastsObj[i].title,
          userID: body.journalUserID,
          userName: body.journalUserName,
          uid: body.uid
        }

        podcastUrls[uuid] = url

        // Embed podcasts to body
        body.podcasts = podcastUrls
      }

      // Add podcast uploads
      let podcastUrlCnt = Object.keys(params.podcasturl).length

      // embed urls to body
      if (podcastUrlCnt > 0) {
        var uuid = uuidv4();
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          real_companyID: params.real_companyID,
          execmember: body.execmember,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.podcasturl.podcastname,
          url: params.podcasturl.podcasturl,
          dailyThoughtID: "0",
          podcastDescription: "Podcast",
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 2,
          storageName: params.podcasturl.podcastname,
          userID: body.journalUserID,
          userName: body.journalUserName,
          uid: body.uid
        }

        podcastUrls[uuid] = url;

        // Embed podcasts to body
        body.podcasts = podcastUrls;
      }

      // Add audio recordings
      let audioUrlCnt = Object.keys(params.audiourl).length

      // embed urls to body
      if (audioUrlCnt > 0) {
        var uuid = uuidv4()
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          real_companyID: params.real_companyID,
          execmember: body.execmember,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.audiourl.audioname,
          url: params.audiourl.audiourl,
          dailyThoughtID: "0",
          podcastDescription: "Voicemail",
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 1,
          storageName: params.audiourl.audioname,
          userID: body.journalUserID,
          userName: body.journalUserName,
          uid: body.uid
        }

        podcastUrls[uuid] = url;

        // Embed podcasts to body
        body.podcasts = podcastUrls;
      }
    }

    // Embed images
    var imageUrlCnt = Object.keys(params.imageurl).length;
    var imageUrls = {};

    // embed urls to body
    if (imageUrlCnt > 0) {
      var uuid = uuidv4();
      var url = {
        bytes: 0,
        caption: params.imageurl.imagename,
        dailyThoughtID: "0",
        date: body.dateRegistered,
        dateRegistered: body.dateRegistered,
        dateUploaded: body.dateRegistered,
        height: 0,
        imageSize: 0,
        stringDate: new Date(body.dateRegistered).toLocaleString(),
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        stringDateUploaded: new Date(body.dateRegistered).toLocaleString(),
        thumbNailUrl: params.imageurl.imageurl,
        title: body.title,
        url: params.imageurl.imageurl,
        photoID: uuid,
        userID: body.journalUserID,
        userName: body.journalUserName,
        real_companyID: params.real_companyID,
        execmember: body.execmember,
        uid: body.uid,
        points: body.points ? body.points : "0",
        pointsy: body.pointsy ? body.pointsy : '0',
        pointsCalf: body.pointsCalf ? body.pointsCalf : "0",
        width: 0
      }

      imageUrls[uuid] = url

      // Embed images to body
      body.photos = imageUrls
    }

    // Embed Videos
    // attach any available video object
    // Add video url to thought
    let videoUrlCount = Object.keys(params.videosObj).length;
    let videoUrls = {};

    for (var i = 0; i < videoUrlCount; i++) {
      var uuid = uuidv4();
      var url = {
        active: true,
        company_status: USER_LOGGED.companyID + "_true",
        caption: params.videosObj[i].title,
        companyID: USER_LOGGED.companyID,
        real_companyID: params.real_companyID,
        execmember: body.execmember,
        companyName: USER_LOGGED.companyName,
        date: body.dateRegistered,
        dateRegistered: body.dateRegistered,
        dateUploaded: body.dateRegistered,
        dateUpdated: 0,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.videosObj[i].title,
        url: params.videosObj[i].url,
        dailyThoughtID: "0",
        description: body.title,
        videoID: uuid,
        storageName: params.videosObj[i].title,
        userID: body.journalUserID,
        userName: body.journalUserName,
        uid: body.uid,
        points: body.points ? body.points : "0",
        pointsy: body.pointsy ? body.pointsy : '0',
        pointsCalf: body.pointsCalf ? body.pointsCalf : "0",
        photoURL: USER_LOGGED.photoURL || "",
        videoSize: 0,
        lengthInSeconds: 0,
        UploadExternal: "vidupload"
      }

      videoUrls[uuid] = url

      // Embed videos to body
      body.videos = videoUrls
    }

    if (params.VidUploadExternal !== "videonone") {
      // Add video uploads
      let videoUrlCnt = Object.keys(params.videourl).length

      // embed urls to body
      if (videoUrlCnt > 0) {
        var uuid = uuidv4()
        var url = {
          active: true,
          company_status: USER_LOGGED.companyID + "_true",
          caption: params.videourl.videoname,
          companyID: USER_LOGGED.companyID,
          real_companyID: params.real_companyID,
          execmember: body.execmember,
          companyName: USER_LOGGED.companyName,
          date: body.dateRegistered,
          dateRegistered: body.dateRegistered,
          dateUploaded: body.dateRegistered,
          dateUpdated: 0,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          url: params.videourl.videourl,
          dailyThoughtID: "0",
          description: body.title,
          videoID: uuid,
          storageName: params.videourl.title,
          userID: body.journalUserID,
          userName: body.journalUserName,
          uid: body.uid,
          points: body.points ? body.points : "0",
          pointsy: body.pointsy ? body.pointsy : '0',
          pointsCalf: body.pointsCalf ? body.pointsCalf : "0",
          photoURL: USER_LOGGED.photoURL || "",
          videoSize: 0,
          lengthInSeconds: 0,
          UploadExternal: "videxternal"
        }

        videoUrls[uuid] = url

        // Embed podcasts to body
        body.videos = videoUrls
      }
    }

    // clean data before posting
    delete body["maxLengthThought"];
    delete body["maxLengthAuthor"];
    delete body["modalTitle"];
    delete body["postInternalGlobal"];
    delete body["uploadInProgress"];
    delete body["selectedDate"];
    delete body["selectedTime"];
    delete body["PodcastExternalRecord"];
    delete body["VidUploadExternal"];
    delete body["articlesObj"];
    delete body["audiourl"];
    delete body["imageurl"];
    delete body["podcastsObj"];
    delete body["podcastType"];
    delete body["podcasturl"];
    delete body["videourl"];
    delete body["videosObj"];
    delete body["fileurl"];
    delete body["filesObj"];

    // Editing or Creating new post?
    if (params.editing) {
      delete body["dateRegistered"];
      delete body["post"];
      body.name = "thoughts"
      body.id = params.post.dailyThoughtID; //params.post.id;

      // post new data
      //body.hashtags = "#sport3";

      console.log("thoughtsActions finished posting 3 : ", body);

      axios
        .put(`${BASE_API_URL}/thoughts/${body.id}`, body)
        .then(res => {
          dispatch({
            type: "CREATE_THOUGHT_SUCCESS",
          })
        })
        .catch(err => {
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error creating a LEAD, please confirm that all fields are filled in! 3: " + err.message
          })

        })
    } else {
      // post new data

     if ((body.groupid === "1") && (body.dailyThoughtType == "4") && (body.selectedgroups.length > 0)) {
        let multi_requests = [];

        for (let i = 0, l = body.selectedgroups.length; i < l; i += 1) {
          let single_body = { ...body };
          single_body.groupid = body.selectedgroups[i].group_id
          single_body.groupname = body.selectedgroups[i].group_name

          delete single_body["selectedgroups"];

          const request = axios.post(`${BASE_API_URL}/thoughts`, single_body)

          multi_requests.push(request)
        }

        axios.all(multi_requests).then(axios.spread((...responses) => {
          dispatch({
            type: "CREATE_THOUGHT_SUCCESS"
          });

        })).catch(errors => {
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error posting a thought!"
          });
        })
      } else {

        axios
          .post(`${BASE_API_URL}/thoughts`, body)
          .then(res => {
            dispatch({
              type: "CREATE_THOUGHT_SUCCESS"
            });
          })
          .catch(err => {
            dispatch({
              type: "SHOW_TOAST",
              open: true,
              variant: "error",
              message: "Error creating a post, please confirm that all fields are filled in!"
            });
          });
      }
    }
  }
}

export const createThoughtNew = (params) => {
  console.log('createThought props a: ', params)
  let USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE))
  return async (dispatch, getState) => {
    // set user data
    let body = {
      ...params,
      companyID: USER_LOGGED.companyID,
      companyName: USER_LOGGED.companyName,
      journalUserID: USER_LOGGED.userID,
      journalUserName: USER_LOGGED.firstName + ' ' + USER_LOGGED.lastName,
      user_type: USER_LOGGED.userType,
      photoURL: USER_LOGGED.photoURL || '',
      postingFrom: APP_TYPE === 'PUBLIC' ? 1 : 2
    }

    // attach any available urls object
    // Add url to thought
    let urlCount = params.articlesObj.length
    let articleUrls = {}
    let imageUrlCnt = params.imagesObj.length
    let imageUrls = {}
    let fileCount = params.filesObj.length
    let filesUrl = {}
    let podcastUrlCnt = params.podcastsObj.length
    let podcastUrls = {}
    let videoUrlCnt = params.videosObj.length
    let videoUrls = {}


    // embed urls to body
    if (urlCount > 0) {
      const addArticle = params.articlesObj.map(article => {
        return new Promise((resolve, reject) => {
          let uuid = uuidv4()
          let newUrl = {
            companyID: USER_LOGGED.companyID,
            companyName: USER_LOGGED.companyName,
            dateRegistered: body.dateRegistered,
            stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
            title: article.title,
            url: article.url
          }
          resolve(articleUrls[uuid] = newUrl)
        })
      })

      const addArticleResponse = await Promise.all(addArticle)
      body.urls = addArticleResponse
    }

    // Add files to thought
    // embed files to body
    if (fileCount > 0) {
      const addFile = params.filesObj.map(file => {
        return new Promise((resolve, reject) => {
          let uuid = uuidv4()
          let newFile = {
            companyID: USER_LOGGED.companyID,
            companyName: USER_LOGGED.companyName,
            dateRegistered: body.dateRegistered,
            stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
            title: file.title,
            url: file.url
          }
          resolve(filesUrl[uuid] = newFile)
        })
      })
      const addFileResponse = await Promise.all(addFile)
      body.files = addFileResponse
    }


    // attach any available podcasts object
    // Add podcast url to thought
    // Add podcast uploads
    console.log('createThought props 2: ', params)
    // embed urls to body
    if (podcastUrlCnt > 0) {
      console.log('createThought props 2: ', params)
      let uuid = uuidv4()
      const addPodcast = params.podcastsObj.map(pod => {
        return new Promise((resolve, reject)=> {
          let newUrl = {
            active: true,
            companyID: USER_LOGGED.companyID,
            companyName: USER_LOGGED.companyName,
            dateRegistered: body.dateRegistered,
            dateScheduled: body.dateScheduled,
            stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
            stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
            title: pod.title,
            url: pod.url,
            dailyThoughtID: '0',
            podcastDescription: 'Podcast',
            podcastID: uuid,
            podcastSize: 0,
            podcastType: 2,
            storageName: params.podcastname,
            userID: body.journalUserID,
            userName: body.journalUserName,
            execmember: body.execmember
          }
          resolve(podcastUrls[uuid] = newUrl)
        })
      })
      const addPodcastResponse = await Promise.all(addPodcast)
      body.podcasts = addPodcastResponse
    }


    // Embed images
    // embed urls to body

    if (imageUrlCnt > 0) {
      let uuid = uuidv4()
      const addImage = params.imagesObj.map((img) => {
        return new Promise((resolve, reject) => {
          let url = {
            bytes: 0,
            caption: img.title,
            dailyThoughtID: '0',
            date: body.dateRegistered,
            dateRegistered: body.dateRegistered,
            dateUploaded: body.dateRegistered,
            height: 0,
            imageSize: 0,
            stringDate: new Date(body.dateRegistered).toLocaleString(),
            stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
            stringDateUploaded: new Date(body.dateRegistered).toLocaleString(),
            thumbNailUrl: img.url,
            title: img.title,
            url: img.url,
            photoID: uuid,
            userID: body.journalUserID,
            userName: body.journalUserName,
            execmember: body.execmember,
            width: 0
          }
          resolve(imageUrls[uuid] = url)
        })
      })
      const addImageResponse = await Promise.all(addImage)
      body.photos = addImageResponse
    }

    // Embed Videos
    // attach any available video object
    // Add video url to thought

    if (videoUrlCnt > 0) {
      const addVideo = params.videosObj.map(vid => {
        return new Promise((resolve, reject) => {
          let uuid = uuidv4()
          let newUrl = {
            active: true,
            company_status: USER_LOGGED.companyID + '_true',
            caption: vid.title,
            companyID: USER_LOGGED.companyID,
            companyName: USER_LOGGED.companyName,
            date: body.dateRegistered,
            dateRegistered: body.dateRegistered,
            dateUploaded: body.dateRegistered,
            dateUpdated: 0,
            stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
            title: vid.title,
            url: vid.url,
            dailyThoughtID: '0',
            description: body.title,
            videoID: uuid,
            storageName: params.videovalue === 'vidupload' ? vid.title : '',
            userID: body.journalUserID,
            userName: body.journalUserName,
            photoURL: USER_LOGGED.photoURL || '',
            videoSize: 0,
            execmember: body.execmember,
            lengthInSeconds: 0,
          }

          resolve(videoUrls[uuid] = newUrl)
        })
      })
      const addVideoResponse = await Promise.all(addVideo)
      body.videos = addVideoResponse
    }


    // clean data before posting
    delete body.maxLengthThought
    delete body.maxLengthAuthor
    delete body.modalTitle
    delete body.postInternalGlobal
    delete body.uploadInProgress
    delete body.selectedDate
    delete body.selectedTime
    delete body.PodcastExternalRecord
    delete body.VidUploadExternal
    // delete body.articlesObj
    delete body.audiourl
    delete body.imageurl
    // delete body.podcastsObj
    delete body.podcastType
    delete body.podcasturl
    delete body.videourl
    // delete body.videosObj
    delete body.fileurl
    // delete body.filesObj

    // Editing or Creating new post?
    if (params.editing) {
      delete body.dateRegistered
      delete body.post
      body.name = 'thoughts'
      body.id = params.post.id // params.post.dailyThoughtID;

      // post new data
       console.log('thoughtsActions: ', params);

      axios
        .put(`${BASE_API_URL}/thoughts/${body.id}`, body)
        .then(res => {
          params.props.handleClose()
          dispatch({
            type: 'CREATE_THOUGHT_SUCCESS'
          })
        })
        .catch(err => {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error creating a LEAD, please confirm that all fields are filled in! 4: ' + err.message
          })
          return 'failure'
        })
    } else {
      // post new data
      console.log('[createThought submit 1: ]', params)
      if (params.groupid === '1' && params.dailyThoughtType === 4 && params.selectedGroups.length > 0) {
        console.log('createThought submit2: ', params)
        let multi_requests = []
        for (let i = 0; i < body.selectedGroups.length; i++) {
          let single_body = { ...body }
          single_body.groupid = body.selectedGroups[i].group_id
          single_body.groupname = body.selectedGroups[i].group_name

          delete single_body.selectedGroups

          const request = axios.post(`${BASE_API_URL}/thoughts`, single_body)

          multi_requests.push(request)
        }

        // console.log("multi_requests: ", multi_requests)

        axios.all(multi_requests).then(axios.spread((...responses) => {
          console.log('[finished posting: ]', responses)
          params.props.handleClose()
          dispatch({
            type: 'CREATE_THOUGHT_SUCCESS'
          })

          if (params.props.editing === false) {
            let params1 = {
              uid: params.props.userdata.uid,
              userID: params.props.userdata.userID,
              pointsCurrent: params.props.userdata.points === undefined ? 0 : params.props.userdata.points,
              pointsAdded: 10,
              ypointsCurrent: params.props.userdata.pointsy === undefined ? 0 : params.props.userdata.pointsy,
              trailID: this.makeid(10),
              companyName: params.props.userdata.companyName,
              firstName: params.props.userdata.firstName,
              lastName: params.props.userdata.lastName,
              title: 'Forum Post',
              actionType: 'Forum Post',
              actionDesc: 'Forum Post' 
            }
            params.props.addMorePoints(params1)
          } 
          // else {
          //   // INVITATION TO EXECUTIVE CLUB///////////////////////////
          //   let params2 = {
          //     type: SHOW_TOAST,
          //     open: true,
          //     variant: 'success',
          //     message: 'Executive Club members earn points for submitting material',
          //     pos: 'bottom'
          //   }
          //   params.props.showToast(params2)
          //   return
          // }
          return 'success'
        })).catch(errors => {
          // react on errors.
          // console.log("error posting: ", errors)
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error posting a LEAD!'
          })
          return 'failure'
        })
      } else {
        // console.log("single posting: ", body)
        console.log('[createThought submit 3: ]', body)
        axios
          .post(`${BASE_API_URL}/thoughts`, body)
          .then((res) => {
            params.props.handleClose()
            dispatch({
              type: 'CREATE_THOUGHT_SUCCESS'
            })

            if (params.props.editing === false) {
              let params1 = {
                uid: params.props.userdata.uid,
                userID: params.props.userdata.userID,
                pointsCurrent: params.props.userdata.points === undefined ? 0 : params.props.userdata.points,
                pointsAdded: 10,
                ypointsCurrent: params.props.userdata.pointsy === undefined ? 0 : params.props.userdata.pointsy,
                trailID: this.makeid(10),
                companyName: params.props.userdata.companyName,
                firstName: params.props.userdata.firstName,
                lastName: params.props.userdata.lastName,
                title: 'Forum Post',
                actionType: 'Forum Post',
                actionDesc: 'Forum Post'             
              }
              params.props.addMorePoints(params1)
            } 
            // else {
            //   // INVITATION TO EXECUTIVE CLUB///////////////////////////
            //   let params2 = {
            //     type: SHOW_TOAST,
            //     open: true,
            //     variant: 'success',
            //     message: 'Executive Club members earn points for submitting material',
            //     pos: 'bottom'
            //   }
            //   params.props.showToast(params2)
            //   return
            // }
            return 
          })
          .catch((e) => {
            dispatch({
              type: 'SHOW_TOAST',
              open: true,
              variant: 'error',
              message: 'Error creating a LEAD, please confirm that all fields are filled in! 5: ' + e.message
            })
            return 'failure'
          })
      }
    }
  }
}
