import React from "react";

import { connect } from "react-redux";
import {
  signInWithEmailAndPassword,
  signInWithGoogle
} from "../../store/actions/authActions";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import { Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Avatar from "@material-ui/core/Avatar";
import { grey } from "@material-ui/core/colors";
// Custom Components
import Companylogo from "../../components/layout/Companylogo";
import googleLogo from "../../assets/images/google-plus.svg";
import classNames from "classnames";
import { LIGHTBLUE, HOVERBLUE } from "../../utils/color_utils";
import { APP_TYPE } from "../../store/actions/types";
import {
  HOVERMAROON,
  GLPMAROON,
  RED,
  MAROONHOVER
} from "../../utils/color_utils";
import { app_config } from "../../constants";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  authCardHeading: {
    margin: "10px 15px 0"
  },
  authCardTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    margin: theme.spacing.unit
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(320 + theme.spacing.unit * 3 * 2)]: {
      width: 320,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 5
  },
  submitButton: {
    minWidth: "205px",
    padding: "10px 0px",
    marginTop: theme.spacing.unit,
    color: "#ffffff",
    backgroundColor: GLPMAROON,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: HOVERMAROON
    }

    //'#d51317'  -- Red
  },
  submitGoogle: {
    minWidth: "205px",
    padding: "10px 0px",
    backgroundColor: RED,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: MAROONHOVER
    },
    margin: `${theme.spacing.unit}px 0px`
  },
  button: {
    margin: `${theme.spacing.unit}px 0px`,
    color: LIGHTBLUE
  },

  smallButton: {
    margin: "10px 4px",
    backgroundColor: GLPMAROON,
    color: "#ffffff",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: HOVERMAROON
    }
  },
  avatar: {
    marginRight: 10,
    width: 30,
    height: 30
  },
  formTextControl: {
    color: grey[700],
    fontSize: "0.875rem"
  },
  version: {
    fontSize: "8px",
    color: "#C0C0C0"
  }
});

function AuthCardHeader(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.authCardHeading)}>
      <Companylogo />
      <Typography
        className={classNames(classes.authCardTitle)}
        component="h6"
        variant="body2"
        align="center"
      >
        Leadership Platform
      </Typography>
    </div>
  );
}

class SignIn extends React.Component {
  state = {
    email: "",
    password: "",
    showPassword: false,
    showToast: false
  };

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  SignInWithEmail = e => {
    e.preventDefault();
    // Only on Live server
    this.checkUserDomain(this.state.email);
    this.props.signInEmail(this.state);
  };

  signInWithGoogle = e => {
    e.preventDefault();
    this.props.signInGoogle(this.state);
  };

  checkUserDomain = usr => {
    var url = window.location.origin;

    var corporates = [
      "edcon.co.za",
      "edcon@",
      "oneconnect.co.za",
      "oneconnect@",
     // "blsa.org.za",
      //"blsa@",
     // "businessleadership.org.za",
     // "businessleadership@",
      "harmony@",
      "harmony"
    ];

    for (var x = 0; x < corporates.length; x++) {
      var y = corporates[x];
      var patt = new RegExp(y);

      if (patt.test(usr)) {
        var corporateDomains = {
          "oneconnect.co.za": "https://oneconnect.thinklead.co.za",
          "oneconnect@": "https://oneconnect.thinklead.co.za",
          "edcon.co.za": "https://edcon.thinklead.co.za",
          "edcon@": "https://edcon.thinklead.co.za",
         // "blsa.org.za": "https://blsa.thinklead.co.za",
         // "blsa@": "https://blsa.thinklead.co.za",
          //"businessleadership.org.za": "https://blsa.thinklead.co.za",
          //"businessleadership@": "https://blsa.thinklead.co.za",
          "harmony@": "https://harmony.thinklead.co.za",
          "harmony": "https://harmony.thinklead.co.za"
        };

        var companyurl = corporateDomains[y];

        if (url !== companyurl) {
          window.location.replace(companyurl);
        }
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <AuthCardHeader {...this.props} />
          <form className={classes.form} onSubmit={this.SignInWithEmail}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                value={this.state.email}
               // autoFocus={true}
                onChange={this.handleChange("email")}
              />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submitButton}
            >
              LOGIN
            </Button>
            {APP_TYPE === "PUBLIC" ? (
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.submitGoogle}
                  onClick={this.signInWithGoogle}
                >
                  <Avatar
                    alt="Google Logo"
                    src={googleLogo}
                    className={classes.avatar}
                  />
                  Sign In With Google
                </Button>
                <FormControlLabel
                  value="start"
                  classes={{ label: classes.formTextControl }}
                  control={
                    <Button
                      size="small"
                      variant="contained"
                      className={classes.smallButton}
                      component={Link}
                      to="/signup"
                    >
                      Sign up
                    </Button>
                  }
                  label="You dont have an account?"
                  labelPlacement="start"
                />
              </div>
            ) : null}

            <Button
              component={Link}
              color="primary"
              disableRipple={true}
              disableFocusRipple={true}
              to="/reset-password"
              className={classes.button}
              fullWidth
            >
              Forgot Password
            </Button>
          </form>
          <span className={classes.version}>Version {app_config.version}</span>
        </Paper>
      </main>
    );
  }
}

const SignInModule = withStyles(styles)(SignIn);

const mapDispatchToProps = dispatch => {
  return {
    signInEmail: creds => dispatch(signInWithEmailAndPassword(creds)),
    signInGoogle: () => dispatch(signInWithGoogle())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SignInModule);