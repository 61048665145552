import { SURVEY_Q_LIST, SURVEY_A_LIST } from "./types";
import firebase from "../firebase";

export const compileQuestions = params => {
  if (params.type === SURVEY_Q_LIST) {
    return (dispatch, getState) => {
      firebase.database.ref(`surveys/${params.companyID}/${params.surveyid}`).on("value", function(data) {
        if (data.val()) {
       
          dispatch({
            type: SURVEY_Q_LIST,
            survey_Q_list: data.val()
          });
    
        } else {
          return (dispatch, getState) => {
            dispatch({
              type: SURVEY_Q_LIST,
              survey_Q_list: {}
            });
          };
        }
      });
   } 
 }
};

// export const compileAnswers = params => {
//   if (params.type === SURVEY_A_LIST) {
//     console.log('inside compileQuestions !');
//     return (dispatch, getState) => {

//     console.log('sortedData in surveyQAction: ', params.sortedData1[0] );
//     var importString1 = params.sortedData1[0]+", "+params.sortedData1[1]+", "+params.sortedData1[2]+", "+params.sortedData1[3]+", "+params.sortedData1[4]
//     var importString2 = params.sortedData2[0]+", "+params.sortedData2[1]+", "+params.sortedData2[2]+", "+params.sortedData2[3]+", "+params.sortedData2[4]
//     var importString3 = params.sortedData3[0]+", "+params.sortedData3[1]+", "+params.sortedData3[2]+", "+params.sortedData3[3]+", "+params.sortedData3[4]
//     var importString4 = params.sortedData4[0]+", "+params.sortedData4[1]+", "+params.sortedData4[2]+", "+params.sortedData4[3]+", "+params.sortedData4[4]
//     var importString5 = params.sortedData5[0]+", "+params.sortedData5[1]+", "+params.sortedData5[2]+", "+params.sortedData5[3]+", "+params.sortedData5[4]

//     if (params.sortedData1!=='undefined' && params.sortedData1!==undefined) {
//     dispatch({
//         type: SURVEY_A_LIST,
//         survey_A_list1: importString1, // 'bull' //[params.q1, params.q2, params.q3, params.q4, params.q5]
//         survey_A_list2: importString2,
//         survey_A_list3: importString3,
//         survey_A_list4: importString4,
//         survey_A_list5: importString5
//       });
//       }
//       else { 
//         dispatch({
//             type: SURVEY_A_LIST,
//             survey_A_list1: 'Nothing', // 'bull' //[params.q1, params.q2, params.q3, params.q4, params.q5]
//             survey_A_list2: 'Nothing',
//             survey_A_list3: 'Nothing',
//             survey_A_list4: 'Nothing',
//             survey_A_list5: 'Nothing'
//             });
//       }
//     }; 
//   } else {
//     return (dispatch, getState) => {
//       dispatch({
//         type: "NO_ERROR",
//         survey_A_list1: 'Nothing', // 'bull' //[params.q1, params.q2, params.q3, params.q4, params.q5]
//         survey_A_list2: 'Nothing',
//         survey_A_list3: 'Nothing',
//         survey_A_list4: 'Nothing',
//         survey_A_list5: 'Nothing'
//       });
//     };
//   }
// };

