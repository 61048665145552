import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Paper,
	TextField,
	InputBase,
	Fab,
	Typography,
	Grid,
	InputAdornment,
	MenuItem,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import gql from 'graphql-tag';
import { graphql, ApolloProvider } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import VideoSearch from './VideoSearch';
import ThoughtSearch from './ThoughtSearch';
import PodcastSearch from './PodcastSearch';
import ArticleSearch from './ArticleSearch';
import Results from './Results';

import { QUERY_LIST_USERS, QUERY_LIST_VIDEOS, QUERY_SEARCH_USERS, QUERY_SEARCH_VIDEOS } from './queries';

const styles = (theme) => ({
	paper: {
		padding: '0 20px 0 0',
		margin: '20px 10px'
	},
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#ffffff',
		width: '100%'
	},
	results: {
		display: 'block',
		margin: 40,
		width: '100%'
	},
	input: {
		marginLeft: 8,
		flex: 1,
		borderBottom: '2px solid #000000',
		padding: '5px 0'
	},
	textField: {
		flexBasis: 200
	},
	margin: {
		margin: theme.spacing.unit
	},
	searchInput: {
		padding: 20
	},
	searchText: {
		//color: '#ffffff !important',
		fontSize: 20
	},
	fab: {
		margin: theme.spacing.unit,
		'&:hover': {
			backgroundColor: 'rgb(255, 255, 255, .8)'
		}
	},
	container: {
		padding: 10,
		borderBottom: '1px solid #ddd'
	},
	title: {
		fontSize: 18
	},
	description: {
		fontSize: 15,
		color: 'rgba(0, 0, 0, .5)'
	},
	img: {
		margin: 'auto',
		display: 'block',
		maxWidth: '100%',
		maxHeight: '100%'
	}
});

const ranges = [
	{
		value: 'thoughts',
		label: 'Messages'
	},
	{
		value: 'articles',
		label: 'Articles'
	},
	{
		value: 'videos',
		label: 'Videos'
	},
	{
		value: 'podcasts',
		label: 'Podcasts'
	}
];
class Search extends Component {
	state = {
		searchQuery: '',
		searchCategory: 'thoughts'
	};

	onChange = (e) => {
		const value = e.target.value;
		this.handleFilter(value);
	};
	handleFilter = debounce((val) => {
		this.props.onSearch(val);
	}, 250);
	handleResults = (resultType, results) => {
		const { classes } = this.props;
		return <Results type={resultType} items={results[resultType]} {...this.props} />;
	};
	handleChange = (prop) => (event) => {
		this.setState({ [prop]: event.target.value });
	};
	render() {
		const { classes } = this.props;
		const { getListUsers, getListVideos } = this.props;
		const { searchCategory } = this.state;

		return getListUsers || getListVideos ? (
			<Grid container spacing={0}>
				<Grid item xs={12} md={2}>
					<Paper className={classes.paper}>
						<TextField
							select
							label={<Typography className={classNames(classes.searchText)}> Search for </Typography>}
							fullWidth
							className={classNames(classes.margin, classes.textField)}
							classes={{
								root: classes.textField
							}}
							value={this.state.searchCategory}
							onChange={this.handleChange('searchCategory')}
							placeholder="Select"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<ListIcon />
									</InputAdornment>
								)
							}}
						>
							{ranges.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Paper>
				</Grid>
				<Grid item xs={12} md={10}>
					{searchCategory.includes('videos') && (
						<VideoSearch render={(listVideos) => this.handleResults('videos', listVideos)} />
					)}
					{searchCategory.includes('thoughts') && (
						<ThoughtSearch render={(listThoughts) => this.handleResults('thoughts', listThoughts)} />
					)}
					{searchCategory.includes('articles') && (
						<ArticleSearch render={(listArticles) => this.handleResults('articles', listArticles)} />
					)}
					{searchCategory.includes('podcasts') && (
						<PodcastSearch render={(listPodcasts) => this.handleResults('podcasts', listPodcasts)} />
					)}
				</Grid>
				<Grid item xs={12} />
			</Grid>
		) : null;
	}
}

const SearchStyled = withStyles(styles)(Search);

/**
 * 
 * <div class={classes.results}>
							<Typography variant="h5">Search Results ({listUsers.users.length}) </Typography>
							{!!loading && <p>Searching ...</p>}
							{!loading && !listUsers.users.length && <p>Sorry, no results.</p>}
							<List>
								{!loading &&
									listUsers.users.map((user, index) => (
										<ListItem alignItems="flex-start" divider={true} button>
											<ListItemAvatar>
												<Grid item md={2} xs={3}>
													<img
														className={classes.img}
														src="http://www.codecovers.eu/assets/img/modules/materialadmin/img11.jpg?1422538629"
													/>
												</Grid>
											</ListItemAvatar>
											<ListItemText
												primary="Post Title"
												secondary={
													<React.Fragment>
														<Typography gutterBottom component="h2" color="textPrimary">
															Subtitle | Date
														</Typography>
														<Typography component="subtitle2" color="textPrimary">
															Author/Journal User
														</Typography>
														{" — I'll be in your neighborhood doing"}
													</React.Fragment>
												}
											/>
										</ListItem>
									))}
							</List>
						</div>
 */

export default compose(
	graphql(
		QUERY_LIST_VIDEOS,
		{ name: 'getListVideos' },
		{
			options: (data) => ({
				fetchPolicy: 'cache-and-network'
			}),
			props: (props) => ({
				onSearch: (searchQuery) => {
					searchQuery.toLowerCase();
					return props.getListVideos.fetchMore({
						query: searchQuery === '' ? QUERY_LIST_VIDEOS : QUERY_SEARCH_VIDEOS,
						variables: {
							searchQuery
						},
						updateQuery: (previousResult, { fetchMoreResult }) => ({
							...previousResult,
							listVideos: {
								...previousResult.listVideos,
								videos: fetchMoreResult.listVideos.videos
							}
						})
					});
				},
				listVideos: props.getListVideos
			})
		}
	),
	graphql(
		QUERY_LIST_USERS,
		{ name: 'getListUsers' },
		{
			options: (data) => ({
				fetchPolicy: 'cache-and-network'
			}),
			props: (props) => ({
				onSearch: (searchQuery) => {
					searchQuery.toLowerCase();
					return props.getListUsers.fetchMore({
						query: searchQuery === '' ? QUERY_LIST_USERS : QUERY_SEARCH_USERS,
						variables: {
							searchQuery
						},
						updateQuery: (previousResult, { fetchMoreResult }) => ({
							...previousResult,
							listUsers: {
								...previousResult.listUsers,
								users: fetchMoreResult.listUsers.users
							}
						})
					});
				},
				listUsers: props.getListUsers
			})
		}
	)
)(SearchStyled);
