import { cGET_O, cGET_O_S, cGET_SLAVE_OPINION, cGET_OPINIONID } from '../actions/types';

const initState = {
  Copinion: {},
  Cfeedback: {},
  Copinionid: '',
  Csopinion: {},
};

const opinionCReducer = (state = initState , action) => {
  switch(action.type){
    case cGET_O:
      return{ 
        ...state,
        type: "cGET_O",
        Copinion: action.opinion
      }
    case cGET_O_S:
      console.log('green putOpinions reducer: ', action.Csopinion)
      return{ 
        ...state,
        type: "cGET_O_S",
        Csopinion: action.sopinion
      }
    case cGET_SLAVE_OPINION:
      console.log('green opinionReducer : ', action.Cslaveopinion)
      return{ 
        ...state,
        type: "cGET_SLAVE_OPINION",
        Cfeedback: action.slaveopinion 
      }
    case cGET_OPINIONID:
      return{ 
        ...state,
        type: "cGET_OPINIONID",
        Copinionid: action.opinion
      }
    default:
      return state
  }
  
}

export default opinionCReducer