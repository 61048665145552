import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ThoughtsCard from "../Cards/ThoughtsCard";
import RemoveAttachmentDialog from "../Dialogs/RemoveAttachmentDialog";
import AddToPLDPDialog from "../Dialogs/AddToPLDPDialog";
import Progressbar from "../ProgressBar";
import { HIDE_COMMENTS_BUTTON } from "../../store/actions/types";
import MediaPlayer from "../Dialogs/MediaPlayer";

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class ThoughtsView extends React.Component {
  state = {
    openmediaplayer: false,
    mediatype: "podcast",
    media: null,
    openAddPLDP: false,
    attachmentType: "",
    thoughtToAdd: {}
  };

  OpenMediaPlayer = (media, mediatype) => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media,
      mediatype: mediatype
    });
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    });
  };

  handleOpenAddToPLDPDialog = thought => {
    this.setState(prevState => ({
      openAddPLDP: true,
      thoughtToAdd: thought
    }));
  };

  handleAddToPLDP = () => { };
  handleCloseDialog = () => {
    this.setState(prevState => ({
      openAddPLDP: false,
      thoughtToAdd: {}
    }));
  };
  handleRemoveAttachement = (thought, attachmentType, attachmentID) => { };

  render() {
    const { openmediaplayer, media, mediatype } = this.state;
    const { classes, thoughts, match, followingData } = this.props;

    return (
      <React.Fragment>
        {/* {Object.keys(thoughts).length > 0 ? null : (
          <Progressbar className={classes.progress} color="secondary" />
        )} */}

        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}
        <div className={classes.cardstyles}>
          {thoughts &&
            Object.keys(thoughts).map(id => {
              return (
                <ThoughtsCard
                  onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
                  ref={"" + thoughts[id].dailyThoughtID}
                  onCommentsClick={this.handleCommentsClick}
                  thought={thoughts[id]}
                  key={id}
                  PlayMedia={this.OpenMediaPlayer}
                  onRemoveAttachement={this.handleRemoveAttachement}
                  {...this.props}
                  following={
                    followingData
                      ? followingData[thoughts[id].journalUserID]
                        ? true
                        : false
                      : false
                  }
                  hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                  openFileViewer={this.props.openFileViewer}
                />
              );
            })}

          <AddToPLDPDialog
            open={this.state.openAddPLDP}
            onClickProceed={this.handleAddToPLDP}
            onCloseDialog={this.handleCloseDialog}
          />
          <RemoveAttachmentDialog />
        </div>
      </React.Fragment>
    );
  }
}

ThoughtsView.propTypes = {
  classes: PropTypes.object.isRequired,
  thoughts: PropTypes.array
};

var ThoughtsViewStyled = withStyles(styles)(ThoughtsView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(ThoughtsViewStyled);
