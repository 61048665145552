import React, { Component } from "react";
import PropTypes from "prop-types";
import PodcastsView from "../components/Views/PodcastsView";
import { connect } from "react-redux";
import {
  fetchPodcasts,
  fetchExternalPodcasts
} from "../store/actions/podcastsActions";
import { fetchUserData } from "../store/actions/userActions";
import {
  INTERNAL_PODCASTS,
  EXTERNAL_PODCASTS,
  SET_SEEN_NOTIFICATIONS
} from "../store/actions/types";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Podcasts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioGroupPlacement: { marginTop: "0px" },
      podcastsFilter: "All",
      height: window.innerHeight,
      minData: 6
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  handleScroll() {
    const windowHeight =
      "innerHeight" in window ?
      window.innerHeight :
      document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 6
      });

      this.fetchData();
    }
  }

  handleResize = () => {
    console.log('handleResize')
    if (window.innerWidth <= 602) {
      this.setState({
        radioGroupPlacement: { marginTop: '130px' }
      }, function() {
        this.radioGroup()
      })
    } else {
      this.setState({
        radioGroupPlacement: { marginTop: '0px' }
      }, function () {
        this.radioGroup()
      })
    }
  }

  componentDidMount() {
    this.fetchData();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
    this.fetchData();
  };

  handlePodcastFilterChange = (event) => {
    this.setState({
      podcastsFilter: event.target.value
    }, function() {
      return this.fetchData()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.podcastsFilter !== prevState.podcastsFilter) {
     // console.log('test')
    }
  }

  fetchData = async function() {
    new Promise((resolve, reject) => {
      if (this.props.userdata && this.props.userdata.companyID !== '-Lu4BlhZ-tsDSnd-fnFd') {
        this.props.fetchExternalPodcasts({
          type: EXTERNAL_PODCASTS,
          companyID: this.props.userdata.companyID,
          orderChild: "dailyThoughtType_status",
          equalTo: "2_approved",
          minData: this.state.minData,
          resolve: resolve,
          reject: reject
        });
      } else {
        resolve({
          type: EXTERNAL_PODCASTS,
          externalpodcasts: {}
        })
      }
      })
      .then((resolve, reject) => {
        new Promise((resolve, reject) => {
          this.props.fetchPodcasts({
            type: INTERNAL_PODCASTS,
            companyID: this.props.userdata.companyID,
            orderChild: "companyID_status",
            equalTo: this.props.userdata.companyID + "_approved",
            minData: this.state.minData,
            resolve: resolve,
            reject: reject
          });
        });
      })
      .then(() => {
        this.props.fetchUserData({
          type: SET_SEEN_NOTIFICATIONS,
          userID: this.props.userdata.userID,
          path: this.props.match.path,
          unseenotifications: this.props.unseenotifications.unseenotifications
        });
      });
  };

  radioGroup = () => {
    // console.log('create radio group', this.state.radioGroupPlacement)

    if (this.props.userdata.companyID === '-Lu4BlhZ-tsDSnd-fnFd') {
      return (<div></div>)
    }

    return (<RadioGroup aria-label="position" name="position" value={this.state.podcastsFilter} onChange={this.handlePodcastFilterChange} row style={this.state.radioGroupPlacement}>
      <FormControlLabel
        value="All"
        control={<Radio color="primary" />}
        label="All"
        labelPlacement="end"
      />
      <FormControlLabel
        value="Global"
        control={<Radio color="primary" />}
        label="Global"
        labelPlacement="end"
      />
      <FormControlLabel
        value="Internal"
        control={<Radio color="primary" />}
        label="Internal"
        labelPlacement="end"
      />
    </RadioGroup>)
  }

  render() {
    const { podcasts, userdata } = this.props;
    return (
      <div>
        {userdata && podcasts ? (
          <React.Fragment>
            {/* {this.radioGroup()} */}

            <PodcastsView
              podcastsFilter={this.state.podcastsFilter}
              podcasts={podcasts}
              currentUser={userdata}
              {...this.props}
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

Podcasts.propTypes = {
  fetchPodcasts: PropTypes.func.isRequired,
  fetchExternalPodcasts: PropTypes.func.isRequired,
  podcasts: PropTypes.object
};

const mapStateToProps = state => {
  return {
    podcasts: {
      ...state.podcasts.externalpodcasts,
      ...state.podcasts.podcasts
    },
    userdata: state.userDialog.userDetails[0],
    unseenotifications: state.userdata.unseenotifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPodcasts: params => dispatch(fetchPodcasts(params)),
    fetchExternalPodcasts: params => dispatch(fetchExternalPodcasts(params)),
    fetchUserData: params => dispatch(fetchUserData(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Podcasts);