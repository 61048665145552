import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader, Button, Checkbox, ListItemIcon
} from '@material-ui/core'
import { showToast } from '../../store/actions/toastAction'
import { LIGHTGREY, GREEN, RED } from "../../utils/color_utils";

const styles = {
  root: {
    width: "100%",
    backgroundColor: "#fff",
  },
}

class SurveysList extends Component {
  constructor (props) {
    super(props)
  }

  state = {
    checked: [],
  };

  componentDidMount() {
  }


  handleToggle = (value) => () => {
    this.props.previewSurvey(value)
  };

  render() {
    const { classes, company_surveys, user_groups } = this.props;

    return (
      <div>
        <List subheader={<ListSubheader style={{ backgroundColor: `${LIGHTGREY}`, fontWeight: "bold", fontSize: "16px", lineHeight: "28px", color: "#000", height: '40px', display: 'flex', alignItems: 'center' }}>Open Surveys</ListSubheader>} dense className={classes.root}>
          {company_surveys && Object.keys(company_surveys).map(id => {
            const labelId = `${company_surveys[id].survey_id}`;
            const groupId = `${company_surveys[id].group_id}`;
            const labelName = `${company_surveys[id].survey_title}`;

            if (groupId in user_groups) {
              return (
                <div>
                  <ListItem key={labelId} role={undefined} dense button onClick={this.handleToggle(labelId)}>
                    <ListItemText id={labelId} primary={`${labelName}`} />
                  </ListItem>
                </div>
              );
            }

          })}
        </List>
      </div>
    )
  }
}


SurveysList.propTypes = {
  classes: PropTypes.object,
  userdata: PropTypes.any
}

const SurveysListStyled = withStyles(styles)(SurveysList)

const mapStateToProps = state => {
  return {
    assigned_study_materials: state.users.assigned_study_materials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveysListStyled)