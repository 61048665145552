import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emphasize, withStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  Paper,
  Grid,
  Typography, Breadcrumbs, Chip, Tooltip, Button,
  Link as FileLink, ExpansionPanelActions, Modal,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import MUIDataTable from 'mui-datatables'
import UploadFiles from '../../components/UploadFiles'
import DescriptionIcon from '@material-ui/icons/Description'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/Done';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import indigo from '@material-ui/core/colors/indigo'
import { updateOrCreateProgrammes, addSurvey, newSurveyEmail } from "../../store/actions/companyAdminActions";
import { SUPPORTED_FILE_TYPES, SHOW_TOAST } from "../../store/actions/types";
import { showToast } from '../../store/actions/toastAction'
import { DATE_FORMAT } from "../../utils/date_format_utils";
import Groupselect from "./groupselect";
import { GREEN, RED } from "../../utils/color_utils";
import { RadioGroup, Radio } from 'react-radio-group'

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 40 + rand()
const left = 50 + rand()

const columns = ['Survey Title', 'Groups']

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    boxShadow: 'none'
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  userTypeButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${indigo[500]}`,
    border: '1px solid',
  },
  archiveButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${RED}`,
    border: '1px solid',
  },
  activateButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${GREEN}`,
    border: '1px solid',
  },
  icon: {
    fontSize: '20px'
  }
})


let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    multiple,
    filterby,
    onSelectedFile,
    archiveSelectedProgramme,
    editSelectedSurvey,
    notifyParticipants,
    previewSurvey,
  } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      elevation={0}
    >
      <div className={classes.title} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {numSelected === 1 ? (
              <React.Fragment>
                {filterby === 'active' ? (
                  <React.Fragment>
                    <Button
                      variant='outline'
                      aria-label='Preview Survey'
                      onClick={previewSurvey}
                      className={classes.activateButton}
                      size='small'
                    >
                      <VisibilityIcon className={classes.icon} />
                      Preview
                    </Button>
                    <Button
                      variant='outline'
                      aria-label='Edit survey'
                      onClick={editSelectedSurvey}
                      className={classes.userTypeButton}
                      size='small'
                    >
                      <EditIcon className={classes.icon} />
                      Edit
                    </Button>
                    <Button
                      variant='outline'
                      aria-label='Notify Participants'
                      onClick={notifyParticipants}
                      className={classes.userTypeButton}
                      size='small'
                    >
                      <EmailIcon className={classes.icon} />
                      Notify Participants
                    </Button>
                    <Button
                      variant='outline'
                      aria-label='Archive'
                      onClick={archiveSelectedProgramme}
                      className={classes.archiveButton}
                      size='small'
                    >
                      <PlaylistAddCheckIcon className={classes.icon} />
                      Deactivate
                    </Button>
                  </React.Fragment>
                ) : <React.Fragment>
                    <Button
                      variant='outline'
                      aria-label='Archive'
                      onClick={onSelectedFile}
                      className={classes.activateButton}
                      size='small'
                    >
                      <PlaylistAddCheckIcon className={classes.icon} />
                      Activate
                    </Button>
                  </React.Fragment>}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)


const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none',
    },
  },
}))(Chip);

const styles = theme => ({
  root: {
    marginTop: 0,
    overflowX: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '130px',
      padding: '0 10px'
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  pageHeader: {
    marginTop: 0,
    padding: theme.spacing(1)
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
})

class SurveyConfigurations extends Component {
  state = {
    filterby: 'active',
    dataListClone: [],
    surveysList: [],
    openConfirm: false,
    open: false,
    open_survey: false,
    programme: {},
    companyID: '',
    survey_id: '',
    survey_title: '',
    survey_link: '',
    assigned_group_ids: [],
    assigned_group_names: '',
    dateRegistered: '',
    groupid: "",
    groupname: "",
    multiplegroups: false,
    selectedgroups: [],
  };

  options = {
    filterType: 'radio',
    selectableRows: 'single',
    customToolbarSelect: e => {
      return (
        <EnhancedTableToolbar
          numSelected={e.data.length}
          multiple={e.data.length > 0}
          options={{
            elevation: 0
          }}
          filterby={this.state.filterby}
          onSelectedFile={() =>
            this.handleSelectedProgramme()
          }
          archiveSelectedProgramme={() =>
            this.handleArchiveSelectedProgramme()
          }
          editSelectedSurvey={() =>
            this.handleEditSelectedSurvey()
          }
          notifyParticipants={() =>
            this.handleNotifyParticipants()
          }
          previewSurvey={() =>
            this.handlePreviewSurvey()
          }
        />
      )
    },
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {

      // let data =
      //   users &&
      //   Object.keys(users).map((v, i) => {
      //     return { data: users[v], uid: users[v].id }
      //   })

      if (currentRowsSelected[0] !== undefined) {
        window.sf = this.state.dataListClone[currentRowsSelected[0].dataIndex];
      }
    }
  }

  handleEditSelectedSurvey = () => {
    let survey = window.sf;

    this.setState({
      open: true,
      survey_id: survey[4],
      survey_title: survey[0],
      survey_link: survey[3],
      assigned_group_ids: survey[2],
      assigned_group_names: survey[1],
      dateRegistered: survey[6],
      groupid: "",
      groupname: "",
      multiplegroups: false,
      filterby: survey[7],
      selectedgroups: survey[8],
    })
  };

  handleNotifyParticipants = () => {
    let survey = window.sf;

    this.props.newSurveyEmail({
      ...this.state,
      survey_id: survey[4],
      survey_title: survey[0],
      survey_link: survey[3],
      assigned_group_ids: survey[2],
      assigned_group_names: survey[1],
      dateRegistered: survey[6],
      selectedgroups: survey[8],
      companyID: this.props.userdata.companyID,
      companyName: this.props.userdata.companyName,
      company_id: this.props.userdata.companyID,
    });

  };

  handlePreviewSurvey = () => {
    let survey = window.sf;

    this.setState({
      open_survey: true,
      survey_id: survey[4],
      survey_title: survey[0],
      survey_link: survey[3],
      assigned_group_ids: survey[2],
      assigned_group_names: survey[1],
      dateRegistered: survey[6],
      groupid: "",
      groupname: "",
      multiplegroups: false,
      filterby: survey[7],
      selectedgroups: survey[8],
    })
  };

  handleSelectedProgramme = () => {
    let survey = window.sf;

    this.props.addSurvey({
      ...this.state,
      survey_id: survey[4],
      survey_title: survey[0],
      survey_link: survey[3],
      assigned_group_ids: survey[2],
      assigned_group_names: survey[1],
      dateRegistered: survey[6],
      selectedgroups: survey[8],
      filterby: this.state.filterby === 'active' ? 'archived' : 'active',
      companyID: this.props.userdata.companyID
    });
  };

  handleArchiveSelectedProgramme = () => {
    this.setState({
      openConfirm: true
    })
  };

  confirmArchiveProgramme = () => {
    let survey = window.sf;

    this.props.addSurvey({
      ...this.state,
      survey_id: survey[4],
      survey_title: survey[0],
      survey_link: survey[3],
      assigned_group_ids: survey[2],
      assigned_group_names: survey[1],
      dateRegistered: survey[6],
      selectedgroups: survey[8],
      filterby: this.state.filterby === 'active' ? 'archived' : 'active',
      companyID: this.props.userdata.companyID
    });

    this.handleCloseConfirm()
  };

  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false
    })
  };

  handleClose = () => {
    this.setState({
      open: false,
      open_survey: false,
      programme: {},
      survey_id: '',
      survey_title: '',
      survey_link: '',
      assigned_group_ids: [],
      assigned_group_names: '',
      dateRegistered: '',
      groupid: "",
      groupname: "",
      multiplegroups: false,
      selectedgroups: [],
    })
  };

  handleSaveSurvey = () => {
    if (this.state.survey_title === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter Survey Title ..."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.survey_link === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please paste Survey Link ..."
      };

      this.props.showToast(params);
      return;
    }


    this.props.addSurvey({ ...this.state, companyID: this.props.userdata.companyID });

    this.handleClose();
  };

  componentDidMount() {

  }

  transformTableData = () => {
    let { company_surveys } = this.props
    let surveys = [];
    let dataListClone = [];
    let filtered_surveys = [];

    Object.keys(company_surveys).map((id, index) => {
      surveys.push(company_surveys[id])
    })

    filtered_surveys = surveys.filter(survey => survey.status.toLowerCase().includes(this.state.filterby.toLowerCase()));

    dataListClone = filtered_surveys.map((id, index) => {
      return [
        filtered_surveys[index].survey_title,
        filtered_surveys[index].selectedgroups_names,
        filtered_surveys[index].selectedgroups_ids,
        filtered_surveys[index].survey_link,
        filtered_surveys[index].survey_id,
        filtered_surveys[index].companyID,
        filtered_surveys[index].dateRegistered,
        filtered_surveys[index].status,
        filtered_surveys[index].selectedgroups,
        filtered_surveys[index].companyID + "_" + filtered_surveys[index].status,
      ]
    })

    this.state = { ...this.state, dataListClone: dataListClone, surveysList: surveys };

    return dataListClone
  };

  handleFilteredFiles = (val) => {
    this.setState({
      filterby: val
    })
  }

  openSurvey = () => {
    this.setState({
      open: true,
      survey_id: '',
      survey_title: '',
      survey_link: '',
      assigned_group_ids: [],
      assigned_group_names: '',
      groupid: "",
      groupname: "",
      multiplegroups: false,
      selectedgroups: [],
    })
  };

  handleGroupsChange(value, name, selectedgroups, multiplegroups) {
    this.setState({ groupid: value, groupname: name, selectedgroups, multiplegroups });
  }

  render() {
    const { programme } = this.state
    const { classes } = this.props

    return (
      <div>
        <Grid container spacing={2} gutterBottom style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={12}>
            <Paper style={{ maxWidth: '100vw' }} className={classes.pageHeader}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant='h5'>Survey Configuration</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                      component={Link}
                      to={`/companyadmin`}
                      label="Company Admin"
                    />
                    <StyledBreadcrumb
                      component={Link}
                      to={`/groups`}
                      label="Manage Groups"
                    />
                    <StyledBreadcrumb
                      label="Survey Configuration"
                    />
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        size="small"
                        className={classes.userTypeButton}
                        onClick={this.openSurvey}
                      >
                        New Survey Config
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ textAlign: 'center' }}>
                    <RadioGroup name="filteredtasks" selectedValue={this.state.filterby} onChange={this.handleFilteredFiles}>
                      <Radio value="active" /> Active
                      <Radio value="archived" style={{ marginLeft: '8px' }} /> Archived
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <MUIDataTable
                    title={''}
                    data={this.transformTableData()}
                    columns={columns}
                    options={this.options}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Modal
          aria-labelledby='confirmation-modal'
          aria-describedby='confirmation-modal-description'
          open={this.state.openConfirm}
          className={classes.modal}
        >
          <div style={modalStyle2} className={classes.paper}>
            <Typography variant='h6' gutterBottom>Please Confirm</Typography>
            <Typography variant='body2' gutterBottom>Do you want to deactivate the selected survey?</Typography>
            <div>
              <ExpansionPanelActions>
                <Button onClick={this.handleCloseConfirm} size='small'>Cancel</Button>
                <Button onClick={this.confirmArchiveProgramme} size='small' color='secondary'>Deactivate</Button>
              </ExpansionPanelActions>
            </div>
          </div>
        </Modal>

        <Dialog
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.open_survey}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Survey Preview</DialogTitle>
          <DialogContent style={{ margin: '0px 10px 0px 10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }} dangerouslySetInnerHTML={{ __html: this.state.survey_link }} />
          </DialogContent>
          <DialogActions>
            <div style={{ justifyContent: 'flex-start' }}>
              <Button onClick={this.handleClose} size='small' color='secondary' variant='outlined' style={{ marginRight: 10 }}>Close</Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.open}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Survey Configurations</DialogTitle>
          <DialogContent style={{ margin: '0px 10px 0px 10px' }}>
            <TextField
             // autoFocus={true}
              required
              margin="dense"
              id="name"
              label="Survey Title"
              type="text"
              fullWidth
              value={this.state.survey_title}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  survey_title: value,
                });
              }}
            />
            <TextField
              required
              margin="dense"
              id="name"
              label="Paste Survey Link"
              type="text"
              multiline
              fullWidth
              value={this.state.survey_link}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  survey_link: value,
                });
              }}
            />
            <Groupselect
              handleGroups={this.handleGroupsChange.bind(this)}
              userdata={this.props.userdata}
              editing={this.props.editing}
              groupid={this.state.groupid}
              groupname={this.state.groupname}
            />
          </DialogContent>
          <DialogActions>
            <div style={{ justifyContent: 'flex-start' }}>
              <Button onClick={this.handleClose} size='small' color='secondary' variant='outlined' style={{ marginRight: 10 }}>Close</Button>
              <Button onClick={this.handleSaveSurvey} size='small' color='primary' variant='outlined'>Submit</Button>
            </div>
          </DialogActions>
        </Dialog>

        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={false}
          onClose={this.handleClose}
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
          </div>
        </Modal>
      </div>
    )
  }
}

SurveyConfigurations.propTypes = {
  classes: PropTypes.object,
}

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
}
const modalStyle2 = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}

const SurveyStyled = withStyles(styles)(SurveyConfigurations)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    company_programmes: state.users.company_programmes,
    company_surveys: state.users.company_surveys,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    updateOrCreateProgrammes: params => dispatch(updateOrCreateProgrammes(params)),
    addSurvey: params => dispatch(addSurvey(params)),
    newSurveyEmail: params => dispatch(newSurveyEmail(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyStyled)

