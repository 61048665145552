// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/database";
// import "firebase/storage";
// import { app_config } from "../../src/constants";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage'

import { app_config } from '../../src/constants'
//fix: remove cached live server url if still persists
// localStorage.removeItem('firebase:host:leadershipplatform-158316.firebaseio.com');

// Initialize Firebase
// Test server configuration
const config = {
  test: {
    apiKey: "AIzaSyDR_1stg_cHHG6Ikg6eful1gVkP3Ay2OYg",
    authDomain: "glp-test-f6590.firebaseapp.com",
    databaseURL: "https://glp-test-f6590.firebaseio.com",
    projectId: "glp-test-f6590",
    storageBucket: "glp-test-f6590.appspot.com",
    messagingSenderId: "809505702015",
    appId: "1:809505702015:web:838b987f342ee57bf27f4a"
  },
  live: {
    apiKey: "AIzaSyBsGWsAIGi7rofC9aQhjLosAlhjPjGZGZc",
    authDomain: "harmony-thinklead.firebaseapp.com",
    databaseURL: "https://harmony-thinklead-default-rtdb.firebaseio.com",
    projectId: "harmony-thinklead",
    storageBucket: "harmony-thinklead.appspot.com",
    messagingSenderId: "904304223204",
    appId: "1:904304223204:web:5b27631f52e215db009df1",
    measurementId: "G-04S8XZTLM6"
    // apiKey: "AIzaSyBBJmTKkT5iNu9BAcxc69OIYlbZREx1CwU",
    // authDomain: "leadershipplatform-158316.firebaseapp.com",
    // databaseURL: "https://leadershipplatform-158316.firebaseio.com",
    // projectId: "leadershipplatform-158316",
    // storageBucket: "leadershipplatform-158316.appspot.com",
    // messagingSenderId: "438289288307"
  }
};

firebase.initializeApp(config[app_config.DB_CONFIG]);

export default firebase;
