/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ThoughtsCard from '../Cards/ThoughtsCard'
import RemoveAttachmentDialog from '../Dialogs/RemoveAttachmentDialog'
import AddToPLDPDialog from '../Dialogs/AddToPLDPDialog'
import Progressbar from '../ProgressBar'
import { HIDE_COMMENTS_BUTTON } from '../../store/actions/types'
import MediaPlayer from '../Dialogs/MediaPlayer'
import FilterListIcon from '@material-ui/icons/FilterList'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'

const styles = theme => ({
  cardstyles: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    position: 'relative'
  },
  filter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginRight: theme.spacing(2)
  }
})

class ThoughtsView extends React.Component {
  state = {
    openmediaplayer: false,
    mediatype: 'podcast',
    media: null,
    openAddPLDP: false,
    attachmentType: '',
    thoughtToAdd: {},
    filter: 'none'
  };

  OpenMediaPlayer = (media, mediatype) => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media,
      mediatype: mediatype
    })
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    })
  };

  handleOpenAddToPLDPDialog = thought => {
    this.setState(prevState => ({
      openAddPLDP: true,
      thoughtToAdd: thought
    }))
  };

  handleAddToPLDP = () => { };
  handleCloseDialog = () => {
    this.setState(prevState => ({
      openAddPLDP: false,
      thoughtToAdd: {}
    }))
  };
  handleRemoveAttachement = (thought, attachmentType, attachmentID) => { };

  handleFilterBy = (e) => {
	  this.setState({ filter: e.target.value })
	  }

  // componentDidMount() {
  //   const { thoughts } = this.props
  //    this.setState({fresh_thought: thoughts})
  // }

  render() {
    const { openmediaplayer, media, mediatype } = this.state
    const { classes, thoughts, match, followingData } = this.props

    return (
      <React.Fragment>
        {/* {Object.keys(thoughts).length > 0 ? null : (
          <Progressbar className={classes.progress} color="secondary" />
        )} */}

        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}
        <div className={classes.filter}>
          <FilterListIcon style={{ marginRight: '10px' }}/>
          <Typography variant='subtitle1'>Filter By</Typography>
          <FormControl className={classes.formControl}>
            <Select
              value={this.state.filter}
              onChange={this.handleFilterBy}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={'none'}>
            None
              </MenuItem>
              <MenuItem value={'videos'}>Videos</MenuItem>
              <MenuItem value={'podcasts'}>Podcasts</MenuItem>
              <MenuItem value={'files'}>Files</MenuItem>
              <MenuItem value={'images'}>Images</MenuItem>
              <MenuItem value={'articles'}>Articles</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.cardstyles}>
          {/* {thoughts &&
            Object.keys(thoughts).map(id => {
              return (
                <ThoughtsCard
                  onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
                  ref={'' + thoughts[id].dailyThoughtID}
                  onCommentsClick={this.handleCommentsClick}
                  thought={thoughts[id]}
                  key={id}
                  PlayMedia={this.OpenMediaPlayer}
                  onRemoveAttachement={this.handleRemoveAttachement}
                  {...this.props}
                  following={
                    followingData
                      ? followingData[thoughts[id].journalUserID]
                        ? true
                        : false
                      : false
                  }
                  hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                  openFileViewer={this.props.openFileViewer}
                />
              )
            })}

         
          <RemoveAttachmentDialog />
        </div> 
        <div className={classes.cardstyles}>
        */}
          {thoughts &&
            Object.keys(thoughts).map((id) => {

              const currentThought = thoughts[id]

              console.log('[Thought payload]', currentThought)
              if (this.state.filter === 'none') {
                return (
                  <ThoughtsCard
                    onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
                    ref={'' + thoughts[id].dailyThoughtID}
                    onCommentsClick={this.handleCommentsClick}
                    thought={currentThought}
                    key={id}
                    PlayMedia={this.OpenMediaPlayer}
                    onRemoveAttachement={this.handleRemoveAttachement}
                    {...this.props}
                    following={
                      followingData
                        ? followingData[thoughts[id].journalUserID]
                          ? true
                          : false
                        : false
                    }
                    hideComments={
                      this.props.userdata &&
          HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                    }
                    openFileViewer={this.props.openFileViewer}
                  />
                )
              } else if (this.state.filter === 'videos' && currentThought.videos) {
                return (
                  <ThoughtsCard
                    onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
                    ref={'' + thoughts[id].dailyThoughtID}
                    onCommentsClick={this.handleCommentsClick}
                    thought={currentThought}
                    key={id}
                    PlayMedia={this.OpenMediaPlayer}
                    onRemoveAttachement={this.handleRemoveAttachement}
                    {...this.props}
                    following={
                      followingData
                        ? followingData[thoughts[id].journalUserID]
                          ? true
                          : false
                        : false
                    }
                    hideComments={
                      this.props.userdata &&
          HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                    }
                    openFileViewer={this.props.openFileViewer}
                  />
                )
              } else if (this.state.filter === 'images' && currentThought.photos) {
                for (let photo in currentThought.photos) {
                  if (currentThought.photos[photo].url !== '') {
                    return (
                      <ThoughtsCard
                        onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
                        ref={'' + thoughts[id].dailyThoughtID}
                        onCommentsClick={this.handleCommentsClick}
                        thought={currentThought}
                        key={id}
                        PlayMedia={this.OpenMediaPlayer}
                        onRemoveAttachement={this.handleRemoveAttachement}
                        {...this.props}
                        following={
                          followingData
                            ? followingData[thoughts[id].journalUserID]
                              ? true
                              : false
                            : false
                        }
                        hideComments={
                          this.props.userdata &&
                       HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                        }
                        openFileViewer={this.props.openFileViewer}
                      />
                    )
                  }
                }
              } else if (this.state.filter === 'articles' && currentThought.urls) {
                return (
                  <ThoughtsCard
                    onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
                    ref={'' + thoughts[id].dailyThoughtID}
                    onCommentsClick={this.handleCommentsClick}
                    thought={currentThought}
                    key={id}
                    PlayMedia={this.OpenMediaPlayer}
                    onRemoveAttachement={this.handleRemoveAttachement}
                    {...this.props}
                    following={
                      followingData
                        ? followingData[thoughts[id].journalUserID]
                          ? true
                          : false
                        : false
                    }
                    hideComments={
                      this.props.userdata &&
                       HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                    }
                    openFileViewer={this.props.openFileViewer}
                  />
                )
              } else if (this.state.filter === 'files' && currentThought.files) {
                return (
                  <ThoughtsCard
                    onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
                    ref={'' + thoughts[id].dailyThoughtID}
                    onCommentsClick={this.handleCommentsClick}
                    thought={currentThought}
                    key={id}
                    PlayMedia={this.OpenMediaPlayer}
                    onRemoveAttachement={this.handleRemoveAttachement}
                    {...this.props}
                    following={
                      followingData
                        ? followingData[thoughts[id].journalUserID]
                          ? true
                          : false
                        : false
                    }
                    hideComments={
                      this.props.userdata &&
                      HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                    }
                    openFileViewer={this.props.openFileViewer}
                  />
                )
              }
              else if (this.state.filter === 'podcasts' && currentThought.podcasts) {
                return (
                  <ThoughtsCard
                    onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
                    ref={'' + thoughts[id].dailyThoughtID}
                    onCommentsClick={this.handleCommentsClick}
                    thought={currentThought}
                    key={id}
                    PlayMedia={this.OpenMediaPlayer}
                    onRemoveAttachement={this.handleRemoveAttachement}
                    {...this.props}
                    following={
                      followingData
                        ? followingData[thoughts[id].journalUserID]
                          ? true
                          : false
                        : false
                    }
                    hideComments={
                      this.props.userdata &&
                      HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                    }
                    openFileViewer={this.props.openFileViewer}
                  />
                )
              }
              // user redux prop
              // // console.log('subcompFilter 2: ', this.props.thoughts[id].subcompetencyid);
              // // console.log('subcompFilter subcompFilter: ', this.props.subcompFilter);

              {/* if (
                this.props.thoughts[id].subcompetencyid ===
                this.state.subcompetencyid
              ) {
                return (
                  <ThoughtsCard
                    onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
                    ref={'' + thoughts[id].dailyThoughtID}
                    onCommentsClick={this.handleCommentsClick}
                    thought={thoughts[id]}
                    key={id}
                    PlayMedia={this.OpenMediaPlayer}
                    onRemoveAttachement={this.handleRemoveAttachement}
                    {...this.props}
                    following={
                      followingData
                        ? followingData[thoughts[id].journalUserID]
                          ? true
                          : false
                        : false
                    }
                    hideComments={
                      this.props.userdata &&
                      HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                    }
                    openFileViewer={this.props.openFileViewer}
                  />
                )
              } */}
            })}

          {/* <AddToPLDPDialog
            open={this.state.openAddPLDP}
            onClickProceed={this.handleAddToPLDP}
            onCloseDialog={this.handleCloseDialog}
          /> */}
          <RemoveAttachmentDialog />
        </div>
      </React.Fragment>
    )
  }
}

ThoughtsView.propTypes = {
  classes: PropTypes.object.isRequired,
  thoughts: PropTypes.array
}

let ThoughtsViewStyled = withStyles(styles)(ThoughtsView)

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  }
}

export default connect(
  mapStateToProps,
  null
)(ThoughtsViewStyled)
