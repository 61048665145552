import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emphasize, withStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  Paper,
  Grid,
  Typography, Breadcrumbs, Chip, Tooltip, Button,
  Link as FileLink, ExpansionPanelActions, Modal,
  Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Divider
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import MUIDataTable from 'mui-datatables'
import UploadFiles from '../UploadFiles'
import DescriptionIcon from '@material-ui/icons/Description'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import IconButton from '@material-ui/core/IconButton'
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import indigo from '@material-ui/core/colors/indigo'
import { addAttendanceRegisters, getGroupAttendanceRegister, getInActiveStudyMaterials, deleteAttendanceRegister } from "../../store/actions/companyAdminActions";
import { SUPPORTED_FILE_TYPES, SHOW_TOAST } from "../../store/actions/types";
import { showToast } from '../../store/actions/toastAction'
import { DATE_FORMAT } from "../../utils/date_format_utils";
import FileViewer from "../FileViewer";
import AssignedGroups from "./AssignedGroups";
import { GREEN, RED } from "../../utils/color_utils";
import { RadioGroup, Radio } from 'react-radio-group'

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 40 + rand()
const left = 50 + rand()

const columns = ['Programme', 'Facilator', 'Date', 'Venue']

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    boxShadow: 'none'
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  userTypeButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${indigo[500]}`,
    border: '1px solid',
  },
  archiveButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${RED}`,
    border: '1px solid',
  },
  activateButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${GREEN}`,
    border: '1px solid',
  },
  icon: {
    fontSize: '20px'
  }
})


let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    multiple,
    filterby,
    onSelectedFile,
    archiveSelectedFile,
    onOpenAssignedGroups,
  } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      elevation={0}
    >
      <div className={classes.title} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {numSelected === 1 ? (
              <React.Fragment>
                <Button
                  variant='outline'
                  aria-label='Archive'
                  onClick={archiveSelectedFile}
                  className={classes.archiveButton}
                  size='small'
                >
                  <PlaylistAddCheckIcon className={classes.icon} />
                  Delete
                </Button>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)


const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none',
    },
  },
}))(Chip);

const styles = theme => ({
  root: {
    marginTop: 0,
    overflowX: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '130px',
      padding: '0 10px'
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  pageHeader: {
    marginTop: 0,
    padding: theme.spacing(1)
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  button: {
    margin: "8px",
    color: `${indigo[500]}`
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  chip: {
    margin: theme.spacing.unit / 2
  }
})

class AttendanceRegister extends Component {
  state = {
    uploadinprogress: false,
    filetitle: '',
    fileurl: '',
    fileuploaded: false,
    filesObj: [],
    filterby: 'active',
    dataListClone: [],
    openfileviewer: false,
    filename: "",
    filetype: "",
    filepath: "",
    openConfirm: false,
    open: false,
    file: {},
    metadata: {},
    openAttendanceDialog: false,
    facilitator_name: '',
    training_venue: '',
    start_date: '',
    end_date: '',
    training_programme: '',
    study_school: '',
    other_facilitators: '',
  };

  options = {
    filterType: 'radio',
    selectableRows: 'single',
    customToolbarSelect: e => {
      return (
        <EnhancedTableToolbar
          numSelected={e.data.length}
          multiple={e.data.length > 0}
          options={{
            elevation: 0
          }}
          filterby={this.state.filterby}
          onSelectedFile={() =>
            this.handleSelectedFile()
          }
          archiveSelectedFile={() =>
            this.handleArchiveSelectedFile()
          }
          onOpenAssignedGroups={() =>
            this.handleOpenAssignedGroups()
          }
        />
      )
    },
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      let { group_attendance_registers } = this.props

      // console.log("currentRowsSelected: ", currentRowsSelected)

      // let data =
      //   users &&
      //   Object.keys(users).map((v, i) => {
      //     return { data: users[v], uid: users[v].id }
      //   })

      if (currentRowsSelected[0] !== undefined) {
        window.sf = this.state.dataListClone[currentRowsSelected[0].dataIndex];
      }
    }
  }

  handleSelectedFile = () => {
    let sFile = window.sf;

    this.props.deleteAttendanceRegister({ companyID: this.props.userdata.companyID, id: sFile[4], group_id: sFile[5] })
  };

  handleArchiveSelectedFile = () => {
    this.setState({
      openConfirm: true
    })
  };

  confirmArchiveFile = () => {
    let sFile = window.sf;

    this.props.deleteAttendanceRegister({ companyID: this.props.userdata.companyID, id: sFile[4], group_id: sFile[5] })

    this.handleCloseConfirm()
  };

  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false
    })
  };

  handleOpenAssignedGroups = () => {
    let sFile = window.sf;

    this.setState({
      open: true,
      file: sFile[5]
    })
  };

  handleCloseAttendanceRegister = () => {
    this.setState({
      openAttendanceDialog: false,
      metadata: {}
    })
  };

  handleSaveAttendanceRegister = () => {
    var metadata = {};
    metadata = this.state.metadata;

    if (this.state.training_venue === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter training venue ..."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.start_date === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter start date ..."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.end_date === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter end date ..."
      };

      this.props.showToast(params);
      return;
    }

    if (Date.parse(this.state.start_date) > Date.parse(this.state.end_date)) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Start date cannot be greater than end date ..."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.facilitator_name === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter Facilitator Name ..."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.fileurl === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please select File ..."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.fileuploaded === true) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please wait, file upload in progress ..."
      };

      this.props.showToast(params);
      return;
    }

    metadata.facilitator_name = this.state.facilitator_name;
    metadata.training_venue = this.state.training_venue;
    metadata.start_date = this.state.start_date;
    metadata.end_date = this.state.end_date;
    metadata.training_programme = this.state.training_programme;
    metadata.study_school = this.state.study_school;
    metadata.other_facilitators = this.state.other_facilitators;
    metadata.group_id = this.props.group_id;

    this.props.addAttendanceRegisters(metadata);

    this.setState({
      uploadinprogress: false,
      filetitle: '',
      fileurl: '',
      fileuploaded: false,
      filesObj: [],
      openfileviewer: false,
      filename: "",
      filetype: "",
      filepath: "",
      openConfirm: false,
      open: false,
      file: {},
      metadata: {},
      openAttendanceDialog: false,
      facilitator_name: '',
      training_venue: '',
      start_date: '',
      end_date: '',
      training_programme: '',
      study_school: '',
      other_facilitators: '',
    })
  };

  handleClose = () => {
    this.setState({
      uploadinprogress: false,
      filetitle: '',
      fileurl: '',
      fileuploaded: false,
      filesObj: [],
      openfileviewer: false,
      filename: "",
      filetype: "",
      filepath: "",
      openConfirm: false,
      open: false,
      file: {},
      metadata: {},
      openAttendanceDialog: false,
      facilitator_name: '',
      training_venue: '',
      start_date: '',
      end_date: '',
      training_programme: '',
      study_school: '',
      other_facilitators: '',
    });
  };

  handleLinkDelete = () => e => {
    this.setState({
      ...this.state,
      fileurl: '',
      filetitle: '',
      fileuploaded: false,
      metadata: {}
    })
  };

  fileUploadFinished = (url, filename, metadata) => {
    metadata.companyID = this.props.userdata.companyID;
    metadata.filename = filename;
    metadata.url = url;

    this.setState({
      ...this.state,
      fileurl: url,
      filetitle: filename,
      fileuploaded: false,
      metadata
    })

  };

  uploadInProgress = status => {
    this.setState({
      ...this.state,
      uploadinprogress: status
    })

    // this.props.onChange("uploadInProgress", status);
  };

  handleFileViewerClose = () => {
    this.setState(state => ({
      openfileviewer: false,
      filename: "",
      filetype: "",
      filepath: "",
      file: {}
    }))
  };

  openAttendanceRegister = () => {
    this.setState(state => ({
      openAttendanceDialog: true,
      metadata: {}
    }))
  };

  openFileViewer = (file) => {
    let filename = file.filename;
    let filepath = file.filepath;
    let lastDot = filename.lastIndexOf('.');
    const ext = filename.substring(lastDot + 1);
    filename = file.programme;

    if (SUPPORTED_FILE_TYPES[ext]) {
      this.setState(state => ({
        openfileviewer: true,
        filetype: ext,
        filepath: filepath,
        filename: filename,
        file: file
      }))
    } else {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Unsupported file type!'
      }

      this.props.showToast(params)
    }

  };

  componentDidMount() {
    // console.log("mounted: ", this.props.group_id)
    this.props.getGroupAttendanceRegister({ group_id: this.props.group_id })
  }

  transformTableData = () => {
    let { group_attendance_registers, group_details } = this.props
    let attendance_registers = group_attendance_registers ? group_attendance_registers : {};
    // console.log("attendance_registers: ", attendance_registers)

    let dataListClone = Object.keys(attendance_registers).map((uid, index) => {
      var start_date = DATE_FORMAT.type(attendance_registers[uid].start_date, "SHORT");
      var end_date = DATE_FORMAT.type(attendance_registers[uid].end_date, "SHORT");
      let programme_date = start_date + ' - ' + end_date;
      let group_no = group_details.group_no ? group_details.group_no : '';
      let study_school = group_details.study_school ? group_details.study_school : '';
      var programme = group_details.group_name + '- Group ' + group_no + ': Study School ' + study_school;

      //  group_details.study_school ? group_details.study_school : '';

      let file = {
        programme: programme,
        filepath: attendance_registers[uid].url,
        filename: attendance_registers[uid].filename,
        companyID: attendance_registers[uid].companyID,
        name: attendance_registers[uid].name,
        size: attendance_registers[uid].size,
        url: attendance_registers[uid].url,
        type: attendance_registers[uid].type,
        contentType: attendance_registers[uid].contentType,
        facilitator_name: attendance_registers[uid].facilitator_name,
        training_venue: attendance_registers[uid].training_venue,
        start_date: attendance_registers[uid].start_date,
        training_programme: attendance_registers[uid].training_programme,
        study_school: attendance_registers[uid].study_school ? attendance_registers[uid].study_school : '',
        other_facilitators: attendance_registers[uid].other_facilitators ? attendance_registers[uid].other_facilitators : '',
      }
      return [
        <FileLink
          component="button"
          variant="body2"
          onClick={() => {
            this.openFileViewer(file);
          }}
        >
          {programme}
        </FileLink>,
        attendance_registers[uid].facilitator_name,
        programme_date,
        attendance_registers[uid].training_venue,
        uid,
        attendance_registers[uid].group_id,
        file
      ]
    })

    this.state = { ...this.state, dataListClone: dataListClone };

    return dataListClone;
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { filepath, filetype, filename, openfileviewer, file } = this.state;
    const { classes, group_details } = this.props

    return (
      <div>
        <Grid container spacing={2} gutterBottom>
          <Grid item xs={12} sm={12}>
            <Paper style={{ maxWidth: '100vw' }} className={classes.pageHeader}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    size="small"
                    className={classes.button}
                    onClick={this.openAttendanceRegister}
                  >
                    Add Attendance Register
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <MUIDataTable
                    title={''}
                    data={this.transformTableData()}
                    columns={columns}
                    options={this.options}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <FileViewer
          filetype={filetype}
          filename={filename}
          filepath={filepath}
          opendialog={openfileviewer}
          handleClose={this.handleFileViewerClose} />
        <Dialog
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.open}
          fullWidth
          maxWidth="md"
        >
          <DialogContent style={{ margin: '0px 10px 0px 10px', height: "80vh" }}>
            <AssignedGroups userdata={this.props.userdata} file={file} />

          </DialogContent>
          <DialogActions>
            <div style={{ justifyContent: 'flex-start' }}>
              <Button onClick={this.handleCloseAssigned} size='small'>Close</Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.openAttendanceDialog}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Upload Attendance Register</DialogTitle>
          <DialogContent style={{ margin: '0px 10px 0px 10px' }}>
            <Typography variant='h6'>Programme/Course: {group_details.group_name} - Group {group_details.group_no ? group_details.group_no : ''}: Study School {group_details.study_school ? group_details.study_school : ''}</Typography>
            <Divider />
            <TextField
              //autoFocus={true}
              required
              margin="dense"
              id="name"
              label="Training Venue"
              type="text"
              fullWidth
              value={this.state.training_venue}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  training_venue: value,
                });
              }}
            />
            <TextField
              margin="dense"
              id="start-date"
              label="Start date"
              fullWidth
              type="date"
              inputProps={{
                name: "start_date",
                id: "start_date"
              }}
              onChange={e =>
                this.handleChange(e, "start_date")
              }
              value={this.state.start_date}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              margin="dense"
              id="end-date"
              label="End date"
              fullWidth
              type="date"
              inputProps={{
                name: "end_date",
                id: "end_date"
              }}
              onChange={e =>
                this.handleChange(e, "end_date")
              }
              value={this.state.end_date}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              required
              margin="dense"
              id="name"
              label="Facilitator Name"
              type="text"
              fullWidth
              value={this.state.facilitator_name}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  facilitator_name: value,
                });
              }}
            />
            <TextField
              margin="dense"
              id="other_facilitators"
              multiline
              label="Other Facilitators"
              type="text"
              fullWidth
              value={this.state.other_facilitators}
              onChange={event => {
                const { value } = event.target;
                this.setState({
                  other_facilitators: value,
                });
              }}
            />
            <div style={{ padding: "8px" }}>
              {this.state.fileurl && this.state.fileurl.length > 0 ?
                <Chip
                  icon={<AttachFileIcon />}
                  label={this.state.filetitle}
                  onDelete={this.handleLinkDelete()}
                  className={classes.chip}
                /> : null
              }
            </div>
            <UploadFiles
              onUploadComplete={this.fileUploadFinished.bind(this)}
              onUploadProgress={this.uploadInProgress.bind(this)}
              uploadtype='attendance_register'
            />

          </DialogContent>
          <DialogActions>
            <div style={{ justifyContent: 'flex-start' }}>
              <Button onClick={this.handleClose} size='small' color='secondary' variant='outlined' style={{ marginRight: 10 }}>Close</Button>
              <Button onClick={this.handleSaveAttendanceRegister} size='small' color='primary' variant='outlined'>Submit</Button>
            </div>
          </DialogActions>
        </Dialog>
        <Modal
          aria-labelledby='confirmation-modal'
          aria-describedby='confirmation-modal-description'
          open={this.state.openConfirm}
          className={classes.modal}
        >
          <div style={modalStyle2} className={classes.paper}>
            <Typography variant='h6' gutterBottom>Please Confirm</Typography>
            <Typography variant='body2' gutterBottom>Do you want to delete the selected attendance register?</Typography>
            <div>
              <ExpansionPanelActions>
                <Button style={{ marginRight: '8px' }} onClick={this.handleCloseConfirm} size='small'>Cancel</Button>
                <Button onClick={this.confirmArchiveFile} size='small' color='secondary'>Delete</Button>
              </ExpansionPanelActions>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

AttendanceRegister.propTypes = {
  classes: PropTypes.object,
}

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
}
const modalStyle2 = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}

const AttendanceRegisterStyled = withStyles(styles)(AttendanceRegister)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    group_attendance_registers: state.users.group_attendance_registers,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    addAttendanceRegisters: params => dispatch(addAttendanceRegisters(params)),
    getGroupAttendanceRegister: params => dispatch(getGroupAttendanceRegister(params)),
    getInActiveStudyMaterials: params => dispatch(getInActiveStudyMaterials(params)),
    deleteAttendanceRegister: params => dispatch(deleteAttendanceRegister(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceRegisterStyled)

