import React from "react";
import ReactDOM from "react-dom";
import "./Index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPlayer from 'react-player'

 class ShowTutorial extends React.Component {
  constructor (props) {
     super(props);
  }

  state = {
    showPic: false,
    mgroupid: this.props.groupID,
    mgroupname: this.props.title,
  }

  componentDidMount() {
    console.log('blue surveyType: ', this.props);
   }

  render(){

    return (
        <React.Fragment>         
        <div>       
        <ReactPlayer 
          url='https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/PointsSystem6.mp4?alt=media&token=05f11b29-11b7-4eee-ae7c-2ec06590508b' 
          className='react-player'
          playing
          width='100%'
          height='100%'
          />

        </div>                                                                                                                  
        </React.Fragment> 

    )
  }
}

 export default ShowTutorial;

// import React from "react";
// import PropTypes from "prop-types";

// const ShowTutorial = () => (
//   <div className="video-responsive">
//     <iframe
//       width="853"
//       height="480"
//       src={`https://www.youtube.com/embed/${'GzIkcq2xaRA'}`}
//       frameBorder="0"
//       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//       allowFullScreen
//       title="Embedded youtube"
//     />
//   </div>
// );

// ShowTutorial.propTypes = {
//   embedId: PropTypes.string.isRequired
// };

// export default ShowTutorial;


