import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { MenuItem, Input, FormControl, Select, InputLabel } from '@material-ui/core';
import { getCompanyValues } from "../../store/actions/pldpActions";

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '200px'
  },
});
class CompetencyOptions extends Component {
  constructor (props) {
    super(props);
    console.log('props for CompetencyOptions: ', this.props);
  }
  state = {
    competencyid: '',
    competencyname: '',
    open: false
  }

  componentWillMount() {
    this.fetchData();
  }

  componentDidMount() {
    this.fetchData();
    // console.log("competency found 1 ", this.props.competencyid)
    if (this.props.competencyid) {
      this.setState({ competencyid: this.props.competencyid })
      // console.log("competency found ", this.props.competencyid)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps !== this.props) {
    //   if (prevProps.competencyid !== this.props.competencyid) {
    //     this.setState({competencyid: this.props.competencyid})
    //   }
    // }
  }

  handleChange = (event, name) => {
    this.setState({ competencyid: event.target.value, competencyname: name.props.children });
    this.props.onChangeValue(event.target.value, name.props.children);
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  handleOpen = () => {
    this.setState({ open: true })
  };

  fetchData = () => {
    let { dailyThoughtType } = this.props
    //dthought = dailyThoughtType;
    //if (dthought===undefined) {dthought = 5} //no thoughtsdialog

   // var dthought 
    if(dailyThoughtType) {parseInt(dailyThoughtType)};
    var pathname = window.location.pathname;
    console.log('green path pathname: ', pathname);
    console.log('green path dailyThoughtType : ', dailyThoughtType);

    switch(dailyThoughtType) {
      case 3:
        this.props.fetchCompanyValues('-Lu4BlhZ-tsDSnd-fnFd');
        break;
      case undefined:
        if ( pathname==='/' || pathname==='/ilead' || pathname==='/videos' || pathname==='/podcasts' || pathname==='/articles' ) {
          this.props.fetchCompanyValues('-Lu4BlhZ-tsDSnd-fnFd');
        }
          else {
          this.props.fetchCompanyValues(this.props.userdata.companyID);
          }
        break;
      default:
          this.props.fetchCompanyValues(this.props.userdata.companyID);
        break;
      }
  };

  filterZZZ = (comLable) => {
    let comLablex = comLable.toString()
    let myTruncatedString = comLablex.substring(0, 4)
    let finalstring = comLablex

    if (myTruncatedString === 'ZZZ_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    if (myTruncatedString === 'AAA_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    return finalstring
  };

  render() {
    const { classes, companyValues, userdata } = this.props;
    const { competencyid, open, competencyname } = this.state;

    console.log('greencompanyValues ', companyValues);

    if (companyValues) { companyValues.sort(function(a, b){
      if(a.valueDesc < b.valueDesc) { return -1; }
      if(a.valueDesc > b.valueDesc) { return 1; }
      return 0;
     })
   }

    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">Main Category</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={competencyid}
            onChange={this.handleChange}
            input={<Input name="competencies" id="competencies" value={competencyname} />}
            aria-expanded="true"
            style={{
              width: 200,
              height: 35,
              color: 'gray',
              paddingLeft: 5,
              fontSize: 14,
              border: 'none',
              marginLeft: 10,
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {companyValues &&
              Object.keys(companyValues).map(id => {              
                const labelId = `${companyValues[id].valueID}`;
                const labelName = `${companyValues[id].valueDesc}`;
                let labelNamex = this.filterZZZ(labelName)
                return (
                  //  (this.props.userdata.companyID==='-LjW7UI7e0uUikYrw-Vj')
                  //  ? 
                  //  labelId!=='b4286108-a99d-46d4-bb9d-eacc64253bf0' && 
                  //  labelId!=='07ea19b6-19d1-4f42-b926-7579008945cd' && 
                  //  labelId!=='ea422d9b-fe94-4110-9db2-512c8e7607b9' && 
                  //  labelId!=='fca710c4-931c-4562-8e58-1a0eb1854a06' && 
                  //  labelId!=='89dac404-0800-41fd-b49d-7ac74c221106' && 
                  //  labelId!=='f6c65e5b-8b5f-4ce9-a01a-f1b9f60fc20e' && 
                  //  labelId!=='2fb61900-3569-4a63-ba00-36ffccf5612b'?
                  //  <MenuItem key={labelId} value={labelId}>{labelNamex}</MenuItem> : null
                  //  : 
                   <MenuItem key={labelId} value={labelId}>{labelNamex}</MenuItem>  
                );
              })}
          </Select>
        </FormControl>
      </div>
    )
  }
}

CompetencyOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  competencyid: PropTypes.any.isRequired,
};

const CompetencyOptionsStyled = withStyles(styles)(CompetencyOptions);

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    companyValues: state.pldp.companyValues
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCompanyValues: params => dispatch(getCompanyValues(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetencyOptionsStyled);
