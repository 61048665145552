import { COLLECT_TESTS } from "../actions/types";

const initState = {
  type: COLLECT_TESTS,
  testresult: ''
};

const surveyTestReducer = (state = initState, action) => {
  switch (action.type) {
    case COLLECT_TESTS:
      return {
        type: COLLECT_TESTS,
        testresult: action.testresult
      };

    default:
      return state;
  }
};

export default surveyTestReducer;