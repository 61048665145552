import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { DARK, LIGHTGREY } from "../../utils/color_utils";

const posttitles = {
  otherclicks: "Post clicks",
  attachmentsclicks: "Attachment clicks",
  listenedto: "Listened to",
  profileclicks: "Profile clicks",
  viewed: "Views",
  readarticle: "Read article"
};
const postnaration = {
  otherclicks: "number of times people clicked around the post",
  attachmentsclicks: "number of times people clicked on post attachments",
  listenedto: "number of times people listened to this podcast",
  profileclicks: "number of times people clicked on your profile photo",
  viewed: "number of times people viewed this video",
  readarticle: "number of times people read this article"
};

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  icon: {
    padding: "5px",
    fontSize: "30px"
  },
  colorDark: {
    color: `${DARK}`
  },
  colorDarkBlue: {
    color: `#607d8b`
  },
  analyticsCount: {
    right: "16px"
  }
});

class PostActivityList extends React.Component {
  _setTotals = (item, total) => {
    var tot = total || 0;
    for (var x in item) {
      tot += item[x] || 0;
    }
    return tot;
  };

  render() {
    const { classes, postsanalytics, postActivity } = this.props;
    return (
      <div>
        <Paper elevation="0">
          <List className={classes.root}>
            <div>
              {postActivity ? (
                <React.Fragment>
                  <ListItem
                    alignItems="flex-start"
                    style={{ backgroundColor: `${LIGHTGREY}` }}
                    divider={true}
                    key="0"
                  >
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.colorDark}
                          component="strong"
                          color="textPrimary"
                          style={{ fontWeight: "bold" }}
                        >
                          @{postActivity.username}
                        </Typography>
                      }
                      className={classes.colorDark}
                      secondary={
                        <React.Fragment>
                          <Typography variant="body2" color="textPrimary">
                            {postActivity.title}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start" divider={true} key="0">
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.colorDark}
                          component="strong"
                          color="textPrimary"
                        >
                          Total engagements
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            className={classes.colorDarkBlue}
                            variant="body2"
                            color="textPrimary"
                          >
                            times people interacted with the post
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemSecondaryAction className={classes.analyticsCount}>
                      {postsanalytics
                        ? this._setTotals(
                            postsanalytics,
                            postActivity.totalEngagement
                          )
                        : postActivity.totalEngagement || 0}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem alignItems="flex-start" divider={true} key="0">
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.colorDark}
                          component="strong"
                          color="textPrimary"
                        >
                          Likes
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            className={classes.colorDarkBlue}
                            variant="body2"
                            color="textPrimary"
                          >
                            times people liked this post
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemSecondaryAction className={classes.analyticsCount}>
                      {postActivity.likesCount || 0}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem alignItems="flex-start" divider={true} key="0">
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.colorDark}
                          component="strong"
                          color="textPrimary"
                        >
                          Comments
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            className={classes.colorDarkBlue}
                            variant="body2"
                            color="textPrimary"
                          >
                            times people commented on this post
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemSecondaryAction className={classes.analyticsCount}>
                      {postActivity.commentsCount || 0}
                    </ListItemSecondaryAction>
                  </ListItem>
                </React.Fragment>
              ) : null}
              {postsanalytics &&
                Object.keys(postsanalytics).map((key, index) => {
                  return (
                    <ListItem
                      alignItems="flex-start"
                      divider={true}
                      key={index}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            className={classes.colorDark}
                            component="strong"
                            color="textPrimary"
                          >
                            {posttitles[key]}
                          </Typography>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              className={classes.colorDarkBlue}
                              variant="body2"
                              color="textPrimary"
                            >
                              {postnaration[key]}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction
                        className={classes.analyticsCount}
                      >
                        {postsanalytics[key]}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </div>
          </List>
        </Paper>
      </div>
    );
  }
}

PostActivityList.propTypes = {
  classes: PropTypes.object.isRequired,
  postsanalytics: PropTypes.array
};

const MyPostActivity = withStyles(styles)(PostActivityList);

const mapStateToProps = state => {
  return {
    postsanalytics: state.analytics.postsanalytics
  };
};

const MyPostActivityObj = connect(
  mapStateToProps,
  null
)(MyPostActivity);

export default MyPostActivityObj;
