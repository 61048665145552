import {
  INTERNAL_PODCASTS,
  EXTERNAL_PODCASTS,
  MY_PODCASTS,
  BASE_API_URL,
  GLP_LOGGED_PROFILE,
  PLDP_PODCASTS
} from "./types";
import firebase from "../firebase";
import axios from "axios";
import { _toArray, _toArrayPosts } from "./commonActions";

export const fetchPodcasts = params => {
  // console.log("----- start fetchPodcasts");
  switch (params.type) {
    case INTERNAL_PODCASTS:
      return (dispatch, getState) => {
        firebase.database
          .ref("podcasts")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              // var sortedData = _toArray(data.val());
              // sortedData.sort(function(a, b) {
              //   if (a.dateScheduled > b.dateScheduled) return -1;
              //   if (a.dateScheduled < b.dateScheduled) return 1;
              //   return 0;
              // });
              var sortedData = data.val();
              dispatch({
                type: INTERNAL_PODCASTS,
                podcasts: sortedData
              });
              params.resolve();
            } else {
              dispatch({
                type: INTERNAL_PODCASTS,
                podcasts: {}
              });
              params.resolve();
            }
          });
      };
    case MY_PODCASTS:
      return (dispatch, getState) => {
        firebase.database
          .ref("podcasts")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var sortedData = _toArrayPosts(data.val());
              sortedData.sort(function(a, b) {
                if (a.dateScheduled > b.dateScheduled) return -1;
                if (a.dateScheduled < b.dateScheduled) return 1;
                return 0;
              });
              dispatch({
                type: MY_PODCASTS,
                mypodcasts: sortedData
              });
            } else {
              dispatch({
                type: MY_PODCASTS,
                mypodcasts: []
              });
            }
          });
      };
    case PLDP_PODCASTS:
      return (dispatch, getState) => {
        firebase.database
          .ref("myPLDP/podcasts")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(10)
          .on("value", function(data) {
            if (data.val()) {
              var sortedData = _toArrayPosts(data.val());
              sortedData.sort(function(a, b) {
                if (a.dateScheduled > b.dateScheduled) return -1;
                if (a.dateScheduled < b.dateScheduled) return 1;
                return 0;
              });
              dispatch({
                type: PLDP_PODCASTS,
                pldppodcasts: sortedData
              });
              return;
            } else {
              dispatch({
                type: PLDP_PODCASTS,
                pldppodcasts: {}
              });
              return;
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: INTERNAL_PODCASTS,
          podcasts: {}
        });
      };
  }
};

export const fetchExternalPodcasts = params => {
  switch (params.type) {
    case EXTERNAL_PODCASTS:
      return (dispatch, getState) => {
        firebase.database
          .ref("podcasts")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              // var sortedData = _toArray(data.val());
              // sortedData.sort(function(a, b) {
              //   if (a.dateScheduled > b.dateScheduled) return -1;
              //   if (a.dateScheduled < b.dateScheduled) return 1;
              //   return 0;
              // });
              var sortedData = data.val();

              dispatch({
                type: EXTERNAL_PODCASTS,
                externalpodcasts: sortedData
              });
              params.resolve();
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: EXTERNAL_PODCASTS,
                  externalpodcasts: {}
                });
                params.resolve();
              };
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: EXTERNAL_PODCASTS,
          externalpodcasts: {}
        });
      };
  }
};

export const createPodcast = params => {
  var USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
  return (dispatch, getState) => {
    if (params.subcompetencyid) {
      //nothing
      }
      else
      {
        params.subcompetencyid = params.competencyid + '-' + 'None';
        console.log('thoughtsActions createThought props: ', params.subcompetencyid);
      }
    // set user data
    var body = {
      ...params,
      companyID: USER_LOGGED.companyID,
      companyName: USER_LOGGED.companyName,
      userID: USER_LOGGED.userID,
      userName: USER_LOGGED.firstName + " " + USER_LOGGED.lastName,
      user_type: USER_LOGGED.userType,
      photoURL: USER_LOGGED.photoURL || ""
    };

    // clean data before posting
    delete body["maxLengthThought"];
    delete body["maxLengthAuthor"];
    delete body["modalTitle"];
    delete body["postInternalGlobal"];
    delete body["uploadInProgress"];
    delete body["selectedDate"];
    delete body["selectedTime"];

    // Editing or Creating new post?
    if (params.editing) {
      delete body["dateRegistered"];
      delete body["post"];
      body.name = "podcasts";
      body.id = params.post.podcastID;

      // post new data
      axios
        .put(`${BASE_API_URL}/podcasts/${body.id}`, body)
        .then(res => {
          dispatch({
            type: "CREATE_PODCAST_SUCCESS"
          });
        })
        .catch(err => {
          if (err.response) {
            dispatch({
              type: "SHOW_TOAST",
              open: true,
              variant: "error",
              message: "Error response: " + err.response.data.message
            });
          } else if (err.request) {
            dispatch({
              type: "SHOW_TOAST",
              open: true,
              variant: "error",
              message: "Error request: " + err.request
            });
          } else {
            dispatch({
              type: "SHOW_TOAST",
              open: true,
              variant: "error",
              message: "Error message: " + err.message
            });
          }
        });
    } else {
      // post new data
      if (body.groupid === "1") {
        // create multiple posts requests
        // console.log("selected groups: ", body.selectedgroups)
        // console.log("multiple posts requests: ", body)
        var multi_requests = [];


        for (let i = 0, l = body.selectedgroups.length; i < l; i += 1) {
          let single_body = { ...body };
          single_body.groupid = body.selectedgroups[i].group_id
          single_body.groupname = body.selectedgroups[i].group_name

          // console.log("i: ", i)
          // console.log("group_id: ", body.selectedgroups[i].group_id)
          // console.log("group_name: ", body.selectedgroups[i].group_name)
          // console.log("single_body: ", single_body)

          const request = axios.post(`${BASE_API_URL}/podcasts`, single_body)

          multi_requests.push(request)
        }

        // console.log("multi_requests: ", multi_requests)

        axios.all(multi_requests).then(axios.spread((...responses) => {
          // console.log("finished posting: ", responses)
          dispatch({
            type: "CREATE_PODCAST_SUCCESS"
          });
          // const responseOne = responses[0]
          // const responseTwo = responses[1]
          // const responesThree = responses[2]
          // use/access the results 
        })).catch(errors => {
          // react on errors.
          console.log("error posting: ", errors)
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error posting a podcast!"
          });
        })
      } else {
       // console.log("single posting: ", body)
        axios
          .post(`${BASE_API_URL}/podcasts`, body)
          .then(res => {
            dispatch({
              type: "CREATE_PODCAST_SUCCESS"
            });
          })
          .catch(err => {
            if (err.response) {
              dispatch({
                type: "SHOW_TOAST",
                open: true,
                variant: "error",
                message: "Error response: " + err.response.data.message
              });
            } else if (err.request) {
              dispatch({
                type: "SHOW_TOAST",
                open: true,
                variant: "error",
                message: "Error request: " + err.request
              });
            } else {
              dispatch({
                type: "SHOW_TOAST",
                open: true,
                variant: "error",
                message: "Error message: " + err.message
              });
            }
          });
      }
    }
  };
};