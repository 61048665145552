import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import ReplyIcon from "@material-ui/icons/AddCommentOutlined";
import AddCommentField from "./AddCommentField";
import { connect } from "react-redux";
import {
  deleteComment,
  deleteSubComment
} from "../../store/actions/commentActions";
import { addMorePoints } from '../../store/actions/freshCardActions'
import { getRealPoints } from '../../store/actions/realPointsActions'
import { GET_POINTS, CLEAR_POINTS, SHOW_TOAST } from "../../store/actions/types";
import { PHOTO_PROFILE } from "./../../utils/photo_utils";
import {
  fetchUserBiography,
  openUserProfileDialog
} from "../../store/actions/userBiographyActions";
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import { showToast } from '../../store/actions/toastAction'
import firebase from './../../store/firebase';

const CommentItem = ({
  comment,
  commentID,
  classes,
  openReply,
  onRemoveComment,
  onToggleExpandReply,
  post,
  postType,
  userData,
  onViewUserDialog,
  showLeadb
}) => {
  const replyToComment = classes.replyToComment;

  return (
    <div>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            alt={comment.journalUserName}
            src={PHOTO_PROFILE(comment.photoURL)}
            style={{ cursor: "pointer" }}
            onClick={e => onViewUserDialog(comment)}
            height={18}
            width={18}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <div>
              <strong className={classes.commenter} title={comment.stringCommentDate}>
                {comment.journalUserName}{isBrowser ? ' (' + comment.stringCommentDate + ')' : null}
             
                {/* <span className={classes.commentTime}>
                {comment.stringCommentDate}
              </span>  */}
    
            {userData.userID === comment.journalUserID || userData.userType === 7 || userData.userType === 14 ? (
                <IconButton style={{   
                  width: '20px',
                  height: '20px',
                }}
                onClick={e => onRemoveComment(comment)}
                aria-label="DeleteComment"
              >

                <DeleteIcon />
                </IconButton>
            
            ) : null}

&nbsp;&nbsp;&nbsp;<IconButton
              style={{   
                width: '20px',
                height: '20px',
              }}
              onClick={onToggleExpandReply}
              className={classNames({ replyToComment: openReply })}
              aria-label="ReplyComment"
            >
              <ReplyIcon/>
            
            </IconButton>
            </strong>
            </div>
          }
           secondary={
            
             <div className={classes.scomments}>
               {comment.comment} 
              </div>
            }
        />

           {/* <ListItemSecondaryAction style={{ display: 'contents' }}> */}

          {/* <span style={{ paddingLeft: '7%' }}>  
            {userData.userID === comment.journalUserID || userData.userType === 7 || userData.userType === 14 ? (
              <IconButton
                onClick={e => onRemoveComment(comment)}
                aria-label="DeleteComment"
              >
                <DeleteIcon />
              </IconButton>
            ) : null}

            <IconButton
              onClick={onToggleExpandReply}
              className={classNames({ replyToComment: openReply })}
              aria-label="ReplyComment"
            >
              <ReplyIcon/>
            
            </IconButton>
          </span> */}


        {/* </ListItemSecondaryAction>   */}
        {/* className={classNames({ replyToComment: openReply })}  */}
      </ListItem>

      {openReply ? (
        <AddCommentField
          parentID={comment.commentID}
          isSubComment={true}
          postType={postType}
          showLeadb={showLeadb}
          post={post}
        />
      ) : null}

      {comment.subComments ? (
        <Collapse
          in={comment.subComments ? true : false}
          timeout="auto"
          unmountOnExit
        >
           <List component="div" disablePadding dense="true">
            {Object.keys(comment.subComments).map(v => (
              <ListItem key={v} dense="true">
                <ListItemAvatar>
                  <Avatar
                    alt={comment.subComments[v].journalUserName}
                    src={PHOTO_PROFILE(comment.subComments[v].photoURL)}
                    style={{ cursor: "pointer" }}
                    onClick={e => onViewUserDialog(comment.subComments[v])}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={    
                    <div>
                      <strong className={classes.commenter} title={comment.stringCommentDate}>
                        {comment.subComments[v].journalUserName}{isBrowser ? ' (' + comment.subComments[v].stringCommentDate + ')' : null}
                        {userData.userID == comment.journalUserID ? (
                        <IconButton style={{   
                          width: '20px',
                          height: '20px',
                        }}
                          onClick={e => onRemoveComment(comment.subComments[v])}
                          aria-label="DeleteComment"
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    
                      </strong>
                    </div>
                  }
                  secondary={
                    <div className={classes.sScomments}>
                    {comment.subComments[v].comment}
                    </div>
                  }
                />
                {/* <ListItemSecondaryAction> */}
                  {/* {userData.userID == comment.journalUserID ? (
                    <IconButton style={{   
                      width: '20px',
                      height: '20px',
                    }}
                      onClick={e => onRemoveComment(comment.subComments[v])}
                      aria-label="DeleteComment"
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null} */}
                {/* </ListItemSecondaryAction> */}
              </ListItem>
            ))}
          </List> 
        </Collapse>
      ) : null}
    </div>
  );
};

function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
charactersLength));
  }
  return result;
}

class Comment extends Component {
  state = {
    expandReply: false
  };

  componentDidMount() {
    const { userdata, post, rrpoints } = this.props;
    console.log('green post getpoints 2 : ', this.props.rrpoints)
      if(this.props.rrpoints) {} else {
        if (this.props.post.uid) {
        var pdata = {
          type: "GET_POINTS",
          uid: this.props.post.uid,
          redpoints: rrpoints
        }
      this.props.getRealPoints(pdata);
      console.log('green post getpoints 3 : ', this.props.rrpoints)
    }
   }
 }
  handleToggleExpandReply = () => {
    this.setState(prevState => ({
      expandReply: !this.state.expandReply
    }));
  };

	pointsPass = (dbPoints, realPoints) => {
		console.log('poixxxxxs 1 ', dbPoints);
		console.log('poixxxxxs 2 ', realPoints);
	
		var pass = false;
		if (dbPoints < realPoints) {
		  if (parseInt(realPoints) - parseInt(dbPoints) < 3) { 
			pass = true;
		  }
		} else
		{
		  if (parseInt(dbPoints) - parseInt(realPoints) < 3) { //too big - CANCEL
			pass = true;
		  } 
		}
		console.log('poixxxxxs 4 ', pass);
		return pass;
	  }

  handleRemoveComment = async comment => {
    const { userdata, postType, post, parentID } = this.props;
    console.log('inside handleRemoveComment : ', post);
     let pdata, ydata = 0;
    if (userdata.userID !== this.props.post.journalUserID) { 
      if (this.props.post.uid) {
        //GET REAL POINTS DIRECTLY FROM DATABASE
       
        await firebase.database
        .ref('user/' + post.uid)
        .once('value', function (xdata) {
          if (xdata.val().points) {
          pdata = xdata.val().points
          ydata = xdata.val().pointsy
          console.log('GET-REAL-POINTS 1: ', pdata )
          //return pdata
          }
          }
        )
      }
    }
    if (userdata.userID !== this.props.post.journalUserID && pdata) { //not the same person (LEAD author cant get points by commenting on own LEAD)    
    console.log('addMorePoints comment delete 1', comment)
    let data = {
      uid: this.props.post.uid,
      userID: this.props.post.journalUserID,
      pointsCurrent: pdata,
      ypointsCurrent: ydata,//this.props.post.pointsy,
      leadPoints: this.props.post.leadPoints ? this.props.post.leadPoints : 0,
      pointsAdded: -2,
      trailID: makeid(10),
      companyName: this.props.post.companyName,
      firstName: this.props.post.journalUserName, // this.props.post.firstName,
      lastName: "empty", // this.props.post.lastName,
      title: comment.comment,
      actionType: "Ilead", //this.state.postInternalGlobal + ' added',
      actionDesc: "comment deleted", //this.state.postInternalGlobal,
      currentUser: userdata.userID,
      leadID: this.props.post.dailyThoughtID ? this.props.post.dailyThoughtID : ""
      }
    
        //ADD POINTS TO LEAD CREATOR
             if(pdata) {
               console.log('addMorePoints comment delete 6', data)
                this.props.addMorePoints(data)
                // if (this.props.showLeadb) {
                //   this.props.showLeadb(this.props.post, data.pointsAdded);
                //   }	
             }
             else
             {
                let params = {
                  type: SHOW_TOAST,
                  open: true,
                  variant: pdata ?  'warning' : 'success',
                  message: pdata ? 'Points could not be updated. Please try later.' : 'OK, Points activated'
                }
         
               this.props.showToast(params)
             } 
          }
          if (this.props.showLeadb) {
            this.props.showLeadb(this.props.post, 'something');
            }	

    let commentParams = {
      postType: postType,
      comment: comment.comment,
      commentID: comment.commentID,
      dailyThoughtType: post.dailyThoughtType,
      groupid: post.groupid,
      groupname: post.groupname,
      postID: post.dailyThoughtID
        ? post.dailyThoughtID
        : post.videoID || post.podcastID || post.newsID
    };
    console.log('addMorePoints comment delete 2', comment.parentID)

    if (comment.parentID) {
      console.log('addMorePoints comment delete 3', comment.parentID)
      commentParams.parentID = comment.parentID;
      this.props.deleteSubComment(commentParams);
      console.log('addMorePoints comment delete 4')

       // if (userdata.userID !== this.props.post.journalUserID && data.pointsCurrent) { //not the same person (LEAD author cant get points by commenting on own LEAD)
        //  console.log('addMorePoints comment delete 5')
          //GET REAL POINTS DIRECTLY FROM DATABASE
          //  var pdata;
          //  firebase.database
          //  .ref('user/' + data.uid)
          //  .on('value', function (xdata) {
          //    if (xdata.val().points) {
          //     pdata = xdata.val().points
          //     console.log('GET-REAL-POINTS 1: ', pdata )
          //    }})
          //    console.log('GET-REAL-POINTS 2: ', pdata )
          // //ADD POINTS TO LEAD CREATOR
          // if(pdata) {
          //   console.log('addMorePoints comment delete 6')
          //    this.props.addMorePoints(data)
          // }
          // else
          // {
          //    let params = {
          //      type: SHOW_TOAST,
          //      open: true,
          //      variant: pdata ?  'warning' : 'success',
          //      message: pdata ? 'Points could not be updated. Please try later.' : 'OK, Points activated'
          //    }
      
          //   this.props.showToast(params)
          // } 
        //}  

    } else {
      this.props.deleteComment(commentParams);
      console.log('addMorePoints comment delete 7', commentParams)
      //delete points from LEAD creator
				//ADD POINTS TO LEAD CREATOR
        //if (userdata.userID !== this.props.post.journalUserID && data.pointsCurrent) { //not the same person (LEAD author cant get points by commenting on own LEAD)
         // console.log('addMorePoints comment delete 8', commentParams)
          //GET REAL POINTS DIRECTLY FROM DATABASE
          //  var pdata;
          //  firebase.database
          //  .ref('user/' + data.uid)
          //  .on('value', function (xdata) {
          //    if (xdata.val().points) {
          //     pdata = xdata.val().points
          //     console.log('GET-REAL-POINTS 1: ', pdata )
          //    }})
          //    console.log('GET-REAL-POINTS 2: ', pdata )
          //ADD POINTS TO LEAD CREATOR
          // if(pdata) {
          //    this.props.addMorePoints(data)
          // }
          // else
          // {
          //    let params = {
          //      type: SHOW_TOAST,
          //      open: true,
          //      variant: pdata ?  'warning' : 'success',
          //      message: pdata ? 'Points could not be updated. Please try later.' : 'OK, Points activated'
          //    }
      
          //   this.props.showToast(params)
          // }
          
        //}
    }
    this.setState(prevState => ({
      comment: ""
    }));
  };
  openViewUserDialog = post => {
    var params = {
      userID: post.journalUserID,
      userName: post.journalUserName,
      companyID: post.companyID,
      companyName: post.companyName,
      photoURL: PHOTO_PROFILE(post.photoURL),
      showprofiledlg: true
    };

    // Fetch user bio data
    this.props.fetchUserBiography({ ...params, type: "USER_BIOGRAPHY" });

    // Open user profile dialog
    this.props.openUserProfileDialog(params);
  };

  render() {
    const { commentID, userdata, showLeadb } = this.props;
    return (
      <CommentItem
        openReply={this.state.expandReply}
        onToggleExpandReply={this.handleToggleExpandReply}
        commentID={commentID}
        onRemoveComment={this.handleRemoveComment}
        onViewUserDialog={this.openViewUserDialog}
        showLeadb={showLeadb? showLeadb: ''}
        userData={userdata}
        {...this.props}
      />
    );
  }
}

Comment.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    userdata: state.userDialog.userDetails[0],
    rrpoints: state.realpoints.realpoints
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUserBiography: params => dispatch(fetchUserBiography(params)),
    openUserProfileDialog: params => dispatch(openUserProfileDialog(params)),
    deleteComment: params => dispatch(deleteComment(params)),
    deleteSubComment: params => dispatch(deleteSubComment(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
    getRealPoints: params => dispatch(getRealPoints(params)),
    showToast: params => dispatch(showToast(params))
  };
};
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comment);
