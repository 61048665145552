import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import {
  withStyles,
  Typography,
  Grid,
  Button,
  IconButton,
  TextField,
  Switch,
  FormGroup,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paperMotivation: {
    border: "1px solid #ccc!important",
    backgroundColor: "#ffffff",
    height: "100%",
    flexGrow: 4
  },
  motivationHeader: {
    backgroundColor: "#0288d1",
    padding: "14px 14px!important"
  },

  colorTextSecondary: {
    color: "#ffffff"
  },
  motivationContent: {
    height: "100%",
    padding: "10px"
  },
  fab: {
    position: "relative",
    top: -8,
    float: "left"
  },
  arrowIcon: {
    color: "#ffffff"
  },
  textField: {
    marginRight: theme.spacing.unit
  },
  textDense: {},
  margin: {
    margin: theme.spacing.unit,
    padding: "10px 30px"
  }
});
window.moment = moment;
class Task extends Component {
  state = {
    pristine: false,
    reminder: false,
    frequency: 0,
    // Task Data
    moveActionDesc: "",
    reminderFrequency: "Does not repeat",
    stringReminderDate: "",
    stringReminderTime: "",
    reminderDate: 0,
    reminderOn: "",
    moveAction: "",
    title: " "
  };

  static FREQUENCY = [
    "Does not repeat",
    "Hourly",
    "Daily",
    "Weekly",
    "Monthly"
  ];

  componentDidMount() {
    if (this.props.hasTask) {
      const { task } = this.props;
      this.setState({
        moveActionDesc: task.moveActionDesc,
        title: task.title,
        reminder: task.reminderOn,
        stringReminderDate: moment(task.reminderDate).format("YYYY-MM-DD"),
        stringReminderTime: moment(task.reminderDate).format("HH:mm"),
        reminderFrequency: task.reminderFrequency
      });
    } else {
      this.setState({
        stringReminderDate: moment().format("YYYY-MM-DD"),
        stringReminderTime: moment().format("HH:mm"),
        reminder: false
      });
    }
  }

  handleReminderToggle = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  splitDate(date, char) {
    return date.split(char);
  }
  handleSaveClick = () => {
    const {
      task,
      journalUser,
      OnSaveTask,
      updateOnSave,
      companyValue
    } = this.props;
    const {
      title,
      stringReminderDate,
      stringReminderTime,
      reminderFrequency,
      reminder
    } = this.state;

    var getDatePart = this.splitDate(stringReminderDate, "-");

    var actualDate =
      getDatePart[1] +
      "/" +
      getDatePart[2] +
      "/" +
      getDatePart[0] +
      " " +
      stringReminderTime +
      ":00";

    var fullReminderDateStr = new Date(actualDate);
    var reminderDate = Date.parse(fullReminderDateStr);

    let newTask = {
      companyID: journalUser.companyID,
      companyName: journalUser.companyName,
      dateRegistered: Date.now(),
      journalUserID: journalUser.userID,
      journalUserName: journalUser.firstName
        ? journalUser.firstName
        : "" + " " + journalUser.lastName
        ? journalUser.lastName
        : "",
      moveAction: companyValue.companyValueID,
      moveActionDesc: companyValue.taskCompanyValue,
      reminderDate: reminderDate,
      reminderFrequency: reminderFrequency,
      reminderOn: reminder ? true : false,
      title: title,
      photoURL: journalUser.photoURL ? journalUser.photoURL : "",
      uid: journalUser.uid,
      user_type: journalUser.userType,
      myPLDPID: task ? task.myPLDPID : "",
      notificationID: task ? task.notificationID : "",
      stringReminderTime,
      stringReminderDate
    };
    OnSaveTask(newTask, updateOnSave);
  };

  render() {
    const {
      classes,
      companyValue,
      onBackClick,
      OnSaveTask,
      updateOnSave
    } = this.props;
    return (
      <Paper square={true} elevation={2} className={classes.paperMotivation}>
        <div className={classes.motivationHeader}>
          <Typography
            variant="subtitle1"
            align="center"
            color="secondary"
            className={classes.colorTextSecondary}
          >
            <IconButton
              size="small"
              onClick={onBackClick}
              aria-label="Add"
              className={classes.fab}
            >
              <ArrowBackIcon className={classes.arrowIcon} />
            </IconButton>
            {companyValue.taskCompanyValue}
          </Typography>
        </div>
        <div className={classes.motivationContent}>
          <form>
            <FormGroup row>
              <List className={classes.root}>
                <ListItem>
                  <TextField
                    error={this.state.title.length === 0}
                    className={classes.textField}
                    id="standard-full-width"
                    label="Take Action"
                    fullWidth
                    multiline
                    inputProps={{
                      name: "title",
                      id: "title"
                    }}
                    helperText={
                      this.state.title.length === 0
                        ? "Take Action is a required field"
                        : ""
                    }
                    onChange={e => this.handleChange(e, "title")}
                    value={this.state.title}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    dense="true"
                    primary={
                      <Typography variant="caption">Reminder</Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      onChange={this.handleReminderToggle("reminder")}
                      checked={this.state.reminder}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <Grid container spacing={16}>
                    <Grid item xs={12}>
                      <ListItemText
                        dense="true"
                        primary={
                          <Typography variant="caption">
                            Schedule Date & Time
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <TextField
                        type="date"
                        inputProps={{
                          name: "stringReminderDate",
                          id: "stringReminderDate"
                        }}
                        onChange={e =>
                          this.handleChange(e, "stringReminderDate")
                        }
                        value={this.state.stringReminderDate}
                        className={classes.textField}
                        id="standard-full-width"
                        label="Date"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField
                        type="time"
                        className={classes.textField}
                        id="standard-full-width"
                        label="Time"
                        value={this.state.stringReminderTime}
                        inputProps={{
                          name: "stringReminderTime",
                          id: "stringReminderTime"
                        }}
                        onChange={e =>
                          this.handleChange(e, "stringReminderTime")
                        }
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="age-simple">Frequency</InputLabel>
                    <Select
                      value={this.state.reminderFrequency}
                      onChange={e => this.handleChange(e, "frequency")}
                      inputProps={{
                        name: "reminderFrequency",
                        id: "reminderFrequency"
                      }}
                    >
                      <MenuItem value={"Does not repeat"}>
                        Does not repeat
                      </MenuItem>
                      <MenuItem value={"Hourly"}>Hourly</MenuItem>
                      <MenuItem value={"Daily"}>Daily</MenuItem>
                      <MenuItem value={"Weekly"}>Weekly</MenuItem>
                      <MenuItem value={"Monthly"}>Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
            </FormGroup>
            <center>
              <Button
                onClick={onBackClick}
                variant="contained"
                size="medium"
                color="secondary"
                className={classes.margin}
              >
                Close
              </Button>
              <Button
                disabled={this.state.title.trim().length === 0}
                onClick={this.handleSaveClick}
                variant="contained"
                size="medium"
                color="primary"
                className={classes.margin}
              >
                Save
              </Button>
            </center>
          </form>
        </div>
      </Paper>
    );
  }
}

Task.propTypes = {
  companyValue: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired
};

export default withStyles(styles)(Task);
