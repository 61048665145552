import React, { Component } from "react";
import PropTypes from "prop-types";
import PodcastsView from "../../components/Views/PldpPodcastsView";
import { connect } from "react-redux";
import {
  fetchPodcasts,
  fetchExternalPodcasts
} from "../../store/actions/podcastsActions";
import { PLDP_PODCASTS } from "../../store/actions/types";

class Podcasts extends Component {
  componentWillMount() {
    var params = {
      type: PLDP_PODCASTS,
      orderChild: "PLDPUserID",
      equalTo: this.props.userdata.userID
    };

    this.props.fetchPodcasts(params);
  }

  render() {
    const { podcasts, userdata } = this.props;
    return (
      <div>
        {userdata && podcasts ? (
          <React.Fragment>
            <PodcastsView podcasts={podcasts} {...this.props} />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

Podcasts.propTypes = {
  fetchPodcasts: PropTypes.func.isRequired,
  fetchExternalPodcasts: PropTypes.func.isRequired,
  podcasts: PropTypes.array
};

const mapStateToProps = state => {
  return {
    podcasts: state.podcasts.pldppodcasts,
    userdata: state.userDialog.userDetails[0]
  };
};

export default connect(
  mapStateToProps,
  { fetchPodcasts, fetchExternalPodcasts }
)(Podcasts);
