import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import firebase from "../../config/fbConfig";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PauseIcon from "@material-ui/icons/Pause";
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import indigo from '@material-ui/core/colors/indigo'
import {
  SHOW_TOAST,
} from "../../store/actions/types";
import { showToast } from "../../store/actions/toastAction";

const storageRef = firebase.storage().ref();
var uploadTask;

const styles = theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  tabsdiv: {
    flexGrow: 1,
    width: "100%!important",
    backgroundColor: theme.palette.background.paper,
    marginTop: "16px"
  },
  button: {
    margin: "8px",
    color: `${indigo[500]}`
  },
  rightIcon: {
    marginLeft: "8px"
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  input: {
    display: "none"
  },
  alignUploadItems: {
    display: "flex",
    display: "-webkit-flex",
    display: "-moz-flex",
    display: "-ms-flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
});

class UploadFiles extends React.Component {
  state = {
    selectedFile: null,
    uploadTask: null,
    downloadURL: null,
    isUploading: false,
    progress: 0,
    uploadStatus: "",
    isPaused: false
  };

  handleUploadFiles() {
    var self = this;
    var filename = "";

    // uploading external file
    if (this.props.filetoupload && this.props.uploadfile) {
      if (this.props.uploadtype === "podcast") {
        filename = "audio_" + new Date().toISOString() + ".wav";
        var file = this.props.filetoupload[0];
        // Create the file metadata
        var metadata = {
          contentType: "audio/wav"
        };
        uploadTask = storageRef
          .child(`test${this.props.uploadtype}s/` + filename)
          .put(file, metadata);
      }

      if (
        this.props.uploadtype === "image" ||
        this.props.uploadtype === "avatar"
      ) {
        filename = "image_" + new Date().toISOString() + ".wav";
        var file = this.props.filetoupload[0];
        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg"
        };
        uploadTask = storageRef
          .child(`testphotos/` + filename)
          .put(file, metadata);
      }
    } else {
      if (
        this.props.uploadtype === "image" ||
        this.props.uploadtype === "avatar"
      ) {
        filename =
          "image_" + new Date().toISOString() + " - " + this.state.selectedFile.name;
        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg"
        };
        uploadTask = storageRef
          .child(`testphotos/` + filename)
          .put(this.state.selectedFile, metadata);
      } else if (
        this.props.uploadtype === "file" || this.props.uploadtype === "study_material" || this.props.uploadtype === 'attendance_register'
      ) {
        filename = this.state.selectedFile.name;
        var lastDot = filename.lastIndexOf('.');
        const ext = filename.substring(lastDot + 1);
        var d = new Date();
        var date_string = d.toDateString();
        var time_string = d.toLocaleTimeString();
        filename = filename.substring(0, filename.lastIndexOf('.'));
        filename = filename + " - " + date_string + " " + time_string + "." + ext;

        if ((ext === 'docx') || (ext === 'pdf')) {
          var metadata = {
            contentType: this.state.selectedFile.type
          };

          uploadTask = storageRef
            .child(`${this.props.uploadtype}s/` + filename)
            .put(this.state.selectedFile, metadata);

        } else {
          this.setState({
            selectedFile: null,
            uploadTask: null,
            downloadURL: null,
            isUploading: false,
            progress: 0,
            uploadStatus: "",
            isPaused: false
          });

          var params = {
            type: SHOW_TOAST,
            open: true,
            variant: "warning",
            message: "Please select .pdf or .docx documents"
          };

          this.props.showToast(params);
          return;
        }


      } else {
        filename = new Date().toISOString() + this.state.selectedFile.name;
        var metadata = {
          contentType: this.state.selectedFile.type
        };

        uploadTask = storageRef
          .child(`test${this.props.uploadtype}s/` + filename)
          .put(this.state.selectedFile, metadata);
      }
    }

    this.state = { ...this.state, isUploading: true };

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            self.onProgress(progress, "Paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            self.onProgress(progress, "Please wait...uploading");
            break;
        }
      },
      function(error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            // console.log("unauthorized");
            break;

          case "storage/canceled":
            // User canceled the upload
            // console.log("cancelled");
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            // console.log("storage unknown");
            break;
        }
      },
      function() {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          uploadTask.snapshot.ref.getMetadata().then(function(metadata) {
            self.onComplete(downloadURL, filename, metadata);
          }).catch(function(error) {
            // Uh-oh, an error occurred!
            console.log("error metadata", error)
          });


        });


      }
    );
  }

  resumeUpload = () => {
    this.setState({
      ...this.state,
      isPaused: false
    });
    uploadTask.resume();
  };

  pauseUpload = () => {
    this.setState({
      ...this.state,
      isPaused: true
    });
    uploadTask.pause();
  };

  cancelUpload = () => {
    this.setState({
      selectedFile: null,
      uploadTask: null,
      downloadURL: null,
      isUploading: false,
      progress: 0,
      uploadStatus: "",
      isPaused: false
    });

    if (uploadTask) {
      uploadTask.cancel();
    }

    uploadTask = null;
    this.props.onUploadProgress(false);
  };

  onProgress = (currentProgress, currentStatus) => {
    this.setState({
      ...this.state,
      isUploading: true,
      progress: currentProgress,
      uploadStatus: currentStatus
    });

    this.props.onUploadProgress(true);
  };

  onComplete = (url, filename, metadata) => {
    const { downloadURL } = this.state;
    uploadTask = null;

    this.setState({
      selectedFile: null,
      uploadTask: null,
      downloadURL: url,
      isUploading: true,
      progress: 0,
      uploadStatus: "",
      isPaused: false
    });

    if (this.props.uploadtype === "avatar") {
      this.setState({
        ...this.state,
        isUploading: false,
        downloadURL: null
      });
    }

    if (this.props.uploadtype === "file" || this.props.uploadtype === "study_material" || this.props.uploadtype === "attendance_register") {
      this.setState({
        ...this.state,
        isUploading: false,
        downloadURL: null
      });
    }
    console.log('inside imageUploadFinished 1');
    
    this.props.onUploadProgress(false);
    this.props.onUploadComplete(url, filename, metadata);
  };

  fileUploadHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
      uploadTask: null,
      downloadURL: null,
      isUploading: false,
      progress: 0,
      uploadStatus: "",
      isPaused: false
    });
    event.target.value = "";
  };

  componentWillUnmount() {
    this.cancelUpload();
  }

  render() {
    const { selectedFile, isUploading, isPaused } = this.state;
    const { classes, uploadtype, uploadfile, filetoupload } = this.props;

    var fileaccept = "";

    if (uploadtype === "podcast") fileaccept = "audio/*";
    else if (uploadtype === "image") fileaccept = "image/*";
    else if (uploadtype === "avatar") fileaccept = "image/*";
    else if (uploadtype === "video") fileaccept = "video/*";
    else if (uploadtype === "file") fileaccept = "file/*";
    else if (uploadtype === "study_material") fileaccept = "file/*";
    else if (uploadtype === "attendance_register") fileaccept = "file/*";

    if (
      (selectedFile && !isUploading) ||
      (uploadfile && filetoupload && !isUploading)
    ) {
      this.handleUploadFiles();
    }

    return (
      <div>
        <input
          accept={fileaccept}
          className={classes.input}
          id="outlined-button-file"
          type="file"
          onChange={this.fileUploadHandler}
        />
        {isUploading ? (
          <div className={classes.alignUploadItems}>
            <div>
              <LinearProgress
                variant="determinate"
                value={this.state.progress}
              />
            </div>
            {!uploadfile && !filetoupload ? (
              <div>
                {selectedFile ? (
                  <div>
                    <div>
                      <Typography variant="body2">
                        {this.state.uploadStatus}
                      </Typography>
                    </div>
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      size="small"
                      className={classes.button}
                      onClick={this.cancelUpload}
                    >
                      <CancelIcon className={classes.leftIcon} />
                      Cancel
                    </Button>
                    {isPaused ? (
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        size="small"
                        className={classes.button}
                        onClick={this.resumeUpload}
                      >
                        Resume
                        <CloudUploadIcon className={classes.rightIcon} />
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="default"
                          component="span"
                          size="small"
                          className={classes.button}
                          onClick={this.pauseUpload}
                        >
                          <PauseIcon className={classes.leftIcon} />
                          Pause
                        </Button>
                      )}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : (
            <div>
              {!uploadfile ? (
                uploadtype === "avatar" ? (
                  <label htmlFor="outlined-button-file">
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      size="small"
                      className={classes.button}
                    >
                      <CloudUploadIcon className={classes.leftIcon} />
                      UPDATE AVATAR
                    </Button>
                  </label>
                ) : (
                    <label htmlFor="outlined-button-file">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        size="small"
                        className={classes.button}
                      >
                        <CloudUploadIcon className={classes.leftIcon} />
                        Upload {uploadtype === "study_material" ? "Study Material" : uploadtype === "attendance_register" ? "Attendance Register" : uploadtype}
                      </Button>
                      {uploadtype === "study_material" || uploadtype === "attendance_register" ? <span>.pdf or .docx documents</span> : null}
                    </label>

                  )
              ) : null}
            </div>
          )}
      </div>
    );
  }
}

UploadFiles.propTypes = {
  classes: PropTypes.object.isRequired
};

const UploadFilesStyled = withStyles(styles)(UploadFiles);

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UploadFilesStyled);


