import * as React from 'react';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';

import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
// const series = [
//     {
//       type: 'bar',
//       stack: '',
//       yAxisKey: 'eco',
//       data: [5, 6, 2, 8, 9],
//     },
//     {
//       type: 'line',
//       yAxisKey: 'pib',
//       color: 'red',
//       data: [1000, 1500, 3000, 5000, 10000],
//     },
//   ];

// const series = [
//   {
//     type: 'line',
//     yAxisKey: 'pib',
//     color: 'red',
//     data: [1, 2, 3, 5, 10],
//   },
// ];

// data: [1000, 1500, 3000, 5000, 10000],
// function convertDate(date) {
//   var day = date.getDate();
//   day = day < 10 ? "0" + day : day;
//   var month = date.getMonth() + 1;
//   month = month < 10 ? "0" + month : month;
//   var year = date.getFullYear();
//   return day + "." + month + "." + year;
// }


function convertDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

// export default function Combining(props) { console.log('in Combining', props) }

export default function Combining(props) {
  console.log('greentestDD 3 : ', props);
  let avex = [];
  if (props.ave.length > 0) {
    avex = props.ave;
  }
  else
  {
    avex = [];
    avex.length = 0;
  }
  console.log('greentestDD 4 : ');
 if (props.scores && props.scores.length > 4) {
  let scoresx = props.scores;

  if (avex.length>0) {
    avex = props.ave;
  }
  else
  {
    avex = [];
    avex.length = 0;
  }
  console.log('greentestDD 5 : ');
  let datesx = props.dates;
  console.log('Combining props d : ', props.dates);
  console.log('Combining props 0 : ', props.scores);
  console.log('greentestDD 5 : ', props.ave);
  //console.log('Combining props 1 : ', scoresx.length);

  console.log('Combining props 1 : ', props.scores.length);

  var scoresx1 = scoresx[scoresx.length - 5];
  var scoresx2 = scoresx[scoresx.length - 4];
  var scoresx3 = scoresx[scoresx.length - 3];
  var scoresx4 = scoresx[scoresx.length - 2];
  var scoresx5 = scoresx[scoresx.length - 1];

  if (avex.length > 0) {
   var ave1 = avex[avex.length - 5];
   var ave2 = avex[avex.length - 4];
   var ave3 = avex[avex.length - 3];
   var ave4 = avex[avex.length - 2];
   var ave5 = avex[avex.length - 1];
  }
  else
  {
    avex = [];
  }
  console.log('greentestDD 6 : ');
  var datesx1 = datesx[datesx.length - 5];
  var datesx2 = datesx[datesx.length - 4];
  var datesx3 = datesx[datesx.length - 3];
  var datesx4 = datesx[datesx.length - 2];
  var datesx5 = datesx[datesx.length - 1];

 // console.log('Combining props 2 : ', scoresxx);
   var dateSeries = ['1. '+convertDate(datesx1), '2. '+convertDate(datesx2), '3. '+convertDate(datesx3), '4. '+convertDate(datesx4), '5. '+convertDate(datesx5)];

  //var dateSeries = ['D1', 'D2', 'D3', 'D4', 'D5'];
  //var dateSeries = ['['+datesx1+']', '['+datesx2+']', '['+datesx3+']', '['+datesx4+']', '['+datesx5+']'];

  //var dateSeries = [datesx1, datesx2, datesx3, datesx4, datesx5];
  console.log('Combining props d2 : ', dateSeries);

  var series;

  if (avex.length > 0 ) {
    console.log('greentestDD 7 : ');
    series = [
      {
        type: 'line',
        yAxisKey: 'pib',
        color: 'red',
        data: [scoresx1, scoresx2, scoresx3, scoresx4, scoresx5]
      },
      {
        type: 'line',
        yAxisKey: 'pib',
        color: 'blue',
        data: [ave1, ave2, ave3, ave4, ave5]
      },
    ];
  }
  else{
    console.log('greentestDD 8 : ');
    series = [
      {
        type: 'line',
        yAxisKey: 'pib',
        color: 'red',
        data: [scoresx1, scoresx2, scoresx3, scoresx4, scoresx5]
      }
    ];
  }
  // var series = [
  //   {
  //     type: 'line',
  //     yAxisKey: 'pib',
  //     color: 'red',
  //     data: [scoresx1, scoresx2, scoresx3, scoresx4, scoresx5]
  //   },
  //   {
  //     type: 'line',
  //     yAxisKey: 'pib',
  //     color: 'blue',
  //     data: avex.length > 0 ? [ave1, ave2, ave3, ave4, ave5] : [0,0,0,0,0]
  //   },
  // ];
 }
 else{
  // var dateSeries = [2010, 2011, 2012, 2013, 2014];
  // //var dateSeries = ['D1', 'D2', 'D3', 'D4', 'D5'];
  //   var series = [
  //   {
  //     type: 'line',
  //     yAxisKey: 'pib',
  //     color: 'red',
  //     data: [5, 5, 5, 5, 5],
  //   },
  // ];
 }

if (props.scores && props.scores.length > 4) {
  return (
    <ChartContainer
      series={series}
      width={isMobile ? 350 : 500}
      height={200}
      xAxis={[
        {
          id: 'years',
         // data: [2010, 2011, 2012, 2013, 2014],
          data: dateSeries,
          scaleType: 'band',
          valueFormatter: (value) => value.toString(),
        },
      ]}
      yAxis={[
        {
          id: 'eco',
          scaleType: 'linear',
        },
        {
          id: 'pib',
          scaleType: 'log',
        },
      ]}
    >
      <BarPlot />
      <LinePlot />
      <ChartsXAxis label="Time" position="bottom" axisId="years" tickNumber="5" tickMinStep="10" tickLabelStyle={{fontSize: '10'}}/>
      {/* <ChartsYAxis label="Results" position="left" axisId="eco" /> */}
      <ChartsYAxis label="score" position="left" axisId="pib" />
    </ChartContainer>
  );
    }
    else 
    { return <div/>}
}

