/* eslint-disable no-inline-comments */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import { LIGHTGREY } from '../../../utils/color_utils'
import { fetchAllMyPLDPTasks } from '../../../store/actions/pldpActions'
import MyTasksListView from './MyTasksListView'
// import {
//   getOpinions
// } from '../../../store/actions/opinionActions'
// import { GET_O } from '../../../store/actions/types'

function includedCompetencies(tasks) {
  let competenciesFound = []
  Object.keys(tasks).map(id => {
    const a = competenciesFound.indexOf(tasks[id].moveAction)
    competenciesFound.push(tasks[id].moveAction)
  })
  return competenciesFound
}

Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

let groupTasks
const styles = theme => ({
  title: {
    borderBottom: `1px solid ${LIGHTGREY}`,
    fontSize: '15px',
    minHeight: '0px',
    width: 'auto',
    // color: '#fff',
    padding: '5px 10px',
    borderRadius: 5
    // backgroundColor: '#0085a5'
  },
  panel: {
    marginBottom: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
    // "&:hover": {
    //   boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)"
    // }
  }
})

class TasksListView extends Component {
  componentDidMount() {
    // console.log('taskslistview userdata : ', this.props.userdata)
    // if (this.props.userdata.userID) {
    //   console.log('statsstate T : ', this.props.userdata.userID) 
    //   this.props.getOpinions({ //loads the opinions of leaders recorded in state
    //     type: GET_O,
    //     leaderID: this.props.userdata.userID
    //   })
    //  } 
  }

  componentWillMount = () => {
    // this.fetchData();
  };

  fetchData = () => {
    this.props.fetchAllMyPLDPTasks({ userID: this.props.userdata.userID })
  };

  getTasksCount = (tasks) => {
    let i; let tasks_count = 0
    for (i = 0; i < tasks.length; i++) {
      tasks_count++
    }

    return tasks_count
  }

  render() {
    const { classes, companyValues, pldpAllMyTasks, competenciesFound, filterZZZ } = this.props
    // const competenciesFound = includedCompetencies(pldpAllMyTasks);
    groupTasks = Object.values(pldpAllMyTasks).groupBy('moveAction')
    delete groupTasks.undefined
    return (
      <div>
        <Grid container spacing={2}>
          {companyValues &&
            Object.keys(companyValues).map(id => {
              const labelId = `${companyValues[id].valueID}`
              const labelName = `${companyValues[id].valueDesc}`

              let tasksCount = 0
              if (groupTasks[labelId]) {
                tasksCount = this.getTasksCount(groupTasks[labelId])
              }

              // if (competenciesFound && (competenciesFound.indexOf(labelId) >= 0)) {
              if (labelName !== 'undefined') {
                return (
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanel expanded={true} className={classes.panel}>
                      <ExpansionPanelSummary>
                        <Typography
                          gutterBottom
                          variant='h5'
                          className={classes.title}>
                          {/* <FormatListBulletedIcon fontSize='small' />  */}
                          {labelName} ({tasksCount})
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <MyTasksListView
                          compid={labelId}
                          pldpAllMyTasks={pldpAllMyTasks}
                          editTask={this.props.editTask}
                          filterZZZ={filterZZZ}
                          //opinions={this.props.opinions}
                          completeTask={this.props.completeTask} />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                )
              }
              // }
            })}
        </Grid>
      </div>
    )
  }
}

TasksListView.propTypes = {
  classes: PropTypes.object.isRequired,
  userdata: PropTypes.any,
  companyValues: PropTypes.any
}

const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    opinions: state.opinions.opinion,
   // userdata: state.userDialog.userDetails[0]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // fetchCompanyValues: params => dispatch(getCompanyValues(params)),
    // deleteCompanyValue: params => dispatch(deleteCompanyValue(params)),
    fetchAllMyPLDPTasks: params => dispatch(fetchAllMyPLDPTasks(params)),
   // getOpinions: params => dispatch(getOpinions(params)),
    // updateNewPLDPTask: (task, edit) => dispatch(updateNewPLDPTask(task, edit)),
    // completeTask: params => dispatch(completeTask(params)),
  }
}

const TasksListViewStyled = withStyles(styles)(TasksListView)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksListViewStyled)
