import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { SupervisorAccount } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Follow from "../Buttons/Follow";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import { GREY, RED, LIGHTBLUE, LIGHTGREY } from "../../utils/color_utils";
import ViewUser from './ViewUser';
import ViewUser_gen from './ViewUser_gen';
import {
  COMPANY_ID,
  JOURNAL_USER_ID,
  USER_FOLLOWERS,
  USER_FOLLOWING
} from "../../store/actions/types";
import { fetchUserData } from "../../store/actions/userActions";
import { PHOTO_PROFILE } from "../../utils/photo_utils";
import {
  fetchUserBiography,
  openUserProfileDialog
} from "../../store/actions/userBiographyActions";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  followButton: {
    backgroundColor: "#fff",
    border: "1px solid #810029",
    color: "#810029",
    fontSize: "8pt !important",
    borderRadius: "17px",
    fontWeight: "500",
    margin: "5px 0",
    padding: "0 15px"
  },
  followingButton: {
    backgroundColor: "#1298f1",
    border: "1px solid #1298f1",
    color: "#ffffff",
    fontSize: "8pt !important",
    borderRadius: "17px",
    fontWeight: "500",
    margin: "5px 0",
    padding: "0 15px"
  },
  icon: {
    padding: "5px",
    fontSize: "30px"
  },
  textStyle: {
    border: `0px`,
    margin: "0 1px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 4px",
      padding: "0px 6px",
      width: "500px"
    }
  },
  heading: {
    display: "flex",
    flex: 1,
    textAlign: "center",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  colorBlue: {
    color: "#1298f1"
  },
  paperStyle: {
    margin: "8px 8px"
  },
  avatarClick: {
    cursor: "pointer"
  }
});

class MyFollowersFollowingList extends React.Component {
  constructor(props) {
    super(props);
  }
 state = {
    maxLengthThought: 0,
    namesearch: '',
    minData: 100,
    requirementKey: Math.random()
  };
  componentWillMount = () => {
    this.fetchData();
    console.log('App userdata componentWillMount: ', this.props.userdata);
  };

  fetchMoreData = () => {
    this.setState({
      minData: this.state.minData + 40
    }, function() {
      this.fetchData();
    });
  };

  fetchData = () => {
    var params = {
      type: USER_FOLLOWERS,
      companyID: this.props.userdata.companyID,
      userID: this.props.userdata.userID,
      minData: this.state.minData
    };
    var params1 = {
      type: USER_FOLLOWING,
      companyID: this.props.userdata.companyID,
      userID: this.props.userdata.userID,
      minData: this.state.minData
    };

    this.props.fetchUserData(params);

    this.props.fetchUserData(params1);
  };

  // openViewUserDialog = thought => {
  //   console.log("clicked");
  //   this.setState({
  //     open: !this.state.open
  //   });
  // };

  openViewUserDialog = post => {
    console.log('post params ', post);
    var params = {
      userID: post.id,
      userName: post.firstName,
      companyID: post.companyID,
      companyName: post.companyName,
      photoURL: PHOTO_PROFILE(post.photoURL),
      showprofiledlg: true
    };

    // Fetch user bio data
    this.props.fetchUserBiography({ ...params, type: "USER_BIOGRAPHY" });

    // Open user profile dialog
    this.props.openUserProfileDialog(params);
  };

  getNewKey = (x) => {
    var xy = x;
    console.log('inside getNewKey ', xy)
    this.setState({ requirementKey: Math.random() });
  };

  // getAvatarPic = (pic) => {
  //   var photo = "https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40";
  //   if ( pic.length > 0 ) {
  //    photo = PHOTO_PROFILE(pic);
  //   }
  //   return photo;
  // };

  // getUserInfo = (userID) => {
  //  let { users } = this.props
  //   this.props.getUserData({
  //     userID: userID
  //   })
    // let dataListClone = Object.keys(users).map((uid, index) => {
    //  // var ds = users[uid].dateRegistered;
    //   return [
    //     users[uid].firstName ? users[uid].firstName : '',
    //     users[uid].lastName ? users[uid].lastName : '',
    //     users[uid].biography ? users[uid].biography : '',
    //   ]
    // })
   //   return users //dataListClone
   // };

  render() {
    const {
      classes,
      className,
      followOrfollowing,
      userfollowers,
      userfollowing,
      followingData
    } = this.props;

    // const getNewKey = (x) => {
    //   var xy = x;
    //   console.log('green inside getNewKey ', xy)
    //   this.setState({ requirementKey: Math.random() });
    // };

    return (
      <div>
        <Typography variant="h6" className={classes.heading}>
          <SupervisorAccount className={classes.icon} />
          <DialogContent className={classes.textStyle}>
          {followOrfollowing === "followers" ? "Followers" : "Following"}
          </DialogContent>
          <DialogContent className={classes.textStyle}>
              <TextField
                //autoFocus={true}
                id="namesearch"
                label="Search"
                multiline
                //fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={event => {
                  const { value } = event.target;
                  var len = value.length;
                  this.setState({
                    maxLengthThought: len + "/140",
                    namesearch: value
                  });
                }}
                value={this.state.namesearch}
              />
              </DialogContent>
        </Typography>
        <Paper className={classes.paperStyle}>
          <List className={classes.root}>
            {followOrfollowing === "followers" ? (
              <div>
                {userfollowers &&
                  Object.keys(userfollowers).map(id => {
                    return (
                      <div>
                    { console.log('check avatar: ', userfollowers[id].photoURL)}
                     { userfollowers[id].firstName.includes(this.state.namesearch) 
                     || userfollowers[id].lastName.includes(this.state.namesearch) 
                     ? 
                     <ListItem alignItems="flex-start" divider={true} key={id}>
                        <ListItemAvatar>
                          {/* <Avatar 
                            alt={
                              userfollowers[id].firstName +
                              " " +
                              userfollowers[id].lastName
                            }
                            src={(userfollowers[id].photoURL.length > 0) ? PHOTO_PROFILE(userfollowers[id].photoURL) : PHOTO_PROFILE("https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40") }
                            className={classes.avatarClick} 
                           // onClick={(e) => this.openViewUserDialog(userfollowers[id])}
                          /> */}
                          {this.props.leaderboardData ?
                            <ViewUser_gen
                              key={this.state.requirementKey}
                              getNewKeyx={this.getNewKey}
                              src={(userfollowers[id].photoURL.length > 0) ? PHOTO_PROFILE(userfollowers[id].photoURL) : PHOTO_PROFILE("https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40") }
                              name={
                                userfollowers[id].firstName +
                                " " +
                                userfollowers[id].lastName
                              }
                              companyName={userfollowers[id].companyName}
                              leaderID={userfollowers[id].userID}
                              slaveID={this.props.userdata.userID}
                              companyID={this.props.userdata.companyID}
                              firstName={this.props.userdata.firstName}
                              lastName={this.props.userdata.lastName}
                             // slaveID={this.props.userdata.userID}
                              //leaderboardData={}
                              //onClick={this.getNewKey('2')}
                             // userData={this.getUserInfo(userfollowers[id].userID)}
                            />
                           :null}

                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              className={classes.colorBlue}
                              component="strong"
                              color="textPrimary"
                            >
                              {userfollowers[id].firstName +
                                " " +
                                userfollowers[id].lastName}
                            </Typography>
                          }
                          className={classes.colorBlue}
                          secondary={
                            <React.Fragment>
                              <Typography component="small" color="textPrimary">
                                {userfollowers[id].companyName}
                              </Typography>
                              <Follow
                                post={userfollowers[id]}
                                following={
                                  userfollowing
                                    ? followingData[userfollowers[id].userID]
                                      ? true
                                      : false
                                    : false
                                }
                              />
                            </React.Fragment>
                          }
                        />
                         
                      </ListItem>
                  :null}
                      </div>
                    );
                        
                  })}
                {userfollowers &&
                Object.keys(userfollowers).length <
                  this.props.useranalytics.followers ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px 0px 10px 0px"
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      size="small"
                      className={classes.button}
                      onClick={this.fetchMoreData.bind(this)}
                    >
                      Load More...
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                {userfollowing &&
                  Object.keys(userfollowing).map(id => {
                    return (

                      <div>
                       
                        { userfollowing[id].firstName.includes(this.state.namesearch) 
                        || userfollowing[id].lastName.includes(this.state.namesearch) 
                        ? 
                      <ListItem alignItems="flex-start" divider={true} key={id}>
                        <ListItemAvatar>
                          {/* <Avatar 
                            alt={
                              userfollowing[id].firstName +
                              " " +
                              userfollowing[id].lastName
                            }
                            src={PHOTO_PROFILE(userfollowing[id].photoURL)}
                            //src={this.getAvatarPic(id)}
                            className={classes.avatarClick}
                            onClick={() => this.openViewUserDialog(userfollowing[id])}
                          /> */}
                           {this.props.leaderboardData ?
                            <ViewUser_gen
                              key={this.state.requirementKey}
                              getNewKeyx={this.getNewKey}
                              src={(userfollowing[id].photoURL.length > 0) ? PHOTO_PROFILE(userfollowing[id].photoURL) : PHOTO_PROFILE("https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40") }
                              name={
                                userfollowing[id].firstName +
                                " " +
                                userfollowing[id].lastName
                              }
                              companyName={userfollowing[id].companyName}
                              leaderID={userfollowing[id].userID}
                              slaveID={this.props.userdata.userID}
                              companyID={this.props.userdata.companyID}
                              firstName={this.props.userdata.firstName}
                              lastName={this.props.userdata.lastName}
                              //onClick={this.getNewKey('2')}
                             // userData={this.getUserInfo(userfollowers[id].userID)}
                            /> 
                            :null}

                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              className={classes.colorBlue}
                              component="strong"
                              color="textPrimary"
                            >
                              {userfollowing[id].firstName +
                                " " +
                                userfollowing[id].lastName}
                            </Typography>
                          }
                          className={classes.colorBlue}
                          secondary={
                            <React.Fragment>
                              <Typography component="small" color="textPrimary">
                                {userfollowing[id].companyName}
                              </Typography>
                              <Follow
                                post={userfollowing[id]}
                                following={true}
                              />
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      :null}
                      </div>
                    );
                  })}

                {userfollowing &&
                Object.keys(userfollowing).length <
                  this.props.useranalytics.following ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px 0px 10px 0px"
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      size="small"
                      className={classes.button}
                      onClick={this.fetchMoreData.bind(this)}
                    >
                      Load More...
                    </Button>
                  </div>
                ) : null}
              </div>
            )}
          </List>
        </Paper>
      </div>
    );
  }
}

MyFollowersFollowingList.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  userfollowers: PropTypes.array,
  userfollowing: PropTypes.array,
  followOrfollowing: PropTypes.string.isRequired
};

const MyFollowersFollowing = withStyles(styles)(MyFollowersFollowingList);

const mapStateToProps = state => {
  return {
    userfollowers: state.userdata.userfollowers,
    userfollowing: state.userdata.userfollowing,
    followingData: state.userdata.followingButton,
    userdata: state.userDialog.userDetails[0],
    useranalytics: state.userdata.useranalytics,
    leaderboardData: state.users.leaderboardData
  };
};


const mapDispatchToProps = dispatch => {
  return {
    fetchUserBiography: params => dispatch(fetchUserBiography(params)),
    openUserProfileDialog: params => dispatch(openUserProfileDialog(params)),
    fetchUserData: params => dispatch(fetchUserData(params)),
    //getUserData: params => dispatch(getUserData(params))
  };
}

const MyFollowersFollowingObj = connect(
  mapStateToProps, mapDispatchToProps
)(MyFollowersFollowing);

class Following extends React.Component {
  render() {
    const { classes, option } = this.props;
    return (
      <React.Fragment>
        <MyFollowersFollowingObj followOrfollowing={option || "followers"} onViewUserDialog={this.openViewUserDialog} />
      </React.Fragment>
    );
  }
}

export default Following;