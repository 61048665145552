/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Grid, IconButton, Tooltip } from
'@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { LIGHTGREY, GREEN } from '../../../utils/color_utils'
import { showToast } from '../../../store/actions/toastAction'
import { fetchSubCompetencies } from '../../../store/actions/subcompetenciesActions'
import { SHOW_TOAST } from '../../../store/actions/types'

Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

function includedSubCompetencies(tasks) {
  let competenciesFound = []
  Object.keys(tasks).map(id => {
    const a = competenciesFound.indexOf(tasks[id].subcompetencyid)
    competenciesFound.push(tasks[id].subcompetencyid)
  })
  return competenciesFound
}

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 40 + rand()
const left = 50 + rand()

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '0 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 12px'
    }
  },
  rootdiv: {
    width: '100%'
  },
  icons: {
    cursor: 'pointer'
  },
  modal: {
    outline: 'none',
    border: 'none'
  }
})

class MyTasksListView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editText: '',
      editID: '',
      open: false,
      openConfirm: false
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    // // console.log("groupTasks obj: ", this.props.groupTasks)
  }

  componentWillMount = () => {
    this.fetchData()
  };

  fetchData = () => {
    this.props.fetchSubCompetencies({
      compid: this.props.compid
    })
  };

  editTask = (id, name, task) => e => {
    this.props.editTask(id, name, task)
  }

  completeTask = (id, name, task, active) => e => {
    let timeNow = new Date().getTime();
    let timePast = (timeNow - task.dateRegistered)/(86400000)

    console.log('green plfp completeTask 1 : ', task.moveActionDesc);
    let action = task.moveActionDesc.substr(0, 9);

    var pass = false;
    
    switch (active) {
      case true: //re-open task
        pass = true;
        break;
      case false: //complete task
      if (timePast > 29 || action === "Activity:") {pass=true}
        break;
    }

    if (pass) {
     this.props.completeTask(id, name, task, active)
     }
     else
     {
       this.props.showToast({
         type: 'SHOW_TOAST',
         open: true,
         variant: 'success',
         message: 'Tasks can only be ticked as completed after 30 days, to ensure integrity of changing behaviours and adopting new habits.'
       })
     }
  }

  render() {
    const { classes, compid, pldpAllMyTasks, subcompetencies_list, filterZZZ } = this.props
    const listTasks = pldpAllMyTasks
    const subCompetenciesFound = includedSubCompetencies(pldpAllMyTasks)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {subcompetencies_list[compid] && Object.keys(subcompetencies_list[compid]).map(id => {
            const labelId = `${subcompetencies_list[compid][id].id}`
            const labelNamex = `${subcompetencies_list[compid][id].name}`
            const labelName = filterZZZ(labelNamex)
            function completedStyle(active) {
              return {
                textDecoration: active ? 'none' : 'line-through'
              }
            }
            if (subCompetenciesFound && (subCompetenciesFound.indexOf(labelId) >= 0)) {
              return (
                <div className={classes.rootdiv}>
                  <List subheader={<ListSubheader style={{ backgroundColor: `${LIGHTGREY}`, fontWeight: 'bold', fontSize: '13px', lineHeight: '28px', color: '#000', borderRadius: '4px' }}>{labelName}</ListSubheader>} dense className={classes.root}>
                    {listTasks &&
                      Object.keys(listTasks).map(id => {
                        const labelTaskId = `${listTasks[id].myPLDPID}`
                        const labelTaskName = `${listTasks[id].title}`
                        if (labelTaskName !== 'undefined') {
                          if (listTasks[id].subcompetencyid === labelId) {
                            return (
                              <div style={completedStyle(listTasks[id].active)}>
                                <ListItem key={labelTaskId} button>
                                  <ListItemText id={labelTaskId} primary={`${labelTaskName}`} />
                                  <ListItemSecondaryAction>
                                    {listTasks[id].active ?
                                      <div>
                                        <Tooltip title='Edit'>
                                          <IconButton size='small' onClick={this.editTask(labelTaskId, labelTaskName, listTasks[id])} edge='end' aria-label='edit'>
                                            <EditIcon fontSize='small' />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Mark Complete'>
                                          <IconButton style={{ color: `${GREEN}` }} size='small' onClick={this.completeTask(labelTaskId, labelTaskName, listTasks[id], false)} edge='end' aria-label='complete'>
                                            <DoneIcon fontSize='small' />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                      : <Tooltip title='Reopen Task'>
                                        <IconButton style={{ color: `${GREEN}` }} size='small' onClick={this.completeTask(labelId, labelName, listTasks[id], true)} edge='end' aria-label='complete'>
                                          <PlaylistAddCheckIcon fontSize='small' />
                                        </IconButton>
                                      </Tooltip>}

                                  </ListItemSecondaryAction>
                                </ListItem>
                              </div>
                            )
                          }
                        }
                      })}

                  </List>

                </div>
              )
            }
          })}
        </Grid>
      </Grid>
    )
  }
}

MyTasksListView.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    subcompetencies_list: state.subcompetenciesList.subcompetencies_list
  }
}

const MyTasksListViewStyled = withStyles(styles)(MyTasksListView)

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    fetchSubCompetencies: params => dispatch(fetchSubCompetencies(params))
    // deleteSubCompetency: params => dispatch(deleteSubCompetency(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTasksListViewStyled)
