import { GET_CORP_TUTORIALS, GET_PUBLIC_TUTORIALS } from './../actions/types';

const initialState = {
	tutorials: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CORP_TUTORIALS:
			return {
				...state,
				tutorials: action.tutorials
			};
		case GET_PUBLIC_TUTORIALS:
			return {
				...state,
				tutorials: action.tutorials
			};
		default:
			return state;
	}
};
