import React from "react";

class Button extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var answers = this.props.answerArray.map(item => {
      return <button onClick>Answer1: {item}</button>;
    });
    return <div>{answers}</div>;
  }
}

export default Button;
