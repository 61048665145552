import React, { Component } from "react";
import { List } from "@material-ui/core";

// Custom Components
import Comment from "./Comment";
import { _toArray } from "./../../store/actions/commonActions";
class CommentList extends Component {
  sortByDate = comments => {
    var sortedData = _toArray(comments);
    sortedData.sort(function(a, b) {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      return 0;
    });
    return sortedData;
  };
  render() {
    const { classes, post } = this.props;
    return (
      <List className={classes.comments}>
        {post.comments ? (
          this.sortByDate(post.comments).map((comment, i) => (
            <Comment
              key={i}
              comment={comment}
              commentID={comment.commentID}
              {...this.props}
            />
          ))
        ) : (
           <center>No Comments</center>
        )}
      </List>
    );
  }
}

CommentList.propTypes = {};

export default CommentList;
