import {
  USER_ANALYTICS,
  USER_FOLLOWERS,
  USER_FOLLOWING,
  USER_NOTIFICATIONS,
  USER_PLDP_NOTIFICATIONS,
  USER_GROUP_NOTIFICATIONS,
  GLP_LOGGED_PROFILE,
  GET_UNSEEN_NOTIFICATIONS,
  GET_UNSEEN_GROUP_NOTIFICATIONS,
  SET_SEEN_GROUP_NOTIFICATIONS,
  GET_UNSEEN_PLDP_NOTIFICATIONS,
  SET_SEEN_NOTIFICATIONS,
  SET_SEEN_PLDP_NOTIFICATIONS,
  UPDATE_MY_FOLLOWERS,
  SEEN_NOTIFICATIONS,
  PUBLIC_COMPANY_ID
} from "./types";
import firebase from "../firebase";
import {
  _objLen,
  _toArrayRemoveTrue,
  _toArrayRemoveTrueFollow
} from "./commonActions";

export const fetchUserData = params => {
  switch (params.type) {
    
    case USER_ANALYTICS:
      return (dispatch, getState) => {
        let userObj = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
        if (userObj) {
          firebase.database
            .ref(`users/${userObj.userID}/analytics`)
            .on("value", function(data) {
              if (data.val()) {
                dispatch({
                  type: USER_ANALYTICS,
                  useranalytics: data.val()
                });
              } else {
                dispatch({
                  type: USER_ANALYTICS,
                  useranalytics: {}
                });
              }
            });
        }
      };
    case UPDATE_MY_FOLLOWERS:
      return (dispatch, getState) => {
        firebase.database
          .ref("users")
          .child(params.userID)
          .child("following")
          .on("value", data => {
            var no_following = _objLen(data.val());

            firebase.database
              .ref("/users/" + params.userID)
              .child("analytics")
              .child("following")
              .set(no_following);
          });

        firebase.database
          .ref("users")
          .child(params.userID)
          .child("follower")
          .on("value", data => {
            var no_followers = _objLen(data.val());

            firebase.database
              .ref("/users/" + params.userID)
              .child("analytics")
              .child("followers")
              .set(no_followers);
          });

        dispatch({
          type: "NO_DATA"
        });
      };
    case USER_FOLLOWERS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/follower`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data[undefined];
              var sortedData = _toArrayRemoveTrueFollow(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.dateRegistered);
                b = new Date(b.dateRegistered);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });

              sortedData = sortedData.filter(function(element) {
                return element !== undefined;
              });

              dispatch({
                type: USER_FOLLOWERS,
                userfollowers: sortedData
              });
            } else {
              dispatch({
                type: "USER_FOLLOWERS",
                userfollowers: {}
              });
            }
          });
      };
    case USER_FOLLOWING:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/following`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data[undefined];
              var sortedData = _toArrayRemoveTrueFollow(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.dateRegistered);
                b = new Date(b.dateRegistered);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });

              sortedData = sortedData.filter(function(element) {
                return element !== undefined;
              });
              dispatch({
                type: USER_FOLLOWING,
                userfollowing: sortedData
              });
            } else {
              dispatch({
                type: USER_FOLLOWING,
                userfollowing: {}
              });
            }
          });
      };
    case "FOLLOWING_BUTTON":
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/following`)
          .on("value", function(data) {
            if (data.val()) {
              dispatch({
                type: "FOLLOWING_BUTTON",
                followingButton: data.val()
              });
            } else {
              dispatch({
                type: "FOLLOWING_BUTTON",
                followingButton: {}
              });
            }
          });
      };
    case GET_UNSEEN_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`group-notifications/${params.userID}`)
          .orderByChild("seen")
          .equalTo(false)
          .on("value", function(data) {
            var unseengroupcounts = {};

            if (data.val()) {
              var sortedData = _toArrayRemoveTrue(data.val());
              unseengroupcounts.unseengroupnotifications = sortedData;
              unseengroupcounts.unseengroupnotificationscnt = sortedData.length || 0;
              
              for (var x in sortedData) {
                if(sortedData[x].groupid !== undefined){
                  unseengroupcounts[sortedData[x].groupid] = (unseengroupcounts[sortedData[x].groupid] || 0 ) + 1
                }
              }

              dispatch({
                type: GET_UNSEEN_GROUP_NOTIFICATIONS,
                unseengroupnotifications: unseengroupcounts
              });
            } else {
              var unseengroupcounts = {
                unseengroupnotifications: {},
                unseengroupnotificationscnt: 0
              };

              dispatch({
                type: GET_UNSEEN_GROUP_NOTIFICATIONS,
                unseengroupnotifications: unseengroupcounts
              });
            }
          });
      };
    case GET_UNSEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/notifications`)
          .orderByChild("seen")
          .equalTo(false)
          .on("value", function(data) {
            var unseencounts = {
              dailythoughts: 0,
              companythoughts: 0,
              topleaderthoughts: 0,
              ileadcorporate: 0,
              ileadpublic: 0,
              podcasts: 0,
              articles: 0,
              videos: 0,
              unseenotifications: {},
              unseenotificationscnt: 0
            };

            if (data.val()) {
              var sortedData = _toArrayRemoveTrue(data.val());
              unseencounts.unseenotifications = sortedData;
              unseencounts.unseenotificationscnt = sortedData.length || 0;
              for (var x in sortedData) {
                switch (sortedData[x].notificationType) {
                  case "thought":
                    if (sortedData[x].thoughtType == "dailythoughts") {
                      unseencounts.dailythoughts++;
                    } else if (sortedData[x].thoughtType == "companythoughts") {
                      if (sortedData[x].companyID !== PUBLIC_COMPANY_ID) {
                        unseencounts.companythoughts++;
                      } else {
                        unseencounts.ileadpublic++;
                      }
                    } else if (
                      sortedData[x].thoughtType == "topleaderthoughts"
                    ) {
                      unseencounts.topleaderthoughts++;
                    } else if (sortedData[x].thoughtType == "ileadcorporate") {
                      unseencounts.ileadcorporate++;
                    } else if (sortedData[x].thoughtType == "ileadpublic") {
                      unseencounts.ileadpublic++;
                    }
                    break;
                  case "podcast":
                    unseencounts.podcasts++;
                    break;
                  case "article":
                    unseencounts.articles++;
                    break;
                  case "video":
                    unseencounts.videos++;
                    break;

                  default:
                    break;
                }
              }

              dispatch({
                type: GET_UNSEEN_NOTIFICATIONS,
                unseenotifications: unseencounts
              });
            } else {
              var unseencounts = {
                dailythoughts: 0,
                companythoughts: 0,
                topleaderthoughts: 0,
                ileadcorporate: 0,
                ileadpublic: 0,
                podcasts: 0,
                articles: 0,
                videos: 0,
                unseenotifications: {},
                unseenotificationscnt: 0
              };

              dispatch({
                type: GET_UNSEEN_NOTIFICATIONS,
                unseenotifications: unseencounts
              });
            }
          });
      };
    case GET_UNSEEN_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/pldpnotifications`)
          .orderByChild("seen")
          .equalTo(false)
          .on("value", function(data) {
            var unseenpldpcounts = {
              pldpnotifications: 0,
              unseenpldpnotifications: {}
            };
            if (data.val()) {
              var sortedData = _toArrayRemoveTrue(data.val());
              unseenpldpcounts = {
                pldpnotifications: sortedData.length || 0,
                unseenpldpnotifications: sortedData
              };

              dispatch({
                type: GET_UNSEEN_PLDP_NOTIFICATIONS,
                unseenpldpnotifications: unseenpldpcounts
              });
            } else {
              var unseenpldpcounts = {
                pldpnotifications: 0,
                unseenpldpnotifications: {}
              };

              dispatch({
                type: GET_UNSEEN_PLDP_NOTIFICATIONS,
                unseenpldpnotifications: unseenpldpcounts
              });
            }
          });
      };
    case SET_SEEN_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;

        for (var x in params.unseengroupnotifications) {
          if (
            params.unseengroupnotifications[x].groupid == params.groupid
          ) {
            myNotifications[
              `group-notifications/${params.userID}/${params.unseengroupnotifications[x].key}/seen`
            ] = true;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_GROUP_NOTIFICATIONS
        });
      };
    case SET_SEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;

        for (var x in params.unseenotifications) {
          switch (params.unseenotifications[x].notificationType) {
            case "thought":
              if (
                params.unseenotifications[x].thoughtType == "dailythoughts" &&
                page == "/daily"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }
              if (
                params.unseenotifications[x].thoughtType == "companythoughts" &&
                page == "/organisational"
              ) {
                if (params.unseenotifications[x].companyID !== PUBLIC_COMPANY_ID) {
                  myNotifications[
                    "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                  ] = true;
                }
              }
              if (
                params.unseenotifications[x].thoughtType ==
                "topleaderthoughts" &&
                page == "/globalcontributors"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              if (
                params.unseenotifications[x].thoughtType == "ileadcorporate" &&
                page == "/ilead"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              if (
                params.unseenotifications[x].thoughtType == "ileadpublic" &&
                page == "/ilead"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }
              if (params.unseenotifications[x].thoughtType == "companythoughts" &&
                page == "/ilead"
              ) {
                if (params.unseenotifications[x].companyID === PUBLIC_COMPANY_ID) {
                  myNotifications[
                    "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                  ] = true;
                }
              }
              break;
            case "podcast":
              if (page == "/podcasts") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;
            case "article":
              if (page == "/articles") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;
            case "video":
              if (page == "/videos") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;

            default:
              break;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_NOTIFICATIONS
        });
      };
    case SET_SEEN_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;
        for (var x in params.unseenpldpnotifications) {
          if (page == "/pldp") {
            myNotifications[
              "/users/" +
                params.userID +
                "/pldpnotifications/" +
                params.unseenpldpnotifications[x].key +
                "/seen"
            ] = true;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_PLDP_NOTIFICATIONS
        });
      };
    case USER_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/notifications`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });
              dispatch({
                type: USER_NOTIFICATIONS,
                usernotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_NOTIFICATIONS,
                  usernotifications: {}
                });
              };
            }
          });
      };
    case USER_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`group-notifications/${params.userID}`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);

                if (a > b) return -1;
                if (a < b) return 1;
                return 0;
              });
              dispatch({
                type: USER_GROUP_NOTIFICATIONS,
                groupnotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_GROUP_NOTIFICATIONS,
                  groupnotifications: {}
                });
              };
            }
          });
      };
    case SEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        // Write the new thought's data
        var updates = {};
        updates[
          "/users/" +
            params.userID +
            `/${params.notificationType}/` +
            params.notificationID +
            "/seen"
        ] = true;
        firebase.database.ref().update(updates);

        dispatch({
          type: SEEN_NOTIFICATIONS
        });
      };
    case USER_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/pldpnotifications`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });
              dispatch({
                type: USER_PLDP_NOTIFICATIONS,
                userpldpnotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_PLDP_NOTIFICATIONS,
                  userpldpnotifications: {}
                });
              };
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: "NO_DATA",
          useranalytics: {},
          userfollowers: {},
          userfollowing: {}
        });
      };
  }
};

export const fetchUserData_con = params => {
  console.log('green fetchUserDate_con : ', params)
  switch (params.type) {
    case USER_ANALYTICS:
      return (dispatch, getState) => {
          firebase.database
          .ref(`user/${params.uid}`)
          .once('value')
          .then(snapshot => { 
     ///////ANALYTICS///////////////////       
              var ana = snapshot.val().analytics;
              if (ana) {
                dispatch({
                  type: USER_ANALYTICS,
                  useranalytics: ana
                });
              } else {
                dispatch({
                  type: USER_ANALYTICS,
                  useranalytics: {}
                });
              }
      ///////GET_UNSEEN_GROUP_NOTIFICATIONS////////
      var ugn = snapshot.val().unseengroupcounts;
        if (ugn) {
          dispatch({
            type: GET_UNSEEN_GROUP_NOTIFICATIONS,
            unseengroupnotifications: ugn
          });
        } else {
          dispatch({
            type: GET_UNSEEN_GROUP_NOTIFICATIONS,
            unseengroupnotifications: {}
          });
        }

      ///////////////GET_UNSEEN_NOTIFICATIONS
      var unsc = snapshot.val().unseencounts;
        var unseencounts = {
          dailythoughts: 0,
          companythoughts: 0,
          topleaderthoughts: 0,
          ileadcorporate: 0,
          ileadpublic: 0,
          podcasts: 0,
          articles: 0,
          videos: 0,
          unseenotifications: {},
          unseenotificationscnt: 0
        };

        if (unsc) {
          unseencounts = unsc;
        }
        else
        {
          unseencounts = {
            dailythoughts: 0,
            companythoughts: 0,
            topleaderthoughts: 0,
            ileadcorporate: 0,
            ileadpublic: 0,
            podcasts: 0,
            articles: 0,
            videos: 0,
            unseenotifications: {},
            unseenotificationscnt: 0
          };
        }
          dispatch({
            type: GET_UNSEEN_NOTIFICATIONS,
            unseenotifications: unseencounts
          });

      //////////////GET_UNSEEN_PLDP_NOTIFICATIONS
      var unspldpc = snapshot.val().pldpnotifications;
        var unseenpldpcounts = {
          pldpnotifications: 0,
          unseenpldpnotifications: {}
        };
        if (unspldpc) {
          unseenpldpcounts = unspldpc;
         // var sortedData = unspldpc;
          // unseenpldpcounts = {
          //   pldpnotifications: 0,
          //   unseenpldpnotifications: sortedData
          // };

          dispatch({
            type: GET_UNSEEN_PLDP_NOTIFICATIONS,
            unseenpldpnotifications: unseenpldpcounts
          });
        } else {
          var unseenpldpcounts = {
            pldpnotifications: 0,
            unseenpldpnotifications: {}
          };

          dispatch({
            type: GET_UNSEEN_PLDP_NOTIFICATIONS,
            unseenpldpnotifications: unseenpldpcounts
          });
        }
    //  ////FOLLOWING_BUTTON/////////////////////////
     // var follow = snapshot.val().following;
      if(params.following) {
        var follow = params.following;
      }
      else {
        var follow = {};
      }

        if (follow) {
          dispatch({
            type: "FOLLOWING_BUTTON",
            followingButton: follow
          });
        } else {
          dispatch({
            type: "FOLLOWING_BUTTON",
            followingButton: follow
          });
        }
      ////////////////////////////////END

      });
    };
    case UPDATE_MY_FOLLOWERS:
      return (dispatch, getState) => {
        firebase.database
          .ref("users")
          .child(params.userID)
          .child("following")
          .on("value", data => {
            var no_following = _objLen(data.val());

            firebase.database
              .ref("/users/" + params.userID)
              .child("analytics")
              .child("following")
              .set(no_following);
          });

        firebase.database
          .ref("users")
          .child(params.userID)
          .child("follower")
          .on("value", data => {
            var no_followers = _objLen(data.val());

            firebase.database
              .ref("/users/" + params.userID)
              .child("analytics")
              .child("followers")
              .set(no_followers);
          });

        dispatch({
          type: "NO_DATA"
        });
      };
    case USER_FOLLOWERS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/follower`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data[undefined];
              var sortedData = _toArrayRemoveTrueFollow(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.dateRegistered);
                b = new Date(b.dateRegistered);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });

              sortedData = sortedData.filter(function(element) {
                return element !== undefined;
              });

              dispatch({
                type: USER_FOLLOWERS,
                userfollowers: sortedData
              });
            } else {
              dispatch({
                type: "USER_FOLLOWERS",
                userfollowers: {}
              });
            }
          });
      };
    case USER_FOLLOWING:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/following`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data[undefined];
              var sortedData = _toArrayRemoveTrueFollow(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.dateRegistered);
                b = new Date(b.dateRegistered);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });

              sortedData = sortedData.filter(function(element) {
                return element !== undefined;
              });
              dispatch({
                type: USER_FOLLOWING,
                userfollowing: sortedData
              });
            } else {
              dispatch({
                type: USER_FOLLOWING,
                userfollowing: {}
              });
            }
          });
      };
 
    case SET_SEEN_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;

        for (var x in params.unseengroupnotifications) {
          if (
            params.unseengroupnotifications[x].groupid == params.groupid
          ) {
            myNotifications[
              `group-notifications/${params.userID}/${params.unseengroupnotifications[x].key}/seen`
            ] = true;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_GROUP_NOTIFICATIONS
        });
      };

    case SET_SEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;
        for (var x in params.unseenotifications) {
          switch (params.unseenotifications[x].notificationType) {
            case "thought":
              if (
                params.unseenotifications[x].thoughtType == "dailythoughts" &&
                page == "/daily"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }
              if (
                params.unseenotifications[x].thoughtType == "companythoughts" &&
                page == "/organisational"
              ) {
                if (params.unseenotifications[x].companyID !== PUBLIC_COMPANY_ID) {
                  myNotifications[
                    "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                  ] = true;
                }
              }
              if (
                params.unseenotifications[x].thoughtType ==
                "topleaderthoughts" &&
                page == "/globalcontributors"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              if (
                params.unseenotifications[x].thoughtType == "ileadcorporate" &&
                page == "/ilead"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              if (
                params.unseenotifications[x].thoughtType == "ileadpublic" &&
                page == "/ilead"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }
              if (params.unseenotifications[x].thoughtType == "companythoughts" &&
                page == "/ilead"
              ) {
                if (params.unseenotifications[x].companyID === PUBLIC_COMPANY_ID) {
                  myNotifications[
                    "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                  ] = true;
                }
              }
              break;
            case "podcast":
              if (page == "/podcasts") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;
            case "article":
              if (page == "/articles") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;
            case "video":
              if (page == "/videos") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;

            default:
              break;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_NOTIFICATIONS
        });
      };
    case SET_SEEN_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;
        for (var x in params.unseenpldpnotifications) {
          if (page == "/pldp") {
            myNotifications[
              "/users/" +
              params.userID +
              "/pldpnotifications/" +
              params.unseenpldpnotifications[x].key +
              "/seen"
            ] = true;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_PLDP_NOTIFICATIONS
        });
      };
    case USER_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/notifications`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });
              dispatch({
                type: USER_NOTIFICATIONS,
                usernotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_NOTIFICATIONS,
                  usernotifications: {}
                });
              };
            }
          });
      };
    case USER_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`group-notifications/${params.userID}`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);

                if (a > b) return -1;
                if (a < b) return 1;
                return 0;
              });
              dispatch({
                type: USER_GROUP_NOTIFICATIONS,
                groupnotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_GROUP_NOTIFICATIONS,
                  groupnotifications: {}
                });
              };
            }
          });
      };
    case SEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        // Write the new thought's data
        var updates = {};
        updates[
          "/users/" +
          params.userID +
          `/${params.notificationType}/` +
          params.notificationID +
          "/seen"
        ] = true;
        firebase.database.ref().update(updates);

        dispatch({
          type: SEEN_NOTIFICATIONS
        });
      };
    case USER_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/pldpnotifications`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });
              dispatch({
                type: USER_PLDP_NOTIFICATIONS,
                userpldpnotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_PLDP_NOTIFICATIONS,
                  userpldpnotifications: {}
                });
              };
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: "NO_DATA",
          useranalytics: {},
          userfollowers: {},
          userfollowing: {}
        });
      };
  }
};

export const fetchUserData_users = params => {
  switch (params.type) {
    
    case USER_ANALYTICS:
      return (dispatch, getState) => {
        let userObj = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
        if (userObj) {
          firebase.database
            .ref(`users/${userObj.userID}/analytics`)
            .on("value", function(data) {
              if (data.val()) {
                dispatch({
                  type: USER_ANALYTICS,
                  useranalytics: data.val()
                });
              } else {
                dispatch({
                  type: USER_ANALYTICS,
                  useranalytics: {}
                });
              }
            });
        }
      };
    case UPDATE_MY_FOLLOWERS:
      return (dispatch, getState) => {
        firebase.database
          .ref("users")
          .child(params.userID)
          .child("following")
          .on("value", data => {
            var no_following = _objLen(data.val());

            firebase.database
              .ref("/users/" + params.userID)
              .child("analytics")
              .child("following")
              .set(no_following);
          });
       
        firebase.database
          .ref("users")
          .child(params.userID)
          .child("follower")
          .on("value", data => {
            var no_followers = _objLen(data.val());

            firebase.database
              .ref("/users/" + params.userID)
              .child("analytics")
              .child("followers")
              .set(no_followers);
          });

        dispatch({
          type: "NO_DATA"
        });
      };
    case USER_FOLLOWERS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/follower`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data[undefined];
              var sortedData = _toArrayRemoveTrueFollow(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.dateRegistered);
                b = new Date(b.dateRegistered);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });

              sortedData = sortedData.filter(function(element) {
                return element !== undefined;
              });

              dispatch({
                type: USER_FOLLOWERS,
                userfollowers: sortedData
              });
            } else {
              dispatch({
                type: "USER_FOLLOWERS",
                userfollowers: {}
              });
            }
          });
      };
    case USER_FOLLOWING:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/following`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data[undefined];
              var sortedData = _toArrayRemoveTrueFollow(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.dateRegistered);
                b = new Date(b.dateRegistered);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });

              sortedData = sortedData.filter(function(element) {
                return element !== undefined;
              });
              dispatch({
                type: USER_FOLLOWING,
                userfollowing: sortedData
              });
            } else {
              dispatch({
                type: USER_FOLLOWING,
                userfollowing: {}
              });
            }
          });
      };
    case "FOLLOWING_BUTTON":
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/following`)
          .on("value", function(data) {
            if (data.val()) {
              dispatch({
                type: "FOLLOWING_BUTTON",
                followingButton: data.val()
              });
            } else {
              dispatch({
                type: "FOLLOWING_BUTTON",
                followingButton: {}
              });
            }
          });
      };
    case GET_UNSEEN_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`group-notifications/${params.userID}`)
          .orderByChild("seen")
          .equalTo(false)
          .on("value", function(data) {
            var unseengroupcounts = {};

            if (data.val()) {
              var sortedData = _toArrayRemoveTrue(data.val());
              unseengroupcounts.unseengroupnotifications = sortedData;
              unseengroupcounts.unseengroupnotificationscnt = sortedData.length || 0;
              
              for (var x in sortedData) {
                if(sortedData[x].groupid !== undefined){
                  unseengroupcounts[sortedData[x].groupid] = (unseengroupcounts[sortedData[x].groupid] || 0 ) + 1
                }
              }

              dispatch({
                type: GET_UNSEEN_GROUP_NOTIFICATIONS,
                unseengroupnotifications: unseengroupcounts
              });
            } else {
              var unseengroupcounts = {
                unseengroupnotifications: {},
                unseengroupnotificationscnt: 0
              };

              dispatch({
                type: GET_UNSEEN_GROUP_NOTIFICATIONS,
                unseengroupnotifications: unseengroupcounts
              });
            }
          });
      };
    case GET_UNSEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/notifications`)
          .orderByChild("seen")
          .equalTo(false)
          .on("value", function(data) {
            var unseencounts = {
              dailythoughts: 0,
              companythoughts: 0,
              topleaderthoughts: 0,
              ileadcorporate: 0,
              ileadpublic: 0,
              podcasts: 0,
              articles: 0,
              survey: 0,
              videos: 0,
              unseenotifications: {},
              unseenotificationscnt: 0
            };

            if (data.val()) {
              let sortedData = _toArrayRemoveTrue(data.val())
              unseencounts.unseenotifications = sortedData
              unseencounts.unseenotificationscnt = sortedData.length || 0

              for (let x in sortedData) {
                switch (sortedData[x].notificationType) {
                  case 'thought':
                    if (sortedData[x].thoughtType == 'dailythoughts') {
                      unseencounts.dailythoughts++
                    } else if (sortedData[x].thoughtType == 'companythoughts') {
                      if (sortedData[x].companyID !== PUBLIC_COMPANY_ID) {
                        unseencounts.companythoughts++
                      } else {
                        unseencounts.ileadpublic++
                      }
                    } else if (
                      sortedData[x].thoughtType == 'topleaderthoughts'
                    ) {
                      unseencounts.topleaderthoughts++
                    } else if (sortedData[x].thoughtType == 'ileadcorporate') {
                      unseencounts.ileadcorporate++
                    } else if (sortedData[x].thoughtType == 'ileadpublic') {
                      unseencounts.ileadpublic++
                    }
                    break
                  case 'podcast':
                    unseencounts.podcasts++
                    break
                  case 'article':
                    unseencounts.articles++
                    break
                  case 'video':
                    unseencounts.videos++
                    break
                  case 'survey':
                    unseencounts.survey++
                    break

                  default:
                    break
                }
              }

              dispatch({
                type: GET_UNSEEN_NOTIFICATIONS,
                unseenotifications: unseencounts
              });
            } else {
              var unseencounts = {
                dailythoughts: 0,
                companythoughts: 0,
                topleaderthoughts: 0,
                ileadcorporate: 0,
                ileadpublic: 0,
                podcasts: 0,
                articles: 0,
                videos: 0,
                survey: 0,
                unseenotifications: {},
                unseenotificationscnt: 0
              }

              dispatch({
                type: GET_UNSEEN_NOTIFICATIONS,
                unseenotifications: unseencounts
              });
            }
          });
      };
    case GET_UNSEEN_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/pldpnotifications`)
          .orderByChild("seen")
          .equalTo(false)
          .on("value", function(data) {
            var unseenpldpcounts = {
              pldpnotifications: 0,
              unseenpldpnotifications: {}
            };
            if (data.val()) {
              var sortedData = _toArrayRemoveTrue(data.val());
              unseenpldpcounts = {
                pldpnotifications: sortedData.length || 0,
                unseenpldpnotifications: sortedData
              };

              dispatch({
                type: GET_UNSEEN_PLDP_NOTIFICATIONS,
                unseenpldpnotifications: unseenpldpcounts
              });
            } else {
              var unseenpldpcounts = {
                pldpnotifications: 0,
                unseenpldpnotifications: {}
              };

              dispatch({
                type: GET_UNSEEN_PLDP_NOTIFICATIONS,
                unseenpldpnotifications: unseenpldpcounts
              });
            }
          });
      };
    case SET_SEEN_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;

        for (var x in params.unseengroupnotifications) {
          if (
            params.unseengroupnotifications[x].groupid == params.groupid
          ) {
            myNotifications[
              `group-notifications/${params.userID}/${params.unseengroupnotifications[x].key}/seen`
            ] = true;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_GROUP_NOTIFICATIONS
        });
      };
    case SET_SEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;

        for (var x in params.unseenotifications) {
          switch (params.unseenotifications[x].notificationType) {
            case "thought":
              if (
                params.unseenotifications[x].thoughtType == "dailythoughts" &&
                page == "/daily"
              ) {
                myNotifications[
                  "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                ] = true;
              } 
              if (
                params.unseenotifications[x].thoughtType == "companythoughts" &&
                page == "/organisational"
              ) {
                if (params.unseenotifications[x].companyID !== PUBLIC_COMPANY_ID) {
                  myNotifications[
                    "/users/" +
                      params.userID +
                      "/notifications/" +
                      params.unseenotifications[x].key +
                      "/seen"
                  ] = true;
                }
              } 
              if (
                params.unseenotifications[x].thoughtType ==
                  "topleaderthoughts" &&
                page == "/globalcontributors"
              ) {
                myNotifications[
                  "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                ] = true;
              } 
              
              if (
                params.unseenotifications[x].thoughtType == "ileadcorporate" &&
                page == "/ilead"
              ) {
                myNotifications[
                  "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                ] = true;
              } 
              
              if (
               params.unseenotifications[x].thoughtType == "ileadpublic" &&
                page == "/ilead"
              ) {
                  myNotifications[
                    "/users/" +
                      params.userID +
                      "/notifications/" +
                      params.unseenotifications[x].key +
                      "/seen"
                  ] = true;
              } 
              if (params.unseenotifications[x].thoughtType == "companythoughts" &&
                 page == "/ilead"
               ) {
                 if (params.unseenotifications[x].companyID === PUBLIC_COMPANY_ID) {
                   myNotifications[
                     "/users/" +
                       params.userID +
                       "/notifications/" +
                       params.unseenotifications[x].key +
                       "/seen"
                   ] = true;
                 }
               }
              break;
            case "podcast":
              if (page == "/podcasts") {
                myNotifications[
                  "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                ] = true;
              }

              break;
            case "article":
              if (page == "/articles") {
                myNotifications[
                  "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                ] = true;
              }

              break;
            case "video":
              if (page == "/videos") {
                myNotifications[
                  "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                ] = true;
              }

              break;

            default:
              break;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_NOTIFICATIONS
        });
      };
    case SET_SEEN_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;
        for (var x in params.unseenpldpnotifications) {
          if (page == "/pldp") {
            myNotifications[
              "/users/" +
                params.userID +
                "/pldpnotifications/" +
                params.unseenpldpnotifications[x].key +
                "/seen"
            ] = true;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_PLDP_NOTIFICATIONS
        });
      };
    case USER_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/notifications`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });
              dispatch({
                type: USER_NOTIFICATIONS,
                usernotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_NOTIFICATIONS,
                  usernotifications: {}
                });
              };
            }
          });
      };
    case USER_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`group-notifications/${params.userID}`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);

                if (a > b) return -1;
                if (a < b) return 1;
                return 0;
              });
              dispatch({
                type: USER_GROUP_NOTIFICATIONS,
                groupnotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_GROUP_NOTIFICATIONS,
                  groupnotifications: {}
                });
              };
            }
          });
      };
    case SEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        // Write the new thought's data
        var updates = {};
        updates[
          "/users/" +
            params.userID +
            `/${params.notificationType}/` +
            params.notificationID +
            "/seen"
        ] = true;
        firebase.database.ref().update(updates);

        dispatch({
          type: SEEN_NOTIFICATIONS
        });
      };
    case USER_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/pldpnotifications`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });
              dispatch({
                type: USER_PLDP_NOTIFICATIONS,
                userpldpnotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_PLDP_NOTIFICATIONS,
                  userpldpnotifications: {}
                });
              };
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: "NO_DATA",
          useranalytics: {},
          userfollowers: {},
          userfollowing: {}
        });
      };
  }
};

export const fetchUserData_xx = params => {
  switch (params.type) {
    case USER_ANALYTICS:
      return (dispatch, getState) => {
          firebase.database
            .ref(`user/${params.uid}/analytics`)
            .on("value", function(data) {
              if (data.val()) {
                dispatch({
                  type: USER_ANALYTICS,
                  useranalytics: data.val()
                });
              } else {
                dispatch({
                  type: USER_ANALYTICS,
                  useranalytics: {}
                });
              }
            });
       // }
      };
    case UPDATE_MY_FOLLOWERS:
      return (dispatch, getState) => {
        firebase.database
          .ref("users")
          .child(params.userID)
          .child("following")
          .on("value", data => {
            var no_following = _objLen(data.val());

            firebase.database
              .ref("/users/" + params.userID)
              .child("analytics")
              .child("following")
              .set(no_following);
          });

        firebase.database
          .ref("users")
          .child(params.userID)
          .child("follower")
          .on("value", data => {
            var no_followers = _objLen(data.val());

            firebase.database
              .ref("/users/" + params.userID)
              .child("analytics")
              .child("followers")
              .set(no_followers);
          });

        dispatch({
          type: "NO_DATA"
        });
      };
    case USER_FOLLOWERS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/follower`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data[undefined];
              var sortedData = _toArrayRemoveTrueFollow(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.dateRegistered);
                b = new Date(b.dateRegistered);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });

              sortedData = sortedData.filter(function(element) {
                return element !== undefined;
              });

              dispatch({
                type: USER_FOLLOWERS,
                userfollowers: sortedData
              });
            } else {
              dispatch({
                type: "USER_FOLLOWERS",
                userfollowers: {}
              });
            }
          });
      };
    case USER_FOLLOWING:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/following`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data[undefined];
              var sortedData = _toArrayRemoveTrueFollow(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.dateRegistered);
                b = new Date(b.dateRegistered);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });

              sortedData = sortedData.filter(function(element) {
                return element !== undefined;
              });
              dispatch({
                type: USER_FOLLOWING,
                userfollowing: sortedData
              });
            } else {
              dispatch({
                type: USER_FOLLOWING,
                userfollowing: {}
              });
            }
          });
      };
    case "FOLLOWING_BUTTON":
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/following`)
          .on("value", function(data) {
            if (data.val()) {
              dispatch({
                type: "FOLLOWING_BUTTON",
                followingButton: data.val()
              });
            } else {
              dispatch({
                type: "FOLLOWING_BUTTON",
                followingButton: {}
              });
            }
          });
      };
      case GET_UNSEEN_GROUP_NOTIFICATIONS:
        return (dispatch, getState) => {
            firebase.database
              .ref(`user/${params.uid}/unseengroupcounts`)
              .on("value", function(data) {
                if (data.val()) {
                  dispatch({
                    type: GET_UNSEEN_GROUP_NOTIFICATIONS,
                    unseengroupnotifications: data.val()
                  });
                } else {
                  dispatch({
                    type: GET_UNSEEN_GROUP_NOTIFICATIONS,
                    unseengroupnotifications: data.val()
                  });
                }
              });
        };
    case GET_UNSEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`user/${params.uid}/unseencounts`)
          .on("value", function(data) {
            var unseencounts = {
              dailythoughts: 0,
              companythoughts: 0,
              topleaderthoughts: 0,
              ileadcorporate: 0,
              ileadpublic: 0,
              podcasts: 0,
              articles: 0,
              videos: 0,
              unseenotifications: {},
              unseenotificationscnt: 0
            };

            if (data.val()) {
              unseencounts = data.val();
            }
            else
            {
              var unseencounts = {
                dailythoughts: 0,
                companythoughts: 0,
                topleaderthoughts: 0,
                ileadcorporate: 0,
                ileadpublic: 0,
                podcasts: 0,
                articles: 0,
                videos: 0,
                unseenotifications: {},
                unseenotificationscnt: 0
              };
            }
              dispatch({
                type: GET_UNSEEN_NOTIFICATIONS,
                unseenotifications: unseencounts
              });
          });
      };

    case GET_UNSEEN_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`user/${params.uid}/pldpnotifications`)
          .on("value", function(data) {
            var unseenpldpcounts = {
              pldpnotifications: 0,
              unseenpldpnotifications: {}
            };
            if (data.val()) {
              var sortedData = data.val();
              unseenpldpcounts = {
                pldpnotifications: sortedData.length || 0,
                unseenpldpnotifications: sortedData
              };

              dispatch({
                type: GET_UNSEEN_PLDP_NOTIFICATIONS,
                unseenpldpnotifications: unseenpldpcounts
              });
            } else {
              var unseenpldpcounts = {
                pldpnotifications: 0,
                unseenpldpnotifications: {}
              };

              dispatch({
                type: GET_UNSEEN_PLDP_NOTIFICATIONS,
                unseenpldpnotifications: unseenpldpcounts
              });
            }
          });
      };
    case SET_SEEN_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;

        for (var x in params.unseengroupnotifications) {
          if (
            params.unseengroupnotifications[x].groupid == params.groupid
          ) {
            myNotifications[
              `group-notifications/${params.userID}/${params.unseengroupnotifications[x].key}/seen`
            ] = true;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_GROUP_NOTIFICATIONS
        });
      };

    case SET_SEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;

        for (var x in params.unseenotifications) {
        switch (params.unseenotifications[x].notificationType) {
            case "thought":
              if (
                params.unseenotifications[x].thoughtType == "dailythoughts" &&
                page == "/daily"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }
              if (
                params.unseenotifications[x].thoughtType == "companythoughts" &&
                page == "/organisational"
              ) {
                if (params.unseenotifications[x].companyID !== PUBLIC_COMPANY_ID) {
                  myNotifications[
                    "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                  ] = true;
                }
              }
              if (
                params.unseenotifications[x].thoughtType ==
                "topleaderthoughts" &&
                page == "/globalcontributors"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              if (
                params.unseenotifications[x].thoughtType == "ileadcorporate" &&
                page == "/ilead"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              if (
                params.unseenotifications[x].thoughtType == "ileadpublic" &&
                page == "/ilead"
              ) {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }
              if (params.unseenotifications[x].thoughtType == "companythoughts" &&
                page == "/ilead"
              ) {
                if (params.unseenotifications[x].companyID === PUBLIC_COMPANY_ID) {
                  myNotifications[
                    "/users/" +
                    params.userID +
                    "/notifications/" +
                    params.unseenotifications[x].key +
                    "/seen"
                  ] = true;
                }
              }
              break;
            case "podcast":
              if (page == "/podcasts") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;
            case "article":
              if (page == "/articles") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;
            case "video":
              if (page == "/videos") {
                myNotifications[
                  "/users/" +
                  params.userID +
                  "/notifications/" +
                  params.unseenotifications[x].key +
                  "/seen"
                ] = true;
              }

              break;

            default:
              break;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_NOTIFICATIONS
        });
      };
    case SET_SEEN_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        var myNotifications = {};
        var page = params.path;
        for (var x in params.unseenpldpnotifications) {
          if (page == "/pldp") {
            myNotifications[
              "/users/" +
              params.userID +
              "/pldpnotifications/" +
              params.unseenpldpnotifications[x].key +
              "/seen"
            ] = true;
          }
        }

        if (Object.keys(myNotifications).length > 0) {
          firebase.database.ref().update(myNotifications);
        }

        dispatch({
          type: SET_SEEN_PLDP_NOTIFICATIONS
        });
      };
    case USER_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/notifications`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });
              dispatch({
                type: USER_NOTIFICATIONS,
                usernotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_NOTIFICATIONS,
                  usernotifications: {}
                });
              };
            }
          });
      };
    case USER_GROUP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`group-notifications/${params.userID}`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);

                if (a > b) return -1;
                if (a < b) return 1;
                return 0;
              });
              dispatch({
                type: USER_GROUP_NOTIFICATIONS,
                groupnotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_GROUP_NOTIFICATIONS,
                  groupnotifications: {}
                });
              };
            }
          });
      };
    case SEEN_NOTIFICATIONS:
      return (dispatch, getState) => {
        // Write the new thought's data
        var updates = {};
        updates[
          "/users/" +
          params.userID +
          `/${params.notificationType}/` +
          params.notificationID +
          "/seen"
        ] = true;
        firebase.database.ref().update(updates);

        dispatch({
          type: SEEN_NOTIFICATIONS
        });
      };
    case USER_PLDP_NOTIFICATIONS:
      return (dispatch, getState) => {
        firebase.database
          .ref(`users/${params.userID}/pldpnotifications`)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var data = data.val();
              delete data["undefined"];
              var sortedData = _toArrayRemoveTrue(data);
              sortedData.sort(function(a, b) {
                a = new Date(a.notificationDate);
                b = new Date(b.notificationDate);
                return a < b ? 1 : -1;
                // if (a > b) return -1;
                // if (a < b) return 1;
                // return 0;
              });
              dispatch({
                type: USER_PLDP_NOTIFICATIONS,
                userpldpnotifications: sortedData
              });
            } else {
              return (dispatch, getState) => {
                dispatch({
                  type: USER_PLDP_NOTIFICATIONS,
                  userpldpnotifications: {}
                });
              };
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: "NO_DATA",
          useranalytics: {},
          userfollowers: {},
          userfollowing: {}
        });
      };
  }
};

export const updateLoggedUser = params => {
  return (dispatch, getState) => {
    firebase.database.ref("logged").push(params.user);
    dispatch({
      type: "NO_DATA"
    });
  };
};
