/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-inline-comments */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import {
  Checkbox,
  TextField,
  Button,
  ListItemText,
  ListItem,
  ListItemIcon,
  List, Slide,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@material-ui/core'
import _ from 'lodash'
import { connect } from 'react-redux'
import { SHOW_LOADER, HIDE_LOADER, SHOW_TOAST } from '../../../store/actions/types'
import { hideLoader, showLoader } from '../../../store/actions/loaderActions'
import { showToast } from '../../../store/actions/toastAction'
import firebase from '../../../store/firebase'
import { getAllCompanyUsers } from '../../../store/actions/companyAdminActions'
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded'

// ---------------
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

class GroupAdminDialog extends Component {
  // --------------
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      userList: this.props.users,
      inputSearch: '',
      groupName: ''
    }

    this.handleGroup = this.handleGroup.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  // --------------
  // filter items
  // handleSearch(event) {
  //   console.log('handleSearch: ', event.target.value);
  //   var searchtext = event.target.value;
  //   searchtext = searchtext.toLowerCase();
  //   console.log('searchtext: ', searchtext);

  //   this.setState({ inputSearch: event.target.value }, () => {
  //     this.setState({
  //       list: this.state.userList.map((obj) => {
  //         console.log('Object.values(obj): ', Object.values(obj));
  //         if (((new RegExp(this.state.inputSearch, 'gm')).test(Object.values(obj)))) {
  //           obj.visible = ''
  //         } else {
  //           obj.visible = 'none'
  //         }
  //         console.log('obj: ', obj);
  //         return obj
  //       })
  //     })
  //   })
  // }

  handleSearch(event) {
    this.setState({ inputSearch: event.target.value }, () => {
      this.setState({
        list: this.state.userList.map((obj) => {
          let sname = obj.firstName
          let slastname = obj.lastName

          if (
            (
              (new RegExp(this.state.inputSearch.toLowerCase(), 'gm')).test(sname.toLowerCase())
            ) ||
          (
            (new RegExp(this.state.inputSearch.toLowerCase(), 'gm')).test(slastname.toLowerCase())
          )
          ) {
            obj.visible = ''
          } else {
            obj.visible = 'none'
          }
          console.log('obj: ', obj)
          return obj
        })
      })
    })
  }

  getName(props) {
    for (let x in props) {
      // // console.log('getURL: ', props[x].url)
      // return props[x].url
      return props[x].firstName
    }
  }

  // --------------
  // select items
  handleCheck(checkedItem) {
    const temp = this.state.userList.map((item) => {
      if (checkedItem.email === item.email) {
        (!item.selected) ? item.selected = true : item.selected = false
      }
      // if ((new RegExp(checkedItem.firstName, 'gm')).test(item.firstName)) {
      //   (!item.selected) ? item.selected = true : item.selected = false
      // }
      return item
    })

    this.setState({
      userList: temp
    })
  }

  // --------------
  // save list
  handleSave(event) {
    // // console.log('handle save', this.state.list)
  }

  handleGroup(event) {
    this.setState({
      groupName: event.target.value
    }, function () {
      // // console.log('groupName', this.state.groupName)
    })
  }

  hasSelected() {
    const check = this.state.userList.map((user) => {
      return (user.selected)
    })
    return check.includes(true)
  }

  // ---------------
  saveGroupList(event) {
    const { userdata, showLoader } = this.props
    const { groupName } = this.state
    const temp = []
    let isSelected = true

    // extract users selected and check if users selected
    if (!this.hasSelected()) {
      isSelected = false
      showToast(
        {
          type: SHOW_TOAST, open: true, variant: 'warning',
          message: 'Please select users to be part of a group.'
        })
    }

    // check if group name inserted
    if (groupName === '') {
      showToast(
        {
          type: SHOW_TOAST, open: true, variant: 'warning',
          message: 'Please provide a group name.'
        })
    }

    // send data to firebase
    if (groupName !== '') {
      if (isSelected) {
        showLoader({ type: SHOW_LOADER })
        let obj = {}
        this.state.userList.map(function (user) {
          if (user.selected) {
            obj[user.userID] = {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              userID: user.userID,
              can_add_remove_users: false,
              can_edit: false,
              can_post: true,
              company_ID: userdata.companyID,
              company_name: userdata.companyName
            }
          }
        })

        // add current user to group with admin access
        obj[userdata.userID] = {
          firstName: userdata.firstName,
          lastName: userdata.lastName,
          email: userdata.email,
          userID: userdata.userID,
          can_add_remove_users: true,
          can_edit: true,
          can_post: true,
          company_ID: userdata.companyID,
          company_name: userdata.companyName
        }
        this.postNewGroup(obj)
      }
    }
  }

  // ---------------
  postNewGroup(rowsSelected) {
    const updates = {}
    const { userdata, showLoader, showToast, closeGroupAdminDialog } = this.props
    const { groupName } = this.state

    // generate new key for group
    const key = firebase.database.ref().child('company_groups_users').push().key

    console.group('rowsSelected.length', rowsSelected, rowsSelected.length)

    // 1 create new group
    updates['/company_groups/' + userdata.companyID + '/' + key] = {
      group_id: key,
      group_name: groupName,
      company_id: userdata.companyID,
      total_users: Object.keys(rowsSelected).length
    }

    // 2 add users to group
    updates['/company_groups_users/' + userdata.companyID + '/' + key] = rowsSelected

    // 3 add group to user specific list
    _.each(rowsSelected, function (value, index) {
      updates['/company_user_groups/' + value.userID + '/' + key] = {
        user_id: value.userID,
        group_id: key,
        group_name: groupName
      }
    })

    // 4 add current user to group with admin access
    updates['/company_user_groups/' + userdata.userID + '/' + key] = {
      user_id: userdata.userID,
      group_id: key,
      group_name: groupName
    }

    // server: create group - send data to firebase
    firebase.database.ref().update(
      updates,
      function (error) {
        if (error) {
          showToast({ type: SHOW_TOAST, open: true, variant: 'error', message: error.message })
        } else {
          showToast(
            {
              type: SHOW_TOAST, open: true, variant: 'success',
              message:
              // eslint-disable-next-line max-len
              'Group has been created and is navigable to using the side menu bar under \'My Groups\'.'
            })
        }
      })

    // hide progress
    showLoader({ type: HIDE_LOADER })

    closeGroupAdminDialog()
  }

  static getDerivedStateFromProps(props, state) {
    state.userList = props.users
  }

  render(props) {
    const {
      open
    } = this.props

    return (
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => {
          this.closeGroupAdminDialog()
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <AddCircleOutlineRoundedIcon />
        Add Group
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder='Insert Group name'
            value={this.state.inputGroupName}
            onChange={this.handleGroup}
            style={{ width: '93%' }}
            margin='normal'
          />

          {/* ------------- */}
          {/* // search bar */}
          <TextField
            placeholder='Search User'
            value={this.state.inputSearch}
            onChange={this.handleSearch}
            style={{ width: '93%' }}
            margin='dense'
            variant='outlined'
          />

          {/* ------------- */}
          {/* // display list */}
          <List dense style={{ overflowY: 'scroll', marginBottom: '20px' }}>
            {
              this.props.users.map((obj) => {
                const labelId = `checkbox-list-secondary-label-${obj.firstName}-${obj.lastName}`
                return (
                  <ListItem key={obj.email} button onClick={(e) => {
                    this.handleCheck(obj)
                  }} style={{ display: obj.visible }}>
                    {/** add avatar */}
                    <ListItemIcon>
                      <Checkbox
                        edge='end' value={obj}
                        checked={obj.selected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color='primary' />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      style={{ width: '10px' }}
                      primary={
                        `${obj.firstName}` + ' ' + `${obj.lastName}` + ' ' + `${obj.email}`} />
                  </ListItem>
                )
              })
            }
            {/* ------------- */}
            {/* save */}
          </List>
          <DialogContentText />
        </DialogContent>

        {/* ---------- */}
        {/* Save */}
        <DialogActions>
          <Button
            onClick= {this.props.closeGroupAdminDialog}
          >
          Cancel
          </Button>
          <Button variant='contained' onClick={() => {
            this.saveGroupList()
          }} color='primary'>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

//  ===============
const mapDispatchToProps = dispatch => {
  return {
    showLoader: params => dispatch(showLoader(params)),
    hideLoader: params => dispatch(hideLoader(params)),
    showToast: params => dispatch(showToast(params)),
    getAllCompanyUsers: params => dispatch(getAllCompanyUsers(params))
  }
}

const mapStateToProps = (state, ownProps) => {
  // // console.log('ownProps', state, ownProps)
  return {
    userdata: state.userDialog.userDetails[0]
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminDialog)
