//import React,{Component} from 'react';
import React,{ createRef } from 'react';
import ReactDOM from 'react-dom'
//import React, { Component } from "react";
//import ScrollUpButton from "react-scroll-up-button"; 
// import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, Row, Col } from 'react-grid-system';
import { showToast } from "../../store/actions/toastAction";
import { createSurvey } from "../../store/actions/SurveyActions";
import { GREY, RED, LIGHTBLUE, LIGHTGREY, MAROONHOVER } from "../../utils/color_utils";
import { updateSurvey } from "../../store/actions/surveyUpdate";
import TestIcon from '@material-ui/icons/QuestionAnswer';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'supercons';
//import {Avatar} from '@material-ui/core'
import Avatar from '@mui/material/Avatar';
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { connect } from 'react-redux'
import {
  getUserProfile
} from '../../store/actions/companyAdminActions'
import {
  GaddOpinions, GgetOpinionsTrail, FindSlave, GgetOpinions, GgetOpinionsOnSubmit
} from '../../store/actions/opinionG_Actions'
import {
  GET_USER_PROFILE, GET_O, SHOW_TOAST, GET_SLAVE_OPINION, GET_OPINIONID, GET_O_S
} from "../../store/actions/types";
import firebase from '../../store/firebase'
import { _toArray, _toArrayUsers, _toArrayUsersx, _toArrayCalendar, _toArrayGroups, _toArrayUsersS } from '../../store/actions/commonActions'
import SendIcon from '@material-ui/icons/Send'
import { withStyles } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { addMorePoints } from '../../store/actions/freshCardActions'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import { requirePropFactory } from '@material-ui/core';
//import { scroller } from "react-scroll";
//import { Route } from 'react-router-dom'++++++++++++++++++++++++++++++++++++++
//import { ScrollTo } from "react-scroll-to";

const table = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #CAC9C8",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 7
}
const tableh = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 10,
  borderRadius: 15
}
const tableM = {
  color: "#515A5A",
  fontSize: 18,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 15
}

const content = {
  margin: "500px",
  height: "800px",
  width: "2000px"
}
const myDIV = {
  height: "250px",
  width: "250px",
  overflow: "auto",
  background: "coral"
}

const buttonStyle = {
  backgroundColor: RED,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const hoverbuttonStyle = {
  backgroundColor: MAROONHOVER,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const styles = {
  mark: {
    width: 250,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  mlist: {
    width: 250,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  survs: {
    width: 1500,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  row2: {
    //     backgroundColor: 'green',
  },
  col1: {
    backgroundColor: 'yellow',
  },
  col2: {
    backgroundColor: 'brown',
  },
  col: {
    padding: '1rem',
    backgroundColor: '#33b5e5',
    border: '2px solid #fff',
    color: '#fff',
    textAlign: 'center'
  },
  rightIcon: {
    paddingRight: '8px'
  }
};

const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: '#fff',
  cursor: 'pointer'
}

const topB = {
  position: 'fixed',
  bottom: '40px',
  right: '25px',
  zindex: '20',
  visibility: 'hidden',
  position: 'relative'
}

class ViewUser extends React.Component {
  constructor(props) {
    super(props);
    this.scollToRef = createRef();
  }

  state = {
    Open: false,
    userDetails: {},
    firstName: '',
    lastName: '',
    followers: 0,
    following: 0,
    companyName: '',
    dailythoughts: 0,
    articles: 0,
    videos: 0,
    podccasts: 0,
    biography: '',
    isLoading: 1,
    maxLengthThought: 0,
    showButton: true,
    tac: 0,
    slave: 0,
    one: 0,
    qoneopinion: "",
    two: 0,
    qtwoopinion: "",
    three: 0,
    qthreeopinion: "",
    four: 0,
    qfouropinion: "",
    // five: 0,
    // qfiveopinion: "",
    // six: 0,
    // qsixopinion: "",
    // seven: 0,
    // qsevenopinion: "",
    // eight: 0,
    // qeightopinion: "",
    // nine: 0,
    // qnineopinion: "",
    // ten: 0,
    // qtenopinion: "",
    stoggleTac: false,
    stoggleSlave: false,
    stoggleOne: false,
    stoggleTwo: false,
    stoggleThree: false,
    stoggleFour: false,
    // stoggleFive: false,
    // stoggleSix: false,
    // stoggleSeven: false,
    // stoggleEight: false,
    // stoggleNine: false,
    // stoggleTen: false,
    is_visible: false,
    hiddenVal: "",
    state_opinionID: "",
    opinionID: "",
    imgURLx: ""
  };

  componentDidMount() {
    let { leaderboardData, feedback, opinions, sopinions, userdata } = this.props
    const { leaderID, slaveID, findSlave } = this.props;

    this.setState({ showButton: true })

    if (this.props.slaveID===this.props.leaderID) {
     // this.setState({ showButton: false })
    }
    this.scollToRef.current.scrollIntoView()
    if (leaderboardData) {
      this.getUserInfo();
    }
  }

    focusTextInput = () => {
      // Focus the text input using the raw DOM API
    //window && window.scroll(0,0);
     // window && window.scrollTo(0,0);
     // window && Window.scrollByLines(0,0)
     // window && Window.scrollByPages(0,0)
      //Element.scrollIntoView(0,0)

      //this.props.history.push('/textInput')

      console.log('inside focusTextInput 1')
      React.findDOMNode(this.refs.textInput).click();

      if (this.textInput) {
        this.textInput.click();
        console.log('inside focusTextInput 2')
       }
     };

  getFeedback = () => {
    return new Promise((resolve, reject) => {

      setTimeout(() => {
         if (this.props.feedback) {
           console.log('green populateState 2 : ', this.props.feedback)
          this.populateState(); 
        } else {
          reject('Failed to the user list');
        }
      }, 500);     
    });
  }

  populateState = () => {
    const { feedback } = this.props;
    
    if (feedback && feedback[0]) {
      Object.keys(feedback[0]).map(id => {
        console.log('inside populateState function 2 : ', feedback[0].selfopinion);
        var feedbackqone = feedback[0].one;
        var feedbackqoneopinion = feedback[0].qoneopinion;
        var feedbackqtwo = feedback[0].two;
        var feedbackqtwoopinion = feedback[0].qtwoopinion;
        var feedbackqthree = feedback[0].three;
        var feedbackqthreeopinion = feedback[0].qthreeopinion;
        var feedbackqfour = feedback[0].four;
        var feedbackqfouropinion = feedback[0].qfouropinion;
        // var feedbackqfive = feedback[0].five;
        // var feedbackqfiveopinion = feedback[0].qfiveopinion;
        // var feedbackqsix = feedback[0].six;
        // var feedbackqsixopinion = feedback[0].qsixopinion;
        // var feedbackqseven = feedback[0].seven;
        // var feedbackqsevenopinion = feedback[0].qsevenopinion;
        // var feedbackqeight = feedback[0].eight;
        // var feedbackqeightopinion = feedback[0].qeightopinion;
        // var feedbackqnine = feedback[0].nine;
        // var feedbackqnineopinion = feedback[0].qnineopinion;
        // var feedbackqten = feedback[0].ten;
        // var feedbackqtenopinion = feedback[0].qtenopinion;
      this.setState({
        one: feedbackqone,
        qoneopinion: feedbackqoneopinion,
        two: feedbackqtwo,
        qtwoopinion: feedbackqtwoopinion,
        three: feedbackqthree,
        qthreeopinion: feedbackqthreeopinion,
        four: feedbackqfour,
        qfouropinion: feedbackqfouropinion,
        // five: feedbackqfive,
        // qfiveopinion: feedbackqfiveopinion,
        // six: feedbackqsix,
        // qsixopinion: feedbackqsixopinion,
        // seven: feedbackqseven,
        // qsevenopinion: feedbackqsevenopinion,
        // eight: feedbackqeight,
        // qeightopinion: feedbackqeightopinion,
        // nine: feedbackqnine,
        // qnineopinion: feedbackqnineopinion,
        // ten: feedbackqten,
        // qtenopinion: feedbackqtenopinion
      })
     }
    )}
    }

 shouldComponentUpdate() {
     return true;
 }

  xxshouldComponentUpdate(prevProps, prevState) {
    console.log('componentDidMount.feedback 3 : ', this.props.feedback)
    console.log('componentDidMount.feedback prevProps: ', prevProps.feedback); 
    console.log('componentDidMount.feedback selfopinion: ', this.state.selfopinion); 
   }

   static getDerivedStateFromProps(nextProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    return {
      feedback: nextProps.feedback,
      // ... other derived state properties
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //this.scollToRef.current.scrollIntoView()
    console.log('componentDidMount.feedback 3 : ', this.props.feedback)
    console.log('green componentDidUpdate prevProps: ', prevProps.feedback); // Previous properties of the component.
    console.log('green componentDidUpdate prevState: ', prevState.feedback) // Previous state of the component.
    //console.log('green componentDidUpdate snapshot: ', snapshot)  // Current rendered content
  }

  // scrollToSection = () => {
  //   console.log('green scrolltosection')
  //   scroller.scrollTo("topspot", {
  //     duration: 800,
  //     delay: 0,
  //     smooth: "easeInOutQuart",
  //   });
  // };

  setFocusName() {
    //this.evalu.focus();
    //document.getElementById("topspot").focus();
    //window && window.scroll(0,0);
    //window && window.scrollTo(0,0);
    //window && Window.scrollByLines(0,0)
    //window && Window.scrollByPages(0,0)
    //Element.scrollIntoView(0,0)

    var Element = document.getElementById("topspot");
    Element.focus();
    //Element.scrollIntoView(0,0)

  
    //element.scrollIntoView(false);
    //element.scrollIntoView({block: "end"});
    //element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

    // element.scrollTo({
    //   top: 100,
    //   left: 100,
    //   behavior: 'smooth'
    // });
  }
  
  focusOn() {
    setTimeout(() => {
      this.setFocusName()
    }, 1000)
  }

  getUserInfo() {
    let { userInfo, leaderboardData } = this.props
    console.log('green getUserInfo : ', leaderboardData)

    var userInf;

    var userDat;
    var firstName = '';
    var lastName = '';
    var followers = 0;
    var following = 0;
    var companyName = '';
    var dailythoughts = 0;
    var articles = 0;
    var videos = 0;
    var podcasts = 0;
    var biography = '';

    let user = {};

    user = Object.assign({}, this.props.leaderboardData);
    let receivedData = {};
    console.log('red leaderboardMData 1 : ', Object.keys(user).length + ' --- ' + this.props.leaderID);
    delete user.undefined

    console.log('red leaderboardMData 2 : ', Object.keys(user).length + ' --- ' + this.props.leaderID);

    if (Object.keys(user).length > 0) {
      let sortedData = _toArrayUsersS(user, this.props.leaderID)

      console.log('red leaderboardMData 3: ', sortedData);

      sortedData.sort(function (a, b) {
        return b.points - a.points
      });

      receivedData = { ...sortedData }
      receivedData = Object.assign({}, sortedData)
      try {
        if (receivedData[0].unseencounts) 
        { console.log('red leaderboardMData 4: ', receivedData[0].unseencounts) }
      }
      catch (err) {
        console.log('red leaderboardMData 4: ', err)
      }
      try {
        if (receivedData[0]) { firstName = receivedData[0].firstName } else { firstName = 'undefined' }
        if (receivedData[0]) { lastName = receivedData[0].lastName } else { lastName = 'undefined' }
        if (receivedData[0].analytics) { followers = receivedData[0].analytics.followers } else { followers = 0 }
        if (receivedData[0].analytics) { following = receivedData[0].analytics.following } else { following = 0 }
        companyName = receivedData[0].companyName
        if (receivedData[0].unseencounts) { dailythoughts = receivedData[0].unseencounts.dailythoughts } else { dailythoughts = 0 }
        if (receivedData[0].unseencounts) { articles = receivedData[0].unseencounts.articles } else { articles = 0 }
        if (receivedData[0].unseencounts) { videos = receivedData[0].unseencounts.videos } else { videos = 0 }
        if (receivedData[0].unseencounts) { podcasts = receivedData[0].unseencounts.podcasts } else { podcasts = 0 }
        biography = receivedData[0].biography
      }
      catch (err) {
        console.log('red leaderboardMData 5: ', err);

        firstName = 'undefined'
        lastName = 'undefined'
        followers = 0
        following = 0
        companyName = 'undefined'
        dailythoughts = 0
        articles = 0
        videos = 0
        podcasts = 0
        biography = 'undefined'
        console.log('err ViewUser : ', err)
      }

    }

    console.log('red leaderboardMData showButton : ', this.props.slaveID + ' --- ' + this.props.leaderID);

    this.setState({
      isLoading: 0,
      firstName: firstName,
      lastName: lastName,
      followers: followers,
      following: following,
      companyName: companyName,
      dailythoughts: dailythoughts,
      articles: articles,
      videos: videos,
      podcasts: podcasts,
      biography: biography,
      showButton: this.props.slaveID===this.props.leaderID ? false : true
    })
     
  }

  getViewerProfile(zuserID) {
    firebase.database
      .ref('user')
      .orderByChild('userID')
      .equalTo(zuserID)
      .on('value', function (data) {
        if (data.val()) {
          var user = data.val()
          let receivedData = {}
          delete user.undefined

          console.log('green getUserData 1: ', user.firstName)
          return user;
        } else {
          console.log('green getUserData 2: null')
          return 0;
        }

      })

  }

  getFollowers = (props) => {
    for (let x in props) {
      return props[x].analytics.followers
    }
  }
  getFollowing = (props) => {
    for (let x in props) {
      return props[x].analytics.following
    }
  }
  getfirstName = (props) => {
    for (let x in props) {
      return props[x].firstName
    }
  }

  //-----------------------------------------
  handleChangeA = () => {
    if (this.state.goodleader) {
      this.setState({ goodleader: false })
    }
    else {
      this.setState({ goodleader: true })
    }

  }

  handleChangeB = () => {
    if (this.state.goodlistener) {
      this.setState({ goodlistener: false })
    }
    else {
      this.setState({ goodlistener: true })
    }

  }
  
 xfindSlave = (leaderID, slaveID) => {
    console.log('blue slave data 2 ')
    var pass = ''; //no post yet
    var database = firebase.database;
     database
      .ref('CopinionsTrail')
      .orderByChild('leaderID')
      .equalTo(leaderID)
      .on('value', function (data) {

        if (data.val()) {
          let odata = data.val()
          if (Object.keys(odata).length > 0) {
            let sortedData = _toArrayUsers(odata)
            Object.keys(sortedData).map((id, index) => {

              if (sortedData[id].leaderID === leaderID) {
                if (sortedData[id].slaveID === slaveID) { //user already made opinion on this leader
                 pass = sortedData[id].opinionID; //has posted before
                 //this.setState({opinionID: sortedData[id].opinionID})
                 //this.setState( (state,props) =>  ({opinionID: sortedData[id].opinionID}) );
                 return pass;
                  console.log('green submitPost leaderID : ', leaderID)
                  console.log('green submitPost slaveID : ', slaveID)
                  console.log('green submitPost opinionID 2 : ', sortedData[id].opinionID)

                }
              }
            })
          }
        }
      })
    return pass;
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  checkFields = () => {
    var protest = false;
    var bootskap = "Please complete the following fields: ";

    console.log('checkFields stoggleSlave: ', this.state.stoggleSlave)
    console.log('checkFields stoggleOne: ', this.state.stoggleOne)
    console.log('checkFields stoggleTwo: ', this.state.stoggleTwo)
    console.log('checkFields stoggleThree: ', this.state.stoggleThree)
    console.log('checkFields stoggleFour: ', this.state.stoggleFour)
    // console.log('checkFields stoggleFive: ', this.state.stoggleFive)
    // console.log('checkFields stoggleSix: ', this.state.stoggleSix)
    // console.log('checkFields stoggleSeven: ', this.state.stoggleSeven)
    // console.log('checkFields stoggleEight: ', this.state.stoggleEight)
    // console.log('checkFields stoggleNine: ', this.state.stoggleNine)
    // console.log('checkFields stoggleTen: ', this.state.stoggleTen)

    console.log('checkFields qoneopinion: ', this.state.qoneopinion)
    console.log('checkFields qtwoopinion: ', this.state.qtwoopinion)
    console.log('checkFields qthreeopinion: ', this.state.qthreeopinion)
    console.log('checkFields qfouropinion: ', this.state.qfouropinion)
    // console.log('checkFields qfiveopinion: ', this.state.qfiveopinion)
    // console.log('checkFields qsixopinion: ', this.state.qsixopinion)
    // console.log('checkFields qsevenopinion: ', this.state.qsevenopinion)
    // console.log('checkFields qeightopinion: ', this.state.qeightopinion)
    // console.log('checkFields qnineopinion: ', this.state.qnineopinion)
    // console.log('checkFields qtenopinion: ', this.state.qtenopinion)

    // if (this.state.stoggleTac===false) {
    //   protest = true;
    //   bootskap = bootskap + 'Participant Agreement, '
    // }

    if (this.state.stoggleSlave===false) {
      protest = true;
      bootskap = bootskap + 'Participant Relation, '
    }
    if (this.state.stoggleOne===false) {
      protest = true;
      bootskap = bootskap + 'Leading Self, '
    }
    if (this.state.stoggleTwo===false) {
      protest = true;
      bootskap = bootskap + 'Leading Others, '
    }
    if (this.state.stoggleThree===false) {
      protest = true;
      bootskap = bootskap + 'Leading Organisation, '
    }
    if (this.state.stoggleFour===false) {
      protest = true;
      bootskap = bootskap + 'Leading Society, '
    }
    // if (this.state.stoggleFive===false) {
    //   protest = true;
    //   bootskap = bootskap + 'Question 5, '
    // }
    // if (this.state.stoggleSix===false) {
    //   protest = true;
    //   bootskap = bootskap + 'Question 6, '
    // }
    // if (this.state.stoggleSeven===false) {
    //   protest = true;
    //   bootskap = bootskap + 'Question 7, '
    // }
    // if (this.state.stoggleEight===false) {
    //   protest = true;
    //   bootskap = bootskap + 'Question 8, '
    // }
    // if (this.state.stoggleNine===false) {
    //   protest = true;
    //   bootskap = bootskap + 'Question 9, '
    // }
    // if (this.state.stoggleTen===false) {
    //   protest = true;
    //   bootskap = bootskap + 'Question 10, '
    // }     
   /////////////////////////////////////////////
    if (this.state.qoneopinion==='') {
     protest = true;
     bootskap = bootskap + 'Leading Self Comment, '
    }
    if (this.state.qtwoopinion==='') {
      protest = true;
      bootskap = bootskap + 'Leading Others Comment, '
     }
     if (this.state.qthreeopinion==='') {
      protest = true;
      bootskap = bootskap + 'Leading Organisation Comment, '
     }
     if (this.state.qfouropinion==='') {
      protest = true;
      bootskap = bootskap + 'Leading Society Comment, '
     }
    //  if (this.state.qfiveopinion==='') {
    //   protest = true;
    //   bootskap = bootskap + 'Question 5 Comment, '
    //  }
    //  if (this.state.qsixopinion==='') {
    //   protest = true;
    //   bootskap = bootskap + 'Question 6 Comment, '
    //  }
    //  if (this.state.qsevenopinion==='') {
    //   protest = true;
    //   bootskap = bootskap + 'Question 7 Comment, '
    //  }
    //  if (this.state.qeightopinion==='') {
    //   protest = true;
    //   bootskap = bootskap + 'Question 8 Comment, '
    //  }
    //  if (this.state.qnineopinion==='') {
    //  protest = true;
    //  bootskap = bootskap + 'Question 9 Comment, '
    //  }
    //  if (this.state.qtenopinion==='') {
    //   protest = true;
    //   bootskap = bootskap + 'Question 10 Comment, '
    // }
 // if (this.state.tac===2) { //user doesnt agree
   if (this.state.stoggleTac===false) { //user doesnt agree
    let params = {
      type: SHOW_TOAST,
      open: true,
      variant: 'warning',
      message: 'You need to agree to the declaration before submitting feedback',
      pos: "bottom"
    }

    this.props.showToast(params)
    return 3;
  }
  else  //user agrees
   {
    if (protest) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: bootskap,
        pos: "bottom"
      }

      this.props.showToast(params)
      return 3;
   }
  }
 }
 getopinionID = () => {
  var params = {
    leaderID: this.props.leaderID,
    slaveID: this.props.slaveID
  }
  this.props.findSlave(params);
 }

 addSlaveOpinion = (opinionID) => {
  const { userdata } = this.props;
  var addPoints = false;
  if (opinionID) {  
    //allready exists
    } else 
  { 
    opinionID = this.makeid(10)
    addPoints = true;
  }
  var params = {
    opinionID: opinionID,
    leaderID: userdata.companyID,
    companyName: userdata.companyName,
    slaveID: userdata.userID,
    companyID: userdata.companyID,
    firstName: userdata.firstName,
    lastName: userdata.lastName,
    slave: this.state.slave,
    qoneopinion: this.state.qoneopinion,
    one: this.state.one,
    qtwoopinion: this.state.qtwoopinion,
    two: this.state.two,
    qthreeopinion: this.state.qthreeopinion,
    three: this.state.three,
    qfouropinion: this.state.qfouropinion,
    four: this.state.four,
    // qfiveopinion: this.state.qfiveopinion,
    // five: this.state.five,
    // qsixopinion: this.state.qsixopinion,
    // six: this.state.six,
    // qsevenopinion: this.state.qsevenopinion,
    // seven: this.state.seven,
    // qeightopinion: this.state.qeightopinion,
    // eight: this.state.eight,
    // qnineopinion: this.state.qnineopinion,
    // nine: this.state.nine,
    // qtenopinion: this.state.qtenopinion,
    // ten: this.state.ten
  }
    this.props.GaddOpinions(params)

  if (addPoints === true && userdata.execmember) {
    let params = {
      uid: userdata.uid,
      userID: userdata.userID,
      pointsCurrent: userdata.points,
      ypointsCurrent: userdata.pointsy,
      pointsAdded: 10,
      trailID: this.makeid(10),
      companyName: userdata.companyName,
      firstName: userdata.firstName,
      lastName: userdata.lastName,
      title: 'Opinion on ' + userdata.companyID,
      actionType: 'Submitted Opinion Leader Poll',
      actionDesc: 'Opinion Poll Completed'
    }
    console.log('blue green props 1 : ', this.props)
    this.props.addMorePoints(params)
  }
}


  GputOpinionsB = (addPoints) => {
    const { leaderID, slaveID, getOpinionsOnSubmit, opinions, userdata } = this.props;
    console.log('green GputOpinionsB 2: ', this.props)
    var slave_count = 0;
    var one_count = 0;
    var two_count = 0;
    var three_count = 0;
    var four_count = 0;
    // var five_count = 0;
    // var six_count = 0;
    // var seven_count = 0;
    // var eight_count = 0;
    // var nine_count = 0;
    // var ten_count = 0;

    var slave_all_count = 0;
    var one_all_count = 0;
    var two_all_count = 0;
    var three_all_count = 0;
    var four_all_count = 0;
    // var five_all_count = 0;
    // var six_all_count = 0;
    // var seven_all_count = 0;
    // var eight_all_count = 0;
    // var nine_all_count = 0;
    // var ten_all_count = 0;

    console.log('green putOpinions sopinions3 ', opinions)

   if (opinions) { Object.keys(opinions).map((opinionsIDd, indx) => {
    console.log('green putOpinions sopinions4 :', opinions)
    if (opinions[indx].slave) { 
      slave_all_count = slave_all_count + 1;
      slave_count = slave_count + opinions[indx].slave 
    }
    if (opinions[indx].one) { 
        one_all_count = one_all_count + 1;
        one_count = one_count + opinions[indx].one
    }
    if (opinions[indx].two) { 
        two_all_count = two_all_count + 1;
        two_count = two_count + opinions[indx].two 
    }
    if (opinions[indx].three) { 
      three_all_count = three_all_count + 1;
     three_count = three_count + opinions[indx].three 
    }   
    if (opinions[indx].four) { 
      four_all_count = four_all_count + 1;
      four_count = four_count + opinions[indx].four 
    }
    // if (opinions[indx].five) { 
    //   five_all_count = five_all_count + 1;
    //   five_count = five_count + opinions[indx].five 
    // }     
    // if (opinions[indx].six) { 
    //   six_all_count = six_all_count + 1;
    //   six_count = six_count + opinions[indx].six 
    // }   
    // if (opinions[indx].seven) { 
    //   seven_all_count = seven_all_count + 1;
    //   seven_count = seven_count + opinions[indx].seven 
    // }  
    // if (opinions[indx].eight) { 
    //   eight_all_count = eight_all_count + 1;
    //   eight_count = eight_count + opinions[indx].eight 
    // }  
    // if (opinions[indx].nine) { 
    //   nine_all_count = nine_all_count + 1;
    //   nine_count = nine_count + opinions[indx].nine 
    // }  
    // if (opinions[indx].ten) { 
    //   ten_all_count = ten_all_count + 1;
    //   ten_count = ten_count + opinions[indx].ten 
    // }       
  })}

   //////////////////////////////////////////////////////////////////
   var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
   var ione = one_all_count > 0 ? one_count / one_all_count : 0;
   var itwo = two_all_count > 0 ? two_count / two_all_count : 0;
   var ithree = three_all_count > 0 ? three_count / three_all_count : 0;
   var ifour = four_all_count > 0 ? four_count / four_all_count : 0;
//    var ifive = five_all_count > 0 ? five_count / five_all_count : 0;
//    var isix = six_all_count > 0 ? six_count / six_all_count : 0;
//    var iseven = seven_all_count > 0 ? seven_count / seven_all_count : 0;
//    var ieight = eight_all_count > 0 ? eight_count / eight_all_count : 0;
//    var inine = nine_all_count > 0 ? nine_count / nine_all_count : 0;
//    var iten = ten_all_count > 0 ? ten_count / ten_all_count : 0;

   //insertPollCount into user and users
   const updates = {}

   var finalScore = (ione + itwo + ithree + ifour)/4
   console.log('green putOpinions scores ione ', ione)
   console.log('green putOpinions scores itwo ', itwo)
   console.log('green putOpinions scores ithree ', ithree)

   console.log('green putOpinions scores final ', finalScore)

   //console.log('scores final uid ', userdata.leaderUid)
   console.log('scores final userID ', userdata.companyID)

  //  updates['/users/' + userdata.companyID + '/feedback'] = finalScore.toFixed(2);
    updates['/companies/' + userdata.companyID + '/feedback' ] = finalScore.toFixed(2);

   // server: create group - send data to firebase
   firebase.database.ref().update(
     updates,
     function (error) {
       if (error) {
         console.log('success : ');
       } else {
        console.log('err : ', error);
       }
     })
     //////////////ADD POINTS OR NOT///////////////////////////////////////////////////////////
     if (addPoints === true && userdata.execmember) {
      let params = {
        uid: userdata.uid,
        userID: userdata.userID,
        pointsCurrent: userdata.points,
        ypointsCurrent: userdata.pointsy,
        pointsAdded: 10,
        trailID: this.makeid(10),
        companyName: userdata.companyName,
        firstName: userdata.firstName,
        lastName: userdata.lastName,
        title: 'Opinion on ' + userdata.companyID,
        actionType: 'Submitted Opinion Leader Poll',
        actionDesc: 'Opinion Poll Completed'
      }
      console.log('blue green props 1 : ', this.props)
      this.props.addMorePoints(params)
    }
     /////////////////////////////////////////////////////////////////////////
}
  GputOpinions = async() => {
    const { sopinions, getOpinionsOnSubmit, userdata } = this.props
    console.log('green putOpinions sopinions2 :', sopinions)

    var addPoints = false;
    //var companyID = userdata.companyID;

    var one_count = 0;
    var two_count = 0;
    var three_count = 0;
    var four_count = 0;
    // var five_count = 0;
    // var six_count = 0;
    // var seven_count = 0;
    // var eight_count = 0;
    // var nine_count = 0;
    // var ten_count = 0;

    var one_all_count = 0;
    var two_all_count = 0;
    var three_all_count = 0;
    var four_all_count = 0;
    // var five_all_count = 0;
    // var six_all_count = 0;
    // var seven_all_count = 0;
    // var eight_all_count = 0;
    // var nine_all_count = 0;
    // var ten_all_count = 0;

    var opinionID = '';
    //var imgURLX = '';
   
    if (sopinions) { Object.keys(sopinions).map((opinionsID, index) => {
      opinionID = sopinions[index].opinionID;
    })}
      ///////////////////////ADD/EDIT opinionsTrail collection///////////////////////////////////////////////
      //console.log('green putOpinions imgURLX :', imgURLX)
      console.log('green GputOpinionsB 1:', userdata)

      if (opinionID) {  
        //allready exists going to edit existing entry
        } else 
      { 
        opinionID = this.makeid(10) //going to make new entry
        addPoints = true;
      }
      var params = {
        opinionID: opinionID,
        leaderID: userdata.companyID,
        companyName: userdata.companyName,
        slaveID: userdata.userID,
        companyID: userdata.companyID,
        firstName: userdata.firstName,
        lastName: userdata.lastName,
        slave: this.state.slave,

        one: this.state.one,
        qoneopinion: this.state.qoneopinion,

        two: this.state.two,
        qtwoopinion: this.state.qtwoopinion,
       
        three: this.state.three,
        qthreeopinion: this.state.qthreeopinion,

        four: this.state.four,
        qfouropinion: this.state.qfouropinion,

        // five: this.state.five,
        // qfiveopinion: this.state.qfiveopinion,

        // six: this.state.six,
        // qsixopinion: this.state.qsixopinion,

        // seven: this.state.seven,
        // qsevenopinion: this.state.qsevenopinion,

        // eight: this.state.eight,
        // qeightopinion: this.state.qeightopinion,

        // nine: this.state.nine,
        // qnineopinion: this.state.qnineopinion,

        // ten: this.state.ten,
        // qtenopinion: this.state.qtenopinion
      }
      this.props.GaddOpinions(params) //ADD OR EDIT UNIQUE MASTER-SLAVE SURVEY
      //////////////////////////////////////////////////////////////////////
     // if (addPoints===false) {
        // getOpinionsOnSubmit({ //REDO sopinions
        //   leaderID: this.props.leaderID, 
        //   slaveID: this.props.slaveID
        // }); 
        await this.props.GgetOpinions({ //REDO sopinions
          leaderID: userdata.companyID, 
        }); 
      //}

      //this.putOpinionsB(addPoints);
      const promise = this.GgetOpinions();
      promise.then(this.onFulfilledB, this.onRejectedB);
    }

    GgetOpinions = () => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.GputOpinionsB();
        }, 3000);     
      });
    }
  
    onFulfilledB = (valIn) => {
      console.log('white users : ', 'ooooooooooooooooo');
    }
    onRejectedB = (error) => {
      console.log('white error : ', error);
    }

   submitPost = async() => {
   const { leaderID, slaveID, GgetOpinionsOnSubmit, userdata } = this.props;
   console.log('green orgEval : ', userdata.userID)
    if (this.checkFields()===3) {
      console.log('checkFields return')
      return
    }
    console.log('green putOpinions leaderID :', leaderID)
    console.log('green putOpinions slaveID :', slaveID)
    // const promise = this.getsOpinions();
    // promise.then(this.onFulfilled, this.onRejected);

   await GgetOpinionsOnSubmit({ 
        leaderID: userdata.companyID, 
        slaveID: userdata.userID
      }); 
    this.setState({ showButton: false })

    console.log('checkFields not return')
    console.log('green putOpinions sopinions1 :', this.props.sopinions)

   //  this.putOpinions(); //compile new mean values for leaderboard from opinions in state
     const promise = this.getsOpinions();
     promise.then(this.onFulfilled, this.onRejected);
  }

  getsOpinions = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.GputOpinions();
      }, 3000);     
    });
  }

  onFulfilled = (valIn) => {
    console.log('white users : ', 'ooooooooooooooooo');
  }
  onRejected = (error) => {
    console.log('white error : ', error);
  }

  toggleSlave = (nr) => {
    const { slave } = this.state;
    this.setState({
      slave: nr,
      stoggleSlave: true
    })
  }

  toggleqone = (nr) => {
    const { one } = this.state;
    this.setState({
      one: nr,
      stoggleOne: true
    })
  }

  toggleTac = (nr) => {
    const { tac } = this.state;
    this.setState({
      tac: nr,
      stoggleTac: nr===1 ? true : false
    })
  }

//   toggleTac = (nr) => {
//     const { tac } = this.state;
//     this.setState({
//       tac: nr,
//       stoggleTac: nr===1 ? true : false
//     })
//   }

  handleChecked = () => {
    this.setState({stoggleTac: !this.state.stoggleTac});
  }

  toggleqtwo = (nr) => {
    const { two } = this.state;
    this.setState({
      two: nr,
      stoggleTwo: true
    })
  }
  
  toggleqthree = (nr) => {
    const { three } = this.state;
    this.setState({
      three: nr,
      stoggleThree: true
    })
  }

  toggleqfour = (nr) => {
    const { four } = this.state;
    this.setState({
      four: nr,
      stoggleFour: true
    })
  }

  toggleqfive = (nr) => {
    const { five } = this.state;
    this.setState({
      five: nr,
      stoggleFive: true
    })
  }
  toggleqsix = (nr) => {
    const { six } = this.state;
    this.setState({
      six: nr,
      stoggleSix: true
    })
  }
  toggleqseven = (nr) => {
    const { seven } = this.state;
    this.setState({
      seven: nr,
      stoggleSeven: true
    })
  }
  toggleqeight = (nr) => {
    const { eight } = this.state;
    this.setState({
      eight: nr,
      stoggleEight: true
    })
  }
  toggleqnine = (nr) => {
    const { nine } = this.state;
    this.setState({
      nine: nr,
      stoggleNine: true
    })
  }
  toggleqten = (nr) => {
    const { ten } = this.state;
    this.setState({
      ten: nr,
      stoggleTen: true
    })
  }

  scrollIt = () => {
    this.scollToRef.current.scrollIntoView()
  }

  render() {
    const { userInfo, getNewKeyx, opinions, feedback } = this.props;
    var selfComment;

    const handleClickOpenMain = () => {
      console.log('green handleClickOpen Main')
      this.getUserInfo()
      this.setState({ 
        Open: true
      });
    };
  
    const onClickz = (e) => {
      e.preventDefault();
      console.log("clicked");
    };

    // const handleClickOpen = () => {
    //   console.log('green handleClickOpen ---')
    //   this.setState({ Open: true });
    // };

    // const handleClose = () => {
    //   this.setState({ Open: false });
    // };
    // const handleClosex = () => {
    //   console.log('handleClose is running');
    //   this.setState({ Open: false });
    // };

    const getFollowers = (props) => {
      for (let x in props) {
        return props[x].analytics.followers
      }
    }
    // const scrollIt = () => {
    //     this.scollToRef.current.scrollIntoView()
    //   }
      console.log('green avatar view : ', this.props.src)
       var imgURL = this.props.src;

    return (
        
         <React.Fragment>
              <TableContainer
                    style={{
                      top: '-15px',
                      left: isMobile ? '-50px' : '0px',
                      width: isMobile ? '360px' : '100%',
                      maxWidth: isMobile ? '400px' : '100%',
                      height: '700px'
                      }}  
              >


                   <Table aria-label='simple table' ref={this.scollToRef}>
                  <TableBody>
                    {this.state.showButton ?
                    <div> 
                      <TableRow><TableCell colspan='4'><br/></TableCell></TableRow>  
                    <TableRow>
                        <TableCell colspan='4' bgColor='#000000' width={isMobile ? '330px' : '100%'}>
                        <div align='center'>
                          <font color='#ffffff'><b>Organisation Assessment</b></font>
                          {/* <input type="text" name="bla" id="bla" value={this.state.hiddenVal} /> */}
                          </div>
                        </TableCell>
                    </TableRow>  

                    <TableRow>
                <TableCell colspan='4'>
                  <div style={{
                    width: isMobile ? '330px' : '100%', 
                    display: 'block'
                  }}>
                 <p style={{display: 'block'}}> 
                 <b>A Personal Declaration:</b><br/>
                 I offer anonymous real-time feedback about the overall leadership culture in our organisation 
                 because I am committed to the purpose of assisting our leaders to become more aware of their 
                 leadership; to continuously raise their standards for a happier, conscious and more prosperous future. I do not offer real-time feedback with malicious intent. And as a leader / future 
                 leader I commit to invite others to give me real-time feedback as well. 
                 I also recognise that all assessments are in some measure based on perception, 
                 yet when facts and perception combine, it offers valuable insights.
                 </p>
                  {/* <p>Please rate the 10 statements below, then add comments behind every rating as to why you give that specific score</p> */}
                    </div>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colspan='4'>
                  <div>
                  <input type="checkbox" onChange={ this.handleChecked }/>&nbsp;&nbsp;I Agree
                  </div>
                </TableCell>
                </TableRow>   

                    <TableRow>
                <TableCell colspan='4'>
                  <div><b>In what capacity are you offering this feedback?:</b></div>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colspan='4'>
                  <div id="rslave">
                    <input type="radio" id="r1" name="slave" value="1" onClick={() => this.toggleSlave(1)}/>&nbsp;&nbsp;CEO - MD - Founder Level
                    <br/><input type="radio" id="r2" name="slave" value="2" onClick={() => this.toggleSlave(2)}/>&nbsp;&nbsp;Senior Leader - Director Level
                    <br/><input type="radio" id="r3" name="slave" value="3" onClick={() => this.toggleSlave(3)}/>&nbsp;&nbsp;Senior Leader - Executive Level
                    <br/><input type="radio" id="r4" name="slave" value="4" onClick={() => this.toggleSlave(4)}/>&nbsp;&nbsp;Senior Leader
                    <br/><input type="radio" id="r5" name="slave" value="5" onClick={() => this.toggleSlave(5)}/>&nbsp;&nbsp;Mid Level Leader
                    <br/><input type="radio" id="r6" name="slave" value="6" onClick={() => this.toggleSlave(6)}/>&nbsp;&nbsp;Junior Leader
                    <br/><input type="radio" id="r7" name="slave" value="7" onClick={() => this.toggleSlave(7)}/>&nbsp;&nbsp;Technical Expert Level
                    <br/><input type="radio" id="r8" name="slave" value="8" onClick={() => this.toggleSlave(8)}/>&nbsp;&nbsp;Other
                  </div>
                </TableCell>
                </TableRow>                   
        
                <TableRow>
                    <TableCell colspan='4'>
                      <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                      > <b>Rate objectively and constructively every one of these 10 statements on a scale of 1-10 (1 being poor and 10 being excellent), with comments to clarify:</b></div>
                    </TableCell>
                  </TableRow>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          >1. On a scale of 1-10 (1 being poor and 10 being excellent) how effective are the leaders in our organisation at leading themselves (Know clearly and are congruent at living personal values; are clear about and tuned in to a sense of purpose; have and strive towards achieving personal goals in life; are organised in general; are generally happy and at peace within; are balanced in physical, emotional, spiritual, financial and other areas of life.)?</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rone">
                            1<input type="radio" id="r1" name="qone" value="1" onClick={() => this.toggleqone(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qone" value="2" onClick={() => this.toggleqone(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qone" value="3" onClick={() => this.toggleqone(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qone" value="4" onClick={() => this.toggleqone(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qone" value="5" onClick={() => this.toggleqone(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qone" value="6" onClick={() => this.toggleqone(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qone" value="7" onClick={() => this.toggleqone(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qone" value="8" onClick={() => this.toggleqone(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qone" value="9" onClick={() => this.toggleqone(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qone" value="10" onClick={() => this.toggleqone(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason/s for your score, so that leadership can know what the organisations strengths are or what possible weaknesses to improve:</b>
                        </TableCell>
                      </TableRow>
                      : null}

                      {/* {feedback[0] ? Object.keys(feedback[0]).map(id => {
                        selfComment = feedback[0].selfopinion }
                        ): selfComment = this.state.selfopinion}   */}

                   {this.state.showButton ?
                      <TableRow >
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='qoneopinion'
                            id='qoneopinion'   
                            //value={this.state.selfopinion ? this.state.selfopinion : fb[0]}   
                            value={this.state.qoneopinion}  
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                qoneopinion: value
                              })
                            }}
                          />
        
                        </TableCell>
                      </TableRow>
                     :null}

                    {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          >2. On a scale of 1-10 (1 being poor and 10 being excellent) how effective are leaders at leading others (Mobilise others - their teams - around a clear and powerful vision; are able to move others towards achieving their own and their teams strategy and objectives; communicate effectively to individuals and teams; ensure unity, a healthy culture in their teams and organisation; in general are respected and viewed as having integrity and characters of substance.)? </div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtwo">
                            1<input type="radio" id="r1" name="qtwo" value="1" onClick={() => this.toggleqtwo(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qtwo" value="2" onClick={() => this.toggleqtwo(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qtwo" value="3" onClick={() => this.toggleqtwo(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qtwo" value="4" onClick={() => this.toggleqtwo(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qtwo" value="5" onClick={() => this.toggleqtwo(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qtwo" value="6" onClick={() => this.toggleqtwo(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qtwo" value="7" onClick={() => this.toggleqtwo(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qtwo" value="8" onClick={() => this.toggleqtwo(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qtwo" value="9" onClick={() => this.toggleqtwo(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qtwo" value="10" onClick={() => this.toggleqtwo(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason/s for your score, so that leadership can know what the organisations strengths are or what possible weaknesses to improve:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            // className={classes.maintextField}
                            name='qtwoopinion'
                            id='qtwoopinion'
                            //value={this.state.othersopinion ? this.state.othersopinion : fb[1]}
                            value={this.state.qtwoopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                qtwoopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      : null} 

                     {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          >3. On a scale of 1-10 (1 being poor and 10 being excellent) how effective are leaders at leading organisation (Drive the entire organisation or different divisions towards a high performance culture and consistent success; align systems, processes and procedures with the organisations vision and strategy, then manage these effectively; manage finances effectively and profitably; optimise effectively all available resources; manage effectively “things” as opposed to people.)? </div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rthree">
                            1<input type="radio" id="r1" name="qthree" value="1" onClick={() => this.toggleqthree(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qthree" value="2" onClick={() => this.toggleqthree(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qthree" value="3" onClick={() => this.toggleqthree(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qthree" value="4" onClick={() => this.toggleqthree(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qthree" value="5" onClick={() => this.toggleqthree(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qthree" value="6" onClick={() => this.toggleqthree(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qthree" value="7" onClick={() => this.toggleqthree(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qthree" value="8" onClick={() => this.toggleqthree(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qthree" value="9" onClick={() => this.toggleqthree(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qthree" value="10" onClick={() => this.toggleqthree(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that your Organisation can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            // className={classes.maintextField}
                            name='qthreeopinion'
                            id='qthreeopinion'
                            //value={this.state.orgopinion ? this.state.orgopinion : feedback[index].orgopinion}
                            value={this.state.qthreeopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                qthreeopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      : null}   

                    {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          >4. On a scale of 1-10 (1 being poor and 10 being excellent) how effective are leaders at leading society (making a difference outside of the work environment - church, family, community…; getting involved in making the world a better place beyond work.)?</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rfour">
                            1<input type="radio" id="r1" name="qfour" value="1" onClick={() => this.toggleqfour(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qfour" value="2" onClick={() => this.toggleqfour(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qfour" value="3" onClick={() => this.toggleqfour(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qfour" value="4" onClick={() => this.toggleqfour(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qfour" value="5" onClick={() => this.toggleqfour(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qfour" value="6" onClick={() => this.toggleqfour(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qfour" value="7" onClick={() => this.toggleqfour(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qfour" value="8" onClick={() => this.toggleqfour(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qfour" value="9" onClick={() => this.toggleqfour(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qfour" value="10" onClick={() => this.toggleqfour(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason/s for your score, so that leadership can know what the organisations strengths are or what possible weaknesses to improve:</b>
                        </TableCell>
                      </TableRow>
                      : null} 
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='qfouropinion'
                            id='qfouropinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.qfouropinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                qfouropinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}   

                  {/* {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>5.  Viewed as a personal responsibility, not the organisations - </b>Leadership development is accepted by all (most) leaders as a personal responsibility, not the organisations - we give our leaders the resources - an ecosystem - an induction 
                          and support. Then they run with their own development plan informed by real-time feedback, with a portfolio of evidence to show what they do. </div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rfive">
                            1<input type="radio" id="r1" name="qfive" value="1" onClick={() => this.toggleqfive(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qfive" value="2" onClick={() => this.toggleqfive(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qfive" value="3" onClick={() => this.toggleqfive(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qfive" value="4" onClick={() => this.toggleqfive(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qfive" value="5" onClick={() => this.toggleqfive(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qfive" value="6" onClick={() => this.toggleqfive(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qfive" value="7" onClick={() => this.toggleqfive(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qfive" value="8" onClick={() => this.toggleqfive(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qfive" value="9" onClick={() => this.toggleqfive(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qfive" value="10" onClick={() => this.toggleqfive(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that your Organisation can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='qfiveopinion'
                            id='qfiveopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.qfiveopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                qfiveopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}  

               {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>6. Bold Accountability - </b>While leaders are responsible for their own development, they are held accountable in a transparent way by those they work with and lead. Those who care enough to take the 
                          time to assess leaders real-time deserve to see at least the overall mean score. Therefore the overall mean score of every leader, by level, appears in a visible place - a leaderboard  </div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rsix">
                            1<input type="radio" id="r1" name="qsix" value="1" onClick={() => this.toggleqsix(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qsix" value="2" onClick={() => this.toggleqsix(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qsix" value="3" onClick={() => this.toggleqsix(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qsix" value="4" onClick={() => this.toggleqsix(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qsix" value="5" onClick={() => this.toggleqsix(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qsix" value="6" onClick={() => this.toggleqsix(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qsix" value="7" onClick={() => this.toggleqsix(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qsix" value="8" onClick={() => this.toggleqsix(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qsix" value="9" onClick={() => this.toggleqsix(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qsix" value="10" onClick={() => this.toggleqsix(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that your Organisation can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='qsixopinion'
                            id='qsixopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.qsixopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                qsixopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}  

               {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>7. Feedback real-time on leadership of all managers - </b>
                          <ul>
                            <li>Can be done at any time when a leader, colleagues, followers want to</li>
                            <li>Is simple; self-explanatory</li>
                            <li>Is easily accessible at all times for participants, from phone, laptop</li>
                            <li>Comes from above, same level and below the leader (360)</li>
                            <li>Is on at least three of the four universal (timeless) levels of leading self, others, organisation (non-people elements) and society (beyond work environment)</li>
                            <li>Produce results real-time - immediately</li>
                            <li>Offers an overall mean score and also mean scores per category of leading self, others, organisation and society</li>
                            <li>Offers comments and examples to leaders, not just ratings and scores</li>
                         </ul>
                         </div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rseven">
                            1<input type="radio" id="r1" name="qseven" value="1" onClick={() => this.toggleqseven(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qseven" value="2" onClick={() => this.toggleqseven(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qseven" value="3" onClick={() => this.toggleqseven(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qseven" value="4" onClick={() => this.toggleqseven(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qseven" value="5" onClick={() => this.toggleqseven(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qseven" value="6" onClick={() => this.toggleqseven(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qseven" value="7" onClick={() => this.toggleqseven(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qseven" value="8" onClick={() => this.toggleqseven(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qseven" value="9" onClick={() => this.toggleqseven(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qseven" value="10" onClick={() => this.toggleqseven(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that your Organisation can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='qsevenopinion'
                            id='qsevenopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.qsevenopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                qsevenopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}  

                 {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>8. Real-time rewards (some gamification) for participation  - </b>Leaders are rewarded - receive points - for all participation, real-time (immediately). So not only does their mean score display in a visible place but also their points. One can 
                          therefore see a correlation between their real-time feedback and real-time development activities.
                         </div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="reight">
                            1<input type="radio" id="r1" name="qeight" value="1" onClick={() => this.toggleqeight(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qeight" value="2" onClick={() => this.toggleqeight(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qeight" value="3" onClick={() => this.toggleqeight(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qeight" value="4" onClick={() => this.toggleqeight(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qeight" value="5" onClick={() => this.toggleqeight(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qeight" value="6" onClick={() => this.toggleqeight(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qeight" value="7" onClick={() => this.toggleqeight(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qeight" value="8" onClick={() => this.toggleqeight(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qeight" value="9" onClick={() => this.toggleqeight(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qeight" value="10" onClick={() => this.toggleqeight(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}

                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that your Organisation can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='qeightopinion'
                            id='qeightopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.qeightopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/240',
                                qeightopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}  

                {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>9. Access to quality resources - </b>Leaders have unlimited access to quality online and/or other programs, coaching options, content (articles, videos, podcasts), forums, webcasts and more, for which they are also 
                          rewarded with points when utilised, and upon completion of programs they receive certificates, immediately. 
                         </div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rnine">
                            1<input type="radio" id="r1" name="qnine" value="1" onClick={() => this.toggleqnine(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qnine" value="2" onClick={() => this.toggleqnine(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qnine" value="3" onClick={() => this.toggleqnine(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qnine" value="4" onClick={() => this.toggleqnine(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qnine" value="5" onClick={() => this.toggleqnine(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qnine" value="6" onClick={() => this.toggleqnine(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qnine" value="7" onClick={() => this.toggleqnine(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qnine" value="8" onClick={() => this.toggleqnine(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qnine" value="9" onClick={() => this.toggleqnine(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qnine" value="10" onClick={() => this.toggleqnine(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}

                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that your Organisation can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='qnineopinion'
                            id='qnineopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.qnineopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/240',
                                qnineopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}  

               {this.state.showButton ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>10. Direct impact on performance - </b>Our cultural belief that permeates across the organisation is that effective real-time leadership FEEDBACK and DEVELOPMENT will directly impact individual and 
                          organisational performance - it’s not just a nice to have activity. 
                         </div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rten">
                            1<input type="radio" id="r1" name="qten" value="1" onClick={() => this.toggleqten(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="qten" value="2" onClick={() => this.toggleqten(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="qten" value="3" onClick={() => this.toggleqten(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="qten" value="4" onClick={() => this.toggleqten(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="qten" value="5" onClick={() => this.toggleqten(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="qten" value="6" onClick={() => this.toggleqten(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="qten" value="7" onClick={() => this.toggleqten(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="qten" value="8" onClick={() => this.toggleqten(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="qten" value="9" onClick={() => this.toggleqten(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="qten" value="10" onClick={() => this.toggleqten(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}

                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that your Organisation can know what their strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='qtenopinion'
                            id='qtenopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.qtenopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/240',
                                qtenopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}  */}

                    {this.state.showButton ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <Button
                            variant='contained'
                            onClick={this.submitPost}
                            style={sendBtn}
                            inputProps={{ maxLength: 280 }}
                            aria-describedby='opinion'
                           // value={this.state.selfopinion}
                            helperText={this.state.maxLengthThought}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                opinion: value
                              })
                            }}
                          >
                            <SendIcon />
                            Submit
                          </Button>
                        </TableCell>
                      </TableRow>
                      : null}
                  </TableBody>
                   </Table>
                
              </TableContainer>
              
        
         </React.Fragment>
      
    ); 
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
    leaderboardData: state.users.leaderboardData,
    sopinions: state.Gopinions.Gsopinion,
    opinions: state.Gopinions.Gopinion,
    feedback: state.Copinions.Cfeedback,
    userdata: state.userDialog.userDetails[0] || null,
    //stateopinionID: state.opinions.opinionid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: params => dispatch(getUserProfile(params)),
    GaddOpinions: params => dispatch(GaddOpinions(params)),
    GgetOpinionsTrail: params => dispatch(GgetOpinionsTrail(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
    GgetOpinions: params => dispatch(GgetOpinions(params)),
    showToast: params => dispatch(showToast(params)),
    GgetOpinionsOnSubmit: params => dispatch(GgetOpinionsOnSubmit(params)),
    //FindSlave: params => dispatch(FindSlave(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser)
