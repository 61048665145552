/* eslint-disable no-nested-ternary */
/* eslint-disable no-inline-comments */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { teal, red, blue, green } from '@material-ui/core/colors'
import { connect } from 'react-redux'
import PLDPThoughts from './PLDP/PLDPThoughts'
import PLDPArticles from './PLDP/PLDPArticles'
import PLDPPodcasts from './PLDP/PLDPPodcasts'
import PLDPVideos from './PLDP/PLDPVideos'
import FilterListIcon from '@material-ui/icons/FilterList'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

function TabContainer(props) {
  return props.children
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    textAlign: 'right'
  },
  tabRoot: {
    'textAlign': 'right',
    'flexDirection': 'row',
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'padding': '0 11px',
    'minHeight': '61px',
    '&$tabSelected': {
      color: '#000000'
    }
  },
  tabSelected: {},
  tabsIndicator: {
    backgroundColor: '#000000'
  },
  videoIcon: {
    color: red[500]
  },
  messagesIcon: {
    color: teal[500]
  },
  articlesIcon: {
    color: blue[500]
  },
  podcastIcon: {
    color: green[500]
  },
  filter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginRight: theme.spacing(2)
  }
})

class MyFavorites extends React.Component {
	state = {
	  tabValue: 0,
	  filter: 'messages'
	};

	handleChange = (e, tabValue) => {
	  this.setState({ tabValue })
	};

	// handleFilterBy = (e) => {
	//   this.setState({ filter: e.target.value })
	//   }

	render() {
	  const { classes } = this.props
	  // const { tabValue } = this.state

	  return (

	    <div className={classes.root}>

	      {/* <div className={classes.filter}>
	        <FilterListIcon style={{ marginRight: '10px' }}/>
	        <Typography variant='subtitle1'>Filter By</Typography>
	        <FormControl className={classes.formControl}>
	          <Select
	            value={this.state.filter}
	            onChange={this.handleFilterBy}
	            displayEmpty
	            className={classes.selectEmpty}
	            inputProps={{ 'aria-label': 'Without label' }}
	          >
	            <MenuItem value={'messages'}>
            Messages
	            </MenuItem>
	            <MenuItem value={'videos'}>Videos</MenuItem>
	            <MenuItem value={'podcasts'}>Podcasts</MenuItem>
	            <MenuItem value={'articles'}>Articles</MenuItem>
	          </Select>
	        </FormControl>
	      </div> */}


	        <PLDPThoughts />


	    </div>
	  )
	}
}

MyFavorites.propTypes = {
  classes: PropTypes.object.isRequired
}
const Favorites = withStyles(styles)(MyFavorites)
const mapStateToProps = (state) => {
  return {
    userdata: state.userDialog.userDetails[0]
  }
}
export default connect(mapStateToProps, null)(Favorites)
