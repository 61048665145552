import { FOLLOW_USER, UNFOLLOW_USER, BASE_API_URL } from "./../actions/types";
import axios from "axios";
import firebase from "../firebase";

export const getUserFollowing = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref(`users/${params.userID}/following`)
      .on("value", function(data) {
        if (data.val()) {
          dispatch({
            type: "GET_FOLLOWING",
            following: data.val()
          });
        } else {
          return (dispatch, getState) => {
            dispatch({
              type: "GET_FOLLOWING",
              following: {}
            });
          };
        }
      });
  };
};
export const followUser = params => {
  return (dispatch, getState) => {
    let database = firebase.database;

    let following = params.followUnfollow;
    var followDate = Date.now();

    if (following == "follow") {
      var theirdata = {
        firstName: params.parentFirstName,
        lastName: params.parentLastName,
        companyName: params.parentCompanyName,
        dateRegistered: followDate,
        photoURL: params.parentPhotoURL,
        userID: params.parentUserID
      };

      var mydata = {
        firstName: params.followerFirstName,
        lastName: params.followerLastName,
        companyName: params.followerCompanyName,
        dateRegistered: followDate,
        photoURL: params.followerPhotoURL ? params.followerPhotoURL : "",
        userID: params.followerUserID
      };

      var updates = {};
      updates[
        "users/" + params.followerUserID + "/following/" + params.parentUserID
      ] = theirdata;
      updates[
        "users/" + params.parentUserID + "/follower/" + params.followerUserID
      ] = mydata;
      updates[
        "followers/" + params.parentUserID + "/" + params.followerUserID
      ] = mydata;

      database
        .ref()
        .update(JSON.parse( JSON.stringify(updates)))
        .then(() => {
          dispatch({
            type: "SHOW_TOAST",
            message: "You are now following : " + theirdata.firstName,
            variant: "success"
          });
        })
        .catch(err => {
          dispatch({
            type: "SHOW_TOAST",
            message: "Error: " + err.message,
            variant: "error"
          });
        });
    } else {
      var updates = {};
      updates[
        "users/" + params.followerUserID + "/following/" + params.parentUserID
      ] = null;
      updates[
        "users/" + params.parentUserID + "/follower/" + params.followerUserID
      ] = null;
      updates[
        "followers/" + params.parentUserID + "/" + params.followerUserID
      ] = null;

      database
        .ref()
        .update(updates)
        .then(() => {
          dispatch({
            type: "SHOW_TOAST",
            message: "You have unfollowed : " + params.parentFirstName,
            variant: "success"
          });
        })
        .catch(err => {
          dispatch({
            type: "SHOW_TOAST",
            message: "Error: " + err.message,
            variant: "error"
          });
        });
    }
  };
};
