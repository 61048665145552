import { UNFOLLOW_USER, FOLLOW_USER } from './../actions/types';

const initialState = {
	isFollowing: false,
	following: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_FOLLOWING':
			return {
				...state,
				following: action.following
			};
		case FOLLOW_USER:
			return {
				type: FOLLOW_USER,
				isFollowing: false,
				following: action.following
			};
		default:
			return state;
	}
};
