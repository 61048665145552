import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "23px",
    padding: "2px 16px",
    cursor: "pointer",
    color: "#000"
  },
  subtitle: {
    fontSize: "12px",
    marginBottom: "4px",
    lineHeight: "16px",
    color: "rgb(114, 76, 204)",
    padding: "2px 16px",
    cursor: "pointer"
  }
});

class ArticleCardsMessage extends React.Component {
  render() {
    const { classes, title, subtitle, color } = this.props;
    return (
      <React.Fragment>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </React.Fragment>
    );
  }
}

ArticleCardsMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default withStyles(styles)(ArticleCardsMessage);
