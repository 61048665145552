import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emphasize, withStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  Paper,
  Grid,
  Typography, Breadcrumbs, Chip, Tooltip, Button,
  Link as FileLink, ExpansionPanelActions, Modal,
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import MUIDataTable from 'mui-datatables'
import UploadFiles from '../../components/UploadFiles'
import DescriptionIcon from '@material-ui/icons/Description'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import indigo from '@material-ui/core/colors/indigo'
import { updateOrCreateProgrammes } from "../../store/actions/companyAdminActions";
import { SUPPORTED_FILE_TYPES, SHOW_TOAST } from "../../store/actions/types";
import { showToast } from '../../store/actions/toastAction'
import { DATE_FORMAT } from "../../utils/date_format_utils";
import FileViewer from "../FileViewer";
import AssignedGroups from "./AssignedGroups";
import { GREEN, RED } from "../../utils/color_utils";
import { RadioGroup, Radio } from 'react-radio-group'
import AddProgramme from './AddProgramme'

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 40 + rand()
const left = 50 + rand()

const columns = ['Programme name',]

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    boxShadow: 'none'
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  userTypeButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${indigo[500]}`,
    border: '1px solid',
  },
  archiveButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${RED}`,
    border: '1px solid',
  },
  activateButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${GREEN}`,
    border: '1px solid',
  },
  icon: {
    fontSize: '20px'
  }
})


let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    multiple,
    filterby,
    onSelectedFile,
    archiveSelectedProgramme,
    editSelectedProgramme,
  } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      elevation={0}
    >
      <div className={classes.title} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {numSelected === 1 ? (
              <React.Fragment>
                {filterby === 'active' ? (
                  <React.Fragment>
                    <Button
                      variant='outline'
                      aria-label='Edit programme'
                      onClick={editSelectedProgramme}
                      className={classes.userTypeButton}
                      size='small'
                    >
                      <EditIcon className={classes.icon} />
                      Edit
                    </Button>
                    <Button
                      variant='outline'
                      aria-label='Archive'
                      onClick={archiveSelectedProgramme}
                      className={classes.archiveButton}
                      size='small'
                    >
                      <PlaylistAddCheckIcon className={classes.icon} />
                      Archive
                    </Button>
                  </React.Fragment>
                ) : <React.Fragment>
                    <Button
                      variant='outline'
                      aria-label='Archive'
                      onClick={onSelectedFile}
                      className={classes.activateButton}
                      size='small'
                    >
                      <PlaylistAddCheckIcon className={classes.icon} />
                      Activate
                    </Button>
                  </React.Fragment>}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)


const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none',
    },
  },
}))(Chip);

const styles = theme => ({
  root: {
    marginTop: 0,
    overflowX: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '130px',
      padding: '0 10px'
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  pageHeader: {
    marginTop: 0,
    padding: theme.spacing(1)
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
})

class Programmes extends Component {
  state = {
    filterby: 'active',
    dataListClone: [],
    programmesList: [],
    openConfirm: false,
    open: false,
    programme: {}
  };

  options = {
    filterType: 'radio',
    selectableRows: 'single',
    customToolbarSelect: e => {
      return (
        <EnhancedTableToolbar
          numSelected={e.data.length}
          multiple={e.data.length > 0}
          options={{
            elevation: 0
          }}
          filterby={this.state.filterby}
          onSelectedFile={() =>
            this.handleSelectedProgramme()
          }
          archiveSelectedProgramme={() =>
            this.handleArchiveSelectedProgramme()
          }
          editSelectedProgramme={() =>
            this.handleEditSelectedProgramme()
          }
        />
      )
    },
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {

      // let data =
      //   users &&
      //   Object.keys(users).map((v, i) => {
      //     return { data: users[v], uid: users[v].id }
      //   })

      if (currentRowsSelected[0] !== undefined) {
        window.sf = this.state.dataListClone[currentRowsSelected[0].dataIndex];
      }
    }
  }

  handleEditSelectedProgramme = () => {
    let programme = window.sf;

    this.setState({
      programme: {
        companyID: programme[2],
        programme_name: programme[0],
        companyName: programme[3],
        updateValue: true,
        dateRegistered: programme[4],
        status: this.state.filterby,
        programme_id: programme[1]
      },
      open: true
    })
  };

  handleSelectedProgramme = () => {
    let programme = window.sf;

    this.props.updateOrCreateProgrammes({
      companyID: programme[2],
      programme_name: programme[0],
      companyName: programme[3],
      updateValue: true,
      dateRegistered: programme[4],
      status: this.state.filterby === 'active' ? 'archived' : 'active',
      programme_id: programme[1]
    });
  };

  handleArchiveSelectedProgramme = () => {
    this.setState({
      openConfirm: true
    })
  };

  confirmArchiveProgramme = () => {
    let programme = window.sf;

    this.props.updateOrCreateProgrammes({
      companyID: programme[2],
      programme_name: programme[0],
      companyName: programme[3],
      updateValue: true,
      dateRegistered: programme[4],
      status: this.state.filterby === 'active' ? 'archived' : 'active',
      programme_id: programme[1]
    });

    this.handleCloseConfirm()
  };

  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false
    })
  };

  handleClose = () => {
    this.setState({
      open: false,
      programme: {}
    })
  };

  componentDidMount() {

  }

  transformTableData = () => {
    let { company_programmes } = this.props
    let programmes = [];
    let dataListClone = [];
    let filtered_programmes = [];

    Object.keys(company_programmes).map((id, index) => {
      programmes.push(company_programmes[id])
    })

    filtered_programmes = programmes.filter(programme => programme.status.toLowerCase().includes(this.state.filterby.toLowerCase()));

    dataListClone = filtered_programmes.map((id, index) => {
      return [
        filtered_programmes[index].programme_name,
        filtered_programmes[index].programme_id,
        filtered_programmes[index].companyID,
        filtered_programmes[index].companyName,
        filtered_programmes[index].dateRegistered,
        filtered_programmes[index].status,
        filtered_programmes[index].companyID + "_" + filtered_programmes[index].status,
      ]
    })

    this.state = { ...this.state, dataListClone: dataListClone, programmesList: programmes };

    return dataListClone
  };

  handleFilteredFiles = (val) => {
    this.setState({
      filterby: val
    })
  }

  render() {
    const { programme } = this.state
    const { classes } = this.props

    return (
      <div>
        <Grid container spacing={2} gutterBottom style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={12}>
            <Paper style={{ maxWidth: '100vw' }} className={classes.pageHeader}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant='h5'>Programmes Configuration</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                      component={Link}
                      to={`/companyadmin`}
                      label="Club Admin"
                    />
                    <StyledBreadcrumb
                      component={Link}
                      to={`/groups`}
                      label="Manage Groups"
                    />
                    <StyledBreadcrumb
                      label="Programmes Configuration"
                    />
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <AddProgramme title='Add Programme' type='programme' />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ textAlign: 'center' }}>
                    <RadioGroup name="filteredtasks" selectedValue={this.state.filterby} onChange={this.handleFilteredFiles}>
                      <Radio value="active" /> Active
                      <Radio value="archived" style={{ marginLeft: '8px' }} /> Archived
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <MUIDataTable
                    title={''}
                    data={this.transformTableData()}
                    columns={columns}
                    options={this.options}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Modal
          aria-labelledby='confirmation-modal'
          aria-describedby='confirmation-modal-description'
          open={this.state.openConfirm}
          className={classes.modal}
        >
          <div style={modalStyle2} className={classes.paper}>
            <Typography variant='h6' gutterBottom>Please Confirm</Typography>
            <Typography variant='body2' gutterBottom>Do you want to archive the selected programme?</Typography>
            <div>
              <ExpansionPanelActions>
                <Button onClick={this.handleCloseConfirm} size='small'>Cancel</Button>
                <Button onClick={this.confirmArchiveProgramme} size='small' color='secondary'>Archive</Button>
              </ExpansionPanelActions>
            </div>
          </div>
        </Modal>

        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.open}
          onClose={this.handleClose}
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            <AddProgramme title='Edit Programme' type='programme' programme={programme} onClose={this.handleClose} />
          </div>
        </Modal>
      </div>
    )
  }
}

Programmes.propTypes = {
  classes: PropTypes.object,
}

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
}
const modalStyle2 = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}

const ProgrammesStyled = withStyles(styles)(Programmes)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    company_programmes: state.users.company_programmes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    updateOrCreateProgrammes: params => dispatch(updateOrCreateProgrammes(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgrammesStyled)

