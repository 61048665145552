import { createStore, applyMiddleware, compose } from "redux";
import appReducer from "./reducers";
import thunk from "redux-thunk";
//import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const initialState = {};

const middleware = [thunk];

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(...middleware),
    // other store enhancers if any
  )
  // compose(
  //   applyMiddleware(...middleware)
  //   //,
  //   //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
);

export default store;