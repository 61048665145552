import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import PostActivity from "./PostActivity";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    margin: "0 0"
  }
});

class ViewPostActivity extends React.Component {
  state = {};

  render() {
    const { classes, postActivity } = this.props;

    return (
      <div className={classes.root}>
        <PostActivity postActivity={postActivity} />
      </div>
    );
  }
}

ViewPostActivity.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewPostActivity);
