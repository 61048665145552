import { SHOW_TOAST, HIDE_TOAST } from './types'

export const showToast = params => {
  if (params.type === SHOW_TOAST) {
    return (dispatch, getState) => {
      dispatch({
        type: SHOW_TOAST,
        open: true,
        variant: params.variant,
        message: params.message,
        pos: params.pos
      })
    }
  }
  return (dispatch, getState) => {
    dispatch({
      type: 'NO_ERROR',
      open: '',
      variant: '',
      message: '',
      pos: ''
    })
  }
}

export const hideToast = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_TOAST, message: '', variant: '', pos: '' })
  }
}

export const clearStates = params => {
  return (dispatch, getState) => {
    dispatch({
      type: params.type
    })
  }
}
