import {
  GET_COMPANIES,
  SHOW_TOAST
} from './types'
import firebase from '../firebase'

export const getCompanies = params => {
  return (dispatch, getState) => {
    //
    firebase.database
      .ref('companies')
      .limitToLast(params.minData)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_COMPANIES,
            companies: data.val()
          })
        } else {
          dispatch({
            type: GET_COMPANIES,
            companies: {}
          })
        }
      })
  }
}

export const updateNewCompany = (params, edit) => {
  return (dispatch, getState) => {
    // set tbl ref
    let dbref = 'companies/'
    let dbref2 = 'active-companies'
    let companyID = ''

    // Generate companyID
    if (!edit) {
      companyID = firebase.database.ref(dbref).push().key
      params.companyID = companyID
    }

    // Write the new podcast's data
    let updates = {}
    updates[`/${dbref}/` + params.companyID] = params
    updates[`/${dbref2}/` + params.companyID + '/companyID'] = params.companyID
    updates[`/${dbref2}/` + params.companyID + '/companyName'] = params.companyName

    firebase.database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: SHOW_TOAST,
          variant: 'success',
          message: 'Company posted successfully!'
        })
      })
      .catch(err => {
        dispatch({
          type: 'SHOW_TOAST',
          open: true,
          variant: 'error',
          message: 'Error response: ' + err.message
        })
      })
  }
}
