import React, { Component } from 'react'
import { GET_POE } from '../store/actions/types'
import { getPoe } from '../store/actions/freshCardActions'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import {
  createTheme,
  MuiThemeProvider,
  getMuiTheme,
  withStyles
} from '@material-ui/core/styles'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'

class Poe extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    userID: this.props.location.state.Everyone
  };

  componentDidMount() {
    const { getPoe } = this.props
    console.log('getPoe : ', this.props.location.state.Everyone)
    this.props.getPoe({
      userID: this.state.userID,
      orderChild: 'userID'
    })
  }

// columns = ["No", "Action Type", "Action Desc", "Title", "Action Date", "pointsAdded"]
columns = ['No', 'Points Category', 'Category', 'Action', 'Action Date', 'pointsAdded']

  options={
    selectableRows: false,
    textLabels: {
      body: {
        noMatch: isMobile ? '...' : 'Sorry no result...'
      }
    }
  }

  timeConverter = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp)
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let year = a.getFullYear()
    // var month = months[a.getMonth()];
    let month = a.getMonth()
    month = month + 1

    let date = a.getDate()
    let vdate = date.toString()
    let vmonth = month.toString()
    let vvdate
    let vvmonth
    console.log('date len: ', vdate.length)
    // ///////////////////
    if (vdate.length === 1) {
      var s = '0' + date
      vvdate = s.substr(s.length - 2)
    } else {
      vvdate = vdate
    }
    // /////////////////////////
    if (vmonth.length === 1) {
      var s = '0' + month
      vvmonth = s.substr(s.length - 2)
    } else {
      vvmonth = month
    }
    // //////////////////
    // vdate = (Math.round(date * 100) / 100).toFixed(2);
    let hour = a.getHours()
    let min = a.getMinutes()
    let sec = a.getSeconds()
    // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    let time = year + '/' + vvmonth + '/' + vvdate
    return time
  }

  transformTableData = () => {
    let { classes, userdata, getAllCompanyUsers, poe } = this.props
    let nr
    console.log('white dataListClone poe ', this.props.poe)
    // convert obj to arr

    if (poe) {
      const values = Object.values(this.props.poe)
      // const fpoe = Object.values(this.props.poe);

      // sort by date
      values.sort((a, b) => new Date(b.fdate) - new Date(a.fdate))

      // convert arr to obj
      const fpoe = { ...values }

      // var objLeng = Object.keys(poe).length;
      let dataListClone = Object.keys(fpoe).map((trailID, index) => {
        console.log('green dataListClone 2 ', fpoe[trailID].firstName)
        // var ds = fpoe[trailID].dateRegistered;
        // var ddds = this.timeConverter(ds);
        // ["No", "Points Category", "Category", "Action", "Action Date", "pointsAdded"]
        nr = parseInt(trailID) + parseInt(1)
        return [
          nr,
          fpoe[trailID].actionType ? fpoe[trailID].actionType : '',
          fpoe[trailID].actionDesc ? fpoe[trailID].actionDesc.substring(0, 12) : '',
          fpoe[trailID].title ? fpoe[trailID].title.substring(0, 12) : '',
          fpoe[trailID].fdate ? fpoe[trailID].fdate : '',
          fpoe[trailID].pointsAdded ? fpoe[trailID].pointsAdded : ''
        ]
      })
      console.log('green dataListClone 3 ', dataListClone)
      return dataListClone
    }
  };

  render() {
    const { poe } = this.props
    console.log('Poe : ', this.props.poe)

    const getMuiTheme = () =>
    createTheme({
        overrides: {
          MuiChip: {
            root: {
              backgroundColor: '#000000'
            }
          }
        }
      })

    return (
      <div>
        <div><h3>Portfolio of Evidence</h3></div>
        {/* {this.props.poe ? */}
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={''}
            data={this.transformTableData()}
            columns={this.columns}
            options={this.options}
          // style={{id: "matrix"}}
          />
        </MuiThemeProvider>
        {/* : null} */}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userdata: state.userDialog.userDetails[0],
    poe: state.poe.poe
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPoe: params => dispatch(getPoe(params))
    // showToast: params => dispatch(showToast(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Poe)
