import { SHOW_SPEEDDIAL } from '../actions/types';

const initState = {
  type: SHOW_SPEEDDIAL,
  speedDPath: ''
};

//const initState = null;
const speedDialReducer = (state = 'initState' , action) => {
  //console.log('inside speedDialReducer :', action.speedDPath);
  if (action.speedDPath===undefined) {
    switch (action.type){
      case SHOW_SPEEDDIAL:
        return {
          ...state,
          speedDPath: 'blank'
        };
      default:
        return {
          ...state
        }
    }
  }
  else {
    switch (action.type){
		case SHOW_SPEEDDIAL:
			return {
				...state,
				speedDPath: action.speedDPath
			};
		default:
      return {
        ...state,
        speedDPath: null
      }
	}
 }

}

export default speedDialReducer;

// import { SURVEY_Q_LIST } from "../actions/types";

// const initState = {
//   type: SURVEY_Q_LIST,
//   survey_Q_list: []
// };

// const surveyQReducer = (state = initState, action) => {
//   switch (action.type) {
//     case SURVEY_Q_LIST:
//       return {
//         type: SURVEY_Q_LIST,
//         survey_Q_list: action.survey_Q_list
//       };

//     default:
//       return state;
//   }
// };

// export default surveyQReducer;
