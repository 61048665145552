/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-inline-comments */
/* eslint-disable no-dupe-keys */
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import ChatIcon from '@material-ui/icons/Chat'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MoreIcon from '@material-ui/icons/MoreVert'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import NotificationsDialog from './../Dialogs/NotificationsDialog'
import AddFeedbackDialog from './../Dialogs/AddFeedbackDialog'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import SideBar from './Sidebar'
import { showToast } from '../../store/actions/toastAction'
import CompanyLogo from './Companylogo'
import Loggeduser from './Loggeduser'
import Leaderboard from './../Dialogs/LeaderDialog'
// import {
//   getOpinions
//  } from '../../store/actions/opinionActions'
import { SIDEBARCOLOR, THEMECOLOR, TEALBLUE } from '../../utils/color_utils'
import { APP_URL_TITLE, USER_CLICKS_SECTION, IS_HARMONY, SHOW_SPEEDDIAL, SHOW_TOAST, LEADERBOARD_LOADING,  SIMPLE_VAL2 } from '../../store/actions/types'
import {
  captureUserActivity
} from '../../store/actions/analyticsActions'
// import {
//   getTopLeaders
// } from '../../store/actions/leaderboardActions'
import {
  loadingAction
} from '../../store/actions/loadingAction'

import { Link } from 'react-router-dom'
import { getContrastYIQ } from '../../store/actions/commonActions'
import isElectron from 'is-electron'
import {
  getAllCompanyLeaders
} from '../../store/actions/companyAdminActions'
import LeaderLoader from './LeaderLoader'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
// import {
//   getOpinions
// } from '../../store/actions/opinionActions'

// import { simpleValAction2 } from '../../store/actions/simpleValAction2'
// import axios from 'axios'
// import * as qs from 'qs'

const drawerWidth = 280

const styles = theme => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  engraved: {
    fontSize: '12px',
    fontFamily: 'Futura',
    // backgroundColor: '#666666',
    webkitBackgroundClip: 'text',
    mozBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent'
    // textShadow: 'rgba(245,245,245,0.5) 3px 5px 1px'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    position: 'absolute',
    right: '0px'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  mobileAppBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    minHeight: '60px',
    marginBottom: '10px'
  },
  mobileLogo: {
    maxWidth: '240px',
    maxHeight: '80px',
    width: 'auto',
    marginTop: '4px'
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    // backgroundColor: `${SIDEBARCOLOR}`,
    backgroundColor: '#fff',
    border: '1px'
  },
  closeIcon: {
    position: 'absolute',
    right: '0',
    top: '0',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  logoheader: {
    height: '130px',
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px'
  },
  badge: {
    backgroundColor: TEALBLUE,
    padding: 2,
    borderRadius: 3,
    color: '#fff'
  }
})

class Navbar extends React.Component {
  constructor(props) {
    super(props)
  }
  state = {
    mobileOpen: false,
    mobileMoreAnchorEl: null,
    open: false,
    users: '',
    openModel: false,
    scroll: 'paper',
    loaderbar: true,
    openNotes: false,
    openFeedback: false,
    themeColor: '#ffffff', // THEMECOLOR,
    user_manual: 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/user_manuals%2FThinkLead%20-%20Harmony%20Platform%20FAQ.pdf?alt=media&token=005772b4-d6df-4ba5-ba15-e02bb81e7c7b',
    report: 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/user_manuals%2FReport%20template.pdf?alt=media&token=7004c2db-2643-4847-af16-6ca94f30a1bc',
    docreport: 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/user_manuals%2FReport%20template3.docx?alt=media&token=4024ce97-4cd9-4456-afb9-155affac4a37',
    success: true,
    fillDetails: false
    // loadingLeaders: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  };

  closeMobileDrawer = () => {
    this.setState(state => ({ mobileOpen: false }))
  };

  handleMenuClose = () => {
    this.handleMobileMenuClose()
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget })
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null })
  };

  handleClickOpenModel = scroll => () => {
    this.setState({ openModel: true, scroll })
  };

  handleMModelClose = () => {
    this.setState({ openModel: false })
  };

  handleOpenNotifications = () => {
    this.setState({
      openNotes: !this.state.openNotes
    })

    let params2 = {
      userdata: this.props.userdata,
      type: USER_CLICKS_SECTION,
      page: 'openedNotificationsPage'
    }

    this.props.captureUserActivity({ ...params2 })

    this.handleMobileMenuClose()
  };

  handleOpenFeedback = () => {
    this.setState({
      openFeedback: !this.state.openFeedback
    })

    let params2 = {
      userdata: this.props.userdata,
      type: USER_CLICKS_SECTION,
      page: 'openedFeedbackPage'
    }

    this.props.captureUserActivity({ ...params2 })

    this.handleMobileMenuClose()
  };

  // openFileViewer = () => {
  //   let file = {
  //     filepath: this.state.user_manual,
  //     filename: "User Manual / FAQs.pdf",
  //   }
  //   console.log('openFileViewer: ', file);

  //   this.props.openFileViewer(file);
  // }

  openFileViewer = () => {
    let file = {
      filepath: this.state.report,
      filename: 'Report / Points.pdf'
    }
    console.log('openFileViewer: ', file)

    this.props.openFileViewer(file)
  }
  openManualFile = () => {
    window.open('https://firebasestorage.googleapis.com/v0/b/harmony-thinklead.appspot.com/o/HarmonyUserManual.pdf?alt=media&token=27cfee00-8f57-4015-a497-b048c8ffccd3')
  }  

  // openFileViewer = () => {
  //   let file = {
  //     filepath: this.state.docreport,
  //     filename: "Report / Points.docx",
  //   }
  //   console.log('openFileViewer: ', file);

  //   this.props.openFileViewer(file);
  // }

  setCompanyColor() {
    let LOGGED_COMPANY = JSON.parse(localStorage.getItem('GLP_LOGGED_COMPANY'))

    if (LOGGED_COMPANY) {
      this.setState({
        ...this.state,
        // themeColor: LOGGED_COMPANY.primaryColor || THEMECOLOR
        // themeColor: LOGGED_COMPANY.primaryColor
        themeColor: '#ffffff'
      })
    }
  }

  // sendGetRequest(cemail, props, status) {
  //   const { simpleValAction2 } = this.props
  //   console.log('green token reducer s1  : ', status);
  //   var code
  //   const data = {
  //     username: 'ZGYrTGlJc3VoZTZhbDZacmZrSHNDbW5iOTFTaXJha1pnbHY5Qkt2bDJWMD0=',
  //     password: 'AQ2GD&*Q'
  //   }
  //   const options = {
  //     method: 'POST',
  //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
  //     data: qs.stringify(data),
  //     url: 'https://mp.exec-club.com/api/auth/session-create'
  //   }

  //   axios(options)
  //     .then(function (response) {
  //     // this.setState({
  //     //   tokenData: response.data.token
  //     //  }, console.log('tokenData 1 : ', response.data.token));
  //       const data = {
  //         email_id: cemail
  //       }
  //       const xoptions = {
  //         method: 'POST',
  //         headers: {
  //           'Content-type': 'application/json',
  //           'Token': response.data.token },
  //         data: data,
  //         url: 'https://mp.exec-club.com/api/validate-user'
  //       }
  //      axios(xoptions)
  //         .then(res => {
  //           console.log('green token reducer s2 :', status);
  //           code = res.data.login_url
  //           if (code && code !== undefined) {
  //             // ///////////////////////////////reached here means EXECUTIVE CLUB member
        
  //            if (status==='2') {
  //           console.log('green token reducer s3 :', status);
  //             // let body = {
  //             //   type: SIMPLE_VAL2,
  //             //   surveyid: true,
  //             //   go: true
  //             // }
  //             //   simpleValAction2(body)

  //               // this.setState({
  //               //   calfmember: true
  //               // })
  //             }
  //             // /////////////////////////////
  //             if (status==='1') {
  //               console.log('green token reducer w :', status);
  //                window.open(res.data.login_url, '_blank')
  //             }

  //             if (status==='3') {
  //               console.log('green token reducer d :', status);
  //             let params = {
  //               type: SHOW_CALF,
  //               code: code,
  //               uid: props.userdata.uid,
  //               userID: props.userdata.userID
  //             }
  //              props.showCalf(params)
  //              window.open(res.data.login_url, '_blank')
  //             }
  //             console.log('valid calf member')
  //           } else {
  //             console.log('green params 5', cemail)
  //           }
  //         })
  //         .catch(error => {
  //           console.log('green params 6: ', error)
  //           return 2
  //         })
  //         .catch(function (error) {
  //           console.log('green params 7: ', error)
  //           return 3
  //         })
  //     })
  //     .catch(function (error) {
  //       console.log('green params 8: ', error)
  //       return 3
  //     })
  // }

  componentDidMount() {
    let { getAllCompanyLeaders, leaderboardData, userdata} = this.props

    this.setCompanyColor()

    //  // Note: if executive club member, if details not filled out, note to complete data ..
     if (userdata && userdata.execmember) {
      console.log('reddish userdata 1 : ', userdata)
     // this.sendGetRequest(userdata.email, this.props, '2') //set onething = true

     // update annual points
     console.log('yupdated 11 : ', userdata)

    //  if (userdata.yupdated && userdata.yupdated===true) {
    //   //do nothing
    //   console.log('yupdated 22 : ', userdata)
    //  } else {
    //   var ydata = {
    //   yupdated: false,
    //   pointsCalf: userdata.pointsCalf,
    //   uid: userdata.uid,
    //   userID: userdata.userID
    //  }
    //  // this.props.setYPoints(ydata);
    //  }

     ///////////////////////

          var pos = '';
          var nation = '';
          var depart = '';
          var Missing = false;
          var fmiss = false;

          if (userdata.position) { pos = '' } else {
            pos = 'Position '
            Missing = true;
          }
          if (this.props.userdata.nationality) { nation = ''} else {
            if (pos!=='') { nation = '& Country ' } else { nation = 'Country ' }
            Missing = true;
          }
          if (pos !== '' || nation !== '') { fmiss = true}

          if (this.props.userdata.department) { depart = '' } else {
            if (fmiss === true) {
              depart = ' & Sector'
            }
            else
            {
              depart = 'Sector'
            }

            Missing = true;
          }

          // if (this.props.userdata.execmember && Missing===true) { //notify executive club member of missing fields
       if ( Missing===true ) { //notify executive club member of missing fields
            // var params = {
            //   type: SHOW_TOAST,
            //   open: true,
            //   variant: 'success',
            //   message: 'Please fill in ' + pos + nation + depart + ' under your profile page & update to ensure you appear on the leaderboard ,and to activate your real-time points'
            // }
            // this.props.showToast(params)
       
          if (window.confirm('Please fill in ' + pos + nation + depart + ' under your profile page & update to ensure you appear on the leaderboard, and to activate your real-time points')) 
          {//nothing
          }
         }  
        }
    //   ///////////////////////////////////////////////////////////////////////////////

    if (this.props.leaderboardData) {
      var objLeng = Object.keys(this.props.leaderboardData).length
    } else {
      var objLeng = 0
    }

    if (objLeng > 4) { // LEADERBOARD DATA EXISTS
      console.log('green this.props.leaderboardData -A: ', this.props.leaderboardData)
    } else // NO LEADERBOARD DATA - CREATE NEW REQUEST FROM DATABASE
    {
      console.log('green this.props.leaderboardData A: ', this.props.leaderboardData)
    //  const promise = this.getUsers();
    //  promise.then(this.onFulfilled, this.onRejected);
    }
  }

  loadLeaderboard = () => {
    // this.setState({ loadingLeaders: true })
    this.props.loadingAction({
      leaderboard_loading: 1,
      type: LEADERBOARD_LOADING
    })
    console.log('green loadLeaderboard : ')
    const promise = this.getUsers()
    promise.then(this.onFulfilled, this.onRejected)
  }

  getUsers = () => {
    console.log('navbar x : ', this.props)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.state.success) {
          this.props.getAllCompanyLeaders({
            orderChild: 'LeaderboardKey',
            LeaderboardKey: 'x=x=x=x',
            companyID: 'XXXXXXXXX'
          })
        } else {
          reject('Failed to the user list')
        }
      }, 3000)
    })
  }

  onFulfilled = (valIn) => {
    console.log('white users : ', 'ooooooooooooooooo')
  }
  onRejected = (error) => {
    console.log('white error : ', error)
  }

  fillDetails() {
    // Note: if executive club member, if details not filled out, note to complete data ..
    if (this.props.userdata && this.props.userdata.execmember) {
      console.log('reddish userdata : ', this.props.userdata)
      let pos = ''
      let nation = ''
      let depart = ''
      let Missing = false
      let fmiss = false

      if (this.props.userdata.position) {
        pos = ''
      } else {
        pos = 'Position '
        Missing = true
      }
      if (this.props.userdata.nationality) {
        nation = ''
      } else {
        if (pos !== '') {
          nation = '& Country '
        } else {
          nation = 'Country '
        }
        Missing = true
      }
      if (pos !== '' || nation !== '') {
        fmiss = true
      }

      if (this.props.userdata.department) {
        depart = ''
      } else {
        if (fmiss === true) {
          depart = ' & Sector'
        } else {
          depart = 'Sector'
        }

        Missing = true
      }

    //  if (this.props.userdata.execmember && Missing === true) { // notify executive club member of missing fields
     if (Missing === true) { // notify executive club member of missing fields
        // let params = {
        //   type: SHOW_TOAST,
        //   open: true,
        //   variant: 'success',
        //   message: 'Please fill in ' + pos + nation + depart + ' under your profile picture and update to ensure you appear on the leaderboard and activate real-time points.'
        // }
        // this.props.showToast(params)
        if (window.confirm('Please fill in ' + pos + nation + depart + ' under your profile page, update and refresh, to ensure you activate real-time points and appear on the leaderboard.')) 
        {//nothing
        }   
      }
    }
    // /////// ////////// ////////// ///////// ////////// /////////// ////////// //////////
  }

  componentDidUpdate = () => {
    // setTimeout(() => {
    //   if (this.props.leaderboardData) {
    //    console.log('green componentDidUpdate leaderboardData : YES');
    //   } else {
    //    console.log('green componentDidUpdate leaderboardData : NO');
    //   }
    // }, 30000);

    if (this.props.userdata) {
     // update annual points
    //  console.log('yupdated 1 : ', this.props.userdata)
    //  this.props.getOpinions({ // loads the opinions of leaders recorded in state
    //   leaderID: this.props.userdata.userID
    //  })
     
    //  if (this.props.userdata.yupdated && this.props.userdata.yupdated===true) {
    //   //do nothing
    //   console.log('yupdated 2 : ', this.props.userdata)
    //  } else {
    //   var ydata = {
    //     yupdated: false,
    //     pointsCalf: this.props.userdata.pointsCalf,
    //     uid: this.props.userdata.uid,
    //     userID: this.props.userdata.userID
    //  }
    //  console.log('yupdated 3 : ', ydata)
    //  // this.props.setYPoints(ydata);
    //  }

     ///////////////////////
    }

    if (this.state.fillDetails === false) {
      if (this.props.userdata) {
        console.log('white componentDidUpdate : ', this.props.userdata)
        this.fillDetails()
        this.setState({ fillDetails: true })
      }
    }
    if (this.props.unseenotifications.unseenotificationscnt + this.props.unseengroupnotifications.unseengroupnotificationscnt + this.props.unseenpldpnotifications.pldpnotifications >= 0) {
      if (isElectron()) {
        let notificationNumber = this.props.unseenotifications.unseenotificationscnt + this.props.unseengroupnotifications.unseengroupnotificationscnt + this.props.unseenpldpnotifications.pldpnotifications
        let strNumber = notificationNumber.toString()
        window.ipcRenderer.send('set_new_notification', strNumber)
      }
    }
  }

  // componentWillUnmount() {
  //    if (this.props.leaderboardData) {
  //       console.log('green componentDidUpdate leaderboardData : YES');
  //      } else {
  //       console.log('green componentDidUpdate leaderboardData : NO');
  //      }
  // }

  componentDidCatch(error, errorInfo) {
    // errorService.report(errorInfo);
    this.setState({ error: error, errorInfo: errorInfo })
  }

  openPointsFile = () => {
    window.open('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/files%2FReport%20Template%204.docx?alt=media&token=18592213-5996-48a2-b634-9d19ca0b69bb')
  }

  openManualFile = () => {
    // window.open('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/files%2FUserManual.pdf?alt=media&token=d15e4d33-29f9-45b5-8bf7-c1e3d477f85a')
    window.open('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/TLE%20User%20Manual%2001-2022.pdf?alt=media&token=6542975a-dcc2-4610-a86a-a32a1e529eff')
  }
  toggleDrawer = (page) => {
    let params2 = {
      userdata: this.props.userdata,
      type: USER_CLICKS_SECTION,
      page
    }

    this.props.captureUserActivity({ ...params2 })
    this.toggleDrawer()

    let body = {
      type: SHOW_SPEEDDIAL,
      path: page
    }
    this.props.showSpeedDial(body)
  };

  //  move = () => {
  //    console.log('blue sky start move')
  //   var i = 0;
  //   if (i == 0) {
  //     i = 1;
  //     var elem = document.getElementById("myBar");
  //     var width = 10;
  //     var id = setInterval(frame, 100);
  //     function frame() {
  //       if (width >= 100) {
  //         clearInterval(id);
  //         i = 0;
  //       } else {
  //         width++;
  //         elem.style.width = width + "%";
  //         elem.innerHTML = width  + "%";
  //       }
  //     }
  //   }
  //  // document.elm.style.border = "3px solid #FF0000"; visibility: hidden
  //   document.getElementById("myBar").style.visibility="hidden";
  //   document.getElementById("myProgress").style.visibility="hidden";

  //   console.log('blue sky end move')
  // }

  render() {
    if (this.state.error && this.state.errorInfo) {
      console.log('Caught an Error: ', this.state.error.toString())
      return (
        <div>

          {/* <p>Caught an Error: {this.state.error.toString()}</p>
          <div>
            {this.state.errorInfo.componentStack}
          </div> */}
        </div>
      )
    }

    const { mobileMoreAnchorEl, themeColor } = this.state
    console.log('green userdata 2: ', this.props)

    const {
      classes,
      theme,
      unseenotifications,
      unseengroupnotifications,
      unseenpldpnotifications,
      location,
      users,
      leaderboardData,
      userdetails,
      loadingLBLeaders
    } = this.props

    console.log('green this.props.leaderboardData E : ', this.props.leaderboardData)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
    let ninja = 'I infiltrated the princess castle'
    var drawer = (<div>laurum isum kakalakachakala</div>)

    let userdetailsb = Object.assign({}, this.props.userdetails[0])
    console.log('green props investigate : ', userdetailsb)

    if (this.props.userdetails.length === 1) {
      drawer = (
        <div>
          <div className={classes.logoheader}>

            <div className={classNames(classes.closeIcon)}>
              <IconButton onClick={this.handleDrawerToggle}>
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <CompanyLogo />
          </div>
          {/* <Loggeduser userdetailsb={userdetailsb} toggleDrawer={this.closeMobileDrawer} opt={false} key={this.props.userdata.uid}/> */}
          <Loggeduser userdetailsb={userdetailsb} toggleDrawer={this.closeMobileDrawer} opt={false} key={this.props.userdetails[0].uid}/>
          {/* <Loggeduser toggleDrawer={this.closeMobileDrawer} opt={true} /> */}
          <SideBar toggleDrawer={this.closeMobileDrawer} />
          {/* {IS_HARMONY ? <HarmonySidebar toggleDrawer={this.closeMobileDrawer} />
          : <SideBar toggleDrawer={this.closeMobileDrawer} />} */}
        </div>
      )
    } else {
      var drawer = (<div />)
    }
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleOpenNotifications}>
          <IconButton color='inherit'>
            <Badge
              invisible={
                unseenotifications.unseenotificationscnt + unseengroupnotifications.unseengroupnotificationscnt + unseenpldpnotifications.pldpnotifications > 0
                  ? false
                  : true
              }
              badgeContent={
                <span className={classes.badge}>
                  {unseenotifications.unseenotificationscnt
                    + unseengroupnotifications.unseengroupnotificationscnt
                    + unseenpldpnotifications.pldpnotifications >
                    20
                    ? '20+'
                    : unseenotifications.unseenotificationscnt + unseengroupnotifications.unseengroupnotificationscnt +
                    unseenpldpnotifications.pldpnotifications}
                </span>
              }
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>

        {this.props.leaderboardData ?
          <Link to={{
            pathname: '/LeaderOnList',
            state: {
              Everyone: 'xx=x=x=x'
            }
          }}
          >
            <Button
              size='small'
              color='#000000'
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ChatIcon />
              <font style={{
                margin: '12px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                color: 'rgba(0,0,0,1)',
                fontSize: '12px'
              }}><b>&nbsp;&nbsp;LeaderTrack</b></font>
            </Button>
          </Link>
          :
          <MenuItem
            type='submit' onClick={() => this.loadLeaderboard()}
          >
            <IconButton color='inherit'>
              <ChatIcon />
            </IconButton>
            <p>Load LeaderTrack</p>
          </MenuItem>
        }

        <MenuItem onClick={this.handleOpenFeedback}>
          <IconButton color='inherit'>
            <ChatIcon />
          </IconButton>
          <p>Support</p>
        </MenuItem>

        {/* <MenuItem
          onClick={this.openPointsFile}
        >
          <IconButton color="inherit">
            <LiveHelpIcon />
          </IconButton>
          <p>GeneratePoints</p>
        </MenuItem> */}

        {/* <MenuItem
          onClick= {this.openManualFile}
        >

          <IconButton color='inherit'>
            <AccountBoxIcon />
          </IconButton>
          <p>User manual</p>
        </MenuItem> */}

        {/* <MenuItem
          onClick={this.handleMobileMenuClose}
          color="inherit"
          component={Link}
          to="/search"
        >
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
          <p>Search</p>
        </MenuItem> */}
      </Menu>
    )

    const mobileAppBar = (
      <React.Fragment>
        <AppBar
          position='fixed'
          elevation={0}
          className={classes.mobileAppBar}
          style={{
            backgroundColor: this.props.companydata
              ? this.props.companydata.topBarColor
              : '#fff',
            color: `${getContrastYIQ(
              this.props.companydata
                ? this.props.companydata.topBarColor
                : '#fff'
            )}`
          }}
        >
          <Toolbar
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={this.handleDrawerToggle}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open
              })}
              style={{ position: 'absolute', left: '0px' }}
            >
              <MenuIcon />
            </IconButton>
            {this.props.companydata ? (
              this.props.companydata.logo ? (
                <img
                  src={this.props.companydata.logo}
                  alt='LP'
                  className={classes.mobileLogo}
                />
              ) : (
                <Typography
                  className={classes.title}
                  variant='h6'
                  color='inherit'
                  noWrap
                >
                    ThinkLead
                </Typography>
              )
            ) : null}

            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup='true'
                onClick={this.handleMobileMenuOpen}
                color='inherit'
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          <div
            style={{
              backgroundColor: this.props.companydata
                ? this.props.companydata.primaryColor
                : '#fff',
              color: `${getContrastYIQ(
                this.props.companydata
                  ? this.props.companydata.primaryColor
                  : '#fff'
              )}`,
              marginTop: '1px',
              padding: '1px 1px'
            }}
          >
            <Typography
              className={classes.title}
              variant='h6'
              color='inherit'
              noWrap
            >
              {APP_URL_TITLE[location.pathname] || ''}
            </Typography>
          </div>
        </AppBar>
      </React.Fragment>
    )
    const desktopAppBar = (
      <AppBar
        position='fixed'
        elevation={0}
        className={classes.appBar}
        // style={{
        //   backgroundColor: this.props.companydata
        //     ? this.props.companydata.primaryColor
        //     : themeColor
        // }}
        style={{
          backgroundColor: '#ffffff'
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            onClick={this.handleDrawerToggle}
            className={classNames(classes.menuButton, {
              [classes.hide]: this.state.open
            })}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            className={classes.title}
            variant='h6'
            color='inherit'
            noWrap
          >
            <font style={{
              margin: '2px',
              // textShadow: '0.2px 0.2px rgb(197,179,88), -1px -1px rgba(0,0,0,1)',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              color: 'rgba(0,0,0,1)',
              fontSize: '25px'
            }}> {APP_URL_TITLE[location.pathname] || 'ThinkLead'} </font>
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>

            {/* <Button size="small" color="inherit" component={Link} to="/search">
              <SearchIcon />
              &#32;&#32;Search
            </Button> */}
            <Button
              size='small'
              color='#000000'
              onClick={this.handleOpenNotifications}
            >
              <Badge
                invisible={
                  unseenotifications.unseenotificationscnt ||
                    unseengroupnotifications.unseengroupnotificationscnt ||
                    unseenpldpnotifications.pldpnotifications > 0
                    ? false
                    : true
                }
                badgeContent={
                  <span className={classes.badge}>
                    {unseenotifications.unseenotificationscnt +
                      unseengroupnotifications.unseengroupnotificationscnt +
                      unseenpldpnotifications.pldpnotifications >
                      20
                      ? '20+'
                      : unseenotifications.unseenotificationscnt + unseengroupnotifications.unseengroupnotificationscnt +
                      unseenpldpnotifications.pldpnotifications}
                  </span>
                }
              >
                <NotificationsIcon />
              </Badge>
              <font style={{
                margin: '2px',
                // textShadow: '0.2px 0.2px rgb(197,179,88), -0.5px -0.5px rgba(0,0,0,1)',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                color: 'rgba(0,0,0,1)',
                fontSize: '14px'
              }}>
              &#32;&#32;
                {/* <span className={classes.engraved}>Notifications</span> */}
             Notifications
              </font>
            </Button>

            {/* <Leaderboard
                  props={this.props}
                /> */}
            {this.props.leaderboardData ?
              <Link to={{
                pathname: '/LeaderOnList',
                state: {
                  Everyone: 'xx=x=x=x'
                }
              }}
              >
                <Button
                  size='small'
                  color='#000000'
                >
                  <ChatIcon />
                  <font style={{
                    margin: '2px',
                    // textShadow: '0.2px 0.2px rgb(197,179,88), -0.5px -0.5px rgba(0,0,0,1)',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    color: 'rgba(0,0,0,1)',
                    fontSize: '14px'
                  }}>LeaderTrack</font>
                </Button>
              </Link>
              :
              <Button
                size='small'
                color='#000000'
                type='submit' onClick={() => this.loadLeaderboard()}
              >
                <ChatIcon />
                <font style={{
                  margin: '2px',
                  // textShadow: '0.2px 0.2px rgb(197,179,88), -0.5px -0.5px rgba(0,0,0,1)',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  color: 'rgba(0,0,0,1)',
                  fontSize: '14px'
                }}>Load LeaderTrack</font>
              </Button>
            }
            {/* <TextField
              name="leaderboardData"
              type="hidden"
              //value={}
              //onChange={this.handleChange}
            /> */}
            <Button
              size='small'
              onClick={this.handleOpenFeedback}
              color='#000000'
            >
              <ChatIcon />
              <font style={{
                margin: '2px',
                // textShadow: '0.2px 0.2px rgb(197,179,88), -0.5px -0.5px rgba(0,0,0,1)',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                color: 'rgba(0,0,0,1)',
                fontSize: '14px'
              }}>Support</font>
            </Button>

            {/* <Button
              size='small'
              onClick={this.openPointsFile}
              color='#000000'
            >

              <AccountBoxIcon />
              <font style={{
                margin: '2px',
                // textShadow: '0.2px 0.2px rgb(197,179,88), -0.5px -0.5px rgba(0,0,0,1)',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                color: 'rgba(0,0,0,1)',
                fontSize: '14px'
              }}>User Manual</font>
            </Button> */}
            
          </div>
          <div />
          <div className={classes.sectionMobile}>
            <IconButton
              aria-haspopup='true'
              onClick={this.handleMobileMenuOpen}
              color='#000000'
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    )

    return (
      <div className={classes.root}>

        {/* { this.props.leaderboardData ? null :
        <div style={{
          width:'100%',
          height: '20px'
          }}><LeaderLoader /></div>
         } */}

        {isMobile ?
          <div><br /><br /></div> :
          <div />
        }
        { this.props.leaderboardData ? null : loadingLBLeaders === 1 ?
          <div style={{
            color: 'red',
            align: 'center'
          }}>Loading LeaderTrack...</div> : null
        }
        <CssBaseline />
        <Hidden smUp implementation='css'>
          {mobileAppBar}
        </Hidden>
        <Hidden xsDown implementation='css'>
          {desktopAppBar}
        </Hidden>
        <nav className={classes.drawer}>

          <NotificationsDialog
            open={this.state.openNotes}
            onOpenNotifications={this.handleOpenNotifications}
          />
          <AddFeedbackDialog
            open={this.state.openFeedback}
            onOpenFeedback={this.handleOpenFeedback}
          />

          <Hidden smUp implementation='css'>
            <Drawer
              container={this.props.container}
              variant='temporary'
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              {drawer}
            </Drawer>

          </Hidden>
          <Hidden xsDown implementation='css'>
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant='permanent'
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        {renderMobileMenu}
      </div>
    )

  // /////////////////////////////////////
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

// const mapStateToProps = state => {
//   return {

const mapStateToProps = (state, ownProps) => {
  return {
    userdetails: state.userDialog.userDetails,
    userdata: state.userDialog.userDetails[0],
    unseenotifications: state.userdata.unseenotifications,
    unseengroupnotifications: state.userdata.unseengroupnotifications,
    unseenpldpnotifications: state.userdata.unseenpldpnotifications,
    companydata: state.loggedInCompany.companydata,
    leaderboardData: state.users.leaderboardData,
    loadingLBLeaders: state.loadingLeaders.leaderboardloading,
   //opinions: state.opinions.opinion
  }
}

const mapDispatchToProps = dispatch => {
  return {
    captureUserActivity: params => dispatch(captureUserActivity(params)),
    getAllCompanyLeaders: params => dispatch(getAllCompanyLeaders(params)),
    //setYPoints: params => dispatch(setYPoints(params)),
    showToast: params => dispatch(showToast(params)),
    loadingAction: params => dispatch(loadingAction(params)),
    // getOpinions: params => dispatch(getOpinions(params)),
   // simpleValAction2: params => dispatch(simpleValAction2(params))
  }
}

const PrimaryAppBarStyled = withStyles(styles, { withTheme: true })(
  Navbar
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrimaryAppBarStyled)
)
