import {
	CREATE_PLDP_TASK,
	GET_MY_PLDP_TASKS,
	GET_ALL_MY_PLDP_TASKS,
	GET_MYPLPD_FAVORITES,
	GET_COMPANY_VALUES,
	DELETE_PLDP_TASK,
	GET_SELECTED_ROW
} from '../actions/types';

const initState = {
	pldpTasks: [],
	pldpAllMyTasks: [],
	newPldpTask: {},
	myPLDPFavorites: {},
	companyValues: [],
	selectedCompanyValue: {}
};

const pldpReducer = (state = initState, action) => {
	switch (action.type) {
		case CREATE_PLDP_TASK:
			return {
				...state,
				newPldpTask: action.newPldpTask,
				type: CREATE_PLDP_TASK
			};
		case GET_MY_PLDP_TASKS:
			return {
				...state,
				pldpTasks: action.pldpTasks
				//	type: GET_MY_PLDP_TASKS
			};
		case GET_ALL_MY_PLDP_TASKS:
			return {
				...state,
				pldpAllMyTasks: action.pldpAllMyTasks
			};
		case DELETE_PLDP_TASK:
			return {
				...state,
				pldpTasks: action.pldpTasks
				//	type: GET_MY_PLDP_TASKS
			};
		case GET_COMPANY_VALUES:
			return {
				...state,
				companyValues: action.companyValues
				//	type: GET_MY_PLDP_TASKS
			};
		case GET_MYPLPD_FAVORITES:
			return {
				...state,
				myPLDPFavorites: action.myPLDPFavorites
				//	type: GET_MY_PLDP_TASKS
			};
		case GET_SELECTED_ROW:
			return {
				...state,
				selectedCompanyValue: action.selectedCompanyValue
			};
		default:
			return state;
	}
};

export default pldpReducer;
