/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emphasize, withStyles } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import {
  Paper,
  Grid, Typography, Tooltip, Breadcrumbs, Chip
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import UploadFiles from '../../components/UploadFiles'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import IconButton from '@material-ui/core/IconButton'

const columns = ['Name', 'Size', 'Type', 'Last Modified']

const data = [
  ['Executive Programs Materials', '-', 'Folder', '-'],
  ['Leadership Training Materials', '-', 'Folder', '-'],
  ['File 1', '183.08 KB', 'application/word', 'Mar 4, 2020'],
  ['File 2', '183.08 KB', 'application/xlsx', 'Mar 5, 2020'],
  ['File 3', '183.08 KB', 'application/pptx', 'Mar 6, 2020']
]

const options = {
  filterType: 'checkbox'
}

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none',
    },
  },
}))(Chip);

const styles = theme => ({
  root: {
    marginTop: 0,
    overflowX: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '130px',
      padding: '0 10px'
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  pageHeader: {
    marginTop: 0,
    padding: theme.spacing(1)
  }
})

class FileRepo extends Component {
  state = {
    uploadinprogress: false,
    filetitle: '',
    fileurl: '',
    fileuploaded: false,
    filesObj: []
  };

  fileUploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      fileurl: url,
      filetitle: filename,
      fileuploaded: false
    })

    let obj = {}


    let objarray = []


    let isValidURL = false


    let isValidTitle = ''; let prop = 'filesObj'

    objarray = this.state[prop]


    if (prop === 'filesObj') {
      isValidTitle = filename
      isValidURL = url

      obj = {
        title: this.state.filetitle,
        url: this.state.fileurl
      }
    }

    objarray.push(obj)

    this.state = { ...this.state, [prop]: objarray }
    // this.props.onChange([prop], objarray);
    // this.resetFields(prop);

    this.props.onChange('fileurl', {
      fileurl: url,
      filetitle: filename,
      fileuploaded: false
    })

    // this.props.onChange("uploadInProgress", false);
  };

  uploadInProgress = status => {
    this.setState({
      ...this.state,
      uploadinprogress: status
    })

    // this.props.onChange("uploadInProgress", status);
  };

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Paper style={{ maxWidth: '100vw' }} className={classes.pageHeader}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant='h5'>Files Repository</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  component={Link}
                  to={`/companyadmin`}
                  label="Club Admin"
                />
                <StyledBreadcrumb
                  label="Files Repository"
                />
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={10}>
                  <UploadFiles
                    onUploadComplete={this.fileUploadFinished.bind(this)}
                    onUploadProgress={this.uploadInProgress.bind(this)}
                    uploadtype='file'
                  />
                </Grid>
                <Grid item xs={6} sm={2} style={{ textAlign: 'left' }}>
                  <Tooltip title='Create Folder'>
                    <IconButton aria-label='create folder' className={classes.margin} size='small'>
                      <CreateNewFolderIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <MUIDataTable
                title={''}
                data={data}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

FileRepo.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedGroup: PropTypes.func.isRequired
}


const FileRepoStyled = withStyles(styles)(FileRepo)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0]
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileRepoStyled)
