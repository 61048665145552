import React from "react";
import ReactDOM from "react-dom";
import "./Index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPlayer from 'react-player'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'

 class ShowTutorial extends React.Component {
  constructor (props) {
     super(props);
  }

  state = {
    showPic: false,
    mgroupid: this.props.groupID,
    mgroupname: this.props.title,
  }

  componentDidMount() {
    console.log('blue surveyType: ', this.props);
   }

  render(){

    return (
        <React.Fragment>         
        <div>       
        <ReactPlayer 
          url={this.props.url}
         // url='https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/PointsSystem6.mp4?alt=media&token=05f11b29-11b7-4eee-ae7c-2ec06590508b' 
          className='react-player'
          playing
          width={isMobile ? '100%' : '500px'}
          height={isMobile ? '100%' : '300px'}
          />

        </div>                                                                                                                  
        </React.Fragment> 

    )
  }
}

 export default ShowTutorial;


