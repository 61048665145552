/* eslint-disable no-inline-comments */
'use strict'
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Tab, Tabs
} from '@material-ui/core'
import { LIGHTBLUE, DARK } from '../../utils/color_utils'
import { withStyles } from '@material-ui/core/styles'
import ImpressionsList from './elements/ImpressionsList'
import StatsCard from './elements/StatsCard'
import { fetchAnalytics } from './../../store/actions/analyticsActions'
import {
  GET_COMPANY_ANALYTICS,
  GET_ACTIVE_COMPANY_USERS,
  GET_COMPANY_COUNTS,
  GET_ACTIVE_COMPANIES
} from '../../store/actions/types'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import OverviewAnalytics from './OverviewAnalytics'
import DetailedAnalytics from './DetailedAnalytics'


function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 0, marginTop: 6 }}>
      {props.children}
    </Typography>
  )
}

window.moment = moment
const styles = theme => ({
  root: {
    marginTop: 0,
    // padding: "10px",
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '110px'
    },
    primary: `${LIGHTBLUE}`,
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: '#1890ff'
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    minHeight: '-webkit-fill-available',
    minHeight: '-moz-fill-available',
    minHeight: 'fill-available',
    background: 'transparent'
  },
  bluepaper: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(2)

  },
  tabsInnerDiv: {
    marginTop: '16px',
    background: '#fff!important',
    borderBottom: '1px solid #e8e8e8'
  },
  paperContainer: {
    padding: '6px',
    marginTop: 0
  }

})

const tabsdiv = {
  flexGrow: 1,
  width: '100%!important',
  backgroundColor: '#fff'
}

class Analytics extends Component {
  state = {
    month: 'All',
    year: moment().format('YYYY'),
    activeUsersPeriod: moment().format('YYYY'),
    currentPeriod: moment().format('YYYY'),
    useractive: 'year-active-users',
    selectedCompany: {},
    company: '',
    tabsvalue: 0
  };

  handleTabsChange = (event, tabsvalue) => {
    this.setState({ tabsvalue })
  }

  componentDidMount() {
    if (this.props.userdata) {
      this.setState({
        company: this.props.userdata.companyID
      })
    }
  }

  renderCompanies = () => {
    const { activeCompanies } = this.props
    return Object.values(activeCompanies).map((v, i) => (
      <MenuItem key={i} value={v.companyID}>
        {v.companyName}
      </MenuItem>
    ))
  };

  renderMonths = () => {
    const months = [
      'All',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    return months.map((v, i) => (
      <MenuItem key={i} value={v}>
        {v}
      </MenuItem>
    ))
  };


  render() {
    const {
      classes,
      analytics,
      activeCompanies,
      activeusersanalytics
    } = this.props
    const { tabsvalue } = this.state

    return (
      <div className={classes.root}>
        <Typography className={classes.paperContainer} variant='h5'>
          Analytics Dashboard
        </Typography>
        <div style={{ tabsdiv }}>
          {/* <AppBar position='static' color='default' > */}
          <Tabs
            value={tabsvalue}
            onChange={this.handleTabsChange}
            variant='fullWidth'
            indicatorColor='primary'
            textColor='primary'
            aria-label='group tabs'
            className={classes.tabsInnerDiv}
          >
            <Tab aria-label='overview' label='Overview' />
            {/*  <Tab aria-label='detailed' label='Detailed' /> */}
          </Tabs>
          {/* Overview Tab */}
          {tabsvalue === 0 && (
            <TabContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper elevation={0} className={classes.paper}>
                    <Grid item xs={12}>
                      <div>
                        <OverviewAnalytics {...this.props} />
                      </div>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {/* Detailed Tab */}
          {/*  {tabsvalue === 1 && (
            <TabContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper elevation={0} className={classes.paper}>
                    <Grid item xs={12}>
                      <div>
                        <DetailedAnalytics />
                      </div>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabContainer>
          )} */}
        </div>
      </div>
    )
  }
}

Analytics.propTypes = {
  analytics: PropTypes.object.isRequired,
  activeCompanies: PropTypes.object.isRequired,
  fetchAnalytics: PropTypes.func.isRequired,
  userdata: PropTypes.object.isRequired
}

const sendBtn = {
  backgroundColor: `${LIGHTBLUE}`,
  color: '#fff'
}

const mapStateToProps = (state, ownProps) => {
  return {
    analytics: state.analytics.analytics,
    activeCompanies: state.analytics.activeCompanies,
    companycountsanalytics: state.analytics.companycountsanalytics,
    activeusersanalytics: state.analytics.activeusersanalytics,
    userdata: state.userDialog.userDetails[0]
  }
}
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchAnalytics
    },
    dispatch
  )
})

const AnalyticsPage = withStyles(styles)(Analytics)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsPage)
