import React, { Component } from "react";
import { EBOOKS } from "../utils/photo_utils";

class Ilead extends Component {
  render() {
    return (
      <div
        style={{
          height: "100vh",
          backgroundColor: "#c9a27099"
        }}
      >
        <img
          style={{ width: "100%" }}
          src={EBOOKS}
          alt="Ebooks coming soon..."
        />
      </div>
    );
  }
}

export default Ilead;
