import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { CloudDownload } from '@material-ui/icons';
import { APP_TYPE } from "../../store/actions/types";

class WindowsDButton extends React.Component {

  constructor (props) {
    super(props);
  }

  chooseOS() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    var macstate = navigator.appVersion.indexOf("Mac");

    if (winstate !== -1) { winner = "win" }
    if (linuxstate !== -1) { winner = "linux" }
    if (macstate !== -1) { winner = "mac" }
    // console.log('chooseOS: ',winner);
    return winner;
  }

  chooseOSpublic() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var macstate = navigator.appVersion.indexOf("Mac");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    winner = 'www.google.com';

    var server = window.location.host
    switch (server) {
      case "thinklead.app":         //live public server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fpublic%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=df76331e-ea29-45b6-b8bb-b157379ab85b';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        break;

      case "glp-test.appspot.com":  //test server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fwindows%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=92dad5e5-ea6d-4bcd-8474-f326a35fcb1d';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fmac%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=a24e2ffb-eac8-4b41-9888-4a4085988ccb';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;

      default:
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fpublic%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=df76331e-ea29-45b6-b8bb-b157379ab85b';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fpublic%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=2d95b319-30ac-457c-a399-9e32d30ee28e';
        }
        break;

    }

    return winner;
  }

  chooseOScorporate() {
    var winner;
    var winstate = navigator.appVersion.indexOf("Win");
    var macstate = navigator.appVersion.indexOf("Mac");
    var linuxstate = navigator.appVersion.indexOf("Linux");
    //placeholder
    winner = 'www.google.com';

    var server = window.location.host
    switch (server) {
      case "thinklead.app":         //live corporate server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fcorporate%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=d7e3a460-23d3-4fd2-9c72-64b3a5320c58';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;

      case "glp-test.appspot.com":  //test server
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fwindows%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=92dad5e5-ea6d-4bcd-8474-f326a35fcb1d';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/glp-test.appspot.com/o/desktopapps%2Fmac%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=a24e2ffb-eac8-4b41-9888-4a4085988ccb';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;

      case "harmony.thinklead.co.za":  //Harmony
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fcorporate%2Fharmony%2FLDP%20Support%20Portal%20Setup%200.1.0.exe?alt=media&token=1153215c-37c5-45f6-95e4-86b85a823225';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2Fharmony%2FLDP%20Support%20Portal-1.0.0.dmg?alt=media&token=a9ec35ef-34b9-4afb-b6a5-5b6cc1cf6a2a';
        }
        if (linuxstate !== -1) {
          winner = '';
        }
        break;

      default:
        if (winstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fwindows%2Fcorporate%2FGlobal%20Leadership%20Platform%20Setup%200.1.0.exe?alt=media&token=d7e3a460-23d3-4fd2-9c72-64b3a5320c58';
        }
        if (macstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        if (linuxstate !== -1) {
          winner = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/desktopapps%2Fmac%2Fcorporate%2FGlobal%20Leadership%20Platform-0.1.0.dmg?alt=media&token=8a210c51-c342-4fed-b6e8-c09cd4f365b0';
        }
        break;
    }

    return winner;
  }

  render() {

    return (
      <span>
        {(this.chooseOS() === "win") ?
          <a style={{
            textDecoration: 'none', color: 'white', backgroundColor: 'white'
          }} href={
            (APP_TYPE === "PUBLIC") ?
              this.chooseOSpublic()
              : this.chooseOScorporate()
          } download target="_self">
            <Button key="undo" fullWidth="true" style={{ backgroundColor: '#43803B', color: 'white' }}>
              Download GLP Desktop app <CloudDownload style={{ paddingRight: '5px', paddingLeft: '15px', height: '34px', fontSize: 40 }} />
            </Button>
          </a>
          : null}
      </span>
    )
  }
}

export default WindowsDButton;