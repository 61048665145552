/* eslint-disable no-undefined */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GroupIcon from '@material-ui/icons/GroupWork'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { LIGHTBLUE, LIGHTGREY } from '../../utils/color_utils'
import firebase from '../../store/firebase'
import Badge from '@material-ui/core/Badge'
import { DEEPORANGE } from '../../utils/color_utils'
import { SET_SEEN_GROUP_NOTIFICATIONS } from '../../store/actions/types'
import { fetchUserData } from '../../store/actions/userActions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  groupslabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: `${LIGHTBLUE}`,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderBottom: `1px solid ${LIGHTGREY}`,
    padding: theme.spacing()
  },
  groupText: {
    fontSize: '0.875rem!important'
  },
  badge: {
    backgroundColor: DEEPORANGE,
    padding: 3,
    borderRadius: 4,
    color: '#fff',
    marginLeft: '12px'
  }
})

class GroupsList extends Component {
  state = {
    groups: [],
    initPosts: false
  };
  componentWillMount() {
    let database = firebase.database
    let ref = 'company_user_groups/' + this.props.userdata.userID

    if (this.props.userdata.userType === 7 || this.props.userdata.userType === 14) {
      ref = 'company_groups/' + this.props.userdata.companyID
    }

    database
      .ref(ref)
      .once('value')
      .then(snapshot => {
        const groupsArr = []

        snapshot.forEach(childSnapshot => {
          if (childSnapshot.val().active === true || childSnapshot.val().active === undefined) {
            groupsArr.push({
              id: childSnapshot.key,
              group_id: childSnapshot.val().group_id,
              group_name: childSnapshot.val().group_name,
              group_details: childSnapshot.val()
            })
          }
        })
        this.setState({ groups: groupsArr })
      })
  }

  componentDidUpdate() {
    if (this.state.initPosts === false) {
      if (this.state.groups.length > 0) {
        this.props.selectedGroup(this.state.groups[0])

        this.props.fetchUserData({
          type: SET_SEEN_GROUP_NOTIFICATIONS,
          userID: this.props.userdata.userID,
          groupid: this.state.groups[0].group_id,
          unseengroupnotifications: this.props.unseengroupnotifications.unseengroupnotifications
        })
      }

      this.setState({ initPosts: true })
    }
  }

  selectedList = (id, item) => {
    this.props.selectedGroup(item)

    this.props.fetchUserData({
      type: SET_SEEN_GROUP_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      groupid: item.group_id,
      unseengroupnotifications: this.props.unseengroupnotifications.unseengroupnotifications
    })
  };

  render() {
    const { classes, unseengroupnotifications } = this.props
    return (
      <div>
        <div className={classes.root}>
          <Typography
            className={classes.groupslabel}
            variant='subtitle2'
            gutterBottom
          >
            <GroupIcon /> My Groups
          </Typography>
          <List component='nav' aria-label='Secondary mailbox folders'>
            {this.state.groups
              ? this.state.groups.map(item => {
                return (
                  <ListItem
                    button
                    style={{ fontSize: '0.785rem' }}
                    divider={true}
                    key={item.id}
                    onClick={this.selectedList.bind(
                      this,
                      item.id,
                      item.group_details
                    )}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.groupText}
                          component='strong'
                        >
                          {item.group_name}
                            &nbsp;
                          <Badge
                            invisible={
                              unseengroupnotifications[item.group_id] ? false : true
                            }
                            badgeContent={
                              <span className={classes.badge}>
                                {unseengroupnotifications[item.group_id]}
                              </span>
                            }
                          />
                        </Typography>
                      }
                    />
                  </ListItem>
                )
              })
              : <div
                style={{ textAlign: 'center', padding: '100px 20px 20px' }}>
                No groups available
              </div>}
          </List>
        </div>
      </div>
    )
  }
}

GroupsList.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedGroup: PropTypes.func.isRequired
}

const GroupsListStyled = withStyles(styles)(GroupsList)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    unseengroupnotifications: state.userdata.unseengroupnotifications
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUserData: params => dispatch(fetchUserData(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsListStyled)
