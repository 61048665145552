import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emphasize, withStyles } from '@material-ui/core/styles'
import { getCompanyValues, deleteCompanyValue } from '../../store/actions/pldpActions'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions, Grid, Typography, Button, Modal, Breadcrumbs, Chip } from '@material-ui/core'
import { Link } from 'react-router-dom'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CancelIcon from '@material-ui/icons/Cancel'
import AccountBalance from '@material-ui/icons/AccountBalance'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { VALUES_NAME, SHOW_TOAST } from '../../store/actions/types'
import { showToast, clearStates } from '../../store/actions/toastAction'
import { LIGHTGREY } from '../../utils/color_utils'
import AddCompetency from './AddCompetency'
import SubCompetencies from './SubCompetencies'

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 40 + rand()
const left = 50 + rand()

const styles = theme => ({
  root: {
    marginTop: 0,
    padding: '10px',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '110px'
    }
  },
  panel: {
    marginBottom: '10px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '6px'
  },
  title: {
    fontWeight: 'bold',
    borderBottom: '1px solid #ccc',
    backgroundColor: `${LIGHTGREY}`,
    fontSize: '14px'
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
})

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none',
    },
  },
}))(Chip);

class Competencies extends Component {
  constructor (props) {
    super(props)
    this.state = {
      editText: '',
      editID: '',
      open: false,
      openConfirm: false
    }
  }

  componentDidMount() {
    this.fetchData()
  }


  componentWillMount = () => {
    this.fetchData()
  };

  fetchData = () => {
    this.props.fetchCompanyValues(this.props.userdata.companyID)
  };

  deleteCompetency = () => {
    this.props.deleteCompanyValue({
      valueID: this.state.editID,
      companyID: this.props.userdata.companyID,
      isDelete: true
    })

    this.handleClose()
  };

  handleClose = () => {
    this.setState({
      editText: '',
      editID: '',
      open: false,
      openConfirm: false
    })
  };

  editCompetency = (id, name) => e => {
    this.setState({
      editText: name,
      editID: id,
      open: true
    })
  }

  deleteCompetencies = (id, name, count) => e => {
    if (count > 0) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Cannot delete this item, there are posts and/or tasks tied to it!'
      }

      this.props.showToast(params)
      return
    }

    this.setState({
      editText: name,
      editID: id,
      openConfirm: true
    })
  }

  deactivateCompetencies = (id, name, count) => e => {
    let confirm_msg = window.confirm("Are you sure you want to deactivate " + name + "?");

    if (confirm_msg == true) {
      // console.log("Confirmed !")
    } else {
      // console.log("Cancelled !")
    }
  }

  render() {
    const { classes, companyValues, userdata } = this.props
    //console.log('purple competency props: ', this.props);

    let count = 0
    return (
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.header}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h5' gutterBottom><AccountBalance /> {VALUES_NAME[`${userdata.companyID}`] ? VALUES_NAME[`${userdata.companyID}`] : VALUES_NAME.other}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Breadcrumbs aria-label="breadcrumb">
              <StyledBreadcrumb
                component={Link}
                to={`/companyadmin`}
                label="Club Admin"
              />
              <StyledBreadcrumb
                label={VALUES_NAME[`${userdata.companyID}`] ? VALUES_NAME[`${userdata.companyID}`] : VALUES_NAME[`other`]}
              />
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AddCompetency title='Add Competency' type='competency' />         
          </Grid>
        </Grid>
        {companyValues &&
          Object.keys(companyValues).map(id => {
            const labelId = `${companyValues[id].valueID}`
            const labelName = `${companyValues[id].valueDesc}`
            if (companyValues[id] && companyValues[id].count) {
              count = companyValues[id].count
            } else {
              count = 0
            }

            if (labelName !== "undefined") {
              return (
                <ExpansionPanel className={classes.panel}>
                  <ExpansionPanelSummary className={classes.title} expandIcon={<div><ExpandMoreIcon /></div>}>
                    {companyValues[id].valueDesc}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    style={{
                      padding: '8px 8px 24px'
                    }}
                  >
                    <SubCompetencies compid={companyValues[id].valueID} />
                  </ExpansionPanelDetails>
                  <ExpansionPanelActions>
                    <Button size='small' color='primary' onClick={this.editCompetency(labelId, labelName)}><EditIcon /> Edit Competency</Button>
                    <Button size='small' color='secondary' onClick={this.deleteCompetencies(labelId, labelName, count)}>
                      <DeleteIcon /> Delete Competency
                    </Button>
                    <Button size='small' color='secondary' onClick={this.deactivateCompetencies(labelId, labelName, count)}>
                      <CancelIcon /> Deactivate Competency
                    </Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>
              )

            }
          })}
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.open}
          onClose={this.handleClose}
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            <AddCompetency title='Edit Competency' type='competency' compid={this.props.compid} editText={this.state.editText} editID={this.state.editID} onClose={this.handleClose} />
          </div>
        </Modal>
        <Modal
          aria-labelledby='confirmation-modal'
          aria-describedby='confirmation-modal-description'
          open={this.state.openConfirm}
          className={classes.modal}
        >
          <div style={modalStyle2} className={classes.paper}>
            <Typography variant='h6' gutterBottom>Please Confirm</Typography>
            <Typography variant='body' gutterBottom>Are you sure you want to delete {this.state.editText}?</Typography>
            <div>
              <ExpansionPanelActions>
                <Button onClick={this.handleClose} size='small'>Cancel</Button>
                <Button onClick={this.deleteCompetency} size='small' color='secondary'>Delete</Button>
              </ExpansionPanelActions>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

Competencies.propTypes = {
  fetchCompanyValues: PropTypes.func.isRequired,
  userdata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
}
const modalStyle2 = {
   top: `${top}%`,
   left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}

const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0],
    companyValues: state.pldp.companyValues
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCompanyValues: params => dispatch(getCompanyValues(params)),
    deleteCompanyValue: params => dispatch(deleteCompanyValue(params)),
    showToast: params => dispatch(showToast(params))
  }
}

const CompetenciesStyled = withStyles(styles)(Competencies)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetenciesStyled)
