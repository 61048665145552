import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import ViewUser from '../UserProfile/ViewUser'
import ViewUser_self from '../UserProfile/ViewUser_self'
import ViewUser_ledain from '../UserProfile/ViewUser_ledain'
import ViewUser_ledain_self from '../UserProfile/ViewUser_ledain_self'
import ViewUser_gen from '../UserProfile/ViewUser_gen'
import ViewUser_self_gen from '../UserProfile/ViewUser_self_gen'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Liking from './../Buttons/Liking'
import LinkIcon from '@material-ui/icons/Link'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import MicIcon from '@material-ui/icons/Mic'

import VideocamIcon from '@material-ui/icons/Videocam'
import DeleteIcon from '@material-ui/icons/Delete'
import ImageIcon from '@material-ui/icons/Image'
// import ForumIcon from "@material-ui/icons/Forum";
import ForumIcon from '@material-ui/icons/CommentOutlined'
// import PersonIcon from '@material-ui/icons/PersonAddRounded'
import MoreMenu from './MoreMenu'
import Badge from '@material-ui/core/Badge'
import { Grid } from '@material-ui/core'
// import Paper from '@material-ui/core/Paper'
import CardMessage from './CardsMessage'
import CloseIcon from '@material-ui/icons/Close'
// import LevelIcon from '@material-ui/icons/PersonPin'
import FileViewer from '../FileViewer'

import { DATE_FORMAT } from '../../utils/date_format_utils'
import { PHOTO_BACKGROUND, PHOTO_PROFILE } from '../../utils/photo_utils'
import {
  GREY,
  RED,
  LIGHTBLUE,
  GREEN,
  LIGHTGREY,
  HOVERRED,
  DARKBLUE
} from '../../utils/color_utils'
// Custom Components
import CommentDialogCard from './../Cards/CommentDialogCard'
import FollowButton from '../Buttons/Follow2'
import Comments from '../Comments'
import AddCommentField from '../Comments/AddCommentField'
import { YOUTUBE } from './../../utils/youtube_utils'
import RemoveAttachmentDialog from '../Dialogs/RemoveAttachmentDialog'
import PLDPFavorites from './../Buttons/PLDPFavorites'
import {
  fetchUserBiography,
  openUserProfileDialog
} from '../../store/actions/userBiographyActions'

import {
  getNewUserdata, insertNewUserdata
} from '../../store/actions/freshCardActions'
import {
  captureUserActivity
} from '../../store/actions/analyticsActions'
import RemoveFavorite from './RemoveFavorite'
import LoadSocialNetworks from '../../components/socialnetworks/socialappmodal'
import { APP_TYPE, USER_CLICKS_POST, IS_HARMONY, FRESH, BASE_API_URL, UPDATE_SURVEY, TEST_PUBLISHER, REFRESH_CARD, GET_OPINIONID } from '../../store/actions/types'
import { ChangeHistoryOutlined } from '@material-ui/icons'
import firebase from '../../store/firebase'
import axios from 'axios'
// import pointsIcon from '@material-ui/core/grow'
import MakeTest from '../../pages/PointsSurveys/MakeTest'
import DoTest from '../../pages/PointsSurveys/DoTest'
import TestStats from '../../pages/PointsSurveys/TestStats'
import PointsTutorial from '../../pages/PointsSurveys/PointsTutorial'
import { surveyStats } from '../../store/actions/surveyStatsAction'
import { surveyTestsDisplay } from '../../store/actions/surveyTestsDisplayAction'
// import { testTestAction } from "../../store/actions/testTestAction";
import { refreshCard } from '../../store/actions/SurveyActions'
import Icon from 'supercons'
import StarRoundedIcon from '@material-ui/icons/StarRounded'

// level 0s
import { BiPyramid } from "react-icons/bi";
// level one
import { BiHive } from "react-icons/bi";
// import { BiExclude } from "react-icons/bi";
// import {BiShieldQuarter } from "react-icons/bi";
// level 2
import { BiArch } from "react-icons/bi";
// import { GiGearHammer } from "react-icons/gi";
// import { FcDebian } from "react-icons/fc";
// level 3
import { BiAperture } from "react-icons/bi";
// import { TiVendorApple } from "react-icons/ti";
// import { TiSpiral } from "react-icons/ti";

// level 4
import { BiAnchor } from "react-icons/bi";
// import { RiHonorOfKingsFill } from "react-icons/ri";
// import { RiFirefoxFill } from "react-icons/ri";
// level 5
import { BiMagnet } from "react-icons/bi";
// import { MdTipsAndUpdates } from "react-icons/md";
// import { MdHourglassFull } from "react-icons/md";
// level 6
import { BiCrown } from "react-icons/bi";
// import { VGiFire } from "react-icons/gi";
// import { DiRuby } from "react-icons/di";
//import { getOpinions, getOpinionsTrail } from "../../store/actions/opinionActions";
import { getOpinions, getOpinionsTrail, FindSlave } from "../../store/actions/opinionActions";
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import { withRouter } from 'react-router-dom'
//import { fetchThoughts } from '../../store/actions/thoughtsActions'
// import {
//   ILEAD_THOUGHTS,
//   APP_TYPE,
//   PUBLIC_COMPANY_ID
// } from '../../store/actions/types'

const styles = theme => ({
  tableContainer: {
    minWidth: isMobile ? '100%' : '650px',
    maxWidth: isMobile ? '100%' : '700px',
    align: 'top',
    alignItems: 'top',
    justifyContent: 'top',
    backgroundColor: '#F9F8F5',
    marginRight: '0px',
    display: 'flex',
    alignContent: 'top'
  },
  tableContainerexp: {
    minWidth: isMobile ? '100%' : '100%',
    maxWidth: isMobile ? '100%' : '100%',
    align: 'left',
    alignItems: 'left',
    justifyContent: 'left',
    marginRight: '300px',
    display: 'flex',
    alignContent: 'left',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5'
  },
  card: {
    // minWidth: '600px',
    // maxWidth: '80%',
    minWidth: isMobile ? '100%' : '600px',
    maxWidth: isMobile ? '100%' : '55%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 4px'
    }
  },
  cardtitle: {
    minWidth: isMobile ? '80%' : '550px',
    maxWidth: isMobile ? '90%' : '600px',
    align: 'top',
    //position: 'relative',
    alignItems: 'top',
    justifyContent: 'top',
    backgroundColor: '#F9F8F5',
   // padding: '10px 10px',
    marginRight: '0px',
    display: 'flex',
   // displayDirection: 'row',
    alignContent: 'top'
  },
  cardtitleExp: {
    // minWidth: isMobile ? '80%' : '65%',
    width: isMobile ? '310px' : '500px',
    minWidth: isMobile ? '250px' : '400px',
    maxWidth: isMobile ? '300px' : '500px',
    align: 'top',
    flexDirection: 'row',
    flexShrink: 1,
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word',
    //position: 'relative',
    alignItems: 'top',
    justifyContent: 'block',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5',
   // padding: '10px 10px',
    //marginRight: '300px',
    display: 'flex',
   // displayDirection: 'row',
    alignContent: 'top'
  },
  card2: {
    // minWidth: isMobile ? '80%' : '500px',
    // maxWidth: isMobile ? '100%' : '45%',
    minWidth: isMobile ? '80%' : '600px',
    maxWidth: isMobile ? '90%' : '650px',
    align: 'top',
    //position: 'relative',
    alignItems: 'top',
    justifyContent: 'top',
    backgroundColor: '#F9F8F5',
   // padding: '10px 10px',
    marginRight: '0px',
    display: 'flex',
   // displayDirection: 'row',
    alignContent: 'top',
    whiteSpace: isMobile ? '' : 'nowrap'
  },
  card2exp: {
    minWidth: isMobile ? '80%' : '100%',
    maxWidth: isMobile ? '90%' : '100%',
    //align: 'top',
    //position: 'relative',
   // alignItems: 'top',
   // justifyContent: 'top',
    align: 'left',
    alignItems: 'left',
    justifyContent: 'left',
    alignContent: 'left',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5',
    //padding: '10px 10px',
    //marginRight: '200px',
    display: 'flex',
   // displayDirection: 'row',
    alignContent: 'top',
    whiteSpace: isMobile ? '' : 'nowrap'
  },
  card3: {
    minWidth: isMobile ? '100%' : '600px',
    maxWidth: isMobile ? '100%' : '75%',
    align: 'top',
    //position: 'relative',
    alignItems: 'top',
    justifyContent: 'top',
    backgroundColor: '#F9F8F5',
   // padding: '10px 10px',
    marginRight: '0px',
    display: 'flex',
   // displayDirection: 'row',
    alignContent: 'top'
  },
  card3exp: {
    minWidth: isMobile ? '80%' : '75%',
    maxWidth: isMobile ? '80%' : '75%',
    align: 'top',
    //position: 'relative',
    alignItems: 'top',
    justifyContent: 'top',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5',
   // padding: '10px 10px',
    marginRight: '0px',
    display: 'flex',
   // displayDirection: 'row',
    alignContent: 'top'
  },
  actions: {
     minWidth: isMobile ? '100%' : '600px',
     maxWidth: isMobile ? '100%' : '55%',
     width: '55%',
    display: 'flex',
    padding: 0
  },
  socialIcon: {
    width: 30,
    height: 30
  },
  actionsComments: {
    // boxSizing: 'border-box',
    minWidth: isMobile ? '80%' : '60%',
    maxWidth: isMobile ? '90%' : '80%',
    width: isMobile ? '90%' : '80%',

    alignItems: 'left',
    // height: '50px',
    // maxHeight: '100px',
    // minHeight: '20px',
    overflow: 'hidden',
    position: 'relative',
    display: 'none',
    verticalAlign: 'top',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5'
    // float: 'left',
    // display: 'inline',
  },
  actionsCommentsexp: {
    // boxSizing: 'border-box',
    minWidth: isMobile ? '300px' : '70%',
    maxWidth: isMobile ? '300px' : '80%',
    width: isMobile ? '300px' : '80%',

    // minWidth: isMobile ? '70%' : '100%',
    // maxWidth: isMobile ? '100%' : '100%',
    // width: isMobile ? '100%' : '100%',

    alignItems: 'left',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5',
    // height: 0,
    // maxHeight: 0,
    // overflow: 'hidden',
    // position: 'relative',
    // display: 'none',
    // verticalAlign: 'top',
    //float: 'left',
    display: 'inline',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: theme.palette.grey[500],
    cursor: 'pointer',
  },
  avatar2: {
    cursor: 'pointer',
    align: 'top',
    width: '50px',
    alignItems: 'top',
    justifyContent: 'top',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5',
    padding: '0px 0px',
    marginRight: '0px',
    marginTop: '10px',
    display: 'flex',
    alignContent: 'top',
   // border: '1px solid #F9F8F5'
  },
  avatar2exp: {
    minWidth: isMobile ? '0px' : '50px',
    maxWidth: isMobile ? '0px' : '50px',
    cursor: 'pointer',
    align: 'top',
    width: isMobile ? '0px' : '50px',
    alignItems: 'top',
    justifyContent: 'top',
    borderColor: '#F9F8F5',
    padding: '0px 0px',
    marginRight: isMobile ? '-40px' : '0px',
    marginTop: '10px',
    display: 'flex',
    alignContent: 'top',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5'
  },
  avatar3: {
    minWidth: isMobile ? '5px' : '50px',
    maxWidth: isMobile ? '10px' : '50px',
    cursor: 'pointer',
    align: 'top',
    width: isMobile ? '20px' : '50px',
    alignItems: 'top',
    justifyContent: 'top',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5',
    padding: '0px 0px',
    marginRight: isMobile ? '30px' : '0px',
    marginLeft: isMobile ? '-10px' : '0px',
    marginTop: '10px',
    display: 'flex',
    alignContent: 'top',
    //border: '1px solid #F9F8F5'
  },
  avatar3exp: {
    //minWidth: isMobile ? '0px' : '0px',
    //maxWidth: isMobile ? '0px' : '0px',
    top: '0px',
    left: '680px',
    width: '570px',
    tableLayout: 'auto',
    maxWidth: '1px',
    // whiteSpace: 'fixed',
    wrap:'nowrap', 
    // alignItems: 'center' ,
    justify:'space-between',
    overflow: 'hidden',
    flexDirection: 'row',
    // flexShrink: 1,
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word',
    // cursor: 'pointer',
    align: 'left',
    alignItems: 'left',
    justifyContent: 'left',
    backgroundColor: 'green',

    width: isMobile ? '0px' : '0px',
    alignItems: 'top',
    justifyContent: 'top',
    backgroundColor: '#F9F8F5',
    borderColor: '#F9F8F5',
    marginRight: isMobile ? '0px' : '0px',
    marginLeft: isMobile ? '-100px' : '-100px',
    marginTop: '10px',
    display: 'flex',
    alignContent: 'top',
  },
  levelIcon: {
    top: '0px',
    left: '-68px',
    width: '20px'
  },
  badgemargin: {
    top: '0px',
    left: '-68px',
    width: '170px',
    [theme.breakpoints.up('sm')]: {
      top: '20px'
    },

    right: '73%',
    color: '#676767',
    border: '2px solid #eeeeee',
    padding: '8px',
    position: 'absolute',
    fontSize: '8pt',
    backgroundColor: '#f4eeee',
    border: `2px solid ${theme.palette.type === 'light'
      ? theme.palette.grey[200]
      : theme.palette.grey[900]
    }`
  },
  badge: {
    width: 'max-content',
    color: '#fff',
    padding: '8px',
    fontSize: '8pt',
    border: `2px solid ${theme.palette.type === 'light'
      ? theme.palette.grey[200]
      : theme.palette.grey[900]
    }`,
    right: '-28px',
    backgroundColor: '#F9F8F5'
  },
  thoughtDate: {
    padding: '5px 0px',
    color: '#676767',
    fontSize: '8pt',
    textAlign: 'center',
    position: 'relative',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px #9a9a9a8a',
    backgroundColor: '#f4eeee',
    left: '10px',
    top: '10px',
    marginTop: '-14px',
    width: '150px',
    maxWidth: '150px !important',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '20px'
    }
  },
  follow: {
    top: '30px',
    position: 'relative'
  },
  followMyPosts: {
    top: '30px',
    position: 'relative'
  },
  followBtn: {
    textAlign: '-webkit-right',
    textAlign: 'right'
  },
  followBtnPos: {
    position: 'relative',
    minHeight: '40px',
    height: '40px!important',
    // top: "0px",
    [theme.breakpoints.down('sm')]: {
      top: '0px'
    },
    [theme.breakpoints.up('md')]: {
      top: '-10px'
    }
  },
  commentsbadge: {
    // minWidth: isMobile ? '100%' : '75%',
    // maxWidth: isMobile ? '100%' : '55%',
    width: 'max-content',
    color: '#fff',
    padding: '8px',
    fontSize: '8pt',
    border: `2px solid ${theme.palette.type === 'light'
      ? theme.palette.grey[200]
      : theme.palette.grey[900]
    }`,
    // right: '-50px',
    // top: '-25px',
    backgroundColor: '#C3BFB2'
  },
  media: {
    //height: '280px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    borderRadius: '1px',
    textAlign: 'center'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#eeeeee',
   // height: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'left'
  },
  cardmedia: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center'
  },
  cardstyleExp: {
    // minWidth: isMobile ? '80%' : '600px',
    // maxWidth: isMobile ? '90%' : '55%',

    // minWidth: isMobile ? '100%' : '650px',
    // maxWidth: isMobile ? '100%' : '700px',
    minWidth: isMobile ? '300px' : '650px',
    maxWidth: isMobile ? '350px' : '700px',
    //flex: '1',
    width: isMobile ? '350px' : '700px',
    //tableLayout: 'auto',
    //maxWidth: '1px',
    // overflow: 'hidden',
    // overflowWrap: 'break-word',
    alignItems: 'left',
    textAlign: 'left',

    //position: 'relative',
    //margin: '10px 6px',
    // maxWidth: '100%', //'600px',
    // [theme.breakpoints.up('sm')]: {
    //   minWidth: '100%', //'320px'
    // }
  },
  cardstyle: {
    // minWidth: isMobile ? '100%' : '600px',
    // maxWidth: isMobile ? '100%' : '55%',
    minWidth: isMobile ? '100%' : '650px',
    maxWidth: isMobile ? '100%' : '700px',
    position: 'relative',
    margin: '10px 6px',
    // maxWidth: '100%', //'600px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '100%', //'320px'
    }
  },
  tableactions: {
    minWidth: '-webkit-fill-available',
  },
  tableHead: {
    borderBottomColor: "white"
  },
  stickyHeader:{
      borderBottomColor: "white"
  },
  attachmentrow: {
    backgroundColor: LIGHTGREY
  },
  favoritesrow: {
    minWidth: isMobile ? '100%' : '75%',
    maxWidth: isMobile ? '100%' : '55%',
    height: '50px',
    width: 'max-content',
    color: '#fff',
    padding: '1px',
    fontSize: '8pt',
    borderColor: '#F9F8F5',
    left: '-20px',
    backgroundColor: '#F9F8F5',
    justifyContent: 'left'
  },
  favoritesrow2: {
    minWidth: isMobile ? '100%' : '75%',
    maxWidth: isMobile ? '100%' : '55%',
    height: '50px',
    color: '#fff',
    //padding: '1px',
    fontSize: '8pt',
    left: '-20px',
    justifyContent: 'left',
    borderColor: '#F9F8F5',
    backgroundColor: '#F9F8F5'
  },
  sharediv: {
    // minWidth: isMobile ? '100%' : '600px',
    // maxWidth: isMobile ? '100%' : '55%',
    flexDirection: 'row',
    width: '100%',
    padding: '3px 31px',
    display: 'flex',
    marginLeft: isMobile ? '-10px':'0px',
    justifyContent: 'center',
    borderColor: '#F9F8F5',
    backgroundColor: '#F9F8F5'
  },
  // sharediv: {
  //   color: '#fff',
  //   float: 'right!important',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   backgroundColor: '#FFFFFF',
  //   borderRadius: '10%',
  //   padding: '0px 10px',
  //   marginRight: '100px',
  //   display: 'flex',
  //   displayDirection: 'row',
  //   alignContent: 'center'
  // },
  favoritesdiv: {
    color: '#fff',
    float: 'left!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  commentsdiv: {
    // minWidth: isMobile ? '99%' : '90%',
    // maxWidth: isMobile ? '99%' : '90%',
    // width: isMobile ? '99%' : '90%',
    marginLeft: isMobile ? '-50px' : '-30px', 
    borderColor: '#F9F8F5',
    backgroundColor: '#F9F8F5',
    //top: '30px',
    //color: '#fff',
    alignItems: 'left',
    justifyContent: 'left',
    backgroundColor: '#F9F8F5',
   // marginRight: '200px',
    //padding: '0px 0px'
  },
  comments: {
    minWidth: isMobile ? '99%' : '90%',
    maxWidth: isMobile ? '99%' : '90%',
    width: isMobile ? '99%' : '90%',
    //display: 'flex',
    margin: '0', 
    padding: '0' ,
    fontSize: 10,
    color: '#575757'
  },
  scomments: {
    width: isMobile ? '180px' : '360px',
    minWidth: isMobile ? '140px' : '310px',
    maxWidth: isMobile ? '190px' : '380px',
    flexDirection: 'row',
    flexShrink: 1,
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word',
    margin: '0', 
    padding: '0' ,
    fontSize: 10,
    color: '#575757'
  },
  sScomments: {
    width: isMobile ? '180px' : '360px',
    minWidth: isMobile ? '140px' : '310px',
    maxWidth: isMobile ? '190px' : '380px',
    flexDirection: 'row',
    flexShrink: 1,
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word',
    margin: '0', 
    padding: '0' ,
    fontSize: 10,
    color: '#575757'
  }, 
  commenter: {
    width: isMobile ? '160px' : '340px',
    minWidth: isMobile ? '120px' : '290px',
    maxWidth: isMobile ? '170px' : '360px',
    marginRight: 30,
    fontSize: 10,
    display: 'flex',
    margin: '0', 
    padding: '0', 
    color: '#575757'
  },
  commentTime: {
    fontSize: 8,
    color: 'rgba(0,0,0,.6)'
  },
  replyToComment: {
    color: LIGHTBLUE,
    transition: '.5s all ease-in',
    transform: 'rotate(-45deg)',
    minWidth: isMobile ? '65%' : '65%',
    maxWidth: isMobile ? '65%' : '85%'
  },
  mask: {
    minWidth: isMobile ? '100%' : '100%',
    maxWidth: isMobile ? '100%' : '100%',
    position: 'absolute',
    borderColor: '#F9F8F5',
    backgroundColor: '#F9F8F5',
    height: 20,
    right: 0,
    cursor: 'pointer',
    bottom: 0,
    left: 0,
    zIndex: 9,
    background:
      'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.67), rgba(255, 255, 255, 1))'
  },
  maskText: {
    minWidth: isMobile ? '100%' : '600px',
    maxWidth: isMobile ? '100%' : '55%',
    position: 'absolute',
    right: 0,
    bottom: 10,
    left: 0,
    padding: 1,
    textAlign: 'center',
    cursor: 'pointer',
    color: LIGHTBLUE,
    borderColor: '#F9F8F5',
    backgroundColor: '#F9F8F5'
  },
  nested: {
    marginLeft: 10,
    backgroundColor: LIGHTGREY
  },
  attachments: {
    display: 'block',
    borderColor: '#ccc',
    textAlign: 'left',
    color: '#FFFFFF',
  },
  attachments2: {
    display: 'block',
    borderColor: '#ccc',
    textAlign: 'left',
    color: '#FFFFFF',
    marginLeft: isMobile ? '0px' : '30px', 
    borderColor: '#F9F8F5',
    backgroundColor: '#F9F8F5'
  },
  attachmentControl: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: '-10',
    fontSize: '6px'
  },
  deleteAttachment: {
    'position': 'absolute',
    'margin': '-28px -13px',
    'background': 'rgba(0, 0, 0, 0.24)',
    'borderColor': 'black',
    '&:hover': {
      backgroundColor: RED
    }
  },
  previewFooter: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '6px',
    bottom: '2px',
    left: '10px',
    fontSize: '10pt !important',
    [theme.breakpoints.down('xs')]: {
      bottom: '10px',
      marginBottom: '0px'
    }
  },
  previewText: {
    display: '-webkit-flex',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 16px'
  },
  close: {
    'position': 'absolute',
    'top': '0px',
    'right': '0px',
    'zIndex': '999999',
    'color': '#fff',
    'backgroundColor': `${RED}`,
    'borderRadius': '0%',
    'padding': '8px',
    '&:hover': {
      background: `${HOVERRED}`
    }
  }
})

const getPhotoURL = (props) => {
  for (let x in props) {
    return props[x].url
  }
}
const CardAttachments = props => {
  const {
    classes,
    thought,
    onExpandAttachments,
    currentUser,
    openAttachments,
    attachmentoption,
    PlayMedia,
    postsClicked,
    openFileViewer,
    onRemoveAttachment
  } = props

  let removeAttachment = (
    _thought,
    _postType,
    _attachmentType,
    _attachmentID
  ) =>
    _thought.journalUserID !== currentUser.userID ? (
      ''
    ) : (
      <IconButton
        onClick={e =>
          onRemoveAttachment(
            e,
            _thought,
            _postType,
            _attachmentType,
            _attachmentID
          )
        }
        className={classes.deleteAttachment}
      >
        <DeleteIcon style={{ color: '#ffffff', fontSize: 16 }} />
      </IconButton>
    )

  return (
    <Grid
      container
      spacing={16}
      direction='row'
      className={classes.tableactions}
    >
      <Grid
        container
        spacing={16}
        direction='row'
        justify='center'
        alignItems='left'
      >
        {/* <Grid item xs={12} className={classes.attachmentrow}>
          <div style={{ color: LIGHTBLUE, padding: '8px 16px' }}>
            {(thought.urls && Object.keys(thought.urls).length > 0) ||
              (thought.podcasts && Object.keys(thought.podcasts).length > 0) ||
              (thought.videos && Object.keys(thought.videos).length > 0) ||
              (thought.files && Object.keys(thought.files).length > 0) ? (
                <Typography style={{ color: LIGHTBLUE }} component='p'>
                View attachment(s) below
                </Typography>
              ) : (
                <Typography style={{ color: LIGHTBLUE, color: 'transparent' }} component='p'>
                View attachment(s) below
                </Typography>
              )}
          </div>
        </Grid> */}

        <Grid item xs={2}>
          <div className={classes.attachmentControl}>
          {thought.urls ?
              <IconButton
                onClick={() => onExpandAttachments('article')}
                style={{ color: LIGHTBLUE }}
                aria-label='Link'
                id={'article_' + thought.id}
                option='article'
              >
              <LinkIcon /> 
              </IconButton>
             : null}
             {thought.podcasts ? 
              <IconButton
                onClick={() => onExpandAttachments('podcast')}
                style={{ color: GREEN }}
                aria-label='Mic'
                id={'podcast_' + thought.id}
                option='podcast'
              >
                <MicIcon /> 
              </IconButton>
           : null}
           {thought.videos ? 
              <IconButton
                onClick={() => onExpandAttachments('video')}
                style={{ color: GREY }}
                aria-label='Videocam'
                id={'video_' + thought.id}
                option='video'
              >
                <VideocamIcon />
              </IconButton>
           : null}
           {getPhotoURL(thought.photos) ? 
              <IconButton
                onClick={() => onExpandAttachments('image')}
                style={{ color: RED }}
                aria-label='Image'
                id={'image_' + thought.id}
                option='image'
              >
                <ImageIcon />
              </IconButton>
           : null}
            {thought.files ?
              <IconButton
                onClick={() => onExpandAttachments('file')}
                style={{ color: DARKBLUE }}
                aria-label='File'
                id={'file_' + thought.id}
                option='file'
              >
                <InsertDriveFileIcon />
              </IconButton>
            : null}
          </div>
        </Grid>
        <Grid item xs={2}>&nbsp;&nbsp;</Grid>
        <Grid item xs={2}>&nbsp;&nbsp;</Grid>
        <Grid item xs={2}>
          <div onClick={() => {
            postsClicked('Open PLDP')
          }}>
            <PLDPFavorites
              post={thought}
              postTypeID='dailyThoughtID'
              postType='thoughts'
              userdata={currentUser}
            />
          </div>
        </Grid>
        <Grid item xs={2}><div/></Grid><Grid item xs={2}><div/></Grid>
      </Grid>
      <Collapse in={openAttachments} timeout='auto' unmountOnExit style={{ width: '100%' }}>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
        >
          <Grid xs>
            {attachmentoption === 'article'
              ? thought.urls
                ? Object.keys(thought.urls).map((v, i) => (
                  <div key={i} className={classes.attachments}>
                    <a onClick={() => {
                      postsClicked('openedLink')
                    }} href={thought.urls[v].url} target='_blank'>
                      {' '}
                      <LinkIcon style={{ color: LIGHTBLUE }} />
                      {thought.urls[v].title}
                    </a>
                    {removeAttachment(thought, 'thoughts', 'urls', v)}
                  </div>
                ))
                : ''
              : null}
            {attachmentoption === 'podcast'
              ? thought.podcasts
                ? Object.keys(thought.podcasts).map((v, i) => (
                  <div key={i} className={classes.attachments}>
                    <a
                      onClick={e => {
                        e.preventDefault()
                        PlayMedia(thought.podcasts[v], 'podcast')
                        postsClicked('listenedTo')
                      }}
                      href={thought.podcasts[v].url}
                      target='_blank'
                    >
                      {' '}
                      <MicIcon style={{ color: GREEN }} />
                      {thought.podcasts[v].title}
                    </a>
                    {removeAttachment(thought, 'thoughts', 'podcasts', v)}
                  </div>
                ))
                : ''
              : null}
            {attachmentoption === 'video'
              ? thought.videos
                ? Object.keys(thought.videos).map((v, i) => (
                  <div key={i} className={classes.attachments}>
                    <a
                      onClick={e => {
                        e.preventDefault()
                        PlayMedia(thought.videos[v], 'videos')
                        postsClicked('viewed')
                      }}
                      href={thought.videos[v].url}
                      target='_blank'
                    >
                      {' '}
                      <img
                        style={{ maxWidth: 120 }}
                        alt='LP'
                        src={YOUTUBE.getYoutubeThumbnail(
                          thought.videos[v].url
                        )}
                      />
                    </a>
                    {removeAttachment(thought, 'thoughts', 'videos', v)}
                  </div>
                ))
                : ''
              : null}
            {attachmentoption === 'image'
              ? thought.photos
                ? Object.keys(thought.photos).map((v, i) => (
                  <div key={i} className={classes.attachments}>
                    <a href={thought.photos[v].url} target='_blank'>
                      {' '}
                      <img
                        style={{ maxWidth: 120 }}
                        alt='LP'
                        src={thought.photos[v].url}
                      />
                    </a>
                    {removeAttachment(thought, 'thoughts', 'photos', v)}
                  </div>
                ))
                : ''
              : null}
            {attachmentoption === 'file'
              ? thought.files
                ? Object.keys(thought.files).map((v, i) => (
                  <Grid
                    container
                    spacing={2}
                    direction='row'
                    style={{
                      margin: '0 8px 6px', boxShadow: '0px 0px 4px #ddd',
                      width: '97%',
                      fontSize: 11
                    }}
                  >
                    <Grid key={i} item xs={10}>
                      <div className='files_to_view' onClick={() => {
                        let file = {
                          filepath: thought.files[v].url,
                          filename: thought.files[v].title
                        }
                        postsClicked('openedFile')
                        console.log('green openFileViewer x', thought.files[v].url)
                        window.open(thought.files[v].url);
                        // openFileViewer(file)
                      }}>
                        <InsertDriveFileIcon style={{ color: DARKBLUE }} />
                        {thought.files[v].title}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>{removeAttachment(thought, 'thoughts', 'files', v)}</div>
                    </Grid>
                  </Grid>

                ))
                : ''
              : null}
          </Grid>
        </Grid>
      </Collapse>
    </Grid >
  )
}
// function HomeIcon(props) {
//   return (
//     <SvgIcon {...props}>
//       <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
//     </SvgIcon>
//   );
// }
// ----------------------------------------
class Thoughts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAttachments: false,
      option: 'article',
      openDeleteAttachmentDialog: false,
      anchorEl: null,
      params: {},
      survLen: 111,
      surStage: 0,
      isLoading: false,
      surveyDone: false,
      requirementKey: Math.random(),
      freshPoints: 0
    }

    // var params = {
    //   leaderID: this.props.thought.journalUserID,
    //   slaveID: this.props.userdata.userID
    // }
    // this.props.FindSlave(params);
    //this.getOpinionID();
  }
  // refreshComponent() {
  //   this.getSurveyLength(this.props.thought.dailyThoughtID, this.props.thought.real_companyID, this.props.thought.companyID)

  //   this.getSurveyStage(this.props.thought.dailyThoughtID)

  //   this.didTest(
  //     this.props.thought.dailyThoughtID,
  //     this.props.userdata.userID,
  //     this.props.thought.journalUserID,
  //     this.props.thought.real_companyID,
  //     this.props.thought.companyID
  //   )
  // }

  componentDidMount() {
    console.log('green getSurveyLength thought.title: ', this.props.thought.title)
    console.log('green getSurveyLength real_companyID: ', this.props.thought.real_companyID)
    console.log('green getSurveyLength companyID: ', this.props.thought.companyID)
    console.log('purple doTest title 0 : ', this.props.thought.points)
    console.log('this.props.userdata.userID : ', this.props.userdata.userID)
    
    console.log('this.props.userdata.companyID : ', this.props.userdata.companyID)

  //   if (this.props.userdata.userID) {
  //     this.props.getOpinions({ //loads the opinions of leaders recorded in state
  //      leaderID: this.props.userdata.userID
  //    })
  //  }

    this.getSurveyLength(this.props.thought.dailyThoughtID, this.props.thought.real_companyID, this.props.thought.companyID)
  //   var params = {
  //     leaderID: this.props.thought.journalUserID,
  //     slaveID: this.props.userdata.userID
  //   }
  //  //this.props.FindSlave(params);
  //  this.getOpinionID();
    //////////////////////////////////////////
    // this.props.getOpinionsTrail({
    //   leaderID: this.props.thought.journalUserID,
    //   slaveID: this.props.userdata.userID
    // })
    //////////////////////////////////////////

    this.getSurveyStage(this.props.thought.dailyThoughtID)

    this.didTest(
      this.props.thought.dailyThoughtID,
      this.props.userdata.userID,
      this.props.thought.journalUserID,
      this.props.thought.real_companyID,
      this.props.thought.companyID
    )

    console.log('refresh redux : ', this.props.refreshThought)
    console.log('yello thoughtID 1: ', this.props.thought.dailyThoughtID)
    // if (this.props.userdata.userID) {
    //    this.props.getOpinions({ //loads the opinions of leaders recorded in state
    //     leaderID: this.props.userdata.userID
    //   })
    // }

    // TEST IF TEST SURVEY HAS BEEN CREATED YET
    // var params = {
    //   type: TEST_PUBLISHER,
    //   companyID: this.props.userdata.companyID,
    //   surveyid: this.props.thought.dailyThoughtID
    // }
    // this.props.testTestAction(params);
  }
  
  // shouldComponentUpdate(nextProps, nextState) {  
  //  if (this.props.thought !== nextProps.thought) {  
  //    return true;  
  //    } 
  //    return false;  
  //  } 

  // getOpinionID = async() => {
  //   let { FindSlave } = this.props
  //   var params = {
  //     leaderID: this.props.thought.journalUserID,
  //     slaveID: this.props.userdata.userID
  //   }
  //     await FindSlave(params);
  // }

  // putOpinions = () => {
  //   let { opinions } = this.props
  //   console.log('inside putOpinions');

  //   var self_count = 0;
  //   var others_count = 0;
  //   var organisation_count = 0;
  //   var society_count = 0;

  //   var self_all_count = 0;
  //   var others_all_count = 0;
  //   var org_all_count = 0;
  //   var sos_all_count = 0;
    
  //   if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
  //     console.log('inside putOpinions 2', opinions[index]);

  //     if (opinions[index].self) { 
  //       self_all_count = self_all_count + 1;
  //       self_count = self_count + opinions[index].self 
  //     }
  //     if (opinions[index].others) { 
  //       others_all_count = others_all_count + 1;
  //       others_count = others_count + opinions[index].others 
  //     }
  //     if (opinions[index].organisation) { 
  //       org_all_count = org_all_count + 1;
  //       organisation_count = organisation_count + opinions[index].organisation 
  //     }   
  //     if (opinions[index].society) { 
  //       sos_all_count = sos_all_count + 1;
  //       society_count = society_count + opinions[index].society 
  //     }   
  //   })}

  //    //////////////////////////////////////////////////////////////////
  //    var iself = self_all_count > 0 ? self_count / self_all_count : 0;
  //    var iothers = others_all_count > 0 ? others_count / others_all_count : 0;
  //    var iorganisation = org_all_count > 0 ? organisation_count / org_all_count : 0;
  //    var isociety = sos_all_count > 0 ?  society_count / sos_all_count : 0;

  //   //  var iself = self_all_count > 0 ? iself.toFixed(2) : 0;
  //   //  var iothers = others_all_count > 0 ? iothers.toFixed(2) : 0;
  //   //  var iorganisation = org_all_count > 0 ? iorganisation.toFixed(2) : 0;
  //   //  var isociety = sos_all_count > 0 ? isociety.toFixed(2) : 0;
  //    //////////////////////////////////////////////////////////////////

  //   // if (opinions) {
  //   //  this.setState({
  //   //   self: iself.toFixed(2),
  //   //   others: iothers.toFixed(2),
  //   //   organisation: iorganisation.toFixed(2),
  //   //   society: isociety.toFixed(2),
  //   //   selfparticipants: self_all_count,
  //   //   othersparticipants: others_all_count,
  //   //   orgparticipants: org_all_count,
  //   //   sosparticipants: org_all_count,
  //   //  })};

  //    //insertPollCount into user and users
  //    const updates = {}

  //    var finalScore = (iself + iothers + iorganisation + isociety)/4
  //    console.log('scores final 1 : ', iself + ' ' + iothers + ' ' + iorganisation + ' ' + isociety)
  //    console.log('scores final 2 : ', finalScore)
  //    //finalScore = finalScore.toFixed(2);
  //    console.log('scores final 3 ', finalScore)
  //    updates['/users/' + this.props.userdata.userID + '/feedback'] = finalScore.toFixed(2);
  //    updates['/user/' + this.props.userdata.uid + '/feedback' ] = finalScore.toFixed(2);
 
  //    // server: create group - send data to firebase
  //    firebase.database.ref().update(
  //      updates,
  //      function (error) {
  //        if (error) {
  //          console.log('success : ');
  //        } else {
  //         console.log('err : ', error);
  //        }
  //      })
  // }
  // showMakeTest = () => {
  //     // DONT SHOW MAKE SURVEY ICON
  //     var params = {
  //       type: REFRESH_CARD,
  //       userID: this.props.userdata.userID,
  //       post: this.props.thought.dailyThoughtID
  //     }
  //     this.props.refreshCard(params)
  // }

  updateLeadPoints = (freshPoints) => {
    console.log('updateLeadPoints : ', freshPoints)
    // let equalTo = ''
    // equalTo =
    //   APP_TYPE === 'CORPORATE'
    //     ? this.props.userdata.companyID + '_ilead_approved'
    //     : PUBLIC_COMPANY_ID + '_approved'

    // let params = {
    //   type: ILEAD_THOUGHTS,
    //   companyID: this.props.userdata.companyID,
    //   orderChild: 'publish_status',
    //   equalTo: equalTo,
    //   minData: 12
    // }
    // this.props.fetchThoughts(params)
  }

  getSurveyStage = async(thoughtIDD) => {
    let st
    console.log('green-star stage 1 : ', thoughtIDD);

    this.setState({ isLoading: true })
    await firebase.database.ref(`dailyThoughts/${thoughtIDD}/stage`).once('value').then((snapshot) => {
      if (snapshot.val()) {
        console.log('green-star stage 2 : ', snapshot.val());
        st = snapshot.val()
      } else {
        st = 0 // undefined null etc.
      }
      this.setState({
        surStage: st,
        isLoading: false
      })
    })
    console.log('green-star stage 3 : ', st);
    return st
  }

  getSurveyLength = async(thoughtIDD, companyIDreal, companyIDset) => {
    let varLength
    let companyIDis

    if (companyIDreal) {
      companyIDis = companyIDreal
    } else {
      companyIDis = companyIDset
    }
    // console.log('green getSurveyLength : ', `surveys/${companyIDis}/${thoughtIDD}/size`);

    this.setState({ isLoading: true })
    await firebase.database.ref(`surveys/${companyIDis}/${thoughtIDD}/size`).once('value').then((snapshot) => {
      // console.log('green getSurveyLength snapshot.val(): ', snapshot.val());

      if (snapshot.val()) {
        varLength = snapshot.val()
      } else {
        varLength = 111 // undefined null etc.
      }
      this.setState({
        survLen: varLength,
        isLoading: false
      })
    })
    console.log('green-star survLength: ', varLength);
    return varLength
  }

  didTest = async(thoughtIDD, personid, publisherID, realCompID, nCompID) => {
    let surveyDonex
    let data
    let icompanyID

    if (realCompID) {
      icompanyID = realCompID
    } else {
      icompanyID = nCompID
    }
    // firebase.database.ref(`surveysTests/${this.props.userdata.companyID}/${thoughtIDD}/${personid}`).once('value').then((snapshot) => {
    console.log('green ref bomb : ', icompanyID + ' - ' + thoughtIDD );

    // var aa = JSON.parse( JSON.stringify(icompanyID ) )
    // var bb = JSON.parse( JSON.stringify(thoughtIDD ) )
     if (icompanyID && thoughtIDD && personid) {
    await firebase.database
      .ref(`surveysTests/${icompanyID}/${thoughtIDD}`)
      .orderByChild('personid')
      .equalTo(personid)
      .once('value')
      .then(snapshot => {
        snapshot.forEach(childSnapshot => {
          data = childSnapshot.val().personid
        })

        console.log('didTest: ', data)
        if (data === personid) {
          if (personid === publisherID) {
            surveyDonex = false
          } else if (data) {
            surveyDonex = true
          } else {
            surveyDonex = false
          }
        } else {
          surveyDonex = false // undefined null etc.
        }
        this.setState({
          surveyDone: surveyDonex,
          isLoading: false
        })
      })
    }
    console.log('green-star survLength: ', surveyDonex);
    return surveyDonex
  }

  handleShowAttachments = option => {
    this.setState(prevState => ({
      showAttachments: !this.state.showAttachments,
      option: option
    }))
  };

  handleRemoveAttachment = (
    e,
    post,
    postType,
    postAttachmentType,
    postAttachmentID
  ) => {
    const params = {
      postID:
        post.dailyThoughtID || post.videoID || post.podcastID || post.newsID,
      postType: postType,
      attachmentType: postAttachmentType,
      attachmentID: postAttachmentID,
      post
    }

    this.setState({
      openDeleteAttachmentDialog: !this.state.openDeleteAttachmentDialog,
      anchorEl: e.currentTarget,
      params: params
    })

    this.handleOpenDeleteAttachmentDialog(e, params)
  };

  handleOpenDeleteAttachmentDialog = e => {
    this.setState({
      openDeleteAttachmentDialog: !this.state.openDeleteAttachmentDialog,
      anchorEl: e.currentTarget
    })
  };
  removeAttachment = () => {
    console.log('green removeAttachment 1: ', this.props)
    this.props.deletePostAttachment(this.state.params)
    this.setState({
      openDeleteAttachmentDialog: !this.state.openDeleteAttachmentDialog,
      anchorEl: null
    })
  };

  renderAttachments = () => {
    const { classes, thought, PlayMedia } = this.props
    const { showAttachments, option } = this.state
    return (
      <React.Fragment>
        <CardAttachments
          attachmentoption={this.state.option}
          openAttachments={this.state.showAttachments}
          onExpandAttachments={this.handleShowAttachments.bind(this)}
          onRemoveAttachment={this.handleRemoveAttachment.bind(this)}
          postsClicked={this.postClicked.bind(this)}
          openFileViewer={this.openFileViewer.bind(this)}
          {...this.props}
        />
        {this.state.openDeleteAttachmentDialog ? (
          <RemoveAttachmentDialog
            attachmentType={this.state.params.attachmentType || ''}
            onClickProceed={() => this.removeAttachment}
            toggleOpenDeleteAttachments={this.handleOpenDeleteAttachmentDialog}
            open={this.state.openDeleteAttachmentDialog}
            anchorEl={this.state.anchorEl}
            thought={this.props.thought}
            // userID={this.props.thought.userID}
            {...this.props}
          />
        ) : null}
      </React.Fragment>
    )
  };

  _openWebsite(id) {
    let params2 = {
      userdata: this.props.userdata,
      post: this.props.thought,
      type: USER_CLICKS_POST,
      res: 'clickedWebsiteLink',
      postTypeID: 'dailyThoughtID',
      postType: 'thoughts',
      obj: {
        itemID: id,
        title: id,
        ref: this._getWebsite(this.props.thought.website ? this.props.thought.website : '')
      }
    }

    // this.props.captureUserActivity({ ...params2 });
  }

  _getWebsite(url) {
    let res = ''
    if (url !== undefined || url !== '') {
      if (url === undefined) {
      } else if (url.indexOf('http') == -1) {
        let addHead = 'http://'
        res = addHead + url
      } else {
        res = url
      }
    }
    return res
  }

  postClicked = (res) => {
    let post = this.props.thought

    let params2 = {
      userdata: this.props.userdata,
      post: post,
      type: USER_CLICKS_POST,
      res: res,
      postTypeID: 'dailyThoughtID',
      postType: 'thoughts',
      obj: {
        itemID: post.dailyThoughtID,
        title: post.dailyThoughtID,
        ref: post.dailyThoughtID
      }
    }

    this.props.captureUserActivity({ ...params2 })
  }

  openFileViewer = (file) => {
    this.props.openFileViewer(file)
  }

  openTutorialViewer = () => {
    let file = {
      filepath: 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/PointsSystem6.mp4?alt=media&token=05f11b29-11b7-4eee-ae7c-2ec06590508b',
      filename: 'PointsSystem6.mp4'
    }

    this.props.openFileViewer(file)
  }

  openViewUserDialog = post => {
    console.log('post params ', post)
    let params = {
      userID: post.journalUserID,
      userName: post.journalUserName,
      companyID: post.companyID,
      companyName: post.companyName,
      photoURL: PHOTO_PROFILE(post.photoURL),
      showprofiledlg: true
    }

    // Fetch user bio data
    this.props.fetchUserBiography({ ...params, type: 'USER_BIOGRAPHY' })

    // Open user profile dialog
    this.props.openUserProfileDialog(params)
  };

  // openViewUserDialog = post => {
  //   let params = {
  //     userID: post.journalUserID,
  //     userName: post.journalUserName,
  //     companyID: post.companyID,
  //     companyName: post.companyName,
  //     photoURL: PHOTO_PROFILE(post.photoURL),
  //     showprofiledlg: true
  //   }

  //   // Fetch user bio data
  //   this.props.fetchUserBiography({ ...params, type: 'USER_BIOGRAPHY' })

  //   let params2 = {
  //     userdata: this.props.userdata,
  //     post: this.props.thought,
  //     type: USER_CLICKS_POST,
  //     res: 'viewedUserProfile',
  //     postTypeID: 'dailyThoughtID',
  //     postType: 'thoughts',
  //     obj: {
  //       itemID: post.dailyThoughtID,
  //       title: post.dailyThoughtID,
  //       ref: post.dailyThoughtID
  //     }
  //   }

  //   this.props.captureUserActivity({ ...params2 })

  //   // Open user profile dialog
  //   this.props.openUserProfileDialog(params)
  // };

  getURL(props) {
    for (let x in props) {
      return props[x].url
    }
  }

  getNAME(props) {
    for (let x in props) {
      return props[x].title
    }
  }
  getCaption(props) {
    for (let x in props) {
      return props[x].caption
    }
  }

  // funcGetPoints = (uid) => {
  //  // const getPoints = firebase.functions().httpsCallable('getPoints');
  //   this.getPoints({ uid: 'going going' }).then(result => {
  //   console.log('funcGetPoints 2: ', result.data);
  //  // return result.data;
  //   })
  //   .catch(error => {
  //     console.log('error in funcGetPoints: ', error)
  //    })
  // }


  funcGetPointsxx = (uidx, pointsx) => {
    // .put(`${BASE_API_URL}/getPoints/${uidx}`, uidx)
    console.log('funcGetPoints 1 ', uidx)

    axios
      .put(`${BASE_API_URL}/thoughts/${uidx}`)
      // .get(`${BASE_API_URL}/getpoints`)
      .then(res => {
        console.log('funcGetPoints 2: ', res.data)
      })
      .catch(err => {
        console.log('funcGetPoints err: ', err)
        // fetch(`${FB_URL}/getPoints`)
        // .then((response) => {
        //   console.log('funcGetPoints 2 ', response.json());
        //   return response.json();
        // })
        // .then((json) => {
        //   console.log(json);
        // });
      })
  }

  funcGetPoints(uidIn, daylyThought) {
    console.log('insertNewUserdata Thought props: ', this.props)
    if (uidIn) {
      let params = {
        uid: uidIn,
        thoughtid: daylyThought
      }
      this.props.insertNewUserdata(params)
    } else {
      console.log('insertNewUserdata no data in funcGetPoints')
    }
  }

  // funcGetPoints = document.querySelector('.call');
  // funcGetPoints.addEventListener(() => {

  // const getPoints = firebase.functions().httpCallable('getPoints');
  // getPoints({ uid: sdfasd }).then(result => {
  //  console.log(result.data);
  // });
  // })

  //  funcGetPoints = uid => {
  //   const callableReturnMessage = firebase.functions().httpsCallable('getPoints');
  //   callableReturnMessage().then((result) => {
  //     console.log(result.data);
  //    // return result.data;
  //   }).catch((error) => {
  //     //console.log(`error: ${JSON.stringify(error)}`);
  //     console.log('error in funcGetPoints: ', error);
  //   });
  //  }
  // Level 1 - 6000
  // Level 2 - 12000
  // Level 3 - 18000
  // Level 4 - 24000
  // Level 5 - 30000
  // Level 6 - 36000

  convertToLev = (inPoints) => {
    let points = parseInt(inPoints)
    let result
    if (points < 0) {
      result = 'Level 0'
    }    
    if (points >= 0 && points <= 2999) {
      result = 'Level 0'
    }
    if (points >= 3000 && points <= 5999) {
      result = 'Level 1'
    }
    if (points >= 6000 && points <= 8999) {
      result = 'Level 2'
    }
    if (points >= 9000 && points <= 11999) {
      result = 'Level 3'
    }
    if (points >= 12000 && points <= 14999) {
      result = 'Level 4'
    }
    if (points >= 15000 && points <= 17999) {
      result = 'Level 5'
    }
    if (points >= 18000) {
      result = 'Level 6'
    }
    // if (points > 21000) {
    //   result = 'Level 6'
    // }
    return result
  }

  // convertToLev = (inPoints) => {
  //   let points = parseInt(inPoints)
  //   let result
  //   if (points < 0) {
  //     result = 'Level 0'
  //   }    
  //   if (points >= 0 && points <= 5999) {
  //     result = 'Level 0'
  //   }
  //   if (points >= 6000 && points <= 11999) {
  //     result = 'Level 1'
  //   }
  //   if (points >= 12000 && points <= 17999) {
  //     result = 'Level 2'
  //   }
  //   if (points >= 18000 && points <= 23999) {
  //     result = 'Level 3'
  //   }
  //   if (points >= 24000 && points <= 29999) {
  //     result = 'Level 4'
  //   }
  //   if (points >= 30000 && points <= 35999) {
  //     result = 'Level 5'
  //   }
  //   if (points > 36000) {
  //     result = 'Level 6'
  //   }
  //   return result
  // }

//  getLeadingHash = (leadId, hash) => {
//   console.log('purple doTest title leadId : ', leadId + ' ' + hash)
//  }

  render() {
    const { thought, classes, match, imageurl, feedback, podcasturl, insertNewUserdata, isLoading, survLen, update_Survey, refreshThought, stateopinionID, isExpanded } = this.props

    console.log('green sss leader: ', thought.journalUserID)
    console.log('green sss slave: ', this.props.userdata.userID)
    //console.log('green sss feedback : ', feedback)

    try {
      if (thought.props.userdata.userType) {
      }
    } catch (err) {
      console.log('no thought.props'. err)
    }

    if (thought.uid) {
      if (this.props.userdata.points) {
        if (thought.uid === this.props.userdata.uid) {
          console.log('insertN---- 0 ', thought)
          let params = {
            uid: thought.uid,
            thoughtid: thought.dailyThoughtID,
            points: this.props.userdata.points
          }
          insertNewUserdata(params)
        } else {
          console.log('insertNewUserdata no data in funcGetPoints')
        }
        // }
      }
    }

    if (isLoading) {
      return <p>Loading ...</p>
    }

    //this.getLeadingHash(thought.hashtags, thought.id)
    // console.log('purple doTest points : ', thought.points)
    console.log('purple doTest thoughts : ', thought)
    // console.log('purple doTest----------------------------------------------------------')
    // console.log('purple doTest dailyThoughtDescription: ', thought.dailyThoughtDescription)
    console.log('purple doTest this.state.survLen: ', this.state.survLen)
    console.log('purple doTest thought.stage: ', thought.stage)
    console.log('purple doTest this.state.surveyDone: ', this.state.surveyDone)
    
    console.log('purpledoTest thought.companyID: ', thought.companyID)
    console.log('purpledoTest userdata.userID: ', this.props.userdata.userID)
    console.log('purpledoTest thought.journalUserID: ', thought.journalUserID)

    //console.log('purple doTest feedback : ', feedback)
    console.log('purple doTest----------------------------------------------------------')

    return (
     <div className={isExpanded ? classes.cardstyleExp : classes.cardstyle}>
        <RemoveFavorite item={thought} itemType='dailyThoughts' />
       {/* //CARD//////////////////////////////////////////////////////////////////// */}
       <TableContainer className={isExpanded ? classes.tableContainerexp : classes.tableContainer}>
      <Table size='small' aria-label='a dense table' border='0'>
      <TableBody>
      <TableRow>
    <TableCell className={isExpanded ? classes.avatar3exp : classes.avatar3}>  
      <TableRow align="left">
      {isExpanded ? <TableCell
          style={{
            //  borderColor: '#F9F8F5',
            //  border: '1px solid blue',
            //  overflow: 'hidden',
            //  whiteSpace: 'nowrap',
            // width: '1px',
            //  tableLayout: 'auto',
            // minWidth: isMobile ? '0px' : '0px',
            // maxWidth: isMobile ? '0px' : '0px',
            // width: isMobile ? '0px' : '0px',
            // backgroundColor: '#000000',

            // width: '1px',
            tableLayout: 'auto',
            maxWidth: '1px',
            overflow: 'hidden',
            // align: 'top',
             backgroundColor: '#000000',

            // marginLeft: isMobile ? '-100px' : '-100px'
            // flexDirection: 'row',
            // flexShrink: 1,
            // whiteSpace: 'pre-wrap', 
            // overflowWrap: 'break-word',

            }}
        >
       </TableCell> :
      <TableCell style={{borderColor: '#F9F8F5'}}>

      {thought.companyID==='-MloJFGCyJJbs_bNlwTg' && thought.journalUserID!==this.props.userdata.userID ?
        //  <ViewUser_ledain 
         <ViewUser_gen
          src={thought.photoURL && (thought.photoURL.length > 0) ? PHOTO_PROFILE(thought.photoURL) : PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40') }
          //id={thought.uid + 'genled'}
          leaderArr={this.props.userdata.leaderArr}
          companyName={this.props.userdata.companyName}
          leaderID={thought.journalUserID}
          slaveID={this.props.userdata.userID}
          companyID={this.props.userdata.companyID}
          firstName={this.props.userdata.firstName}
          lastName={this.props.userdata.lastName}
          uid={this.props.userdata.uid}
          leaderUid={thought.uid}
          points={this.props.userdata.points}
          pointsy={this.props.userdata.pointsy}
          post={thought} 
          following={
            this.props.followingData
              ? this.props.followingData[thought.userID]
                ? true
                : false
              : false
          }
         // following={this.props.following}
          journalUserID={thought.journalUserID}
          userIDlogged={this.props.userdata.userID}
          evalNum={this.props.userdata.evalNum ? this.props.userdata.evalNum : 0}
          evalNumP={this.props.userdata.evalNumP ? this.props.userdata.evalNumP : 0}
        /> :null}

  {thought.companyID==='-MloJFGCyJJbs_bNlwTg' && thought.journalUserID===this.props.userdata.userID ?
        //  <ViewUser_ledain_self 
         <ViewUser_self_gen
          // src={thought.photoURL && (thought.photoURL.length > 0) ? PHOTO_PROFILE(thought.photoURL) : PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40') }
          // companyName={this.props.userdata.companyName}
          // leaderID={thought.journalUserID}
          // slaveID={this.props.userdata.userID}
          // companyID={this.props.userdata.companyID}
          // firstName={this.props.userdata.firstName}
          // lastName={this.props.userdata.lastName}
          // uid={this.props.userdata.uid}
          // leaderUid={thought.uid}
          // points={this.props.userdata.points}
          // pointsy={this.props.userdata.pointsy}
          // post={thought} 
          // following={this.props.following}
          // journalUserID={thought.journalUserID}
          // userIDlogged={this.props.userdata.userID}

          src={thought.photoURL && (thought.photoURL.length > 0) ? PHOTO_PROFILE(thought.photoURL) : PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40') }
          //id={thought.uid + 'selfled'} 
          companyName={this.props.userdata.companyName}
          leaderID={thought.journalUserID}
          slaveID={this.props.userdata.userID}
          companyID={this.props.userdata.companyID}
          firstName={this.props.userdata.firstName}
          lastName={this.props.userdata.lastName}
          uid={this.props.userdata.uid}
          leaderUid={thought.uid}
          points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
          pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
          post={thought} 
          following={
            this.props.followingData
              ? this.props.followingData[thought.userID]
                ? true
                : false
              : false
          }
          journalUserID={thought.journalUserID}
          userIDlogged={this.props.userdata.userID}
        /> :null}

     {thought.companyID!=='-MloJFGCyJJbs_bNlwTg' && thought.journalUserID!==this.props.userdata.userID ?
        <ViewUser_gen
           src={thought.photoURL && (thought.photoURL.length > 0) ? PHOTO_PROFILE(thought.photoURL) : PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40') }
          //src={'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40' }
          // id={thought.uid + 'gen'}
           companyName={this.props.userdata.companyName}
           leaderArr={this.props.userdata.leaderArr}
           leaderID={thought.journalUserID}
           slaveID={this.props.userdata.userID}
           companyID={this.props.userdata.companyID}
           firstName={this.props.userdata.firstName}
           lastName={this.props.userdata.lastName}
           uid={this.props.userdata.uid}
           leaderUid={thought.uid}
           points={this.props.userdata.points}
           pointsy={this.props.userdata.pointsy}
           following={
            this.props.followingData
              ? this.props.followingData[thought.userID]
                ? true
                : false
              : false
          }
           post={thought} 
          // following={this.props.following}
           journalUserID={thought.journalUserID}
           userIDlogged={this.props.userdata.userID}
           evalNum={this.props.userdata.evalNum ? this.props.userdata.evalNum : 0}
           evalNumP={this.props.userdata.evalNumP ? this.props.userdata.evalNumP : 0}
        />  
         :null}
     {thought.companyID!=='-MloJFGCyJJbs_bNlwTg' && thought.journalUserID===this.props.userdata.userID ?
        // <ViewUser_self
        <ViewUser_self_gen
        src={thought.photoURL && (thought.photoURL.length > 0) ? PHOTO_PROFILE(thought.photoURL) : PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40') }
        //id={thought.uid + 'self'}
        companyName={this.props.userdata.companyName}
        leaderID={thought.journalUserID}
        slaveID={this.props.userdata.userID}
        companyID={this.props.userdata.companyID}
        firstName={this.props.userdata.firstName}
        lastName={this.props.userdata.lastName}
        uid={this.props.userdata.uid}
        leaderUid={thought.uid}
        points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
        pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
        post={thought} 
        following={
          this.props.followingData
            ? this.props.followingData[thought.userID]
              ? true
              : false
            : false
        }
        journalUserID={thought.journalUserID}
        userIDlogged={this.props.userdata.userID}
        />  
         :null}

          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
   
            {thought.uid && thought.uid === this.props.userdata.uid ?
              [thought.stage && thought.stage === 2 ?
                <TestStats
                  thoughtID={thought.dailyThoughtID}
                  userdata={this.props.userdata}
                  title={thought.title}
                  key={this.props}
                  surveyid={thought.dailyThoughtID}
                  companyID={this.props.userdata.companyID}
                  props={this.props}
                  userID={this.props.userdata.userID}
                  size={this.state.survLen}
                />
                : null]
              :
                // <PointsTutorial
                //     thoughtID={thought.dailyThoughtID}
                //     userdata={this.props.userdata}
                //     title={thought.title}
                //     key={this.props}
                //     surveyid={thought.dailyThoughtID}
                //     companyID={this.props.userdata.companyID}
                //     props={this.props}
                //     userID={this.props.userdata.userID}
                //     size={this.state.survLen}
                //   />
             <div/>
              // <IconButton color='secondary' aria-label='add an alarm' >
              //   <div style={{ color: 'black', textShadow: '3px 3px 3px gold' }} >
              //     <Icon glyph='menu' size={32} />
              //   </div>
              // </IconButton>
            }

             {thought.uid && thought.uid === this.props.userdata.uid ?           
            [thought.stage && thought.stage === 1 ?
                <MakeTest
                  thoughtID={thought.dailyThoughtID}
                  userdata={this.props.userdata}
                  showLeadb={this.props.showLeadb}
                  title={thought.title}
                  key={this.props}
                  props={this.props}
                  resetMakeTest={this.getSurveyLength}
                  getSurveyStage={this.getSurveyStage}
                  dailyThoughtType={thought.dailyThoughtType}
                  groupid={thought.groupid ? thought.groupid : 0}
                  companyID={thought.real_companyID ? thought.real_companyID : thought.companyID}
                  // real_companyID={thought.real_companyID}
                />
                : null]
              : null}

            {parseInt(this.state.survLen) < parseInt(110) ?
              [thought.stage && thought.stage === 2 && this.state.surveyDone === false ?
                <IconButton style={{
                  color: 'black',
                  width: '48px',
                  height: '48px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <DoTest
                    thoughtID={thought.dailyThoughtID}
                    // thoughtName={thought}
                    companyID={thought.companyID}
                    real_companyID={thought.real_companyID}
                    surveyName={thought.title}
                    userID={this.props.userdata.userID}
                    showLeadb={this.props.showLeadb}
                    // thought={thought}
                    // userID={thought.userID}
                    size={this.state.survLen}
                    resetTest={this.didTest}
                    publisherid={thought.journalUserID}
                    getSurveyStage={this.getSurveyStage}
                    thoughtType={thought.dailyThoughtType}
                    title={thought.title}
                    subtitle={thought.subtitle}
                  />
                </IconButton>
                : null]
              : null}
           
          </div>
   
        </TableCell> 
        }    
        </TableRow>
      <TableRow><TableCell className={isExpanded ? classes.avatar3exp : classes.avatar3}
      ></TableCell></TableRow>  
     </TableCell>
      <TableCell style={{
         minWidth: isMobile ? '100%' : '80%',
         maxWidth: isMobile ? '100%' : '65%',
        // backgroundColor: 'blue',
         align: 'left',
         alignItems: 'left',
         justifyContent: 'left',
         alignContent: 'left',
        }}>

      <TableRow><TableCell className={isExpanded ? classes.card2exp : classes.card2}
      title={DATE_FORMAT.type(thought.dateScheduled, 'BADGE')}>           
       <div style={{
             minWidth: isMobile ? '85%' : '500px',
             maxWidth: isMobile ? '95%' : '550px',
             width: isMobile ? '90%' : '500px',
       }}>
        {isMobile ? <div><b> {thought.journalUserName.slice(0, 30)} </b></div> : null} 
       {
              thought.points ? <>
                <div className={classes.points}><b>{isBrowser ? thought.journalUserName.slice(0, 30) :null}</b>
                  {this.convertToLev(thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points) === 'Level 0' ? <BiPyramid fontSize="1.5em" />
                  :this.convertToLev(thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points) === 'Level 1' ? <BiHive fontSize="1.5em" />
                    : this.convertToLev(thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points) === 'Level 2' ? <BiArch fontSize="1.5em"/>
                      : this.convertToLev(thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points) === 'Level 3' ? <BiAperture fontSize="1.5em"/>
                        : this.convertToLev(thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points) === 'Level 4' ? <BiAnchor fontSize="1.5em" />
                          : this.convertToLev(thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points) === 'Level 5' ? <BiMagnet fontSize="1.5em"/>
                            : this.convertToLev(thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points) === 'Level 6' ? <BiCrown fontSize="1.5em" />
                              : null}

                 {/* { this.state.freshPoints > 0 ? 
                 this.convertToLev(this.state.freshPoints) +'('+this.state.freshPoints+')'
                 :
                  this.convertToLev(parseInt(thought.points) + parseInt(thought.pointsCalf))  +'('+ (parseInt(thought.points) + parseInt(thought.pointsCalf)) +')'
                 } */}
                 
                 {`${this.convertToLev(thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points)} (${thought.pointsCalf ? parseInt(thought.points) + parseInt(thought.pointsCalf) : thought.points} points)`}

                 {/* {isMobile ? <div> <i>{thought.companyName.slice(0, 20)}</i> </div> : null} */}
                  {isBrowser ? <i>{thought.companyName.slice(0, 20)}</i> : null}
              <span 
              style={{
                  whiteSpace: 'nowrap', 
                  flexWrap: 'nowrap'
              }}
              >
              {thought.journalUserID !== this.props.userdata.userID ? <FollowButton post={thought} following={this.props.following} ></FollowButton> : null}       
              {thought.journalUserID === this.props.userdata.userID ? 
               <MoreMenu
                post={thought}
                thought={thought}
                posttype='thoughts'
                userdata={this.props.userdata}
              /> 
              :null}  
              </span>
                </div>
                  </>
                :  thought.companyName
            } 
 
            </div>

            </TableCell></TableRow>
      <TableRow><TableCell className={isExpanded ? classes.cardtitleExp : classes.cardtitle}>
      {/* <div className={isExpanded ? classes.cardtitleExp : classes.cardtitle}> */}
        {thought.title} 
        {/* </div> */}

      </TableCell></TableRow>

      <TableRow style={{ 
        borderColor: '#F9F8F5',
        backgroundColor: '#F9F8F5',
        whiteSpace: 'nowrap',
        // textOverflow: 'ellipsis',
        //overflow: 'hidden',
        //height: '50px'
       }}>
        <TableCell className={classes.favoritesrow2}>
 
 
{ isExpanded===false ?
 <TableRow className={classes.favoritesrow}>
   <TableCell className={classes.favoritesrow}>
  <Liking
    postType='thoughts'
    postID={thought.dailyThoughtID}
    post={thought}
    points={thought.points}
    showLeadb={this.props.showLeadb? this.props.showLeadb: ''}
  />
  </TableCell>
  <TableCell style={{
    borderColor: '#F9F8F5',
    backgroundColor: '#F9F8F5'
  }}>
     <div className={classes.sharediv} >  
          {/* SOCIAL SHARING------------------------------------------- */}
          <IconButton style={{
            color: '#C3BFB2',
            width: '50px',
            height: '30px',
            display: 'flex',
            //justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#F9F8F5',
            backgroundColor: '#F9F8F5'
          }}>
            <LoadSocialNetworks
              post={
                window.location.origin + '/filtered-thoughts/#/' +
                thought.dailyThoughtID
              }
              imageURL={
                window.location.origin +
                PHOTO_BACKGROUND(thought.photos)
              }
              allthought={thought}
              source={'thoughtscard'}
              userdata={this.props.userdata}
              imageprop={this.getURL(this.props.thought.photos)}

              podcasturl={this.getURL(this.props.thought.podcasts)}
              podcastname={this.getNAME(this.props.thought.podcasts)}

              videoname={this.getCaption(this.props.thought.videos)}
              videourl={this.getURL(this.props.thought.videos)}

              filetitle={this.getNAME(this.props.thought.files)}
              fileurl={this.getURL(this.props.thought.files)}

              articlesurl={this.getURL(this.props.thought.urls)}
              articletitle={this.getNAME(this.props.thought.urls)}

              filesprop={this.getURL(this.props.thought.files)}
              logoprop={this.props.thought.logo}
              thought={this.props.thought.title}
              postuserName={this.props.thought.journalUserName}
              postuserId={this.props.thought.journalUserID}
              quotemaster={this.props.thought.subtitle}
              location={this.props.location && this.props.location.pathname ? this.props.location.pathname : ''}
              articleLink={'none'}
              slogan={this.props.thought.slogan}
              website={this.props.thought.website}
              textcolor={this.props.textColor}
            />
          </IconButton>
          {/* SOCIAL SHARING------------------------------------------- */}
        {/* </div>
    </div>*/}

     </div>  
  </TableCell>
  <TableCell style={{ 
    borderColor: '#F9F8F5',
    backgroundColor: '#F9F8F5'
    }}>
  <div
    className={classes.commentsdiv}
    onClick={this.props.OnExpandCommentsClick}
  >
    {/* <div > */}
      <IconButton style={{ 
        marginLeft: "0px", 
        color: '#CDCCB6',
        width: '30px',
        height: '0px'
        }} aria-label='Forum'>
        <ForumIcon />
      </IconButton>

      <Badge 
      //    style={{ 
      //    // width: 'max-content',
      //     color: '#000',
      //     padding: '8px',
      //     fontSize: '8pt',
      //     //right: '-50px',
      //     //top: '-25px',
      //     backgroundColor: '#C3BFB2'
      // }} 
        badgeContent={
          thought.comments
            ? '' + Object.values(thought.comments).length
            : '0'
        }
        classes={{ badge: classes.commentsbadge }}
      /> 
    
  </div>
  </TableCell>
  <TableCell style={{
        borderColor: '#F9F8F5',
        backgroundColor: '#F9F8F5'
    }}>
  <div className={classes.attachments2}>{this.renderAttachments()}</div>
 </TableCell>
  
{/* </div> */}
</TableRow>
:null}

{/* </CardActions> */}

 <div className={this.props.expandComments ? classes.actionsCommentsexp : classes.actionsComments} >
<AddCommentField
  parentID={''}
  isSubComment={false}
  post={thought}
  postType={'thoughts'}
  showLeadb={this.props.showLeadb? this.props.showLeadb: ''}
 // classes={classes}
/> 
</div> 
<CardActions
className={this.props.expandComments ? '' : classes.actionsComments}
>
<Comments postType={'thoughts'} post={thought} {...this.props} />
{!this.props.expandComments ? (
  <div
    onClick={this.props.OnExpandCommentsClick}
    className={classes.maskText}
  >
    <div
      onClick={this.props.OnExpandCommentsClick}
      className={classes.mask}
    >
      View All comments
    </div>
  </div>
) : null}
</CardActions>      
       </TableCell></TableRow>
      </TableCell>

      </TableRow>

      </TableBody>
      </Table>
      </TableContainer>
      {/* //CARD//////////////////////////////////////////////////////////////////// */}

      </div>
    )
  }
}


class ThoughtsCard extends React.Component {
  constructor(props) {
    super(props)
    this.cardRef = React.createRef()
    // console.log('#thoughtcard props ',this.props)
    // // console.log('#thoughtcard photo background: ',this.props.thought.PHOTO_BACKGROUND)
  }
  state = { openDialogCard: false, expanded: false, thought: {}, liked: false };

  handleCommentsClick = () => {
    this.setState(prevState => ({
      openDialogCard: !this.state.openDialogCard
    }))
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  };

  handleLikeClick = thought => {
    this.setState(prevState => ({
      liked: !this.state.liked
    }))
  };

  handleOpenAddToPLDPDialog = thought => {
    this.props.onOpenAddToPLDPDialog(thought)
  };

  openViewUserDialog = thought => {
    this.setState({
      open: !this.state.open
    })
  };

  // shouldComponentUpdate(nextProps, nextState) {  
  //  if (this.state.thought !== nextState.thought) {  
  //   return true;  
  //   }  
  //   return false;  
  // } 

  render() {
    const { classes, thought, getNewUserdata } = this.props

    return (
      <React.Fragment>
        {/* TODO: Add Comments Dialog */}
        {this.state.openDialogCard ? (
          <CommentDialogCard
            style={{ backgroundColor: 'transparent' }}
            open={this.state.openDialogCard}
            onOpenCommentsClick={this.handleCommentsClick}
            render={(state, handleClose) => (
              <React.Fragment>
                <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.close}
                  onClick={this.handleCommentsClick}
                >
                  <CloseIcon fontSize='small' className={classes.icon} />
                </IconButton>
                <Thoughts
                  OnExpandCommentsClick={this.handleCommentsClick}
                  expandComments={true}
                  thought={thought}
                  getNewUserdata={getNewUserdata}
                  isExpanded={true}
                  {...this.props}
                />
              </React.Fragment>
            )}
          />
        ) : (
          <Thoughts
            OnExpandCommentsClick={this.handleCommentsClick}
            expandComments={false}
            thought={thought}
            getNewUserdata={getNewUserdata}
            isExpanded={false}
            {...this.props}
          />
        )}
      </React.Fragment>
    )
  }
}

ThoughtsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  thought: PropTypes.object.isRequired,
  hideComments: PropTypes.bool
}

const ThoughtsCardStyled = withStyles(styles)(ThoughtsCard)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    survey_stats_list: state.surveyStat.survey_stats_list,
    survey_tests_list: state.surveyTes.survey_tests_list,
    refreshThought: state.surveyList.card_refresh,
    feedback: state.opinions.feedback,
    stateopinionID: state.opinions.opinionid,
   // opinions: state.opinions.opinion
    //thoughts: state.thoughts.ilead
    //opinions: state.opinions.opinion
    // testPtest: state.testTest.testShow,
    // update_Survey: state.updateSurvey.update_Survey
    // freshName: state.fresh.name,
    // freshlastName: state.fresh.lastname,
    // points: state.fresh.points,
    // club: state.fresh.club
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUserBiography: params => dispatch(fetchUserBiography(params)),
    openUserProfileDialog: params => dispatch(openUserProfileDialog(params)),
    captureUserActivity: params => dispatch(captureUserActivity(params)),
    insertNewUserdata: params => dispatch(insertNewUserdata(params)),
    surveyStats: params => dispatch(surveyStats(params)),
    surveyTestsDisplay: params => dispatch(surveyTestsDisplay(params)),
    refreshCard: params => dispatch(refreshCard(params)),
    getOpinions: params => dispatch(getOpinions(params)),
    getOpinionsTrail: params => dispatch(getOpinionsTrail(params)),
    FindSlave: params => dispatch(FindSlave(params)),
   // getOpinions: params => dispatch(getOpinions(params)),
    //fetchThoughts: params => dispatch(fetchThoughts(params))
    // testTestAction: params => dispatch(testTestAction(params))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ThoughtsCardStyled)
)
