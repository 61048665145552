import React, { Component } from "react";
import PropTypes from "prop-types";
import VideosView from "../components/Views/VideosView";
import { connect } from "react-redux";
import {
  fetchVideos,
  fetchExternalVideos
} from "../store/actions/videosActions";
import { fetchUserData } from "../store/actions/userActions";
import {
  INTERNAL_VIDEOS,
  EXTERNAL_VIDEOS,
  SET_SEEN_NOTIFICATIONS
} from "../store/actions/types";
import { deletePostAttachment } from "./../store/actions/attachmentActions";

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      minData: 6
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 6
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    new Promise((resolve, reject) => {
      if (this.props.userdata && this.props.userdata.companyID !== '-Lu4BlhZ-tsDSnd-fnFd') {
        this.props.fetchExternalVideos({
          type: EXTERNAL_VIDEOS,
          companyID: this.props.userdata.companyID,
          orderChild: "dailyThoughtType_status",
          equalTo: "2_approved",
          minData: this.state.minData,
          resolve: resolve,
          reject: reject
        });
      } else {
        resolve({
          type: EXTERNAL_VIDEOS,
          externalvideos: {}
        })
      }
    }).then(params => {
        return new Promise((resolve, reject) => {
          this.props.fetchVideos({
            type: INTERNAL_VIDEOS,
            companyID: this.props.userdata.companyID,
            orderChild: "companyID_status",
            equalTo: this.props.userdata.companyID + "_approved",
            minData: this.state.minData,
            resolve: resolve,
            reject: reject
          });
        });
      }).then(params => {
        this.props.fetchUserData({
          type: SET_SEEN_NOTIFICATIONS,
          userID: this.props.userdata.userID,
          path: this.props.match.path,
          unseenotifications: this.props.unseenotifications.unseenotifications
        });
      });
  };

  render() {
    const { videos, userdata } = this.props;
    console.log('videosView props1: ', this.props);

    return (
      <div>
        {userdata && videos ? (
          <React.Fragment>
            <VideosView
              videos={videos}
              currentUser={userdata}
              {...this.props}
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

Videos.propTypes = {
  fetchVideos: PropTypes.func.isRequired,
  fetchExternalVideos: PropTypes.func.isRequired,
  videos: PropTypes.object
};

const mapStateToProps = state => {
  return {
    videos: { ...state.videos.videos, ...state.videos.externalvideos },
    userdata: state.userDialog.userDetails[0],
    unseenotifications: state.userdata.unseenotifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchVideos: params => dispatch(fetchVideos(params)),
    fetchExternalVideos: params => dispatch(fetchExternalVideos(params)),
    deletePostAttachment: params => dispatch(deletePostAttachment(params)),
    fetchUserData: params => dispatch(fetchUserData(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Videos);
