import { UPDATE_SURVEY } from "./types";
import firebase from "../firebase";

export const updateSurvey = params => {
  console.log('UPDATE_SURVEYff 1: ', params);
  
  if (params.type === UPDATE_SURVEY) {
    return (dispatch, getState) => {
      firebase.database.ref(`surveys/${params.companyID}/${params.surveyid}`).on("value", function(data) {
     // firebase.database.ref(`surveys/${params.companyID}/${params.thoughtID}/size`).on("value", function(data) {
      
       var myData=data.val();
        if (data.val()) {
          myData = data.val();
        } 
        else
        {
          myData = 111;
        }
        console.log('UPDATE_SURVEYxx start: ', myData);
        // if(myData===null || myData===undefined) {
        //   console.log('UPDATE_SURVEYxx null or undefined: ', myData);
        //   return (dispatch, getState) => {
        //     dispatch({
        //       type: UPDATE_SURVEY,
        //       update_Survey: 1000
        //     });
        //   };
        // } else {
         if (myData) {
          console.log('UPDATE_SURVEYxx Data 1: ', myData);
          dispatch({
            type: UPDATE_SURVEY,
            update_Survey: myData,
          });
    
        } else {
          console.log('UPDATE_SURVEYxx Data 2: ', myData);
          return (dispatch, getState) => {
            dispatch({
              type: UPDATE_SURVEY,
              update_Survey: 111,
            });
          };
        } 
        }
      // }
      );
   } 
 }
};
