import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { DATE_FORMAT } from "../../utils/date_format_utils";
import { withStyles } from "@material-ui/styles";
import { LIGHTBLUE, LIGHTGREY } from "../../utils/color_utils";
import { Badge, Fab } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import { Button, Tooltip } from '@material-ui/core'
import {
  followUser,
  getUserFollowing
} from "../../store/actions/followerAction";
import { withRouter } from "react-router-dom";


const styles = ({ breakpoints }) => ({
  margin: {
    margin: 10
  },
  badgemargin: {
    // position: "absolute",
    height: "20px !important",
    // top: "-12px",
    // right: "1rem",
    color: "#ffffff",
    padding: "0 20px !important",
    fontSize: "8pt",
    zIndex: 999,
    boxShadow: "none",
    "&:focus": {
      boxShadow: "none"
    },
    zIndex: 999
  },
  badgemarginXs: {
    // position: "absolute",

    height: "20px !important",
    // top: "14px !important",
    // right: "1rem",
    color: "#ffffff",
    padding: "0 20px !important",
    fontSize: "8pt",
    zIndex: 999,
    boxShadow: "none",
    "&:focus": {
      boxShadow: "none"
    }
  },
  followingButton: {
    backgroundColor: LIGHTBLUE,
    border: "none !important"
  },
  followButton: {
    backgroundColor: "#fff",
    border: "1px solid #810029",
    color: "#810029",
    fontWeight: "500"
  },
  hideButton: {
    display: "none"
  }
});

class FollowButton extends Component {
  state = {
    following: false
  };

  componentWillMount() {
    const { userData } = this.props;

    console.log('London following : ', this.props.userData);
  }

  componentDidUpdate() { }
  handleFollowing = () => {
    const { userData, post } = this.props;
    this.setState(prevState => ({
      following: !this.state.following
    }));
    this.sendFollowing(userData, post);
  };

  sendFollowing = (userData, post) => {
    const { followUser } = this.props;
    const params = {
      //	myData
      followerCompanyID: userData.companyID,
      followerCompanyName: userData.companyName,
      //	followerEmail: userData.email,
      followerFirstName: userData.firstName,
      followerLastName: userData.lastName,
      followerPhotoURL: userData.photoURL ? userData.photoURL : "https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40",
      followerUserID: userData.userID,
      // their
      parentUserID: post.journalUserID ? post.journalUserID : post.userID,
      parentCompanyID: post.companyID || "",
      parentCompanyName: post.companyName || "",

      //	parentEmail: '',
      parentFirstName: post.journalUserName
        ? post.journalUserName
        : post.userName
          ? post.userName
          : post.firstName
            ? post.firstName + " " + post.lastName
            : "",
      parentLastName: "",
      parentPhotoURL: post.photoURL ? post.photoURL : "",
      followUnfollow: this.props.following ? "unfollow" : "follow"
    };
    followUser(params);
  };

  render() {
    const { post, postID, classes, userData, match, following } = this.props;

    let toggleClass = this.props.following
      ? classes.followingButton
      : classes.followButton;

    var userID = post.journalUserID ? post.journalUserID : post.userID;
    let followTop =
      window.innerWidth < 600 ? classes.badgemarginXs : classes.badgemargin;
    let hideIfLoggedIn = userID === userData.userID ? classes.hideButton : "";
    const showFollow = match.path !== "/pldp";
    return (
       showFollow && (
        <Fab
          onClick={this.handleFollowing}
          variant="extended"
          size="small"
          className={classNames(
            classes.margin,
            followTop,
            toggleClass,
            hideIfLoggedIn
          )}
          style={{ minHeight: "34px!important" }}
        >
          {following ? "Following" : "Follow"}
        </Fab>
       )
      );
    }
}

FollowButton.propTypes = {};

const Follow = withStyles(styles, { withTheme: true })(FollowButton);

const mapStateToProps = (state, ownProps) => {
  return {
    userData: state.userDialog.userDetails[0]
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    { followUser, getUserFollowing }
  )(Follow)
);
