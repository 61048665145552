import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from "@material-ui/core/withMobileDialog";
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
import { LIGHTGREY } from "../../utils/color_utils";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  dialogHeight: {
    maxHeight: `calc(100% - 20px)`
  },
  title: {
    background: `${LIGHTGREY}`
  },
});

class FileViewerDialog extends Component {

  onError = (e) => {
    console.log("Error: ", e);
  }

  render() {
    const { classes, opendialog, fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={opendialog}
          aria-labelledby="form-dialog-title"
          fullScreen={fullScreen}
          fullWidth
          maxWidth="md"
          classes={{ paper: classes.dialogHeight }}
        >
          <DialogTitle id="form-dialog-title" className={classes.title}>
            <Grid container spacing={2}>
              <Grid item xs={11} sm={11}>
                {this.props.filename ? this.props.filename : "Viewing File"}
              </Grid>
              <Grid item xs={1} sm={1}>
                <IconButton color="secondary" aria-label="close" onClick={this.props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <FileViewer
              fileType={this.props.filetype}
              filePath={this.props.filepath}
              errorComponent={CustomErrorComponent}
              onError={() => this.onError} />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

FileViewerDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool
};

const FileViewerMobDialog = withMobileDialog({ breakpoint: "xs" })(FileViewerDialog);

const FileViewerMobDialogStyled = withStyles(styles)(FileViewerMobDialog);

export default FileViewerMobDialogStyled;
