import { SHOW_TOAST, gGET_O, gGET_O_S, gGET_SLAVE_OPINION, gGET_OPINIONID } from "./../actions/types";
import firebase from "../firebase";
import { _toArrayUsers, _toArrayUsersF } from './commonActions'

// function makeid(length) {
//     var result           = '';
//     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     var charactersLength = characters.length;
//       for ( var i = 0; i < length; i++ ) {
//         result += characters.charAt(Math.floor(Math.random() * 
//   charactersLength));
//     }
//     return result;
//   }

export const GgetOpinionsTrail = params => {
    return (dispatch, getState) => {
      firebase.database
        .ref('GopinionsTrail')
        .orderByChild('leaderID')
        .equalTo(params.leaderID)
        .on('value', function (data) {
          if (data.val()) {
            let feedback = data.val()
            let receivedData = {}
            delete feedback.undefined
            // if (feedback.slaveID !== params.slaveID) {
            //   delete feedback.slaveID
            // }
            if (Object.keys(feedback).length > 0) {
              let sortedData = _toArrayUsersF(feedback, params.slaveID)
              sortedData.sort(function (a, b) {
              })
  
              receivedData = { ...sortedData }
  
              receivedData = Object.assign({}, sortedData)
              //console.log('green populateState 0 : ', receivedData)
              console.log('green feedback 0 : ', receivedData)
             }
  
            dispatch({
              type: gGET_SLAVE_OPINION,
              slaveopinion: receivedData
            })
          } else {
            dispatch({
              type: gGET_SLAVE_OPINION,
              slaveopinion: {}
            })
          }
        })
    }
  }

export const GaddOpinions = params => {
    let database = firebase.database;
    const updates = {};
   // const opinionID = makeid(10);
    var addOpinion = true;
   ///////////////////////////////////////
   console.log('green submitPost opinionID x : ', params)
    return (dispatch, getState) => {
        updates['/GopinionsTrail/' + params.opinionID ] = { 
            opinionID: params.opinionID,
            leaderID: params.leaderID,
            slaveID: params.slaveID,
            companyName: params.companyName,
            companyID: params.companyID,
            firstName: params.firstName,
            lastName: params.lastName,
            slave: params.slave,
            one: params.one,
            qoneopinion: params.qoneopinion,
            two: params.two,
            qtwoopinion: params.qtwoopinion,
            three: params.three,
            qthreeopinion: params.qthreeopinion,
            four: params.four,
            qfouropinion: params.qfouropinion,
            // five: params.five,
            // qfiveopinion: params.qfiveopinion,
            // six: params.six,
            // qsixopinion: params.qsixopinion,
            // seven: params.seven,
            // qsevenopinion: params.qsevenopinion,
            // eight: params.eight,
            // qeightopinion: params.qeightopinion,
            // nine: params.nine,
            // qnineopinion: params.qnineopinion,
            // ten: params.ten,
            // qtenopinion: params.qtenopinion,
           };
          //  dispatch({
          //   type: SHOW_TOAST,
          //   variant: "success",
          //   message: "testing.",
          //   pos: "bottom"
          // });
           database
            .ref()
            .update(updates)
            .then(() => {
               dispatch({
                type: SHOW_TOAST,
                variant: "success",
                message: "Thank you for your feedback.",
                pos: "bottom"
              });
            })
            .catch(err => {
              dispatch({
                type: "SHOW_TOAST",
                open: true,
                variant: "error",
                message: "Error response: " + err.message
              });
              })    
           }
      }
 
      const getSlaveID = (props) => {
        for (let x in props) {
          return props[x].slaveID
        }
       }

       export const xfindSlave = (leaderID, slaveID) => {
         var pass = true;
         var database = firebase.database;
                 database
                  .ref('GopinionsTrail')
                  .orderByChild('leaderID')
                  .equalTo(leaderID)
                  .on('value', function (data) {  
                    if (data.val()) {
                      let odata = data.val() 
                      if (Object.keys(odata).length > 0) {
                       let sortedData = _toArrayUsers(odata)
                       Object.keys(sortedData).map((id, index) => {
 
                     if (sortedData[id].leaderID===leaderID) {
                       if (sortedData[id].slaveID===slaveID) { //user already made opinion on this leader
                         console.log('blue slave data ', slaveID)
                        pass = false;
                        return pass;
                       }
                     }
                   }) }} })}

    export const deleteSlave = params => {
       // console.log('blue slave data 2 ', params)
        return (dispatch, getState) => {
        var database = firebase.database;
                database
                 .ref('GopinionsTrail')
                 .orderByChild('leaderID')
                 .equalTo(params.leaderID)
                 .on('value', function (data) {  
                   if (data.val()) {
                     let odata = data.val() 
                     if (Object.keys(odata).length > 0) {
                      let sortedData = _toArrayUsers(odata)
                      Object.keys(sortedData).map((id, index) => {

                   // console.log('blue slave data params.opinionID : ', params.opinionID)
                   // console.log('blue slave data sortedData[id].opinionID : ', sortedData[id].opinionID)

                   // if (sortedData[id].opinionID === params.opinionID) {} else {
                      if (sortedData[id].slaveID===params.slaveID) { //user already made opinion
                        console.log('blue slave data 5 : ', sortedData[id].opinionID + ' removed')
                        database.ref(`GopinionsTrail/${sortedData[id].opinionID}`).remove()
                      }
                    //}
                  }) }} })}}

 export const GgetOpinions = params => {
      if (params.leaderID) {
        return (dispatch, getState) => {
           firebase.database
            .ref('GopinionsTrail')
            .orderByChild('leaderID')
            .equalTo(params.leaderID)
            .on('value', function (data) {    
              if (data.val()) {
                let odata = data.val()
                let receivedData = {}
                delete odata.undefined
                if (Object.keys(odata).length > 0) {

                  let sortedData = _toArrayUsers(odata)
                  //let sortedData = _toArrayUsersF(odata, params.slaveID)
                  console.log('odata : ', sortedData);
  
                  sortedData.sort(function (a, b) {
      
                    if (a.firstName > b.firstName) {
                      // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                      return 1
                    }
                    if (a.firstName < b.firstName) {
                    //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                      return -1
                    }
                    return 0
                  })
      
                  receivedData = { ...sortedData }
      
                  receivedData = Object.assign({}, sortedData)
                  console.log('in getOpinions 2g ', receivedData)
                 }
      
                dispatch({
                  type: gGET_O,
                  opinion: receivedData
                })
              } else {
                dispatch({
                  type: gGET_O,
                  opinion: {}
                })
              }
            })
        }
       }
      }

      export const GgetOpinionsOnSubmit = params => {
        if (params.leaderID) {
          return (dispatch, getState) => {
              firebase.database
              .ref('GopinionsTrail')
              .orderByChild('leaderID')
              .equalTo(params.leaderID)
              .on('value', function (data) {    
                if (data.val()) {
                  let odata = data.val()
                  let receivedData = {}
                  delete odata.undefined
                  if (Object.keys(odata).length > 0) {
  
                    //let sortedData = _toArrayUsers(odata)
                    let sortedData = _toArrayUsersF(odata, params.slaveID)
                    console.log('odata : ', sortedData);
    
                    sortedData.sort(function (a, b) {
        
                      if (a.firstName > b.firstName) {
                        // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                        return 1
                      }
                      if (a.firstName < b.firstName) {
                      //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                        return -1
                      }
                      return 0
                    })
        
                    receivedData = { ...sortedData }
        
                    receivedData = Object.assign({}, sortedData)
                    console.log('green putOpinions receivedData :', receivedData)
                   }
        
                  dispatch({
                    type: gGET_O_S,
                    sopinion: receivedData
                  })
                } else {
                  dispatch({
                    type: gGET_O_S,
                    sopinion: {}
                  })
                }
              })
          }
         }
        }

        export const FindSlave = params => {
          if (params.leaderID) {
            var opinionIDfinal = '';
             return (dispatch, getState) => {
              firebase.database
                .ref('GopinionsTrail')
                .orderByChild('leaderID')
                .equalTo(params.leaderID)
                .on('value', function (data) {    
                  if (data.val()) {
                    let odata = data.val()
                    let receivedData = {}
                    delete odata.undefined
                    if (Object.keys(odata).length > 0) {
                    console.log('in getOpinions 1 ', odata)

                     // let sortedData = _toArrayUsers(odata)
                      let sortedData = _toArrayUsersF(odata, params.slaveID)
                      console.log('odata : ', sortedData);
      
                      sortedData.sort(function (a, b) {
          
                        if (a.firstName > b.firstName) {
                          // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                          return 1
                        }
                        if (a.firstName < b.firstName) {
                        //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                          return -1
                        }
                        return 0
                      })
          
                      receivedData = { ...sortedData }
          
                      receivedData = Object.assign({}, sortedData)
                      if (receivedData && receivedData[0] && receivedData[0].opinionID) {
                        console.log('in getOpinions 2g ', receivedData[0].opinionID)
                        opinionIDfinal = receivedData[0].opinionID
                      }
                      else
                      {
                        opinionIDfinal = ''
                      }
                     }
          
                    dispatch({
                      type: gGET_OPINIONID,
                      opinion: opinionIDfinal
                    })
                  } else {
                    dispatch({
                      type: gGET_OPINIONID,
                      opinion: ''
                    })
                  }
                })
            }
           }
          }
