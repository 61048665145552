import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PodcastsCard from "../Cards/PodcastsCard";
import MediaPlayer from "../Dialogs/MediaPlayer";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class GroupPodcastsView extends React.Component {
  state = {
    openmediaplayer: false,
    mediatype: "podcast",
    media: null
  };

  OpenMediaPlayer = media => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media
    });
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    });
  };

  render() {
    const { openmediaplayer, media, mediatype } = this.state;
    const { classes, podcast, followingData } = this.props;

    return (
      <React.Fragment>
        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}

        <div className={classes.cardstyles}>
          {podcast ?
            <PodcastsCard
              podcast={podcast}
              key={podcast.id}
              PlayMedia={this.OpenMediaPlayer}
              following={
                followingData
                  ? followingData[podcast.userID]
                    ? true
                    : false
                  : false
              }
              {...this.props}
              hideComments={false}
            />
            : null}
        </div>
      </React.Fragment>
    );
  }
}

GroupPodcastsView.propTypes = {
  classes: PropTypes.object.isRequired,
  podcast: PropTypes.any
};

var GroupPodcastsViewStyled = withStyles(styles)(GroupPodcastsView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(GroupPodcastsViewStyled);
