import {
  USER_ANALYTICS,
  USER_FOLLOWERS,
  USER_FOLLOWING,
  USER_NOTIFICATIONS,
  USER_PLDP_NOTIFICATIONS,
  USER_GROUP_NOTIFICATIONS,
  GET_UNSEEN_GROUP_NOTIFICATIONS,
  GET_UNSEEN_NOTIFICATIONS,
  GET_UNSEEN_PLDP_NOTIFICATIONS
} from '../actions/types'

let unseencounts = {
  dailythoughts: 0,
  companythoughts: 0,
  topleaderthoughts: 0,
  ileadcorporate: 0,
  ileadpublic: 0,
  podcasts: 0,
  articles: 0,
  videos: 0,
  unseenotifications: {},
  unseenotificationscnt: 0
}

let unseenpldpcounts = {
  pldpnotifications: 0,
  unseenpldpnotifications: {}
}

let unseengroupcounts = {
  unseengroupnotifications: {},
  unseengroupnotificationscnt: 0
}

const initState = {
  useranalytics: {},
  userfollowers: {},
  userfollowing: {},
  followingButton: {},
  usernotifications: {},
  groupnotifications: {},
  userpldpnotifications: {},
  unseenotifications: unseencounts,
  unseenpldpnotifications: unseenpldpcounts,
  unseengroupnotifications: unseengroupcounts
}

const userReducer = (state = initState, action) => {
  switch (action.type) {
  case USER_ANALYTICS:
    return {
      ...state,
      useranalytics: action.useranalytics
    }
  case USER_FOLLOWERS:
    return {
      ...state,
      userfollowers: action.userfollowers
    }
  case USER_FOLLOWING:
    return {
      ...state,
      userfollowing: action.userfollowing
    }
  case 'FOLLOWING_BUTTON':
    return {
      ...state,
      followingButton: action.followingButton
    }
  case USER_NOTIFICATIONS:
    return {
      ...state,
      usernotifications: action.usernotifications
    }
  case USER_GROUP_NOTIFICATIONS:
    return {
      ...state,
      groupnotifications: action.groupnotifications
    }
  case GET_UNSEEN_GROUP_NOTIFICATIONS:
    return {
      ...state,
      unseengroupnotifications: action.unseengroupnotifications
    }
  case GET_UNSEEN_NOTIFICATIONS:
    return {
      ...state,
      unseenotifications: action.unseenotifications
    }
  case GET_UNSEEN_PLDP_NOTIFICATIONS:
    return {
      ...state,
      unseenpldpnotifications: action.unseenpldpnotifications
    }
  case USER_PLDP_NOTIFICATIONS:
    return {
      ...state,
      userpldpnotifications: action.userpldpnotifications
    }

  default:
    return state
  }
}

export default userReducer
