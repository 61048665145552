import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { TextField, IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from "@material-ui/icons/Add";
import { showToast } from "../../store/actions/toastAction";
import { updateOrCreateCompanyValue } from "../../store/actions/pldpActions";
import { updateOrCreateSubCompetency, updateOrCreateNone } from "../../store/actions/subcompetenciesActions";
import { testCompetencies } from "../../store/actions/competenciesActions";
import { COMPETENCIES_TEST } from "../../store/actions/types";
import { Button, Tooltip } from '@material-ui/core'

const styles = theme => ({
  root: {
  },
});

class AddCompetency extends Component {
  constructor (props) {
    super(props);
    this.state = {
      competencyname: "",
      fullwidth: false,
      isUpdate: false,
      valueID: "",
      cloneOn: false
    };
  }

  componentDidMount() {
    const { testCompetencies} = this.props;
    console.log('purple addcompetency props: ', this.props);
    testCompetencies({
      type: COMPETENCIES_TEST,
      companyID: this.props.userdata.companyID
    })

    if (this.props.editText && this.props.editText.trim()) {
      // console.log("updating here")
      this.setState({
        competencyname: this.props.editText,
        valueID: this.props.editID,
        isUpdate: true,
        fullwidth: true
      })
    }
  }

  saveObject = () => e => {
    e.preventDefault();

    this.saveData();
  };

  saveData = () => {
    if (
      !this.state.competencyname.trim()
    ) {
      var params = {
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Please add the competency"
      };

      this.props.showToast(params);
      return;
    }

    if (this.props.type === "competency") {
      console.log('insert competency 1 : ', this.props)

      this.props.updateOrCreateCompanyValue({
        companyID: this.props.userdata.companyID,
        valueDesc: this.state.competencyname,
        companyName: this.props.userdata.companyName,
        updateValue: this.state.isUpdate,
        valueID: this.state.valueID
      });
    //   ///TEMPORAL ACTION TO CREATE DEFAULT SUBCOMPETECY WHEN CREATING NEW COMPETENCY
    //     this.props.updateOrCreateSubCompetency({
    //       companyID: this.props.userdata.companyID,
    //       valueDesc: "General x",
    //       companyName: this.props.userdata.companyName,
    //       updateValue: this.state.isUpdate,
    //       valueID: this.state.valueID,
    //       compid: this.state.valueID,
    //     });   
    //  //////////////////////////////////////////////////////////////////////////////
    } else {
      // None field
      // this.props.updateOrCreateNone({
      //   companyID: this.props.userdata.companyID,
      //   valueDesc: 'None',
      //   companyName: this.props.userdata.companyName,
      //   updateValue: this.state.isUpdate,
      //   valueID: this.state.valueID,
      //   compid: this.props.compid,
      // });
      // sub competency
      console.log('insert competency 2 : ', this.props)
      this.props.updateOrCreateSubCompetency({
        companyID: this.props.userdata.companyID,
        valueDesc: this.state.competencyname,
        companyName: this.props.userdata.companyName,
        updateValue: this.state.isUpdate,
        valueID: this.state.valueID,
        compid: this.props.compid,
      });
    }

    this.resetFields();
  }

  randomString = (length) => {
    let text = ''
    let possible = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789'
    for (let i = 0; i < length; i++) {
      text = text + possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

////THE GRAND IMPORT////////////////////////////////////////////////////////////////////////
  saveCloneObject = () => e => {
    e.preventDefault();
    var ctype = ''
    var competencyname = ''
    var valueID = ''
    var compid = ''
    //Activity: Learnings from articles, videos, podcasts
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: Learnings from articles, videos, podcasts'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Article'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Podcast'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Video'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 
//////////////////////////////////////////////////////////////////////////////////////////////
//Activity: Learnings from training modules/courses attended
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: Learnings from training modules/courses attended'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Summarise 5-10 Take Aways'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 
    //Activity: Learnings from observing leaders/specialists in close proximity
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: Learnings from observing leaders/specialists in close proximity'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'A leader that reports to me'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'My leader'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 
    //Activity: How I am actually leading & its impact - examples
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'How I am actually leading & its impact - examples'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Movement of others'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Movement of responsible area'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Personal movement'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        }); 

  //Activity: Learnings from Books/eBooks read: My book review
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: Learnings from Books/eBooks read: My book review'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Book: Name, Author'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'eBook'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
   //Activity: Learnings from 360 degree feedback on leadership/specialisation
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: Learnings from 360 degree feedback on leadership/specialisation'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Feedback from a colleague'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Feedback from a follower'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Feedback from my leader'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Formal online assessment'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
   //Activity: Learnings from personal coaching/mentoring session - who, when, where?
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: Learnings from personal coaching/mentoring session - who, when, where?'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Add who, when, where, learnings(takeaways)'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });

   //Activity: Learnings from webinars/group discussions/presentations attended
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: Learnings from webinars/group discussions/presentations attended'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Group discussion: Learnings'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Presentation: Learnings'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Webcast: Learnings'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });

   //Activity: Learnings from Coaching/Mentoring Someone - who, when, where?
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: Learnings from Coaching/Mentoring Someone - who, when, where?'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    }); 
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Add who, when, where?'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
   //Activity: More learnings from any other develpment activities - what, who, when, where?
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Activity: More learnings from any other develpment activities - what, who, when, where?'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Add who, when, where?'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
    //Essence Competency 1: Leading Self - goals, actions, commitments
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Essence Competency 1: Leading Self - goals, actions, commitments'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Developing Positive Assertiveness'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'High Performance Leadership of Self'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'My Deepest Drivers, Values, Beliefs'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'My Purpose'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'My Vision'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Technical skills/competencies'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
   //Essence Competency 2: Leading Others - goals, actions, commitments
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Essence Competency 2: Leading Others - goals, actions, commitments'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Effective Decision Making'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Effective Delegation'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Effective Meetings'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Effective Problem Solving'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Family'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'High Performance Leadership of Others'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Leading the WHY Generation'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Mentoring Coaching & Counselling'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Others'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Team'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Transferring Technical Competence'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
   //Essence Competency 3: Leading Organisation - goals, actions, commitments
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Essence Competency 3: Leading Organisation - goals, actions, commitments'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Change Mastery'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Creating a High-Performance Culture'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'High Performance Leading of Org'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Inspiring a Purpose'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Inspiring a Vision'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Leading Customer Service Excellence'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Organisational Strategy'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Organisational Values'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });

    //Essence Competency 4: Leading Society - goals, actions, commitments
    ////ADD CATEGORY////////////////////////////////////////////////
    ctype = 'competency'
    competencyname = 'Essence Competency 4: Leading Society - goals, actions, commitments'
    valueID = this.randomString(15)
    compid = ''
    this.saveCloneData({
      ctype: ctype, 
      competencyname: competencyname, 
      valueID: valueID, 
      compid: compid
    });

        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Helping the planet achieve its Best Potential'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
        ////ADD SUB-CATEGORY////////////////////////////////////////////////
        ctype = 'subcompetency'
        competencyname = 'Influence as a global citizen'
        compid = this.randomString(15)
        this.saveCloneData({
          ctype: ctype, 
          competencyname: competencyname, 
          valueID: valueID, 
          compid: compid
        });
   };       
  //};
////////////////////////////////////////////////////////////////////////////////

  saveCloneData = params => {
    console.log('saveCloneData params : ', params)
    if (
      !params.competencyname
    ) {
      var params = {
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Please add the competency"
      };

      this.props.showToast(params);
      return;
    }

    if (params.ctype === "competency") {
      console.log('insert competency 1 : ', this.props)

      this.props.updateOrCreateCompanyValue({
        companyID: this.props.userdata.companyID,
        valueDesc: params.competencyname,
        companyName: this.props.userdata.companyName,
        updateValue: false,
        valueID: params.valueID
      });
    //   ///TEMPORAL ACTION TO CREATE DEFAULT SUBCOMPETECY WHEN CREATING NEW COMPETENCY
    //     this.props.updateOrCreateSubCompetency({
    //       companyID: this.props.userdata.companyID,
    //       valueDesc: "General x",
    //       companyName: this.props.userdata.companyName,
    //       updateValue: this.state.isUpdate,
    //       valueID: this.state.valueID,
    //       compid: this.state.valueID,
    //     });   
    //  //////////////////////////////////////////////////////////////////////////////
    } else {
      // None field
      // this.props.updateOrCreateNone({
      //   companyID: this.props.userdata.companyID,
      //   valueDesc: 'None',
      //   companyName: this.props.userdata.companyName,
      //   updateValue: this.state.isUpdate,
      //   valueID: this.state.valueID,
      //   compid: this.props.compid,
      // });
      // sub competency
     
      console.log('insert competency 2 : ', this.props)
      this.props.updateOrCreateSubCompetency({
        companyID: this.props.userdata.companyID,
        valueDesc: params.competencyname,
        companyName: this.props.userdata.companyName,
        updateValue: false,
        valueID: params.valueID,
        compid: params.compid,
      });
    }

    this.resetFields();
  }

  resetFields = () => {
    if (this.state.isUpdate) {
      this.props.onClose();
    }
    this.setState({
      competencyname: "",
      fullwidth: false,
      isUpdate: false,
      valueID: ""
    });
  }

  handleMouseDown = event => {
    event.preventDefault();
  };

  handlePressEnter = () => {
    // console.log("enter pressed")
    this.saveData()
  };

  render() {
    const { classes, userdata, title, editText } = this.props;
    console.log('addcompetencycompetenciesIS 1 : ', this.props.competenciesIS)
    return (
      <div style={flexAlignObj}>
        <div style={flexObjOne}>
          
          <TextField
            id="competencyName"
            name="competencyName"
            type="text"
            multiline
            className="expandText"
            label={title}
            aria-describedby="competencyName"
            value={this.state.competencyname}
            fullWidth={this.state.fullwidth}
            onChange={event => {
              const { value } = event.target;
              this.setState({
                competencyname: value,
                fullwidth: value.length > 0 ? true : false
              });
            }}
            onKeyPress={(ev) => {
              // console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                this.handlePressEnter()
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="add competency"
                  onClick={this.saveObject()}
                  onMouseDown={this.handleMouseDown.bind(this)}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>,
            }}
          />

           {this.props.competenciesIS && this.props.competenciesIS===true ? null : <Button
           onClick={this.saveCloneObject()}
          >Clone Categories</Button>} 

        </div>
      </div>
    )
  }
}

AddCompetency.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  editText: PropTypes.string
};

const flexAlignObj = {
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center"
};
const flexObjOne = {
  flexGrow: 12
};

const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0],
    competenciesIS: state.competenciesTest.competencies_is
  };
};

const AddCompetencyStyled = withStyles(styles)(AddCompetency);

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    updateOrCreateCompanyValue: params => dispatch(updateOrCreateCompanyValue(params)),
    updateOrCreateSubCompetency: params => dispatch(updateOrCreateSubCompetency(params)),
    updateOrCreateNone: params => dispatch(updateOrCreateNone(params)),
    testCompetencies: params => dispatch(testCompetencies(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCompetencyStyled);  
