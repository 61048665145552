/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import deflogo from '../../assets/thinkleadEcosystem.jpg'

const styles = theme => ({
  root: {
    width: 250,
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

class Companylogo extends Component {
  state = {
    companylogo: deflogo
  };

  componentWillMount() {
    let url_test_app = window.location.href
    let url_live_app = window.location.origin

    let url = checkUserDomain(url_test_app)

    let corporateLogos = {
      'https://oneconnect.thinklead.co.za':
        'https://firebasestorage.googleapis.com/v0/b/glp-corporate.appspot.com/o/photos%2Foneconnect.png?alt=media&token=884d27c0-235e-4867-8242-45efbb02a760',
      //'https://blsa.thinklead.co.za':
       // 'https://firebasestorage.googleapis.com/v0/b/glp-corporate.appspot.com/o/photos%2Fblsa-logo-online.jpg?alt=media&token=021df841-e4f6-4235-844d-775181cd559f',
      'https://edcon.thinklead.co.za':
        'https://firebasestorage.googleapis.com/v0/b/glp-corporate.appspot.com/o/photos%2Fedcon.png?alt=media&token=665ff538-a1ea-492c-b357-a69fadd9053a',
      'http://127.0.0.1:8081':
        'https://firebasestorage.googleapis.com/v0/b/glp-corporate.appspot.com/o/photos%2Foneconnectsolutions.png?alt=media&token=21401e9a-68cc-424a-a491-dd41b83808db',
      'https://glp-test-f6590.appspot.com':
        'https://firebasestorage.googleapis.com/v0/b/glp-corporate.appspot.com/o/photos%2Foneconnectsolutions.png?alt=media&token=21401e9a-68cc-424a-a491-dd41b83808db',
      'https://harmony.thinklead.co.za':
        'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/companyLogos%2Fthumbnail.png?alt=media&token=5749af48-dd93-4c2f-9759-c865954ceea9'
    }

    let src = corporateLogos[url] || deflogo

    this.setState(state => ({
      companylogo: src
    }))

    function checkUserDomain(uri) {
      let corporates = [
        'edcon.co.za',
        'edcon@',
        'edcon',
        'oneconnect.co.za',
        'oneconnect@',
        'oneconnect',
        //'blsa.org.za',
        //'blsa@',
        //'businessleadership.org.za',
        //'businessleadership@',
        //'businessleadership',
        //'blsa',
        'harmony@',
        'harmony'
      ]

      for (let x = 0; x < corporates.length; x++) {
        let y = corporates[x]
        let patt = new RegExp(y)

        if (patt.test(uri)) {
          let corporateDomains = {
            'oneconnect.co.za': 'https://oneconnect.thinklead.co.za',
            'oneconnect@': 'https://oneconnect.thinklead.co.za',
            'oneconnect': 'https://oneconnect.thinklead.co.za',
            'edcon.co.za': 'https://edcon.thinklead.co.za',
            'edcon': 'https://edcon.thinklead.co.za',
            'edcon@': 'https://edcon.thinklead.co.za',
            //'blsa.org.za': 'https://blsa.thinklead.co.za',
            //'blsa': 'https://blsa.thinklead.co.za',
            //'blsa@': 'https://blsa.thinklead.co.za',
            //'businessleadership.org.za': 'https://blsa.thinklead.co.za',
            //'businessleadership@': 'https://blsa.thinklead.co.za',
            //'businessleadership': 'https://blsa.thinklead.co.za',
            'harmony': 'https://harmony.thinklead.co.za',
            'harmony@': 'https://harmony.thinklead.co.za'
          }

          return corporateDomains[y]
        }
      }
    }
  }

  render() {
    const { companylogo } = this.state
    const { classes, companydata } = this.props
    return (
      <div {...this.props}>
        <img
          className={classes.root}
          src={
            companydata ? companydata.logo : companylogo ? companylogo : deflogo
            // companydata ?  companydata.logo : deflogo
          }
        />
      </div>
    )
  }
}

Companylogo.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    companydata: state.loggedInCompany.companydata
  }
}

const CompanylogoStyled = withStyles(styles, { withTheme: true })(Companylogo)

export default connect(
  mapStateToProps,
  null
)(CompanylogoStyled)
