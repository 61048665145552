import React, { Component } from 'react';
import {
  isMobile,
  isBrowser
} from 'react-device-detect'

// const styles = theme => ({
//   root: {
//     marginTop: 0,
//     flexGrow: 1,
//     [theme.breakpoints.down("xs")]: {
//       marginTop: "130px",
//       padding: "0 10px",
//     }
//   },
//   media: {
//     height: 0,
//     paddingTop: '56.25%', // 16:9
//     backgroundSize: "contain",
//     backgroundRepeat: "no-repeat",
//     position: "relative",
//     display: "flex",
//     width: "100%",
//     alignItems: "center",
//     borderRadius: "1px",
//     textAlign: "center"
//   },
//   mediaUpload: {
//     height: 140,
//   },
//   mediaUploadIcon: {
//     height: 70,
//   },
//   expand: {
//     transform: 'rotate(0deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//       duration: theme.transitions.duration.shortest,
//     }),
//   },
//   expandOpen: {
//     transform: 'rotate(180deg)',
//   },
//   avatar: {
//   },
// });

class Bookshop extends Component {

  componentDidMount () {
    const script = document.createElement("script");

          window.ecwid_script_defer = true;
          
          //script = document.createElement('script');
          script.charset = 'utf-8';
          script.type = 'text/javascript';
          script.src = 'https://app.ecwid.com/script.js?41002023';

          document.getElementById('my-store-41002023').appendChild(script);

          window._xnext_initialization_scripts = [
              // Storefront widget
              { 
                widgetType: 'ProductBrowser', id: 'my-store-41002023', arg: [
                  'id=my-store-41002023'
                ] 
              },
              // Horizontal categories widget
              { 
                widgetType: 'CategoriesV2', id: 'id=my-categories-41002023', arg: [
                  'id=my-categories-41002023'
                ] 
              },
              // Search widget
              { 
                widgetType: 'SearchWidget', id: 'my-search-41002023', arg: [
                  '"id=my-search-41002023"'
                ] 
              },
              // 'Buy now' button for product
              { 
                widgetType: 'SingleProduct', id: 'Product-1', arg: [
                  'id=Product-1'
                ]
              }
          ];

        // Initialize Minicart Widget. A div with class '.ec-cart-widget' must be present on a page  
          
        //Ecwid.init();

    document.body.appendChild(script);
}
  render() {
     //this.Ecwid.init();
      return (
       <React.Fragment>

       {isMobile ? (
          <div>
            <p>&nbsp;</p>
          </div>
        ) : (
          <div />
        )} 

        {/* <div>
        <script data-cfasync="false" type="text/javascript" src="https://app.ecwid.com/script.js?41002023&data_platform=code" charset="utf-8"></script>
        <script type="text/javascript"> xProductBrowser("id=41002023", "defaultCategoryId=CATEGORYID");</script>
        </div>  */}
        <div id="my-store-41002023"></div>
        <div id="my-categories-41002023"></div>
        <div id="my-search-41002023"></div>
        <div class="ec-cart-widget"></div>

         </React.Fragment>
      )
  }
}

export default Bookshop;