/* eslint-disable no-undefined */
import {
  GET_MY_PLDP_TASKS,
  GET_ALL_MY_PLDP_TASKS,
  BASE_API_URL,
  GET_COMPANY_VALUES,
  SHOW_TOAST,
  SHOW_LOADER,
  HIDE_LOADER
} from './types'
import { hideLoader, showLoader } from './loaderActions'
import { updateOrCreateSubCompetency } from './subcompetenciesActions'
import axios from 'axios'
import firebase from '../firebase'
import { _toArray } from './commonActions'
const {v4: uuidv4} = require('uuid')

const COMPANY_VALUES = BASE_API_URL + '/companyvalues'

export const fetchMyPLDPTasks = (userID, active) => {
  return (dispatch, getState) => {
    // console.log("active : ", active)
    if (active === undefined) {
      active = true
    }
    // console.log("active : ", active)
    firebase.database.ref('pldp-tasks/' + userID)
      .orderByChild('active')
      .equalTo(active)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_MY_PLDP_TASKS,
            pldpTasks: data.val()
          })
        } else {
          dispatch({
            type: GET_MY_PLDP_TASKS,
            pldpTasks: {}
          })
        }
      })
  }
}

export const fetchAllMyPLDPTasks = params => {
  return (dispatch, getState) => {
    let userID = params.userID
    firebase.database.ref('pldp-tasks/' + userID)
      .on('value', function (data) {
        if (data.val()) {
          let sortedData = _toArray(data.val())
          sortedData.sort(function (a, b) {
            if (a.active > b.active) {
              return -1
            }
            if (a.active < b.active) {
              return 1
            }
            return 0
          })
          dispatch({
            type: GET_ALL_MY_PLDP_TASKS,
            pldpAllMyTasks: sortedData
          })
        } else {
          dispatch({
            type: GET_ALL_MY_PLDP_TASKS,
            pldpAllMyTasks: []
          })
        }
      })
  }
}

export const updateNewPLDPTask = (newPldpTask, edit) => {
  return (dispatch, getState) => {
    // set tbl ref
    let dbref = 'pldp-tasks/' + newPldpTask.journalUserID
    let dbref2 = 'pldpNotifications'

    let active = true


    let stringDateRegistered


    let userType


    let myPLDPID


    let notificationID


    let reminderStatus = 'Sent'


    let stringReminderDate


    let userDescription

    if (newPldpTask.reminderOn === true) {
      if (!newPldpTask.reminderDate || !newPldpTask.reminderFrequency.trim()) {
        dispatch({
          type: SHOW_TOAST,
          variant: 'success',
          message:
            'There are some missing fields, please check reminder date or frequency before posting.'
        })
        return
      }

      stringReminderDate = new Date(newPldpTask.reminderDate).toLocaleString()

      if (newPldpTask.reminderDate > newPldpTask.dateRegistered) {
        reminderStatus = 'Unsent'
      } else {
        reminderStatus = 'Sent'
        newPldpTask.reminderFrequency = 'Does not repeat'
      }
    } else {
      reminderStatus = 'Sent'
      newPldpTask.reminderFrequency = 'Does not repeat'
      stringReminderDate = newPldpTask.stringReminderDate
    }

    stringDateRegistered = new Date(
      newPldpTask.dateRegistered
    ).toLocaleString()
    // userDescription = config.userTypesDesc[newPldpTask.user_type];
    userType = newPldpTask.user_type

    newPldpTask.active = active
    newPldpTask.journalUserID_Active = newPldpTask.journalUserID + '_' + active
    newPldpTask.reminderStatus = reminderStatus
    newPldpTask.stringDateRegistered = stringDateRegistered
    newPldpTask.stringReminderDate = stringReminderDate
    // newPldpTask.userDescription = userDescription;
    newPldpTask.userType = userType

    delete newPldpTask.user_type

    // Generate postID
    myPLDPID = firebase.database.ref(dbref).push().key
    notificationID = firebase.database.ref(dbref2).push().key

    let myPLDPID_Del = newPldpTask.myPLDPID
    let notificationID_Del = newPldpTask.notificationID

    newPldpTask.myPLDPID = myPLDPID
    newPldpTask.notificationID = notificationID

    if (newPldpTask.subcompetency === '') {
      newPldpTask.subcompetency = 'Default'
      console.log('purple updateNewPLDPTask NOTHING:', newPldpTask.subcompetency)
    } else {
      console.log('purple updateNewPLDPTask present :', newPldpTask.subcompetency)
    }

    // Write the new podcast's data
    let updates = {}
    updates[`/${dbref}/` + myPLDPID] = newPldpTask
    updates[`/${dbref2}/` + notificationID] = newPldpTask

    // console.log("newPldpTask: ", newPldpTask)
    // console.log("updates: ", updates)
    // console.log("newPldpTask: ", edit)

    firebase.database
      .ref()
      .update(updates)
      .then(() => {
        if (edit) {
          if (myPLDPID_Del) {
            firebase.database
              .ref('pldp-tasks/' + newPldpTask.journalUserID + '/' + myPLDPID_Del)
              .remove()
          }

          if (notificationID_Del) {
            firebase.database
              .ref('pldpNotifications/' + notificationID_Del)
              .remove()
          }
        }
        dispatch({
          type: SHOW_TOAST,
          variant: 'success',
          message: 'Action/Goal/Learnings added successfully!'
        })
      })
      .catch(err => {
        dispatch({
          type: 'SHOW_TOAST',
          open: true,
          variant: 'error',
          message: 'Error response: ' + err.message
        })
      })
  }
}

export const completeTask = params => {
  //new Date().getTime();

  return (dispatch, getState) => {
    let newPldpTask = params.task
    let edit = params.edit
    let active = params.active

    console.log('green plfp completeTask 3 : ', params.task.moveActionDesc);

    //  let timeNow = new Date().getTime();
    //  let timePast = (timeNow - newPldpTask.dateRegistered)/(86400000)

    //  if (timePast < 30) {
    //   dispatch({
    //     type: 'SHOW_TOAST',
    //     open: true,
    //     variant: 'error',
    //     message: 'Tasks can only be marked as complete after a minimum of 30 days'
    //   })
    //  } 
    //  else
    //  {}
    
    // set tbl ref
    let dbref = 'pldp-tasks/' + newPldpTask.journalUserID

    let stringDateRegistered


    let userType


    let myPLDPID


    let reminderStatus = active ? 'Sent' : 'Completed'


    let stringReminderDate


    reminderStatus = active ? 'Sent' : 'Completed'
    // newPldpTask.reminderFrequency = "Does not repeat";
    stringReminderDate = newPldpTask.stringReminderDate

    if (active===true) { //re-open task
      let timeNow = new Date().getTime();
      //console.log('green plfp completeTask 4 : ', new Date(timeNow).toLocaleString());
      newPldpTask.dateRegistered = timeNow;
      stringDateRegistered = new Date(timeNow).toLocaleString()
    }
     else //complete task
    {
      stringDateRegistered = new Date(
        newPldpTask.dateRegistered
      ).toLocaleString()
    }
    newPldpTask.active = active
    newPldpTask.journalUserID_Active = newPldpTask.journalUserID + '_' + active
    newPldpTask.reminderStatus = reminderStatus
    newPldpTask.stringDateRegistered = stringDateRegistered
    newPldpTask.stringReminderDate = stringReminderDate
    newPldpTask.completionDate = Date.now()
    newPldpTask.completionDateStr = new Date(
      newPldpTask.completionDate
    ).toLocaleString()

    delete newPldpTask.user_type

    myPLDPID = newPldpTask.myPLDPID
    let notificationID_Del = newPldpTask.notificationID

    // Write the new podcast's data
    let updates = {}

    updates[`/${dbref}/` + myPLDPID] = newPldpTask

    let msg = active ? 'Open' : 'Completed'

    firebase.database
      .ref()
      .update(updates)
      .then(() => {
        if (edit) {
          if (notificationID_Del) {
            firebase.database
              .ref('pldpNotifications/' + notificationID_Del)
              .remove()
          }
        }
        dispatch({
          type: 'SHOW_TOAST',
          open: true,
          variant: 'success',
          message: 'Action/Goal/Learning ' + msg + '!'
        })
      })
      .catch(err => {
        dispatch({
          type: 'SHOW_TOAST',
          open: true,
          variant: 'error',
          message: 'Error response: ' + err.message
        })
      })
   }
}

export const removePLDPFavorites = params => {
  return (dispatch, getState) => {
    // instantiate db
    let database = firebase.database

    let theType = params.itemType // Thoughts | Videos | Podcasts | Articles

    if (params.answer) {
      // let database = firebase.database();

      let ref = database
        .ref()
        .child('myPLDP')
        .child(params.itemType)
      // let pldpKey = this.user.userID+'_____'+this.entityid;
      let pldpKey = params.item.id
      ref.child(pldpKey).remove()
      dispatch({
        type: SHOW_TOAST,
        variant: 'success',
        message: `PLFP ${params.itemType} has been removed!`
      })
    } else {
      dispatch({
        type: SHOW_TOAST,
        variant: 'error',
        message: `Please respond to the confirm dialog`
      })
    }
  }
}

export const addPLDPFavorites = params => {
  // console.log("------ start of addPLDPFavorites");

  let databasePath = {
    thoughts: 'dailyThoughts',
    articles: 'news',
    podcasts: 'podcasts',
    videos: 'videos'
  }

  return (dispatch, getState) => {
    let pldpFavoritedPost = {
      ...params.post,
      ...params.user
    }

    let newPostID = firebase.database
      .ref()
      .child('/myPLDP/' + databasePath[params.postType])
      .push().key

    // console.log("------ success add key");

    dispatch(showLoader({ type: SHOW_LOADER }))
    console.log('greeny addPLDPFavorites 5: ', params)

    let ref = firebase.database.ref('/myPLDP/' + databasePath[params.postType])
    ref
      .orderByChild(params.orderChild)
      .equalTo(params.equalTo)
      .once('value')
      .then(function (snapshot) {
        // console.log("------ success add post to pldp");
        snapshot.forEach(function (childSnapshot) {
          if (newPostID === childSnapshot.key) {
            // // console.log("Got the new ID");
          } else {
            // remove each old child
            ref.child(childSnapshot.key).remove()
          }
        })
      })

    // console.log('greeny addPLDPFavorites 7: ', "/myPLDP/" + databasePath[params.postType] + "/" + newPostID);

    let updates = {}
    updates[
      '/myPLDP/' + databasePath[params.postType] + '/' + newPostID
    ] = pldpFavoritedPost

    return firebase.database.ref().update(updates, function (error) {
      if (error) {
        window.confirm('There was an error in your Personal Library submission')
      } else {
        window.confirm('LEAD has been successfully added to your Personal Library')
      }
    })
  }
}

// Through SDK
export const updateOrCreateCompanyValue = params => {
  return (dispatch, getState) => {
    let valueID = params.updateValue ? params.valueID : uuidv4()
    var timeInMs = Date.now()

    let data = {
      companyID: params.companyID,
      valueID: valueID,
      valueDesc: params.valueDesc,
      companyName: params.companyName,
      dateRegistered: timeInMs,
      status: 'active',
      companyID_status: params.companyID + '_active'
    }

    // Write the new rating data
    let updates = {}
    updates['/company-values/' + params.companyID + '/' + valueID] = data

    if (params.updateValue === false) {
      let subvalueID = params.updateValue ? params.valueID : uuidv4()
      var timeInMs = Date.now()

      let data_sub = {
        companyID: params.companyID,
        sid: subvalueID,
        compid: valueID,
        name: 'ZZZ_General',
        companyName: params.companyName,
        dateRegistered: timeInMs,
        status: 'active',
        companyID_status: params.companyID + '_active'
      }

      updates[`competencies_sub/${valueID}/` + subvalueID] = data_sub
    }


    firebase.database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: SHOW_TOAST,
          message: 'Record posted successfully!',
          variant: 'success'
        })
      })
      .catch(err => {
        dispatch({
          type: SHOW_TOAST,
          message: err.message,
          variant: 'error'
        })
      })
  }
}

// const includeAll = async (params, valueID) => {
// //const includeAll = (params, valueID) => {
//   console.log('black inside updateOrCreateSubCompetency 2');

//   await updateOrCreateSubCompetency({
//     companyID: params.companyID,
//     valueDesc: "General x",
//     companyName: params.companyName,
//     updateValue: false,
//     valueID: "",
//     compid: valueID,
//   });
// }

export const deleteCompanyValue = params => {
  return (dispatch, getState) => {
    let valueID = params.valueID

    // do validations code here??

    if (!params.isDelete) {
      dispatch({
        type: SHOW_TOAST,
        message: 'Record not removed',
        variant: 'error'
      })
      return
    }
    // Write the new rating data
    let updates = {}
    updates['/company-values/' + params.companyID + '/' + valueID] = null

    firebase.database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: SHOW_TOAST,
          message: 'Record removed successfully!',
          variant: 'success'
        })
      })
      .catch(err => {
        dispatch({
          type: SHOW_TOAST,
          message: err.message,
          variant: 'error'
        })
      })
  }
}

export const getSelectedCompanyValue = rowData => {
  return (dispatch, getState) =>
    dispatch({
      type: 'GET_SELECTED_ROW',
      selectedCompanyValue: rowData
    })
}

export const getCompanyValues = companyID => {
  return (dispatch, getState) => {
    firebase.database.ref('company-values/' + companyID).on('value', function (data) {
      if (data.val()) {
        let filteredCompanyValues = Object.values(data.val()).filter(v => {
          return v.companyID ? v.companyID : null
        })
        let sortedData = _toArray(filteredCompanyValues)
        sortedData.sort(function (a, b) {
          if (a.dateRegistered > b.dateRegistered) {
            return 1
          }
          if (a.dateRegistered < b.dateRegistered) {
            return -1
          }
          return 0
        })
        dispatch({
          type: GET_COMPANY_VALUES,
          companyValues: sortedData
        })
      } else {
        dispatch({
          type: GET_COMPANY_VALUES,
          pldpTasks: []
        })
      }
    })
  }
}

export const fetchCompanyValues = companyID => {
  return (dispatch, getState) => {
    axios
      .get(`${COMPANY_VALUES}/${companyID}`)
      .then(res => {
        let filteredCompanyValues = Object.values(res.data.data).filter(v => {
          return v.companyID ? v.companyID : null
        })
        let sortedData = _toArray(filteredCompanyValues)
        sortedData.sort(function (a, b) {
          if (a.dateRegistered > b.dateRegistered) {
            return 1
          }
          if (a.dateRegistered < b.dateRegistered) {
            return -1
          }
          return 0
        })
        dispatch({
          type: GET_COMPANY_VALUES,
          companyValues: sortedData
        })
      })
      .catch(err => {
        dispatch({
          type: GET_COMPANY_VALUES,
          companyValues: []
        })
        if (err.response) {
          dispatch({
            type: GET_COMPANY_VALUES,
            companyValues: []
          })
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error response: ' + err.response.data.message
          })
        } else if (err.request) {
          dispatch({
            type: GET_COMPANY_VALUES,
            companyValues: []
          })
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error request: ' + err.request
          })
        } else {
          dispatch({
            type: GET_COMPANY_VALUES,
            companyValues: []
          })
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error message: ' + err.message
          })
        }
      })
  }
}

export const removePLDPTask = (pldpTask, userID) => {
  return (dispatch, getState) => {
    // return;
    const body = {
      uid: pldpTask.uid,
      id: userID,
      myPLDPID: pldpTask.myPLDPID,
      notificationID: pldpTask.notificationID,
      name: 'pldptasks'
    }
    axios
      .delete(`${BASE_API_URL}/pldptasks/${body.id}`, { data: { ...body } })
      .then(res => {
        dispatch({
          type: 'SHOW_TOAST',
          open: true,
          variant: 'success',
          message: 'Task deleted successfully...'
        })
      })
      .catch(err => {
        if (err.response) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error response: ' + err.response.data.message
          })
        } else if (err.request) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error request: ' + err.request
          })
        } else {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error message: ' + err.message
          })
        }
      })
  }
}
