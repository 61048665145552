import { SHOW_TOAST, GET_O, GET_O_S, GET_SL, GET_SLAVE_OPINION, GET_OPINIONID, FB_URL } from "./../actions/types";
import firebase from "../firebase";
import { _toArrayUsers, _toArrayUsersF, opinionsDatefix } from './commonActions'
import axios from 'axios';

// function makeid(length) {
//     var result           = '';
//     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     var charactersLength = characters.length;
//       for ( var i = 0; i < length; i++ ) {
//         result += characters.charAt(Math.floor(Math.random() * 
//   charactersLength));
//     }
//     return result;
//   }

export const getOpinionsTrail = params => {
    return (dispatch, getState) => {
      firebase.database
        .ref('opinionsTrail')
        .orderByChild('leaderID')
        .equalTo(params.leaderID)
        .on('value', function (data) {
          if (data.val()) {
            let feedback = data.val()
            let receivedData = {}
            delete feedback.undefined
            // if (feedback.slaveID !== params.slaveID) {
            //   delete feedback.slaveID
            // }
            if (Object.keys(feedback).length > 0) {
              let sortedData = _toArrayUsersF(feedback, params.slaveID)
              sortedData.sort(function (a, b) {
              })
  
              receivedData = { ...sortedData }
  
              receivedData = Object.assign({}, sortedData)
              //console.log('green populateState 0 : ', receivedData)
              console.log('green feedback 0 : ', receivedData)
             }
  
            dispatch({
              type: GET_SLAVE_OPINION,
              slaveopinion: receivedData
            })
          } else {
            dispatch({
              type: GET_SLAVE_OPINION,
              slaveopinion: {}
            })
          }
        })
    }
  }

  
  export const addSOpinion = params => {
    let database = firebase.database;
    const updates = {};
   // const opinionID = makeid(10);
    var addOpinion = true;
   //////////////////////////////////////////////////////
   console.log('green submitPost addSOpinion x : ', params)
    return (dispatch, getState) => {
        updates['/opinionsTrail/' + params.opinionID ] = { 
            opinionID: params.opinionID,
            leaderID: params.leaderID,
            slaveID: params.slaveID,
            companyName: params.companyName,
            companyID: params.companyID,
            firstName: params.firstName,
            lastName: params.lastName,
            slave: params.slave,
            simpleopinion: params.simpleopinion,
            simple: params.simple,
            sdate: params.sdate,
            type: params.type
           };

        // updates['/opinionsTrail/' + params.opinionID + '/simpleopinion'] = params.simpleopinion;
        // updates['/opinionsTrail/' + params.opinionID + '/simple'] = params.simple;
           database
            .ref()
            .update(updates)
            .then(() => {
               dispatch({
                type: SHOW_TOAST,
                variant: "success",
                message: "Thank you for your feedback.",
                pos: "bottom"
              });
            })
            .catch(err => {
              dispatch({
                type: "SHOW_TOAST",
                open: true,
                variant: "error",
                message: "Error response: " + err.message
              });
              })    
           }
      }

  //WHATSTEPP SEND////////////////////////////////////////////////////////////////
  const sendWhatsAppMessage = async(phoneNumber, message) => {
    try {
      console.log('Sending WhatsApp message:', message);
      const res = await axios.post(`${FB_URL}/m109-whatsappsend`, {
        params: {
         to: phoneNumber, // recipient's phone number in international format
         message: message, // message content
        }
      });

      console.log('Response from backend:', res.data);
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
    }
  }

  async function getUserCellphone(userID) {
    let db = firebase.database;
    try {
      // Reference to the user table and specific user
      const userRef = db.ref(`users/${userID}/cellphone`);
      
      // Fetch cellphone field
      const snapshot = await userRef.once('value');
      
      if (snapshot.exists()) {
        const cellphone = snapshot.val();
        console.log(`Cellphone for user ${userID}: ${cellphone}`);
        return cellphone;
      } else {
        console.log(`No data available for userID: ${userID}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching cellphone for user ${userID}:`, error);
      throw error;
    }
  }

      export const addOpinions_ledain = params => {
        let database = firebase.database;
        const updates = {};
       // const opinionID = makeid(10);
        var addOpinion = true;
       ///////////////////////////////////////
       console.log('green submitPost addOpinions_ledain : ', params)
       console.log('green-putOpinions-self 2 :', params)

        return (dispatch, getState) => {
            updates['/opinionsTrail/' + params.opinionID ] = { 
                opinionID: params.opinionID,
                leaderID: params.leaderID,
                slaveID: params.slaveID,
                companyName: params.companyName,
                companyID: params.companyID,
                firstName: params.firstName,
                lastName: params.lastName,
                slave: params.slave,
                valueA: params.valueA,
                valueAopinion: params.valueAopinion,
                valueB: params.valueB,
                valueBopinion: params.valueBopinion,
                valueC: params.valueC,
                valueCopinion: params.valueCopinion,
                valueD: params.valueD,
                valueDopinion: params.valueDopinion,
                valueE: params.valueE,
                valueEopinion: params.valueEopinion,
                valueF: params.valueF,
                valueFopinion: params.valueFopinion,
                valueG: params.valueG,
                valueGopinion: params.valueGopinion,
                valueH: params.valueH,
                valueHopinion: params.valueHopinion,
                type: params.type,

                simple: params.simple ? params.simple : 0,
                simpleopinion: params.simpleopinion ? params.simpleopinion : '',
                sdate: params.sdate
               };
               database
                .ref()
                .update(updates)
                .then(() => {
                   dispatch({
                    type: SHOW_TOAST,
                    variant: "success",
                    message: "Thank you for your feedback.",
                    pos: "bottom"
                  });
                  //SEND WHATSAPP NOTIFICATION
                  const tellphone = getUserCellphone(params.leaderID);
                  console.log('cellphone number: ', tellphone);
                  const message = 'Notice: You have just been evaluated.';
                  if (tellphone) {
                     sendWhatsAppMessage(tellphone, message);
                  }
                 
                })
                .catch(err => {
                  dispatch({
                    type: "SHOW_TOAST",
                    open: true,
                    variant: "error",
                    message: "Error response: " + err.message
                  });
                  })    
               }
          }

    export const addOpinions = params => {
       let database = firebase.database;
            const updates = {};
           // const opinionID = makeid(10);
            var addOpinion = true;
           ///////////////////////////////////////
           console.log('green submitPost addOpinions : ', params)
            return (dispatch, getState) => {
                updates['/opinionsTrail/' + params.opinionID ] = { 
                    opinionID: params.opinionID,
                    leaderID: params.leaderID,
                    slaveID: params.slaveID,
                    companyName: params.companyName,
                    companyID: params.companyID,
                    firstName: params.firstName,
                    lastName: params.lastName,
                    slave: params.slave,
                    valueA: params.valueA,
                    valueAopinion: params.valueAopinion,
                    valueB: params.valueB,
                    valueBopinion: params.valueBopinion,
                    valueC: params.valueC,
                    valueCopinion: params.valueCopinion,
                    valueD: params.valueD,
                    valueDopinion: params.valueDopinion,
                    valueE: params.valueE,
                    valueEopinion: params.valueEopinion,
                    valueF: params.valueF,
                    valueFopinion: params.valueFopinion,
                    valueG: params.valueG,
                    valueGopinion: params.valueGopinion,
                    valueH: params.valueH,
                    valueHopinion: params.valueHopinion,
                    type: params.type
                    // simple: params.simple ? params.simple : 0,
                    // simpleopinion: params.simpleopinion ? params.simpleopinion : ''
                   };
                   database
                    .ref()
                    .update(updates)
                    .then(() => {
                       dispatch({
                        type: SHOW_TOAST,
                        variant: "success",
                        message: "Thank you for your feedback.",
                        pos: "bottom"
                      });
                    })
                    .catch(err => {
                      dispatch({
                        type: "SHOW_TOAST",
                        open: true,
                        variant: "error",
                        message: "Error response: " + err.message
                      });
                      })    
                   }
              }

// export const addOpinions_31Aug = params => {
//     let database = firebase.database;
//     const updates = {};
//    // const opinionID = makeid(10);
//     var addOpinion = true;
//    ///////////////////////////////////////
//    console.log('green submitPost opinionID x : ', params)
//     return (dispatch, getState) => {
//         updates['/opinionsTrail/' + params.opinionID ] = { 
//             opinionID: params.opinionID,
//             leaderID: params.leaderID,
//             slaveID: params.slaveID,
//             companyName: params.companyName,
//             companyID: params.companyID,
//             firstName: params.firstName,
//             lastName: params.lastName,
//             slave: params.slave,
//             self: params.self,
//             selfopinion: params.selfopinion,
//             others: params.others,
//             othersopinion: params.othersopinion,
//             organisation: params.organisation,
//             orgopinion: params.orgopinion,
//             society: params.society,
//             sosopinion: params.sosopinion,
//             simple: params.simple ? params.simple : 0,
//             simpleopinion: params.simpleopinion ? params.simpleopinion : ''
//            };
//            database
//             .ref()
//             .update(updates)
//             .then(() => {
//                dispatch({
//                 type: SHOW_TOAST,
//                 variant: "success",
//                 message: "Thank you for your feedback.",
//                 pos: "bottom"
//               });
//             })
//             .catch(err => {
//               dispatch({
//                 type: "SHOW_TOAST",
//                 open: true,
//                 variant: "error",
//                 message: "Error response: " + err.message
//               });
//               })    
//            }
//       }
 
      const getSlaveID = (props) => {
        for (let x in props) {
          return props[x].slaveID
        }
       }

       export const xfindSlave = (leaderID, slaveID) => {
         var pass = true;
         var database = firebase.database;
                 database
                  .ref('opinionsTrail')
                  .orderByChild('leaderID')
                  .equalTo(leaderID)
                  .on('value', function (data) {  
                    if (data.val()) {
                      let odata = data.val() 
                      if (Object.keys(odata).length > 0) {
                       let sortedData = _toArrayUsers(odata)
                       Object.keys(sortedData).map((id, index) => {
 
                     if (sortedData[id].leaderID===leaderID) {
                       if (sortedData[id].slaveID===slaveID) { //user already made opinion on this leader
                         console.log('blue slave data ', slaveID)
                        pass = false;
                        return pass;
                       }
                     }
                   }) }} })}

    export const deleteSlave = params => {
       // console.log('blue slave data 2 ', params)
        return (dispatch, getState) => {
        var database = firebase.database;
                database
                 .ref('opinionsTrail')
                 .orderByChild('leaderID')
                 .equalTo(params.leaderID)
                 .on('value', function (data) {  
                   if (data.val()) {
                     let odata = data.val() 
                     if (Object.keys(odata).length > 0) {
                      let sortedData = _toArrayUsers(odata)
                      Object.keys(sortedData).map((id, index) => {

                   // console.log('blue slave data params.opinionID : ', params.opinionID)
                   // console.log('blue slave data sortedData[id].opinionID : ', sortedData[id].opinionID)

                   // if (sortedData[id].opinionID === params.opinionID) {} else {
                      if (sortedData[id].slaveID===params.slaveID) { //user already made opinion
                        console.log('blue slave data 5 : ', sortedData[id].opinionID + ' removed')
                        database.ref(`opinionsTrail/${sortedData[id].opinionID}`).remove()
                      }
                    //}
                  }) }} })}}

 export const getOpinions = params => {
  console.log('In findOpinionsD 2 : ', params)
      if (params.leaderID) {
        return (dispatch, getState) => {
          firebase.database
            .ref('opinionsTrail')
            .orderByChild('leaderID')
            .equalTo(params.leaderID)
            .on('value', function (data) {    
              console.log('In findOpinionsD 3 : ', data)
              if (data.val()) {
                let odata = data.val()
                let receivedData = {}
                delete odata.undefined
                if (Object.keys(odata).length > 0) {

                  console.log('In findOpinionsD 4 : ', odata)

                  let sortedData = opinionsDatefix(odata)
                  //let sortedData = _toArrayUsers(odata)
                  //let sortedData = _toArrayUsersF(odata, params.slaveID)
                  console.log('odata : ', sortedData);
  
                  sortedData.sort(function (a, b) {
      
                    if (a.sdate > b.sdate) {
                      // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                      return -1
                    }
                    if (a.sdate < b.sdate) {
                    //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                      return 1
                    }
                    return 0
                  })
      
                  receivedData = { ...sortedData }
      
                  receivedData = Object.assign({}, sortedData)
                  console.log('In findOpinionsD 5 : ', receivedData)
                 }
      
               dispatch({
                  type: GET_O,
                  opinion: receivedData 
                })
              } else {
                dispatch({
                  type: GET_O,
                  opinion: {}
                })
              }
            })
        }
       }
      }

      export const getOpinions_pSlave = params => {
        console.log('In findOpinionsDD 2 : ', params)
        console.log('checkOpinionsSubmit 4', params.slaveID)
            if (params.slaveID) {
              return (dispatch, getState) => {
                firebase.database
                  .ref('opinionsTrail')
                  .orderByChild('slaveID')
                  .equalTo(params.slaveID)
                  .on('value', function (data) {    
                    console.log('In findOpinionsDD 3 : ', data)
                    if (data.val()) {
                      let odata = data.val()
                      let receivedData = {}
                      delete odata.undefined
                      if (Object.keys(odata).length > 0) {
      
                        console.log('In findOpinionsDD 4 : ', odata)
      
                        let sortedData = opinionsDatefix(odata)
                        //let sortedData = _toArrayUsers(odata)
                        //let sortedData = _toArrayUsersF(odata, params.slaveID)
                        console.log('odata : ', sortedData);

                        console.log('In findOpinionsDD 5 : ', data)

                        sortedData.sort(function (a, b) {
            
                          if (a.sdate > b.sdate) {
                            // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                            return -1
                          }
                          if (a.sdate < b.sdate) {
                          //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                            return 1
                          }
                          return 0
                        })
            
                        receivedData = { ...sortedData }
            
                        receivedData = Object.assign({}, sortedData)
                        console.log('In findOpinionsDD 6 : ', receivedData)
                       }
                       console.log('checkOpinionsSubmit 5')

                     dispatch({
                        type: GET_SL,
                        opinion_sl: receivedData 
                      })
                    } else {
                      dispatch({
                        type: GET_SL,
                        opinion_sl: {}
                      })
                    }
                  })
              }
             }
            }

      export const getOpinionsOnSubmit = params => {
        if (params.leaderID) {
          return (dispatch, getState) => {
              firebase.database
              .ref('opinionsTrail')
              .orderByChild('leaderID')
              .equalTo(params.leaderID)
              .on('value', function (data) {    
                if (data.val()) {
                  let odata = data.val()
                  let receivedData = {}
                  delete odata.undefined
                  if (Object.keys(odata).length > 0) {
  
                    //let sortedData = _toArrayUsers(odata)
                    let sortedData = _toArrayUsersF(odata, params.slaveID)
                    console.log('odata : ', sortedData);
    
                    sortedData.sort(function (a, b) {
        
                      if (a.firstName > b.firstName) {
                        // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                        return 1
                      }
                      if (a.firstName < b.firstName) {
                      //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                        return -1
                      }
                      return 0
                    })
        
                    receivedData = { ...sortedData }
        
                    receivedData = Object.assign({}, sortedData)
                    console.log('green putOpinions receivedData :', receivedData)
                   }
        
                  dispatch({
                    type: GET_O_S,
                    sopinion: receivedData
                  })
                } else {
                  dispatch({
                    type: GET_O_S,
                    sopinion: {}
                  })
                }
              })
          }
         }
        }

        export const FindSlave = params => {
          if (params.leaderID) {
            var opinionIDfinal = '';
             return (dispatch, getState) => {
              firebase.database
                .ref('opinionsTrail')
                .orderByChild('leaderID')
                .equalTo(params.leaderID)
                .on('value', function (data) {    
                  if (data.val()) {
                    let odata = data.val()
                    let receivedData = {}
                    delete odata.undefined
                    if (Object.keys(odata).length > 0) {
                    console.log('in getOpinions 1 ', odata)

                     // let sortedData = _toArrayUsers(odata)
                      let sortedData = _toArrayUsersF(odata, params.slaveID)
                      console.log('odata : ', sortedData);
      
                      sortedData.sort(function (a, b) {
          
                        if (a.firstName > b.firstName) {
                          // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                          return 1
                        }
                        if (a.firstName < b.firstName) {
                        //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                          return -1
                        }
                        return 0
                      })
          
                      receivedData = { ...sortedData }
          
                      receivedData = Object.assign({}, sortedData)
                      if (receivedData && receivedData[0] && receivedData[0].opinionID) {
                        console.log('in getOpinions 2b ', receivedData[0].opinionID)
                        opinionIDfinal = receivedData[0].opinionID
                      }
                      else
                      {
                        opinionIDfinal = ''
                      }
                     }
          
                    dispatch({
                      type: GET_OPINIONID,
                      opinion: opinionIDfinal
                    })
                  } else {
                    dispatch({
                      type: GET_OPINIONID,
                      opinion: ''
                    })
                  }
                })
            }
           }
          }

  //  export const FindSlave = params => {
  //       return (dispatch, getState) => {
  //       var pass = ''; //no post yet
  //       var database = firebase.database;
  //       database
  //         .ref('opinionsTrail')
  //         .orderByChild('leaderID')
  //         .equalTo(params.leaderID)
  //         .on('value', function (data) {
    
  //           if (data.val()) {
  //             let odata = data.val()
  //             if (Object.keys(odata).length > 0) {
  //               let sortedData = _toArrayUsers(odata)
  //               Object.keys(sortedData).map((id, index) => {
    
  //                 if (sortedData[id].leaderID === params.leaderID) {
  //                   if (sortedData[id].slaveID === params.slaveID) { //user already made opinion on this leader
  //                    pass = sortedData[id].opinionID; //has posted before
  //                     console.log('green submitPost leaderID : ', params.leaderID)
  //                     console.log('green submitPost slaveID : ', params.slaveID)
  //                     console.log('green submitPost opinionID 2 : ', sortedData[id].opinionID)
  //                     if (pass) {
  //                     dispatch({
  //                       type: GET_OPINIONID,
  //                       opinion: pass
  //                     })
  //                   } else {
  //                     dispatch({
  //                       type: GET_OPINIONID,
  //                       opinion: {}
  //                     })
  //                   }
  //                   }
  //                 }
  //               })
  //             }
  //           }
  //         })
  //       }
  //     }
