import { COLLECT_TESTS } from "./types";
import firebase from "../firebase";
import ImagePanoramaFishEye from "material-ui/svg-icons/image/panorama-fish-eye";
const {v4: uuidv4} = require('uuid')

function fillup(strIn) {
  var str = strIn;
  return str.split(' ').join('_');
}
export const appendTest = params => {
  console.log('collecTestResulsts params: ', params);
  if (params.type === COLLECT_TESTS) {
    var isCompanyID;
    if (params.real_companyID) {
      isCompanyID = params.real_companyID;
    }
     else
    {
      isCompanyID = params.companyID;
    } 
    return (dispatch, getState) => {
        var timeInMs = Date.now();
        var dbaseAppend = firebase.database;

        var updates = {};      
        // updates['surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/' + 'surveyname'] = params.surveyTitle;       
        // updates['surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/' + 'personid'] = params.personid;  
        // updates['surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/' + 'personname'] = params.personName+' '+params.personLname;    
        // updates['surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/' + 'testscore'] = params.counterCorrectAnswers;       
        // updates['surveysTests/' + params.companyID + '/' + params.groupID + '/'+ params.surveyid + '/' + params.personid + '/' + 'qlen'] = params.qlen;       
        updates['surveysTests/' + isCompanyID + '/' + '/'+ params.surveyid + '/' + params.personid + '/' + 'surveyname'] = params.surveyTitle;       
        updates['surveysTests/' + isCompanyID + '/' + '/'+ params.surveyid + '/' + params.personid + '/' + 'personid'] = params.personid;  
        updates['surveysTests/' + isCompanyID + '/' + '/'+ params.surveyid + '/' + params.personid + '/' + 'personname'] = params.personName+' '+params.personLname;    
        updates['surveysTests/' + isCompanyID + '/' + '/'+ params.surveyid + '/' + params.personid + '/' + 'testscore'] = params.counterCorrectAnswers;       
        updates['surveysTests/' + isCompanyID + '/' + '/'+ params.surveyid + '/' + params.personid + '/' + 'qlen'] = params.qlen;       

        dbaseAppend
        .ref().update(updates)

          .then(() => {
            dispatch({
              type: COLLECT_TESTS,
              testresult: params.surveyid
            });
          })
          .catch(err => {
            dispatch({
              type: COLLECT_TESTS,
              message: err.message
            });
          });

      //})

      };
     }
    };
    