import { SIMPLE_VAL } from "../actions/types";

const initState = {
  type: SIMPLE_VAL,
  sVal: false
};

const simpleValReducer = (state = initState, action) => {
  console.log('green simpleaction 1 : ', action.s_action);
  switch (action.type) {
    case SIMPLE_VAL:
      return {
        type: SIMPLE_VAL,
        sVal: action.s_action
      };

    default:
      return state;
  }
};

export default simpleValReducer;
