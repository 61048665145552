/* eslint-disable no-var */
import defUser from '../assets/default-user.png'
import defBackground from '../assets/gold.jpg'
import mic from '../assets/mic.png'
import play from '../assets/play.png'
import Podcast from '../assets/Podcast.png'
import Voicemail from '../assets/Voicemail.png'
import ebooks from '../assets/ebooks.jpg'

function PHOTO_BACKGROUND(photos) {
  let photo_url = typeof photos === 'string'
  if (photo_url) {
    return photo_url || defBackground
  }
  for (let item in photos) {
    if (photos[item].thumbNailUrl) {
      photo_url = photos[item].thumbNailUrl || defBackground
    } else {
      photo_url = photos[item].url || defBackground
    }
  }
  return photo_url || defBackground
}

function PHOTO_PROFILE(photo) {
  if (photo === '../images/default-user.png') {
    return defUser
  }
  return photo || defUser
}

function PODCAST_IMG(photo) {
  return photo === 'Podcast' ? Podcast : Voicemail
}

function RESIZE_IMAGE(file, imgType) {
  let reader = new FileReader()
  reader.onloadend = function () {
    let tempImg = new Image()
    tempImg.src = reader.result
    tempImg.onload = function () {
      if (imgType == 'thumbnail') {
        var MAX_WIDTH = 400
        var MAX_HEIGHT = 300
      } else {
        var MAX_WIDTH = 800
        var MAX_HEIGHT = 600
      }

      let tempW = tempImg.width
      let tempH = tempImg.height
      if (tempW > tempH) {
        if (tempW > MAX_WIDTH) {
          tempH = tempH * (MAX_WIDTH / tempW)
          tempW = MAX_WIDTH
        }
      } else if (tempH > MAX_HEIGHT) {
        tempW = tempW * (MAX_HEIGHT / tempH)
        tempH = MAX_HEIGHT
      }

      let canvas = document.createElement('canvas')
      canvas.width = tempW
      canvas.height = tempH
      let ctx = canvas.getContext('2d')
      ctx.drawImage(this, 0, 0, tempW, tempH)

      // return data url
      let dataURL = canvas.toDataURL('image/jpeg')

      if (imgType == 'thumbnail') {
        sessionStorage.setItem('userthumbnail', dataURL)
      } else {
        sessionStorage.setItem('userphoto', dataURL)
      }
    }
  }
  reader.readAsDataURL(file)
}

export {
  PHOTO_BACKGROUND,
  PHOTO_PROFILE,
  mic as MIC,
  play as PLAY,
  PODCAST_IMG,
  ebooks as EBOOKS,
  RESIZE_IMAGE,
  defUser as DEFUSER
}
