import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GroupIcon from "@material-ui/icons/GroupWork";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MyMessages from "./MyMessages";
import GroupsList from "../components/groups/GroupsList";
import Hidden from "@material-ui/core/Hidden";
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import defBackground from "../assets/images/def_group_img.jpg";
import { LIGHTBLUE, LIGHTGREY, DARKSIDEBAR } from "../utils/color_utils";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  paper: {
    padding: theme.spacing(),
    textAlign: "left",
    color: theme.palette.text.secondary,
    minHeight: '-webkit-fill-available',
    minHeight: '-moz-fill-available',
    minHeight: 'fill-available'
  },
  paperMob: {
    padding: theme.spacing(),
    textAlign: "left",
    color: theme.palette.text.secondary,
    width: '-webkit-fill-available',
    width: '-moz-fill-available',
    width: 'fill-available'
  },
  papertop: {
    padding: theme.spacing(),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: "-5px!important",
    marginLeft: "-10px!important",
    marginRight: "-10px!important",
    height: "300px!important",
    [theme.breakpoints.down("xs")]: {
      marginTop: "32px!important"
    },
    backgroundColor: `${LIGHTGREY}`,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundImage: `url(${defBackground})`
  },
  papertopinfo: {
    padding: theme.spacing(),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: "-5px!important",
    marginLeft: "-10px!important",
    marginRight: "-10px!important",
    marginBottom: "10px!important",
    height: "60px!important",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-1px!important"
    },
    backgroundColor: "#fff!important"
  },
  papertoplabel: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: `${DARKSIDEBAR}`,
    marginTop: "-5px!important",
    marginLeft: "-10px!important",
    marginRight: "-10px!important",
    marginBottom: "0px!important",
    height: "60px!important",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px!important"
    },
    fontSize: "20px",
    fontWeight: "bold",
    backgroundColor: `${LIGHTGREY}`
  },
  groupslabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: `${LIGHTBLUE}`,
    textTransform: "uppercase",
    fontWeight: "bold",
    borderBottom: `1px solid ${LIGHTGREY}`,
    padding: theme.spacing()
  },
  topmask: {
    position: "absolute",
    top: "0px",
    right: "0px",
    left: "0px",
    bottom: "0px",
    zIndex: "1",
    transition: "opacity 0.8s, visibility 0.8s",
    backgroundColor: "#000",
    opacity: "0.37",
    "&:hover": {
      opacity: "0",
      transition: "opacity 0.8s, visibility 0.8s"
    },
    maxHeight: "280px!important",
    marginRight: "-10px!important",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "342px!important",
      marginTop: "0px"
    }
  },
  stickybar: {
    position: "-webkit-sticky",
    position: "sticky",
    top: "-150px",
    zIndex: 999,
    [theme.breakpoints.down("xs")]: {
      top: "-146px"
    }
  }
}));

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const UserPostStatItem = props => {
  const { count, caption, classes } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Typography variant="body2">
        <strong>{count}</strong>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <strong>{caption}</strong>
      </Typography>
    </div>
  );
};

export default function MyGroups() {
  const [state, setState] = useState({ currGroupCaption: "", currGroupId: "" });

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleListItemClick(event, index) {
    setState({ currGroupId: event.group_id, currGroupCaption: event.group_name });
  }

  function handleListItemLoad(event, index) {
    setState({ currGroupId: event.group_id, currGroupCaption: event.group_name });
  }

  function handleGroupIdUpdate(event, index) {
    setState({ currGroupId: "", currGroupCaption: state.currGroupCaption });
  }

  const classes = useStyles();
  return (
    <div>
      <div className={classes.stickybar}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className={classes.papertop}>
              {/* <div className={classes.topmask} /> */}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.papertoplabel}>
              {state.currGroupCaption}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Hidden mdUp implementation="css">
              <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Click to View Groups</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paper elevation={0} className={classes.paperMob}>
                    <GroupsList
                      selectedGroup={event => handleListItemClick(event, 1)}
                      loadedListGroup={event => handleListItemLoad(event, 1)}
                    />
                  </Paper>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Hidden>
          </Grid>
          {/* <Grid item xs={12}>
            <div className={classes.papertopinfo}>
              <Grid
                container
                spacing={16}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid xs={3} item>
                  <UserPostStatItem
                    textColor="white"
                    count={0}
                    caption="Members"
                  />
                </Grid>
                <Grid xs={3} item>
                  <UserPostStatItem
                    textColor="white"
                    count={0}
                    caption="Posts"
                  />
                </Grid>
                <Grid xs={3} item>
                  <UserPostStatItem
                    textColor="white"
                    count={0}
                    caption="Comments"
                  />
                </Grid>
                <Grid xs={3} item>
                  <UserPostStatItem
                    textColor="white"
                    count={0}
                    caption="Likes"
                  />
                </Grid>
              </Grid>
            </div>
          </Grid> */}
        </Grid>
      </div>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Paper elevation={0} className={classes.paper}>
              <MyMessages group_id={state.currGroupId} group_name={state.currGroupCaption} updateGroupId={event => handleGroupIdUpdate(event, 1, state.currGroupCaption)} />
            </Paper>
          </Grid>
          <Grid item md={4} xs={12}>
            <Hidden smDown implementation="css">
              <Paper elevation={0} className={classes.paper}>
                <GroupsList
                  selectedGroup={event => handleListItemClick(event, 1)}
                  loadedListGroup={event => handleListItemLoad(event, 1)}
                />
              </Paper>
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
