import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  GLP_LOGGED_COMPANY,
  SHOW_TOAST,
  GLP_LOGGED_PROFILE
} from './../../store/actions/types'
import SendIcon from '@material-ui/icons/Send'
import MessageIcon from '@material-ui/icons/Message'
import { LIGHTBLUE } from '../../utils/color_utils'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import { showToast } from './../../store/actions/toastAction'
import { sendFeedback } from './../../store/actions/feedbackActions'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  TextField,
  Slide,
  AppBar,
  IconButton,
  Toolbar
} from '@material-ui/core'

const styles = theme => ({
  appBar: {
    position: 'static',
    marginBottom: '20px',
    backgroundColor: 'transparent',
    color: `${LIGHTBLUE}`
  },
  dialogTitleText: {
    color: LIGHTBLUE
  },
  flex: {
    flex: 1,
    color: '#000'
  },
  submitButton: {
    backgroundColor: LIGHTBLUE,
    color: '#ffffff'
  },
  group: {
    flexDirection: 'row'
  },
  fieldError: {
    color: '#FF0000'
  }
})

function Transition(props) {
  return <Slide direction='left' {...props} />
}

const Feedback = ({
  classes,
  handleChange,
  handleChangeSubject,
  value,
  subject,
  message,
  feedbackError,
  subjectError,
  messageError
}) => (
  <div>
    <FormGroup row>
      <FormControl
        component='fieldset'
        className={classes.formControl}
        fullWidth
      >
        {/* <FormLabel component="legend">Contact Support</FormLabel> */}
        <div className={classes.fieldError}>
          {feedbackError}
        </div>
        <RadioGroup
          aria-label='feedback'
          name='feedback'
          className={classes.group}
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value='Feedback'
            control={<Radio />}
            label='Feedback'
          />
          <FormControlLabel
            value='Defect'
            control={<Radio />}
            label='Contact Support'
          />
        </RadioGroup>
      </FormControl>
    </FormGroup>
    <FormGroup row>
      <span className={classes.fieldError}>
        {subjectError}
      </span>
      <TextField
        id='standard-name'
        label='Subject'
        className={classes.textField}
        value={subject}
        name='subject'
        onChange={handleChangeSubject('subject')}
        margin='normal'
        fullWidth
      />
    </FormGroup>
    <FormGroup row>
      <span className={classes.fieldError}>
        {messageError}
      </span>
      <TextField
        id='standard-name'
        label='Message'
        className={classes.textField}
        value={message}
        name='message'
        onChange={handleChangeSubject('message')}
        margin='normal'
        rows='5'
        rowsMax='10'
        multiline
        fullWidth
      />
    </FormGroup>
  </div>
)

class AddFeedback extends React.Component {
  state = {
    open: false,
    scroll: 'paper',
    value: 'feedback',
    subject: '',
    message: '',
    subjectError: '',
    messageError: '',
    feedbackError: '',
    category: ''
  };

  componentDidMount() {}
  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll })
  };

  handleClose = () => {
    this.setState({
      value: 'feedback',
      subject: '',
      message: '',
      subjectError: '',
      messageError: '',
      feedbackError: ''
    })

    this.props.onOpenFeedback()
  };

  submitFeedBackForm = () => {
    const feedbackForm = {
      category: this.state.value,
      companyID: this.props.userdata.companyID,
      companyName: this.props.userdata.companyName,
      journalUserEmail: this.props.userdata.email,
      journalUserID: this.props.userdata.userID,
      journalUserName: this.props.userdata.firstName + ' ' + this.props.userdata.lastName,
      message: this.state.message,
      subject: this.state.subject,
      userPlatform: window.navigator.userAgent
    }
    let error_check = false
    let err_msg = []

    if (feedbackForm.category === 'feedback') {
      error_check = true
      err_msg.push('Feedback Category')
    }

    if (feedbackForm.subject === '') {
      error_check = true
      err_msg.push('Subject')
    }

    if (feedbackForm.message === '') {
      error_check = true
      err_msg.push('Feedback Message')
    }

    if (!error_check) {
      this.props.sendFeedback(feedbackForm)
      this.handleClose()
    } else {
      this.props.showToast({
        type: SHOW_TOAST,
        open: true,
        variant: 'error',
        message: 'Please complete the following fields: ' + err_msg.join(', ').toString() + '.'
      })
    }
  };

  handleChange = event => {
    this.setState({ value: event.target.value })
  };

  handleChangeSubject = name => event => {
    this.setState({
      [name]: event.target.value
    })
  };

  render() {
    const { classes, theme } = this.props
    const { value, subject, message } = this.state
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          fullScreen={window.innerWidth >= 425 ? false : true}
          fullWidth
          TransitionComponent={Transition}
          aria-labelledby='scroll-dialog-title'
        >
          {window.innerWidth >= 425 ? (
            <DialogTitle id='alert-dialog-title'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MessageIcon
                  fontSize='small'
                  style={{ color: `${LIGHTBLUE}` }}
                />
                <span
                  style={{
                    color: `${LIGHTBLUE}`,
                    fontWeight: 'bold',
                    fontSize: '15px'
                  }}
                >
                  &nbsp;Contact Form
                </span>
              </div>
            </DialogTitle>
          ) : (
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color='inherit'
                  onClick={this.handleClose}
                  aria-label='Close'
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography
                  variant='h6'
                  color='inherit'
                  className={classes.flex}
                >
                  Send Message
                </Typography>
              </Toolbar>
            </AppBar>
          )}

          <DialogContent>
            <Feedback
              classes={classes}
              handleChange={this.handleChange}
              handleChangeSubject={this.handleChangeSubject}
              value={value}
              subject={subject}
              subjectError={this.state.subjectError}
              feedbackError={this.state.feedbackError}
              message={message}
              messageError={this.state.messageError}
              {...this.props}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.submitFeedBackForm}
              size='lg'
              variant='contained'
              className={classes.submitButton}
            >
              <SendIcon />
              Send Message
            </Button>
            <Button onClick={this.handleClose} size='lg' color='secondary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

// TODO: Check Theme object is definedss
const AddFeedbackDialog = withStyles(styles, { withTheme: true })(AddFeedback)

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    sendFeedback: params => dispatch(sendFeedback(params))
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0]
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFeedbackDialog)
