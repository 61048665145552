import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/Email'
import {
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader, Button, Checkbox, ListItemIcon
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { SUPPORTED_FILE_TYPES, SHOW_TOAST } from "../../store/actions/types";
import { showToast } from '../../store/actions/toastAction'
import { getAssignedStudyMaterials, removeGroupStudyMaterials, studyMaterialsEmail } from '../../store/actions/companyAdminActions'
import { LIGHTGREY, GREEN, RED } from "../../utils/color_utils";

const styles = {
  root: {
    width: "100%",
    backgroundColor: "#fff",
  },
}

class AssignedGroupsList extends Component {
  constructor (props) {
    super(props)
  }

  state = {
    checked: [],
  };

  componentDidMount() {
    this.props.getAssignedStudyMaterials(
      {
        file: this.props.file,
        userdata: this.props.userdata,
      }
    )
  }

  removeGroup = (file) => e => {
    this.props.removeGroupStudyMaterials(
      {
        ...file
      }
    )
  }

  notifyGroupMembers = (assigned_study_materials) => e => {
    if (this.state.checked.length === 0) {
      var params = {
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Please select group(s) to notify!"
      };

      this.props.showToast(params);
      return;
    }

    this.props.studyMaterialsEmail(
      {
        data: assigned_study_materials,
        selectedgroups: this.state.checked
      }
    );
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked })
  };

  render() {
    const { classes, assigned_study_materials } = this.props;

    return (
      <div>
        <div style={{ textAlign: 'right', marginBottom: "10px" }}>
          <Button variant='outlined'
            color='primary'
            size='small' onClick={this.notifyGroupMembers(assigned_study_materials)}><EmailIcon fontSize='small' style={{ marginRight: "8px" }} /> Notify Members</Button>
        </div>
        <List subheader={<ListSubheader style={{ backgroundColor: `${LIGHTGREY}`, fontWeight: "bold", fontSize: "13px", lineHeight: "28px", color: "#000", borderRadius: "4px" }}>Assigned Groups</ListSubheader>} dense className={classes.root}>
          {assigned_study_materials && Object.keys(assigned_study_materials).map(id => {
            const labelId = `${assigned_study_materials[id].group_id}`;
            const labelName = `${assigned_study_materials[id].group_name}`;

            return (
              <div>
                <ListItem key={labelId} role={undefined} dense button onClick={this.handleToggle(labelId)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={this.state.checked.indexOf(labelId) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${labelName}`} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Remove Group">
                      <IconButton style={{ color: `${RED}` }} size="small" onClick={this.removeGroup(assigned_study_materials[id])} edge="end" aria-label="complete">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            );
          })}
        </List>
      </div>
    )
  }
}


AssignedGroupsList.propTypes = {
  classes: PropTypes.object,
  userdata: PropTypes.any
}

const AssignedGroupsListStyled = withStyles(styles)(AssignedGroupsList)

const mapStateToProps = state => {
  return {
    assigned_study_materials: state.users.assigned_study_materials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    getAssignedStudyMaterials: params => dispatch(getAssignedStudyMaterials(params)),
    removeGroupStudyMaterials: params => dispatch(removeGroupStudyMaterials(params)),
    studyMaterialsEmail: params => dispatch(studyMaterialsEmail(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignedGroupsListStyled)