import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ThoughtsCard from "../Cards/ThoughtsCard";
import RemoveAttachmentDialog from "../Dialogs/RemoveAttachmentDialog";
import AddToPLDPDialog from "../Dialogs/AddToPLDPDialog";
import Progressbar from "../ProgressBar";

import MediaPlayer from "../Dialogs/MediaPlayer";

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class GroupThoughtsView extends React.Component {
  state = {
    openmediaplayer: false,
    mediatype: "podcast",
    media: null,
    openAddPLDP: false,
    attachmentType: "",
    thoughtToAdd: {}
  };

  OpenMediaPlayer = (media, mediatype) => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media,
      mediatype: mediatype
    });
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    });
  };

  handleOpenAddToPLDPDialog = thought => {
    this.setState(prevState => ({
      openAddPLDP: true,
      thoughtToAdd: thought
    }));
  };

  handleAddToPLDP = () => { };
  handleCloseDialog = () => {
    this.setState(prevState => ({
      openAddPLDP: false,
      thoughtToAdd: {}
    }));
  };
  handleRemoveAttachement = (thought, attachmentType, attachmentID) => { };

  render() {
    const { openmediaplayer, media, mediatype } = this.state;
    const { classes, thought, match, followingData } = this.props;

    return (
      <React.Fragment>
        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}
        <div className={classes.cardstyles}>
          {thought ?
            <ThoughtsCard
              onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
              ref={"" + thought.dailyThoughtID}
              onCommentsClick={this.handleCommentsClick}
              thought={thought}
              key={thought.id}
              PlayMedia={this.OpenMediaPlayer}
              onRemoveAttachement={this.handleRemoveAttachement}
              {...this.props}
              following={
                followingData
                  ? followingData[thought.journalUserID]
                    ? true
                    : false
                  : false
              }
              hideComments={false}
              openFileViewer={this.props.openFileViewer}
            />
            : null}

          <AddToPLDPDialog
            open={this.state.openAddPLDP}
            onClickProceed={this.handleAddToPLDP}
            onCloseDialog={this.handleCloseDialog}
          />
          <RemoveAttachmentDialog />
        </div>
      </React.Fragment>
    );
  }
}

GroupThoughtsView.propTypes = {
  classes: PropTypes.object.isRequired,
  thought: PropTypes.any
};

var GroupThoughtsViewStyled = withStyles(styles)(GroupThoughtsView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(GroupThoughtsViewStyled);
