import {
  INTERNAL_ARTICLES,
  EXTERNAL_ARTICLES,
  MY_ARTICLES,
  BASE_API_URL,
  GLP_LOGGED_PROFILE,
  PLDP_ARTICLES
} from "./types";
import firebase from "../firebase";
import axios from "axios";
import { _toArray, _toArrayPosts } from "./commonActions";

const {v4: uuidv4} = require('uuid')

export const fetchArticles = params => {
  switch (params.type) {
    case INTERNAL_ARTICLES:
      return (dispatch, getState) => {
        firebase.database
          .ref("news")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              // var sortedData = _toArray(data.val());
              // sortedData.sort(function(a, b) {
              //   if (a.dateScheduled > b.dateScheduled) return -1;
              //   if (a.dateScheduled < b.dateScheduled) return 1;
              //   return 0;
              // });
              var sortedData = data.val();
              dispatch({
                type: INTERNAL_ARTICLES,
                articles: sortedData
              });
            } else {
              dispatch({
                type: INTERNAL_ARTICLES,
                articles: {}
              });
            }
          });
      };
    case MY_ARTICLES:
      return (dispatch, getState) => {
        firebase.database
          .ref("news")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              var sortedData = _toArrayPosts(data.val());
              sortedData.sort(function(a, b) {
                if (a.dateScheduled > b.dateScheduled) return -1;
                if (a.dateScheduled < b.dateScheduled) return 1;
                return 0;
              });
              dispatch({
                type: MY_ARTICLES,
                myarticles: sortedData
              });
            } else {
              dispatch({
                type: MY_ARTICLES,
                myarticles: {}
              });
            }
          });
      };
    case PLDP_ARTICLES:
      return (dispatch, getState) => {
        firebase.database
          .ref("myPLDP/news")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(10)
          .on("value", function(data) {
            if (data.val()) {
              var sortedData = _toArrayPosts(data.val());
              sortedData.sort(function(a, b) {
                if (a.dateScheduled > b.dateScheduled) return -1;
                if (a.dateScheduled < b.dateScheduled) return 1;
                return 0;
              });
              dispatch({
                type: PLDP_ARTICLES,
                pldparticles: sortedData
              });
            } else {
              dispatch({
                type: PLDP_ARTICLES,
                pldparticles: {}
              });
              return;
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: "NO_DATA",
          articles: {}
        });
      };
  }
};

export const fetchExternalArticles = params => {
  switch (params.type) {
    case EXTERNAL_ARTICLES:
      return (dispatch, getState) => {
        firebase.database
          .ref("news")
          .orderByChild(params.orderChild)
          .equalTo(params.equalTo)
          .limitToLast(params.minData)
          .on("value", function(data) {
            if (data.val()) {
              // var sortedData = _toArray(data.val());
              // sortedData.sort(function(a, b) {
              //   if (a.dateScheduled > b.dateScheduled) return -1;
              //   if (a.dateScheduled < b.dateScheduled) return 1;
              //   return 0;
              // });
              var sortedData = data.val();
              dispatch({
                type: EXTERNAL_ARTICLES,
                externalarticles: sortedData
              });
            } else {
              dispatch({
                type: EXTERNAL_ARTICLES,
                externalarticles: {}
              });
            }
          });
      };
    default:
      return (dispatch, getState) => {
        dispatch({
          type: EXTERNAL_ARTICLES,
          externalarticles: {}
        });
      };
  }
};

export const createArticle = params => {
  console.log('green createArticle : ', params);
  
  var USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));
  return (dispatch, getState) => {
    if (params.subcompetencyid) {
      //nothing
      }
      else
      {
        params.subcompetencyid = params.competencyid + '-' + 'None';
        console.log('thoughtsActions createThought props: ', params.subcompetencyid);
      }
    // set user data
    var body = {
      ...params,
      companyID: USER_LOGGED.companyID,
      companyName: USER_LOGGED.companyName,
      journalUserID: USER_LOGGED.userID,
      journalUserName: USER_LOGGED.firstName + " " + USER_LOGGED.lastName,
      user_type: USER_LOGGED.userType,
      photoURL: USER_LOGGED.photoURL || ""
    };
    console.log('purple createArticle body : ', body);

    // attach any available urls object
    // Add url to thought
    var urlCount = Object.keys(params.articlesObj).length;
    var urls = {};

    for (var i = 0; i < urlCount; i++) {
      var uuid = uuidv4();
      var url = {
        companyID: USER_LOGGED.companyID,
        companyName: USER_LOGGED.companyName,
        dateRegistered: body.dateRegistered,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.articlesObj[i].title,
        url: params.articlesObj[i].url
      };
      urls[uuid] = url;
    }

    // embed urls to body
    if (urlCount > 0) {
      body.urls = urls;
    }

    // Add files to thought
    var fileCount = Object.keys(params.filesObj).length;
    var files = {};

    for (var i = 0; i < fileCount; i++) {
      var uuid = uuidv4();
      var file = {
        companyID: USER_LOGGED.companyID,
        companyName: USER_LOGGED.companyName,
        dateRegistered: body.dateRegistered,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.filesObj[i].title,
        url: params.filesObj[i].url
      };

      files[uuid] = file;
    }

    // embed files to body
    if (fileCount > 0) {
      body.files = files;
    }

    // attach any available podcasts object
    // Add podcast url to thought
    if (params.PodcastExternalRecord !== "podcastnone") {
      var podcastUrlCount = Object.keys(params.podcastsObj).length;
      var podcastUrls = {};

      for (var i = 0; i < podcastUrlCount; i++) {
        var uuid = uuidv4();
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.podcastsObj[i].title,
          url: params.podcastsObj[i].url,
          // newsID: "0",
          podcastDescription: "Podcast",
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 2,
          storageName: params.podcastsObj[i].title,
          userID: body.journalUserID,
          userName: body.journalUserName
        };

        podcastUrls[uuid] = url;

        // Embed podcasts to body
        body.podcasts = podcastUrls;
      }

      // Add podcast uploads
      var podcastUrlCnt = Object.keys(params.podcasturl).length;

      // embed urls to body
      if (podcastUrlCnt > 0) {
        var uuid = uuidv4();
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.podcasturl.podcastname,
          url: params.podcasturl.podcasturl,
          // newsID: "0",
          podcastDescription: "Podcast",
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 2,
          storageName: params.podcasturl.podcastname,
          userID: body.journalUserID,
          userName: body.journalUserName
        };

        podcastUrls[uuid] = url;

        // Embed podcasts to body
        body.podcasts = podcastUrls;
      }

      // Add audio recordings
      var audioUrlCnt = Object.keys(params.audiourl).length;

      // embed urls to body
      if (audioUrlCnt > 0) {
        var uuid = uuidv4();
        var url = {
          active: true,
          companyID: USER_LOGGED.companyID,
          companyName: USER_LOGGED.companyName,
          dateRegistered: body.dateRegistered,
          dateScheduled: body.dateScheduled,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          stringDateScheduled: new Date(body.dateScheduled).toLocaleString(),
          title: params.audiourl.audioname,
          url: params.audiourl.audiourl,
          // newsID: "0",
          podcastDescription: "Voicemail",
          podcastID: uuid,
          podcastSize: 0,
          podcastType: 1,
          storageName: params.audiourl.audioname,
          userID: body.journalUserID,
          userName: body.journalUserName
        };

        podcastUrls[uuid] = url;

        // Embed podcasts to body
        body.podcasts = podcastUrls;
      }
    }

    // Embed images
    var imageUrlCnt = Object.keys(params.imageurl).length;
    var imageUrls = {};

    // embed urls to body
    if (imageUrlCnt > 0) {
      var uuid = uuidv4();

      var url = {
        bytes: 0,
        caption: params.imageurl.imagename,
        // newsID: "0",
        date: body.dateRegistered,
        dateRegistered: body.dateRegistered,
        dateUploaded: body.dateRegistered,
        height: 0,
        imageSize: 0,
        stringDate: new Date(body.dateRegistered).toLocaleString(),
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        stringDateUploaded: new Date(body.dateRegistered).toLocaleString(),
        thumbNailUrl: params.imageurl.imageurl,
        title: body.title,
        url: params.imageurl.imageurl,
        photoID: uuid,
        userID: body.journalUserID,
        userName: body.journalUserName,
        width: 0
      };

      imageUrls[uuid] = url;

      // Embed images to body
      body.photos = imageUrls;
    }

    // Embed Videos
    // attach any available video object
    // Add video url to thought
    var videoUrlCount = Object.keys(params.videosObj).length;
    var videoUrls = {};

    for (var i = 0; i < videoUrlCount; i++) {
      var uuid = uuidv4();
      var url = {
        active: true,
        company_status: USER_LOGGED.companyID + "_true",
        caption: params.videosObj[i].title,
        companyID: USER_LOGGED.companyID,
        companyName: USER_LOGGED.companyName,
        date: body.dateRegistered,
        dateRegistered: body.dateRegistered,
        dateUploaded: body.dateRegistered,
        dateUpdated: 0,
        stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
        title: params.videosObj[i].title,
        url: params.videosObj[i].url,
        // newsID: "0",
        description: body.title,
        videoID: uuid,
        storageName: params.videosObj[i].title,
        userID: body.journalUserID,
        userName: body.journalUserName,
        photoURL: USER_LOGGED.photoURL || "",
        videoSize: 0,
        lengthInSeconds: 0,
        UploadExternal: "vidupload"
      };

      videoUrls[uuid] = url;

      // Embed videos to body
      body.videos = videoUrls;
    }

    if (params.VidUploadExternal !== "videonone") {
      // Add video uploads
      var videoUrlCnt = Object.keys(params.videourl).length;

      // embed urls to body
      if (videoUrlCnt > 0) {
        var uuid = uuidv4();
        var url = {
          active: true,
          company_status: USER_LOGGED.companyID + "_true",
          caption: params.videourl.videoname,
          companyID: USER_LOGGED.companyID,
          companyName: USER_LOGGED.companyName,
          date: body.dateRegistered,
          dateRegistered: body.dateRegistered,
          dateUploaded: body.dateRegistered,
          dateUpdated: 0,
          stringDateRegistered: new Date(body.dateRegistered).toLocaleString(),
          url: params.videourl.videourl,
          // newsID: "0",
          description: body.title,
          videoID: uuid,
          storageName: params.videourl.title,
          userID: body.journalUserID,
          userName: body.journalUserName,
          photoURL: USER_LOGGED.photoURL || "",
          videoSize: 0,
          lengthInSeconds: 0,
          UploadExternal: "videxternal"
        };

        videoUrls[uuid] = url;

        // Embed podcasts to body
        body.videos = videoUrls;
      }
    }

    // clean data before posting
    delete body["maxLengthThought"];
    delete body["maxLengthAuthor"];
    delete body["modalTitle"];
    delete body["postInternalGlobal"];
    delete body["uploadInProgress"];
    delete body["selectedDate"];
    delete body["selectedTime"];
    delete body["PodcastExternalRecord"];
    delete body["VidUploadExternal"];
    delete body["articlesObj"];
    delete body["audiourl"];
    delete body["imageurl"];
    delete body["podcastsObj"];
    delete body["podcastType"];
    delete body["podcasturl"];
    delete body["videourl"];
    delete body["videosObj"];
    delete body["fileurl"];
    delete body["filesObj"];

    // Editing or Creating new post?
    if (params.editing) {
      delete body["dateRegistered"];
      delete body["post"];
      body.name = "articles";
      body.id = params.post.newsID;

      //console.log("body: ", body);

      // post new data
      axios
        .put(`${BASE_API_URL}/articles/${body.id}`, body)
        .then(res => {
          dispatch({
            type: "CREATE_ARTICLE_SUCCESS"
          });
        })
        .catch(err => {
          console.log("err: ", err)
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error creating a post, please confirm that all fields are filled in 1!"
          });
          // if (err.response) {
          //   dispatch({
          //     type: "SHOW_TOAST",
          //     open: true,
          //     variant: "error",
          //     message: "Error response: " + err.response.data.message
          //   });
          // } else if (err.request) {
          //   dispatch({
          //     type: "SHOW_TOAST",
          //     open: true,
          //     variant: "error",
          //     message: "Error request: " + err.request
          //   });
          // } else {
          //   dispatch({
          //     type: "SHOW_TOAST",
          //     open: true,
          //     variant: "error",
          //     message: "Error message: " + err.message
          //   });
          // }
        });
    } else {
      // post new data
      if ((body.groupid === "1") && (body.dailyThoughtType == "4") && (body.selectedgroups.length > 0)) {
        // create multiple posts requests
        // console.log("selected groups: ", body.selectedgroups)
        // console.log("multiple posts requests: ", body)
        var multi_requests = [];


        for (let i = 0, l = body.selectedgroups.length; i < l; i += 1) {
          let single_body = { ...body };
          single_body.groupid = body.selectedgroups[i].group_id
          single_body.groupname = body.selectedgroups[i].group_name

          // console.log("i: ", i)
          // console.log("group_id: ", body.selectedgroups[i].group_id)
          // console.log("group_name: ", body.selectedgroups[i].group_name)
          // console.log("single_body: ", single_body)

          const request = axios.post(`${BASE_API_URL}/articles`, single_body)

          multi_requests.push(request)
        }

        console.log("multi_requests: ", multi_requests)

        axios.all(multi_requests).then(axios.spread((...responses) => {
          // console.log("finished posting: ", responses)
          dispatch({
            type: "CREATE_ARTICLE_SUCCESS"
          });
          // const responseOne = responses[0]
          // const responseTwo = responses[1]
          // const responesThree = responses[2]
          // use/access the results 
        })).catch(errors => {
          // react on errors.
          // console.log("error posting: ", errors)
          dispatch({
            type: "SHOW_TOAST",
            open: true,
            variant: "error",
            message: "Error posting an article!"
          });
        })
      } else {
        console.log("single posting : ", body)
        //.post(`${BASE_API_URL}/articles`, body)
        axios
          .post(`${BASE_API_URL}/articles`, body)
          .then(res => {
             console.log("single posting 2: ", res)
            dispatch({
              type: "CREATE_ARTICLE_SUCCESS"
            });
          })
          .catch(err => {
            console.log("single posting 3: ", body)
            dispatch({
              type: "SHOW_TOAST",
              open: true,
              variant: "error",
              message: "Error creating a post, please confirm that all fields are filled in 2 !" + err
            });
            // if (err.response) {
            //   dispatch({
            //     type: "SHOW_TOAST",
            //     open: true,
            //     variant: "error",
            //     message: "Error response: " + err.response.data.message
            //   });
            // } else if (err.request) {
            //   dispatch({
            //     type: "SHOW_TOAST",
            //     open: true,
            //     variant: "error",
            //     message: "Error request: " + err.request
            //   });
            // } else {
            //   dispatch({
            //     type: "SHOW_TOAST",
            //     open: true,
            //     variant: "error",
            //     message: "Error message: " + err.message
            //   });
            // }
          });
      }
    }
  };
};
