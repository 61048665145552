import firebase from "../firebase";
import { COMPETENCIES_LIST, COMPETENCIES_TEST } from "./types";
import { _toArray } from "./commonActions";

export const fetchCompetencies = params => {
  return (dispatch, getState) => {
    firebase.database.ref(`competencies/${params.companyID}`).on("value", function(data) {
      if (data.val()) {
        var sortedData = _toArray(data.val());
        dispatch({
          type: COMPETENCIES_LIST,
          competencies_list: sortedData
        });

      } else {
        return (dispatch, getState) => {
          dispatch({
            type: COMPETENCIES_LIST,
            competencies_list: []
          });
        };
      }
    });
  };
};

export const testCompetencies = params => {
  var x;
  return (dispatch, getState) => {
    firebase.database.ref(`company-values/${params.companyID}`).on("value", function(data) {
      if (data.val()) {
       x = true;
      }
      else {
       x = false;
      }

      if (x===true) {
      console.log('addcompetencycompetenciesIS 2a : ', data.val())   
        dispatch({
          type: COMPETENCIES_TEST,
          competencies_is: true
        });

      } else {
        console.log('addcompetencycompetenciesIS 2b : ', x)
        return (dispatch, getState) => {
          dispatch({
            type: COMPETENCIES_TEST,
            competencies_is: false
          });
        };
      }
    });
  };
};
