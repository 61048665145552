import React from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import {
  isMobile,
  isBrowser
} from 'react-device-detect'

//import "./Popup.css";
const popupContainer = {
    position: 'fixed',
    width: isMobile ? '500px' : '100%',
    height: isMobile ? '850px' : '110%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    //overlay: {zIndex: 999},
    zIndex: '99999'
  }
  const popupBody = {
    position: 'absolute',
    left: isMobile ? '0%' : '30%',
    right: isMobile ? '26%' : '30%',
    bottom: isMobile ? '45%' : '30%',
    top: isMobile ? '10%' : '30%',
    textAlign: 'left',
    margin: 'auto',
    borderRadius: '15px',
    borderColor: 'black',
    background: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    //overlay: {zIndex: 999},
    zIndex: '9999999'
  }
export const Pop = ({ text, closePopup }) => {
  return (
    <div style={popupContainer} >
     <div style={popupBody} >
      <p><br/><b>&nbsp; &nbsp;{text}</b>&nbsp; &nbsp; </p> 
      <ol>
      <li>Make sure your low rating is given with the right intention, 
      to truly help the leader and that it reflects the Leaders behaviour 
      over a period of time. In other words, it is not based on an isolated incident - 
      feedback on isolated behaviours can be given under real-time feedback above.</li> 
      <li>Should you still feel the low rating is a fair reflection, please ensure your 
      typed comment in the feedback box explains sufficiently to the leader why it is low, 
      so that he/she can accurately work on improving.</li>
      </ol>
      
      &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;<Button onClick={closePopup} 
      style={{ color: 'black', transition: 'color 0.3s' }}
        onMouseEnter={(e) => {
            e.target.style.color = 'red';
        }}
        onMouseLeave={(e) => {
            e.target.style.color = 'black';
        }}
      >
        <b>I UNDERSTAND - CONTINUE</b></Button>
     </div>
    </div>
    
  );
};