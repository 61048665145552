import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Typography, AppBar, IconButton, Toolbar } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  dialogHeight: {
    maxHeight: `calc(100% - 2px)`
  },
  appBar: {
    //position: "static",
    backgroundColor: "#c3272e !important"
  }
});
class CommentDialogCard extends React.Component {
  state = {
    open: false,
    scroll: "body"
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.props.onOpenCommentsClick();
  };

  render() {
    const { classes, opendialog, fullScreen } = this.props;
    
    return (
      <Dialog
        style={{
          // backgroundColor:  "transparent", 
          // width: "500px",
           //position: "relative",
           // display: "block",
            // paddingRight: "5px",
           // paddingLeft: "-200px",
            //border: "10px",
            //borderRadius: "4px",
           // effect: "fadeInDown",
           // padding: "10rem",

            // minWidth: "420px", 
            // maxWidth: "700px",
            // marginLeft: "auto",
            // marginRight: "auto",
            // position: "fixed",
            // height: "100%",
            // top: "0px",
            // left: "-15%",

            minWidth: "100%", 
            maxWidth: "100%",
            marginLeft: "-10px",
            marginRight: "auto",
            position: "fixed",
            height: "100%",
            top: "0px",
            left: "0px",
            
            //transform: "translate(-50%, -50%)"
 
            //top: "50%",
            //left: "-100px",
            //transform: "translate(-50%, -50%)"
           // margin: "0 auto"
            //position: "relative",
            // display: "flex",
            // flexDirection: "column",
            // marginTop: "50%"

            // position: "absolute",
            // top: "20%",
            // left: "50%",

            //width: "300px"

          }}
        open={this.props.open}
        onClose={this.handleClose}
       // classes={{ paper: classes.dialogHeight }}
      >
        {/* {window.innerWidth >= 425 ? null : (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">
                Viewing Post
              </Typography>
            </Toolbar>
          </AppBar>
        )} */}

        {this.props.render(this.state, this.handleClose)}
      </Dialog>
    );
  }
}


CommentDialogCard.propTypes = {
  classes: PropTypes.object.isRequired
};

const commentDialogStyled = withStyles(styles)(CommentDialogCard);
export default commentDialogStyled;
// export default CommentDialogCard;
