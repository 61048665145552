import React, { Component } from "react";
import PropTypes from "prop-types";
import VideosView from "../components/Views/MyVideosView";
import { connect } from "react-redux";
import { fetchVideos } from "../store/actions/videosActions";
import { MY_VIDEOS } from "../store/actions/types";

class MyVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      minData: 6
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 6
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    var params = {
      type: MY_VIDEOS,
      companyID: this.props.userdata.companyID,
      orderChild: "postOwner",
      equalTo: this.props.userdata.userID,
      minData: this.state.minData
    };
    this.props.fetchVideos(params);
  };

  render() {
    const { videos, userdata } = this.props;
    console.log('videosView props 2: ', this.props);
    return (
      <div>
        {userdata && videos ? (
          <React.Fragment>
            {/* <VideosView videos={videos} {...this.props} /> */}
            <VideosView videos={videos} userdata={this.props.userdata} />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

MyVideos.propTypes = {
  fetchVideos: PropTypes.func.isRequired,
  videos: PropTypes.array
};

const mapStateToProps = state => {
  return {
    videos: state.videos.myvideos,
    userdata: state.userDialog.userDetails[0]
  };
};

export default connect(
  mapStateToProps,
  { fetchVideos }
)(MyVideos);
