/* eslint-disable no-shadow */
/* eslint-disable max-nested-callbacks */
/* eslint-disable max-len */

import firebase from '../firebase'

import {
  LOGIN_SUCCESS,
  SIGNOUT_SUCCESS,
  SHOW_TOAST,
  ERROR,
  INFO,
  USER_LOGGED_IN,
  GLP_LOGGED_PROFILE,
  GLP_LOGGED_COMPANY,
  APP_TYPE,
  WARNING
} from './types'
import { loggedInProfileActions } from './loggedInProfileActions'
import { DATE_FORMAT } from '../../utils/date_format_utils'
import axios from 'axios'

require('firebase/auth')
// authActions.js:279
// black signInWithEmailAndPassword
// black signUpNewUsers

export const signInWithEmailAndPassword = credentials => {
  return async (dispatch, getState) => {
    // signInWithEmailAndPassword(credentials.username, credentials.userpassword)
    firebase.auth.signInWithEmailAndPassword(credentials.username, credentials.userpassword)
      .then((auth) => {
        let userobj = {
          uid: auth.user.uid,
          displayName: auth.user.displayName,
          email: auth.user.email,
          emailVerified: auth.user.emailVerified,
          refreshToken: auth.user.refreshToken,
          metadata: auth.user.metadata,
          selectClub: auth.user.selectClub,
          companyID: getCompanyID(auth.user.email)
        }
        console.log('green signIn: ', userobj)

        // localStorage.setItem(USER_LOGGED_IN, JSON.stringify(userobj));
        // loggedInProfileActions(userobj.uid);
        // dispatch({ type: LOGIN_SUCCESS, auth: userobj });

        let userLoginData = {
          uid: auth.user.uid,
          name: auth.user.displayName,
          email: auth.user.email,
          dateRegistered: Date.now(),
          clickType: 'lastlogin'
        }

        localStorage.setItem(USER_LOGGED_IN, JSON.stringify(userobj))

        firebase.database
          .ref(`/user/${userobj.uid}`)
          .once('value')
          .then(data => {
            let isUser = data.val()

            if (isUser) {
              firebase.database.ref('user-lastlogin').push(userLoginData)
               loggedInProfileActions(userobj.uid);
              dispatch({ type: LOGIN_SUCCESS, auth: userobj })
              dispatch({ type: GLP_LOGGED_COMPANY })
            } else {
              // get date and time
              let timeInMs = Date.now()
              let datetime = new Date(timeInMs)
              // format date
              datetime = DATE_FORMAT.type(datetime, 'BADGE')

              let userID = firebase.database
                .ref()
                .child('users')
                .push().key
              var data = {
                // companyID: "-LjW7UI7e0uUikYrw-Vj",
                // companyName: "Thinklead Ecosystem",
                dateRegistered: timeInMs,
                email: auth.user.email,
                firstName: auth.user.displayName,
                lastName: '',
                password: '',
                stringDateRegistered: datetime,
                userDescription: 'Public Contributor',
                uid: auth.user.uid,
                userID: userID,
                userType: 3,
                inviteSent: true,
                companyID_userType: auth.user.selectClub + '_3',
                photoURL: auth.user.photoURL
                // companyID: params.selectClub,
                // companyName: params.clubName
              }

              // Write the new rating data
              let updates = {}
              updates['/users/' + userID] = data
              updates['/user/' + auth.user.uid] = data

              firebase.database.ref().update(updates, function (error) {
                if (error) {
                  // The write failed...
                  firebase.auth.signOut().then(() => {
                    localStorage.removeItem(USER_LOGGED_IN)
                    localStorage.removeItem(GLP_LOGGED_PROFILE)
                    localStorage.removeItem(GLP_LOGGED_COMPANY)

                    dispatch({
                      type: SHOW_TOAST,
                      variant: ERROR,
                      message: error.message
                    })
                    return
                  })
                } else {
                  loggedInProfileActions(userobj.uid);
                  firebase.database.ref('user-lastlogin').push(userLoginData)
                  dispatch({ type: LOGIN_SUCCESS, auth: userobj })
                  dispatch({ type: GLP_LOGGED_COMPANY })
                  return
                }
              })
            }
          })
          .catch(function (error) {
            firebase.auth.signOut().then(() => {
              localStorage.removeItem(USER_LOGGED_IN)
              localStorage.removeItem(GLP_LOGGED_PROFILE)
              localStorage.removeItem(GLP_LOGGED_COMPANY)

              dispatch({
                type: SHOW_TOAST,
                variant: ERROR,
                message: error.message
              })
              return
            })
          })
      })
      .catch(err => {
        localStorage.removeItem(USER_LOGGED_IN)
        dispatch({ type: SHOW_TOAST, variant: ERROR, message: err.message })
      })
  }
}

export const signInWithEmailAndPasswordx = credentials => {
  console.log('green signInWithEmailAndPassword : ', credentials.username)
  return async (dispatch, getState) => {
    await firebase.auth
      .signInWithEmailAndPassword(credentials.username, credentials.userpassword)
      .then(auth => {
        let userobj = {
          uid: auth.user.uid,
          displayName: auth.user.displayName,
          email: auth.user.email,
          emailVerified: auth.user.emailVerified,
          refreshToken: auth.user.refreshToken,
          metadata: auth.user.metadata,
          selectClub: auth.user.selectClub,
          companyID: getCompanyID(auth.user.email)
        }
        console.log('green signIn: ', userobj)

        // localStorage.setItem(USER_LOGGED_IN, JSON.stringify(userobj));
        // loggedInProfileActions(userobj.uid);
        // dispatch({ type: LOGIN_SUCCESS, auth: userobj });

        let userLoginData = {
          uid: auth.user.uid,
          name: auth.user.displayName,
          email: auth.user.email,
          dateRegistered: Date.now(),
          clickType: 'lastlogin'
        }

        localStorage.setItem(USER_LOGGED_IN, JSON.stringify(userobj))

        firebase.database
          .ref(`/user/${userobj.uid}`)
          .once('value')
          .then(data => {
            let isUser = data.val()

            if (isUser) {
              firebase.database.ref('user-lastlogin').push(userLoginData)
               loggedInProfileActions(userobj.uid);
              dispatch({ type: LOGIN_SUCCESS, auth: userobj })
              dispatch({ type: GLP_LOGGED_COMPANY })
            } else {
              // get date and time
              let timeInMs = Date.now()
              let datetime = new Date(timeInMs)
              // format date
              datetime = DATE_FORMAT.type(datetime, 'BADGE')

              let userID = firebase.database
                .ref()
                .child('users')
                .push().key
              var data = {
                // companyID: "-LjW7UI7e0uUikYrw-Vj",
                // companyName: "Thinklead Ecosystem",
                dateRegistered: timeInMs,
                email: auth.user.email,
                firstName: auth.user.displayName,
                lastName: '',
                password: '',
                stringDateRegistered: datetime,
                userDescription: 'Public Contributor',
                uid: auth.user.uid,
                userID: userID,
                userType: 3,
                inviteSent: true,
                companyID_userType: auth.user.selectClub + '_3',
                photoURL: auth.user.photoURL
                // companyID: params.selectClub,
                // companyName: params.clubName
              }

              // Write the new rating data
              let updates = {}
              updates['/users/' + userID] = data
              updates['/user/' + auth.user.uid] = data

              firebase.database.ref().update(updates, function (error) {
                if (error) {
                  // The write failed...
                  firebase.auth.signOut().then(() => {
                    localStorage.removeItem(USER_LOGGED_IN)
                    localStorage.removeItem(GLP_LOGGED_PROFILE)
                    localStorage.removeItem(GLP_LOGGED_COMPANY)

                    dispatch({
                      type: SHOW_TOAST,
                      variant: ERROR,
                      message: error.message
                    })
                    return
                  })
                } else {
                   loggedInProfileActions(userobj.uid);
                  firebase.database.ref('user-lastlogin').push(userLoginData)
                  dispatch({ type: LOGIN_SUCCESS, auth: userobj })
                  dispatch({ type: GLP_LOGGED_COMPANY })
                  return
                }
              })
            }
          })
          .catch(function (error) {
            firebase.auth.signOut().then(() => {
              localStorage.removeItem(USER_LOGGED_IN)
              localStorage.removeItem(GLP_LOGGED_PROFILE)
              localStorage.removeItem(GLP_LOGGED_COMPANY)

              dispatch({
                type: SHOW_TOAST,
                variant: ERROR,
                message: error.message
              })
              return
            })
          })
      })
      .catch(err => {
        localStorage.removeItem(USER_LOGGED_IN)
        dispatch({ type: SHOW_TOAST, variant: ERROR, message: err.message })
      })
  }
}

function splitNameFirst(fullnamea) {
  let fullNameb = fullnamea.split(' ')
  let firstName = fullNameb[0]
  console.log('greeny signInWithGoogle firstname: ', firstName)
  return firstName
}

function splitNameLast(fullnamea) {
  let fullNameb = fullnamea.split(' ')
  let firstName = fullNameb[0]
  let lastName = fullNameb[fullNameb.length - 1]
  console.log('greeny signInWithGoogle lastName: ', lastName)
  return lastName
}

export const signInWithGoogle = selectClub => {
  //function signInWithGoogle(selectClub) {
 //const {selectClub} = props;
///////////////////////////////////////////////////////
 // const paramsx = sss;
 console.log('showLogin 3 :', selectClub);
 //  const selectClubb = selectClub.selectClubb;
 let selectClubb = selectClub;

let provider = new firebase.auth_.GoogleAuthProvider()
 // let hhh = sss.selectClubb;
  console.log('showLogin 4 :', selectClubb);
 // console.log('showLogin 5 :', hhh);
/////////////////////////////////////////////////////////
return async (dispatch, getState) => {
   await firebase.auth
     .signInWithPopup(provider)
     .then(function (auth) {
       let userobj = {
         uid: auth.user.uid,
         displayName: auth.user.displayName,
         email: auth.user.email,
         emailVerified: auth.user.emailVerified,
         refreshToken: auth.user.refreshToken,
         metadata: auth.user.metadata,
         photoURL: auth.user.photoURL || '',
         accessToken: auth.credential.accessToken
       }
       console.log('showLogin 5 :', selectClubb);
       let userLoginData = {
         uid: auth.user.uid,
         name: auth.user.displayName,
         email: auth.user.email,
         dateRegistered: Date.now(),
         clickType: 'lastlogin'
       }

       localStorage.setItem(USER_LOGGED_IN, JSON.stringify(userobj))

       console.log('showLogin 6 :', userLoginData);

       firebase.database
         .ref(`/user/${userobj.uid}`)
         .once('value')
         .then(data => {
           let isUser = data.val()

           if (isUser) {
             console.log('showLogin 7 :', userLoginData);
             firebase.database.ref('user-lastlogin').push(userLoginData)
              loggedInProfileActions(userobj.uid);
             dispatch({ type: LOGIN_SUCCESS, auth: userobj })
             dispatch({ type: GLP_LOGGED_COMPANY })
           } else {
             console.log('showLogin 8 :', userLoginData);
             // get date and time
             let timeInMs = Date.now()
             let datetime = new Date(timeInMs)
             // format date
             datetime = DATE_FORMAT.type(datetime, 'BADGE')

             let userID = firebase.database
               .ref()
               .child('users')
               .push().key
             var data = {
               companyID: selectClubb,
               companyName: 'Thinklead Ecosystem',
               dateRegistered: timeInMs,
               email: auth.user.email,
               firstName: splitNameFirst(auth.user.displayName),
               lastName: splitNameLast(auth.user.displayName),
               password: '',
               stringDateRegistered: datetime,
               userDescription: 'Public Contributer',
               uid: auth.user.uid,
               userID: userID,
               userType: 3,
               inviteSent: true,
               // companyID_userType: auth.user.selectClub + '_3',
               companyID_userType: selectClubb + '_3',
               photoURL: auth.user.photoURL
               // companyID: params.selectClub,
               // companyName: params.clubName
             }

             // Write the new rating data
             let updates = {}
             updates['/users/' + userID] = data
             updates['/user/' + auth.user.uid] = data

             firebase.database.ref().update(updates, function (error) {
               if (error) {
                 console.log('showLogin 9 :', updates);
                 // The write failed...
                 firebase.auth.signOut().then(() => {
                   localStorage.removeItem(USER_LOGGED_IN)
                   localStorage.removeItem(GLP_LOGGED_PROFILE)
                   localStorage.removeItem(GLP_LOGGED_COMPANY)

                   dispatch({
                     type: SHOW_TOAST,
                     variant: ERROR,
                     message: '' // error.message
                   })
                   return
                 })
               } else {
                 console.log('showLogin 10 :', userobj.uid);
                 updates
                 loggedInProfileActions(userobj.uid)

                 // firebase.database.ref('user-lastlogin').push(userLoginData)

                 // dispatch({ type: LOGIN_SUCCESS, auth: userobj })
                 // dispatch({ type: GLP_LOGGED_COMPANY})
                 console.log('black signInWithGoogle 2 :', userobj)
                 window.location.reload()

                // window.location.href = 'https://thinklead.app/'; 
                 // SIGN IN//////////////////////////////////////////////
                 // signInWithGoogle2();
                 // /////////////////////////////////////////////////////
                 console.log('black signInWithGoogle 3:', userobj)
                 // return
               }
             })
           }
         })
         .catch(function (error) {
           console.log('black signInWithGoogle err2:', error)
           firebase.auth.signOut().then(() => {
             localStorage.removeItem(USER_LOGGED_IN)
             localStorage.removeItem(GLP_LOGGED_PROFILE)
             localStorage.removeItem(GLP_LOGGED_COMPANY)

             dispatch({
               type: SHOW_TOAST,
               variant: ERROR,
               message: error.message
             })
             return
           })
         })
     })
     .catch(function (error) {
       console.log('black signInWithGoogle err3:', error)
       localStorage.removeItem(USER_LOGGED_IN)
       dispatch({ type: SHOW_TOAST, variant: ERROR, message: error.message })
     })
 }
}

export const resetUserPassword = params => {
  return async (dispatch, getState) => {
    await firebase.auth
      .sendPasswordResetEmail(params.email)
      .then(function () {
        dispatch({
          type: SHOW_TOAST,
          variant: INFO,
          message: 'A password reset link has been mailed to your email account.'
        })
      })
      .catch(function (error) {
        dispatch({ type: SHOW_TOAST, variant: ERROR, message: error.message })
      })
  }
}

const getCompanyID = email => {
  let compID
  // console.log('black inside getCompanyID', email)
  // ------------------------USERS
  firebase.database
    .ref('users').orderByChild('email').equalTo(email)
    .once('value').then((snapshot) => {
      snapshot.forEach((subSnapshot) => {
        let companyID = subSnapshot.companyID
        if (companyID) {
          compID = companyID
        }
      })
    })
  // ------------------------USER
  firebase.database
    .ref('user').orderByChild('email').equalTo(email)
    .once('value').then((snapshot) => {
      snapshot.forEach((subSnapshot) => {
        let companyID = subSnapshot.companyID
        if (companyID) {
          compID = companyID
        }
      })
    })
  // ------------------------SUBSCRIBED
  firebase.database
    .ref('subscribed').orderByChild('email').equalTo(email)
    .once('value').then((snapshot) => {
      snapshot.forEach((subSnapshot) => {
        let companyID = subSnapshot.companyID
        if (companyID) {
          compID = companyID
        }
      })
    })
  if (compID) {
    return compID
  }
}

export const signUpNewUsers = params => {
  console.log('black signUpNewUsers:', params)
  return async (dispatch, getState) => {
    await firebase.auth
      .createUserWithEmailAndPassword(params.email, params.password)
      .then(function (auth) {
        let uid = auth.user.uid
        // get date and time
        let timeInMs = Date.now()
        let datetime = new Date(timeInMs)
        // format date
        datetime = DATE_FORMAT.type(datetime, 'BADGE')

        let userobj = {
          uid: auth.user.uid,
          displayName: auth.user.displayName,
          email: auth.user.email,
          emailVerified: auth.user.emailVerified,
          refreshToken: auth.user.refreshToken,
          metadata: auth.user.metadata
        }

        let userLoginData = {
          uid: auth.user.uid,
          name: auth.user.displayName,
          email: auth.user.email,
          dateRegistered: Date.now(),
          clickType: 'lastlogin'
        }

        // console.log('black params.username:', params.username);

        if (params.username) {
          // console.log('black get companyid: ', this.getCompanyID(params.username));
        }

        let userID = firebase.database
          .ref()
          .child('users')
          .push().key

        let data = {
          // companyID: "-LjW7UI7e0uUikYrw-Vj", //"-LEiZPT-C2PyLu_YLKNU",
          // companyName: "Thinklead Ecosystem",
          dateRegistered: timeInMs,
          email: params.email,
          firstName: params.firstName,
          lastName: params.lastName,
          password: '',
          stringDateRegistered: datetime,
          userDescription: 'Standard User',
          uid: uid,
          points: 1,
          feedback: 0,
          feedbackOn: true,
          gfeedbackOn: true,
          userID: userID,
          userType: 8,
          inviteSent: true,
          agreeTCs: params.agreeTCs,
          companyID_userType: params.selectClub + '_8',
          companyID: params.selectClub,
          companyName: params.clubName
        }

        // Write the new rating data
        let updates = {}
        updates['/users/' + userID] = data
        updates['/user/' + uid] = data

        firebase.database.ref().update(updates, function (error) {
          if (error) {
            // The write failed...
            firebase.auth.signOut().then(() => {
              localStorage.removeItem(USER_LOGGED_IN)
              localStorage.removeItem(GLP_LOGGED_PROFILE)
              localStorage.removeItem(GLP_LOGGED_COMPANY)

              dispatch({
                type: SHOW_TOAST,
                variant: ERROR,
                message: error.message
              })
              return
            })
          } else {
            // If its public app - send verification email
            if (APP_TYPE === 'PUBLIC') {
              let actionCodeSettings = {
                url: window.location.origin
              }
              auth.user
                .sendEmailVerification()
                .then(function () {
                  firebase.auth.signOut().then(() => {
                    localStorage.removeItem(USER_LOGGED_IN)
                    localStorage.removeItem(GLP_LOGGED_PROFILE)
                    localStorage.removeItem(GLP_LOGGED_COMPANY)
                    dispatch({
                      type: SHOW_TOAST,
                      open: true,
                      variant: 'success',
                      message: 'You have signed up Successfully. Please log in with your details.'
                    })
                    window.location.href = 'https://harmony.thinklead.co.za/' ;
                    return
                  })
                    .catch(function (error) {
                      firebase.auth.signOut().then(() => {
                        localStorage.removeItem(USER_LOGGED_IN)
                        localStorage.removeItem(GLP_LOGGED_PROFILE)
                        localStorage.removeItem(GLP_LOGGED_COMPANY)

                        dispatch({
                          type: SHOW_TOAST,
                          variant: ERROR,
                          message: error.message
                        })
                        return
                      })
                    })
                })

              // Else allow user to log in
              // loggedInProfileActions(userobj.uid);
              firebase.database.ref('user-lastlogin').push(userLoginData)
              dispatch({ type: LOGIN_SUCCESS, auth: userobj })
              dispatch({ type: GLP_LOGGED_COMPANY })
              return
            }
          }


        // you can save the user data here.
        })
      })
      .catch(function (error) {
        firebase.auth.signOut().then(() => {
          localStorage.removeItem(USER_LOGGED_IN)
          localStorage.removeItem(GLP_LOGGED_PROFILE)
          localStorage.removeItem(GLP_LOGGED_COMPANY)

          dispatch({
            type: SHOW_TOAST,
            variant: ERROR,
            message: error.message
          })
          return
        })
      })
  }
}

export const authStateChanged = userobj => {
  return (dispatch, getState) => {
     loggedInProfileActions(userobj.uid);
    dispatch({ type: LOGIN_SUCCESS, auth: userobj })
    dispatch({ type: GLP_LOGGED_COMPANY })
  }
}

export const signOut = () => {
  return (dispatch, getState) => {
    firebase.auth.signOut().then(() => {
      localStorage.removeItem(USER_LOGGED_IN)
      localStorage.removeItem(GLP_LOGGED_PROFILE)
      localStorage.removeItem(GLP_LOGGED_COMPANY)
      delete axios.defaults.headers.common['Authorization']; //new line to secure login uid
      dispatch({ type: SIGNOUT_SUCCESS })
    })
  }
}
