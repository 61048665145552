import {
  FRESH,
  FRESH_ADD,
  SHOW_TOAST,
  FRESH_VIDEOS,
  GET_POE,
  GET_POINTS,
  CLEAR_POINTS
} from './../actions/types'
import firebase from '../firebase'
import { _toArrayUsers } from './commonActions'

export const insertNewUserdata = (params) => {
  let userData
  let database = firebase.database
  const updates = {}
  console.log('insertN---- 1: ', params)
  return (dispatch, getState) => {
    // Insert/replace in daylyThoughts collection
    updates['/dailyThoughts/' + params.thoughtid + '/points'] = params.points
    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'FRESH_ADD'
        })
      })
      .catch((err) => {
        dispatch({
          type: 'FRESH_ADD'
        })
      })
  }
}

export const insertNewVideoUserdata = (params) => {
  let userData
  let database = firebase.database
  const updates = {}
  console.log('insertNewVideoUserdata---- vids: ', params)
  return (dispatch, getState) => {
    // Insert/replace in daylyThoughts collection
    updates['/videos/' + params.thoughtid + '/points'] = params.points
    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'FRESH_VIDEOS'
        })
      })
      .catch((err) => {
        dispatch({
          type: 'FRESH_VIDEOS'
        })
      })
  }
}

function formatDate(date) {
  let d = new Date(date)


  let month = '' + (d.getMonth() + 1)


  let day = '' + d.getDate()


  let year = d.getFullYear()

  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }

  return [year, month, day].join('-')
}

const updateLeads = params => {
  console.log('updateLeads 1 : ', params)

  return (dispatch, getState) => {
    firebase.database
      .ref('dailyThoughts')
      .orderByChild('journalUserID')
      .equalTo(params.userID)
      .on('value', function (data) {
        if (data.val()) {
          let data = data.val()
          let receivedData = {}
          delete data.undefined
          if (Object.keys(data).length > 0) {
            let sortedData = _toArrayUsers(data)

            console.log('updateLeads data 4 : ', sortedData)

            sortedData.sort(function (a, b) {
              if (a.points > b.points) {
                // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return 1
              }
              if (a.points < b.points) {
                //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return -1
              }
              return 0
            })

            receivedData = { ...sortedData }

            receivedData = Object.assign({}, sortedData)
          }

          dispatch({
            type: GET_POE,
            poe: receivedData
          })
        } else {
          dispatch({
            type: GET_POE,
            poe: {}
          })
        }
      })
  }
}

// export const xxaddMorePoints = params => {
//   console.log('inside addMorePoints : ', params)
//   let database = firebase.database;
//   if (params.pointsCurrent) {
//       var totPoints = parseInt(params.pointsCurrent) + parseInt(params.pointsAdded);
//   }
//   else
//   {
//     totPoints = 0;
//   }
//   if (params.ypointsCurrent) {
//     var ytotPoints = parseInt(params.ypointsCurrent) + parseInt(params.pointsAdded);
//   }
//   else
//   {
//     ytotPoints = 0;
//   }

//   if (totPoints < 0) { totPoints = 0 }
//   if (ytotPoints < 0) { ytotPoints = 0 }
//   const updates = {};

//   const d = new Date();
//   const y = d.getFullYear();
//   const m = d.getMonth();
//   const day = d.getDate();
//   const fdate = formatDate(d); //y + '/' + m + '/' + day;

//   console.log('pinky ytotpoints ', ytotPoints);
//   console.log('pinky totpoints ', totPoints);
//   var pointsNote;

//   if (params.currentUser===params.userID) { //Points made by LEAD owner
//     pointsNote = " points added! You now have " + totPoints + " points."
//     }
//   else
//   { //Points made by other users comment on LEAD
//     pointsNote = " points added to " + params.firstName ;
//   }
//   if (totPoints) {
//     // this.updateLeads({
//     //   userID: params.userID
//     // })
//     var r = 0;
//     var kk, ds, nn;
//   return async (dispatch, getState) => {
//     //console.log('blue totPoints 1 : ', totPoints);
//         updates['/user/' + params.uid + '/points'] = totPoints;
//         updates['/users/' + params.userID + '/points'] = totPoints;
//         updates['/user/' + params.uid + '/pointsy'] = ytotPoints;
//         updates['/users/' + params.userID + '/pointsy'] = ytotPoints;
//         console.log('pointsPass 6 ', params.actionDesc);
//         if (params.actionDesc==="comment deleted" || params.actionDesc==="LEAD comment") {
//           //  updates['/dailyThoughts/' + params.leadID + '/points'] = totPoints;
//             //console.log('blue totPoints 1 : ', params);
//             console.log('pointsPass 7 ', params.actionDesc);
//         //  if (params.actionDesc==="LEAD comment") {
//             //UPDATE ALL LEADS (OR TOP 10) FOR THIS USER
//              var postRef = database.ref("dailyThoughts").orderByChild("journalUserID").equalTo(params.userID);
//               postRef.on("value", function (snapshot) {
//                 console.log('pointsPass 8 ');
//                 //console.log("update LEADS 2")
//                 console.log("update LEADS 4", r + " - " + snapshot.numChildren());
//                   snapshot.forEach(function(subChild) {
//                   nn = snapshot.numChildren() - 100;
//                   if (r > nn) {
//                    //console.log("update LEADS 5", r );
//                    // console.log("update LEADS 6", params.leadID)
//                    // database.ref("dailyThoughts").child(subChild.key).update({"points" : totPoints});
//                    console.log('pointsPass 9 ');
//                     updates['/dailyThoughts/' + subChild.key + '/points'] = totPoints;
//                   }
//                   r = r + 1;
//                 });
//               });
//               //  updates['/dailyThoughts/' + params.leadID + '/points'] = totPoints;
//              //}
//         }
//       //TRAIL OF POINTS RECORDED PORTFOLIO OF EVIDENCE
//       //updates['/pointsTrail/' + params.userID + '/' + params.trailID ] = {

//       updates['/pointsTrail/' + params.trailID ] = {
//           userID: params.userID,
//           fdate: fdate,
//           trailID: params.trailID,
//           companyName: params.companyName,
//           firstName: params.firstName,
//           lastName: params.lastName,
//           title: params.title,
//           actionType: params.actionType,
//           actionDesc: params.actionDesc,
//           pointsAdded: params.pointsAdded
//          };
//          await database
//           .ref()
//           .update(updates)
//           .then(() => {
//              dispatch({
//               type: SHOW_TOAST,
//               variant: "success",
//               message: params.pointsAdded > 0 ? params.pointsAdded + pointsNote :
//               'Task Re-opened or Post deleted - Points adjusted',
//               pos: "bottom"
//             });
//           })
//           .catch(err => {
//             dispatch({
//               type: "SHOW_TOAST",
//               open: true,
//               variant: "error",
//               message: "Error response: " + err.message
//             });
//             })
//          }
//       }
//     }

//  const xaddMorePoints = params => {
//   console.log('inside addMorePoints : ', params)
//   let database = firebase.database;
//   if (params.pointsCurrent) {
//       var totPoints = parseInt(params.pointsCurrent) + parseInt(params.pointsAdded);
//   }
//   else
//   {
//     totPoints = 0;
//   }
//   if (params.ypointsCurrent) {
//     var ytotPoints = parseInt(params.ypointsCurrent) + parseInt(params.pointsAdded);
//   }
//   else
//   {
//     ytotPoints = 0;
//   }

//   if (totPoints < 0) { totPoints = 0 }
//   if (ytotPoints < 0) { ytotPoints = 0 }
//   const updates = {};

//   const d = new Date();
//   const y = d.getFullYear();
//   const m = d.getMonth();
//   const day = d.getDate();
//   const fdate = formatDate(d); //y + '/' + m + '/' + day;

//   console.log('pinky ytotpoints ', ytotPoints);
//   console.log('pinky totpoints ', totPoints);
//   var pointsNote;

//   if (params.currentUser===params.userID) { //Points made by LEAD owner
//     pointsNote = " points added! You now have " + totPoints + " points."
//     }
//   else
//   { //Points made by other users comment on LEAD
//     pointsNote = " points added to " + params.firstName ;
//   }
//   if (totPoints) {
//     var r = 0;
//     var kk, ds, nn, xx, restmm, snapshotKidz;
//   return (dispatch, getState) => {
//     //console.log('blue totPoints 1 : ', totPoints);
//         updates['/user/' + params.uid + '/points'] = totPoints;
//         updates['/users/' + params.userID + '/points'] = totPoints;
//         updates['/user/' + params.uid + '/pointsy'] = ytotPoints;
//         updates['/users/' + params.userID + '/pointsy'] = ytotPoints;
//         console.log('pointsPass 6 ', params.actionDesc);
//         if (params.actionDesc==="comment deleted" || params.actionDesc==="LEAD comment") {
//             console.log("update LEADS 1")
//             //UPDATE ALL LEADS (OR TOP 10) FOR THIS USER
//             var postRef = database.ref("dailyThoughts").orderByChild("journalUserID").equalTo(params.userID);
//             console.log("update LEADS 2")
//               postRef.on("value", function (snapshot) {
//                 console.log("update LEADS 3")
//               // console.log("update LEADS 4", r + " - " + snapshot.numChildren());
//                   snapshot.forEach(function(subChild) {

//                   snapshotKidz = snapshot.numChildren()
//                   if (snapshotKidz > 50) {
//                     xx = snapshotKidz - 50;
//                     if (xx > 50) { nn = 50 }
//                     if (xx < 50 ) { nn = snapshotKidz - 50 }
//                     restmm = snapshotKidz - nn;
//                   }
//                   else
//                   {
//                     restmm = 0;
//                   }
//                   if (r > restmm) {
//                   // console.log("update LEADS 5", r );
//                     console.log("update LEADS subChild.key", subChild.key)
//                     console.log("update LEADS nn", nn)
//                     console.log("update LEADS r", r)

//                    // database.ref("dailyThoughts").child(subChild.key).update({"points" : totPoints});
//                     updates['/dailyThoughts/' + subChild.key + '/points'] = totPoints;
//                   }
//                   r = r + 1;
//                 });
//               });
//              }

//       updates['/pointsTrail/' + params.trailID ] = {
//           userID: params.userID,
//           fdate: fdate,
//           trailID: params.trailID,
//           companyName: params.companyName,
//           firstName: params.firstName,
//           lastName: params.lastName,
//           title: params.title,
//           actionType: params.actionType,
//           actionDesc: params.actionDesc,
//           pointsAdded: params.pointsAdded
//          };
//           database
//           .ref()
//           .update(updates)
//           .then(() => {
//              dispatch({
//               type: SHOW_TOAST,
//               variant: "success",
//               message: params.pointsAdded > 0 ? params.pointsAdded + pointsNote :
//               'Task Re-opened or Post deleted - Points adjusted',
//               pos: "bottom"
//             });
//           })
//           .catch(err => {
//             dispatch({
//               type: "SHOW_TOAST",
//               open: true,
//               variant: "error",
//               message: "Error response: " + err.message
//             });
//             })
//          }
//       }
//     }

export const addMorePoints = params => {
  console.log('update LEADS 1', params)
  let parleadPoints
  if (params.leadPoints) {
    // its ok
    parleadPoints = params.leadPoints
  } else {
    parleadPoints = 0
  }
  let database = firebase.database
  if (params.pointsCurrent) {
    var totPoints = parseInt(params.pointsCurrent) + parseInt(params.pointsAdded)
    var leadPoints = parseInt(parleadPoints) + parseInt(params.pointsAdded)
  } else {
    totPoints = 0
  }
  if (params.ypointsCurrent!==undefined) {
    var ytotPoints = parseInt(params.ypointsCurrent) + parseInt(params.pointsAdded)
  } else {
    ytotPoints = 0
  }

  if (totPoints < 0) {
    totPoints = 0
  }
  if (ytotPoints < 0) {
    ytotPoints = 0
  }
  const updates = {}

  const d = new Date()
  const y = d.getFullYear()
  const m = d.getMonth()
  const day = d.getDate()
  const fdate = formatDate(d) // y + '/' + m + '/' + day;
  var QueryContent = [];
  var arrayLength;
  
  console.log('pinky ytotpoints ', ytotPoints)
  console.log('pinky totpoints ', totPoints)
  let pointsNote
  console.log('update LEADS 2 ', params)
  if (params.currentUser === params.userID) { // Points made by LEAD owner
    pointsNote = ' points added! You now have ' + totPoints + ' points.'
  } else { // Points made by other users comment on LEAD
    pointsNote = ' points added to ' + params.firstName
  }
  if (totPoints) {
    console.log('update LEADS 3')
    let r = 0
    let kk; let ds; let nn; let xx; let restmm; let snapshotKidz
    return async (dispatch, getState) => {
      console.log('update LEADS 4', params.actionDesc)
      updates['/user/' + params.uid + '/points'] = totPoints
      updates['/users/' + params.userID + '/points'] = totPoints
      updates['/user/' + params.uid + '/pointsy'] = ytotPoints
      updates['/users/' + params.userID + '/pointsy'] = ytotPoints
     // updates['/users/' + params.uid + '/points'] = totPoints
      if (params.actionDesc === 'LEAD comment' || params.actionDesc === 'comment deleted') {
        database.ref('dailyThoughts').child(params.leadID).update({ leadPoints: leadPoints })
 
        console.log('update LEADS 5')
        // UPDATE ALL LEADS (OR TOP 10) FOR THIS USER
        let postRef = database.ref('dailyThoughts').orderByChild('journalUserID').equalTo(params.userID)
        console.log('update LEADS 6')
        // postRef.on("value", function (snapshot) {
        postRef.once('value')
          .then(async snapshot => {
            console.log('update LEADS 7')
            snapshot.forEach(function (subChild) {
              console.log('update LEADS 8')
              snapshotKidz = snapshot.numChildren()
              if (snapshotKidz > 50) {
                xx = snapshotKidz - 50
                if (xx > 50) {
                  nn = 50
                }
                if (xx < 50) {
                  nn = snapshotKidz - 50
                }
                restmm = snapshotKidz - nn
              } else {
                restmm = 0
              }
              if (r > restmm) {
                console.log('update LEADS 9')
                // leadUpdate.push(subChild.val().dailyThoughtID);

               // database.ref('dailyThoughts').child(subChild.val().dailyThoughtID).update({ points: totPoints })
                QueryContent.push('/dailyThoughts/' + subChild.val().dailyThoughtID + '/points');

                // updates['/dailyThoughts/' + subChild.val().dailyThoughtID + '/points'] = totPoints;
                console.log('update LEADS 10', totPoints)
              }
              r = r + 1
            })

            ///////////////////////////////////////////////
            console.log('QueryContent 1 : ', QueryContent[2])

            arrayLength = QueryContent.length;
      
            for (var i = 0; i < arrayLength; i++) {
              // console.log(QueryContent[i]);
              console.log('QueryContent 3 : ', QueryContent[i])
              updates[QueryContent[i]] = totPoints;
            }

            await database
            .ref()
            .update(updates)
            .then(() => {
              dispatch({
                type: SHOW_TOAST,
                variant: 'success',
                message: params.pointsAdded > 0 ? params.pointsAdded + pointsNote :
                  'Points adjusted - 1',
                pos: 'bottom'
              })
            })
            .catch(err => {
              dispatch({
                type: 'SHOW_TOAST',
                open: true,
                variant: 'error',
                message: 'Error response: ' + err.message
              })
            })

            ////////////////////////////////////////////////

          })
      }

      updates['/pointsTrail/' + params.trailID] = {
        userID: params.userID,
        fdate: fdate,
        trailID: params.trailID,
        companyName: params.companyName,
        firstName: params.firstName,
        lastName: params.lastName,
        title: params.title,
        actionType: params.actionType,
        actionDesc: params.actionDesc,
        pointsAdded: params.pointsAdded
      }

      await database
        .ref()
        .update(updates)
        .then(() => {
          dispatch({
            type: SHOW_TOAST,
            variant: 'success',
            message:
              params.pointsAdded > 0
                ? params.pointsAdded + pointsNote
                : 'Points adjusted ' +
                params.pointsAdded +
                ' points subtracted',
            pos: 'bottom'
          })
        })
        .catch((err) => {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error response: ' + err.message
          })
        })
    }
  }
}

export const likesPoints = params => {
  let database = firebase.database
  console.log('green blue 10 ', params)
  let parleadPoints
  if (params.leadPoints && params.leadPoints > 0) {
    // its ok
    parleadPoints = params.leadPoints
  } else {
    parleadPoints = 0
  }

  if (params.pointsCurrent) {
    if (params.pointsAdded > 0) {
      var totPoints = parseInt(params.pointsCurrent) + parseInt(1)
      var leadPoints = parseInt(parleadPoints) + parseInt(1)
    } else {
      var totPoints = parseInt(params.pointsCurrent) - parseInt(1)
      var leadPoints = parseInt(parleadPoints) - parseInt(1)
    }
    // var totPoints = parseInt(params.pointsCurrent) + parseInt(params.pointsAdded);
  } else {
    totPoints = 0
  }
  if (params.ypointsCurrent!==undefined) {
    var ytotPoints = parseInt(params.ypointsCurrent) + parseInt(params.pointsAdded)
  } else {
    ytotPoints = 0
  }

  if (totPoints < 0) {
    totPoints = 0
  }
  if (ytotPoints < 0) {
    ytotPoints = 0
  }
  const updates = {}

  const d = new Date()
  const y = d.getFullYear()
  const m = d.getMonth()
  const day = d.getDate()
  const fdate = formatDate(d) // y + '/' + m + '/' + day;
  var doQuery = false;
  var q = 0;
  var QueryContent = [];
  var arrayLength;

  console.log('pinky ytotpoints ', ytotPoints)
  console.log('pinky totpoints ', totPoints)
  let pointsNote = ''

  if (params.currentUser === params.userID) { // Points made by LEAD owner
    pointsNote = ' point added! You now have ' + totPoints + ' points.'
  } else { // Points made by other users comment on LEAD
    pointsNote = ' point added to ' + params.firstName
  }
  console.log('totPoints_is : ', totPoints)
  if (totPoints) {
    let r = 0
    let kk; let ds; let nn; let xx; let restmm; let snapshotKidz
    let leadUpdate = []

    console.log('greenytotpoints n : ', totPoints)
    console.log('greenytotpoints y : ', ytotPoints)

    return async (dispatch, getState) => {
      updates['/user/' + params.uid + '/points'] = totPoints
      updates['/users/' + params.userID + '/points'] = totPoints
      updates['/user/' + params.uid + '/pointsy'] = ytotPoints
      updates['/users/' + params.userID + '/pointsy'] = ytotPoints

      if (params.actionDesc === 'LEAD comment') {
        database.ref('dailyThoughts').child(params.leadID).update({ leadPoints: leadPoints })
        //database.ref('dailyThoughts').child(params.leadID).update({ points: totPoints })
        
        console.log('update LEADS 1')
        // UPDATE ALL LEADS (OR TOP 10) FOR THIS USER
        let postRef = database.ref('dailyThoughts').orderByChild('journalUserID').equalTo(params.userID)
        console.log('update LEADS 2')
        // postRef.on("value", function (snapshot) {
        postRef.once('value')
          .then(async snapshot => {
            console.log('update LEADS 3')
            snapshot.forEach(function (subChild) {
          //  for( const subChild of snapshot ){
              //const value = snapshot.val()

              // for (var i in snapshot.docs) {
              //   const subChild = snapshot.docs[i]

              snapshotKidz = snapshot.numChildren();

              // console.log('greenblueyello 10 1 ', subChild) 

              if (snapshotKidz > 50) {
                xx = snapshotKidz - 50
                if (xx > 50) {
                  nn = 50
                }
                if (xx < 50) {
                  nn = snapshotKidz - 50
                }
                restmm = snapshotKidz - nn
              } else {
                restmm = 0
              }
              if (r > restmm) {
                // leadUpdate.push(subChild.val().dailyThoughtID);

                // leadUpdate.push({"a": subChild.val().dailyThoughtID});

                // database.ref('dailyThoughts').child(subChild.val().dailyThoughtID).update({ points: totPoints })

                console.log('QueryContent 0  ')
                // database.ref('dailyThoughts').child(subChild.val().dailyThoughtID).update({ points: totPoints })

                //  database.ref('dailyThoughts').child(subChild.val().dailyThoughtID).update({ points: totPoints })

                // database.ref("dailyThoughts").child(subChild.val().dailyThoughtID).update({"leadPoints" : leadPoints});
                // updates['/dailyThoughts/' + subChild.val().dailyThoughtID + '/points'] = totPoints;
                q = q + 1;
                QueryContent.push('/dailyThoughts/' + subChild.val().dailyThoughtID + '/points');

              }
              r = r + 1
            }

            )
            ///////////////////////////////////////////////
            console.log('QueryContent 1 : ', QueryContent[2])

            arrayLength = QueryContent.length;
      
            for (var i = 0; i < arrayLength; i++) {
              // console.log(QueryContent[i]);
              console.log('QueryContent 3 : ', QueryContent[i])
              updates[QueryContent[i]] = totPoints;
            }
           //return;

            await database
            .ref()
            .update(updates)
            .then(() => {
              dispatch({
                type: SHOW_TOAST,
                variant: 'success',
                message: params.pointsAdded > 0 ? params.pointsAdded + pointsNote :
                  'Points adjusted - 1',
                pos: 'bottom'
              })
            })
            .catch(err => {
              dispatch({
                type: 'SHOW_TOAST',
                open: true,
                variant: 'error',
                message: 'Error response: ' + err.message
              })
            })

            ////////////////////////////////////////////////
          }
            
          )
      }

      if (doQuery) {
        updates[QueryContent] = totPoints; 
       // database.ref('dailyThoughts').child(subChild.val().dailyThoughtID).update({ points: totPoints })
      }

      console.log('QueryContent 1 : ', QueryContent[2])

      var arrayLength = QueryContent.length;
      console.log('QueryContent 2 : ', q)

      for (var i = 0; i < arrayLength; i++) {
        // console.log(QueryContent[i]);
        console.log('QueryContent 3 : ', QueryContent[i])
        updates[QueryContent[i]] = totPoints;
      }

      updates['/pointsTrail/' + params.trailID] = {
        userID: params.userID,
        fdate: fdate,
        trailID: params.trailID,
        companyName: params.companyName,
        firstName: params.firstName,
        lastName: params.lastName,
        title: params.title,
        actionType: params.actionType,
        actionDesc: params.actionDesc,
        pointsAdded: params.pointsAdded
      }

       await database
        .ref()
        .update(updates)
        .then(() => {
          dispatch({
            type: SHOW_TOAST,
            variant: 'success',
            message: params.pointsAdded > 0 ? params.pointsAdded + pointsNote :
              'Points adjusted - 1',
            pos: 'bottom'
          })
        })
        .catch(err => {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'Error response: ' + err.message
          })
        })
    }
  }
}

// const updateUserPoints = async(leadUpdate, totPoints) => {
//   let database = firebase.database;
//   console.log('updateleadUpdate 3 : ', leadUpdate);

//   for (let p = 0; p < leadUpdate.length; p++) {
//     console.log('updateleadUpdate 4 : ', leadUpdate);
//       await database.ref("dailyThoughts").child(leadUpdate[p]).update({"points" : totPoints});
//       console.log('updateleadUpdate 5 : ', leadUpdate[p]);
//      }
//      for(const userId of userIds) {
//       const user = await getUser(userId)
//       users.push(user);
//      }
// }

// export const clearRealPoints = params => {
//     return (dispatch, getState) => {
//             dispatch({
//               type: CLEAR_POINTS,
//               realpoints: {}
//         })
//     }
// }

function getP(props) {
  for (let x in props) {
    return props[x].points
  }
}

// export const getRealPoints = params => {
//    console.log('green getRealPoints 1 : ', params)
//    var finalpoints = 0;
//     return async(dispatch, getState) => {
//       await firebase.database
//         .ref('user')
//         .orderByChild('uid')
//         .equalTo(params.uid)
//         .on('value', function (data) {
//            if (data.val()) {
//             let alldata = data.val()
//             let receivedData = {}

//          if (Object.keys(alldata).length > 0) {
//           sortedData = alldata.points
//           let sortedData = _toArrayUsers(alldata)
//           sortedData.sort(function (a, b) {

//             if (a.points > b.points) {
//               return 1
//             }
//             if (a.points < b.points) {
//               return -1
//             }
//             return 0
//           })

//           receivedData = { ...sortedData }

//           receivedData = Object.assign({}, sortedData)
//           finalpoints = getP(receivedData)
//           console.log('green getRealPoints 2 : ', sortedData)

//            dispatch({
//               type: GET_POINTS,
//               realpoints: finalpoints
//             })
//           } else {
//             dispatch({
//               type: GET_POINTS,
//               realpoints: {}
//             })
//           }
//         }
//       })
//     }
//   }

export const getPoe = (params) => {
  console.log('poe data 1 : ', params)

  return (dispatch, getState) => {
    firebase.database
      .ref('pointsTrail')
      .orderByChild('userID')
      .equalTo(params.userID)
      .on('value', function (data) {
        if (data.val()) {
          let poedata = data.val()
          let receivedData = {}
          delete poedata.undefined
          if (Object.keys(poedata).length > 0) {
            let sortedData = _toArrayUsers(poedata)

            console.log('poe data 4 : ', sortedData)

            sortedData.sort(function (a, b) {
              if (a.firstName > b.firstName) {
                // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return 1
              }
              if (a.firstName < b.firstName) {
                //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return -1
              }
              return 0
            })

            receivedData = { ...sortedData }

            receivedData = Object.assign({}, sortedData)
          }

          dispatch({
            type: GET_POE,
            poe: receivedData
          })
        } else {
          dispatch({
            type: GET_POE,
            poe: {}
          })
        }
      })
  }
}

export const getNewUserdata = (params) => {
  let userData
  console.log('getNewUserdatax: ', params)

  return (dispatch, getState) => {
    if (params.uid) {
      firebase.database.ref(`user/${params.uid}`).on('value', function (data) {
        if (data.val()) {
          userData = data.val()
          dispatch({
            type: 'FRESH',
            name: userData.firstName,
            lastname: userData.lastName,
            points: userData.points,
            club: userData.companyName
          })
        } else {
          return (dispatch, getState) => {
            dispatch({
              type: 'FRESH',
              name: '',
              lastname: '',
              points: '',
              club: ''
            })
          }
        }
      })
    } else {
      return (dispatch, getState) => {
        dispatch({
          type: 'FRESH',
          name: '',
          lastname: '',
          points: '',
          club: ''
        })
      }
    }
  }
}
