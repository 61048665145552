import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Avatar, Chip, Grid
} from '@material-ui/core'

import {
  withStyles,
} from '@material-ui/core/styles'
import { LIGHTGREY } from "../../utils/color_utils";
import { PHOTO_PROFILE } from '../../utils/photo_utils'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
});

class SelectedUsers extends Component {
  render() {
    const { classes, participants } = this.props;
    const noOfParticipants = participants && Object.keys(participants).length > 0 ? `Selected Participants (${Object.keys(participants).length})` : '';

    return (
      <div className={classes.root}>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>{noOfParticipants}</Grid>
        </Grid>
        {participants && Object.keys(participants).map((participant, id) => {
          const labelId = `${participants[participant].firstName} ${participants[participant].lastName}`;

          return (
            <div>

              <Chip
                avatar={<Avatar alt={participants[participant].firstName} src={participants[participant].photoURL} />}
                label={labelId}
                key={participants[participant].userID}
                onDelete={e => {
                  this.props.handleSelected(participants[participant])
                }}
              />
            </div>

          );
        })}
      </div>
    )
  }
}

SelectedUsers.propTypes = {
  classes: PropTypes.object,
  participants: PropTypes.any,
}

const SelectedUsersStyled = withStyles(styles)(SelectedUsers)

export default (SelectedUsersStyled);
