import firebase from '../firebase'
import { GLP_LOGGED_COMPANY } from './types'

export const loggedInCompanyActions = companyID => {
  firebase.database.ref(`companies/${companyID}`).on('value', function (data) {
    if (data.val()) {
      let company = data.val()
      localStorage.setItem(GLP_LOGGED_COMPANY, JSON.stringify(company))
    }
  })
}

export const fetchCompanyData = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref(`companies/${params.companyID}`)
      .on('value', function (data) {
        if (data.val()) {
          let company = data.val()
          dispatch({ type: 'FETCHED_COMPANY_DATA', companydata: company })
        } else {
          dispatch({ type: 'FETCHED_COMPANY_DATA', companydata: {} })
        }
      })
  }
}
