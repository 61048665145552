import React from "react";
import "./FinalScope.css";
import { appendTest } from "../../store/actions/collectTestResults";
import {
  COLLECT_TESTS, SHOW_TOAST
} from "../../store/actions/types";
import { connect } from "react-redux";
import firebase from "../../store/firebase";
import { addMorePoints } from '../../store/actions/freshCardActions'
import { showToast } from '../../store/actions/toastAction'

class FinalScope extends React.Component {
  constructor(props) {
    super(props);
    console.log('black group id: ', props);
  }
  componentDidMount(){
    console.log('black COLLECT_TESTS 1: ', this.props);
    var isCompanyID;
    if (this.props.real_companyID) {
      isCompanyID = this.props.real_companyID;
    }
     else
    {
      isCompanyID = this.props.companyID;
    }
    if (this.props.publisherid !== this.props.personid) { //test isnt done by same publisher
    var passTest = parseInt(this.props.counterCorrectAnswers) / parseInt(this.props.counterCorrectAnswers + this.props.counterIncorrectAnswers)
    var msg;
    if (passTest < 0.5) {
      msg = 'You FAILED the test. Select CANCEL if you want to redo the test or select OK to submit.'
    }
    else
    {
      msg = 'You PASSED the test. Select CANCEL if you want to redo the test or select OK to submit.'
    }
    var pass;
    if (window.confirm(msg)) {
      // Save it!
      console.log('greeny Thing was saved to the database.');
      pass = true;
          var params = {
            type: COLLECT_TESTS,
            surveyid: this.props.surveyid,
            companyID: isCompanyID,
            personid: this.props.personid,
            counterCorrectAnswers: this.props.counterCorrectAnswers,
            surveyTitle: '',
            personName: this.props.personName,
            personLname: this.props.personLname,
            qlen: this.props.qlen
          };
        this.props.appendTest(params);
        console.log('finalscope props 1: ', this.props);
      } else {
      // Do nothing!
      console.log('greeny Thing was not saved to the database.');
      pass = false;
      }
     if (pass===true) {
    //   var params = {
    //     type: COLLECT_TESTS,
    //     surveyid: this.props.surveyid,
    //     companyID: isCompanyID,
    //     personid: this.props.personid,
    //     counterCorrectAnswers: this.props.counterCorrectAnswers,
    //     surveyTitle: '',
    //     personName: this.props.personName,
    //     personLname: this.props.personLname,
    //     qlen: this.props.qlen
    //   };
    // this.props.appendTest(params);
    // console.log('finalscope props 1: ', this.props);

    if (this.props.userdata.execmember===true) {
      var params = {
        uid: this.props.uid,
        userID: this.props.personid,
        pointsCurrent: this.props.points ? this.props.points : 0,
        ypointsCurrent: this.props.pointsy? this.props.pointsy : 0,
        pointsAdded: this.finalPoints(),
        company_id: this.props.userdata.companyName,
        companyName: this.props.userdata.companyName, 
        firstName: this.props.userdata.firstName,
        lastName: this.props.userdata.lastName,
        useremail: this.props.userdata.email,
        title: this.props.title,
        thoughtType: this.props.thoughtType,
        trailID: this.makeid(10),
        actionType: 'Test Passed',
        actionDesc: this.props.title + ' Test'
      }
      console.log('finalscope props 2: ', params);
      this.props.addMorePoints(params);
      console.log('inside showLead function A ', this.props)

      if (this.props.showLeadb) {
        this.props.showLeadb(this.props, params.pointsAdded);
        }	
    }
    // else
    // {
    //   var params = {
    //     type: SHOW_TOAST,
    //     open: true,
    //     variant: 'success',
    //     message: 'Only Executive Club Members can earn POINTS by completing tests.',
    //     pos: 'bottom'
    //   }

    //   this.props.showToast(params)
    //   return
    // }
     }
   // this.props.resetTest(this.props.surveyid, this.props.personid);
    }
  }
   
  // finalPoints = () => {
  //   var points;
  //   var fpoints;
  //   points = parseInt(this.props.counterCorrectAnswers) / parseInt(this.props.qlen);
  //   console.log('finalpoints points : ', points);
  //   fpoints = parseInt(points * 20);
  //   return fpoints;
  // }

    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
      }
      return result;
    }

  finalPoints = () => {
    var points;
    var fpoints;
    points = parseInt(this.props.counterCorrectAnswers) / parseInt(this.props.qlen);
    if (points >= 0.5) {
      fpoints = parseInt(20);
    }
    else
    {
      fpoints = parseInt(0);
    }
    console.log('finalpoints points : ', points);
    return fpoints;
   }

  render() {
    const { appendTest } = this.props;
    console.log('FinalScope: ', this.props);
    //setTimeout(console.log('Done'), 5000);
    var finalP;
    if (this.finalPoints() >= 0.5) {
      finalP = 'blue';  //PASSED
    }
    else
    {
      finalP = 'red'; //FAILED
    }
    //   var params = {
    //     uid: this.props.uid,
    //     userID: this.props.personid,
    //     pointsCurrent: this.props.points,
    //     pointsAdded: this.finalPoints()
    //   }
    //   console.log('FinalScope params: ', params);
    //  this.props.addMorePoints(params);

    return (
      <React.Fragment>
      { this.props.typeofSurvey==="T" ?
             <div style={{
                width:'100%', 
                align: 'center',
                backgroundColor: '#ffffff',
              }}>
        <div id="result" style={{ color: finalP }}>
          {parseInt(this.props.counterCorrectAnswers) / parseInt(this.props.counterCorrectAnswers + this.props.counterIncorrectAnswers) < 0.5 ? "FAILED!": "PASSED!" }
        </div>
        <div align="left" id="finalScope" style={{ color: finalP }}>Test Analysis:</div>
        <div id="result" style={{ color: finalP }}>
          Correct Answers: {this.props.counterCorrectAnswers}
        </div>
        <div id="result" style={{ color: finalP }}>
          Wrong Answers: {this.props.counterIncorrectAnswers}
        </div>
        <div id="result" style={{ color: finalP }}>
         Person who took test: {this.props.personName + ' ' + this.props.personLname}
        </div>
        <div id="result" style={{ color: finalP }}>
         of Club: {this.props.companyName}
        </div>
        <div id="result" style={{ color: finalP }}>
        <b> Points Added</b>: <div id="result" style={{ color: finalP, fontSize: '30px', }}>{this.finalPoints()}</div>
        </div>
      </div>
      :
      <div align="center">     
        <div id="result" style={{ color: finalP }}>
         Thank You for completing the survey.
        </div>
        <div id="result" style={{ color: finalP }}>
         Person who took test: {this.props.personid}
        </div>
        <div id="result" style={{ color: finalP }}>
         of company: {this.props.companyID}
        </div>
      </div>
      }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    collectTest: state.collectTest.collectTest
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    appendTest: params => dispatch(appendTest(params)),
    addMorePoints: params => dispatch(addMorePoints(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalScope);

