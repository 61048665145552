import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MicIcon from "@material-ui/icons/Mic";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import ReactCountdownClock from "react-countdown-clock";
import UploadFiles from "../UploadFiles";
import { showToast } from "../../store/actions/toastAction";

import {
  GREY,
  RED,
  LIGHTBLUE,
  GREEN,
  LIGHTGREY,
  DARK
} from "../../utils/color_utils";
import Mic from "../../assets/mic.png";

var AudioContex;
var context;
var audioBuffer = [];
var myArrayBuffer, nowBuffering, source;
var newBlob = new Blob();
var podcast_ID = "";

var audio_context;
var recorder;
var processor;

const styles = theme => ({
  recorderArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: DARK,
    borderRadius: "6px",
    color: "#fff",
    height: "auto",
    padding: "16px",
    minHeight: "280px",
    textAlign: "center"
  },
  micStyles: {
    width: "auto",
    height: "80px"
  },
  timeobj: {
    width: "100%",
    padding: "8px 16px",
    fontSize: "36px",
    textAlign: "center",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginLeft: "-38px"
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  button: {
    margin: "8px"
  }
});

class AudioRecorder extends React.Component {
  state = {
    audioBuffer: [],
    timeobj: "",
    url: "",
    startrecord: false,
    paused: true,
    podcastuploaded: false,
    startfileupload: false,
    podcastname: ""
  };

  removeItems = () => {
    this.setState({
      ...this.state,
      url: "",
      audioBuffer: [],
      startrecord: false,
      paused: true
    });
  };
  //   recorder js functions
  init = () => {
    var self = this;

    try {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
      window.URL = window.URL || window.webkitURL;

      // audio_context = new AudioContext;
      audio_context = null;
      if (typeof AudioContext !== "undefined") {
        audio_context = new window.AudioContext();
      } else if (typeof webkitAudioContext !== "undefined") {
        audio_context = new window.webkitAudioContext();
      } else {
        // this.__log("No web audio support in this browser!");
        alert("No web audio support in this browser!");
        return;
      }

      processor = audio_context.createScriptProcessor(4096, 2, 2);
      processor.connect(audio_context.destination);
    } catch (e) {
      // this.__log('No web audio support in this browser! ');
      alert("No web audio support in this browser!");
    }

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(this.startUserMedia)
        .catch(function(e) {
          var message = {
            NotAllowedError: "Please enabled your browser Microphone!",
            AbortError: "Recording aborted unexpectedly by device!",
            NotReadableError:
              "Audio recording denied access by web page or proxy!",
            SecurityError: "Audio recording not supported!"
          };
          // self.stopRecording();
          self.removeRecordings();

          var params = {
            type: "SHOW_TOAST",
            open: true,
            variant: "warning",
            message:
              message[e.name] ||
              "Audio recording denied access by web page or proxy!"
          };

          // show error toast
          self.props.showToast(params);
        });
    } else if (navigator.getUserMedia) {
      navigator.getUserMedia({ audio: true }, this.startUserMedia, function(e) {
        // this.__log("No live audio input");
      });
    } else if (navigator.webkitGetUserMedia) {
      navigator.webkitGetUserMedia(
        { audio: true },
        this.startUserMedia,
        function(e) {
          // this.__log("No live audio input");
        }
      );
    } else {
      // this.__log("No user media support");
    }
  };

  createDownloadLink = () => {
    var self = this;
    recorder &&
      recorder.exportWAV(function(blob) {
        var url = URL.createObjectURL(blob);
        var newaudioBuffer = [];
        newaudioBuffer.push(blob);

        self.setState({
          ...self.state,
          startrecord: false,
          paused: true,
          url: url,
          audioBuffer: newaudioBuffer
        });
      });
  };

  removeRecordings = () => {
    audioBuffer = [];

    this.setState({
      ...this.state,
      url: "",
      audioBuffer: [],
      startrecord: false,
      paused: true,
      podcastuploaded: false,
      startfileupload: false
    });

    this.props.onFileDeleted();
  };

  startUpload = () => {
    this.setState({
      ...this.state,
      podcastuploaded: false,
      startfileupload: true
    });
  };

  startUserMedia = stream => {
    window.localStream = stream;

    var input = audio_context.createMediaStreamSource(stream);

    recorder = new window.Recorder(input, processor);

    audio_context.resume();
    recorder && recorder.record();
  };

  startRecording = () => {
    audioBuffer = [];
    this.setState({
      ...this.state,
      startrecord: true,
      audioBuffer: [],
      paused: false,
      url: "",
      podcastuploaded: false,
      startfileupload: false
    });

    this.props.onFileDeleted();

    this.init();
  };

  startRecordingAftrInit = () => {
    audio_context.resume();
    recorder && recorder.record();
    // this.__log("Recording...");
  };

  stopRecording = () => {
    // var button = this.$.stoprecord;

    recorder && recorder.stop();
    // stop timer
    clearInterval(this.timer);

    this.setState({
      ...this.state,
      startrecord: false,
      paused: true
    });

    if (window.localStream) {
      window.localStream.getAudioTracks()[0].stop();
    }

    // create WAV download link using audio data blob
    this.createDownloadLink();

    if (recorder) {
      recorder.clear();
    }

    audio_context.close();
    audio_context = null;
    processor = null;
  };

  uploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      url: url,
      podcastname: filename,
      podcastuploaded: true,
      startfileupload: false
    });
    this.props.uploadInProgress(false);
    this.props.onFileUploadComplete(url, filename);
  };

  uploadInProgress = status => {
    this.props.uploadInProgress(status);
  };

  render() {
    const {
      timeobj,
      url,
      startrecord,
      paused,
      podcastuploaded,
      audioBuffer,
      uploadfile,
      startfileupload
    } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div>
          <div className={classes.recorderArea}>
            <div style={{ textAlign: "center" }}>
              <img
                className={classes.micStyles}
                src={Mic}
                alt="Recorder Image"
              />
              <div>
                <div id="msg">
                  <span>Max 2 minutes</span>
                </div>
                {startrecord ? (
                  <div id="timeobj" className={classes.timeobj}>
                    <ReactCountdownClock
                      seconds={120}
                      color="#e0e0e0"
                      alpha={0.9}
                      size={80}
                      paused={paused}
                      onComplete={this.createDownloadLink.bind(this)}
                    />
                  </div>
                ) : null}
                <div style={{ marginTop: "10px", width: "100%" }}>
                  {!startrecord ? (
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{
                        backgroundColor: LIGHTBLUE,
                        color: "#fff"
                      }}
                      onClick={this.startRecording.bind(this)}
                    >
                      <MicIcon className={classes.leftIcon} />
                      RECORD
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{
                        backgroundColor: RED,
                        color: "#fff"
                      }}
                      onClick={this.stopRecording.bind(this)}
                    >
                      <StopRoundedIcon className={classes.leftIcon} />
                      STOP
                    </Button>
                  )}
                </div>
              </div>
              <div>
                {url ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%"
                    }}
                  >
                    <div id="recordingslist" style={{ width: "100%" }}>
                      <audio controls src={url}>
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    <div id="msg">
                      {startfileupload && !podcastuploaded ? (
                        <UploadFiles
                          onUploadComplete={this.uploadFinished.bind(this)}
                          onUploadProgress={this.uploadInProgress.bind(this)}
                          uploadtype="podcast"
                          uploadfile={startfileupload}
                          filetoupload={audioBuffer}
                        />
                      ) : (
                        <div>
                          {!startfileupload && !podcastuploaded ? (
                            <span>Click to save audio when done recording</span>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div style={{ width: "100%" }}>
                      {!startfileupload && !podcastuploaded ? (
                        <Button
                          variant="contained"
                          className={classes.button}
                          style={{ backgroundColor: GREEN, color: "#fff" }}
                          onClick={this.startUpload.bind(this)}
                        >
                          <CloudUploadIcon className={classes.leftIcon} />
                          SAVE AUDIO
                        </Button>
                      ) : null}

                      <Button
                        variant="contained"
                        className={classes.button}
                        style={{ backgroundColor: RED, color: "#fff" }}
                        onClick={this.removeRecordings.bind(this)}
                      >
                        <CancelIcon className={classes.leftIcon} />
                        DELETE
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const AudioRecorderStyled = withStyles(styles)(AudioRecorder);

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AudioRecorderStyled);
