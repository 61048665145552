import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Paper,
	TextField,
	InputBase,
	Fab,
	Typography,
	Grid,
	InputAdornment,
	MenuItem,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import gql from 'graphql-tag';
import { graphql, ApolloProvider } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { QUERY_LIST_USERS, QUERY_LIST_VIDEOS, QUERY_SEARCH_USERS, QUERY_SEARCH_VIDEOS } from './queries';
const styles = (theme) => ({
	paper: {
		padding: '0 20px 0 0',
		margin: '20px 10px'
	},
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#ffffff',
		width: '100%'
	},
	results: {
		display: 'block',
		margin: 40,
		width: '100%'
	},
	input: {
		flex: '1',
		display: 'flex',
		padding: '13px 10px',
		margin: '6px 15px',
		borderBottom: '1px solid #dddddd'
	},
	textField: {
		flexBasis: 200
	},
	margin: {
		margin: theme.spacing.unit
	},
	searchInput: {
		padding: 20
	},
	searchText: {
		//color: '#ffffff !important',
		fontSize: 20
	},
	fab: {
		margin: theme.spacing.unit,
		'&:hover': {
			backgroundColor: 'rgb(255, 255, 255, .8)'
		}
	},
	container: {
		padding: 10,
		borderBottom: '1px solid #ddd'
	},
	title: {
		fontSize: 18
	},
	description: {
		fontSize: 15,
		color: 'rgba(0, 0, 0, .5)'
	},
	img: {
		margin: 'auto',
		display: 'block',
		maxWidth: '100%',
		maxHeight: '100%'
	}
});

const ranges = [
	{
		value: 'messages',
		label: 'Messages'
	},
	{
		value: 'articles',
		label: 'Articles'
	},
	{
		value: 'videos',
		label: 'Videos'
	},
	{
		value: 'podcasts',
		label: 'Podcasts'
	},
	{
		value: 'users',
		label: 'Users'
	}
];
class VideoSearch extends Component {
	state = {
		searchQuery: '',
		searchCategory: ''
	};

	onChange = (e) => {
		const value = e.target.value;
		this.handleFilter(value);
	};
	handleFilter = debounce((val) => {
		this.props.onSearch(val);
	}, 250);

	componentDidMount() {
		const { listVideos } = this.props.data;
		// console.log('getListVideos', listVideos);
	}
	handleChange = (prop) => (event) => {
		this.setState({ [prop]: event.target.value });
	};
	render() {
		const { classes } = this.props;
		const { listVideos } = this.props.data;
		return listVideos ? (
			<React.Fragment>
				<Paper className={classes.paper}>
					<InputBase
						className={classes.input}
						onChange={this.onChange.bind(this)}
						placeholder="Enter your videos search here..."
					/>

					<Divider />
				</Paper>

				<Grid container spacing={16}>
					{this.props.render(listVideos)}
				</Grid>
			</React.Fragment>
		) : null;
	}
}

const VideoSearchStyled = withStyles(styles)(VideoSearch);

/**
 * 
 * <div class={classes.results}>
							<Typography variant="h5">Search Results ({listUsers.users.length}) </Typography>
							{!!loading && <p>Searching ...</p>}
							{!loading && !listUsers.users.length && <p>Sorry, no results.</p>}
							<List>
								{!loading &&
									listUsers.users.map((user, index) => (
										<ListItem alignItems="flex-start" divider={true} button>
											<ListItemAvatar>
												<Grid item md={2} xs={3}>
													<img
														className={classes.img}
														src="http://www.codecovers.eu/assets/img/modules/materialadmin/img11.jpg?1422538629"
													/>
												</Grid>
											</ListItemAvatar>
											<ListItemText
												primary="Post Title"
												secondary={
													<React.Fragment>
														<Typography gutterBottom component="h2" color="textPrimary">
															Subtitle | Date
														</Typography>
														<Typography component="subtitle2" color="textPrimary">
															Author/Journal User
														</Typography>
														{" — I'll be in your neighborhood doing"}
													</React.Fragment>
												}
											/>
										</ListItem>
									))}
							</List>
						</div>
 */

export default compose(
	graphql(QUERY_LIST_VIDEOS, {
		options: (data) => ({
			fetchPolicy: 'cache-and-network'
		}),
		props: (props) => ({
			onSearch: (searchQuery) => {
				searchQuery.toLowerCase();
				return props.data.fetchMore({
					query: searchQuery === '' ? QUERY_LIST_VIDEOS : QUERY_SEARCH_VIDEOS,
					variables: {
						searchQuery
					},
					updateQuery: (previousResult, { fetchMoreResult }) => ({
						...previousResult,
						listVideos: {
							...previousResult.listVideos,
							videos: fetchMoreResult.listVideos.videos
						}
					})
				});
			},
			data: props.data
		})
	})
)(VideoSearchStyled);
