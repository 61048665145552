import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { resetUserPassword } from "../../store/actions/authActions";
import { connect } from "react-redux";
import { SHOW_LOADER, HIDE_LOADER } from "../../store/actions/types";
import { hideLoader, showLoader } from "../../store/actions/loaderActions";
import { withStyles } from "@material-ui/core/styles";

const style = {
  forgotPassword: {
    marginRight: '5px',
    textDecoration: 'underline',
    color: '#2980B9',
    '&:hover': {
      cursor: 'pointer',
    }
  }
}

//===============
class ForgotPassword extends React.Component {

  //---------------
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      open: false
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleClose = this.handleClose.bind(this);
  };

  //---------------
  handleClickOpen = (e) => {
    this.setState({
      open: true
    });
  }

  //---------------
  resetPassword = (e) => {
    e.preventDefault();

    this.props.resetPassword({
      email: this.state.emailAddress,
      actionCodeSettings: {
        url: window.location.origin
      }
    });

    this.setState({
      open: false
    });

  }

  //---------------
  handleClose = (e) => {
    this.setState({
      open: false
    });

    this.props.showLoader({ type: HIDE_LOADER });
  }

  //---------------
  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  };

  //---------------
  render(props) {
    const { classes } = this.props;

    return (
      <div>
        <span className={classes.forgotPassword} style={{}} onClick={this.handleClickOpen}>
          Forgot Password?
        </span>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Recover Password</DialogTitle>          
          <DialogContent>
            <DialogContentText>
              Please enter email address associated to account:
            </DialogContentText>
            <TextField
              //autoFocus={true}
              margin="dense"
              id="name"
              value={this.state.emailAddress}
              onChange={this.handleChange("emailAddress")}
              placeholder="email"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
            <Button onClick={this.resetPassword} color="secondary" variant="outlined" >
              Reset
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

//===============
const mapDispatchToProps = dispatch => {
  return {
    resetPassword: params => dispatch(resetUserPassword(params)),
    showLoader: params => dispatch(showLoader(params)),
    hideLoader: params => dispatch(hideLoader(params))
  };
};

const ForgotPasswordModule = withStyles(style)(ForgotPassword);

//===============
export default connect(
  null,
  mapDispatchToProps
)(ForgotPasswordModule);