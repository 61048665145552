import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CreateIcon from '@material-ui/icons/Create'
import MoreIcon from '@material-ui/icons/More'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles } from '@material-ui/core/styles'
import RemoveAttachmentDialog from '../Dialogs/RemoveAttachmentDialog'
import PostActivityDialog from '../Dialogs/PostActivityDialog'
import AddModal from '../Dialogs/AddModal'
import { RED } from '../../utils/color_utils'
import { deletePosts } from '../../store/actions/postsActions'
import { fetchPostAnalytics } from '../../store/actions/analyticsActions'
import { addMorePoints } from '../../store/actions/freshCardActions'

const ITEM_HEIGHT = 48
const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 2
  }
})
const dialogtypes = {
  thoughts: 'Post',
  articles: 'Article',
  podcasts: 'Podcast',
  videos: 'Video'
}

class MoreMenu extends React.Component {
  state = {
    anchorEl: null,
    anchorElDialog: null,
    openDeleteDialog: null,
    openPostActivityDialog: null,
    postActivity: {
      likesCount: 0,
      commentsCount: 0,
      totalEngagement: 0,
      username: '',
      title: '',
      postID: ''
    },
    opendialog: null,
    dialogname: ''
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleClose = () => {
    this.setState({ anchorEl: null })
  };

  _getCount(itms) {
    let res = 0
    res = itms == undefined ? 0 : Object.keys(itms).length
    return res
  }

  _getLikesCount(itms) {
    let res = 0
    res = itms == undefined ? 0 : itms
    return res
  }

  handleModalClose = () => {
    this.setState(state => ({
      opendialog: false,
      dialogname: ''
    }))
  };

  openModal = () => {
    this.setState(state => ({
      opendialog: true,
      dialogname: dialogtypes[this.props.posttype],
      anchorEl: null
    }))
  };

  postAnalytics = () => {
    let likesCount = this._getLikesCount(this.props.post.countLikes)
    let commentsCount = this._getCount(this.props.post.comments)
    let totalEngagement = likesCount + commentsCount
    let username = this.props.post.journalUserName || this.props.post.userName
    let posttitle = this.props.post.title
    let postID =
      this.props.post.dailyThoughtID ||
      this.props.post.newsID ||
      this.props.post.podcastID ||
      this.props.post.videoID ||
      ''

    let postActivity = {
      likesCount: likesCount,
      commentsCount: commentsCount,
      totalEngagement: totalEngagement,
      username: username,
      title: posttitle,
      postID: postID

    }

    this.setState({
      anchorEl: null,
      openPostActivityDialog: true,
      postActivity: postActivity
    })

    // Fetch posts activity
    this.props.fetchPostAnalytics(postID)
  };

  handleCloseActivityDialog = () => {
    this.setState({
      openPostActivityDialog: null
    })
  };

  handleOpenDeleteDialog = e => {
    this.setState({
      openDeleteDialog: !this.state.openDeleteDialog,
      anchorElDialog: e.currentTarget,
      anchorEl: null
    })
  };

  makeid() {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
     // for ( var i = 0; i < length; i++ ) {
      for ( var i = 0; i < charactersLength; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
  }

  removePost = () => {
    let params = {
      posttype: this.props.posttype,
      post: this.props.post
    }

    // Delete Posts
    this.props.deletePosts(params)

       // DELETE POINTS///////////////////////////
      // if (this.params.editing === false) {
        let paramsb = {
          uid: params.post.uid,
          userID: params.post.journalUserID,
          pointsCurrent: params.post.points,
          ypointsCurrent: params.post.pointsy ? params.post.pointsy : 0,
          pointsAdded: -10,
          trailID: this.makeid(10), //params.post.dailyThoughtID,
          companyName: params.post.companyName,
          firstName: params.post.journalUserName,
          lastName: '',//params.post.lastName,
          title: params.post.title,
          actionType: 'Forum post deleted',
          actionDesc: 'Forum post deleted'
        }
        console.log('postactions 5 : ', paramsb)
        this.props.addMorePoints(paramsb)
     // }

    this.setState({
      openDeleteDialog: !this.state.openDeleteDialog,
      anchorElDialog: null,
      anchorEl: null
    })
  };

  render() {
    const { anchorEl } = this.state
    const { match, classes, userdata } = this.props
    const open = Boolean(anchorEl)
    // 3: true,
    // 4: true,
    // 7: true,
    // 9: true,
    // 10: true,
    // 11: true,
    // 12: true,
    // 14: true
    const showEditable = match.path === '/myposts' || userdata.userType === 7 || userdata.userType === 14 || userdata.userType === 12 || userdata.userType === 11 || userdata.userType === 10 || userdata.userType === 9 || userdata.userType === 7 || userdata.userType === 4 || userdata.userType === 3
    return (
      showEditable && (
        <span
        style={{
          whiteSpace: 'nowrap', 
          flexWrap: 'nowrap'
        }}
        >
          <IconButton
            aria-label='More'
            aria-owns={open ? 'long-menu' : undefined}
            aria-haspopup='true'
            onClick={this.handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}
          >
            <MenuItem onClick={this.openModal}>
              <CreateIcon className={classes.icon} fontSize='small' />
              &nbsp;Edit
            </MenuItem>
            <MenuItem onClick={this.postAnalytics}>
              <MoreIcon className={classes.icon} fontSize='small' />
              &nbsp;Post Activity
            </MenuItem>
            <MenuItem onClick={this.handleOpenDeleteDialog}>
              <DeleteIcon
                className={classes.icon}
                fontSize='small'
                style={{ color: `${RED}` }}
              />
              &nbsp;Delete
            </MenuItem>
          </Menu>
          {this.state.openDeleteDialog ? (
            <RemoveAttachmentDialog
              attachmentType={this.props.posttype || ''}
              onClickProceed={this.removePost}
              toggleOpenDeleteAttachments={this.handleOpenDeleteDialog}
              open={this.state.openDeleteDialog}
              anchorEl={this.state.anchorElDialog}
              thought={this.props.thought}
            />
          ) : null}
          {this.state.openPostActivityDialog ? (
            <PostActivityDialog
              open={this.state.openPostActivityDialog}
              postActivity={this.state.postActivity}
              onClosePostActivity={this.handleCloseActivityDialog.bind(this)}
            />
          ) : null}
          {this.state.opendialog ? (
            <AddModal
              handleModalClose={this.handleModalClose.bind(this)}
              dialogname={this.state.dialogname}
              opendialog={this.state.opendialog}
              editing={true}
              post={this.props.post}
              posttype={this.props.posttype}
              {...this.props}
            />
          ) : null}
        </span>
      )
    )
  }
}

MoreMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  posttype: PropTypes.string.isRequired,
  post: PropTypes.object.isRequired
}

const MoreMenuStyled = withStyles(styles)(MoreMenu)

const mapDispatchToProps = dispatch => {
  return {
    deletePosts: params => dispatch(deletePosts(params)),
    fetchPostAnalytics: params => dispatch(fetchPostAnalytics(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
  }
}

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(MoreMenuStyled)
)
