
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ThoughtsCard from '../Cards/ThoughtsCard'
import LeadsList from '../Cards/LeadsList'
import RemoveAttachmentDialog from '../Dialogs/RemoveAttachmentDialog'
import AddToPLDPDialog from '../Dialogs/AddToPLDPDialog'
import Progressbar from '../ProgressBar'
import MediaPlayer from '../Dialogs/MediaPlayer'
// import CompSelect from "../categories/compSelect";
// import SubCompSelect from "../categories/subcompSelect";
import { storeSubCompetency } from '../../store/actions/subcompActions'
import { STORE_SUBCOMPETENCYID } from '../../store/actions/types'
import Typography from '@material-ui/core/Typography'
import CategoriesSelect from '../../components/categories/categoriesSelect'
import { HIDE_COMMENTS_BUTTON, ILEAD_THOUGHTS, PUBLIC_COMPANY_ID, ILEAD_BLEADS, ILEAD_LEADS2, ILEAD_LEADS3, ILEAD_LEADS4, APP_TYPE, ILEAD_THOUGHTS_REQ, ORG_THOUGHTS } from '../../store/actions/types'
import FilterListIcon from '@material-ui/icons/FilterList'
//import MenuItem from '@material-ui/core/MenuItem'
//import FormControl from '@material-ui/core/FormControl'
//import Select from '@material-ui/core/Select'
//import GlobalforumTutorial from '../../pages/PointsSurveys/GlobalforumTutorial'
import GenTutorial from '../../pages/PointsSurveys/GenTutorial'
import { MenuItem, Input, FormControl, Select, InputLabel } from '@material-ui/core';
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { fetchThoughts, getTrendingLeads } from '../../store/actions/thoughtsActions'

const styles = (theme) => ({
  cardstyles: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    position: 'relative'
  },
  leadtrends: {
    color: "#515A5A",
    fontSize: 14,
    border: "0px",
    backgroundColor: '#F2F3F4',
    padding: 1,
    borderRadius: 2,
    align: 'left'
  },
  cleadtrends: {
    color: "#515A5A",
    fontSize: 14,
    border: "0px",
   // backgroundColor: '#F2F3F4',
    backgroundColor: '#ffffff',
    padding: 1,
    borderRadius: 2,
    align: 'left',
    marginLeft: '70px'
  },
  cats: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    align: 'left',
    alignItems: 'left',
    justifyContent: 'left',
    alignContent: 'left',
    marginLeft: isMobile ? '10px' : '20px'
  },
  trends: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    align: 'right',
    alignItems: 'left',
    justifyContent: 'left',
    alignContent: 'left',
    marginLeft: isMobile ? '50px' : '600px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginRight: theme.spacing(2)
  }
})

const basiclayout = {
  color: "#515A5A",
  fontSize: 14,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}

const backGlobal = {
  marginLeft: '150px',
  color: "#656152",
  cursor: "pointer",
  hover :{
    color: "#ffffff"
  },
}

class ThoughtsView extends React.Component {
  constructor(props){
    super(props)
    this.showLead = this.showLead.bind(this)
    console.log('organisational compponentDidMount 11 : ', this.props.thoughts)  
    console.log('organisational compponentDidMount 12 : ', this.props.thoughtsb) 
    console.log('greenfilteredPosts 14 : ', this.props) 

    if (this.props.thoughtsb && this.props.location.pathname==='/organisational') {
      this.reloadProps();
    }
   // this.handleScroll = this.handleScroll.bind(this)
   }
  //}
  state = {
    openmediaplayer: false,
    mediatype: 'podcast',
    media: null,
    openAddPLDP: false,
    attachmentType: '',
    thoughtToAdd: {},
    subcompetencyid: '',
    filter: 'none',
    fresh_thought: '',
    lthoughts: {},
   // loadThoughts: true
    minData: 12,
    // backgroundColor: '#8E8887'
   }
 // };

   handleScroll = () => {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const windowBottom = Math.round(windowHeight + window.pageYOffset)
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 12
      })
      this.fetchData()
    }
  }


  handleCompetencyChange = (event, name) => {
    this.setState({ competencyid: name.props.value })
  };

  handleFilterBy = (e) => {
    this.setState({ filter: e.target.value })
  }

  reloadProps = async() => {
    //  this.props.thoughts = this.props.thoughtsb
      var params = {
        type: ORG_THOUGHTS,
        companyID: this.props.userdata.companyID,
        orderChild: "publish_status",
        equalTo: this.props.userdata.companyID + "_approved",
        minData: 12
      };
     await this.props.fetchThoughts(params);
  }

  componentWillReceiveProps(nextProps) {
    console.log('organisational compponentDidMount 8 : ', this.props) 
    
   // console.log('test logged in user 0 ', nextProps.userdata.email ? nextProps.userdata.email : null);
   // console.log('test logged in user 1 ', this.props.userdata.email ? this.props.userdata.email : null); 
    if (nextProps.userdata.email && this.props.userdata.email) {
     if (nextProps.userdata.email !== this.props.userdata.email) {
      window.location.reload(false);
     // nextProps = this.props
     }
    }
    if (this.props !== nextProps) {
    // console.log('test logged in user 2 ', nextProps.userdata.email ? nextProps.userdata.email : null); 
    }
    

    if(this.props.xthoughts) {
      this.setState({
        lthoughts: this.props.xthoughts
      })
    }

   }

  //  shouldComponentUpdate(nextProps, newState) {
  //   return this.props.thoughts.points === nextProps.thoughts.points; // use === to compare types too!
  //  }

  handleSubCompetencyChange = (event, name) => {
    this.setState({ subcompetency: name.props.children })
    this.setState({ subcompetencyid: name.props.value })

    // Redux add
    let params = {
      type: STORE_SUBCOMPETENCYID,
      cvalue: name.props.value,
      scompname: name.props.children
    }
    this.props.storeSubCompetency(params)
  };

  OpenMediaPlayer = (media, mediatype) => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media,
      mediatype: mediatype
    })
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    })
  };

  handleOpenAddToPLDPDialog = (thought) => {
    this.setState((prevState) => ({
      openAddPLDP: true,
      thoughtToAdd: thought
    }))
  };

   getThought(props) {
    for (let x in props) {
      return props[x].subcompetencyid
      break
    }
  }

  getSubcomp = (props) => {
    for (let x in props) {
      // // console.log('getSubcomp: ', props[x])
      return props[x].subcompetencyid
      // return props[x]
    }
  }

  GfetchData = () => {
    let equalTo = ''
    // CORPORATE I-LEAD
    // equalTo =
    //   APP_TYPE === 'CORPORATE'
    //     ? this.props.userdata.companyID + '_ilead_approved'
    //     : PUBLIC_COMPANY_ID + '_approved'
    var x = 12
    let params = {
      type: ILEAD_THOUGHTS_REQ,
      companyID: this.props.userdata.companyID,
      orderChild: 'publish_status',
      equalTo: PUBLIC_COMPANY_ID + '_approved',
      //this.props.userdata.companyID + "_approved",
      minData: this.state.minData //x //parseInt(12)
    }

    this.props.fetchThoughts(params)

    console.log('greenthoughts ThoughtsView GfetchData : ', this.props.thoughts);

    setTimeout(() => {
     var newThought = this.props.thoughts
      this.setState({
        lthoughts: newThought
      })
     }, 1000);  
  };

  getThoughtID = (props, thoughtID) => {
    for (let x in props) {
      console.log('inside showLead function A ', props[x].dailyThoughtID)
      console.log('inside showLead function B ', thoughtID)
      if (thoughtID===props[x].dailyThoughtID) {
         console.log('getThoughtID ', x)
         return x;
      }
      
     // return props[x].dailyThoughtID

      // return props[x]
    }
  }

  // loadLeads = () => {
  //   const promise = this.getLeads()
  //   promise.then(this.onFulfilled, this.onRejected)
  // }

  // getLeads = () => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //         this.props.getTrendingLeads()
  //     }, 6000)
  //   })
  // }

  showLead = (arrThoughts, opt) => {
   // const { thoughts } = this.props
   console.log('organisational compponentDidMount opt : ', opt) 
   console.log('organisational compponentDidMount arrThoughts : ', arrThoughts) 
    var x;
    var newThought;
    var xnewThought;
  //   var keys = Object.keys(lthoughts);
  //   var keysb = keys[0];
  //   console.log(keys[0]);
   
   // if (opt) {
  //     lthoughts.points = parseInt(lthoughts.points) + parseInt(opt);
  //     lthoughts.leadPoints = parseInt(lthoughts.leadPoints) + parseInt(opt);
  //     lthoughts.countLikes= parseInt(lthoughts.countLikes) + parseInt(opt);
  //     lthoughts.Likes = {keysb : opt===1 ? true: false};
  //   }

    if (opt) { //if populated then this function is used to do actions on the LEAD
     // setTimeout(() => {
      // if(this.props.thoughts) {
      //   //do nothing
      // } else {
        let equalTo = ''
        equalTo =
          APP_TYPE === 'CORPORATE'
            ? this.props.userdata.companyID + '_ilead_approved'
            : PUBLIC_COMPANY_ID + '_approved'

        // this.loadLeads()
       // this.props.getTrendingLeads({equalTo:''})
        console.log('greenthoughts ThoughtsView newThought 1 ', this.props.thoughts)

        // let equalTo = ''
        // equalTo =
        //   APP_TYPE === 'CORPORATE'
        //     ? this.props.userdata.companyID + '_ilead_approved'
        //     : PUBLIC_COMPANY_ID + '_approved'

        // console.log('greenthoughts ThoughtsView newThought 2 ', equalTo)

      //   this.loadLeads(equalTo)

        let params = {
          type: ILEAD_BLEADS, //ILEAD_LEADS4,
         // companyID: this.props.userdata.companyID,
          companyID: '-LEiZPT-C2PyLu_YLKNU',
          orderChild: 'publish_status',
         // equalTo: PUBLIC_COMPANY_ID + '_approved',
          equalTo: '-LEiZPT-C2PyLu_YLKNU_approved',
          minData: this.state.minData
        }
       //load leads data
      
       this.props.fetchThoughts(params)

      var lthoughts = Object.assign({}, arrThoughts)
      console.log('inside showLead function 3 ', lthoughts)

      console.log('greenthoughts ThoughtsView newThought 2 ', this.props.thoughts)
      xnewThought = this.props.thoughts;
      console.log('inside showLead function 3 ', lthoughts.dailyThoughtID) 
   
      if (lthoughts.dailyThoughtID && lthoughts.dailyThoughtID!==undefined) {
        console.log('inside showLead function 4 ', this.props.thoughts)
         x = this.getThoughtID(this.props.thoughts, lthoughts.dailyThoughtID);
      }
      else
      {
        x = this.getThoughtID(this.props.thoughts, lthoughts.thoughtID);
        console.log('inside showLead function 5 ', this.props.thoughts[x])
      }
      console.log('inside showLead function 6 ', x)

    setTimeout(() => {
      let params = {
        type: ILEAD_BLEADS,
        companyID: this.props.userdata.companyID,
        orderChild: 'publish_status',
        equalTo: PUBLIC_COMPANY_ID + '_approved',
        minData: this.state.minData
        //  type: ILEAD_BLEADS,
        //  companyID: '-LEiZPT-C2PyLu_YLKNU',
        //  orderChild: 'publish_status',
        //  equalTo: '-LEiZPT-C2PyLu_YLKNU_approved',
        //  minData: this.state.minData
      }
     //load leads data
     //this.props.fetchThoughts({type: ILEAD_LEADS4})
      // this.props.fetchThoughts(params)
     
      //this.loadLeads(equalTo)

      console.log('greenthoughts ThoughtsView newThought 3a ', this.props.thoughts[x])
      console.log('greenthoughts ThoughtsView newThought 3c ', x)

      newThought = this.props.thoughts[x]
     

      if (x) {
        this.setState({
          lthoughts: newThought
        })
      }
      else
      {
        this.setState({
          lthoughts: lthoughts
        })
      }

     }, 6000); 
    }
    else      //JUST DISPLAY THE LEAD
    {
      console.log('greenthoughts ThoughtsView newThought 3b ', arrThoughts)
      this.setState({
        lthoughts: arrThoughts
      })
    }

    // let params = {
    //   type: ILEAD_BLEADS,
    //   companyID: this.props.userdata.companyID,
    //   orderChild: 'publish_status',
    //   equalTo: this.props.userdata.companyID + '_ilead_approved',
    //  // minData: this.state.minData
    // }

    // this.props.fetchThoughts(params)
    // this.setState({lthoughts: this.props.thoughts})
  //}
 }

 loadLeads = (equalTo) => {
  console.log('inside loadLeads');

  const promise = this.getLeads(equalTo)
  promise.then(this.onFulfilled, this.onRejected)
}

getLeads = (equalTo) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
        let params2 = {
          type: ILEAD_LEADS4, //ILEAD_LEADS,
          companyID: this.props.userdata.companyID,
          orderChild: 'publish_status',
          equalTo: equalTo,
          minData: this.state.minData
        }
        console.log('ileads_leads2 1 : ', params2);
        //this.props.getTrendingLeads(params2)
        this.props.fetchThoughts(params2)
    }, 2000)
  })
}

  handleAddToPLDP = () => {};
  handleCloseDialog = () => {
    this.setState((prevState) => ({
      openAddPLDP: false,
      thoughtToAdd: {}
    }))
  };
  handleRemoveAttachement = (thought, attachmentType, attachmentID) => {};

  // function to go with CategoriesSelect ///
  handleCompState = (scname, scid) => {
    this.setState({ subcompetency: scname, subcompetencyid: scid })
  };

  onFocus = () => {
    this.setState({
      backgroundColor: '#DEC65F'
    })
  }

  onBlur = () => {
    this.setState({
      backgroundColor: '#DEC65F'
    })
  }

  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll)
    // this.fetchData()

    //  this.props.getOpinions({ //loads the opinions of leaders recorded in state
    //    type: GET_O,
    //    leaderID: this.props.userdata.userID
    //  })

    console.log('greenfilteredPosts c ', this.props )
    // if(this.props.xthoughts) {
    //   this.setState({
    //     lthoughts: this.props.xthoughts
    //   })
    // }
    if(this.props.xthoughts) {
      this.setState({
        lthoughts: this.props.xthoughts
      })
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {  
  //   if (this.props.thoughts !== nextProps.thoughts) {  
  //    return true;  
  //    }  
  //    return false;  
  //  } 
   
  
  render() {
    const { openmediaplayer, media, mediatype, lthoughts } = this.state
    const { classes, thoughts, match, followingData, cvalue } = this.props
  
    console.log('greenfilteredPosts 5 : ', this.props.xthoughts);
    
    var x = 0;
    let pss = false;

    if (this.props.thoughts || this.props.thoughtsb || this.props.xthoughts) {
      pss = true;
    }
    console.log('greenthoughts ThoughtsView pss ', pss)

    var currentThought;
    var ct = {};
    
    return (
      <React.Fragment>
        {isMobile ? (
          <div>
            <p>&nbsp;</p>
          </div>
        ) : (
          <div />
        )}

        <div style={{ display: 'Block' }}> 
        
          <CategoriesSelect
            keepsubcomp={false}
            doCompState={this.handleCompState}
            companyID={this.props.userdata.companyID}
            displayComp={this.props.fscompname}
          />

{/* { isMobile && (this.props.location.pathname==='/' || this.props.location.pathname==='/ilead') && this.props.topleads ? 
   <div className={classes.cleadtrends}>
    <br/>
    <b><i>trending leads</i></b><br/>
       <LeadsList 
         topleads={this.props.topleads}
         showLeadb={this.showLead}
       />
      </div>  
  : null} */}

         {/* <GlobalforumTutorial/>  */}
         {/* <GenTutorial
             // url={'https://youtu.be/rsn6Vec3fQA'}
              url={'https://youtu.be/bWqgnPWGt-0'}
           />  */}
        </div>
        {/* {Object.keys(thoughts).length > 0 ? null : (
          <Progressbar className={classes.progress} color="secondary" />
        )} */}

<MDBContainer style={basiclayout} >  
  <MDBRow> 
    <MDBCol>
        <div className={classes.filter}>
          {/* <FilterListIcon style={{ 
           //marginRight: '10px',
            
            // align: 'left',
            // alignItems: 'left',
            // justifyContent: 'left',
            // alignContent: 'left' 
            }}/> */}
          {/* <Typography variant='subtitle1'>Filter By</Typography> */}

          {/* <GlobalforumTutorial/> */}
          <GenTutorial
             // url={'https://youtu.be/rsn6Vec3fQA'}
              url={'https://youtu.be/bWqgnPWGt-0'}
           /> 
          <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label"><b>Filter by</b></InputLabel>
            <Select
              value={this.state.filter}
              onChange={this.handleFilterBy}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={'none'}>
            none
              </MenuItem>
              <MenuItem value={'videos'}>Videos</MenuItem>
              <MenuItem value={'podcasts'}>Podcasts</MenuItem>
              <MenuItem value={'files'}>Files</MenuItem>
              <MenuItem value={'images'}>Images</MenuItem>
              <MenuItem value={'articles'}>Articles</MenuItem>
            </Select>
          </FormControl>
          {/* {lthoughts && typeof lthoughts.dailyThoughtID !== 'undefined' ? 
          //  <div onClick={this.GfetchData()} style={{ 
            <div style={backGlobal}
            onClick={() => { this.GfetchData() }}
            >
            back to Global Forum
            </div>
          :null}  */}
        </div>
 
        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}
  
        <div className={classes.cardstyles}>
     {/* {lthoughts.dailyThoughtID ?  */}
          {lthoughts && typeof lthoughts.dailyThoughtID !== 'undefined' ? 
         // currentThought = this.state.lthoughts
         // Object.assign({}, currentThought);
       // Object.keys(this.state.lthoughts).slice(0, 1).map((id) => {

       <ThoughtsCard
       onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
       ref={'' + lthoughts.dailyThoughtID}
       onCommentsClick={this.handleCommentsClick}
       thought={lthoughts}
       showLeadb={this.showLead}
       key={lthoughts.dailyThoughtID}
       PlayMedia={this.OpenMediaPlayer}
       onRemoveAttachement={this.handleRemoveAttachement}
       {...this.props}
       following={
         followingData
           ? followingData[lthoughts.journalUserID]
             ? true
             : false
           : false
       }
       hideComments={
         this.props.userdata &&
HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
       }
       openFileViewer={this.props.openFileViewer}
     />
      :
      
      ///////////////////////////////////////////////////////////////////
      //  Object.keys(ct).map((id) => {

      // // Object.keys(lthoughts).slice(0, 1).map((id) => { 

      //  // feed.filter((item, index) => index < 5).map((filteredItem) =>
      // //     var currentThought = lthoughts;
      //    var cthoughts = Object.assign({}, lthoughts);
      //   // var cthoughts = JSON.stringify(lthoughts)
      //   //  console.log('green currentThought 1 : ', currentThought);

      //      // if (this.state.lthoughts.dailyThoughtID) {
      //      //   currentThought = this.state.lthoughts
      //      //   // if (thoughts[id].length) {} else { thoughts[id].length = 40 }
      //      //    //currentThought = thoughts[id]
      //      //    console.log('green currentThought 2 : ', currentThought);               
      //      // } else {
      //      //   currentThought = thoughts[id]
      //      //   console.log('green currentThought 1 : ', currentThought);
      //      // }

      //    // const currentThought = thoughts[id]
      //      //const currentThought = this.state.fresh_thought[id]; //thoughts[id]
      //      console.log('[Thought payload]', lthoughts)
      //      // if (this.props.tlead === 'trendingLead') {
      //      //    return (
      //      //     <div>Trending lead</div>
      //      //    )
      //      // }
      //      if (this.state.filter === 'none' && this.state.subcompetencyid.trim() === '') {
      //        return (
      //          <ThoughtsCard
      //            onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
      //            ref={'' + lthoughts.dailyThoughtID}
      //            onCommentsClick={this.handleCommentsClick}
      //            thought={cthoughts}
      //            showLeadb={this.showLead}
      //            key={lthoughts.dailyThoughtID}
      //            PlayMedia={this.OpenMediaPlayer}
      //            onRemoveAttachement={this.handleRemoveAttachement}
      //            {...this.props}
      //            following={
      //              followingData
      //                ? followingData[lthoughts.journalUserID]
      //                  ? true
      //                  : false
      //                : false
      //            }
      //            hideComments={
      //              this.props.userdata &&
      //  HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
      //            }
      //            openFileViewer={this.props.openFileViewer}
      //          />
      //        )
      //      } else if (this.state.filter === 'videos' && this.state.subcompetencyid.trim() === '' && currentThought.videos) {
      //        return (
      //          <ThoughtsCard
      //            onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
      //            ref={'' + lthoughts.dailyThoughtID}
      //            onCommentsClick={this.handleCommentsClick}
      //            thought={cthoughts}
      //            showLeadb={this.showLead}
      //            key={lthoughts.dailyThoughtID}
      //            PlayMedia={this.OpenMediaPlayer}
      //            onRemoveAttachement={this.handleRemoveAttachement}
      //            {...this.props}
      //            following={
      //              followingData
      //                ? followingData[lthoughts.journalUserID]
      //                  ? true
      //                  : false
      //                : false
      //            }
      //            hideComments={
      //              this.props.userdata &&
      //  HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
      //            }
      //            openFileViewer={this.props.openFileViewer}
      //          />
      //        )
      //      } else if (this.state.filter === 'images' && this.state.subcompetencyid.trim() === '' && currentThought.photos) {
      //        for (let photo in lthoughts.photos) {
      //          if (lthoughts.photos[photo].url !== '') {
      //            return (
      //              <ThoughtsCard
      //                onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
      //                ref={'' + lthoughts.dailyThoughtID}
      //                onCommentsClick={this.handleCommentsClick}
      //                thought={cthoughts}
      //                showLeadb={this.showLead}
      //                key={lthoughts.dailyThoughtID}
      //                PlayMedia={this.OpenMediaPlayer}
      //                onRemoveAttachement={this.handleRemoveAttachement}
      //                {...this.props}
      //                following={
      //                  followingData
      //                    ? followingData[lthoughts.journalUserID]
      //                      ? true
      //                      : false
      //                    : false
      //                }
      //                hideComments={
      //                  this.props.userdata &&
      //              HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
      //                }
      //                openFileViewer={this.props.openFileViewer}
      //              />
      //            )
      //          }
      //        }
      //      } else if (this.state.filter === 'articles' && this.state.subcompetencyid.trim() === '' && currentThought.urls) {
      //        return (
      //          <ThoughtsCard
      //            onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
      //            ref={'' + lthoughts.dailyThoughtID}
      //            onCommentsClick={this.handleCommentsClick}
      //            thought={cthoughts}
      //            showLeadb={this.showLead}
      //            key={lthoughts.dailyThoughtID}
      //            PlayMedia={this.OpenMediaPlayer}
      //            onRemoveAttachement={this.handleRemoveAttachement}
      //            {...this.props}
      //            following={
      //              followingData
      //                ? followingData[lthoughts.journalUserID]
      //                  ? true
      //                  : false
      //                : false
      //            }
      //            hideComments={
      //              this.props.userdata &&
      //              HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
      //            }
      //            openFileViewer={this.props.openFileViewer}
      //          />
      //        )
      //      } else if (this.state.filter === 'files' && this.state.subcompetencyid.trim() === '' && currentThought.files) {
      //        return (
      //          <ThoughtsCard
      //            onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
      //            ref={'' + lthoughts.dailyThoughtID}
      //            onCommentsClick={this.handleCommentsClick}
      //            thought={cthoughts}
      //            showLeadb={this.showLead}
      //            key={lthoughts.dailyThoughtID}
      //            PlayMedia={this.OpenMediaPlayer}
      //            onRemoveAttachement={this.handleRemoveAttachement}
      //            {...this.props}
      //            following={
      //              followingData
      //                ? followingData[lthoughts.journalUserID]
      //                  ? true
      //                  : false
      //                : false
      //            }
      //            hideComments={
      //              this.props.userdata &&
      //  HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
      //            }
      //            openFileViewer={this.props.openFileViewer}
      //          />
      //        )
      //      } else if (this.state.filter === 'podcasts' && this.state.subcompetencyid.trim() === '' && currentThought.podcasts) {
      //        return (
      //          <ThoughtsCard
      //            onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
      //            ref={'' + lthoughts.dailyThoughtID}
      //            onCommentsClick={this.handleCommentsClick}
      //            thought={cthoughts}
      //            showLeadb={this.showLead}
      //            key={lthoughts.dailyThoughtID}
      //            PlayMedia={this.OpenMediaPlayer}
      //            onRemoveAttachement={this.handleRemoveAttachement}
      //            {...this.props}
      //            following={
      //              followingData
      //                ? followingData[lthoughts.journalUserID]
      //                  ? true
      //                  : false
      //                : false
      //            }
      //            hideComments={
      //              this.props.userdata &&
      //              HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
      //            }
      //            openFileViewer={this.props.openFileViewer}
      //          />
      //        )
      //      }

      //      if (
      //      // this.props.thoughts[id].subcompetencyid === this.state.subcompetencyid
      //      lthoughts.subcompetencyid === this.state.subcompetencyid
      //      ) {
      //        return (
      //          <ThoughtsCard
      //            onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
      //            ref={'' + lthoughts.dailyThoughtID}
      //            onCommentsClick={this.handleCommentsClick}
      //            thought={cthoughts}
      //            showLeadb={this.showLead}
      //            key={lthoughts.dailyThoughtID}
      //            PlayMedia={this.OpenMediaPlayer}
      //            onRemoveAttachement={this.handleRemoveAttachement}
      //            {...this.props}
      //            following={
      //              followingData
      //                ? followingData[lthoughts.journalUserID]
      //                  ? true
      //                  : false
      //                : false
      //            }
      //            hideComments={
      //              this.props.userdata &&
      //              HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
      //            }
      //            openFileViewer={this.props.openFileViewer}
      //          />
      //        )
      //      }
      //     }
      //    )
      ///////////////////////////////////////////////////////////////////
          //  :

            (pss && 
              
              Object.keys(this.props.thoughtsb ? this.props.thoughtsb : thoughts).map((id) => {
                if (this.props.thoughtsb) {
                   var currentThought = this.props.thoughtsb[id] 
                }
                else
                {
                  var currentThought = thoughts[id]
                }
                
                console.log('greenfilteredPosts 7 : ', currentThought);
                // if (this.state.lthoughts.dailyThoughtID) {
                //   currentThought = this.state.lthoughts
                //   // if (thoughts[id].length) {} else { thoughts[id].length = 40 }
                //    //currentThought = thoughts[id]
                //    console.log('green currentThought 2 : ', currentThought);               
                // } else {
                //   currentThought = thoughts[id]
                //   console.log('green currentThought 1 : ', currentThought);
                // }

              // const currentThought = thoughts[id]
                //const currentThought = this.state.fresh_thought[id]; //thoughts[id]
                console.log('[Thought payload]', currentThought)
                // if (this.props.tlead === 'trendingLead') {
                //    return (
                //     <div>Trending lead</div>
                //    )
                // }
                if (this.state.filter === 'none' && this.state.subcompetencyid.trim() === '') {
                  return (
                    <ThoughtsCard
                      onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
                      ref={'' + currentThought.dailyThoughtID}
                      onCommentsClick={this.handleCommentsClick}
                      thought={currentThought}
                      key={id}
                      PlayMedia={this.OpenMediaPlayer}
                      onRemoveAttachement={this.handleRemoveAttachement}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[currentThought.journalUserID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={
                        this.props.userdata &&
            HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                      }
                      openFileViewer={this.props.openFileViewer}
                    />
                  )
                } else if (this.state.filter === 'videos' && this.state.subcompetencyid.trim() === '' && currentThought.videos) {
                  return (
                    <ThoughtsCard
                      onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
                      ref={'' + currentThought.dailyThoughtID}
                      onCommentsClick={this.handleCommentsClick}
                      thought={currentThought}
                      key={id}
                      PlayMedia={this.OpenMediaPlayer}
                      onRemoveAttachement={this.handleRemoveAttachement}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[currentThought.journalUserID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={
                        this.props.userdata &&
            HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                      }
                      openFileViewer={this.props.openFileViewer}
                    />
                  )
                } else if (this.state.filter === 'images' && this.state.subcompetencyid.trim() === '' && currentThought.photos) {
                  for (let photo in currentThought.photos) {
                    if (currentThought.photos[photo].url !== '') {
                      return (
                        <ThoughtsCard
                          onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
                          ref={'' + currentThought.dailyThoughtID}
                          onCommentsClick={this.handleCommentsClick}
                          thought={currentThought}
                          key={id}
                          PlayMedia={this.OpenMediaPlayer}
                          onRemoveAttachement={this.handleRemoveAttachement}
                          {...this.props}
                          following={
                            followingData
                              ? followingData[currentThought.journalUserID]
                                ? true
                                : false
                              : false
                          }
                          hideComments={
                            this.props.userdata &&
                        HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                          }
                          openFileViewer={this.props.openFileViewer}
                        />
                      )
                    }
                  }
                } else if (this.state.filter === 'articles' && this.state.subcompetencyid.trim() === '' && currentThought.urls) {
                  return (
                    <ThoughtsCard
                      onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
                      ref={'' + currentThought.dailyThoughtID}
                      onCommentsClick={this.handleCommentsClick}
                      thought={currentThought}
                      key={id}
                      PlayMedia={this.OpenMediaPlayer}
                      onRemoveAttachement={this.handleRemoveAttachement}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[currentThought.journalUserID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={
                        this.props.userdata &&
                        HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                      }
                      openFileViewer={this.props.openFileViewer}
                    />
                  )
                } else if (this.state.filter === 'files' && this.state.subcompetencyid.trim() === '' && currentThought.files) {
                  return (
                    <ThoughtsCard
                      onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
                      ref={'' + currentThought.dailyThoughtID}
                      onCommentsClick={this.handleCommentsClick}
                      thought={currentThought}
                      key={id}
                      PlayMedia={this.OpenMediaPlayer}
                      onRemoveAttachement={this.handleRemoveAttachement}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[currentThought.journalUserID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={
                        this.props.userdata &&
            HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                      }
                      openFileViewer={this.props.openFileViewer}
                    />
                  )
                } else if (this.state.filter === 'podcasts' && this.state.subcompetencyid.trim() === '' && currentThought.podcasts) {
                  return (
                    <ThoughtsCard
                      onOpenAddToPLDPDialog={() => this.handleOpenAddToPLDPDialog(thoughts[id])}
                      ref={'' + currentThought.dailyThoughtID}
                      onCommentsClick={this.handleCommentsClick}
                      thought={currentThought}
                      key={id}
                      PlayMedia={this.OpenMediaPlayer}
                      onRemoveAttachement={this.handleRemoveAttachement}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[currentThought.journalUserID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={
                        this.props.userdata &&
                        HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                      }
                      openFileViewer={this.props.openFileViewer}
                    />
                  )
                }

                if (
                // this.props.thoughts[id].subcompetencyid === this.state.subcompetencyid
                currentThought.subcompetencyid === this.state.subcompetencyid
                ) {
                  return (
                    <ThoughtsCard
                      onOpenAddToPLDPDialog={this.handleOpenAddToPLDPDialog}
                      ref={'' + currentThought.dailyThoughtID}
                      onCommentsClick={this.handleCommentsClick}
                      thought={currentThought}
                      key={id}
                      PlayMedia={this.OpenMediaPlayer}
                      onRemoveAttachement={this.handleRemoveAttachement}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[currentThought.journalUserID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={
                        this.props.userdata &&
                        HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]
                      }
                      openFileViewer={this.props.openFileViewer}
                    />
                  )
                }
              }))}
   

          <AddToPLDPDialog
            open={this.state.openAddPLDP}
            onClickProceed={this.handleAddToPLDP}
            onCloseDialog={this.handleCloseDialog}
          />
          <RemoveAttachmentDialog />
        </div>
    </MDBCol>                                                                             
    <MDBCol>
   
   {/* { isBrowser && (this.props.location.pathname==='/' || this.props.location.pathname==='/ilead') && this.props.topleads ? 
   <div className={classes.leadtrends}>
    <br/><br/><br/><br/>
    <b><i>trending leads</i></b><br/>
       <LeadsList 
         topleads={this.props.topleads}
         showLeadb={this.showLead}
        //  loadback={this.props.location.pathname==='/' ? true:false }
       />
      </div>  
  : null } */}
 
     </MDBCol>  
    </MDBRow> 
  </MDBContainer>                 

      </React.Fragment>
    )
  }

}

ThoughtsView.propTypes = {
  classes: PropTypes.object.isRequired,
  thoughts: PropTypes.any
}

let ThoughtsViewStyled = withStyles(styles)(ThoughtsView)

const mapStateToProps = (state) => {
  return {
    followingData: state.userdata.followingButton,
    // STORE_SUBCOMPETENCYID: state.competencies.STORE_SUBCOMPETENCYID,
    subcompFilter: state.subcomp.cvalue,
    fscompname: state.scompname.scompname,
    thoughts: state.thoughts.ilead,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeSubCompetency: (params) => dispatch(storeSubCompetency(params)),
    fetchThoughts: (params) => dispatch(fetchThoughts(params)),
    getTrendingLeads: params => dispatch(getTrendingLeads(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThoughtsViewStyled)
// export default (ThoughtsViewStyled)
