import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import SubCompetencyOptions from "./SubCompetencyOptions";

const styles = {
  searchUser__block: {
    background: "#fff",
    position: "relative",
    top: "1px"
  }
};

class SubCompSelect extends Component {
  constructor (props) {
    super(props);
  }

  state = {
    name: ""
  };

  handleChangeValue = (id, name) => {
    this.setState(
      preState => ({
        ...preState,
        name: name
      }),
      function() {
        this.props.handleSubCompetency(id, name);
      }
    );
  };

  render() {
    const { competencyid } = this.props;

    return (
      <SubCompetencyOptions
        competencyid={competencyid}
        subcompetencyid={this.props.subcompetencyid}
        onChangeValue={this.handleChangeValue}
      />
    );
  }
}

SubCompSelect.propTypes = {
  competencyid: PropTypes.string.isRequired
};

export default withStyles(styles)(SubCompSelect);
