import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SendIcon from "@material-ui/icons/Send";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

import AttachmentIcon from "@material-ui/icons/Attachment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField
} from "@material-ui/core";
import { ACCESS_COMPANY_PROFILE, IS_HARMONY, HARMONY_USER_TYPES } from "../../store/actions/types";

const styles = theme => ({
  appBar: {
    position: "static",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
    backgroundColor: "#d51317"
  },
  flex: {
    flex: 1,
    width: 600
  },
  dialog: {},
  submitButton: {
    backgroundColor: "#2196F3 !important"
  },
  button: {
    margin: theme.spacing.unit,
    color: "#ffffff",
    backgroundColor: "#0288d1",
    "&:hover": {
      backgroundColor: "#0288d1"
    }
  },
  uploadButton: {
    display: "block!important",
    width: "100%!important",
    height: "36px!important",
    opacity: "0!important",
    overflow: "hidden!important",
    marginTop: "-36px",
    cursor: "pointer!important",
    zIndex: "999999!important"
  }
});

const Transition = props =>
  window.innerWidth >= 425 ? (
    <Fade checkIn={true} {...props} />
  ) : (
      <Slide direction="left" {...props} />
    );

class UserTypeDialog extends React.Component {
  updateUserFunc = null;
  state = {
    scroll: "paper",
    usertype: 0
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleChangeUser = event => {
    this.setState({ usertype: event.target.value });
  };
  handleClose = () => {
    this.props.onOpenUserTypeDialog();
  };
  renderUserTypes = () => {
    const { selectedUserType } = this.props;
    var userTypes;
    var superUser = this.props.userdata.superUser
      ? this.props.userdata.superUser
      : false;

    if (ACCESS_COMPANY_PROFILE[this.props.userdata.companyID] || superUser) {
      switch(this.props.userdata.userType) {
        case 14:
          userTypes = [
            { id: 8, value: "Standard User" },
            { id: 3, value: "Public Contributor" },
            { id: 12, value: "Delegate" },
            { id: 10, value: "Corporate I-Leader" },
            { id: 11, value: "Organisational Contributor" },
           // { id: 14, value: "Client Admin" },
          ];
          break;
        default:
          userTypes = [
            { id: 3, value: "Public Contributor" },
            { id: 8, value: "Standard User" },
            { id: 12, value: "Delegate" },
            { id: 10, value: "Corporate I-Leader" },
            { id: 11, value: "Organisational Contributor" },
            { id: 4, value: "Global Contributor" },
            { id: 14, value: "Client Admin" },
            { id: 7, value: "Company Admin" }
          ];
      }
    } else {
      switch(this.props.userdata.userType) {
        case 14:
          userTypes = [
            { id: 8, value: "Standard User" },
            { id: 3, value: "Public Contributor" },
            { id: 12, value: "Delegate" },
            { id: 10, value: "Corporate I-Leader" },
            { id: 11, value: "Organisational Contributor" },
          //  { id: 14, value: "Client Admin" },
      ];
          break;
        default:
          userTypes = [
            { id: 8, value: "Standard User" },
            { id: 3, value: "Public Contributor" },
            { id: 12, value: "Delegate" },
            { id: 10, value: "Corporate I-Leader" },
            { id: 11, value: "Organisational Contributor" },
            { id: 4, value: 'Global Contributor' },
            { id: 14, value: "Client Admin" },
            { id: 7, value: "Company Admin" },
          ];
      }
    }

    return userTypes.map((v, i) => (
      <MenuItem
        key={i}
        value={v.id}
        selected={v.value.includes(selectedUserType)}
      >
        {v.value}
      </MenuItem>
    ));
  };
  render() {
    const {
      classes,
      theme,
      onSubmitSelectedUserType,
      selectedUserType
    } = this.props;
    let self = this;

    //console.log('userTypeDialog props: ', this.props.userdata.userType);

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          fullScreen={window.innerWidth >= 425 ? false : true}
          fullWidth
          TransitionComponent={Transition}
          aria-labelledby="scroll-dialog-title"
        >
          {window.innerWidth >= 425 ? (
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h6">
                Change Type: ( {selectedUserType} )
              </Typography>
            </DialogTitle>
          ) : (
              ""
            )}
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Change User Type
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <form>
              <FormControl fullWidth>
                <InputLabel htmlFor="usertype">User Category</InputLabel>
                <Select
                  value={this.state.usertype}
                  onChange={this.handleChangeUser}
                  inputProps={{
                    name: "usertype",
                    id: "usertype"
                  }}
                >
                  {this.renderUserTypes()}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => onSubmitSelectedUserType(this.state.usertype)}
              size="large"
            >
              <SendIcon />
              Submit
            </Button>
            <Button onClick={this.handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UserTypeDialog);
