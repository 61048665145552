import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PodcastsCard from "../Cards/PodcastsCard";
import MediaPlayer from "../Dialogs/MediaPlayer";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";
import CategoriesSelect from "../../components/categories/categoriesSelect";
import { HIDE_COMMENTS_BUTTON } from "../../store/actions/types";
import {
  isMobile
} from "react-device-detect";

var receivedData = {};

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class PodcastsView extends React.Component {
  constructor (props) {
    super(props);
  }
  state = {
    openmediaplayer: false,
    mediatype: "podcast",
    media: null,
    subcompetencyid: ""
  };

  OpenMediaPlayer = media => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media
    });
  };


  //function to go with CategoriesSelect ///
  handleCompState = (scname, scid) => {
    this.setState({ subcompetency: scname });
    this.setState({ subcompetencyid: scid });
  }

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    });
  };

  _sortData = data => {
    var sortedData = _toArray(data);
    sortedData.sort(function(a, b) {
      if (a.dateScheduled > b.dateScheduled) return -1;
      if (a.dateScheduled < b.dateScheduled) return 1;
      return 0;
    });

    return sortedData;
  };

  componentDidUpdate() {
    // console.log('match.path ', this.props.match.path)
    if (Object.keys(this.props.podcasts).length > 0) {
      var arrayData = this._sortData(this.props.podcasts);
      receivedData = { ...arrayData };

      receivedData = Object.assign({}, arrayData);
    }
  }

  render() {
    const { openmediaplayer, media, mediatype } = this.state;
    const { classes, podcasts, followingData } = this.props;
    var podcastSubcomp;
    
    return (
      <React.Fragment>
        {isMobile?
         <div><p>&nbsp;</p></div>: 
         <div></div>
        }

        <CategoriesSelect keepsubcomp={false} doCompState={this.handleCompState} companyID={this.props.userdata.companyID} displayComp={this.props.fscompname} />

        {/* {Object.keys(podcasts).length > 0 ? null : (
          <Progressbar className={classes.progress} color="secondary" />
        )} */}

        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}

        <div className={classes.cardstyles}>
          {this.props.match.path === '/filtered-podcasts' ? this.props.match.path === '/filtered-podcasts' &&
            podcasts && Object.keys(podcasts).length > 0 && Object.keys(podcasts).map(id => {
              if (this.state.subcompetencyid.trim() === "") {
                return (
                  <PodcastsCard
                    podcast={podcasts[id]}
                    key={id}
                    PlayMedia={this.OpenMediaPlayer}
                    following={
                      followingData
                        ? followingData[podcasts[id].userID]
                          ? true
                          : false
                        : false
                    }
                    {...this.props}
                    hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                  />
                );
              }
              else {
                //user redux prop
                if (this.props.podcasts[podcasts[id].id].subcompetencyid === this.state.subcompetencyid) {
                  return (
                    <PodcastsCard
                      podcast={podcasts[id]}
                      key={id}
                      PlayMedia={this.OpenMediaPlayer}
                      following={
                        followingData
                          ? followingData[podcasts[id].userID]
                            ? true
                            : false
                          : false
                      }
                      {...this.props}
                      hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                    />
                  );
                }
              }

            }) : podcasts &&
            Object.keys(podcasts).length > 0 &&
            Object.keys(receivedData).map(id => {

              if (this.state.subcompetencyid.trim() === "") {
                return (
                  <PodcastsCard
                    podcast={receivedData[id]}
                    key={id}
                    PlayMedia={this.OpenMediaPlayer}
                    following={
                      followingData
                        ? followingData[receivedData[id].userID]
                          ? true
                          : false
                        : false
                    }
                    {...this.props}
                    hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                  />
                );
              }
              else {
                //user redux prop
                if (receivedData[id]) {
                  console.log('podcasts recievedData: ',receivedData[id]);
                  podcastSubcomp = receivedData[id].subcompetencyid
                }
                else {
                  podcastSubcomp = this.props.podcasts[receivedData[id].id].subcompetencyid
                }                
                if (podcastSubcomp === this.state.subcompetencyid) {
                  return (
                    <PodcastsCard
                      podcast={receivedData[id]}
                      key={id}
                      PlayMedia={this.OpenMediaPlayer}
                      following={
                        followingData
                          ? followingData[receivedData[id].userID]
                            ? true
                            : false
                          : false
                      }
                      {...this.props}
                      hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                    />
                  );
                }
              }

            })}
        </div>
      </React.Fragment>
    );
  }
}

PodcastsView.propTypes = {
  classes: PropTypes.object.isRequired,
  podcasts: PropTypes.object.isRequired
};

var PodcastsViewStyled = withStyles(styles)(PodcastsView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(PodcastsViewStyled);