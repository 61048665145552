import { LEADERBOARD_LOADING } from "../actions/types";

const initialState = {
  leaderboardloading: 0
};

const loadingReducer = (state = initialState, action) => {
  console.log('green leaderboardloading 2 : ', action.leaderboard_loading);
  switch (action.type) {
    case LEADERBOARD_LOADING:
      return {
        ...state,
        type: LEADERBOARD_LOADING,
        leaderboardloading: action.leaderboard_loading
      };
       break; 
     default:
      return state
    // default:
    //     return {
    //         type: LEADERBOARD_LOADING,
    //         leaderboardloading: 0
    //       };
  }
};

export default loadingReducer;
