"use strict";
import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Typography, Paper, Grid, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MessagesIcon from "@material-ui/icons/SpeakerNotes";
import VideoCamIcon from "@material-ui/icons/Videocam";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MicIcon from "@material-ui/icons/Mic";
import GroupIcon from "@material-ui/icons/GroupWork";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import { green, orange, grey, purple, blue, yellow, blueGrey } from "@material-ui/core/colors";

const styles = theme => ({
  avatarIcon: {
    margin: 10
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    width: 100,
    height: 100,
    backgroundColor: purple[500]
  },
  groupsAvatar: {
    margin: 10,
    color: "#fff",
    width: 100,
    height: 100,
    backgroundColor: blueGrey[500]
  },
  videosAvatar: {
    margin: 10,
    color: "#fff",
    width: 100,
    height: 100,
    backgroundColor: blue[500]
  },
  articlesAvatar: {
    margin: 10,
    color: "#fff",
    width: 100,
    height: 100,
    backgroundColor: green[500]
  },
  podcastsAvatar: {
    margin: 10,
    color: "#fff",
    width: 100,
    height: 100,
    backgroundColor: orange[700]
  },
  pldpAvatar: {
    margin: 10,
    color: "#fff",
    width: 100,
    height: 100,
    backgroundColor: yellow[700]
  },
  // Borders
  purpleBorder: {
    borderLeft: "7px solid " + purple[500]
  },
  groupsBorder: {
    borderLeft: "7px solid " + blueGrey[500]
  },

  videosBorder: {
    borderLeft: "7px solid " + blue[500]
  },
  articlesBorder: {
    borderLeft: "7px solid " + green[500]
  },
  podcastsBorder: {
    borderLeft: "7px solid " + orange[500]
  },
  pldpBorder: {
    borderLeft: "7px solid " + yellow[500]
  },
  typography: {
    color: grey[500]
  },
  paperContainer: {
    marginLeft: "8px",
    marginRight: "8px",
    marginBottom: "8px"
  },
  centerGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
});

function StatsCard(props) {
  const { classes, stat, index } = props;
  const colors = [
    {
      icon: <MessagesIcon fontSize="large" />,
      heading: "Messages",
      avatarColor: classes.purpleAvatar,
      border: classes.purpleBorder
    },
    {
      icon: <VideoCamIcon fontSize="large" />,
      heading: "Videos",
      avatarColor: classes.videosAvatar,
      border: classes.videosBorder
    },
    {
      icon: <RemoveRedEye fontSize="large" />,
      heading: "Articles",
      avatarColor: classes.articlesAvatar,
      border: classes.articlesBorder
    },
    {
      icon: <MicIcon fontSize="large" />,
      heading: "Podcasts",
      avatarColor: classes.podcastsAvatar,
      border: classes.podcastsBorder
    },
    {
      icon: <LibraryBooksIcon fontSize="large" />,
      heading: "PLDP",
      avatarColor: classes.pldpAvatar,
      border: classes.pldpBorder
    }
  ];

  return (
    <Grid item xs={12} md={4}>
      <Paper
        className={classNames(classes.paperContainer, colors[index].border)}
      >
        <Grid container spacing={0}>
          <Grid item>
            <Avatar className={classNames(colors[index].avatarColor)}>
              {colors[index].icon}
            </Avatar>
          </Grid>

          <Grid item xs={6} md={6} container>
            <Grid item xs container direction="column">
              <Grid item xs className={classes.centerGrid}>
                <Typography
                  className={classNames(classes.typography)}
                  gutterBottom
                  variant="subtitle1"
                >
                  {colors[index].heading}
                </Typography>
                <Typography gutterBottom variant="h6">
                  {stat.count}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default withStyles(styles)(StatsCard);
