/* eslint-disable no-dupe-keys */
/* eslint-disable consistent-this */
/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SendIcon from '@material-ui/icons/Send'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Typography from '@material-ui/core/Typography'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Grid
} from '@material-ui/core'
import { LIGHTBLUE } from '../../utils/color_utils'
import {
  APP_TYPE,
  NEW_CORP_USERS,
  NEW_PUBLIC_USERS,
  SHOW_TOAST,
  HARMONY_USER_TYPES,
  IS_HARMONY,
} from '../../store/actions/types'
import { showToast } from '../../store/actions/toastAction'
import {
    getAllCompanyUsers
} from '../../store/actions/companyAdminActions'
import { v3 } from 'uuid'
import {Checkbox} from '@material-ui/core'

const styles = theme => ({
  appBar: {
    position: 'static',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    backgroundColor: '#d51317'
  },
  flex: {
    flex: 1,
    width: 600
  },
  dialog: {},
  submitButton: {
    backgroundColor: '#2196F3 !important'
  },
  button: {
    'margin': theme.spacing.unit,
    'color': '#ffffff',
    'backgroundColor': '#0288d1',
    '&:hover': {
      backgroundColor: '#0288d1'
    }
  }
})

const Transition = props =>
  window.innerWidth >= 425 ? (
    <Fade checkIn={true} {...props} />
  ) : (
      <Slide direction='left' {...props} />
    )

class AddTokensDialog extends React.Component {
  state = {
    open: false,
    scroll: 'paper',
    userSelected: '',
    // surName: '',
    firstName: '',
    lastName: '',
    // gender: '',
    // position: '',
    // points: '',
    // race: '',
    // nationality: '',
    // idNumber: '',
    // department: '',
    // operation: '',
    email: '',
    amount: 1,
    tokenType: '',
    // edit: false,
    // uid: '',
    tokenID: '',
    personID: '',
    personName: '',
    personLastName: ''
    // demoOn: false
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };

  selectTokenProps = event => {
    // -MYnqWWpV4pznTXDX-Ar | Boulate / Octopus : paula.paulatek@gmail.com 
   // -MYnqWWpV4pznTXDX-Ar|Boulate/Octopus:paula.paulatek@gmail.com
    var str = event.target.value;
    var pid = str.substring(0, str.indexOf("|"));  //str.split("|").pop();
    //    |/:
    var pname = str.split('|').pop().split('/')[0];
    var lname = str.split('/').pop().split(';')[0];
      //////////////////////////////////////
      //  var char1 = str.indexOf('/') + 1;
      //  var char2 = str.lastIndexOf(';');
      //  var plname = str.substring(char1, char2);

      //  var lname = str.substring(
      //   str.lastIndexOf("/") + 1, 
      //   str.lastIndexOf(";")
      // );

      // let substring = getSubstring(originalString, ',', '!');
      /////////////////////////////////////////
    var n = str.lastIndexOf(';');
    var pemail = str.substring(n + 1);

    console.log('selectTokenProps : ', event.target.value)
     this.setState({
      personID: pid,
      personName: pname,
      personLastName: lname, 
      personEmail: pemail
     })
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll })
  };

  handleChangeUser = event => {
    console.log('green handleChangeUser 1 : ', event.target.value)
    let text = event.target.value;
    const chars = text.split("|");

    this.setState({ 
      userID: chars[0], 
      firstName: chars[1], 
      lastName: chars[2], 
      email: chars[3]
    })

  };

  handleClose = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
    })
  };

  closeDialog = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
    })
    this.props.onOpenAddUserDialog()
  };

//   renderUserTypes = () => {
    
//     const userTypes = IS_HARMONY ? HARMONY_USER_TYPES : (APP_TYPE === 'PUBLIC' ? NEW_PUBLIC_USERS : NEW_CORP_USERS)

//     return userTypes.map((v, i) => (
//       <MenuItem key={v.id} value={v.id}>
//         {v.value}
//       </MenuItem>
//     ))
//   };

renderUsers = () => {
    let { users } = this.props
   
    return Object.keys(users).map((v, i) => (
        <MenuItem key={users[v].userID} value={users[v].userID + '|' + users[v].firstName + '/' + users[v].lastName + ';' + users[v].email} >
        {users[v].firstName + ' ' + users[v].lastName }
      </MenuItem>
    ))

    // return users.map((v, i) => (
    //     <MenuItem key={v.id} value={v.id}>
    //       {v.firstName}
    //     </MenuItem>
    //   ))
  };

//   renderCompanySettings = (type) => {
//     let itemdata = null
//     console.log('renderCompanySettings: ', this.props.company_settings);

//     if (this.props.company_settings && this.props.company_settings[type]) {
//       itemdata = this.props.company_settings[type];

//       return itemdata.map((v, i) => (
//         <MenuItem key={i} value={v}>
//           {v}
//         </MenuItem>
//       ))
//     }
//   };

//   renderGeneralSettings = (type) => {
//     let itemdata = null

//     if (this.props.general_settings && this.props.general_settings[type]) {
//       itemdata = this.props.general_settings[type];

//       return itemdata.map((v, i) => (
//         <MenuItem key={i} value={v}>
//           {v}
//         </MenuItem>
//       ))
//     }
//   };

  handleDemo = event => {
    this.setState({
      demoOn: event.target.checked
    })
  };

  componentDidMount() {
    let { classes, userdata, getAllCompanyUsers} = this.props
    getAllCompanyUsers({
        companyID: userdata.companyID,
        orderChild: 'companyID'
      })
  }

  componentDidUpdate() {

   // if ((this.props.edit === true) && (this.state.edit === false) && (this.props.open)) {
      // let editedUser = this.props.editedUser;
      // console.log('green editUser : ', editedUser);

      // this.setState({
      //   //usertype: editedUser.userType ? editedUser.userType : '',
      //   //surName: editedUser.surName ? editedUser.surName : '',
      //   firstName: editedUser.firstName ? editedUser.firstName : '',
      //   lastName: editedUser.lastName ? editedUser.lastName : '',
      //   //gender: editedUser.gender ? editedUser.gender : '',
      //   //position: editedUser.position ? editedUser.position : '',
      //   //points: editedUser.points ? editedUser.points : '',
      //   //race: editedUser.race ? editedUser.race : '',
      //   //nationality: editedUser.nationality ? editedUser.nationality : '',
      //   //idNumber: editedUser.idNumber ? editedUser.idNumber : '',
      //   //department: editedUser.department ? editedUser.department : '',
      //   //operation: editedUser.operation ? editedUser.operation : '',
      //   email: editedUser.email ? editedUser.email : '',
      //   //uid: editedUser.uid ? editedUser.uid : '',
      //   tokenID: editedUser.tokenID ? editedUser.tokenID : '',
      //   //demoOn: editedUser.demoOn ? editedUser.demoOn : false
      // })
    //}
    // else if ((this.props.edit === true) && (this.state.edit === true) && (this.props.open === false)) {
    //   let editedUser = this.props.editedUser;
    //   this.setState({
    //     firstName: '',
    //     lastName: '',
    //     email: '',
    //     edit: false,
    //     tokenID: '',
    //   })
    // }

    // getAllCompanySettings({
    //   companyID: this.props.userdata.companyID,
    // })
    // getAllGeneralSettings({
    //   companyID: this.props.userdata.companyID,
    // })
  }
  // loadPeople = (type) => {
  //   let itemdata = null

  //     return this.props.users.map((v, i) => (
  //       <MenuItem key={i} value={obj.userID}>
  //         {v.userID}
  //       </MenuItem>
  //     ))
    
  // };

  render() {
    const { classes, theme, onSubmitUserClick, fullScreen, userdata} = this.props

    const {
      usertype,
      firstName,
      lastName,
      email
    } = this.state
   //console.log('user details props: ', this.props);

    let self = this
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          fullScreen={fullScreen}
          fullWidth
          maxWidth='sm'
          TransitionComponent={Transition}
          aria-labelledby='scroll-dialog-title'
        >
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PersonAddIcon
                fontSize='small'
                style={{ color: `${LIGHTBLUE}` }}
              />
              <span
                style={{
                  color: `${LIGHTBLUE}`,
                  fontWeight: 'bold',
                  fontSize: '15px'
                }}
              >
                &nbsp;Send Token
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <form autoComplete="off">

                {/* <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='userSelected'>Select User</InputLabel>
                    <Select
                      value={this.state.userSelected}
                      onChange={this.handleChangeUser}
                      inputProps={{
                        name: 'userSelected',
                        id: 'userSelected'
                      }}
                    >
                      {this.renderUsers()}
                    </Select>
                  </FormControl>
                </Grid> */}
               
                
                {/* <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label='Firstname'
                      fullWidth
                      value={this.state.firstName}
                      onChange={e => this.handleChange(e, 'firstName')}
                      inputProps={{
                        name: 'firstName',
                        id: 'firstName'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  
                </Grid>
              
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <TextField
                      label='Lastname'
                      fullWidth
                      value={this.state.lastName}
                      onChange={e => this.handleChange(e, 'lastName')}
                      inputProps={{
                        name: 'lastName',
                        id: 'lastName'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>

                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      label='Initials'
                      fullWidth
                      value={this.state.surName}
                      onChange={e => this.handleChange(e, 'surName')}
                      inputProps={{
                        name: 'surName',
                        id: 'surName'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label='Email'
                      fullWidth
                      value={this.state.email}
                      onChange={e => this.handleChange(e, 'email')}
                      inputProps={{
                        name: 'email',
                        id: 'email'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </Grid> */}
           
            
                  {/* <FormControl fullWidth>
                    <TextField
                      required
                      label='Token Type'
                      fullWidth
                      value={this.state.tokenType}
                      onChange={e => this.handleChange(e, 'tokenType')}
                      inputProps={{
                        name: 'tokenType',
                        id: 'amount'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl> */}

                <Grid item xs={12} sm={6}>
               <FormControl fullWidth>
                    <InputLabel htmlFor='tokenType'>Select Token Type</InputLabel>
                    <Select
                      value={this.state.tokenType}
                      onChange={e => this.handleChange(e, 'tokenType')}
                      inputProps={{
                        name: 'tokenType',
                        id: 'tokenType'
                      }}
                    >    
                    {/* //CALF PRODUCTS MODIFY HERE   */}
                     <MenuItem key={'app'} value={'app'} >
                       {'Token to buy ThinkLead app'}
                     </MenuItem>
                     <MenuItem key={'ex1'} value={'ex1'} >
                       {'Token to buy Executive Club 1'}
                     </MenuItem>  
                     <MenuItem key={'ex2'} value={'ex2'} >
                       {'Token to buy Executive Club 2'}
                     </MenuItem>  
                    </Select>
                  </FormControl>
                </Grid>  
     
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='person'>Select Person</InputLabel>
                    <Select
                      value={this.state.personID}
                      onChange={e => this.selectTokenProps(e, 'person')}
                      inputProps={{
                        name: 'person',
                        id: 'person'
                      }}
                    >
                      <MenuItem value={'x'}>
                        <em>'No Selection'</em>
                      </MenuItem>   
                      {this.renderUsers()}
                    </Select>
                  </FormControl>
                </Grid>


            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              disabled={!this.state.personID}
              onClick={e => onSubmitUserClick({
                personID: this.state.personID,
                personName: this.state.personName,
                personLastName: this.state.personLastName,
                personEmail: this.state.personEmail,
                tokenType:this.state.tokenType,
                firstName: userdata.firstName,
                lastName: userdata.lastName,
                email: userdata.email,
                userID: userdata.userID,
                companyID: userdata.companyID,
                amount: this.state.amount
              })}
              className={classes.button}
              size='large'
            >
              <SendIcon />
              Send Token
            </Button>
            <Button onClick={this.closeDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

// const AddTokensDialog = withMobileDialog({ breakpoint: 'xs' })(
//     AddTokensDialog
// )

const AddTokensDialogStyled = withStyles(styles, { withTheme: true })(AddTokensDialog)


const mapStateToProps = state => {
  return {
    users: state.users.users
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    getAllCompanyUsers: params => dispatch(getAllCompanyUsers(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTokensDialogStyled);
