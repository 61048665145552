import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PodcastsCard from "../Cards/PodcastsCard";
import MediaPlayer from "../Dialogs/MediaPlayer";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  }
});

class PodcastsView extends React.Component {
  state = {
    openmediaplayer: false,
    mediatype: "podcast",
    media: null
  };

  OpenMediaPlayer = media => {
    this.setState({
      ...this.state,
      openmediaplayer: true,
      media: media
    });
  };

  CloseMediaPlayer = () => {
    this.setState({
      ...this.state,
      openmediaplayer: false,
      media: null
    });
  };

  render() {
    const { openmediaplayer, media, mediatype } = this.state;
    const { classes, podcasts, followingData } = this.props;

    return (
      <React.Fragment>
        {/* {Object.keys(podcasts).length > 0 ? null : (
          <Progressbar className={classes.progress} color="secondary" />
        )} */}

        {openmediaplayer ? (
          <MediaPlayer
            mediatype={mediatype}
            media={media}
            openmediaplayer
            CloseMedia={this.CloseMediaPlayer}
          />
        ) : null}

        <div className={classes.cardstyles}>
          {podcasts &&
            //Object.keys(podcasts).length > 0 &&
            Object.keys(podcasts).map(id => {
              return (
                <PodcastsCard
                  podcast={podcasts[id]}
                  key={id}
                  PlayMedia={this.OpenMediaPlayer}
                  following={
                    followingData
                      ? followingData[podcasts[id].userID]
                        ? true
                        : false
                      : false
                  }
                  userdata={this.props.userdata}
                />
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

PodcastsView.propTypes = {
  classes: PropTypes.object.isRequired,
  podcasts: PropTypes.object.isRequired
};

var PodcastsViewStyled = withStyles(styles)(PodcastsView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(PodcastsViewStyled);
