/**
  TITLE: 
  DESCRIPTION:
    Retrieve action property from loaderActions and set LoaderBar state
**/

//=========
//LIBS
import { SHOW_LOADER, HIDE_LOADER } from '../actions/types';

//=========
//FUNCTIONS
//----------
//Function: Set state of component
const setLoaderState = (state, visible ,action) => {
  return {
    ...state,
    type: (visible)? SHOW_LOADER: HIDE_LOADER,
    showloader: action.showloader
  };
};

//=========
//Main: Retrieves action passed through and updates state of component
export default (state = { type: HIDE_LOADER, showloader: false } , action) => {
  return (action.type === SHOW_LOADER)? 
  setLoaderState(state, true, action): 
  setLoaderState(state, false, action);
}