import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  withStyles
} from '@material-ui/core/styles'
import { Typography, Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Tab, Tabs, IconButton, FormControlLabel, Checkbox } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import CloseIcon from '@material-ui/icons/Close';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TodayIcon from '@material-ui/icons/Today';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { showToast } from '../../store/actions/toastAction'
import { SHOW_TOAST } from '../../store/actions/types'
import Reminder from "./Reminder";
import EventsListAdmin from "./EventsListAdmin";
import { LIGHTBLUE, LIGHTGREY, } from '../../utils/color_utils'
import { postGroupEvent, getGroupEventsReminders, deleteGroupEventReminders } from "../../store/actions/companyAdminActions";

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 0, marginTop: 6 }}>
      {props.children}
    </Typography>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  margin: {
    margin: theme.spacing(1),
  },
  headerLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: `${LIGHTBLUE}`,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: theme.spacing()
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  headerDiv: {
    borderBottom: `1px solid ${LIGHTGREY}`,
  },
  eventHeaderText: {
    color: `${LIGHTBLUE}`,
    fontWeight: 'bold',
    borderBottom: `1px solid ${LIGHTGREY}`,
    padding: theme.spacing(),
    marginBottom: theme.spacing(1),
  },
})

const tabsdiv = {
  flexGrow: 1,
  width: '100%!important',
  backgroundColor: '#fff',
}

class Events extends Component {
  state = {
    companyID: '',
    companyName: '',
    event_id: '',
    event_title: '',
    event_description: '',
    event_start_date: new Date(),
    event_start_epoch: Date.now(),
    event_end_date: '',
    event_end_epoch: '',
    event_location: '',
    send_email_immediately: false,
    event_dialog_title: 'Add Event',
    reminders: {},
    tabsvalue: 0
  };

  handleTabsChange = (event, tabsvalue) => {
    this.setState({ tabsvalue })
  }

  componentDidMount() {
    this.setState({
      companyID: this.props.userdata.companyID,
      companyName: this.props.userdata.companyName,
    })
  }

  componentDidUpdate() {
    if (this.state.event_id.length > 0 && this.props.group_events_reminders !== this.state.reminders) {
      // console.log("updating reminders")
      this.setState({
        reminders: this.props.group_events_reminders
      })
      // this.state = {
      //   ...this.state,
      //   reminders: this.props.group_events_reminders
      // };
    }
  }

  handleChange = event => {
    let val = event.target.value;
    if (event.target.name === 'send_email_immediately') {
      val = event.target.checked;
    }
    this.setState({ [event.target.name]: val });
  };

  openDialog = (type, title) => e => {
    this.setState({
      open: true,
    });
  }

  editEvent = (event) => {
    this.props.getGroupEventsReminders({ group_id: event.group_id });
    this.setState({
      open: true,
      companyID: event.company_id,
      companyName: this.props.userdata.companyName,
      event_id: event.event_id,
      event_title: event.event_title,
      event_description: event.event_description,
      event_start_date: new Date(event.event_start_date),
      event_start_epoch: event.event_start_epoch,
      event_end_date: new Date(event.event_end_date),
      event_end_epoch: event.event_end_epoch,
      event_location: event.event_location,
      send_email_immediately: event.send_email_immediately ? event.send_email_immediately : false,
      event_dialog_title: 'Edit Event',
      reminders: this.props.group_events_reminders ? this.props.group_events_reminders : {},
    });
  }

  handleClose = () => {
    this.setState({
      open: false,
      event_title: '',
      event_description: '',
      event_start_date: '',
      event_start_epoch: '',
      event_end_date: '',
      event_end_epoch: '',
      event_location: '',
      send_email_immediately: false,
      event_dialog_title: 'Add Event',
      event_id: '',
      reminders: {},
    });
  };

  addReminder = () => {
    var reminders = this.state.reminders;
    var timeInMs = Date.now();
    var reminder = {
      reminder_id: timeInMs,
      reminder_type: 'Email',
      reminder_time: 10,
      reminder_frequency: 'minutes',
    }

    reminders[timeInMs] = reminder;

    this.setState({
      ...this.state,
      reminders
    });
  }

  removeReminder = (id) => {
    if (this.state.event_id.length > 0) {
      this.props.deleteGroupEventReminders({ group_id: this.props.group_id, group_reminder_id: this.props.group_id + '_' + id });

      var reminders = this.state.reminders;
      var group_reminder_id = this.props.group_id + '_' + id;
      if (group_reminder_id in reminders) {
        delete reminders[group_reminder_id];
      }

      if (id in reminders) {
        delete reminders[id];
      }

      this.setState({ ...this.state, reminders });
    } else {
      var reminders = this.state.reminders;
      delete reminders[id];
      this.setState({ ...this.state, reminders });
    }

  }

  updateReminder = (event, id) => {
    if (this.state.event_id.length > 0) {
      var reminders = this.state.reminders;
      var group_reminder_id = this.props.group_id + '_' + id;

      if (group_reminder_id in reminders) {
        reminders[group_reminder_id][event.target.name] = event.target.value
        this.setState({ ...this.state, reminders });
      }
    } else {
      var reminders = this.state.reminders;
      reminders[id][event.target.name] = event.target.value
      this.setState({ ...this.state, reminders });
    }
  };

  submitEvent = () => {
    if (this.state.event_title === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter the event title ..."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.event_start_date === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter start date and time ..."
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.event_end_date === "") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please enter end date and time ..."
      };

      this.props.showToast(params);
      return;
    }

    if (Date.parse(this.state.event_start_epoch) >= Date.parse(this.state.event_end_epoch)) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Start date cannot be greater or equal to end date ..."
      };

      this.props.showToast(params);
      return;
    }

    var body = { ...this.state, ...this.props.group_details }

    this.props.postGroupEvent(body);

    this.handleClose()

  }

  render() {
    const { classes, group_id } = this.props
    const { event_title, event_description, event_start_date, event_end_date, open, event_location, reminders, event_dialog_title, send_email_immediately } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.headerDiv}>
          <Grid
            container spacing={2}
            style={
              { display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }
            }>
            <Grid item xs={12} sm={10}>
              <Typography
                className={classes.headerLabel}
                variant='subtitle2'
                gutterBottom
              >
                Events
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} style={{ textAlign: 'left' }}>
              <Button
                variant='outlined'
                color='primary'
                size='small'
                onClick={this.openDialog(1, 'Add Event: ')}
              >
                <AddIcon className={classes.leftIcon} fontSize='small' />
                Add Event
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <EventsListAdmin group_id={group_id} editEvent={this.editEvent} />
            </Grid>
          </Grid>
        </div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container spacing={2}>
              <Grid item xs={11} sm={11}>
                {event_dialog_title}
              </Grid>
              <Grid item xs={1} sm={1}>
                <IconButton color="secondary" aria-label="close" onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ margin: '0px 10px 0px 10px', height: "80vh" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  //autoFocus={true}
                  required
                  margin="dense"
                  id="name"
                  label="Event title"
                  type="text"
                  fullWidth
                  value={event_title}
                  onChange={event => {
                    const { value } = event.target;
                    this.setState({
                      event_title: value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="toCalendarLabel">
                <Typography variant='body2'>From</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePicker
                  selected={event_start_date}
                  onChange={date => {
                    var event_start_epoch = Date.parse(date)
                    this.setState({
                      event_start_date: date,
                      event_start_epoch
                    });
                  }}
                  className="calendarPicker"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant='body2'> - </Typography>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                <DatePicker
                  selected={event_end_date}
                  onChange={date => {
                    var event_end_epoch = Date.parse(date)
                    this.setState({
                      event_end_date: date,
                      event_end_epoch
                    });
                  }}
                  className="calendarPicker"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom className={classes.eventHeaderText}>
                      Event Details
                    </Typography>
                    <Grid item xs={12} sm={12}>
                      <Grid container style={{ alignItems: 'center', marginBottom: '12px' }}>
                        <Grid item>
                          <RoomOutlinedIcon />
                        </Grid>
                        <Grid item xs={11} sm={11} className="customTextField">
                          <TextField
                            id="event_location"
                            label="Add location"
                            size="small"
                            fullWidth
                            type="text"
                            inputProps={{
                              name: "event_location",
                              id: "event_location"
                            }}
                            onChange={e =>
                              this.handleChange(e, "event_location")
                            }
                            value={event_location}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Grid container style={{ marginBottom: '12px' }}>
                        <Grid item>
                          <NotificationsNoneOutlinedIcon />
                        </Grid>
                        <Grid item xs={11} sm={11}>
                          {Object.keys(reminders).map((reminder) => {
                            return (< Reminder key={reminder} updateReminder={this.updateReminder} removeReminder={this.removeReminder} reminder={reminders[reminder]} />)
                          })}
                          {/* {Object.keys(this.props.reminders).map((reminder, id) => {
                            console.log("reminder: ", reminder)
                            console.log("reminder index: ", id)
                            return (< Reminder key={reminder} updateReminder={this.updateReminder} removeReminder={this.removeReminder} reminder={this.props.reminders[reminder]} />)
                          })} */}
                          <Button style={{ color: '#3c4043', textTransform: 'none' }} onClick={this.addReminder}>Add notification</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Grid container style={{ marginBottom: '12px' }}>
                        <Grid item>
                          <SubjectIcon />
                        </Grid>
                        <Grid item xs={11} sm={11} className="customTextField">
                          <TextField
                            id="event_description"
                            label="Description"
                            fullWidth
                            multiline
                            rows="8"
                            type="text"
                            inputProps={{
                              name: "event_description",
                              id: "event_description"
                            }}
                            onChange={e =>
                              this.handleChange(e, "event_description")
                            }
                            value={event_description}
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={<Checkbox checked={send_email_immediately} onChange={e =>
                          this.handleChange(e, "send_email_immediately")
                        } name="send_email_immediately" />}
                        label="Send Email Immediately"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'flex-start' }}>
            <div>
              <Button color="secondary" onClick={this.handleClose}>CLOSE</Button>
              <Button variant="contained" color="primary" onClick={this.submitEvent}>SAVE</Button>
            </div>
          </DialogActions>
        </Dialog>

      </div>
    )
  }
}

Events.propTypes = {
  classes: PropTypes.object.isRequired,
}


const EventsStyled = withStyles(styles)(Events)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    group_events_reminders: state.users.group_events_reminders,
    reminders: state.users.group_events_reminders,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    postGroupEvent: params => dispatch(postGroupEvent(params)),
    getGroupEventsReminders: params => dispatch(getGroupEventsReminders(params)),
    deleteGroupEventReminders: params => dispatch(deleteGroupEventReminders(params)),
    showToast: params => dispatch(showToast(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsStyled)
