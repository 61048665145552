import {
    CHECK_TOKEN
  } from '../actions/types'
  
  const initialState = {
    hasToken: 'no'
  }
  const tokenReducer = (state = initialState, action) => {
    console.log('inside askpermission T :', action.type);
    switch (action.type) {
      case CHECK_TOKEN:
        console.log('greentokenusers 5 : ', action.hasaToken);
          return {
            ...state,
           type: CHECK_TOKEN,
           hasToken: action.hasaToken 
          }  
          break;                     
      default:
        return state
    }
  }
  export default tokenReducer
  