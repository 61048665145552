import React from "react";
import firebase from "../../store/firebase";
import { compileQuestions } from "../../store/actions/surveyQAction";
import { connect } from "react-redux";
import {
  SURVEY_Q_LIST
} from "../../store/actions/types";
import Answers from "./Answers";

class Questions extends React.Component {
  constructor(props) {
    super(props);
  }
 
   componentDidMount() {
     this.getQ();
    }

   getQ = () => {
     var isCompanyID;
      if (this.props.real_companyID) {
        isCompanyID = this.props.real_companyID;
      }
       else
      {
        isCompanyID = this.props.companyID;
      }
        var body = {
          type: SURVEY_Q_LIST,
          companyID: isCompanyID, //this.props.companyID,
          surveyid: this.props.surveyid       
          }

       this.props.compileQuestions(body);  //survey_Q_list        
    }

  render() {
    const { survey_Q_list } = this.props;
    console.log('blue this.props.surveyid: ', this.props.surveyid);

      return (
      <React.Fragment>
      <div id="questions"
          style={{
           marginLeft: '40px',
           width: '100%',
           align: 'center'
          }}>
        {this.props.questionNumber===-1?
          <div>          
               <Answers
                key={this.props.surveyid}
                surveyid={this.props.surveyid}
                companyID={this.props.real_companyID ? this.props.real_companyID: this.props.companyID}
                questionNumber={this.props.questionNumber}
                personid={this.props.personid}
                checkingCorrectAnswerx={this.props.checkingCorrectAnswerx}
              />                     
          </div>        
        :null}

        {survey_Q_list && Object.keys(survey_Q_list).map((QandA, id) => {
          const questionNumber = String("QandA"+(parseInt(this.props.questionNumber) + 1));
          //questionNumber = String(questionNumber);
          console.log('green question: ', questionNumber);
          
          if (id==this.props.questionNumber) {
           return (
            <div>
              <h4 style={{
                marginLeft: '-40px',
                width: '350px',
                align: 'center',
                fontSize: '25px',
                color: '#000000'
              }}>
            {/* {survey_Q_list[QandA].question}<span>?</span> */}
            {survey_Q_list[QandA].question}?
            </h4>     
            <div style={{
                marginLeft: '-50px',
                width: '300px',
                align: 'center'
              }}>      
               <Answers
                key={this.props.surveyid}
                surveyid={this.props.surveyid}
                companyID={this.props.real_companyID ? this.props.real_companyID: this.props.companyID}
                questionNumber={this.props.questionNumber}
                QandA={survey_Q_list[QandA]}
                personid={this.props.personid}
                checkingCorrectAnswer={this.props.checkingCorrectAnswer}
              />
              </div>
            </div>
            ) 
          }
          // else {
          //   console.log('blue id: ', id);
          //   if (this.props.questionNumber===-1) {
          //        return (
          //     <div>
          //       <p>here we are!</p>              
          //     </div>
          //     )          
          //   }
           
          // }
        })}
      </div>

    </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    survey_Q_list: state.surveyQ.survey_Q_list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    compileQuestions: params => dispatch(compileQuestions(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
