import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  title: {
    fontSize: 15,
    fontWeight: 600,
    padding: "0px 8px",
    marginBottom: "6px",
    color: "#000000"
  },
  subtitle: {
    fontSize: 12,
    fontStyle: "italic",
    padding: "3px",
    color: "#000000"
  }
});

class CardsMessage extends React.Component {
  render() {
    const { classes, title, subtitle, color } = this.props;
    return (
      <React.Fragment>
        <div className={classes.title}>{title}</div> 
        <div className={classes.subtitle}>{subtitle}</div>
      </React.Fragment>
    );
  }
}

CardsMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default withStyles(styles)(CardsMessage);
