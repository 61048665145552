import React from "react";
import { connect } from "react-redux";

import { resetUserPassword } from "../../store/actions/authActions";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
// Custom Components
import Companylogo from "../../components/layout/Companylogo";
import classNames from "classnames";

import { LIGHTBLUE, HOVERBLUE } from "../../utils/color_utils";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(320 + theme.spacing.unit * 3 * 2)]: {
      width: 320,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  button: {
    margin: `${theme.spacing.unit * 2}px 0px`,
    color: LIGHTBLUE
  },
  authCardHeading: {
    margin: "10px 15px"
  },
  authCardTitle: {
    textTransform: "uppercase",
    fontStyle: "bold",
    margin: theme.spacing.unit
  },
  submitButton: {
    marginTop: theme.spacing.unit * 3,
    padding: "15px 0",
    backgroundColor: LIGHTBLUE,
    "&:hover": {
      backgroundColor: HOVERBLUE
    }
  }
});

function AuthCardHeader(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.authCardHeading)}>
      <Companylogo />
      <Typography
        className={classNames(classes.authCardTitle)}
        component="h6"
        variant="body2"
        align="center"
      >
        Leadership Platform
      </Typography>
      <Typography component="h5" variant="h5" align="center">
        Forgot your password?
      </Typography>
    </div>
  );
}

class ForgotPassword extends React.Component {
  state = {
    email: ""
  };

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value });
  };

  resetPassword = e => {
    e.preventDefault();

    var params = {
      email: this.state.email,
      actionCodeSettings: {
        url: window.location.origin
      }
    };

    this.props.resetPassword(params);
    this.props.history.push("/");
  };

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <AuthCardHeader {...this.props} />

          <Typography variant="caption" gutterBottom align="center">
            To reset password. enter your email, presss the button and check
            mail to follow instructions.
          </Typography>

          <form className={classes.form} onSubmit={this.resetPassword}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
               // autoFocus={true}
                onChange={this.handleChange("email")}
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              Reset Password
            </Button>

            <Button
              component={Link}
              to="/"
              className={classes.button}
              fullWidth
            >
              Back to login
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

const ForgotPasswordModule = withStyles(styles)(ForgotPassword);

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: params => dispatch(resetUserPassword(params))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ForgotPasswordModule);