import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const data_types = ["Messages", "Articles", "Podcasts", "Videos", "PLDP"];
const data_types_obj = {
  thoughts: "Messages",
  articles: "Articles",
  podcasts: "Podcasts",
  videos: "Videos",
  pldp: "PLDP"
};


function createData(name, count) {
  return { name, count };
}


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function MostVisitedPagesTable(props) {
  const classes = useStyles();

  const [data_rows, setDataRows] = useState([]);

  function handleStatusChange(prop_data) {
    let graph_d = [];

    let visited_pages = prop_data && prop_data.analytics && prop_data.analytics.visited_pages ? prop_data.analytics.visited_pages : {};

    // console.log("analytics here 2: ", visited_pages);

    for (const visited_page in visited_pages) {

      let topic_title = visited_page ? visited_page : "not_specified"
      let topic_total = visited_pages[visited_page] ? visited_pages[visited_page] : 0

      graph_d.push(createData(topic_title, topic_total));


      // console.log("topics: ", `${visited_page}: ${visited_pages[visited_page]}`);
    }

    setDataRows(graph_d);
  }

  useEffect(() => {
    handleStatusChange(props);
  }, [props]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Page Name</StyledTableCell>
            <StyledTableCell align="center">Visits</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data_rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.count}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
