/* eslint-disable no-inline-comments */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import {
  withStyles,
  Typography,
  Grid,
  Button,
  IconButton,
  TextField,
  Switch,
  FormGroup,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  ExpansionPanelActions,
  Divider
} from '@material-ui/core'
import { showToast, clearStates } from '../../../store/actions/toastAction'
import {
  SHOW_TOAST
} from '../../../store/actions/types'
import { LIGHTGREY } from '../../../utils/color_utils'
import CategoriesSelect from '../../../components/categories/categoriesSelect'
import { addMorePoints } from '../../../store/actions/freshCardActions'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paperMotivation: {
    backgroundColor: '#ffffff',
    height: '100%',
    flexGrow: 4
  },
  motivationHeader: {
    padding: '10px 14px!important'
  },

  headerFooterColor: {
    marginTop: 20,
    backgroundColor: `#fff`
  },

  colorTextSecondary: {
    color: '#000'
  },
  motivationContent: {
    height: '100%',
    padding: '10px'
  },
  fab: {
    position: 'relative',
    top: -8,
    float: 'left'
  },
  arrowIcon: {
    color: '#ffffff'
  },
  textField: {
    marginRight: theme.spacing.unit
  },
  textDense: {},
  margin: {
    margin: theme.spacing.unit,
    padding: '10px 30px'
  }
})
window.moment = moment
class Task extends Component {
  state = {
    pristine: false,
    reminder: false,
    frequency: 0,
    // Task Data
    moveActionDesc: '',
    reminderFrequency: 'Does not repeat',
    stringReminderDate: '',
    stringReminderTime: '',
    reminderDate: 0,
    reminderOn: '',
    moveAction: '',
    title: ' ',
    subcompetency: '',
    subcompetencyid: '',
    competency: '',
    competencyid: ''
  };

  static FREQUENCY = [
    'Does not repeat',
    'Hourly',
    'Daily',
    'Weekly',
    'Monthly'
  ];

  componentDidMount() {
    if (this.props.hasTask) {
      const { task } = this.props

      this.setState({
        moveActionDesc: task.moveActionDesc,
        title: task.title,
        reminder: task.reminderOn,
        stringReminderDate: moment(task.reminderDate).format('YYYY-MM-DD'),
        stringReminderTime: moment(task.reminderDate).format('HH:mm'),
        reminderFrequency: task.reminderFrequency,
        subcompetency: task.subcompetency,
        subcompetencyid: task.subcompetencyid,
        competency: task.moveActionDesc,
        competencyid: task.moveAction,
        moveActionDesc: task.moveActionDesc,
        moveAction: task.moveAction
      })
    } else {
      this.setState({
        stringReminderDate: moment().format('YYYY-MM-DD'),
        stringReminderTime: moment().format('HH:mm'),
        reminder: false
      })
    }
  }

  handleReminderToggle = name => event => {
    this.setState({ [name]: event.target.checked })
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };
  splitDate(date, char) {
    return date.split(char)
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
   charactersLength));
    }
    return result;
  }

  handleSaveClick = () => {
    if (this.state.title.trim() == '') {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please enter the action..'
      }
      this.props.showToast(params)
      return
    }

    if (this.state.competencyid.trim() == '') {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please select a competency option..'
      }

      this.props.showToast(params)
      return
    }

    if (this.state.subcompetencyid.trim() == '') {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please select a sub competency option..'
      }

      this.props.showToast(params)
      return
    }

    const {
      task,
      journalUser,
      OnSaveTask,
      updateOnSave,
      companyValue
    } = this.props
    const {
      title,
      stringReminderDate,
      stringReminderTime,
      reminderFrequency,
      reminder
    } = this.state

    let getDatePart = this.splitDate(stringReminderDate, '-')

    let actualDate =
      getDatePart[1] +
      '/' +
      getDatePart[2] +
      '/' +
      getDatePart[0] +
      ' ' +
      stringReminderTime +
      ':00'

    let fullReminderDateStr = new Date(actualDate)
    let reminderDate = Date.parse(fullReminderDateStr)

    let newTask = {
      companyID: journalUser.companyID,
      companyName: journalUser.companyName,
      dateRegistered: Date.now(),
      journalUserID: journalUser.userID,
      journalUserName: journalUser.firstName
        ? journalUser.firstName
        : '' + ' ' + journalUser.lastName
          ? journalUser.lastName
          : '',
      moveAction: this.state.competencyid,
      moveActionDesc: this.state.competency,
      subcompetencyid: this.state.subcompetencyid,
      subcompetency: this.state.subcompetency,
      reminderDate: reminderDate,
      reminderFrequency: reminderFrequency,
      reminderOn: reminder ? true : false,
      title: title,
      photoURL: journalUser.photoURL ? journalUser.photoURL : '',
      uid: journalUser.uid,
      user_type: journalUser.userType,
      myPLDPID: task ? task.myPLDPID : '',
      notificationID: task ? task.notificationID : '',
      stringReminderTime,
      stringReminderDate
    }
    OnSaveTask(newTask, updateOnSave)
    // ////ADD POINTS///////////////////////////////
    console.log('green updateOnSave : ', journalUser)
    console.log('green newTask : ', newTask)
    if (updateOnSave !== true && journalUser.execmember === true) {
      var params = {
        uid: journalUser.uid,
        userID: journalUser.userID,
        pointsCurrent: journalUser.points,
        ypointsCurrent: journalUser.pointsy,
        pointsAdded: 10,
        trailID: this.makeid(10),
        companyName: journalUser.companyName,
        firstName: journalUser.firstName,
        lastName: journalUser.lastName,
        title: title,
        actionType: 'PLFP Task added',
        actionDesc: 'PLFP Task'
      }
      this.props.addMorePoints(params)
    }
  };

  // function to go with CategoriesSelect ///
  handleCompState = (scname, scid, type) => {
    if (type === 'competency') {
      this.setState({
        ...this.state,
        competency: scname,
        competencyid: scid,
        moveActionDesc: scname,
        moveAction: scid
      })
    } else {
      this.setState({
        ...this.state,
        subcompetency: scname,
        subcompetencyid: scid
      })
    }
  }

  render() {
    const {
      classes,
      companyValue,
      onBackClick,
      OnSaveTask,
      updateOnSave,
      journalUser,
      title,
      task
    } = this.props

    if (this.props.hasTask) {
      // console.log('tast 2: ', task);
    }

    return (
      <div variant='outlined' elevation={0} className={classes.paperMotivation}>
        <div className={classes.motivationHeader}>
          <Typography variant='h6'>{title}</Typography>
          <Divider />
        </div>

        <div className={classes.motivationContent}>
          <form>
            <FormGroup row>
              <List className={classes.root}>
                <ListItem>
                  <TextField
                    error={this.state.title.length === 0}
                    className={classes.textField}
                    id='standard-full-width'
                    label='Record Actions/ Learnings/ Impact'
                    fullWidth
                    multiline
                    inputProps={{
                      name: 'title',
                      id: 'title'
                    }}
                    helperText={
                      this.state.title.length === 0
                        ? 'Take Action is a required field'
                        : ''
                    }
                    onChange={e => this.handleChange(e, 'title')}
                    value={this.state.title}
                    margin='normal'
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </ListItem>
                <ListItem>

                  {/* Add competency dropdown here */}
                  {this.props.hasTask ?
                    <CategoriesSelect
                      competency={task.moveActionDesc}
                      competencyid={task.moveAction}
                      subcompetency={task.subcompetency}
                      subcompetencyid={task.subcompetencyid}
                      keepsubcomp={false}
                      pldp={true}
                      doCompState={this.handleCompState}
                      companyID={journalUser.companyID}
                      displayComp={this.state.subcompetency} />
                    :
                    <CategoriesSelect
                      competency={this.state.competency}
                      competencyid={this.state.competencyid}
                      subcompetency={this.state.subcompetency}
                      subcompetencyid={this.state.subcompetencyid}
                      keepsubcomp={false}
                      pldp={true}
                      doCompState={this.handleCompState}
                      companyID={journalUser.companyID}
                      displayComp={this.state.subcompetency} />
                  }

                </ListItem>
                <ListItem>
                  <ListItemText
                    dense='true'
                    primary={
                      <Typography variant='caption'>Enable Reminder</Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      onChange={this.handleReminderToggle('reminder')}
                      checked={this.state.reminder}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem hidden={!this.state.reminder}>
                  <Grid container spacing={16}>
                    <Grid item xs={12}>
                      <ListItemText
                        dense='true'
                        primary={
                          <Typography variant='caption'>
                            Schedule Date & Time
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <TextField
                        type='date'
                        inputProps={{
                          name: 'stringReminderDate',
                          id: 'stringReminderDate'
                        }}
                        onChange={e =>
                          this.handleChange(e, 'stringReminderDate')
                        }
                        value={this.state.stringReminderDate}
                        className={classes.textField}
                        id='standard-full-width'
                        label='Date'
                        fullWidth
                        margin='normal'
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField
                        type='time'
                        className={classes.textField}
                        id='standard-full-width'
                        label='Time'
                        value={this.state.stringReminderTime}
                        inputProps={{
                          name: 'stringReminderTime',
                          id: 'stringReminderTime'
                        }}
                        onChange={e =>
                          this.handleChange(e, 'stringReminderTime')
                        }
                        fullWidth
                        margin='normal'
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem hidden={!this.state.reminder}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='age-simple'>Frequency</InputLabel>
                    <Select
                      value={this.state.reminderFrequency}
                      onChange={e => this.handleChange(e, 'frequency')}
                      inputProps={{
                        name: 'reminderFrequency',
                        id: 'reminderFrequency'
                      }}
                    >
                      <MenuItem value={'Does not repeat'}>
                        Does not repeat
                      </MenuItem>
                      <MenuItem value={'Hourly'}>Hourly</MenuItem>
                      <MenuItem value={'Daily'}>Daily</MenuItem>
                      <MenuItem value={'Weekly'}>Weekly</MenuItem>
                      <MenuItem value={'Monthly'}>Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
            </FormGroup>
            <div>
              <ExpansionPanelActions className={classes.headerFooterColor}>
                <Button
                  onClick={this.props.onClose}
                  variant='outlined'
                  color='secondary'>
                Cancel
                </Button>
                <Button
                  onClick={this.handleSaveClick}
                  variant='outlined'
                  color='primary'>
                Save
                </Button>
              </ExpansionPanelActions>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

Task.propTypes = {
  companyValue: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired
}

const TaskStyled = withStyles(styles)(Task)

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    addMorePoints: params => dispatch(addMorePoints(params))
  }
}

export default connect(
  null,
  mapDispatchToProps
)(TaskStyled)
