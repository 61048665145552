import { GET_O, GET_O_S, GET_SLAVE_OPINION, GET_OPINIONID, GET_SL } from '../actions/types';

const initState = {
  opinion: {},
  opinion_sl: {},
  feedback: {},
  opinionid: '',
  sopinion: {},
};

const opinionReducer = (state = initState , action) => {
  switch(action.type){
    case GET_O:
      console.log('opinionReducer : ', action.opinion)
      return{ 
        ...state,
        type: "GET_O",
        opinion: action.opinion
      }
    case GET_SL:
        console.log('opinionReducerp : ', action.opinion_sl)
        return{ 
          ...state,
          type: "GET_SL",
          opinion_sl: action.opinion_sl
        }  
    case GET_O_S:
      console.log('green putOpinions reducer: ', action.sopinion)
      return{ 
        ...state,
        type: "GET_O_S",
        sopinion: action.sopinion
      }
    case GET_SLAVE_OPINION:
      console.log('green opinionReducer : ', action.slaveopinion)
      return{ 
        ...state,
        type: "GET_SLAVE_OPINION",
        feedback: action.slaveopinion 
      }
    case GET_OPINIONID:
      return{ 
        ...state,
        type: "GET_OPINIONID",
        opinionid: action.opinion
      }
    default:
      return state
  }
  
}

export default opinionReducer
