import { GLP_LOGGED_COMPANY, GLP_LOGGED_PROFILE } from '../actions/types'

let currentCompany = {}
let currentUser = {}

try {
  currentCompany = JSON.parse(localStorage.getItem(GLP_LOGGED_COMPANY))
  currentUser = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE))
} catch (err) {
  currentCompany = {}
  currentUser = {}
}

const initState = {
  companydata: currentCompany,
  userdata: currentUser
}

const loggedInCompanyReducer = (state = initState, action) => {
  switch (action.type) {
  case GLP_LOGGED_COMPANY:
    console.log('black signInWithGoogle 4: ', currentUser)
    return {
      ...state,
      userdata: currentUser
    }
  case 'FETCHED_COMPANY_DATA':
    return {
      ...state,
      companydata: action.companydata
    }
  default:
    return {
      ...state
    }
  }
}

export default loggedInCompanyReducer
