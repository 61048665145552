import { GET_POE } from '../actions/types';

const initState = {
    type: GET_POE
};

const poeReducer = (state = initState , action) => {
  switch(action.type){
    case GET_POE:
      return{ 
        ...state,
        type: "GET_POE",
        poe: action.poe
      }
    default:
      return state
  }
  
}

export default poeReducer