/* eslint-disable guard-for-in */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable no-inline-comments */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react'
// import moment from 'moment'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import CancelIcon from '@material-ui/icons/Cancel'
import { Row } from '@mui-treasury/components/flex'
import SendIcon from '@material-ui/icons/Send'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ScheduleIcon from '@material-ui/icons/Schedule'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import Avatar from '@material-ui/core/Avatar'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import ThoughtsAttachment from './ThoughtsAttachments'

// IMPORT GROUP DROPDOWN
import Groupselect from '../groups/groupselect'
// import LoadSocialNetworks from "../../components/socialappmodal";

import {
  _DEFAULT_DATE_TIME,
  formatDateTimeMacIOS
} from '../../utils/date_format_utils'
import { GREY, RED, LIGHTBLUE, LIGHTGREY } from '../../utils/color_utils'
// import { PHOTO_BACKGROUND, PHOTO_PROFILE } from '../../utils/photo_utils'
import { showToast, clearStates } from '../../store/actions/toastAction'
import { createThought, createThought_IOS } from '../../store/actions/thoughtsActions'
import { createVideo } from '../../store/actions/videosActions'
import { createPodcast } from '../../store/actions/podcastsActions'
import { createArticle } from '../../store/actions/articlesActions'
import { addMorePoints } from '../../store/actions/freshCardActions'
import { refreshCard } from '../../store/actions/SurveyActions'
//import { simpleAction } from "../../store/actions/simpleAction";
// import { storeSubCompetency } from "../../store/actions/subcompActions";
import {
  APP_TYPE,
  SHOW_TOAST,
  CREATE_THOUGHT_SUCCESS,
  RESTRICTED_USER_TYPES,
  CAN_POST_ORG_ILEAD,
  CAN_POST_GLOBAL,
  CAN_POST_DAILY,
  CAN_POST_INTERNAL,
  ACCESS_COMPETENCIES,
  IS_DELEGATE_USER,
  HIDE_POSTING_TO_ILEAD,
  GLP_LOGGED_PROFILE,
  INPUT_HASHTAG
 // SIMPLE_VALUES
} from '../../store/actions/types'
import { isURL } from '../../utils'
import AdvancedSettings from './AdvancedSettings'
import YearSelect from '../DateSelector/YearSelect.js'
import MonthSelect from '../DateSelector/MonthSelect.js'
import DaySelect from '../DateSelector/DaySelect.js'
import HourSelect from '../DateSelector/HourSelect.js'
import MinuteSelect from '../DateSelector/MinuteSelect.js'
import CategoriesSelect from '../categories/categoriesSelect'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const theme = createTheme({
  overrides: {
    MuiPickersClock: {
      clock: {
        backgroundColor: '#cdcbcb'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#000000'
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: '#000000'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#000000'
      }
    },
    MuiDialogActions: {
      spacing: {
        backgroundColor: '#cdcbcb'
      }
    }
  }
})


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  titleColor: {
    color: `${LIGHTBLUE}`,
    fontSze: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  divStyle: {
    // border: `1px solid ${LIGHTGREY}!important;`,
    margin: '0 14px',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      margin: '0 4px',
      padding: '0px 6px'
    }
  },
  dialogHeight: {
    maxHeight: `calc(100% - 20px)`
  },
  cats: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  maintextField: {
    'width': '100%',
    'marginTop': 30,
    'borderRadius': 4,
    'backgroundColor': '#fcfcfb',
    'borderColor': '#fbfbfb',
    // 'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff'
    },
    '&$focused': {
      backgroundColor: '#fff',
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: '#fff'
    }
  },
  rightIcon: {
    paddingRight: '8px'
  }
})

function MaterialUIPickers(props) {
  let tempDate // = new Date();

  if (props.dss) {
    tempDate = new Date(props.dss)
  } else {
    tempDate = new Date()
  }

  // console.log('tempDate = ',tempDate);
  let date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds()
  const [selectedDate, setSelectedDate] = React.useState(date)
  const [selectedTime, setSelectedTime] = React.useState(date)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify='space-around'>
        <KeyboardDatePicker
          margin='normal'
          id='date-picker-dialog'
          label='Date picker dialog'
          // format="MM/dd/yyyy"
          format='yyyy/dd/MM'
          value={selectedDate}
          onChange={(val) => {
            props.handleDateChange(val)
            setSelectedDate(val)
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
        <KeyboardTimePicker
          margin='normal'
          id='time-picker'
          label='Time picker'
          value={selectedTime}
          onChange={(val) => {
            props.handleTimeChange(val)
            setSelectedTime(val)
          }}
          KeyboardButtonProps={{
            'aria-label': 'change time'
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

const dialogstyles = (theme) => ({
  root: {
    margin: 0,
    height: 50,
    marginBottom: 20
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const CustomDialogTitle = withStyles(dialogstyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})


class ThoughtsDialog extends React.Component {
  constructor(props) {
    super(props)
    if (this.props.post === null) {
      // console.log('props for thoughtsDialog: ', this.props);
    } else {
      // console.log('props for thoughtsDialog competencyid: ', this.props.post)
    }
  }

  getImg() {
    if (this.props.post) {
      return this.getURL(this.props.post.photos) // there is an image
      // console.log('there is an image')
    }

    return ''
    // console.log('there is NO image')            //no image
  }

  getURL(props) {
    for (let x in props) {
      return props[x].url
    }
  }

  getTempDate() {
    if (this.props.dss) {
      return (new Date(this.props.dss))
    }
    return (new Date())
  }
  tempDate = this.getTempDate()
 date = this.tempDate.getFullYear() + '-' + (this.tempDate.getMonth() + 1) + '-' + this.tempDate.getDate() + ' ' + this.tempDate.getHours() + ':' + this.tempDate.getMinutes() + ':' + this.tempDate.getSeconds()


  state = {
    schedulePanelOpen: false,
    maxLengthThought: '0/280',
    maxLengthAuthor: '0/100',
    isHidden: true,
    postInternalGlobal: IS_DELEGATE_USER[this.props.userdata.userType] ? 'postGroup' : 'postInternal',
    uploadInProgress: false,
    selectedDate: '',
    selectedTime: '',
    groupid: '',
    groupname: '',
    multiplegroups: false,
    selectedgroups: [],
    textColor: '#000',
    website: '',
    slogan: '',
    logo: '',

    // body data to be posted
    PodcastExternalRecord: 'podcastnone',
    VidUploadExternal: 'videonone',
    dailyThoughtType: '1',
    dateRegistered: 0,
    dateScheduled: 0,
    subtitle: '',
    title: '',
    podcasturl: {},
    audiourl: {},
    // imageurl: {},
    imageurle: this.getImg(),
    imageurl: { imageurl: this.getImg() },
    fileurl: {},
    videourl: {},

    videosObj: [],
    podcastsObj: [],
    articlesObj: [],
    filesObj: [],

    podcastType: 2,
    show: false,
    // competency: this.props.competency,
    // competencyid: this.props.competencyid,
    // subcompetency: this.props.subcompetency, //this.props.fscompname,
    // subcompetencyid: this.props.subcompetencyid //this.props.subcompFilter
    competency: '',
    competencyid: '',
    subcompetency: '', // this.props.fscompname,
    subcompetencyid: '', // this.props.subcompFilter
    dateScheduledPost: '',
    selectedTimeShown: this.date,
    selectedDateShown: this.date,
    iphonemsg: ''
  };

  toggleOn = () => {
    const { show } = this.state
    this.setState({
      show: true
      // postInternalGlobal: radio
    })
  };

  toggleOff = () => {
    const { show } = this.state
    this.setState({
      show: false
      // postInternalGlobal: radio
    })
  };

  MaterialUIPickers(props) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify='space-around'>
          <KeyboardDatePicker
            margin='normal'
            id='date-picker-dialog'
            label='Date picker dialog'
            // format="MM/dd/yyyy"
            format='yyyy/dd/MM'
            value={this.state.selectedDateShown}
            onChange={(val) => {
              props.handleDateChange(val)
              // setSelectedDateShown(val)
              this.setState({ selectedDateShown: val })
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
          <KeyboardTimePicker
            margin='normal'
            id='time-picker'
            label='Time picker'
            value={this.state.selectedTimeShown}
            onChange={(val) => {
              props.handleTimeChange(val)
              // setSelectedTimeShown(val)
              this.setState({ selectedTimeShown: val })
            }}
            KeyboardButtonProps={{
              'aria-label': 'change time'
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    )
  }

   cancelSchedule = () => {
     this.setState({
       selectedDate: '',
       selectedTime: '',
       dateScheduledPost: '',
       dateRegistered: ''
     })
   }

  handleTimeChange = (date) => {
    const time = date.getHours() + ':' + date.getMinutes()
    // setSelectedTime(time)
    this.setState({
      selectedTime: time
    })
  }

  handleDateChange = (date) => {
    const dateSet = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    // setSelectedDate(dateSet)
    this.setState({
      selectedDate: dateSet
    })
  }

  handleSetSchedule = () => {
    if (this.state.selectedDate === '' || this.state.selectedTime === '') {
      let tempDate = Date.parse(new Date(this.state.selectedDateShown))
      console.log('[temp date]', tempDate)
      // setDateScheduledPost(`${selectedDate} ${selectedTime}`)
      // setDisableDoneButton(false)
      // setSchedulePanelOpen(true)
      this.setState({
        dateScheduledPost: `${this.state.selectedDate} ${this.state.selectedTime}`
      })
    } else {
      let tempDate = Date.parse(new Date(`${this.state.selectedDate}:${this.state.selectedTime}`))
      console.log('[temp date]', tempDate)
      // setDateScheduledPost(`${selectedDate} ${selectedTime}`)
      // setDisableDoneButton(false)
      // setSchedulePanelOpen(true)
      this.setState({
        dateScheduledPost: `${this.state.selectedDate} ${this.state.selectedTime}`
      })
    }
  }

  // componentWillMount() {
  //   if (this.props.competencyid) {
  //     this.setState({competencyid: this.props.competencyid})
  //   }
  //   if (this.props.subcompetencyid) {
  //     this.setState({subcompetencyid: this.props.subcompetencyid})
  //   }
  // }

  //  // function to go with CategoriesSelect //
  //   handleCompState = (scname, scid) => {
  //     // console.log('inside handleCompState x:', scname);
  //      this.setState({subcompetencyid: scname, subcompetencyid: scid});
  //    }

  // function to go with CategoriesSelect ///
  handleCompState = (scname, scid, type) => {
    if (type === 'competency') {
      this.setState({
        ...this.state,
        competency: scname,
        competencyid: scid,
        subcompetency: '',
        subcompetencyid: ''
      })
    } else {
      this.setState({
        ...this.state,
        subcompetency: scname,
        subcompetencyid: scid
      })
    }
  }

  handleValueChange = prop => event => {
    // postInternalGlobal
    //let {simpleAction } = this.props
    console.log('green post 3 : ', event.target.value)
    console.log('green post 4 : ', prop)
    var dailyT;

    if (prop === 'postInternalGlobal') {
      this.setState({
        dailyThoughtType:
          event.target.value === 'postInternal'
            ? 1
            : event.target.value === 'postGlobally'
              ? 2
              : event.target.value === 'postILead'
                ? 3
                : 4,
        [prop]: event.target.value
      })
      ///////////////////////////////
      switch(event.target.value) {
        case 'postInternal':
          dailyT = 1;
          break;
        case 'postGlobally':
          dailyT = 2;
          break;
        case 'postILead':
          dailyT = 3;
          break;
        default:
          dailyT = 4;
      } 
      this.setState({dailyThoughtType: dailyT})
      ///////////////////////////////
      // var body = {
      //   type: SIMPLE_VALUES,
      //   surveyid: dailyT      
      //   }
      // simpleAction(body);

    //console.log('green post A : ', dailyT);

      if (event.target.value !== 'postGroup') {
        this.setState({
          groupid: '',
          groupname: '',
          multiplegroups: false,
          selectedgroups: [],
          dailyThoughtType: dailyT
        })
      }
    }
  };

  handleURLChanged(prop, value) {
    this.setState({
      [prop]: value
    })
  }

  handleExternalUpload(value) {
    if (value === 'audio') {
      this.setState({
        PodcastExternalRecord: 'upload',
        podcastType: 1
      })
    } else {
      this.setState({
        PodcastExternalRecord: value,
        podcastType: 2
      })
    }
  }

  handleGroupsChange(value, name, selectedgroups, multiplegroups) {
    this.setState({ groupid: value, groupname: name, selectedgroups, multiplegroups })
  }

  handleCompetencyChange(value, name) {
    // console.log('handleCompetencyChange value:', value);
    // console.log('handleCompetencyChange name:', name);
    this.setState({ compid: value, competency: name })
  }

  carrycid(event, name) {
    this.setState({ competencyid: event.value })
  }

  cancelSchedule() {
    this.setState({
      dateRegistered: 0,
      dateScheduled: 0,
      selectedDate: '',
      selectedTime: ''
    })
  }

  handleChange = panel => (event, newExpanded) => {
    this.setState({
      schedulePanelOpen: newExpanded
    })
  };

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
  }

  getURL(props) {
    for (let x in props) {
      return props[x].url
    }
  }

  getURLv(props) {
    for (let x in props) {
      return props[x].videourl
    }
  }

  getMobileOS() {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return "iOS"
    }
    return "Other"
  }

  submitPost = (e) => {
    e.preventDefault()
    console.log('[props for ThoughtsDialog :]', this.props)
    // console.log('submit post', this.props, this.state);
    // console.log('submit post', this.props.year, this.props.month, this.props.day, this.props.hour, this.props.minute);
    if (this.state.uploadInProgress) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please wait, Upload in progress..'
      }

      this.props.showToast(params)
      return
    }

    // ///////////////////
    //if (this.state.subcompetencyid === '' || this.state.subcompetencyid == null) {
      if (this.state.competencyid === '' || this.state.competencyid == null) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please add a Category to your post..'
      }

      this.props.showToast(params)
      return
    }
    // ////////////////////////

    // // console.log('this.state.imageurl.imageurl:', this.state.imageurl.imageurl)
    if (
      !this.state.title.trim() &&
      !this.state.imageurl.imageurl 
      //&& !this.state.subtitle.trim()
    ) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please provide thought message or attach an image template'
      }

      this.props.showToast(params)
      return
    }

    if (this.state.groupid == '' && this.state.dailyThoughtType == '4') {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please select a group!'
      }

      this.props.showToast(params)
      return
    }

    if (this.state.groupid == '1' && this.state.dailyThoughtType == '4' && this.state.selectedgroups.length === 0) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please select a group!'
      }

      this.props.showToast(params)
      return
    }

    let actualScheduleDate
    let dateRegistered = Date.now()
    // If scheduled validate date
    if (this.props.editing) {
      dateRegistered = this.props.post.dateRegistered

      if (this.props.post.dateScheduled > this.state.dateScheduledPost) {
        // eslint-disable-next-line no-sequences
        actualScheduleDate = this.props.post.dateScheduled
      }
    }

    if (this.state.dateScheduledPost === '') {
      // actualScheduleDate = dateRegistered
      actualScheduleDate = Date.parse(new Date(this.date))
    } else {
      actualScheduleDate = this.state.dateScheduledPost
      actualScheduleDate = Date.parse(new Date(this.state.dateScheduledPost))
      console.log('[date scheduled]', actualScheduleDate)
    }


    if (this.props.editing) {
      dateRegistered = this.props.post.dateRegistered

      if (this.props.post.dateScheduled > this.state.dateScheduled) {
        this.state.dateScheduled = this.props.post.dateScheduled
      }
    }

    // if (this.state.schedulePanelOpen) {
    //   dateScheduled = Date.parse(new Date(this.props.year + '/' + this.props.month + '/' + this.props.day + ' ' + this.props.hour + ':' + this.props.minute))
    // } else {
    //   dateScheduled = Date.now()
    // }

    // dateScheduled = moment().year(this.props.year).month(this.props.month).date(this.props.day).hour(this.props.hour).minute(this.props.minute)
    // console.log('old dateScheduled', dateScheduled)

    // dateScheduled = Date.parse(new Date(this.props.year + '/' + this.props.month + '/' + this.props.day + ' ' + this.props.hour + ':' + this.props.minute))
    if (this.state.dateScheduledPost === '') {
      // actualScheduleDate = dateRegistered
      actualScheduleDate = Date.parse(new Date(this.date))
    } else {
      // actualScheduleDate = this.state.dateScheduledPost
      actualScheduleDate = Date.parse(new Date(this.state.dateScheduledPost))
      console.log('[date scheduled]', actualScheduleDate)
    }
    // console.log('new dateScheduled', dateScheduled)
    console.log('green xxxx props : ', this.props.userdata)

    let body = {
      ...this.state,
      dateRegistered,
      dateScheduled: actualScheduleDate,
      editing: this.props.editing || false,
      post: this.props.post || false,
      uid: this.props.userdata.uid,
      points: this.props.userdata.points ? this.props.userdata.points : '0',
      pointsCalf: this.props.userdata.pointsCalf ? this.props.userdata.pointsCalf : '0',
      stage: 1, // no test published yet
      real_companyID: this.props.userdata.companyID,
      execmember: this.props.userdata.execmember ? this.props.userdata.execmember : false,
      // points: this.props.userdata.points ? this.props.userdata.points : '',
      companyID_subcompetencyid: this.props.userdata.companyID + '_' + this.state.subcompetencyid,
    }
    this.setState({
      selectedDate: '',
      selectedTime: ''
    })

    // Post Thought
    // videosObj
    // videourl
    console.log('purple primary createThought 1 : ', body)
    // console.log('purple primary createThought 1 : ', body.videosObj)
    // console.log('purple primary createThought 2 : ', this.getURL(body.videosObj))
    // console.log('purple primary createThought 3 : ', body.videourl)
    // console.log('purple primary createThought 4 : ', body.videourl.videourl)

    //console.log('purple post data : ', this.props)
    ////////////////////////////////////////////////////////////////////////////////////////
     var lead = body.title;
      var hash1 = lead.indexOf("#"); //8
      var hashtagsx = lead.slice(hash1); //  #Hello#world
      var hashtagsy = hashtagsx.slice(1);// Hello#world
      var hash2 = hashtagsy.indexOf("#"); //get pos of second hash
      if (hash2===-1) {
        var hashtagsz = hashtagsy.slice(0);// Hello //// 
      } else {
        var hashtagsz = hashtagsy.slice(0, hash2);// Hello //// 
      }
      var hashtagsa = hashtagsz.trim();
      var space1 = hashtagsa.length; //6
      var hashtagsb = hashtagsa.slice(0, space1);// Hello

     if (hashtagsb) { body.hashtag = hashtagsb} else { body.hashtag = ''} //add hash to body
     console.log('green hashtag lead: ', lead);
     console.log('green hashtag hash1: ', hash1);
     console.log('green hashtag hashtagsx: ', hashtagsx);
     console.log('green hashtag hashtagsy: ', hashtagsy);
     console.log('green hashtag hash2: ', hash2);
     console.log('green hashtag hashtagsz: ', hashtagsz);
     console.log('green hashtag space1: ', space1);
     console.log('green hashtag hashtagsb: ', body.hashtag);

     // var data = {
    //   type: INPUT_HASHTAG,
    //   hash: hashtags,
    // }
    // this.props.fetchThoughts(data) //input hashtags in hastag database
    /////////////////////////////////////////////////////////////////////////////////////////
    const os = this.getMobileOS()

    if(os === 'iOS') {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Unfortunately you cannot LEAD on version iOS 14 and earlier. Please lead from any laptop, desktop or android device. At this stage it’s out of our control, yet we will continue to do all we can to resolve it.'
      }

      this.props.showToast(params)
      return;

     // this.props.createThought_IOS(body)
    }
    else {
      this.props.createThought(body)
    }
   
    if (body.postInternalGlobal==='postILead') {
    //var lurl = this.getURL(body.videosObj)
    //LIBRARY CODE  ////////////////////////////////////////////////////////////////
   if (this.getURL(body.videosObj) || body.videourl.videourl) { /////UPLOAD TO VIDEO LIBRARY
   // if (lurl || body.videourl.videourl) { //////////////UPLOAD TO VIDEO LIBRARY
      if (this.getURL(body.articlesObj)) {} else { 
        console.log('purple primary createThought 6 : ', body.videourl.videourl)

        var vUploadExternal 
        var vurl
        if (this.getURL(body.videosObj)) {
          vUploadExternal = "external" 
          vurl = this.getURL(body.videosObj)
        }
        else {
          vUploadExternal = "upload" 
          vurl = body.videourl.videourl
        }
       // console.log('purple primary createThought 7 : ', vurl)
      // video link youtube -> this.getURL(body.videosObj)
      // video uploaded -> body.videourl.videourl
      let vbody = {
        ...this.state,
        UploadExternal: vUploadExternal, 
        attachment: false,
        author: body.subtitle,
        ypointsCurrent: this.props.userdata.pointsy,
        firstName: this.props.userdata.firstName,
        journalUserName: this.props.userdata.firstName,
        lastName: body.lastName,
        competency: body.competency,
        competencyid: body.competencyid,
        dailyThoughtType: body.dailyThoughtType,
        dateRegistered: body.dateRegistered,
        dateScheduled: body.dateScheduled,
        description: body.title,
        editing: body.editing,
        groupid: body.groupid,
        groupname: body.groupname,
        maxLengthAuthor: "3/100",
        maxLengthThought: "14/280",
        modalTitle: "Add a new video",
        multiplegroups: body.multiplegroups,
        post: null,
        postInternalGlobal: body.postInternalGlobal,
        schedulePanelOpen: false,
        selectedDate: body.selectedDate,
        selectedTime: body.selectedTime,
        selectedgroups: [],
        show: false,
        subcompetency: body.subcompetency,
        subcompetencyid: body.subcompetencyid,
        title: body.title,
        uid: body.uid,
        uploadInProgress: false,
        url: vurl,
        userID: body.userID,
        userName: body.userName,
        points: body.points,//this.props.userdata.points ? this.props.userdata.points : '0',
        pointsCalf: body.pointsCalf,//this.props.userdata.pointsCalf ? this.props.userdata.pointsCalf : '0',
        real_companyID: body.real_companyID,//this.props.userdata.companyID,
        execmember: body.execmember//this.props.userdata.execmember ? this.props.userdata.execmember : false,
      }
      console.log('purple primary createThought 5 : ', vbody)
        this.props.createVideo(vbody)
     }
    }

    if (this.getURL(body.podcastsObj) || body.podcasturl.podcasturl) { /////UPLOAD TO PODCAST LIBRARY

      if (this.getURL(body.articlesObj)) {} else { 
      var vUploadExternal 
      var vurl
      if (this.getURL(body.podcastsObj)) {
        vUploadExternal = "external" 
        vurl = this.getURL(body.podcastsObj)
      }
      else {
        vUploadExternal = "upload" 
        vurl = body.podcasturl.podcasturl
      }
    //  console.log('purple primary createThought 7 : ', vurl)
    // video link youtube -> this.getURL(body.videosObj)
    // video uploaded -> body.videourl.videourl
    let vbody = {
      companyID: body.companyID,
      companyName: body.companyName, 
      userID: body.userID,
      userName: body.userName,
      user_type: body.user_type,
      podcastType: body.podcastType,
      UploadExternal: vUploadExternal, 
      attachment: false,
      author: body.subtitle,
      competency: body.competency,
      competencyid: body.competencyid,
      dailyThoughtType: body.dailyThoughtType,
      dateRegistered: body.dateRegistered,
      dateScheduled: body.dateScheduled,
      description: body.title,
      editing: body.editing,
      groupid: body.groupid,
      groupname: body.groupname,
      maxLengthAuthor: "3/100",
      maxLengthThought: "14/280",
      modalTitle: "Add a new podcast",
      multiplegroups: body.multiplegroups,
      post: null,
      postInternalGlobal: body.postInternalGlobal,
      schedulePanelOpen: false,
      selectedDate: body.selectedDate,
      selectedTime: body.selectedTime,
      selectedgroups: [],
      show: false,
      subcompetency: body.subcompetency,
      subcompetencyid: body.subcompetencyid,
      title: body.title,
      uid: body.uid,
      uploadInProgress: false,
      url: vurl
    }
    console.log('purple primary createThought 5 : ', vbody)
    this.props.createPodcast(vbody)
   }
  }
    if (this.getURL(body.articlesObj)) { //////////////UPLOAD TO ARTICLES LIBRARY
      // var vUploadExternal 
      // var vurl
      //if (this.getURL(body.articlesObj)) {
        vUploadExternal = "external" 
        vurl = this.getURL(body.articlesObj)
      //}
      // else {
      //   vUploadExternal = "upload" 
      //   vurl = body.podcasturl.podcasturl
      // }
      // console.log('purple primary createThought 7 : ', vbody)
     //video link youtube -> this.getURL(body.videosObj)
     //video uploaded -> body.videourl.videourl

    let vbody = {
      PodcastExternalRecord: "podcastnone",
      VidUploadExternal: "videonone",
      companyID_subcompetencyid: this.props.userdata.companyID + '_' + body.subcompetencyid,
      fileurl: {},
      articleLink: vurl,
      articleType: 2,
      body: body.title,
      userID: this.props.userdata.userID,
      userName: this.props.userdata.firstName,
      companyID: this.props.userdata.companyID,
      articlesObj: body.articlesObj,
      filesObj: body.filesObj,
      podcastsObj: body.podcastsObj,
      videosObj: body.videosObj,
      podcasturl: body.podcasturl,
      videourl: body.videourl,
      audiourl: body.audiourl,
      imageurl: body.imageurl,
      manualOrExternal: "externalLink",
      modalTitle: "Add a new article",
      showA: false,
      companyID: body.companyID,
      companyName: body.companyName, 
      // userID: body.userID,
      // userName: body.userName,
      user_type: body.user_type,
      podcastType: body.podcastType,
      UploadExternal: vUploadExternal, 
      attachment: false,
     // author: body.subtitle,
      author:this.props.userdata.userID,
      competency: body.competency,
      competencyid: body.competencyid,
      dailyThoughtType: body.dailyThoughtType,
      dateRegistered: body.dateRegistered,
      dateScheduled: body.dateScheduled,
      description: body.title,
      editing: body.editing,
      groupid: body.groupid,
      groupname: body.groupname,
      maxLengthAuthor: "3/100",
      maxLengthThought: "14/280",
      multiplegroups: body.multiplegroups,
      post: null,
      postInternalGlobal: body.postInternalGlobal,
      schedulePanelOpen: false,
      selectedDate: body.selectedDate,
      selectedTime: body.selectedTime,
      selectedgroups: [],
      show: false,
      subcompetency: body.subcompetency,
      subcompetencyid: body.subcompetencyid,
      subtitle: body.subtitle,
      title: body.title,
      uid: body.uid,
      uploadInProgress: false,
      url: vurl,
      journalUserName: this.props.userdata.firstName,
    }
    console.log('purple createArticle body.userName : ', vbody.userName)
   // console.log('purple createArticle this.props.userdata.userName : ', this.props.userdata.userName)
    if (vbody.articleLink) {
      console.log('purple createArticle createThought 5 : ', vbody)
      this.props.createArticle(vbody)
      }
    }
  }
    //if (this.props.userdata.execmember === true) {
    // ADD POINTS///////////////////////////
      if (this.props.editing === false) {
        let params = {
          uid: this.props.userdata.uid,
          userID: this.props.userdata.userID,
          pointsCurrent: this.props.userdata.points,
          ypointsCurrent: this.props.userdata.pointsy,
          pointsAdded: 10,
          trailID: this.makeid(10),
          companyName: this.props.userdata.companyName,
          firstName: this.props.userdata.firstName,
          lastName: this.props.userdata.lastName,
          title: this.state.title,
          actionType: this.state.postInternalGlobal + ' added',
          actionDesc: this.state.postInternalGlobal
        }
        console.log('purple addMorePoints params : ', params)
        this.props.addMorePoints(params)
      }
      //e.preventDefault();
   //} 
    // else {
    //   // INVITATION TO EXECUTIVE CLUB///////////////////////////
    //   let params = {
    //     type: SHOW_TOAST,
    //     open: true,
    //     variant: 'success',
    //     message: 'Executive Club members earn points for submitting material',
    //     pos: 'bottom'
    //   }

    //   this.props.showToast(params)
    //   return
    // }
  };

  componentDidUpdate = () => {
    let self = this

    if (this.props.CREATE_THOUGHT === CREATE_THOUGHT_SUCCESS) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'success',
        message: 'Thought posted successfully.'
      }

      // show success toast subcompetencyid: post.subcompetencyid,
      //  competecyid: post.competencyid
      // close dialog

      self.props.handleClose()
    }
  }

  componentDidMount() {
    if (this.props.editing) {
      const { post } = this.props

      this.setState({
        modalTitle: 'Edit a LEAD',
        postInternalGlobal:
          post.dailyThoughtType === 1
            ? 'postInternal'
            : post.dailyThoughtType === 2
              ? 'postGlobally'
              : post.dailyThoughtType === 3
                ? 'postILead'
                : 'postGroup',
        selectedDate: _DEFAULT_DATE_TIME('DATE', post.dateScheduled),
        selectedTime: _DEFAULT_DATE_TIME('TIME', post.dateScheduled),
        dailyThoughtType: post.dailyThoughtType,
        dateRegistered: post.dateRegistered,
        dateScheduled: post.dateScheduled,
        subtitle: post.subtitle,
        title: post.title,
        textColor: post.textColor,
        subcompetencyid: post.subcompetencyid ? post.subcompetencyid : '',
        competencyid: post.competencyid ? post.competencyid : ''
      })

      if (post.dailyThoughtType === 4) {
        this.toggleOn()
        this.setState({ groupid: post.groupid, groupname: post.groupname })
      }
    }
    
    const os = this.getMobileOS()
    if(os === 'iOS') {
      this.setState({iphonemsg: 'Unfortunately you cannot LEAD on version iOS 14 and earlier. Please lead from any laptop, desktop or android device. At this stage it’s out of our control, yet we will continue to do all we can to resolve it.'})
     }

    // show groups dropdown for delegate users
    if (IS_DELEGATE_USER[this.props.userdata.userType]) {
      this.toggleOn()
      this.setState({
        dailyThoughtType: 4
      })
    }
  }

  handleTimeChange = (date) => {
    let tempTime = date
    this.setState({
      hourScheduled: tempTime.getHours(),
      minScheduled: tempTime.getMinutes(),
      secScheduled: tempTime.getSeconds(),
      // timeScheduled: tempTime.getTime(),
      selectedTime: tempTime.getHours() + ':' + tempTime.getMinutes()
    })
  }

  handleDateChange = (date) => {
    let tempDate = date
    this.setState({
      yearScheduled: tempDate.getFullYear(),
      monthScheduled: tempDate.getMonth(),
      dayScheduled: tempDate.getDate(),
      selectedDate: tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate()
    })
  }
   USER_LOGGED = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE))
   render() {
     const { classes, opendialog, fullScreen, simplething } = this.props
     console.log('Competencies VALUES: ', this.props.competencyid)
     return (
       <div>
         {/* component='fieldset' */}
         {/* <FormControl onSubmit={this.submitPost(this)}>  */}
         <Dialog
           open={opendialog}
           onClose={this.props.handleClose}
           aria-labelledby='responsive-dialog-title'
           fullScreen={fullScreen}
           fullWidth
           maxWidth='md'
           classes={classes.dialogHeight }
         >
           {/*  <DialogTitle id='form-dialog-title'>
              <span className={classes.titleColor}>
                {this.state.modalTitle}
              </span>
            </DialogTitle> */}
           <CustomDialogTitle
             id='customized-dialog-title'
             onClose={this.props.handleClose}
           >
              Create a LEAD 
           </CustomDialogTitle>
             <span 
             style={{color: `${RED}`,
                fontSze: '0.45rem',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: '500',
                justifyContent: 'center',
                alignItems: 'center',
                width: '60%',
                marginLeft: '25px'
                }}>{this.state.iphonemsg}
                </span>
         
           <DialogContent className={classes.divStyle} dividers>
             {/* <TextField
                id='title'
                label='Enter thought'
                multiline
                fullWidth
                margin='normal'
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 280 }}
                aria-describedby='thoughtTitle'
                helperText={this.state.maxLengthThought}
                onChange={event => {
                  const { value } = event.target
                  let len = value.length
                  this.setState({
                    maxLengthThought: len + '/280',
                    title: value
                  })
                }}
                value={this.state.title}
              /> */}
             <Row>
               <Avatar alt='user' src={this.props.userdata.photoURL} style={{ width: 50, height: 50, marginRight: 10 }}/>
               <div>
                 <Typography variant='subtitle1'>{this.USER_LOGGED.firstName + ' ' + this.USER_LOGGED.lastName}</Typography>
                 <Typography variant='subtitle2'>{this.USER_LOGGED.companyName}</Typography>
               </div>
             </Row>
             <TextField
               placeholder='Type what you want to LEAD with here:'
               variant="outlined"
               sx={{
                input: {
                   color: 'red',
                   "&::placeholder": {    // <----- Add this.
                      opacity: 1,
                   },
                  },
                  label: { color: 'blue' }
                }}
               className={classes.maintextField}
               name='thought'
               id='thought'
               multiline
               disableUnderline
               rows={4}
               InputLabelProps={{ shrink: true }}
               inputProps={{ maxLength: 280 }}
               aria-describedby='thought'
               value={this.state.title}
               helperText={this.state.maxLengthThought}
               onChange={event => {
                 const { value } = event.target
                 let len = value.length
                 this.setState({
                   maxLengthThought: len + '/280',
                   title: value
                 })
               }}
             />
             <div style={{ width: '100%' }}>
               <ThoughtsAttachment
                 handleExtUpload={this.handleExternalUpload.bind(this)}
                 onChange={this.handleURLChanged.bind(this)}
               />
             </div>
             <input type="hidden" name="author" value="none" />
             {/* <TextField
               id='author'
               label='LEAD author'
               multiline
               fullWidth
               margin='normal'
               InputLabelProps={{
                 shrink: true
               }}
               inputProps={{ maxLength: 100 }}
               aria-describedby='authorInput'
               helperText={this.state.maxLengthAuthor}
              //  onChange={event => {
              //    const { value } = 'none'//event.target
              //    let len = value.length
              //    this.setState({
              //      maxLengthAuthor: len + '/100',
              //      subtitle: value
              //    })
              //  }}
               value='none'//{this.state.subtitle}
             /> */}
             {this.props.userdata &&
                CAN_POST_ORG_ILEAD[this.props.userdata.userType] ? (
                 <React.Fragment>
                   <RadioGroup
                     aria-label='postInternalGlobal'
                     name='postInternalGlobal'
                     value={this.state.postInternalGlobal}
                     onChange={this.handleValueChange('postInternalGlobal')}
                     style={{ flexDirection: 'row', marginBottom: '10px' }}
                   >
                     {/* dont display 'post to organisational' if user is 'corporate i-lead' for harmony */}
                     {CAN_POST_INTERNAL[this.props.userdata.userType] ?
                         <FormControlLabel
                           value='postInternal'
                           control={<Radio color='primary' />}
                           label='LEAD Forum'
                           labelPlacement='end'
                           onClick={this.toggleOff}
                         /> : null }
                     {this.props.userdata &&
                      CAN_POST_GLOBAL[this.props.userdata.userType] ? (
                         <FormControlLabel
                           value='postGlobally'
                           control={<Radio color='primary' />}
                           label='Global Contributor'
                           labelPlacement='end'
                           onClick={this.toggleOff}
                         />
                       ) : null}
                     {this.props.userdata &&
                      CAN_POST_DAILY[this.props.userdata.userType] ? (
                         <FormControlLabel
                           value='postInternal'
                           control={<Radio color='primary' />}
                           label='Post Daily'
                           labelPlacement='end'
                           onClick={this.toggleOff}
                         />
                       ) : null}

                     {this.props.userdata && HIDE_POSTING_TO_ILEAD[this.props.userdata.companyID] ? (null) : (
                       <FormControlLabel
                         value='postILead'
                         control={<Radio color='primary' />}
                         label='LEAD Global Forum'
                         labelPlacement='end'
                         onClick={this.toggleOff}
                       />
                     )}

                     {<FormControlLabel
                       value='postGroup'
                       control={<Radio color='primary' />}
                       label='LEAD Group Forum'
                       labelPlacement='end'
                       onClick={this.toggleOn}
                     />}
                   </RadioGroup>

                   {this.state.show && (
                     <React.Fragment>
                       <Groupselect
                         handleGroups={this.handleGroupsChange.bind(this)}
                         userdata={this.props.userdata}
                         editing={this.props.editing}
                         groupid={this.state.groupid}
                         groupname={this.state.groupname}
                       />
                       <br />
                     </React.Fragment>
                   )}
                   <Divider />
                 </React.Fragment>
               ) : null}
             <div>
               {this.state.imageurle ? <img
                 style={{ padding: '10px', width: '80%' }}
                 src={this.state.imageurle}
               />
                 : null}
             </div>

             <div style={{ width: '100%', marginTop: '8px' }}>
               { this.props.userdata.companyID === '-LjW7UI7e0uUikYrw-Vj' ? 
                ( 
                   // ACCESS_COMPETENCIES['-LEiZPT-C2PyLu_YLKNU'] ?
                      this.props.post === null ?
                        <CategoriesSelect
                        key={this.state.dailyThoughtType} dailyThoughtT={3} competency={this.props.competency ? this.props.competency : ''} competencyid={this.props.competencyid ? this.props.competencyid : ''} subcompetency={this.props.subcompetency ? this.props.subcompetency : ''} subcompetencyid={this.props.subcompetencyid ? this.props.subcompetencyid : ''} keepsubcomp={false} doCompState={this.handleCompState} companyID={'-LEiZPT-C2PyLu_YLKNU'} displayComp={this.state.subcompetency} />
                        :
                        <CategoriesSelect
                        key={this.state.dailyThoughtType} dailyThoughtT={this.state.dailyThoughtType} competency={this.props.post.competency} competencyid={this.props.post.competencyid} subcompetency={this.props.post.subcompetency} subcompetencyid={this.props.post.subcompetencyid} keepsubcomp={false} doCompState={this.handleCompState} companyID={'-LEiZPT-C2PyLu_YLKNU'} displayComp={this.state.subcompetency} />
                     // : null
                     )
                : 
               (
               ACCESS_COMPETENCIES[this.props.userdata.companyID] ?
                 this.props.post === null ?
                   <CategoriesSelect
                   key={this.state.dailyThoughtType} dailyThoughtT={this.state.dailyThoughtType} competency={this.props.competency ? this.props.competency : ''} competencyid={this.props.competencyid ? this.props.competencyid : ''} subcompetency={this.props.subcompetency ? this.props.subcompetency : ''} subcompetencyid={this.props.subcompetencyid ? this.props.subcompetencyid : ''} keepsubcomp={false} doCompState={this.handleCompState} companyID={this.props.userdata.companyID} displayComp={this.state.subcompetency} />
                   :
                   <CategoriesSelect
                   key={this.state.dailyThoughtType} dailyThoughtT={this.state.dailyThoughtType} competency={this.props.post.competency} competencyid={this.props.post.competencyid} subcompetency={this.props.post.subcompetency} subcompetencyid={this.props.post.subcompetencyid} keepsubcomp={false} doCompState={this.handleCompState} companyID={this.props.userdata.companyID} displayComp={this.state.subcompetency} />
                 : null)}
             </div>
             {/* <div style={{ width: '100%' }}>
               <ThoughtsAttachment
                 handleExtUpload={this.handleExternalUpload.bind(this)}
                 onChange={this.handleURLChanged.bind(this)}
               />
             </div> */}
             <div style={{ width: '100%', marginBottom: '10px' }}>
               <ExpansionPanel onChange={this.handleChange()}>
                 <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                   <ScheduleIcon style={{ color: '#ccbc74', paddingRight: 10, fontSize: 30 }} />
                    &nbsp;
                   {this.state.selectedDate ? (
                     <div
                       style={{
                         width: '100%',
                         display: 'flex',
                         flexDirection: 'row',
                         alignItems: 'center'
                       }}
                     >
                       <Typography variant='body2'>
                         {'Scheduled for: ' +
                            this.state.selectedDate +
                            ' ' +
                            this.state.selectedTime}
                       </Typography>
                        &nbsp;
                       <Button
                         variant='contained'
                         color='secondary'
                         component='span'
                         size='small'
                         className={classes.button}
                         onClick={this.cancelSchedule.bind(this)}
                       >
                         <CancelIcon />
                       </Button>
                     </div>
                   ) : (
                     <Typography className={classes.heading}>
                        Schedule for later release (optional)
                     </Typography>
                   )}
                 </ExpansionPanelSummary>
                 <ExpansionPanelDetails
                   style={{
                     padding: '8px 8px 24px',
                     backgroundColor: LIGHTGREY
                   }}
                 >
                   {/*  <ThemeProvider theme={theme}>
                      <MaterialUIPickers
                        dss={this.state.dateScheduled}
                        handleTimeChange={this.handleTimeChange.bind(this)}
                        handleDateChange={this.handleDateChange.bind(this)}
                      />
                    </ThemeProvider> */}
                   <Grid container spacing={8}>
                     <Grid item xs={12}>
                       <div>
                         {/* <YearSelect />
                         <MonthSelect />
                         <DaySelect />
                         <HourSelect />
                         <MinuteSelect /> */}
                         <ThemeProvider theme={theme}>
                           <MaterialUIPickers
                             dss={this.state.dateScheduledPost}
                             handleTimeChange={this.handleTimeChange}
                             handleDateChange={this.handleDateChange}
                           />
                         </ThemeProvider>
                         {this.state.dateScheduledPost === '' ?
                           <Button
                             variant='contained'
                             color='primary'
                             component='span'
                             size='small'
                             className={classes.button}
                             onClick={this.handleSetSchedule}
                             style={{marginLeft: 50, marginTop: 10, padding: 10}}
                           >
                             {/*  <DeleteRoundedIcon className={classes.leftIcon} /> */}
                          Set Schedule
                           </Button>
                           :

                           <div
                             style={{
                               width: '100%',
                               display: 'flex',
                               flexDirection: 'row',
                               alignItems: 'center'
                             }}
                           >
                             <Typography variant='body1'>
                               {`Scheduled for: ${this.state.selectedDate} : ${this.state.selectedTime}`}
                             </Typography>
                              &nbsp;
                             <Button
                               //style={{ cursor:'pointer' }}
                               // variant='contained'
                               // color='secondary'
                               component='span'
                               size='small'
                               className={classes.button}
                               onClick={this.cancelSchedule}
                             >
                               <CancelIcon style={{ color: 'red' }}/>
                             </Button>
                           </div>
                         }
                       </div>
                     </Grid>
                   </Grid>
                 </ExpansionPanelDetails>
               </ExpansionPanel>

             </div>
             <div style={{ width: '100%', marginBottom: '10px' }}>
               <AdvancedSettings
                 textColor={this.state.textColor}
                 onChange={this.handleURLChanged.bind(this)}
               />
               <br />
             </div>
           </DialogContent>
           <DialogActions>
             {/* <Button
                variant='contained'
                onClick={this.props.handleClose}
                style={{ backgroundColor: RED, color: '#fff' }}
              >
                CANCEL
              </Button> */}

           <form onSubmit={this.submitPost.bind(this)}>
             <Button
               variant='contained'
              // onClick={this.submitPost.bind(this)}
               style={sendBtn}
               type='submit'
             >
               <SendIcon className={classes.rightIcon} />
              LEAD
             </Button>
            </form>

           </DialogActions>
         </Dialog>
          {/* </FormControl> */}
       </div>
     )
   }
}

ThoughtsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
}

const dateObj = {
  width: '100%'
}

const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: '#fff',
  cursor: 'pointer'
}

const thoughtDialog = withMobileDialog({ breakpoint: 'xs' })(ThoughtsDialog)

const thoughtDialogStyled = withStyles(styles)(thoughtDialog)

const mapStateToProps = state => {
  return {
    CREATE_THOUGHT: state.thoughts.CREATE_THOUGHT,
    userdata: state.userDialog.userDetails[0],
    year: state.datepickerReducer.yearSelected,
    month: state.datepickerReducer.monthSelected,
    hour: state.datepickerReducer.hourSelected,
    minute: state.datepickerReducer.minuteSelected,
    day: state.datepickerReducer.daySelected,
   // simplething: state.simpleVal.simplething
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    clearStates: params => dispatch(clearStates(params)),
    createThought: params => dispatch(createThought(params)),
    //fetchThoughts: params => dispatch(fetchThoughts(params)),
    createVideo: params => dispatch(createVideo(params)),
    createPodcast: params => dispatch(createPodcast(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
    createArticle: params => dispatch(createArticle(params)),
    refreshCard: params => dispatch(refreshCard(params)),
    createThought_IOS: params => dispatch(createThought_IOS(params)),
    //simpleAction: params => dispatch(simpleAction(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(thoughtDialogStyled)
