import { TEST_PUBLISHER, TEST_USER } from "../actions/types";

const initState = {
    type: TEST_PUBLISHER,
    testShow: false
};

const testTestReducer = (state = initState , action) => {
  console.log('testTestReducer return: ', action.show);
  
  switch(action.type){
    case TEST_PUBLISHER:
      return{ 
        ...state,
        type: TEST_PUBLISHER,
        testShow: action.show
      }
    case TEST_USER:
      return{ 
        ...state,
        type: TEST_USER,
        show: false
      }
    default:
      return state
  }
  
}

export default testTestReducer