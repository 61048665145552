import {
    GET_ALL_TOKENS,
  } from '../actions/types'
  
  const initialState = {
    tokens: {}

  }
  const allTokensReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_TOKENS:
          return {
            ...state,
            type: GET_ALL_TOKENS,
            tokens: action.users,
          }
      default:
        return state
    }
  }
  export default allTokensReducer

  