import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { green, orange, red } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { GREEN, RED, PINK } from "../../utils/color_utils";

import {
  Grid,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Toolbar,
  Tooltip,
  IconButton
} from "@material-ui/core";
import {
  fetchCompanyValues,
  updateOrCreateCompanyValue,
  getSelectedCompanyValue,
  deleteCompanyValue
} from "./../../store/actions/pldpActions";
import FilterListIcon from "@material-ui/icons/FilterList";
import { fetchActiveCompanies } from "./../../store/actions/analyticsActions";
import CompanyValueDialog from "./../../components/Dialogs/CompanyValueDialog";
import MUIDataTable from "mui-datatables";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showToast } from "./../../store/actions/toastAction";

const toolbarStyles = theme => ({
  root: {
    paddingRight: 10,
    boxShadow: "none !Important"
  },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: green[400]
  },
  title: {
    flex: "0 0 auto"
  },
  button: {
    margin: 10,
    padding: 10,
    fontSize: 10,
    color: "#ffffff",
    backgroundColor: "#0288d1",
    "&:hover": {
      backgroundColor: "#0288d1"
    }
  },
  deleteButton: {
    backgroundColor: red[400],
    color: "#ffffff",
    padding: 10,
    fontSize: 10
  },
  activeButton: {
    backgroundColor: orange[800],
    margin: "0 15px",
    padding: 10,
    color: "#ffffff",
    fontSize: 10
  },
  userTypeButton: {
    backgroundColor: green[400],
    color: "#ffffff",
    padding: 10,
    fontSize: 10
  },

  settingsIcons: {
    margin: 10
  }
});

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    multiple,
    onDeleteCompanyValue,
    onUpdateCompanyValue
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      elevation={0}
    >
      <div className={classes.title} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {numSelected == 1 ? (
              <React.Fragment>
                <Button
                  onClick={onUpdateCompanyValue}
                  variant="contained"
                  aria-label="Delete"
                  className={classes.activeButton}
                >
                  <EditIcon />
                  Edit Value
                </Button>
              </React.Fragment>
            ) : null}
            <Button
              disabled={numSelected > 1}
              onClick={onDeleteCompanyValue}
              variant="contained"
              className={classes.deleteButton}
              aria-label="Delete"
            >
              <DeleteIcon />
              {multiple ? `Delete (${numSelected})` : "Delete"}
            </Button>
          </React.Fragment>
        ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

let EnhancedToolbar = withStyles(toolbarStyles, { withTheme: true })(
  EnhancedTableToolbar
);

const styles = theme => ({
  buttonSection: {
    margin: 10
  },
  addValueButton: {
    backgroundColor: GREEN,
    color: "white",
    padding: 10
  },
  formControl: {
    minWidth: 200
  },
  button: {
    margin: 10,
    backgroundColor: "#0288d1",
    "&:hover": {
      backgroundColor: "#0288d1"
    }
  },
  paperHeader: {
    backgroundColor: PINK
  },
  activate: {
    color: GREEN
  },
  deactivate: {
    color: RED
  },
  label: {
    textAlign: "center",
    fontStyle: "italic"
  }
});

const SelectedRow = props => (
  <input
    type="hidden"
    value={props.selectedRow}
    onClick={e => props.onSelectedRow(e.target.value)}
  />
);
class ViewCompanyValues extends Component {
  getMuiTheme = () =>
  createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#FF000"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: "0px",
            fontSize: "0.75rem!important"
          },
          responsiveStacked: {
            height: "34px!important",
            fontSize: "0.75rem!important"
          },
          cellStacked: {
            height: "34px!important",
            fontSize: "0.75rem!important",
            width: "50px!important",
            textAlign: "right!important"
          }
        },
        MUIDataTableSelectCell: {
          root: {
            borderBottom: "0px"
          }
        }
      }
    });

  columns = ["Company Value", "No. Tasks", "Status"];

  options = {
    filterType: "radio",
    rowHover: true,
    selectableRows: true,
    responsive: "stacked",
    //	onRowClick: (rowData, rowMeta) => this.handleOnSelectedRow({ rowData, rowMeta }),
    customToolbarSelect: e => {
      return (
        <EnhancedToolbar
          numSelected={e.data.length}
          multiple={e.data.length > 0}
          options={{
            elevation: 0
          }}
          onUpdateCompanyValue={() => this.handleUpdateCompanyValue()}
          onDeleteCompanyValue={() => this.handleDeleteCompanyValue()}
          {...this.props}
        />
      );
    },
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      const { pldp, getSelectedCompanyValue } = this.props;
      window.sv = pldp.companyValues[currentRowsSelected[0].dataIndex];
      this.handleOnSelectedRow(window.sv);
    },
    print: false,
    download: false,
    filter: false
  };

  state = {
    data: [],
    company: "",
    openDialog: false,
    companyName: "",
    companyValue: "",
    isUpdate: false,
    selectedCompanyValueID: ""
  };
  componentWillUnmount() {
    window.sv = null;
  }

  componentDidMount() {
    const { userdata } = this.props;
    const companyID = userdata.companyID;
    this.props.fetchCompanyValues(companyID);
    this.props.fetchActiveCompanies();
    this.setState({
      company: userdata.companyID,
      companyName: userdata.companyName
    });
  }

  handleOnSelectedRow = currentRowsSelected => {
    // // console.log("currentRowsSelected", currentRowsSelected);
  };

  formatDisplayTableData = () => {
    const { classes, pldp } = this.props;
    return pldp.companyValues.map((v, i) => {
      return [
        v.valueDesc,
        v.count ? v.count : 0,
        <RadioButtonCheckedIcon
          key={v.companyID}
          className={
            v.status !== "active" ? classes.deactivate : classes.activate
          }
        />
      ];
    });
  };

  toggleOpenDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog
    });
  };

  handleOnSubmitCompanyValue = companyValue => {
    const { userdata } = this.props;
    this.props.updateOrCreateCompanyValue({
      companyID: this.state.company,
      valueDesc: companyValue,
      companyName: this.state.companyName,
      updateValue: this.state.isUpdate,
      valueID: this.state.selectedCompanyValueID
    });
    this.toggleOpenDialog();
    this.props.fetchCompanyValues(this.state.company);
  };

  handleUpdateCompanyValue = value => {
    var selectedValue = window.sv;
    this.setState({
      companyValue: selectedValue.valueDesc,
      isUpdate: !this.state.isUpdate,
      selectedCompanyValueID: selectedValue.valueID
    });

    this.toggleOpenDialog();
  };
  handleAddCompanyValue = () => {
    this.toggleOpenDialog();
  };
  handleDeleteCompanyValue = () => {
    var selectedValue = window.sv;

    let count =
      selectedValue.hasOwnProperty("count") && selectedValue.count > 0;

    if (count) {
      this.props.showToast({
        variant: "success",
        type: "SHOW_TOAST",
        message:
          "Error Deleting " +
          selectedValue.valueDesc +
          ".\n There are task already attached to it!"
      });
    } else {
      const answerDelete = window.confirm(
        `Delete selected value \n ( ${selectedValue.valueDesc} ) ?`
      );
      var params = {
        ...selectedValue,
        isDelete: answerDelete
      };

      this.props.deleteCompanyValue(params);
    }
    this.props.fetchCompanyValues(selectedValue.companyID);
  };
  handleCompanyChange = e => {
    this.setState({
      company: e.target.value,
      companyName: this.props.activeCompanies[e.target.value].companyName
    });
    this.props.fetchCompanyValues(e.target.value);
  };

  render() {
    const { data } = this.state;
    const { classes, pldp, activeCompanies } = this.props;

    return (
      <div className={classes.buttonSection}>
        <Grid container spacing={8} className={classes.buttonSection}>
          <Grid item xs md={10}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="company">Select a Company</InputLabel>
              <Select
                value={this.state.company}
                placeholder="Select a Company"
                onChange={this.handleCompanyChange}
                inputProps={{
                  name: {},
                  id: "company"
                }}
              >
                {activeCompanies
                  ? Object.values(activeCompanies).map((v, i) => (
                    <MenuItem
                      name={v.companyName}
                      value={v.companyID}
                      key={i}
                    >
                      {v.companyName}
                    </MenuItem>
                  ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs md={2}>
            <Button
              variant="contained"
              onClick={this.handleAddCompanyValue}
              className={classes.addValueButton}
            >
              <AddIcon />
              Add Value
            </Button>
          </Grid>
        </Grid>
        <Typography
          className={classes.label}
          variant="caption"
          color="inherit"
          noWrap
        >
          Please select to edit / delete
        </Typography>

        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title="LEAD Categories"
            data={this.formatDisplayTableData()}
            columns={this.columns}
            options={this.options}
          />
        </MuiThemeProvider>
        <CompanyValueDialog
          open={this.state.openDialog}
          onCloseDialog={this.toggleOpenDialog}
          companyID={this.state.company}
          companyName={this.state.companyName}
          company={activeCompanies[this.state.company]}
          OnSubmitCompanyValue={value => this.handleOnSubmitCompanyValue(value)}
          updateOnSave={this.state.isUpdate}
          companyValue={this.state.isUpdate ? this.state.companyValue : ""}
        />
      </div>
    );
  }
}

ViewCompanyValues.propTypes = {};
const CompanyValues = withStyles(styles, { withTheme: true })(
  ViewCompanyValues
);

const mapStateToProps = (state, ownProps) => {
  return {
    pldp: state.pldp,
    activeCompanies: state.analytics.activeCompanies,
    userdata: state.userDialog.userDetails[0]
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchCompanyValues,
      fetchActiveCompanies,
      updateOrCreateCompanyValue,
      getSelectedCompanyValue,
      deleteCompanyValue,
      showToast
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyValues);
