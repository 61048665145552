import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  Avatar,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { HOVERRED, RED, DARK, LIGHTGREY } from "../../utils/color_utils";
import { openUserProfileDialog } from "../../store/actions/userBiographyActions";
import { PHOTO_PROFILE } from "../../utils/photo_utils";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  margin: {
    margin: theme.spacing.unit
  },
  dialogHeight: {
    height: "60vh",
    // maxHeight: `calc(100% - 30px)`,
    minHeight: "60vh"
  },
  divStyle: {
    margin: "0px",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
      padding: "0px",
      alignItems: "start",
      justifyContent: "start"
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: `calc(100% - 20px)`
  },
  close: {
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "999999",
    color: "#fff",
    backgroundColor: `${RED}`,
    borderRadius: "0%",
    padding: "8px",
    "&:hover": {
      background: `${HOVERRED}`
    }
  },

  bigAvatar: {
    margin: "10px 30px",
    width: 100,
    height: 100,
    border: "4px solid #c3272e",
    textAlign: "center"
  },
  paperRoot: {
    flexGrow: 1,
    textAlign: "center",
    height: "100%",
    width: "100%"
  },
  profileStats: {
    backgroundColor: "#c3272e",
    padding: "0px 8px 0px 0px"
  },
  profileBio: {
    backgroundColor: `${LIGHTGREY}`,
    padding: 10,
    textAlign: "left"
  },
  companyText: {
    color: "#4285f4",
    fontWeight: "100"
  },
  followButton: {
    backgroundColor: "#fff",
    border: "1px solid #810029",
    color: "#810029",
    fontSize: "8pt !important",
    borderRadius: "17px",
    fontWeight: "500"
  }
});

const UserAvatar = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <Avatar
            src={PHOTO_PROFILE(
              props.biography
                ? props.biography.photoURL
                : props.showprofiledlg.photoURL
            )}
            className={classNames(classes.bigAvatar)}
            aria-label="Photo"
          >
          </Avatar>
          <Typography variant="h6">
            {props.biography
              ? props.biography.firstName + " " + props.biography.lastName
              : props.showprofiledlg.userName}
          </Typography>
          <Typography
            className={classNames(classes.companyText)}
            variant="subtitle2"
          >
            {props.biography
              ? props.biography.companyName
              : props.showprofiledlg.companyName}
          </Typography>
          {/* <Button
            variant="outlined"
            size="small"
            className={classNames(classes.margin, classes.followButton)}
          >
            Following
          </Button> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const UserPostStatItem = props => {
  const { count, caption, classes, textColor } = props;
  return (
    <React.Fragment>
      <Typography
        style={{ color: `${textColor}` }}
        className={classNames(classes.textCount)}
        variant="h5"
      >
        <strong>{count}</strong>
      </Typography>
      <Typography
        style={{ color: `${textColor}` }}
        variant="caption"
        gutterBottom
      >
        {caption}
      </Typography>
    </React.Fragment>
  );
};

class ViewUserProfile extends Component {
  state = {};
  handleClose = e => {
    var params = {
      userID: this.props.showprofiledlg.userID,
      userName: this.props.showprofiledlgjournalUserName,
      companyID: this.props.showprofiledlgcompanyID,
      companyName: this.props.showprofiledlgcompanyName,
      photoURL: this.props.showprofiledlg.photoURL,
      showprofiledlg: false
    };

    // Open user profile dialog
    this.props.openUserProfileDialog(params);
  };

  render() {
    const { postStats } = this.state;
    const {
      classes,
      showprofiledlg,
      media,
      fullScreen,
      biography,
      analytics
    } = this.props;
    
    console.log('biography',biography)

    return (
      <div>
        <FormControl component="fieldset">
          <Dialog
            open={showprofiledlg.showprofiledlg}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen={fullScreen}
            fullWidth
            maxWidth="sm"
            classes={{ paper: classes.dialogHeight }}
          >
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose.bind(this)}
            >
              <CloseIcon fontSize="small" className={classes.icon} />
            </IconButton>
            <DialogContent className={classes.divStyle}>
              <Paper elevation="0" className={classes.paperRoot}>
                <Grid
                  container
                  spacing={16}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  elevation="0"
                >
                  <Grid xs={2} item>
                    <UserPostStatItem
                      count={biography.analytics ? biography.analytics.followers : 0}
                      caption="Followers"
                      {...this.props}
                    />
                  </Grid>
                  <Grid item>
                    <UserAvatar {...this.props} />
                  </Grid>
                  <Grid xs={2} item>
                    <UserPostStatItem
                      count={biography.analytics ? biography.analytics.following : 0}
                      caption="Following"
                      {...this.props}
                    />
                  </Grid>
                </Grid>
                <div className={classNames(classes.profileStats)}>
                  <Grid
                    container
                    spacing={16}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid xs={3} item>
                      <UserPostStatItem
                        textColor="white"
                        count={analytics ? analytics.thoughts || 0 : 0}
                        caption="Messages"
                        {...this.props}
                      />
                    </Grid>
                    <Grid xs={3} item>
                      <UserPostStatItem
                        textColor="white"
                        count={analytics ? analytics.articles || 0 : 0}
                        caption="Articles"
                        {...this.props}
                      />
                    </Grid>
                    <Grid xs={3} item>
                      <UserPostStatItem
                        textColor="white"
                        count={analytics ? analytics.videos || 0 : 0}
                        caption="Videos"
                        {...this.props}
                      />
                    </Grid>
                    <Grid xs={3} item>
                      <UserPostStatItem
                        textColor="white"
                        count={analytics ? analytics.podcasts || 0 : 0}
                        caption="Podcasts"
                        {...this.props}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={16} direction="row">
                  <Grid xs={12} item>
                    <div className={classNames(classes.profileBio)}>
                      <Typography variant="h6">
                        <strong>Biography</strong>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid xs={12} item>
                    {biography ? (
                      <Typography
                        style={{ padding: "8px 8px 4px 4px", margin: '10px 10px 10px 10px', textAlign: "left", overflowY: 'visible', height: '50vh'  }}
                      >
                        {biography.biography || null}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </Paper>
            </DialogContent>
            <DialogActions />
          </Dialog>
        </FormControl>
      </div>
    );
  }
}

ViewUserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

const ViewUserProfileDlg = withMobileDialog({ breakpoint: "xs" })(
  ViewUserProfile
);

const ViewUserProfileStyled = withStyles(styles)(ViewUserProfileDlg);

const mapStateToProps = state => {
  return {
    biography: state.userbiography.biography,
    analytics: state.userbiography.biography.analytics
      ? state.userbiography.biography.analytics
      : null,
    showprofiledlg: state.userbiography.showprofiledlg
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openUserProfileDialog: params => dispatch(openUserProfileDialog(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUserProfileStyled);
