import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles } from '@material-ui/core/styles'

  class LeaderLoader extends React.Component {

    componentDidMount = () => {
        this.move(); //activate progress bar
    }

    move = () => {
        console.log('blue sky start move')
       var i = 0;
       if (i == 0) {
         i = 1;
         var elem = document.getElementById("myBar");
         var width = 10;
         var id = setInterval(frame, 100);
         function frame() {
           if (width >= 100) {
             clearInterval(id);
             i = 0;
           } else {
             width++;
             elem.style.width = width + "%";
             elem.innerHTML = width  + "%";
           }
         }
       }
     }
    render() {
      const { classes } = this.props;
        
      return (
    //    <div className={classes.root}>    
    //     <div id="myProgress" className={classes.myProgress}>
    //       <div align='center' className={classes.myProgress}><b>Loading the Leaderboard</b></div>
    //       <div id="myBar" className={classes.myBar}>10%</div>
    //     </div>
    //     </div>

        <div style={{
            width: '100%'
          }}>    
         <div id="myProgress" 
          style={{
             width: '100%',
             height: '10px',
             backgroundColor: '#ffffff',
             textAlign: 'center',
             lineHeight: '20px',
             color: 'gold'
           }}>
           <div align='center' 
              style={{
                    width: '100%',
                    height: '10px',
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    lineHeight: '20px',
                    color: 'gold'
                }}>
               <b><font color='#000000'>Uploading new Users</font></b></div>
           <div id="myBar" 
             style={{
                    width: '100%',
                    height: '10px',
                    backgroundColor: '#000000',
                    textAlign: 'center',
                    lineHeight: '20px',
                    color: 'gold'
                 }}>10%</div>
            </div>
         </div>   
     
      )
    }
}

export default LeaderLoader;
