import { LEADERBOARD_LOADING } from './types'

export const loadingAction = params => {
  console.log('green leaderboardloading 1 : ', params.leaderboard_loading)
  if (params.type === LEADERBOARD_LOADING) {
    return (dispatch, getState) => {
      if (params) {
        dispatch({
          type: LEADERBOARD_LOADING,
          leaderboard_loading: params.leaderboard_loading
        })
      } else {
        return (dispatch, getState) => {
          dispatch({
            type: LEADERBOARD_LOADING,
            leaderboard_loading: 0
          })
        }
      }
    }
  }
}
