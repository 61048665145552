import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, Grid } from "@material-ui/core";
import { CHANGE_ABOUT_PLDP } from "../../store/actions/types";

const styles = theme => ({});

const mainDiv = {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  flexDirection: 'column'
}

const innerDiv = { width: '60%' }

const innerDivSelected = window.innerWidth < 600 ? { width: '100%' } : innerDiv;

const AboutPLDP = props => {
  return (
    <div style={mainDiv}>
      {props.userdata && !CHANGE_ABOUT_PLDP[props.userdata.companyID] ?
        <React.Fragment>
          <Typography gutterBottom variant="h6">
            ABOUT – Personal Development Plan (LFP)
        </Typography>
          <Paper>
            <iframe
              width="100%"
              height="315px"
              src="https://www.youtube.com/embed/omY7IDX_pu8"
              frameborder="0"
              allow="encrypted-media"
              allowfullscreen=""
            />
          </Paper>
        </React.Fragment>
        :
        <div style={innerDivSelected}>
          <Typography gutterBottom variant="h6" style={{ marginTop: '1rem' }}>
            Welcome to your Leadership Fitness Development Journey
          </Typography>
          <Paper style={{ backgroundColor: '#fafafa', paddingLeft: 10, paddingRight: 10 }} elevation={0} variant="outlined">
            <Typography gutterBottom variant="body2">
              As a leader, you are required to complete your Leadership Fitness Plan(LFP) for each leadership development competency module.
            </Typography>
            <Typography gutterBottom variant="body2">
              This will ensure that you receive support on areas of development pertaining to your leadership development journey.
            </Typography>
            <Typography gutterBottom variant="body2">The PDLP will form part of your performance review discussion process with your supervisor or HOD.
            </Typography>
          </Paper>
        </div>
      }

    </div>
  );
};

export default AboutPLDP;
