import React, { Component } from "react";
import PropTypes from "prop-types";
import ArticlesView from "../components/Views/MyArticlesView";
import { connect } from "react-redux";
import { fetchArticles } from "../store/actions/articlesActions";
import { MY_ARTICLES, JOURNAL_USER_ID } from "../store/actions/types";
import { deletePostAttachment } from "./../store/actions/attachmentActions";

class MyArticles extends Component {
  constructor (props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      minData: 6
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 6
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    var params = {
      type: MY_ARTICLES,
      companyID: this.props.userdata.companyID,
      orderChild: "journalUserID",
      equalTo: this.props.userdata.userID,
      minData: this.state.minData
    };
    this.props.fetchArticles(params);
  };

  render() {
    const { articles, userdata } = this.props;
    return (
      <div>
        {userdata && articles ? (
          <React.Fragment>
            <ArticlesView
              articles={articles}
              currentUser={userdata}
              {...this.props}
              openFileViewer={this.props.openFileViewer}
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

MyArticles.propTypes = {
  fetchArticles: PropTypes.func.isRequired,
  articles: PropTypes.array
};

const mapStateToProps = state => {
  return {
    articles: state.articles.myarticles,
    userdata: state.userDialog.userDetails[0]
  };
};

export default connect(
  mapStateToProps,
  { fetchArticles, deletePostAttachment }
)(MyArticles);
