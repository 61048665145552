/* eslint-disable no-unused-vars */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ThoughtsView from '../components/Views/ThoughtsView'
import ArticlesView from '../components/Views/ArticlesView'
import PodcastsView from '../components/Views/PodcastsView'
import VideosView from '../components/Views/VideosView'
import MyFilteredMessages from './MyFilteredMessages'
import { connect } from 'react-redux'
import { filteredPost, filteredGroupPost } from '../store/actions/thoughtsActions'
import { FILTERED_POST, FILTERED_GROUP_POST } from '../store/actions/types'

class filteredPosts extends Component {
  state={
    filteredID: ''
  }

  loadPost() {
    let locationhash = this.props.location.hash
    let filtered_id = locationhash.replace(/#\//g, '') //'-NQebIY5LWuY-6DPwAjD'
    console.log('greenfilteredPosts 0 : ', filtered_id);

    this.setState({filteredID: filtered_id})

    switch (this.props.match.path) {
      case '/filtered-thoughts':
        var params = {
          type: FILTERED_POST,
          companyID: this.props.userdata.companyID,
          orderChild: 'dailyThoughtID',
          equalTo: filtered_id, //'-NQ-_tr08-dyaLcat6hV', 
          ref: 'dailyThoughts'
        }
       this.props.filteredPost(params)
        break
      case '/filtered-podcasts':
        var params = {
          type: FILTERED_POST,
          companyID: this.props.userdata.companyID,
          orderChild: 'podcastID',
          equalTo: filtered_id,
          ref: 'podcasts'
        }
        this.props.filteredPost(params)
        break
      case '/filtered-articles':
        var params = {
          type: FILTERED_POST,
          companyID: this.props.userdata.companyID,
          orderChild: 'newsID',
          equalTo: filtered_id,
          ref: 'news'
        }
        this.props.filteredPost(params)
        break
      case '/filtered-videos':
        var params = {
          type: FILTERED_POST,
          companyID: this.props.userdata.companyID,
          orderChild: 'videoID',
          equalTo: filtered_id,
          ref: 'videos'
        }
        this.props.filteredPost(params)
        break
      case '/filtered-group-posts':
        var params = {
          type: FILTERED_POST,
          companyID: this.props.userdata.companyID,
          ref: 'group-posts/' + filtered_id
        }
        this.props.filteredGroupPost(params)
        break
      default:
        break
    }
  }

  componentDidMount = () => {
    this.loadPost()
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.hash !== this.props.location.hash) {
      this.loadPost()
    }
    // else if (prevProps.location.hash == this.props.location.hash) {
    //   // console.log("filtered 789 ");
    //   // this.loadPost();
    // }
  }

  getFilteredPost = (props) => {
    console.log('filterpostsTest : ', props)
    for (var x in props) {
      return props[x].id
    }
  }

  render() {
    const { filteredpost, userdata, location, match } = this.props
   // console.log('greenfilteredPosts 1a : ', this.props);
   // console.log('greenfilteredPosts 1b : ', match.path);
    console.log('greenfilteredPosts 1 : ', this.state.filteredID);

    return (
      <div>
        {/* {userdata && this.getFilteredPost(filteredpost) ? ( */}
        {userdata && match.path && this.props.filteredpost[0] ? (
          <React.Fragment>
            {match.path === '/filtered-thoughts' ? (
              <ThoughtsView
                thoughts={this.props.filteredpost[0]}
                xthoughts={this.props.filteredpost[0]}
                //thoughtsb={this.props.filteredpost[0]}
               // lthoughts={this.props.filteredpost[0]}
                currentUser={userdata}
                notificationx={true}
                name={this.state.filteredID}
                key={this.state.filteredID}
                {...this.props}
                openFileViewer={this.props.openFileViewer}
              />
            ) : match.path === '/filtered-articles' ? (
              <ArticlesView
                articles={filteredpost}
                currentUser={userdata}
                {...this.props}
                openFileViewer={this.props.openFileViewer}
              />
            ) : match.path === '/filtered-podcasts' ? (
              <PodcastsView
                podcasts={filteredpost}
                currentUser={userdata}
                {...this.props}
              />
            ) : match.path === '/filtered-videos' ? (
              <VideosView
                videos={filteredpost}
                currentUser={userdata}
                {...this.props}
              />
            ) : match.path === '/filtered-group-posts' ? (
              <MyFilteredMessages
                groupposts={filteredpost}
                currentUser={userdata}
                {...this.props}
                openFileViewer={this.props.openFileViewer}
              />
            ) : <div style={{ textAlign: 'center', padding: '100px 20px 20px', color: '#000000' }}>No post found. The post may have been deleted.</div>}
          </React.Fragment>
        ) : <div style={{ textAlign: 'center', padding: '100px 20px 20px', color: '#000000' }}>No post found. The post may have been deleted.</div>}
      </div>
    )
  }
}

filteredPosts.propTypes = {
  filteredPost: PropTypes.any,
  filteredGroupPost: PropTypes.any,
  filteredpost: PropTypes.any,
  userdata: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    filteredpost: state.thoughts.filteredpost,
    userdata: state.userDialog.userDetails[0]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filteredPost: params => dispatch(filteredPost(params)),
    filteredGroupPost: params => dispatch(filteredGroupPost(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(filteredPosts)
