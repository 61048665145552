import { CREATE_COMMENT, DELETE_COMMENT  } from "./../actions/types";

const initialState = {
    comments: {}
}

const commentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_COMMENT:
                return {
                    ...state,
                    type: CREATE_COMMENT,
                    comments: action.comments
                }
            break;
    
    case DELETE_COMMENT:
                return {
                    ...state,
                    type: DELETE_COMMENT,
                    comments: action.comments
                }
            break;
        default:
         return state
            break;
    }
}

export default commentsReducer;