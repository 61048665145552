/* eslint-disable no-inline-comments */
/* eslint-disable consistent-return */
/* eslint-disable no-undefined */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React from 'react'
import ReactDOM from 'react-dom'
// import DataTable from "react-data-table-component";
import MUIDataTable from 'mui-datatables'
import {
  createTheme,
  MuiThemeProvider,
  getMuiTheme,
  withStyles
} from '@material-ui/core/styles'
// import "./styles.css";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getTopLeadersFromProps,
  getAllCompanySettings,
  getAllGeneralSettings
  // keepTopLeaders
} from '../../store/actions/companyAdminActions'
// import {
//   leaderboardAll
// } from '../../store/actions/leaderboardActions'
import {
  Avatar
} from '@material-ui/core'
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Grid
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {
  GET_ALL_COMPANY_SETTINGS,
  GET_ALL_GENERAL_SETTINGS,
  LEADERBOARD,
  SHOW_TOAST
} from '../../store/actions/types'
import { Container, Row, Col } from 'react-grid-system'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import LoaderBar from '../../components/LoaderBar'
import FinalScope from '../../pages/PointsSurveys/FinalScope'
import { showToast } from '../../store/actions/toastAction'
import ToggleButton from 'react-toggle-button'
import ViewUser from '../UserProfile/ViewUser'
import ViewUser_self from '../UserProfile/ViewUser_self'
import ViewUser_ledain from '../UserProfile/ViewUser_ledain'
import ViewUser_gen from '../UserProfile/ViewUser_gen'
//import ViewUser_ledain_self from '../UserProfile/ViewUser_ledain_self'
import ViewUser_self_gen from '../UserProfile/ViewUser_self_gen'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import GenTutorial from '../../pages/PointsSurveys/GenTutorial'
import { getCompanyValues } from '../../store/actions/pldpActions'
import { scopedCssBaselineClasses } from '@mui/material'
import { SettingsBrightnessSharp } from '@material-ui/icons'
//import { GrSupport } from "react-icons/gr";
import { BiBi } from "react-icons/bi";

class LeaderOnList extends React.Component {
  constructor(props) {
    super(props)
         try {
      console.log("Welcome guest!");
    }
    catch(err) {
      console.log('greenError : ', err.message)
    }

  }
   state = {
     // users: this.props.location.state.Everyone ? this.props.location.state.Everyone : null,
     uid: '',
     leaderboardKey: '',
     userID: '',
     selectedSector: 'x',
     selectedCountry: 'x',
     selectedClub: this.props.userdata.companyID,
     selectedPostion: 'x',
     leaderboardData: {},
     togglePoints: false
   };

   componentDidMount() {
     console.log('green this.props.leaderboardData H : ', this.props.leaderboardData)
     console.log('green this.props.leaderboardData C : ', this.props.userdata.companyID)
     let leaderboardMData = {}
     let lkey

    //  try {
    //   console.log("Welcome guest!");
    // }
    // catch(err) {
    //   console.log('greenError 2 : ', err.message)
    // }

     // this.props.getCompanyValues('-LEiZPT-C2PyLu_YLKNU');
     // this.props.fetchCompanyValues('-LEiZPT-C2PyLu_YLKNU');
     // Note 'you have to be part of executive club ..
     if (this.props.userdata.execmember) {} else {
      //  let params = {
      //    type: SHOW_TOAST,
      //    open: true,
      //    variant: 'success',
      //    message: 'Sign up for Executive Club to access our Learning Platform, generate more real-time points and utilise the leadership feedback system.'
      //  }
      //  setTimeout(() => {
      //   this.props.showToast(params)
      // }, 3000)
      
      if (window.confirm('Sign up for Executive Club to access our Learning Platform, generate more real-time points and utilise the leadership feedback system.')) {
        // Save it!
        //console.log('Thing was saved to the database.');
        ///pass = true;
       // } else {
        // Do nothing!
        //console.log('Thing was not saved to the database.');
        //pass = false;
        }
     }
    if (this.props.location.state.Everyone) {
       lkey = this.props.userdata.companyID + '=x=x=x'
     } else if (this.state.leaderboardKey) {
       lkey = this.state.leaderboardKey
     } else {
       lkey = this.props.userdata.companyID + '=x=x=x'
     }
     if (this.props.leaderboardData) {
       console.log('green this.props.leaderboardData K : ', this.state.leaderboardKey)

       console.log('green leaderboardDataTest pre : ', this.props.leaderboardData)

       // console.log('green leaderboardDataTest result : ', result);

       // leaderboardMData = Object.assign({}, this.props.leaderboardData);
       leaderboardMData = JSON.parse(JSON.stringify(this.props.leaderboardData))
       // leaderboardMData = Object.assign({}, this.props.leaderboardData);
       // leaderboardMData = Object.create(this.props.leaderboardData);
       // leaderboardMData = this.props.leaderboardData;

       console.log('green leaderboardDataTest post : ', leaderboardMData)
       console.log('green-getTopLeadersFromProps 2 : ', this.state.togglePoints)

       this.props.getTopLeadersFromProps({ // this.props.users
         Alldata: leaderboardMData,
         companyID: this.props.userdata.companyID,
         orderChild: 'LeaderboardKey',
         LeaderboardKey: lkey, // 'xx_x_x_x',
         firstOrder: false,
         pointsy: this.state.togglePoints
       })
       console.log('green this.props.leaderboardData I ', this.props.leaderboardData)
       console.log('greenXcompanyID ', this.props.userdata.companyID)
       this.props.getAllCompanySettings({
         companyID: '-Lu4BlhZ-tsDSnd-fnFd'//this.props.userdata.companyID // '-LEiZPT-C2PyLu_YLKNU' //'-Lu4BlhZ-tsDSnd-fnFd
       })
       this.props.getAllGeneralSettings({
         companyID: this.props.userdata.companyID
       })

       if (isBrowser) {
         console.log('isMobile false')
         this.transformTableData()
       } else {
         console.log('isMobile true')
         this.transformTableData_m()
       }
     }

     // this.transformTableData()
   }

   clearEmptyStrings(obj) {
     Object.keys(obj).forEach(key => {
       if (typeof obj[key] === 'object') {
         return obj[key]
       }
       if (obj[key] === '') {
         delete obj[key]
       }
     })
   }

  //  columns = this.state.togglePoints ? [
  //   'No', 'Avatar', 'Lastname', 'Sector', 'Country', 'Club', 'Position', 'Year Points']
  //    : ['No', 'Avatar', 'Name', 'Lastname', 'Sector', 'Country', 'Club', 'Position', 'Points', 'RFeedback', 'Feedback']


   columns = this.state.togglePoints ? 
   [ 
    {
      name: "No",
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            width: "5%",
            maxWidth: "10%",
            zIndex: 100
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101
          }
        })
      }
    },
    {
      name: "Avatar",
      flex: 1,
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", width: '5%' } })
      }
    },
    {
      name: "Name",
      flex: 1,
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Lastname",
      flex: 1,
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Sector",
      flex: 1,
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Country",
      flex: 1,
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Club",
      flex: 1,
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Position",
      flex: 1,
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Year Points",
      flex: 1,
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
      }
    }]
   
     :
     [ 
      {
        name: "No",
        options: {
          filter: true,
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: "white",
              zIndex: 100
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 101
            }
          })
        }
      },
      {
        name: "Avatar",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "Name",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "Lastname",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "Sector",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "Country",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "Club",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "Position",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "Points",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      // {
      //   name: "n",
      //   flex: 1,
      //   options: {
      //     filter: true,
      //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
      //   }
      // },
      {
        name: "G-FB",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "R-FB",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      },
      {
        name: "Feedback",
        flex: 1,
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
        }
      }
    ]   

   columnsm = this.state.togglePoints ? ['No', 'Avatar', 'Name', 'Lastname', 'Year Points']
     : ['No', 'Avatar', 'Name', 'Lastname', 'Points', 'G-FB', 'R-FB', 'Feedback', ]


    renderGeneralSettings = (type) => {
      let itemdata = null
      console.log('green renderGeneralSettings : ', this.props.general_settings[type])

      if (this.props.general_settings && this.props.general_settings[type]) {
        itemdata = this.props.general_settings[type]
        //itemdata.sort()
        return itemdata.map((v, i) => (
          <option key={i} value={v}>
            {v}
          </option>
        ))
      }
    };

   filterZZZ = (comLable) => {
     let comLablex = comLable.toString()
     let myTruncatedString = comLablex.substring(0, 4)
     let finalstring = comLablex

     if (myTruncatedString === 'ZZZ_') {
       finalstring = comLablex.substring(4, comLablex.length)
     }
     if (myTruncatedString === 'AAA_') {
       finalstring = comLablex.substring(4, comLablex.length)
     }
     return finalstring
   };

    renderCompanySettings = (type) => {
      let itemdata = null
    console.log('greenrenderCompanySettings : ', this.props.company_settings)

      if (this.props.company_settings && this.props.company_settings[type]) {
        itemdata = this.props.company_settings[type]
        // this.sortArray(itemdata);
        itemdata.sort()
        
        return itemdata.map((v, i) => (
         v !== 'Leading Self' && v !== 'Delivering Results' && v !== 'Relating to Others' && v !== 'Versatile Leader' ?
          <option key={i} value={v}>
            {this.filterZZZ(v)}
          </option>
          :null

        ))
      }
    };
    sortArray = (arr) => {
      arr.sort(function (a, b) {
        return a.length - b.length
      })
    }

    options={
      selectableRows: false,
      textLabels: {
        body: {
          noMatch: isMobile ? '...' : 'Sorry no result...'
        }
      }
    }

    handleChangeClub = event => {
      this.setState({
        selectedClub: event.target.value
      })
      console.log('green handleChangeClub : ', event.target.value)
      this.loadLeaders('club', event.target.value)
    }

    handleChangeCountry = event => {
      this.setState({
        selectedCountry: event.target.value
      })
      this.loadLeaders('country', event.target.value)
    }

    handleChangePostion = event => {
      this.setState({
        selectedPostion: event.target.value
      })
      this.loadLeaders('position', event.target.value)
    }

    handleChangeSector = event => {
      console.log('green handleChangeSector : ', event.target.value)
      this.setState({
        selectedSector: event.target.value
      })
      this.loadLeaders('sector', event.target.value)
    }

    // handleToggle = (val) => {
    //   var newval;
    //   if (val) {
    //     newval = false;
    //   }
    //   else
    //   {
    //     newval = true;
    //   }

    //   this.setState({
    //     togglePoints: newval,
    //   })
    //  this.loadLeaders('', newval);
    // }

    showdetails = () => {
      console.log('green showdetails')
    }

    loadLeaders = (crit, val) => {
      let { users, getTopLeadersFromProps, leaderboardData } = this.props
      let leaderboardKey
      let leaderboardMData = {}
      console.log('inside loadLeaders')
      leaderboardMData = Object.assign({}, this.props.leaderboardData)
      let pp

      switch (crit) {
      case 'club':
        leaderboardKey = val + '=' + this.state.selectedCountry + '=' + this.state.selectedPostion + '=' + this.state.selectedSector
        pp = this.state.togglePoints
        break
      case 'country':
        leaderboardKey = this.state.selectedClub + '=' + val + '=' + this.state.selectedPostion + '=' + this.state.selectedSector
        pp = this.state.togglePoints
        break
      case 'position':
        leaderboardKey = this.state.selectedClub + '=' + this.state.selectedCountry + '=' + val + '=' + this.state.selectedSector
        pp = this.state.togglePoints
        break
      case 'sector':
        leaderboardKey = this.state.selectedClub + '=' + this.state.selectedCountry + '=' + this.state.selectedPostion + '=' + val
        pp = this.state.togglePoints
        break
      case 'pointsy':
        leaderboardKey = this.state.selectedClub + '=' + this.state.selectedCountry + '=' + this.state.selectedPostion + '=' + this.state.selectedSector
        pp = val
        break
      default:
        leaderboardKey = this.state.selectedClub + '=' + this.state.selectedCountry + '=' + this.state.selectedPostion + '=' + this.state.selectedSector
        pp = this.state.togglePoints
        break
      }
      console.log('green this.props.leaderboardData T : ', this.props.leaderboardData)
      console.log('green this.props.leaderboardData K : ', leaderboardKey)

      // users[uid].feedbackOn && showGFeedback
      console.log('green-getTopLeadersFromProps 1 : ', pp)

      this.props.getTopLeadersFromProps({ // this.props.users
        Alldata: leaderboardMData,
        companyID: this.props.userdata.companyID,
        orderChild: 'LeaderboardKey',
        LeaderboardKey: leaderboardKey,
        firstOrder: false,
        pointsy: pp
      })

      this.setState({ leaderboardKey: leaderboardKey })
      // this.transformTableData();
      if (isBrowser) {
        this.transformTableData()
      } else {
        this.transformTableData_m()
      }
    }


    transformTableData = () => {
      let { classes, userdata, getAllCompanyUsers, users } = this.props
      let nr
      let showGFeedback
      console.log('white dataListClone users ', users)
      var PointsDisplay;
      var pointsM;
      var ponitsY;
      var threeSixty;

      let objLeng = Object.keys(this.props.users).length
      
      let dataListClone = Object.keys(users).map((uid, index) => {
        console.log('green-icon ' + users[uid].eval360)

        if ( users[uid].eval360===true) {threeSixty = ' **'} else {threeSixty = ''}

        if (users[uid].pointsCalf) {
          var pointsM = parseInt(users[uid].points) + parseInt(users[uid].pointsCalf) 
          var pointsY = parseInt(users[uid].pointsy) + parseInt(users[uid].pointsCalf) 
        }
        else
        {
          var pointsM = parseInt(users[uid].points)  
          var pointsY = parseInt(users[uid].pointsy) 
        }
        if (this.state.togglePoints) {
          PointsDisplay = pointsY
        }
        else
        {
          PointsDisplay = pointsM
        }

        if (users[uid].gfeedbackOn === false) { // show only if club id = logged in users club id
          if (users[uid].companyID === this.props.userdata.companyID) { // only show feedback here
            showGFeedback = true
          } else {
            showGFeedback = false
          }
        } else {
          showGFeedback = true
        }

        let ds = users[uid].dateRegistered
        nr = parseInt(uid) + parseInt(1)

        console.log('green avatar photoURL : ', users[uid].photoURL)
        console.log('blue userdata : ', this.props.userdata)


        return [
          <div style={{
            width: '30px',
            height: '30px',
            lineHeight: '30px',
            borderRadius: '50%',
            fontSize: '14px',
            color: '#000',
            textAlign: 'center',
            background: '#cdbc74'
          }}>{nr}</div>,

       [users[uid].companyID==='-MloJFGCyJJbs_bNlwTg' && users[uid].userID!==this.props.userdata.userID ?
           <ViewUser_gen
            src={users[uid].photoURL}
            id={users[uid].uid}
            companyName={users[uid].companyName}
            leaderArr={this.props.userdata.leaderArr}
            leaderID={users[uid].userID}
            slaveID={this.props.userdata.userID}
            companyID={this.props.userdata.companyID}
            firstName={this.props.userdata.firstName}
            lastName={this.props.userdata.lastName}
            uid={this.props.userdata.uid}
            leaderUid={users[uid].uid}
            points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
            pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
            post={users[uid]}
            following={
              this.props.followingData
                ? this.props.followingData[users[uid].userID]
                  ? true
                  : false
                : false
            }
            journalUserID={users[uid].userID}
            userIDlogged={this.props.userdata.userID}
            evalNum={this.props.userdata.evalNum ? this.props.userdata.evalNum : 0}
            evalNumP={this.props.userdata.evalNumP ? this.props.userdata.evalNumP : 0}
            />
            :

      users[uid].companyID==='-MloJFGCyJJbs_bNlwTg' && users[uid].userID===this.props.userdata.userID ?
            <ViewUser_self_gen
             src={users[uid].photoURL}
             id={users[uid].uid}
             companyName={users[uid].companyName}
             leaderID={users[uid].userID}
             slaveID={this.props.userdata.userID}
             companyID={this.props.userdata.companyID}
             firstName={this.props.userdata.firstName}
             lastName={this.props.userdata.lastName}
             uid={this.props.userdata.uid}
             leaderUid={users[uid].uid}
             points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
             pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
             post={users[uid]}
             following={
               this.props.followingData
                 ? this.props.followingData[users[uid].userID]
                   ? true
                   : false
                 : false
             }
             journalUserID={users[uid].userID}
             userIDlogged={this.props.userdata.userID}
             />
             :
    users[uid].companyID!=='-MloJFGCyJJbs_bNlwTg' && users[uid].userID!==this.props.userdata.userID ?         
          // <ViewUser
          <ViewUser_gen
            src={users[uid].photoURL}
            id={users[uid].uid}
            companyName={users[uid].companyName}
            leaderArr={this.props.userdata.leaderArr}
            leaderID={users[uid].userID}
            slaveID={this.props.userdata.userID}
            companyID={this.props.userdata.companyID}
            firstName={this.props.userdata.firstName}
            lastName={this.props.userdata.lastName}
            uid={this.props.userdata.uid}
            leaderUid={users[uid].uid}
            points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
            pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
            post={users[uid]}
            following={
              this.props.followingData
                ? this.props.followingData[users[uid].userID]
                  ? true
                  : false
                : false
            }
            journalUserID={users[uid].userID}
            userIDlogged={this.props.userdata.userID}
            evalNum={this.props.userdata.evalNum ? this.props.userdata.evalNum : 0}
            evalNumP={this.props.userdata.evalNumP ? this.props.userdata.evalNumP : 0}
          /> 
          :
    users[uid].companyID!=='-MloJFGCyJJbs_bNlwTg' && users[uid].userID===this.props.userdata.userID ?         
          // <ViewUser_self
          <ViewUser_self_gen
            src={users[uid].photoURL}
            id={users[uid].uid}
            companyName={users[uid].companyName}
            leaderID={users[uid].userID}
            slaveID={this.props.userdata.userID}
            companyID={this.props.userdata.companyID}
            firstName={this.props.userdata.firstName}
            lastName={this.props.userdata.lastName}
            uid={this.props.userdata.uid}
            leaderUid={users[uid].uid}
            points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
            pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
            post={users[uid]}
            following={
              this.props.followingData
                ? this.props.followingData[users[uid].userID]
                  ? true
                  : false
                : false
            }
            journalUserID={users[uid].userID}
            userIDlogged={this.props.userdata.userID}
          /> :null
           ]
          ,
          users[uid].firstName ? users[uid].firstName : '',
          users[uid].lastName ? users[uid].lastName : '',
          users[uid].department ? users[uid].department : '',
          users[uid].nationality ? users[uid].nationality : '',
          users[uid].companyName ? users[uid].companyName : '',
          users[uid].position ? users[uid].position : '',
         PointsDisplay,
          // users[uid].evalNum ? users[uid].evalNum : '',
          users[uid].evalNumP ? users[uid].evalNumP : '',
          users[uid].feedbackOn && showGFeedback && users[uid].sfeedback && (users[uid].sfeedback !==0) && (users[uid].sfeedback !==1) ? users[uid].sfeedback : 'n/a',
          users[uid].feedbackOn && showGFeedback && users[uid].feedback && (users[uid].feedback !==0) && (users[uid].feedback !==1) ? users[uid].feedback + [threeSixty]: 'n/a',
          users[uid].uid ? users[uid].uid : '',
          users[uid].userID ? users[uid].userID : ''
        ]
      })
      console.log('green dataListClone ', dataListClone)
      return dataListClone
    };


    transformTableData_m = () => {
      let { classes, userdata, getAllCompanyUsers, users } = this.props
      let nr
      let showGFeedback
      console.log('white dataListClone users ', users)
      var threeSixty;

      let objLeng = Object.keys(this.props.users).length

      let dataListClone = Object.keys(users).map((uid, index) => {
        if ( users[uid].eval360===true) {threeSixty = ' **'} else {threeSixty = ''}
        if (users[uid].gfeedbackOn === false) { // show only if club id = logged in users club id
          if (users[uid].companyID === this.props.userdata.companyID) { // only show feedback here
            showGFeedback = true
          } else {
            showGFeedback = false
          }
        } else {
          showGFeedback = true
        }

        let ds = users[uid].dateRegistered
        nr = parseInt(uid) + parseInt(1)

        // var imgURL = toString(users[uid].photoURL);
        // var imgURL2 = imgURL.replace(/\//g, "*");
        // var imgURL2 = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40';
        // if (imgURL) {
        //   imgURL2 = imgURL //imgURL.replace(/\/?(\?|#|$)/, '/$1');
        // // imgURL2 = PHOTO_PROFILE(users[uid].photoURL)

        console.log('green avatar photoURL : ', users[uid].photoURL)
        // };

        return [
          <div style={{
            width: '30px',
            height: '30px',
            lineHeight: '30px',
            borderRadius: '50%',
            fontSize: '14px',
            color: '#000',
            textAlign: 'center',
            background: '#cdbc74'
          }}>{nr}</div>,
          [users[uid].companyID==='-MloJFGCyJJbs_bNlwTg' && users[uid].userID!==this.props.userdata.userID ?
          // <ViewUser_ledain
          <ViewUser_gen
           src={users[uid].photoURL}
           id={users[uid].uid}
           companyName={users[uid].companyName}
           leaderArr = {this.props.userdata.leaderArr}
           leaderID={users[uid].userID}
           slaveID={this.props.userdata.userID}
           companyID={this.props.userdata.companyID}
           firstName={this.props.userdata.firstName}
           lastName={this.props.userdata.lastName}
           uid={this.props.userdata.uid}
           leaderUid={users[uid].uid}
           points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
           pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
           post={users[uid]}
           following={
             this.props.followingData
               ? this.props.followingData[users[uid].userID]
                 ? true
                 : false
               : false
           }
           journalUserID={users[uid].userID}
           userIDlogged={this.props.userdata.userID}
           evalNum={this.props.userdata.evalNum ? this.props.userdata.evalNum : 0}
           evalNumP={this.props.userdata.evalNumP ? this.props.userdata.evalNumP : 0}
           />
           :

     users[uid].companyID==='-MloJFGCyJJbs_bNlwTg' && users[uid].userID===this.props.userdata.userID ?
          // <ViewUser_ledain_self
          <ViewUser_self_gen
            src={users[uid].photoURL}
            id={users[uid].uid}
            companyName={users[uid].companyName}
            leaderID={users[uid].userID}
            slaveID={this.props.userdata.userID}
            companyID={this.props.userdata.companyID}
            firstName={this.props.userdata.firstName}
            lastName={this.props.userdata.lastName}
            uid={this.props.userdata.uid}
            leaderUid={users[uid].uid}
            points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
            pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
            post={users[uid]}
            following={
              this.props.followingData
                ? this.props.followingData[users[uid].userID]
                  ? true
                  : false
                : false
            }
            journalUserID={users[uid].userID}
            userIDlogged={this.props.userdata.userID}
            />
            :
   users[uid].companyID!=='-MloJFGCyJJbs_bNlwTg' && users[uid].userID!==this.props.userdata.userID ?         
        //  <ViewUser
         <ViewUser_gen
           src={users[uid].photoURL}
           id={users[uid].uid}
           companyName={users[uid].companyName}
           leaderArr={this.props.userdata.leaderArr}
           leaderID={users[uid].userID}
           slaveID={this.props.userdata.userID}
           companyID={this.props.userdata.companyID}
           firstName={this.props.userdata.firstName}
           lastName={this.props.userdata.lastName}
           uid={this.props.userdata.uid}
           leaderUid={users[uid].uid}
           points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
           pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
           post={users[uid]}
           following={
             this.props.followingData
               ? this.props.followingData[users[uid].userID]
                 ? true
                 : false
               : false
           }
           journalUserID={users[uid].userID}
           userIDlogged={this.props.userdata.userID}
           evalNum={this.props.userdata.evalNum ? this.props.userdata.evalNum : 0}
           evalNumP={this.props.userdata.evalNumP ? this.props.userdata.evalNumP : 0}
         /> 
         :
   users[uid].companyID!=='-MloJFGCyJJbs_bNlwTg' && users[uid].userID===this.props.userdata.userID ?         
         // <ViewUser_self
         <ViewUser_self_gen
           src={users[uid].photoURL}
           id={users[uid].uid}
           companyName={users[uid].companyName}
           leaderID={users[uid].userID}
           slaveID={this.props.userdata.userID}
           companyID={this.props.userdata.companyID}
           firstName={this.props.userdata.firstName}
           lastName={this.props.userdata.lastName}
           uid={this.props.userdata.uid}
           leaderUid={users[uid].uid}
           points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
           pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}          
           post={users[uid]}
           following={
             this.props.followingData
               ? this.props.followingData[users[uid].userID]
                 ? true
                 : false
               : false
           }
           journalUserID={users[uid].userID}
           userIDlogged={this.props.userdata.userID}
         /> :null
          ],
          users[uid].firstName ? users[uid].firstName : '',
          users[uid].lastName ? users[uid].lastName : '',
          // users[uid].department ? users[uid].department : "",
          // // users[uid].operation ? users[uid].operation : "",
          // users[uid].nationality ? users[uid].nationality : "",
          // users[uid].companyName ? users[uid].companyName : "",
          // users[uid].position ? users[uid].position : "",
          // //users[uid].points ? users[uid].points : "",
          // this.state.togglePoints ? users[uid].pointsy : users[uid].points,

         // this.state.togglePoints                        ? users[uid].pointsy : (users[uid].pointsCalf ? parseInt(users[uid].points) + parseInt(users[uid].pointsCalf) : parseInt(users[uid].points)),
          this.state.togglePoints && users[uid].pointsCalf ? parseInt(users[uid].pointsy) + parseInt(users[uid].pointsCalf) : (users[uid].pointsCalf ? parseInt(users[uid].points) + parseInt(users[uid].pointsCalf) : parseInt(users[uid].points)), 
         // users[uid].evalNum ? users[uid].evalNum : '',
          users[uid].evalNumP ? users[uid].evalNumP : '',
          users[uid].feedbackOn && showGFeedback && users[uid].sfeedback && (users[uid].sfeedback !==0) && (users[uid].sfeedback !==1) ? users[uid].sfeedback : 'n/a',
          users[uid].feedbackOn && showGFeedback && users[uid].feedback && (users[uid].feedback !==0) && (users[uid].feedback !==1) ? users[uid].feedback + [threeSixty]: 'n/a',
          users[uid].uid ? users[uid].uid : '',
          users[uid].userID ? users[uid].userID : ''
        ]
      })
      console.log('green this.props.leaderboardData S ', dataListClone)
      return dataListClone
    };

    transformTableData_m_x = () => {
      let { classes, userdata, getAllCompanyUsers, users } = this.props
      let nr
      let showGFeedback
      console.log('white dataListClone users ', users)

      let objLeng = Object.keys(this.props.users).length

      let dataListClone = Object.keys(users).map((uid, index) => {
        if (users[uid].gfeedbackOn === false) { // show only if club id = logged in users club id
          if (users[uid].companyID === this.props.userdata.companyID) { // only show feedback here
            showGFeedback = true
          } else {
            showGFeedback = false
          }
        } else {
          showGFeedback = true
        }

        let ds = users[uid].dateRegistered
        nr = parseInt(uid) + parseInt(1)
        var threeSixty;
        // var imgURL = toString(users[uid].photoURL);
        // var imgURL2 = imgURL.replace(/\//g, "*");
        // var imgURL2 = 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40';
        // if (imgURL) {
        //   imgURL2 = imgURL //imgURL.replace(/\/?(\?|#|$)/, '/$1');
        // // imgURL2 = PHOTO_PROFILE(users[uid].photoURL)

        console.log('green avatar photoURL : ', users[uid].photoURL)
        // };

        return [
          <div style={{
            width: '30px',
            height: '30px',
            lineHeight: '30px',
            borderRadius: '50%',
            fontSize: '14px',
            color: '#000',
            textAlign: 'center',
            background: '#cdbc74'
          }}>{nr}</div>,
          // <ViewUser
          <ViewUser_gen
            // alt={users[uid].firstName + ' ' + users[uid].lastName}
            //  //alt={'piet pompies'}
            // src={(users[uid].photoURL > 0) ? users[uid].photoURL : PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40') }
            src={users[uid].photoURL}
            id={users[uid].uid}
            // src={'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40'}
            // src={imgURL2 ? imgURL2 : 'https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/appassets%2Fdefault-user.png?alt=media&token=7a1a942d-4a23-4ea4-aac5-cac77daebc40' }
            companyName={users[uid].companyName}
            leaderArr={this.props.userdata.leaderArr}
            leaderID={users[uid].userID}
            slaveID={this.props.userdata.userID}
            companyID={this.props.userdata.companyID}
            firstName={this.props.userdata.firstName}
            lastName={this.props.userdata.lastName}
            uid={this.props.userdata.uid}
            leaderUid={users[uid].uid}
            points={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.points) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.points)}
            pointsy={this.props.userdata.pointsCalf ? parseInt(this.props.userdata.pointsy) + parseInt(this.props.userdata.pointsCalf) : parseInt(this.props.userdata.pointsy)}
            evalNum={this.props.userdata.evalNum ? this.props.userdata.evalNum : 0}
            evalNumP={this.props.userdata.evalNumP ? this.props.userdata.evalNumP : 0}
            //pointsy={this.props.userdata.pointsy}
          />,
          users[uid].firstName ? users[uid].firstName : '',
          users[uid].lastName ? users[uid].lastName : '',
          // users[uid].department ? users[uid].department : "",
          // // users[uid].operation ? users[uid].operation : "",
          // users[uid].nationality ? users[uid].nationality : "",
          // users[uid].companyName ? users[uid].companyName : "",
          // users[uid].position ? users[uid].position : "",
          // //users[uid].points ? users[uid].points : "",
          // this.state.togglePoints ? users[uid].pointsy : users[uid].points,

         // this.state.togglePoints                        ? users[uid].pointsy : (users[uid].pointsCalf ? parseInt(users[uid].points) + parseInt(users[uid].pointsCalf) : parseInt(users[uid].points)),
          this.state.togglePoints && users[uid].pointsCalf ? parseInt(users[uid].pointsy) + parseInt(users[uid].pointsCalf) : (users[uid].pointsCalf ? parseInt(users[uid].points) + parseInt(users[uid].pointsCalf) : parseInt(users[uid].points)), 
         // users[uid].evalNum ? users[uid].evalNum : '',
          users[uid].evalNumP ? users[uid].evalNumP : '',
          users[uid].feedbackOn && showGFeedback && users[uid].sfeedback && (users[uid].sfeedback !==0) && (users[uid].sfeedback !==1) ? users[uid].sfeedback : 'n/a',
          users[uid].feedbackOn && showGFeedback && users[uid].feedback && (users[uid].feedback !==0 ) && (users[uid].feedback !==1) ? users[uid].feedback + threeSixty: 'n/a',
          users[uid].uid ? users[uid].uid : '',
          users[uid].userID ? users[uid].userID : ''
        ]
      })
      console.log('green this.props.leaderboardData S ', dataListClone)
      return dataListClone
    };

    render() {
      let { leaderboardData, users } = this.props
    
      console.log('greenthis.companyValues ', this.props.companyValues)
      console.log('green this.props. leaderboardData 5 ', this.props.leaderboardData)
      console.log('green this.props. users 5 ', this.props.users)

      // const getMuiTheme = () =>
      // createTheme({
      //   components: {
      //     tableBody: {
      //       "& > :not(:last-child)": {
      //        // borderBottom: "25px solid red",
      //         border: "0px solid red",
      //       }},
      //     MuiTableCell: {
      //       styleOverrides:{ root: {
      //         padding: '0px 1px 0px 0px',
      //         backgroundColor: '#CDCAC6',
      //         minWidth: '10px',
      //         Width: '10px',
      //         maxWidth: '10px',
      //         borderCollapse: 'separate',
      //         borderSpacing: '0px 0px'
      //       }}
      //     },
      //     MuiToolbar: {
      //       styleOverrides:{regular: {
      //         minHeight: '6px'
      //       }}
      //     }
      //   },
      //     overrides: {
      //       MUIDataTable: {
      //         root: {
      //           backgroundColor: '#FF000'
      //         },
      //         paper: {
      //           boxShadow: 'none'
      //         },
      //         responsiveScroll: {
      //           marginTop: '50px'
      //         }
      //       },
      //       MUIDataTableHeadCell: {
      //         root: {
      //           padding: '0px',
      //           verticalAlign: 'top!important',
      //           padding: '0px 1px 0px 0px'
      //         },
      //         head: {
      //           fontSize: '0.7rem',
      //           fontWeight: '500',
      //           padding: '0px 1px 0px 0px',
      //           verticalAlign: 'textTop',
      //           color: 'none'
      //         }
      //       },
      //       MUIDataTableBodyCell: {
      //         root: {
      //           borderBottom: '0px',
      //           fontSize: '0.70rem!important',
      //           whiteSpace: 'normal',
      //           wordWrap: 'break-word',
      //           overflowWrap: 'break-word',
      //          // padding: '0px 0px 0px 8px',
      //           padding: '0px 1px 0px 0px',
      //           color: 'black',
      //           minWidth: 5,
      //           borderCollapse: 'separate',
      //           borderSpacing: '0px 0px'
      //         }
      //       },
      //       MUIDataTableSelectCell: {
      //         root: {
      //           borderBottom: '0px',
      //           minWidth: '5px',
      //           Width: '10px',
      //           maxWidth: '5px',
      //           borderCollapse: 'separate',
      //           borderSpacing: '0px 0px'
      //         }
      //       },
      //       MUIDataTableHeadCell: {
      //         sortAction: {
      //           '& path': {
      //             color: '#000000', // or whatever you need
      //           }
      //         },
      //         sortActive: {
      //           color: '#ffffff' // whatever you need
      //         }
      //       },
      //       MUIDataTableToolbarSelect: {
      //         root: {
      //           display: 'inlineBlock'
      //         }
      //       }
      //     }
      //   })

      const getMuiTheme = () =>
      createTheme({
          overrides: {
            MUIDataTable: {
              root: {
                backgroundColor: '#FF000'
              },
              paper: {
                boxShadow: 'none'
              },
              responsiveScroll: {
                marginTop: '50px'
              }
            },
            MUIDataTableHeadCell: {
              root: {
                padding: '0px',
                verticalAlign: 'top!important'
              },
              head: {
                fontSize: '0.7rem',
                fontWeight: '500',
                padding: '0px',
                verticalAlign: 'textTop'
              }
            },
            MUIDataTableBodyCell: {
              root: {
                borderBottom: '0px',
                fontSize: '0.70rem!important',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                padding: '0px 1px 0px 0px'
              }
              // responsiveStacked: {
              //   height: "34px!important",
              //   fontSize: "0.70rem!important"
              // },
              // cellStacked: {
              //   height: "44px!important",
              //   fontSize: "0.70rem!important",
              //   // width: "50px!important",
              //   textAlign: "right!important"
              // }
            },
            MUIDataTableSelectCell: {
              root: {
                borderBottom: '0px'
              }
            },
            MUIDataTableToolbarSelect: {
              root: {
                display: 'inlineBlock'
              }
            }
          }
        });

      // const getMuiTheme = () =>
      // createTheme({
      //   components: {
      //     MuiTableCell: {
      //       styleOverrides:{ root: {
      //         padding: '8px',
      //         backgroundColor: '#CDCAC6',
      //         width: '300px',
      //         maxWidth: '300px'
      //       }}
      //     },
      //     MuiToolbar: {
      //       styleOverrides:{regular: {
      //         minHeight: '6px'
      //       }}
      //     }
      //   }
      // });

      // MUIDataTableHeadCell({
      //   sortAction: {
      //       '& path': {
      //         color: "#ffffff" // or whatever you need
      //       },
      //    },
      //   sortActive: {
      //     color: "#000000"  // whatever you need
      //   }
      // });

      const getMuiTheme_S = () =>
      createTheme({
          overrides: {
            MuiChip: {
              root: {
                backgroundColor: '#000000'
              }
            }
          }
        })

      return (
        <div>
          <div align='center'>
            { this.props.userdata.execmember ? null :
              <font color='green'>
                <a target='main' href='https://mp.exec-club.com/' >Sign up for Executive Club to access our Learning Platform, generate more real-time points and utilise the leadership feedback system.</a>
              </font>
            }
          </div>
          {isMobile ?
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead height='30px'>
                  <TableRow />
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell> 
                       {/* style={{ fontSize: 10, flex: 1, flexDirection: 'row' }}> */}
                      <GenTutorial
                        // url={'https://youtu.be/FkavqYiH6v0'}
                        //url={'https://youtu.be/_Y4jOW71yJ8'}
                        url={'https://youtu.be/uIF_Ynheocg?feature=shared'}
                      />
                    </TableCell>
                    <TableCell style={{ fontSize: 10, flex: 1, flexDirection: 'row' }}>
                      {this.state.togglePoints ? 'Annual: ' : 'All-time: '}
                    </TableCell>
                    <TableCell>
                      <ToggleButton
                        value={ this.state.togglePoints || false }
                        thumbStyle={{ borderRadius: '2' }}
                        trackStyle={{ borderRadius: '2' }}
                        onToggle={(value) => {
                          this.setState({
                            togglePoints: !value
                          })
                          this.loadLeaders('pointsy', !value)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
             Club:
                    </TableCell>
                    <TableCell>
                      <select value={this.state.selectedClub} onChange={this.handleChangeClub}>
                        <option value={this.props.userdata.companyID}>My Club</option>
                        <option value='x'>All Clubs</option>
                      </select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
             Country:
                    </TableCell>
                    <TableCell>
                      <select value={this.state.selectedCountry} onChange={this.handleChangeCountry}>
                        <option value='x'>All Countries</option>
                        {this.renderGeneralSettings('countriesb')}
                      </select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
             Sector:
                    </TableCell>
                    <TableCell>
                      <select
                        onChange={this.handleChangeSector}
                        // onChange={this.hangleChange}
                        onClose={this.handleClose}
                        onOpen={this.handleOpen}
                        // value={competencyid}
                        value={this.state.selectedSector}
                        style={{
                          width: 200,
                          height: 35,
                          color: 'gray',
                          paddingLeft: 5,
                          fontSize: 14,
                          border: 'none',
                          marginLeft: 10
                        }}
                      >
                        <option value='x'>All Sectors / Divisions</option>
                        <option value=''>
                          <em>None</em>
                        </option>
                        {this.props.companyValues &&
              Object.keys(this.props.companyValues).map(id => {
                const labelId = `${this.props.companyValues[id].valueID}`
                const labelName = `${this.props.companyValues[id].valueDesc}`
                // console.log('harmony-competencies : ', labelId + ' -- ' + labelName)
                let labelNamex = this.filterZZZ(labelName)
                return (
                  (this.props.userdata.companyID === '-LjW7UI7e0uUikYrw-Vj')
                    ?
                    labelId !== 'b4286108-a99d-46d4-bb9d-eacc64253bf0' &&
                   labelId !== '07ea19b6-19d1-4f42-b926-7579008945cd' &&
                   labelId !== 'ea422d9b-fe94-4110-9db2-512c8e7607b9' &&
                   labelId !== 'fca710c4-931c-4562-8e58-1a0eb1854a06' &&
                   labelId !== '89dac404-0800-41fd-b49d-7ac74c221106' &&
                   labelId !== 'f6c65e5b-8b5f-4ce9-a01a-f1b9f60fc20e' &&
                   labelId !== '2fb61900-3569-4a63-ba00-36ffccf5612b' ?
                      <option key={labelId} value={labelId}>{labelNamex}</option> : null
                    : <option key={labelId} value={labelId}>{labelNamex}</option>
                )
              })}
                      </select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
             Position:
                    </TableCell>
                    <TableCell>
                      <select value={this.state.selectedPosition} onChange={this.handleChangePostion}>
                        <option value='x'>All Positions / Levels</option>
                        {this.renderGeneralSettings('position')}
                      </select>
                    </TableCell>
                  </TableRow>
                </TableBody>

              </Table>
            </TableContainer>
            :
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead height='1px' />
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontSize: 10, flex: 1, flexDirection: 'row' }}>
                      <GenTutorial
                       // url={'https://youtu.be/FkavqYiH6v0'}
                        url={'https://youtu.be/_Y4jOW71yJ8'}
                      />
                    </TableCell>
                    <TableCell style={{ fontSize: 10, flex: 1, flexDirection: 'row' }}>
                      {this.state.togglePoints ? 'Annual: ' : 'All-time: '}
                    </TableCell>
                    <TableCell>
                      <ToggleButton
                        value={ this.state.togglePoints || false }
                        thumbStyle={{ borderRadius: '2' }}
                        trackStyle={{ borderRadius: '2' }}
                        onToggle={(value) => {
                          this.setState({
                            togglePoints: !value
                          })
                          this.loadLeaders('pointsy', !value)
                        }}
                      />
                      {/* <ToggleButton
                     // value={ this.state.togglePoints ? false : true }
                      thumbStyle={{borderRadius: '2' }}
                      trackStyle={{borderRadius: '2' }}
                      onToggle={this.handleToggle(this.state.togglePoints)} /> */}
                    </TableCell>

                    <TableCell>
                      <select value={this.state.selectedClub} onChange={this.handleChangeClub}>
                        { this.state.selectedClub === 'xx'
                          ?
                          <option value={this.props.userdata.companyID} selected>My Club</option>
                          :
                          <option value={this.props.userdata.companyID}>My Club</option>
                        }

                        <option value='x'>All Clubs</option>
                      </select>
                    </TableCell>
                    <TableCell>
                      <select value={this.state.selectedCountry} onChange={this.handleChangeCountry}>
                        <option value='x'>All Countries</option>
                        {this.renderGeneralSettings('countriesb')}
                      </select>
                    </TableCell>
                    <TableCell>
                      <select value={this.state.selectedSector} onChange={this.handleChangeSector}>
                        <option value='x'>All Sectors / Divisions</option>
                        {this.renderCompanySettings('departments')}
                      </select>

                      {/* <select
                    onChange={handleChangeSector}
                    value={this.state.selectedSector}
                    style={{
                      width: 200,
                      height: 35,
                      color: 'gray',
                      paddingLeft: 5,
                      fontSize: 14,
                      border: 'none',
                      marginLeft: 10,
                    }}
                  >
                    <option value="x">All Sectors</option>
                    <option value="">
                      <em>None</em>
                    </option>
                    {this.props.companyValues &&
                      Object.keys(this.props.companyValues).map(id => {
                        const labelId = `${this.props.companyValues[id].valueID}`;
                        const labelName = `${this.props.companyValues[id].valueDesc}`;
                        let labelNamex = filterZZZ(labelName)
                        return (
                          (this.props.userdata.companyID==='-LjW7UI7e0uUikYrw-Vj')
                          ?
                          labelId!=='b4286108-a99d-46d4-bb9d-eacc64253bf0' &&
                          labelId!=='07ea19b6-19d1-4f42-b926-7579008945cd' &&
                          labelId!=='ea422d9b-fe94-4110-9db2-512c8e7607b9' &&
                          labelId!=='fca710c4-931c-4562-8e58-1a0eb1854a06' &&
                          labelId!=='89dac404-0800-41fd-b49d-7ac74c221106' &&
                          labelId!=='f6c65e5b-8b5f-4ce9-a01a-f1b9f60fc20e' &&
                          labelId!=='2fb61900-3569-4a63-ba00-36ffccf5612b'?
                          <option key={labelId} value={labelId}>{labelNamex}</option> : null
                          : <option key={labelId} value={labelId}>{labelNamex}</option>
                        );
                      })}
                  </select>                    */}
                    </TableCell>
                    <TableCell>
                      <select value={this.state.selectedPosition} onChange={this.handleChangePostion}>
                        <option value='x'>All Positions / Levels</option>
                        {this.renderGeneralSettings('position')}
                      </select>
                    </TableCell>
                    {/* <TableCell>
                  <Button
                    variant='contained'
                    onClick={this.loadLeaders.bind(this)}
                    autoFocus
                    type='submit'
                  >
                    Show List
                  </Button>
                  </TableCell> */}
                  </TableRow>
                </TableBody>

              </Table>
            </TableContainer>
          }
          <div />

          {this.props.users ?
          <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={''}
                data={isBrowser ? this.transformTableData() : this.transformTableData_m()}
                columns={isBrowser ? this.columns : this.columnsm}
                options={this.options}
                style={{ id: 'matrix' }}
              />

            </MuiThemeProvider>
            </div>
            : null}
        </div>
      )
    }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    followingData: state.userdata.followingButton,
    user_exists: state.users.userexists,
    company_settings: state.users.company_settings,
    general_settings: state.users.general_settings,
    leaderboardData: state.users.leaderboardData,
    users: state.users.users,
    companyValues: state.pldp.companyValues
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTopLeadersFromProps: params => dispatch(getTopLeadersFromProps(params)),
    showToast: params => dispatch(showToast(params)),
    getAllCompanySettings: params => dispatch(getAllCompanySettings(params)),
    getAllGeneralSettings: params => dispatch(getAllGeneralSettings(params)),
    fetchCompanyValues: params => dispatch(getCompanyValues(params))
    // leaderboardAll: params => dispatch(leaderboardAll(params))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(LeaderOnList)
