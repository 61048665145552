/* eslint-disable no-inline-comments */
/* eslint-disable no-shadow */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import SubjectIcon from '@material-ui/icons/Subject';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import { LIGHTBLUE, LIGHTGREY, DEEPORANGE, PINK, LIGHTBLUE300 } from '../../utils/color_utils'
import { getAllCompanyGroups, deleteGroup } from '../../store/actions/companyAdminActions'

import { getGroupEvents, getGroupEventsReminders, deleteGroupEvents } from "../../store/actions/companyAdminActions";

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 40 + rand()
const left = 50 + rand()

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  linkBtn: {
    backgroundColor: `${LIGHTBLUE}`,
    color: '#ffffff',
    '&:hover, &:focus': {
      textDecoration: 'none',
      backgroundColor: `${LIGHTBLUE300}`,
    },
    '&:active': {
      textDecoration: 'none',
      backgroundColor: `${LIGHTBLUE300}`,
    },
  },
  icon: {
    margin: theme.spacing.unit * 2
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  groupslabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: `${LIGHTBLUE}`,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderBottom: `1px solid ${LIGHTGREY}`,
    padding: theme.spacing()
  },
  groupText: {
    fontSize: '0.875rem!important'
  },
  badge: {
    backgroundColor: DEEPORANGE,
    padding: 3,
    borderRadius: 4,
    color: '#fff',
    marginLeft: '12px'
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
})

class EventsListAdmin extends Component {
  state = {
    groups: [],
    initPosts: false,
    checked: [],
    anchorEl: null,
    openMenu: false,
    openConfirm: false,
    event_title: '',
    event: {}
  };


  componentDidMount() {
    this.props.getAllCompanyGroups({
      companyID: this.props.userdata.companyID
    })

    this.props.getGroupEvents({
      group_id: this.props.group_id
    })
  }

  componentDidUpdate() {
  }

  handleOpenClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      openMenu: true
    })
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      openMenu: false
    })
  };

  deleteEvent = () => {
    this.props.deleteGroupEvents({ group_id: this.state.event.group_id, event_id: this.state.event.event_id })
    this.handleCloseConfirmation();
  }

  deleteEvents = (event) => e => {
    this.setState({ openConfirm: true, event_title: event.event_title, event: event })
  }

  handleCloseConfirmation = () => {
    this.setState({ openConfirm: false, event_title: '', event: {} })
  }

  editEvent = (event) => e => {
    this.props.getGroupEventsReminders({ group_id: event.group_id });
    this.props.editEvent(event);
  }

  loadGroupMembers = (name, id) => {
    this.props.handleClick(name, id)
  }

  getRandomColor() {
    let colorValues = ['teal']
    return colorValues[Math.floor(Math.random() * colorValues.length)]
  }


  render() {
    const { classes, group_events } = this.props
    return (
      <div>
        <div className={classes.root}>
          <Grid container spacing={2}>
            {group_events &&
              Object.keys(group_events).map(id => {
                const event_title = group_events[id].event_title
                const event_location = group_events[id].event_location
                const event_start_date = group_events[id].event_start_date
                const event_end_date = group_events[id].event_start_date
                const event_description = group_events[id].event_description
                return (
                  <Grid key={id} item xs={12} sm={4}>
                    <Card>
                      <CardActionArea>
                        <CardContent>
                          <Typography gutterBottom variant='h6' component='h2'>
                            {event_title}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <Grid container>
                                <Grid item>
                                  <AccessTimeIcon fontSize="small" style={{ color: '#5f6368' }} />
                                </Grid>
                                <Grid item xs={11} sm={11}>
                                  <Typography variant="caption">{event_start_date} {event_end_date}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Grid container>
                                <Grid item>
                                  <SubjectIcon fontSize="small" style={{ color: '#5f6368' }} />
                                </Grid>
                                <Grid item xs={11} sm={11}>
                                  <Typography variant="body2">{event_description}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Grid container>
                                <Grid item>
                                  <RoomOutlinedIcon fontSize="small" style={{ color: '#5f6368' }} />
                                </Grid>
                                <Grid item xs={11} sm={11}>
                                  <Typography variant="body2">{event_location}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                          </Grid>
                        </CardContent>
                      </CardActionArea>
                      <CardActions style={{ float: 'right' }}>
                        <Tooltip title="Edit event">
                          <IconButton size="small" edge="end" aria-label="edit" onClick={this.editEvent(group_events[id])}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete event">
                          <IconButton size="small" edge="end" aria-label="delete" onClick={this.deleteEvents(group_events[id])}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </CardActions>
                    </Card>
                  </Grid>)
              })}
          </Grid>
        </div>

        <Modal
          aria-labelledby='confirmation-modal'
          aria-describedby='confirmation-modal-description'
          open={this.state.openConfirm}
          className={classes.modal}
        >
          <div style={modalStyle2} className={classes.paper}>
            <Typography variant='h6' gutterBottom>Please Confirm</Typography>
            <Typography variant='body' gutterBottom>Are you sure you want to delete {this.state.editText}?</Typography>
            <div>
              <ExpansionPanelActions>
                <Button onClick={this.handleCloseConfirmation} size='small'>Cancel</Button>
                <Button onClick={this.deleteEvent} size='small' color='secondary'>Delete</Button>
              </ExpansionPanelActions>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

EventsListAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedGroup: PropTypes.func.isRequired
}

const modalStyle2 = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}

const EventsListAdminStyled = withStyles(styles)(EventsListAdmin)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    company_groups: state.users.company_groups,
    group_events: state.users.group_events,
    group_events_reminders: state.users.group_events_reminders,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllCompanyGroups: params => dispatch(getAllCompanyGroups(params)),
    getGroupEvents: params => dispatch(getGroupEvents(params)),
    getGroupEventsReminders: params => dispatch(getGroupEventsReminders(params)),
    deleteGroupEvents: params => dispatch(deleteGroupEvents(params)),
    deleteGroup: params => dispatch(deleteGroup(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsListAdminStyled)
