/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import moment from 'moment'
let _DATE_FORMAT = {}

let dateFormatTypes = {
  BADGE: {
    format: 'DD MMMM YYYY hh:mm A'
  },
  NOTIFICATIONS: {
    format: 'MMMM DD hh:mm A'
  },
  TASK: {
    format: 'MMM DD hh:mm'
  },
  SHORT: {
    format: 'DD MMM YYYY'
  }
}

/**
 * Creates a formatted version of the date
 * @param Date in milliseconds
 * @return String "DD MMMM YYYY h:mm:ss A"
 */

_DATE_FORMAT.type = (dt, type) => {
  return moment(dt).format(dateFormatTypes[type].format)
}

function _DEFAULT_DATE_TIME(type, dt) {
  return type === 'DATE'
    ? moment(dt || Date.now()).format('YYYY-MM-DD')
    : type === 'TIME'
      ? moment(dt || Date.now()).format('HH:mm')
      : moment(dt || Date.now()).format('YYYY-MM-DD')
}

function formatDateTimeMacIOS(date, time) {
  let stringDate = moment(date).format('YYYY-MM-DD')

  let getDatePart = stringDate.split('-')

  let actualDate =
    getDatePart[1] +
    '/' +
    getDatePart[2] +
    '/' +
    getDatePart[0] +
    ' ' +
    time +
    ':00'

  let fullDateTimeStr = new Date(actualDate)

  return fullDateTimeStr
}

export {
  _DATE_FORMAT as DATE_FORMAT,
  _DEFAULT_DATE_TIME,
  formatDateTimeMacIOS
}
