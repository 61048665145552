import { STORE_SUBCOMPETENCYID } from '../actions/types';

const initState = {
    type: STORE_SUBCOMPETENCYID,
    cvalue: '',
    scompname: ''
};

const subcompReducer = (state = initState , action) => {
  switch(action.type){
    case STORE_SUBCOMPETENCYID:
      return{ 
        ...state,
        type: STORE_SUBCOMPETENCYID,
        cvalue: action.cvalue,
        scompname: action.scompname
      }
    default:
      return state
  }
  
}

export default subcompReducer