import firebase from "../firebase";
import { _toArray } from "./commonActions";

export const fetchUserBiography = params => {
  return (dispatch, getState) => {
    firebase.database.ref(`users/${params.userID}`).on("value", function(data) {
      if (data.val()) {
        dispatch({
          type: "USER_BIOGRAPHY",
          biography: data.val()
        });

        if (params.showprofile) {
          dispatch({
            type: "VIEW_USER_PROFILE"
          });
        }
      } else {
        return (dispatch, getState) => {
          dispatch({
            type: "USER_BIOGRAPHY",
            biography: ""
          });
        };
      }
    });
  };
};

export const openUserProfileDialog = params => {
  return (dispatch, getState) => {
    dispatch({
      type: "VIEW_USER_PROFILE",
      showprofiledlg: params
    });
  };
};
