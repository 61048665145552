import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { emphasize, withStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  Paper,
  Grid,
  Typography, Breadcrumbs, Chip, Tooltip, Button,
  Link as FileLink, ExpansionPanelActions, Modal,
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import MUIDataTable from 'mui-datatables'
import UploadFiles from '../../components/UploadFiles'
import DescriptionIcon from '@material-ui/icons/Description'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import IconButton from '@material-ui/core/IconButton'
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import indigo from '@material-ui/core/colors/indigo'
import { addStudyMaterials, getActiveStudyMaterials, getInActiveStudyMaterials, updateStudyMaterials } from "../../store/actions/companyAdminActions";
import { SUPPORTED_FILE_TYPES, SHOW_TOAST } from "../../store/actions/types";
import { showToast } from '../../store/actions/toastAction'
import { DATE_FORMAT } from "../../utils/date_format_utils";
import FileViewer from "../FileViewer";
import AssignedGroups from "./AssignedGroups";
import { GREEN, RED } from "../../utils/color_utils";
import { RadioGroup, Radio } from 'react-radio-group'

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 40 + rand()
const left = 50 + rand()

const columns = ['Name', 'Size (Kbs)', 'Type', 'Date Uploaded']

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    boxShadow: 'none'
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  userTypeButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${indigo[500]}`,
    border: '1px solid',
  },
  archiveButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${RED}`,
    border: '1px solid',
  },
  activateButton: {
    margin: theme.spacing.unit,
    fontSize: '10px',
    color: `${GREEN}`,
    border: '1px solid',
  },
  icon: {
    fontSize: '20px'
  }
})


let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    multiple,
    filterby,
    onSelectedFile,
    archiveSelectedFile,
    onOpenAssignedGroups,
  } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      elevation={0}
    >
      <div className={classes.title} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {numSelected === 1 ? (
              <React.Fragment>
                {filterby === 'active' ? (
                  <React.Fragment>
                    <Button
                      variant='outline'
                      aria-label='View assigned groups'
                      onClick={onOpenAssignedGroups}
                      className={classes.userTypeButton}
                      size='small'
                    >
                      <AssignmentIcon className={classes.icon} />
                      View Assigned Groups
                    </Button>
                    <Button
                      variant='outline'
                      aria-label='Archive'
                      onClick={archiveSelectedFile}
                      className={classes.archiveButton}
                      size='small'
                    >
                      <PlaylistAddCheckIcon className={classes.icon} />
                      Archive
                    </Button>
                  </React.Fragment>
                ) : <React.Fragment>
                    <Button
                      variant='outline'
                      aria-label='Archive'
                      onClick={onSelectedFile}
                      className={classes.activateButton}
                      size='small'
                    >
                      <PlaylistAddCheckIcon className={classes.icon} />
                      Activate
                    </Button>
                  </React.Fragment>}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)


const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none',
    },
  },
}))(Chip);

const styles = theme => ({
  root: {
    marginTop: 0,
    overflowX: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '130px',
      padding: '0 10px'
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  pageHeader: {
    marginTop: 0,
    padding: theme.spacing(1)
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
})

class StudyMaterials extends Component {
  state = {
    uploadinprogress: false,
    filetitle: '',
    fileurl: '',
    fileuploaded: false,
    filesObj: [],
    filterby: 'active',
    dataListClone: [],
    openfileviewer: false,
    filename: "",
    filetype: "",
    filepath: "",
    openConfirm: false,
    open: false,
    file: {}
  };

  options = {
    filterType: 'radio',
    selectableRows: 'single',
    customToolbarSelect: e => {
      return (
        <EnhancedTableToolbar
          numSelected={e.data.length}
          multiple={e.data.length > 0}
          options={{
            elevation: 0
          }}
          filterby={this.state.filterby}
          onSelectedFile={() =>
            this.handleSelectedFile()
          }
          archiveSelectedFile={() =>
            this.handleArchiveSelectedFile()
          }
          onOpenAssignedGroups={() =>
            this.handleOpenAssignedGroups()
          }
        />
      )
    },
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      let { active_study_materials, inactive_study_materials } = this.props

      // console.log("currentRowsSelected: ", currentRowsSelected)

      // let data =
      //   users &&
      //   Object.keys(users).map((v, i) => {
      //     return { data: users[v], uid: users[v].id }
      //   })

      if (currentRowsSelected[0] !== undefined) {
        window.sf = this.state.dataListClone[currentRowsSelected[0].dataIndex];
      }
    }
  }

  handleSelectedFile = () => {
    let sFile = window.sf;

    this.props.updateStudyMaterials({ companyID: this.props.userdata.companyID, id: sFile[4], status: this.state.filterby === 'active' ? 'archive' : 'activate' })
  };

  handleArchiveSelectedFile = () => {
    this.setState({
      openConfirm: true
    })
  };

  confirmArchiveFile = () => {
    let sFile = window.sf;

    this.props.updateStudyMaterials({ companyID: this.props.userdata.companyID, id: sFile[4], status: this.state.filterby === 'active' ? 'archive' : 'activate' })

    this.handleCloseConfirm()
  };

  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false
    })
  };

  handleOpenAssignedGroups = () => {
    let sFile = window.sf;

    this.setState({
      open: true,
      file: sFile[5]
    })
  };

  handleCloseAssigned = () => {
    this.setState({
      open: false,
      file: {}
    })
  };

  fileUploadFinished = (url, filename, metadata) => {
    this.setState({
      ...this.state,
      fileurl: url,
      filetitle: filename,
      fileuploaded: false
    })

    metadata.companyID = this.props.userdata.companyID;
    metadata.filename = filename;
    metadata.url = url;

    this.props.addStudyMaterials(metadata);
  };

  uploadInProgress = status => {
    this.setState({
      ...this.state,
      uploadinprogress: status
    })

    // this.props.onChange("uploadInProgress", status);
  };

  handleFileViewerClose = () => {
    this.setState(state => ({
      openfileviewer: false,
      filename: "",
      filetype: "",
      filepath: "",
      file: {}
    }))
  };

  openFileViewer = (file) => {
    let filename = file.filename;
    let filepath = file.filepath;
    let lastDot = filename.lastIndexOf('.');
    const ext = filename.substring(lastDot + 1);
    filename = filename.substring(0, filename.lastIndexOf('.'));

    if (SUPPORTED_FILE_TYPES[ext]) {
      this.setState(state => ({
        openfileviewer: true,
        filetype: ext,
        filepath: filepath,
        filename: filename,
        file: file
      }))
    } else {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Unsupported file type!'
      }

      this.props.showToast(params)
    }

  };

  componentDidMount() {
    this.props.getActiveStudyMaterials({ companyID: this.props.userdata.companyID })
  }

  transformTableData = () => {
    let { active_study_materials, inactive_study_materials } = this.props
    let study_materials = this.state.filterby === 'active' ? active_study_materials : inactive_study_materials;

    let dataListClone = Object.keys(study_materials).map((uid, index) => {
      var file_date = new Date(study_materials[uid].updated);
      var date_updated = DATE_FORMAT.type(file_date, "SHORT");
      let file = {
        filepath: study_materials[uid].url,
        filename: study_materials[uid].filename,
        companyID: study_materials[uid].companyID,
        name: study_materials[uid].name,
        size: study_materials[uid].size,
        url: study_materials[uid].url,
        type: study_materials[uid].type,
        contentType: study_materials[uid].contentType,
        study_material_id: study_materials[uid].study_material_id,
      }
      return [
        <FileLink
          component="button"
          variant="body2"
          onClick={() => {
            this.openFileViewer(file);
          }}
        >
          {study_materials[uid].filename}
        </FileLink>,
        study_materials[uid].size,
        study_materials[uid].contentType === 'application/pdf' ? <PictureAsPdfIcon color='secondary' /> : <DescriptionIcon color='primary' />,
        date_updated,
        uid,
        file
      ]
    })

    this.state = { ...this.state, dataListClone: dataListClone };

    return dataListClone
  };

  handleFilteredFiles = (val) => {
    if (val.trim() == "active") {
      this.setState({ filterby: 'active' })
      this.props.getActiveStudyMaterials({ companyID: this.props.userdata.companyID })
    } else if (val.trim() == "archived") {
      this.setState({ filterby: 'archived' })
      this.props.getInActiveStudyMaterials({ companyID: this.props.userdata.companyID })
    } else {
      this.setState({ filterby: 'active' })
      this.props.getActiveStudyMaterials({ companyID: this.props.userdata.companyID })
    }
  }

  render() {
    const { filepath, filetype, filename, openfileviewer, file } = this.state;
    const { classes } = this.props

    return (
      <div>
        <Grid container spacing={2} gutterBottom style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={12}>
            <Paper style={{ maxWidth: '100vw' }} className={classes.pageHeader}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant='h5'>Study Materials</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                      component={Link}
                      to={`/companyadmin`}
                      label="Club Admin"
                    />
                    <StyledBreadcrumb
                      component={Link}
                      to={`/groups`}
                      label="Manage Groups"
                    />
                    <StyledBreadcrumb
                      label="Study Materials"
                    />
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <UploadFiles
                        onUploadComplete={this.fileUploadFinished.bind(this)}
                        onUploadProgress={this.uploadInProgress.bind(this)}
                        uploadtype='study_material'
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ textAlign: 'center' }}>
                    <RadioGroup name="filteredtasks" selectedValue={this.state.filterby} onChange={this.handleFilteredFiles}>
                      <Radio value="active" /> Active
                      <Radio value="archived" style={{ marginLeft: '8px' }} /> Archived
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <MUIDataTable
                    title={''}
                    data={this.transformTableData()}
                    columns={columns}
                    options={this.options}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <FileViewer
          filetype={filetype}
          filename={filename}
          filepath={filepath}
          opendialog={openfileviewer}
          handleClose={this.handleFileViewerClose} />
        <Dialog
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.open}
          fullWidth
          maxWidth="md"
        >
          <DialogContent style={{ margin: '0px 10px 0px 10px', height: "80vh" }}>
            <AssignedGroups userdata={this.props.userdata} file={file} />

          </DialogContent>
          <DialogActions>
            <div style={{ justifyContent: 'flex-start' }}>
              <Button onClick={this.handleCloseAssigned} size='small' variant='outlined'>Close</Button>
            </div>
          </DialogActions>
        </Dialog>
        <Modal
          aria-labelledby='confirmation-modal'
          aria-describedby='confirmation-modal-description'
          open={this.state.openConfirm}
          className={classes.modal}
        >
          <div style={modalStyle2} className={classes.paper}>
            <Typography variant='h6' gutterBottom>Please Confirm</Typography>
            <Typography variant='body2' gutterBottom>Do you want to archive the selected study material?</Typography>
            <div>
              <ExpansionPanelActions>
                <Button onClick={this.handleCloseConfirm} size='small'>Cancel</Button>
                <Button onClick={this.confirmArchiveFile} size='small' color='secondary'>Archive</Button>
              </ExpansionPanelActions>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

StudyMaterials.propTypes = {
  classes: PropTypes.object,
}

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
}
const modalStyle2 = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}

const StudyMaterialsStyled = withStyles(styles)(StudyMaterials)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    active_study_materials: state.users.active_study_materials,
    inactive_study_materials: state.users.inactive_study_materials,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    addStudyMaterials: params => dispatch(addStudyMaterials(params)),
    getActiveStudyMaterials: params => dispatch(getActiveStudyMaterials(params)),
    getInActiveStudyMaterials: params => dispatch(getInActiveStudyMaterials(params)),
    updateStudyMaterials: params => dispatch(updateStudyMaterials(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudyMaterialsStyled)

