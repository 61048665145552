import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  USER_PLDP_NOTIFICATIONS,
  USER_NOTIFICATIONS,
  SEEN_NOTIFICATIONS,
  USER_GROUP_NOTIFICATIONS
} from "../../store/actions/types";
import { fetchUserData } from "../../store/actions/userActions";
import { PHOTO_PROFILE } from "../../utils/photo_utils";
import { DATE_FORMAT } from "../../utils/date_format_utils";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  followButton: {
    backgroundColor: "#fff",
    border: "1px solid #810029",
    color: "#810029",
    fontSize: "8pt !important",
    borderRadius: "17px",
    fontWeight: "500",
    margin: "5px 0",
    padding: "0 15px"
  },
  followingButton: {
    backgroundColor: "#1298f1",
    border: "1px solid #1298f1",
    color: "#ffffff",
    fontSize: "8pt !important",
    borderRadius: "17px",
    fontWeight: "500",
    margin: "5px 0",
    padding: "0 15px"
  },
  icon: {
    padding: "5px",
    fontSize: "30px"
  },
  heading: {
    display: "flex",
    flex: 1,
    textAlign: "center",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  colorBlue: {
    color: "#1298f1"
  },
  paperStyle: {
    margin: "8px 8px"
  }
});

class NotificationsList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      minData: 30
    };
  }

  componentDidMount = () => {
    console.log('greenfilteredPosts 6 : ');
    this.fetchData();
  };

  fetchMoreData = () => {
    let setData = this.state.minData + 20;
    this.setState({
      minData: setData
    }, function() {
      this.fetchData();
    });
  };

  fetchData = () => {
    var params = {
      type: USER_NOTIFICATIONS,
      companyID: this.props.userdata.companyID,
      userID: this.props.userdata.userID,
      minData: this.state.minData
    };

    var params1 = {
      type: USER_PLDP_NOTIFICATIONS,
      companyID: this.props.userdata.companyID,
      userID: this.props.userdata.userID,
      minData: this.state.minData
    };

    var params2 = {
      type: USER_GROUP_NOTIFICATIONS,
      companyID: this.props.userdata.companyID,
      userID: this.props.userdata.userID,
      minData: this.state.minData
    };

    this.props.fetchUserData(params);

    this.props.fetchUserData(params1);

    this.props.fetchUserData(params2);
  };

  _getNotifyLink(typ, notificationItemID, groupid = "") {
    var pageMatcher = {
      thought: "/filtered-thoughts/#/" + notificationItemID,
      article: "/filtered-articles/#/" + notificationItemID,
      podcast: "/filtered-podcasts/#/" + notificationItemID,
      pldpreminder: "/pldp",
      video: "/filtered-videos/#/" + notificationItemID,
      groupposts: "/filtered-group-posts/#/" + groupid + "/" + notificationItemID,
      survey: "/surveys",
    };

    return pageMatcher[typ];
  }

  handleClose = (id, type, e) => {
    console.log('greennotification : ', id);
    this.setSeenNotification(id, type);
    this.props.handleClose(e);
  };

  setSeenNotification(notificationID, notificationType) {
    var params = {
      type: SEEN_NOTIFICATIONS,
      notificationID: notificationID,
      userID: this.props.userdata.userID,
      notificationType: notificationType,
      seen: true
    };

    this.props.fetchUserData(params);
  }

  render() {
    const {
      classes,
      className,
      option,
      usernotifications,
      groupnotifications,
      userpldpnotifications
    } = this.props;

    return (
      <div>
        <Paper className={classes.paperStyle}>
          <List className={classes.root}>
            {option === "notifications" ? (
              <div>
                {usernotifications &&
                  Object.keys(usernotifications).map(id => {
                    var seenColor = usernotifications[id].seen
                      ? "#fff"
                      : "#eee";
                    return usernotifications[id].newNotificationID ? (
                      <ListItem
                        component={Link}
                        button
                        to={this._getNotifyLink(
                          usernotifications[id].notificationType,
                          usernotifications[id].notificationItemID,
                          ""
                        )}
                        alignItems="flex-start"
                        divider={true}
                        key={id}
                        onClick={this.handleClose.bind(
                          this,
                          usernotifications[id].newNotificationID,
                          "notifications"
                        )}
                        style={{ backgroundColor: `${seenColor}` }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={usernotifications[id].journalUserName}
                            src={PHOTO_PROFILE(usernotifications[id].photoURL)}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              className={classes.colorBlue}
                              component="strong"
                              color="textPrimary"
                            >
                              {usernotifications[id].journalUserName}
                            </Typography>
                          }
                          className={classes.colorBlue}
                          secondary={
                            <React.Fragment>
                              <Typography variant="body2" color="textPrimary">
                                {usernotifications[id].notificationMsg}&nbsp;
                              </Typography>
                              <Typography variant="caption" color="textPrimary">
                                {DATE_FORMAT.type(
                                  usernotifications[id].notificationDate,
                                  "NOTIFICATIONS"
                                )}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ) : null;
                  })}
                {usernotifications &&
                  Object.keys(usernotifications).length <
                  30 ? null : usernotifications &&
                    Object.keys(usernotifications).length >= 50 ? null : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px 0px 10px 0px"
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                          size="small"
                          className={classes.button}
                          onClick={this.fetchMoreData.bind(this)}
                        >
                          Load More...
                    </Button>
                      </div>
                    )}
              </div>
            ) : option === "pldpnotifications" ? (
              <div>
                {userpldpnotifications &&
                  Object.keys(userpldpnotifications).map(id => {
                    var seenColor = userpldpnotifications[id].seen
                      ? "#fff"
                      : "#eee";
                    return userpldpnotifications[id].newNotificationID ? (
                      <ListItem
                        alignItems="flex-start"
                        divider={true}
                        key={id}
                        to={this._getNotifyLink(
                          userpldpnotifications[id].notificationType,
                          userpldpnotifications[id].notificationItemID,
                          ""
                        )}
                        component={Link}
                        button
                        onClick={this.handleClose.bind(
                          this,
                          userpldpnotifications[id].newNotificationID,
                          "pldpnotifications"
                        )}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={userpldpnotifications[id].journalUserName}
                            src={PHOTO_PROFILE(
                              userpldpnotifications[id].photoURL
                            )}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              className={classes.colorBlue}
                              component="strong"
                              color="textPrimary"
                            >
                              {userpldpnotifications[id].journalUserName}
                            </Typography>
                          }
                          className={classes.colorBlue}
                          secondary={
                            <React.Fragment>
                              <Typography variant="body2" color="textPrimary">
                                {userpldpnotifications[id].notificationMsg}
                                &nbsp;
                              </Typography>
                              <Typography variant="caption" color="textPrimary">
                                {DATE_FORMAT.type(
                                  userpldpnotifications[id].notificationDate,
                                  "NOTIFICATIONS"
                                )}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ) : null;
                  })}
                {userpldpnotifications &&
                  Object.keys(userpldpnotifications).length <
                  30 ? null : userpldpnotifications &&
                    Object.keys(userpldpnotifications).length >= 50 ? null : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px 0px 10px 0px"
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                          size="small"
                          className={classes.button}
                          onClick={this.fetchMoreData.bind(this)}
                        >
                          Load More...
                    </Button>
                      </div>
                    )}
              </div>
            ) : (
                  <div>
                    {groupnotifications &&
                      Object.keys(groupnotifications).map(id => {
                        var seenColor = groupnotifications[id].seen
                          ? "#fff"
                          : "#eee";
                        return groupnotifications[id].newNotificationID ? (
                          <ListItem
                            component={Link}
                            button
                            to={this._getNotifyLink(
                              "groupposts",
                              groupnotifications[id].notificationItemID,
                              groupnotifications[id].groupid,
                            )}
                            alignItems="flex-start"
                            divider={true}
                            key={id}
                            onClick={this.handleClose.bind(
                              this,
                              groupnotifications[id].newNotificationID,
                              "groupnotifications"
                            )}
                            style={{ backgroundColor: `${seenColor}` }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt={groupnotifications[id].journalUserName}
                                src={PHOTO_PROFILE(groupnotifications[id].photoURL)}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  className={classes.colorBlue}
                                  component="strong"
                                  color="textPrimary"
                                >
                                  {groupnotifications[id].journalUserName}
                                </Typography>
                              }
                              className={classes.colorBlue}
                              secondary={
                                <React.Fragment>
                                  <Typography variant="body2" color="textPrimary">
                                    {groupnotifications[id].notificationMsg}&nbsp;
                              </Typography>
                                  <Typography variant="caption" color="textPrimary">
                                    {DATE_FORMAT.type(
                                      groupnotifications[id].notificationDate,
                                      "NOTIFICATIONS"
                                    )}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        ) : null;
                      })}
                    {groupnotifications &&
                      Object.keys(groupnotifications).length <
                      30 ? null : groupnotifications &&
                        Object.keys(groupnotifications).length >= 50 ? null : (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "10px 0px 10px 0px"
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              component="span"
                              size="small"
                              className={classes.button}
                              onClick={this.fetchMoreData.bind(this)}
                            >
                              Load More...
                    </Button>
                          </div>
                        )}
                  </div>
                )}
          </List>
        </Paper>
      </div>
    );
  }
}

NotificationsList.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  usernotifications: PropTypes.any,
  groupnotifications: PropTypes.any,
  userpldpnotifications: PropTypes.any,
  option: PropTypes.string.isRequired
};

const Notifications = withStyles(styles)(NotificationsList);

const mapStateToProps = state => {
  return {
    usernotifications: state.userdata.usernotifications,
    groupnotifications: state.userdata.groupnotifications,
    userpldpnotifications: state.userdata.userpldpnotifications,
    userdata: state.userDialog.userDetails[0],
    useranalytics: state.userdata.useranalytics
  };
};

export default connect(
  mapStateToProps, { fetchUserData }
)(Notifications);