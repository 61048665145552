import {
  INTERNAL_ARTICLES,
  EXTERNAL_ARTICLES,
  MY_ARTICLES,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_ERROR,
  PLDP_ARTICLES
} from "../actions/types";

const initState = {
  articles: {},
  pldparticles: {},
  externalarticles: {},
  article: {},
  CREATE_ARTICLE: ""
};

const articlesReducer = (state = initState, action) => {
  switch (action.type) {
    case INTERNAL_ARTICLES:
      return {
        ...state,
        articles: action.articles,
        type: INTERNAL_ARTICLES,
        CREATE_ARTICLE: ""
      };
    case EXTERNAL_ARTICLES:
      return {
        ...state,
        externalarticles: action.externalarticles,
        type: EXTERNAL_ARTICLES,
        CREATE_ARTICLE: ""
      };
    case MY_ARTICLES:
      return {
        ...state,
        myarticles: action.myarticles,
        type: MY_ARTICLES,
        CREATE_ARTICLE: ""
      };
    case PLDP_ARTICLES:
      return {
        ...state,
        pldparticles: action.pldparticles,
        type: PLDP_ARTICLES,
        CREATE_ARTICLE: ""
      };
    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        CREATE_ARTICLE: CREATE_ARTICLE_SUCCESS
      };
    case "CLEAR_ARTICLE_SUCCESS":
      return {
        ...state,
        CREATE_ARTICLE: ""
      };
    default:
      return state;
  }
};

export default articlesReducer;
