import { FRESH, FRESH_ADD, FRESH_POINTS, FRESH_VIDEOS, TEST_FINAL, GET_POE } from './../actions/types';

const initialState = {
  realpoints: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'FRESH':
			return {
				...state,
                type: "FRESH",
                name: action.name,
                lastname: action.lastname,
                points: action.points,
                club: action.club
			};
            case 'FRESH_ADD':
                return {
                    ...state,
                    type: "FRESH_ADD",
                    msg: 'points added'
                };
            case 'TEST_FINAL':
                return {
                    ...state,
                    type: "TEST_FINAL",
                    msg: 'points added'
                };
            case 'FRESH_POINTS':
                return {
                    ...state,
                    type: "FRESH_POINTS",
                    msg: 'points added'
                };
            case 'FRESH_VIDEOS':
                return {
                    ...state,
                    type: "FRESH_VIDEOS",
                    msg: 'points added'
                };
            // case 'GET_POINTS':
            //     console.log('freshcardreducer get_points : ', action.realpoints)
            //     return {
            //         ...state,
            //         type: "GET_POINTS",
            //         realpoints: action.realpoints
            //     };  
            // case 'CLEAR_POINTS':
            //     return {
            //         ...state,
            //         type: "CLEAR_POINTS",
            //         realpoints: 0
            //     };                             
		  default:
			return state;
	}
};
