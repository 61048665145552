import React from "react";
import Modal from "react-awesome-modal";
import Sharebuttons from "./socialapp";
import ShareIcon from "@material-ui/icons/Share";
import Sharelogo from '@material-ui/icons/MailRounded' 
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button';
import { GREY, RED, LIGHTBLUE, LIGHTGREY, MAROONHOVER } from "../../utils/color_utils";
import MetaTags from 'react-meta-tags';

const buttonStyle = {
  backgroundColor: RED,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const hoverbuttonStyle = {
  backgroundColor: MAROONHOVER,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};


class LoadSocialNetworks extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      visible: false,
      buttonhover: buttonStyle
    };
  }

  openModal = () => {
    this.setState(prevState => ({
      visible: !this.state.visible
    }));
  }

  closeModal = () => {
    this.setState({
      visible: false
    });
  }

  hoverClose = (prop) => {
    if (prop === false) {
      this.setState({ buttonhover: buttonStyle });
    }
    else {
      this.setState({ buttonhover: hoverbuttonStyle });
    }
  }

  render() {
    const { exitModal, classes } = this.props;

    return (
      <React.Fragment>
        <div 
          style={{ 
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
            }}
            value="Open" onClick={() => this.openModal()}>
          <ShareIcon />
        </div>
        <Modal
          key="close"
          visible={this.state.visible}
          border="10px"
          borderRadius="4px"
          effect="fadeInDown"
          padding="10rem"
          position= "fixed"
          display= "block" 
          paddingRight="20px"
          minWidth="200px" 
          maxWidth="500px" 
          onClickAway={() => this.closeModal()}
        >
          <div style={{ padding: '20px' }}> Share </div>
          <div>
            <Sharebuttons
              post={this.props.post}
              imageURL={this.props.imageURL}
              propstosend={this.props}
              userdata={this.props.userdata}
              source={this.props.source}
              thought={this.props.thought}
              postuserId={this.props.postuserId}
              postuserName={this.props.postuserName}
              quotemaster={this.props.quotemaster}
              location={this.props.location}
              closeModal={this.closeModal}
              imageprop={this.props.imageprop}
              filesprop={this.props.filesprop}

              podcasturl={this.props.podcasturl}
              podcastname={this.props.podcastname}
              videoname={this.props.videoname} 
              videourl={this.props.videourl}
              filetitle={this.props.filetitle}
              fileurl={this.props.fileurl}
              articlesurl={this.props.articlesurl}
              articletitle={this.props.articletitle}

              logoprop={this.props.logoprop}
              articleLink={this.props.articleLink}
              slogan={this.props.slogan}
              website={this.props.website}
              textcolor={this.props.textcolor}
            />
          </div>
          <div style={{ padding: '20px' }}>
            <a href="javascript:void(0);" onClick={() => this.closeModal()} style={{ textDecoration: 'none' }}>
              <Button style={this.state.buttonhover} onMouseOver={() => this.hoverClose(true)} onMouseLeave={() => this.hoverClose(false)}>
                Close
                </Button>

              {/* <Button style={buttonStyle}>Close</Button> */}
            </a>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default LoadSocialNetworks;
