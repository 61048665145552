import React from "react";
import ReactDOM from "react-dom";
import "./Index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from 'react-router-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import "./Index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Survey from "./Survey";
import { Container, Row, Col } from 'react-grid-system';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Grid,
  FormHelperText,
  Button
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Hidden } from '@material-ui/core';
import DialogActions from "@material-ui/core/DialogActions";
import { GREY, RED, LIGHTBLUE, LIGHTGREY } from "../../utils/color_utils";
import SendIcon from "@material-ui/icons/Send";
import { showToast } from "../../store/actions/toastAction";
//import { updateSurvey } from "../../store/actions/surveyUpdate";
import firebase from "../../store/firebase";
import { connect } from "react-redux";
import {
  // SURVEY_LIST,
  UPDATE_SURVEY
  // SURVEY_STATS_LIST,
  // SIMPLE_VALUES,
  // SURVEY_TESTS_LIST
} from "../../store/actions/types";
import { array } from "prop-types";
import './sidebar.css'; 
import { blue } from "@material-ui/core/colors";


const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: "#fff"
};
const sendBtn2 = {
  backgroundColor: RED,
  color: "#fff"
};
const table = {
    color: "#515A5A",
    fontSize: 14,
    // border: "1px solid #707B7C",
    border: "1px solid #CAC9C8",
    backgroundColor: '#F2F3F4',
    padding: 1,
    borderRadius: 7
}
const tableh = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 10,
  borderRadius: 15
}
const tableM = {
  color: "#515A5A",
  fontSize: 18,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 15
}

// @media (max-width: 768px) {
//   .box {
//       width: 50px;
//   }
// }

const styles = {
  mlist: {
    width: 250,
    // backgroundColor: 'red',
  },
  survs: {
    width: 1500,
    // backgroundColor: 'blue',
  },
  row2: {
//     backgroundColor: 'green',
  },
  col1: {
    backgroundColor: 'yellow',
  },
  col2: {
    backgroundColor: 'brown',
  },
  col: {
    padding: '1rem',
    backgroundColor: '#33b5e5',
    border: '2px solid #fff',
    color: '#fff',
    textAlign: 'center'
    }
};

class Test extends React.Component {
  constructor (props) {
     super(props);
    //  var params = {
    //   type: UPDATE_SURVEY,
    //   companyID: props.companyID,
    //   thoughtID: props.thoughtID
    //   };
    //   this.updateSurvey(params);
  }

  state = {
    surveysLoaded: this.props.update_Survey,
    showPic: true,
    groupname: "",
    groupid: "",
    defaultgroup: "Select Group",
    gr: ""
  }

  componentDidMount() {
    // var params = {
    //   type: UPDATE_SURVEY,
    //   companyID: this.props.companyID,
    //   thoughtID: this.props.thoughtID
    //   };
    //   this.props.updateSurvey(params);
  }
  
  // surveySize() {
  //    this.update_Survey && Object.keys(this.update_Survey).map((QandA, id) => {         
  //     //return this.update_Survey.size
  //     return this.update_Survey
  //   }) 

  // }

  functionTwo = (var1, callback) => {
    callback(var1);
    console.log('inside callback 2: ', this.props.update_Survey);   
   }

    objsize = (Myobj) => {
      var osize = 0, key;
      for (key in Myobj) {
          if (Myobj.hasOwnProperty(key)) osize++;
      }
      return osize;
    };

    getKeys(object) {
      function iter(o, p) {
          if (Array.isArray(o)) { return; }
          if (o && typeof o === 'object') {
              var keys = Object.keys(o);
              if (keys.length) {
                  keys.forEach(function (k) { iter(o[k], p.concat(k)); });
              }
              return;
          }
          result.push(p.join('.'));
      }
      var result = [];
      iter(object, []);
      return result;
  }

  hidePic = (cond) => {
     this.setState({showPic:true});
  }

  render(){
  // const { update_Survey } = this.props;
   console.log('Test props: ', this.props);

  return (                                                                                                  
          // <div style={styles.survs}>
          <div
          style={{
            width: '350px',
            maxWidth: '350px',
            height: '500px',

            align: "center"
            // top: '50px',
            // width: '100%',
            // maxWidth: '100%'
        }}
        >
          {/* { update_Survey && Object.keys(update_Survey).map((QandA, id) => {         
            return (
                <div>
                  this is where it is
                  <Survey 
                      //letgoPic={this.hidePic} 
                      groupid={'1'}
                      userID={this.props.userID}
                      personid={this.props.userID} 
                      key={this.props.thoughtID}
                      companyID={this.props.companyID}  
                      surveyid={this.props.thoughtID}
                      typeofSurvey={'T'} 
                      size={update_Survey.size} //{update_Survey[QandA].size}
                    />
                </div>                 
                );
              }) 
            }    */}
                  <Survey 
                      groupid={this.props.thoughtID}
                      userID={this.props.userID}
                      personid={this.props.userID} 
                      key={this.props.thoughtID}
                      showLeadb={this.props.showLeadb}
                      thoughtID={this.props.thoughtID}
                     // thought={this.props.thought}
                      companyID={this.props.companyID}  
                      real_companyID={this.props.real_companyID}
                      surveyid={this.props.thoughtID}
                      typeofSurvey={'T'} 
                      size={this.props.size}
                      surveyName={this.props.surveyName}
                      publisherid={this.props.publisherid}
                      thoughtType={this.props.thoughtType}
                      title={this.props.title}
                      subtitle={this.props.subtitle}
                    />
          </div>
  );
 }
}

// function OldSchoolMenuLink({ label, to, activeOnlyWhenExact }) {
//   var tutu="/"+to;
//   let match = useRouteMatch({
//     path: tutu,
//     exact: activeOnlyWhenExact
//   });

//   return (
//     <div className={match ? "active" : ""}>
//       {match && ">> "}
//       <Link to={tutu}>{label}</Link>
//     </div>
//   );
// }

function handleChange() {
  console.log('in select function:');
}
function swapStuff(str) {
  var c, cc;
    c = str.replace(/\./g, (i => _ => ' ?'[i = 1 - i])(1));
    cc = c.replace(/\,/g, (i => _ => ' ?'[i = 1 - i])(1));
    cc = cc.replace(/\’/g, (i => _ => '?'[i = 1 - i])(1));
    cc = cc.replace(/\?/g, (i => _ => ' ?'[i = 1 - i])(1));
  return cc;
 }


const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    survey_list: state.surveyList.survey_list,
    //update_Survey: state.updateSurvey.update_Survey,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     updateSurvey: params => dispatch(updateSurvey(params))
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Test);
export default connect(mapStateToProps)(Test);


