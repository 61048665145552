import React, { useState, useEffect } from 'react'
import Chart from "react-google-charts";

const data = [
  ['Gender', 'Male', 'Female'],
  ['All Users', 48, 30],
  ['Group Participants', 24, 8],
  ['Messages', 76, 97],
  ['Articles', 25, 20],
  ['Podcasts', 12, 12],
  ['Videos', 7, 10],
  ['PLDP', 30, 20],
]

const data_types = ["Messages", "Articles", "Podcasts", "Videos", "PLDP"];
const data_types_obj = {
  thoughts: "Messages",
  articles: "Articles",
  podcasts: "Podcasts",
  videos: "Videos",
  pldp: "PLDP"
};

function PostsByGenderChart (props) {

  const [data_rows, setDataRows] = useState([]);

  function handleStatusChange(prop_data) {
    let graph_d = [];
    graph_d.push(['Gender', 'Male', 'Female', 'Not Specified'])

    let gender = prop_data && prop_data.analytics && prop_data.analytics.gender ? prop_data.analytics.gender : {};

    console.log("analytics here 2: ", gender);

    let male = gender && gender.Male ? gender.Male : {};
    let female = gender && gender.Female ? gender.Female : {};
    let not_specified = gender && gender.not_specified ? gender.not_specified : {};

    data_types.map((v, i) => {
      let male_no = 0;
      let female_no = 0;
      let not_specified_no = 0;

      if (v === "Messages") {
        male_no = male && male.thoughts ? male.thoughts : 0;
        female_no = female && female.thoughts ? female.thoughts : 0;
        not_specified_no = not_specified && not_specified.thoughts ? not_specified.thoughts : 0;
        graph_d.push([v, male_no, female_no, not_specified_no]);
      }
      else if (v === "Articles") {
        male_no = male && male.articles ? male.articles : 0;
        female_no = female && female.articles ? female.articles : 0;
        not_specified_no = not_specified && not_specified.articles ? not_specified.articles : 0;
        graph_d.push([v, male_no, female_no, not_specified_no]);
      }
      else if (v === "Podcasts") {
        male_no = male && male.podcasts ? male.podcasts : 0;
        female_no = female && female.podcasts ? female.podcasts : 0;
        not_specified_no = not_specified && not_specified.podcasts ? not_specified.podcasts : 0;
        graph_d.push([v, male_no, female_no, not_specified_no]);
      }
      else if (v === "Videos") {
        male_no = male && male.videos ? male.videos : 0;
        female_no = female && female.videos ? female.videos : 0;
        not_specified_no = not_specified && not_specified.videos ? not_specified.videos : 0;
        graph_d.push([v, male_no, female_no, not_specified_no]);
      }
      else if (v === "PLDP") {
        male_no = male && male.pldp ? male.pldp : 0;
        female_no = female && female.pldp ? female.pldp : 0;
        not_specified_no = not_specified && not_specified.pldp ? not_specified.pldp : 0;
        graph_d.push([v, male_no, female_no, not_specified_no]);
      }

    })

    setDataRows(graph_d);
  }

  useEffect(() => {
    handleStatusChange(props);
  }, [props]);
  
  return <>
    <Chart
      width={'100%'}
      height={'300px'}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={data_rows}
      options={{
        // Material design options
        chart: {
          title: 'Posts Distribution',
        },
      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  </>
}

export default PostsByGenderChart