import React, { useState, useEffect } from 'react'
import Chart from "react-google-charts";

let headers = ['Month', 'Messages', 'Videos', 'Articles', 'Podcasts', 'PLDP'];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const data = [
  ['Month', 'Messages', 'Videos', 'Articles', 'Podcasts'],
  ['Jan', 1000, 200, 1170, 460],
  ['Feb', 1170, 250, 1030, 540],
  ['Mar', 660, 400, 460, 1170],
  ['Apr', 1030, 1000, 350, 500],
  ['May', 1000, 1170, 200, 460],
  ['Jun', 1170, 250, 1030, 800],
  ['Jul', 660, 300, 1170, 460],
  ['Aug', 300, 900, 1000, 1120],
  ['Sep', 400, 1200, 1170, 460],
  ['Oct', 1170, 460, 700, 1030],
  ['Nov', 660, 1120, 1170, 460],
  ['Dec', null, null, null, null],
]

const TopMultiLineCharts = (props) => {
  const [combined_data, setCombinedData] = useState([]);
  const incoming_data = props.analytics;

  function handleStatusChange(analytics) {
    let analytics_data = [];
    analytics_data.push(headers);
    console.log("analytics here data", analytics)
    months.map((v, i) => {
      let month_data = [];
      let thoughts = null, articles = null, videos = null, podcasts = null, pldp = null


      if (analytics && analytics[v]) {
        month_data.push("" + v);

        thoughts = analytics && analytics[v] && analytics[v]["thoughts"] ? analytics[v]["thoughts"] : 0;
        videos = analytics && analytics[v] && analytics[v]["videos"] ? analytics[v]["videos"] : 0;
        articles = analytics && analytics[v] && analytics[v]["articles"] ? analytics[v]["articles"] : 0;
        podcasts = analytics && analytics[v] && analytics[v]["podcasts"] ? analytics[v]["podcasts"] : 0;
        pldp = analytics && analytics[v] && analytics[v]["pldp"] ? analytics[v]["pldp"] : 0;

        if (thoughts === undefined) {
          thoughts = 0;
        }
        if (videos === undefined) {
          videos = 0;
        }
        if (articles === undefined) {
          articles = 0;
        }
        if (podcasts === undefined) {
          podcasts = 0;
        }
        if (pldp === undefined) {
          pldp = 0;
        }

        month_data.push(thoughts);
        month_data.push(videos);
        month_data.push(articles);
        month_data.push(podcasts);
        month_data.push(pldp);

        analytics_data.push(month_data);
      }


      // console.log("analytics here", v)
      // console.log("analytics here", i)

    });

    setCombinedData(analytics_data);
  }

  useEffect(() => {
    // console.log("analytics here 1")
    handleStatusChange(props.analytics);
  }, [props.analytics]);

  // let analytics = props.analytics;
  // console.log("analytics here", analytics)
  // console.log("analytics here", combined_data)

  return <>
    <Chart
      width={'100%'}
      height={'300px'}
      chartType="Line"
      loader={<div>Loading Chart</div>}
      data={combined_data}
      options={{
        // Material design options
        chart: {
          title: 'Posts Distribution',
        },
      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  </>
}

export default TopMultiLineCharts