/* eslint-disable no-inline-comments */
'use strict'
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button
} from '@material-ui/core'
import { LIGHTBLUE, DARK } from '../../utils/color_utils'
import { withStyles } from '@material-ui/core/styles'
import ImpressionsList from './elements/ImpressionsList'
import StatsCard from './elements/StatsCard'
import TopMultiLineCharts from './elements/TopMultiLineCharts'
import PopularTopicsCharts from './elements/PopularTopicsCharts'
import PopularTopicsTable from './elements/PopularTopicsTable'
import MostVisitedPagesTable from './elements/MostVisitedPagesTable'
import MostVisitedPagesPie from './elements/MostVisitedPagesPie'
import PostsByGenderTable from './elements/PostsByGenderTable'
import PostsByGenderChart from './elements/PostsByGenderChart'
import PostsByOperationTable from './elements/PostsByOperationTable'
import PostsByDepartmentTable from './elements/PostsByDepartmentTable'
import { fetchAnalytics } from '../../store/actions/analyticsActions'
import { getCompanyValues } from '../../store/actions/pldpActions'
import {
  GET_COMPANY_ANALYTICS,
  GET_ACTIVE_COMPANY_USERS,
  GET_COMPANY_COUNTS,
  GET_ACTIVE_COMPANIES
} from '../../store/actions/types'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import { PHOTO_PROFILE } from '../../utils/photo_utils'

// require dependencies
// const PDFDocument = require("pdfkit");
// const blobStream = require("blob-stream");

window.moment = moment
const styles = theme => ({
  root: {
    marginTop: 0,
    // padding: "10px",
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '110px'
    }
  },
  listRoot: {
    marginTop: 10,
    padding: '10px 0px',
    flexGrow: 1
  },
  smallText: {
    fontSize: '10px!important'
  },
  colorBlue: {
    color: `${LIGHTBLUE}`,
    fontWeight: 'bold'
  },
  colorDark: {
    color: `${DARK}`,
    fontWeight: 'bold'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  paperContainer: {
    // padding: "10px",
    marginTop: 0
  },
  paperContainerList: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  grow: {
    flexGrow: 1,
    marginTop: 8,
    padding: '0 10px',
    backgroundColor: '#ffffff'
  },
  growNoBackground: {
    flexGrow: 1,
    marginTop: 8,
    padding: '0 10px'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  colorPrimary: {
    color: '#ffffff',
    backgroundColor: '#d51317'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 160,
    // maxWidth: 400,
    width: '100%!important'
  },
  alignLeft: {
    textAlign: 'left!important',
    padding: '0 8px'
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  textCount: {
    fontSize: 14
  },
  usertitle: {
    color: LIGHTBLUE
  },
  paper: {
    backgroundColor: '#ffffff !important',
    borderRadius: '4px',
    padding: '0 8px 0 0',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginBottom: theme.spacing.unit * 3
  },
  paperNoElevation: {
    backgroundColor: '#ffffff !important',
    borderRadius: '4px',
    padding: '12px 8px',
    marginBottom: theme.spacing.unit * 3
  }
})

class OverviewAnalytics extends Component {
  state = {
    month: 'All',
    year: moment().format('YYYY'),
    activeUsersPeriod: moment().format('YYYY'),
    currentPeriod: moment().format('YYYY'),
    useractive: 'year-active-users',
    selectedCompany: {},
    company: ''
  };

  componentDidMount() {
    if (this.props.userdata) {
      this.setState({
        company: this.props.userdata.companyID
      })
    }

    this.fetchAnalyticsdata(
      this.props.userdata.companyID,
      moment().format('YYYY'),
      'year-active-users'
    )
  }

  fetchAnalyticsdata = (companyid, currentperiod, useractive) => {
    // overall analytics data
    let params = {
      type: GET_COMPANY_ANALYTICS,
      ref: `/company-analytics/${companyid}/${currentperiod}`
    }

    this.props.fetchAnalytics(params)
    // active users data
    let params_users = {
      type: GET_ACTIVE_COMPANY_USERS,
      ref: `/company-analytics/${companyid}/${useractive}/${currentperiod}`
    }

    this.props.fetchAnalytics(params_users)
    // company counts data
    let params_counts = {
      type: GET_COMPANY_COUNTS,
      ref: `/company-analytics/${companyid}/counts`
    }

    this.props.fetchAnalytics(params_counts)

    // fetch active companies
    let params_active = {
      type: GET_ACTIVE_COMPANIES,
      ref: `/active-companies`
    }
    this.props.fetchAnalytics(params_active)

    // fetch company values
    this.props.getCompanyValues(this.props.userdata.companyID)
  };

  onCompanyChange = event => {
    this.setState({ [event.target.name]: event.target.value, month: 'All' })
    this.fetchAnalyticsdata(
      event.target.value,
      this.state.year,
      'year-active-users'
    )
  };

  handleMonthChange = event => {
    if (event.target.value == 'All') {
      this.setState({
        [event.target.name]: event.target.value,
        activeUsersPeriod: this.state.year,
        currPeriod: this.state.year,
        useractive: 'year-active-users'
      })

      var useractive = 'year-active-users'
      var period = this.state.year
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        activeUsersPeriod: this.state.year + '-' + event.target.value,
        currPeriod: this.state.year + '-' + event.target.value,
        useractive: 'month-active-users'
      })

      var useractive = 'month-active-users'
      var period = this.state.year + '-' + event.target.value
    }

    this.fetchAnalyticsdata(this.state.company, period, useractive)
  };
  handleYearChange = event => {
    if (this.state.month == 'All') {
      this.setState({
        [event.target.name]: event.target.value,
        activeUsersPeriod: event.target.value,
        currPeriod: event.target.value,
        useractive: 'year-active-users'
      })

      var useractive = 'year-active-users'
      var period = event.target.value
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        activeUsersPeriod: event.target.value + '-' + this.state.month,
        currPeriod: event.target.value + '-' + this.state.month,
        useractive: 'month-active-users'
      })

      var useractive = 'month-active-users'
      var period = event.target.value + '-' + this.state.month
    }

    this.fetchAnalyticsdata(this.state.company, period, useractive)
  };

  renderStats(analytics) {
    console.log('[analytics: ]', analytics)
    const stats = [
      {
        count: analytics ? analytics.thoughts || 0 : 0
      },
      {
        count: analytics ? analytics.videos || 0 : 0
      },
      {
        count: analytics ? analytics.articles || 0 : 0
      },
      {
        count: analytics ? analytics.podcasts || 0 : 0
      },
      {
        count: analytics ? analytics.pldp || 0 : 0
      }
      /* {
        count: analytics ? analytics.clicks || 0 : 0
      } */
    ]
    return stats.map((stat, index) => (
      <StatsCard key={index} index={index} stat={stat} {...this.props} />
    ))
  }

  renderCompanies = () => {
    const { activeCompanies } = this.props
    console.log('renderCompanies : ', activeCompanies);

    return Object.values(activeCompanies).map((v, i) => (
      <MenuItem id={i} value={v.companyID}>
        {v.companyName}
      </MenuItem>
    ))
  };

  renderMonths = () => {
    const months = [
      'All',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    return months.map((v, i) => (
      <MenuItem key={i} value={v}>
        {v}
      </MenuItem>
    ))
  };


  render() {
    console.log('[activeusersanalytics]: ', this.props.activeusersanalytics)
    const {
      classes,
      analytics,
      activeCompanies,
      activeusersanalytics,
      companyValues
    } = this.props
    console.log('analytics x: ', this.props.userdata );

    return (
      <div className={classes.root}>
        <Grid container className={classes.paper}>
          <Grid item xs={12} md={4} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              {this.props.userdata.analytics ? (
                <React.Fragment>
                  <InputLabel htmlFor='company-input'>
                    Select Company
                  </InputLabel>
                  <Select
                    value={this.state.company}
                    onChange={this.onCompanyChange}
                    inputProps={{
                      name: 'company',
                      id: 'company'
                    }}
                  >
                   {this.renderCompanies()}
                  </Select>
                </React.Fragment>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='month'>Month</InputLabel>
              <Select
                value={this.state.month}
                onChange={this.handleMonthChange}
                inputProps={{
                  name: 'month',
                  id: 'month'
                }}
              >
                {this.renderMonths()}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='year'>Year</InputLabel>
              <Select
                value={this.state.year}
                onChange={this.handleYearChange}
                inputProps={{
                  name: 'year',
                  id: 'year'
                }}
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2018}>2018</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant='h6' color='inherit' className={classes.grow} />
        <Grid container className={classes.paper}>
          <Grid item xs={12} md={12} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <TopMultiLineCharts analytics={analytics} />
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant='h6' color='inherit' className={classes.grow}>
          Posts Summary
        </Typography>
        {
          analytics ? (
            <Grid container justify='space-between' className={classes.paperNoElevation}>
              {this.renderStats(analytics)}
            </Grid>
          ) : (
            <center>
              <CircularProgress style={{ color: '#b30000' }} mode='indeterminate' size={100} />
              {/* <CircularProgress className={classes.progress} color="secondary" /> */}
            </center>
          )
        }
        <Typography variant='h6' color='inherit' className={classes.grow}>
          Popular Topics
        </Typography>
        <Grid container className={classes.paper}>
          <Grid item xs={12} md={12} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <PopularTopicsCharts companyValues={companyValues} analytics={analytics} />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <PopularTopicsTable companyValues={companyValues} analytics={analytics} />
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant='h6' color='inherit' className={classes.grow}>
          Most Visited Pages
        </Typography>
        <Grid container className={classes.paper}>
          <Grid item xs={12} md={12} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <MostVisitedPagesTable analytics={analytics} />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} md={6} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <MostVisitedPagesPie analytics={analytics} />
            </FormControl>
          </Grid> */}
        </Grid>
        <Typography variant='h5' color='inherit' className={classes.growNoBackground}>
          Demographics
        </Typography>
        <Typography variant='h6' color='inherit' className={classes.grow}>
          Analysis By Gender
        </Typography>
        <Grid container className={classes.paper}>
          <Grid item xs={12} md={6} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <PostsByGenderTable companyValues={companyValues} analytics={analytics} />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <PostsByGenderChart companyValues={companyValues} analytics={analytics} />
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant='h6' color='inherit' className={classes.grow}>
          Analysis By Operations
        </Typography>
        <Grid container className={classes.paper}>
          <Grid item xs={12} md={12} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <PostsByOperationTable companyValues={companyValues} analytics={analytics} />
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant='h6' color='inherit' className={classes.grow}>
          Analysis By Department
        </Typography>
        <Grid container className={classes.paper}>
          <Grid item xs={12} md={12} className={classes.alignLeft}>
            <FormControl className={classes.formControl}>
              <PostsByDepartmentTable companyValues={companyValues} analytics={analytics} />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Typography variant='h5' color='inherit' className={classes.grow}>
          User Engagement
        </Typography>
        <Grid container spacing={16} justify='space-between' className={classes.paper}>
          <Grid item xs={12} md={6}>
            <ImpressionsList heading='Impressions' {...this.props} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.paper}>
            <Typography variant='h6' color='inherit'>
              Top 10 Active Members
            </Typography>
            {activeusersanalytics &&
              Object.keys(activeusersanalytics).length > 0 ? (
                <React.Fragment>
                  <Paper className={classes.paperContainerList}>
                    <List className={classes.listRoot}>
                      {activeusersanalytics &&
                        Object.keys(activeusersanalytics).map((v, id) => {
                          return (
                            <React.Fragment>
                              <ListItem
                                alignItems='flex-start'
                                divider={true}
                                key={id}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    alt={
                                      activeusersanalytics[v].data.journalUserName
                                    }
                                    src={PHOTO_PROFILE(
                                      activeusersanalytics[v].data.photoURL
                                    )}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography
                                      className={classes.colorBlue}
                                      component='strong'
                                      color='textPrimary'
                                      variant='body2'
                                    >
                                      {
                                        activeusersanalytics[v].data
                                          .journalUserName
                                      }
                                    </Typography>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <Grid container spacing={16}>
                                        <Grid item xs={2}>
                                          <Typography
                                            variant='button'
                                            color='inherit'
                                            className={classes.colorDark}
                                          >
                                            <center>
                                              <strong>
                                                {activeusersanalytics[v]
                                                  .thoughts || '-'}
                                              </strong>
                                            </center>
                                          </Typography>
                                          <Typography
                                            variant='caption'
                                            color='inherit'
                                            className={classes.smallText}
                                          >
                                            <center>Messages</center>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Typography
                                            variant='button'
                                            color='inherit'
                                            className={classes.colorDark}
                                          >
                                            <center>
                                              <strong>
                                                {activeusersanalytics[v]
                                                  .articles || '-'}
                                              </strong>
                                            </center>
                                          </Typography>
                                          <Typography
                                            variant='caption'
                                            color='inherit'
                                            className={classes.smallText}
                                          >
                                            <center>Articles</center>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Typography
                                            variant='button'
                                            color='inherit'
                                            className={classes.colorDark}
                                          >
                                            <center>
                                              <strong>
                                                {activeusersanalytics[v].videos ||
                                                  '-'}
                                              </strong>
                                            </center>
                                          </Typography>
                                          <Typography
                                            variant='caption'
                                            color='inherit'
                                            className={classes.smallText}
                                          >
                                            <center>Videos</center>
                                          </Typography>
                                        </Grid>

                                        <Grid item xs={2}>
                                          <Typography
                                            variant='button'
                                            color='inherit'
                                            className={classes.colorDark}
                                          >
                                            <center>
                                              <strong>
                                                {activeusersanalytics[v]
                                                  .podcasts || '-'}
                                              </strong>
                                            </center>
                                          </Typography>
                                          <Typography
                                            variant='caption'
                                            color='inherit'
                                            className={classes.smallText}
                                          >
                                            <center>Podcasts</center>
                                          </Typography>
                                        </Grid>

                                        <Grid item xs={2}>
                                          <Typography
                                            variant='button'
                                            color='inherit'
                                            className={classes.colorDark}
                                          >
                                            <center>
                                              <strong>
                                                {activeusersanalytics[v]
                                                  .clicks || '-'}
                                              </strong>
                                            </center>
                                          </Typography>
                                          <Typography
                                            variant='caption'
                                            color='inherit'
                                            className={classes.smallText}
                                          >
                                            <center>Interactions</center>
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            </React.Fragment>
                          )
                        })}
                    </List>
                  </Paper>
                </React.Fragment>
              ) : (
                <center>
                  {Object.keys(activeusersanalytics).length === 0 ? null : (
                    // <CircularProgress mode="indeterminate" size={50} color="#4ddbff"/>
                    <CircularProgress className={classes.progress} color='secondary' />
                  )}
                </center>
              )}
          </Grid>
        </Grid>
      </div >
    )
  }
}

OverviewAnalytics.propTypes = {
  analytics: PropTypes.object.isRequired,
  activeCompanies: PropTypes.object.isRequired,
  fetchAnalytics: PropTypes.func.isRequired,
  userdata: PropTypes.object.isRequired
}

const sendBtn = {
  backgroundColor: `${LIGHTBLUE}`,
  color: '#fff'
}

const mapStateToProps = (state, ownProps) => {
  return {
    analytics: state.analytics.analytics,
    activeCompanies: state.analytics.activeCompanies,
    companycountsanalytics: state.analytics.companycountsanalytics,
    activeusersanalytics: state.analytics.activeusersanalytics,
    userdata: state.userDialog.userDetails[0],
    companyValues: state.pldp.companyValues
  }
}
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchAnalytics, getCompanyValues
    },
    dispatch
  )
})

const AnalyticsPage = withStyles(styles)(OverviewAnalytics)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsPage)
