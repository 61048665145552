/* eslint-disable no-inline-comments */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import ForumIcon from '@material-ui/icons/CommentOutlined'
import Badge from '@material-ui/core/Badge'
import Liking from './../../components/Buttons/Liking'
import CardMessage from './ArticleCardsMessage'
import CloseIcon from '@material-ui/icons/Close'
import { DATE_FORMAT } from '../../utils/date_format_utils'
import { PHOTO_PROFILE, PODCAST_IMG } from '../../utils/photo_utils'
import { LIGHTBLUE, LIGHTGREY, HOVERRED, RED } from '../../utils/color_utils'
import Comments from '../Comments'
import AddCommentField from '../Comments/AddCommentField'
import CommentDialogCard from './../Cards/CommentDialogCard'
import Follow from '../Buttons/Follow'
import { Grid } from '@material-ui/core'
import PLDPFavorites from './../Buttons/PLDPFavorites'
import {
  fetchUserBiography,
  openUserProfileDialog
} from '../../store/actions/userBiographyActions'
import {
  captureUserActivity
} from '../../store/actions/analyticsActions'
import MoreMenu from './MoreMenu'
import RemoveFavorite from './RemoveFavorite'
import LoadSocialNetworks from '../../components/socialnetworks/socialappmodal'
import { APP_TYPE, USER_CLICKS_POST, IS_HARMONY } from '../../store/actions/types'

const styles = theme => ({
  card: {
    maxWidth: 600,
    position: 'relative'
  },
  actions: {
    display: 'flex',
    padding: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  sharediv: {
    color: '#fff',
    float: 'right!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e1e8f7',
    borderRadius: '10%',
    padding: '0px 10px',
    marginRight: '0px'
  },
  actionsComments: {
    boxSizing: 'border-box',
    alignItems: 'center',
    height: 0,
    maxHeight: 0,
    overflow: 'hidden',
    position: 'relative',
    display: 'none'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: theme.palette.grey[500]
  },
  badgemargin: {
    position: 'absolute',
    left: '-70px',
    width: 'max-content',
    top: '10px',
    right: '76%',
    backgroundColor: '#f4eeee',
    color: '#676767',
    padding: '8px',
    fontSize: '8pt',
    border: `2px solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`
  },
  badge: {
    width: 'max-content',
    color: '#fff',
    padding: '8px',
    fontSize: '8pt',
    border: `2px solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`,
    right: '-28px',
    backgroundColor: LIGHTBLUE
  },
  commentsbadge: {
    width: 'max-content',
    color: '#fff',
    padding: '8px',
    fontSize: '8pt',
    border: `2px solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`,
    right: '-13px',
    backgroundColor: LIGHTBLUE
  },
  cardstyle: {
    flex: '1',
    position: 'relative',
    margin: '10px',
    minWidth: '320px',
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 4px',
      minWidth: '300px'
    }
  },
  tableactions: {
    minWidth: '-webkit-fill-available'
  },
  favoritesrow: {
    backgroundColor: LIGHTGREY,
    flexDirection: 'row',
    width: '-webkit-fill-available',
    padding: '8px 16px'
  },
  favoritesdiv: {
    color: '#fff',
    float: 'left!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  commentsdiv: {
    color: '#fff',
    float: 'right!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e1e8f7',
    borderRadius: '10%',
    padding: '0px 10px',
    marginRight: '20px'
  },
  comments: {
    display: 'block',
    width: '100%'
  },
  commenter: {
    marginRight: 10
  },
  commentTime: {
    fontSize: 12,
    color: 'rgba(0,0,0,.6)'
  },
  replyToComment: {
    color: LIGHTBLUE,
    transition: '.5s all ease-in',
    transform: 'rotate(-45deg)'
  },
  mask: {
    position: 'absolute',
    height: 150,
    right: 0,
    cursor: 'pointer',
    bottom: 0,
    left: 0,
    zIndex: 9,
    background: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.67), rgba(255, 255, 255, 1))'
  },
  maskText: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    padding: 5,
    textAlign: 'center',
    cursor: 'pointer',
    color: LIGHTBLUE
  },
  nested: {
    marginLeft: 40,
    backgroundColor: LIGHTGREY
  },

  pldpdiv: {
    color: '#fff',
    float: 'right!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10%',
    padding: '0px 10px',
    marginRight: '20px'
  },
  podcastimg: {
    width: '60px',
    height: '60px',
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  close: {
    'position': 'absolute',
    'top': '0px',
    'right': '0px',
    'zIndex': '999999',
    'color': '#fff',
    'backgroundColor': `${RED}`,
    'borderRadius': '0%',
    'padding': '8px',
    '&:hover': {
      background: `${HOVERRED}`
    }
  },
  thoughtDate: {
    padding: '5px 0px',
    color: '#676767',
    fontSize: '8pt',
    textAlign: 'center',
    position: 'relative',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px #9a9a9a8a',
    backgroundColor: '#f4eeee',
    left: '10px',
    top: '10px',
    marginTop: '-14px',
    width: '150px',
    maxWidth: '150px !important',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '20px'
    }
  },
  follow: {
    top: '30px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      top: '20px'
    }
  },
  followMyPosts: {
    top: '20px',
    position: 'relative'
  },
  followBtn: {
    textAlign: '-webkit-right',
    textAlign: 'right'
  },
  followBtnPos: {
    position: 'relative',
    minHeight: '40px',
    height: '40px!important',
    // top: "0px",
    [theme.breakpoints.down('sm')]: {
      top: '0px'
    },
    [theme.breakpoints.up('md')]: {
      top: '-10px'
    }
  }
})

class Podcasts extends React.Component {
  playMedia = e => {
    this.props.PlayMedia(this.props.podcast)
  };

  postClicked = (res) => {
    let post = this.props.podcast

    let params2 = {
      userdata: this.props.userdata,
      post: post,
      type: USER_CLICKS_POST,
      res: res,
      postTypeID: 'podcastID',
      postType: 'podcasts',
      obj: {
        itemID: post.podcastID,
        title: post.podcastID,
        ref: post.podcastID
      }
    }

    this.props.captureUserActivity({ ...params2 })
  }

  openViewUserDialog = post => {
    let params = {
      userID: post.userID,
      userName: post.userName,
      companyID: post.companyID,
      companyName: post.companyName,
      photoURL: PHOTO_PROFILE(post.photoURL),
      showprofiledlg: true
    }

    // Fetch user bio data
    this.props.fetchUserBiography({ ...params, type: 'USER_BIOGRAPHY' })

    let params2 = {
      userdata: this.props.userdata,
      post: post,
      type: USER_CLICKS_POST,
      res: 'viewedUserProfile',
      postTypeID: 'podcastID',
      postType: 'podcasts',
      obj: {
        itemID: post.podcastID,
        title: post.podcastID,
        ref: post.podcastID
      }
    }

    this.props.captureUserActivity({ ...params2 })

    // Open user profile dialog
    this.props.openUserProfileDialog(params)
  };

  render() {
    const { classes, podcast, currentUser } = this.props

    return (
      <div className={classes.cardstyle}>
        <Grid
          container
          spacing={8}
          className={
            window.location.pathname === '/myposts'
              ? classes.followMyPosts
              : classes.follow
          }
        >
          <Grid item xs={6}>
            <div className={classes.thoughtDate}>
              {DATE_FORMAT.type(podcast.dateScheduled, 'BADGE')}
            </div>
          </Grid>
          <Grid item xs={6} className={classes.followBtn}>
            <div className={classes.followBtnPos}>
              <Follow post={podcast} following={this.props.following} />
            </div>
          </Grid>
        </Grid>
        {/* <Badge
          classes={{ badge: classes.badgemargin }}
          badgeContent={DATE_FORMAT.type(podcast.dateScheduled, "BADGE")}
        />
        <Follow post={podcast} following={this.props.following} /> */}
        <RemoveFavorite item={podcast} itemType='podcasts' />
        <Card className={classes.card} style={{ padding: 0 }} onClick={() => this.postClicked('clickedPost')}>
          <CardHeader
            avatar={
              <Avatar
                aria-label='Photo'
                className={classes.avatar}
                src={PHOTO_PROFILE(podcast.photoURL)}
                onClick={() => this.openViewUserDialog(podcast)}
                style={{ cursor: 'pointer' }}
              >
                P
              </Avatar>
            }
            action={
              <MoreMenu
                post={podcast}
                posttype='podcasts'
                userdata={this.props.userdata}
              />
            }
            title={podcast.userName}
            subheader={podcast.companyName}
            style={{ backgroundColor: LIGHTGREY }}
          />
          <CardContent style={{ padding: 0 }} >
            <table className={classes.tableactions}>
              <tbody>
                <tr style={{ textAlign: 'left' }}>
                  <td style={{ whiteSpace: 'no-wrap', width: '60px' }}>
                    <img
                      className={classes.podcastimg}
                      src={PODCAST_IMG(podcast.podcastDescription)}
                      onClick={e => {
                        this.playMedia(this)
                        this.postClicked('listenedTo')
                      }}
                    />
                  </td>
                  <td>
                    <div
                      style={{ color: '#3a3a3a!important', cursor: 'pointer' }}
                      onClick={e => {
                        this.playMedia(this)
                        this.postClicked('listenedTo')
                      }}
                    >
                      <CardMessage
                        title={podcast.title}
                        subtitle={podcast.author}
                        color={'#3a3a3a!important'}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 0, textAlign: 'right' }} colSpan='2' onClick={e => {
                    this.postClicked('Open PLDP')
                  }}>
                    <PLDPFavorites
                      post={podcast}
                      postTypeID='podcastID'
                      postType='podcasts'
                      userdata={currentUser}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </CardContent>
          <CardActions className={classes.actions} disableActionSpacing>
            <div className={classes.favoritesrow}>
              <Liking
                postType='podcasts'
                postID={podcast.podcastID}
                post={podcast}
              />

              <div className={classes.sharediv} >
                { IS_HARMONY ? (null) : (

                  <div className={(APP_TYPE === 'PUBLIC') ? '' : classes.actionsComments} >
                    {/* <IconButton style={{ color: LIGHTBLUE, width: "48px", height: "48px" }}>
                <LoadSocialNetworks
                  post={
                    window.location.origin + "/filtered-podcasts/#/" +
                    podcast.podcastID
                  } />
              </IconButton> */}
                  </div>
                )}
              </div>

              <div className={classes.commentsdiv} onClick={this.props.OnExpandCommentsClick}>
                <div>
                  <IconButton style={{ color: LIGHTBLUE }} aria-label='Forum'>
                    <ForumIcon />
                  </IconButton>
                </div>
                <div>
                  <Badge
                    badgeContent={
                      podcast.comments
                        ? '' + Object.values(podcast.comments).length
                        : '0'
                    }
                    classes={{ badge: classes.commentsbadge }}
                  />
                </div>

              </div>
            </div>

          </CardActions>

          <div className={this.props.expandComments ? '' : classes.actionsComments} >
            <AddCommentField
              parentID={''}
              isSubComment={false}
              post={podcast}
              postType={'podcasts'}
              classes={classes}
            />
          </div>
          <CardActions
            className={this.props.expandComments ? '' : classes.actionsComments}
          >
            <Comments postType={'podcasts'} post={podcast} {...this.props} />
            {!this.props.expandComments ? (
              <div
                onClick={this.props.OnExpandCommentsClick}
                className={classes.mask}
              >
                <div
                  onClick={this.props.OnExpandCommentsClick}
                  className={classes.maskText}
                >
                  View All comments
                </div>
              </div>
            ) : null}
          </CardActions>
        </Card>
      </div>
    )
  }
}

class PodcastsCard extends React.Component {
  state = { podcast: {}, openDialogCard: false, expanded: false };

  handleCommentsClick = () => {
    this.setState(prevState => ({
      openDialogCard: !this.state.openDialogCard
    }))
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  };

  componentDidMount() {
   //  console.log("user: ", this.props.userdata);
  }

  render() {
    const { classes, podcast } = this.props
    console.log('props for podcasts: ', this.props);
    return (
      <React.Fragment>
        {this.state.openDialogCard ? (
          <CommentDialogCard
            open={this.state.openDialogCard}
            onOpenCommentsClick={this.handleCommentsClick}
            render={(state, handleClose) => (
              <React.Fragment>
                <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.close}
                  onClick={this.handleCommentsClick}
                >
                  <CloseIcon fontSize='small' className={classes.icon} />
                </IconButton>
                <Podcasts
                  podcast={podcast}
                  OnExpandCommentsClick={this.handleCommentsClick}
                  expandComments={true}
                  {...this.props}
                />
              </React.Fragment>
            )
            }
          />
        ) : (
          <Podcasts
            podcast={podcast}
            OnExpandCommentsClick={this.handleCommentsClick}
            expandComments={false}
            {...this.props}
          />
        )}
      </React.Fragment>
    )
  }
}

PodcastsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  podcast: PropTypes.object.isRequired,
  hideComments: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUserBiography: params => dispatch(fetchUserBiography(params)),
    openUserProfileDialog: params => dispatch(openUserProfileDialog(params)),
    captureUserActivity: params => dispatch(captureUserActivity(params))
  }
}

const PodcastsCardStyled = withStyles(styles)(PodcastsCard)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PodcastsCardStyled)
