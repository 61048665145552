import React from "react";

class Correct extends React.Component {
  render() {
    return (
      <div id="correct">
        <p>GS: {this.props.counterCorrectAnswers}</p>
      </div>
    );
  }
}

export default Correct;
