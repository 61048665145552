import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { GREY, DARK, LIGHTBLUE } from "../../utils/color_utils";
import { DATE_FORMAT } from "../../utils/date_format_utils";
import {
  getContrastYIQ,
  _getCommentsCount,
  _getLikesCount
} from "../../store/actions/commonActions";
import classNames from "classnames";
import Avatar from "@material-ui/core/Avatar";
import { PHOTO_BACKGROUND, PHOTO_PROFILE } from "../../utils/photo_utils";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ForumIcon from "@material-ui/icons/Forum";
import { DriveEtaOutlined } from "@material-ui/icons";
import { fetchUserBiography } from "../../store/actions/userBiographyActions";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  container: {
    backgroundColor: `${DARK}`,
    color: "#fff"
  },
  media: {
    height: "80px",
    paddingTop: "56.25%", // 16:9
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
    // maxWidth: "400px"
  },
  button: {
    margin: theme.spacing.unit,
    color: "#fff"
  },
  list: {
    "&:hover": {
      background: "#E8E8E8",
      cursor: "pointer"
    }
  }
});

class ReadArticles extends Component {
  componentDidMount = () => {
    this.props.fetchUserBiography({ userID: this.props.article.journalUserID });
    return;
  };
  closeArticleViewer = () => {
    this.props.closeViewer();
  };
  readArticle = e => {
    this.props.ReadArticle(this.props.articles[e.currentTarget.id]);
  };
  render() {
    const { classes, companydata, articles, article, biography } = this.props;
    return (
      <div className={classes.root}>
        <div
          style={{
            backgroundColor: companydata
              ? companydata.primaryColor
              : "#d51317" || "#d51317"
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} md={9}>
              <Paper
                elevation="0"
                className={classNames(classes.paper, classes.container)}
                square
              >
                <div>
                  <IconButton
                    className={classes.button}
                    aria-label="Back"
                    onClick={this.closeArticleViewer.bind(this)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </div>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.container}
                >
                  {article.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.container}
                >
                  {article.subtitle}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper
                elevation="0"
                className={classes.paper}
                square
                style={{
                  backgroundColor: companydata
                    ? companydata.primaryColor
                    : "#d51317" || "#d51317"
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    color: `${getContrastYIQ(
                      companydata ? companydata.primaryColor : "#d51317"
                    )}`
                  }}
                >
                  {DATE_FORMAT.type(article.dateScheduled, "SHORT")}
                </Typography>
                <div
                  variant="subtitle2"
                  style={{
                    color: `${getContrastYIQ(
                      companydata ? companydata.primaryColor : "#d51317"
                    )}`,
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "13px"
                  }}
                >
                  <ForumIcon fontSize="small" />
                  &nbsp;
                  {_getCommentsCount(article.comments)}
                </div>
                <div
                  variant="subtitle2"
                  style={{
                    color: `${getContrastYIQ(
                      companydata ? companydata.primaryColor : "#d51317"
                    )}`,
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "13px"
                  }}
                >
                  <FavoriteIcon fontSize="small" />
                  &nbsp;
                  {_getLikesCount(article.likesData)}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          <Grid item xs={12} md={9}>
            {article.photos ? (
              <div>
                <CardMedia
                  className={classes.media}
                  image={PHOTO_BACKGROUND(article.photos)}
                />
              </div>
            ) : null}

            <Paper elevation="0" className={classes.paper}>
              <div style={{ fontFamily: "sans-serif", whiteSpace: "pre-wrap" }}>
                {article.body}
              </div>
            </Paper>
            <Paper
              elevation="0"
              className={classes.paper}
              style={{
                backgroundColor: "transparent",
                border: "6px solid #fff",
                padding: "8px"
              }}
            >
              <Grid container spacing={8}>
                <Grid item xs={9}>
                  <Paper
                    elevation="0"
                    className={classes.paper}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Typography variant="h6">Posted by</Typography>
                    <Typography variant="body2" color="textPrimary">
                      {article.journalUserName}
                    </Typography>
                  </Paper>
                  <div
                    style={{
                      fontFamily: "sans-serif",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    {biography}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%"
                    }}
                  >
                    <Paper
                      elevation="0"
                      className={classes.paper}
                      style={{
                        backgroundColor: "transparent"
                      }}
                    >
                      <Avatar
                        aria-label="Photo"
                        className={classes.avatar}
                        src={PHOTO_PROFILE(article.photoURL)}
                      >
                        P
                      </Avatar>
                    </Paper>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper
              elevation="0"
              className={classes.paper}
              style={{ backgroundColor: "transparent" }}
            >
              <Typography variant="h6" style={{ color: `${LIGHTBLUE}` }}>
                Recent Articles
              </Typography>
              <List className={classes.root}>
                <div>
                  {articles &&
                    Object.keys(articles).map(id => {
                      return (
                        <div>
                          {articles[id].manualOrExternal === "externalLink" ? (
                            <ListItem
                              className={classes.list}
                              alignItems="flex-start"
                              key={`${id}`}
                              id={id}
                              component="a"
                              href={articles[id].articleLink}
                              target="_blank"
                            >
                              <ListItemText
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {articles[id].title}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      style={{ color: `${GREY}` }}
                                    >
                                      {DATE_FORMAT.type(
                                        articles[id].dateScheduled,
                                        "BADGE"
                                      )}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.list}
                              alignItems="flex-start"
                              key={`${id}`}
                              id={id}
                              onClick={this.readArticle.bind(this)}
                            >
                              <ListItemText
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {articles[id].title}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      style={{ color: `${GREY}` }}
                                    >
                                      {DATE_FORMAT.type(
                                        articles[id].dateScheduled,
                                        "BADGE"
                                      )}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          )}
                        </div>
                      );
                    })}
                </div>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ReadArticles.propTypes = {
  classes: PropTypes.object.isRequired,
  articles: PropTypes.array,
  companydata: PropTypes.object,
  biography: PropTypes.string
};

const ReadArticlesStyled = withStyles(styles)(ReadArticles);

const mapStateToProps = state => {
  return {
    articles: state.articles.articles,
    companydata: state.loggedInCompany.companydata,
    biography: state.userbiography.biography.biography
      ? state.userbiography.biography.biography
      : ""
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUserBiography: params => dispatch(fetchUserBiography(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadArticlesStyled);
