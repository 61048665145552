import { UPDATE_SURVEY } from "../actions/types";

// const initState = {
//   type: UPDATE_SURVEY,
//   update_Survey: [{"size": "1000"}]
// };

const initState={
  type: UPDATE_SURVEY,
  update_Survey: 111,
}

// const surveyUpdateReducer = (state = [], action) => {
//   switch (action.type) {
//     case 'UPDATE_SURVEY':
//       var result = Object.keys(action.update_Survey).map((key) => [Number(key), action.update_Survey[key]]);
     
//       state = result.slice();
//       state.push(state);
//       return state;
//     break;
//     default:
//        return initState;
//   }
// }

// export default (state=[], action) => {
//  const surveyUpdateReducer = (state=[], action) => {
const surveyUpdateReducer = (state = initState, action) => {
 console.log('capman action.update_Survey: ', action.update_Survey);
  switch (action.type) {
    case UPDATE_SURVEY:
      if (action.update_Survey) {
        console.log('capman action.update_Survey 1: ', action.update_Survey);
        return {
          type: UPDATE_SURVEY,
          update_Survey: action.update_Survey,
        };
      }
      else
        console.log('capman action.update_Survey 2: ', action.update_Survey);
        { 
        return {
          type: UPDATE_SURVEY,
          update_Survey: action.update_Survey,
        };  
      }

    default:
      return state;
  }
};

export default surveyUpdateReducer;
