import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import { LIGHTBLUE } from '../../utils/color_utils';
import AddIcon from '@material-ui/icons/Add';
import { showToast } from '../../store/actions/toastAction';
import { connect } from 'react-redux';
import { TextField, FormGroup, Typography, FormControl, FormLabel } from '@material-ui/core';

const styles = (theme) => ({
	appBar: {
		position: 'static'
	},
	dialogTitleText: {
		color: LIGHTBLUE
	},
	flex: {
		flex: 1
	},
	submitButton: {
		backgroundColor: LIGHTBLUE,
		color: '#ffffff'
	},
	textField: {
		width: '100%'
	}
});
const CompanyValueForm = (props) => {
	const { classes, companyID, company, companyValue, companyName, onCompanyValueChange } = props;
	return (
		<div>
			<Typography variant="h6">{companyName}</Typography>
			<FormGroup row>
				<TextField
					id="standard-name"
					label="Company Value"
					className={classes.textField}
					value={companyValue}
					defaultValue={companyValue}
					name="companyValue"
					onChange={onCompanyValueChange('companyValue')}
					margin="normal"
					fullWidth
				/>
			</FormGroup>
		</div>
	);
};
class CompanyValue extends Component {
	state = {
		scroll: 'paper',
		companyValue: ''
	};

	componentDidMount() {
		const { companyValue, updateOnSave } = this.props;

		this.setState({
			companyValue: updateOnSave ? companyValue : ''
		});
	}
	handleClose = () => {
		this.props.onCloseDialog();
	};

	handleCompanyValueChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	render() {
		const { companyID, classes, OnSubmitCompanyValue, companyName, companyValue, updateOnSave } = this.props;
		// console.log('companyValue from Dialog', companyValue);
		return (
			<Dialog
				open={this.props.open}
				onClose={this.handleClose}
				scroll={this.state.scroll}
				//    fullScreen={window.innerWidth >= 425 ? false : true}
				fullWidth
				aria-labelledby="scroll-dialog-title"
			>
				<DialogTitle id="alert-dialog-title">
					<strong className={classes.dialogTitleText}>
						<AddIcon />LEAD Categories
					</strong>
				</DialogTitle>
				<DialogContent>
					<div>
						<Typography variant="h6">{companyName}</Typography>
						<FormGroup row>
							<TextField
								id="standard-name"
								label="Company Value"
								className={classes.textField}
								defaultValue={updateOnSave ? companyValue : this.state.companyValue}
								name="companyValue"
								inputProps={{
									name: 'companyValue',
									id: 'companyValue'
								}}
								onChange={this.handleCompanyValueChange}
								margin="normal"
								fullWidth
							/>
						</FormGroup>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => OnSubmitCompanyValue(this.state.companyValue)}
						size="large"
						variant="contained"
						className={classes.submitButton}
					>
						Submit
					</Button>
					<Button onClick={this.handleClose} size="large" color="secondary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

CompanyValue.propTypes = {};

const CompanyValueDialog = withStyles(styles)(CompanyValue);
export default CompanyValueDialog;
