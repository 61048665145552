const initState = {
  type: "USER_BIOGRAPHY",
  biography: "",
  showprofiledlg: {}
};

const userBiographyReducer = (state = initState, action) => {
  switch (action.type) {
    case "USER_BIOGRAPHY":
      return {
        ...state,
        type: "USER_BIOGRAPHY",
        biography: action.biography
      };
    case "VIEW_USER_PROFILE":
      return {
        ...state,
        showprofiledlg: action.showprofiledlg
      };

    default:
      return state;
  }
};

export default userBiographyReducer;
