/* eslint-disable no-dupe-keys */
import { app_config } from '../../../src/constants'

// live server base api url
export const BASE_API_URL = app_config.BASE_API_URL
export const BASE_URL = app_config.BASE_URL
export const FB_URL = app_config.FB_URL

// PUBLIC = 1 OR CORPORATE = 2 OR HYBRID = 3
export const APP_TYPE = app_config.APP_TYPE
export const SERVER_TYPE = app_config.SERVER_TYPE
// IS_HARMONY = TRUE/FALSE
export const IS_HARMONY = app_config.IS_HARMONY

// DEV, TEST, PROD
export const NODE_ENV = 'DEV'
export const DEFAULT_PROFILE_PIC = 'DEFAULT_PROFILE_PIC'
export const ORG_THOUGHTS = 'ORG_THOUGHTS'
export const PLDP_THOUGHTS = 'PLDP_THOUGHTS'
export const PLDP_ARTICLES = 'PLDP_ARTICLES'
export const PLDP_PODCASTS = 'PLDP_PODCASTS'
export const PLDP_VIDEOS = 'PLDP_VIDEOS'
export const GET_SELECTED_ROW = 'GET_SELECTED_ROW'
export const ILEAD_THOUGHTS = 'ILEAD_THOUGHTS'
export const ILEAD_BLEADS = 'ILEAD_BLEADS'
export const ILEAD_LEADS = 'ILEAD_LEADS'
export const ILEAD_LEADS2 = 'ILEAD_LEADS2'
export const ILEAD_LEADS2x = 'ILEAD_LEADS2x'
export const ILEAD_LEADS5x = 'ILEAD_LEADS5x'
export const ILEAD_LEADS6x = 'ILEAD_LEADS6x'
export const ILEAD_LEADS3 = 'ILEAD_LEADS3'
export const ILEAD_LEADS4 = 'ILEAD_LEADS4'
export const CORP_ILEAD_THOUGHTS = 'CORP_ILEAD_THOUGHTS'
export const GC_THOUGHTS = 'GC_THOUGHTS'
export const ILEAD_THOUGHTS_REQ = 'ILEAD_THOUGHTS_REQ'
export const DAILY_THOUGHTS = 'DAILY_THOUGHTS'
export const MY_THOUGHTS = 'MY_THOUGHTS'
export const ALL_GROUPS_POSTS = 'ALL_GROUPS_POSTS'
export const SELECTED_GROUP_POSTS = 'SELECTED_GROUP_POSTS'
export const SCHEDULED_GROUP_POSTS = 'SCHEDULED_GROUP_POSTS'
export const SELECTED_GROUP_FILES = 'SELECTED_GROUP_FILES'
export const FILTERED_GROUP_POST = 'FILTERED_GROUP_POST'

export const MY_ARTICLES = 'MY_ARTICLES'
export const INTERNAL_ARTICLES = 'INTERNAL_ARTICLES'
export const EXTERNAL_ARTICLES = 'EXTERNAL_ARTICLES'

export const MY_PODCASTS = 'MY_PODCASTS'
export const INTERNAL_PODCASTS = 'INTERNAL_PODCASTS'
export const EXTERNAL_PODCASTS = 'EXTERNAL_PODCASTS'

export const MY_VIDEOS = 'MY_VIDEOS'
export const INTERNAL_VIDEOS = 'INTERNAL_VIDEOS'
export const EXTERNAL_VIDEOS = 'EXTERNAL_VIDEOS'

export const FILTERED_POST = 'FILTERED_POST'
export const GET_AI = 'GET_AI'

// ATTACHMENTS

export const GET_MY_PLDP_TASKS = 'GET_MY_PLDP_TASKS'
export const GET_ALL_MY_PLDP_TASKS = 'GET_ALL_MY_PLDP_TASKS'
export const CREATE_PLDP_TASK = 'CREATE_NEW_PLDP_TASK'
export const GET_COMPANY_VALUES = 'GET_COMPANY_VALUES'
export const DELETE_PLDP_TASK = 'DELETE_PLDP_TASK'

// MYPLDP FAVORITES
export const GET_MYPLPD_FAVORITES = 'GET_MYPLPD_FAVORITES'

export const GET_ACTIVE_COMPANIES = 'GET_ACTIVE_COMPANIES'
export const GET_COMPANY_ANALYTICS = 'GET_COMPANY_ANALYTICS'
export const GET_ACTIVE_COMPANY_USERS = 'GET_ACTIVE_COMPANY_USERS'
export const GET_COMPANY_COUNTS = 'GET_COMPANY_COUNTS'
export const GET_UNSEEN_NOTIFICATIONS = 'GET_UNSEEN_NOTIFICATIONS'
export const GET_UNSEEN_GROUP_NOTIFICATIONS = 'GET_UNSEEN_GROUP_NOTIFICATIONS'
export const GET_UNSEEN_PLDP_NOTIFICATIONS = 'GET_UNSEEN_PLDP_NOTIFICATIONS'
export const SET_SEEN_NOTIFICATIONS = 'SET_SEEN_NOTIFICATIONS'
export const SET_SEEN_GROUP_NOTIFICATIONS = 'SET_SEEN_GROUP_NOTIFICATIONS'
export const SEEN_NOTIFICATIONS = 'SEEN_NOTIFICATIONS'
export const SET_SEEN_PLDP_NOTIFICATIONS = 'SET_SEEN_PLDP_NOTIFICATIONS'

export const MY_FOLLOWERS = 'MY_FOLLOWERS'
export const MY_NOTIFICATIONS = 'MY_NOTIFICATIONS'
export const MY_PLDP_NOTIFICATIONS = 'MY_PLDP_NOTIFICATIONS'

export const GLP_TUTORIALS = 'GLP_TUTORIALS'
export const COMPANY_STAFF = 'COMPANY_STAFF'
export const COMPANY_ANALYTICS = 'COMPANY_ANALYTICS'
export const POST_ANALYTICS = 'POST_ANALYTICS'
export const USER_CLICKS_POST = 'USER_CLICKS_POST'
export const USER_CLICKS_SECTION = 'USER_CLICKS_SECTION'

export const CREATE_THOUGHT = 'CREATE_THOUGHT'
export const CREATE_VIDEO = 'CREATE_VIDEO'
export const CREATE_ARTICLE = 'CREATE_ARTICLE'
export const CREATE_PODCAST = 'CREATE_PODCAST'
export const CREATE_GROUP = 'CREATE_GROUP'

export const UPDATE_POST_LIKING = 'UPDATE_POST_LIKING'

// COMMENTS
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'

// COMPANY ADMIN
export const GET_ASSIGNED_STUDY_MATERIALS = 'GET_ASSIGNED_STUDY_MATERIALS'
export const GET_ACTIVE_STUDY_MATERIALS = 'GET_ACTIVE_STUDY_MATERIALS'
export const GET_GROUPS_ATTENDANCE_REGISTER = 'GET_GROUPS_ATTENDANCE_REGISTER'
export const GET_INACTIVE_STUDY_MATERIALS = 'GET_INACTIVE_STUDY_MATERIALS'
export const GET_ALL_COMPANY_USERS = 'GET_ALL_COMPANY_USERS'
export const GET_COMPANY_PROGRAMMES = 'GET_COMPANY_PROGRAMMES'
export const GET_COMPANY_SURVEYS = 'GET_COMPANY_SURVEYS'
export const GET_GROUP_SURVEYS = 'GET_GROUP_SURVEYS'
export const GET_COMPANIES = 'GET_COMPANIES'
export const UPDATE_COMPANY_USER = 'UPDATE_COMPANY_USER'
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER'
export const CREATE_COMPANY_USER = 'CREATE_COMPANY_USER'
export const ERROR_CREATING_COMPANY_USER = 'ERROR_CREATING_COMPANY_USER'
export const USER_EXISTS = 'USER_EXISTS'
export const DELETE_GROUP = 'DELETE_GROUP'
export const GET_ALL_COMPANY_GROUPS = 'GET_ALL_COMPANY_GROUPS'
export const GET_ALL_GROUP_USERS = 'GET_ALL_GROUP_USERS'
export const GET_GROUP_DETAILS = 'GET_GROUP_DETAILS'
export const GET_GROUP_EVENTS = 'GET_GROUP_EVENTS'
export const GET_GROUP_EVENTS_REMINDERS = 'GET_GROUP_EVENTS_REMINDERS'
export const DELETE_GROUP_EVENT = 'DELETE_GROUP_EVENT'
export const GET_USER_GROUPS = 'GET_USER_GROUPS'
export const GET_ALL_GENERAL_SETTINGS = 'GET_ALL_GENERAL_SETTINGS'
export const GET_ALL_COMPANY_SETTINGS = 'GET_ALL_COMPANY_SETTINGS'
export const SIMPLE_VAL = 'SIMPLE_VAL'
export const SIMPLE_VAL2 = 'SIMPLE_VAL2'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const YEARSELECTED = 'YEARSELECTED'
export const MONTHSELECTED = 'MONTHSELECTED'
export const DAYSELECTED = 'DAYSELECTED'
export const HOURSELECTED = 'HOURSELECTED'
export const MINUTESELECTED = 'MINUTESELECTED'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'

export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const ERROR = 'error'
export const WARNING = 'warning'
export const INFO = 'info'
export const SUCCESS = 'success'

// TUTORIALS
export const GET_CORP_TUTORIALS = 'GET_CORP_TUTORIALS'
export const GET_PUBLIC_TUTORIALS = 'GET_PUBLIC_TUTORIALS'

// USER PROFILE DIALOG
export const SHOW_EDIT_USER_DIALOG = 'SHOW_EDIT_USER_DIALOG'
export const SHOW_USER_PROFILE_DIALOG = 'SHOW_USER_PROFILE_DIALOG'
export const LOG_USER_PROFILE_DIALOG = 'LOG_USER_PROFILE_DIALOG'
export const UPDATE_USER_PROFILE_DIALOG = 'UPDATE_USER_PROFILE_DIALOG'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const CLEAR_UPDATE_USER_SUCCESS = 'CLEAR_UPDATE_USER_SUCCESS'

// FOLLOWING
export const FOLLOW_USER = 'FOLLOW_USER'
export const UNFOLLOW_USER = 'UNFOLLOW_USER'
export const UPDATE_MY_FOLLOWERS = 'UPDATE_MY_FOLLOWERS'

// FEEDBACK
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK'

// TEST
let USER_LOGGED = JSON.parse(localStorage.getItem('GLP_LOGGED_PROFILE'))

export const COMPANY_ID = USER_LOGGED ? USER_LOGGED.companyID : ''
export const JOURNAL_USER_ID = USER_LOGGED ? USER_LOGGED.userID : ''
export const JOURNAL_USER_NAME = USER_LOGGED
  ? USER_LOGGED.firstName + ' ' + USER_LOGGED.lastName
  : ''
export const JOURNAL_PHOTOURL = USER_LOGGED ? USER_LOGGED.photoURL : ''
export const PUBLIC_COMPANY_ID = '-LEiZPT-C2PyLu_YLKNU'

// USER SPECIFIC DATA
export const USER_ANALYTICS = 'USER_ANALYTICS'
export const USER_FOLLOWERS = 'USER_FOLLOWERS'
export const USER_FOLLOWING = 'USER_FOLLOWING'
export const USER_NOTIFICATIONS = 'USER_NOTIFICATIONS'
export const USER_PLDP_NOTIFICATIONS = 'USER_PLDP_NOTIFICATIONS'
export const USER_GROUP_NOTIFICATIONS = 'USER_GROUP_NOTIFICATIONS'
export const VIEW_USER_PROFILE = 'VIEW_USER_PROFILE'
export const USER_BIOGRAPHY = 'USER_BIOGRAPHY'

export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const GLP_LOGGED_PROFILE = 'GLP_LOGGED_PROFILE'
export const GLP_LOGGED_COMPANY = 'GLP_LOGGED_COMPANY'

// CREATE POSTS STATUS
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_ERROR = 'CREATE_ARTICLE_ERROR'

export const CREATE_THOUGHT_SUCCESS = 'CREATE_THOUGHT_SUCCESS'
export const CREATE_THOUGHT_ERROR = 'CREATE_THOUGHT_ERROR'

export const CREATE_PODCAST_SUCCESS = 'CREATE_PODCAST_SUCCESS'
export const CREATE_PODCAST_ERROR = 'CREATE_PODCAST_ERROR'

export const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS'
export const CREATE_VIDEO_ERROR = 'CREATE_VIDEO_ERROR'

export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CLEAR_GROUP_SUCCESS = 'CLEAR_GROUP_SUCCESS'
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR'
export const SHOW_SPEEDDIAL = 'SHOW_SPEEDDIAL'
export const SHOW_CALF = 'SHOW_CALF'
export const FRESH = 'FRESH'
export const FRESH_ADD = 'FRESH_ADD'
export const FRESH_POINTS = 'FRESH_POINTS'
export const FRESH_VIDEOS = 'FRESH_VIDEOS'

export const SURVEY_LIST = 'SURVEY_LIST'
export const SURVEY_Q_LIST = 'SURVEY_Q_LIST'
export const SURVEY_A_LIST = 'SURVEY_A_LIST'
export const SURVEY_STATS_LIST = 'SURVEY_STATS_LIST'
export const SURVEY_TESTS_LIST = 'SURVEY_TESTS_LIST'

export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const COLLECT_ANSWER = 'COLLECT_ANSWER'
export const COLLECT_TESTS = 'COLLECT_TESTS'
export const SIMPLE_VALUES = 'SIMPLE_VALUES'
export const COLLECT_FINAL_ANSWER = 'COLLECT_FINAL_ANSWER'

export const STORE_SUBCOMPETENCYID = 'TORE_SUBCOMPETENCYID'
export const STORE_DRAWER = 'STORE_DRAWER'
export const COMPETENCIES_LIST = 'COMPETENCIES_LIST'
export const SUBCOMPETENCIES_LIST = 'SUBCOMPETENCIES_LIST'
export const TEST_PUBLISHER = 'TEST_PUBLISHER'
export const TEST_USER = 'TEST_USER'
export const REFRESH_CARD = 'REFRESH_CARD'
export const TEST_HISTORY = 'TEST_HISTORY'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const LEADERBOARD = 'LEADERBOARD'
export const GET_POE = 'GET_POE'

export const GET_SL = 'GET_SL'
export const GET_O = 'GET_O'
export const GET_O_S = 'GET_O_S'
export const GET_SLAVE_OPINION = 'GET_SLAVE_OPINION'
export const GET_OPINIONID = 'GET_OPINIONID'

export const cGET_O = 'cGET_O'
export const cGET_O_S = 'cGET_O_S'
export const cGET_SLAVE_OPINION = 'cGET_SLAVE_OPINION'
export const cGET_OPINIONID = 'cGET_OPINIONID'

export const gGET_O = 'gGET_O'
export const gGET_O_S = 'gGET_O_S'
export const gGET_SLAVE_OPINION = 'gGET_SLAVE_OPINION'
export const gGET_OPINIONID = 'gGET_OPINIONID'

export const LEADERBOARD_LOADING = 'LEADERBOARD_LOADING'
export const GET_ALL_TOKENS = 'GET_ALL_TOKENS'
export const INPUT_HASHTAG = 'INPUT_HASHTAG'
export const GET_TOP_HASHTAGS = 'GET_TOP_HASHTAGS'
export const GET_POINTS = 'GET_POINTS'
export const CLEAR_POINTS = 'CLEAR_POINTS'
export const GET_MY_TOKEN = 'GET_MY_TOKEN'
export const COMPETENCIES_TEST = 'COMPETENCIES_TEST'
export const GET_COMPANY_ADMIN = 'GET_COMPANY_ADMIN'
export const CHECK_TOKEN = 'CHECK_TOKEN'

// export const FIXED_LEADER_LIST = 'FIXED_LEADER_LIST';

// User Types Restrictions
// eslint-disable-next-line max-len
// 3 - i-lead, 5 - master contributor, 10 - Corporate ileader, 11 - Organisational contributor, 9 - Daily User
export const RESTRICTED_USER_TYPES = {
  3: true,
  5: true,
  10: true
}

// 4 - Global Contributor, 7 - Company Admin, 11 - Organisational contributor, 9 - Daily User
export const CAN_POST_ORG_ILEAD = {
  3: true,
  4: true,
  7: true,
  9: true,
  10: true,
  11: true,
  12: true,
  14: true
}

// 9 - Daily User
export const CAN_POST_DAILY = {
  9: true
}

export const CAN_POST_INTERNAL = {
  3: true,
  4: true,
  7: true,
  9: true,
  11: true,
  12: true, //delegate
  14: true
}

export const NEW_CORP_USERS = [
  { id: 8, value: 'Standard User' },
  { id: 12, value: 'Delegate' },
  { id: 10, value: 'Corporate I-Leader' },
  { id: 11, value: 'Organisational Contributor' },
  { id: 14, value: 'Client Admin' },
  { id: 7, value: 'Company Admin' },
  { id: 4, value: 'Global Contributor' }
]

export const HARMONY_USER_TYPES = [
  { id: 12, value: 'Delegate' },
  { id: 3, value: 'Public Contributor' },
  { id: 11, value: 'Organisational Contributor' },
  { id: 14, value: 'Client Admin' },
  { id: 7, value: 'Company Admin' },
  { id: 4, value: 'Global Contributor' }
]

export const NEW_PUBLIC_USERS = [
  { id: 8, value: 'Standard User' },
  { id: 3, value: 'Public Contributor' },
  { id: 11, value: 'Organisational Contributor' },
  { id: 14, value: 'Client Admin' },
  { id: 7, value: 'Company Admin' },
  { id: 4, value: 'Global Contributor' }
]

// Global contributors
export const CAN_POST_GLOBAL = {
  4: true
}

// Standar User
export const BLOCKED_USER_TYPES = {
  8: true
}

// Can access company profile
// 7 - Company Admin, 4 - GC,
// PUBLIC APP - ONLY Leadership Platform users
// CORPORATE APP - ONLY Admins & GCs
// -LDVbbRyIMhukVtTVQ0n - OneConnect
// -KgsUcgfo7z1U9MXgd9i - Leadership Platform
// -Lu4BlhZ-tsDSnd-fnFd - Harmony
	
export const ACCESS_COMPANY_PROFILE = {
  '-Lu4BlhZ-tsDSnd-fnFd': true,
  '-LDVbbRyIMhukVtTVQ0n': true,
  '-KgsUcgfo7z1U9MXgd9i': true,
  '4': true,
  '7': true,
  '14': true,
  'superUser': true
}

export const HIDE_COMMENTS_BUTTON = {
  '-Lu4BlhZ-tsDSnd-fnFd': true,
  '-KgsUcgfo7z1U9MXgd9i': true
}

export const HIDE_POSTING_TO_ILEAD = {
  '-KgsUcgfo7z1U9MXgd9i': true,
  '-Lu4BlhZ-tsDSnd-fnFd': true
}

export const IS_DELEGATE_USER = {
  12: true
}

export const HIDE_MY_POSTS_TABS = {
  12: true
}

export const ACCESS_COMPANY_PROFILE_USER = {
  4: true,
  7: true,
  14: true,
  11: true,
  superUser: true
}

export const IS_COMPANY_ADMIN = {
  7: true,
  14: true
}

// App URLs
export const APP_URL_TITLE = {
  '/': APP_TYPE === 'PUBLIC' ? 'LEAD Global Forum' : 'LEAD Forum',
  '/organisational': 'Forum',
  '/ilead': 'LEAD Global Forum',
  '/globalcontributors': 'Global Contributors',
  '/daily': 'Daily Thoughts',
  '/myposts': 'My Posts',
  '/videos': 'Videos',
  '/podcasts': 'Podcasts & Audio',
  '/articles': 'Articles',
  '/ebooks': 'eBooks',
  // "/pldp": "Personal LDP",
  '/pldp': 'Personal Development Plan',
  '/analytics': 'Analytics',
  '/companyadmin': 'Club Admin',
  '/tutorials': 'TLE Tutorials',
  '/tcs': 'TLE T&Cs',
  '/userprofile': 'User Profile',
  '/organisational': 'LEAD Forum',
  '/groupmessages': 'Group Forums',
  '/competencies': 'Competencies',
  '/LeaderOnList': 'LeaderTrack',
  '/orgEval': 'Organisation Assessment',
  '/orgStats': 'Organisation Stats',
  '/genEval': 'General Assessment',
  '/genStats': 'General Stats',
  '/gotocalf': 'Executive Club'
}

// App URLs
export const HIDE_ADD_BTN = {
  '/pldp': true,
  '/tutorials': true,
  '/pldp': true,
  '/analytics': true,
  '/companyadmin': true,
  '/tcs': true,
  '/userprofile': true,
  '/competencies': true,
  '/pldp/companyvalues': true,
  '/companyvalues': true,
  '/groups': true,
  '/groupdetails': true,
  '/studymaterials': true,
  '/facilitators': true,
  '/groupcourses': true,
  '/gotocli': true,
  '/GotoExjoin': true,
  '/Gotocalf': true,
  '/LeaderOnList': true,
  '/LeaderList': true,
  '/Bookshop': true,
  '/Poe': true,
  '/orgStats': true,
  '/orgEval': true,
  '/genStats': true,
  '/genEval': true,
  '/tokens': true
}

// post types
export const postTypes = {
  thoughts: 'dailyThoughts',
  articles: 'news',
  podcasts: 'podcasts',
  videos: 'videos',
  groupposts: 'group-posts'
}

export const VALUES_NAME = {
  '-Lu4BlhZ-tsDSnd-fnFd': 'Competencies',
  'other': 'LEAD Category'
}

export const ACCESS_EXEC_CLUB = {
  '-Lu4BlhZ-tsDSnd-fnFd': true,
  '-KgsUcgfo7z1U9MXgd9i': true,
}

export const ACCESS_COMPETENCIES = {
  '-Lu4BlhZ-tsDSnd-fnFd': true,
  '-KgsUcgfo7z1U9MXgd9i': true,
}

export const CHANGE_ABOUT_PLDP = {
  '-Lu4BlhZ-tsDSnd-fnFd': true,
  '-KgsUcgfo7z1U9MXgd9i': true,
}

export const SUPPORTED_FILE_TYPES = {
  png: true,
  jpeg: true,
  jpg: true,
  gif: true,
  bmp: true,
  pdf: true,
  csv: true,
  xlsx: true,
  docx: true,
  mp4: true,
  webm: true,
  mp3: true
}


