import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import { RED } from "./../../utils/color_utils";
import { removePLDPFavorites } from "./../../store/actions/pldpActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


const styles = theme => ({
  button: {
    margin: "22px",
    position: "absolute",
    width: "max-content",

    top: "-23px",
    right: "-9%",
    backgroundColor: "#eee",
    zIndex: 999
  },

  removeIcon: {
    color: RED
  }
});
class RemoveFavoriteButton extends Component {
  handleRemoveItem = () => {
    const { item, itemType, removePLDPFavorites } = this.props;
    var answer = window.confirm("Remove this " + itemType + " from My LFP?");
    let params = {
      item: item,
      itemType: itemType,
      answer: answer
    };
    removePLDPFavorites(params);
  };
  render() {
    const { classes, match } = this.props;
    const showRemove = match.path.includes("/pldp");

    return (
      showRemove && (
        <IconButton
          size="small"
          className={classes.button}
          onClick={this.handleRemoveItem}
          aria-label="Delete"
        >
          <DeleteIcon className={classes.removeIcon} />
        </IconButton>
      )
    );
  }
}

const RemoveFavorite = withStyles(styles)(RemoveFavoriteButton);

export default withRouter(
  connect(
    null,
    { removePLDPFavorites }
  )(RemoveFavorite)
);
