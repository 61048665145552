import {
    GET_USER_PROFILE
  } from '../actions/types'
  
  const initialState = {
    userInfo: {}
  }
  const getUserProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case  GET_USER_PROFILE:
        console.log('green getUserData 4:', action.userInfo)
        return {
          ...state,
          type: GET_USER_PROFILE,
          userInfo: action.userInfo
        };
      default:
        return state
    }
  }
  export default getUserProfileReducer
  