import { LOGIN_SUCCESS, LOGIN_ERROR, SIGNOUT_SUCCESS, HIDE_TOAST, USER_LOGGED_IN } from '../actions/types'

let currentUser = null

try {
  currentUser = JSON.parse(localStorage.getItem(USER_LOGGED_IN))
} catch (err) {
  currentUser = null
}

const initState = {
  authError: null,
  auth: currentUser
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
  case LOGIN_SUCCESS:
    return {
      ...state,
      authError: null,
      auth: action.auth
    }
  case SIGNOUT_SUCCESS:
    return {
      ...state,
      authError: null,
      auth: null
    }
  default:
    return {
      ...state,
      authError: null,
      auth: currentUser
    }
  }
}

export default authReducer
