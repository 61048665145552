import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PlayIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import {
  LIGHTBLUE,
  LIGHTGREY,
  HOVERRED,
  RED,
  DARK
} from "../../utils/color_utils";
import { YOUTUBE } from "./../../utils/youtube_utils";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  margin: {
    margin: theme.spacing.unit
  },
  titleColor: {
    fontSze: "1.0rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "500",
    lineHeight: "1.6",
    letterSpacing: "0.0075em",
    backgroundColor: "#fff",
    padding: "8px 24px"
  },
  title: {
    backgroundColor: "#fff",
    fontSze: "1.0rem!important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    display: "flex",
    alignItems: "center",
    color: `${DARK}`
  },
  dialogHeight: {
    maxHeight: `calc(100% - 30px)`,
    backgroundColor: "#000",
    minHeight: "60vh"
  },
  divStyle: {
    margin: "0 14px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 4px",
      padding: "0px 6px",
      alignItems: "start",
      justifyContent: "start"
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80%"
  },
  close: {
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "999999",
    color: "#fff",
    backgroundColor: `${RED}`,
    borderRadius: "0%",
    padding: "8px",
    "&:hover": {
      background: `${HOVERRED}`
    }
  }
});

class MediaPlayer extends Component {
  handleClose = e => {
    this.props.CloseMedia();
  };

  render() {
    const { classes, openmediaplayer, media, fullScreen } = this.props;
    return (
      <div>
        <FormControl component="fieldset">
          <Dialog
            open={openmediaplayer}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen={fullScreen}
            fullWidth
            maxWidth="sm"
            classes={{ paper: classes.dialogHeight }}
          >
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose.bind(this)}
            >
              <CloseIcon fontSize="small" className={classes.icon} />
            </IconButton>
            <DialogTitle className={classes.titleColor} id="form-dialog-title">
              <div className={classes.title}>
                <PlayIcon />
                &nbsp;{media.title}
              </div>
            </DialogTitle>
            <DialogContent className={classes.divStyle}>
              <iframe
                id="iframeViewer"
                src={YOUTUBE.getMediaURL(media.url)}
                autoPlay="false"
                frameBorder="0"
                height="400"
                width="100%"
                webkitAllowFullscreen
                mozAllowFullscreen
                allowFullscreen
              />
            </DialogContent>
            <DialogActions />
          </Dialog>
        </FormControl>
      </div>
    );
  }
}

MediaPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

const MediaPlayerDlg = withMobileDialog({ breakpoint: "xs" })(MediaPlayer);

export default withStyles(styles)(MediaPlayerDlg);
