import React, { Component } from "react";
import PropTypes from "prop-types";
import ThoughtsView from "../../components/Views/PldpThoughtsView";
import { connect } from "react-redux";
import { fetchThoughts } from "../../store/actions/thoughtsActions";
import { PLDP_THOUGHTS } from "../../store/actions/types";

class PLDPThoughts extends Component {
  componentWillMount = () => {
    var params = {
      type: PLDP_THOUGHTS,
      orderChild: "PLDPUserID",
      equalTo: this.props.userdata.userID,
      userID: this.props.userdata.userID
      // companyName: this.props.userdata.companyName,
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    };
    this.props.fetchThoughts(params);
  };

  render() {
    const { thoughts, userdata } = this.props;
    return (
      <div>
        {userdata && thoughts ? (
          <React.Fragment>
            <ThoughtsView
              thoughts={thoughts}
              currentUser={userdata}
              {...this.props}
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

PLDPThoughts.propTypes = {
  fetchThoughts: PropTypes.func.isRequired,
  thoughts: PropTypes.array,
  userdata: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    thoughts: state.thoughts.pldpthoughts,
    userdata: state.userDialog.userDetails[0]
  };
};

export default connect(
  mapStateToProps,
  { fetchThoughts }
)(PLDPThoughts);
