/* eslint-disable no-inline-comments */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CancelIcon from '@material-ui/icons/Cancel'
import PermMediaRoundedIcon from '@material-ui/icons/PermMediaRounded'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Collapse from '@material-ui/core/Collapse'
import CardMedia from '@material-ui/core/CardMedia'

import UploadFiles from '../UploadFiles'
// import Iframe from "react-iframe";
import AudioRecorder from './AudioRecorder'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import LinkIcon from '@material-ui/icons/Link'
import MicIcon from '@material-ui/icons/Mic'
import VideocamIcon from '@material-ui/icons/Videocam'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import ImageIcon from '@material-ui/icons/Image'
import CheckIcon from '@material-ui/icons/Check'
import AppBar from '@material-ui/core/AppBar'
// import { Player } from 'video-react'
import ReactPlayer from 'react-player'
import {
  GREY,
  RED,
  LIGHTBLUE,
  GREEN,
  LIGHTGREY,
  DARK,
  DARKBLUE
} from '../../utils/color_utils'

import { isURL } from '../../utils'
import { showToast } from '../../store/actions/toastAction'
import Chip from '@material-ui/core/Chip'

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 0 }}>
      {props.children}
    </Typography>
  )
}

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
    // maxWidth: "400px"
  },
  tabsdiv: {
    flexGrow: 1,
    width: '100%!important',
    // backgroundColor: theme.palette.background.paper,
    marginTop: '16px'
  },
  heading: {
    height: 0,
    paddingTop: '5%', // 16:9
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  button: {
    margin: '8px'
  },
  bluecolor: {
    color: LIGHTBLUE
  },
  chip: {
    margin: theme.spacing.unit / 2
  }
})

class ThoughtsAttachments extends React.Component {
  state = {
    expanded: true,
    tabsvalue: 0,
    manualOrExternal: 'externalLink',
    uploadinprogress: false,

    podcastuploaded: false,
    podcasturl: '',
    podcastvalue: 'podcastnone',
    podcastname: '',
    podcasttitle: '',
    podcastObjurl: '',
    podcastsObj: [],
    podcastTabDisabled: false,

    audiourl: '',
    audioname: '',
    audiouploaded: false,


    videovalue: 'videonone',
    videourl: '',
    videouploaded: false,
    videoname: '',
    videotitle: '',
    videoObjurl: '',
    videosObj: [],
    videoTabDisabled: false,

    imageurl: '',
    imageuploaded: false,
    imagename: '',
    imageTabDisabled: false,

    articletitle: '',
    articleurl: '',
    articlesObj: [],
    articlesTabDisabled: false,

    filetitle: '',
    fileurl: '',
    fileuploaded: false,
    filesObj: [],
    filesTabDisabled: false
  };

  handlePodcastChange = event => {
    this.setState({ podcastvalue: event.target.value })
    this.props.handleExtUpload(event.target.value)
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  };

  handleTabsChange = (event, tabsvalue) => {
    if (this.state.uploadinprogress) {
      let params = {
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Please wait, Upload in progress..'
      }

      this.props.showToast(params)
      return
    }

    this.setState({ tabsvalue })
  };

  audioUploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      audiourl: url,
      audioname: filename,
      audiouploaded: url ? true : false,
      uploadinprogress: false,
      filesTabDisabled: true,
      videoTabDisabled: true,
      imageTabDisabled: true,
      articlesTabDisabled: true
    })

    this.props.onChange('audiourl', {
      audiourl: url,
      audioname: filename,
      audiouploaded: url ? true : false
    })

    this.props.onChange('uploadInProgress', false)
  };

  uploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      podcasturl: url,
      podcastname: filename,
      podcastuploaded: url ? true : false,
      uploadinprogress: false
    })

    this.props.onChange('podcasturl', {
      podcasturl: url,
      podcastname: filename,
      podcastuploaded: url ? true : false
    })

    this.props.onChange('uploadInProgress', false)
  };

  videoUploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      videourl: url,
      videoname: filename,
      videouploaded: url ? true : false,
      uploadinprogress: false,
      podcastTabDisabled: true,
      filesTabDisabled: true,
      imageTabDisabled: true,
      articlesTabDisabled: true
    })
    this.props.onChange('videourl', {
      videourl: url,
      videoname: filename,
      videouploaded: url ? true : false
    })

    this.props.onChange('uploadInProgress', false)
  };

  imageUploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      imageurl: url,
      imagename: filename,
      imageuploaded: url ? true : false,
      uploadinprogress: false,
      podcastTabDisabled: true,
      videoTabDisabled: true,
      filesTabDisabled: true,
      articlesTabDisabled: true
    })
    this.props.onChange('imageurl', {
      imageurl: url,
      imagename: filename,
      imageuploaded: url ? true : false
    })

    this.props.onChange('uploadInProgress', false)
  };


  fileUploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      fileurl: url,
      filetitle: filename,
      fileuploaded: false,
      uploadinprogress: false,
      podcastTabDisabled: true,
      videoTabDisabled: true,
      imageTabDisabled: true,
      articlesTabDisabled: true
    })

    let obj = {}


    let objarray = []


    let isValidURL = false


    let isValidTitle = ''; let prop = 'filesObj'

    objarray = this.state[prop]


    if (prop === 'filesObj') {
      isValidTitle = filename
      isValidURL = url

      obj = {
        title: this.state.filetitle,
        url: this.state.fileurl
      }
    }

    objarray.push(obj)

    this.state = { ...this.state, [prop]: objarray }
    this.props.onChange([prop], objarray)
    this.resetFields(prop)

    this.props.onChange('fileurl', {
      fileurl: url,
      filetitle: filename,
      fileuploaded: false
    })

    this.props.onChange('uploadInProgress', false)
  };

  uploadInProgress = status => {
    this.setState({
      ...this.state,
      uploadinprogress: status
    })

    this.props.onChange('uploadInProgress', status)
  };

  onPodcastUploadDeleted = () => {
    this.setState({
      ...this.state,
      podcasturl: '',
      podcastuploaded: false,
      podcastTabDisabled: false,
      videoTabDisabled: false,
      imageTabDisabled: false,
      articlesTabDisabled: false,
      filesTabDisabled: false
    })
    this.props.onChange('podcasturl', {})
  };

  onAudioUploadDeleted = () => {
    this.setState({
      ...this.state,
      audiourl: '',
      audioname: '',
      audiouploaded: false,
      podcastTabDisabled: false,
      videoTabDisabled: false,
      imageTabDisabled: false,
      articlesTabDisabled: false,
      filesTabDisabled: false
    })
    this.props.onChange('audiourl', {})
  };

  onVideoUploadDeleted = () => {
    this.setState({
      ...this.state,
      videourl: '',
      videouploaded: false,
      podcastTabDisabled: false,
      videoTabDisabled: false,
      imageTabDisabled: false,
      articlesTabDisabled: false,
      filesTabDisabled: false
    })
    this.props.onChange('videourl', {})
  };

  onImageUploadDeleted = () => {
    this.setState({
      ...this.state,
      imageurl: '',
      imagename: '',
      imageuploaded: false,
      podcastTabDisabled: false,
      videoTabDisabled: false,
      imageTabDisabled: false,
      articlesTabDisabled: false,
      filesTabDisabled: false
    })
    this.props.onChange('imageurl', {})
  };

  onFileUploadDeleted = () => {
    this.setState({
      ...this.state,
      fileurl: '',
      filetitle: '',
      fileuploaded: false,
      podcastTabDisabled: false,
      videoTabDisabled: false,
      imageTabDisabled: false,
      articlesTabDisabled: false,
      filesTabDisabled: false
    })

    this.props.onChange('fileurl', {})
  };

  handleLinkClick = url => e => {
    window.open(url, '_blank')
  };

  handleLinkDelete = (obj, prop) => {
    let objData = this.state[prop]
    objData.splice(obj, 1)
    this.props.onChange([prop], objData)
    if (prop === 'articlesObj' && objData.length < 1) {
      this.setState({
        podcastTabDisabled: false,
        videoTabDisabled: false,
        imageTabDisabled: false,
        filesTabDisabled: false
      })
    }
    if (prop === 'podcastsObj' && objData.length < 1) {
      this.setState({
        articlesTabDisabled: false,
        videoTabDisabled: false,
        imageTabDisabled: false,
        filesTabDisabled: false
      })
    }
    if (prop === 'videosObj' && objData.length < 1) {
      this.setState({
        articlesTabDisabled: false,
        podcastTabDisabled: false,
        imageTabDisabled: false,
        filesTabDisabled: false
      })
    }
    if (prop === 'filesObj' && objData.length < 1) {
      this.setState({
        articlesTabDisabled: false,
        podcastTabDisabled: false,
        imageTabDisabled: false,
        videoTabDisabled: false
      })
    }
  };

  saveObject = prop => e => {
    e.preventDefault()
    let obj = {}


    let objarray = []


    let isValidURL = false


    let isValidTitle = ''

    objarray = this.state[prop]

    if (prop === 'articlesObj') {
      isValidTitle = this.state.articletitle
      isValidURL = isURL(this.state.articleurl)

      obj = {
        title: this.state.articletitle,
        url: this.state.articleurl
      }
      this.setState({
        podcastTabDisabled: true,
        videoTabDisabled: true,
        imageTabDisabled: true,
        filesTabDisabled: true
      })
    }
    if (prop === 'podcastsObj') {
      isValidTitle = this.state.podcasttitle
      isValidURL = isURL(this.state.podcastObjurl)

      obj = {
        title: this.state.podcasttitle,
        url: this.state.podcastObjurl
      }
      this.setState({
        articlesTabDisabled: true,
        videoTabDisabled: true,
        imageTabDisabled: true,
        filesTabDisabled: true
      })
    }
    if (prop === 'filesObj') {
      isValidTitle = this.state.filetitle
      isValidURL = isURL(this.state.fileurl)

      obj = {
        title: this.state.filetitle,
        url: this.state.fileurl
      }
      this.setState({
        podcastTabDisabled: true,
        videoTabDisabled: true,
        imageTabDisabled: true,
        articlesTabDisabled: true
      })
    }
    if (prop === 'videosObj') {
      isValidTitle = this.state.videotitle
      isValidURL = isURL(this.state.videoObjurl)

      obj = {
        title: this.state.videotitle,
        url: this.state.videoObjurl
      }
      this.setState({
        podcastTabDisabled: true,
        articlesTabDisabled: true,
        imageTabDisabled: true,
        filesTabDisabled: true
      })
    }

    if (!isValidTitle.trim()) {
      var params = {
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Please provide a valid title for the link'
      }

      this.props.showToast(params)
      return
    }

    if (!isValidURL) {
      var params = {
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Please provide a valid url link'
      }

      this.props.showToast(params)
      return
    }

    objarray.push(obj)

    this.state = { ...this.state, [prop]: objarray }
    this.props.onChange([prop], objarray)
    this.resetFields(prop)
  };

  resetFields(prop) {
    if (prop === 'articlesObj') {
      this.setState({
        articletitle: '',
        articleurl: ''
      })
    }
    if (prop === 'podcastsObj') {
      this.setState({
        podcasttitle: '',
        podcastObjurl: ''
      })
    }
    if (prop === 'filesObj') {
      this.setState({
        filetitle: '',
        fileurl: ''
      })
    }
    if (prop === 'videosObj') {
      this.setState({
        videotitle: '',
        videoObjurl: ''
      })
    }
  }

  handleVideoChange = event => {
    this.setState({ videovalue: event.target.value })
    this.props.onChange('VidUploadExternal', event.target.value)
  };

  render() {
    const {
      podcastvalue,
      podcastuploaded,
      podcasturl,
      videourl,
      manualOrExternal,
      tabsvalue,
      videovalue,
      videouploaded,
      imageurl,
      imageuploaded,
      fileuploaded,
      filesObj,
      articlesObj,
      podcastsObj,
      videosObj,
      audiouploaded,
      audiourl
    } = this.state
    const { classes } = this.props

    return (
      <React.Fragment>
        {/* Attachments */}
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary
            color='primary'
            expandIcon={<ExpandMoreIcon />}
          >
             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <PermMediaRoundedIcon style={{ color: '#ccbc74', paddingRight: 10, fontSize: 30 }}/>
               <Typography className={classes.heading}>
              Add attachments below (optional)
              </Typography> 
            </div> 
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ padding: '2px 2px 24px', backgroundColor: '#fbfbfb' }}
          >
            <div className={classes.tabsdiv}>
              <AppBar position='static' color='default'>
                <Tabs
                  value={tabsvalue}
                  onChange={this.handleTabsChange}
                  variant='scrollable'
                  indicatorColor='secondary'
                  textColor='secondary'
                  scrollButtons='auto'
                >
                  <Tab icon={<Tooltip title='Links' placement='top'><LinkIcon style={{ color: LIGHTBLUE }} /></Tooltip>} disabled={this.state.articlesTabDisabled} />
                  <Tab icon={<Tooltip title='Podcasts' placement='top'><MicIcon style={{ color: GREEN }} /></Tooltip>} disabled={this.state.podcastTabDisabled}/>
                  <Tab icon={<Tooltip title='Videos' placement='top'><VideocamIcon style={{ color: GREY }} /></Tooltip>} disabled={this.state.videoTabDisabled}/>
                  <Tab icon={<Tooltip title='Images' placement='top'><ImageIcon style={{ color: RED }} /></Tooltip>} disabled={this.state.imageTabDisabled}/>
                  <Tab icon={<Tooltip title='Files' placement='top'><InsertDriveFileIcon style={{ color: DARKBLUE }} /></Tooltip>} disabled={this.state.filesTabDisabled}/>
                </Tabs>
              </AppBar>
              {tabsvalue === 0 && (
                <TabContainer>
                  <div style={ linksAttachment }>
                    <div>
                      <TextField
                        name='articletitle'
                        label='Enter article title'
                        multiline
                        fullWidth
                        margin='normal'
                        InputLabelProps={{
                          shrink: true
                        }}
                        aria-describedby='articletitle'
                        value={this.state.articletitle}
                        onChange={event => {
                          const { value } = event.target
                          this.setState({
                            articletitle: value
                          })
                        }}
                      />
                    </div>
                    <div style={flexAlignObj}>
                      <div style={flexObjOne}>
                        <TextField
                          name='articleurl'
                          type='url'
                          label='Enter article link'
                          multiline
                          fullWidth
                          margin='normal'
                          InputLabelProps={{
                            shrink: true
                          }}
                          aria-describedby='articleurl'
                          value={this.state.articleurl}
                          onChange={event => {
                            const { value } = event.target
                            this.setState({
                              articleurl: value
                            })
                          }}
                        />
                      </div>
                      <div style={flexObjTwo}>
                        <Button
                          type='button'
                          variant='contained'
                          color='primary'
                          style={sendBtn}
                          onClick={this.saveObject('articlesObj')}
                        >
                          <CheckIcon className={classes.rightIcon} />
                        </Button>
                      </div>
                    </div>
                    <div>
                      {articlesObj &&
                        Object.keys(articlesObj).map(article => {
                          return (
                            <Chip
                              icon={<LinkIcon />}
                              label={articlesObj[article].title}
                              onClick={this.handleLinkClick(
                                articlesObj[article].url
                              )}
                              onDelete={() => this.handleLinkDelete(
                                article,
                                'articlesObj'
                              )}
                              className={classes.chip}
                              key={article}
                            />
                          )
                        })}
                    </div>
                  </div>
                </TabContainer>
              )}
              {tabsvalue === 1 && (
                <TabContainer>
                  <div className={classes.tabsdiv}>
                    <div style={podcastsAttachment}>
                      <RadioGroup
                        aria-label='PodcastExternalRecord'
                        name='PodcastExternalRecord'
                        value={this.state.podcastvalue}
                        onChange={this.handlePodcastChange}
                        style={{
                          flexDirection: 'row',
                          marginBottom: '10px'
                        }}
                      >
                        <FormControlLabel
                          name='external'
                          value='external'
                          control={<Radio color='primary' />}
                          label='External link'
                          labelPlacement='end'
                        />

                        <FormControlLabel
                          name='upload'
                          value='upload'
                          control={<Radio color='primary' />}
                          label='Upload podcast'
                          labelPlacement='end'
                        />
                        {/* <FormControlLabel
                          name="audio"
                          value="audio"
                          control={<Radio color="primary" />}
                          label="Record audio"
                          labelPlacement="end"
                        /> */}
                        <FormControlLabel
                          name='podcastnone'
                          value='podcastnone'
                          control={<Radio color='primary' />}
                          label='None'
                          labelPlacement='end'
                        />
                      </RadioGroup>
                      <div>
                        {podcastvalue === 'external' && (
                          <div>
                            <div>
                              <TextField
                                name='podcasttitle'
                                label='Enter podcast title'
                                multiline
                                fullWidth
                                margin='normal'
                                InputLabelProps={{
                                  shrink: true
                                }}
                                aria-describedby='podcasttitle'
                                value={this.state.podcasttitle}
                                onChange={event => {
                                  const { value } = event.target
                                  this.setState({
                                    podcasttitle: value
                                  })
                                }}
                              />
                            </div>
                            <div style={flexAlignObj}>
                              <div style={flexObjOne}>
                                <TextField
                                  name='podcastObjurl'
                                  type='url'
                                  label='Enter podcast link'
                                  multiline
                                  fullWidth
                                  margin='normal'
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  aria-describedby='podcastObjurl'
                                  value={this.state.podcastObjurl}
                                  onChange={event => {
                                    const { value } = event.target
                                    this.setState({
                                      podcastObjurl: value
                                    })
                                  }}
                                />
                              </div>
                              <div style={flexObjTwo}>
                                <Button
                                  type='button'
                                  variant='contained'
                                  color='primary'
                                  style={sendBtn}
                                  onClick={this.saveObject('podcastsObj')}
                                >
                                  <CheckIcon className={classes.rightIcon} />
                                </Button>
                              </div>
                            </div>
                            <div>
                              {podcastsObj &&
                                Object.keys(podcastsObj).map(podcast => {
                                  return (
                                    <Chip
                                      icon={<LinkIcon />}
                                      label={podcastsObj[podcast].title}
                                      onClick={this.handleLinkClick(
                                        podcastsObj[podcast].url
                                      )}
                                      onDelete={() => this.handleLinkDelete(
                                        podcast,
                                        'podcastsObj'
                                      )}
                                      className={classes.chip}
                                      key={podcast}
                                    />
                                  )
                                })}
                            </div>
                          </div>
                        )}
                        {podcastvalue === 'upload' && (
                          <Collapse in={podcastvalue === 'upload'}>
                            {podcastuploaded ? (
                              <div>
                                <div>
                                  <audio controls src={podcasturl}>
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                  <br />
                                </div>
                                <div>
                                  <Button
                                    variant='contained'
                                    color='secondary'
                                    component='span'
                                    size='small'
                                    className={classes.button}
                                    onClick={this.onPodcastUploadDeleted}
                                  >
                                    <CancelIcon className={classes.leftIcon} />
                                    Delete Podcast
                                  </Button>
                                </div>
                                <br />
                              </div>
                            ) : (
                              <UploadFiles
                                onUploadComplete={this.uploadFinished.bind(
                                  this
                                )}
                                onUploadProgress={this.uploadInProgress.bind(
                                  this
                                )}
                                uploadtype='podcast'
                              />
                            )}
                          </Collapse>
                        )}
                        {podcastvalue === 'audio' && (
                          <Collapse in={podcastvalue === 'audio'}>
                            {audiouploaded ? (
                              <div>
                                <div>
                                  <audio controls src={audiourl}>
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                  <br />
                                </div>
                                <div>
                                  <Button
                                    variant='contained'
                                    color='secondary'
                                    component='span'
                                    size='small'
                                    className={classes.button}
                                    onClick={this.onAudioUploadDeleted}
                                  >
                                    <CancelIcon className={classes.leftIcon} />
                                    Delete audio message
                                  </Button>
                                </div>
                                <br />
                              </div>
                            ) : (
                              <AudioRecorder
                                onFileUploadComplete={this.audioUploadFinished.bind(
                                  this
                                )}
                                uploadInProgress={this.uploadInProgress.bind(
                                  this
                                )}
                                onFileDeleted={this.onAudioUploadDeleted.bind(
                                  this
                                )}
                              />
                            )}
                          </Collapse>
                        )}
                      </div>
                    </div>
                    <br />
                  </div>
                </TabContainer>
              )}
              {tabsvalue === 2 && (
                <TabContainer>
                  <div style={videosAttachment}>
                    <RadioGroup
                      aria-label='VidUploadExternal'
                      name='VidUploadExternal'
                      value={this.state.videovalue}
                      onChange={this.handleVideoChange}
                      style={{ flexDirection: 'row', marginBottom: '10px' }}
                    >
                      <FormControlLabel
                        name='videxternal'
                        value='external'
                        control={<Radio color='primary' />}
                        label='External link'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        name='vidupload'
                        value='upload'
                        control={<Radio color='primary' />}
                        label='Upload video'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        name='videonone'
                        value='videonone'
                        control={<Radio color='primary' />}
                        label='None'
                        labelPlacement='end'
                      />
                    </RadioGroup>
                    <div>
                      {videovalue === 'external' && (
                        <div>
                          <div>
                            <TextField
                              name='videotitle'
                              label='Enter video title'
                              multiline
                              fullWidth
                              margin='normal'
                              InputLabelProps={{
                                shrink: true
                              }}
                              aria-describedby='videotitle'
                              value={this.state.videotitle}
                              onChange={event => {
                                const { value } = event.target
                                this.setState({
                                  videotitle: value
                                })
                              }}
                            />
                          </div>
                          <div style={flexAlignObj}>
                            <div style={flexObjOne}>
                              <TextField
                                name='videoObjurl'
                                type='url'
                                label='Enter video link'
                                multiline
                                fullWidth
                                margin='normal'
                                InputLabelProps={{
                                  shrink: true
                                }}
                                aria-describedby='videoObjurl'
                                value={this.state.videoObjurl}
                                onChange={event => {
                                  const { value } = event.target
                                  this.setState({
                                    videoObjurl: value
                                  })
                                }}
                              />
                            </div>
                            <div style={flexObjTwo}>
                              <Button
                                type='button'
                                variant='contained'
                                color='primary'
                                style={sendBtn}
                                onClick={this.saveObject('videosObj')}
                              >
                                <CheckIcon className={classes.rightIcon} />
                              </Button>
                            </div>
                          </div>
                          <div>
                            {videosObj &&
                              Object.keys(videosObj).map(video => {
                                return (
                                  <Chip
                                    icon={<LinkIcon />}
                                    label={videosObj[video].title}
                                    onClick={this.handleLinkClick(
                                      videosObj[video].url
                                    )}
                                    onDelete={() => this.handleLinkDelete(
                                      video,
                                      'videosObj'
                                    )}
                                    className={classes.chip}
                                    key={video}
                                  />
                                )
                              })}
                          </div>
                        </div>
                      )}
                      {videovalue === 'upload' && (
                        <Collapse in={videovalue === 'upload'}>
                          {videouploaded ? (
                            <div>
                              <ReactPlayer
                                height= '270px'
                                width= '100%'
                                playsInline
                                url={videourl}
                                controls={true}
                              />
                              <br />
                              <Button
                                variant='contained'
                                color='secondary'
                                component='span'
                                size='small'
                                className={classes.button}
                                onClick={this.onVideoUploadDeleted}
                              >
                                <CancelIcon className={classes.leftIcon} />
                                Delete Video
                              </Button>
                              <br />
                            </div>
                          ) : (
                            <UploadFiles
                              onUploadComplete={this.videoUploadFinished.bind(
                                this
                              )}
                              onUploadProgress={this.uploadInProgress.bind(
                                this
                              )}
                              uploadtype='video'
                            />
                          )}
                        </Collapse>
                      )}
                    </div>
                  </div>
                </TabContainer>
              )}
              {tabsvalue === 3 && (
                <TabContainer>
                  {imageuploaded ? (
                    <div style={{ padding: '8px' }}>
                      <CardMedia className={classes.media} image={imageurl} />
                      <Button
                        variant='contained'
                        color='secondary'
                        component='span'
                        size='small'
                        className={classes.button}
                        onClick={this.onImageUploadDeleted}
                      >
                        <CancelIcon className={classes.leftIcon} />
                        Delete Image
                      </Button>
                    </div>
                  ) : (
                    <div style={{ padding: '8px' }}>
                      <UploadFiles
                        onUploadComplete={this.imageUploadFinished.bind(this)}
                        onUploadProgress={this.uploadInProgress.bind(this)}
                        uploadtype='image'
                      />
                    </div>
                  )}
                </TabContainer>
              )}
              {tabsvalue === 4 && (
                <TabContainer>
                  {/* {fileuploaded ? ( */}
                  <div style={{ padding: '8px' }}>
                    {filesObj &&
                      Object.keys(filesObj).map(file => {
                        return (
                          <Chip
                            icon={<AttachFileIcon />}
                            label={filesObj[file].title}
                            onClick={this.handleLinkClick(
                              filesObj[file].url
                            )}
                            onDelete={() => this.handleLinkDelete(
                              file,
                              'filesObj'
                            )}
                            className={classes.chip}
                            key={file}
                          />
                        )
                      })}
                  </div>
                  <div style={{ padding: '8px' }}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={12}><Typography variant='caption'>Supported formats - .pdf or .docx files</Typography></Grid>
                        <UploadFiles
                          onUploadComplete={this.fileUploadFinished.bind(this)}
                          onUploadProgress={this.uploadInProgress.bind(this)}
                          uploadtype='file'
                        />
                      </Grid>
                    </Grid>
                  </div>
                </TabContainer>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <br />
      </React.Fragment>
    )
  }
}

ThoughtsAttachments.propTypes = {
  classes: PropTypes.object.isRequired
}

const linksAttachment = {
  border: `2px solid ${LIGHTBLUE}`,
  padding: '0 8px'
}
const videosAttachment = {
  border: `2px solid ${DARK}`,
  padding: '0 8px',
  transition: 'height 2s',
  WebkitTransition: 'height 2s'
}

const podcastsAttachment = {
  border: `1px solid ${GREEN}`,
  padding: '0 8px',
  transition: 'height 2s',
  WebkitTransition: 'height 2s'
}
const flexAlignObj = {
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center'
}
const flexObjOne = {
  flexGrow: 9
}
const flexObjTwo = {
  flexGrow: 1
}
const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: '#fff'
}

const ThoughtsAttachmentsStyled = withStyles(styles)(ThoughtsAttachments)

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params))
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ThoughtsAttachmentsStyled)
