import { SIMPLE_VAL2 } from "../actions/types";

const initState = {
  sVal2: false
};

const simpleValReducer2 = (state = initState, action) => {
  console.log('green token reducer RE : ', action.s_action2);
  switch (action.type) {
    case SIMPLE_VAL2:
      return {
        ...state,
        type: SIMPLE_VAL2,
        sVal2: action.s_action2
      };

    default:
      return state;
  }
};
export default simpleValReducer2;