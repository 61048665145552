/* eslint-disable no-inline-comments */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { GREY, RED, LIGHTBLUE, LIGHTGREY } from '../../utils/color_utils'
import {
  SHOW_TOAST,
  STORE_SUBCOMPETENCYID,
  APP_TYPE
} from '../../store/actions/types'
import CompSelect from '../../components/categories/compSelect'
import SubCompSelect from '../../components/categories/subcompSelect'
import { storeSubCompetency } from '../../store/actions/subcompActions'
import { showToast, clearStates } from '../../store/actions/toastAction'
import { VALUES_NAME } from '../../store/actions/types'
import AccountBalance from '@material-ui/icons/AccountBalanceOutlined'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  titleColor: {
    color: `${LIGHTBLUE}`,
    fontSze: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  divStyle: {
    border: `1px solid ${LIGHTGREY}!important;`,
    margin: '0 14px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 4px',
      padding: '0px 6px'
    }
  },
  dialogHeight: {
    maxHeight: `calc(100% - 20px)`
  },
  cats: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  note: {
    color: 'red',
    justifyContent: 'center',
    alignItems: 'center',
    fontSze: '1.25rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  panel: {
    'marginBottom': '10px',
    'border': '1px solid rgba(0, 0, 0, 0.12)',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)'
    }
  }
})
class CategoriesSelect extends React.Component {
  constructor(props) {
    super(props)
    console.log('blackish props for CategoriesSelect: ', this.props);
  }

  state = {
    competency: '',
    competencyid: '',
    subcompetency: '',
    subcompetencyid: '',
    dailyThoughtType: this.props.dailyThoughtT
  };

  componentWillMount() {
    if (this.props.competencyid) {
      this.setState({ competencyid: this.props.competencyid })
    }

    if (this.props.subcompetencyid) {
      this.setState({ subcompetencyid: this.props.subcompetencyid })
    }
  }

  componentDidMount() {
    if (this.props.competencyid) {
      this.setState({ competencyid: this.props.competencyid })
    }

    if (this.props.subcompetencyid) {
      this.setState({ subcompetencyid: this.props.subcompetencyid })
    }
  }

  handleCompetencyChange = (id, name) => {
    console.log('blackish inside handleCompetencyChange', id)

    this.setState({ competencyid: id, competency: name })
    this.props.doCompState(name, id, 'competency')
    // if (this.props.pldp) {
    //   this.props.doCompState(name, id, "competency");
    // }
    //  else {
    //   this.props.doCompState(name, id);
    // }
  }

  handleSubCompetencyChange = (id, name) => {
    console.log('blackish inside handleSubCompetencyChange', id)

    this.setState({ subcompetency: name })
    this.setState({ subcompetencyid: id })
    this.props.doCompState(name, id, 'subcompetency')
    // if (this.props.pldp) {
    //   this.props.doCompState(name, id, "subcompetency");
    // }
    // else {
    //   this.props.doCompState(name, id);
    // }

    // // console.log('categoriesSelect redux: ', name);

    // if (this.props.keepsubcomp === true) {
    // Redux add
    // var params = {
    //   type: STORE_SUBCOMPETENCYID,
    //   cvalue: id,
    //   scompname: name
    // };
    // this.props.storeSubCompetency(params);
    // }
  }

  render() {
    const { classes, opendialog, fullScreen } = this.props
    console.log('categoriesSelect: ', this.props)

    return (
      <div>
        <div style={{ width: '100%' }}>
          {this.props.pldp ?
            <ExpansionPanelDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CompSelect
                    competencyid={this.state.competencyid}
                    pldp={this.props.pldp} handleCompetency={this.handleCompetencyChange.bind(this)}
                    companyID={this.props.companyID}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SubCompSelect
                    subcompetencyid={this.state.subcompetencyid}
                    pldp={this.props.pldp}
                    handleSubCompetency={this.handleSubCompetencyChange.bind(this)}
                    competencyid={this.state.competencyid}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
            : <ExpansionPanel className={classes.panel}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AccountBalance style={{ color: '#ccbc74', paddingRight: 10, fontSize: 30 }}/>
                  <Typography className={classes.heading}>
                    {VALUES_NAME[`${this.props.companyID}`] ? VALUES_NAME[`${this.props.companyID}`] : VALUES_NAME.other}: {this.props.keepsubcomp === true ? this.props.scompname : this.state.subcompetency}
                  </Typography>
                </div>
              </ExpansionPanelSummary>
              {/* {this.props.companyID==='-LjW7UI7e0uUikYrw-Vj'? <div align="center"
            className={classes.note}>
              <b>The following competencies have been disabled and will be deleted on the 25th of July 2021: </b><br/>
              Techniques of high performance,
              My verbal affirmations,
              My goals,
              My vision,
              My purpose,
              My top 5 values,
              influence.<br/>
              <b>If you have posted anything under the above mentioned, please re-select the competencies.</b>
            </div> : null} */}
              <ExpansionPanelDetails>
                <Typography>
                  <div className={classes.cats}>
                    <CompSelect
                      competencyid={this.state.competencyid}
                      handleCompetency={this.handleCompetencyChange.bind(this)}
                      companyID={this.props.companyID}
                      dailyThoughtType={this.state.dailyThoughtType}
                    />
                    <SubCompSelect
                      subcompetencyid={this.state.subcompetencyid}
                      handleSubCompetency={this.handleSubCompetencyChange.bind(this)}
                      competencyid={this.state.competencyid}
                    />
                  </div>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>}

        </div>

      </div>
    )
  }
}

CategoriesSelect.propTypes = {
  pldp: PropTypes.bool
}

let CategoriesStyled = withStyles(styles)(CategoriesSelect)

const mapStateToProps = state => {
  return {
    subcompFilter: state.subcomp.cvalue, // subcategory id
    fscompname: state.scompname.scompname // subcategory name
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    storeSubCompetency: params => dispatch(storeSubCompetency(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesStyled)
