import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, Row, Col } from 'react-grid-system';
import { showToast } from "../../store/actions/toastAction";
import { GREY, RED, LIGHTBLUE, LIGHTGREY, MAROONHOVER } from "../../utils/color_utils";
import Test from "./Test";
// import AlarmIcon from '@material-ui/icons/MoneySharp';
import ShareIcon from "@material-ui/icons/StarSharp";
import Icon from 'supercons';

// const sendBtn = {
//   backgroundColor: LIGHTBLUE,
//   color: "#fff"
// };
// const sendBtn2 = {
//   backgroundColor: RED,
//   color: "#fff"
// };
const table = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #CAC9C8",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 7
}
const tableh = {
color: "#515A5A",
fontSize: 14,
border: "1px solid #707B7C",
backgroundColor: '#F2F3F4',
padding: 10,
borderRadius: 15
}
const tableM = {
color: "#515A5A",
fontSize: 18,
border: "1px solid #707B7C",
backgroundColor: '#F2F3F4',
padding: 1,
borderRadius: 15
}

const buttonStyle = {
  backgroundColor: RED,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const hoverbuttonStyle = {
  backgroundColor: MAROONHOVER,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const styles = {
mlist: {
  width: 250,
  // backgroundColor: 'red',
},
survs: {
  width: 1500,
  // backgroundColor: 'blue',
},
row2: {
//     backgroundColor: 'green',
},
col1: {
  backgroundColor: 'yellow',
},
col2: {
  backgroundColor: 'brown',
},
col: {
  padding: '1rem',
  backgroundColor: '#33b5e5',
  border: '2px solid #fff',
  color: '#fff',
  textAlign: 'center'
  }
};

export default function DoTest(props) {
  const [open, setOpen] = React.useState(false);
  var showtheTest = props.size;
  console.log('Dotest props: ', props);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosex = (thoughtID, userID, publisherID, real_companyID, companyID) => {
    //props.resetTest(thoughtID, userID, props.publisherid);
    props.resetTest(
      thoughtID, 
      userID, 
      publisherID,
      real_companyID, 
      companyID
      );
    props.getSurveyStage(thoughtID);
    console.log('handleClose is running');
    setOpen(false);
  };
  return (
    <div>
      {/* <IconButton color="secondary" aria-label="add an alarm" onClick={handleClickOpen}>
        <div style={{ color: 'black', textShadow: '3px 3px 3px gold' }}>
          <Icon glyph="transactions" size={32} />
        </div>
      </IconButton> */}
        <div onClick={handleClickOpen}
          style={{ 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: 'black', 
            textShadow: '3px 3px 3px gold'
            }}
            >
          <ShareIcon />
        </div>
      {/* <Dialog open={open} onClose={handleClose()} aria-labelledby="form-dialog-title" */}
      <Dialog open={open} aria-labelledby="form-dialog-title"
           style={{
            // top: '-10px',
            // width: '500px',
            // maxWidth: '500px',
            // height: '500px',
            // align: 'center'
            top: '-15px',
            width: '100%',
            maxWidth: '100%',
            height: '700px'
          }}
             >
        <DialogContent
              //  style={{
              //   width: '500px',
              //   maxWidth: '500px',
              //   height: '500px',
              //   align: 'center'
              // }}
              >
          <DialogContentText
              //    style={{
              //     width: '500px',
              //     maxWidth: '500px',
              //     height: '500px',
              //     align: 'center'
              //  }}
               >
              <Test 
                thoughtID={props.thoughtID}
                companyID={props.companyID}
                real_companyID={props.real_companyID}
                userID={props.userID}
                surveyName={props.surveyName}
                size={props.size}
                showLeadb={props.showLeadb}
                // thought={props.thought}
                //resetTest={props.resetTest}
                //getSurveyStage={props.getSurveyStage}
                publisherid={props.publisherid}
                thoughtType={props.thoughtType}
                title={props.title}
                subtitle={props.subtitle}
              />
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClosex(
            props.thoughtID, 
            props.userID, 
            props.publisherid, 
            props.real_companyID, 
            props.companyID
            )} color="primary">
          {/* <Button onClick={props.resetTest(props.thoughtID, props.userID)} color="primary"> */}
          {/* <Button onClick={() => this.resetTest(props.thoughtID, props.userID)} color="primary"> */}

            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

