import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ReactDOM from 'react-dom';
import './index.css';
import BigCalendar from "react-big-calendar-like-google";
import moment, { now } from "moment";
import {
  Typography, Grid, Button
} from '@material-ui/core'
import SubjectIcon from '@material-ui/icons/Subject';
import RoomIcon from '@material-ui/icons/Room';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import EventIcon from '@material-ui/icons/Event';
import CloseIcon from '@material-ui/icons/Close';
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
import Modal from "react-modal";
// import { DatePicker, RangeDatePicker } from "@y0c/react-datepicker";
// import "@y0c/react-datepicker/assets/styles/calendar.scss";
import "dayjs/locale/ko";
import "dayjs/locale/en";
import { getGroupEvents } from "../../store/actions/companyAdminActions";

const Panel = ({ header, children }) => (
  <div style={{ height: "300px" }}>
    <h1>{header}</h1>
    <div>{children}</div>
  </div>
);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

moment.locale("en");
BigCalendar.momentLocalizer(moment);

const allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

const style = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.66)",
    zIndex: 10000,
    width: '100%',
    height: 'auto'
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    margin: "0 auto",
    bottom: 'auto',
    maxWidth: '500px',
    height: 'auto'
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

Modal.setAppElement("#root");

class Calendar extends Component {
  constructor () {
    super();
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  state = {
    date: new Date(moment().format('YYYY'), moment().format('M'), moment().format('D')),
    view: "month",
    myyear: moment().format('YYYY'),
    mymonth: moment().format('M'),
    myday: moment().format('D'),
    width: 700,
    modalIsOpen: false,
    eventdayStart: 0,
    eventdayEnd: 0,
    eventtitle: "",
    eventdesc: "",
    eventlocation: "",
    eventgroup_id: "",
    eventgroup_name: "",
  };

  openModal(myday) {
    // var tday = myday.toString();
    // var sDay = new Date(tday);
    // var dstart = myday.start.getDate().toString();
    // var dend = myday.end.getDate().toString();
    var dstart = myday.start.toString();
    var dend = myday.end.toString();

    if (this.state.view === "month") {
      this.setState({
        modalIsOpen: true,
        eventtitle: myday.title,
        eventdayStart: dstart,
        eventdayEnd: dend,
        eventdesc: myday.desc,
        eventlocation: myday.location,
        eventgroup_id: myday.group_id,
        eventgroup_name: myday.group_name,
      });
    }
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleEventClick = (e) => {
    var tday = e.toString();
    var myDay = new Date(tday);

    // console.log('event clicked!', myDay.getMonth());
    if (this.state.view === "month") {
      this.setState({ myday: myDay.getDate(), mymonth: myDay.getMonth(), view: "day" });
    }
  };

  formatDate = (string) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }

  componentDidMount() {
    this.props.getGroupEvents({
      group_id: this.props.group_id
    })

    window.addEventListener("resize", () => {
      /*this.setState({
        width: window.innerWidth,
        height: window.innerHeight
      });*/
    });
  }

  render() {
    const onChange = title => (...args) => console.log(title, args);
    return (
      <div style={{ height: 700 }}>

        <BigCalendar
          selectable={true}
          events={this.props.group_events}
          //view={["month"]}
          view={this.state.view}
          onView={() => { }}
          date={this.state.date}
          onNavigate={date => this.setState({ date })}
          onSelectEvent={(event) => this.openModal(event)}
        //onSelectEvent={this.handleOpenModal}
        // onSelectSlot={(event) => this.handleEventClick(event.slots, event)}
        />

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="View Event"
          style={style}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1} sm={1}>
                    <ViewQuiltIcon fontSize='small' color='primary' />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Typography variant='body1'>{this.state.eventtitle}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography variant='subtitle1'>{this.state.eventdayStart} -  {this.state.eventdayEnd}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1} sm={1}>
                    <RoomIcon />
                  </Grid>
                  <Grid item xs={11} sm={11} >
                    <Typography variant='subtitle1'>{this.state.eventlocation}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1} sm={1}>
                    <SubjectIcon />
                  </Grid>
                  <Grid item xs={11} sm={11} >
                    <Typography variant='subtitle1'>{this.state.eventdesc}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1} sm={1}>
                    <EventIcon />
                  </Grid>
                  <Grid item xs={11} sm={11} >
                    <Typography variant='caption'>{this.state.eventgroup_name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
                <Button variant='outlined' size='small' onClick={this.closeModal}>Close</Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </div>
    )
  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedGroup: PropTypes.func.isRequired
}

const CalendarStyled = withStyles(styles)(Calendar)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    group_events: state.users.group_events
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getGroupEvents: params => dispatch(getGroupEvents(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarStyled)
