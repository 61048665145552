/* eslint-disable no-dupe-keys */
/* eslint-disable consistent-this */
/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SendIcon from '@material-ui/icons/Send'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Typography from '@material-ui/core/Typography'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Grid
} from '@material-ui/core'
import { LIGHTBLUE } from '../../utils/color_utils';
import {
  APP_TYPE,
  NEW_CORP_USERS,
  NEW_PUBLIC_USERS,
  SHOW_TOAST,
  HARMONY_USER_TYPES,
  IS_HARMONY,
} from '../../store/actions/types';
import { showToast } from '../../store/actions/toastAction'
import {
    getAllCompanyUsers, getAdmin
} from '../../store/actions/companyAdminActions'
import { v3 } from 'uuid'
import {Checkbox} from '@material-ui/core'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
//import { truncate } from 'fs/promises'

const styles = theme => ({
  appBar: {
    position: 'static',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    backgroundColor: '#d51317'
  },
  flex: {
    flex: 1,
    width: 600
  },
  dialog: {},
  submitButton: {
    backgroundColor: '#2196F3 !important'
  },
  button: {
    'margin': theme.spacing.unit,
    'color': '#ffffff',
    'backgroundColor': '#0288d1',
    '&:hover': {
      backgroundColor: '#0288d1'
    },
  },
    deadButton: {
      'margin': theme.spacing.unit,
      'color': 'yello',
      'backgroundColor': '#eeeeee',
      'cursor': 'not-allowed',
      'pointerEvents': 'none'
  },
})

const Transition = props =>
  window.innerWidth >= 425 ? (
    <Fade checkIn={true} {...props} />
  ) : (
      <Slide direction='left' {...props} />
    )

class AddTokensDialog extends React.Component {
  state = {
    open: false,
    scroll: 'paper',
    userSelected: '',
    firstName: '',
    lastName: '',
    email: '',
    amount: 1,
    tokenType: '',
    tokenID: '',
    personID: '',
    personName: '',
    personLastName: '',
    lockButton: false
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };

  selectTokenProps = event => {
    // -MYnqWWpV4pznTXDX-Ar | Boulate / Octopus : paula.paulatek@gmail.com 
   // -MYnqWWpV4pznTXDX-Ar|Boulate/Octopus:paula.paulatek@gmail.com
    var str = event.target.value;
    var pid = str.substring(0, str.indexOf("|"));  //str.split("|").pop();
    //    |/:
    var pname = str.split('|').pop().split('/')[0];
    var lname = str.split('/').pop().split(';')[0];
      //////////////////////////////////////
      //  var char1 = str.indexOf('/') + 1;
      //  var char2 = str.lastIndexOf(';');
      //  var plname = str.substring(char1, char2);

      //  var lname = str.substring(
      //   str.lastIndexOf("/") + 1, 
      //   str.lastIndexOf(";")
      // );

      // let substring = getSubstring(originalString, ',', '!');
      /////////////////////////////////////////
    var n = str.lastIndexOf(';');
    var pemail = str.substring(n + 1);

    console.log('selectTokenProps : ', event.target.value)
     this.setState({
      personID: pid,
      personName: pname,
      personLastName: lname, 
      personEmail: pemail
     })
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll })
  };

  handleChangeUser = event => {
    console.log('green handleChangeUser 1 : ', event.target.value)
    let text = event.target.value;
    const chars = text.split("|");

    this.setState({ 
      userID: chars[0], 
      firstName: chars[1], 
      lastName: chars[2], 
      email: chars[3]
    })

  };

  handleClose = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
    })
  };

  closeDialog = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
    })
    this.props.onOpenAddUserDialog()
  };


// renderUsers = () => {
//     let { users } = this.props
   
//     return Object.keys(users).map((v, i) => (
//         <MenuItem key={users[v].userID} value={users[v].userID + '|' + users[v].firstName + '/' + users[v].lastName + ';' + users[v].email} >
//         {users[v].firstName + ' ' + users[v].lastName }
//       </MenuItem>
//     ))
 // };


  handleDemo = event => {
    this.setState({
      demoOn: event.target.checked
    })
  };

  componentDidMount() {
    let { classes, userdata, getAllCompanyUsers} = this.props
    console.log('request admin : ', userdata)
    // getAdmin({
    //    //companyID_userType: userdata.companyID + '_7'
    //    appOwner: true
    //   })
    // getAllCompanyUsers({
    //     companyID: userdata.companyID,
    //     orderChild: 'companyID'
    //   })
  }

  componentDidUpdate() {

   
  }
   preSubmit = () => {
    const { onSubmitUserClick} = this.props
    this.setState({ lockButton: true })
     onSubmitUserClick({
          tokenType:this.state.tokenType,
          amount: this.state.amount
      });

   }

  render() {
    const { classes, theme, onSubmitUserClick, fullScreen, userdata} = this.props

    const {
      usertype,
      firstName,
      lastName,
      email
    } = this.state
   //console.log('green createToken usersData A: ', adminAcc);

    let self = this
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          fullScreen={fullScreen}
          fullWidth
          maxWidth='sm'
          TransitionComponent={Transition}
          aria-labelledby='scroll-dialog-title'
        >
          {/* <div> */}
          <DialogTitle id='alert-dialog-title'>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
              <PersonAddIcon
                fontSize='small'
                style={{ color: `${LIGHTBLUE}` }}
              />
              <span
                style={{
                  color: `${LIGHTBLUE}`,
                  fontWeight: 'bold',
                  fontSize: '15px'
                }}
              >
                &nbsp;Request Your Token
              </span>
            {/* </div> */}
          </DialogTitle>
          {/* </div>
          <div> */}
          <DialogContent>
            <form autoComplete="off">
            {/* <Grid item xs={12} sm={6}> */}
               <FormControl fullWidth>
                    <InputLabel htmlFor='tokenType'>Select Token Type</InputLabel>
                    <Select
                      value={this.state.tokenType}
                      onChange={e => this.handleChange(e, 'tokenType')}
                      inputProps={{
                        name: 'tokenType',
                        id: 'tokenType'
                      }}
                    >
                      {/* //CALF PRODUCTS MODIFY HERE */}
                     <MenuItem key={'app'} value={'app'} >
                       {isMobile? 'Executive Club Ownership $85/month': 'Executive Club Membership & Ownership $85/month'}
                     </MenuItem>
                      <MenuItem key={'ex1'} value={'ex1'} >
                       {'Executive Club Membership 1: $28/month'}
                     </MenuItem>    
                     <MenuItem key={'ex2'} value={'ex2'} >
                       {'Executive Club Membership 2: $12.99/month'}
                     </MenuItem>                   

                    </Select>
                  </FormControl>
                {/* </Grid>   */}

            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              disabled={!this.state.tokenType}
              // onClick={e => onSubmitUserClick({
              //   tokenType:this.state.tokenType,
              //   amount: this.state.amount
              // })}
              onClick={e => this.preSubmit({
                tokenType:this.state.tokenType,
                amount: this.state.amount
              })}
              //className={classes.button }
              className={this.state.lockButton===true ? classes.deadButton :classes.button }
              size='large'
            >
              <SendIcon />
              Request Token
            </Button>

            {/* <Button onClick={this.closeDialog}>Close</Button> */}
            <Button onClick={this.closeDialog}>Close</Button>
          </DialogActions>
        </Dialog>
        {/* </div> */}
      </div>
    )
  }
}

// const AddTokensDialog = withMobileDialog({ breakpoint: 'xs' })(
//     AddTokensDialog
// )

const AddTokensDialogStyled = withStyles(styles, { withTheme: true })(AddTokensDialog)


const mapStateToProps = state => {
  return {
    //users: state.users.users,
   // adminAcc: state.users.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
   // getAllCompanyUsers: params => dispatch(getAllCompanyUsers(params)),
   // getAdmin: params => dispatch(getAdmin(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTokensDialogStyled);
