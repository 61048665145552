import gql from 'graphql-tag';
// 1. ---- USERS ---- //
const QUERY_SEARCH_USERS = gql`
	query($searchQuery: String) {
		listUsers(filter: { contains: $searchQuery }, limit: 10) {
			users {
				firstName
				lastName
				email
			}
		}
	}
`;
const QUERY_LIST_USERS = gql`
	query {
		listUsers(limit: 10) {
			users {
				firstName
				lastName
				email
			}
		}
	}
`;

// 2. ---- THOUGHTS ---- //
const QUERY_SEARCH_THOUGHTS = gql`
	query($searchQuery: String) {
		listThoughts(filter: { contains: $searchQuery }, limit: 10) {
			thoughts {
				dailyThoughtID
				title
				subtitle
				journalUserName
				dailyThoughtDescription
				slogan
				companyName
				stringDateRegistered
				photoURL
			}
		}
	}
`;
const QUERY_LIST_THOUGHTS = gql`
	query {
		listThoughts(limit: 10) {
			thoughts {
				dailyThoughtID
				title
				subtitle
				journalUserName
				dailyThoughtDescription
				slogan
				companyName
				stringDateRegistered
				photoURL
			}
		}
	}
`;
// 3. ---- VIDEOS ---- //
const QUERY_SEARCH_VIDEOS = gql`
	query($searchQuery: String) {
		listVideos(filter: { contains: $searchQuery }, limit: 10) {
			videos {
				videoID
				userName
				description
				companyName
			}
		}
	}
`;
const QUERY_LIST_VIDEOS = gql`
	query {
		listVideos(limit: 10) {
			videos {
				videoID
				userName
				description
				companyName
			}
		}
	}
`;

// 4. ---- ARTICLES ---- //
const QUERY_SEARCH_ARTICLES = gql`
	query($searchQuery: String) {
		listArticles(filter: { contains: $searchQuery }, limit: 10) {
			articles {
				newsID
				articleTypeDescription
				dailyThoughtDescription
				body
				companyName
				journalUserName
				title
				subtitle
				stringDateRegistered
			}
		}
	}
`;
const QUERY_LIST_ARTICLES = gql`
	query {
		listArticles(limit: 10) {
			articles {
				newsID
				articleTypeDescription
				dailyThoughtDescription
				body
				companyName
				journalUserName
				title
				subtitle
				stringDateRegistered
			}
		}
	}
`;

// 5. ---- PODCASTS ---- //
const QUERY_SEARCH_PODCASTS = gql`
	query($searchQuery: String) {
		listPodcasts(filter: { contains: $searchQuery }, limit: 10) {
			podcasts {
				title
				podcastID
				photoURL
				companyName
				userName
			}
		}
	}
`;
const QUERY_LIST_PODCASTS = gql`
	query {
		listPodcasts(limit: 10) {
			podcasts {
				title
				podcastID
				photoURL
				companyName
				userName
			}
		}
	}
`;

export {
	// users
	QUERY_SEARCH_USERS,
	QUERY_LIST_USERS,
	// thoughts
	QUERY_SEARCH_THOUGHTS,
	QUERY_LIST_THOUGHTS,
	// videos
	QUERY_SEARCH_VIDEOS,
	QUERY_LIST_VIDEOS,
	// articles
	QUERY_SEARCH_ARTICLES,
	QUERY_LIST_ARTICLES,
	// podcasts
	QUERY_SEARCH_PODCASTS,
	QUERY_LIST_PODCASTS
};
