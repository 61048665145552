/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */
import React from 'react'
import { toast } from 'react-toastify'
import About from '../../components/LandingPage/About'
import LoaderBar from '../../components/LoaderBar'
import { hideLoader, showLoader } from '../../store/actions/loaderActions'
import { BASE_API_URL } from '../../store/actions/types'
import { connect } from 'react-redux'
import axios from 'axios'
import { showToast } from '../../store/actions/toastAction'
import { loggedInProfileActions2 } from '../../store/actions/loggedInProfileActions'
import { authStateChanged } from '../../store/actions/authActions'

class LandingPage extends React.Component {
  state = {
    loading: false
  };

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const token = query.get('login_token')
    if (token) {
      this.setState({ loading: true })
      axios
        .post(`${BASE_API_URL}/v1/integration/validate`, { login_token: token })
        .then(async (res) => {
          console.log('token vfvff', res.data)
          const { data } = res.data
          let userobj = {
            uid: data.uid,
            displayName: `${data.firstName} ${data.lastName}`,
            email: data.email,
            emailVerified: true
          }
          localStorage.setItem('USER_LOGGED_IN', JSON.stringify(userobj))
          this.props.authStateChanged(userobj)
          this.props.loggedProfileActions(data.uid)
          await this.props.updateLoginStatus()
        })
        .catch(() => {
          this.setState({ loading: false })
          toast.error('Login Token invalid or expired!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light'
          })
        })
    }
  }
  render() {
    return (
      <div style={{ backgroundColor: '#000', height: '100vh' }}>
        {this.state.loading ? (
          <LoaderBar showprogress={this.state.loading} />
        ) : (
          <About />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showprogress: state.loader.showloader
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (params) => dispatch(showLoader(params)),
    hideLoader: (params) => dispatch(hideLoader(params)),
    showToast: (params) => dispatch(showToast(params)),
    loggedProfileActions: (params) => dispatch(loggedInProfileActions2(params)),
    authStateChanged: (params) => dispatch(authStateChanged(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
