/* eslint-disable max-len */
/* eslint-disable no-undefined */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import firebase from '../../store/firebase'

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Grid,
  Chip
} from '@material-ui/core'

const styles = {
  root: {
    '&>div': {
      '&:hover': {
        borderBottom: '1px solid red'
      }
    }
  },
  formControl: {
    'width': '100%',
    '& label span': {
      color: 'red'
    }
  },
  searchForm_submit_button: {
    'background': '#e40000',
    'borderRadius': 0,
    'color': '#FFF',
    'fontSize': 20,
    'fontWeight': 100,
    '&:hover': {
      background: '#a30000',
      borderColor: '#a30000'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}

class Groupoptions extends React.Component {
  constructor(props) {
    super(props)
    // console.log("groupoptions.js props: ", this.props);
  }

  state = {
    gr: '',
    group_id: '',
    group_name: 'Select Group',
    selectedgroups: [],
    open: false
  };

  handleChangeMultiple = (event) => {
    const { value } = event.target
    let selectedgroups = []

    for (let i = 0, l = value.length; i < l; i = i + 1) {
      if (value[i] !== undefined) {
        selectedgroups.push(value[i])
      }
    }

    this.setState({
      selectedgroups,
      open: false
    })

    this.props.handleInput(selectedgroups, 'multiplegroups')
  };

  handleClose = () => {
    this.setState({
      open: false
    })
  };

  handleOpen = () => {
    this.setState({
      open: true
    })
  };

  componentDidMount() {
    let database = firebase.database
    let ref = 'company_user_groups/' + this.props.userdata.userID

    if (this.props.userdata.userType === 7 || this.props.userdata.userType === 14) {
      ref = 'company_groups/' + this.props.userdata.companyID
    }

    // if (this.props.editing) {
    //   this.setState({
    //     group_id: this.props.groupid,
    //     group_name: this.props.groupname,
    //   })
    // }

    database
      .ref(ref)
      .once('value')
      .then(snapshot => {
        const groupsArr = []
        snapshot.forEach(childSnapshot => {
          if (childSnapshot.val().active === true || childSnapshot.val().active === undefined) {
            groupsArr.push({
              id: childSnapshot.key,
              group_id: childSnapshot.val().group_id,
              group_name: childSnapshot.val().group_name
            })
          }
        })
        this.setState({ gr: groupsArr })
      })
  }

  render() {
    const style = { color: 'red' }

    const {
      form: { groups, multiplegroups },
      handleInput,
      classes
    } = this.props

    const { open } = this.state

    return (
      <div>
        <Grid item sm={12}>
          <form className='' noValidate autoComplete='off'>
            <FormControl required className={classes.formControl}>
              <InputLabel shrink htmlFor='groups'>
                My Groups
              </InputLabel>
              {!this.props.editing && (this.props.userdata.userType === 7 || this.props.userdata.userType === 14) ?
                <Select
                  labelId='demo-mutiple-chip-label'
                  id='demo-mutiple-chip'
                  multiple
                  open={open}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  value={this.state.selectedgroups}
                  onChange={event => this.handleChangeMultiple(event)}
                  input={<Input />}
                >
                  {this.state.gr
                    ? this.state.gr.map(item => {
                      return (
                        <MenuItem key={item.group_name} value={item}>
                          {item.group_name}
                        </MenuItem>
                      )
                    })
                    : null}
                </Select>
                :
                <Select
                  value={groups}
                  displayEmpty
                  onChange={event => handleInput(event, 'groups')}
                  input={<Input name='groups' id='groups' />}
                >
                  <MenuItem key={this.state.group_name} value={this.state.group_id}>
                    <em>{this.state.group_name}</em>
                  </MenuItem>
                  {this.state.gr
                    ? this.state.gr.map(item => {
                      return (
                        <MenuItem key={item.group_name} value={item}>{item.group_name}</MenuItem>
                      )
                    })
                    : null}
                </Select>
              }
            </FormControl>
          </form>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Groupoptions)

