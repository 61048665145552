/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undefined */

import { EuroSymbol } from "@material-ui/icons"

/* eslint-disable no-underscore-dangle */
export const _toArray = obj => {
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {
      obj[key].id = key
      return obj[key]
    })
  }
}

export const _toArrayGroups = obj => {
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {
      obj[key].id = key

      if (obj[key].active === undefined || obj[key].active === null) {
        obj[key].active = true
      }
      return obj[key]
    })
  }
}

export const _toArrayCalendar = obj => {
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {
      obj[key].title = obj[key].event_title
      obj[key].start = new Date(obj[key].event_start_epoch)
      obj[key].end = new Date(obj[key].event_end_epoch)
      obj[key].desc = obj[key].event_description
      obj[key].location = obj[key].event_location
      obj[key].group_id = obj[key].group_id
      obj[key].group_name = obj[key].group_name
      return obj[key]
    })
  }
}

export const _toArrayUsersx = obj => {

  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {
      obj[key].id = key

      if (obj[key].disabled === undefined || obj[key].disabled === null) {
        obj[key].disabled = false
      }

      return obj[key]
    })
  }
}

function processEachChar(_string) {
  let finalString = '';
  var mainStr = _string;
  var lenMainStr = mainStr.length;

  //company, country, position, sector
  var i = 0;
  var u = 0;
  var x = 0;
  var arrFields = [];
  let fcompanyID = '';
  let fcountry = '';
  let fposition = '';
  let fsector  = '';

  for (let charAtIndex of _string) {
      u = u + 1;
    if (charAtIndex === '=') {
      i = i + 1;
     // console.log('green finalString : ', finalString)
     finalString = _string.slice(x, u-1)
      x = u;
     console.log('green finalString : ', finalString)
      switch(i) {
        case 1:
          fcompanyID = finalString; //fcompanyID + charAtIndex // finalString.substr(1, _string.length - 1) ;
          break;
        case 2:
          fcountry = finalString // fcountry + charAtIndex;
          break;
        case 3:
          fposition = finalString // fposition + charAtIndex;
          fsector = mainStr.substr(u, _string.length - u)
          //fsector = mainStr.substr(45, 9)

          console.log('green finalString fposition: ', fposition)
          console.log('green finalString fsector: ', fsector)
          console.log('green finalString u: ', u)
          console.log('green finalString _string.length - u: ', _string.length - u)
          console.log('green finalString _string: ', _string)
          break;
        // case 4:
        //   fsector = finalString // fsector + charAtIndex;
        //   break;
        default:
      }
      console.log('green fcompanyID : ', fcompanyID)
      finalString = finalString + charAtIndex;
    }
    else
    {
      //DO NOTHING
    }
  }
  arrFields = [
    fcompanyID,
    fcountry,
    fposition,
    fsector
  ]
  console.log('green arrFields : ', fcompanyID + ' ' + fcountry + ' ' + fposition + ' ' + fsector)

  return arrFields;
}

function cleanP_backup(obj, ScompanyID) {
  //var arrFields = [];
  var objDeleted = false;

  for (var propName in obj) {
    var objDeleted = false;
   //companyID
   if (objDeleted === false) {
    if (obj[propName].companyID) {
      if (obj[propName].companyID !== ScompanyID && ScompanyID !== 'x') {
        delete obj[propName];
        objDeleted = true;
      }
    }
    else{
      delete obj[propName];
      objDeleted = true;
    }
  }
   //points
    if (objDeleted === false) {
      if (obj[propName].points) {
        if (obj[propName].points < 1) {
          delete obj[propName];
          objDeleted = true;
        }
      }
      else {
        delete obj[propName];
        objDeleted = true;
      }   
   }     
  }
  return obj
}

function cleanP(obj, ScompanyID) {
  //var arrFields = [];
  var objDeleted = false;

  for (var propName in obj) {
    var objDeleted = false;
    
   //companyID
   if (objDeleted === false) {
    if (obj[propName].companyID) {
      if (obj[propName].companyID !== ScompanyID && ScompanyID !== 'x') {
        delete obj[propName];
        objDeleted = true;
      }
    }
    else{
      delete obj[propName];
      objDeleted = true;
    }
  }
    //points
    if (objDeleted === false) {
      obj[propName].pointsAll = obj[propName].points + obj[propName].pointsCalf
      if (obj[propName].pointsAll) {
        if (obj[propName].pointsAll < 1) {
          delete obj[propName];
          objDeleted = true;
        }
      }
      else {
        delete obj[propName];
        objDeleted = true;
      }   
   }     
  }
  return obj
}

function XcleanL(obj, lkey) {
  var arrFields = [];
  var objDeleted = false;
  
  arrFields = processEachChar(lkey);

  console.log('green cleanL : ', arrFields)

  let fcompanyID = arrFields[0];
  let fcountry = arrFields[1];
  let fposition = arrFields[2];
  let fsector  = arrFields[3];

 // console.log('green this.props.leaderboardData U ', obj)

  for (var propName in obj) {
    var objDeleted = false;
   //fcountry--------------------------------------
    try {
      var dept = obj[propName].nationality ;
     // console.log('green this.props.leaderboardData C ', dept)
       if (obj[propName].nationality !== fcountry && fcountry !== 'x') {
        delete obj[propName];
        }   
       }
       catch(err) {
         delete obj[propName];
       }
//fcompany--------------------------------------
  try {
    var dept = obj[propName].companyID ;
    console.log('green this.props.leaderboardData C ', dept)
     if (obj[propName].companyID !== fcompanyID && fcompanyID !== 'x') {
      delete obj[propName];
      }   
     }
     catch(err) {
       delete obj[propName];
     }
   //fposition--------------------------------------
    try {
      var dept = obj[propName].position ;
      console.log('green this.props.leaderboardData C ', dept)
       if (obj[propName].position !== fposition && fposition !== 'x') {
        delete obj[propName];
        }   
       }
       catch(err) {
         delete obj[propName];
       }

   //fsector---------------------------------------------
   console.log('green this.props.leaderboardData G ', obj)
   try {
     var dept = obj[propName].department ;
     console.log('green this.props.leaderboardData C ', dept)
      if (obj[propName].department !== fsector && fsector !== 'x') {
       delete obj[propName];
       }   
      }
      catch(err) {
        delete obj[propName];
      }
    }
    return obj
  } 

  // function cleanY(obj, lkey) {
  //   console.log('green pointsY 1: ', lkey)

  //   var arrFields = [];
  //   var objDeleted = false;
  //   var keepObj = false;
  //   console.log('green pointsY 2: ', lkey);
  
  //   arrFields = processEachChar(lkey);
  //   console.log('green pointsY 3: ', arrFields)
  
  //   let fcompanyID = arrFields[0];
  //   let fcountry = arrFields[1];
  //   let fposition = arrFields[2];
  //   let fsector  = arrFields[3];
  
  //   for (var propName in obj) {
  
  //     objDeleted = false;

  //     // //pointsy
  //     // if (objDeleted === false) { 
  //     //   for (var propName in obj) {
  //     //    if (obj[propName].pointsy === null || obj[propName].pointsy === undefined) {
  //     //      delete obj[propName];
  //     //      objDeleted = true;
  //     //    }
  //     //   }
  //     //  }
  //     // //points
  //     // if (objDeleted === false) { 
  //     //  for (var propName in obj) {
  //     //   if (obj[propName].points === null || obj[propName].points === undefined) {
  //     //     delete obj[propName];
  //     //     objDeleted = true;
  //     //   }
  //     //  }
  //     // }

  //     //fcountry
  //     if (objDeleted === false) { 
  //     if (obj[propName].nationality) {
  //       if (obj[propName].nationality !== fcountry && fcountry !== 'x') {
  //         delete obj[propName];
  //         objDeleted = true;
  //       }
  //       console.log('black L 2 ')
  //     }
  //     else{
  //       delete obj[propName];
  //       objDeleted = true;
  //     }
  //    }
  
  //    //fcompanyID
  //    if (objDeleted === false) {
  //     if (obj[propName].companyID) {
  //       if (obj[propName].companyID !== fcompanyID && fcompanyID !== 'x') {
  //         delete obj[propName];
  //         objDeleted = true;
  //       }
  //       console.log('black L 2')   
  //     }
  //     else{
  //       delete obj[propName];
  //       objDeleted = true;
  //    }   
  //   }
  
  //    //fposition
  //    if (objDeleted === false) {
  //     if (obj[propName].position) {
  //       if (obj[propName].position !== fposition && fposition !== 'x') {
  //         delete obj[propName];
  //         objDeleted = true;
  //       }
  //       console.log('black L 2 ')
  //     }
  //     else{
  //       delete obj[propName];
  //       objDeleted = true;
  //     }
  //    }
  
  //    //fsector
  //    if (objDeleted === false) {
  //     if (obj[propName].department) {
  //       console.log('green fsector 1: ', fsector);
  //       if (obj[propName].department !== fsector && fsector !== 'x') {
  //         delete obj[propName];
  //         objDeleted = true;
  //       }
  //       console.log('black L 2 ')
  //     }
  //     else{
  //       delete obj[propName];
  //       objDeleted = true;
  //     }
  //    }   
  //   }
  //   console.log('black L 10 ')
  //   return obj
  // }
  function cleanY(obj) {
    for (var propName in obj) {
     // console.log('green-getTopLeadersFromProps 4 : ', obj[propName].pointsy);
      if (obj[propName].pointsy === null || obj[propName].pointsy === undefined) {
       // delete obj[propName];
        obj[propName].pointsy = 0;
      }
    }
    return obj
  }

  export const findToken = (obj) => {
    for (var propName in obj) {
      if (obj[propName].isCredit === true) {
        delete obj[propName];
      }
    }
    console.log('greenfindtoken : ', obj)
    return obj
  }

  export const findAppOwner = (obj, companyID) => {
    for (var propName in obj) {
      if (obj[propName].companyID !== companyID) {
        delete obj[propName];
      }
    }
    console.log('greenfindtoken : ', obj)
    return obj
  }

  function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

function cleanL(obj, lkey) {
 
  var arrFields = [];
  var objDeleted = false;
  var keepObj = false;
  console.log('green cleanL lkey: ', lkey);

  arrFields = processEachChar(lkey);
  console.log('green cleanL : ', arrFields)
  //console.log('green cleanL 1: ', obj);

  let fcompanyID = arrFields[0];
  let fcountry = arrFields[1];
  let fposition = arrFields[2];
  let fsector  = arrFields[3];

  console.log('green cleanL fcompanyID 1: ', fcompanyID);
  console.log('green cleanL 2: ', obj);

  for (var propName in obj) {
    objDeleted = false;
    //feedback
      if (obj[propName].feedback >= 0 && isNumber(obj[propName].feedback)) {

      } else {
        //obj[propName].feedback = 'n/a';
        delete obj[propName];
        objDeleted = true;
        // objDeleted = true;
      }

   //country
    if (objDeleted === false) { 
    if (obj[propName].nationality) {
      if (obj[propName].nationality !== fcountry && fcountry !== 'x') {
        delete obj[propName];
        objDeleted = true;
      }
      console.log('black L 2 ')
    }
    else{
      delete obj[propName];
      objDeleted = true;
    }
    console.log('green cleanL 1: ', obj);
   }

   //fcompanyID
   if (objDeleted === false) {
    if (obj[propName].companyID) {
      if (obj[propName].companyID !== fcompanyID && fcompanyID !== 'x') {
        delete obj[propName];
        objDeleted = true;
      }
      console.log('black L 2')   
    }
    else{
      delete obj[propName];
      objDeleted = true;
   }  
   console.log('green cleanL 2: ', obj); 
  }

   //fposition
   if (objDeleted === false) {
    if (obj[propName].position) {
      if (obj[propName].position !== fposition && fposition !== 'x') {
        delete obj[propName];
        objDeleted = true;
      }
      console.log('black L 2 ')
    }
    else{
      delete obj[propName];
      objDeleted = true;
    }
    console.log('green cleanL 3: ', obj);
   }

   //fsector
   if (objDeleted === false) {
    if (obj[propName].department) {
      console.log('green fsector 1: ', fsector);
      if (obj[propName].department !== fsector && fsector !== 'x') {
        delete obj[propName];
        objDeleted = true;
      }
      console.log('black L 2 ')
    }
    else{
      delete obj[propName];
      objDeleted = true;
    }
   }  
   
   console.log('green cleanL 4: ', obj);
  }
  console.log('black L 10 ')
  console.log('green cleanL 5: ', obj);
  return obj
}

function cleanS(obj, userIDin) {
  var objDeleted = false;
  var keepObj = false;
  console.log('green cleanS K : ', userIDin);

  for (var propName in obj) {
    objDeleted = false;
  //  //fcompanyID
  //  if (objDeleted === false) {
  //   if (obj[propName].companyID) {
  //     if (obj[propName].companyIDin !== companyIDin && companyIDin !== 'x') {
  //       delete obj[propName];
  //       objDeleted = true;
  //     }
  //   }
  //   else{
  //     delete obj[propName];
  //     objDeleted = true;
  //  }   
  // }

   //userID
   if (objDeleted === false) {
    if (obj[propName].userID) {
      if (obj[propName].userID !== userIDin && userIDin !== 'x') {
        delete obj[propName];
        objDeleted = true;
      }
    }
    else{
      delete obj[propName];
      objDeleted = true;
    }
   }

  }
  return obj
}

export const _toArrayUsersL = (companyID, obj, lkey, pointsY) => {
  var fkey;
  if (obj !== undefined) {
    console.log('blue lkey ', lkey)
    if (lkey==='xx=x=x=x') {
      console.log('green cleanP V1 ', obj)
      cleanP(obj, companyID)
      console.log('green cleanP v2 ', obj)
    }
    else
    {
      if (pointsY) {
        console.log('green cleanY v1 ', obj)
         cleanY(obj, lkey)
         cleanL(obj, lkey) //YEAR POINTS
         console.log('green cleanY v2 ', obj)
      }
      else
      {
        console.log('green cleanL v1 ', obj)
         cleanL(obj, lkey) //ALL TIME POINTS
         console.log('green cleanL v2 ', obj)
      }
     
      console.log('green this.props.leaderboardData V ', obj)
    }
    console.log('green this.props.leaderboardData R ', obj)
    
    return Object.keys(obj).map(function (key, index) {
      obj[key].id = key
       if ((obj[key].points >=0) && (obj[key].pointsCalf >=0)) {
         obj[key].pointsAll = parseInt(obj[key].points) + parseInt(obj[key].pointsCalf)  
         console.log('_toArrayUsersL 1a: ', obj[key].pointsAll) 
        }
         else
         { 
           if(obj[key].points >=0) {
           obj[key].pointsAll = obj[key].points   
           console.log('_toArrayUsersL 2a: ', obj[key].pointsAll)
           }
           else
           {
             obj[key].pointsAll = 0   
           }
         }

         if ((obj[key].pointsy >=0) && (obj[key].pointsCalf >=0)) {
          obj[key].pointsAlly = parseInt(obj[key].pointsy) + parseInt(obj[key].pointsCalf)  
          console.log('_toArrayUsersL 1b: ', obj[key].pointsAlly) 
         }
          else
          { 
            if(obj[key].pointsy) {
            obj[key].pointsAlly = obj[key].pointsy   
            console.log('_toArrayUsersL 2b: ', obj[key].pointsAlly)
            }
            else
            {
              obj[key].pointsAlly = 0   
            }
          }

      if (obj[key].disabled === undefined || obj[key].disabled === null) {
        obj[key].disabled = false
      }
      return obj[key]
    })
  }
}

export const _toArrayUsersS = (obj, lkey) => {
  var fkey;
  if (obj !== undefined) {
      cleanS(obj, lkey)

    return Object.keys(obj).map(function (key, index) {
      obj[key].id = key

      if (obj[key].disabled === undefined || obj[key].disabled === null) {
        obj[key].disabled = false
      }
      return obj[key]
    })
  }
}

function clean(obj) {
  for (var propName in obj) {
    if (obj[propName].points === null || obj[propName].points === undefined) {
      delete obj[propName];
    }
  }
  return obj
}

function cleanF(obj, slaveIDD) {

  for (var propName in obj) {
    if (obj[propName].slaveID !== slaveIDD) {
      delete obj[propName];
    }
  }
  console.log('green cleanF : ', obj)
  return obj
}

export const _toArrayUsersM = obj => {
  var fkey;
  if (obj !== undefined) {
    clean(obj)
    return Object.keys(obj).map(function (key, index) {
      obj[key].id = key
        if (obj[key].disabled === undefined || obj[key].disabled === null) {
          obj[key].disabled = false
        }
       
      console.log('green toArrayUsers : ',obj[key].points)
       return obj[key]
    })
  }
}

export const _toArrayUsersF = (obj, slaveIDD) => {

  var fkey;
  if (obj !== undefined) {
    cleanF(obj,slaveIDD)
    console.log('green _toArrayUsersF : ', obj)
    return Object.keys(obj).map(function (key, index) {
      obj[key].id = key
        if (obj[key].disabled === undefined || obj[key].disabled === null) {
          obj[key].disabled = false
        }
       
       return obj[key]
    })
  }
}

export const _toArrayUsers = obj => {
  var fkey;
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {
     // console.log('green company users : ', obj[key].firstName + "   " + obj[key].lastName + "   " + obj[key].email)
      obj[key].id = key
        if (obj[key].disabled === undefined || obj[key].disabled === null) {
          obj[key].disabled = false
        }
       
      //console.log('green toArrayUsers : ',obj[key].points)
       return obj[key]
    })
  }
}

export const opinionsDatefix = obj => {
  var fkey;
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {
      obj[key].id = key
        if (obj[key].sdate === undefined || obj[key].sdate === null) {
          obj[key].sdate = '2023-10-03T16:10:39.822Z'
        }    
      //console.log('green toArrayUsers : ',obj[key].points)
       return obj[key]
    })
  }
}

export const filteredTasksArray = (obj, val) => {
  let res = {}
  let filteredObj = Object.keys(obj).reduce((p, c) => {
    if (obj[c]) {
      p[c] = obj[c]
    }
    return p
  }, {})
  // if (obj !== undefined) {
  //   return Object.values(obj).map(function(row, index) {
  //     var completed = false;
  //     if (row.completed == undefined || row.completed == null) {
  //       completed = false;
  //     } else {
  //       completed = row.completed
  //     }

  //     if (val === "open") {
  //       if (!completed) {
  //         res[row.myPLDPID] = row
  //         //return obj[key];
  //       }
  //     } else {
  //       if (completed) {
  //         res[row.myPLDPID] = row
  //       }
  //     }

  //    return res;
  //   });
  // }
}

export const _toArrayPosts = obj => {
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {

     if (obj[key]) {
      if (obj[key].dateScheduled === undefined || obj[key].dateScheduled === null || obj[key] === undefined) {
        delete obj[key];
      }
      else{
        obj[key].id = key
        return obj[key]
      }
     }
     else
     {
      delete obj[key];
     }
      // obj[key].id = key
      // return obj[key]
    })
  }
}

export const _toArrayLeads = obj => {
   cleanLeads(obj)
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {
      if (obj[key].dateScheduled === undefined) {
        obj[key].dateScheduled = obj[key].dateRegistered
      }
      obj[key].id = key
      return obj[key]
    })
  }
}

function cleanLeads(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
    try {
      if (obj[propName].leadPoints === null || obj[propName].leadPoints === undefined) {
        delete obj[propName];
      }
    } catch (err) {
      console.log('cleanLeads err: '. err)
    }
  }
  return obj
}

export const _toArrayLeadsLimit = obj => {
  var x = 0;
  for (var propName in obj) {
    x = x + 1;
    if (x > 10) {
      delete obj[propName];
    }
  }
  return obj
}

// export const _toArrayLeadsLimit = obj => {
//   var x = 0;
//   var objB = []
//   for (var propName in obj) {
//     x = x + 1;
//     if (x < 11) {
//      // delete obj[propName];
//      objB.push(obj[propName]);
//      }
//    //  obj.slice(0, 10)
//    }
//    return objB;
//  }

// export const _toArrayLeadsLimit = obj => {
//   var x = 0;

//   for (var propName in obj) {
//     console.log('inside _toArrayLeadsLimit 1: ', obj)
//     x = x + 1;
//     if (x > 10) {
//       delete obj[propName];
//     }
//   }
//   console.log('inside _toArrayLeadsLimit 2: ', obj[2])
//   return obj
// }

export const _toArrayHash = obj => {
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key, index) {
      if (obj[key].count === undefined) {
        obj[key].count = obj[key].count
      }
      obj[key].id = key
      return obj[key]
    })
  }
}

export const _toArrayRemoveTrue = obj => {
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key) {
      let retObj = {}
      retObj = obj[key]

      if (retObj !== true) {
        retObj.key = key
        retObj.id = key
        return retObj
      }
    })
  }
}

export const _toArrayRemoveTrueFollow = obj => {
  if (obj !== undefined) {
    return Object.keys(obj).map(function (key) {
      let retObj = {}
      retObj = obj[key]

      if (retObj !== true) {
        if (retObj !== undefined) {
          retObj.key = key
          retObj.id = key
          return retObj
        }
      }
    })
  }
}

export const getContrastYIQ = hexcolor => {
  console.log('green hexcolor: ', hexcolor);
  if (hexcolor) {
    hexcolor = hexcolor.split('#')[1]
  }
  else
  {
    hexcolor = '#fff';
    hexcolor = hexcolor.split('#')[1]
  }
  
  let r = parseInt(hexcolor.substr(0, 2), 16)
  let g = parseInt(hexcolor.substr(2, 2), 16)
  let b = parseInt(hexcolor.substr(4, 2), 16)
  let yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? '#151515' : 'white'
}

export const _getCommentsCount = itms => {
  let itmsNo = itms === undefined ? 0 : Object.keys(itms).length
  return itmsNo === 1 ? '1 Comment' : itmsNo + ' Comments'
}

export const _getLikesCount = itms => {
  let itmsNo = itms === undefined ? 0 : Object.keys(itms).length
  return itmsNo === 1 ? '1 Like' : itmsNo + ' Likes'
}

export const _objLen = obj => {
  let len = 0
  if (obj) {
    len = Object.keys(obj).length
  }
  return len
}
