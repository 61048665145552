import {
  INTERNAL_VIDEOS,
  EXTERNAL_VIDEOS,
  MY_VIDEOS,
  CREATE_VIDEO_SUCCESS,
  PLDP_VIDEOS
} from "../actions/types";

const initState = {
  videos: {},
  pldpvideos: {},
  externalvideos: {},
  video: {},
  CREATE_VIDEO: ""
};

const videosReducer = (state = initState, action) => {
  switch (action.type) {
    case INTERNAL_VIDEOS:
      return {
        ...state,
        videos: action.videos,
        type: INTERNAL_VIDEOS,
        CREATE_VIDEO: ""
      };
    case EXTERNAL_VIDEOS:
      return {
        ...state,
        externalvideos: action.externalvideos,
        type: EXTERNAL_VIDEOS,
        CREATE_VIDEO: ""
      };
    case MY_VIDEOS:
      return {
        ...state,
        myvideos: action.myvideos,
        type: MY_VIDEOS,
        CREATE_VIDEO: ""
      };
    case PLDP_VIDEOS:
      return {
        ...state,
        pldpvideos: action.pldpvideos,
        type: PLDP_VIDEOS,
        CREATE_VIDEO: ""
      };
    case CREATE_VIDEO_SUCCESS:
      return {
        ...state,
        CREATE_VIDEO: CREATE_VIDEO_SUCCESS
      };
    case "CLEAR_VIDEO_SUCCESS":
      return {
        ...state,
        CREATE_VIDEO: ""
      };
    default:
      return state;
  }
};

export default videosReducer;
