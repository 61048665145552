import { TEST_HISTORY } from "../actions/types";

const initState = {
  type: TEST_HISTORY,
  q: '',
  a1: '',
  a2: '',
  a3: '',
  a4: '',
  a5: ''
};

const testHistoryReducer = (state = initState, action) => {
  switch (action.type) {
    case TEST_HISTORY:
      return {
        type: TEST_HISTORY,
        q: action.q,
        a1: action.a1,
        a2: action.a2,
        a3: action.a3,
        a4: action.a4,
        a5: action.a5
      };

    default:
      return state;
  }
};

export default testHistoryReducer;