import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { SHOW_TOAST, UPDATE_USER_SUCCESS, CLEAR_UPDATE_USER_SUCCESS } from '../../store/actions/types';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { connect } from 'react-redux';
import { updateUserPassword } from '../../store/actions/userDialogActions';
import { showToast, clearStates } from '../../store/actions/toastAction';

import {
	Grid,
	Typography,
	Button,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormGroup,
	TextField,
	Tab,
	Tabs,
	NoSsr,
	AppBar,
	InputAdornment,
	IconButton
} from '@material-ui/core';

import { VisibilityOff, Visibility } from '@material-ui/icons';

const styles = (theme) => ({
	appBar: {
		position: 'static',
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		},
		backgroundColor: '#d51317'
	},
	flex: {
		flex: 1,
		width: 600
	},
	dialog: {},
	submitButton: {
		backgroundColor: '#2196F3 !important'
	},
	button: {
		margin: theme.spacing.unit,
		color: '#ffffff',
		backgroundColor: '#0288d1',
		'&:hover': {
			backgroundColor: '#0288d1'
		}
	},
	uploadButton: {
		display: 'block!important',
		width: '100%!important',
		height: '36px!important',
		opacity: '0!important',
		overflow: 'hidden!important',
		marginTop: '-36px',
		cursor: 'pointer!important',
		zIndex: '999999!important'
	}
});

const Transition = (props) =>
	window.innerWidth >= 425 ? <Fade checkIn={true} {...props} /> : <Slide direction="left" {...props} />;

class ChangeUserPasswordDialog extends React.Component {
	updateUserFunc = null;
	state = {
		p: '',
		cp: ''
	};

	handleClickOpen = (scroll) => () => {
		this.setState({ open: true, scroll });
	};

	handleChange = (prop) => (event) => {
		this.setState({
			[prop]: event.target.value
		});
	};

	changeUserPassword = () => {
		if (!this.state.p.trim()) {
			var params = {
				type: 'SHOW_TOAST',
				open: true,
				variant: 'warning',
				message: 'Password is required!'
			};

			this.props.showToast(params);
			return;
		}

		if (!this.state.cp.trim()) {
			var params = {
				type: 'SHOW_TOAST',
				open: true,
				variant: 'warning',
				message: 'Confirm password is required!'
			};

			this.props.showToast(params);
			return;
		}

		if (this.state.p.trim() !== this.state.cp.trim()) {
			var params = {
				type: 'SHOW_TOAST',
				open: true,
				variant: 'warning',
				message: 'Password and confirm password do not match!'
			};

			this.props.showToast(params);
			return;
		}

		if (this.state.p.trim().length < 15) {
			var params = {
				type: 'SHOW_TOAST',
				open: true,
				variant: 'warning',
				message: 'Password length must be at least 15 characters long!'
			};

			this.props.showToast(params);
			return;
		}

		var selectedCurrentUser = window.su.data;
		selectedCurrentUser.uid = window.su.uid;
		delete selectedCurrentUser['password'];
		var body = {
			...selectedCurrentUser,
			password: this.state.p
		};

		// console.log('body', body);

		// Post CHANGES
		this.props.updateUserPassword(body);
		this.handleClose();
	};

	handleClickShowPassword = () => {
		this.setState((state) => ({ showPassword: !state.showPassword }));
	};
	handleClickConfirmPassword = () => {
		this.setState((state) => ({
			showConfirmPassword: !state.showConfirmPassword
		}));
	};
	handlePasswordStrength(password) {
		var strengthMessage = 'Strength: ';
		if (!password.length) return strengthMessage + 'None';
		if (password.length < 8) return strengthMessage + 'Weak';
		var hasUpperCase = /[A-Z]/.test(password);
		var hasLowerCase = /[a-z]/.test(password);
		var hasNumbers = /\d/.test(password);
		var hasNonalphas = /\W/.test(password);
		if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas > 3) {
			return strengthMessage + ' Strong';
		} else {
			return strengthMessage + 'Medium';
		}
	}

	componentDidUpdate = () => {
		var self = this;

		if (this.props.UPDATE_USER === UPDATE_USER_SUCCESS) {
			var params = {
				type: SHOW_TOAST,
				open: true,
				variant: 'success',
				message: 'User Password updated successfully.'
			};

			// this.setState({
			// 	...this.state,
			// 	unsavedchanges: false
			// });

			// show success toast
			this.props.showToast(params);

			// clear success state
			this.props.clearStates({ type: CLEAR_UPDATE_USER_SUCCESS });

			// Reload states
			//	this.props.showUserProfile({ type: 'SHOW_USER_PROFILE_DIALOG' });
		}
	};

	handleSubmitPasswordChange = () => { };
	handleClose = () => {
		this.setState({
			p: '',
			cp: ''
		});
		this.props.onOpenChangePassword();
	};

	randomString = (length) => {
		let text = "";
		let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789";
		for (var i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		this.setState({
			p: text.toUpperCase(),
			cp: text.toUpperCase()
		});
	}

	render() {
		const { classes, theme } = this.props;
		let self = this;

		return (
			<div>
				<Dialog
					open={this.props.open}
					onClose={this.handleClose}
					scroll={this.state.scroll}
					fullScreen={window.innerWidth >= 425 ? false : true}
					fullWidth
					TransitionComponent={Transition}
					aria-labelledby="scroll-dialog-title"
				>
					{window.innerWidth >= 425 ? (
						<DialogTitle id="alert-dialog-title">
							<Typography variant="h6">Change Password</Typography>
						</DialogTitle>
					) : (
							''
						)}
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
								<ArrowBackIcon />
							</IconButton>
							<Typography variant="h6" color="inherit" className={classes.flex}>
								Change Password
							</Typography>
						</Toolbar>
					</AppBar>
					<DialogContent>
						<div>
							<Grid container spacing={8}>
								<Grid item xs={12}>
									<TextField
										id="password"
										type={this.state.showPassword ? 'text' : 'password'}
										label="New password"
										value={this.state.p}
										helperText={this.handlePasswordStrength(this.state.p)}
										fullWidth
										margin="normal"
										InputLabelProps={{
											shrink: true
										}}
										onChange={this.handleChange('p')}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="Toggle password visibility"
														onClick={this.handleClickShowPassword}
													>
														{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="confirmpassword"
										type={this.state.showConfirmPassword ? 'text' : 'password'}
										fullWidth
										label="Confirm password"
										value={this.state.cp}
										helperText={this.handlePasswordStrength(this.state.cp)}
										margin="normal"
										InputLabelProps={{
											shrink: true
										}}
										onChange={this.handleChange('cp')}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="Toggle password visibility"
														onClick={this.handleClickConfirmPassword}
													>
														{this.state.showConfirmPassword ? (
															<VisibilityOff />
														) : (
																<Visibility />
															)}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
							</Grid>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							onClick={e =>
								this.randomString(15)
							}
							className={classes.button}
							size="small"
						>
							<VpnKeyIcon className={classes.rightIcon} />
							&nbsp; AUTO GENERATE PASSWORD
						</Button>
						<Button
							variant="contained"
							onClick={this.changeUserPassword}
							className={classes.button}
							size="small"
						>
							<SendIcon className={classes.rightIcon} />
							&nbsp; CHANGE PASSWORD
						</Button>
						<Button size="small" onClick={this.handleClose}>Close</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

ChangeUserPasswordDialog.propTypes = {
	open: PropTypes.bool.isRequired
};

const ChangeUserPasswordDialogStyled = withStyles(styles, { withTheme: true })(ChangeUserPasswordDialog);

const mapStateToProps = (state) => {
	return {
		UPDATE_USER: state.userDialog.UPDATE_USER
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showToast: (params) => dispatch(showToast(params)),
		clearStates: (params) => dispatch(clearStates(params)),
		updateUserPassword: (params) => dispatch(updateUserPassword(params))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserPasswordDialogStyled);