import firebase from "../firebase";
import { SUBCOMPETENCIES_LIST, SHOW_TOAST } from "./types";
import { _toArray } from "./commonActions";
const {v4: uuidv4} = require('uuid')

export const fetchSubCompetencies = params => {
  return (dispatch, getState) => {
    firebase.database.ref(`competencies_sub/${params.compid}`).on("value", function(data) {
      if (data.val()) {
        var sortedData = _toArray(data.val());
        sortedData.sort(function(a, b) {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
        var subcompetencies = {};
        subcompetencies[params.compid] = sortedData;

        dispatch({
          type: SUBCOMPETENCIES_LIST,
          subcompetencies_list: subcompetencies,
          subcompetencies: data.val(),
        });

      } else {
        return (dispatch, getState) => {
          dispatch({
            type: SUBCOMPETENCIES_LIST,
            subcompetencies_list: [],
            subcompetencies: {},
          });
        };
      }
    });
  };
};

// Through SDK
export const updateOrCreateSubCompetency = params => {
  return (dispatch, getState) => {
    var valueID = params.updateValue ? params.valueID : uuidv4();

    var timeInMs = Date.now();
    var dataN = {
      companyID: params.companyID,
      sid:  params.compid + '-' + 'None',
      compid: params.compid,
      name: 'AAA_None',
      companyName: params.companyName,
      dateRegistered: timeInMs,
      status: "active",
      companyID_status: params.companyID + "_active"
    };
    var data = {
      companyID: params.companyID,
      sid: valueID,
      compid: params.compid,
      name: params.valueDesc,
      companyName: params.companyName,
      dateRegistered: timeInMs,
      status: "active",
      companyID_status: params.companyID + "_active"
    };

    // Write the new rating data
    var updates = {};
    updates[`competencies_sub/${params.compid}/` +params.compid + '-' + 'None'] = dataN;
    updates[`competencies_sub/${params.compid}/` + valueID] = data;

    firebase.database
      .ref()
      .update(updates)
      .then(() => {

        // if (params.compid.trim() !== "") {
        //   let countItems = firebase
        //     .database
        //     .ref("company-values")
        //     .child(params.companyID)
        //     .child(params.compid)
        //     .child("count");

        //   let countItem = countItems.transaction(function(current) {
        //     return (current || 0) + 1;
        //   });
        // }
        dispatch({
          type: SHOW_TOAST,
          message: "Record posted successfully!",
          variant: "success"
        });
      })
      .catch(err => {
        dispatch({
          type: SHOW_TOAST,
          message: err.message,
          variant: "error"
        });
      }); 
  };
};

export const updateOrCreateNone = params => {
  return (dispatch, getState) => {
    var valueID = params.compid + '-' + 'None';

    var timeInMs = Date.now();

    var data = {
      companyID: params.companyID,
      sid: valueID,
      compid: params.compid,
      name: 'None',
      companyName: params.companyName,
      dateRegistered: timeInMs,
      status: "active",
      companyID_status: params.companyID + "_active"
    };

    // Write the new rating data
    var updates = {};
    updates[`competencies_sub/${params.compid}/` + valueID] = data;

    firebase.database
      .ref()
      .update(updates)
      .then(() => {

        dispatch({
          type: SHOW_TOAST,
          message: "Record posted successfully!",
          variant: "success"
        });
      })
      .catch(err => {
        dispatch({
          type: SHOW_TOAST,
          message: err.message,
          variant: "error"
        });
      }); 
  };
};

// Through SDK
export const deleteSubCompetency = params => {
  return (dispatch, getState) => {
    // Implement validations here?

    // Write the new rating data
    var updates = {};
    updates[`competencies_sub/${params.compid}/` + params.editID] = null;

    firebase.database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: SHOW_TOAST,
          message: "Record deleted successfully!",
          variant: "success"
        });
      })
      .catch(err => {
        dispatch({
          type: SHOW_TOAST,
          message: err.message,
          variant: "error"
        });
      });
  };
};

