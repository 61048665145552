import axios from "axios";
import {
  CREATE_COMMENT,
  BASE_API_URL,
  DELETE_COMMENT,
  SHOW_TOAST,
  postTypes
} from "./types";
import firebase from "../firebase";

export const createNewComment = (post, userData, commentParams) => {
  console.log('green createNewComment props : ', userData);

  let gender = userData && userData.gender ? userData.gender : "not_specified";
  let points = userData && userData.points ? userData.points : "not_specified";
  let department = userData && userData.department ? userData.department : "not_specified";
  let nationality = userData && userData.nationality ? userData.nationality : "not_specified";
  let operation = userData && userData.operation ? userData.operation : "not_specified";
  let race = userData && userData.race ? userData.race : "not_specified";

  let groupid = post && post.groupid ? post.groupid : "not_specified";
  let competencyid = post && post.competencyid ? post.competencyid : "not_specified";
  let subcompetencyid = post && post.subcompetencyid ? post.subcompetencyid : "not_specified";

  const commentBody = {
    companyID: userData.companyID ? userData.companyID : "not_specified",
    companyName: userData.companyName ? userData.companyName : "not_specified",
    journalUserID: userData.userID ? userData.userID : "not_specified",
    journalUserName: userData.firstName ? userData.firstName + " " + userData.lastName : "not_specified",
    photoURL: userData.photoURL ? userData.photoURL : "not_specified",
    comment: commentParams.comment,
    postType: commentParams.postType,
    postID: post.dailyThoughtID
      ? post.dailyThoughtID
      : post.videoID || post.podcastID || post.newsID,
    commentUncomment: "comment",
    dailyThoughtType: post.dailyThoughtType,
    gender,
    points,
    department,
    operation,
    nationality,
    race,
    groupid,
    competencyid,
    subcompetencyid
  };

  if (post.dailyThoughtType === 4) {
    commentBody.groupid = post.groupid
    commentBody.groupname = post.groupname
  }
console.log('green commentActions : ', commentBody)
  return _saveComment(commentBody);
 
};

export const createNewSubComment = (post, userData, commentParams) => {
  let gender = userData && userData.gender ? userData.gender : "not_specified";
  let points = userData && userData.points ? userData.points : "not_specified";
  let department = userData && userData.department ? userData.department : "not_specified";
  let nationality = userData && userData.nationality ? userData.nationality : "not_specified";
  let operation = userData && userData.operation ? userData.operation : "not_specified";
  let race = userData && userData.race ? userData.race : "not_specified";

  let groupid = post && post.groupid ? post.groupid : "not_specified";
  let competencyid = post && post.competencyid ? post.competencyid : "not_specified";
  let subcompetencyid = post && post.subcompetencyid ? post.subcompetencyid : "not_specified";

  const commentBody = {
    companyID: userData.companyID,
    companyName: userData.companyName,
    journalUserID: userData.userID,
    journalUserName: userData.firstName + " " + userData.lastName,
    photoURL: userData.photoURL,
    comment: commentParams.comment,
    postType: commentParams.postType,
    postID: post.dailyThoughtID
      ? post.dailyThoughtID
      : post.videoID || post.podcastID || post.newsID,
    commentUncomment: "comment",
    parentID: commentParams.parentID,
    dailyThoughtType: post.dailyThoughtType,
    gender,
    points,
    department,
    operation,
    nationality,
    race,
    groupid,
    competencyid,
    subcompetencyid
  };

  if (post.dailyThoughtType === 4) {
    commentBody.groupid = post.groupid
    commentBody.groupname = post.groupname
  }

  // console.log("commentBody", commentBody);

  return _saveSubComment(commentBody);

  // return (dispatch, getState) => {
  //   axios
  //     .post(`${BASE_API_URL}/subcomments`, commentBody)
  //     .then(res =>
  //       dispatch({
  //         type: SHOW_TOAST,
  //         open: true,
  //         variant: "success",
  //         message: "Comment posted successfully!"
  //       })
  //     )
  //     .catch(err =>
  //       dispatch({
  //         type: SHOW_TOAST,
  //         open: true,
  //         variant: "error",
  //         message: err.message
  //       })
  //     );
  // };
};

export const deleteComment = commentParams => {
  const commentBody = {
    postType: commentParams.postType,
    postID: commentParams.postID,
    dailyThoughtType: commentParams.dailyThoughtType,
    commentUncomment: "uncomment",
    commentID: commentParams.commentID,
    competencyid: commentParams.competencyid===undefined ? 0: commentParams.competencyid,
    subcompetencyid: commentParams.subcompetencyid===undefined ? 0: commentParams.subcompetencyid
  };

  if (commentParams.dailyThoughtType === 4) {
    commentBody.groupid = commentParams.groupid
    commentBody.groupname = commentParams.groupname
  }

  return _saveComment(commentBody);
};

export const deleteSubComment = commentParams => {
  const commentBody = {
    postType: commentParams.postType,
    postID: commentParams.postID,
    dailyThoughtType: commentParams.dailyThoughtType,
    commentUncomment: "uncomment",
    parentID: commentParams.parentID,
    commentID: commentParams.commentID,
    competencyid: commentParams.competencyid===undefined ? 0: commentParams.competencyid,
    subcompetencyid: commentParams.subcompetencyid===undefined ? 0: commentParams.subcompetencyid
  };

  if (commentParams.dailyThoughtType === 4) {
    commentBody.groupid = commentParams.groupid
    commentBody.groupname = commentParams.groupname
  }

  return _saveSubComment(commentBody);
};

function _saveComment(body) {
  // console.log('inside _saveComment body:', body);

  var data = {}
  //if its group posts
  if (body.dailyThoughtType === 4) {
    var dataref = postTypes["groupposts"] + "/" + body.groupid
    data.groupid = body.groupid
    data.groupname = body.groupname
  } else {
    var dataref = postTypes[body.postType]
  }

  // check if all fields are provided
  if (
    body.commentUncomment &&
    body.commentUncomment.trim().toLowerCase() === "comment"
  ) {
    if (
      !body.companyID ||
      !body.companyName ||
      !body.journalUserID ||
      !body.journalUserName ||
      !body.postType ||
      !body.postID ||
      !body.comment
    ) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Please enter your comment!"
        })
      }
    }

    if (!body.photoURL) {
      body.photoURL =
        "https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/mailassets%2Fdefault-user.png?alt=media&token=ea955943-9b02-4cd9-95c0-cd1436569498";
    }

    // check tbl ref
    if (dataref == undefined) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Unavailable post type! Please check post type parameter."
        })
      }
    }

    var dateRegistered,
      commentID,
      clickData = {};
    dateRegistered = Date.now();
    var stringDateRegistered = new Date(dateRegistered).toLocaleString();

    commentID = firebase.database
      .ref()
      .child("/" + dataref + "/" + body.postID.trim() + "/comments")
      .push().key;

    // comments data
    data.companyID = body.companyID;
    data.companyName = body.companyName;
    data.journalUserID = body.journalUserID;
    data.journalUserName = body.journalUserName;
    data.date = dateRegistered;
    data.stringDate = stringDateRegistered;
    data.stringCommentDate = stringDateRegistered;
    data.photoURL = body.photoURL;
    data.postType = body.postType;
    data.dailyThoughtID = body.postID;
    data.commentID = commentID;
    data.comment = body.comment;
    data.gender = body.gender;
    data.points = body.points;
    data.department = body.department;
    data.operation = body.operation;
    data.nationality = body.nationality;
    data.race = body.race;
    data.groupid = body.groupid;
    data.competencyid = body.competencyid;
    data.subcompetencyid = body.subcompetencyid;

    // click data - analytics
    clickData.myCompanyID = body.companyID;
    clickData.myCompanyName = body.companyName;
    clickData.userID = body.journalUserID;
    clickData.userName = body.journalUserName;
    clickData.dateRegistered = dateRegistered;
    clickData.commentID = commentID;
    clickData.myPhoto = body.photoURL;
    clickData.postType = body.postType;
    clickData.journalID = body.postID;
    clickData.clickType = "posts";
    clickData.clickArea = "comment";
    clickData.clickItem = "button";
    clickData.competencyid = body.competencyid;
    clickData.subcompetencyid = body.subcompetencyid;
    clickData.gender = body.gender;
    clickData.points = body.points;
    clickData.department = body.department;
    clickData.operation = body.operation;
    clickData.nationality = body.nationality;
    clickData.race = body.race;
    clickData.groupid = body.groupid;
    //console.log('body.competencyid: ', body.competencyid);

    var updates = {};

    // post comments
    updates[
      "/" + dataref + "/" + body.postID.trim() + "/comments/" + commentID
    ] = data;
    updates["/comments/" + commentID] = data;
    updates["user-clicks/" + commentID] = clickData;

  } else if (
    body.commentUncomment &&
    body.commentUncomment.trim().toLowerCase() === "uncomment"
  ) {
    if (!body.commentID || !body.postType || !body.postID) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Please enter your comment!"
        })
      }
    }

    if (
      !body.commentID.trim() ||
      !body.postType.trim() ||
      !body.postID.trim()
    ) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Please enter your comment!"
        })
      }
    }

    // check tbl ref
    if (dataref == undefined) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Error commenting! Please contact the support team!"
        })
      }
    }

    var updates = {};
    // post comments
    updates[
      "/" + dataref + "/" + body.postID.trim() + "/comments/" + body.commentID
    ] = null;
    updates["/comments/" + body.commentID] = null;

    //console.log('inside _saveComment updates:', updates);

  } else {
    return (dispatch, getState) => {
      dispatch({
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Error commenting! Please contact the support team!"
      })
    }
  }

  return (dispatch, getState) => {
    firebase.database
      .ref()
      .update(updates, function(error) {
        if (error) {
          dispatch({
            type: SHOW_TOAST,
            open: true,
            variant: "warning",
            message: `${error.message}`
          })
        } else {
          if (body.commentUncomment.trim().toLowerCase() === "uncomment") {
            dispatch({
              type: SHOW_TOAST,
              open: true,
              variant: "success",
              message: "Comment deleted successfully!"
            })
          } else {
            dispatch({
              type: SHOW_TOAST,
              open: true,
              variant: "success",
              message: "Comment posted successfully!"
            })
          }
        }
      });
  }
}

function _saveSubComment(body) {
  var data = {}
  //if its group posts
  if (body.dailyThoughtType === 4) {
    var dataref = postTypes["groupposts"] + "/" + body.groupid
    data.groupid = body.groupid
    data.groupname = body.groupname
  } else {
    var dataref = postTypes[body.postType]
  }

  if (
    body.commentUncomment &&
    body.commentUncomment.trim().toLowerCase() === "comment"
  ) {
    if (
      !body.companyID ||
      !body.companyName ||
      !body.journalUserID ||
      !body.journalUserName ||
      !body.postType ||
      !body.postID ||
      !body.comment ||
      !body.parentID
    ) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Please enter your comment."
        })
      }
    }

    if (
      !body.companyID.trim() ||
      !body.companyName.trim() ||
      !body.journalUserID.trim() ||
      !body.journalUserName.trim() ||
      !body.postType.trim() ||
      !body.postID.trim() ||
      !body.comment.trim() ||
      !body.parentID.trim()
    ) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Please enter your comment."
        })
      }
    }

    if (!body.photoURL) {
      body.photoURL =
        "https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/mailassets%2Fdefault-user.png?alt=media&token=ea955943-9b02-4cd9-95c0-cd1436569498";
    }

    // check tbl ref
    if (dataref == undefined) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Unavailable post type! Please check post type parameter."
        })
      }
    }

    var dateRegistered,
      subcommentID,
      clickData = {};
    dateRegistered = Date.now();
    var stringDateRegistered = new Date(dateRegistered).toLocaleString();

    subcommentID = firebase.database
      .ref()
      .child(
        "/" +
        dataref +
        "/" +
        body.postID.trim() +
        "/comments/" +
        body.parentID.trim() +
        "/subComments"
      )
      .push().key;

    // comments data
    data.companyID = body.companyID;
    data.companyName = body.companyName;
    data.journalUserID = body.journalUserID;
    data.journalUserName = body.journalUserName;
    data.date = dateRegistered;
    data.stringDate = stringDateRegistered;
    data.stringCommentDate = stringDateRegistered;
    data.photoURL = body.photoURL;
    data.postType = body.postType;
    data.dailyThoughtID = body.postID;
    data.commentID = subcommentID;
    data.parentID = body.parentID;
    data.comment = body.comment;
    data.gender = body.gender;
    data.points = body.points;
    data.department = body.department;
    data.operation = body.operation;
    data.nationality = body.nationality;
    data.race = body.race;
    data.groupid = body.groupid;
    data.competencyid = body.competencyid;
    data.subcompetencyid = body.subcompetencyid;

    // click data - analytics
    clickData.myCompanyID = body.companyID;
    clickData.myCompanyName = body.companyName;
    clickData.userID = body.journalUserID;
    clickData.userName = body.journalUserName;
    clickData.dateRegistered = dateRegistered;
    clickData.commentID = subcommentID;
    data.parentID = body.parentID;
    clickData.myPhoto = body.photoURL;
    clickData.postType = body.postType;
    clickData.journalID = body.postID;
    clickData.clickType = "posts";
    clickData.clickArea = "comment";
    clickData.clickItem = "button";
    clickData.competencyid = body.competencyid;
    clickData.subcompetencyid = body.subcompetencyid;
    clickData.gender = body.gender;
    clickData.points = body.points;
    clickData.department = body.department;
    clickData.operation = body.operation;
    clickData.nationality = body.nationality;
    clickData.race = body.race;
    clickData.groupid = body.groupid;
    var updates = {};

    // post comments
    updates[
      "/" +
      dataref +
      "/" +
      body.postID.trim() +
      "/comments/" +
      body.parentID +
      "/subComments/" +
      subcommentID
    ] = data;
    updates[
      "/comments/" + body.parentID + "/subComments" + subcommentID
    ] = data;
    updates["user-clicks/" + subcommentID] = clickData;
  } else if (
    body.commentUncomment &&
    body.commentUncomment.trim().toLowerCase() === "uncomment"
  ) {
    if (!body.commentID || !body.parentID || !body.postType || !body.postID) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Error posting! Please contact the support team."
        })
      }
    }

    if (
      !body.commentID.trim() ||
      !body.parentID.trim() ||
      !body.postType.trim() ||
      !body.postID.trim()
    ) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Error posting! Please contact the support team."
        })
      }
    }

    // check tbl ref
    if (dataref == undefined) {
      return (dispatch, getState) => {
        dispatch({
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Error posting! Please contact the support team."
        })
      }
    }

    var updates = {};
    // post comments
    updates[
      "/" +
      dataref +
      "/" +
      body.postID.trim() +
      "/comments/" +
      body.parentID +
      "/subComments/" +
      body.commentID
    ] = null;
    updates[
      "/comments/" + body.parentID + "/subComments/" + body.commentID
    ] = null;
    console.log('green commentActions : ', body.commentID)
   ///////////////////////////////////////////////////////////////

  } else {
    return (dispatch, getState) => {
      dispatch({
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Error posting! Please contact the support team."
      })
    }
  }

  return (dispatch, getState) => {
    firebase.database
      .ref()
      .update(updates, function(error) {
        if (error) {
          dispatch({
            type: SHOW_TOAST,
            open: true,
            variant: "warning",
            message: `${error.message}`
          })
        } else {
          if (body.commentUncomment.trim().toLowerCase() === "uncomment") {
            dispatch({
              type: SHOW_TOAST,
              open: true,
              variant: "success",
              message: "Comment deleted successfully!"
            })
          } else {
            dispatch({
              type: SHOW_TOAST,
              open: true,
              variant: "success",
              message: "Comment posted successfully!"
            })
          }
        }
      });
  }
}
