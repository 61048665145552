import firebase from "../firebase";
import { SURVEY_LIST, SHOW_TOAST, REFRESH_CARD } from "./types";
import { _toArray } from "./commonActions";
const {v4: uuidv4} = require('uuid')

export const createSurvey = params => {
  console.log('inside createSurvey', params.companyID);

  if (params.type === SURVEY_LIST) {
  return (dispatch, getState) => {

    var valueID = params.thoughtID; //uuidv4();
    var timeInMs = Date.now();

    var data = {
      surveyid: params.thoughtID, //valueID,
      title: params.title,
      companyID: params.companyID,
      //real_companyID: params.companyID,
      type: params.typeofSurvey,
      size: params.size,
      QandA1: params.QandA1,
      QandA2: params.QandA2,
      QandA3: params.QandA3,
      QandA4: params.QandA4,
      QandA5: params.QandA5,
      QandA6: params.QandA6,
      QandA7: params.QandA7,
      QandA8: params.QandA8,
      QandA9: params.QandA9,
      QandA10: params.QandA10,
      QandA11: params.QandA11,
      QandA12: params.QandA12,
      ownMaterial: params.ownMaterial,
      dailyThoughtType: params.dailyThoughtType,
      groupid: params.groupid
    };
    // Write the new rating data
   // console.log('params.QandA5: ', toObject(params.QandA5));
   // console.log('typeof(toObject(params.QandA5)): ', typeof(toObject(params.QandA5)));

    var updates = {};
    updates[`surveys/${params.companyID}/` + valueID] = data;
    if (params.dailyThoughtType==='4' || params.dailyThoughtType===4)
    {
      updates[`company-group-posts/${params.companyID}/${params.thoughtID}/stage`] = 2; //test published
      updates[`group-posts/${params.groupid}/${params.thoughtID}/stage`] = 2; //test published
    }
    else {
      updates[`dailyThoughts/${params.thoughtID}/stage`] = 2; //test published
    }
   
    firebase.database
      .ref()
      .update(updates)
      .then(() => {

        // dispatch({
        //   type: SHOW_TOAST,
        //   message: "Survey posted successfully!",
        //   variant: "success"
        // });

        dispatch({
          type: SURVEY_LIST,
          message: "Survey posted successfully!",
          variant: "success"
        });
      })
      .catch(err => {
        dispatch({
          type: SHOW_TOAST,
          message: err.message,
          variant: "error.."
        });
      });
  };
 }
};

export const refreshCard = params => {
  console.log('inside refresh action: ', params);

  if (params.type === REFRESH_CARD) {
    return (dispatch, getState) => {
        if (params.refresh===true) {      
          dispatch({
            type: REFRESH_CARD,
            survey_refresh: 'show'
          });
    
        } else {
          return (dispatch, getState) => {
            dispatch({
              type: REFRESH_CARD,
              survey_refresh: 'noshow'
            });
          };
        }
   } 
 }
};
