/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, Route, Switch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { withStyles, Typography, Grid, Button } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { connect } from 'react-redux'
import Task from './Task'
import { DATE_FORMAT } from './../../utils/date_format_utils'
const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 'auto'
  }
})

Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

const motivationStyles = theme => ({
  paperMotivation: {
    border: '1px solid #ccc!important',
    backgroundColor: '#ffffff',
    height: '100%',
    flexGrow: 4
  },
  motivationHeader: {
    backgroundColor: '#0288d1',
    padding: '14px 16px!important'
  },

  colorTextSecondary: {
    color: '#ffffff'
  },
  motivationContent: {
    height: '100%',
    paddingTop: '10px',
    margin: 10
  },
  pldpCard: {
    margin: '5px 12px',
    padding: '12px'
  },
  fab: {
    position: 'relative',
    top: -8,
    float: 'right'
  },
  reminderBadge: {
    backgroundColor: 'rgba(138, 138, 138, 0.08)',
    borderRadius: '2px',
    position: 'relative',
    padding: 10,
    fontSize: 12,
    flex: '1 1 auto',
    fontSize: 11,
    margin: '0 6px'
  },
  bellIcon: {
    marginRight: theme.spacing.unit,
    color: '#757575',
    fontSize: 19
  },
  closeIcon: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: 15,
    borderRadius: '50%'
  },
  taskTitle: {
    cursor: 'pointer'
  }
})

const Motivation = props => {
  const {
    classes,
    onAddTask,
    deletePLDP,
    tasks,
    valueID,
    title,
    onTaskOpen
  } = props
  const formattedTasks = Object.values(tasks).filter(
    v => v.moveAction === valueID
  )
  return (
    <Paper elevation={0} className={classes.paperMotivation}>
      <div className={classes.motivationHeader}>
        <Typography
          variant='body2'
          color='secondary'
          className={classes.colorTextSecondary}
        >
          {title}
          <Fab
            size='small'
            color='secondary'
            onClick={() => onAddTask(title, valueID, true)}
            aria-label='Add'
            className={classes.fab}
          >
            <AddIcon />
          </Fab>
        </Typography>
      </div>
      {/* <pre>
				{ JSON.stringify(formattedTasks  ,null,4)}
			</pre> */}

      <div className={classes.motivationContent}>
        {formattedTasks.map((v, i) => (
          <Paper
            elevation={1}
            key={i}
            className={classes.pldpCard}
            square={true}
          >
            <Typography
              variant='body2'
              className={classes.taskTitle}
              gutterBottom
              onClick={() => onTaskOpen(v, valueID, title)}
            >
              {v.title ? v.title : ''}
            </Typography>
            <br />

            <Button
              className={classes.reminderBadge}
              onClick={() => onTaskOpen(v, valueID, title)}
            >
              <NotificationsActiveIcon className={classes.bellIcon} />
              <span>{DATE_FORMAT.type(v.reminderDate, 'TASK')}</span>
            </Button>
            <IconButton onClick={() => deletePLDP(v)} size='small'>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Paper>
        ))}
      </div>
    </Paper>
  )
}

const MotivationCard = withStyles(motivationStyles)(Motivation)

class MyTasks extends Component {
  state = {
    addTask: false,
    taskCompanyValue: '',
    companyValueID: '',
    editTask: false,
    taskOpened: {}
  };

  handleOnAddTask = (title, valueID, openAddTask) => {
    this.setState({
      addTask: openAddTask,
      taskCompanyValue: title,
      companyValueID: valueID
    })
  };

  handleOnBackClick = () => {
    this.setState({
      addTask: false,
      editTask: false,
      taskOpened: {}
    })
  };

  handleOnTaskOpen = (task, valueID, title) => {
    this.setState({
      taskOpened: task,
      companyValueID: valueID,
      taskCompanyValue: title,
      editTask: !this.state.editTask
    })
  };

  handleOnSaveTask = (newTask, updateOnSave) => {
    this.props.updateNewPLDPTask(newTask, updateOnSave)
    this.setState({
      addTask: false,
      editTask: false,
      taskOpened: {}
    })
  };
  renderCompanyValues = () => {
    const { companyValues, pldpTasks, onDeleteTask } = this.props
    return companyValues.map((value, i) => (
      <Grid item xs={12} sm={12} md={6}>
        <MotivationCard
          key={i}
          title={value.valueDesc}
          tasks={pldpTasks}
          deletePLDP={onDeleteTask}
          valueID={value.valueID}
          onAddTask={this.handleOnAddTask}
          onTaskOpen={this.handleOnTaskOpen}
          {...this.props}
        />
      </Grid>
    ))
  };

  renderTasks = () => {
    const { pldpTasks } = this.props
    const groupTasks = Object.values(pldpTasks).groupBy('moveAction')

    const tasks = Object.keys(groupTasks).map((v, i) => (
      <Grid item xs={12} md={4}>
        <MotivationCard
          key={i}
          // moveTitle={pldpTask[]}
          taskValueID={v}
          task={groupTasks[v]}
          onAddTask={this.handleOnAddTask}
          onTaskOpen={this.handleOnTaskOpen}
        />
      </Grid>
    ))

    return tasks
  };

  render() {
    const {
      classes,
      pldpTasks,
      router,
      userdata,
      updateNewPLDPTask,
      companypldpslogan
    } = this.props
    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid itm xs={12} md={12}>
          <Typography
            variant='subtitle'
            style={{ textAlign: 'center', padding: '0px 10px 0px 10px' }}
          >
            {companypldpslogan
              ? companypldpslogan
              : 'Leaders are in the business of movement. Law of Movement: All movement is governed by integration (coming together) of Motivation, Direction and Structure'}
          </Typography>
        </Grid>
        {!this.state.addTask && !this.state.editTask ? (
          this.renderCompanyValues()
        ) : (
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={11} md={6}>
              {this.state.addTask ? (
                <Task
                  hasTask={false}
                  updateOnSave={this.state.editTask}
                  companyValue={this.state}
                  OnSaveTask={this.handleOnSaveTask}
                  onBackClick={this.handleOnBackClick}
                  journalUser={userdata}
                />
              ) : (
                <Task
                  hasTask={true}
                  task={this.state.taskOpened}
                  companyValue={this.state}
                  onBackClick={this.handleOnBackClick}
                  OnSaveTask={this.handleOnSaveTask}
                  updateOnSave={this.state.editTask}
                  journalUser={userdata}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

MyTasks.propTypes = {
  pldpTasks: PropTypes.array.isRequired,
  companyValues: PropTypes.array.isRequired,
  onDeleteTask: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    companypldpslogan: state.loggedInCompany.companydata
      ? state.loggedInCompany.companydata.companypldpslogan
      : 'Leaders are in the business of movement. Law of Movement: All movement is governed by integration (coming together) of Motivation, Direction and Structure'
  }
}

// export default withStyles(styles)(MyTasks);

const MyTasksStyled = withStyles(styles, { withTheme: true })(MyTasks)

export default connect(
  mapStateToProps,
  null
)(MyTasksStyled)
