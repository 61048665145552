import { SHOW_TOAST, HIDE_TOAST } from '../actions/types';

const initState = {
    type: HIDE_TOAST,
    open: false,
    variant: '',
    message: '',
    pos: ''
};

const toastReducer = (state = initState , action) => {
  switch(action.type){
    case SHOW_TOAST:
      return{ 
        ...state,
        type: SHOW_TOAST,
        open: true,
        variant: action.variant,
        message: action.message,
        pos: action.pos
      }
    case HIDE_TOAST:
      return{ 
        ...state,
        type: HIDE_TOAST,
        open: false,
        variant: '',
        message: '',
        pos: ''
      }
    default:
      return state
  }
  
}

export default toastReducer