import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import VideosCard from "../Cards/VideosCard";
import Progressbar from "../ProgressBar";
import { _toArray } from "../../store/actions/commonActions";
import CategoriesSelect from "../../components/categories/categoriesSelect";
import { HIDE_COMMENTS_BUTTON } from "../../store/actions/types";
import {
  isMobile
} from "react-device-detect";

var receivedData = {};

const styles = theme => ({
  cardstyles: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative"
  },
  containerIWantToScroll: {
    overflowY: "auto"
 }
});


class VideosView extends React.Component {
  constructor (props) {
    super(props);
    //this.getData = this.getData.bind(this)
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>SpeedDial props: ', this.props)
  }
  // testSidebar = (valueIn) => {
  //   //this.props.sendData(valueIn);
  //   console.log('in testSidebar: ', this.props);
  // }
  // componentDidMount() {
  //   if (this.testSidebar('good')===true) {
  //     console.log('black sidebar is OK');
  //   }
  //   else
  //   {
  //     console.log('black sidebar is NOT OK!');
  //   }
  // }
  state = {
    subcompetencyid: ""
  };

  _sortData = data => {
    var sortedData = _toArray(data);
    sortedData.sort(function(a, b) {
      if (a.dateScheduled > b.dateScheduled) return -1;
      if (a.dateScheduled < b.dateScheduled) return 1;
      return 0;
    });

    return sortedData;
  };

  //function to go with CategoriesSelect ///
  handleCompState = (scname, scid) => {
    this.setState({ subcompetency: scname });
    this.setState({ subcompetencyid: scid });
  }
  componentDidMount() {
    // console.log('videosView props: ', this.props);
    // console.log('videosView state: ', this.state);
    // if (this.state.subcompetencyid!=="" && this.state.subcompetencyid!==" " && this.state.subcompetencyid!==undefined) {
    //   //nothing
    //   }
    //   else
    //   {
    //     this.setState({
    //       subcompetencyid: this.state.competencyid + '-' + 'None'
    //     })
        
    //   }
  }
  componentDidUpdate() {
    if (Object.keys(this.props.videos).length > 0) {
      var arrayData = this._sortData(this.props.videos);
      receivedData = { ...arrayData };
      receivedData = Object.assign({}, arrayData);
    }
  }

  render() {
    const { classes, videos, followingData } = this.props;

    return (
      <React.Fragment>
       {isMobile?
         <div><p>&nbsp;</p></div>: 
         <div></div>
        }
        <CategoriesSelect 
        keepsubcomp={false} 
        doCompState={this.handleCompState} 
        companyID={this.props.userdata.companyID} 
        //companyID={'-KgsUcgfo7z1U9MXgd9i'}
        //companyID={'-LEiZPT-C2PyLu_YLKNU'}
        displayComp={this.props.fscompname} 
        />

        <div className={classes.cardstyles}>
          {this.props.match.path === '/filtered-videos' ? this.props.match.path === '/filtered-videos' &&
            videos && Object.keys(videos).length > 0 && Object.keys(videos).map(id => {

              if (this.state.subcompetencyid.trim() === "") {
                return (
                  <VideosCard
                    video={videos[id]}
                    key={id}
                    {...this.props}
                    following={
                      followingData
                        ? followingData[videos[id].userID]
                          ? true
                          : false
                        : false
                    }
                    hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                  />
                );
              }
              else {
                if (this.props.videos[videos[id].id].subcompetencyid === this.state.subcompetencyid) {
                  return (
                    <VideosCard
                      video={videos[id]}
                      key={id}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[videos[id].userID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                    />
                  );
                }
              }

            }) : videos &&
            Object.keys(videos).length > 0 &&
            Object.keys(receivedData).map(id => {
              if (this.state.subcompetencyid.trim() === "" || this.state.subcompetencyid == null) {
                return (
                  <VideosCard
                    video={receivedData[id]}
                    key={id}
                    {...this.props}
                    following={
                      followingData
                        ? followingData[receivedData[id].userID]
                          ? true
                          : false
                        : false
                    }
                    hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                  />
                );
              }
              else {
               console.log('videos view props: ', receivedData[id]);
               if (receivedData[id].subcompetencyid === this.state.subcompetencyid) {
               // if (this.props.videos[receivedData[id].id].subcompetencyid === this.state.subcompetencyid) {
                return (
                    <VideosCard
                      video={receivedData[id]}
                      key={id}
                      {...this.props}
                      following={
                        followingData
                          ? followingData[receivedData[id].userID]
                            ? true
                            : false
                          : false
                      }
                      hideComments={this.props.userdata && HIDE_COMMENTS_BUTTON[this.props.userdata.companyID]}
                    />
                  );
                }
              }

            })}

        </div>
      </React.Fragment>
    );
  }
}

VideosView.propTypes = {
  classes: PropTypes.object.isRequired,
  videos: PropTypes.object.isRequired
};

var VideosViewStyled = withStyles(styles)(VideosView);

const mapStateToProps = state => {
  return {
    followingData: state.userdata.followingButton
  };
};

export default connect(
  mapStateToProps,
  null
)(VideosViewStyled);
