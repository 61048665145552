import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Groupoptions from './groupoptions'

const styles = {
  searchUser__block: {
    background: '#f6f6f6',
    position: 'relative',
    top: '1px'
  }
}

class Groupselect extends Component {
  constructor (props) {
    super(props)
  }

  state = {
    form: {
      searchValue: '',
      groups: '',
      selectedgroups: [],
      searchCriteria: ''
    }
  };

  componentDidMount() {
    if (this.props.editing) {
      this.setState({
        form: {
          ...this.state.form,
          groups: {
            id: this.props.groupid,
            group_id: this.props.groupid,
            group_name: this.props.groupname
          }
        }
      })
    }
  }

  handleInputFieldChange = (event, name) => {
    if (name === "multiplegroups") {
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            selectedgroups: event
          },
          dailyThoughtType: 4,
          groupid: 1,
          groupname: 'multiplegroups'
        }),
        function() {
          this.props.handleGroups("1", 'multiplegroups', event, true)
        }
      )

    } else {
      const { target } = event
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: target.value
          },
          dailyThoughtType: 4,
          groupid: target.value.group_id,
          groupname: target.value.group_name
        }),
        function() {
          this.props.handleGroups(target.value.group_id, target.value.group_name, [], false)
        }
      )
    }

  };

  render() {
    const { classes } = this.props


    const { form } = this.state
    return (
      <div className={classes.searchUser__block}>
        <Groupoptions
          handleInput={this.handleInputFieldChange}
          form={form}
          userdata={this.props.userdata}
          editing={this.props.editing}
          groupid={this.props.groupid}
          groupname={this.props.groupname}
        />
      </div>
    )
  }
}

export default withStyles(styles)(Groupselect)
