import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CancelIcon from "@material-ui/icons/Cancel";

import SendIcon from "@material-ui/icons/ShareOutlined";
import Divider from "@material-ui/core/Divider";

import ScheduleIcon from "@material-ui/icons/Schedule";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import ThoughtsAttachment from "./ThoughtsAttachments";

//IMPORT GROUP DROPDOWN
import Groupselect from "../../components/groups/groupselect";

import {
  _DEFAULT_DATE_TIME,
  formatDateTimeMacIOS
} from "../../utils/date_format_utils";
import { GREY, RED, LIGHTBLUE, LIGHTGREY } from "../../utils/color_utils";

import { showToast, clearStates } from "../../store/actions/toastAction";
import { createThought } from "../../store/actions/thoughtsActions";
import {
  SHOW_TOAST,
  CREATE_THOUGHT_SUCCESS,
  RESTRICTED_USER_TYPES,
  CAN_POST_ORG_ILEAD,
  CAN_POST_GLOBAL,
  CAN_POST_INTERNAL
} from "../../store/actions/types";
import { isURL } from "../../utils";
import AdvancedSettings from "./AdvancedSettings";
import Organisation from "@material-ui/icons/Business";
//import IconButton from "@material-ui/core/IconButton";
import GLobalIcon from "@material-ui/icons/PeopleOutline";
import IleadIcon from "@material-ui/icons/AcUnit";
import Tooltip from '@material-ui/core/Tooltip';
//import Popup from "reactjs-popup";
//import { Popup } from 'semantic-ui-react'
//import { Tooltip } from 'reactstrap';
//import "./styles.css";


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  tooltip: {
    color: "lightblue",
    backgroundColor: "green"
  },
  titleColor: {
    color: `${LIGHTBLUE}`,
    fontSze: "1.25rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "500",
    lineHeight: "1.6",
    letterSpacing: "0.0075em"
  },
  divStyle: {
    border: `1px solid ${LIGHTGREY}!important;`,
    margin: "0 14px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 4px",
      padding: "0px 6px"
    }
  },
  dialogHeight: {
    maxHeight: `calc(100% - 20px)`
  },
  sharecomp: {
    border: "10px solid"
  }
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    // maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9',
    // visibility: 'visible !important',
    // zIndex: '100000000 !important',
    // //position: 'fixed',
    // overflow: 'visible',
    // fixed:'false',
    // placement:'top',
    // dataContainer:'.modal-body',
    // placement: 'in top',
    // dataPlacement: 'top'
    zIndex: '1000',
    position: 'absolute',
    top: '0',//move the element to the top of div.third of div.third
    left: '0',//if you want to move the element to the left;
    //zIndex: '666',
    top: '80%',
    left: '50%',
    width: '15em',
    background: '#fff',
    background: 'rgba (255, 255, 255, .9)',
    border: '1px solid #128197',
    borderRadius: '.5em',
    padding: '1em',
    textAlign: 'left',
    placement: 'top',
    container: 'modalbody'
  },
}))(Tooltip);

class ReducedThoughtsDialog extends React.Component {
  constructor (props) {
    super(props);
    this.internal = React.createRef();
    // var varpodcasturl
    // if (this.props.podcasturl && this.props.podcastname && this.props.podcastuploaded) {
    //   varpodcasturl = { 
    //     podcasturl:this.props.podcasturl.podcasturl, 
    //     podcastname:this.props.podcasturl.podcastname, 
    //     podcastuploaded: this.props.podcasturl.podcastuploaded
    //    }
    // }
    // else
    // { 
    //   varpodcasturl = {} 
    // }
    console.log('purple constructor: ', this.truncateString(this.props.podcasturl, 15));
  }

  state = {
    isHidden: true,
    // maxLengthThought: this.props.thought.length + "/280",
    // maxLengthAuthor: this.props.quotemaster ? this.props.quotemaster.length + "/100" : "100",
    maxLengthAuthor: "3/100",
    maxLengthThought: "14/280",
    modalTitle: "Add a new thought",
    postInternalGlobal: "postInternal",
    uploadInProgress: false,
    selectedDate: "",
    selectedTime: "",
    groupid: "",
    groupname: "",
    textColor: this.props.textcolor, //"#fff",
    website: this.props.website, //"www.leadershipplatform.com",
    slogan: this.props.slogan, //"Multiplying Leaders. Moving Society",
    logo: this.props.logoprop, //"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD",

    // body data to be posted
    PodcastExternalRecord: this.props.podcasturl ? "upload": "podcastnone",
    VidUploadExternal: this.props.videourl ? "upload": "videonone",
    dailyThoughtType: 1,
    dateRegistered: 0,
    dateScheduled: 0,
    subtitle: this.props.quotemaster,
    title: this.props.thought,
    podcasturl: this.truncateString(this.props.podcasturl, 15) === 'https://firebas' ? {}: { podcasturl: this.props.podcasturl, podcastname: this.props.podcastname, podcastuploaded: true },
    podcastsObj: this.truncateString(this.props.podcasturl, 15) === 'https://firebas' ? [ { url: this.props.podcasturl, title: this.props.podcastname } ]:[], 

    audiourl: {},
    imageurl: { imageurl: this.props.imageprop },
    fileurl: { fileurl: this.props.fileurl, filetitle: this.props.filetitle },
    filesObj: this.props.fileurl ? [{ url: this.props.fileurl, title: this.props.filetitle }]:[],
    
    urls: this.props.articletitle ? [{ title: this.props.articletitle, url: this.props.articlesurl}]: [],
   
    videourl: this.truncateString(this.props.videourl, 15) === 'https://firebas' ? {}: { videoname: this.props.videoname, videourl: this.props.videourl },
    videosObj: this.truncateString(this.props.videourl, 15) === 'https://firebas' ? [ { url: this.props.videourl, title: this.props.videoname } ]:[],
  
    articlesObj: [],
    podcastType: 2,
    show: false,
    btinternal: false,
    btglobal: false,
    btilead: false,
    internalbuttonstyle: sendBtn,
    globalbuttonstyle: sendBtn,
    ileadbuttonstyle: sendBtn,
    //tooltipOpen: false
    competency: this.props.competency,
    competencyid: this.props.competencyid,
    subcompetency: this.props.subcompetency, //this.props.fscompname,
    subcompetencyid: this.props.subcompetencyid //this.props.subcompFilter
  };

  truncateString(str, num) {
   var strA = String(str);
   return strA.substring(0, num);
  
  }

  toggleOn = () => {
    const { show } = this.state;
    this.setState({
      show: true
    });
  };

  toggleOff = () => {
    const { show } = this.state;
    this.setState({ show: false });
  };

  handleValueChange = (prop, postv) => event => {
    // postInternalGlobal
    //console.log('#postv: ', postv)
    var ttype;
    if (prop === "postInternalGlobal") {
      switch (String(postv)) {
        case "postInternal":
          //this.internal.setAttribute("disabled", "disabled");
          //alert('Post shared to Organisational');
          this.setState({ btinternal: true, internalbuttonstyle: disBtn })
          ttype = 1;
          break;

        case "postGlobally":
          //alert('Post shared to Global');
          this.setState({ btglobal: true })
          this.setState({ btglobal: true, globalbuttonstyle: disBtn })
          ttype = 2;
          break;

        case "postILead":
          //alert('Post shared to I-Lead');
          this.setState({ btilead: true })
          this.setState({ btilead: true, ileadbuttonstyle: disBtn })
          ttype = 3;
          break;

        default:
          ttype = 1;
          break;
      }

      //console.log('#ttype: ', ttype)
      this.setState({ dailyThoughtType: ttype }, () => this.submitPost())
    }

  };

  handleURLChanged(prop, value) {
    this.setState({
      [prop]: value
    });
  }

  handleExternalUpload(value) {
    if (value === "audio") {
      this.setState({
        PodcastExternalRecord: "upload",
        podcastType: 1
      });
    } else {
      this.setState({
        PodcastExternalRecord: value,
        podcastType: 2
      });
    }
  }

  handleGroupsChange(value, name) {
    this.setState({ groupid: value, groupname: name });
  }

  cancelSchedule() {
    this.setState({
      dateRegistered: 0,
      dateScheduled: 0,
      selectedDate: "",
      selectedTime: ""
    });
  }

  submitPost = () => {
    if (this.state.uploadInProgress) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please wait, Upload in progress.."
      };

      this.props.showToast(params);
      return;
    }

    //console.log('this.state.imageurl.imageurl:',this.state.imageurl.imageurl)

    if (
      !this.props.thought.trim() &&
      !this.props.imageprop &&
      !this.props.quotemaster.trim()
    ) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please provide thought message or attach an image template"
      };
      //console.log('#PARAMS: ', params)
      //this.props.showToast(params);
      return;
    }

    if (this.state.groupid.trim() == "" && this.state.dailyThoughtType == "4") {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Please select a group!"
      };

      this.props.showToast(params);
      return;
    }

    var selectedDate,
      selectedTime,
      dateRegistered,
      dateScheduled,
      actualScheduleDate;

    dateRegistered = Date.now();

    selectedDate = this.state.selectedDate;
    selectedTime = this.state.selectedTime;

    // If scheduled validate date
    if (selectedDate.trim().length > 0) {
      actualScheduleDate = formatDateTimeMacIOS(selectedDate, selectedTime);
      dateScheduled = Date.parse(actualScheduleDate);
    } else {
      dateScheduled = dateRegistered;
    }

    if (this.props.editing) {
      dateRegistered = this.props.post.dateRegistered;

      if (this.props.post.dateScheduled > dateScheduled)
        dateScheduled = this.props.post.dateScheduled;
    }

    if (!this.props.editing && dateRegistered > dateScheduled) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Schedule date must be greater than current date / time."
      };

      this.props.showToast(params);
      return;
    }
    var body;
    var body = {
      ...this.state,
      dateRegistered: dateRegistered,
      dateScheduled: dateScheduled,
      editing: this.props.editing || null,
      post: this.props.post || null
    };
    this.props.createThought(body)
    // }   

    this.setState({
      selectedDate: "",
      selectedTime: ""
    });
  };

  componentDidUpdate = () => {
    var self = this;
    //console.log('#INSIDE componentDidUpdate')
    if (this.props.CREATE_THOUGHT === CREATE_THOUGHT_SUCCESS) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "success",
        message: "LEAD Shared successfully."
      };

      // show success toast
      this.props.showToast(params);

      // clear success state
      this.props.clearStates({ type: "CLEAR_THOUGHT_SUCCESS" });

      // close dialog
      this.props.closeModal();
    }
  };

  componentDidMount() {
    if (this.props.editing) {
      const { post } = this.props;
      //console.log('#post.title: ', post.title)
      this.setState({
        modalTitle: "Edit a thought",
        postInternalGlobal:
          post.dailyThoughtType === 1
            ? "postInternal"
            : post.dailyThoughtType === 2
              ? "postGlobally"
              : post.dailyThoughtType === 3
                ? "postILead"
                : "postGroup",
        selectedDate: _DEFAULT_DATE_TIME("DATE", post.dateScheduled),
        selectedTime: _DEFAULT_DATE_TIME("TIME", post.dateScheduled),
        dailyThoughtType: post.dailyThoughtType,
        dateRegistered: post.dateRegistered,
        dateScheduled: post.dateScheduled,
        subtitle: this.props.quotemaster, //post.subtitle,
        title: this.props.thought, //post.title,
        textColor: post.textColor
      });

      if (post.dailyThoughtType === 4) {
        this.toggleOn();
        this.setState({ groupid: post.groupid, groupname: post.groupname });
      }
    }
  }

  render() {
    const { classes, opendialog, fullScreen, closeModal } = this.props;
    return (
      <FormControl>
        <DialogActions>
          <DialogContent>
            <input type="hidden"
              id="title"
              value={this.props.thought}
            />
            <input type="hidden"
              id="author"
              value={this.props.quotemaster}
            />

     { this.props.userdata && this.props.userdata.companyID !== '-LjW7UI7e0uUikYrw-Vj'
           && CAN_POST_INTERNAL[this.props.userdata.userType] ? (
                <React.Fragment>

                  {this.props.location === '/organisational' ?

                    <FormControlLabel
                      value="postInternal"
                      control={
                        <Button
                          value="postInternal"
                          style={greyBtn}
                        >
                          <Organisation />
                          {/* Organisational */}
                        </Button>
                      }
                      label="Share Club Forum"
                      labelPlacement="top"
                    />

                    :
                    <FormControlLabel
                      value="postInternal"
                      control={

                        <Button
                          value="postInternal"
                          onClick={this.handleValueChange("postInternalGlobal", "postInternal")}
                          style={this.state.internalbuttonstyle}
                          disabled={this.state.btinternal}
                        >
                          <Organisation />
                        </Button>
                      }
                      label="Share Club Forum"
                      labelPlacement="top"
                    />
                  }

                  {this.props.userdata &&
                    CAN_POST_GLOBAL[this.props.userdata.userType] ? (
                      <React.Fragment>
                        {this.props.location === '/globalcontributors' ?

                          <FormControlLabel
                            value="postGlobally"
                            control={
                              <Button
                                value="postGlobally"
                                style={greyBtn}
                              >
                                <GLobalIcon />
                              </Button>
                            }
                            label="Share Global"
                            labelPlacement="top"
                          />
                          :
                          <FormControlLabel
                            value="postGlobally"
                            control={
                              <Button
                                value="postGlobally"
                                onClick={this.handleValueChange("postInternalGlobal", "postGlobally")}
                                style={this.state.globalbuttonstyle}
                                disabled={this.state.btglobal}
                              >
                                <GLobalIcon />

                              </Button>
                            }
                            label="Share Global"
                            labelPlacement="top"
                          />

                        }
                      </React.Fragment>
                    )
                    : null
                  }

                  {this.props.location === '/ilead' || this.props.location === '/' ?
                    <FormControlLabel
                      value="postILead"
                      control={
                        <Button
                          value="postILead"
                          style={greyBtn}
                        >
                          <IleadIcon />
                          {/* I-LEAD */}
                        </Button>
                      }
                      label="Share Global Forum"
                      labelPlacement="top"
                    />
                    :
                    //  <HtmlTooltip
                    // title={
                    //   <React.Fragment>
                    //     <Typography color="inherit">Share I-Lead</Typography>
                    //     <em>{"Share"}</em> <b>{'this thought'}</b> <u>{'I-LEAD'}</u>.{' '}
                    //     {"If you press this button, the post will share To I-Lead."}
                    //   </React.Fragment>
                    // }
                    // >
                    //   <Button
                    //   value="postILead"
                    //   onClick={this.handleValueChange("postInternalGlobal","postILead")}
                    //   style={this.state.ileadbuttonstyle}
                    //   disabled={this.state.btilead}
                    // >
                    //   <IleadIcon />
                    //   {/* I-LEAD */}
                    // </Button>

                    // </HtmlTooltip>   

                    <FormControlLabel
                      value="postILead"
                      control={
                        <Button
                          value="postILead"
                          onClick={this.handleValueChange("postInternalGlobal", "postILead")}
                          style={this.state.ileadbuttonstyle}
                          disabled={this.state.btilead}
                        >
                          <IleadIcon />
                          {/* I-LEAD */}
                        </Button>
                      }
                      label="Share Global Forum"
                      labelPlacement="top"
                    />
                  }

                  {/* GROUPS//////////////////////////////////////////// */}
                  {/* { <FormControlLabel
                    value="postGroup"
                    control={<Radio color="primary" />}
                    label="Post to Group"
                    labelPlacement="end"
                    onClick={this.toggleOn}
                  /> } */}
                  {/* GROUPS//////////////////////////////////////////// */}
                  {/* </RadioGroup> */}
                  {/* GROUPS//////////////////////////////////////////// */}
                  {/* {this.state.show && (
                  <React.Fragment>
                    <Groupselect
                      handleGroups={this.handleGroupsChange.bind(this)}
                      userdata={this.props.userdata}
                    />
                  </React.Fragment>
                )} */}
                  {/* GROUPS//////////////////////////////////////////// */}

                </React.Fragment>
              ) : null}

          </DialogContent>

          {/* <Button
              variant="contained"
              onClick={this.submitPost.bind(this)}
              style={sendBtn}
            >
              <SendIcon />
              SHARE
            </Button> */}
        </DialogActions>
      </FormControl>
    );
  }
}

ReducedThoughtsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

const dateObj = {
  width: "100%"
};

const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: "#fff",
  width: "82px",
  height: "68px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  //outline:'none',
  //outlineStyle: 'none'
};

const greyBtn = {
  backgroundColor: LIGHTGREY,
  color: "#fff",
  width: "82px",
  height: "68px",
  border: "10px solid",
  borderRadius: "15px",
  borderColor: "#fff"
  //borderOutline:'none'
  //borderColor: 'transparent'
};

const disBtn = {
  backgroundColor: GREY,
  color: "#fff",
  width: "82px",
  height: "68px",
  border: "10px solid",
  borderRadius: "15px",
  borderColor: "#fff"
};

const reducedThoughtsDialog = withMobileDialog({ breakpoint: "xs" })(ReducedThoughtsDialog);

const reducedThoughtsDialogStyled = withStyles(styles)(reducedThoughtsDialog);

const mapStateToProps = state => {
  return {
    CREATE_THOUGHT: state.thoughts.CREATE_THOUGHT,
    userdata: state.userDialog.userDetails[0]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    clearStates: params => dispatch(clearStates(params)),
    createThought: params => dispatch(createThought(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reducedThoughtsDialogStyled);

