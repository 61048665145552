import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { TextField, IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from "@material-ui/icons/Add";
import { showToast } from "../../store/actions/toastAction";
import { updateOrCreateProgrammes } from "../../store/actions/companyAdminActions";

const styles = theme => ({
  root: {
  },
});

class AddProgramme extends Component {
  constructor (props) {
    super(props);
    this.state = {
      programme_name: "",
      fullwidth: false,
      isUpdate: false,
      programme_id: "",
      dateRegistered: "",
      status: "active"
    };
  }

  componentDidMount() {
    if (this.props.programme) {
      // console.log("updating here")
      this.setState({
        programme_name: this.props.programme.programme_name,
        programme_id: this.props.programme.programme_id,
        status: this.props.programme.status,
        dateRegistered: this.props.programme.dateRegistered,
        isUpdate: true,
        fullwidth: true
      })
    }
  }

  saveObject = () => e => {
    e.preventDefault();

    this.saveData();
  };

  saveData = () => {
    if (
      !this.state.programme_name.trim()
    ) {
      var params = {
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Please enter programme name"
      };

      this.props.showToast(params);
      return;
    }

    if (this.props.type === "programme") {
      this.props.updateOrCreateProgrammes({
        companyID: this.props.userdata.companyID,
        programme_name: this.state.programme_name,
        companyName: this.props.userdata.companyName,
        updateValue: this.state.isUpdate,
        dateRegistered: this.state.dateRegistered,
        status: this.state.status,
        programme_id: this.state.programme_id
      });
    }

    this.resetFields();
  }

  resetFields = () => {
    if (this.state.isUpdate) {
      this.props.onClose();
    }
    this.setState({
      programme_name: "",
      fullwidth: false,
      isUpdate: false,
      programme_id: "",
      status: "active",
    });
  }

  // handleMouseDown = event => {
  //   event.preventDefault();
  // };

  handlePressEnter = () => {
    // console.log("enter pressed")
    this.saveData()
  };

  render() {
    const { classes, userdata, title, editText } = this.props;
    return (
      <div style={flexAlignObj}>
        <div style={flexObjOne}>
          <TextField
            id="programmeName"
            name="programmeName"
            type="text"
            multiline
            className="expandText"
            label={title}
            aria-describedby="programmeName"
            value={this.state.programme_name}
            fullWidth={this.state.fullwidth}
            onChange={event => {
              const { value } = event.target;
              this.setState({
                programme_name: value,
                fullwidth: value.length > 0 ? true : false
              });
            }}
            onKeyPress={(ev) => {
              // console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                this.handlePressEnter()
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="add competency"
                  onClick={this.saveObject()}
                  //onMouseDown={this.handleMouseDown.bind(this)}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>,
            }}
          />
        </div>
      </div>
    )
  }
}

AddProgramme.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  editText: PropTypes.string
};

const flexAlignObj = {
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center"
};
const flexObjOne = {
  flexGrow: 12
};

const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0]
  };
};

const AddProgrammeStyled = withStyles(styles)(AddProgramme);

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    updateOrCreateProgrammes: params => dispatch(updateOrCreateProgrammes(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProgrammeStyled);  
