/* eslint-disable no-inline-comments */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { MenuItem, Input, FormControl, Select, InputLabel } from '@material-ui/core'
import { fetchSubCompetencies } from '../../store/actions/subcompetenciesActions'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '200px'
  }
})
class SubCompetencyOptions extends Component {
  state = {
    competencyid: '',
    subcompetencyid: '',
    subcompetencyname: '',
    open: false
  }

  componentDidMount() {
    // console.log('subcompetencyid: ', this.props.subcompetencyid);

    if (this.props.subcompetencyid) {
      this.setState({ subcompetencyid: this.props.subcompetencyid })
    } else {
      this.setState({ subcompetencyid: this.props.competencyid + '-' + 'None' })
    }

    if (this.props.competencyid) {
      this.setState({ competencyid: this.props.competencyid })
      this.fetchData(this.props.competencyid)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (prevProps.competencyid !== this.props.competencyid) {
        this.fetchData(this.props.competencyid)
      }
    }
  }

  handleChange = (event, name) => {
    this.setState({
      subcompetencyid: event.target.value,
      subcompetencyname: name.props.children
    })
    this.props.onChangeValue(event.target.value, name.props.children)
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  handleOpen = () => {
    this.setState({ open: true })
  };

  fetchData = (compid) => {
    this.props.fetchSubCompetencies({ compid })
  };

  filterZZZ = (comLable) => {
    let comLablex = comLable.toString()
    let myTruncatedString = comLablex.substring(0, 4)
    let finalstring = comLablex

    if (myTruncatedString === 'ZZZ_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    if (myTruncatedString === 'AAA_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    return finalstring
  };

  render() {
    const { classes, subcompetencies_list, competencyid } = this.props
    const { subcompetencyid, open, subcompetencyname } = this.state
    console.log('subcompetencyOptions props: ', this.props.competencyid)

    return (
      <div>
        <FormControl className={classes.formControl}>
          {/* <InputLabel id='demo-controlled-open-select-label'>Sub Competency</InputLabel> */}
          <InputLabel shrink={false}>
           {subcompetencyid==='-None' ? 'Sub Category' : ''}
          </InputLabel>
          <Select
            labelId='demo-controlled-open-select-label'
            id='demo-controlled-open-select-sub'
            inputProps={{
              id: subcompetencyid
            }}
            open={open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={subcompetencyid}
            onChange={this.handleChange}
            input={<Input name='competencies' id='competencies' value={subcompetencyname} />}
            aria-expanded='true'
            style={{
              width: 200,
              height: 35,
              color: 'gray',
              paddingLeft: 5,
              fontSize: 14,
              border: 'none',
              marginLeft: 10
            }}
          >
            {/* <MenuItem value={this.props.competencyid + '-' + 'None'}>
              <em>None</em>
            </MenuItem> */}
            {subcompetencies_list[competencyid] &&
              Object.keys(subcompetencies_list[competencyid]).map(id => {
                const labelId = `${subcompetencies_list[competencyid][id].id}`
                const labelName = `${subcompetencies_list[competencyid][id].name}`
                let labelNamex = this.filterZZZ(labelName)
                return (
                  // labelId!==this.props.competencyid + '-' + 'None'?
                  // <MenuItem key={labelName} value={labelId}>{labelNamex}</MenuItem>:null
                  <MenuItem key={labelName} value={labelId}>{labelNamex}</MenuItem>
                )
              })}
          </Select>
        </FormControl>
      </div>
    )
  }
}

SubCompetencyOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  competencyid: PropTypes.string.isRequired
}

const SubCompetencyOptionsStyled = withStyles(styles)(SubCompetencyOptions)

const mapStateToProps = state => {
  return {
    subcompetencies_list: state.subcompetenciesList.subcompetencies_list
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSubCompetencies: params => dispatch(fetchSubCompetencies(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubCompetencyOptionsStyled)
