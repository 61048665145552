import React, { Component } from "react";
import PropTypes from "prop-types";
import GroupThoughtsView from "../components/Views/GroupThoughtsView";
import GroupArticlesView from "../components/Views/GroupArticlesView";
import GroupPodcastsView from "../components/Views/GroupPodcastsView";
import GroupVideosView from "../components/Views/GroupVideosView";
import FileViewer from "../components/FileViewer";
import { Tabs, Tab, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Link, AppBar, Button } from "@material-ui/core";
import { connect } from "react-redux";
import ChatIcon from "@material-ui/icons/Chat";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {
  LIGHTBLUE,
  DARKBLUE
} from "../utils/color_utils";
import { showToast } from '../store/actions/toastAction'
import { fetchThoughts } from "../store/actions/thoughtsActions";
import { fetchUserData } from "../store/actions/userActions";
import { SET_SEEN_NOTIFICATIONS, SELECTED_GROUP_POSTS, SELECTED_GROUP_FILES, SUPPORTED_FILE_TYPES, SHOW_TOAST, SCHEDULED_GROUP_POSTS } from "../store/actions/types";
import { deletePostAttachment } from "./../store/actions/attachmentActions";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

class GroupPosts extends Component {
  constructor (props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      minData: 10,
      group_id: "",
      tabsvalue: 0,
      openfileviewer: false,
      filename: "",
      filetype: "",
      filepath: "",
      ismyposts: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 10
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    // load files
    this.props.fetchThoughts({
      type: SELECTED_GROUP_FILES,
      companyID: this.props.userdata.companyID,
      minData: this.state.minData + 50,
      group_id: this.state.group_id
      // userID: this.props.userdata.userID,
      // companyName: this.props.userdata.companyName,
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    });
    if (window.location.pathname === "/myposts") {
      this.setState({
        ismyposts: true
      });
    }
    window.addEventListener("scroll", this.handleScroll);
    // this.fetchData();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount = () => {
    // this.fetchData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.group_id !== "") {
      if (prevProps.group_id !== this.props.group_id) {
        this.setState({ group_id: this.props.group_id })
        this.fetchDataInit();
        this.props.updateGroupId();
      }
    }

  }
  handleTabsChange = (event, tabsvalue) => {
    this.props.fetchThoughts({
      type: SELECTED_GROUP_FILES,
      companyID: this.props.userdata.companyID,
      minData: this.state.minData + 50,
      group_id: this.state.group_id
      // userID: this.props.userdata.userID,
      // companyName: this.props.userdata.companyName,
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    });
    this.setState({ tabsvalue });
  };

  fetchData = () => {
    if (window.location.pathname === "/myposts") {
      var params = {
        type: SELECTED_GROUP_POSTS,
        companyID: this.props.userdata.companyID,
        orderChild: "journalUserID",
        equalTo: this.props.userdata.userID,
        minData: this.state.minData,
        group_id: this.state.group_id
      };

      var params2 = {
        type: SCHEDULED_GROUP_POSTS,
        companyID: this.props.userdata.companyID,
        orderChild: "journalUserID",
        equalTo: this.props.userdata.userID,
        minData: this.state.minData,
        group_id: this.state.group_id
        // userID: this.props.userdata.userID,
        // companyName: this.props.userdata.companyName,
        // firstName: this.props.userdata.firstName,
        // lastName: this.props.userdata.lastName,
        // points: this.props.userdata.points ? this.props.userdata.points : ''
      };
      this.setState({
        ismyposts: true
      });

      this.props.fetchThoughts(params2);
    } else {
      var params = {
        type: SELECTED_GROUP_POSTS,
        companyID: this.props.userdata.companyID,
        orderChild: "dailyThoughtType_status",
        equalTo: "4_approved",
        minData: this.state.minData,
        group_id: this.state.group_id
        // userID: this.props.userdata.userID,
        // companyName: this.props.userdata.companyName,
        // firstName: this.props.userdata.firstName,
        // lastName: this.props.userdata.lastName,
        // points: this.props.userdata.points ? this.props.userdata.points : ''
      };
    }

    this.props.fetchThoughts(params);

    this.props.fetchUserData({
      type: SET_SEEN_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      unseenotifications: this.props.unseenotifications.unseenotifications,
      companyName: this.props.userdata.companyName
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // userID: this.props.userdata.userID,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    });

    this.props.fetchThoughts({
      type: SELECTED_GROUP_FILES,
      companyID: this.props.userdata.companyID,
      minData: this.state.minData + 50,
      group_id: this.state.group_id
      // LoggedUserID: this.props.userdata.userID,
      // companyName: this.props.userdata.companyName,
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    });
  };

  fetchDataInit = () => {
    if (window.location.pathname === "/myposts") {
      var params = {
        type: SELECTED_GROUP_POSTS,
        companyID: this.props.userdata.companyID,
        orderChild: "journalUserID",
        equalTo: this.props.userdata.userID,
        minData: this.state.minData,
        group_id: this.props.group_id
      };

      var params2 = {
        type: SCHEDULED_GROUP_POSTS,
        companyID: this.props.userdata.companyID,
        orderChild: "journalUserID",
        equalTo: this.props.userdata.userID,
        minData: this.state.minData,
        group_id: this.state.group_id
        // userID: this.props.userdata.userID,
        // companyName: this.props.userdata.companyName,
        // firstName: this.props.userdata.firstName,
        // lastName: this.props.userdata.lastName,
        // points: this.props.userdata.points ? this.props.userdata.points : ''
      };

      this.setState({
        ismyposts: true
      });

      this.props.fetchThoughts(params2);
    } else {
      var params = {
        type: SELECTED_GROUP_POSTS,
        companyID: this.props.userdata.companyID,
        orderChild: "dailyThoughtType_status",
        equalTo: "4_approved",
        minData: this.state.minData,
        group_id: this.props.group_id
        // userID: this.props.userdata.userID,
        // companyName: this.props.userdata.companyName,
        // firstName: this.props.userdata.firstName,
        // lastName: this.props.userdata.lastName,
        // points: this.props.userdata.points ? this.props.userdata.points : ''
      };
    }

    this.props.fetchThoughts(params);

    this.props.fetchUserData({
      type: SET_SEEN_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      unseenotifications: this.props.unseenotifications.unseenotifications,
      companyName: this.props.userdata.companyName
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    });

    this.props.fetchThoughts({
      type: SELECTED_GROUP_FILES,
      companyID: this.props.userdata.companyID,
      minData: this.state.minData + 50,
      group_id: this.state.group_id,
      userID: this.props.userdata.userID
      // companyName: this.props.userdata.companyName,
      // firstName: this.props.userdata.firstName,
      // lastName: this.props.userdata.lastName,
      // points: this.props.userdata.points ? this.props.userdata.points : ''
    });
  };

  getFileType = (url, filename) => {
    // console.log("filename", filename)
    // console.log("url", url)
    var lastDot = filename.lastIndexOf('.');
    const ext = filename.substring(lastDot + 1);
    this.setState({
      ...this.state,
      filepath: url,
      filetype: ext,
      tabsvalue: 2
    })
    // return ext;
  }

  onError(e) {
    // console.log("Error: ", e);
  }

  handleFileViewerClose = () => {
    this.setState(state => ({
      openfileviewer: false,
      filename: "",
      filetype: "",
      filepath: ""
    }))
  };

  openGroupFile = (file) => {
    console.log('green openGroupfile : ', file)
    // let filename = file.filename;
    // let filepath = file.filepath;
    // let lastDot = filename.lastIndexOf('.');
    // const ext = filename.substring(lastDot + 1);
    // filename = filename.substring(0, filename.lastIndexOf('.'));
    window.open(file.filepath);
   }

  openFileViewer = (file) => {
    console.log('green inside openFileViewer')
    let filename = file.filename;
    let filepath = file.filepath;
    let lastDot = filename.lastIndexOf('.');
    const ext = filename.substring(lastDot + 1);
    filename = filename.substring(0, filename.lastIndexOf('.'));

    if (SUPPORTED_FILE_TYPES[ext]) {
      this.setState(state => ({
        openfileviewer: true,
        filetype: ext,
        filepath: filepath,
        filename: filename
      }))
    } else {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Unsupported file type!'
      }
      this.props.showToast(params)
    }
  };

  render() {
    const { tabsvalue, filepath, filetype, filename, openfileviewer, ismyposts } = this.state;
    const { groupposts, userdata, classes, groupfiles, scheduledgroupposts } = this.props;
    const preventDefault = event => event.preventDefault();
    return (
      <div>
        <div style={{ tabsdiv }}>
          <AppBar position="static" color="default">
            <Tabs
              value={tabsvalue}
              onChange={this.handleTabsChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="group tabs"
              style={{ tabsInnerDiv }}
            >
              <Tab aria-label="posts" label="Posts" />
              <Tab aria-label="files" label="Files" />
              {ismyposts ? <Tab aria-label="scheduled" label="Scheduled" /> : null}
            </Tabs>
          </AppBar>
          {tabsvalue === 0 && (
            <TabContainer>
              <div>
                {groupposts ?
                  Object.keys(groupposts).map(id => {
                    if (groupposts && groupposts[id] && groupposts[id].files) {
                      // console.log("files: ", groupposts[id]);
                    }
                    return groupposts[id].postGroupType === "thoughts" ? (
                      <GroupThoughtsView
                        thought={groupposts[id]}
                        currentUser={userdata}
                        {...this.props}
                        openFileViewer={this.openFileViewer}
                      />
                    ) : groupposts[id].postGroupType === "articles" ? <GroupArticlesView grouparticle={groupposts[id]}
                      currentUser={userdata}
                      {...this.props} openFileViewer={this.openFileViewer} /> : groupposts[id].postGroupType === "podcasts" ? <GroupPodcastsView podcast={groupposts[id]}
                        currentUser={userdata}
                        {...this.props} /> : groupposts[id].postGroupType === "videos" ? <GroupVideosView video={groupposts[id]}
                          currentUser={userdata}
                          {...this.props} /> : null;
                  })
                  : <div style={{ textAlign: 'center', padding: '100px 20px 20px' }}>No posts available</div>}
              </div>
            </TabContainer>
          )}
          {tabsvalue === 1 && (
            <TabContainer>
              <Paper component={Paper}>
                <Table style={{ minWidth: 400 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Group Files</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupfiles ?
                      Object.values(groupfiles).map(row => {
                        return (
                          <TableRow key={row.url}>
                            <TableCell align="left">
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                  let file = {
                                    filepath: row.url,
                                    filename: row.title,
                                  }
                                  // this.openFileViewer(file); //just open file
                                  this.openGroupFile(file);     //download file
                                }}
                              >
                                {row.title}
                              </Link>
                            </TableCell>
                          </TableRow>
                        )
                      }) : <div> No files available</div>}
                  </TableBody>
                </Table>
              </Paper>
            </TabContainer>
          )}
          {tabsvalue === 2 && (
            <TabContainer>
              <div>
                {scheduledgroupposts ?
                  Object.keys(scheduledgroupposts).map(id => {
                    if (scheduledgroupposts && scheduledgroupposts[id] && scheduledgroupposts[id].files) {
                      // console.log("files: ", groupposts[id]);
                    }
                    return scheduledgroupposts[id].postGroupType === "thoughts" ? (
                      <GroupThoughtsView
                        thought={scheduledgroupposts[id]}
                        currentUser={userdata}
                        {...this.props}
                        openFileViewer={this.openFileViewer}
                      />
                    ) : scheduledgroupposts[id].postGroupType === "articles" ? <GroupArticlesView grouparticle={scheduledgroupposts[id]}
                      currentUser={userdata}
                      {...this.props} openFileViewer={this.openFileViewer} /> : scheduledgroupposts[id].postGroupType === "podcasts" ? <GroupPodcastsView podcast={scheduledgroupposts[id]}
                        currentUser={userdata}
                        {...this.props} /> : scheduledgroupposts[id].postGroupType === "videos" ? <GroupVideosView video={scheduledgroupposts[id]}
                          currentUser={userdata}
                          {...this.props} /> : null;
                  })
                  : <div style={{ textAlign: 'center', padding: '100px 20px 20px' }}>No posts available</div>}
              </div>
            </TabContainer>
          )}
        </div>
        <FileViewer
          filetype={filetype}
          filename={filename}
          filepath={filepath}
          opendialog={openfileviewer}
          handleClose={this.handleFileViewerClose} />
      </div>
    );
  }
}

GroupPosts.propTypes = {
  fetchThoughts: PropTypes.func.isRequired,
  groupposts: PropTypes.any,
  groupfiles: PropTypes.any,
  userdata: PropTypes.object.isRequired
};

const tabsdiv = {
  flexGrow: 1,
  width: "100%!important",
  backgroundColor: "#fff",
  marginTop: "16px"
}

const tabsInnerDiv = {
  marginTop: '16px',
  background: '#fff!important',
  borderBottom: '1px solid #e8e8e8'
}

const mapStateToProps = state => {
  return {
    groupposts: state.thoughts.groupposts,
    scheduledgroupposts: state.thoughts.scheduledgroupposts,
    groupfiles: state.thoughts.groupfiles,
    userdata: state.userDialog.userDetails[0],
    unseenotifications: state.userdata.unseenotifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    fetchThoughts: params => dispatch(fetchThoughts(params)),
    deletePostAttachment: params => dispatch(deletePostAttachment(params)),
    fetchUserData: params => dispatch(fetchUserData(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupPosts);
