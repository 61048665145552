import { STORE_DRAWER } from '../actions/types';

const initState = {
    type: STORE_DRAWER,
    xdrawer: [],
    compadded: ""
};

const drawerReducer = (state = initState , action) => {

  switch(action.type){
    case STORE_DRAWER:
      return{ 
        ...state,
        type: STORE_DRAWER,
        xdrawer: action.xdrawer
        // : action.compadded
        // type: STORE_DRAWER,
        // xdrawer: {
        //   ...state.xdrawer,
        //   ...action.xdrawer
        // }
      }
      default: {
        // return {
        //   ...state
        // }
        return state;
      }
  }
  
}

export default drawerReducer