// =========
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'

// =========
class LoaderBar extends React.Component {
  // ----------
  // DISPLAY: Hide / Show LoaderBar depending on showprogress attribute set in custom html tag
  render() {
    return (
      <div>
        {
          (this.props.showprogress) ? (
            <div>
              <div id='loader_parent'>
                <div id='loader' />
                <span id='loader_text'>Please wait...</span>
              </div>
            </div>
          ) : null
        }
      </div>
    )
  }
}

// =========
export default LoaderBar
