import {
  UPDATE_POST_LIKING,
  BASE_API_URL,
  GLP_LOGGED_PROFILE,
  postTypes
} from "./types";
import axios from "axios";
import firebase from "../firebase";
import { DATE_FORMAT } from "../../utils/date_format_utils";

const loggedInProfile = JSON.parse(localStorage.getItem(GLP_LOGGED_PROFILE));

export const likedBy = () => { };

export const updateLikePost = (params, post, userdata) => {
  return (dispatch, getState) => {

    let gender = userdata && userdata.gender ? userdata.gender : "not_specified";
    let points = userdata && userdata.points ? userdata.points : "not_specified";
    let department = userdata && userdata.department ? userdata.department : "not_specified";
    let nationality = userdata && userdata.nationality ? userdata.nationality : "not_specified";
    let operation = userdata && userdata.operation ? userdata.operation : "not_specified";
    let race = userdata && userdata.race ? userdata.race : "not_specified";

    let groupid = post && post.groupid ? post.groupid : "not_specified";
    let competencyid = post && post.competencyid ? post.competencyid : "not_specified";
    let subcompetencyid = post && post.subcompetencyid ? post.subcompetencyid : "not_specified";

    const likeData = {
      companyID: userdata.companyID,
      companyName: userdata.companyName,
      journalUserID: userdata.userID,
      journalUserName:
        userdata.firstName + " " + userdata.lastName,
      photoURL: userdata && userdata.photoURL ? userdata.photoURL : "",
      postType: params.postType,
      postID: params.postID,
      likeUnlike: params.likeUnlike,
      dailyThoughtType: post.dailyThoughtType,
      gender,
      points,
      department,
      operation,
      nationality,
      race,
      groupid,
      competencyid,
      subcompetencyid
    };

    if (post.dailyThoughtType === 4) {
      likeData.groupid = post.groupid
      likeData.groupname = post.groupname
    }

    // // console.log("likeData", likeData)
    return _saveLike(likeData);
    // axios
    //   .post(`${BASE_API_URL}/likes`, likeData)
    //   .then(res => // console.log(res.data))
    //   .catch(err => // console.log(err));
  };
};

function _saveLike(data) {
  console.log('green-saveLike : ', data)
  //if its group posts
  if (data.dailyThoughtType === 4) {
    var dataref = postTypes["groupposts"] + "/" + data.groupid
  } else {
    var dataref = postTypes[data.postType]
  }

  if (data.likeUnlike == "like" || data.likeUnlike == "likes") {
    // get date and time
    var timeInMs = Date.now();
    var likesStringDate = DATE_FORMAT.type(timeInMs, "BADGE");
    var photoURL = "";

    photoURL = data.photoURL;

    if (photoURL == undefined || photoURL == null) {
      photoURL = "";
    }

    let gender = data && data.gender ? data.gender : "not_specified";
    let points = data && data.points ? data.points : "not_specified";
    let department = data && data.department ? data.department : "not_specified";
    let nationality = data && data.nationality ? data.nationality : "not_specified";
    let operation = data && data.operation ? data.operation : "not_specified";
    let race = data && data.race ? data.race : "not_specified";

    let groupid = data && data.groupid ? data.groupid : "not_specified";
    let competencyid = data && data.competencyid ? data.competencyid : "not_specified";
    let subcompetencyid = data && data.subcompetencyid ? data.subcompetencyid : "not_specified";


    var user_post = data.journalUserID + "_" + data.postID;

    var postdata = {
      companyID: data.companyID,
      companyName: data.companyName,
      journalUserID: data.journalUserID,
      journalUserName: data.journalUserName,
      likesDate: timeInMs,
      likesStringDate: likesStringDate,
      photoURL: photoURL,
      postType: postTypes[data.postType],
      itemID: data.postID,
      userPost: user_post,
      gender,
      points,
      department,
      operation,
      nationality,
      race,
      groupid,
      competencyid,
      subcompetencyid
    };

    var clickdata = {
      userID: data.journalUserID,
      userName: data.journalUserName,
      myPhoto: photoURL,
      myCompanyID: data.companyID,
      myCompanyName: data.companyName,
      dateRegistered: timeInMs,
      postType: postTypes[data.postType],
      journalID: data.postID,
      clickType: "posts",
      clickArea: "like",
      clickItem: "button",
      gender,
      points,
      department,
      operation,
      nationality,
      race,
      groupid,
      competencyid,
      subcompetencyid
    };

    var updatedUserData = {};
    updatedUserData[
      dataref +
      "/" +
      data.postID +
      "/likes/" +
      data.journalUserID
    ] = true;
    updatedUserData[
      dataref +
      "/" +
      data.postID +
      "/likesData/" +
      data.journalUserID
    ] = data;
    updatedUserData["user-liked/" + user_post] = postdata;
    updatedUserData["user-clicks/" + user_post] = clickdata;

    // Do a deep-path update
    return firebase.database.ref().update(updatedUserData, function(error) {
      if (error) {
        // console.log("Error updating data:", error);
      } else {
        let countLikes = firebase.database
          .ref(dataref)
          .child(data.postID)
          .child("countLikes");
        let currentCount = countLikes.transaction(function(current) {
          return (current || 0) + 1;
        });
      }
    });
  } else {
    var user_post = data.journalUserID + "_" + data.postID;
    var updatedUserData = {};
    updatedUserData[
      dataref +
      "/" +
      data.postID +
      "/likes/" +
      data.journalUserID
    ] = null;
    updatedUserData[
      dataref +
      "/" +
      data.postID +
      "/likesData/" +
      data.journalUserID
    ] = null;
    updatedUserData["user-liked/" + user_post] = null;

    // Do a deep-path update
    return firebase.database.ref().update(updatedUserData, function(error) {
      if (error) {
        // console.log("Error updating data:", error);
      } else {
        let countLikes = firebase.database
          .ref(dataref)
          .child(data.postID)
          .child("countLikes");

        let currentCount = countLikes.transaction(function(current) {
          return (current || 0) - 1;
        });
      }
    });
  }
}
