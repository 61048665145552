import { COMPETENCIES_LIST } from "../actions/types";

const initState = {
  type: COMPETENCIES_LIST,
  competencies_list: []
};

const competenciesReducer = (state = initState, action) => {
  switch (action.type) {
    case COMPETENCIES_LIST:
      return {
        type: COMPETENCIES_LIST,
        competencies_list: action.competencies_list
      };
    default:
      return state;
  }
};

export default competenciesReducer;
