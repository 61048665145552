import React from 'react'
import './index.css'
import App from './App'
//import { app_config } from './constants'
import ReactDOM from 'react-dom/client';

// 1. Enable only when compiling for the Browser
//import registerServiceWorker from './registerServiceWorker'

// import { unregister } from './registerServiceWorker';

import { Provider } from 'react-redux'
import store from './store'
//import WebFont from 'webfontloader'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <App />
     </Provider>
  </React.StrictMode>
);

// WebFont.load({
//   google: {
//     families: [
//       'PT Serif Web:300,400,700',
//       'Titillium Web:300,400,700',
//       'IBM Plex Sans Condensed',
//       'IBM Plex Sans',
//       'PT Serif',
//       'Roboto Condensed',
//       'Poppins',
//       'Yantramanav'
//     ]
//   }
// })

// const WithProvider = () => (
//   <Provider store={store}>
//     <App />
//   </Provider>
// )

// ReactDOM.render(<WithProvider />, document.getElementById('root'))
// 2. Enable only when compiling for the Browser
//registerServiceWorker()

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';


