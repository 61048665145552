import { SURVEY_STATS_LIST } from "../actions/types";

const initState = {
  type: SURVEY_STATS_LIST,
  survey_stats_list: []
};

const surveyStatsReducer = (state = initState, action) => {
  switch (action.type) {
    case SURVEY_STATS_LIST:
      console.log('action.survey_stats_list : ', action.survey_stats_list)
      return {
        type: SURVEY_STATS_LIST,
        survey_stats_list: action.survey_stats_list
      };

    default:
      return state;
  }
};

export default surveyStatsReducer;
