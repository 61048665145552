import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Videocam, Mic, RemoveRedEye, PersonPin, GroupWork } from "@material-ui/icons";
import { grey, red } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import MyThoughts from "../MyThoughts";
import MyArticles from "../MyArticles";
import MyPodcasts from "../MyPodcasts";
import MyVideos from "../MyVideos";
import MyGroups from "../MyGroups";
import { HIDE_MY_POSTS_TABS } from "./../../store/actions/types";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: grey[900],
    color: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      marginTop: "130px!important"
    }
  },
  tabsIndicator: {
    backgroundColor: "#ca001e"
  },
  tabRoot: {
    color: "#ffffff",
    // "&:hover": {
    //   backgroundColor: red[500],
    //   opacity: 0.9
    // },
    "&$tabSelected": {
      backgroundColor: "#ca001e",
      fontWeight: 700
    }
  },
  tabSelected: {}
});

class MyPosts extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, theme } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>
        {this.props.userdata && !HIDE_MY_POSTS_TABS[this.props.userdata.userType] ?
          <div className={classes.root}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              variant="fullWidth"
            >
              <Tab
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                icon={<PersonPin />}
                label="Messages"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                icon={<RemoveRedEye />}
                label="Articles"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                icon={<Mic />}
                label="Podcasts"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                icon={<Videocam />}
                label="Videos"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                icon={<GroupWork />}
                label="Group Posts"
              />
            </Tabs>
          </div>
          : null
        }
        {this.props.userdata && !HIDE_MY_POSTS_TABS[this.props.userdata.userType] ?
          <React.Fragment>
            {value === 0 && (
              <TabContainer>
                <MyThoughts />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <MyArticles />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <MyPodcasts />
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer>
                <MyVideos />
              </TabContainer>
            )}
            {value === 4 && (
              <TabContainer>
                <MyGroups />
              </TabContainer>
            )}
          </React.Fragment>
          :
          <React.Fragment>
            {value === 0 && (
              <TabContainer>
                <MyGroups />
              </TabContainer>
            )}
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

MyPosts.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
  }
}

const MyPostsStyled = withStyles(styles, { withTheme: true })(MyPosts);

export default connect(
  mapStateToProps,
  null
)(MyPostsStyled)
