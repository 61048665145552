/* eslint-disable no-undefined */
import {
  GET_COMPANY_ANALYTICS,
  GET_ACTIVE_COMPANY_USERS,
  GET_COMPANY_COUNTS,
  GET_ACTIVE_COMPANIES,
  POST_ANALYTICS,
  USER_CLICKS_POST,
  USER_CLICKS_SECTION,
  BASE_API_URL
} from './types'
import firebase from '../firebase'
import axios from 'axios'
import { _toArray } from './commonActions'

const API_ACTIVE_COMPANIES_URL = BASE_API_URL + '/activecompanies'

export const fetchAnalytics = params => {
  switch (params.type) {
  case GET_COMPANY_ANALYTICS:
    return (dispatch, getState) => {
      firebase.database.ref(`${params.ref}`).on('value', function (data) {
        console.log('green fetchAnalytics : ', data.val())
        if (data.val()) {
          dispatch({
            type: GET_COMPANY_ANALYTICS,
            analytics: data.val()
          })
        } else {
          dispatch({
            type: GET_COMPANY_ANALYTICS,
            analytics: {}
          })
        }
      })
    }
  case GET_COMPANY_COUNTS:
    return (dispatch, getState) => {
      firebase.database.ref(`${params.ref}`).on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_COMPANY_COUNTS,
            companycountsanalytics: data.val()
          })
        } else {
          dispatch({
            type: GET_COMPANY_COUNTS,
            companycountsanalytics: {}
          })
        }
      })
    }
  case GET_ACTIVE_COMPANY_USERS:
    return (dispatch, getState) => {
      firebase.database
        .ref(`${params.ref}`)
        .orderByChild('total')
        .limitToLast(10)
        .on('value', function (data) {
          if (data.val()) {
            let sortedData = _toArray(data.val())
            sortedData.sort(function (a, b) {
              if (a.total > b.total) {
                return -1
              }
              if (a.total < b.total) {
                return 1
              }
              return 0
            })
            dispatch({
              type: GET_ACTIVE_COMPANY_USERS,
              activeusersanalytics: sortedData
            })
          } else {
            dispatch({
              type: GET_ACTIVE_COMPANY_USERS,
              activeusersanalytics: []
            })
          }
        })
    }
  case GET_ACTIVE_COMPANIES:
    return (dispatch, getState) => {
      firebase.database.ref(`${params.ref}`).on('value', function (data) {
        let sortedData = _toArray(data.val())
        sortedData.sort(function (a, b) {
          if (a.companyName < b.companyName) {
            return -1
          }
          if (a.companyName > b.companyName) {
            return 1
          }
          return 0
        })
        if (data.val()) {
          dispatch({
            type: GET_ACTIVE_COMPANIES,
            activeCompanies: sortedData
          })
        } else {
          dispatch({
            type: GET_ACTIVE_COMPANIES,
            activeCompanies: []
          })
        }
      })
    }

  default:
    return (dispatch, getState) => {
      dispatch({
        type: GET_COMPANY_ANALYTICS,
        analytics: {}
      })
    }
  }
}

export const fetchActiveCompanies = () => {
  return (dispatch, getState) => {
    axios
      .get(API_ACTIVE_COMPANIES_URL)
      .then(res => {
        dispatch({
          type: GET_ACTIVE_COMPANIES,
          activeCompanies: res.data.data
        })
      })
      .catch(err => console.log(err))
  }
}

export const fetchPostAnalytics = id => {
  return (dispatch, getState) => {
    // update new data
    axios
      .get(`${BASE_API_URL}/postsanalytics/${id}`)
      .then(res => {
        dispatch({
          type: POST_ANALYTICS,
          postsanalytics: res.data.data
        })
      })
      .catch(err => {
        if (err.response) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'warning',
            message: '' + err.response.data.message
          })
          dispatch({
            type: POST_ANALYTICS,
            postsanalytics: {}
          })
        } else if (err.request) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'warning',
            message: '' + err.request
          })
          dispatch({
            type: POST_ANALYTICS,
            postsanalytics: {}
          })
        } else {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'warning',
            message: '' + err.message
          })
          dispatch({
            type: POST_ANALYTICS,
            postsanalytics: {}
          })
        }
      })
  }
}

export const captureUserActivity = params => {
  switch (params.type) {
  case USER_CLICKS_POST:
    return (dispatch, getState) => {
      let database = firebase.database
      let userID = params.userdata.userID
      let userName = params.userdata.firstName + ' ' + params.userdata.lastName
      let myPhoto
      let myCompanyID = params.userdata.companyID
      let myCompanyName = params.userdata.companyName
      let dateRegistered = Date.now()
      let postType = params.postType
      // e.g. thoughts

      if (params.userdata.photoURL !== undefined || params.userdata.photoURL !== '') {
        myPhoto = params.userdata.photoURL
      } else {
        myPhoto = ''
      }

      if (myPhoto === undefined) {
        myPhoto = ''
      }

      // var filteredArray = this.findObjectByKey(this.thoughtsData, "dailyThoughtID", journalID);
      let filteredArray = params.post

      let journalID = params.post[params.postTypeID]
      let journalUserID = filteredArray.journalUserID || filteredArray.userID
      let journalUserName = filteredArray.journalUserName || filteredArray.userName
      let journalCompanyID = filteredArray.companyID
      let journalCompanyName = filteredArray.companyName
      let journalDateRegistered = filteredArray.dateRegistered
      let journalPhotoURL

      if (filteredArray.photoURL !== undefined || filteredArray.photoURL !== '') {
        journalPhotoURL = filteredArray.photoURL
      } else {
        journalPhotoURL = ''
      }

      if (journalPhotoURL === undefined) {
        journalPhotoURL = ''
      }

      let data = {
        userID: userID,
        userName: userName,
        myPhoto: myPhoto,
        myCompanyID: myCompanyID,
        myCompanyName: myCompanyName,
        dateRegistered: dateRegistered,
        postType: postType,
        journalID: journalID,
        journalUserID: journalUserID,
        journalUserName: journalUserName,
        journalCompanyID: journalCompanyID,
        journalCompanyName: journalCompanyName,
        journalDateRegistered: journalDateRegistered,
        journalPhotoURL: journalPhotoURL,
        clickType: 'posts',
        clickArea: params.res,
        clickItem: params.obj
      }

      database.ref('user-clicks').push(data).then(() => {
        dispatch({
          type: USER_CLICKS_POST
        })
      }).catch((err) => {
        dispatch({
          type: USER_CLICKS_POST
        })
      })
    }
  case USER_CLICKS_SECTION:
    return (dispatch, getState) => {
      let database = firebase.database
      let userID = params.userdata.userID
      let userName = params.userdata.firstName + ' ' + params.userdata.lastName
      let myPhoto
      let myCompanyID = params.userdata.companyID
      let myCompanyName = params.userdata.companyName
      let dateRegistered = Date.now()

      if (params.userdata.photoURL !== undefined || params.userdata.photoURL !== '') {
        myPhoto = params.userdata.photoURL
      } else {
        myPhoto = ''
      }

      if (myPhoto == undefined) {
        myPhoto = ''
      }

      let data = {
        userID: userID,
        userName: userName,
        myPhoto: myPhoto,
        myCompanyID: myCompanyID,
        myCompanyName: myCompanyName,
        dateRegistered: dateRegistered,
        clickType: 'section',
        clickArea: params.page
      }

      database.ref('user-clicks').push(data).then(() => {
        dispatch({
          type: USER_CLICKS_SECTION
        })
      }).catch((err) => {
        dispatch({
          type: USER_CLICKS_SECTION
        })
      })
    }
  default:
    return (dispatch, getState) => {
      dispatch({
        type: USER_CLICKS_POST
      })
    }
  }
}
