"use strict";
import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CommentIcon from "@material-ui/icons/Comment";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { green, red, grey, purple, blue, lime } from "@material-ui/core/colors";

const styles = theme => ({
  impressionsIcon: {
    width: 25
  },
  counter: {
    margin: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  },
  personIcon: {
    color: grey[500]
  },
  peopleIcon: {
    color: green[500]
  },
  postIcon: {
    color: red[500]
  },
  commentIcon: {
    color: purple[500]
  },
  likesIcon: {
    color: blue[500]
  },
  taskIcon: {
    color: lime[500]
  },
  listBg: {
    backgroundColor: grey[100]
  }
});

const Impression = props => {
  const { classes, item, id, index } = props;
  const icons = [
    {
      icon: color => (
        <PersonIcon
          color="primary"
          className={classNames(classes.impressionsIcon, classes.personIcon)}
        />
      ),
      label: "Total No. of Users",
      color: grey[500]
    },
    {
      icon: color => (
        <PeopleIcon
          color="primary"
          className={classNames(classes.impressionsIcon, classes.peopleIcon)}
        />
      ),
      label: "Total Active Users",
      color: green[500]
    },
    {
      icon: color => (
        <ViewQuiltIcon
          color="primary"
          className={classNames(classes.impressionsIcon, classes.postIcon)}
        />
      ),
      label: "Post",
      color: red[500]
    },
    {
      icon: color => (
        <CommentIcon
          color="primary"
          className={classNames(classes.impressionsIcon, classes.commentIcon)}
        />
      ),
      label: "Comments",
      color: purple[500]
    },
    {
      icon: color => (
        <FavoriteIcon
          color="primary"
          className={classNames(classes.impressionsIcon, classes.likesIcon)}
        />
      ),
      label: "Likes",
      color: blue[500]
    },
    {
      icon: color => (
        <MergeTypeIcon
          color="primary"
          className={classNames(classes.impressionsIcon, classes.taskIcon)}
        />
      ),
      label: "Leadership Fitness task Created",
      color: lime[500]
    }
  ];
  return (
    <ListItem
      divider={true}
      className={classNames(index % 2 !== 0 ? classes.listBg : null)}
    >
      <ListItemAvatar>{icons[index].icon(icons[index].color)}</ListItemAvatar>
      <ListItemText primary={icons[index].label} />
      <ListItemSecondaryAction>
        <Typography variant="subtitle1" className={classNames(classes.counter)}>
          {item.count}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ImpressionsList = props => {
  const { classes, companycountsanalytics, analytics } = props;
  console.log('impressionsList : ', companycountsanalytics)
  const stats = [
    {
      count: companycountsanalytics ? companycountsanalytics.noofusers || 0 : 0
    },
    {
      count: analytics ? analytics.activeusers || 0 : 0
    },
    {
      count: analytics
        ? analytics.articles ||
        0 + analytics.podcasts ||
        0 + analytics.thoughts ||
        0 + analytics.videos ||
        0
        : 0
    },
    {
      count: analytics ? analytics.comments || 0 : 0
    },
    {
      count: analytics ? analytics.likes || 0 : 0
    },
    {
      count: analytics ? analytics.pldp || 0 : 0
    }
  ];
  // console.log('analytics.articles: ', analytics.articles);

  return (
    <React.Fragment>
      <Typography variant="h6" color="inherit" className={classes.grow}>
        {props.heading}
      </Typography>
      <Paper>
        <List dense={true}>
          {" "}
          {stats.map((v, i) => (
            // <Impression item={v} index={i} key={i} {...props} />
            <Impression item={v} index={i} id={i} {...props} />
          ))}
        </List>
      </Paper>
    </React.Fragment>
  );
};

export default withStyles(styles)(ImpressionsList);
