/* eslint-disable no-inline-comments */
/* eslint-disable no-shadow */
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
// import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { LIGHTBLUE, LIGHTGREY, DEEPORANGE, PINK, LIGHTBLUE300 } from '../../utils/color_utils'
import { getAllCompanyGroups, deleteGroup } from '../../store/actions/companyAdminActions'

function getStatus() {
  return ['Yet_To_Start', 'Started', 'Completed', 'Graduated'];
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  linkBtn: {
    backgroundColor: `${LIGHTBLUE}`,
    color: '#ffffff',
    '&:hover, &:focus': {
      textDecoration: 'none',
      backgroundColor: `${LIGHTBLUE300}`,
    },
    '&:active': {
      textDecoration: 'none',
      backgroundColor: `${LIGHTBLUE300}`,
    },
  },
  icon: {
    margin: theme.spacing.unit * 2
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  groupslabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: `${LIGHTBLUE}`,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderBottom: `1px solid ${LIGHTGREY}`,
    padding: theme.spacing()
  },
  groupText: {
    fontSize: '0.875rem!important'
  },
  badge: {
    backgroundColor: DEEPORANGE,
    padding: 3,
    borderRadius: 4,
    color: '#fff',
    marginLeft: '12px'
  }
})

class GroupsListAdmin extends Component {
  state = {
    groups: [],
    initPosts: false,
    checked: [],
    anchorEl: null,
    openMenu: false
  };

  componentWillMount() {
    // this.props.getAllCompanyGroups({
    //   companyID: this.props.userdata.companyID
    // })
  }

  componentDidUpdate() {
    this.state = {
      ...this.state,
      inputSearchGroups: this.props.inputSearchGroups
    };
  }

  handleOpenClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      openMenu: true
    })
  };

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      openMenu: false
    })
  };

  handleDeleteGroup = (name, id) => {
    this.props.handleClick(name, id)

    // if (this.props.groups_users) {
    //   console.log('cannot delete')
    // } else {
    //   console.log('can delete')
    // }
  }

  loadGroupMembers = (name, id) => {
    this.props.handleClick(name, id)
  }



  render() {
    const { classes, company_groups } = this.props
    const status = getStatus();
    return (
      <div>
        <div className={classes.root}>
          <Grid container spacing={2}>
            {company_groups &&
              Object.keys(company_groups).map((group, id) => {
                const group_name = company_groups[group].group_name
                const group_id = company_groups[group].group_id
                const group_status = company_groups[group].group_status
                const group_no = company_groups[group].group_no ? company_groups[group].group_no : ''
                const study_school = company_groups[group].study_school ? company_groups[group].study_school : ''
                const total_users = company_groups[group] && company_groups[group].total_users
                  ? company_groups[group].total_users : 0
                const total_posts = company_groups[group] && company_groups[group].total_posts
                  ? company_groups[group].total_posts : 0
                const plus_users = total_users ? total_users - 3 : total_users
                // {total_posts} posts, 
                return (
                  <Grid item xs={12} sm={4}>
                    <Card>
                      <CardActionArea onClick={() => {
                        this.props.handleClick(company_groups[group])
                      }}>
                        <CardContent>
                          <Typography gutterBottom variant='h6' component='h2'>
                            {group_name}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0,0,0,0.04)' }}>
                              <Typography variant='body2'>Group {group_no} </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='body2'>Study School {study_school} </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='caption'>{total_users} participants </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='caption'>~ {status[group_status - 1]}</Typography>
                            </Grid>
                            <Grid
                              item xs={12}
                              style={{ borderBottom: '1px solid rgba(0,0,0,0.04)' }}
                            >
                            </Grid>

                          </Grid>
                        </CardContent>
                      </CardActionArea>
                      <CardActions style={{ float: 'right' }}>
                        <NavLink to={`/groupdetails/${group_id}`} className={classes.linkBtn}>
                          <Button
                            size='small'
                            variant='contained'
                            className={classes.linkBtn}>
                            Open
                          </Button>
                        </NavLink>
                      </CardActions>
                    </Card>
                  </Grid>)
              })}
          </Grid>
        </div>
      </div>
    )
  }
}

GroupsListAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedGroup: PropTypes.func.isRequired,
  inputSearchGroups: PropTypes.string,
  company_groups: PropTypes.object
}

const GroupsListAdminStyled = withStyles(styles)(GroupsListAdmin)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllCompanyGroups: params => dispatch(getAllCompanyGroups(params)),
    deleteGroup: params => dispatch(deleteGroup(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsListAdminStyled)
