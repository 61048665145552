import {
   GET_COMPANY_ADMIN, GET_MY_TOKEN
  } from '../actions/types'
  
  const initialState = {
    admin: {},
    mtoken: {}
  }
  const checkTokenReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_COMPANY_ADMIN:
        console.log('this.props.adminAcc 3 : ', action.oadmin);
          return {
                ...state,
                type: GET_COMPANY_ADMIN,
                oadmin: action.oadmin,
              }  
      case GET_MY_TOKEN:
        console.log('green token reducer G :', action.mtoken);
          return {
            type: GET_MY_TOKEN,
            mtoken: action.mtoken
          }                       
      default:
        return state
    }
  }
  export default checkTokenReducer
  