/* eslint-disable no-undefined */
/* eslint-disable no-inline-comments */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { connect } from 'react-redux'
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import EmailIcon from '@material-ui/icons/Email'
import EditIcon from '@material-ui/icons/Edit'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import TableRow from '@material-ui/core/TableRow'
import { LIGHTBLUE, LIGHTGREY, DEEPORANGE } from '../../utils/color_utils'
import { getAllCompanyGroupUsers } from '../../store/actions/companyAdminActions'


const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  group_userslabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: `${LIGHTBLUE}`,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    // borderBottom: `1px solid ${LIGHTGREY}`,
    padding: theme.spacing()
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  groupText: {
    fontSize: '0.875rem!important'
  },
  badge: {
    backgroundColor: DEEPORANGE,
    padding: 3,
    borderRadius: 4,
    color: '#fff',
    marginLeft: '12px'
  },
  table: {
    minWidth: 650
  }
})


class GroupUsers extends Component {
  state = {
    page: 0,
    rowsPerpage: 10,
    groupId: null
  };

  componentDidMount() {
    if (this.state.groupId !== this.props.groupId) {
      this.setState({ ...this.state, groupId: this.props.groupId })
      this.fetchGroupUsers()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.groupId !== this.props.groupId) {
    //   this.fetchGroupUsers()
    // }
  }

  fetchGroupUsers() {
    this.props.getAllCompanyGroupUsers({
      companyID: this.props.userdata.companyID,
      groupID: this.props.groupId
    })
  }

  getMuiTheme = () =>
  createTheme({
      overrides: {
        MitemataTable: {
          root: {
            backgroundColor: '#FF000'
          },
          paper: {
            boxShadow: 'none'
          },
          responsiveScroll: {
            marginTop: '50px'
          }
        },
        MitemataTableHeadCell: {
          root: {
            padding: '0px',
            verticalAlign: 'top!important'
          },
          head: {
            fontSize: '0.7rem',
            fontWeight: '500',
            padding: '0px',
            verticalAlign: 'textTop'
          }
        },
        MitemataTableBodyCell: {
          root: {
            borderBottom: '0px',
            fontSize: '0.70rem!important',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            padding: '0px 8px 0px 0px'
          }

        },
        MitemataTableSelectCell: {
          root: {
            borderBottom: '0px'
          }
        },
        MitemataTableToolbarSelect: {
          root: {
            display: 'inlineBlock'
          }
        }
      }
    });
  columns = [
    {
      name: 'Firstname',
      options: { filter: true }
    },
    {
      name: 'Lastname',
      options: { filter: true }
    },
    {
      name: 'Email',
      options: { filter: true }
    }
  ];

  options = {
    filterType: 'radio',
    sort: true,
    rowHover: true,
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: 'single',
    fixedHeader: false,
    tableHeaderStyle: { borderRight: '40px solid transparent' }
  };

  transformTableData = () => {
    let { classes, userdata, getAllCompanygroup_Users, group_users } = this.props

    let dataListClone = group_users &&
      Object.keys(group_users).map(item => {
        return [

          group_users[item].firstName,
          group_users[item].lastName,
          group_users[item].email
        ]
      })

    return dataListClone
  };
  handleSelectedUser = currentRowsSelected => {
    // this.setState({
    //  selectedRowData: {
    //    uid: rowUserData[5].props.id,
    //    userID: rowUserData[5].key,
    //    firstName: rowUserData[1],
    //    lastName: rowUserData[2],
    //    email: rowUserData[3],
    //    userType: rowUserData[4]
    //  }
    // });
  };

  click = () => {
    // console.log(this.props)
  }

  sendWelcomeEmail = () => e => {
    console.log("Email sent")
  }

  editGroup = () => e => {
    console.log("group Edited")
  }

  render() {
    const { classes, group_users } = this.props

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} style={{ textAlign: 'left' }} className={classes.root}>
            <Typography
              className={classes.group_userslabel}
              variant='subtitle2'
              gutterBottom
            >
              <Link onClick={this.click}>Group Participants</Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} style={{ textAlign: 'left' }}>
            <Button
              variant='outlined'
              color='primary'
              size='small'
              onClick={this.props.sendWelcomeEmail}
            >
              <EmailIcon className={classes.leftIcon} fontSize='small' />
              SEND WELCOME EMAIL
            </Button>
          </Grid>
          {/* <Grid item xs={6} sm={2} style={{ textAlign: 'left' }}>
            <Button
              variant='outlined'
              color='primary'
              size='small'
              onClick={this.editGroup()}
            >
              <EditIcon className={classes.leftIcon} fontSize='small' />
              EDIT GROUP
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={12} style={{ textAlign: 'left' }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>First Name </TableCell>
                    <TableCell >Last Name</TableCell>
                    <TableCell >Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group_users &&
                    Object.keys(group_users).map(item => {
                      return (
                        <TableRow key={group_users.groupId}>
                          <TableCell component='th' scope='row'>
                            {group_users[item].firstName}
                          </TableCell>
                          <TableCell>{group_users[item].lastName}</TableCell>
                          <TableCell>{group_users[item].email}</TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>

              </Table>
            </TableContainer>
          </Grid>
        </Grid>

      </div>
    )
  }
}

GroupUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedGroup: PropTypes.func.isRequired
}


const GroupUsersStyled = withStyles(styles)(GroupUsers)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    company_groups: state.users.company_groups,
    group_users: state.users.group_users
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllCompanyGroupUsers: params => dispatch(getAllCompanyGroupUsers(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupUsersStyled)
