import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import NoSsr from "@material-ui/core/NoSsr";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { grey, orange } from "@material-ui/core/colors";

import Notifications from "./Notifications";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0, marginTop: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

function LinkTab(props) {
  return (
    <Tab
      component={"button"}
      onClick={event => event.preventDefault()}
      {...props}
    />
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    margin: "0 0"
  },
  tabsRoot: {
    backgroundColor: grey[700]
  },
  tabRoot: {
    fontWeight: theme.typography.fontWeightBold
  },

  tabsIndicator: {
    borderBottom: "4px solid #ff5722",
    backgroundColor: "#ff5722"
  },
  "&$tabSelected": {
    color: grey[50],
    fontWeight: theme.typography.fontWeightMedium
  },
  tabSelected: {
    color: grey[50],
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: "#ff5722"
  },
  topBarStyle: {
    borderBottom: "6px solid #ff5722"
  }
});

class ViewNotifications extends React.Component {
  state = {
    value: 0,
    option: "notifications"
  };

  handleChange = (event, value) => {
    this.setState({
      value,
      option: value === 0 ? "notifications" : value === 1 ? "pldpnotifications" : "groupnotifications"
    });
  };

  componentDidMount() {
    this.setState({
      value: this.props.option === "notifications" ? 0 : 1,
      option: this.props.option
    });
  }

  handleClose = e => {
    this.props.handleClose(e);
  };

  followTabs = ({ classes }) => (
    <AppBar position="sticky" className={classes.topBarStyle}>
      <Tabs
        variant="fullWidth"
        value={this.state.value}
        onChange={this.handleChange}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator
        }}
      >
        <LinkTab
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected
          }}
          label="POSTS"
        />
        <LinkTab
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected
          }}
          label="PLFP"
        />
        <LinkTab
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected
          }}
          label="GROUPS"
        />
      </Tabs>
    </AppBar>
  );
  render() {
    const { classes, option } = this.props;
    const { value } = this.state;

    return (
      <NoSsr>
        <div className={classes.root}>
          {this.followTabs({ ...this.props })}
          {value === 0 && (
            <TabContainer style={{ padding: "4px 8px" }}>
              <Notifications
                handleClose={this.handleClose}
                option={this.state.option}
              />
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <Notifications
                handleClose={this.handleClose}
                option={this.state.option}
              />
            </TabContainer>
          )}
          {value === 2 && (
            <TabContainer style={{ padding: "4px 8px" }}>
              <Notifications
                handleClose={this.handleClose}
                option={this.state.option}
              />
            </TabContainer>
          )}
        </div>
      </NoSsr>
    );
  }
}

ViewNotifications.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewNotifications);
