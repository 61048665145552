import {
    GET_AI
  } from '../actions/types'
  
  const initialState = {
    airesponse: ''
  }
  const chatGPTReducer = (state = initialState, action) => {
    switch (action.type) {  
      case GET_AI:
        return {
          ...state,
          type: GET_AI,
          gptanswer: action.gptanswer,
        } 
      default:
        return state
    }
  }
  export default chatGPTReducer
  