//=========
//LIBS
import authReducer from "./authReducer";
import thoughtsReducer from "./thoughtsReducer";
import articlesReducer from "./articlesReducer";
import podcastsReducer from "./podcastsReducer";
import videosReducer from "./videosReducer";
import pldpReducer from "./pldpReducer";
import analyticsReducer from "./analyticsReducer";
import userDialogReducer from "./userDialogReducer";
import feedbackReducer from "./feedbackReducer";
import tutorialsReducer from "./tutorialsReducer";
import { combineReducers } from "redux";
import toastReducer from "./toastReducer";
import likesReducer from "./likesReducer";
import userReducer from "./userReducer";
import loggedInCompanyReducer from "./loggedInCompanyReducer";
import userBiographyReducer from "./userBiographyReducer";
import followerReducer from "./followerReducer";
import commentsReducer from "./commentsReducer";
import attachmentReducer from "./attachmentReducer";
import companyAdminReducer from "./companyAdminReducer";
import loaderReducer from "./loaderReducer";
import datepickerReducer from "./datepickerReducer";
import subcompReducer from "./subcompReducer";
import drawerReducer from "./drawerReducer";
import competenciesReducer from "./competenciesReducer";
import competenciesTestReducer from "./competenciesTestReducer";
import subcompetenciesReducer from "./subcompetenciesReducer";
import companiesReducer from "./companiesReducer";
import speedDialReducer from "./speedDialReducer";
import calfReducer from "./calfReducer";
import surveyReducer from "./surveyReducer";
import surveyQReducer from "./surveyQReducer";
import surveyUpdateReducer from "./surveyUpdateReducer";
import surveyAnswerReducer from "./surveyAnswerReducer";
import surveyStatsReducer from "./surveyStatsReducer";
import surveyTestReducer from "./surveyTestReducer";
import simpleReducer from "./simpleReducer";
import simpleValReducer from "./simpleValReducer";
import simpleValReducer2 from "./simpleValReducer2";
import testsDisplayReducer from "./testsDisplayReducer";
import freshCardReducer from "./freshCardReducer";
import testTestReducer from "./testTestReducer";
import testHistoryReducer from "./testHistoryReducer";
import getUserProfileReducer from "./getUserProfileReducer";
import poeReducer from "./poeReducer";
import opinionReducer from "./opinionReducer";
import opinionCReducer from "./opinionCReducer";
import opinionG_Reducer from "./opinionG_Reducer";
import loadingReducer from "./loadingReducer";
import realPointsReducer from "./realPointsReducer"
import checkTokenReducer from "./checkTokenReducer"
import tokenReducer from "./tokenReducer"
import allTokensReducer from "./allTokensReducer"
import chatGPTReducer from "./chatGPTReducer"
//=========
const rootReducer = combineReducers({
  auth: authReducer,
  thoughts: thoughtsReducer,
  articles: articlesReducer,
  podcasts: podcastsReducer,
  videos: videosReducer,
  pldp: pldpReducer,
  analytics: analyticsReducer,
  toast: toastReducer,
  userDialog: userDialogReducer,
  feedback: feedbackReducer,
  tutorials: tutorialsReducer,
  likes: likesReducer,
  speedDialP: speedDialReducer,
  userdata: userReducer,
  loggedInCompany: loggedInCompanyReducer,
  following: followerReducer,
  userbiography: userBiographyReducer,
  commentsReducer: commentsReducer,
  attachmentReducer: attachmentReducer,
  users: companyAdminReducer,
  loader: loaderReducer,
  datepickerReducer: datepickerReducer,
  subcomp: subcompReducer,
  scompname: subcompReducer,
  rdrawer: drawerReducer,
  competenciesList: competenciesReducer,
  competenciesTest: competenciesTestReducer,
  subcompetenciesList: subcompetenciesReducer,
  companiesList: companiesReducer,
  speedDialP: speedDialReducer,
  calf: calfReducer,
  surveyList: surveyReducer,
  surveyQ: surveyQReducer,
  updateSurvey: surveyUpdateReducer,
  collectAnw: surveyAnswerReducer,
  collectTest: surveyTestReducer,
  surveyStat: surveyStatsReducer,
  simpleVal: simpleReducer,
  oneVal: simpleValReducer,
  oneVal2: simpleValReducer2,
  surveyTes: testsDisplayReducer,
  fresh: freshCardReducer,
  testTest: testTestReducer,
  testHistory: testHistoryReducer,
  userInfo: getUserProfileReducer,
  poe: poeReducer,
  opinions: opinionReducer,
  Copinions: opinionCReducer,
  Gopinions: opinionG_Reducer,
  loadingLeaders: loadingReducer,
  realpoints: realPointsReducer,
  mainToken: checkTokenReducer,
  hasToken: tokenReducer,
  allTokens: allTokensReducer,
  gptanswer: chatGPTReducer
});

//=========
export default rootReducer;
