/* eslint-disable no-inline-comments */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import PaletteRoundedIcon from '@material-ui/icons/PaletteRounded'
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded'
import { LIGHTGREY } from '../../utils/color_utils'
import ColorWheel from '../../utils/ColorWheel'

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  palleteStyle: {
    backgroundColor: '#fff'
  }
})

class AdvancedSettings extends React.Component {
  state = {
    expanded: false,
    tabsvalue: 0,
    podcastvalue: 'podcastexternal',
    videovalue: 'videxternal',
    color: this.props.textColor,
    website: '',
    slogan: '',
    open: false
  };

  handleColorChange = color => {
    this.setState({ color, open: false })
    this.props.onChange('textColor', color)
  };

  handleShowColorPallet = () => {
    // // console.log(this.state.open);
    this.setState({ open: true })
  };

  componentDidMount = () => {
    let LOGGED_COMPANY = JSON.parse(localStorage.getItem('GLP_LOGGED_COMPANY'))

    this.setState({
      ...this.state,
      website: LOGGED_COMPANY.companyWebsite || '',
      slogan: LOGGED_COMPANY.companySlogan || ''
    })

    this.props.onChange('website', LOGGED_COMPANY.companyWebsite || '')
    this.props.onChange('slogan', LOGGED_COMPANY.companySlogan || '')
    this.props.onChange('logo', LOGGED_COMPANY.companyLogo || '')
  };

  render() {
    const {
      open,
      color,
      website,
      slogan
    } = this.state
    const { classes } = this.props

    return (
      <React.Fragment>
        <ExpansionPanel>
          <ExpansionPanelSummary
            color='primary'
            expandIcon={<ExpandMoreIcon />}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <SettingsRoundedIcon style={{ color: '#ccbc74', paddingRight: 10, fontSize: 30 }}/>
              <Typography className={classes.heading}>
              Advance Settings (optional)
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{
              padding: '8px 8px 24px',
              backgroundColor: LIGHTGREY,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <ColorWheel open={open} onChange={this.handleColorChange} />
            <div style={{ padding: '8px 16px', backgroundColor: `${color}` }}>
              <TextField
                id='selectedColor'
                variant='filled'
                label='Select text color'
                value={this.state.color}
                className={classes.palleteStyle}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='Toggle color pallet'
                        onClick={this.handleShowColorPallet}
                      >
                        <PaletteRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>

            <TextField
              name='companySlogan'
              label='Enter company slogan'
              multiline
              fullWidth
              margin='normal'
              InputLabelProps={{
                shrink: true
              }}
              aria-describedby='companySlogan'
              value={slogan}
              onChange={event => {
                const { value } = event.target
                this.setState({
                  slogan: value
                })
                this.props.onChange('slogan', value)
              }}
            />
            <TextField
              name='companyWebsite'
              label='Enter company website'
              multiline
              fullWidth
              margin='normal'
              InputLabelProps={{
                shrink: true
              }}
              aria-describedby='companyWebsite'
              value={website}
              onChange={event => {
                const { value } = event.target
                this.setState({
                  website: value
                })
                this.props.onChange('website', value)
              }}
            />
            {/* <Button color="primary">Preview</Button> */}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <br />
      </React.Fragment>
    )
  }
}

AdvancedSettings.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AdvancedSettings)
