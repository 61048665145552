import React, { Component } from "react";
import PropTypes from "prop-types";
import ArticlesView from "../../components/Views/PldpArticlesView";
import { connect } from "react-redux";
import {
  fetchArticles,
  fetchExternalArticles
} from "../../store/actions/articlesActions";
import { PLDP_ARTICLES } from "../../store/actions/types";
import { deletePostAttachment } from "./../../store/actions/attachmentActions";

class Articles extends Component {
  componentWillMount() {
    var params = {
      type: PLDP_ARTICLES,
      orderChild: "PLDPUserID",
      equalTo: this.props.userdata.userID
    };

    this.props.fetchArticles(params);
  }

  render() {
    const { articles, userdata } = this.props;
    return (
      <div>
        {articles ? (
          <React.Fragment>
            <ArticlesView
              articles={articles}
              currentUser={userdata}
              {...this.props}
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

Articles.propTypes = {
  articles: PropTypes.array
};

const mapStateToProps = state => {
  return {
    articles: state.articles.pldparticles,
    userdata: state.userDialog.userDetails[0]
  };
};

export default connect(
  mapStateToProps,
  { fetchArticles, fetchExternalArticles, deletePostAttachment }
)(Articles);
