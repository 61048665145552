import {
  GET_ALL_COMPANY_USERS,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  CREATE_COMPANY_USER,
  GET_ALL_COMPANY_GROUPS,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP,
  CLEAR_GROUP_SUCCESS,
  USER_EXISTS,
  GET_ALL_GROUP_USERS,
  GET_GROUP_DETAILS,
  DELETE_GROUP,
  GET_GROUP_EVENTS,
  GET_GROUP_EVENTS_REMINDERS,
  DELETE_GROUP_EVENT,
  GET_GROUPS_ATTENDANCE_REGISTER,
  GET_COMPANY_PROGRAMMES,
  GET_COMPANY_SURVEYS,
  GET_GROUP_SURVEYS,
  GET_ACTIVE_STUDY_MATERIALS,
  GET_INACTIVE_STUDY_MATERIALS,
  GET_ASSIGNED_STUDY_MATERIALS,
  GET_USER_GROUPS,
  GET_ALL_COMPANY_SETTINGS,
  GET_ALL_GENERAL_SETTINGS,
  LEADERBOARD,
  //GET_ALL_TOKENS,
  //GET_MY_TOKEN,
  //GET_COMPANY_ADMIN,
  //CHECK_TOKEN
  //FIXED_LEADER_LIST
} from '../actions/types'

const initialState = {
  users: {},
  userexists: {},
  company_groups: {},
  group_details: {},
  group_events: [],
  group_events_reminders: {},
  active_study_materials: {},
  inactive_study_materials: {},
  assigned_study_materials: {},
  company_programmes: {},
  company_surveys: {},
  group_surveys: {},
  user_groups: {},
  general_settings: {},
  company_settings: {},
  CREATE_GROUP: ""
}
const companyAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANY_SETTINGS:
      return {
        ...state,
        type: GET_ALL_COMPANY_SETTINGS,
        company_settings: action.company_settings,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_ALL_GENERAL_SETTINGS:
      return {
        ...state,
        type: GET_ALL_GENERAL_SETTINGS,
        general_settings: action.general_settings,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_ALL_COMPANY_USERS:
     // console.log('green this.props.leaderboardData S ', action.users);
      return {
        ...state,
        type: GET_ALL_COMPANY_USERS,
        users: action.users,
        userexists: {},
        CREATE_GROUP: "",
      }
    // case GET_COMPANY_ADMIN:
    //   // console.log('green this.props.leaderboardData S ', action.users);
    //     return {
    //       ...state,
    //       type: GET_COMPANY_ADMIN,
    //       admin: action.admin,
    //     }
    // case GET_ALL_TOKENS:
    //     return {
    //       ...state,
    //       type: GET_ALL_TOKENS,
    //       tokens: action.users,
    //       userexists: {},
    //       CREATE_GROUP: "",
    //     }
    // case CHECK_TOKEN:
    //   console.log('inside askpermission R :', action.hastoken);
    //     return {
    //       type: CHECK_TOKEN,
    //       hasatoken: action.hastoken
    //     }  
    // case GET_MY_TOKEN:
    //   console.log('green token reducer G :', action.mtoken);
    //     return {
    //       type: GET_MY_TOKEN,
    //       mtoken: action.mtoken
    //     }    
    case LEADERBOARD:
      return {
        ...state,
        type: LEADERBOARD,
        leaderboardData: action.leaderboard_action,
        userexists: {},
        CREATE_GROUP: "",
      } 
    // case FIXED_LEADER_LIST:
    //     return {
    //       ...state,
    //       type: FIXED_LEADER_LIST,
    //       leaderboardFixedData: action.leaderboard_action,
    //       userexists: {},
    //       CREATE_GROUP: "",
    //     }         
    case GET_ALL_COMPANY_GROUPS:
      return {
        ...state,
        type: GET_ALL_COMPANY_GROUPS,
        company_groups: action.company_groups,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_ASSIGNED_STUDY_MATERIALS:
      return {
        ...state,
        type: GET_ASSIGNED_STUDY_MATERIALS,
        assigned_study_materials: action.assigned_study_materials,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_USER_GROUPS:
      return {
        ...state,
        type: GET_USER_GROUPS,
        user_groups: action.user_groups,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_GROUPS_ATTENDANCE_REGISTER:
      return {
        ...state,
        type: GET_GROUPS_ATTENDANCE_REGISTER,
        group_attendance_registers: action.group_attendance_registers,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_COMPANY_PROGRAMMES:
      return {
        ...state,
        type: GET_COMPANY_PROGRAMMES,
        company_programmes: action.company_programmes,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_COMPANY_SURVEYS:
      return {
        ...state,
        type: GET_COMPANY_SURVEYS,
        company_surveys: action.company_surveys,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_GROUP_SURVEYS:
      return {
        ...state,
        type: GET_GROUP_SURVEYS,
        group_surveys: action.group_surveys,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_ACTIVE_STUDY_MATERIALS:
      return {
        ...state,
        type: GET_ACTIVE_STUDY_MATERIALS,
        active_study_materials: action.active_study_materials,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_INACTIVE_STUDY_MATERIALS:
      return {
        ...state,
        type: GET_INACTIVE_STUDY_MATERIALS,
        inactive_study_materials: action.inactive_study_materials,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_GROUP_DETAILS:
      return {
        ...state,
        type: GET_GROUP_DETAILS,
        group_details: action.group_details,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_GROUP_EVENTS:
      return {
        ...state,
        type: GET_GROUP_EVENTS,
        group_events: action.group_events,
        userexists: {},
        CREATE_GROUP: "",
      }
    case GET_GROUP_EVENTS_REMINDERS:
      return {
        ...state,
        type: GET_GROUP_EVENTS_REMINDERS,
        group_events_reminders: action.group_events_reminders,
        userexists: {},
        CREATE_GROUP: "",
      }
    case UPDATE_COMPANY_USER:
      return {
        ...state,
        type: UPDATE_COMPANY_USER,
        users: action.users,
        userexists: {},
        CREATE_GROUP: "",
      }
    case DELETE_COMPANY_USER:
      return {
        ...state,
        type: DELETE_COMPANY_USER,
        users: action.users,
        userexists: {},
        CREATE_GROUP: "",
      }
    case CREATE_COMPANY_USER:
      return {
        ...state,
        type: CREATE_COMPANY_USER,
        users: action.users,
        userexists: {},
        CREATE_GROUP: "",
      }
    case USER_EXISTS:
      return {
        ...state,
        type: USER_EXISTS,
        userexists: action.userexists,
        CREATE_GROUP: "",
      }
    case GET_ALL_GROUP_USERS:
      return {
        ...state,
        type: GET_ALL_GROUP_USERS,
        group_users: action.group_users,
        userexists: {},
        CREATE_GROUP: "",
      }
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        userexists: {},
        CREATE_GROUP: CREATE_GROUP_SUCCESS
      };
    case CLEAR_GROUP_SUCCESS:
      return {
        ...state,
        userexists: {},
        CREATE_GROUP: ""
      };
    case DELETE_GROUP:
      return {
        ...state,
        type: DELETE_GROUP,
        group_users: action.group_users,
        userexists: {},
        CREATE_GROUP: "",
      };
    // case  GET_USER_PROFILE:
    //   console.log('green getUserData 4:', action.user)
    //   return {
    //     ...state,
    //     type: GET_USER_PROFILE,
    //     user: action.user,
    //     userexists: {}
    //   };
    default:
      return state
  }
}
export default companyAdminReducer
