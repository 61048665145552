import firebase from '../firebase'
import {
  GLP_LOGGED_PROFILE,
  LOGIN_SUCCESS,
  LOG_USER_PROFILE_DIALOG,
  BASE_API_URL,
  USER_ANALYTICS
} from './types'
import { _toArray } from './commonActions'
import axios from 'axios'
import { loggedInCompanyActions } from './loggedInCompanyActions'

export const loggedInProfileActions = (uid) => {
  console.log('black signInWithGoogle 5:', uid)
  return (dispatch, getState) => {
    if (uid) {
      firebase.database.ref('user/' + uid).on('value', function (res) {
        if (res.val()) {
          console.log('green formattedData : ', res.val())
          let formattedData = []
          formattedData[0] = res.val()
          formattedData[0].id = uid
          formattedData[0].uid = uid
          // formattedData[0].userID = res.val().userID;
          // formattedData[0].companyID = res.val().companyID;
          localStorage.removeItem(GLP_LOGGED_PROFILE)
          localStorage.setItem(GLP_LOGGED_PROFILE, JSON.stringify(res.val()))
          loggedInCompanyActions(res.val().companyID)

          dispatch({
            type: LOG_USER_PROFILE_DIALOG,
            userDetails: formattedData
          })
        } else {
          dispatch({
            type: LOG_USER_PROFILE_DIALOG,
            userDetails: []
          })
        }
      })
      // axios
      //   .get(`${BASE_API_URL}/users`, {
      //     params: {
      //       tblcol: "uid",
      //       tblval: uid
      //     }
      //   })
      //   .then(res => {
      //     var formattedData = [];
      //     formattedData[0] = res.data.data;
      //     formattedData[0].id = uid;
      //     formattedData[0].uid = uid;
      //     localStorage.setItem(
      //       GLP_LOGGED_PROFILE,
      //       JSON.stringify(res.data.data)
      //     );
      //     loggedInCompanyActions(res.data.data.companyID);

      //     dispatch({
      //       type: LOG_USER_PROFILE_DIALOG,
      //       userDetails: formattedData
      //     });
      //     // dispatch({ type: USER_ANALYTICS });
      //   })
      //   .catch(err => {
      //     console.log(err.message);
      //   });
    } else {
      dispatch({
        type: LOG_USER_PROFILE_DIALOG,
        userDetails: []
      })
    }
  }
}

export const loggedInProfileActions2 = (uid) => {
  return (dispatch, getState) => {
    if (uid) {
      firebase.database.ref('users/' + uid).on('value', function (res) {
        if (res.val()) {
          console.log('green formattedData : ', res.val())
          let formattedData = []
          formattedData[0] = res.val()
          formattedData[0].id = uid
          formattedData[0].uid = uid
          // formattedData[0].userID = res.val().userID;
          // formattedData[0].companyID = res.val().companyID;
          localStorage.removeItem(GLP_LOGGED_PROFILE)
          localStorage.setItem(GLP_LOGGED_PROFILE, JSON.stringify(res.val()))
          loggedInCompanyActions(res.val().companyID)

          dispatch({
            type: LOG_USER_PROFILE_DIALOG,
            userDetails: formattedData
          })
        } else {
          dispatch({
            type: LOG_USER_PROFILE_DIALOG,
            userDetails: []
          })
        }
      })
  
    } else {
      dispatch({
        type: LOG_USER_PROFILE_DIALOG,
        userDetails: []
      })
    }
  }
}

export const loggedInSuccess = (userobj) => {
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_SUCCESS, auth: userobj })
  }
}
