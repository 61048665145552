import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PopularTopicsPie from './PopularTopicsPie';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, count, topic_data) {
  let thoughts = topic_data && topic_data.thoughts ? topic_data.thoughts : 0;
  let articles = topic_data && topic_data.articles ? topic_data.articles : 0;
  let podcasts = topic_data && topic_data.podcasts ? topic_data.podcasts : 0;
  let videos = topic_data && topic_data.videos ? topic_data.videos : 0;
  let pldp = topic_data && topic_data.pldp ? topic_data.pldp : 0;

  return {
    name,
    count,
    history: [
      { item: 'Messages', count: thoughts },
      { item: 'Articles', count: articles },
      { item: 'Videos', count: videos },
      { item: 'Podcasts', count: podcasts },
      { item: 'PLDP', count: pldp },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="center">{row.count}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle2" gutterBottom component="div">
                {row.name} Drilldown
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="center">Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.history.map((historyRow) => (
                        <TableRow key={historyRow.item}>
                          <TableCell>{historyRow.item}</TableCell>
                          <TableCell align="center">{historyRow.count}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <PopularTopicsPie history={row.history} />
                  </FormControl>
                </Grid>
              </Grid>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    count: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};


export default function PopularTopicsTable(props) {

  const [data_rows, setDataRows] = useState([]);

  function handleStatusChange(prop_data) {
    let topics_h = [];
    let topics_d = [];
    let graph_d = [];

    let topics = prop_data && prop_data.analytics && prop_data.analytics.topics ? prop_data.analytics.topics : {};

    topics_h.push("Topic")
    topics_d.push("Topic(s)")

    for (const topic in topics) {
      var __FOUND = prop_data.companyValues.find(function(company_value, index) {
        if (company_value.valueID === topic)
          return true;
      });

      let topic_title = __FOUND && __FOUND.valueDesc ? __FOUND.valueDesc : "not_specified"
      let topic_total = topics[topic].total ? topics[topic].total : 0
      let topic_data = topics[topic] ? topics[topic] : {}

      graph_d.push(createData(topic_title, topic_total, topic_data));

      // console.log("topics: ", `${topic_title}: ${topics[topic].total}`);
    }

    // graph_d.push(topics_h)


    console.log("topics: ", graph_d);

    setDataRows(graph_d);
  }

  useEffect(() => {
    handleStatusChange(props);
  }, [props]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Topic</TableCell>
            <TableCell align="center">Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data_rows && data_rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
