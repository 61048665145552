//import React,{Component} from 'react';
import React,{ createRef } from 'react';
import ReactDOM from 'react-dom'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, Row, Col } from 'react-grid-system';
import { showToast } from "../../store/actions/toastAction";
import { createSurvey } from "../../store/actions/SurveyActions";
import { GREY, RED, LIGHTBLUE, LIGHTGREY, MAROONHOVER } from "../../utils/color_utils";
import { updateSurvey } from "../../store/actions/surveyUpdate";
import TestIcon from '@material-ui/icons/QuestionAnswer';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'supercons';
//import {Avatar} from '@material-ui/core'
import Avatar from '@mui/material/Avatar';
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { connect } from 'react-redux'
import {
  getUserProfile
} from '../../store/actions/companyAdminActions'
import {
  addOpinions_ledain, addSOpinion, getOpinionsTrail, FindSlave, getOpinions, getOpinionsOnSubmit
} from '../../store/actions/opinionActions'
import {
  GET_USER_PROFILE, GET_O, SHOW_TOAST, GET_SLAVE_OPINION, GET_OPINIONID, GET_O_S
} from "../../store/actions/types";
import firebase from '../../store/firebase'
import { _toArray, _toArrayUsers, _toArrayUsersx, _toArrayCalendar, _toArrayGroups, _toArrayUsersS } from '../../store/actions/commonActions'
import SendIcon from '@material-ui/icons/Send'
import { withStyles } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { addMorePoints } from '../../store/actions/freshCardActions'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import FollowButton from '../../components/Buttons/Follow2'
import generalEval from './data/generalEval.json'

const table = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #CAC9C8",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 7
}
const tableh = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 10,
  borderRadius: 15
}
const tableM = {
  color: "#515A5A",
  fontSize: 18,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 15
}

const content = {
  margin: "500px",
  height: "800px",
  width: "2000px"
}
const myDIV = {
  height: "250px",
  width: "250px",
  overflow: "auto",
  background: "coral"
}

const buttonStyle = {
  backgroundColor: RED,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const hoverbuttonStyle = {
  backgroundColor: MAROONHOVER,
  color: "#fff",
  width: "120px",
  height: "48px",
  border: "10px solid",
  borderColor: "#fff",
  borderRadius: "15px",
  textDecoration: "none"
};

const styles = {
  mark: {
    width: 250,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  mlist: {
    width: 250,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  survs: {
    width: 1500,
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  row2: {
    //     backgroundColor: 'green',
  },
  col1: {
    backgroundColor: 'yellow',
  },
  col2: {
    backgroundColor: 'brown',
  },
  col: {
    padding: '1rem',
    backgroundColor: '#33b5e5',
    border: '2px solid #fff',
    color: '#fff',
    textAlign: 'center'
  },
  rightIcon: {
    paddingRight: '8px'
  }
};

const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: '#fff',
  cursor: 'pointer'
}

const topB = {
  position: 'fixed',
  bottom: '40px',
  right: '25px',
  zindex: '20',
  visibility: 'hidden',
  position: 'relative'
}

class ViewUser extends React.Component {
  constructor(props) {
    super(props);
    const okanhzai = React.createRef(null);
    this.scollToRef = okanhzai;

    const okanhzaix = React.createRef(null);
    this.targetDivRef = okanhzaix;
  }

  state = {
    Open: false,
    userDetails: {},
    firstName: '',
    lastName: '',
    followers: 0,
    following: 0,
    companyName: '',
    dailythoughts: 0,
    articles: 0,
    videos: 0,
    podccasts: 0,
    biography: '',
    isLoading: 1,
    maxLengthThought: 0,
    showButton: true,
    shownRealtime: false,
    clickedOnMenu: false,
    tac: 0,
    slave: 0,
    simple: 0,
    simpleopinion: "",
    valueA: 0,
    valueAopinion: "",
    valueB: 0,
    valueBopinion: "",
    valueC: 0,
    valueCopinion: "",
    valueD: 0,
    valueDopinion: "",
    valueE: 0, 
    valueEopinion: "", 
    valueF: 0,
    valueFopinion: "",
    valueG: 0,
    valueGopinion: "",
    valueH: 0,
    valueHopinion: "",
    stoggleTac: false,
    stoggleSlave: false,
    stoggleSimple: false,
    stogglevalueA: false,
    stogglevalueB: false,
    stogglevalueC: false,
    stogglevalueD: false,
    stogglevalueE: false,
    stogglevalueF: false,
    stogglevalueG: false,
    stogglevalueH: false,
    is_visible: false,
    hiddenVal: "",
    state_opinionID: "",
    opinionID: "",
    imgURLx: "",
    simpleopinion: "",
    descA: "", 
    headingA: "",
    descB: "", 
    headingB: "",
    descC: "", 
    headingC: "",
    descD: "", 
    headingD: "",
    descE: "", 
    headingE: "",
    descF: "", 
    headingF: "",
    descG: "", 
    headingG: "",
    descH: "", 
    headingH: ""
  };

  async componentDidMount() {
    let { leaderboardData, feedback, opinions, sopinions } = this.props
    const { leaderID, slaveID, findSlave } = this.props;

    if (this.props.slaveID===this.props.leaderID) {
    //  this.setState({ showButton: false })
    }
    console.log('viewUser_self_gen gen ', this.props)
    console.log('handleChecked 1 ', this.state.stoggleTac)
    console.log('generic self eval : ', this.props.slaveID)
    console.log('viewuser props 1 ', this.props);
    await this.fillHeadings()

    if (leaderboardData) {
      this.getUserInfo();
    }
  }

  fillHeadings = () => {
    var n = 0
    generalEval.surveyData.map((surveyData) => {
      if ((surveyData.companyID===this.props.companyID) && (surveyData.type==="self")) {
        if (surveyData.title) {
          n = n + 1;
          switch(n) {
            case 1:
              this.setState({
                descA: surveyData.desc, 
                headingA: surveyData.title
              }) 
              break;
            case 2:
              this.setState({
                descB: surveyData.desc, 
                headingB: surveyData.title
              }) 
              break;
            case 3:
              this.setState({
                descC: surveyData.desc, 
                headingC: surveyData.title
              }) 
              break;
            case 4:
              this.setState({
                descD: surveyData.desc, 
                headingD: surveyData.title
              }) 
              break;
            case 5:
              this.setState({
                descE: surveyData.desc, 
                headingE: surveyData.title
              }) 
              break;
            case 6:
              this.setState({
                descF: surveyData.desc, 
                headingF: surveyData.title
              }) 
              break;
            case 7:
              this.setState({
                descG: surveyData.desc, 
                headingG: surveyData.title
              }) 
              break;
            case 8:
              this.setState({
                descH: surveyData.desc, 
                headingH: surveyData.title
              }) 
              break;
            default:
              
          }
          //n = n + 1;  
          console.log('fillHeadings : ', n)       
        }
      }
    })

  }
    focusTextInput = () => {
      console.log('inside focusTextInput 1')
      React.findDOMNode(this.refs.textInput).click();

      if (this.textInput) {
        this.textInput.click();
        console.log('inside focusTextInput 2')
       }
     };

  getFeedback = () => {
    return new Promise((resolve, reject) => {

      setTimeout(() => {
         if (this.props.feedback) {
           console.log('green populateState 2 : ', this.props.feedback)
          this.populateState(); 
        } else {
          reject('Failed to the user list');
        }
      }, 500);     
    });
  }

  populateState = () => {
    const { feedback } = this.props;
    var feedbackvalueA;
    var feedbackvalueAopinion;
    var feedbackvalueB;
    var feedbackvalueBopinion;
    var feedbackvalueC;
    var feedbackvalueCopinion;
    var feedbackvalueD;
    var feedbackvalueDopinion;
    var feedbackvalueE;
    var feedbackvalueEopinion;
    var feedbackvalueF;
    var feedbackvalueFopinion;
    var feedbackvalueG;
    var feedbackvalueGopinion;
    var feedbackvalueH;
    var feedbackvalueHopinion;

    if (feedback && feedback[0]) {
      Object.keys(feedback[0]).map(id => {
        console.log('inside populateState function 2 : ', feedback[0].valueAopinion);
        //var feedbacksimple = feedback[0].simple;
        feedbackvalueA = feedback[0].valueA;
        feedbackvalueAopinion = feedback[0].valueAopinion;
        feedbackvalueB = feedback[0].valueB;
        feedbackvalueBopinion = feedback[0].valueBopinion;
        feedbackvalueC = feedback[0].valueC;
        feedbackvalueCopinion = feedback[0].valueCopinion;
        feedbackvalueD = feedback[0].valueD;
        feedbackvalueDopinion = feedback[0].valueDopinion;
        feedbackvalueE = feedback[0].valueE;
        feedbackvalueEopinion = feedback[0].valueEopinion;
        feedbackvalueF = feedback[0].valueF;
        feedbackvalueFopinion = feedback[0].valueFopinion;
        feedbackvalueG = feedback[0].valueG;
        feedbackvalueGopinion = feedback[0].valueGopinion;
        feedbackvalueH = feedback[0].valueH;
        feedbackvalueHopinion = feedback[0].valueHopinion;

      this.setState({
        valueA: feedbackvalueA,
        valueAopinion: feedbackvalueAopinion,
        valueB: feedbackvalueB,
        valueBopinion: feedbackvalueBopinion,
        valueC: feedbackvalueC,
        valueCopinion: feedbackvalueCopinion,
        valueD: feedbackvalueD,
        valueDopinion: feedbackvalueDopinion,
        valueE: feedbackvalueE,
        valueEopinion: feedbackvalueEopinion,
        valueF: feedbackvalueF,
        valueFopinion: feedbackvalueFopinion,
        valueG: feedbackvalueG,
        valueGopinion: feedbackvalueGopinion,
        valueH: feedbackvalueH,
        valueHopinion: feedbackvalueHopinion,
       
      })
     }
    )}
    }

    shouldComponentUpdate() {
        return true;
    }

  xxshouldComponentUpdate(prevProps, prevState) {
    console.log('componentDidMount.feedback 3 : ', this.props.feedback)
    console.log('componentDidMount.feedback prevProps: ', prevProps.feedback); 
    console.log('componentDidMount.feedback valueAopinion: ', this.state.valueAopinion); 
   }

   static getDerivedStateFromProps(nextProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    return {
      feedback: nextProps.feedback,
      // ... other derived state properties
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('componentDidMount.feedback 3 : ', this.props.feedback)
    console.log('green componentDidUpdate prevProps: ', prevProps.feedback); // Previous properties of the component.
    console.log('green componentDidUpdate prevState: ', prevState.feedback) // Previous state of the component.
  }

  setFocusName() {
    var Element = document.getElementById("topspot");
    Element.focus();
  }
  
  focusOn() {
    setTimeout(() => {
      this.setFocusName()
    }, 1000)
  }

  getUserInfo() {
    let { userInfo, leaderboardData } = this.props
    console.log('green getUserInfo : ', leaderboardData)
    
    var userInf;
    var userDat;
    var firstName = '';
    var lastName = '';
    var followers = 0;
    var following = 0;
    var companyName = '';
    var dailythoughts = 0;
    var articles = 0;
    var videos = 0;
    var podcasts = 0;
    var biography = '';

    let user = {};

    user = Object.assign({}, this.props.leaderboardData);
    let receivedData = {};
    console.log('red leaderboardMData 1 : ', Object.keys(user).length + ' --- ' + this.props.leaderID);
    delete user.undefined

    console.log('red leaderboardMData 2 : ', Object.keys(user).length + ' --- ' + this.props.leaderID);

    if (Object.keys(user).length > 0) {
      let sortedData = _toArrayUsersS(user, this.props.leaderID)

      console.log('red leaderboardMData 3: ', sortedData);

      sortedData.sort(function (a, b) {
        return b.points - a.points
      });

      receivedData = { ...sortedData }
      receivedData = Object.assign({}, sortedData)
      try {
        if (receivedData[0].unseencounts) 
        { console.log('red leaderboardMData 4: ', receivedData[0].unseencounts) }
      }
      catch (err) {
        console.log('red leaderboardMData 4: ', err)
      }
      try {
        if (receivedData[0]) { firstName = receivedData[0].firstName } else { firstName = 'undefined' }
        if (receivedData[0]) { lastName = receivedData[0].lastName } else { lastName = 'undefined' }
        if (receivedData[0].analytics) { followers = receivedData[0].analytics.followers } else { followers = 0 }
        if (receivedData[0].analytics) { following = receivedData[0].analytics.following } else { following = 0 }
        companyName = receivedData[0].companyName
        if (receivedData[0].unseencounts) { dailythoughts = receivedData[0].unseencounts.dailythoughts } else { dailythoughts = 0 }
        if (receivedData[0].unseencounts) { articles = receivedData[0].unseencounts.articles } else { articles = 0 }
        if (receivedData[0].unseencounts) { videos = receivedData[0].unseencounts.videos } else { videos = 0 }
        if (receivedData[0].unseencounts) { podcasts = receivedData[0].unseencounts.podcasts } else { podcasts = 0 }
        biography = receivedData[0].biography
      }
      catch (err) {
        console.log('red leaderboardMData 5: ', err);

        firstName = 'undefined'
        lastName = 'undefined'
        followers = 0
        following = 0
        companyName = 'undefined'
        dailythoughts = 0
        articles = 0
        videos = 0
        podcasts = 0
        biography = 'undefined'
        console.log('err ViewUser : ', err)
      }

    }

    console.log('red leaderboardMData showButton : ', this.props.slaveID + ' --- ' + this.props.leaderID);

    this.setState({
      isLoading: 0,
      firstName: firstName,
      lastName: lastName,
      followers: followers,
      following: following,
      companyName: companyName,
      dailythoughts: dailythoughts,
      articles: articles,
      videos: videos,
      podcasts: podcasts,
      biography: biography,
      showButton: true//this.props.slaveID===this.props.leaderID ? false : true
    })
     
  }

  getViewerProfile(zuserID) {
    firebase.database
      .ref('user')
      .orderByChild('userID')
      .equalTo(zuserID)
      .on('value', function (data) {
        if (data.val()) {
          var user = data.val()
          let receivedData = {}
          delete user.undefined

          console.log('green getUserData 1: ', user.firstName)
          return user;
        } else {
          console.log('green getUserData 2: null')
          return 0;
        }

      })

  }

  getFollowers = (props) => {
    for (let x in props) {
      return props[x].analytics.followers
    }
  }
  getFollowing = (props) => {
    for (let x in props) {
      return props[x].analytics.following
    }
  }
  getfirstName = (props) => {
    for (let x in props) {
      return props[x].firstName
    }
  }

  //-----------------------------------------
  handleChangeA = () => {
    if (this.state.goodleader) {
      this.setState({ goodleader: false })
    }
    else {
      this.setState({ goodleader: true })
    }

  }

  handleChangeB = () => {
    if (this.state.goodlistener) {
      this.setState({ goodlistener: false })
    }
    else {
      this.setState({ goodlistener: true })
    }

  }
  
 xfindSlave = (leaderID, slaveID) => {
    console.log('blue slave data 2 ')
    var pass = ''; //no post yet
    var database = firebase.database;
     database
      .ref('opinionsTrail')
      .orderByChild('leaderID')
      .equalTo(leaderID)
      .on('value', function (data) {

        if (data.val()) {
          let odata = data.val()
          if (Object.keys(odata).length > 0) {
            let sortedData = _toArrayUsers(odata)
            Object.keys(sortedData).map((id, index) => {

              if (sortedData[id].leaderID === leaderID) {
                if (sortedData[id].slaveID === slaveID) { //user already made opinion on this leader
                 pass = sortedData[id].opinionID; //has posted before
                 return pass;
                  console.log('green submitPost leaderID : ', leaderID)
                  console.log('green submitPost slaveID : ', slaveID)
                  console.log('green submitPost opinionID 2 : ', sortedData[id].opinionID)

                }
              }
            })
          }
        }
      })
    return pass;
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  checkFields = () => {
    const { 
        descA, 
        headingA, 
        descB, 
        headingB,
        descC, 
        headingC,
        descD, 
        headingD,
        descE, 
        headingE,
        descF, 
        headingF,
        descG, 
        headingG,
        descH, 
        headingH
         } = this.state;

    var protest = false;
    var bootskap = "Please complete the following fields: ";

    console.log('checkFields stoggleSlave: ', this.state.stoggleSlave)
    console.log('checkFields stogglevalueA: ', this.state.stogglevalueA)
    console.log('checkFields stogglevalueB: ', this.state.stogglevalueB)
    console.log('checkFields stogglevalueC: ', this.state.stogglevalueC)
    console.log('checkFields stogglevalueD: ', this.state.stogglevalueD)

    console.log('checkFields valueAopinion: ', this.state.valueAopinion)
    console.log('checkFields valueBopinion: ', this.state.valueBopinion)
    console.log('checkFields valueCopinion: ', this.state.valueCopinion)
    console.log('checkFields valueDopinion: ', this.state.valueDopinion)

    if (this.state.stoggleTac===false) {
     // console.log('checkFields tac: ', this.state.tac)
      protest = true;
      bootskap = bootskap + 'Participant Agreement, '
    }
    if (this.state.stoggleSlave===false) {
      protest = true;
      bootskap = bootskap + 'Participant Relation, '
    }


     if (this.state.stogglevalueA===false && headingA) {
      protest = true;
      bootskap = bootskap + headingA + ', '
    }
    if (this.state.stogglevalueB===false && headingB) {
      protest = true;
      bootskap = bootskap + headingB + ', '
    }
    if (this.state.stogglevalueC===false && headingC) {
      protest = true;
      bootskap = bootskap + headingC + ', '
    }
    if (this.state.stogglevalueD===false && headingD) {
      protest = true;
      bootskap = bootskap + headingD + ', '
    }
    if (this.state.stogglevalueE===false && headingE) {
        protest = true;
        bootskap = bootskap + headingE + ', '
      }
      if (this.state.stogglevalueF===false && headingF) {
        protest = true;
        bootskap = bootskap + headingF + ', '
      }
      if (this.state.stogglevalueG===false && headingG) {
        protest = true;
        bootskap = bootskap + headingG + ', '
      }
      if (this.state.stogglevalueH===false && headingH) {
        protest = true;
        bootskap = bootskap + headingH + ', '
      }
   
    if (this.state.valueAopinion==='' && headingA) {
     protest = true;
     bootskap = bootskap + headingA + ' Comment, '
    }
    if (this.state.valueBopinion==='' && headingB) {
      protest = true;
      bootskap = bootskap + headingB + ' Comment, '
     }
     if (this.state.valueCopinion==='' && headingC) {
      protest = true;
      bootskap = bootskap + headingC + ' Comment, '
     }
     if (this.state.valueDopinion==='' && headingD) {
      protest = true;
      bootskap = bootskap + headingD + ' Comment, '
     }
     if (this.state.valueEopinion==='' && headingE) {
        protest = true;
        bootskap = bootskap + headingE + ' Comment, '
       }
       if (this.state.valueFopinion==='' && headingF) {
        protest = true;
        bootskap = bootskap + headingF + ' Comment, '
       }
       if (this.state.valueGopinion==='' && headingG) {
        protest = true;
        bootskap = bootskap + headingG + ' Comment, '
       }
       if (this.state.valueHopinion==='' && headingH) {
        protest = true;
        bootskap = bootskap + headingH + ' Comment, '
       }
   if (this.state.stoggleTac===false) { //user doesnt agree
    let params = {
      type: SHOW_TOAST,
      open: true,
      variant: 'warning',
      message: 'You need to agree to the declaration before submitting feedback',
      pos: "bottom"
    }

    this.props.showToast(params)
    return 3;
  }
  else  //user agrees
   {
    if (protest) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: bootskap,
        pos: "bottom"
      }

      this.props.showToast(params)
      return 3;
   }
  }
 }

 checkSFields = () => {
  var protest = false;
    var bootskap = "Please complete the following fields: ";

    if (this.state.stoggleTac===false) {
      // console.log('checkFields tac: ', this.state.tac)
       protest = true;
       bootskap = bootskap + 'Participant Agreement, '
     }
    if (this.state.stoggleSlave===false) {
      protest = true;
      bootskap = bootskap + 'Participant Relation, '
    }
    //if (this.state.stoggleSimple===false) {
     // protest = true;
     // bootskap = bootskap + 'Immediate feedback score, '
   //}
    // if (this.state.simpleopinion==='') {
    //   protest = true;
    //   bootskap = bootskap + 'Immediate feedback comment, '
    //  }
    
   if (this.state.stoggleTac===false) { //user doesnt agree
    let params = {
      type: SHOW_TOAST,
      open: true,
      variant: 'warning',
      message: 'You need to agree to the declaration before submitting feedback',
      pos: "bottom"
    }

    this.props.showToast(params)
    return 3;
  }
  else  //user agrees
   {
    if (protest) {
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: bootskap,
        pos: "bottom"
      }

      this.props.showToast(params)
      return 3;
   }
  }
}
 getopinionID = () => {
  var params = {
    leaderID: this.props.leaderID,
    slaveID: this.props.slaveID
  }
  this.props.findSlave(params);
 }

 addSlaveOpinion = (opinionID) => {
  var addPoints = false;
  // valueE: feedbackvalueE,
  // valueEopinion: feedbackvalueEopinion,
  // valueF: feedbackvalueF,
  // valueFopinion: feedbackvalueFopinion,
  // valueG: feedbackvalueG,
  // valueGopinion: feedbackvalueGopinion,
  // valueH: feedbackvalueH,
  // valueHopinion: feedbackvalueHopinion

  if (opinionID) {  
    //allready exists
    } else 
  { 
    opinionID = this.makeid(10)
    addPoints = true;
  }
  var params = {
    opinionID: opinionID,
    leaderID: this.props.leaderID,
    companyName: this.props.companyName,
    slaveID: this.props.slaveID,
    companyID: this.props.companyID,
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    slave: this.state.slave,
    valueA: this.state.valueA,
    valueAopinion: this.state.valueAopinion,
    valueBopinion: this.state.valueBopinion,
    simple: this.state.simple,
    simpleopinion: this.state.simpleopinion,
    valueB: this.state.valueB,
    valueC: this.state.valueC,
    valueCopinion: this.state.valueCopinion,
    valueD: this.state.valueD,
    valueDopinion: this.state.valueDopinion,

    valueE: this.state.valueE,
    valueEopinion: this.state.valueEopinion,
    valueF: this.state.valueF,
    valueFopinion: this.state.valueFopinion,
    valueG: this.state.valueG,
    valueGopinion: this.state.valueGopinion,
    valueH: this.state.valueH,
    valueHopinion: this.state.valueHopinion,
    sdate: new Date()
    //type: ''
    // valueE: feedbackvalueE,
    // valueEopinion: feedbackvalueEopinion,
    // valueF: feedbackvalueF,
    // valueFopinion: feedbackvalueFopinion,
    // valueG: feedbackvalueG,
    // valueGopinion: feedbackvalueGopinion,
    // valueH: feedbackvalueH,
    // valueHopinion: feedbackvalueHopinion
  }
    this.props.addOpinions_ledain(params)

  if (addPoints === true && this.props.execmember) {
    let params = {
      uid: this.props.uid,
      userID: this.props.slaveID,
      pointsCurrent: this.props.points,
      ypointsCurrent: this.props.pointsy,
      pointsAdded: 10,
      trailID: this.makeid(10),
      companyName: this.props.companyName,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      title: 'Opinion on ' + this.props.leaderID,
      actionType: 'Submitted Opinion Leader Poll',
      actionDesc: 'Opinion Poll Completed'
    }
    console.log('blue green props 1 : ', this.props)
    this.props.addMorePoints(params)
  }
}


  putOpinionsB = (addPoints) => {
    const { leaderID, slaveID, getOpinionsOnSubmit, opinions } = this.props;
    var n = 0;
     //////////////ADD POINTS OR NOT///////////////////////////////////////////////////////////
     if (addPoints === true && this.props.execmember) {
      let params = {
        uid: this.props.uid,
        userID: this.props.slaveID,
        pointsCurrent: this.props.points,
        ypointsCurrent: this.props.pointsy,
        pointsAdded: 10,
        trailID: this.makeid(10),
        companyName: this.props.companyName,
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        title: 'Opinion on ' + this.props.leaderID,
        actionType: 'Submitted Opinion Leader Poll',
        actionDesc: 'Opinion Poll Completed'
      }
      console.log('blue green props 1 : ', this.props)
      this.props.addMorePoints(params)
    }
     /////////////////////////////////////////////////////////////////////////
}
  putOpinions = async() => {
    const { sopinions, getOpinionsOnSubmit } = this.props
    console.log('green-putOpinions-self 0 :', sopinions)

    var addPoints = false;

    var valueA_count = 0;
    var simple_count = 0;
    var valueB_count = 0;
    var valueC_count = 0;
    var valueD_count = 0;
    var valueE_count = 0;
    var valueF_count = 0;
    var valueG_count = 0;
    var valueH_count = 0;

    var valueA_all_count = 0;
    var valueB_all_count = 0;
    var valueC_all_count = 0;
    var valueD_all_count = 0;
    var valueE_all_count = 0;
    var valueF_all_count = 0;
    var valueG_all_count = 0;
    var valueH_all_count = 0;

    var opinionID = '';
    var simple;
    var simpleopinion;
   
    // if (sopinions) { Object.keys(sopinions).map((opinionsID, index) => {
    //   opinionID = sopinions[index].opinionID;
    //   simple = sopinions[index].simple;
    //   simpleopinion = sopinions[index].simpleopinion;
    // })}
    ///////////////////////ADD/EDIT opinionsTrail collection///////////////////////////////////////////////

      // if (opinionID) {  
      //   //allready exists going to edit existing entry
      //   } else 
      // { 
      //   opinionID = this.makeid(10) //going to make new entry
      //   addPoints = true;
      // }
      var params = {
        opinionID: this.makeid(10), //going to make new entry //opinionID,
        leaderID: this.props.leaderID,
        companyName: this.props.companyName,
        slaveID: this.props.slaveID,
        companyID: this.props.companyID,
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        slave: this.state.slave,
        valueAopinion: this.state.valueAopinion,
        valueA: this.state.valueA,
        valueBopinion: this.state.valueBopinion,
        valueB: this.state.valueB,
        valueC: this.state.valueC,
        valueCopinion: this.state.valueCopinion,
        valueD: this.state.valueD,
        valueDopinion: this.state.valueDopinion,
        valueE: this.state.valueE,
        valueEopinion: this.state.valueEopinion,
        valueF: this.state.valueF,
        valueFopinion: this.state.valueFopinion,
        valueG: this.state.valueG,
        valueGopinion: this.state.valueGopinion,
        valueH: this.state.valueH,
        valueHopinion: this.state.valueHopinion,
        simple: '',// simple,
        simpleopinion: '',//simpleopinion,
        sdate: new Date(),
        type: 3 // leader evaluating self on club values
      }
      console.log('green-putOpinions-self 1 :', params)
      
      this.props.addOpinions_ledain(params) //ADD OR EDIT UNIQUE MASTER-SLAVE SURVEY
      //////////////////////////////////////////////////////////////////////
      console.log('green-putOpinions-self 3 :', params)
        await this.props.getOpinions({ //REDO sopinions
          leaderID: this.props.leaderID, 
        }); 

     // const promise = this.getOpinions();
     // promise.then(this.onFulfilledB, this.onRejectedB);
    }

    getOpinions = (points) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.putOpinionsB(points);
        }, 3000);     
      });
    }
  
    onFulfilledB = (valIn) => {
      console.log('white users : ', 'ooooooooooooooooo');
    }
    onRejectedB = (error) => {
      console.log('white error : ', error);
    }

    putSOpinions = async() => {
      const { sopinions, getOpinionsOnSubmit } = this.props
      console.log('green putOpinions sopinions2 :', sopinions)
  
      var addPoints = false;
      var simple_all_count = 0;
      var opinionID = '';
     
      if (sopinions) { Object.keys(sopinions).map((opinionsID, index) => {
        opinionID = sopinions[index].opinionID;
      })}
        ///////////////////////ADD/EDIT opinionsTrail collection///////////////////////////////////////////////
        // console.log('green putOpinions opinionID :', opinionID)
  
        // if (opinionID) {  
        //   //allready exists going to edit existing entry
        //   console.log('green putOpinions opinionID 1 :', opinionID)
        //   } else 
        // { 
        //   console.log('green putOpinions opinionID 2 :', sopinions)
        //   opinionID = this.makeid(10) //going to make new entry
        //   addPoints = true;
        // }
        var params = {
          opinionID: this.makeid(10), //going to make new entryopinionID,
          leaderID: this.props.leaderID,
          companyName: this.props.companyName,
          slaveID: this.props.slaveID,
          companyID: this.props.companyID,
          firstName: this.props.firstName,
          lastName: this.props.lastName,
          slave: 11,//this.state.slave,
          simpleopinion: this.state.simpleopinion,
          simple: this.state.simple ? this.state.simple : 0,
          sdate: new Date(),
          type: 4 // leader evaluating self REALTIME QUICK 
        }
        //this.props.addOpinion(params) 
        console.log('green putOpinions opinionID 3 :', params)
        this.props.addSOpinion(params) //ADD OR EDIT UNIQUE MASTER-SLAVE SURVEY
        //////////////////////////////////////////////////////////////////////
  
          await this.props.getOpinions({ //REDO sopinions
            leaderID: this.props.leaderID, 
          }); 
  
        const promise = this.getOpinions(false);
        promise.then(this.onFulfilledB, this.onRejectedB);
        this.setState({Open: false})
      }

   submitPost = async() => {
   const { leaderID, slaveID, getOpinionsOnSubmit } = this.props;

    if (this.checkFields()===3) {
      console.log('checkFields return')
      return
    }

   await getOpinionsOnSubmit({ 
        leaderID: leaderID, 
        slaveID: slaveID
      }); 
    this.setState({ showButton: false })

    console.log('checkFields not return')
    console.log('green putOpinions sopinions1 :', this.props.sopinions)

   //  this.putOpinions(); //compile new mean values for leaderboard from opinions in state
     const promise = this.getsOpinions();
     promise.then(this.onFulfilled, this.onRejected);
  }

  submitSPost = async() => {
    const { leaderID, slaveID, getOpinionsOnSubmit } = this.props;
 
     if (this.checkSFields()===3) {
       console.log('checkFields return')
       return
     }
     console.log('green putOpinions leaderID :', leaderID)
     console.log('green putOpinions slaveID :', slaveID)

     await getOpinionsOnSubmit({ 
         leaderID: leaderID, 
         slaveID: slaveID
       }); 

     this.setState({ showButton: false })

     console.log('inside submitSPost')

     console.log('checkFields not return')
     console.log('green putOpinions sopinions1 :', this.props.sopinions)
 
    //  this.putOpinions(); //compile new mean values for leaderboard from opinions in state
      const promise = this.getSsOpinions();
      promise.then(this.onFulfilled, this.onRejected);
   }

  getsOpinions = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.putOpinions();
      }, 3000);     
    });
  }

  getSsOpinions = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.putSOpinions();
      }, 3000);     
    });
  }

  onFulfilled = (valIn) => {
    console.log('white users : ', 'ooooooooooooooooo');
  }
  onRejected = (error) => {
    console.log('white error : ', error);
  }

  toggleSlave = (nr) => {
    const { slave } = this.state;
    this.setState({
      slave: nr,
      stoggleSlave: true
    })
  }

  togglevalueA = (nr) => {
    const { valueA } = this.state;
    this.setState({
      valueA: nr,
      stogglevalueA: true
    })
  }
  toggleSimple = (nr) => {
    const { simple } = this.state;
    this.setState({
      simple: nr,
      stoggleSimple: true
    })
  }
  toggleTac = (nr) => {
    const { tac } = this.state;
    this.setState({
      tac: nr,
      stoggleTac: nr===1 ? true : false
    })
  }

  handleChecked = () => {
    console.log('handleChecked 2', this.state.stoggleTac)
   // this.setState({stoggleTac: !this.state.stoggleTac});
   if (document.querySelectorAll('#declaration input[type=checkbox]:checked').length > 0)
   {
    this.setState({stoggleTac: true})
   }
   else
    {
      this.setState({stoggleTac: false})
    }
  }

  togglevalueB = (nr) => {
    const { valueB } = this.state;
    this.setState({
      valueB: nr,
      stogglevalueB: true
    })
  }
  
  togglevalueC = (nr) => {
    const { valueC } = this.state;
    this.setState({
      valueC: nr,
      stogglevalueC: true
    })
  }

  togglevalueD = (nr) => {
    const { valueD } = this.state;
    this.setState({
      valueD: nr,
      stogglevalueD: true
    })
  }
 
  togglevalueE = (nr) => {
    const { valueE } = this.state;
    this.setState({
      valueE: nr,
      stogglevalueE: true
    })
  }
  togglevalueF = (nr) => {
    const { valueF } = this.state;
    this.setState({
      valueF: nr,
      stogglevalueF: true
    })
  }
  togglevalueG = (nr) => {
    const { valueG } = this.state;
    this.setState({
      valueG: nr,
      stogglevalueG: true
    })
  }
  togglevalueH = (nr) => {
    const { valueH } = this.state;
    this.setState({
      valueH: nr,
      stogglevalueH: true
    })
  }

render() {
    const { userInfo, getNewKeyx, opinions, feedback } = this.props;
    console.log('green blue viewUser : ', this.props)
    var valueAComment;

    const handleClickOpenMain = () => {
      console.log('green handleClickOpen Main')
      this.getUserInfo()
      this.setState({ 
        Open: true
      });
    };
  
    const onClickz = (e) => {
      e.preventDefault();
      console.log("clicked");
    };

    const handleClickOpen = () => {
      console.log('green handleClickOpen ---')
      this.setState({ Open: true });
    };

    const handleClose = () => {
      this.setState({ Open: false });
    };
    const handleClosex = () => {
      console.log('handleClose is running');
      this.setState({ Open: false });
    };

    const getFollowers = (props) => {
      for (let x in props) {
        return props[x].analytics.followers
      }
    }
    const scrollIt = () => {
        this.scollToRef.current.scrollIntoView()
      }

      const scrollToTargetDiv = () => {
        console.log('greenhandleSourceDivClick 4')
           setTimeout(function () {
                this.targetDivRef.current.scrollIntoView({ behavior: 'smooth'});
                console.log('greenhandleSourceDivClick 5')
           }, 500);   
        }
    
       const handleSourceDivClick2 = () => {
        // e.preventDefault();
        // e.stopPropagation();
        console.log('greenhandleSourceDivClick 2')
        this.setState({
         shownRealtime: this.state.shownRealtime ? false : true,
         clickedOnMenu: true
        })  

        //  scrollToTargetDiv
  
       };
    
       const handleSourceDivClick3 = () => {
        // e.preventDefault();
        // e.stopPropagation();
        console.log('greenhandleSourceDivClick 3')
          this.setState({
            shownRealtime: this.state.shownRealtime===false ? true : false,
            clickedOnMenu: true
          })

         // scrollToTargetDiv

        };

      console.log('green avatar view : ', this.props.src)
       var imgURL = this.props.src;

      const toggleSidenav = () => {
        var css = (this.props.showHideSidenav === "hidden") ? "show" : "hidden";
        this.setState({"showHideSidenav":css});
      }

    return (
         <div>
          <div 
          // style={{ 
          //   display: 'flex',
          //   cursor: 'pointer',
          //   justifyContent: 'center',
          //   alignItems: 'center',
          //   align:'center',
          //   color: 'black', 
          //   textShadow: '3px 3px 3px gold',
          //   width: '100%',
          //   maxWidth: '100%'
          //   }}
          >
             <IconButton 
              style={{
                color: 'black',
                width: '48px',
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              >
            <Avatar
              alt={this.props.alt}
              src={PHOTO_PROFILE(this.props.src)}
              //src={PHOTO_PROFILE(require("/static/images/remy.jpg"))} 
              //src={PHOTO_PROFILE('../images/default-user.png')}
              //src={PHOTO_PROFILE(this.props.srcx)}
              //src={PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/thinklead-b3299.appspot.com/o/testphotos%2Fimage_2021-03-03T08%3A39%3A23.736Z%20-%20bruce.jpeg?alt=media&token=f0f0d61d-bb3c-4cea-b03d-88facfa1290e')}
              id={this.props.id}
              onClick={handleClickOpenMain}
            />
           </IconButton>
          </div>

          <Dialog 
          open={this.state.Open} 
          //onLoad={scrollIt}
          onLoad={this.state.clickedOnMenu ? scrollToTargetDiv : scrollIt}
          onClose={handleClose} 

          style={{
             top: '-15px' ,
             left: isMobile ? '-50px' : '0',
             width: isMobile ? '450px' : '100%',
             maxWidth: isMobile ? '450px' : '100%',
             height: '700px',
             }}  
          >
            <DialogContent
              style={{
                top: '-15px',
                left: isMobile ? '0px' : '0px',
                width: isMobile ? '410px' : '100%',
                maxWidth: isMobile ? '400px' : '100%',
                height: '700px'
                }}  
            >
              <div>
              <TableContainer
                    style={{
                      top: '-15px',
                      left: isMobile ? '-50px' : '0px',
                      width: isMobile ? '360px' : '100%',
                      maxWidth: isMobile ? '400px' : '100%',
                      height: '700px'
                      }}  
              >
                <Table aria-label='simple table'>
                  <TableBody>
                  <TableRow><TableCell colspan='4'>
                  {/* <div align='center'><a name='gototop' id='gototop'>aaaaa</a></div> */}

                  <div ref={this.scollToRef}></div>
                    </TableCell></TableRow>
                    <TableRow
                    style={{
                      width: isMobile ? '450px' : '100%',
                      maxWidth: isMobile ? '450px' : '100%'
                      }}
                    >
                      <TableCell align='center' colspan='1'> Followers: {this.state.followers}</TableCell>
                      <TableCell align='center' colspan='2' border='1'>
                      <div align='center'>
                          <Avatar
                            alt={this.state.firstName}
                            src={this.props.src}
                            id={this.props.userID}
                            onClick={handleClickOpen}
                          />
                          </div>

                  {this.props.journalUserID !== this.props.userIDlogged ? <div align='center'><FollowButton post={this.props.post} following={this.props.following} ></FollowButton></div> : null}       
                        
                      </TableCell>
                      <TableCell align='center' colspan='1'>Following: {this.state.following} </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center' colspan='4'>{this.state.firstName + ' ' + this.state.lastName}<br />{this.props.companyName}</TableCell>
                    </TableRow>
                
                    <TableRow bgColor='#000000' 
                    >
                      <TableCell>
                        <font color='#ffffff'><b>Messages: {this.state.dailythoughts}</b></font>
                      </TableCell>
                      <TableCell>
                        <font color='#ffffff'><b>Articles: {this.state.articles}</b></font>
                      </TableCell>
                      <TableCell>
                        <font color='#ffffff'><b>Videos: {this.state.videos}</b></font>
                      </TableCell>
                      <TableCell>
                        <font color='#ffffff'><b>Podcasts: {this.state.podcasts}</b></font>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colspan='4'>
                        Biography:
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan='4' style={{size:8}} >
                        {this.state.biography ? this.state.biography : 'No Biography yet'}
                      </TableCell>
                    </TableRow>
                    </TableBody>
                   </Table>

                   <Table aria-label='simple table'>
                  <TableBody>

                    <TableRow>
                        <TableCell colspan='4' bgColor='#000000'>
                        <div align='center'>
                          <font color='#ffffff'><b>Leader Evaluation</b></font>
                          </div>
                        </TableCell>
                    </TableRow>  

                    <TableRow>
                <TableCell colspan='4'>
                  <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}>
                    <b>A Personal Declaration:</b><br/>
                    I offer anonymous real-time feedback because I am committed to the purpose of assisting leaders 
                    to become more aware of their leadership; 
                    to continuously raise their standards for a happier, conscious and more prosperous world. 
                    I do not offer real-time feedback with malicious intent. And as a leader / future leader 
                    I commit to invite others to give me real-time feedback as well.
                    I also recognise that all assessments are in some measure based on perception, yet when facts and 
                    perception combine, 
                    it offers a leader valuable insights.
                    </div>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colspan='4'>
                  <div id="declaration" >
                  <input type="checkbox" onChange={ this.handleChecked }/>&nbsp;&nbsp;I Agree
                  </div>
                </TableCell>
                </TableRow>   

                    <TableRow>
                <TableCell colspan='4'>
                  <div><b>In what capacity are you offering this leadership feedback?:</b></div>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colspan='4'>
                  <div id="rslave">
                    <br/><input type="radio" id="r10" name="slave" value="10" onClick={() => this.toggleSlave(11)}/>&nbsp;&nbsp;I am evaluating myself 
                  </div>
                </TableCell>
                </TableRow>                   
                
                {this.state.showButton ?
                      <TableRow bgColor='#eeeeee'>
                        <TableCell colspan='4'><span style={{size: '18px', cursor: 'pointer'}}>
                        <div
                         onClick={handleSourceDivClick2}
                         style={{ cursor: 'pointer' }}
                        > 
                       <div ref={this.targetDivRef}></div>                      
                        <b>1. Real-time Behaviour and Performance Feedback Assessement</b>
                        </div>
                        </span>
                        </TableCell>
                      </TableRow>
                      : null}               
                    {this.state.showButton && this.state.shownRealtime ?
                      <TableRow bgColor='#eeeeee'>
                        <TableCell colspan='4' backgroundColor='#eeeeee'>
                          <b>The only way for anyone to continue effective, or change ineffective everyday 
                          behaviours and performance towards their Best Self, is through real-time feedback. 
                          Please type your real-time feedback for yourself in the box below. </b>
                          Where appropriate, also give a simple rating on the behaviour or performance. 
                          <b>The scale is 1-10 (1 being poor and 10 being excellent):</b>
                        </TableCell>

                      </TableRow>
                      : null}
                     {this.state.showButton && this.state.shownRealtime ?
                     <div bgColor='#eeeeee'>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rself">
                            1<input type="radio" id="r1" name="simple" value="1" onClick={() => this.toggleSimple(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="simple" value="2" onClick={() => this.toggleSimple(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="simple" value="3" onClick={() => this.toggleSimple(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="simple" value="4" onClick={() => this.toggleSimple(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="simple" value="5" onClick={() => this.toggleSimple(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="simple" value="6" onClick={() => this.toggleSimple(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="simple" value="7" onClick={() => this.toggleSimple(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="simple" value="8" onClick={() => this.toggleSimple(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="simple" value="9" onClick={() => this.toggleSimple(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="simple" value="10" onClick={() => this.toggleSimple(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                        </div>
                          :null}
                {this.state.showButton && this.state.shownRealtime ?
                      <TableRow bgColor='#eeeeee'>
                        <TableCell colspan='4' bgColor='#eeeeee'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='simpleopinion'
                            id='simpleopinion'     
                            value={this.state.simpleopinion}  
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                simpleopinion: value
                              })
                            }}
                          />
        
                        </TableCell>
                      </TableRow>
                     :null}

                    {this.state.showButton && this.state.shownRealtime ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <Button
                            variant='contained'
                            onClick={this.submitSPost}
                            style={sendBtn}
                            inputProps={{ maxLength: 280 }}
                            aria-describedby='opinion'
                           // value={this.state.selfopinion}
                            helperText={this.state.maxLengthThought}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                opinion: value
                              })
                            }}
                          >
                            <SendIcon />
                            Submit
                          </Button>
                        </TableCell>
                      </TableRow>
                      : null}
                   {this.state.showButton ?
                    <React.Fragment>
                    <TableRow>
                      <TableCell colspan='4'><span style={{size: '18px', cursor: 'pointer'}}>
                      <div
                         onClick={handleSourceDivClick3}
                         style={{ cursor: 'pointer' }}
                        > 
                      <b>2. Real-time Leadership Competencies Feedback Assessement</b>
                      </div>
                      </span>
                      </TableCell>
                    </TableRow>
                    {this.state.showButton && this.state.shownRealtime===false ?
                    <TableRow>
                    <TableCell colspan='4' backgroundColor='#eeeeee'>
                      <b>For each attribute, rate yourself on a scale of 1 to 10: 
                        1-2 very poor - immediate coaching & customised development urgent; 
                        3-4 poor - coach recommended, customised development required; 
                        5-6 lukewarm - constructive feedback & customised development required; 
                        7-8 hot - overall good, miner gaps/blind spots to address through 
                        customised development; 9-10 sizzling hot, excellent - lives the attribute, 
                        can benefit from coaching others – proactive development possible.</b>
                      </TableCell>
                      </TableRow>
                     : null}
                  </React.Fragment>
                      : null} 
                      {this.state.headingA && this.state.shownRealtime===false ? 
                      <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                            ><b>1. {this.state.headingA} : </b>{this.state.descA}</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rvalueA">
                            1<input type="radio" id="r1" name="valueA" value="1" onClick={() => this.togglevalueA(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="valueA" value="2" onClick={() => this.togglevalueA(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="valueA" value="3" onClick={() => this.togglevalueA(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="valueA" value="4" onClick={() => this.togglevalueA(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="valueA" value="5" onClick={() => this.togglevalueA(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="valueA" value="6" onClick={() => this.togglevalueA(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="valueA" value="7" onClick={() => this.togglevalueA(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="valueA" value="8" onClick={() => this.togglevalueA(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="valueA" value="9" onClick={() => this.togglevalueA(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="valueA" value="10" onClick={() => this.togglevalueA(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton && this.state.headingA && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <b>Comment on the reason for your score, so that you can know what your strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}

                   {this.state.showButton && this.state.headingA && this.state.shownRealtime===false ?
                      <TableRow >
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='valueAopinion'
                            id='valueAopinion'     
                            value={this.state.valueAopinion}  
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                valueAopinion: value
                              })
                            }}
                          />
        
                        </TableCell>
                      </TableRow>
                     :null}

                    {this.state.showButton && this.state.headingB && this.state.shownRealtime===false ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                            ><b>2. {this.state.headingB} : </b>{this.state.descB}</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rvalueB">
                            1<input type="radio" id="r1" name="valueB" value="1" onClick={() => this.togglevalueB(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="valueB" value="2" onClick={() => this.togglevalueB(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="valueB" value="3" onClick={() => this.togglevalueB(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="valueB" value="4" onClick={() => this.togglevalueB(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="valueB" value="5" onClick={() => this.togglevalueB(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="valueB" value="6" onClick={() => this.togglevalueB(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="valueB" value="7" onClick={() => this.togglevalueB(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="valueB" value="8" onClick={() => this.togglevalueB(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="valueB" value="9" onClick={() => this.togglevalueB(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="valueB" value="10" onClick={() => this.togglevalueB(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton && this.state.headingB && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <b>Comment on the reason for your score, so that you can know what your strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton && this.state.headingB && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='valueBopinion'
                            id='valueBopinion'
                            value={this.state.valueBopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                valueBopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      : null} 

                     {this.state.showButton && this.state.headingC && this.state.shownRealtime===false ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                         ><b>3. {this.state.headingC} : </b>{this.state.descC}</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtogglevalueC">
                            1<input type="radio" id="r1" name="valueC" value="1" onClick={() => this.togglevalueC(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="valueC" value="2" onClick={() => this.togglevalueC(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="valueC" value="3" onClick={() => this.togglevalueC(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="valueC" value="4" onClick={() => this.togglevalueC(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="valueC" value="5" onClick={() => this.togglevalueC(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="valueC" value="6" onClick={() => this.togglevalueC(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="valueC" value="7" onClick={() => this.togglevalueC(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="valueC" value="8" onClick={() => this.togglevalueC(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="valueC" value="9" onClick={() => this.togglevalueC(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="valueC" value="10" onClick={() => this.togglevalueC(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton && this.state.headingC && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <b>Comment on the reason for your score, so that you can know what your strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton && this.state.headingC && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            // className={classes.maintextField}
                            name='valueCopinion'
                            id='valueCopinion'
                            //value={this.state.orgopinion ? this.state.orgopinion : feedback[index].orgopinion}
                            value={this.state.valueCopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                valueCopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      : null}   

                    {this.state.showButton && this.state.headingD && this.state.shownRealtime===false ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                           ><b>4. {this.state.headingD} : </b>{this.state.descD}</div>
                       
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtogglevalueD">
                            1<input type="radio" id="r1" name="valueD" value="1" onClick={() => this.togglevalueD(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="valueD" value="2" onClick={() => this.togglevalueD(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="valueD" value="3" onClick={() => this.togglevalueD(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="valueD" value="4" onClick={() => this.togglevalueD(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="valueD" value="5" onClick={() => this.togglevalueD(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="valueD" value="6" onClick={() => this.togglevalueD(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="valueD" value="7" onClick={() => this.togglevalueD(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="valueD" value="8" onClick={() => this.togglevalueD(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="valueD" value="9" onClick={() => this.togglevalueD(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="valueD" value="10" onClick={() => this.togglevalueD(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton && this.state.headingD && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that you can know what your strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton && this.state.headingD && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='valueDopinion'
                            id='valueDopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.valueDopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                valueDopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}  

                    {this.state.showButton && this.state.headingE && this.state.shownRealtime===false ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                     ><b>5. {this.state.headingE} : </b>{this.state.descE}</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtogglevalueE">
                            1<input type="radio" id="r1" name="valueE" value="1" onClick={() => this.togglevalueE(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="valueE" value="2" onClick={() => this.togglevalueE(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="valueE" value="3" onClick={() => this.togglevalueE(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="valueE" value="4" onClick={() => this.togglevalueE(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="valueE" value="5" onClick={() => this.togglevalueE(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="valueE" value="6" onClick={() => this.togglevalueE(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="valueE" value="7" onClick={() => this.togglevalueE(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="valueE" value="8" onClick={() => this.togglevalueE(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="valueE" value="9" onClick={() => this.togglevalueE(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="valueE" value="10" onClick={() => this.togglevalueE(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton && this.state.headingE && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that you can know what your strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton && this.state.headingE && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='valueEopinion'
                            id='valueEopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.valueEopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                valueEopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null}  

                    {this.state.showButton && this.state.headingF && this.state.shownRealtime===false ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>6. {this.state.headingF} : </b>{this.state.descF}</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtogglevalueF">
                            1<input type="radio" id="r1" name="valueF" value="1" onClick={() => this.togglevalueF(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="valueF" value="2" onClick={() => this.togglevalueF(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="valueF" value="3" onClick={() => this.togglevalueF(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="valueF" value="4" onClick={() => this.togglevalueF(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="valueF" value="5" onClick={() => this.togglevalueF(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="valueF" value="6" onClick={() => this.togglevalueF(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="valueF" value="7" onClick={() => this.togglevalueF(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="valueF" value="8" onClick={() => this.togglevalueF(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="valueF" value="9" onClick={() => this.togglevalueF(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="valueF" value="10" onClick={() => this.togglevalueF(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton && this.state.headingF && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that you can know what your strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton && this.state.headingF && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='valueFopinion'
                            id='valueFopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.valueFopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                valueFopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null} 

                    {this.state.showButton && this.state.headingG && this.state.shownRealtime===false ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                        ><b>7. {this.state.headingG} : </b>{this.state.descG}</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtogglevalueG">
                            1<input type="radio" id="r1" name="valueG" value="1" onClick={() => this.togglevalueG(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="valueG" value="2" onClick={() => this.togglevalueG(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="valueG" value="3" onClick={() => this.togglevalueG(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="valueG" value="4" onClick={() => this.togglevalueG(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="valueG" value="5" onClick={() => this.togglevalueG(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="valueG" value="6" onClick={() => this.togglevalueG(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="valueG" value="7" onClick={() => this.togglevalueG(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="valueG" value="8" onClick={() => this.togglevalueG(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="valueG" value="9" onClick={() => this.togglevalueG(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="valueG" value="10" onClick={() => this.togglevalueG(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton && this.state.headingG && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that you can know what your strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton && this.state.headingG && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='valueGopinion'
                            id='valueGopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.valueGopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                valueGopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                           :null} 

                    {this.state.showButton && this.state.headingH && this.state.shownRealtime===false ?
                    <div>
                      <TableRow>
                        <TableCell colspan='4'>
                          <div style={{width: isMobile ? '330px' : '100%', display: 'block'}}
                          ><b>8. {this.state.headingH} : </b>{this.state.descH}</div>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colspan='4'>
                          <div id="rtogglevalueH">
                            1<input type="radio" id="r1" name="valueH" value="1" onClick={() => this.togglevalueH(1)}/>&nbsp;
                            2<input type="radio" id="r2" name="valueH" value="2" onClick={() => this.togglevalueH(2)}/>&nbsp;
                            3<input type="radio" id="r3" name="valueH" value="3" onClick={() => this.togglevalueH(3)}/>&nbsp;
                            4<input type="radio" id="r4" name="valueH" value="4" onClick={() => this.togglevalueH(4)}/>&nbsp;
                            5<input type="radio" id="r5" name="valueH" value="5" onClick={() => this.togglevalueH(5)}/>&nbsp;
                            6<input type="radio" id="r6" name="valueH" value="6" onClick={() => this.togglevalueH(6)}/>&nbsp;
                            7<input type="radio" id="r7" name="valueH" value="7" onClick={() => this.togglevalueH(7)}/>&nbsp;
                            8<input type="radio" id="r8" name="valueH" value="8" onClick={() => this.togglevalueH(8)}/>&nbsp;
                            9<input type="radio" id="r9" name="valueH" value="9" onClick={() => this.togglevalueH(9)}/>&nbsp;
                            10<input type="radio" id="r10" name="valueH" value="10" onClick={() => this.togglevalueH(10)}/>
                          </div>
                        </TableCell>
                        </TableRow>
                       </div>
                      : null}
                    {this.state.showButton && this.state.headingH && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                        <b>Comment on the reason for your score, so that you can know what your strengths are or what possible weaknesses to work on:</b>
                        </TableCell>
                      </TableRow>
                      : null}
                    {this.state.showButton && this.state.headingH && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <TextareaAutosize
                            label='Type your message here'
                            name='valueHopinion'
                            id='valueHopinion'
                            //value={this.state.sosopinion ? this.state.sosopinion : feedback[index].sosopinion}
                            value={this.state.valueHopinion}
                            helperText={this.state.maxLengthThought}
                            style={{width: isMobile ? '330px' : '100%'}}
                            minRows={5}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                valueHopinion: value
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>                                         
                      :null} 

                    {this.state.showButton && this.state.shownRealtime===false ?
                      <TableRow>
                        <TableCell colspan='4'>
                          <Button
                            variant='contained'
                            onClick={this.submitPost}
                            style={sendBtn}
                            inputProps={{ maxLength: 280 }}
                            aria-describedby='opinion'
                           // value={this.state.selfopinion}
                            helperText={this.state.maxLengthThought}
                            onChange={event => {
                              const { value } = event.target
                              let len = value.length
                              this.setState({
                                maxLengthThought: len + '/280',
                                opinion: value
                              })
                            }}
                           >
                            <SendIcon />
                            Submit
                          </Button>
                        </TableCell>
                      </TableRow>
                      : null}

                  </TableBody>
                   </Table>
                
              </TableContainer>
               </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClosex()} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
    ); 
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
    leaderboardData: state.users.leaderboardData,
    sopinions: state.opinions.sopinion,
    opinions: state.opinions.opinion,
    feedback: state.opinions.feedback,
    //stateopinionID: state.opinions.opinionid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: params => dispatch(getUserProfile(params)),
    addOpinions_ledain: params => dispatch(addOpinions_ledain(params)),
    addSOpinion: params => dispatch(addSOpinion(params)),
    getOpinionsTrail: params => dispatch(getOpinionsTrail(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
    getOpinions: params => dispatch(getOpinions(params)),
    showToast: params => dispatch(showToast(params)),
    getOpinionsOnSubmit: params => dispatch(getOpinionsOnSubmit(params)),
    //FindSlave: params => dispatch(FindSlave(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser)
