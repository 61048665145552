import {
  SHOW_EDIT_USER_DIALOG,
  SHOW_USER_PROFILE_DIALOG,
  LOG_USER_PROFILE_DIALOG,
  CLEAR_UPDATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS
} from '../actions/types'

const initState = {
  userDetails: [],
  UPDATE_USER: ''
}

const userDialogReducer = (state = initState, action) => {
  switch (action.type) {
  case SHOW_EDIT_USER_DIALOG:
    return {
      ...state,
      type: SHOW_EDIT_USER_DIALOG,
      userDetails: action.userDetails
    }

  case SHOW_USER_PROFILE_DIALOG:
    return {
      ...state,
      type: SHOW_USER_PROFILE_DIALOG,
      userDetails: action.userDetails
    }
  case LOG_USER_PROFILE_DIALOG:
    return {
      ...state,
      type: LOG_USER_PROFILE_DIALOG,
      userDetails: action.userDetails,
      UPDATE_USER: 'LOG_UPDATE_USER_SUCCESS'
    }
  case UPDATE_USER_SUCCESS:
    return {
      ...state,
      UPDATE_USER: UPDATE_USER_SUCCESS
    }
  case CLEAR_UPDATE_USER_SUCCESS:
    return {
      ...state,
      UPDATE_USER: ''
    }

  default:
    return state
  }
}

export default userDialogReducer
