//

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from 'react-redux'
//import { withRouter } from 'react-router-dom'
import { addMorePoints } from '../../store/actions/freshCardActions'

const styles = theme => ({
  paper: {
    width: 250,
    overflow: "hidden auto"
  },
  popper: {
    zIndex: 999
  }
});

class RemoveAttachmentDialog extends React.Component {
 constructor(props){
    super(props);
    console.log('green addMorePoints 0: ', this.props)
 }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = e => {
    this.props.toggleOpenDeleteAttachments(e);
  };

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
  }

  handleProceed = () => {
    console.log('green deleteadd addMorePoints : ', this.props)
   // if (this.props.userdata.execmember) {
    this.props.onClickProceed();
      //// ADD POINTS////////////////////////////////
      // var params = {
      //   uid: this.props.thought.uid,
      //   userID: this.props.thought.journalUserID,
      //   pointsCurrent: this.props.thought.points,
      //   ypointsCurrent: this.props.userdata.pointsy,
      //   pointsAdded: -10,
      //   trailID: this.makeid(10),
      //   companyName: this.props.userdata.companyName,
      //   firstName: this.props.userdata.firstName,
      //   lastName: this.props.userdata.lastName,
      //   title: this.props.thought.title,
      //   actionType: this.props.thought.dailyThoughtDescription + ' removed',
      //   actionDesc: this.props.thought.dailyThoughtDescription       
      // }
      // this.props.addMorePoints(params)
    
  };

  render() {
    const { classes, attachmentType, open, anchorEl } = this.props;
    const id = open ? "simple-popper" : null;
    return (
      <Popper
        className={classes.popper}
        placement={"right-end"}
        id={id}
        open={open}
        anchorEl={anchorEl}
      >
        <Paper className={classes.paper}>
          <DialogTitle id="max-width-dialog-title">
            Discard {attachmentType}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to remove this {attachmentType}{" "}
              attachment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Close
            </Button>
            <Button onClick={this.handleProceed} color="primary">
              Discard
            </Button>
          </DialogActions>
        </Paper>
      </Popper>
    );
  }
}

RemoveAttachmentDialog.propTypes = {
  classes: PropTypes.object.isRequired
};
const RemoveAttachmentDialogStyled = withStyles(styles)(RemoveAttachmentDialog)

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addMorePoints: params => dispatch(addMorePoints(params))
  }
}

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(RemoveAttachmentDialogStyled)

//export default withStyles(styles)(RemoveAttachmentDialog);
