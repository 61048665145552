import { SHOW_CALF } from './types'
import firebase from '../firebase'

export const showCalf = params => {
  if (params.type === SHOW_CALF) {
    return (dispatch, getState) => {
      // mark user as calf member
      let database = firebase.database
      let updates = {}
      console.log('green showCalf: ', params)

      updates['/users/' + params.userID + '/execmember' ] = true
      updates['/user/' + params.uid + '/execmember' ] = true
     if (params.userType==='8' || params.userType===8 ) {
      updates['/users/' + params.userID + '/companyID_userType' ] = params.companyID + '_3'
      updates['/users/' + params.userID + '/userDescription' ] = 'Public Contributor'
      updates['/users/' + params.userID + '/userType' ] = '3'
      updates['/user/' + params.uid + '/companyID_userType' ] = params.companyID + '_3'
      updates['/user/' + params.uid + '/userDescription' ] = 'Public Contributor'
      updates['/user/' + params.uid + '/userType' ] = '3'
     }
      database
        .ref()
        .update(updates)
        .then(() => {
          dispatch({
            type: SHOW_CALF,
            link: params.code
          })
        })
        .catch(() => {
          dispatch({
            type: 'NO_ERROR'
          })
        })
    }
  }
}

// export const showCalf = params => {
//   if (params.type === SHOW_CALF) {
//     return (dispatch, getState) => {
//       //mark user as calf member

//       dispatch({
//         type: SHOW_CALF,
//         link: params.code
//       });
//     };
//   } else {
//     return (dispatch, getState) => {
//       dispatch({
//         type: "NO_ERROR",
//       });
//     };
//   }
// };

