import React from 'react'
import PropTypes from 'prop-types'
import { CompactPicker } from 'react-color'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'

class ColorWheel extends React.Component {
  state = {
    background: '#fff',
    open: false
  };

  handleChangeComplete = color => {
    this.setState({ background: color.hex, open: false })
    this.props.onChange(color.hex)
  };

  render() {
    const { classes, onClose, selectedValue, open } = this.props

    // this.setState({ open })

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby='simple-dialog-color'
      >
        <DialogTitle id='simple-dialog-color'>Text color</DialogTitle>
        <div>
          <CompactPicker
            color={this.state.background}
            onChangeComplete={this.handleChangeComplete}
          />
        </div>
      </Dialog>
    )
  }
}

ColorWheel.propTypes = {
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
  open: PropTypes.bool.isRequired
}

export default ColorWheel
