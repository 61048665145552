/* eslint-disable no-inline-comments */
/* eslint-disable consistent-return */
/* eslint-disable no-undefined */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import MUIDataTable from 'mui-datatables'
import { hideLoader, showLoader } from '../store/actions/loaderActions'
import {
  SHOW_TOAST,
  SHOW_LOADER,
  HIDE_LOADER,
  VALUES_NAME,
  ACCESS_COMPETENCIES,
  SIMPLE_VALUES
} from '../store/actions/types'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Paper,
  Button,
  Avatar,
  Card,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  Menu,
  MenuItem
} from '@material-ui/core'
import { green, orange, red } from '@material-ui/core/colors'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import DescriptionIcon from '@material-ui/icons/Description'
import TextsmsIcon from '@material-ui/icons/Textsms'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import FilterListIcon from '@material-ui/icons/FilterList'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import grey from '@material-ui/core/colors/grey'
import AddCorpUserDialog from './../components/Dialogs/AddCorpUserDialog'
import GroupAdminDialog from './../components/Dialogs/GroupAdmin/GroupAdminDialog'
import GroupEditAdminDialog from './../components/Dialogs/GroupAdmin/GroupEditAdminDialog'
import UserTypeDialog from './../components/Dialogs/UserTypeDialog'
import ChangeUserPasswordDialog from './../components/Dialogs/ChangeUserPasswordDialog'
import { LIGHTGREY, LIGHTBLUE } from '../utils/color_utils'
import CardContent from '@material-ui/core/CardContent'
import { PHOTO_PROFILE } from '../utils/photo_utils'
import { connect } from 'react-redux'
import {
  getAllCompanyUsers,
  deleteCompanyUser,
  resetYCompanyUser,
  createCompanyUser,
  updateCompanyUser,
  resendWelcomeEmail,
  changeUserType,
  checkUserExists,
  getAllCompanySettings,
  getAllGeneralSettings,
  editUser,
  checkUserExistsEdit
} from './../store/actions/companyAdminActions'
// [NEED TO BE FIX] need to fix as per need
import xlsx from 'xlsx'
import firebase from '../store/firebase'
import { showToast } from './../store/actions/toastAction'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import TextField from '@material-ui/core/TextField'
import LeaderLoader from './LeaderLoader'
import { simpleAction } from './../store/actions/simpleAction'

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    boxShadow: 'none'
  },
  highlight: theme.palette.type === 'light' ? {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85)
  } : {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  button: {
    'margin': theme.spacing.unit,
    'color': '#ffffff',
    'backgroundColor': `${LIGHTBLUE}`,
    '&:hover': {
      backgroundColor: `${LIGHTBLUE}`,
      color: '#ffffff'
    },
    'fontSize': '10px'
  },
  deleteButton: {
    backgroundColor: red[400],
    color: '#ffffff',
    margin: theme.spacing.unit,
    fontSize: '10px'
  },
  activeButton: {
    backgroundColor: orange[800],
    color: '#ffffff',
    margin: theme.spacing.unit,
    fontSize: '10px'
  },
  userTypeButton: {
    backgroundColor: green[400],
    color: '#ffffff',
    margin: theme.spacing.unit,
    fontSize: '10px'
  },

  settingsIcons: {
    margin: theme.spacing.unit
  },
  icon: {
    fontSize: '20px'
  }
})

const table = {
  color: 'red',
  fontSize: 8,
  border: '1px solid grey'
}

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    multiple,
    onDeleteUser,
    onResetYUser,
    onChangeUserData,
    onOpenUpdateUserDialog,
    onResendWelcomeEmail,
    onOpenChangePassword,
    onOpenUserTypeDialog,
    handleEditUserDialog
  } = props

  console.log('green props: ', props.userType)

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      elevation={0}
    >
      <div className={classes.title} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {numSelected === 1 ? (
              <React.Fragment>
                <Button
                  variant='contained'
                  aria-label='Edit'
                  onClick={handleEditUserDialog}
                  className={classes.userTypeButton}
                  size='small'
                >
                  <EditIcon className={classes.icon} />
                  Edit
                </Button>
                <Button
                  variant='contained'
                  aria-label='Delete'
                  onClick={onOpenUserTypeDialog}
                  className={classes.userTypeButton}
                  size='small'
                >
                  <EditIcon className={classes.icon} />
                  Change User type
                </Button>
                <Button
                  variant='contained'
                  onClick={onResendWelcomeEmail}
                  className={classes.button}
                  aria-label='Delete'
                  size='small'
                >
                  <MarkunreadMailboxIcon className={classes.icon} />
                  Resend Welcome Email
                </Button>

                <Button
                  onClick={onOpenChangePassword}
                  variant='contained'
                  className={classes.button}
                  aria-label='Change Password'
                  size='small'
                >
                  <TextsmsIcon className={classes.icon} />
                  Change Password
                </Button>
                <Button
                  onClick={() => onChangeUserData('changeActiveStatus')}
                  variant='contained'
                  aria-label='Delete'
                  className={classes.activeButton}
                  size='small'
                >
                  <CheckIcon className={classes.icon} />
                  Activate / Deactivate
                </Button>
              </React.Fragment>
            ) : null}
            {props.userType === 14 ? null :
            (<div>
             <Button
                onClick={onDeleteUser}
                variant='contained'
                className={classes.deleteButton}
                aria-label='Delete'
                size='small'
              >
                <DeleteIcon />
                {multiple ? `Delete (${numSelected})` : 'Delete'}
              </Button>
            
              <Button
                onClick={onResetYUser}
                variant='contained'
                className={classes.activeButton}
                aria-label='Reset Annual Points'
                size='small'
              >
                <DeleteIcon />
                {multiple ? `Reset Annual Points for (${numSelected})` : 'Reset Annual Points'}
              </Button>
              </div>)
            }
          </React.Fragment>
        ) : (
          <Tooltip title='Filter list'>
            <IconButton aria-label='Filter list'>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    minWidth: '700',
    minWidth: 650,
    size: 'small',
    ariaLabel: 'a dense table'
  },
  tableRow: {
    height: 30
  },
  tableCell: {
    padding: "0px 16px"
  },
  btn: {
    margin: theme.spacing.unit
  },
  button: {
    'margin': theme.spacing.unit,
    'backgroundColor': '#0288d1',
    '&:hover': {
      backgroundColor: '#0288d1'
    }
  },
  button2: {
    'margin': theme.spacing.unit,
    'backgroundColor': '#0288d1',
    '&:hover': {
      backgroundColor: '#0288d1'
    },
    'height': '50px',
    'width': '15%'
  },
  pageHeader: {
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: '120px'
    }
  },
  paperHeader: {
    backgroundColor: grey[300]
  },
  activate: {
    color: green[800],
    textAlign: 'center'
  },
  deactivate: {
    color: red[400],
    textAlign: 'center'
  },
  card: {
    width: 500
  }
})

const UserCardList = props => {
  const { classes } = props
  return (
    <Card className={classes.card}>
      <CardContent>
        <Avatar>{props.user[1][0]}</Avatar>
        <List>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>First Name</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[1]}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>Last Name</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[2]}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>Email</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[3]}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>User type</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[4]}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant='caption'>Status</Typography>}
            />
            <ListItemSecondaryAction>
              <Typography variant='body2'>{props.user[5]}</Typography>
              {/* <RadioButtonCheckedIcon className={classes.activate} /> */}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

class CompanyAdmin extends Component {
  constructor(props) {
    super(props)
    //    // this.handleSelectedUserType = this.handleSelectedUserType.bind(this);

    // this.state = {
    //   data: [],
    //   openGroupAdminDialog: false,
    //   openGroupEditAdminDialog: false,
    //   openDialog: false,
    //   openUploadDialog: false,
    //   openPasswordChange: false,
    //   openUserType: false,
    //   selectedRowData: {},
    //   selectedUserType: "",
    //   selectedFile: null,
    //   showFdelete: false,
    //   selectDelete: 0,
    //   profilesDelete: {},
    //   anchorEl: null,
    //   edit: false,
    //   editedUser: {},
    //   uploading: false
    // };

    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  getMuiTheme = () =>
  createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: '#FF000'
          },
          paper: {
            boxShadow: 'none'
          },
          responsiveScroll: {
            marginTop: '50px'
          }
        },
        MUIDataTableHeadCell: {
          root: {
            padding: '0px',
            verticalAlign: 'top!important'
          },
          head: {
            fontSize: '0.7rem',
            fontWeight: '500',
            padding: '0px',
            verticalAlign: 'textTop'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: '0px',
            fontSize: '0.70rem!important',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            padding: '0px 1px 0px 0px'
          }
          // responsiveStacked: {
          //   height: "34px!important",
          //   fontSize: "0.70rem!important"
          // },
          // cellStacked: {
          //   height: "44px!important",
          //   fontSize: "0.70rem!important",
          //   // width: "50px!important",
          //   textAlign: "right!important"
          // }
        },
        MUIDataTableSelectCell: {
          root: {
            borderBottom: '0px'
          }
        },
        MUIDataTableToolbarSelect: {
          root: {
            display: 'inlineBlock'
          }
        }
      }
    });

  columns = [{
    name: 'Avatar',
    options: { filter: false }
  },
  {
    name: 'Firstname',
    options: { filter: true }
  },
  {
    name: 'Lastname',
    options: { filter: true }
  },
  // {
  //   name: 'Initials',
  //   options: { filter: true }
  // },
  {
    name: 'Email',
    options: { filter: true }
  },
  {
    name: 'User Type',
    options: { filter: true }
  },
  // {
  //   name: 'Gender',
  //   options: { filter: true }
  // },
  {
    name: 'Points',
    options: { filter: true }
  },
  {
    name: 'Sector',
    options: { filter: true }
  },
  // {
  //   name: 'Operation',
  //   options: { filter: true }
  // },
  {
    name: 'Position',
    options: { filter: true }
  },
  {
    name: 'Country',
    options: { filter: true }
  },
  // {
  //   name: 'Status',
  //   options: { filter: false }
  // },
  {
    name: 'DateRegistered',
    options: { filter: false }
  },
  {
    name: 'EC',
    options: { filter: false }
  },
  {
    name: 'Feedback',
    options: { filter: false }
  }
  ];

  options = {
    filterType: 'radio',
    sort: true,
    rowHover: true,
    responsive: 'standard',
    print: false,
    download: false,
    selectableRows: 'multiple',
    fixedHeader: false,
    tableHeaderStyle: { borderRight: '40px solid transparent' },
    onFilterChange: (changedColumn, filterList) => {
      // console.log(changedColumn, filterList);
    },
    // expandableRows: true,
    // renderExpandableRow: (rowData, rowMeta) => <UserCardList user={rowData} {...this.props} />,

    customToolbarSelect: e => {
      // console.log('customToolbarSelect props: ', this.props.userdata.userType);

      return (
        <EnhancedTableToolbar
          userType={this.props.userdata.userType}
          numSelected={e.data.length}
          multiple={e.data.length > 0}
          options={{
            elevation: 0
          }}
          onOpenChangePassword={this.handleOpenChangePassword}
          onOpenUserTypeDialog={this.handleUserTypeDialog}
          handleEditUserDialog={this.handleEditUserDialog}
          onResendWelcomeEmail={() =>
            this.handleResendWelcomeEmail(this.props.resendWelcomeEmail)
          }
          onChangeUserData={changeType => this.handleChangeUserData(changeType)}
          onDeleteUser={() =>
            e.data.length === 1 ?
              this.handleDeleteUser()
              :
              this.handleBulkDeleteUser(this.props.deleteCompanyUser, e.data.length)
          }
          onResetYUser={() =>
            e.data.length === 1 ?
              this.handleResetYUser()
              :
              this.handleResetBulkUsers(this.props.resetYCompanyUser, e.data.length)
          }
        />
      )
    },
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      let { classes, userdata, users } = this.props
      // console.log('testhd allRowsSelected 1: ', allRowsSelected );
      // console.log('testhd numSelected: ', allRowsSelected.length );

      let data =
        users &&
        Object.keys(users).map((v, i) => {
          return { data: users[v], uid: users[v].id }
        })
      console.log('testhd data: ', data)

      // ............................................
      let selectedData =
      allRowsSelected &&
        Object.keys(allRowsSelected).map((v, i) => {
        //  return { data: allRowsSelected[v], uid: allRowsSelected[v].dataIndex }
        //  return { data: data[allRowsSelected[v].dataIndex], uid: allRowsSelected[v].dataIndex }
          return { data: users[allRowsSelected[v].dataIndex], uid: allRowsSelected[v].dataIndex }
        })
      // ............................................

      if (allRowsSelected.length === 1) {
        if (currentRowsSelected[0] !== undefined) {
          window.su = data[currentRowsSelected[0].dataIndex]
          this.handleSelectedUser(window.su)
        }
      } else {
        window.su = selectedData
      }
    }
  };

  state = {
    data: [],
    openGroupAdminDialog: false,
    openGroupEditAdminDialog: false,
    openDialog: false,
    openUploadDialog: false,
    openPasswordChange: false,
    openUserType: false,
    selectedRowData: {},
    selectedUserType: '',
    selectedFile: null,
    showFdelete: false,
    selectDelete: 0,
    profilesDelete: {},
    anchorEl: null,
    edit: false,
    editedUser: {},
    uploading: false
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: !this.state.openDialog })
  };

  handleUserTypeDialog = () => {
    this.setState({
      openUserType: !this.state.openUserType,
      selectedUserType: window.su.data.userDescription
    })
  };
  handleOpenChangePassword = () => {
    this.setState({
      openPasswordChange: !this.state.openPasswordChange
    })
  };

  handleSelectedUserType = userTypeSelected => {
    const { changeUserType, userdata } = this.props
    let user = window.su.data
    user.uid = window.su.uid
    let params = {
      usersData: user,
      currentloggedinuser: userdata,
      selectedUserType: userTypeSelected
    }
    changeUserType(params)
    this.handleUserTypeDialog()
  };
  handleUploadDialog = () => {
    this.setState({ openUploadDialog: !this.state.openUploadDialog })
  };
  handleChangeUserData = changeType => {
    const { updateCompanyUser, getAllCompanyUsers, userdata } = this.props
    let userProfile = window.su.data
    userProfile.uid = window.su.uid
    let params = {
      changeType: changeType,
      userProfile: userProfile
    }
    updateCompanyUser(params)
    getAllCompanyUsers({
      companyID: userdata.companyID,
      orderChild: 'companyID'
    })
  };

  handleResendWelcomeEmail = resendWelcomeEmail => {
    let user = window.su
    resendWelcomeEmail({
      userProfile: { uid: user.uid, ...user.data }
    })
  };

  componentWillUnmount() {
    window.su = null
  }

  handleSelectedUser = currentRowsSelected => {
    // this.setState({
    //  selectedRowData: {
    //    uid: rowUserData[5].props.id,
    //    userID: rowUserData[5].key,
    //    firstName: rowUserData[1],
    //    lastName: rowUserData[2],
    //    email: rowUserData[3],
    //    userType: rowUserData[4]
    //  }
    // });
  };

  handleSubmitUser = userData => {
    console.log('userData', userData)
    let params = {
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      companyID: this.props.userdata.companyID,
      companyName: this.props.userdata.companyName,
      userType: userData.usertype,
      selectedCategory: userData.usertype,
      surName: userData.surName,
      gender: userData.gender,
      points: userData.points,
      race: userData.race,
      position: userData.position,
      nationality: userData.nationality,
      idNumber: userData.idNumber,
      cellphone: userData.cellphone,
      department: userData.department,
      operation: userData.operation,
      edit: userData.edit,
      uid: userData.uid,
      userID: userData.userID,
      feedback: userData.feedback,
      feedbackOn: userData.feedbackOn ? userData.feedbackOn : true,
      demoOn: userData.demoOn ? userData.demoOn : false,
      LeaderboardKey: this.props.userdata.companyID + '_' + userData.nationality + '_' + userData.position + '_' + userData.department
    }
    console.log('create user', params)

    // check if user exists
    // this.props.createCompanyUser(params)
    if (userData.edit === false) {
      this.props.checkUserExists(params, () => {
        this.props.createCompanyUser(params)
        this.props.getAllCompanyUsers({
          companyID: this.props.userdata.companyID,
          orderChild: 'companyID'
        })
      }, () => {
        this.handleCloseDialog()
      })
    } else {
      // this.props.editUser(params)
      this.props.checkUserExistsEdit(params) // USE THIS PROCESS ONCE USERS CAN BE RE-AUTHENTICATED AFTER EMAIL CHANGE
      this.props.editUser(params)
      this.props.getAllCompanyUsers({
        companyID: this.props.userdata.companyID,
        orderChild: 'companyID'
      })
      this.handleCloseDialog()
    }
  };

  componentDidUpdate() {
    // if (this.props.user_exists && this.props.user_exists.proceed === true) {
    // create new user if everything is fine

    // }
  }

  componentDidMount() {
    let { classes, userdata, getAllCompanyUsers, getAllCompanySettings, getAllGeneralSettings, users, simpleAction } = this.props
    getAllCompanyUsers({
      companyID: userdata.companyID,
      orderChild: 'companyID'
    })
    getAllCompanySettings({
      companyID: userdata.companyID
    })
    getAllGeneralSettings({
      companyID: userdata.companyID
    })
    let body = {
      type: SIMPLE_VALUES,
      surveyid: false
    }
    simpleAction(body)
  }

  formatUserList(param) {
    const temp = []
    param.map((user) => {
      temp.push({
        firstName: user[2],
        lastName: user[3],
        email: user[4],
        userType: user[5],
        gender: user[6],
        department: user[7],
        operation: user[8],
        position: user[9],
        nationality: user[10],
        uid: user[11],
        userID: user[12],
        stringDateRegistered: user[13],
        selected: false,
        visible: '',
        execmember: user[15],
        feedback: user[16]
      })
    })

    return temp
  }

  timeConverter = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp)
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let year = a.getFullYear()
    // var month = months[a.getMonth()];
    let month = a.getMonth()
    month = month + 1

    let date = a.getDate()
    let vdate = date.toString()
    let vmonth = month.toString()
    let vvdate
    let vvmonth
    console.log('date len: ', vdate.length)
    // ///////////////////
    if (vdate.length === 1) {
      var s = '0' + date
      vvdate = s.substr(s.length - 2)
    } else {
      vvdate = vdate
    }
    // /////////////////////////
    if (vmonth.length === 1) {
      var s = '0' + month
      vvmonth = s.substr(s.length - 2)
    } else {
      vvmonth = month
    }
    // //////////////////
    // vdate = (Math.round(date * 100) / 100).toFixed(2);
    let hour = a.getHours()
    let min = a.getMinutes()
    let sec = a.getSeconds()
    // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    let time = year + '/' + vvmonth + '/' + vvdate
    return time
  }

  transformTableData = () => {
    let { classes, userdata, getAllCompanyUsers, users } = this.props
     console.log('transformTableDatax 1 : ', this.props);

    let dataListClone = Object.keys(users).map((uid, index) => {
      console.log('green avatar : ', users[uid].photoURL)
      let ds = users[uid].dateRegistered
      // var date = new Date(ds);
      // var hours = date.getHours();
      // var ddds = hours // d.getMonth();
      let ddds = this.timeConverter(ds)
      return [
        <Avatar
          alt={users[uid].firstName + ' ' + users[uid].lastName}
          src={PHOTO_PROFILE(users[uid].photoURL)}
          id={users[uid].uid}
        />,

        users[uid].firstName ? users[uid].firstName : '',
        users[uid].lastName ? users[uid].lastName : '',
        // users[uid].surName ? users[uid].surName : '',
        users[uid].email,
        users[uid].userDescription,
        // users[uid].gender ? users[uid].gender : "",
        users[uid].points ? users[uid].points : '',
        users[uid].department ? users[uid].department : '',
        // users[uid].operation ? users[uid].operation : "",
        users[uid].position ? users[uid].position : '',
        users[uid].nationality ? users[uid].nationality : '',
        users[uid].cellphone ? users[uid].cellphone : '',
        // users[uid].disabled ? false : true
        // <RadioButtonCheckedIcon
        //   id={users[uid].uid}
        //   key={users[uid].userID}
        //   className={
        //     users[uid].disabled ? classes.deactivate : classes.activate
        //   }
        // />,
        ddds ? ddds : '',
        users[uid].execmember ? 'Y' : 'N',
        users[uid].feedback ? users[uid].feedback : 'N',
        users[uid].uid ? users[uid].uid : '',
        users[uid].userID ? users[uid].userID : ''
      ]
    })

    return dataListClone
  };

  handleCloseGroupAdminDialog = () => {
    this.setState({ openGroupAdminDialog: false })
  }

  handleCloseGroupEditAdminDialog = () => {
    this.setState({ openGroupEditAdminDialog: false })
  }

  handleDeleteUser() {
    const { deleteCompanyUser, showToast } = this.props

    let userProfile = window.su.data
    userProfile.uid = window.su.uid

    let currUserID = this.props.userdata.userID

    if (currUserID == userProfile.userID) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Cannot delete your own account!'
      })

      return
    }

    const answerEmail = prompt(
      `Are you sure you want to remove ${userProfile.firstName} ${userProfile.lastName} \n ( Please type in the email of the User !) `
    )

    if (answerEmail === '' || answerEmail === null || answerEmail !== userProfile.email) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'User cannot be deleted: Incorrect email address provided.'
      })

      return
    }

    deleteCompanyUser({
      userProfile: userProfile
    })
    showToast({
      type: 'SHOW_TOAST',
      open: true,
      variant: 'success',
      message: 'Deleted ' + userProfile.firstName + ' ' + userProfile.lastName + ' successfully!'
    })
  }

  handleResetYUser() {
    const { resetYCompanyUser, showToast } = this.props

    let userProfile = window.su.data
    userProfile.uid = window.su.uid

    let currUserID = this.props.userdata.userID

    // if (currUserID == userProfile.userID) {
    //   showToast({
    //     type: 'SHOW_TOAST',
    //     open: true,
    //     variant: 'warning',
    //     message: 'Cannot delete your own account!'
    //   })

    //   return
    // }

    const answerEmail = prompt(
      `Are you sure you want to Reset Annual Points for ${userProfile.firstName} ${userProfile.lastName} \n ( Please type in the email of the User !) `
    )

    if (answerEmail === '' || answerEmail === null || answerEmail !== userProfile.email) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'User annual points cannot be reset: Incorrect email address provided.'
      })

      return
    }

    resetYCompanyUser({
      userProfile: userProfile
    })
    
    showToast({
      type: 'SHOW_TOAST',
      open: true,
      variant: 'success',
      message: 'Reset Annual points for ' + userProfile.firstName + ' ' + userProfile.lastName + ' successfully!'
    })
  }

  handleBulkDeleteUser(deleteCompanyUser, numSelected) {
    let { classes, userdata, users, showToast } = this.props

    let userProfile = window.su

    let currUserID = this.props.userdata.userID
    let allDeletedUsers = ''
    let suicide = false

    let sdata =
    userProfile &&
    Object.keys(userProfile).map((v, i) => {
      allDeletedUsers = allDeletedUsers + '\n' + userProfile[v].data.firstName + ' ' + userProfile[v].data.lastName + '\n'
      if (currUserID == userProfile[v].data.userID) {
        suicide = true
      }
    })

    if (suicide == true) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Cannot delete your own account!'
      })

      return
    }

    const answerYes = prompt(
      `Are you sure you want to remove ${allDeletedUsers} \n ( Please type 'yes' if you want to proceed !) `
    )

    console.log('answer: ', 'yes')

    // if (answerYes === '' || answerYes === null || answerYes !== 'yes' || answerYes !== 'Yes' || answerYes !== 'y') {
    if (answerYes === '' || answerYes === null) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Delete process cancelled.'
      })

      return
    }

    let data = userProfile &&
      Object.keys(userProfile).map((v, i) => {
        deleteCompanyUser({
          userProfile: userProfile[v].data
        })
      })

    showToast({
      type: 'SHOW_TOAST',
      open: true,
      variant: 'success',
      message: 'Deleted ' + '\n' + allDeletedUsers + ' successfully!'
    })
  }

  handleResetBulkUsers(resetYCompanyUser, numSelected) {
    let { classes, userdata, users, showToast } = this.props

    let userProfile = window.su

    let currUserID = this.props.userdata.userID
    let allDeletedUsers = ''

    // let sdata =
    // userProfile &&
    // Object.keys(userProfile).map((v, i) => {
    //   allDeletedUsers = allDeletedUsers + '\n' + userProfile[v].data.firstName + ' ' + userProfile[v].data.lastName + '\n'
    //   if (currUserID == userProfile[v].data.userID) {
    //     suicide = true
    //   }
    // })

    // if (suicide == true) {
    //   showToast({
    //     type: 'SHOW_TOAST',
    //     open: true,
    //     variant: 'warning',
    //     message: 'Cannot delete your own account!'
    //   })

    //   return
    // }

    const answerYes = prompt(
      `Are you sure you want to Reset Annual points for ${allDeletedUsers} \n ( Please type 'yes' if you want to proceed !) `
    )

    console.log('answer: ', 'yes')

    // if (answerYes === '' || answerYes === null || answerYes !== 'yes' || answerYes !== 'Yes' || answerYes !== 'y') {
    if (answerYes === '' || answerYes === null) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Reset of Annual Points cancelled.'
      })

      return
    }

    let data = userProfile &&
      Object.keys(userProfile).map((v, i) => {
        resetYCompanyUser({
          userProfile: userProfile[v].data
        })
      })

    showToast({
      type: 'SHOW_TOAST',
      open: true,
      variant: 'success',
      message: 'Annual Points Reset ' + '\n' + allDeletedUsers + ' successfully!'
    })
  }

  yyhandleDeleteUser(deleteCompanyUser, numSelected) {
    const { showToast } = this.props

    let userProfile = window.su.data
    userProfile.uid = window.su.uid
    // console.log('testhd userProfile: ', userProfile);

    let currUserID = this.props.userdata.userID

    if (currUserID == userProfile.userID) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Cannot delete your own account!'
      })

      return
    }

    // const answerEmail = prompt(
    //   `Are your sure you want to remove ${userProfile.firstName} ${userProfile.lastName} \n ( Please type in the email of the User !) `
    // );

    // console.log("answer: ", answerEmail);

    // if (answerEmail === '' || answerEmail === null || answerEmail !== userProfile.email) {
    //   showToast({
    //     type: 'SHOW_TOAST',
    //     open: true,
    //     variant: 'warning',
    //     message: "User cannot be deleted: Incorrect email address provided."
    //   });

    //   return;
    // }

    // showToast({
    //   type: 'SHOW_TOAST',
    //   open: true,
    //   variant: 'error',
    //   message: "User deleted."
    // });

    // return;
    // deleteCompanyUser({
    //   userProfile: userProfile
    // })
  }

  xxhandleDeleteUser() {
    const { deleteCompanyUser, showToast } = this.props

    let userProfile = window.su.data
    userProfile.uid = window.su.uid

    let currUserID = this.props.userdata.userID

    if (currUserID == userProfile.userID) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'Cannot delete your own account!'
      })

      return
    }

    const answerEmail = prompt(
      `Are you sure you want to remove ${userProfile.firstName} ${userProfile.lastName} \n ( Please type in the email of the User !) `
    )

    // console.log("answer: ", answerEmail);

    if (answerEmail === '' || answerEmail === null || answerEmail !== userProfile.email) {
      showToast({
        type: 'SHOW_TOAST',
        open: true,
        variant: 'warning',
        message: 'User cannot be deleted: Incorrect email address provided.'
      })

      return
    }

    // showToast({
    //   type: 'SHOW_TOAST',
    //   open: true,
    //   variant: 'error',
    //   message: "User deleted."
    // });

    // return;
    deleteCompanyUser({
      userProfile: userProfile
    })
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true, edit: false, editedUser: {} })
    this.handleClose()
  };

  handleEditUserDialog = () => {
    this.setState({ openDialog: true, edit: true, editedUser: window.su.data })
  };

  formatDate(date) {
    let d = new Date(date)


    let month = '' + (d.getMonth() + 1)


    let day = '' + d.getDate()


    let year = d.getFullYear()


    let hours = '' + d.getHours()


    let minutes = '' + d.getMinutes()


    let seconds = '' + d.getSeconds()


    let milliseconds = '' + d.getMilliseconds()

    let today = new Date()


    let thisYear = today.getFullYear()

    // get week day name Sunday - Saturday
    let dayName = this.getDayMonthStr(d.getDay(), 'day', true)

    // get month name
    let monthName = this.getDayMonthStr(d.getMonth(), 'month', true)

    // add preceding zeros where needed
    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    if (hours.length < 2) {
      hours = '0' + hours
    }
    if (minutes.length < 2) {
      minutes = '0' + minutes
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds
    }
    if (milliseconds.length < 2) {
      milliseconds = '0' + milliseconds
    }

    // format thoughts date
    // Monday, 04 December 2017 14:21:56
    let thoughtDate =
      dayName +
      ', ' +
      day +
      ' ' +
      monthName +
      ' ' +
      year +
      ' ' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds

    return thoughtDate
    // return [year, month, day].join('-');
  }

  getDayMonthStr(val, dateParam, long) {
    // days
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ]
    let daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']

    // Months
    let monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    let monthNamesShort = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    if (dateParam === 'day' && long === true) {
      // return day in long format
      return days[val]
    } else if (dateParam === 'day' && long === false) {
      // return days in short
      return daysShort[val]
    }
    if (dateParam === 'month' && long === true) {
      // return month in long format
      return monthNames[val]
    } else if (dateParam === 'month' && long === false) {
      // return month in short
      return monthNamesShort[val]
    }
  }

  openGroupAdmin = event => {
    this.setState({ openGroupAdminDialog: true })
  }

  openGroupEditAdmin = event => {
    this.setState({ openGroupEditAdminDialog: true })
  }

  openEditGroupAdmin = event => {
    this.setState({ openEditGroupAdminDialog: true })
  }

  onChangeHandler = event => {
    const {simpleAction} = this.props;
     let companyID = this.props.userdata.companyID
     let companyName = this.props.userdata.companyName
     // let companyID = "-LoacuJOqQP7sZU7_sFQ";
     // let companyName = "Global Leadership Initiatives";
     let reader = new FileReader()
     let timeInMs = Date.now()
     let datetime = new Date(timeInMs)
 
     datetime = this.formatDate(datetime)
 
     reader.onload = function(e) {
       let data = e.target.result
       // if (data) {
       //   this.setState({ uploading: true });
       // }
       var body = {
         type: SIMPLE_VALUES,
         surveyid: true      
         }
       simpleAction(body);
 
       console.log('green uploading')
       let workbook = xlsx.read(data, {
         type: 'binary'
       })
 
       function to_json(workbook) {
         let result = {}
         workbook.SheetNames.forEach(function(sheetName) {
           let roa = xlsx.utils.sheet_to_row_object_array(
             workbook.Sheets[sheetName]
           )
           if (roa.length > 0) {
             result[sheetName] = roa
           }
         })
         return result
       }
 
       function randomString(length) {
         let text = "";
         let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789";
         for (var i = 0; i < length; i++) {
           text += possible.charAt(Math.floor(Math.random() * possible.length));
         }
         return text;
       }
 
       let xls_json = to_json(workbook)
       let users
 
       Object.keys(xls_json).forEach(key => {
         users = xls_json[key]
         users.forEach((userobj, index) => {
           let email = userobj.Email
 
           if (email !== undefined && email.length > 5) {
             let userID = firebase.database
               .ref()
               .child('newUploadUsers')
               .push().key
             let defPassword = randomString(15) //"PASS123456789";
             // let defPassword =
             //   Math.random()
             //     .toString(36)
             //     .substring(2, 18) +
             //   Math.random()
             //     .toString(36)
             //     .substring(2, 18)
             defPassword = defPassword.toUpperCase() //"PASS123456789";
 
             let data = {
               companyID: companyID,
               companyName: companyName,
               dateRegistered: timeInMs,
               email: userobj.Email,
               firstName: userobj.Firstname,
               lastName: userobj.Lastname,
               surName: userobj.Surname ? userobj.Surname : "",
               gender: userobj.gender ? userobj.gender : "",
               points: userobj.points ? userobj.points : "",
               race: userobj.race ? userobj.race : "",
               position: userobj.position ? userobj.position : "",
               nationality: userobj.nationality ? userobj.nationality : "",
               idNumber: userobj.idNumber ? userobj.idNumber : "",
               cellphone: userobj.cellphone ? userobj.cellphone : "",
               department: userobj.department ? userobj.department : "",
               operation: userobj.operation ? userobj.operation : "",
               password: defPassword,
               stringDateRegistered: userobj.stringDateRegistered ? userobj.stringDateRegistered : "",
               userDescription: userobj['User Type'],
               newUploadUserID: userID,
               userType: 8,
               companyID_userType: companyID + '_' + userobj['User Type']
             }
 
             let selectedUserType = ''
 
             selectedUserType = [
               { value: 3, description: 'Public Contributor' },
               { value: 7, description: 'Company Admin' },
               { value: 8, description: 'Standard User' },
               { value: 10, description: 'Corporate I-Leader' },
               { value: 11, description: 'Organisational Contributor' },
               { value: 4, description: 'Global Contributor' },
               { value: 14, description: 'Client Admin' },
               { value: 12, description: 'Delegate' }
             ].find((userType) => {
               if (userType.description === data.userDescription) {
                 return userType.value
               }
             })
 
             data.userType = selectedUserType.value
 
             // console.log('bulk upload', data);
             // Write the new rating data
             let updates = {}
            
             updates['/newUploadUsers/' + userID] = data
 
             // console.log("new upload users updates", updates);
             firebase.database.ref().update(updates, error => {
               if (error) {
                 showToast({
                   type: 'SHOW_TOAST',
                   open: true,
                   variant: 'error',
                   message: error.message
                 })
               } else {
 
                 var body = {
                   type: SIMPLE_VALUES,
                   surveyid: false      
                   }
                 simpleAction(body);
 
                 showToast({
                   type: 'SHOW_TOAST',
                   open: true,
                   variant: 'success',
                   message: 'Bulk file containing users has been uploaded.'
                 })
               }
             })
           }
         })
       })
     }
     this.handleClose()
     console.log('green green close thing')
     reader.readAsBinaryString(event.target.files[0])
     event.target.value = ''
   };

  // onChangeHandler = event => {
  //   const { simpleAction } = this.props
  //   let companyID = this.props.userdata.companyID
  //   let companyName = this.props.userdata.companyName
  //   // let companyID = "-LoacuJOqQP7sZU7_sFQ";
  //   // let companyName = "Global Leadership Initiatives";
  //   let reader = new FileReader()
  //   let timeInMs = Date.now()
  //   let datetime = new Date(timeInMs)

  //   datetime = this.formatDate(datetime)

  //   reader.onload = function (e) {
  //     let data = e.target.result
  //     var workbook;
  //     // if (data) {
  //     //   this.setState({ uploading: true });
  //     // }
  //     let body = {
  //       type: SIMPLE_VALUES,
  //       surveyid: true
  //     }
  //     simpleAction(body)

  //     // [NEED TO BE FIX] need to fix as per need
  //     console.log('green uploading')
  //     // let workbook = xlsx.read(data, {
  //     //   type: 'binary'
  //     // })

  //     // [NEED TO BE FIX] need to fix as per need
  //     function to_json(workbook) {
  //       let result = {}
  //       workbook.SheetNames.forEach(function (sheetName) {
  //         // let roa = xlsx.utils.sheet_to_row_object_array(
  //         //   workbook.Sheets[sheetName]
  //         // )
  //         // if (roa.length > 0) {
  //         //   result[sheetName] = roa
  //         // }
  //       })
  //       return result
  //     }

  //     function randomString(length) {
  //       let text = ''
  //       let possible = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789'
  //       for (let i = 0; i < length; i++) {
  //         text = text + possible.charAt(Math.floor(Math.random() * possible.length))
  //       }
  //       return text
  //     }

  //     let xls_json = to_json(workbook)
  //     let users

  //     Object.keys(xls_json).forEach(key => {
  //       users = xls_json[key]
  //       users.forEach((userobj, index) => {
  //         let email = userobj.Email

  //         if (email !== undefined && email.length > 5) {
  //           let userID = firebase.database
  //             .ref()
  //             .child('newUploadUsers')
  //             .push().key
  //           let defPassword = randomString(15) // "PASS123456789";
  //           // let defPassword =
  //           //   Math.random()
  //           //     .toString(36)
  //           //     .substring(2, 18) +
  //           //   Math.random()
  //           //     .toString(36)
  //           //     .substring(2, 18)
  //           defPassword = defPassword.toUpperCase() // "PASS123456789";

  //           let data = {
  //             companyID: companyID,
  //             companyName: companyName,
  //             dateRegistered: timeInMs,
  //             email: userobj.Email,
  //             firstName: userobj.Firstname,
  //             lastName: userobj.Lastname,
  //             surName: userobj.Surname ? userobj.Surname : '',
  //             gender: userobj.gender ? userobj.gender : '',
  //             points: userobj.points ? userobj.points : '',
  //             race: userobj.race ? userobj.race : '',
  //             position: userobj.position ? userobj.position : '',
  //             nationality: userobj.nationality ? userobj.nationality : '',
  //             idNumber: userobj.idNumber ? userobj.idNumber : '',
  //             department: userobj.department ? userobj.department : '',
  //             operation: userobj.operation ? userobj.operation : '',
  //             password: defPassword,
  //             stringDateRegistered: userobj.stringDateRegistered ? userobj.stringDateRegistered : '',
  //             userDescription: userobj['User Type'],
  //             newUploadUserID: userID,
  //             userType: 8,
  //             companyID_userType: companyID + '_' + userobj['User Type']
  //           }

  //           let selectedUserType = ''

  //           selectedUserType = [
  //             { value: 3, description: 'Public Contributor' },
  //             { value: 7, description: 'Company Admin' },
  //             { value: 8, description: 'Standard User' },
  //             { value: 10, description: 'Corporate I-Leader' },
  //             { value: 11, description: 'Organisational Contributor' },
  //             { value: 4, description: 'Global Contributor' },
  //             { value: 14, description: 'Client Admin' },
  //             { value: 12, description: 'Delegate' }
  //           ].find((userType) => {
  //             if (userType.description === data.userDescription) {
  //               return userType.value
  //             }
  //           })

  //           data.userType = selectedUserType.value

  //           // console.log('bulk upload', data);
  //           // Write the new rating data
  //           let updates = {}

  //           updates['/newUploadUsers/' + userID] = data

  //           // console.log("new upload users updates", updates);
  //           firebase.database.ref().update(updates, error => {
  //             if (error) {
  //               showToast({
  //                 type: 'SHOW_TOAST',
  //                 open: true,
  //                 variant: 'error',
  //                 message: error.message
  //               })
  //             } else {
  //               let body = {
  //                 type: SIMPLE_VALUES,
  //                 surveyid: false
  //               }
  //               simpleAction(body)

  //               showToast({
  //                 type: 'SHOW_TOAST',
  //                 open: true,
  //                 variant: 'success',
  //                 message: 'Bulk file containing users has been uploaded.'
  //               })
  //             }
  //           })
  //         }
  //       })
  //     })
  //   }
  //   this.handleClose()
  //   console.log('green green close thing')
  //   reader.readAsBinaryString(event.target.files[0])
  //   event.target.value = ''
  // };

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget })
    // console.log(this.state.anchorEl)
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, match, location, users, showToast } = this.props

    let StyledMenu = withStyles({
      paper: {
        border: '1px solid #d3d4d5',
        backgroundColor: LIGHTGREY
      }
    })((props) => (
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        {...props}
      />
    ))

    let StyledMenuItem = withStyles((theme) => ({
      root: {
        '&:focus': {
          'backgroundColor': theme.palette.primary.main,
          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.common.white
          }
        }
      }
    }))(MenuItem)

    return (

      <Paper style={{ maxWidth: '120vw' }} className={classes.pageHeader}>
        <div>
          { this.props.simplething === true ?
            <div style={{
              width: '100%',
              height: '20px'
            }}><LeaderLoader /></div>
            : null}
        </div>

        <div>
          <Button
            className={classes.btn}
            aria-controls='customized-menu'
            aria-haspopup='true'
            variant='outlined'
            color='primary'
            size='small'
            onClick={this.handleClick}
          >
            ACTIONS
            <ArrowDropDownIcon />
          </Button>
          <StyledMenu
            id='customized-menu'
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            {this.props.userdata.userType === 14 ? null :
              <StyledMenuItem
                onClick={this.handleOpenDialog}
              >
                <ListItemIcon>
                  <PersonAddIcon fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary='Add User' />

              </StyledMenuItem>
            }
            {this.props.userdata.userType === 14 ? null :
              <input
                className={classes.input}
                style={{ display: 'none' }}
                id='raised-button-file'
                type='file'
                onChange={this.onChangeHandler}
              />
            }
            {this.props.userdata.userType === 14 ? null :
              <label htmlFor='raised-button-file'>
                <StyledMenuItem
                  // variant='raised'
                  component='span'
                  variant='contained'
                  color='primary'
                >
                  <ListItemIcon>
                    <GroupAddIcon fontSize='medium' />
                  </ListItemIcon>
                  <ListItemText primary='Upload Users (XLS)' />
                </StyledMenuItem>
              </label>
            }

            {/* <StyledMenuItem
              onClick={this.openGroupAdmin}
            >
              <ListItemIcon>
                <GroupAddIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Add Group' />
            </StyledMenuItem>

            <StyledMenuItem
              onClick={this.openGroupEditAdmin}
            >
              <ListItemIcon>
                <EditIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Edit Group' fontSize='small' />
            </StyledMenuItem> */}
            <StyledMenuItem
              component={Link}
              to={`/groups`}
              variant='contained'
            >

              <ListItemIcon>
                <GroupWorkIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Manage Groups' />
            </StyledMenuItem>
            {ACCESS_COMPETENCIES[this.props.userdata.companyID] ?
              <StyledMenuItem
                component={Link}
                to={`/competencies`}
                variant='contained'
              >
                <ListItemIcon>
                  <AccountBalanceIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>
                  {VALUES_NAME[`${this.props.userdata.companyID}`]
                    ? VALUES_NAME[`${this.props.userdata.companyID}`]
                    : VALUES_NAME.other}
                </ListItemText>
              </StyledMenuItem>
              : null}

            {this.props.userdata.userType === 7 || this.props.userdata.userType === '7' ?
              <StyledMenuItem
                component={Link}
                to={`/tokens`}
                variant='contained'
              >
                <ListItemIcon>
                  <AccountBalanceIcon fontSize='small' />
                </ListItemIcon>
                {/* <ListItemText>
                  {VALUES_NAME[`${this.props.userdata.companyID}`]
                    ? VALUES_NAME[`${this.props.userdata.companyID}`]
                    : VALUES_NAME.other}
                </ListItemText> */}
                <ListItemText primary='Tokens' />
              </StyledMenuItem>
              : null}

          </StyledMenu>

        </div>

        {/* <div>
       { this.state.uploading ?
        <div style={{
          width:'100%',
          height: '20px'
          }}><LeaderLoader /></div>
         :null}
         </div> */}

        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title={'Club Members'}
            data={this.transformTableData()}
            columns={this.columns}
            options={this.options}
            {...this.props}
          />
        </MuiThemeProvider>

        <GroupAdminDialog
          open={this.state.openGroupAdminDialog}
          closeGroupAdminDialog={this.handleCloseGroupAdminDialog}
          columns={this.columns}
          users={this.formatUserList(this.transformTableData())}
        />

        <GroupEditAdminDialog
          open={this.state.openGroupEditAdminDialog}
          closeGroupAdminDialog={this.handleCloseGroupEditAdminDialog}
          columns={this.columns}
          users={this.formatUserList(this.transformTableData())}
        />

        <AddCorpUserDialog
          open={this.state.openDialog}
          edit={this.state.edit}
          editedUser={this.state.editedUser}
          onSubmitUserClick={this.handleSubmitUser}
          updateOnSave={this.state.openUpdateDialog}
          onOpenAddUserDialog={this.handleCloseDialog}
          userdata={this.props.userdata}
        />

        <UserTypeDialog
          open={this.state.openUserType}
          onSubmitSelectedUserType={this.handleSelectedUserType}
          selectedUserType={this.state.selectedUserType}
          onOpenUserTypeDialog={this.handleUserTypeDialog}
          userdata={this.props.userdata}
        />
        <ChangeUserPasswordDialog
          onOpenChangePassword={this.handleOpenChangePassword}
          open={this.state.openPasswordChange}
        />
      </Paper>
    )
  }
}
const CompanyAdminStyled = withStyles(styles)(CompanyAdmin)

const mapStateToProps = (state, ownProps) => {
  return {
    userdata: state.userDialog.userDetails[0],
    users: state.users.users,
    user_exists: state.users.userexists,
    showprogress: state.loader.showloader,
    simplething: state.simpleVal.simplething
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllCompanyUsers,
    getAllCompanySettings,
    getAllGeneralSettings,
    deleteCompanyUser,
    resetYCompanyUser,
    createCompanyUser,
    checkUserExists,
    checkUserExistsEdit,
    resendWelcomeEmail,
    updateCompanyUser,
    changeUserType,
    showLoader,
    showToast,
    hideLoader,
    editUser,
    simpleAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminStyled)
