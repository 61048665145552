import { SHOW_CALF } from '../actions/types';

const initState = {
    type: SHOW_CALF,
    link: ''
};

const calfReducer = (state = initState , action) => {
  //console.log('calfReducer params 1: ', action.link);

  switch(action.type){
    case SHOW_CALF:
      return{ 
        ...state,
        type: SHOW_CALF,
        calf: action.link
      }
    default:
      return state
  }
  
}

export default calfReducer