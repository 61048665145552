import { SIMPLE_VAL2 } from "./types";


export const simpleValAction2 = params => {
  if (params.type === SIMPLE_VAL2 && params.go===true) {
      console.log('green simpleaction 2 : ', params.surveyid);
    if(params.surveyid) {
    return (dispatch, getState) => {
        if (params) {
          dispatch({
            type: SIMPLE_VAL2,
            s_action2: params.surveyid
          });
    
        } else {
         // return (dispatch, getState) => {
            dispatch({
              type: SIMPLE_VAL2,
              s_action2: false
            });
         // };
        }
   } }
   else
   {
    return (dispatch, getState) => {
      dispatch({
        type: SIMPLE_VAL2,
        s_action2: false
      });
    };
   }
 }
};