/* eslint-disable no-inline-comments */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import MicIcon from '@material-ui/icons/Mic'
import VideocamIcon from '@material-ui/icons/Videocam'
import ImageIcon from '@material-ui/icons/Image'
import ForumIcon from '@material-ui/icons/CommentOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'

import Badge from '@material-ui/core/Badge'
import Liking from './../../components/Buttons/Liking'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CardMessage from './CardsMessage'
import CloseIcon from '@material-ui/icons/Close'
import ArticleCardsMessage from './ArticleCardsMessage'
import { DATE_FORMAT } from '../../utils/date_format_utils'
import { PHOTO_BACKGROUND, PHOTO_PROFILE } from '../../utils/photo_utils'
import { YOUTUBE } from './../../utils/youtube_utils'
import CommentDialogCard from './../Cards/CommentDialogCard'
import Follow from '../Buttons/Follow'

import AddCommentField from '../Comments/AddCommentField'
import Comments from '../Comments'

import { APP_TYPE, USER_CLICKS_POST, IS_HARMONY } from '../../store/actions/types'


import {
  GREY,
  RED,
  LIGHTBLUE,
  GREEN,
  LIGHTGREY,
  HOVERRED,
  DARKBLUE
} from '../../utils/color_utils'
import RemoveAttachmentDialog from '../Dialogs/RemoveAttachmentDialog'
import PLDPFavorites from './../Buttons/PLDPFavorites'
import {
  fetchUserBiography,
  openUserProfileDialog
} from '../../store/actions/userBiographyActions'
import {
  captureUserActivity
} from '../../store/actions/analyticsActions'
import MoreMenu from './MoreMenu'
import RemoveFavorite from './RemoveFavorite'
import LoadSocialNetworks from '../../components/socialnetworks/socialappmodal'

const styles = theme => ({
  card: {
    maxWidth: 600,
    position: 'relative'
  },
  // media: {
  //   height: 0,
  //   paddingTop: "56.25%", // 16:9
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center"
  // },
  actions: {
    display: 'flex',
    padding: 0
  },
  actionsComments: {
    boxSizing: 'border-box',
    alignItems: 'center',
    height: 0,
    maxHeight: 0,
    overflow: 'hidden',
    position: 'relative',
    display: 'none'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: theme.palette.grey[500]
  },
  badgemargin: {
    position: 'absolute',
    left: '-70px',
    width: 'max-content',
    top: '10px',
    right: '76%',
    backgroundColor: '#f4eeee',
    color: '#676767',
    padding: '8px',
    fontSize: '8pt',
    border: `2px solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`
  },
  badge: {
    width: 'max-content',
    color: '#fff',
    padding: '8px',
    fontSize: '8pt',
    border: `2px solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`,
    right: '-28px',
    backgroundColor: LIGHTBLUE
  },
  commentsbadge: {
    width: 'max-content',
    color: '#fff',
    padding: '8px',
    fontSize: '8pt',
    border: `2px solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`,
    right: '-13px',
    backgroundColor: LIGHTBLUE
  },
  // overlay: {
  //   position: "absolute",
  //   top: "25%",
  //   textAlign: "center",
  //   width: "-webkit-fill-available",
  //   backgroundColor: "none",
  //   cursor: "pointer"
  // },
  media: {
    height: '280px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    borderRadius: '1px',
    textAlign: 'center'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    textAlign: 'center',
    width: '100%',
    backgroundColor: 'none',
    height: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardmedia: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  cardstyle: {
    flex: '1',
    position: 'relative',
    margin: '10px',
    minWidth: '320px',
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 4px',
      minWidth: '300px'
    }
  },
  nested: {
    marginLeft: 40,
    backgroundColor: LIGHTGREY
  },
  tableactions: {
    minWidth: '-webkit-fill-available'
  },
  attachmentrow: {
    backgroundColor: LIGHTGREY
  },
  comments: {
    display: 'block',
    width: '100%'
  },
  commenter: {
    marginRight: 10
  },
  commentTime: {
    fontSize: 12,
    color: 'rgba(0,0,0,.6)'
  },
  replyToComment: {
    color: LIGHTBLUE,
    transition: '.5s all ease-in',
    transform: 'rotate(-45deg)'
  },
  attachments: {
    display: 'inline-block',
    maxHeight: 150,
    padding: 8,
    borderRadius: 4,
    boxShadow: '0px 0px 10px #ddd',
    borderColor: '#ccc',
    textAlign: 'left',
    marginBottom: 15,
    marginLeft: 27,
    fontSize: 12,
    color: GREY
  },
  attachmentControl: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: 10
  },
  deleteAttachment: {
    // position: "absolute",
    // margin: "-28px -13px",
    'background': 'rgba(0, 0, 0, 0.24)',
    'borderColor': 'black',
    '&:hover': {
      backgroundColor: RED
    }
  },
  favoritesrow: {
    backgroundColor: LIGHTGREY,
    flexDirection: 'row',
    width: '-webkit-fill-available',
    padding: '8px 16px'
  },
  favoritesdiv: {
    color: '#fff',
    float: 'left!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  commentsdiv: {
    color: '#fff',
    float: 'right!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e1e8f7',
    borderRadius: '10%',
    padding: '0px 10px',
    marginRight: '20px'
  },
  mask: {
    position: 'absolute',
    height: 150,
    right: 0,
    cursor: 'pointer',
    bottom: 0,
    left: 0,
    zIndex: 9,
    background:
      'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.67), rgba(255, 255, 255, 1))'
  },
  maskText: {
    position: 'absolute',
    right: 0,
    bottom: 10,
    left: 0,
    padding: 5,
    textAlign: 'center',
    cursor: 'pointer',
    color: LIGHTBLUE
  },
  close: {
    'position': 'absolute',
    'top': '0px',
    'right': '0px',
    'zIndex': '999999',
    'color': '#fff',
    'backgroundColor': `${RED}`,
    'borderRadius': '0%',
    'padding': '8px',
    '&:hover': {
      background: `${HOVERRED}`
    }
  },
  sharediv: {
    color: '#fff',
    float: 'right!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e1e8f7',
    borderRadius: '10%',
    padding: '0px 10px',
    marginRight: '0px'
  },
  thoughtDate: {
    padding: '5px 0px',
    color: '#676767',
    fontSize: '8pt',
    textAlign: 'center',
    position: 'relative',
    borderRadius: '25px',
    boxShadow: '0px 0px 10px #9a9a9a8a',
    backgroundColor: '#f4eeee',
    left: '10px',
    top: '10px',
    marginTop: '-14px',
    width: '150px',
    maxWidth: '150px !important',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '20px'
    }
  },
  follow: {
    top: '30px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      top: '20px'
    }
  },
  followMyPosts: {
    top: '20px',
    position: 'relative'
  },
  followBtn: {
    textAlign: '-webkit-right',
    textAlign: 'right'
  },
  followBtnPos: {
    position: 'relative',
    minHeight: '40px',
    height: '40px!important',
    // top: "0px",
    [theme.breakpoints.down('sm')]: {
      top: '0px'
    },
    [theme.breakpoints.up('md')]: {
      top: '-10px'
    }
  }
})

const CardAttachments = props => {
  const {
    classes,
    post,
    onExpandAttachments,
    currentUser,
    openAttachments,
    attachmentoption,
    PlayMedia,
    postsClicked,
    openFileViewer,
    onRemoveAttachment
  } = props
  let removeAttachment = (_post, _postType, _attachmentType, _attachmentID) =>
    _post.journalUserID !== currentUser.userID ? (
      ''
    ) : (
      <IconButton
        onClick={e =>
          onRemoveAttachment(
            e,
            _post,
            _postType,
            _attachmentType,
            _attachmentID
          )
        }
        className={classes.deleteAttachment}
      >
        <DeleteIcon style={{ color: '#ffffff', fontSize: 16 }} />
      </IconButton>
    )
  return (
    <Grid
      container
      spacing={16}
      direction='row'
      className={classes.tableactions}
    >
      {/* <Grid item xs={3} className={classes.socialIcon}>
        <div>
          <IconButton>
            <LoadSocialNetworks user={currentUser} post={"x"} />
          </IconButton>
        </div>
      </Grid> */}
      <Grid
        container
        spacing={16}
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={12} className={classes.attachmentrow}>
          <div style={{ color: LIGHTBLUE, padding: '8px 16px' }}>
            {(post.urls && Object.keys(post.urls).length > 0) ||
              (post.podcasts && Object.keys(post.podcasts).length > 0) ||
              (post.videos && Object.keys(post.videos).length > 0) ||
              (post.files && Object.keys(post.files).length > 0) ? (
                <Typography style={{ color: LIGHTBLUE }} component='p'>
                  View attachment(s) below
                </Typography>
              ) : (
                <Typography style={{ color: LIGHTBLUE }} component='p'>
                  &nbsp;
                </Typography>
              )}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.attachmentControl}>
            <Tooltip title='Links' placement='top'>
              <IconButton
                onClick={e => onExpandAttachments('article')}
                style={{ color: LIGHTBLUE }}
                aria-label='Link'
                id={'article_' + post.id}
                option='article'
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <Typography style={{ color: LIGHTBLUE }} component='p'>
              {post.urls ? Object.keys(post.urls).length : 0}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.attachmentControl}>
            <Tooltip title='Podcasts' placement='top'>
              <IconButton
                onClick={e => onExpandAttachments('podcast')}
                style={{ color: GREEN }}
                aria-label='Mic'
                id={'podcast_' + post.id}
                option='podcast'
              >
                <MicIcon />
              </IconButton>
            </Tooltip>
            <Typography style={{ color: GREEN }} component='p'>
              {post.podcasts ? Object.keys(post.podcasts).length : 0}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.attachmentControl}>
            <Tooltip title='Videos' placement='top'>
              <IconButton
                onClick={e => onExpandAttachments('video')}
                style={{ color: GREY }}
                aria-label='Videocam'
                id={'video_' + post.id}
                option='video'
              >
                <VideocamIcon />
              </IconButton>
            </Tooltip>
            <Typography style={{ color: GREY }} component='p'>
              {post.videos ? Object.keys(post.videos).length : 0}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.attachmentControl}>
            <Tooltip title='Images' placement='top'>
              <IconButton
                onClick={e => onExpandAttachments('image')}
                style={{ color: RED }}
                aria-label='Image'
                id={'image_' + post.id}
                option='image'
              >
                <ImageIcon />
              </IconButton>
            </Tooltip>
            <Typography style={{ color: RED }} component='p'>
              {post.photos ? Object.keys(post.photos).length : 0}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.attachmentControl}>
            <Tooltip title='Files' placement='top'>
              <IconButton
                onClick={e => onExpandAttachments('file')}
                style={{ color: DARKBLUE }}
                aria-label='File'
                id={'file_' + post.id}
                option='file'
              >
                <InsertDriveFileIcon />
              </IconButton>
            </Tooltip>
            <Typography style={{ color: DARKBLUE }} component='p'>
              {post.files ? Object.keys(post.files).length : 0}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2}>
          <td onClick={e => {
            postsClicked('Open PLDP')
          }}>
            <PLDPFavorites
              post={post}
              postTypeID='newsID'
              postType='articles'
              userdata={currentUser}
            />
          </td>
        </Grid>
      </Grid>
      <Collapse in={openAttachments} timeout='auto' unmountOnExit>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
        >
          <Grid xs>
            {attachmentoption === 'article'
              ? post.urls
                ? Object.keys(post.urls).map((v, i) => (
                  <div key={i} className={classes.attachments}>
                    <a onClick={e => {
                      postsClicked('openedLink')
                    }} href={post.urls[v].url} target='_blank'>
                      {' '}
                      <LinkIcon style={{ color: LIGHTBLUE }} />
                      {post.urls[v].title}
                    </a>
                    {removeAttachment(post, 'articles', 'urls', v)}
                  </div>
                ))
                : ''
              : null}
            {attachmentoption === 'podcast'
              ? post.podcasts
                ? Object.keys(post.podcasts).map((v, i) => (
                  <div key={i} className={classes.attachments}>
                    <a
                      onClick={e => {
                        e.preventDefault()
                        PlayMedia(post.podcasts[v], 'podcast')
                        postsClicked('listenedTo')
                      }}
                      href={post.podcasts[v].url}
                      target='_blank'
                    >
                      {' '}
                      <MicIcon style={{ color: GREEN }} />
                      {post.podcasts[v].title}
                    </a>
                    {removeAttachment(post, 'articles', 'podcasts', v)}
                  </div>
                ))
                : ''
              : null}
            {attachmentoption === 'video'
              ? post.videos
                ? Object.keys(post.videos).map((v, i) => (
                  <div key={i} className={classes.attachments}>
                    <a
                      onClick={e => {
                        e.preventDefault()
                        PlayMedia(post.videos[v], 'videos')
                        postsClicked('viewed')
                      }}
                      href={post.videos[v].url}
                      target='_blank'
                    >
                      {' '}
                      <img
                        style={{ maxWidth: 120 }}
                        alt='LP'
                        src={YOUTUBE.getYoutubeThumbnail(post.videos[v].url)}
                      />
                    </a>
                    {removeAttachment(post, 'articles', 'videos', v)}
                  </div>
                ))
                : ''
              : null}
            {attachmentoption === 'image'
              ? post.photos
                ? Object.keys(post.photos).map((v, i) => (
                  <div key={i} className={classes.attachments}>
                    <a href={post.photos[v].url} target='_blank'>
                      {' '}
                      <img
                        style={{ maxWidth: 120 }}
                        alt='LP'
                        src={post.photos[v].thumbNailUrl}
                      />
                    </a>
                    {removeAttachment(post, 'articles', 'photos', v)}
                  </div>
                ))
                : ''
              : null}
            {attachmentoption === 'file'
              ? post.files
                ? Object.keys(post.files).map((v, i) => (
                  <Grid
                    container
                    spacing={2}
                    direction='row'
                    style={{
                      margin: '0 8px 6px', boxShadow: '0px 0px 4px #ddd',
                      width: '97%',
                      fontSize: 11
                    }}
                  >
                    <Grid key={i} item xs={10}>
                      <div className='files_to_view' onClick={e => {
                        let file = {
                          filepath: post.files[v].url,
                          filename: post.files[v].title
                        }
                        postsClicked('openedFile')
                        openFileViewer(file)
                      }}>
                        <InsertDriveFileIcon style={{ color: DARKBLUE }} />
                        {post.files[v].title}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>{removeAttachment(post, 'articles', 'files', v)}</div>
                    </Grid>
                  </Grid>
                ))
                : ''
              : null}
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  )
}

class Articles extends React.Component {
  state = {
    showAttachments: false,
    option: 'article',
    openDeleteAttachmentDialog: false,
    anchorEl: null,
    corpapp: true,
    params: {}
  };
  readArticle = e => {
    let params = {
      userID: this.props.article.journalUserID,
      userName: this.props.article.journalUserName,
      companyID: this.props.article.companyID,
      companyName: this.props.article.companyName,
      photoURL: PHOTO_PROFILE(this.props.article.photoURL),
      showprofiledlg: false
    }

    // Fetch user bio data
    this.props.fetchUserBiography({ ...params, type: 'USER_BIOGRAPHY' })

    this.props.ReadArticle(this.props.article)
  };

  componentWillMount() {
    if (APP_TYPE === 'PUBLIC') {
      this.setState({ corpapp: false })
    }
  }

  handleShowAttachments = option => {
    this.setState(prevState => ({
      showAttachments: !this.state.showAttachments,
      option: option
    }))
  };

  handleRemoveAttachment = (
    e,
    post,
    postType,
    postAttachmentType,
    postAttachmentID
  ) => {
    const params = {
      postID:
        post.dailyThoughtID || post.videoID || post.podcastID || post.newsID,
      postType: postType,
      attachmentType: postAttachmentType,
      attachmentID: postAttachmentID,
      post
    }

    this.setState({
      openDeleteAttachmentDialog: !this.state.openDeleteAttachmentDialog,
      anchorEl: e.currentTarget,
      params: params
    })

    this.handleOpenDeleteAttachmentDialog(e, params)
  };

  handleOpenDeleteAttachmentDialog = e => {
    this.setState({
      openDeleteAttachmentDialog: !this.state.openDeleteAttachmentDialog,
      anchorEl: e.currentTarget
    })
  };
  removeAttachment = () => {
    this.props.deletePostAttachment(this.state.params)
    this.setState({
      openDeleteAttachmentDialog: !this.state.openDeleteAttachmentDialog,
      anchorEl: null
    })
  };
  renderAttachments = () => {
    const { classes, article, currentUser, PlayMedia } = this.props
    const { showAttachments, option } = this.state
    return (
      <React.Fragment>
        <CardAttachments
          attachmentoption={this.state.option}
          openAttachments={this.state.showAttachments}
          onExpandAttachments={this.handleShowAttachments.bind(this)}
          onRemoveAttachment={this.handleRemoveAttachment.bind(this)}
          post={article}
          postsClicked={this.postClicked.bind(this)}
          openFileViewer={this.openFileViewer.bind(this)}
          currentUser={currentUser}
          {...this.props}
        />
        {this.state.openDeleteAttachmentDialog ? (
           <RemoveAttachmentDialog
             attachmentType={this.state.params.attachmentType || ''}
             onClickProceed={this.removeAttachment}
             toggleOpenDeleteAttachments={this.handleOpenDeleteAttachmentDialog}
             open={this.state.openDeleteAttachmentDialog}
             anchorEl={this.state.anchorEl}
           />
        ) : null}
      </React.Fragment>
    )
  };

  postClicked = (res) => {
    let post = this.props.article

    let params2 = {
      userdata: this.props.userdata,
      post: post,
      type: USER_CLICKS_POST,
      res: res,
      postTypeID: 'newsID',
      postType: 'articles',
      obj: {
        itemID: post.newsID,
        title: post.newsID,
        ref: post.newsID
      }
    }

    this.props.captureUserActivity({ ...params2 })
  }

  openFileViewer = (file) => {
    this.props.openFileViewer(file)
  }

  openViewUserDialog = post => {
    let params = {
      userID: post.journalUserID,
      userName: post.journalUserName,
      companyID: post.companyID,
      companyName: post.companyName,
      photoURL: PHOTO_PROFILE(post.photoURL),
      showprofiledlg: true
    }

    // Fetch user bio data
    this.props.fetchUserBiography({ ...params, type: 'USER_BIOGRAPHY' })

    let params2 = {
      userdata: this.props.userdata,
      post: post,
      type: USER_CLICKS_POST,
      res: 'viewedUserProfile',
      postTypeID: 'newsID',
      postType: 'articles',
      obj: {
        itemID: post.newsID,
        title: post.newsID,
        ref: post.newsID
      }
    }

    this.props.captureUserActivity({ ...params2 })

    // Open user profile dialog
    this.props.openUserProfileDialog(params)
  };

  render() {
    // console.log(
    // "PHOTO_BACKGROUND(this.props.article.photos) : ",
    //   PHOTO_BACKGROUND(this.props.article.photos)
    // );
    // console.log("article.articleLink: ", this.props.article.articleLink);
    // // console.log(
    //   "PHOTO_PROFILE(post.photoURL): ",
    //   PHOTO_PROFILE(this.props.post.photoURL)
    // );

    const { classes, article } = this.props
    console.log('articles props: ', this.props);
    
    return (
      <div className={classes.cardstyle}>
        <Grid
          container
          spacing={8}
          className={
            window.location.pathname === '/myposts'
              ? classes.followMyPosts
              : classes.follow
          }
        >
          <Grid item xs={6}>
            <div className={classes.thoughtDate}>
              {DATE_FORMAT.type(article.dateScheduled, 'BADGE')}
            </div>
          </Grid>
          <Grid item xs={6} className={classes.followBtn}>
            <div className={classes.followBtnPos}>
              <Follow post={article} following={this.props.following} />
            </div>
          </Grid>
        </Grid>
        {/* <Badge
          classes={{ badge: classes.badgemargin }}
          badgeContent={DATE_FORMAT.type(article.dateScheduled, "BADGE")}
        />
        <Follow post={article} following={this.props.following} /> */}
        <RemoveFavorite item={article} itemType='news' />

        <Card className={classes.card} onClick={() => this.postClicked('clickedPost')}>
          <CardHeader
            avatar={
              <Avatar
                aria-label='Photo'
                className={classes.avatar}
                src={PHOTO_PROFILE(article.photoURL)}
                onClick={() => this.openViewUserDialog(article)}
                style={{ cursor: 'pointer' }}
              >
                P
              </Avatar>
            }
            action={
              <MoreMenu
                post={article}
                posttype='articles'
                userdata={this.props.userdata}
              />
            }
            title={article.journalUserName}
            subheader={article.companyName}
          />

          {article.manualOrExternal === 'externalLink' ? (
            <div>
              <CardMedia
                className={classes.media}
                image={PHOTO_BACKGROUND(article.photos)}
                component='a'
                href={article.articleLink}
                target='_blank'
                style={{ cursor: 'pointer' }}
                onClick={() => this.postClicked('readArticle')}
              >
                {' '}
                <div
                  className={classes.overlay}
                  style={{
                    color: article.textColor || '#fff',
                    cursor: 'pointer'
                  }}
                >
                  {!article.photos ? (
                    <CardMessage
                      title={article.title}
                      subtitle={article.subtitle}
                      color={article.textColor}
                    />
                  ) : 
                  <CardMessage
                  title={article.title}
                  subtitle={article.subtitle}
                  color={article.textColor}
                />
                  }
                </div>
              </CardMedia>
              <CardContent
                component='a'
                href={article.articleLink}
                target='_blank'
                style={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                <ArticleCardsMessage
                  title={article.title}
                  subtitle={article.subtitle}
                />
              </CardContent>
            </div>
          ) : (
            <div>
              <CardMedia
                className={classes.media}
                image={PHOTO_BACKGROUND(article.photos)}
                style={{ cursor: 'pointer' }}
                onClick={this.readArticle.bind(this)}
              >
                <div
                  className={classes.overlay}
                  style={{
                    color: article.textColor || '#fff',
                    cursor: 'pointer'
                  }}
                >
                  {!article.photos ? (
                    <CardMessage
                      title={article.title}
                      subtitle={article.subtitle}
                      color={article.textColor}
                      onClick={this.readArticle.bind(this)}
                    />
                  ) : 
                  <CardMessage
                  title={article.title}
                  subtitle={article.subtitle}
                  color={article.textColor}
                  onClick={this.readArticle.bind(this)}
                />
                  }
                </div>
              </CardMedia>
              <CardContent
                style={{ cursor: 'pointer' }}
                onClick={this.readArticle.bind(this)}
              >
                <ArticleCardsMessage
                  title={article.title}
                  subtitle={article.subtitle}
                />
              </CardContent>
            </div>
          )}

          <CardActions className={classes.actions} disableActionSpacing>
            {this.renderAttachments()}
          </CardActions>
          <CardActions className={classes.actions} disableActionSpacing>
            <div className={classes.favoritesrow}>
              <Liking
                postType='articles'
                postID={article.newsID}
                post={article}
              />
              <div className={classes.sharediv} >
                { IS_HARMONY ? (null) : (
                  <div className={(APP_TYPE === 'PUBLIC') ? '' : classes.actionsComments} >
                    {/* <IconButton style={{ color: LIGHTBLUE, width: '48px', height: '48px' }}>
                      <LoadSocialNetworks
                        post={
                          window.location.origin + '/filtered-articles/#/' +
                        article.newsID
                        } />
                    </IconButton> */}
                  </div>
                )}
              </div>

              <div className={classes.commentsdiv} onClick={this.props.OnExpandCommentsClick}>
                <div>
                  <IconButton style={{ color: LIGHTBLUE }} aria-label='Forum'>
                    <ForumIcon />
                  </IconButton>
                </div>
                <div>
                  <Badge
                    badgeContent={
                      article.comments
                        ? '' + Object.values(article.comments).length
                        : '0'
                    }
                    classes={{ badge: classes.commentsbadge }}
                  />
                </div>
              </div>
            </div>
          </CardActions>
          <div className={this.props.expandComments ? '' : classes.actionsComments} >
            <AddCommentField
              parentID={''}
              isSubComment={false}
              post={article}
              postType={'articles'}
              classes={classes}
            />
          </div>
          <CardActions
            className={this.props.expandComments ? '' : classes.actionsComments}
          >
            <Comments postType={'articles'} post={article} {...this.props} />
            {!this.props.expandComments ? (
              <div
                onClick={this.props.OnExpandCommentsClick}
                className={classes.mask}
              >
                <div
                  onClick={this.props.OnExpandCommentsClick}
                  className={classes.maskText}
                >
                  View All commentss
                </div>
              </div>
            ) : null}
          </CardActions>
        </Card>
      </div>
    )
  }
}
class ArticlesCard extends React.Component {
  state = { expanded: false, openDialogCard: false, article: {} };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  };
  handleCommentsClick = () => {
    this.setState(prevState => ({
      openDialogCard: !this.state.openDialogCard
    }))
  };

  // handleExpandClick = () => {
  //   this.setState(state => ({ expanded: !state.expanded }));
  // };
  openViewUserDialog = thought => {
    this.setState({
      open: !this.state.open
    })
  };

  handleLikeClick = thought => {
    this.setState(prevState => ({
      liked: !this.state.liked
    }))
  };

  render() {
    const { classes, article } = this.props
    console.log('ArticlesCard props : ', this.props);
    return (
      <React.Fragment>
        {this.state.openDialogCard ? (
          <CommentDialogCard
            open={this.state.openDialogCard}
            onOpenCommentsClick={this.handleCommentsClick}
            render={(state, handleClose) => (
              <React.Fragment>
                <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.close}
                  onClick={this.handleCommentsClick}
                >
                  <CloseIcon fontSize='small' className={classes.icon} />
                </IconButton>
                <Articles
                  article={article}
                  OnExpandCommentsClick={this.handleCommentsClick}
                  expandComments={true}
                  {...this.props}
                />
              </React.Fragment>
            )}
          />
        ) : (
          <Articles
            article={article}
            OnExpandCommentsClick={this.handleCommentsClick}
            expandComments={false}
            {...this.props}
          />
        )}
      </React.Fragment>
    )
  }
}

ArticlesCard.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  hideComments: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUserBiography: params => dispatch(fetchUserBiography(params)),
    openUserProfileDialog: params => dispatch(openUserProfileDialog(params)),
    captureUserActivity: params => dispatch(captureUserActivity(params))
  }
}

const ArticlesCardStyled = withStyles(styles)(ArticlesCard)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticlesCardStyled)
