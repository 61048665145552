import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    minHeight: "100vh",
    // position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  placeholder: {
    height: 40
  }
});

class ProgressBar extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    var self = this;

    // setTimeout(() => {
    //   self.hideProgressBar();
    // }, 20000);
  }

  hideProgressBar() {
    this.setState({
      loading: false
    });
  }

  render() {
    const { loading } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress style={{ color: "#b30000" }} mode="indeterminate" size={100} />
          //<CircularProgress className={classes.progress} color="secondary" />
        ) : (
            <Typography>No data found!</Typography>
          )}
      </div>
    );
  }
}

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProgressBar);
