/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-shadow */

import React, { Component } from 'react'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  emphasize, withStyles, createTheme,
  MuiThemeProvider
} from '@material-ui/core/styles'
import {
  Paper,
  Grid,
  Typography, IconButton, Button, InputAdornment,
  FormControl, InputLabel, Input, Breadcrumbs, Chip, Dialog,
  DialogActions, DialogContent, Select, DialogTitle, TextField, MenuItem,
  Stepper, Step, StepLabel,
  Avatar,
  Menu
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import GroupsListAdmin from './GroupsListAdmin'
import GroupSummary from './GroupSummary'
import UsersList from './UsersList'
import SelectedUsers from './SelectedUsers'
import { SHOW_TOAST } from '../../store/actions/types'
import { showToast } from '../../store/actions/toastAction'
import {
  getAllCompanyUsers,
  createCompanyGroup,
  getAllCompanyGroupUsers,
  getAllCompanyGroups,
  getCompanyProgrammes,
  deleteUserFromGroup,
  getCompanySurveys } from '../../store/actions/companyAdminActions'
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import { LIGHTGREY } from '../../utils/color_utils'
import TrainingProgrammes from './TrainingProgrammes'


function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 0 }}>
      {props.children}
    </Typography>
  )
}

function getSteps() {
  return ['Group info', 'Group participants', 'Save group']
}

function getStatus() {
  return ['Yet_To_Start', 'Started', 'Completed', 'Graduated']
}

let StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    backgroundColor: LIGHTGREY
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

let StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      'backgroundColor': theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

const styles = theme => ({
  root: {
    marginTop: 0,
    overflowX: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '130px',
      padding: '0 10px'
    }
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  pageHeader: {
    marginTop: 0,
    padding: theme.spacing(1),
    backgroundColor: 'transparent'
  }
})

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    'backgroundColor': theme.palette.grey[100],
    'height': theme.spacing(3),
    'color': theme.palette.grey[800],
    'fontWeight': theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      textDecoration: 'none'
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      textDecoration: 'none'
    }
  }
}))(Chip)

class Groups extends Component {
  state = {
    group_id: '',
    companyID: '',
    companyName: '',
    tabsvalue: 0,
    group_start_date: '',
    group_end_date: '',
    group_name: '',
    group_no: '',
    study_school: '',
    group_status: '',
    open: false,
    dialogTitle: '',
    activeStep: 0,
    users: [],
    usersData: [],
    usersList: [],
    company_programmes: [],
    selectedUsers: {},
    updates: {},
    selectedRows: [],
    userID: '',
    inputSearch: '',
    inputSearchGroups: '',
    anchorEl: null
  }

  handleMenuClick = (e) => {
    this.setState({ anchorEl: e.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  removeSelectedRows = (row) => {
    let selectedRows = this.state.selectedRows

    for (let i = 0; i < selectedRows.length; i++) {
      if (selectedRows[i] === row) {
        selectedRows.splice(i, 1)
      }
    }

    this.setState({ selectedRows })
  }

  clearSelectedRows = () => {
    let selectedRows = this.state.selectedRows

    selectedRows.splice(0, selectedRows.length)

    this.setState({ selectedRows })
  }

  setSelectedRows = (row) => {
    let selectedRows = this.state.selectedRows

    selectedRows.push(row)

    this.setState({ selectedRows })
  }

  setSelectedUsers = (users, selectedUser) => {
    let selectedUsers = users
    let usersList = this.state.usersList

    for (let i = 0; i < usersList.length; i++) {
      if (usersList[i].uid === selectedUser.uid) {
        usersList[i].selected = !usersList[i].selected
      }
    }

    this.setState({ selectedUsers, usersList })
  }


  // select all
  setSelectedAllUsers = () => {
    let usersList = this.state.usersList
    let selectedUsers = []

    for (let i = 0; i < usersList.length; i++) {
      usersList[i].selected = true

      let selectedUser = {
        firstName: usersList[i].firstName,
        lastName: usersList[i].lastName,
        email: usersList[i].email,
        userID: usersList[i].userID,
        uid: usersList[i].uid,
        photoURL: PHOTO_PROFILE(usersList[i].photoURL),
        can_add_remove_users: false,
        can_edit: false,
        can_post: true,
        company_ID: this.props.userdata.companyID,
        company_name: this.props.userdata.companyName
      }

      selectedUsers[usersList[i].userID] = selectedUser
    }

    this.setState({ selectedUsers, usersList })
  }


  // unselect all
  setUnSelectedAllUsers = () => {
    let usersList = this.state.usersList
    let selectedUsers = []

    for (let i = 0; i < usersList.length; i++) {
      usersList[i].selected = false
    }

    this.setState({ selectedUsers, usersList })
  }


  // handle select all
  handleSelectAll = (e) => {
    if (e.target.checked) {
      this.setSelectedAllUsers()
    } else {
      this.setUnSelectedAllUsers()
    }
  }

  handleEditClick = (group_info) => {
    if (this.state.group_id !== group_info.group_id) {
      this.fetchGroupUsers(group_info.group_id)
    }

    this.setState({
      ...this.state,
      dialogTitle: 'Edit Group: ',
      group_name: group_info.group_name,
      group_no: group_info.group_no ? group_info.group_no : '',
      study_school: group_info.study_school ? group_info.study_school : '',
      group_id: group_info.group_id,
      date_created: group_info.date_created ? group_info.date_created : Date.now(),
      group_start_date: group_info.group_start_date ? group_info.group_start_date : '',
      group_end_date: group_info.group_end_date ? group_info.group_end_date : '',
      group_status: group_info.group_status ? group_info.group_status : '',
      open: true,
      edit: true,
      company_programmes: this.filterActiveProgrammes(this.props.company_programmes)
    })
  }

  fetchGroupUsers(group_id) {
    this.props.getAllCompanyGroupUsers({
      companyID: this.props.userdata.companyID,
      groupID: group_id
    })
  }

  handleChange = event => {
    // console.log("name: ", event.target.name)
    // console.log("value: ", event.target.value)
    this.setState({ [event.target.name]: event.target.value })
  };

  // filter items
  handleSearch = (event) => {
    this.setState({ inputSearch: event.target.value }, () => {
      this.setState({
        usersList: this.state.usersData.filter(user => user.firstName.toLowerCase().includes(this.state.inputSearch.toLowerCase()) || user.lastName.toLowerCase().includes(this.state.inputSearch.toLowerCase()) || user.email.toLowerCase().includes(this.state.inputSearch.toLowerCase()))
      })
    })
  }

  // filter items
  handleSearchGroup = (event) => {
    this.setState({ inputSearchGroups: event.target.value })
  }


  handleSelected = (user) => {
    let selectedUsers = this.state.selectedUsers
    let updates = this.state.updates ? this.state.updates : {}

    let selectedUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      userID: user.userID,
      uid: user.uid,
      photoURL: PHOTO_PROFILE(user.photoURL),
      can_add_remove_users: false,
      can_edit: false,
      can_post: true,
      company_ID: this.props.userdata.companyID,
      company_name: this.props.userdata.companyName
    }

    if (selectedUser.userID in selectedUsers) {
      delete selectedUsers[selectedUser.userID]

      if (this.state.edit && this.state.group_id.trim().length > 0) {
        updates['/company_user_groups/' + user.userID + '/' + this.state.group_id] = null
        this.setState({ updates })
        // this.props.deleteUserFromGroup({
        //   group_id: this.state.group_id,
        //   userID: user.userID
        // })
      }
    } else {
      selectedUsers[selectedUser.userID] = user
    }

    this.setSelectedUsers(selectedUsers, selectedUser)
  }


  componentDidMount() {
    this.setState({
      userID: this.props.userdata.userID,
      companyID: this.props.userdata.companyID,
      companyName: this.props.userdata.companyName
    })

    this.props.getAllCompanyUsers({
      companyID: this.props.userdata.companyID,
      orderChild: 'companyID'
    })

    this.props.getAllCompanyGroups({
      companyID: this.props.userdata.companyID
    })

    this.props.getCompanyProgrammes({ companyID: this.props.userdata.companyID })

    this.props.getCompanySurveys({ companyID: this.props.userdata.companyID })
  }

  openDialog = (type, title) => e => {
    this.setState({
      dialogTitle: title,
      open: true,
      usersData: this.transformUsersData(),
      usersList: this.transformUsersData(),
      company_programmes: this.filterActiveProgrammes(this.props.company_programmes),
      edit: type === 1 ? false : true
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      group_id: '',
      tabsvalue: 0,
      group_start_date: '',
      group_end_date: '',
      group_name: '',
      group_no: '',
      study_school: '',
      group_status: '',
      dialogTitle: '',
      activeStep: 0,
      selectedUsers: {},
      updates: {},
      selectedRows: [],
      edit: false
    })
  };

  handleNext = () => {
    if (this.state.activeStep === 0) {
      if (this.state.group_name === '') {
        var params = {
          type: SHOW_TOAST,
          open: true,
          variant: 'warning',
          message: 'Please select programme name ...'
        }

        this.props.showToast(params)
        return
      }

      if (this.state.group_no === '') {
        var params = {
          type: SHOW_TOAST,
          open: true,
          variant: 'warning',
          message: 'Please enter group number ...'
        }

        this.props.showToast(params)
        return
      }

      if (this.state.study_school === '') {
        var params = {
          type: SHOW_TOAST,
          open: true,
          variant: 'warning',
          message: 'Please enter study school ...'
        }

        this.props.showToast(params)
        return
      }

      if (this.state.group_start_date === '') {
        // var params = {
        //   type: SHOW_TOAST,
        //   open: true,
        //   variant: "warning",
        //   message: "Please enter the start date ..."
        // };

        // this.props.showToast(params);
        // return;
      }

      if (this.state.group_end_date === '') {
        // var params = {
        //   type: SHOW_TOAST,
        //   open: true,
        //   variant: "warning",
        //   message: "Please enter the end date ..."
        // };

        // this.props.showToast(params);
        // return;
      }

      if (this.state.group_start_date !== '' && this.state.group_end_date !== '') {
        if (Date.parse(this.state.group_start_date) >= Date.parse(this.state.group_end_date)) {
          var params = {
            type: SHOW_TOAST,
            open: true,
            variant: 'warning',
            message: 'Start date cannot be greater or equal to end date ...'
          }

          this.props.showToast(params)
          return
        }
      }

      if (this.state.group_status === '') {
        // var params = {
        //   type: SHOW_TOAST,
        //   open: true,
        //   variant: "warning",
        //   message: "Please select the group status ..."
        // };

        // this.props.showToast(params);
        // return;
      }

      if (this.state.edit === true && this.props.group_users) {
        var usersList = this.state.usersList
        let selectedUsers = []

        let usersData = this.transformUsersData()
        var usersList = this.transformUsersData()

        for (let i = 0; i < usersList.length; i++) {
          if (usersList[i] && usersList[i].userID && usersList[i].userID in this.props.group_users) {
            usersList[i].selected = true

            let selectedUser = {
              firstName: usersList[i].firstName,
              lastName: usersList[i].lastName,
              email: usersList[i].email,
              userID: usersList[i].userID,
              uid: usersList[i].uid,
              photoURL: PHOTO_PROFILE(usersList[i].photoURL),
              can_add_remove_users: false,
              can_edit: false,
              can_post: true,
              company_ID: this.props.userdata.companyID,
              company_name: this.props.userdata.companyName
            }
            selectedUsers[usersList[i].userID] = selectedUser
          }
        }

        this.setState({
          ...this.state,
          usersData,
          usersList,
          selectedUsers,
          activeStep: this.state.activeStep + 1,
          tabsvalue: this.state.activeStep + 1
        })
      } else {
        this.setState({
          ...this.state,
          activeStep: this.state.activeStep + 1,
          tabsvalue: this.state.activeStep + 1
        })
      }
    } else if (this.state.activeStep === 1) {
      if (Object.keys(this.state.selectedUsers).length === 0) {
        var params = {
          type: SHOW_TOAST,
          open: true,
          variant: 'warning',
          message: 'Please select group participants ...'
        }

        this.props.showToast(params)
        return
      }

      this.setState({
        ...this.state,
        activeStep: this.state.activeStep + 1,
        tabsvalue: this.state.activeStep + 1
      })
    } else if (this.state.activeStep === 2) {
      // create group
      this.props.createCompanyGroup({
        ...this.state
      })

      this.handleClose()
    }
  };

  handleBack = () => {
    this.setState({
      ...this.state,
      activeStep: this.state.activeStep - 1,
      tabsvalue: this.state.activeStep - 1
    })
  };

  transformTableData = () => {
    let { classes, users } = this.props

    let dataListClone = Object.keys(users).map((uid, index) => {
      return [
        <Avatar
          alt={users[uid].firstName + ' ' + users[uid].lastName}
          src={PHOTO_PROFILE(users[uid].photoURL)}
          id={users[uid].uid}
        />,

        users[uid].firstName,
        users[uid].lastName,
        users[uid].email,
        users[uid].userID,
        users[uid].uid,
        PHOTO_PROFILE(users[uid].photoURL),
        false
      ]
    })

    return dataListClone
  };

  filterActiveProgrammes = () => {
    let { company_programmes } = this.props
    let programmes = []
    let dataListClone = []
    let filtered_programmes = []

    Object.keys(company_programmes).map(id => {
      programmes.push(company_programmes[id])
    })

    filtered_programmes = programmes.filter(programme => programme.status.toLowerCase().includes('active'))

    filtered_programmes.map((id, index) => {
      dataListClone.push(filtered_programmes[index].programme_name)
    })

    return dataListClone
  }

  transformUsersData = () => {
    let { classes, users } = this.props

    let dataListClone = []

    Object.keys(users).map((uid, index) => {
      users[index].selected = false
      dataListClone.push(users[index])
    })

    return dataListClone
  };

  transformGroupsData = () => {
    let { classes, company_groups } = this.props

    let dataListClone = []

    Object.keys(company_groups).map((group, index) => {
      dataListClone.push(company_groups[index])
    })

    return dataListClone
  };

  formatUserList(param) {
    const temp = []
    param.map((user) => {
      temp.push({
        firstName: user[1],
        lastName: user[2],
        email: user[3],
        userType: user[4],
        userID: user[5].key,
        selected: false,
        visible: ''
      })
    })

    return temp
  }

  render() {
    const { classes } = this.props
    const { dialogTitle, open, tabsvalue, activeStep, group_name, group_no, inputSearchGroups } = this.state
    const steps = getSteps()
    const status = getStatus()
    let company_groups = this.props.company_groups ? this.transformGroupsData(this.props.company_groups) : {}
    let filtered_groups = company_groups.filter(group => group.group_name.toLowerCase().includes(inputSearchGroups.toLowerCase()) || group.group_no.toLowerCase().includes(inputSearchGroups.toLowerCase()))
    return (
      <div className={classes.root}>
        <Paper elevation={0} style={{ maxWidth: '100vw' }} className={classes.pageHeader}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant='h5'>Groups</Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Breadcrumbs aria-label='breadcrumb'>
                <StyledBreadcrumb
                  component={Link}
                  to={`/companyadmin`}
                  label='Club Admin'
                />
                <StyledBreadcrumb
                  label='Groups'
                />
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth className={classes.margin}>
                <InputLabel htmlFor='search-groups'>Search</InputLabel>
                <Input
                  id='search-groups'
                  onChange={e =>
                    this.handleSearchGroup(e)
                  }
                  startAdornment={
                    <InputAdornment
                      position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
              {/* style={
                  { display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }
                } */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} style={{ textAlign: 'left' }}>
                  <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={this.openDialog(1, 'Add Group: ')}
                  >
                    <AddIcon className={classes.leftIcon} fontSize='small' />
                    Add Group
                  </Button>
                </Grid>
                <Grid item xs={12} sm={9} style={{ textAlign: 'right' }}>
                  <div>
                    <Button
                      variant='outlined'
                      color='primary'
                      size='small'
                      component={Link}
                      to={`/studymaterials`}
                      style={{ marginRight: '8px' }}
                    >
                      Study Materials
                    </Button>
                    <Button
                      variant='outlined'
                      color='primary'
                      size='small'
                      component={Link}
                      to={`/surveyconfigurations`}
                      style={{ marginRight: '8px' }}
                    >
                      Survey Configurations
                    </Button>
                    <Button
                      variant='outlined'
                      color='primary'
                      size='small'
                      component={Link}
                      to={`/programmes`}
                    >
                      Programmes Configuration
                    </Button>
                    {/* <Button
                      className={classes.btn}
                      aria-controls='customized-menu'
                      aria-haspopup='true'
                      variant='outlined'
                      color='primary'
                      size='small'
                      onClick={this.handleMenuClick}
                    >
                      ACTIONS
                    <ArrowDropDownIcon />
                    </Button>
                    <StyledMenu
                      id='customized-menu'
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleMenuClose}
                    >
                      <StyledMenuItem
                        component={Link}
                        to={`/studymaterials`}
                        variant='contained'>
                        <ListItemText primary='Study Materials' />
                      </StyledMenuItem>
                    </StyledMenu> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>

              <GroupsListAdmin
                handleClick={this.handleEditClick}
                inputSearchGroups={inputSearchGroups}
                company_groups={filtered_groups}
              />
            </Grid>
          </Grid>
        </Paper>

        <Dialog
          open={open}
          aria-labelledby='form-dialog-title'
          fullWidth
          maxWidth='md'
        >
          <DialogTitle id='form-dialog-title'><Grid container spacing={2}>
            <Grid item xs={11} sm={11}>
              {dialogTitle} {group_name}
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton color='secondary' aria-label='close' onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          </DialogTitle>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <DialogContent style={{ border: '1px solid #ccc', margin: '0px 10px 0px 10px', height: '80vh' }}>
            {tabsvalue === 0 && (
              <TabContainer>
                <TrainingProgrammes group_name={this.state.group_name} handleChange={this.handleChange} company_programmes={this.state.company_programmes} />
                <TextField
                  //autoFocus={true}
                  required
                  margin='dense'
                  id='group_no'
                  label='Group No'
                  type='text'
                  fullWidth
                  value={this.state.group_no}
                  onChange={event => {
                    const { value } = event.target
                    this.setState({
                      group_no: value
                    })
                  }}
                />
                <TextField
                  //autoFocus={true}
                  required
                  margin='dense'
                  id='study_school'
                  label='Study School'
                  type='text'
                  fullWidth
                  value={this.state.study_school}
                  onChange={event => {
                    const { value } = event.target
                    this.setState({
                      study_school: value
                    })
                  }}
                />
                <TextField
                  margin='dense'
                  id='start-date'
                  label='Start date'
                  fullWidth
                  type='date'
                  inputProps={{
                    name: 'group_start_date',
                    id: 'group_start_date'
                  }}
                  onChange={e =>
                    this.handleChange(e, 'group_start_date')
                  }
                  value={this.state.group_start_date}
                  fullWidth
                  margin='normal'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  margin='dense'
                  id='end-date'
                  label='End date'
                  fullWidth
                  type='date'
                  inputProps={{
                    name: 'group_end_date',
                    id: 'group_end_date'
                  }}
                  onChange={e =>
                    this.handleChange(e, 'group_end_date')
                  }
                  value={this.state.group_end_date}
                  fullWidth
                  margin='normal'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <InputLabel id='group-status-label' style={{ marginTop: '14px', fontSize: '12px' }}>Status</InputLabel>
                <Select
                  labelId='group-status-label'
                  id='group-status'
                  value={this.state.group_status}
                  onChange={e =>
                    this.handleChange(e, 'group_status')
                  }
                  inputProps={{
                    name: 'group_status',
                    id: 'group_status'
                  }}
                  fullWidth
                >
                  {status.map((label, id) => (
                    <MenuItem value={id + 1}>{label}</MenuItem>
                  ))}
                </Select>
              </TabContainer>
            )}
            {tabsvalue === 1 && (
              <TabContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      placeholder='Search'
                      value={this.state.inputSearch}
                      onChange={e =>
                        this.handleSearch(e)
                      }
                      style={{ width: '93%' }}
                      margin='dense'
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectedUsers
                      participants={this.state.selectedUsers}
                      handleSelected={this.handleSelected}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <UsersList
                      participants={this.state.usersList}
                      handleSelectAll={this.handleSelectAll}
                      handleSelected={this.handleSelected}
                    />
                  </Grid>
                </Grid>
              </TabContainer>
            )}
            {tabsvalue === 2 && (
              <TabContainer >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant='h6'>Review Group Info</Typography>
                </div>
                <GroupSummary
                  participants={this.state.selectedUsers}
                  group_name={this.state.group_name}
                  group_no={this.state.group_no}
                  study_school={this.state.study_school}
                  start_date={this.state.group_start_date}
                  end_date={this.state.group_end_date}
                  status={status[this.state.group_status - 1]}
                />
              </TabContainer>
            )}

          </DialogContent>
          <DialogActions style={{ justifyContent: 'flex-start' }}>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Button onClick={this.handleClose} color='secondary'>CLOSE</Button>
                </div>
              ) : (
                <div>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.backButton}
                    >
                        Back
                    </Button>
                    <Button variant='contained' color='primary' onClick={this.handleNext}>
                      {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {/* <Button onClick={this.handleClose} color="secondary">
              Cancel
          </Button>
            <Button onClick={this.saveCompany} color="primary">
              Submit
          </Button> */}
          </DialogActions>
        </Dialog>
      </div >
    )
  }
}

Groups.propTypes = {
  classes: PropTypes.object
}

const mapStateToProps = state => {
  return {
    classes: PropTypes.object,
    CREATE_GROUP: state.users.CREATE_GROUP,
    userdata: state.userDialog.userDetails[0],
    company_groups: state.users.company_groups,
    users: state.users.users,
    group_users: state.users.group_users,
    company_programmes: state.users.company_programmes
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    getAllCompanyUsers: params => dispatch(getAllCompanyUsers(params)),
    getAllCompanyGroups: params => dispatch(getAllCompanyGroups(params)),
    createCompanyGroup: params => dispatch(createCompanyGroup(params)),
    getAllCompanyGroupUsers: params => dispatch(getAllCompanyGroupUsers(params)),
    getCompanyProgrammes: params => dispatch(getCompanyProgrammes(params)),
    getCompanySurveys: params => dispatch(getCompanySurveys(params)),
    deleteUserFromGroup: params => dispatch(deleteUserFromGroup(params))
  }
}

const ViewGroupsStyled = withStyles(styles)(Groups)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewGroupsStyled)


