import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const data_types = ["Messages", "Articles", "Podcasts", "Videos", "PLDP"];
const data_types_obj = {
  thoughts: "Messages",
  articles: "Articles",
  podcasts: "Podcasts",
  videos: "Videos",
  pldp: "PLDP"
};


function createData(name, male, female, not_specified) {
  return { name, male, female, not_specified };
}
// createData('All Users', 48, 30),
// createData('Active Users', 22, 10),
// createData('Group Participants', 24, 8),
const rows = [
  createData('Messages', 76, 97, 0),
  createData('Articles', 25, 20, 0),
  createData('Podcasts', 12, 12, 0),
  createData('Videos', 7, 10, 0),
  createData('PLDP', 30, 20, 0),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function PostsByGenderTable(props) {
  const classes = useStyles();

  const [data_rows, setDataRows] = useState([]);

  function handleStatusChange(prop_data) {
    let graph_d = [];

    let gender = prop_data && prop_data.analytics && prop_data.analytics.gender ? prop_data.analytics.gender : {};

    console.log("analytics here 2: ", gender);

    let male = gender && gender.Male ? gender.Male : {};
    let female = gender && gender.Female ? gender.Female : {};
    let not_specified = gender && gender.not_specified ? gender.not_specified : {};

    data_types.map((v, i) => {
      let male_no = 0;
      let female_no = 0;
      let not_specified_no = 0;

      if (v === "Messages") {
        male_no = male && male.thoughts ? male.thoughts : 0;
        female_no = female && female.thoughts ? female.thoughts : 0;
        not_specified_no = not_specified && not_specified.thoughts ? not_specified.thoughts : 0;
        graph_d.push(createData(v, male_no, female_no, not_specified_no));
      }
      else if (v === "Articles") {
        male_no = male && male.articles ? male.articles : 0;
        female_no = female && female.articles ? female.articles : 0;
        not_specified_no = not_specified && not_specified.articles ? not_specified.articles : 0;
        graph_d.push(createData(v, male_no, female_no, not_specified_no));
      }
      else if (v === "Podcasts") {
        male_no = male && male.podcasts ? male.podcasts : 0;
        female_no = female && female.podcasts ? female.podcasts : 0;
        not_specified_no = not_specified && not_specified.podcasts ? not_specified.podcasts : 0;
        graph_d.push(createData(v, male_no, female_no, not_specified_no));
      }
      else if (v === "Videos") {
        male_no = male && male.videos ? male.videos : 0;
        female_no = female && female.videos ? female.videos : 0;
        not_specified_no = not_specified && not_specified.videos ? not_specified.videos : 0;
        graph_d.push(createData(v, male_no, female_no, not_specified_no));
      }
      else if (v === "PLDP") {
        male_no = male && male.pldp ? male.pldp : 0;
        female_no = female && female.pldp ? female.pldp : 0;
        not_specified_no = not_specified && not_specified.pldp ? not_specified.pldp : 0;
        graph_d.push(createData(v, male_no, female_no, not_specified_no));
      }

    })

    setDataRows(graph_d);
  }

  useEffect(() => {
    handleStatusChange(props);
  }, [props]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Item</StyledTableCell>
            <StyledTableCell align="right">Male</StyledTableCell>
            <StyledTableCell align="right">Female</StyledTableCell>
            <StyledTableCell align="right">Not Specified</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data_rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.male}</StyledTableCell>
              <StyledTableCell align="right">{row.female}</StyledTableCell>
              <StyledTableCell align="right">{row.not_specified}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
