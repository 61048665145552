import { STORE_SUBCOMPETENCYID } from "./types";

export const storeSubCompetency = params => {
  if (params.type === STORE_SUBCOMPETENCYID) {
    return (dispatch, getState) => {
      dispatch({
        type: STORE_SUBCOMPETENCYID,
        cvalue: params.cvalue,
        scompname: params.scompname
      });
    };
  } else {
    return (dispatch, getState) => {
      dispatch({
        type: STORE_SUBCOMPETENCYID,
        cvalue: "",
        scompname: ""
      });
    };
  }
};

// export const hideToast = () => {
//   return (dispatch, getState) => {
//     dispatch({ type: STORE_SUBCOMPETENCYID, message: "", variant: "" });
//   };
// };

export const clearStates = params => {
  return (dispatch, getState) => {
    dispatch({
      type: params.type
    });
  };
};
