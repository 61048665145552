import { SURVEY_LIST, REFRESH_CARD } from "../actions/types";

const initState = {
  type: SURVEY_LIST,
  survey_list: []
};

const surveyReducer = (state = initState, action) => {
  console.log('inside refresh recuder 2: ', action.type);
  switch (action.type) {
    case SURVEY_LIST:
      console.log('Inside SURVEY_LIST')
      return {
        type: SURVEY_LIST,
        survey_list: action.survey_list
      };
    case REFRESH_CARD:
      console.log('inside refresh reducer 3: ', action.survey_refresh);
        return {
          type: REFRESH_CARD,
          card_refresh: action.survey_refresh
        };
    default:
      return state;
  }
};

export default surveyReducer;
