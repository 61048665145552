import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Grid, IconButton, Modal, Tooltip, ExpansionPanelActions, Button, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SHOW_TOAST } from "../../store/actions/types";
import { showToast } from "../../store/actions/toastAction";
import { fetchSubCompetencies, deleteSubCompetency } from "../../store/actions/subcompetenciesActions";
import AddCompetency from "./AddCompetency";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
const top = 40 + rand();
const left = 50 + rand();

const styles = theme => ({
  root: {
    width: "100%"
  },
  icons: {
    cursor: "pointer"
  },
  modal: {
    outline: "none",
    border: "none"
  },
  paper: {
    position: 'absolute',
    width: "100%",
    maxWidth: "600px",
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
});

class SubCompetencies extends Component {
  constructor (props) {
    super(props);
    this.state = {
      editText: "",
      editID: "",
      open: false,
      openConfirm: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }


  fetchData = () => {
    this.props.fetchSubCompetencies({
      compid: this.props.compid
    });
  };
  deleteSubCompetency = () => {
    this.props.deleteSubCompetency({
      compid: this.props.compid,
      editID: this.state.editID,
      companyID: this.props.userdata.companyID
    });

    this.fetchData();

    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      editText: "",
      editID: "",
      open: false,
      openConfirm: false
    });
  };

  editSubCompetency = (id, name) => e => {
    this.setState({
      editText: name,
      editID: id,
      open: true
    });
  }

  deleteSubCompetencies = (id, name, count) => e => {
    if (count > 0) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Cannot delete this item, there are posts and/or tasks tied to it!"
      };

      this.props.showToast(params);
      return;
    }

    this.setState({
      editText: name,
      editID: id,
      openConfirm: true
    });
  }

  render() {
    const { classes, compid, subcompetencies_list } = this.props;
    var count = 0;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List subheader={<ListSubheader>Sub Competencies</ListSubheader>} dense className={classes.root}>
            {subcompetencies_list[compid] && Object.keys(subcompetencies_list[compid]).map(id => {
              const labelId = `${subcompetencies_list[compid][id].id}`;
              const labelName = `${subcompetencies_list[compid][id].name}`;
              if (subcompetencies_list[compid][id] && subcompetencies_list[compid][id].count) {
                count = subcompetencies_list[compid][id].count
              } else {
                count = 0
              }
              if (labelName !== "undefined") {
                return (
                  <ListItem key={labelId} button>
                    <ListItemText id={labelId} primary={`${labelName}`} />
                    <ListItemSecondaryAction>
                      <Tooltip title="Edit">
                        <IconButton size="small" onClick={this.editSubCompetency(labelId, labelName)} edge="end" aria-label="edit">
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton size="small" onClick={this.deleteSubCompetencies(labelId, labelName, count)} edge="end" aria-label="delete">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }

            })}
          </List>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            className={classes.modal}
          >
            <div style={modalStyle} className={classes.paper}>
              <AddCompetency title="Edit Sub Competency" type="subcompetency" compid={this.props.compid} editText={this.state.editText} editID={this.state.editID} onClose={this.handleClose} />
            </div>
          </Modal>
          <Modal
            aria-labelledby="confirmation-modal"
            aria-describedby="confirmation-modal-description"
            open={this.state.openConfirm}
            onClose={this.handleClose}
            className={classes.modal}
          >
            <div style={modalStyle2} className={classes.paper}>
              <Typography variant="h6" gutterBottom>Please Confirm</Typography>
              <Typography variant="body" gutterBottom>Are you sure you want to delete {this.state.editText}?</Typography>
              <div>
                <ExpansionPanelActions>
                  <Button onClick={this.handleClose} size="small">Cancel</Button>
                  <Button onClick={this.deleteSubCompetency} size="small" color="secondary">Delete</Button>
                </ExpansionPanelActions>
              </div>
            </div>
          </Modal>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: "16px", marginTop: "-20px" }}>
          <AddCompetency title="Add Sub Competency" type="subcompetency" compid={compid} />
        </Grid>
      </Grid>
    )
  }
}

SubCompetencies.propTypes = {
  classes: PropTypes.object.isRequired
};

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
}
const modalStyle2 = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: "16px 12px 0px"
}

const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0],
    subcompetencies_list: state.subcompetenciesList.subcompetencies_list
  };
};

const SubCompetenciesStyled = withStyles(styles)(SubCompetencies);

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    fetchSubCompetencies: params => dispatch(fetchSubCompetencies(params)),
    deleteSubCompetency: params => dispatch(deleteSubCompetency(params)),
    showToast: params => dispatch(showToast(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubCompetenciesStyled);
