import { GET_POINTS, CLEAR_POINTS } from './../actions/types';

const initState = {
  type: "GET_POINTS",
  realpoints: []
};

//export default (state = initialState, action) => {
    const realPointsReducer = (state = initState , action) => {
	switch (action.type) {
            case 'GET_POINTS':
                console.log('green blue 8 : ', action.realpoints)
                return {
                    ...state,
                    type: "GET_POINTS",
                    realpoints: action.realpoints
                };  
            case 'CLEAR_POINTS':
              console.log('green blue 9 : ', action.realpoints)
              return {
                  ...state,
                  type: "CLEAR_POINTS",
                  realpoints: []
              };                             
		  default:
			return state;
	}
};

export default realPointsReducer