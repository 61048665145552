import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Typography, Grid, TextField, Select, MenuItem, IconButton, Tooltip } from '@material-ui/core'

class Reminder extends Component {
  state = {
  };

  render() {
    const { reminder } = this.props;

    return (
      <div>
        <Grid container spacing={2} style={{ alignItems: 'center', marginBottom: '12px' }}>
          <Grid item xs={12} sm={2}>
            <div className='customTextField' style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='body2'>Email</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className='customTextField' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <TextField
                id="reminder_time"
                size="small"
                fullWidth
                type="number"
                defaultValue={reminder.reminder_time}
                inputProps={{
                  name: "reminder_time",
                  id: "reminder_time",
                  min: 0,
                  max: 10,
                  step: 1
                }}
                onChange={e =>
                  this.props.updateReminder(e, reminder.reminder_id)
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className='customTextField'>
              <Select
                id="reminder_frequency"
                inputProps={{
                  name: "reminder_frequency",
                  id: "reminder_frequency"
                }}
                fullWidth
                className='customTextField'
                defaultValue={reminder.reminder_frequency}
                onChange={e =>
                  this.props.updateReminder(e, reminder.reminder_id)
                }
              >
                <MenuItem value='minutes'>minutes</MenuItem>
                <MenuItem value='hours'>hours</MenuItem>
                <MenuItem value='days'>days</MenuItem>
                <MenuItem value='weeks'>weeks</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip title="Remove notification">
              <IconButton size="small" edge="end" aria-label="cancel"
                onClick={e =>
                  this.props.removeReminder(reminder.reminder_id)
                }
              >
                <CloseOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Reminder.propTypes = {
  reminder: PropTypes.any.isRequired,
}

export default Reminder;
